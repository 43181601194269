/***
 * Library contains JavaScript API for interaction with Seeneco Framework Web Services contained in NotificationPackage
 * @author akeapy Generated by Seeneco Framework Modeler 
 *
 */
(function(w){
/***
* 
*/
w.NotificationEvent = function() {
  this.PACKAGE = "NOTIFICATION";
  this.CLASSIFIER = "NOTIFICATION_EVENT";
  this.CopySupportingElement = false;
  this.ID = -1;
  this.Code = "";
  this.Name = "";
  this.EmailTemplateFile = "";
  this.UIHtmlTemplate = "";
};
/***
* 
*/
w.NotificationRequest = function() {
  this.PACKAGE = "NOTIFICATION";
  this.CLASSIFIER = "NOTIFICATION_REQUEST";
  this.CopySupportingElement = false;
  this.ID = -1;
  this.NotificationDateTime = null;
  this.Comment = "";
  this.Parameters = {};
  this.Method = "";
  this.EventCode = "";
  this.NotificationTarget = "";
  this.Sent = false;
  this.MarkedRead = false;
  this.TenantID = -1;
  this.NotificationSessionID = -1;
  this.BusinessServiceCode = "";
};
/***
* 
*/
w.NotificationSessionRequest = function() {
  this.PACKAGE = "NOTIFICATION";
  this.CLASSIFIER = "NOTIFICATION_SESSION_REQUEST";
  this.CopySupportingElement = false;
  this.Notifications = [];
  this.SessionEvents = [];
  this.NotificationDate = null;
  this.OperationUID = -1;
};
 
 

/***
 * Client API to NotificationServiceWS web service
 */
w.NotificationServiceClient = function() {
  /***
   * System name of target service
   */
  this.serviceName = "NotificationServiceWSImpl";
};

/***
 * [DEFAULT!!DEFAULT]
 * @param notification [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.NotificationServiceClient.prototype.createAndSendNotification = function(notification, callbackHandler) {
  var parameterNames = ["notification"];
  var notificationJSON = JSON.stringify(notification);
  var parameterValues = [notificationJSON];
  var soapMessage = buildSOAPMessage("createAndSendNotification", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param tenantID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.NotificationServiceClient.prototype.getNotifications = function(tenantID, callbackHandler) {
  var parameterNames = ["tenantID"];
  var parameterValues = [tenantID];
  var soapMessage = buildSOAPMessage("getNotifications", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param notifications [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.NotificationServiceClient.prototype.updateNotifications = function(notifications, callbackHandler) {
  var parameterNames = ["notifications"];
  var notificationsJSON = JSON.stringify(notifications);
  var parameterValues = [notificationsJSON];
  var soapMessage = buildSOAPMessage("updateNotifications", parameterNames, parameterValues);
  var resultHasComplexType = false;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param notification [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.NotificationServiceClient.prototype.createNotification = function(notification, callbackHandler) {
  var parameterNames = ["notification"];
  var notificationJSON = JSON.stringify(notification);
  var parameterValues = [notificationJSON];
  var soapMessage = buildSOAPMessage("createNotification", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param notificationIDs [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.NotificationServiceClient.prototype.markNotificationsReaded = function(notificationIDs, callbackHandler) {
  var parameterNames = ["notificationIDs"];
  var notificationIDsJSON = JSON.stringify(notificationIDs);
  var parameterValues = [notificationIDsJSON];
  var soapMessage = buildSOAPMessage("markNotificationsReaded", parameterNames, parameterValues);
  var resultHasComplexType = false;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};


/***
 * Package security rights constants
 */

w.NotificationSecurityRights = {

};
})(typeof exports !== 'undefined' ? exports : window);
