/***
 * Library contains JavaScript API for interaction with Seeneco Framework Web Services contained in BudgetPackage
 * @author AlexPlex Generated by Seeneco Framework Modeler 
 *
 */
(function(w){
/***
* 
*/
w.Budget = function() {
  this.PACKAGE = "BUDGET";
  this.CLASSIFIER = "BUDGET";
  this.CopySupportingElement = false;
  this.ID = -1;
  this.Name = "";
  this.BudgetItems = [];
  this.Intervals = [];
  this.CounterpartyID = -1;
  this.ValueIntervalType = -1;
  this.MainCurrencyID = -1;
  this.FinancialStatementType = "";
  this.FinancialStatementTemplate = null;
  this.Consolidated = false;
  this.AccountingStandardID = -1;
  this.Version = null;
  this.VariantID = -1;
};
/***
* 
*/
w.BudgetChangeSet = function() {
  this.PACKAGE = "BUDGET";
  this.CLASSIFIER = "BUDGET_CHANGE_SET";
  this.CopySupportingElement = false;
  this.NewItems = [];
  this.ChangedItems = [];
  this.RemovedItems = [];
  this.FactValue = false;
};
/***
* 
*/
w.BudgetItem = function() {
  this.PACKAGE = "BUDGET";
  this.CLASSIFIER = "BUDGET_ITEM";
  this.CopySupportingElement = false;
  this.ID = -1;
  this.Name = "";
  this.Description = "";
  this.OrderNumForLevel = -1;
  this.ChildItems = [];
  this.ParentItemID = -1;
  this.ItemTemplate = null;
  this.Values = [];
  this.FinancialStatementType = "";
  this.AccChartItem = null;
};
/***
* 
*/
w.BudgetItemAllocation = function() {
  this.PACKAGE = "BUDGET";
  this.CLASSIFIER = "BUDGET_ITEM_ALLOCATION";
  this.CopySupportingElement = false;
  this.BudgetItemID = -1;
  this.RCAllocationPercents = [];
};
/***
* 
*/
w.BudgetItemTag = function() {
  this.PACKAGE = "BUDGET";
  this.CLASSIFIER = "BUDGET_ITEM_TAG";
  this.CopySupportingElement = false;
  this.BudgetItemID = -1;
  this.BudgetType = "";
};
/***
* 
*/
w.BudgetItemValue = function() {
  this.PACKAGE = "BUDGET";
  this.CLASSIFIER = "BUDGET_ITEM_VALUE";
  this.CopySupportingElement = false;
  this.BudgetPeriodIntervalID = -1;
  this.Value = -1;
  this.FactValue = false;
};
/***
* 
*/
w.BudgetPeriod = function() {
  this.PACKAGE = "BUDGET";
  this.CLASSIFIER = "BUDGET_PERIOD";
  this.CopySupportingElement = false;
  this.ID = -1;
  this.Name = "";
  this.StartDate = null;
  this.EndDate = null;
};
/***
* Date period for which budget item measure value is fixed
*/
w.BudgetPeriodInterval = function() {
  this.PACKAGE = "BUDGET";
  this.CLASSIFIER = "BUDGET_PERIOD_INTERVAL";
  this.CopySupportingElement = false;
  this.ID = -1;
  this.Name = "";
  this.OrderNum = -1;
  this.ValueIntervalType = -1;
  this.EndDate = null;
  this.CalendarValueDate = null;
  this.Audited = false;
  this.HasBreakdown = false;
  this.MainCurrencyID = -1;
};
/***
* 
*/
w.BudgetTransformationRequest = function() {
  this.PACKAGE = "BUDGET";
  this.CLASSIFIER = "BUDGET_TRANSFORMATION_REQUEST";
  this.CopySupportingElement = false;
  this.ExecutionProcessUID = -1;
  this.CounterpartyID = -1;
  this.TransformationID = -1;
  this.VariantID = -1;
};
/***
* 
*/
w.BudgetTransformationResult = function() {
  this.PACKAGE = "BUDGET";
  this.CLASSIFIER = "BUDGET_TRANSFORMATION_RESULT";
  this.CopySupportingElement = false;
  this.Status = "";
  this.ExecutionProcessUID = -1;
  this.ChangedItemsCount = -1;
  this.TargetBudgetID = -1;
};
/***
* 
*/
w.BudgetVariant = function() {
  this.PACKAGE = "BUDGET";
  this.CLASSIFIER = "BUDGET_VARIANT";
  this.CopySupportingElement = false;
  this.Code = "";
  this.ID = -1;
  this.Name = "";
  this.GUID = "";
};
/***
* 
*/
w.BudgetVersion = function() {
  this.PACKAGE = "BUDGET";
  this.CLASSIFIER = "BUDGET_VERSION";
  this.CopySupportingElement = false;
  this.ID = -1;
  this.FixDate = null;
  this.FixEmployee = null;
  this.Fixed = false;
  this.CreateDate = null;
  this.CreateEmployee = null;
  this.Comment = "";
};
/***
* 
*/
w.ParseFSFileResponse = function() {
  this.PACKAGE = "BUDGET";
  this.CLASSIFIER = "PARSE_FSFILE_RESPONSE";
  this.CopySupportingElement = false;
  this.FSItems = [];
  this.Status = "";
  this.Message = "";
  this.ErrorCode = "";
};
/***
* 
*/
w.RCAllocationPercent = function() {
  this.PACKAGE = "BUDGET";
  this.CLASSIFIER = "RCALLOCATION_PERCENT";
  this.CopySupportingElement = false;
  this.ResponsibilityCenterID = -1;
  this.Percent = -1;
};
/***
* 
*/
w.ResponsibilityCenterBudget = function() {
  this.PACKAGE = "BUDGET";
  this.CLASSIFIER = "RESPONSIBILITY_CENTER_BUDGET";
  this.CopySupportingElement = false;
  this.ResponsibilityCenterID = -1;
  this.GeneralBudgetID = -1;
  this.ID = -1;
  this.Name = "";
  this.BudgetItems = [];
  this.Intervals = [];
  this.CounterpartyID = -1;
  this.ValueIntervalType = -1;
  this.MainCurrencyID = -1;
  this.FinancialStatementType = "";
  this.FinancialStatementTemplate = null;
  this.Consolidated = false;
  this.AccountingStandardID = -1;
  this.Version = null;
  this.VariantID = -1;
};
 
 

/***
 * Client API to BudgetServiceWS web service
 */
w.BudgetServiceClient = function() {
  /***
   * System name of target service
   */
  this.serviceName = "BudgetServiceWSImpl";
};

/***
 * [DEFAULT!!DEFAULT]
 * @param budgetID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.BudgetServiceClient.prototype.getBudgetByID = function(budgetID, callbackHandler) {
  var parameterNames = ["budgetID"];
  var parameterValues = [budgetID];
  var soapMessage = buildSOAPMessage("getBudgetByID", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = true;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!Creates general budget for organization that sums up all budgets of responsibility centers!DEFAULT]
 * @param budgetPeriodID [DEFAULT!!DEFAULT]
 * @param fsTemplateID [DEFAULT!Financial statement template ID to create budget from!DEFAULT]
 * @param currencyID [DEFAULT!!DEFAULT]
 * @param variantID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.BudgetServiceClient.prototype.createGeneralBudget = function(budgetPeriodID, fsTemplateID, currencyID, variantID, callbackHandler) {
  var parameterNames = ["budgetPeriodID", "fsTemplateID", "currencyID", "variantID"];
  var parameterValues = [budgetPeriodID, fsTemplateID, currencyID, variantID];
  var soapMessage = buildSOAPMessage("createGeneralBudget", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param responsibilityCenterID [DEFAULT!!DEFAULT]
 * @param generalBudgetID [DEFAULT!Identifier of general budget that sums up value of responsibility center budget!DEFAULT]
 * @param fsTemplateID [DEFAULT!Financial statement template ID to create budget from!DEFAULT]
 * @param currencyID [DEFAULT!!DEFAULT]
 * @param variantID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.BudgetServiceClient.prototype.createResponsibilityCenterBudget = function(responsibilityCenterID, generalBudgetID, fsTemplateID, currencyID, variantID, callbackHandler) {
  var parameterNames = ["responsibilityCenterID", "generalBudgetID", "fsTemplateID", "currencyID", "variantID"];
  var parameterValues = [responsibilityCenterID, generalBudgetID, fsTemplateID, currencyID, variantID];
  var soapMessage = buildSOAPMessage("createResponsibilityCenterBudget", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param budgetID [DEFAULT!!DEFAULT]
 * @param changeSet [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.BudgetServiceClient.prototype.updateBudgetContents = function(budgetID, changeSet, callbackHandler) {
  var parameterNames = ["budgetID", "changeSet"];
  var changeSetJSON = JSON.stringify(changeSet);
  var parameterValues = [budgetID, changeSetJSON];
  var soapMessage = buildSOAPMessage("updateBudgetContents", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!Refreshes summary values in general(parent) budget from child budgets!DEFAULT]
 * @param generalBudgetID [DEFAULT!Identifier of general (parent) budget to refresh!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.BudgetServiceClient.prototype.refreshChildBudgetsSummary = function(generalBudgetID, callbackHandler) {
  var parameterNames = ["generalBudgetID"];
  var parameterValues = [generalBudgetID];
  var soapMessage = buildSOAPMessage("refreshChildBudgetsSummary", parameterNames, parameterValues);
  var resultHasComplexType = false;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param allocationPeriodID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.BudgetServiceClient.prototype.getBudgetItemAllocations = function(allocationPeriodID, callbackHandler) {
  var parameterNames = ["allocationPeriodID"];
  var parameterValues = [allocationPeriodID];
  var soapMessage = buildSOAPMessage("getBudgetItemAllocations", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param allocationPeriodID [DEFAULT!!DEFAULT]
 * @param newAllocations [DEFAULT!!DEFAULT]
 * @param changedAllocations [DEFAULT!!DEFAULT]
 * @param removedAllocations [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.BudgetServiceClient.prototype.changeAllocationsForPeriod = function(allocationPeriodID, newAllocations, changedAllocations, removedAllocations, callbackHandler) {
  var parameterNames = ["allocationPeriodID", "newAllocations", "changedAllocations", "removedAllocations"];
  var newAllocationsJSON = JSON.stringify(newAllocations);
  var changedAllocationsJSON = JSON.stringify(changedAllocations);
  var removedAllocationsJSON = JSON.stringify(removedAllocations);
  var parameterValues = [allocationPeriodID, newAllocationsJSON, changedAllocationsJSON, removedAllocationsJSON];
  var soapMessage = buildSOAPMessage("changeAllocationsForPeriod", parameterNames, parameterValues);
  var resultHasComplexType = false;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param budgetID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.BudgetServiceClient.prototype.calculateBudgetFact = function(budgetID, callbackHandler) {
  var parameterNames = ["budgetID"];
  var parameterValues = [budgetID];
  var soapMessage = buildSOAPMessage("calculateBudgetFact", parameterNames, parameterValues);
  var resultHasComplexType = false;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!Adds budget item by template set for item and
 initializes budget item with given collection of values.
If template for item is not created yet - first creates it and assigns to created item!DEFAULT]
 * @param budgetItem [DEFAULT!!DEFAULT]
 * @param budgetID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.BudgetServiceClient.prototype.addBudgetItemByTemplate = function(budgetItem, budgetID, callbackHandler) {
  var parameterNames = ["budgetItem", "budgetID"];
  var budgetItemJSON = JSON.stringify(budgetItem);
  var parameterValues = [budgetItemJSON, budgetID];
  var soapMessage = buildSOAPMessage("addBudgetItemByTemplate", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param budgetItem [DEFAULT!!DEFAULT]
 * @param removeItemTemplate [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.BudgetServiceClient.prototype.removeBudgetItem = function(budgetItem, removeItemTemplate, callbackHandler) {
  var parameterNames = ["budgetItem", "removeItemTemplate"];
  var budgetItemJSON = JSON.stringify(budgetItem);
  var parameterValues = [budgetItemJSON, removeItemTemplate];
  var soapMessage = buildSOAPMessage("removeBudgetItem", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.BudgetServiceClient.prototype.getBudgetPeriods = function(callbackHandler) {
  var parameterNames = [];
  var parameterValues = [];
  var soapMessage = buildSOAPMessage("getBudgetPeriods", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = true;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param counterpartyID [DEFAULT!!DEFAULT]
 * @param fsTemplateID [DEFAULT!!DEFAULT]
 * @param currencyID [DEFAULT!!DEFAULT]
 * @param valueIntervalType [DEFAULT!!DEFAULT]
 * @param variantID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.BudgetServiceClient.prototype.createCounterpartyBudget = function(counterpartyID, fsTemplateID, currencyID, valueIntervalType, variantID, callbackHandler) {
  var parameterNames = ["counterpartyID", "fsTemplateID", "currencyID", "valueIntervalType", "variantID"];
  var parameterValues = [counterpartyID, fsTemplateID, currencyID, valueIntervalType, variantID];
  var soapMessage = buildSOAPMessage("createCounterpartyBudget", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param budgetID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.BudgetServiceClient.prototype.removeBudget = function(budgetID, callbackHandler) {
  var parameterNames = ["budgetID"];
  var parameterValues = [budgetID];
  var soapMessage = buildSOAPMessage("removeBudget", parameterNames, parameterValues);
  var resultHasComplexType = false;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param counterpartyID [DEFAULT!!DEFAULT]
 * @param variantID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.BudgetServiceClient.prototype.getRatioCalculationLogs = function(counterpartyID, variantID, callbackHandler) {
  var parameterNames = ["counterpartyID", "variantID"];
  var parameterValues = [counterpartyID, variantID];
  var soapMessage = buildSOAPMessage("getRatioCalculationLogs", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = true;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param budgetID [DEFAULT!!DEFAULT]
 * @param currencyID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.BudgetServiceClient.prototype.getBudgetInCurrencyByID = function(budgetID, currencyID, callbackHandler) {
  var parameterNames = ["budgetID", "currencyID"];
  var parameterValues = [budgetID, currencyID];
  var soapMessage = buildSOAPMessage("getBudgetInCurrencyByID", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = true;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param budgetID [DEFAULT!!DEFAULT]
 * @param periodIntervalID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.BudgetServiceClient.prototype.removeBudgetPeriodInterval = function(budgetID, periodIntervalID, callbackHandler) {
  var parameterNames = ["budgetID", "periodIntervalID"];
  var parameterValues = [budgetID, periodIntervalID];
  var soapMessage = buildSOAPMessage("removeBudgetPeriodInterval", parameterNames, parameterValues);
  var resultHasComplexType = false;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param budgetID [DEFAULT!!DEFAULT]
 * @param budgetPeriodInterval [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.BudgetServiceClient.prototype.updateBudgetPeriodInterval = function(budgetID, budgetPeriodInterval, callbackHandler) {
  var parameterNames = ["budgetID", "budgetPeriodInterval"];
  var budgetPeriodIntervalJSON = JSON.stringify(budgetPeriodInterval);
  var parameterValues = [budgetID, budgetPeriodIntervalJSON];
  var soapMessage = buildSOAPMessage("updateBudgetPeriodInterval", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param counterpartyID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.BudgetServiceClient.prototype.getCounterpartyBudgetPeriodIntervals = function(counterpartyID, callbackHandler) {
  var parameterNames = ["counterpartyID"];
  var parameterValues = [counterpartyID];
  var soapMessage = buildSOAPMessage("getCounterpartyBudgetPeriodIntervals", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = true;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param counterpartyID [DEFAULT!!DEFAULT]
 * @param budgetID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.BudgetServiceClient.prototype.removeCounterpartyBudget = function(counterpartyID, budgetID, callbackHandler) {
  var parameterNames = ["counterpartyID", "budgetID"];
  var parameterValues = [counterpartyID, budgetID];
  var soapMessage = buildSOAPMessage("removeCounterpartyBudget", parameterNames, parameterValues);
  var resultHasComplexType = false;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param counterpartyID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.BudgetServiceClient.prototype.getCounterpartyBudgetList = function(counterpartyID, callbackHandler) {
  var parameterNames = ["counterpartyID"];
  var parameterValues = [counterpartyID];
  var soapMessage = buildSOAPMessage("getCounterpartyBudgetList", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param budget [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.BudgetServiceClient.prototype.updateBudget = function(budget, callbackHandler) {
  var parameterNames = ["budget"];
  var budgetJSON = JSON.stringify(budget);
  var parameterValues = [budgetJSON];
  var soapMessage = buildSOAPMessage("updateBudget", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!Saves current not fixed version of budget and creates new version.
Returns added new version of budget!DEFAULT]
 * @param counterpartyID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.BudgetServiceClient.prototype.saveCounterpartyBudgetVersion = function(counterpartyID, callbackHandler) {
  var parameterNames = ["counterpartyID"];
  var parameterValues = [counterpartyID];
  var soapMessage = buildSOAPMessage("saveCounterpartyBudgetVersion", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param budgetID [DEFAULT!!DEFAULT]
 * @param currencyID [DEFAULT!!DEFAULT]
 * @param budgetVersionID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.BudgetServiceClient.prototype.getBudgetVersionInCurrencyByID = function(budgetID, currencyID, budgetVersionID, callbackHandler) {
  var parameterNames = ["budgetID", "currencyID", "budgetVersionID"];
  var parameterValues = [budgetID, currencyID, budgetVersionID];
  var soapMessage = buildSOAPMessage("getBudgetVersionInCurrencyByID", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param counterpartyID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.BudgetServiceClient.prototype.getCounterpartyBudgetVersions = function(counterpartyID, callbackHandler) {
  var parameterNames = ["counterpartyID"];
  var parameterValues = [counterpartyID];
  var soapMessage = buildSOAPMessage("getCounterpartyBudgetVersions", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = true;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param budgetID [DEFAULT!!DEFAULT]
 * @param currencyID [DEFAULT!!DEFAULT]
 * @param budgetVersionDate [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.BudgetServiceClient.prototype.getBudgetVersionInCurrencyByDate = function(budgetID, currencyID, budgetVersionDate, callbackHandler) {
  var parameterNames = ["budgetID", "currencyID", "budgetVersionDate"];
  var parameterValues = [budgetID, currencyID, budgetVersionDate];
  var soapMessage = buildSOAPMessage("getBudgetVersionInCurrencyByDate", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = true;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param counterpartyID [DEFAULT!!DEFAULT]
 * @param transformationID [DEFAULT!!DEFAULT]
 * @param variantID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.BudgetServiceClient.prototype.transformBudget = function(counterpartyID, transformationID, variantID, callbackHandler) {
  var parameterNames = ["counterpartyID", "transformationID", "variantID"];
  var parameterValues = [counterpartyID, transformationID, variantID];
  var soapMessage = buildSOAPMessage("transformBudget", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param transformationProcessUID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.BudgetServiceClient.prototype.getBudgetTransformationResult = function(transformationProcessUID, callbackHandler) {
  var parameterNames = ["transformationProcessUID"];
  var parameterValues = [transformationProcessUID];
  var soapMessage = buildSOAPMessage("getBudgetTransformationResult", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param counterpartyID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.BudgetServiceClient.prototype.checkCurrentBudgetVersionContainsChanges = function(counterpartyID, callbackHandler) {
  var parameterNames = ["counterpartyID"];
  var parameterValues = [counterpartyID];
  var soapMessage = buildSOAPMessage("checkCurrentBudgetVersionContainsChanges", parameterNames, parameterValues);
  var resultHasComplexType = false;
  var autoRestartOnError = true;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param counterpartyID [DEFAULT!!DEFAULT]
 * @param budgetVariantID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.BudgetServiceClient.prototype.getCounterpartyBudgetPeriodIntervalsByVariantID = function(counterpartyID, budgetVariantID, callbackHandler) {
  var parameterNames = ["counterpartyID", "budgetVariantID"];
  var parameterValues = [counterpartyID, budgetVariantID];
  var soapMessage = buildSOAPMessage("getCounterpartyBudgetPeriodIntervalsByVariantID", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!Add new budget items relevant to FSTemplateItems that appeared in Financial Statement Template after budget creation 
Return false is budget is already up-to-date!DEFAULT]
 * @param budgetID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.BudgetServiceClient.prototype.updateBudgetByTemplate = function(budgetID, callbackHandler) {
  var parameterNames = ["budgetID"];
  var parameterValues = [budgetID];
  var soapMessage = buildSOAPMessage("updateBudgetByTemplate", parameterNames, parameterValues);
  var resultHasComplexType = false;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param budgetID [DEFAULT!!DEFAULT]
 * @param periodIntervalIDList [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.BudgetServiceClient.prototype.removeBudgetPeriodIntervals = function(budgetID, periodIntervalIDList, callbackHandler) {
  var parameterNames = ["budgetID", "periodIntervalIDList"];
  var periodIntervalIDListJSON = JSON.stringify(periodIntervalIDList);
  var parameterValues = [budgetID, periodIntervalIDListJSON];
  var soapMessage = buildSOAPMessage("removeBudgetPeriodIntervals", parameterNames, parameterValues);
  var resultHasComplexType = false;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!Return both day and "from start of financial year" currency rates for all periods of counterparty budgets
Source rates - rates of budget intervals!DEFAULT]
 * @param counterpartyID [DEFAULT!!DEFAULT]
 * @param targetCurrencyID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.BudgetServiceClient.prototype.getCounterpartyCurrencyRates = function(counterpartyID, targetCurrencyID, callbackHandler) {
  var parameterNames = ["counterpartyID", "targetCurrencyID"];
  var parameterValues = [counterpartyID, targetCurrencyID];
  var soapMessage = buildSOAPMessage("getCounterpartyCurrencyRates", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param budgetVersion [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.BudgetServiceClient.prototype.updateBudgetVersion = function(budgetVersion, callbackHandler) {
  var parameterNames = ["budgetVersion"];
  var budgetVersionJSON = JSON.stringify(budgetVersion);
  var parameterValues = [budgetVersionJSON];
  var soapMessage = buildSOAPMessage("updateBudgetVersion", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param startDate [DEFAULT!!DEFAULT]
 * @param endDate [DEFAULT!!DEFAULT]
 * @param budgetID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.BudgetServiceClient.prototype.getBudgetPeriodIntervals = function(startDate, endDate, budgetID, callbackHandler) {
  var parameterNames = ["startDate", "endDate", "budgetID"];
  var parameterValues = [startDate, endDate, budgetID];
  var soapMessage = buildSOAPMessage("getBudgetPeriodIntervals", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!Синхронизировать статью бюджета при добавлении/изменении/удалении статьи ПС УУ!DEFAULT]
 * @param accountsChartItem [DEFAULT!!DEFAULT]
 * @param synchronizationType [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.BudgetServiceClient.prototype.synchronizeBudgetItemAccountsChartItem = function(accountsChartItem, synchronizationType, callbackHandler) {
  var parameterNames = ["accountsChartItem", "synchronizationType"];
  var accountsChartItemJSON = JSON.stringify(accountsChartItem);
  var parameterValues = [accountsChartItemJSON, synchronizationType];
  var soapMessage = buildSOAPMessage("synchronizeBudgetItemAccountsChartItem", parameterNames, parameterValues);
  var resultHasComplexType = false;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param startDate [DEFAULT!!DEFAULT]
 * @param endDate [DEFAULT!!DEFAULT]
 * @param budgetID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.BudgetServiceClient.prototype.createBudgetPeriodIntervals = function(startDate, endDate, budgetID, callbackHandler) {
  var parameterNames = ["startDate", "endDate", "budgetID"];
  var parameterValues = [startDate, endDate, budgetID];
  var soapMessage = buildSOAPMessage("createBudgetPeriodIntervals", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};


/***
 * Package security rights constants
 */

w.BudgetSecurityRights = {

    BUDGET_PLANNING_RIGHT:new SecurityRightCheckHandle('2e034e32-dc4d-46c9-8e7e-39640af66245')
};
})(typeof exports !== 'undefined' ? exports : window);
