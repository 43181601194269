// @ts-nocheck
import * as _ from 'underscore';

import { invalidateStateFromAction, StoreAsyncItem } from '@flux';
import { checkAsyncItemLoadAction, createDefaultAsyncItem } from '../../../core/libs/reducer-creator';
import {
	CHANGE_TARIFF_LEVEL,
	CREATE_PROMISED_PAYMENT_SERVICE_REQUEST,
	FETCH_ALL_TENANT_BILLS,
	FETCH_BILLING_SERVICE_STATUS,
	FETCH_TARIFFS,
	FETCH_TENANT_ACCOUNT,
	FETCH_TENANT_BILLS,
	INVALIDATE_ALL_TENANT_BILLS,
	REGISTER_SERVICE_REQUEST,
	REVOKE_TENANT_BILL,
	SELECT_TARIFF,
	SEND_INVOICE_PAYMENT_TO_CMS,
	SET_STATUS_AFTER_BILLING,
} from '../actions/billing.actions';
import { FETCH_SESSION_CONTEXT } from '../actions/session-context.actions';

interface IBillingState {
	allTenantBills: StoreAsyncItem<Record<string, TenantBill>>;
	billingServiceStatus: StoreAsyncItem<BillingServiceStatus>;
	CMSPaymentResponse: StoreAsyncItem<CMSCreatePaymentDraftResponse>;
	selectedTariff: {
		tariff: Tariff;
		tariffRate: TariffRate;
	};
	statusAfterBilling: StatusAction;
	tariffs: StoreAsyncItem<Record<string, Tariff>>;
	tenantAccount: StoreAsyncItem<TenantAccount>;
	tenantBills: StoreAsyncItem<Record<string, TenantBill>>;
}

const initialState: IBillingState = {
	allTenantBills: createDefaultAsyncItem({}),
	billingServiceStatus: createDefaultAsyncItem({}),
	CMSPaymentResponse: createDefaultAsyncItem({}),
	selectedTariff: {
		tariff: null,
		tariffRate: null,
	},
	statusAfterBilling: null,
	tariffs: createDefaultAsyncItem({}),
	tenantAccount: createDefaultAsyncItem({}),
	tenantBills: createDefaultAsyncItem({}),
};

export default function (state = initialState, action) {
	switch (action.type) {
		case FETCH_SESSION_CONTEXT:
		case CHANGE_TARIFF_LEVEL:
			return {
				...state,
				tenantAccount: invalidateStateFromAction(action, state.tenantAccount),
			};

		case CREATE_PROMISED_PAYMENT_SERVICE_REQUEST:
			return {
				...state,
				allTenantBills: invalidateStateFromAction(action, state.allTenantBills),
				billingServiceStatus: invalidateStateFromAction(action, state.billingServiceStatus),
				tenantAccount: invalidateStateFromAction(action, state.tenantAccount),
			};

		case FETCH_ALL_TENANT_BILLS:
			const fetchAllTenantBillsAction = action as AsyncAction<Array<TenantBill>>;

			return {
				...state,
				allTenantBills: checkAsyncItemLoadAction(fetchAllTenantBillsAction, state.allTenantBills, response =>
					_.indexBy(response, el => el.ID),
				),
			};

		case FETCH_BILLING_SERVICE_STATUS:
			const fetchBillingServiceStatusAction = action as AsyncAction<BillingServiceStatus>;

			return {
				...state,
				billingServiceStatus: checkAsyncItemLoadAction(
					fetchBillingServiceStatusAction,
					state.billingServiceStatus,
					response => response,
				),
			};

		case FETCH_TARIFFS:
			const fetchTariffsAction = action as AsyncAction<Array<Tariff>>;

			return {
				...state,
				tariffs: checkAsyncItemLoadAction(fetchTariffsAction, state.tariffs, response =>
					_.indexBy(response, el => el.ID),
				),
			};

		case FETCH_TENANT_ACCOUNT:
			const fetchTenantAccountAction = action as AsyncAction<TenantAccount>;

			return {
				...state,
				tenantAccount: checkAsyncItemLoadAction(fetchTenantAccountAction, state.tenantAccount, response => response),
			};

		case FETCH_TENANT_BILLS:
			const fetchTenantBillsAction = action as AsyncAction<Array<TenantBill>>;

			return {
				...state,
				tenantBills: checkAsyncItemLoadAction(fetchTenantBillsAction, state.tenantBills, response =>
					_.indexBy(response, el => el.ID),
				),
			};

		case INVALIDATE_ALL_TENANT_BILLS:
			return {
				...state,
				allTenantBills: invalidateStateFromAction(action, state.allTenantBills),
			};

		case REGISTER_SERVICE_REQUEST:
			const registerServiceRequestAction = action as AsyncAction<SuccessMessage>;

			if (registerServiceRequestAction.status === 'RECEIVE') {
				return {
					...state,
					tenantAccount: {
						...state.tenantAccount,
						didInvalidate: true,
					},
					tenantBills: {
						...state.tenantBills,
						didInvalidate: true,
					},
					allTenantBills: {
						...state.allTenantBills,
						didInvalidate: true,
					},
				};
			} else {
				return state;
			}

		case REVOKE_TENANT_BILL:
			const revokeTenantBillAction = action as AsyncAction<boolean>;

			if (revokeTenantBillAction.status === 'RECEIVE') {
				return {
					...state,
					tenantAccount: {
						...state.tenantAccount,
						didInvalidate: true,
					},
					tenantBills: {
						...state.tenantBills,
						didInvalidate: true,
					},
					allTenantBills: {
						...state.allTenantBills,
						didInvalidate: true,
					},
				};
			} else {
				return state;
			}

		case SELECT_TARIFF:
			const selectTariffAction = action as StaticAction<{ tariff: Tariff; tariffRate: TariffRate }>;

			return {
				...state,
				selectedTariff: {
					tariff: selectTariffAction.value.tariff,
					tariffRate: selectTariffAction.value.tariffRate,
				},
			};

		case SEND_INVOICE_PAYMENT_TO_CMS:
			const sendToCMSAction = action as AsyncAction<CMSCreatePaymentDraftResponse>;

			return {
				...state,
				CMSPaymentResponse: checkAsyncItemLoadAction(sendToCMSAction, state.CMSPaymentResponse),
			};

		case SET_STATUS_AFTER_BILLING:
			const setStatusAction = action as StaticAction<StatusAction>;

			return {
				...state,
				statusAfterBilling: setStatusAction.value,
			};

		default:
			return state;
	}
}

export { IBillingState };
