import styled from 'styled-components';

import * as c from '../../basic-theme/colors';

interface IRoot {
	withoutPaddings: boolean;
}

const Root = styled.div`
	width: 100%;
	height: auto;
	padding: 17px;
	border-radius: 2px;
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: flex-start;

	${(p: IRoot) =>
		p.withoutPaddings &&
		`
		padding: 0;
	`}
`;

const IconLayout = styled.div`
	position: relative;
	top: 1px;
	flex-shrink: 0;
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	align-items: center;
	margin-right: 8px;

	& svg {
		width: 8px !important;
		height: 8px !important;
	}
`;

const MessageLayout = styled.div`
	flex-shrink: 1;
	font-size: 14px;
	line-height: 1.43;
	text-align: left;
	color: ${c.blackSecondary};
`;

export { Root, IconLayout, MessageLayout };
