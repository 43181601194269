import React from 'react';

import { IconBase, IconBaseProps } from './base';

export type BookIconProps = IconBaseProps;

const BookIcon: React.FC<BookIconProps> = props => {
	return (
		<IconBase {...props}>
			<svg
				fill='currentColor'
				stroke='currentColor'
				strokeWidth='0'
				viewBox='0 0 16 14'
				xmlns='http://www.w3.org/2000/svg'>
				<path d='M1 2.828C1.885 2.458 3.154 2.059 4.388 1.935C5.718 1.801 6.846 1.998 7.5 2.687V12.433C6.565 11.903 5.38 11.83 4.287 11.94C3.107 12.06 1.917 12.401 1 12.751V2.828ZM8.5 2.687C9.154 1.998 10.282 1.801 11.612 1.935C12.846 2.059 14.115 2.458 15 2.828V12.751C14.082 12.401 12.893 12.059 11.713 11.941C10.619 11.83 9.435 11.902 8.5 12.433V2.687ZM8 1.783C7.015 0.936003 5.587 0.810003 4.287 0.940003C2.773 1.093 1.245 1.612 0.293 2.045C0.205649 2.08473 0.131575 2.14876 0.079621 2.22944C0.0276667 2.31012 2.65714e-05 2.40404 0 2.5L0 13.5C2.3162e-05 13.5837 0.0210371 13.666 0.0611171 13.7394C0.101197 13.8128 0.159062 13.875 0.229411 13.9203C0.29976 13.9656 0.380345 13.9925 0.463783 13.9986C0.547222 14.0046 0.630848 13.9896 0.707 13.955C1.589 13.555 3.01 13.074 4.387 12.935C5.796 12.793 6.977 13.022 7.61 13.812C7.65685 13.8704 7.71622 13.9175 7.78372 13.9499C7.85122 13.9823 7.92513 13.9991 8 13.9991C8.07487 13.9991 8.14878 13.9823 8.21628 13.9499C8.28378 13.9175 8.34315 13.8704 8.39 13.812C9.023 13.022 10.204 12.793 11.612 12.935C12.99 13.074 14.412 13.555 15.293 13.955C15.3692 13.9896 15.4528 14.0046 15.5362 13.9986C15.6197 13.9925 15.7002 13.9656 15.7706 13.9203C15.8409 13.875 15.8988 13.8128 15.9389 13.7394C15.979 13.666 16 13.5837 16 13.5V2.5C16 2.40404 15.9723 2.31012 15.9204 2.22944C15.8684 2.14876 15.7944 2.08473 15.707 2.045C14.755 1.612 13.227 1.093 11.713 0.940003C10.413 0.809003 8.985 0.936003 8 1.783Z' />
			</svg>
		</IconBase>
	);
};

export { BookIcon };
