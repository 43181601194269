/***
 * Library contains JavaScript API for interaction with Seeneco Framework Web Services contained in BillingPackage
 * @author tim Generated by Seeneco Framework Modeler 
 *
 */
(function(w){
  /***
  * 
  */
  w.AccessPeriod = function() {
    this.PACKAGE = "BILLING";
    this.CLASSIFIER = "ACCESS_PERIOD";
    this.CopySupportingElement = false;
    this.From = null;
    this.To = null;
    this.TariffName = "";
    this.DurationMonth = -1;
    this.BillingServiceRequestID = -1;
    this.PaymentMethod = "";
    this.PaymentDate = null;
    this.AmoPipeline = "";
    this.AmoStatus = "";
    this.AmoResponsible = "";
    this.TariffRate = -1;
  };
  /***
  * 
  */
  w.APIBillingPayment = function() {
    this.PACKAGE = "BILLING";
    this.CLASSIFIER = "APIBILLING_PAYMENT";
    this.CopySupportingElement = false;
    this.Amount = -1;
    this.PaymentDocumentDate = null;
    this.Reference = "";
    this.PayerLegalName = "";
    this.PayerTaxCode = "";
    this.PayerAccountNumber = "";
    this.PaymentDocumentNumber = "";
    this.PayerBankBIC = "";
    this.PayerBankName = "";
    this.BillNumber = "";
  };
  /***
  * 
  */
  w.APITariff = function() {
    this.PACKAGE = "BILLING";
    this.CLASSIFIER = "APITARIFF";
    this.CopySupportingElement = false;
    this.Name = "";
    this.Rates = [];
    this.Code = "";
    this.BusinessService = "";
  };
  /***
  * 
  */
  w.APITariffRate = function() {
    this.PACKAGE = "BILLING";
    this.CLASSIFIER = "APITARIFF_RATE";
    this.CopySupportingElement = false;
    this.Name = "";
    this.PeriodMonthes = -1;
    this.Amount = -1;
  };
  /***
  * 
  */
  w.APITenantAccount = function() {
    this.PACKAGE = "BILLING";
    this.CLASSIFIER = "APITENANT_ACCOUNT";
    this.CopySupportingElement = false;
    this.ExpireDate = null;
    this.Active = false;
    this.ActiveTariff = "";
    this.TrialMode = false;
    this.FreemiumMode = false;
    this.OrganizationLegalName = "";
    this.OrganizationTaxCode = "";
    this.BusinessService = "";
  };
  /***
  * 
  */
  w.APITenantBill = function() {
    this.PACKAGE = "BILLING";
    this.CLASSIFIER = "APITENANT_BILL";
    this.CopySupportingElement = false;
    this.Amount = -1;
    this.IssueDateTime = null;
    this.BillNumber = "";
    this.IssuerLegalName = "";
    this.IssuerLegalAddress = "";
    this.IssuerBankName = "";
    this.IssuerBankBIC = "";
    this.IssuerBankCorrAccNumber = "";
    this.IssuerSettlementAccountNumber = "";
    this.IssuerStateRegNumber = "";
    this.IssuerTaxReferenceNumber = "";
    this.IssuerTaxRegReasonCode = "";
    this.Description = "";
    this.BusinessService = "";
  };
  /***
  * 
  */
  w.BillingDefaultMapping = function() {
    this.PACKAGE = "BILLING";
    this.CLASSIFIER = "BILLING_DEFAULT_MAPPING";
    this.CopySupportingElement = false;
    this.BillingGroupID = -1;
    this.BusinessServiceID = -1;
    this.ProviderID = -1;
    this.TariffID = -1;
    this.ID = -1;
    this.RequestedTariffLevelID = -1;
  };
  /***
  * 
  */
  w.BillingGroup = function() {
    this.PACKAGE = "BILLING";
    this.CLASSIFIER = "BILLING_GROUP";
    this.CopySupportingElement = false;
    this.ID = -1;
    this.Name = "";
    this.Code = "";
    this.Tariffs = [];
    this.BusinessService = null;
    this.DescriptionURL = "";
  };
  /***
  * Operation executed in billing subsystem - such as synchronizing direct debit agreements, issuing bills, sending payment demands
  */
  w.BillingOperation = function() {
    this.PACKAGE = "BILLING";
    this.CLASSIFIER = "BILLING_OPERATION";
    this.CopySupportingElement = false;
    this.ID = -1;
    this.BusinessDate = null;
    this.Status = "";
    this.OperationType = "";
    this.StartDate = null;
    this.FinishDate = null;
    this.Description = "";
    this.BusinessService = null;
  };
  /***
  * 
  */
  w.BillingOperationStepLog = function() {
    this.PACKAGE = "BILLING";
    this.CLASSIFIER = "BILLING_OPERATION_STEP_LOG";
    this.CopySupportingElement = false;
    this.BillingOperationID = -1;
    this.Description = "";
    this.Success = false;
    this.ID = -1;
    this.TenantID = -1;
    this.BillingOperationStep = "";
  };
  /***
  * 
  */
  w.BillingPayment = function() {
    this.PACKAGE = "BILLING";
    this.CLASSIFIER = "BILLING_PAYMENT";
    this.CopySupportingElement = false;
    this.Amount = -1;
    this.PaymentDate = null;
    this.Reference = "";
    this.PaymentMethodCode = "";
    this.ID = -1;
    this.ExternalSystemBillUID = "";
  };
  /***
  * 
  */
  w.BillingPaymentDemand = function() {
    this.PACKAGE = "BILLING";
    this.CLASSIFIER = "BILLING_PAYMENT_DEMAND";
    this.CopySupportingElement = false;
    this.ID = -1;
    this.Reference = "";
    this.PaymentDemandDate = null;
    this.Amount = -1;
    this.PayerTaxCode = "";
    this.PayerAccountNumber = "";
    this.PaymentDocumentNumber = "";
    this.PayerBankBIC = "";
    this.UID = "";
    this.BillNumber = "";
    this.PayerBankCorrAccount = "";
    this.RecipientTaxCode = "";
    this.RecipientAccountNumber = "";
    this.RecipientBankBIC = "";
    this.RecipientBankCorrAccount = "";
    this.PayerName = "";
    this.RecipientName = "";
    this.AcceptanceTerm = -1;
    this.TenantID = -1;
    this.AgreementID = -1;
    this.State = "";
    this.BankComment = "";
    this.BillingOperationID = -1;
  };
  /***
  * 
  */
  w.BillingPaymentDemandResponse = function() {
    this.PACKAGE = "BILLING";
    this.CLASSIFIER = "BILLING_PAYMENT_DEMAND_RESPONSE";
    this.CopySupportingElement = false;
    this.PaymentDemand = null;
    this.Status = "";
    this.Message = "";
    this.ErrorCode = "";
  };
  /***
  * 
  */
  w.BillingPaymentDemandStateResponse = function() {
    this.PACKAGE = "BILLING";
    this.CLASSIFIER = "BILLING_PAYMENT_DEMAND_STATE_RESPONSE";
    this.CopySupportingElement = false;
    this.State = "";
    this.Comment = "";
    this.Status = "";
    this.Message = "";
    this.ErrorCode = "";
  };
  /***
  * 
  */
  w.BillingPaymentDemandStateSynchJob = function() {
    this.PACKAGE = "BILLING";
    this.CLASSIFIER = "BILLING_PAYMENT_DEMAND_STATE_SYNCH_JOB";
    this.CopySupportingElement = false;
    this.LastCheckDateTime = null;
    this.FirstCheckDateTime = null;
    this.StartedSecurityAgent = null;
    this.SessionTicketUID = "";
    this.PaymentDemandUID = "";
    this.ID = -1;
    this.PaymentSystemCode = "";
    this.SingleCheck = false;
  };
  /***
  * 
  */
  w.BillingPaymentsByFilterRequest = function() {
    this.PACKAGE = "BILLING";
    this.CLASSIFIER = "BILLING_PAYMENTS_BY_FILTER_REQUEST";
    this.CopySupportingElement = false;
    this.DateStart = null;
    this.DateFinish = null;
    this.TenantIDList = [];
  };
  /***
  * 
  */
  w.BillingPaymentsByFilterResponse = function() {
    this.PACKAGE = "BILLING";
    this.CLASSIFIER = "BILLING_PAYMENTS_BY_FILTER_RESPONSE";
    this.CopySupportingElement = false;
    this.TenantID = -1;
    this.BillingPayments = [];
  };
  /***
  * 
  */
  w.BillingServiceRequest = function() {
    this.PACKAGE = "BILLING";
    this.CLASSIFIER = "BILLING_SERVICE_REQUEST";
    this.CopySupportingElement = false;
    this.ID = -1;
    this.TariffName = "";
    this.CreateDateTime = null;
    this.Processed = false;
    this.DurationName = "";
    this.Revoked = false;
    this.RequiredStartDate = null;
    this.TariffRate = null;
    this.BillingOperationID = -1;
  };
  /***
  * 
  */
  w.BillingServiceStatus = function() {
    this.PACKAGE = "BILLING";
    this.CLASSIFIER = "BILLING_SERVICE_STATUS";
    this.CopySupportingElement = false;
    this.AccessGranted = false;
    this.Warning = false;
    this.Message = "";
    this.FreemiumMode = false;
    this.BillStatusCode = "";
    this.PromisedPaymentAvailable = false;
  };
  /***
  * 
  */
  w.ChangeTariffLevelRequest = function() {
    this.PACKAGE = "BILLING";
    this.CLASSIFIER = "CHANGE_TARIFF_LEVEL_REQUEST";
    this.CopySupportingElement = false;
    this.TariffLevelID = -1;
    this.StartDate = null;
    this.BusinessServiceID = -1;
  };
  /***
  * 
  */
  w.DirectDebitAgreement = function() {
    this.PACKAGE = "BILLING";
    this.CLASSIFIER = "DIRECT_DEBIT_AGREEMENT";
    this.CopySupportingElement = false;
    this.ID = -1;
    this.Reference = "";
    this.PayerTaxCode = "";
    this.PayerAccountNumber = "";
    this.PayerBankBIC = "";
    this.DateStart = null;
    this.Active = false;
    this.DateFinish = null;
    this.PayerOrganizationUID = "";
    this.BankSubsystemInstanceUID = "";
    this.PayerName = "";
    this.PayerBankCorrAccount = "";
    this.CreatedBillingOperationID = -1;
    this.UpdatedOperationID = -1;
    this.BusinessService = null;
  };
  /***
  * 
  */
  w.DirectDebitAgreementResponse = function() {
    this.PACKAGE = "BILLING";
    this.CLASSIFIER = "DIRECT_DEBIT_AGREEMENT_RESPONSE";
    this.CopySupportingElement = false;
    this.DirectDebitAgreements = [];
    this.Status = "";
    this.Message = "";
    this.ErrorCode = "";
  };
  /***
  * 
  */
  w.DirectDebitAgreementSynchRequest = function() {
    this.PACKAGE = "BILLING";
    this.CLASSIFIER = "DIRECT_DEBIT_AGREEMENT_SYNCH_REQUEST";
    this.CopySupportingElement = false;
    this.AgreementStatusChangeDate = null;
    this.PaymentSystemCode = "";
    this.PaymentSystemRecipientAccount = null;
    this.PaymentSystemSSInstanceID = -1;
    this.OperationUID = -1;
  };
  /***
  * 
  */
  w.DirectDebitBillsProcessRequest = function() {
    this.PACKAGE = "BILLING";
    this.CLASSIFIER = "DIRECT_DEBIT_BILLS_PROCESS_REQUEST";
    this.CopySupportingElement = false;
    this.ServiceExpireDate = null;
    this.BillingOperationTypeID = -1;
    this.PaymentSystemCode = "";
    this.PaymentSystemRecipientAccount = null;
    this.PaymentSystemSSInstanceID = -1;
    this.OperationUID = -1;
  };
  /***
  * 
  */
  w.DirectDebitPaymentSystemRequest = function() {
    this.PACKAGE = "BILLING";
    this.CLASSIFIER = "DIRECT_DEBIT_PAYMENT_SYSTEM_REQUEST";
    this.CopySupportingElement = false;
    this.PaymentSystemCode = "";
    this.PaymentSystemRecipientAccount = null;
    this.PaymentSystemSSInstanceID = -1;
    this.OperationUID = -1;
  };
  /***
  * 
  */
  w.HistoryMonthTariffRestrictionValue = function() {
    this.PACKAGE = "BILLING";
    this.CLASSIFIER = "HISTORY_MONTH_TARIFF_RESTRICTION_VALUE";
    this.CopySupportingElement = false;
    this.HistoryMonthLimit = -1;
    this.Restriction = null;
    this.Enabled = false;
  };
  /***
  * 
  */
  w.ObjectCountTariffRestrictionValue = function() {
    this.PACKAGE = "BILLING";
    this.CLASSIFIER = "OBJECT_COUNT_TARIFF_RESTRICTION_VALUE";
    this.CopySupportingElement = false;
    this.ObjectCountLimit = -1;
    this.Restriction = null;
    this.Enabled = false;
  };
  /***
  * Request for synchronize state of payment demands that were not implemented in time 
  and were put aside for future implementation due to some reasons (i.e. insufficient funds)
  */
  w.OutstandingBillingPaymentDemandsStateSynchRequest = function() {
    this.PACKAGE = "BILLING";
    this.CLASSIFIER = "OUTSTANDING_BILLING_PAYMENT_DEMANDS_STATE_SYNCH_REQUEST";
    this.CopySupportingElement = false;
    this.PaymentDemandsNumber = -1;
    this.PaymentSystemCode = "";
    this.PaymentSystemRecipientAccount = null;
    this.PaymentSystemSSInstanceID = -1;
    this.OperationUID = -1;
  };
  /***
  * 
  */
  w.PaymentRequisites = function() {
    this.PACKAGE = "BILLING";
    this.CLASSIFIER = "PAYMENT_REQUISITES";
    this.CopySupportingElement = false;
    this.LegalName = "";
    this.LegalAddress = "";
    this.BankName = "";
    this.BankBIC = "";
    this.BankCorrAccNumber = "";
    this.SettlementAccountNumber = "";
    this.StateRegNumber = "";
    this.TaxReferenceNumber = "";
    this.TaxRegReasonCode = "";
    this.ID = -1;
  };
  /***
  * 
  */
  w.PaymentServiceOperationResult = function() {
    this.PACKAGE = "BILLING";
    this.CLASSIFIER = "PAYMENT_SERVICE_OPERATION_RESULT";
    this.CopySupportingElement = false;
    this.ResultMessage = "";
    this.OperationSuccess = false;
    this.AppliedPaymentMethod = "";
  };
  /***
  * Request to external payment service 
  enabling tenant to pay for his bills by means of card, e-money etc
  */
  w.PaymentServiceRequest = function() {
    this.PACKAGE = "BILLING";
    this.CLASSIFIER = "PAYMENT_SERVICE_REQUEST";
    this.CopySupportingElement = false;
    this.ServiceURL = "";
    this.Parameters = {};
  };
  /***
  * 
  */
  w.PaymentServiceResponse = function() {
    this.PACKAGE = "BILLING";
    this.CLASSIFIER = "PAYMENT_SERVICE_RESPONSE";
    this.CopySupportingElement = false;
    this.Values = {};
  };
  /***
  * 
  */
  w.SeenecoCashTransaction = function() {
    this.PACKAGE = "BILLING";
    this.CLASSIFIER = "SEENECO_CASH_TRANSACTION";
    this.CopySupportingElement = false;
    this.TransactionDate = null;
    this.Amount = -1;
    this.Reference = "";
    this.TransactionUID = "";
    this.Charge = false;
    this.Recipient = null;
    this.Payer = null;
    this.RecipientAccount = null;
    this.PayerAccount = null;
    this.InternalTransfer = false;
  };
  /***
  * 
  */
  w.SeenecoCounterpartyAccount = function() {
    this.PACKAGE = "BILLING";
    this.CLASSIFIER = "SEENECO_COUNTERPARTY_ACCOUNT";
    this.CopySupportingElement = false;
    this.AccountNumber = "";
    this.BankName = "";
    this.BankBIC = "";
    this.CounterpartyAccountUID = "";
    this.AccountType = "";
  };
  /***
  * 
  */
  w.SeenecoCounterpartyBrief = function() {
    this.PACKAGE = "BILLING";
    this.CLASSIFIER = "SEENECO_COUNTERPARTY_BRIEF";
    this.CopySupportingElement = false;
    this.CounterpartyUID = "";
    this.Name = "";
    this.TaxCode = "";
    this.LegalEntity = false;
    this.SoleProprietor = false;
  };
  /***
  * 
  */
  w.SeenecoEmployeeBrief = function() {
    this.PACKAGE = "BILLING";
    this.CLASSIFIER = "SEENECO_EMPLOYEE_BRIEF";
    this.CopySupportingElement = false;
    this.EmployeeUID = "";
    this.Name = "";
  };
  /***
  * 
  */
  w.SeenecoInvoice = function() {
    this.PACKAGE = "BILLING";
    this.CLASSIFIER = "SEENECO_INVOICE";
    this.CopySupportingElement = false;
    this.Outgoing = false;
    this.Description = "";
    this.RequiredPaymentDate = null;
    this.DateIssued = null;
    this.Number = "";
    this.Counterparty = null;
    this.LegalEntityAccount = null;
    this.InvoiceUID = "";
    this.LegalEntity = null;
    this.SignEmployee = null;
    this.Items = [];
    this.Status = "";
    this.CashTransactions = [];
    this.ExternalOrderUID = "";
    this.ResponsibleEmployee = null;
  };
  /***
  * 
  */
  w.SeenecoInvoiceItem = function() {
    this.PACKAGE = "BILLING";
    this.CLASSIFIER = "SEENECO_INVOICE_ITEM";
    this.CopySupportingElement = false;
    this.ItemAmount = -1;
    this.Description = "";
    this.ItemAmountTotal = -1;
    this.ItemPrice = -1;
    this.ItemTaxRate = -1;
    this.Qty = -1;
    this.InvoiceItemUID = "";
  };
  /***
  * 
  */
  w.Tariff = function() {
    this.PACKAGE = "BILLING";
    this.CLASSIFIER = "TARIFF";
    this.CopySupportingElement = false;
    this.ID = -1;
    this.Name = "";
    this.Rates = [];
    this.Code = "";
    this.Restrictions = [];
    this.BusinessApplication = null;
    this.BusinessApplicationInstance = null;
    this.DirectDebitSupport = false;
    this.BusinessService = null;
    this.Level = null;
  };
  /***
  * 
  */
  w.TariffRate = function() {
    this.PACKAGE = "BILLING";
    this.CLASSIFIER = "TARIFF_RATE";
    this.CopySupportingElement = false;
    this.ID = -1;
    this.Name = "";
    this.Duration = -1;
    this.Rate = -1;
    this.Active = false;
  };
  /***
  * 
  */
  w.TariffRestriction = function() {
    this.PACKAGE = "BILLING";
    this.CLASSIFIER = "TARIFF_RESTRICTION";
    this.CopySupportingElement = false;
    this.ID = -1;
    this.Name = "";
    this.SystemName = "";
  };
  /***
  * 
  */
  w.TariffRestrictionValue = function() {
    this.PACKAGE = "BILLING";
    this.CLASSIFIER = "TARIFF_RESTRICTION_VALUE";
    this.CopySupportingElement = false;
    this.Restriction = null;
    this.Enabled = false;
  };
  /***
  * 
  */
  w.TenantAccount = function() {
    this.PACKAGE = "BILLING";
    this.CLASSIFIER = "TENANT_ACCOUNT";
    this.CopySupportingElement = false;
    this.ExpireDate = null;
    this.Balance = -1;
    this.CreditDays = -1;
    this.ActiveTariff = null;
    this.RequestedTariff = null;
    this.RequestedTariffRate = null;
    this.TrialDays = -1;
    this.TrialPeriodStartDateTime = null;
    this.ID = -1;
    this.BillingGroup = null;
    this.DirectDebitActive = false;
    this.BusinessService = null;
    this.RequestedTariffLevelID = -1;
  };
  /***
  * 
  */
  w.TenantBill = function() {
    this.PACKAGE = "BILLING";
    this.CLASSIFIER = "TENANT_BILL";
    this.CopySupportingElement = false;
    this.ID = -1;
    this.Amount = -1;
    this.IssueDateTime = null;
    this.Paid = false;
    this.Reference = "";
    this.TenantID = -1;
    this.TenantName = "";
    this.Revoked = false;
    this.PaymentRequisites = null;
    this.StatusCode = "";
    this.BillingPayment = null;
    this.PeriodDateStart = null;
    this.PeriodDateEnd = null;
    this.DirectDebitSupport = false;
    this.BillingOperationID = -1;
    this.Tariff = null;
    this.TariffRate = null;
    this.BusinessService = null;
    this.BasedDocumentDate = null;
  };
  /***
  * 
  */
  w.TenantBillAutoCreationRequest = function() {
    this.PACKAGE = "BILLING";
    this.CLASSIFIER = "TENANT_BILL_AUTO_CREATION_REQUEST";
    this.CopySupportingElement = false;
    this.ExpireDate = null;
    this.OperationUID = -1;
  };
  /***
  * 
  */
  w.TenantBillFilterCriteria = function() {
    this.PACKAGE = "BILLING";
    this.CLASSIFIER = "TENANT_BILL_FILTER_CRITERIA";
    this.CopySupportingElement = false;
    this.IssueDateTimeStart = null;
    this.IssueDateTimeFinish = null;
    this.TenantIDList = "";
    this.OnlyUnpaid = false;
    this.ServiceExpireDateStart = null;
    this.ServiceExpireDateFinish = null;
    this.OnlyDirectDebitSupport = false;
    this.TariffList = [];
    this.BusinessServiceID = -1;
  };
   
   
  
  /***
   * Client API to BillingServiceWS web service
   */
  w.BillingServiceClient = function() {
    /***
     * System name of target service
     */
    this.serviceName = "BillingServiceWSImpl";
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param initialBalance [DEFAULT!!DEFAULT]
   * @param tenantID [DEFAULT!!DEFAULT]
   * @param businessServiceID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.BillingServiceClient.prototype.createTenantAccount = function(initialBalance, tenantID, businessServiceID, callbackHandler) {
    var parameterNames = ["initialBalance", "tenantID", "businessServiceID"];
    var parameterValues = [initialBalance, tenantID, businessServiceID];
    var soapMessage = buildSOAPMessage("createTenantAccount", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param tenantID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.BillingServiceClient.prototype.getTenantAccount = function(tenantID, callbackHandler) {
    var parameterNames = ["tenantID"];
    var parameterValues = [tenantID];
    var soapMessage = buildSOAPMessage("getTenantAccount", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!Checks whether active tenant tariff services can be used (are paid or credit period is not expired).
  !DEFAULT]
   * @param tenantID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.BillingServiceClient.prototype.checkServiceStatus = function(tenantID, callbackHandler) {
    var parameterNames = ["tenantID"];
    var parameterValues = [tenantID];
    var soapMessage = buildSOAPMessage("checkServiceStatus", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param tenantID [DEFAULT!!DEFAULT]
   * @param tariffRateID [DEFAULT!!DEFAULT]
   * @param requiredStartDate [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.BillingServiceClient.prototype.registerServiceRequest = function(tenantID, tariffRateID, requiredStartDate, callbackHandler) {
    var parameterNames = ["tenantID", "tariffRateID", "requiredStartDate"];
    var parameterValues = [tenantID, tariffRateID, requiredStartDate];
    var soapMessage = buildSOAPMessage("registerServiceRequest", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param criteria [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.BillingServiceClient.prototype.getTenantBills = function(criteria, callbackHandler) {
    var parameterNames = ["criteria"];
    var criteriaJSON = JSON.stringify(criteria);
    var parameterValues = [criteriaJSON];
    var soapMessage = buildSOAPMessage("getTenantBills", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!Adds bill payment that was already received by service provider, but not registered in system automatically!DEFAULT]
   * @param billID [DEFAULT!!DEFAULT]
   * @param payment [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.BillingServiceClient.prototype.addReceivedBillPayment = function(billID, payment, callbackHandler) {
    var parameterNames = ["billID", "payment"];
    var paymentJSON = JSON.stringify(payment);
    var parameterValues = [billID, paymentJSON];
    var soapMessage = buildSOAPMessage("addReceivedBillPayment", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param tenantID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.BillingServiceClient.prototype.getTariffs = function(tenantID, callbackHandler) {
    var parameterNames = ["tenantID"];
    var parameterValues = [tenantID];
    var soapMessage = buildSOAPMessage("getTariffs", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param billID [DEFAULT!!DEFAULT]
   * @param paymentServiceCode [DEFAULT!String code of supported external payment service!DEFAULT]
   * @param resultPage [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.BillingServiceClient.prototype.createPaymentServiceRequest = function(billID, paymentServiceCode, resultPage, callbackHandler) {
    var parameterNames = ["billID", "paymentServiceCode", "resultPage"];
    var parameterValues = [billID, paymentServiceCode, resultPage];
    var soapMessage = buildSOAPMessage("createPaymentServiceRequest", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!Processes payment service response and returns operation result info. 
  In case of successfult operation check payment is added to the bill!DEFAULT]
   * @param response [DEFAULT!!DEFAULT]
   * @param paymentServiceCode [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.BillingServiceClient.prototype.processPaymentServiceResponse = function(response, paymentServiceCode, callbackHandler) {
    var parameterNames = ["response", "paymentServiceCode"];
    var responseJSON = JSON.stringify(response);
    var parameterValues = [responseJSON, paymentServiceCode];
    var soapMessage = buildSOAPMessage("processPaymentServiceResponse", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!Adds tenant to given billing group!DEFAULT]
   * @param tenantID [DEFAULT!!DEFAULT]
   * @param billingGroupID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.BillingServiceClient.prototype.addTenantToBillingGroup = function(tenantID, billingGroupID, callbackHandler) {
    var parameterNames = ["tenantID", "billingGroupID"];
    var parameterValues = [tenantID, billingGroupID];
    var soapMessage = buildSOAPMessage("addTenantToBillingGroup", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param tenantID [DEFAULT!!DEFAULT]
   * @param billingGroupID [DEFAULT!!DEFAULT]
   * @param businessServiceID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.BillingServiceClient.prototype.changeTenantBillingGroup = function(tenantID, billingGroupID, businessServiceID, callbackHandler) {
    var parameterNames = ["tenantID", "billingGroupID", "businessServiceID"];
    var parameterValues = [tenantID, billingGroupID, businessServiceID];
    var soapMessage = buildSOAPMessage("changeTenantBillingGroup", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param trialDuration [DEFAULT!!DEFAULT]
   * @param trialStartDateTime [DEFAULT!!DEFAULT]
   * @param tenantAccountID [DEFAULT!!DEFAULT]
   * @param expireDate [DEFAULT!!DEFAULT]
   * @param comment [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.BillingServiceClient.prototype.setTrialConditions = function(trialDuration, trialStartDateTime, tenantAccountID, expireDate, comment, callbackHandler) {
    var parameterNames = ["trialDuration", "trialStartDateTime", "tenantAccountID", "expireDate", "comment"];
    var parameterValues = [trialDuration, trialStartDateTime, tenantAccountID, expireDate, comment];
    var soapMessage = buildSOAPMessage("setTrialConditions", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param billID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.BillingServiceClient.prototype.revokeTenantBill = function(billID, callbackHandler) {
    var parameterNames = ["billID"];
    var parameterValues = [billID];
    var soapMessage = buildSOAPMessage("revokeTenantBill", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!Generates new serivce request for current active tariff if expiration date is near and
   similar request is not already generated!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.BillingServiceClient.prototype.requestActiveTariffAutoExtension = function(callbackHandler) {
    var parameterNames = [];
    var parameterValues = [];
    var soapMessage = buildSOAPMessage("requestActiveTariffAutoExtension", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param tariffCode [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.BillingServiceClient.prototype.getTariffByCode = function(tariffCode, callbackHandler) {
    var parameterNames = ["tariffCode"];
    var parameterValues = [tariffCode];
    var soapMessage = buildSOAPMessage("getTariffByCode", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param tariffID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.BillingServiceClient.prototype.getBillingGroupByTariff = function(tariffID, callbackHandler) {
    var parameterNames = ["tariffID"];
    var parameterValues = [tariffID];
    var soapMessage = buildSOAPMessage("getBillingGroupByTariff", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.BillingServiceClient.prototype.getAllBillingGroups = function(callbackHandler) {
    var parameterNames = [];
    var parameterValues = [];
    var soapMessage = buildSOAPMessage("getAllBillingGroups", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param billingGroupID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.BillingServiceClient.prototype.getTariffsByBillingGroup = function(billingGroupID, callbackHandler) {
    var parameterNames = ["billingGroupID"];
    var parameterValues = [billingGroupID];
    var soapMessage = buildSOAPMessage("getTariffsByBillingGroup", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param billID [DEFAULT!!DEFAULT]
   * @param billStatusCode [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.BillingServiceClient.prototype.updateTenantBillStatus = function(billID, billStatusCode, callbackHandler) {
    var parameterNames = ["billID", "billStatusCode"];
    var parameterValues = [billID, billStatusCode];
    var soapMessage = buildSOAPMessage("updateTenantBillStatus", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param synchJob [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.BillingServiceClient.prototype.synchronizePaymentDemandState = function(synchJob, callbackHandler) {
    var parameterNames = ["synchJob"];
    var synchJobJSON = JSON.stringify(synchJob);
    var parameterValues = [synchJobJSON];
    var soapMessage = buildSOAPMessage("synchronizePaymentDemandState", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param tenantID [DEFAULT!!DEFAULT]
   * @param request [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.BillingServiceClient.prototype.registerServiceRequestForTenant = function(tenantID, request, callbackHandler) {
    var parameterNames = ["tenantID", "request"];
    var requestJSON = JSON.stringify(request);
    var parameterValues = [tenantID, requestJSON];
    var soapMessage = buildSOAPMessage("registerServiceRequestForTenant", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param operation [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.BillingServiceClient.prototype.createBillingOperation = function(operation, callbackHandler) {
    var parameterNames = ["operation"];
    var operationJSON = JSON.stringify(operation);
    var parameterValues = [operationJSON];
    var soapMessage = buildSOAPMessage("createBillingOperation", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param operation [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.BillingServiceClient.prototype.updateBillingOperation = function(operation, callbackHandler) {
    var parameterNames = ["operation"];
    var operationJSON = JSON.stringify(operation);
    var parameterValues = [operationJSON];
    var soapMessage = buildSOAPMessage("updateBillingOperation", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param operationID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.BillingServiceClient.prototype.getBillingOperationByID = function(operationID, callbackHandler) {
    var parameterNames = ["operationID"];
    var parameterValues = [operationID];
    var soapMessage = buildSOAPMessage("getBillingOperationByID", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param businessDate [DEFAULT!!DEFAULT]
   * @param operationTypeID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.BillingServiceClient.prototype.getBillingOperationsForDate = function(businessDate, operationTypeID, callbackHandler) {
    var parameterNames = ["businessDate", "operationTypeID"];
    var parameterValues = [businessDate, operationTypeID];
    var soapMessage = buildSOAPMessage("getBillingOperationsForDate", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param businessServiceID [DEFAULT!!DEFAULT]
   * @param subsystemInstanceID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.BillingServiceClient.prototype.getBusinessServiceDDAccount = function(businessServiceID, subsystemInstanceID, callbackHandler) {
    var parameterNames = ["businessServiceID", "subsystemInstanceID"];
    var parameterValues = [businessServiceID, subsystemInstanceID];
    var soapMessage = buildSOAPMessage("getBusinessServiceDDAccount", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param operationStepLogs [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.BillingServiceClient.prototype.createBillingOperationStepLogs = function(operationStepLogs, callbackHandler) {
    var parameterNames = ["operationStepLogs"];
    var operationStepLogsJSON = JSON.stringify(operationStepLogs);
    var parameterValues = [operationStepLogsJSON];
    var soapMessage = buildSOAPMessage("createBillingOperationStepLogs", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param businessServiceID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.BillingServiceClient.prototype.getActiveTenants = function(businessServiceID, callbackHandler) {
    var parameterNames = ["businessServiceID"];
    var parameterValues = [businessServiceID];
    var soapMessage = buildSOAPMessage("getActiveTenants", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!Запрос смены уровня тарифа!DEFAULT]
   * @param request [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.BillingServiceClient.prototype.changeTariffLevel = function(request, callbackHandler) {
    var parameterNames = ["request"];
    var requestJSON = JSON.stringify(request);
    var parameterValues = [requestJSON];
    var soapMessage = buildSOAPMessage("changeTariffLevel", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param request [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.BillingServiceClient.prototype.getBillingPaymentsByFilter = function(request, callbackHandler) {
    var parameterNames = ["request"];
    var requestJSON = JSON.stringify(request);
    var parameterValues = [requestJSON];
    var soapMessage = buildSOAPMessage("getBillingPaymentsByFilter", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param businessServiceID [DEFAULT!!DEFAULT]
   * @param tenantProviderID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.BillingServiceClient.prototype.getBillingDefaultMapping = function(businessServiceID, tenantProviderID, callbackHandler) {
    var parameterNames = ["businessServiceID", "tenantProviderID"];
    var parameterValues = [businessServiceID, tenantProviderID];
    var soapMessage = buildSOAPMessage("getBillingDefaultMapping", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!обещанный платеж!DEFAULT]
   * @param tenantID [DEFAULT!!DEFAULT]
   * @param creditDaysCount [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.BillingServiceClient.prototype.promisePayment = function(tenantID, creditDaysCount, callbackHandler) {
    var parameterNames = ["tenantID", "creditDaysCount"];
    var parameterValues = [tenantID, creditDaysCount];
    var soapMessage = buildSOAPMessage("promisePayment", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param tenantAccount [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.BillingServiceClient.prototype.changeTenantAccount = function(tenantAccount, callbackHandler) {
    var parameterNames = ["tenantAccount"];
    var tenantAccountJSON = JSON.stringify(tenantAccount);
    var parameterValues = [tenantAccountJSON];
    var soapMessage = buildSOAPMessage("changeTenantAccount", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param businessServiceID [DEFAULT!!DEFAULT]
   * @param tenantID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.BillingServiceClient.prototype.getAccessPeriods = function(businessServiceID, tenantID, callbackHandler) {
    var parameterNames = ["businessServiceID", "tenantID"];
    var parameterValues = [businessServiceID, tenantID];
    var soapMessage = buildSOAPMessage("getAccessPeriods", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  
  /***
   * Package security rights constants
   */
  
  w.BillingSecurityRights = {
  
  };
  })(typeof exports !== 'undefined' ? exports : window);
  