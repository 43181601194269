import React, { useMemo } from 'react';

import { Autopicker, AutopickerProps, AutopickerRenderItemContentOptions } from '@ui/autopicker';
import { formatRegisterNumber } from '@utils';
import { Box } from '@ui/box';
import { Typography } from '@ui/typography';
import { HighLightedText } from '@ui/highlighted-text';
import { ProviderIcon } from '@ui/provider-icon';
import { FundsRegisterAutopickerCRUD, FundsRegisterAutopickerCrudProps } from '../funds-register-autopicker-crud';

export type FundsRegisterAutopickerProps = {
	name?: string;
	hasChangeRight: boolean;
	disableAdd?: boolean;
	disableEdit?: boolean;
	crudTransformer?: (value: FundsRegister) => any;
	CrudProps?: Partial<Pick<FundsRegisterAutopickerCrudProps, 'isBankAccount' | 'withoutTypeChange' | 'legalEntityID'>>;
} & AutopickerProps<FundsRegisterBrief>;

const FundsRegisterAutopicker: React.FC<FundsRegisterAutopickerProps> = props => {
	const {
		name: formName,
		crudTransformer,
		hasChangeRight,
		disableAdd,
		disableEdit,
		CrudProps: { isBankAccount, withoutTypeChange, legalEntityID },
	} = props;
	const pluralItems = useMemo(() => ['счёт', 'счёта', 'счетов'] as [string, string, string], []);

	const renderItemContent = ({
		item,
		containerWidth,
		searchText,
	}: AutopickerRenderItemContentOptions<FundsRegisterBrief>) => {
		const hasBank = Boolean(item.Bank);
		const providerName = item?.Bank?.Name;
		const isSmallContainer = containerWidth < 340;

		return (
			<Box
				title={hasBank ? `Банковский счёт в ${item.Bank.Name}` : 'Виртуальный счёт'}
				display='flex'
				alignItems='flex-start'
				fullWidth>
				<ProviderIcon providerName={providerName} size={16} fallback={hasBank ? 'bank-account' : 'virtual-account'} />
				<Box marginLeft={4}>
					<Box marginBottom={2} fontSize={isSmallContainer ? 12 : 13}>
						<HighLightedText value={item.Name} query={searchText} />
					</Box>
					<Typography.Label component='div' fontSize={11}>
						Номер: <HighLightedText value={formatRegisterNumber(item.Number)} query={searchText} />
					</Typography.Label>
				</Box>
			</Box>
		);
	};

	return (
		<Autopicker
			labelText='Счёт'
			hintText='Выберите счёт...'
			pluralItems={pluralItems}
			getNameForSearchTextFilter={getNameForSearchTextFilter}
			renderItemContent={renderItemContent}
			renderAddTrigger={
				!disableAdd && hasChangeRight
					? ({ searchText, isSmallContainer, onRelatedPopupChange }) => {
							return (
								<FundsRegisterAutopickerCRUD
									variant='add'
									formName={formName}
									searchText={searchText}
									transformer={crudTransformer}
									isSmallContainer={isSmallContainer}
									isBankAccount={isBankAccount}
									withoutTypeChange={withoutTypeChange}
									legalEntityID={legalEntityID}
									onRelatedPopupChange={onRelatedPopupChange}
								/>
							);
					  }
					: undefined
			}
			renderEditTrigger={
				!disableEdit && hasChangeRight
					? ({ ID, onRelatedPopupChange }) => {
							return (
								<FundsRegisterAutopickerCRUD
									variant='edit'
									formName={formName}
									fundsRegisterID={ID}
									transformer={crudTransformer}
									onRelatedPopupChange={onRelatedPopupChange}
								/>
							);
					  }
					: undefined
			}
			{...props}
			fullWidth
		/>
	);
};

FundsRegisterAutopicker.defaultProps = {
	CrudProps: {},
};

const getNameForSearchTextFilter = (item: FundsRegisterBrief) => `${item.Name} ${item.Number}`;

export { FundsRegisterAutopicker };
