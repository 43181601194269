import { runInitializationEffect } from './initializations.effect';


const initializationEffects = [
	runInitializationEffect
];

export {
	initializationEffects,
};
