import { createTypes } from '@flux';

type ActionTypes = {
	INVALIDATE_OPERATIONS: string;
	SET_DATE_RANGE: string;
	SET_DATE_SORTING: string;
	SET_FILTER_BY_TEXT: string;
	GROUP_BY_DATE_TYPE: string;
	SET_IS_INCLUDES_VAT: string;
	SET_IS_SEPARATE_VAT: string;
	SET_FILTER_BY_DIRECTION: string;
	SET_FILTER_BY_STATUS: string;
	SET_FILTER_BY_PERSONAL: string;
	SET_CASHFLOW_TYPES_FILTER: string;
	SET_BUSINESS_UNITS_FILTER: string;
	SET_PROJECTS_FILTER: string;
	SET_CASHFLOW_ITEMS_FILTER: string;
	SET_COUNTERPARTIES_FILTER: string;
	SET_FUNDS_REGISTERS_FILTER: string;
	SET_EMPLOYEES_FILTER: string;
	MERGE_FILTERS: string;
	RESET_FILTERS: string;
	FETCH_OPERATIONS: string;
	ADD_OPERATION: string;
	UPDATE_OPERATION: string;
	REMOVE_OPERATIONS: string;
	TRANSFORM_PLAN_OPERATIONS_TO_FACT: string;
	MASS_UPDATE_OPERATIONS: string;
	LINK_OPERATIONS_TO_CASHFLOW_ITEM: string;
	ADD_OPERATIONS_BY_TEMPLATE: string;
	UPDATE_OPERATIONS_BY_TEMPLATE: string;
	SPLIT_OPERATION: string;
	UPDATE_SPLIT_OPERATIONS: string;
	FETCH_FUNDS_REGISTER_STATISTICS: string;
	SEND_PLAN_OPERATIONS_TO_BANK: string;
	FETCH_MODULE_PREFERENCES: string;
};

export const mainTypes = createTypes<ActionTypes>(
	[
		'INVALIDATE_OPERATIONS',
		'SET_DATE_RANGE',
		'SET_DATE_SORTING',
		'SET_FILTER_BY_TEXT',
		'GROUP_BY_DATE_TYPE',
		'SET_IS_INCLUDES_VAT',
		'SET_IS_SEPARATE_VAT',
		'SET_FILTER_BY_DIRECTION',
		'SET_FILTER_BY_STATUS',
		'SET_FILTER_BY_PERSONAL',
		'SET_CASHFLOW_TYPES_FILTER',
		'SET_BUSINESS_UNITS_FILTER',
		'SET_PROJECTS_FILTER',
		'SET_CASHFLOW_ITEMS_FILTER',
		'SET_COUNTERPARTIES_FILTER',
		'SET_FUNDS_REGISTERS_FILTER',
		'SET_EMPLOYEES_FILTER',
		'MERGE_FILTERS',
		'RESET_FILTERS',
		'FETCH_OPERATIONS',
		'ADD_OPERATION',
		'UPDATE_OPERATION',
		'REMOVE_OPERATIONS',
		'TRANSFORM_PLAN_OPERATIONS_TO_FACT',
		'MASS_UPDATE_OPERATIONS',
		'LINK_OPERATIONS_TO_CASHFLOW_ITEM',
		'ADD_OPERATIONS_BY_TEMPLATE',
		'UPDATE_OPERATIONS_BY_TEMPLATE',
		'SPLIT_OPERATION',
		'UPDATE_SPLIT_OPERATIONS',
		'FETCH_FUNDS_REGISTER_STATISTICS',
		'SEND_PLAN_OPERATIONS_TO_BANK',
		'FETCH_MODULE_PREFERENCES',
	],
	'😀PL_OPERATIONS',
);
