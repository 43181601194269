import React from 'react';

function withDocumentTitle(title: string) {
	return WrappedComponent => {
		return class extends React.PureComponent {
			static displayName = 'withDocumentTitle[HOC]';

			componentDidMount() {
				document.title = title;
			}

			render() {
				return <WrappedComponent {...this.props} />;
			}
		};
	};
}

export default withDocumentTitle;
