import { Store } from 'redux';

import { IAppState } from '@store';
import { isReceiveAction } from '@flux';
import { types as measureUnitsTypes } from '@measure-units/actions/types';
import { invalidateMeasureUnits } from '@measure-units/actions/invalidators';

function askMergeMeasureUnitsInvalidator(store: Store<IAppState>, action: StoreAction) {
	const { dispatch } = store;
	const invalidateMap = {
		[measureUnitsTypes.RUN_INVALIDATION_EFFECT]: true,
	};

	if (invalidateMap[action.type]) {
		dispatch(invalidateMeasureUnits());
	}
}

function askMeasureUnitsInvalidator(store: Store<IAppState>, action: StoreAction) {
	const { dispatch } = store;
	const invalidateMap = {};

	if (invalidateMap[action.type] && isReceiveAction(action)) {
		dispatch(invalidateMeasureUnits());
	}
}

function createInvalidatorEffect() {
	return (store: Store<IAppState>) => (next: (action: StoreAction) => void) => (action: StoreAction) => {
		next(action);
		askMergeMeasureUnitsInvalidator(store, action);
		askMeasureUnitsInvalidator(store, action);
	};
}

const runInvalidatorEffect = createInvalidatorEffect();

export { runInvalidatorEffect };
