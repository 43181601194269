import styled, { css } from 'styled-components';

import { sizesMM, PageOrientation } from '../models';

type RootProps = {
	orientation: PageOrientation;
};

const Root = styled.div<RootProps>`
	overflow: hidden;
	margin: auto;
	transform: translate(0, 0);

	& .hidden {
		position: absolute;
		opacity: 0;
		z-index: -1;
	}

	& .content {
		${p => css`
			overflow: hidden;
			width: ${getContentWidthMM(p.orientation)}mm;
			height: ${getContenHeightMM(p.orientation)}mm;
			column-width: ${sizesMM[p.orientation].width}mm;
			column-fill: auto;
			column-gap: 0;
		`}
	}

	& .paper {
		position: relative;
		border: 1px solid #e0e0e0;
		background-color: #fff;
		color: #000;
		margin-top: 20px;
		margin-bottom: 20px;

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}

		${p => css`
			width: ${sizesMM[p.orientation].width}mm;
			height: ${sizesMM[p.orientation].height}mm;
			padding: ${sizesMM[p.orientation].margin}mm;
		`}
	}
`;

function getContentWidthMM(orientation: PageOrientation) {
	return sizesMM[orientation].width - sizesMM[orientation].margin * 2;
}

function getContenHeightMM(orientation: PageOrientation) {
	return sizesMM[orientation].height - sizesMM[orientation].margin * 2;
}

export { Root, getContentWidthMM };
