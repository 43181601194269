import React, { memo, useMemo, useState } from 'react';

import { useMapDispatch, useMapState } from '@core/flux';
import { useLocalStorage } from '@core/hooks/use-local-storage';
import { ROUTE_MAP } from '@core/routes/urls';
import { today } from '@core/utils/date';
import {
	NotifySupportsByEmailsComment,
	NotifySupportsByEmailsEventCode,
	NotifySupportsByEmailsOptions,
	platformActionsPack,
} from '@platform/actions/main.actions';
import { BusinessServiceType } from '@platform/model';
import { selectBusinessServiceType } from '@platform/selectors/billing.selectors';
import { Help as XHelp } from './help.view';

type HelpProps = {};

const Help: React.FC<HelpProps> = memo(() => {
	const [businessServiceType] = useMapState([selectBusinessServiceType]);
	const [notifySupportsByEmails] = useMapDispatch([platformActionsPack.notifySupportsByEmails]);

	const [orderDemoRequestDay, setOrderDemoRequestDay] = useLocalStorage(NotifySupportsByEmailsEventCode.ORDER_DEMO, '');
	const [orderSettingRequestDay, setOrderSettingRequestDay] = useLocalStorage(
		NotifySupportsByEmailsEventCode.ORDER_SETTING,
		'',
	);
	const [isOpen, setIsOpen] = useState<boolean>(false);

	const todayDate = useMemo(() => today(), []);
	const canOrderDemo = useMemo(() => orderDemoRequestDay !== todayDate, [orderDemoRequestDay]);
	const canOrderSetting = useMemo(() => orderSettingRequestDay !== todayDate, [orderSettingRequestDay]);
	const isBfmApp = businessServiceType === BusinessServiceType.BFM;
	const baseHelpLink = `${location.origin}/help/${isBfmApp ? 'bfm' : 'invoice'}`;

	const handleOpenSectionHelp = () => window.open(getHelpLink(location.pathname) || baseHelpLink, '_blank');

	const handleOpenStartHelp = () => window.open(baseHelpLink, '_blank');

	const handleOrderDemo = () => {
		const options: NotifySupportsByEmailsOptions = {
			comment: NotifySupportsByEmailsComment.ORDER_DEMO,
			onComplete: () => {
				setOrderDemoRequestDay(todayDate);
			},
			eventCode: NotifySupportsByEmailsEventCode.ORDER_DEMO,
		};

		notifySupportsByEmails(options);
	};

	const handleOrderSetting = () => {
		const options: NotifySupportsByEmailsOptions = {
			comment: NotifySupportsByEmailsComment.ORDER_SETTING,
			onComplete: () => {
				setOrderSettingRequestDay(todayDate);
			},
			eventCode: NotifySupportsByEmailsEventCode.ORDER_SETTING,
		};

		notifySupportsByEmails(options);
	};

	const handleToggleMenu = () => setIsOpen(!isOpen);

	const handleWriteToSupportChat = () => window.carrotquest && window.carrotquest.open();

	return (
		<XHelp
			canOrderDemo={canOrderDemo}
			canOrderSetting={canOrderSetting}
			isOpen={isOpen}
			onOpenSectionHelp={handleOpenSectionHelp}
			onOpenStartHelp={handleOpenStartHelp}
			onOrderDemo={handleOrderDemo}
			onOrderSetting={handleOrderSetting}
			onToggleMenu={handleToggleMenu}
			onWriteToSupportChat={handleWriteToSupportChat}
		/>
	);
});

const baseCarrotArticle = 'https://app17307.carrotquest.help/article/';

const helpLinkMap = {
	[ROUTE_MAP.BALANCE]: `${baseCarrotArticle}16787`,
	[ROUTE_MAP.CASHFLOW_LIMITS]: `${baseCarrotArticle}16870`,
	[ROUTE_MAP.COUNTERPARTIES]: `${baseCarrotArticle}16950`,
	[ROUTE_MAP.DASHBOARD]: `${baseCarrotArticle}17099`,
	[ROUTE_MAP.INVOICES]: `${baseCarrotArticle}16896`,
	[ROUTE_MAP.PL_OPERATIONS]: `${baseCarrotArticle}16786`,
	[ROUTE_MAP.PL_PLANNING]: `${baseCarrotArticle}16815`,
	[ROUTE_MAP.PROJECTS]: `${baseCarrotArticle}16949`,
	[ROUTE_MAP.STANDARD_REPORTS]: `${baseCarrotArticle}16857`,
	[ROUTE_MAP.TENANT_LEGAL_ENTITIES]: `${baseCarrotArticle}16951`,
};

const getHelpLink = (pathname: string) =>
	pathname ? helpLinkMap[pathname] || getHelpLink(pathname.substring(0, pathname.lastIndexOf('/'))) : null;

export { Help, HelpProps };
