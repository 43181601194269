import { createTypes } from '@flux';

type ActionTypes = {
	INVALIDATE: string;
	FETCH: string;
	FETCH_BY_ID: string;
	CLEAR_SINGLE: string;
	ADD: string;
	UPDATE: string;
	REMOVE: string;
	SET_TEXT_FILTER: string;
	APPLY: string;
	SET_TRANSFER_RULE_ACTIVITY: string;
	SET_IS_AUTO_APPLY_RULES: string;
	SET_APPLY_DATE_RANGE: string;
};

const types = createTypes<ActionTypes>(
	[
		'INVALIDATE',
		'FETCH',
		'FETCH_BY_ID',
		'CLEAR_SINGLE',
		'ADD',
		'UPDATE',
		'REMOVE',
		'SET_TEXT_FILTER',
		'APPLY',
		'SET_TRANSFER_RULE_ACTIVITY',
		'SET_IS_AUTO_APPLY_RULES',
		'SET_APPLY_DATE_RANGE',
	],
	'TRANSFER_RULES',
);

export { types };
