import React, { useMemo } from 'react';

import { Autopicker, AutopickerProps } from '@ui/autopicker';

export type FundsRegisterPurposeAutopickerProps = {
	name?: string;
} & AutopickerProps<FundsRegisterPurpose>;

const FundsRegisterPurposeAutopicker: React.FC<FundsRegisterPurposeAutopickerProps> = props => {
	const pluralItems = useMemo(() => ['назначение', 'назначения', 'назначений'] as [string, string, string], []);

	return (
		<Autopicker
			labelText='Назначение счёта'
			hintText='Например, «Депозитный»'
			pluralItems={pluralItems}
			{...props}
			fullWidth
		/>
	);
};

export { FundsRegisterPurposeAutopicker };
