import {
	StoreAsyncItem,
	createReducer,
	createAsyncInitialState,
	checkAsyncAction,
	invalidateStateFromAction,
} from '@flux';
import { types } from '../actions/types';
import { FilterType } from '../models';

export type MainPaymentRulesState = {
	paymentRules: StoreAsyncItem<Array<PaymentRule>>;
	executionResult: PaymentRuleExecutionResult;
	executionProgress: number;
	textFilter: string;
	typeFilter: Record<FilterType, boolean>;
	isAutoApplyRules: boolean;
};

const initialState: MainPaymentRulesState = {
	paymentRules: createAsyncInitialState([]),
	executionResult: null,
	executionProgress: 0,
	textFilter: '',
	typeFilter: {
		[FilterType.INCOME]: true,
		[FilterType.EXPENSE]: true,
		[FilterType.PROJECT]: true,
		[FilterType.BUSINESS_UNIT]: true,
	},
	isAutoApplyRules: true,
};

const mainPaymentRulesReducer = createReducer<MainPaymentRulesState>(initialState, {
	[types.INVALIDATE_PAYMENT_RULES]: (action: StaticAction, state) => {
		return {
			paymentRules: invalidateStateFromAction(action, state.paymentRules),
		};
	},
	[types.FETCH_PAYMENT_RULES]: (action: AsyncAction<Array<PaymentRule>>, state) => {
		return {
			paymentRules: checkAsyncAction(action, state.paymentRules),
		};
	},
	[types.ADD_PAYMENT_RULE]: (action: AsyncAction, state) => {
		return {
			paymentRules: invalidateStateFromAction(action, state.paymentRules),
		};
	},
	[types.UPDATE_PAYMENT_RULE]: (action: AsyncAction, state) => {
		return {
			paymentRules: invalidateStateFromAction(action, state.paymentRules),
		};
	},
	[types.REMOVE_PAYMENT_RULE]: (action: AsyncAction, state) => {
		return {
			paymentRules: invalidateStateFromAction(action, state.paymentRules),
		};
	},
	[types.SET_FILTER_BY_TYPE]: (action: StaticAction<Record<FilterType, boolean>>) => {
		return {
			typeFilter: { ...action.value },
		};
	},
	[types.SET_FILTER_BY_TEXT]: (action: StaticAction<string>) => {
		return {
			textFilter: action.value,
		};
	},
	[types.SET_IS_AUTO_APPLY_RULES]: (action: StaticAction<boolean>) => {
		return {
			isAutoApplyRules: action.value,
		};
	},
});

export default mainPaymentRulesReducer;
