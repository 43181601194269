import React from 'react';

import { Portal } from '@ui/portal';
import { Root, Message } from './styled';

interface IProps {
	messages: Array<React.ReactNode>;
}

interface IState {
	primaryIdx: number;
	animationPausedMap: Record<string, boolean>;
}

class Notify extends React.PureComponent<IProps, IState> {
	static displayName = 'Notify';
	static defaultProps = {
		messages: [],
	};
	state = {
		primaryIdx: 0,
		animationPausedMap: {},
	};
	notifyStore: Array<{
		time: number;
		message: React.ReactNode;
	}> = [];

	componentDidMount() {
		this.controlRendering();
		this.initGarbageCollector();
	}

	componentDidUpdate(prevProps: IProps) {
		if (prevProps.messages !== this.props.messages) {
			setTimeout(() => {
				requestAnimationFrame(() => {
					this.controlRendering();
				});
			}, 300);
		}
	}

	controlRendering = () => {
		const { messages } = this.props;
		const { primaryIdx } = this.state;

		if (primaryIdx < messages.length) {
			this.notifyStore.push({
				time: new Date().getTime(),
				message: messages[primaryIdx],
			});
			this.setState(
				{
					primaryIdx: primaryIdx + 1,
				},
				() => {
					requestAnimationFrame(() => {
						this.controlRendering();
					});
				},
			);
		} else {
			window['requestIdleCallback'](() => {
				this.forceUpdate();
			});
			this.initGarbageCollector();
		}
	};

	initGarbageCollector = () => {
		const liveTime = 10000;
		const timeout = setTimeout(() => {
			const { animationPausedMap } = this.state;
			const now = new Date().getTime();
			const count = this.notifyStore.length;

			this.notifyStore = this.notifyStore.filter(el => animationPausedMap[el.time] || now - el.time <= liveTime);

			if (count > this.notifyStore.length) {
				this.forceUpdate();
			}

			if (this.notifyStore.length === 0) {
				clearTimeout(timeout);
				this.setState({
					animationPausedMap: {},
				});
			}
		}, liveTime);
	};

	handleMouseOver = el => () => {
		const { animationPausedMap } = this.state;

		!animationPausedMap[el.time] &&
			this.setState({
				animationPausedMap: {
					...animationPausedMap,
					[el.time]: true,
				},
			});
	};

	handleMouseLeave = el => () => {
		const { animationPausedMap } = this.state;

		animationPausedMap[el.time] &&
			this.setState(
				{
					animationPausedMap: {
						...animationPausedMap,
						[el.time]: false,
					},
				},
				() => this.initGarbageCollector(),
			);
	};

	render() {
		const { animationPausedMap } = this.state;

		return (
			<Portal>
				<Root>
					{this.notifyStore.map(el => {
						return (
							<Message
								key={el.time}
								animationPaused={animationPausedMap[el.time]}
								onMouseOver={this.handleMouseOver(el)}
								onMouseLeave={this.handleMouseLeave(el)}>
								{el.message}
							</Message>
						);
					})}
				</Root>
			</Portal>
		);
	}
}

export { Notify };
