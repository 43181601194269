import React from 'react';

import { Box } from '@ui/box';
import { DocumentPage } from '@ui/document-page';
import { DocumentTable, ReportLayout } from '@ui/documents-rendering';
import { InvoiceBankQrCode } from '@ui/invoice-bank-qr-code';
import { QrCode } from '@ui/qr-code';
import { Stamp } from '@ui/stamp';
import { Typography } from '@ui/typography';
import { detectIsNaturalPerson } from '@utils/counterparty';
import { formatCurrency, formatCurrencyAsText } from '@utils/currency';
import { pluralDate, prettyDate } from '@utils/date';
import { getVatTaxRateName } from '@utils/documents';
import {
	createInvoiceQrCodeUrl,
	detectHasAnyVat,
	generateSharedDocumentUrl,
	getFinancialDocumentItemsParams,
	getFormattedQty,
	getInvoiceStatuses,
	getTaxAmountByItem,
} from '@utils/invoice';
import { formatNumber } from '@utils/numbers';
import { capitalizeSentence, comma } from '@utils/strings';
import { correctFileUrl } from '@utils/url';
import {
	CompanyLogo,
	CompanySeal,
	Divider,
	EmployeeName,
	EmployeeSign,
	Footer,
	ItemsTable,
	PhotoRequisitesLayout,
	Placeholder,
	Root,
	StampLayout,
	Table,
	TableBodyCell,
} from './styled';

export type InvoiceDocumentViewerProps = {
	variant: 'paper' | 'report';
	invoice: Invoice;
	withoutPhotoRequisites?: boolean;
};

const InvoiceDocumentViewer: React.FC<InvoiceDocumentViewerProps> = props => {
	const { invoice, variant, withoutPhotoRequisites } = props;

	const renderInvoiceTemplate = () => {
		const taxCode = invoice?.LegalEntity?.TaxCode;
		const additionalTaxCode = !invoice?.LegalEntity?.SoleProprietor ? invoice?.LegalEntity?.AdditionalTaxCode : '';
		const legalEntity = invoice?.LegalEntity;
		const counterparty = invoice?.Counterparty;
		const { totalAmount, totalTaxAmount, totalClearAmount } = getFinancialDocumentItemsParams(invoice.Items);
		const { isPaid } = getInvoiceStatuses(invoice);
		const paymentDate = isPaid ? invoice?.Payments[0]?.PaymentDate : '';
		const isRevoked = invoice.Revoked;
		const sharedDocumentUid = invoice.SharedDocumentUID;
		const url = createInvoiceQrCodeUrl(
			generateSharedDocumentUrl({
				type: 'invoice',
				uid: sharedDocumentUid,
			}),
		);
		const isNaturalPerson = detectIsNaturalPerson(counterparty);
		const legalEntityInfo = comma([
			legalEntity?.Name,
			legalEntity?.TaxCode && `ИНН ${legalEntity.TaxCode}`,
			!legalEntity?.SoleProprietor && legalEntity?.AdditionalTaxCode && `КПП ${legalEntity.AdditionalTaxCode}`,
			legalEntity?.StateRegistrationCode
				? legalEntity.SoleProprietor
					? `ОГРНИП ${legalEntity.StateRegistrationCode}`
					: `ОГРН ${legalEntity.StateRegistrationCode}`
				: '',
		]);
		const counterpartyInfo = comma([
			counterparty?.Name,
			counterparty?.TaxCode && `ИНН ${counterparty.TaxCode}`,
			!counterparty?.SoleProprietor && counterparty?.AdditionalTaxCode && `КПП ${counterparty.AdditionalTaxCode}`,
			counterparty?.StateRegistrationCode
				? counterparty.SoleProprietor
					? `ОГРНИП ${counterparty.StateRegistrationCode}`
					: `ОГРН ${counterparty.StateRegistrationCode}`
				: '',
		]);
		const businessRoleName = invoice.SignEmployee?.BusinessRole?.Name || 'Руководитель';
		const qrCodeSize = 140;
		const hasAnyVat = detectHasAnyVat(invoice.Items);

		return (
			<Root>
				<Box marginBottom={10}>
					<DocumentTable className='no-borders'>
						<colgroup>
							<col width='50%' />
							<col width='50%' />
						</colgroup>
						<tbody>
							<tr>
								<td>
									{isNaturalPerson ? (
										<InvoiceBankQrCode variant='inline' value={invoice} size={qrCodeSize} />
									) : sharedDocumentUid ? (
										<QrCode variant='inline' value={url} size={qrCodeSize} />
									) : (
										<div />
									)}
									{isPaid && (
										<StampLayout>
											<Stamp appearance='paid' sum={totalAmount} date={paymentDate} />
										</StampLayout>
									)}
									{isRevoked && (
										<StampLayout>
											<Stamp appearance='revoked' />
										</StampLayout>
									)}
								</td>
								<td className='text-right'>
									{invoice?.LegalEntity?.LogoURL && (
										<CompanyLogo alt='Логотип' src={correctFileUrl(invoice.LegalEntity.LogoURL)} />
									)}
								</td>
							</tr>
						</tbody>
					</DocumentTable>
				</Box>
				<Table>
					<colgroup>
						<col width='30%' />
						<col width='30%' />
						<col width='100px' />
						<col />
					</colgroup>
					<tbody>
						<tr>
							<TableBodyCell noBorderBottom colSpan={2}>
								{invoice?.FundsRegister?.Bank?.Name || '-'}
							</TableBodyCell>
							<TableBodyCell>БИК</TableBodyCell>
							<TableBodyCell noBorderBottom>{invoice?.FundsRegister?.Bank?.Bic || '-'}</TableBodyCell>
						</tr>
						<tr>
							<TableBodyCell colSpan={2}>
								<Box>
									<Typography.Text fontSize={10}>Банк получателя</Typography.Text>
								</Box>
							</TableBodyCell>
							<TableBodyCell>Сч. №</TableBodyCell>
							<TableBodyCell>{invoice?.FundsRegister?.Bank?.CorrAccount || '-'}</TableBodyCell>
						</tr>
						<tr>
							<TableBodyCell>ИНН {taxCode || '-'}</TableBodyCell>
							<TableBodyCell>КПП {additionalTaxCode || '-'}</TableBodyCell>
							<TableBodyCell noBorderBottom>Сч. №</TableBodyCell>
							<TableBodyCell noBorderBottom>{invoice?.FundsRegister?.Number || '-'}</TableBodyCell>
						</tr>
						<tr>
							<TableBodyCell noBorderBottom colSpan={2}>
								{invoice?.LegalEntity?.Name}
							</TableBodyCell>
							<TableBodyCell noBorderBottom></TableBodyCell>
							<TableBodyCell noBorderBottom></TableBodyCell>
						</tr>
						<tr>
							<TableBodyCell colSpan={2}>
								<Box>
									<Typography.Text fontSize={10}> Получатель</Typography.Text>
								</Box>
							</TableBodyCell>
							<TableBodyCell></TableBodyCell>
							<TableBodyCell></TableBodyCell>
						</tr>
					</tbody>
				</Table>
				<Box margin='20px 0'>
					<Typography.Title textTransform='none' fontWeight={700}>
						Счёт на оплату №{invoice.Number} от {pluralDate(invoice.DateIssued)}
					</Typography.Title>
				</Box>
				<Box marginBottom={10}>
					<Divider />
				</Box>
				<Table>
					<colgroup>
						<col width='110px' />
						<col />
					</colgroup>
					<tbody>
						<tr>
							<TableBodyCell noBorders>
								<Box marginBottom={10}>
									Поставщик
									<br />
									(Исполнитель):
								</Box>
							</TableBodyCell>
							<TableBodyCell noBorders>
								<Box marginBottom={10}>
									<Typography.Text fontWeight={700}>
										{legalEntityInfo || '-'}
										<br />
										{invoice?.LegalEntity?.Address}
									</Typography.Text>
								</Box>
							</TableBodyCell>
						</tr>
						<tr>
							<TableBodyCell noBorders>
								<Box marginBottom={10}>
									Покупатель
									<br />
									(Заказчик):
								</Box>
							</TableBodyCell>
							<TableBodyCell noBorders>
								<Box marginBottom={10}>
									<Typography.Text fontWeight={700}>
										{counterpartyInfo || '-'}
										<br />
										{invoice?.Counterparty?.Address}
									</Typography.Text>
								</Box>
							</TableBodyCell>
						</tr>
						<tr>
							<TableBodyCell noBorders>
								<Box marginBottom={10}>Комментарий:</Box>
							</TableBodyCell>
							<TableBodyCell noBorders>
								<Box marginBottom={10}>
									<Typography.Text style={{ whiteSpace: 'pre-line' }} fontWeight={700}>
										{invoice?.Description || '-'}
									</Typography.Text>
								</Box>
							</TableBodyCell>
						</tr>
					</tbody>
				</Table>
				<ItemsTable>
					<colgroup>
						<col width='20px' />
						<col width='270px' />
						<col width='60px' />
						<col width='40px' />
						<col width='70px' />
						<col width='60px' />
						<col width='70px' />
						<col />
					</colgroup>
					<thead>
						<tr>
							<th className='text-bold fat-border-left fat-border-top' rowSpan={2}>
								№
							</th>
							<th className='text-bold fat-border-top' rowSpan={2}>
								Товары (работы, услуги)
							</th>
							<th className='text-bold fat-border-top' rowSpan={2}>
								Кол-во
							</th>
							<th className='text-bold fat-border-top' rowSpan={2}>
								Ед.
							</th>
							<th className='text-bold fat-border-top' rowSpan={2}>
								Цена, ₽
							</th>
							<th className='text-bold fat-border-top' colSpan={2}>
								НДС
							</th>
							<th className='text-bold fat-border-right fat-border-top' rowSpan={2}>
								Сумма, ₽
							</th>
						</tr>
						<tr>
							<th className='text-bold'>ставка, %</th>
							<th className='text-bold'>сумма, ₽</th>
						</tr>
					</thead>
					<tbody>
						{invoice.Items.map((x, idx, arr) => {
							const isLast = idx === arr.length - 1;
							const sharedClassName = isLast ? 'fat-border-bottom' : '';

							return (
								<tr key={x.ID}>
									<td className={`text-center fat-border-left ${sharedClassName}`}>{idx + 1}</td>
									<td className={sharedClassName}>{x.Description}</td>
									<td className={`text-right ${sharedClassName}`}>{getFormattedQty(x)}</td>
									<td className={`text-center ${sharedClassName}`}>
										{x.MeasureUnit?.ID > 0 ? x.MeasureUnit?.Name : 'шт'}
									</td>
									<td className={`text-right ${sharedClassName}`}>{formatNumber(x.ItemPrice)}</td>
									<td className={`text-center ${sharedClassName}`}>{getVatTaxRateName(x.ItemTaxRate)}</td>
									<td className={`text-right ${sharedClassName}`}>{formatNumber(getTaxAmountByItem(x))}</td>
									<td className={`text-right fat-border-right ${sharedClassName}`}>
										{formatNumber(x.ItemAmountTotal)}
									</td>
								</tr>
							);
						})}
						<tr>
							<td className='text-right text-bold no-borders summary' colSpan={7}>
								<Box marginTop={10}>Итого:</Box>
							</td>
							<td className='text-right no-borders summary'>
								<Box marginTop={10}>{formatCurrency(totalClearAmount)}</Box>
							</td>
						</tr>
						<tr>
							<td className='text-right text-bold no-borders summary' colSpan={7}>
								НДС:
							</td>
							<td className='text-right no-borders summary'>
								{hasAnyVat ? formatCurrency(totalTaxAmount) : 'не облагается'}
							</td>
						</tr>
						<tr>
							<td className='text-right text-bold no-borders summary' colSpan={7}>
								Всего к оплате:
							</td>
							<td className='text-right no-borders summary'>{formatCurrency(totalAmount)}</td>
						</tr>
					</tbody>
				</ItemsTable>
				<Box marginBottom={20}>
					<Box>
						Всего наименований {invoice.Items.length}, на сумму {formatNumber(totalAmount)} руб.
					</Box>
					<Typography.Text fontWeight={700}>{capitalizeSentence(formatCurrencyAsText(totalAmount))}</Typography.Text>
				</Box>
				<Box marginBottom={20}>Оплатить не позднее {prettyDate(invoice.RequiredPaymentDate)}</Box>
				<Box marginBottom={20}>
					<Divider />
				</Box>
				<Footer>
					<Box position='relative' display='flex' alignItems='flex-start' width='55%' paddingRight={10}>
						<Box flex='0 0 135px' marginRight={10}>
							<Typography.Text fontWeight={700}>{businessRoleName}</Typography.Text>
						</Box>
						<Placeholder>
							<PhotoRequisitesLayout isInvisible={withoutPhotoRequisites}>
								{invoice?.SignEmployee?.SignImageURL && (
									<EmployeeSign alt='Подпись' src={correctFileUrl(invoice.SignEmployee.SignImageURL)} />
								)}
								{invoice?.LegalEntity?.CompanySealImageURL && (
									<CompanySeal alt='Печать' src={correctFileUrl(invoice.LegalEntity.CompanySealImageURL)} />
								)}
							</PhotoRequisitesLayout>
							<EmployeeName>{invoice?.SignEmployee?.Name}</EmployeeName>
						</Placeholder>
					</Box>
				</Footer>
			</Root>
		);
	};

	const map: Record<InvoiceDocumentViewerProps['variant'], () => React.ReactElement> = {
		paper: () => <DocumentPage orientation='portrait'>{renderInvoiceTemplate()}</DocumentPage>,
		report: () => <ReportLayout>{renderInvoiceTemplate()}</ReportLayout>,
	};

	return invoice ? map[variant]() : null;
};

export { InvoiceDocumentViewer };
