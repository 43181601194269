import React from 'react';
import CheckboxMUI from 'material-ui/Checkbox';

import { Box } from '@ui/box';
import { Typography } from '@ui/typography';
import { HelpMark, HelpMarkProps } from '@ui/help-mark';
import { CheckboxCheckedIcon } from '@ui/icons/checkbox-checked';
import { CheckboxUncheckedIcon } from '@ui/icons/checkbox-unchecked';
import { TooltipPosition } from '../tooltip';

export type CheckboxProps = {
	helpText?: React.ReactNode;
	tooltipPosition?: TooltipPosition;
	HelpMarkProps?: Partial<HelpMarkProps>;
} & __MaterialUI.Switches.CheckboxProps;

const Checkbox: React.FC<CheckboxProps> = props => {
	const { label, disabled, helpText, tooltipPosition = 'top', HelpMarkProps, ...rest } = props;

	const renderLabel = () => {
		if (!label) return;

		return (
			<Box display='inline-flex' alignItems='center'>
				<Typography.Text display='inline' marginRight={10}>
					{label}
				</Typography.Text>
				{helpText && <HelpMark label={helpText} TooltipProps={{ position: tooltipPosition }} {...HelpMarkProps} />}
			</Box>
		);
	};

	return (
		<CheckboxMUI
			checkedIcon={<CheckboxCheckedIcon color={disabled ? 'muted' : 'accent'} size={24} />}
			uncheckedIcon={<CheckboxUncheckedIcon color='black' size={24} />}
			labelStyle={style.label}
			label={renderLabel()}
			disabled={disabled}
			{...rest}
		/>
	);
};

const style: Record<string, React.CSSProperties> = {
	label: { fontSize: 14, lineHeight: '25px', width: 'auto' },
};

Checkbox.defaultProps = {
	HelpMarkProps: {},
};

export { Checkbox };
