import styled from 'styled-components';


type kpiLayoutProps =  {
	invoiceViewerMode: boolean;
}

const Root = styled.div`
	width: 100%;
  height: 100%;
`;

const BrowserRoot = styled.div`
	width: 100%;
  height: 100%;
`;

const MobileRoot = styled.div`
	width: 100%;
	height: 100%;
	background-color: #fff;
	display: flex;
`;

const KPILayout = styled.div`
	position: relative;
	flex-shrink: 1;

	${(props: kpiLayoutProps) => props.invoiceViewerMode && `
		width: 100%;
	`}
`;

const TitleLayout = styled.div`
	position: relative;
	flex: 1 2 auto;
	display: flex;
	align-items: center;
`;

const ControlsLayout = styled.div`
	flex-shrink: 0;
	position: relative;
	display: flex;
	flex-flow: row nowrap;
	align-items: stretch;
`;


export {
	Root,
	BrowserRoot,
	MobileRoot,
	KPILayout,
	TitleLayout,
	ControlsLayout
}