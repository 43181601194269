import React from 'react';

import { SuccessIcon } from '@ui/icons/success';
import { SpinnerAutoLayout, Spinner } from '@ui/spinner';
import {
	Root,
	WrapLayout,
	IconLayout,
	TitleLayout,
	Title,
	Detail,
	ControlsLayout,
	ButtonLayout,
	FirstActionButton,
	SecondActionButton,
} from './styled';

type DrawerChooseDialogProps = {
	title?: React.ReactNode;
	description?: React.ReactNode;
	firstActionLabel: string;
	secondActionLabel: string;
	isFetching?: boolean;
	onFirstAction: (e: React.MouseEvent) => void;
	onSecondAction: (e: React.MouseEvent) => void;
};

const DrawerChooseDialog: React.FC<DrawerChooseDialogProps> = props => {
	const { title, description, firstActionLabel, secondActionLabel, isFetching, onFirstAction, onSecondAction } = props;

	if (isFetching) {
		return (
			<Root>
				<SpinnerAutoLayout appearance='max-drawer'>
					<Spinner />
				</SpinnerAutoLayout>
			</Root>
		);
	}

	return (
		<Root>
			<WrapLayout>
				<IconLayout>
					<SuccessIcon color='alarm' size={100} />
				</IconLayout>
				<TitleLayout>
					<Title>{title}</Title>
					<Detail>{description}</Detail>
				</TitleLayout>
				<ControlsLayout>
					<ButtonLayout>
						<FirstActionButton label={firstActionLabel} onClick={onFirstAction} />
					</ButtonLayout>
					<ButtonLayout>
						<SecondActionButton label={secondActionLabel} onClick={onSecondAction} />
					</ButtonLayout>
				</ControlsLayout>
			</WrapLayout>
		</Root>
	);
};

export default DrawerChooseDialog;
