import React from 'react';
import { connect } from 'react-redux';

import { IAppState } from '@store';
import { selectAppProgressBarOptions } from '@shared/selectors/ui.selectors';
import XAppProgressBar, { AppProgressBarProps as XAppProgressBarProps } from './app-progress-bar.view';


type AppProgressBarProps = {} & Partial<XAppProgressBarProps>;

class AppProgressBar extends React.PureComponent<AppProgressBarProps> {
	static displayName = 'AppProgressBar[Container]';

	render () {
		const {
			options,
			...rest
		} = this.props;

		return (
			<XAppProgressBar
				{...rest}
				options={options}
			/>
		);
	}
}

function mapStateToProps(state: IAppState): Partial<AppProgressBarProps> {
	return {
		options: selectAppProgressBarOptions(state),
	};
}

export default connect<any, any, AppProgressBarProps>(mapStateToProps)(AppProgressBar);
