import createAsyncAction, { createStaticDataAction, REQUEST, RECEIVE } from '../libs/action-creator';
import {
	createReducer,
	checkAsyncItemLoadAction,
	createDefaultAsyncItem,
	invalidateAction,
	restoreValidationState,
} from '../libs/reducer-creator';
import { createAsyncSelector, createSelector } from '../libs/selector-creator';
import { useAutoFetch } from './use-auto-fetch';
import { useMapState } from './use-map-state';
import { useMapDispatch } from './use-map-dispatch';
import { useDefaultValueEffect } from './use-default-value-effect';
import { createRightCheck } from './rights';

type ActionType<T> = {
	[key in keyof T]: string;
};

export type StoreAsyncItem<T> = {
	isFetching: boolean;
	didInvalidate: boolean;
	isLoaded: boolean;
	item: T;
};

function createTypes<T>(types: Array<string>, prefix = ''): ActionType<T> {
	const typesObj = types.reduce((acc, type) => ((acc[type] = `[${prefix}]: ${type}`), acc), {} as ActionType<T>);

	return typesObj;
}

function isRequestAction(action: AsyncAction | StaticAction): boolean {
	const asycAction = action as AsyncAction;

	return asycAction.status === REQUEST;
}

function isReceiveAction(action: AsyncAction | StaticAction): boolean {
	const asycAction = action as AsyncAction;

	return asycAction.status ? asycAction.status === RECEIVE : true;
}

function invalidateStateByFunction<A, S>(
	action: StoreAction<A>,
	state: S,
	invalidator: (action: StoreAction<A>) => boolean = () => true,
) {
	const asyncAction = action as AsyncAction;
	const isReceive = isReceiveAction(asyncAction);

	if (!isReceive) {
		return {
			...state,
			isFetching: true,
		};
	} else {
		return {
			...state,
			isFetching: false,
			didInvalidate: invalidator(asyncAction),
		};
	}
}

const isReceiveType = (type: string) => type === RECEIVE;

function forceFetchingState<S>(action: AsyncAction, state: StoreAsyncItem<S>) {
	const isReceive = isReceiveAction(action);

	if (isReceive) {
		return {
			...state,
			isFetching: false,
		};
	}

	return {
		...state,
		isFetching: true,
	};
}

type OptimisticUpdateStateOptions<S> = {
	action: AsyncAction;
	state: StoreAsyncItem<S>;
	getOptimisticValue: (state: StoreAsyncItem<S>, action: AsyncAction) => S;
};

function optimisticUpdateState<S>(options: OptimisticUpdateStateOptions<S>): StoreAsyncItem<S> {
	const { action, state, getOptimisticValue } = options;
	const isRequest = isRequestAction(action);

	if (isRequest) {
		return {
			...state,
			isFetching: false,
			isLoaded: true,
			item: getOptimisticValue(state, action),
		};
	}

	return {
		...state,
		isFetching: false,
		isLoaded: true,
	};
}

export {
	createAsyncAction,
	createStaticDataAction as createStaticAction,
	createTypes,
	createReducer,
	checkAsyncItemLoadAction as checkAsyncAction,
	createDefaultAsyncItem as createAsyncInitialState,
	createAsyncSelector,
	createSelector,
	isReceiveAction,
	isReceiveType,
	restoreValidationState,
	invalidateStateByFunction,
	RECEIVE,
	useAutoFetch,
	useMapState,
	useMapDispatch,
	useDefaultValueEffect,
	invalidateAction as invalidateStateFromAction,
	forceFetchingState,
	optimisticUpdateState,
	createRightCheck,
};
