// @ts-nocheck
import React from 'react';
import * as _ from 'underscore';
import { RadioButton, RadioButtonGroup as RadioButtonGroupMUI } from 'material-ui/RadioButton';

import { FormElementContext } from '@ui/forms/inputs';
import { withTheme, WithThemeProps } from '@theme';
import { Root, Label } from './styled';

export type RadioButtonGroupProps<T> = {
	dataSource: Array<T>;
	dataSourceConfig?: { text: string; value: keyof T };
	value: any;
	disabled?: boolean;
	readonly?: boolean;
	errorText?: string;
	successText?: string;
	errorStyle?: React.CSSProperties;
	warningText?: string;
	informationText?: string;
	labelText?: string;
	className?: string;
	fullWidth?: boolean;
	style?: React.CSSProperties;
	radioButtonStyle?: React.CSSProperties;
	name?: string;
	direction?: 'column' | 'row';
	paddingOff?: boolean;
	onChange: (value, item?: T) => void;
} & WithThemeProps;

class RadioButtonGroupBase<T> extends React.Component<RadioButtonGroupProps<T>> {
	static contextType = FormElementContext;
	static defaultProps = {
		disabled: false,
		readonly: false,
		dataSourceConfig: { value: 'ID', text: 'Name' },
		direction: 'row',
	};
	valueField = this.props.dataSourceConfig.value;
	textField = this.props.dataSourceConfig.text;
	name: string = getDefaultName();

	componentDidMount() {
		this.setRef();
	}

	setRef() {
		if (this.context && this.context.setWrappedElem) {
			this.context.setWrappedElem(this);
		}
	}

	focus = () => {};

	handleChange = (e, value) => {
		const item = _.find(this.props.dataSource, d => d[this.valueField] === value);

		this.props.onChange(value, item);
	};

	render() {
		const { direction, labelText, dataSource, disabled, readonly, radioButtonStyle, className, theme, paddingOff } =
			this.props;
		const items = _.map(dataSource, (data, index) => {
			const labelColor = disabled ? theme.palette.label : theme.palette.text;

			return (
				<RadioButton
					disabled={disabled || readonly}
					key={data[this.valueField].toString()}
					value={data[this.valueField]}
					label={data[this.textField]}
					labelStyle={{ fontSize: '14px', width: 'auto', color: labelColor }}
					style={{
						...{
							marginBottom: direction === 'column' && index < dataSource.length - 1 ? '10px' : 0,
							width: 'auto',
						},
						...radioButtonStyle,
					}}
				/>
			);
		});

		const style = this.props.style || {};
		style.display = 'flex';
		style.flexDirection = this.props.direction;
		const hasLabel = Boolean(labelText);

		return (
			<Root hasLabel={hasLabel} className={className} disabled={disabled} paddingOff={paddingOff}>
				{labelText && <Label htmlFor={this.props.name || this.name}>{labelText}</Label>}
				<RadioButtonGroupMUI
					className='radio-buttons-group_btns'
					name={this.props.name || this.name}
					onChange={this.handleChange}
					valueSelected={this.props.value}
					style={style}>
					{items}
				</RadioButtonGroupMUI>
			</Root>
		);
	}
}

const getDefaultName = (function () {
	const name = 'CORE:DEFAULT_RADIO_BUTTON_GROUP_';
	let i = 0;
	return () => name + (i++).toString();
})();

const RadioButtonGroup = withTheme(RadioButtonGroupBase as any) as React.ComponentType<RadioButtonGroupProps<unknown>>;

export { RadioButton, RadioButtonGroup, RadioButtonGroupMUI };
