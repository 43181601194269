import React, { useMemo } from 'react';

import { Box } from '@ui/box';
import { HighLightedText } from '@ui/highlighted-text';
import { Autopicker, AutopickerProps, AutopickerRenderItemContentOptions } from '@ui/autopicker';

export type CurrencyAutopickerProps = {
	name?: string;
} & AutopickerProps<Currency>;

const CurrencyAutopicker: React.FC<CurrencyAutopickerProps> = props => {
	const pluralItems = useMemo(() => ['валюта', 'валюты', 'валют'] as [string, string, string], []);

	const renderItemContent = ({ item, searchText, containerWidth }: AutopickerRenderItemContentOptions<Currency>) => {
		const isSmallContainer = containerWidth < 340;

		return (
			<Box fontSize={isSmallContainer ? 12 : 13} fullWidth>
				<HighLightedText value={item.Name} query={searchText} /> [
				<HighLightedText value={item.LetterCode} query={searchText} />] [
				<HighLightedText value={item.DigitCode || '-'} query={searchText} />]
			</Box>
		);
	};

	return (
		<Autopicker
			labelText='Валюта'
			hintText='Выберите валюту...'
			pluralItems={pluralItems}
			getNameForSearchTextFilter={getNameForSearchTextFilter}
			renderItemContent={renderItemContent}
			maxItems={100}
			{...props}
			fullWidth
		/>
	);
};

const getNameForSearchTextFilter = (item: Currency) => `${item.Name} ${item.DigitCode} ${item.LetterCode}`;

export { CurrencyAutopicker };
