import React from 'react';

import { useLackOfTwitching, useBlockingScroll } from '@utils/dom';
import { Portal } from '@ui/portal';
import { Box } from '@ui/box';
import { Overlay } from '../overlay';
import { CloseButton } from '@ui/button/close-button';
import { Root, ContentLayout } from './styled';

export type SlideContentProps = {};

export type SlideContentState = {
	isOpen?: boolean;
	content?: React.ReactNode;
	onClose?: () => void;
};

class SlideContentBase extends React.PureComponent<SlideContentProps, SlideContentState> {
	static displayName = 'SlideContent';
	state: SlideContentState = {
		isOpen: false,
		content: null,
		onClose: () => {},
	};
	rootRef: HTMLElement = null;
	scrollRef: HTMLElement = null;
	dispose = () => {};

	setRootRef = (ref: HTMLElement) => (this.rootRef = ref);

	setScrollRef = (ref: HTMLElement) => (this.scrollRef = ref);

	public getScrollContainer = () => this.scrollRef;

	public open = (state: Partial<SlideContentState>) => {
		const [lack, disposeLack] = useLackOfTwitching();
		const unblockScroll = useBlockingScroll();

		this.setState({ ...state, isOpen: true });
		lack();
		this.dispose = () => {
			disposeLack();
			unblockScroll();
		};
	};

	public close = () => {
		const { isOpen, onClose } = this.state;

		if (!isOpen) return;

		this.dispose();
		this.setState({ isOpen: false });
		setTimeout(() => {
			typeof onClose === 'function' && onClose();
			this.reset();
		}, 200);
	};

	public reset = () =>
		this.setState({
			content: null,
			onClose: () => {},
		});

	public setContent = (state: Partial<SlideContentState>) => this.setState({ ...state });

	render() {
		const { isOpen, content } = this.state;

		return (
			<Portal>
				<Overlay isOpen={isOpen} />
				<Root ref={this.setRootRef} isOpen={isOpen}>
					<Box position='absolute' zIndex={10} top={12} right={12}>
						{isOpen && <CloseButton onClick={this.close} />}
					</Box>
					<ContentLayout ref={this.setScrollRef}>{content}</ContentLayout>
				</Root>
			</Portal>
		);
	}
}

const scope = {
	ref: null,
};

const setRef = ref => (scope.ref = ref);

const getRef = () => scope.ref;

const SlideContent = {
	Base: SlideContentBase,
	setRef,
	get: getRef as () => SlideContentBase,
};

export { SlideContent };
