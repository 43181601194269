import store from '@store';
import { selectContext, selectCurrentTenantID, selectCurrentUserID } from '@platform/selectors/context.selectors';
import { createObjectMap } from '@utils/object';
import { getAppName, getGlobalConfig } from '@config';

type HasAccessToUnitOptions = {
	systemComponents?: Array<string>;
	systemRights?: Array<string | Array<string>>;
};

const hasAccessToUnit = (options: HasAccessToUnitOptions) => {
	const { systemComponents, systemRights } = options;
	let hasSystemComponentsAccess = true;
	let hasSystemRightsAccess = true;
	const sessionContext = selectContext(store.getState());
	const availableComponents = sessionContext?.AvailableComponents;
	const userRights = sessionContext?.UserRights;

	if (availableComponents?.length) {
		const availableComponentsMap = createObjectMap(sessionContext?.AvailableComponents, x => x.SystemName);
		if (systemComponents?.length) {
			const matches = systemComponents.reduce((acc, x) => (acc += availableComponentsMap[x] ? 1 : 0), 0);
			if (matches !== systemComponents.length) {
				hasSystemComponentsAccess = false;
			}
		}
	} else {
		hasSystemComponentsAccess = false;
	}

	if (userRights?.length) {
		const userRightsMap = createObjectMap(sessionContext?.UserRights, x => x.SystemName);
		if (systemRights?.length) {
			if (typeof systemRights[0] === 'string') {
				const matches = systemRights.reduce((acc, x) => (acc += userRightsMap[x as string] ? 1 : 0), 0);
				if (matches !== systemRights.length) {
					hasSystemRightsAccess = false;
				}
			} else {
				if (systemRights[0]?.length) {
					const matches = systemRights[0].reduce((acc, x) => (acc += userRightsMap[x] ? 1 : 0), 0);
					if (matches < 1) {
						hasSystemRightsAccess = false;
					}
				}
			}
		}
	} else {
		hasSystemRightsAccess = false;
	}

	return hasSystemComponentsAccess && hasSystemRightsAccess;
};

const getUserData = () => {
	const state = store.getState();
	return {
		tenantID: selectCurrentTenantID(state),
		userID: selectCurrentUserID(state),
	};
};

function transformAppTitle(title: string) {
	const isInvoiceApp = getAppName() === 'invoice';
	const isBfmApp = getAppName() === 'bfm';
	const isSberbank = getGlobalConfig().isSberbank;
	const isSberbankAndInvoice = isSberbank && isInvoiceApp;

	if (isSberbank) {
		if (isBfmApp) return `${title} :: «Бизнес Аналитика»`;
		if (isSberbankAndInvoice) return `${title} :: «Сервис выставления счетов»`;

		return title;
	}

	return `${title} :: «Seeneco BFM - Управление финансами»`;
}

export { hasAccessToUnit, getUserData, transformAppTitle };
