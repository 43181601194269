import { IAppState } from '@store';
import { createAsyncSelector, createSelector } from '@flux';
import { getQuarterName } from '@utils';
import { DetalizationOptions } from '@analytics/actions';
import { checkRowIDForDetalization } from '@analytics/selectors/reporting.selectors';
import { mainCashflowItemsSelectorsPack } from '@cashflow-items/selectors';
import { ReportTableCell } from '@analytics/components/reporting/shared/report-table';

const selectAsyncPlOperations = createAsyncSelector<Array<PLOperationBrief>, IAppState>({
	get: s => s.analytics.reporting.operations,
	selector: createSelector(
		s => s.analytics.reporting.operations.item,
		item => item,
	),
});

const selectAsyncAciSeriesMap = createAsyncSelector<Record<string, PLOperationAccountsChartItemSeries>, IAppState>({
	get: s => s.analytics.reporting.aciSeries,
	selector: createSelector(
		s => s.analytics.reporting.aciSeries.item,
		item => item,
	),
});

const selectAsyncCashBalanceRecords = createAsyncSelector<Array<CashBalanceRecord>, IAppState>({
	get: s => s.analytics.reporting.cashBalanceRecords,
	selector: createSelector(
		s => s.analytics.reporting.cashBalanceRecords.item,
		item => item,
	),
});

const selectIsDataFetching = (state: IAppState) =>
	mainCashflowItemsSelectorsPack.selectAsyncCashflowItems.selectIsFetching(state) ||
	mainReportingSelectorsPack.selectAsyncAciSeriesMap.selectIsFetching(state) ||
	mainReportingSelectorsPack.selectAsyncCashBalanceRecords.selectIsFetching(state);

const selectIsDataLoaded = (state: IAppState) =>
	mainCashflowItemsSelectorsPack.selectAsyncCashflowItems.selectIsLoaded(state) &&
	mainReportingSelectorsPack.selectAsyncAciSeriesMap.selectIsLoaded(state) &&
	mainReportingSelectorsPack.selectAsyncCashBalanceRecords.selectIsLoaded(state);

function selectDateRange(state: IAppState) {
	return state.analytics.reporting.dateRange;
}

function selectLegalEntityFilter(state: IAppState) {
	return state.analytics.reporting.legalEntityFilter;
}

function selectProjectFilter(state: IAppState) {
	return state.analytics.reporting.projectFilter;
}

function selectTextFilter(state: IAppState) {
	return state.analytics.reporting.textFilter;
}

function selectIsIncludesVAT(state: IAppState) {
	return state.analytics.reporting.isIncludesVAT;
}

function selectSelectedReportTableCell(state: IAppState): ReportTableCell {
	return state.analytics.reporting.selectedCell;
}

function selectDetalizationOptions(state: IAppState): DetalizationOptions {
	return state.analytics.reporting.detalizationOptions;
}

function selectIsExpandedCfReport(state: IAppState) {
	return state.analytics.reporting.isExpandedCfReport;
}

function selectIsExpandedPlReport(state: IAppState) {
	return state.analytics.reporting.isExpandedPlReport;
}

function isOperationsDetalizationOpen(state: IAppState) {
	const cell = selectSelectedReportTableCell(state);

	return cell && cell.col !== -1 && checkRowIDForDetalization(cell);
}

const selectOperationsDetalizationTitle = createSelector(selectSelectedReportTableCell, tableCell => {
	if (!tableCell || tableCell.col === -1) return '';

	const col = tableCell.row.columns[tableCell.col];
	const name = tableCell.row.parentName
		? `«${tableCell.row.name}» по «${tableCell.row.parentName}»`
		: `«${tableCell.row.name}»`;
	const date = col.date
		? col.reduceBy === 'QUARTER'
			? `${getQuarterName(+col.date.split(' ')[0])} кв. ${col.date.split(' ')[1]}`
			: col.date.toLocaleLowerCase()
		: '';
	const title = date ? `Детализация ${name} за ${date} г.` : `Детализация ${name}`;

	return title;
});

export const mainReportingSelectorsPack = {
	selectAsyncPlOperations,
	selectAsyncAciSeriesMap,
	selectAsyncCashBalanceRecords,
	selectIsDataFetching,
	selectIsDataLoaded,
	selectDateRange,
	selectLegalEntityFilter,
	selectProjectFilter,
	selectTextFilter,
	selectIsIncludesVAT,
	selectSelectedReportTableCell,
	selectDetalizationOptions,
	selectIsExpandedCfReport,
	selectIsExpandedPlReport,
	isOperationsDetalizationOpen,
	selectOperationsDetalizationTitle,
};
