import React from 'react';
import * as _ from 'underscore';
import * as moment from 'moment';

import { getDrawer, getURLParameterByName } from '../../../../../../../core/libs/utils';
import { DrawerRightControls } from '@ui/drawer';
import { ResultView } from '@ui/result-view';
import { SUCCESS, FAILED } from '../../../../../../shared/constants/statuses';
import { history } from '../../../../layout/layout.view';
import { ROUTE_MAP } from '../../../../../../../core/routes/urls';
import { Root, RightContentLayout } from './styled';

const TENANT_ACCOUNT_URL = ROUTE_MAP.TENANT_ACCOUNT;

interface IProps {
	expireDate: string;

	resetPath: () => void;
}

class PersonPaymentResultDisplay extends React.PureComponent<IProps> {
	static displayName = 'PersonPaymentResultDisplay';

	componentDidMount() {
		this.setDrawerRightContent();
		this.setDrawerOnClose();
	}

	setDrawerRightContent = () => {
		getDrawer().setContent({
			rightContent: this.renderRightContent(),
		});
	};

	setDrawerOnClose = () => {
		const { resetPath } = this.props;

		getDrawer().setOnClose(() => {
			resetPath();
			history.push(TENANT_ACCOUNT_URL);
		});
	};

	handleClose = () => {
		getDrawer().close();
	};

	renderRightContent = () => {
		const { resetPath } = this.props;

		return (
			<RightContentLayout>
				<DrawerRightControls actionLabel={'Закрыть'} onAction={this.handleClose} />
			</RightContentLayout>
		);
	};

	render() {
		const { expireDate } = this.props;
		const paymentServiceResult = getURLParameterByName('PaymentServiceResult');
		const type = paymentServiceResult === 'Success' ? SUCCESS : FAILED;
		const title =
			paymentServiceResult === 'Success'
				? `Сервис оплачен до ${moment(expireDate, 'DD-MM-YYYY').format('DD.MM.YYYY')} г.`
				: 'Произошла ошибка';

		return (
			<Root>
				<ResultView type={type} title={title} />
			</Root>
		);
	}
}

export default PersonPaymentResultDisplay;
