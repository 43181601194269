import { combineReducers } from 'redux';

import mainTransactionsReducer, { MainTransactionsState } from './main.reducer';


export type TransactionsState = {
	main: MainTransactionsState;
};

const transactionsReducer = combineReducers<TransactionsState>({
	main: mainTransactionsReducer,
});

export default transactionsReducer;
