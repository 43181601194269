import { runInvalidatorEffect } from './invalidator.effect';


const clientsEffects = [
	runInvalidatorEffect,
];

export {
	clientsEffects,
};
