import React from 'react';

import { Root, ContentLayout, Item, IconLayout, TextLayout } from './styled';

type CardPickerSourceItem<T> = {
	value: number;
	text: string;
	item?: T;
	renderIcon?: (color: 'accent' | 'black') => React.ReactElement;
};

type CardPickerProps = {
	dataSource: Array<CardPickerSourceItem<any>>;
	value: number;
	itemWidth?: string;
	onChange: (source: CardPickerSourceItem<any>) => void;
};

const CardPicker: React.FC<CardPickerProps> = props => {
	const { dataSource, value, itemWidth, onChange } = props;

	const handleOnChange = (value: number) => onChange(dataSource[value]);

	const handleClick = (value: number) => (e: React.MouseEvent) => {
		handleOnChange(value);
		e.stopPropagation();
	};

	return (
		<Root>
			<ContentLayout>
				{dataSource &&
					dataSource.map((source, index) => {
						return (
							<Item
								key={index}
								itemWidth={itemWidth}
								isActive={source.value === value}
								onClick={handleClick(source.value)}>
								<IconLayout>{source.renderIcon(source.value === value ? 'accent' : 'black')}</IconLayout>
								<TextLayout>{source.text}</TextLayout>
							</Item>
						);
					})}
			</ContentLayout>
		</Root>
	);
};

export { CardPicker, CardPickerSourceItem };
