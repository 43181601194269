import { createAsyncAction, createStaticAction } from '@flux';
import { FetchPlanningReportOptions, FetchOperationsOptions } from '@core/api/pl';
import { FetchCashflowForecastOptions } from '@core/api/funds';

import { types } from './types';
import { PlanningViewModeCode, PlanningGranulationModeCode, CellCoords } from '@planning/models';
import {
	selectAsyncPlanningReportItems,
	selectAsyncCashflowForecast,
	selectAsyncForecastIndicatorData,
	selectAsyncCashGapIndicatorData,
	selectAsyncPlanOperations,
	selectAsyncDetailsOperations,
} from '@planning/selectors';
import { OperationDateType } from '@pl/model';

const actions = {
	setDateRange: (value: DateRange) => createStaticAction(types.SET_DATE_RANGE)(value),
	setViewMode: (value: PlanningViewModeCode) => createStaticAction(types.SET_VIEW_MODE)(value),
	setGranulationMode: (value: PlanningGranulationModeCode) => createStaticAction(types.SET_GRANULATION_MODE)(value),
	setSearchText: (value: string) => createStaticAction(types.SET_SEARCH_TEXT)(value),
	setChartVisibility: (value: boolean) => createStaticAction(types.SET_CHART_VISIBILITY)(value),
	setSelectedCoords: (value: CellCoords) => createStaticAction(types.SET_SELECTED_COORDS)(value),
	setOperationDetailsOptions: (options: FetchOperationsOptions) =>
		createStaticAction(types.SET_OPERATION_DETAILS_OPTIONS)(options),
	setOperationDetailsTitle: (value: string) => createStaticAction(types.SET_OPERATION_DETAILS_TITLE)(value),
	setExpandedHierarchyMap: (value: Record<string, boolean>) =>
		createStaticAction(types.SET_EXPANDED_HIERARCHY_MAP)(value),
	setForecastIndicatorDate: (value: string) => createStaticAction(types.SET_FORECAST_INDICATOR_DATE)(value),
	restoreOperationDetailsValidation: () => createStaticAction(types.RESTORE_OPERATION_DETAILS_VALIDATION)(),
	setDateType: (value: OperationDateType) => createStaticAction(types.SET_DATE_TYPE)(value),
	setTenantEntitiesFilter: (value: ComplexFilter) => createStaticAction(types.SET_TENANT_ENTITIES_FILTER)(value),
	setBusinessUnitsFilter: (value: ComplexFilter) => createStaticAction(types.SET_BUSINESS_UNITS_FILTER)(value),
	setProjectsFilter: (value: ComplexFilter) => createStaticAction(types.SET_PROJECTS_FILTER)(value),
	setCashflowItemsFilter: (value: ComplexFilter) => createStaticAction(types.SET_CASHFLOW_ITEMS_FILTER)(value),
	setFundsRegistersFilter: (value: ComplexFilter) => createStaticAction(types.SET_FUNDS_REGISTERS_FILTER)(value),
	fetchPlanningReport: createAsyncAction(
		types.FETCH_PLANNING_REPORT,
		(api, _, __, options: FetchPlanningReportOptions) => api.plPack.plOperation.fetchPlanningReport(options),
		{
			isValidSelector: selectAsyncPlanningReportItems.selectIsValid,
			isFetchingSelector: selectAsyncPlanningReportItems.selectIsFetching,
		},
	) as (options: FetchPlanningReportOptions) => void,
	fetchCashflowForecast: createAsyncAction(
		types.FETCH_CASHFLOW_FORECAST,
		(api, _, __, options: FetchCashflowForecastOptions) => api.fundsPack.fundsRegister.fetchCashflowForecast(options),
		{
			isValidSelector: selectAsyncCashflowForecast.selectIsValid,
			isFetchingSelector: selectAsyncCashflowForecast.selectIsFetching,
		},
	) as (options: FetchCashflowForecastOptions) => void,
	fetchForecastIndicatorData: createAsyncAction(
		types.FETCH_FORECAST_INDICATOR_DATA,
		(api, _, __, options: FetchCashflowForecastOptions) => api.fundsPack.fundsRegister.fetchCashflowForecast(options),
		{
			isValidSelector: selectAsyncForecastIndicatorData.selectIsValid,
			isFetchingSelector: selectAsyncForecastIndicatorData.selectIsFetching,
		},
	) as (options: FetchCashflowForecastOptions) => void,
	fetchCashGapIndicatorData: createAsyncAction(
		types.FETCH_CASH_GAP_INDICATOR_DATA,
		(api, _, __, options: FetchCashflowForecastOptions) => api.fundsPack.fundsRegister.fetchCashflowForecast(options),
		{
			isValidSelector: selectAsyncCashGapIndicatorData.selectIsValid,
			isFetchingSelector: selectAsyncCashGapIndicatorData.selectIsFetching,
		},
	) as (options: FetchCashflowForecastOptions) => void,
	fetchPlanOperations: createAsyncAction(
		types.FETCH_PLAN_OPERATIONS,
		(api, _, __, options: FetchOperationsOptions) =>
			api.plPack.plOperation.fetchOperationsBriefs({ ...options, takeBy: 'plan' }),
		{
			isValidSelector: selectAsyncPlanOperations.selectIsValid,
			isFetchingSelector: selectAsyncPlanOperations.selectIsFetching,
		},
	) as (options: FetchOperationsOptions) => void,
	fetchDetailsOperations: createAsyncAction(
		types.FETCH_DETAILS_OPERATIONS,
		(api, _, __, options: FetchOperationsOptions) => api.plPack.plOperation.fetchOperationsBriefs(options),
		{
			isValidSelector: selectAsyncDetailsOperations.selectIsValid,
			isFetchingSelector: selectAsyncDetailsOperations.selectIsFetching,
		},
	) as (options: FetchOperationsOptions) => void,
};

export const mainPlanningActionsPack = actions;
