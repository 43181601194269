import React, { useRef, useState, useEffect, useMemo, forwardRef, useImperativeHandle, useCallback } from 'react';

import { useMounted, useForceUpdate } from '@core/hooks';
import { createObjectMap } from '@utils/object';
import { detectIsFunction, compose, dummy } from '@utils/helpers';
import { sortAscBy } from '@utils/sorting';
import { Box } from '@ui/box';
import { Typography } from '@ui/typography';
import { Spinner } from '@ui/spinner';
import { HighLightedText } from '@ui/highlighted-text';
import { CheckIcon } from '@ui/icons/check';
import { GhostIcon } from '@ui/icons/ghost';
import { RequiredListRendererProps } from './autopicker';
import { ListImperativeRef } from './shared';
import {
	ScrollZone,
	ListItem,
	CheckIconLayout,
	ListItemTextLayout,
	ListItemControlsLayout,
	AdditionalControlsLayout,
	VisibilityControlsLayout,
} from './styled';

type AutopickerFlatListProps<T = any> = {} & RequiredListRendererProps<T>;

const AutopickerFlatList = forwardRef<ListImperativeRef, AutopickerFlatListProps>((props, ref) => {
	const {
		value,
		searchText,
		isUpdating,
		getID,
		getName,
		getNameForSearchTextFilter,
		dataSource,
		isSmallContainer,
		containerWidth,
		saveOriginalSorting,
		maxItems,
		relatedPopupID,
		renderItemContent,
		renderEditTrigger,
		renderRemoveTrigger,
		detectIsItemDisabled = () => false,
		createUnselectItem,
		onSelectItem,
	} = props;
	const { mounted } = useMounted();
	const { forceUpdate } = useForceUpdate();
	const [navigationID, setNavigationID] = useState<SimpleID>(DEFAULT_NAVIGATION_ID);
	const scrollZoneRef = useRef<HTMLUListElement>(null);
	const hasAnyFilter = Boolean(searchText);
	const items = useMemo(() => {
		const unselectItem = detectIsFunction(createUnselectItem) ? createUnselectItem() : null;
		const filteredItems = compose<Array<any>>(
			(items: Array<any>) => (items.length > maxItems ? items.slice(0, maxItems) : items),
			(items: Array<any>) =>
				searchText
					? items.filter(x => getNameForSearchTextFilter(x).toLowerCase().indexOf(searchText.toLowerCase()) !== -1)
					: items,
		)([...dataSource]);
		const sortedItems = saveOriginalSorting
			? filteredItems
			: sortAscBy(filteredItems, [{ fn: x => getNameForSearchTextFilter(x) }]);

		if (!hasAnyFilter && unselectItem) {
			sortedItems.unshift(unselectItem);
		}

		return sortedItems;
	}, [searchText, dataSource]);
	const itemsMap = useMemo(() => createObjectMap(items, x => getID(x)), [items]);
	const itemsLength = items.length;

	useEffect(() => {
		if (!mounted()) return;
		setNavigationID(DEFAULT_NAVIGATION_ID);
	}, [itemsLength]);

	useEffect(() => {
		if (!mounted()) return;
		if (!scrollZoneRef.current) return;
		forceUpdate();
	}, [scrollZoneRef.current]);

	useEffect(() => {
		if (!mounted()) return;
		if (isUpdating) return;
		forceUpdate();
	}, [isUpdating]);

	useImperativeHandle(ref, () => ({
		keyboard,
	}));

	const getPrevVisibleItemID = (ID: SimpleID) => {
		const candidateIdx = items.findIndex(x => getID(x) === ID);
		const idx = candidateIdx === -1 ? 1 : candidateIdx;
		const prevItem = items[idx - 1];
		if (!prevItem) return DEFAULT_NAVIGATION_ID;
		const prevItemID = getID(prevItem);

		return prevItemID;
	};

	const getNextVisibleItemID = (ID: SimpleID) => {
		const candidateIdx = items.findIndex(x => getID(x) === ID);
		const idx = candidateIdx === -1 ? -1 : candidateIdx;
		const nextItem = items[idx + 1];

		if (!nextItem) return ID;
		const nextItemID = getID(nextItem);

		return nextItemID;
	};

	const keyboard = {
		processArrowUp: () => {
			const ID = getPrevVisibleItemID(navigationID);

			ID >= -1 && setNavigationID(ID);
		},
		processArrowDown: () => {
			const ID = getNextVisibleItemID(navigationID);

			ID >= -1 && setNavigationID(ID);
		},
		processArrowLeft: () => {},
		processArrowRight: () => {},
		processEnter: () => {
			const item = itemsMap[navigationID];
			if (!item) return;
			const isDisabled = detectIsItemDisabled(item);

			item && !isDisabled && onSelectItem(null, item);
		},
	};

	const handleSelectItem = (item: any) => (e: React.MouseEvent) => {
		onSelectItem(e, item);
	};

	const handleStopPropagation = (e: React.MouseEvent) => e.stopPropagation();

	const renderProcessedItemContent = (item: any) => {
		if (detectIsFunction(renderItemContent)) {
			return renderItemContent({ item, searchText, containerWidth });
		}

		return <HighLightedText value={getName(item)} query={searchText} />;
	};

	if (isUpdating) {
		return (
			<Box display='flex' justifyContent='center' alignItems='center' height={150} fullWidth>
				<Spinner appearance='updating' />
			</Box>
		);
	}

	const hasEditRenderer = detectIsFunction(renderEditTrigger);
	const hasRemoveRenderer = detectIsFunction(renderRemoveTrigger);
	const hasAnyTrigger = hasEditRenderer || hasRemoveRenderer;
	const scrollWidth = scrollZoneRef.current?.offsetWidth - scrollZoneRef.current?.clientWidth || 0;

	return (
		<ScrollZone ref={scrollZoneRef}>
			{itemsLength ? (
				items.map(x => {
					const ID = getID(x);
					const name = getName(x);
					const isUnselectItem = ID === -1;
					const isSelected = value && value[ID];
					const isNavigated = navigationID === ID;
					const isMuted = isUnselectItem;
					const isDisabled = !isUnselectItem && detectIsItemDisabled(x);
					const isRelated = ID > 0 && ID === relatedPopupID;
					const controlsCount = [hasEditRenderer, hasRemoveRenderer].filter(Boolean).length;

					return (
						<AutopickerFlatListItem
							key={ID}
							isMuted={isMuted}
							isDisabled={isDisabled}
							isSelected={isSelected}
							isNavigated={isNavigated}
							isSmallContainer={isSmallContainer}
							scrollWidth={scrollWidth}
							onClick={!isDisabled ? handleSelectItem(x) : dummy}>
							{({ isHovered }) => {
								return (
									<>
										<ListItemTextLayout>
											{isSelected && (
												<CheckIconLayout>
													<CheckIcon color='accent' size={20} />
												</CheckIconLayout>
											)}
											{renderProcessedItemContent(x)}
										</ListItemTextLayout>
										<ListItemControlsLayout count={controlsCount}>
											<VisibilityControlsLayout isNavigated={isNavigated}>
												{hasAnyTrigger && !isMuted && !isDisabled && (isHovered || isNavigated || isRelated) && (
													<AdditionalControlsLayout onClick={handleStopPropagation}>
														{hasEditRenderer && renderEditTrigger(ID, name)}
														{hasRemoveRenderer && renderRemoveTrigger(ID, name)}
													</AdditionalControlsLayout>
												)}
											</VisibilityControlsLayout>
										</ListItemControlsLayout>
									</>
								);
							}}
						</AutopickerFlatListItem>
					);
				})
			) : (
				<Box
					display='flex'
					flexDirection='column'
					justifyContent='center'
					alignItems='center'
					minHeight={150}
					padding={20}>
					<Box marginBottom={10}>
						<GhostIcon color='accent' size={24} />
					</Box>
					{searchText ? (
						<Typography.Label textAlign='center'>По запросу «{searchText}» ничего не найдено</Typography.Label>
					) : (
						<Typography.Label textAlign='center'>Ничего не найдено</Typography.Label>
					)}
				</Box>
			)}
		</ScrollZone>
	);
});

const DEFAULT_NAVIGATION_ID = -2;

AutopickerFlatList.defaultProps = {
	maxItems: Infinity,
};

type AutopickerFlatListItemProps = {
	isMuted: boolean;
	isDisabled: boolean;
	isSelected: boolean;
	isNavigated: boolean;
	isSmallContainer: boolean;
	scrollWidth: number;
	children: (options: AutopickerFlatListItemChildrenOptions) => React.ReactNode;
	onClick: (e: React.MouseEvent) => void;
};

const AutopickerFlatListItem: React.FC<AutopickerFlatListItemProps> = props => {
	const { isNavigated, isSmallContainer, children, ...rest } = props;
	const rootRef = useRef<HTMLLIElement>(null);
	const [isHovered, setIsHovered] = useState(false);

	useEffect(() => {
		if (!isNavigated) return;
		scrollIntoView();
	}, [isNavigated]);

	const scrollIntoView = () => rootRef.current?.scrollIntoView({ block: 'center', behavior: 'smooth' });

	const handleMouseOver = useCallback(() => {
		setIsHovered(true);
	}, []);

	const handleMouseLeave = useCallback(() => {
		setIsHovered(false);
	}, []);

	return (
		<ListItem
			ref={rootRef}
			isSmallContainer={isSmallContainer}
			isNavigated={isNavigated}
			onMouseOver={handleMouseOver}
			onMouseLeave={handleMouseLeave}
			{...rest}>
			{children({ isHovered })}
		</ListItem>
	);
};

type AutopickerFlatListItemChildrenOptions = {
	isHovered: boolean;
};

export { AutopickerFlatList };
