/***
 * Library contains JavaScript API for interaction with Seeneco Framework Web Services contained in CounterpartyPackage
 * @author User Generated by Seeneco Framework Modeler 
 *
 */
(function(w){
  /***
  * 
  */
  w.AbstractJoinCounterpartyRequest = function() {
    this.PACKAGE = "COUNTERPARTY";
    this.CLASSIFIER = "ABSTRACT_JOIN_COUNTERPARTY_REQUEST";
    this.CopySupportingElement = false;
    this.MainCounterpartyID = -1;
    this.SecondaryCounterpartyID = -1;
    this.CurrentEmployee = null;
    this.OperationUID = -1;
  };
  /***
  * Аналитическая группа контрагентов используется для произвольной группировки контрагентов (альтернативной по отношению к ГСЗ) для консолидации данных по ним в управленческой отчетности
  */
  w.AnalyticalCounterpartyGroup = function() {
    this.PACKAGE = "COUNTERPARTY";
    this.CLASSIFIER = "ANALYTICAL_COUNTERPARTY_GROUP";
    this.CopySupportingElement = false;
    this.Name = "";
    this.ID = -1;
    this.TaxCode = "";
    this.PhoneNumber = "";
    this.EMail = "";
    this.LogoURL = "";
    this.Code = "";
    this.TextBlocks = [];
    this.CounterpartyAmounts = [];
    this.Groups = [];
    this.NameEng = "";
    this.UID = "";
    this.CounterpartyTypedAttributes = [];
    this.ExternalIDs = [];
    this.CounterpartyLinks = [];
    this.RecordStatus = -1;
    this.AttachedDocuments = [];
    this.Accounts = [];
    this.Technical = false;
    this.ClientManagerID = -1;
    this.Contacts = [];
    this.Comment = "";
  };
  /***
  * 
  */
  w.APICounterpartyAccount = function() {
    this.PACKAGE = "COUNTERPARTY";
    this.CLASSIFIER = "APICOUNTERPARTY_ACCOUNT";
    this.CopySupportingElement = false;
    this.AccountNumber = "";
    this.BankName = "";
    this.BankBIC = "";
    this.CounterpartyAccountUID = "";
    this.AccountType = "";
  };
  /***
  * 
  */
  w.APICounterpartyBrief = function() {
    this.PACKAGE = "COUNTERPARTY";
    this.CLASSIFIER = "APICOUNTERPARTY_BRIEF";
    this.CopySupportingElement = false;
    this.CounterpartyUID = "";
    this.Name = "";
    this.TaxCode = "";
    this.LegalEntity = false;
    this.SoleProprietor = false;
    this.AdditionalTaxCode = "";
  };
  /***
  * 
  */
  w.APIEmployeeBrief = function() {
    this.PACKAGE = "COUNTERPARTY";
    this.CLASSIFIER = "APIEMPLOYEE_BRIEF";
    this.CopySupportingElement = false;
    this.EmployeeUID = "";
    this.Name = "";
    this.SignImage = "";
  };
  /***
  * 
  */
  w.ArbitrationCase = function() {
    this.PACKAGE = "COUNTERPARTY";
    this.CLASSIFIER = "ARBITRATION_CASE";
    this.CopySupportingElement = false;
    this.CaseNumber = "";
    this.ClaimAmount = -1;
    this.ArbitrationTypeDescription = "";
    this.RegistrationDate = null;
    this.CloseDate = null;
    this.CourtName = "";
    this.Defendants = [];
    this.Claimants = [];
    this.LastDecisionDescription = "";
  };
  /***
  * Defines Контрагент
  */
  w.Counterparty = function() {
    this.PACKAGE = "COUNTERPARTY";
    this.CLASSIFIER = "COUNTERPARTY";
    this.CopySupportingElement = false;
    this.Name = "";
    this.ID = -1;
    this.TaxCode = "";
    this.PhoneNumber = "";
    this.EMail = "";
    this.LogoURL = "";
    this.Code = "";
    this.TextBlocks = [];
    this.CounterpartyAmounts = [];
    this.Groups = [];
    this.NameEng = "";
    this.UID = "";
    this.CounterpartyTypedAttributes = [];
    this.ExternalIDs = [];
    this.CounterpartyLinks = [];
    this.RecordStatus = -1;
    this.AttachedDocuments = [];
    this.Accounts = [];
    this.Technical = false;
    this.ClientManagerID = -1;
    this.Contacts = [];
    this.Comment = "";
  };
  /***
  * 
  */
  w.CounterpartyAccount = function() {
    this.PACKAGE = "COUNTERPARTY";
    this.CLASSIFIER = "COUNTERPARTY_ACCOUNT";
    this.CopySupportingElement = false;
    this.Bank = null;
    this.ID = -1;
    this.AccountNumber = "";
    this.UID = "";
  };
  /***
  * 
  */
  w.CounterpartyAmount = function() {
    this.PACKAGE = "COUNTERPARTY";
    this.CLASSIFIER = "COUNTERPARTY_AMOUNT";
    this.CopySupportingElement = false;
    this.Amount = -1;
    this.CurrencyID = -1;
    this.CounterpartyAttribute = null;
    this.TypedValue = "";
  };
  /***
  * 
  */
  w.CounterpartyAttribute = function() {
    this.PACKAGE = "COUNTERPARTY";
    this.CLASSIFIER = "COUNTERPARTY_ATTRIBUTE";
    this.CopySupportingElement = false;
    this.Name = "";
    this.ID = -1;
    this.Code = "";
    this.DataType = "";
    this.AmountFlag = false;
  };
  /***
  * 
  */
  w.CounterpartyAttributeValue = function() {
    this.PACKAGE = "COUNTERPARTY";
    this.CLASSIFIER = "COUNTERPARTY_ATTRIBUTE_VALUE";
    this.CopySupportingElement = false;
    this.CounterpartyAttribute = null;
    this.TypedValue = "";
  };
  /***
  * 
  */
  w.CounterpartyBrief = function() {
    this.PACKAGE = "COUNTERPARTY";
    this.CLASSIFIER = "COUNTERPARTY_BRIEF";
    this.CopySupportingElement = false;
    this.TaxCode = "";
    this.AdditionalTaxCode = "";
    this.CompanySealImageURL = "";
    this.LogoURL = "";
    this.NameEng = "";
    this.LegalEntity = false;
    this.SoleProprietor = false;
    this.LegalEntityTypeCode = "";
    this.Groups = [];
    this.StateRegistrationCode = "";
    this.Address = "";
    this.Technical = false;
    this.ClientManagerID = -1;
    this.Comment = "";
    this.ConsigneeAddress = "";
    this.Code = "";
    this.ID = -1;
    this.Name = "";
    this.GUID = "";
  };
  /***
  * 
  */
  w.CounterpartyContact = function() {
    this.PACKAGE = "COUNTERPARTY";
    this.CLASSIFIER = "COUNTERPARTY_CONTACT";
    this.CopySupportingElement = false;
    this.ID = -1;
    this.Email = "";
    this.Description = "";
    this.CounterpartyID = -1;
    this.Name = "";
    this.Phone = "";
  };
  /***
  * 
  */
  w.CounterpartyExternalID = function() {
    this.PACKAGE = "COUNTERPARTY";
    this.CLASSIFIER = "COUNTERPARTY_EXTERNAL_ID";
    this.CopySupportingElement = false;
    this.ID = -1;
    this.Type = null;
    this.Value = "";
  };
  /***
  * 
  */
  w.CounterpartyExternalIDType = function() {
    this.PACKAGE = "COUNTERPARTY";
    this.CLASSIFIER = "COUNTERPARTY_EXTERNAL_IDTYPE";
    this.CopySupportingElement = false;
    this.ID = -1;
    this.Name = "";
    this.Code = "";
  };
  /***
  * 
  */
  w.CounterpartyFilter = function() {
    this.PACKAGE = "COUNTERPARTY";
    this.CLASSIFIER = "COUNTERPARTY_FILTER";
    this.CopySupportingElement = false;
    this.QueryString = "";
    this.SelectLegalEntities = false;
    this.SelectEmployees = false;
    this.SelectTenantLegalEntities = false;
    this.CounterpartyGroupIDList = [];
    this.SelectNaturalPersons = false;
    this.Count = -1;
    this.SelectSoleProprietors = false;
    this.LegalEntityTypeIDList = [];
    this.ClientManagerIDList = [];
  };
  /***
  * 
  */
  w.CounterpartyGroup = function() {
    this.PACKAGE = "COUNTERPARTY";
    this.CLASSIFIER = "COUNTERPARTY_GROUP";
    this.CopySupportingElement = false;
    this.Name = "";
    this.ID = -1;
    this.Brief = "";
    this.LimitedAccessGroup = false;
    this.AccessLevel = -1;
  };
  /***
  * 
  */
  w.CounterpartyGroupEmployeeAccess = function() {
    this.PACKAGE = "COUNTERPARTY";
    this.CLASSIFIER = "COUNTERPARTY_GROUP_EMPLOYEE_ACCESS";
    this.CopySupportingElement = false;
    this.AccessLevel = -1;
    this.EmployeeID = -1;
    this.CounterpartyGroupID = -1;
    this.CounterpartyID = -1;
  };
  /***
  * 
  */
  w.CounterpartyIndicatorValue = function() {
    this.PACKAGE = "COUNTERPARTY";
    this.CLASSIFIER = "COUNTERPARTY_INDICATOR_VALUE";
    this.CopySupportingElement = false;
    this.Value = -1;
    this.Currency = null;
    this.ValueDate = null;
    this.Indicator = null;
    this.ID = -1;
  };
  /***
  * 
  */
  w.CounterpartyLink = function() {
    this.PACKAGE = "COUNTERPARTY";
    this.CLASSIFIER = "COUNTERPARTY_LINK";
    this.CopySupportingElement = false;
    this.LinkID = -1;
    this.CounterpartyLinkRoleID = -1;
    this.CounterpartyBrief = null;
    this.StartDate = null;
    this.EndDate = null;
  };
  /***
  * 
  */
  w.CounterpartyProfileRequest = function() {
    this.PACKAGE = "COUNTERPARTY";
    this.CLASSIFIER = "COUNTERPARTY_PROFILE_REQUEST";
    this.CopySupportingElement = false;
    this.TaxCode = "";
    this.StateRegistrationCode = "";
  };
  /***
  * 
  */
  w.CounterpartyProfileResponse = function() {
    this.PACKAGE = "COUNTERPARTY";
    this.CLASSIFIER = "COUNTERPARTY_PROFILE_RESPONSE";
    this.CopySupportingElement = false;
    this.Request = null;
    this.CounterpartyProfile = null;
    this.Status = "";
  };
  /***
  * создается при отправке инвойса контрагенту
  */
  w.CounterpartyResponsibleEmployee = function() {
    this.PACKAGE = "COUNTERPARTY";
    this.CLASSIFIER = "COUNTERPARTY_RESPONSIBLE_EMPLOYEE";
    this.CopySupportingElement = false;
    this.EMail = "";
    this.CounterpartyID = -1;
  };
  /***
  * 
  */
  w.CreateJointCounterpartyRequest = function() {
    this.PACKAGE = "COUNTERPARTY";
    this.CLASSIFIER = "CREATE_JOINT_COUNTERPARTY_REQUEST";
    this.CopySupportingElement = false;
    this.MainCounterpartyID = -1;
    this.SecondaryCounterpartyID = -1;
    this.CurrentEmployee = null;
    this.OperationUID = -1;
  };
  /***
  * 
  */
  w.Employee = function() {
    this.PACKAGE = "COUNTERPARTY";
    this.CLASSIFIER = "EMPLOYEE";
    this.CopySupportingElement = false;
    this.MainBusinessRole = null;
    this.UserID = -1;
    this.MonthWorkdays = [];
    this.SignImageURL = "";
    this.Employer = null;
    this.BirthDate = null;
    this.Name = "";
    this.ID = -1;
    this.TaxCode = "";
    this.PhoneNumber = "";
    this.EMail = "";
    this.LogoURL = "";
    this.Code = "";
    this.TextBlocks = [];
    this.CounterpartyAmounts = [];
    this.Groups = [];
    this.NameEng = "";
    this.UID = "";
    this.CounterpartyTypedAttributes = [];
    this.ExternalIDs = [];
    this.CounterpartyLinks = [];
    this.RecordStatus = -1;
    this.AttachedDocuments = [];
    this.Accounts = [];
    this.Technical = false;
    this.ClientManagerID = -1;
    this.Contacts = [];
    this.Comment = "";
  };
  /***
  * 
  */
  w.EmployeeBrief = function() {
    this.PACKAGE = "COUNTERPARTY";
    this.CLASSIFIER = "EMPLOYEE_BRIEF";
    this.CopySupportingElement = false;
    this.BusinessRole = null;
    this.SignImageURL = "";
    this.UserID = -1;
    this.Employer = null;
    this.PhoneNumber = "";
    this.EMail = "";
    this.TaxCode = "";
    this.AdditionalTaxCode = "";
    this.CompanySealImageURL = "";
    this.LogoURL = "";
    this.NameEng = "";
    this.LegalEntity = false;
    this.SoleProprietor = false;
    this.LegalEntityTypeCode = "";
    this.Groups = [];
    this.StateRegistrationCode = "";
    this.Address = "";
    this.Technical = false;
    this.ClientManagerID = -1;
    this.Comment = "";
    this.ConsigneeAddress = "";
    this.Code = "";
    this.ID = -1;
    this.Name = "";
    this.GUID = "";
  };
  /***
  * 
  */
  w.GroupOfBorrowers = function() {
    this.PACKAGE = "COUNTERPARTY";
    this.CLASSIFIER = "GROUP_OF_BORROWERS";
    this.CopySupportingElement = false;
    this.Name = "";
    this.ID = -1;
    this.TaxCode = "";
    this.PhoneNumber = "";
    this.EMail = "";
    this.LogoURL = "";
    this.Code = "";
    this.TextBlocks = [];
    this.CounterpartyAmounts = [];
    this.Groups = [];
    this.NameEng = "";
    this.UID = "";
    this.CounterpartyTypedAttributes = [];
    this.ExternalIDs = [];
    this.CounterpartyLinks = [];
    this.RecordStatus = -1;
    this.AttachedDocuments = [];
    this.Accounts = [];
    this.Technical = false;
    this.ClientManagerID = -1;
    this.Contacts = [];
    this.Comment = "";
  };
  /***
  * 
  */
  w.JoinCounterpartyRequest = function() {
    this.PACKAGE = "COUNTERPARTY";
    this.CLASSIFIER = "JOIN_COUNTERPARTY_REQUEST";
    this.CopySupportingElement = false;
    this.MainCounterpartyID = -1;
    this.SecondaryCounterpartyID = -1;
    this.CurrentEmployee = null;
    this.OperationUID = -1;
  };
  /***
  * 
  */
  w.LegalEntity = function() {
    this.PACKAGE = "COUNTERPARTY";
    this.CLASSIFIER = "LEGAL_ENTITY";
    this.CopySupportingElement = false;
    this.Trademarks = [];
    this.FoundationPlace = "";
    this.FoundationDate = null;
    this.StaffCount = -1;
    this.TenantLegalEntity = false;
    this.CompanyGroup = false;
    this.ParentCompanyGroup = null;
    this.Country = null;
    this.Industry = null;
    this.LegalEntityScale = null;
    this.LegalEntityType = null;
    this.StateIndustry = null;
    this.LegalAddress = "";
    this.AdditionalTaxCode = "";
    this.CompanySealImageURL = "";
    this.SoleProprietor = false;
    this.RegistrationAuthority = "";
    this.StateRegistrationCode = "";
    this.BankClientDateFrom = null;
    this.Employees = [];
    this.LegalEntityTaxMode = null;
    this.ConsigneeAddress = "";
    this.Name = "";
    this.ID = -1;
    this.TaxCode = "";
    this.PhoneNumber = "";
    this.EMail = "";
    this.LogoURL = "";
    this.Code = "";
    this.TextBlocks = [];
    this.CounterpartyAmounts = [];
    this.Groups = [];
    this.NameEng = "";
    this.UID = "";
    this.CounterpartyTypedAttributes = [];
    this.ExternalIDs = [];
    this.CounterpartyLinks = [];
    this.RecordStatus = -1;
    this.AttachedDocuments = [];
    this.Accounts = [];
    this.Technical = false;
    this.ClientManagerID = -1;
    this.Contacts = [];
    this.Comment = "";
  };
  /***
  * 
  */
  w.LegalEntityCurrency = function() {
    this.PACKAGE = "COUNTERPARTY";
    this.CLASSIFIER = "LEGAL_ENTITY_CURRENCY";
    this.CopySupportingElement = false;
    this.Currency = null;
    this.RoleSystemName = "";
  };
  /***
  * 
  */
  w.LegalEntityExternalProfile = function() {
    this.PACKAGE = "COUNTERPARTY";
    this.CLASSIFIER = "LEGAL_ENTITY_EXTERNAL_PROFILE";
    this.CopySupportingElement = false;
    this.TaxServiceCode = "";
    this.TaxCodeAdditional = "";
    this.SocialSecurityServiceCode = "";
    this.PensionFundCode = "";
    this.MainStateIndustryClassifier = null;
    this.AdditionalStateIndustryClassifiers = [];
    this.RegistrationDate = null;
    this.Active = false;
    this.CharterCapitalAmount = -1;
    this.Founders = [];
    this.Leaders = [];
    this.ArbitrationCases = [];
    this.InventoryDate = null;
    this.TaxCode = "";
    this.StateRegistrationCode = "";
    this.LegalName = "";
    this.JuridicalAddress = "";
  };
  /***
  * 
  */
  w.LegalEntityExternalReference = function() {
    this.PACKAGE = "COUNTERPARTY";
    this.CLASSIFIER = "LEGAL_ENTITY_EXTERNAL_REFERENCE";
    this.CopySupportingElement = false;
    this.TaxCode = "";
    this.StateRegistrationCode = "";
    this.LegalName = "";
    this.JuridicalAddress = "";
  };
  /***
  * 
  */
  w.LegalEntityFounderExternalProfile = function() {
    this.PACKAGE = "COUNTERPARTY";
    this.CLASSIFIER = "LEGAL_ENTITY_FOUNDER_EXTERNAL_PROFILE";
    this.CopySupportingElement = false;
    this.TaxCode = "";
    this.Name = "";
    this.CharterCapitalInvestmentAmount = -1;
  };
  /***
  * 
  */
  w.LegalEntityLeaderExternalProfile = function() {
    this.PACKAGE = "COUNTERPARTY";
    this.CLASSIFIER = "LEGAL_ENTITY_LEADER_EXTERNAL_PROFILE";
    this.CopySupportingElement = false;
    this.TaxCode = "";
    this.Name = "";
    this.Position = "";
  };
  /***
  * 
  */
  w.LegalEntityScale = function() {
    this.PACKAGE = "COUNTERPARTY";
    this.CLASSIFIER = "LEGAL_ENTITY_SCALE";
    this.CopySupportingElement = false;
    this.Name = "";
    this.ID = -1;
    this.SystemName = "";
  };
  /***
  * 
  */
  w.LegalEntityTaxMode = function() {
    this.PACKAGE = "COUNTERPARTY";
    this.CLASSIFIER = "LEGAL_ENTITY_TAX_MODE";
    this.CopySupportingElement = false;
    this.ID = -1;
    this.Code = "";
    this.Name = "";
    this.HasVAT = false;
  };
  /***
  * 
  */
  w.LegalEntityType = function() {
    this.PACKAGE = "COUNTERPARTY";
    this.CLASSIFIER = "LEGAL_ENTITY_TYPE";
    this.CopySupportingElement = false;
    this.Name = "";
    this.ID = -1;
    this.SystemName = "";
  };
  /***
  * 
  */
  w.NaturalPerson = function() {
    this.PACKAGE = "COUNTERPARTY";
    this.CLASSIFIER = "NATURAL_PERSON";
    this.CopySupportingElement = false;
    this.BirthDate = null;
    this.Name = "";
    this.ID = -1;
    this.TaxCode = "";
    this.PhoneNumber = "";
    this.EMail = "";
    this.LogoURL = "";
    this.Code = "";
    this.TextBlocks = [];
    this.CounterpartyAmounts = [];
    this.Groups = [];
    this.NameEng = "";
    this.UID = "";
    this.CounterpartyTypedAttributes = [];
    this.ExternalIDs = [];
    this.CounterpartyLinks = [];
    this.RecordStatus = -1;
    this.AttachedDocuments = [];
    this.Accounts = [];
    this.Technical = false;
    this.ClientManagerID = -1;
    this.Contacts = [];
    this.Comment = "";
  };
  /***
  * 
  */
  w.StandardCompanyIndicator = function() {
    this.PACKAGE = "COUNTERPARTY";
    this.CLASSIFIER = "STANDARD_COMPANY_INDICATOR";
    this.CopySupportingElement = false;
    this.Measure = -1;
    this.Code = "";
    this.ID = -1;
    this.Name = "";
    this.GUID = "";
  };
  /***
  * 
  */
  w.TenantEntityCanBeRemovedResponse = function() {
    this.PACKAGE = "COUNTERPARTY";
    this.CLASSIFIER = "TENANT_ENTITY_CAN_BE_REMOVED_RESPONSE";
    this.CopySupportingElement = false;
    this.Items = [];
  };
  /***
  * 
  */
  w.TenantEntityCanBeRemovedResponseItem = function() {
    this.PACKAGE = "COUNTERPARTY";
    this.CLASSIFIER = "TENANT_ENTITY_CAN_BE_REMOVED_RESPONSE_ITEM";
    this.CopySupportingElement = false;
    this.CanBeRemoved = false;
    this.TenantLegalEntityID = -1;
    this.FundsRegistersCount = -1;
    this.OperationsCount = -1;
    this.InvoicesCount = -1;
    this.IntegrationsCount = -1;
  };
  /***
  * 
  */
  w.TradeMark = function() {
    this.PACKAGE = "COUNTERPARTY";
    this.CLASSIFIER = "TRADE_MARK";
    this.CopySupportingElement = false;
    this.Name = "";
    this.ID = -1;
  };
   
   
  
  /***
   * Client API to CounterpartyServiceWS web service
   */
  w.CounterpartyServiceClient = function() {
    /***
     * System name of target service
     */
    this.serviceName = "CounterpartyServiceWSImpl";
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param counterparty [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CounterpartyServiceClient.prototype.addCounterparty = function(counterparty, callbackHandler) {
    var parameterNames = ["counterparty"];
    var counterpartyJSON = JSON.stringify(counterparty);
    var parameterValues = [counterpartyJSON];
    var soapMessage = buildSOAPMessage("addCounterparty", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param counterparty [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CounterpartyServiceClient.prototype.changeCounterparty = function(counterparty, callbackHandler) {
    var parameterNames = ["counterparty"];
    var counterpartyJSON = JSON.stringify(counterparty);
    var parameterValues = [counterpartyJSON];
    var soapMessage = buildSOAPMessage("changeCounterparty", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param counterpartyID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CounterpartyServiceClient.prototype.getCounterpartyByID = function(counterpartyID, callbackHandler) {
    var parameterNames = ["counterpartyID"];
    var parameterValues = [counterpartyID];
    var soapMessage = buildSOAPMessage("getCounterpartyByID", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = true;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param counterpartyID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CounterpartyServiceClient.prototype.removeCounterparty = function(counterpartyID, callbackHandler) {
    var parameterNames = ["counterpartyID"];
    var parameterValues = [counterpartyID];
    var soapMessage = buildSOAPMessage("removeCounterparty", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param legalEntityCurrency [DEFAULT!!DEFAULT]
   * @param counterpartyID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CounterpartyServiceClient.prototype.addLegalEntityCurrency = function(legalEntityCurrency, counterpartyID, callbackHandler) {
    var parameterNames = ["legalEntityCurrency", "counterpartyID"];
    var legalEntityCurrencyJSON = JSON.stringify(legalEntityCurrency);
    var parameterValues = [legalEntityCurrencyJSON, counterpartyID];
    var soapMessage = buildSOAPMessage("addLegalEntityCurrency", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param counterpartyID [DEFAULT!!DEFAULT]
   * @param legalEntityCurrency [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CounterpartyServiceClient.prototype.removeLegalEntityCurrency = function(counterpartyID, legalEntityCurrency, callbackHandler) {
    var parameterNames = ["counterpartyID", "legalEntityCurrency"];
    var legalEntityCurrencyJSON = JSON.stringify(legalEntityCurrency);
    var parameterValues = [counterpartyID, legalEntityCurrencyJSON];
    var soapMessage = buildSOAPMessage("removeLegalEntityCurrency", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param counterpartyID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CounterpartyServiceClient.prototype.getLegalEntityCurrencies = function(counterpartyID, callbackHandler) {
    var parameterNames = ["counterpartyID"];
    var parameterValues = [counterpartyID];
    var soapMessage = buildSOAPMessage("getLegalEntityCurrencies", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = true;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param counterpartyID [DEFAULT!!DEFAULT]
   * @param currencyRoleSystemName [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CounterpartyServiceClient.prototype.getLegalEntityCurrencyByRole = function(counterpartyID, currencyRoleSystemName, callbackHandler) {
    var parameterNames = ["counterpartyID", "currencyRoleSystemName"];
    var parameterValues = [counterpartyID, currencyRoleSystemName];
    var soapMessage = buildSOAPMessage("getLegalEntityCurrencyByRole", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = true;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param naturalPerson [DEFAULT!!DEFAULT]
   * @param legalEntityTypeID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CounterpartyServiceClient.prototype.getCounterpartyAttributes = function(naturalPerson, legalEntityTypeID, callbackHandler) {
    var parameterNames = ["naturalPerson", "legalEntityTypeID"];
    var parameterValues = [naturalPerson, legalEntityTypeID];
    var soapMessage = buildSOAPMessage("getCounterpartyAttributes", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = true;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param counterpartyID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CounterpartyServiceClient.prototype.freezeCounterparty = function(counterpartyID, callbackHandler) {
    var parameterNames = ["counterpartyID"];
    var parameterValues = [counterpartyID];
    var soapMessage = buildSOAPMessage("freezeCounterparty", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param counterpartyID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CounterpartyServiceClient.prototype.deleteCounterparty = function(counterpartyID, callbackHandler) {
    var parameterNames = ["counterpartyID"];
    var parameterValues = [counterpartyID];
    var soapMessage = buildSOAPMessage("deleteCounterparty", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param counterpartyID [DEFAULT!!DEFAULT]
   * @param versionDate [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CounterpartyServiceClient.prototype.getCounterpartyVersion = function(counterpartyID, versionDate, callbackHandler) {
    var parameterNames = ["counterpartyID", "versionDate"];
    var parameterValues = [counterpartyID, versionDate];
    var soapMessage = buildSOAPMessage("getCounterpartyVersion", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = true;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param counterpartyIDList [DEFAULT!!DEFAULT]
   * @param versionDate [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CounterpartyServiceClient.prototype.getCounterpartyVersionList = function(counterpartyIDList, versionDate, callbackHandler) {
    var parameterNames = ["counterpartyIDList", "versionDate"];
    var counterpartyIDListJSON = JSON.stringify(counterpartyIDList);
    var parameterValues = [counterpartyIDListJSON, versionDate];
    var soapMessage = buildSOAPMessage("getCounterpartyVersionList", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = true;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param counterpartyID [DEFAULT!!DEFAULT]
   * @param counterpartyGroupID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CounterpartyServiceClient.prototype.addCounterpartyToGroup = function(counterpartyID, counterpartyGroupID, callbackHandler) {
    var parameterNames = ["counterpartyID", "counterpartyGroupID"];
    var parameterValues = [counterpartyID, counterpartyGroupID];
    var soapMessage = buildSOAPMessage("addCounterpartyToGroup", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param counterpartyID [DEFAULT!!DEFAULT]
   * @param counterpartyGroupID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CounterpartyServiceClient.prototype.removeCounterpartyFromGroup = function(counterpartyID, counterpartyGroupID, callbackHandler) {
    var parameterNames = ["counterpartyID", "counterpartyGroupID"];
    var parameterValues = [counterpartyID, counterpartyGroupID];
    var soapMessage = buildSOAPMessage("removeCounterpartyFromGroup", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param counterpartyGroupID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CounterpartyServiceClient.prototype.getCounterpartiesInGroup = function(counterpartyGroupID, callbackHandler) {
    var parameterNames = ["counterpartyGroupID"];
    var parameterValues = [counterpartyGroupID];
    var soapMessage = buildSOAPMessage("getCounterpartiesInGroup", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = true;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CounterpartyServiceClient.prototype.getCurrentEmployee = function(callbackHandler) {
    var parameterNames = [];
    var parameterValues = [];
    var soapMessage = buildSOAPMessage("getCurrentEmployee", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param counterpartyGroupID [DEFAULT!!DEFAULT]
   * @param employeeAccessList [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CounterpartyServiceClient.prototype.grantCounterpartyGroupAccess = function(counterpartyGroupID, employeeAccessList, callbackHandler) {
    var parameterNames = ["counterpartyGroupID", "employeeAccessList"];
    var employeeAccessListJSON = JSON.stringify(employeeAccessList);
    var parameterValues = [counterpartyGroupID, employeeAccessListJSON];
    var soapMessage = buildSOAPMessage("grantCounterpartyGroupAccess", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param counterpartyGroupID [DEFAULT!!DEFAULT]
   * @param employeeIDList [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CounterpartyServiceClient.prototype.denyCounterpartyGroupAccess = function(counterpartyGroupID, employeeIDList, callbackHandler) {
    var parameterNames = ["counterpartyGroupID", "employeeIDList"];
    var employeeIDListJSON = JSON.stringify(employeeIDList);
    var parameterValues = [counterpartyGroupID, employeeIDListJSON];
    var soapMessage = buildSOAPMessage("denyCounterpartyGroupAccess", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param group [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CounterpartyServiceClient.prototype.addCounterpartyGroup = function(group, callbackHandler) {
    var parameterNames = ["group"];
    var groupJSON = JSON.stringify(group);
    var parameterValues = [groupJSON];
    var soapMessage = buildSOAPMessage("addCounterpartyGroup", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param group [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CounterpartyServiceClient.prototype.updateCounterpartyGroup = function(group, callbackHandler) {
    var parameterNames = ["group"];
    var groupJSON = JSON.stringify(group);
    var parameterValues = [groupJSON];
    var soapMessage = buildSOAPMessage("updateCounterpartyGroup", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param counterpartyGroupID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CounterpartyServiceClient.prototype.removeCounterpartyGroup = function(counterpartyGroupID, callbackHandler) {
    var parameterNames = ["counterpartyGroupID"];
    var parameterValues = [counterpartyGroupID];
    var soapMessage = buildSOAPMessage("removeCounterpartyGroup", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param counterpartyGroupID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CounterpartyServiceClient.prototype.getCounterpartyGroupByID = function(counterpartyGroupID, callbackHandler) {
    var parameterNames = ["counterpartyGroupID"];
    var parameterValues = [counterpartyGroupID];
    var soapMessage = buildSOAPMessage("getCounterpartyGroupByID", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = true;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!Returns list of counterparty identifiers for which current user has access right!DEFAULT]
   * @param counterpartyIDList [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CounterpartyServiceClient.prototype.checkAccessForCounterpartyList = function(counterpartyIDList, callbackHandler) {
    var parameterNames = ["counterpartyIDList"];
    var counterpartyIDListJSON = JSON.stringify(counterpartyIDList);
    var parameterValues = [counterpartyIDListJSON];
    var soapMessage = buildSOAPMessage("checkAccessForCounterpartyList", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = true;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param counterpartyList [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CounterpartyServiceClient.prototype.addCounterpartyList = function(counterpartyList, callbackHandler) {
    var parameterNames = ["counterpartyList"];
    var counterpartyListJSON = JSON.stringify(counterpartyList);
    var parameterValues = [counterpartyListJSON];
    var soapMessage = buildSOAPMessage("addCounterpartyList", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param mainCounterpartyID [DEFAULT!!DEFAULT]
   * @param secondaryCounterpartyID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CounterpartyServiceClient.prototype.createJointCounterparty = function(mainCounterpartyID, secondaryCounterpartyID, callbackHandler) {
    var parameterNames = ["mainCounterpartyID", "secondaryCounterpartyID"];
    var parameterValues = [mainCounterpartyID, secondaryCounterpartyID];
    var soapMessage = buildSOAPMessage("createJointCounterparty", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param mainCounterpartyID [DEFAULT!!DEFAULT]
   * @param secondaryCounterpartyID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CounterpartyServiceClient.prototype.joinCounterparty = function(mainCounterpartyID, secondaryCounterpartyID, callbackHandler) {
    var parameterNames = ["mainCounterpartyID", "secondaryCounterpartyID"];
    var parameterValues = [mainCounterpartyID, secondaryCounterpartyID];
    var soapMessage = buildSOAPMessage("joinCounterparty", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param mainCounterpartyID [DEFAULT!!DEFAULT]
   * @param secondaryCounterpartyID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CounterpartyServiceClient.prototype.checkJoinIsPossible = function(mainCounterpartyID, secondaryCounterpartyID, callbackHandler) {
    var parameterNames = ["mainCounterpartyID", "secondaryCounterpartyID"];
    var parameterValues = [mainCounterpartyID, secondaryCounterpartyID];
    var soapMessage = buildSOAPMessage("checkJoinIsPossible", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param operationUID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CounterpartyServiceClient.prototype.getCounterpartyJoinStatus = function(operationUID, callbackHandler) {
    var parameterNames = ["operationUID"];
    var parameterValues = [operationUID];
    var soapMessage = buildSOAPMessage("getCounterpartyJoinStatus", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param codeOrTaxCode [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CounterpartyServiceClient.prototype.getCounterpartyByCodeOrTaxCode = function(codeOrTaxCode, callbackHandler) {
    var parameterNames = ["codeOrTaxCode"];
    var parameterValues = [codeOrTaxCode];
    var soapMessage = buildSOAPMessage("getCounterpartyByCodeOrTaxCode", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param counterpartyIDs [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CounterpartyServiceClient.prototype.getCounterpartiesByIDs = function(counterpartyIDs, callbackHandler) {
    var parameterNames = ["counterpartyIDs"];
    var counterpartyIDsJSON = JSON.stringify(counterpartyIDs);
    var parameterValues = [counterpartyIDsJSON];
    var soapMessage = buildSOAPMessage("getCounterpartiesByIDs", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param document [DEFAULT!!DEFAULT]
   * @param counterpartyID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CounterpartyServiceClient.prototype.attachDocumentToCounterparty = function(document, counterpartyID, callbackHandler) {
    var parameterNames = ["document", "counterpartyID"];
    var documentJSON = JSON.stringify(document);
    var parameterValues = [documentJSON, counterpartyID];
    var soapMessage = buildSOAPMessage("attachDocumentToCounterparty", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param documentID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CounterpartyServiceClient.prototype.removeDocumentFromCounterparty = function(documentID, callbackHandler) {
    var parameterNames = ["documentID"];
    var parameterValues = [documentID];
    var soapMessage = buildSOAPMessage("removeDocumentFromCounterparty", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param counterpartyIDs [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CounterpartyServiceClient.prototype.getCounterpartiesBriefByIDs = function(counterpartyIDs, callbackHandler) {
    var parameterNames = ["counterpartyIDs"];
    var counterpartyIDsJSON = JSON.stringify(counterpartyIDs);
    var parameterValues = [counterpartyIDsJSON];
    var soapMessage = buildSOAPMessage("getCounterpartiesBriefByIDs", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param filter [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CounterpartyServiceClient.prototype.getCounterpartyBriefListByFilter = function(filter, callbackHandler) {
    var parameterNames = ["filter"];
    var filterJSON = JSON.stringify(filter);
    var parameterValues = [filterJSON];
    var soapMessage = buildSOAPMessage("getCounterpartyBriefListByFilter", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CounterpartyServiceClient.prototype.getStandardCompanyIndicators = function(callbackHandler) {
    var parameterNames = [];
    var parameterValues = [];
    var soapMessage = buildSOAPMessage("getStandardCompanyIndicators", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = true;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param counterpartyID [DEFAULT!!DEFAULT]
   * @param indicatorID [DEFAULT!!DEFAULT]
   * @param startDate [DEFAULT!!DEFAULT]
   * @param endDate [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CounterpartyServiceClient.prototype.getCounterpartyIndicatorValues = function(counterpartyID, indicatorID, startDate, endDate, callbackHandler) {
    var parameterNames = ["counterpartyID", "indicatorID", "startDate", "endDate"];
    var parameterValues = [counterpartyID, indicatorID, startDate, endDate];
    var soapMessage = buildSOAPMessage("getCounterpartyIndicatorValues", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = true;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param indicatorValue [DEFAULT!!DEFAULT]
   * @param counterpartyID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CounterpartyServiceClient.prototype.addCounterpartyIndicatorValue = function(indicatorValue, counterpartyID, callbackHandler) {
    var parameterNames = ["indicatorValue", "counterpartyID"];
    var indicatorValueJSON = JSON.stringify(indicatorValue);
    var parameterValues = [indicatorValueJSON, counterpartyID];
    var soapMessage = buildSOAPMessage("addCounterpartyIndicatorValue", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param indicatorValue [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CounterpartyServiceClient.prototype.updateCounterpartyIndicatorValue = function(indicatorValue, callbackHandler) {
    var parameterNames = ["indicatorValue"];
    var indicatorValueJSON = JSON.stringify(indicatorValue);
    var parameterValues = [indicatorValueJSON];
    var soapMessage = buildSOAPMessage("updateCounterpartyIndicatorValue", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param iD [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CounterpartyServiceClient.prototype.removeCounterpartyIndicatorValue = function(iD, callbackHandler) {
    var parameterNames = ["iD"];
    var parameterValues = [iD];
    var soapMessage = buildSOAPMessage("removeCounterpartyIndicatorValue", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param indicatorID [DEFAULT!!DEFAULT]
   * @param valueDate [DEFAULT!!DEFAULT]
   * @param counterpartyID [DEFAULT!!DEFAULT]
   * @param currencyID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CounterpartyServiceClient.prototype.getCounterpartyIndicatorClosestValue = function(indicatorID, valueDate, counterpartyID, currencyID, callbackHandler) {
    var parameterNames = ["indicatorID", "valueDate", "counterpartyID", "currencyID"];
    var parameterValues = [indicatorID, valueDate, counterpartyID, currencyID];
    var soapMessage = buildSOAPMessage("getCounterpartyIndicatorClosestValue", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = true;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CounterpartyServiceClient.prototype.getCounterpartyGroupList = function(callbackHandler) {
    var parameterNames = [];
    var parameterValues = [];
    var soapMessage = buildSOAPMessage("getCounterpartyGroupList", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param responsibleEmployee [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CounterpartyServiceClient.prototype.addCounterpartyResponsibleEmployee = function(responsibleEmployee, callbackHandler) {
    var parameterNames = ["responsibleEmployee"];
    var responsibleEmployeeJSON = JSON.stringify(responsibleEmployee);
    var parameterValues = [responsibleEmployeeJSON];
    var soapMessage = buildSOAPMessage("addCounterpartyResponsibleEmployee", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param counterpartyID [DEFAULT!!DEFAULT]
   * @param employeeEmail [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CounterpartyServiceClient.prototype.getCounterpartyResponsibleEmployee = function(counterpartyID, employeeEmail, callbackHandler) {
    var parameterNames = ["counterpartyID", "employeeEmail"];
    var parameterValues = [counterpartyID, employeeEmail];
    var soapMessage = buildSOAPMessage("getCounterpartyResponsibleEmployee", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CounterpartyServiceClient.prototype.getLegalEntityTaxModes = function(callbackHandler) {
    var parameterNames = [];
    var parameterValues = [];
    var soapMessage = buildSOAPMessage("getLegalEntityTaxModes", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param tenantEntityIDsList [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CounterpartyServiceClient.prototype.checkTenantEntityCanBeRemoved = function(tenantEntityIDsList, callbackHandler) {
    var parameterNames = ["tenantEntityIDsList"];
    var tenantEntityIDsListJSON = JSON.stringify(tenantEntityIDsList);
    var parameterValues = [tenantEntityIDsListJSON];
    var soapMessage = buildSOAPMessage("checkTenantEntityCanBeRemoved", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param contact [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CounterpartyServiceClient.prototype.addCounterpartyContact = function(contact, callbackHandler) {
    var parameterNames = ["contact"];
    var contactJSON = JSON.stringify(contact);
    var parameterValues = [contactJSON];
    var soapMessage = buildSOAPMessage("addCounterpartyContact", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param contact [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CounterpartyServiceClient.prototype.updateCounterpartyContact = function(contact, callbackHandler) {
    var parameterNames = ["contact"];
    var contactJSON = JSON.stringify(contact);
    var parameterValues = [contactJSON];
    var soapMessage = buildSOAPMessage("updateCounterpartyContact", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param contactID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CounterpartyServiceClient.prototype.removeCounterpartyContact = function(contactID, callbackHandler) {
    var parameterNames = ["contactID"];
    var parameterValues = [contactID];
    var soapMessage = buildSOAPMessage("removeCounterpartyContact", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param counterpartyID [DEFAULT!необязательный параметр. если нет - вернуть все контакты арендатора!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CounterpartyServiceClient.prototype.getCounterpartyContacts = function(counterpartyID, callbackHandler) {
    var parameterNames = ["counterpartyID"];
    var parameterValues = [counterpartyID];
    var soapMessage = buildSOAPMessage("getCounterpartyContacts", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  
  /***
   * Package security rights constants
   */
  
  w.CounterpartySecurityRights = {
  
      COUNTERPARTY_CONFIDENTIAL_DATA_ADMINISTRATION:new SecurityRightCheckHandle('1ded85f7-6593-4be3-881b-ba83b71542f7'),
      COUNTERPARTY_INPUT_BUDGET_RIGHT:new SecurityRightCheckHandle('73ac747f-3ceb-4485-b6de-d164486910dd'),
      COUNTERPARTY_INPUT_REFERENCE_DATA_RIGHT:new SecurityRightCheckHandle('d908fa06-6a49-457f-953e-4a894db6f0b4'),
      COUNTERPARTY_JOIN_RIGHT:new SecurityRightCheckHandle('3f8d213b-a963-4758-a28c-3913e4c3fcee'),
      COUNTERPARTY_VIEW_RIGHT:new SecurityRightCheckHandle('4e9fd6b4-febf-48bd-b0a5-ed828d6bcde0'),
      EMPLOYEE_REFERENCE_DATA_INPUT_RIGHT:new SecurityRightCheckHandle('4bf63abf-2335-4e9a-97a1-4a93a2ab4912'),
      X_CLIENTS__CHANGE_CLIENTS:new SecurityRightCheckHandle('1689cb5c-3051-4abe-8b71-f067470eaf3b'),
      X_CLIENTS__VIEW_CLIENTS:new SecurityRightCheckHandle('1b06e053-ee3d-41ea-ac08-9589a2f9da09'),
      X_COUNTERPARTIES__CHANGE_COUNTERPARTIES:new SecurityRightCheckHandle('a5bcdda0-5f79-4969-8032-497b9e5a8255'),
      X_COUNTERPARTIES__VIEW_COUNTERPARTIES:new SecurityRightCheckHandle('4041d708-c808-4bac-a57f-73138daa3db4'),
      X_EMPLOYEES__CHANGE_EMPLOYEES:new SecurityRightCheckHandle('4ccc621a-7e1f-4ecf-870b-b01a4c5fb026'),
      X_EMPLOYEES__VIEW_EMPLOYEES:new SecurityRightCheckHandle('f98090dd-4967-457c-b647-cf2dacc36e64'),
      X_TENANT_LEGAL_ENTITIES__CHANGE_TENANT_LEGAL_ENTITIES:new SecurityRightCheckHandle('3a9f58c1-3005-4ae5-9e2d-cb27b2ce5f43'),
      X_TENANT_LEGAL_ENTITIES__VIEW_TENANT_LEGAL_ENTITIES:new SecurityRightCheckHandle('c88fd380-d100-466f-86ef-309d7710e564')
  };
  })(typeof exports !== 'undefined' ? exports : window);
  