import React from 'react';

import { GoalStep } from '@core/hooks/use-goals';
import { Checkbox } from '@ui/checkbox';
import { RaisedButton } from '@ui/raised-button';
import { ButtonLayout, CheckboxLayout, Root, actionButtonStyle, buttonStyle, highlightStyle } from './styled';

interface IProps {
	actionLabel?: string;
	canSubmit?: boolean;
	checkboxLabel?: string;
	checked?: boolean;
	closeLabel?: string;
	fullMode?: boolean;
	goalStepOnAction?: GoalStep;
	goalStepOnClose?: GoalStep;
	isCheckboxDisabled?: boolean;
	isHighlight?: boolean;
	onAction?: (ev) => void;
	onCheck?: (value: boolean) => void;
	onClose?: (ev) => void;
	padding?: string;
}

const DrawerFooterControls: React.FC<IProps> = props => {
	const {
		actionLabel,
		canSubmit,
		checkboxLabel,
		checked,
		closeLabel,
		fullMode,
		goalStepOnAction,
		goalStepOnClose,
		isCheckboxDisabled,
		isHighlight,
		onAction,
		onCheck,
		onClose,
		padding,
	} = props;

	if (fullMode) {
		return (
			<Root padding={padding} fullMode={fullMode}>
				{onClose && (
					<ButtonLayout>
						<RaisedButton appearance='text' goalStep={goalStepOnClose} onClick={onClose} size='large'>
							{closeLabel || 'Отмена'}
						</RaisedButton>
					</ButtonLayout>
				)}
				{actionLabel && (
					<ButtonLayout>
						<RaisedButton
							color='primary'
							disabled={!canSubmit}
							goalStep={goalStepOnAction}
							onClick={onAction}
							size='large'
							style={actionButtonStyle}>
							{actionLabel}
						</RaisedButton>
					</ButtonLayout>
				)}
			</Root>
		);
	}

	return (
		<Root padding={padding} fullMode={fullMode}>
			{checkboxLabel && (
				<CheckboxLayout>
					<Checkbox
						labelStyle={isHighlight && highlightStyle}
						label={checkboxLabel}
						checked={checked}
						disabled={isCheckboxDisabled}
						onCheck={() => onCheck(!checked)}
					/>
				</CheckboxLayout>
			)}
			{actionLabel && (
				<ButtonLayout>
					<RaisedButton
						disabled={!canSubmit}
						goalStep={goalStepOnAction}
						label={actionLabel}
						onClick={onAction}
						style={buttonStyle}
						type={'primary'}
					/>
				</ButtonLayout>
			)}
			{onClose && (
				<ButtonLayout>
					<RaisedButton
						goalStep={goalStepOnClose}
						label={closeLabel || 'Отмена'}
						onClick={onClose}
						style={buttonStyle}
					/>
				</ButtonLayout>
			)}
		</Root>
	);
};

DrawerFooterControls.defaultProps = {
	canSubmit: true,
};

export default DrawerFooterControls;
