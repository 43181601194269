import React, { memo } from 'react';

import { useMapState, useMapDispatch, useAutoFetch } from '@flux';
import { withFormAutopicker } from '@ui/autopicker';
import { mainFundsRegistersSelectorsPack } from '@funds-registers/selectors';
import { mainFundsRegistersActionsPack } from '@funds-registers/actions';
import {
	FundsRegisterPurposeAutopicker as XFundsRegisterPurposeAutopicker,
	FundsRegisterPurposeAutopickerProps as XFundsRegisterPurposeAutopickerProps,
} from './funds-register-purpose-autopicker.view';

export type FundsRegisterPurposeAutopickerProps = {} & Omit<
	XFundsRegisterPurposeAutopickerProps,
	'dataSource' | 'isFetching' | 'isLoaded'
>;

const FundsRegisterPurposeAutopicker: React.FC<FundsRegisterPurposeAutopickerProps> = memo(props => {
	const [isFetching, isLoaded, dataSource] = useMapState([
		mainFundsRegistersSelectorsPack.selectAsyncFundsRegisterPurposes.selectIsFetching,
		mainFundsRegistersSelectorsPack.selectAsyncFundsRegisterPurposes.selectIsLoaded,
		mainFundsRegistersSelectorsPack.selectAsyncFundsRegisterPurposes.selectItem,
	]);
	const [fetchFundsRegisterPurposes] = useMapDispatch([mainFundsRegistersActionsPack.fetchFundsRegisterPurposes]);

	useAutoFetch({
		selector: mainFundsRegistersSelectorsPack.selectAsyncFundsRegisterPurposes.selectDidInvalidate,
		fetch: () => fetchFundsRegisterPurposes(),
	});

	return (
		<XFundsRegisterPurposeAutopicker {...props} isFetching={isFetching} isLoaded={isLoaded} dataSource={dataSource} />
	);
});

const FormFundsRegisterPurposeAutopicker = withFormAutopicker<FundsRegisterPurposeAutopickerProps, unknown>(
	FundsRegisterPurposeAutopicker,
);

const fundsRegisterPurposeAutopickerTransformer = {
	fundsRegisterPurpose: {
		single: {
			transformInput: ({ input }: { input: FundsRegisterPurpose }) => (input ? { [input.ID]: true } : null),
			transformOutput: ({ items }) => (items[0] as FundsRegisterPurpose) || null,
		},
	},
};

export {
	FundsRegisterPurposeAutopicker,
	FormFundsRegisterPurposeAutopicker,
	fundsRegisterPurposeAutopickerTransformer,
};
