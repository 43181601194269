import React from 'react';

import { IconBase, IconBaseProps } from './base';

export type UnitBudgetingIconProps = IconBaseProps;

const UnitBudgetingIcon: React.FC<UnitBudgetingIconProps> = props => {
	return (
		<IconBase {...props}>
			<svg
				stroke='currentColor'
				fill='currentColor'
				strokeWidth='0'
				viewBox='0 0 1024 1024'
				xmlns='http://www.w3.org/2000/svg'>
				<path d='M928 160H96c-17.7 0-32 14.3-32 32v640c0 17.7 14.3 32 32 32h832c17.7 0 32-14.3 32-32V192c0-17.7-14.3-32-32-32zm-40 208H676V232h212v136zm0 224H676V432h212v160zM412 432h200v160H412V432zm200-64H412V232h200v136zm-476 64h212v160H136V432zm0-200h212v136H136V232zm0 424h212v136H136V656zm276 0h200v136H412V656zm476 136H676V656h212v136z'></path>
			</svg>
		</IconBase>
	);
};

export { UnitBudgetingIcon };
