import React from 'react';
import { ReactNode } from 'react';

import { Box } from '@ui/box';
import { withTheme, WithThemeProps } from '@theme';

type Config = {
	eventName: string;
	timeout: number;
};

export type ConfirmEnhancerProps = {
	renderConfirmContent?: (color?: string) => ReactNode;
} & WithThemeProps;

type State = {
	isRequested: boolean;
};

function withConfirm<P = any>(config?: Config) {
	const { eventName = 'onClick', timeout = 2000 } = config || {};

	return (WrappedComponent: React.ComponentType<P>) => {
		class Enhancer extends React.Component<P & ConfirmEnhancerProps> {
			static displayName = `withConfirm(${WrappedComponent.name || WrappedComponent.displayName})`;
			static defaultProps = {
				renderConfirmContent: (color: string) => (
					<Box
						component='span'
						display='inline-flex'
						justifyContent='center'
						alignItems='center'
						fontSize='0.8rem'
						color={color}>
						OK
					</Box>
				),
			};
			state: State = {
				isRequested: false,
			};
			isMountedComponent = false;

			componentDidMount() {
				this.isMountedComponent = true;
			}

			componentWillUnmount() {
				this.isMountedComponent = false;
			}

			handleRequest = (e: React.SyntheticEvent<{}>) => {
				const { isRequested } = this.state;
				if (!this.isMountedComponent) return;

				if (!isRequested) {
					this.setState({ isRequested: true });
				} else {
					this.props[eventName](e);
				}

				setTimeout(() => {
					this.isMountedComponent && this.setState({ isRequested: false });
				}, timeout);
			};

			render() {
				const { theme } = this.props;
				const { isRequested } = this.state;
				const props = {
					...this.props,
					[eventName]: this.handleRequest,
				};
				const { renderConfirmContent, children, ...rest } = props;

				return (
					<WrappedComponent {...(rest as any)}>
						{isRequested ? renderConfirmContent(theme.palette.alarm) : children}
					</WrappedComponent>
				);
			}
		}

		return withTheme(Enhancer as any) as unknown as React.ComponentType<P & ConfirmEnhancerProps>;
	};
}

export default withConfirm;
