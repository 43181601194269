import { Store } from 'redux';

import { IAppState } from '@store';
import { isReceiveAction } from '@flux';
import { types as mainDocumentsActionTypes } from '@documents/actions/types';
import { types as clientTypes } from '@clients/actions/types';
import { types as counterpartiesTypes } from '@counterparties/actions/types';
import { types as employeesTypes } from '@employees/actions/types';
import { invalidateInvoices } from '@invoices/actions/invalidators';

function askInvoicesInvalidator(store: Store<IAppState>, action: StoreAction) {
	const { dispatch } = store;
	const syncMap = {
		[counterpartiesTypes.RUN_COUNTERPARTIES_INVALIDATION_EFFECT]: true,
	};
	const asyncMap = {
		[mainDocumentsActionTypes.ADD_DOCUMENT]: true,
		[mainDocumentsActionTypes.UPDATE_DOCUMENT]: true,
		[mainDocumentsActionTypes.REMOVE_DOCUMENT]: true,
		[clientTypes.UPDATE_CLIENT]: true,
		[clientTypes.REMOVE_CLIENT]: true,
		[counterpartiesTypes.UPDATE_COUNTERPARTY]: true,
		[counterpartiesTypes.REMOVE_COUNTERPARTY]: true,
		[employeesTypes.UPDATE_EMPLOYEE]: true,
		[employeesTypes.REMOVE_EMPLOYEE]: true,
	};

	if (syncMap[action.type]) {
		dispatch(invalidateInvoices());
	} else if (asyncMap[action.type] && isReceiveAction(action)) {
		dispatch(invalidateInvoices());
	}
}

function createInvalidatorEffect() {
	return (store: Store<IAppState>) => (next: (action: StoreAction) => void) => (action: StoreAction) => {
		next(action);
		askInvoicesInvalidator(store, action);
	};
}

const runInvalidatorEffect = createInvalidatorEffect();

export { runInvalidatorEffect };
