import { checkAsyncAction, createAsyncInitialState, createReducer, invalidateStateFromAction } from '@flux';
import { types } from '@marketplaces/actions/types';

export type MainMarketplacesState = {
	operationTypes: StoreAsyncItem<Array<MarketplaceOperationType>>;
	rules: StoreAsyncItem<Array<MarketplaceOperationRule>>;
};

const initialState: MainMarketplacesState = {
	operationTypes: createAsyncInitialState([]),
	rules: createAsyncInitialState([]),
};

const mainMarketplacesReducer = createReducer<MainMarketplacesState>(initialState, {
	[types.ADD_RULE]: (action: StaticAction, state) => {
		return {
			rules: invalidateStateFromAction(action, state.rules),
		};
	},
	[types.FETCH_OPERATION_TYPES]: (action: AsyncAction<Array<MarketplaceOperationType>>, state) => {
		return {
			operationTypes: checkAsyncAction(action, state.operationTypes),
		};
	},
	[types.FETCH_RULES_TYPES]: (action: AsyncAction<Array<MarketplaceOperationRule>>, state) => {
		return {
			rules: checkAsyncAction(action, state.rules),
		};
	},
});

export default mainMarketplacesReducer;
