import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { getDrawer } from '@utils';
import { mainTenantProfileActionsPack } from '@platform/actions/main-tenant-profile.actions';
import XRemoveStandardDataForm from './remove-standard-data.view';

type RemoveStandardDataFormProps = {
	removeDemoData?: () => void;
};

class RemoveStandardDataForm extends React.PureComponent<RemoveStandardDataFormProps> {
	static displayName = 'RemoveStandardDataForm[Container]';

	handleRemoveData = () => {
		this.props.removeDemoData();
		this.handleClose();
	};

	handleClose = () => getDrawer().close();

	render() {
		const { removeDemoData, ...rest } = this.props;

		return <XRemoveStandardDataForm {...rest} onRemove={this.handleRemoveData} onClose={this.handleClose} />;
	}
}

function mapDispatchToProps(dispatch: Dispatch<any>) {
	return bindActionCreators(
		{
			removeDemoData: mainTenantProfileActionsPack.actions.removeDemoData,
		},
		dispatch,
	);
}

const RemoveDemoDataFormContainer = connect<any, any, RemoveStandardDataFormProps>(
	null,
	mapDispatchToProps,
)(RemoveStandardDataForm);

function openRemoveStandardDataFormDrawer() {
	getDrawer().open({
		title: 'Подтвердите удаление демо-данных',
		footer: null,
		overlay: true,
		content: <RemoveDemoDataFormContainer />,
	});
}

export { openRemoveStandardDataFormDrawer };
export default RemoveDemoDataFormContainer;
