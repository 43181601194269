import React from 'react';

import { getGlobalConfig } from '../../../../../../../config';
import { getDrawer } from '../../../../../../../core/libs/utils';
import { DrawerRightControls } from '../../../../../../../core/ui/drawer';
import {
	Form,
	FormInputField,
	FormGroup,
	FormGroupTitle,
	FormGroupHorizontalLayout,
	FormMemberWidth,
} from '../../../../../../../core/ui/forms';
import { RadioButtonGroup } from '@ui/radio-button';
import { Alert } from '@ui/alert';
import { pathTo } from '../../billing-display-controller/paths';
import { BillingProvider } from '@platform/model/billing';
import {
	Root,
	HeaderLayout,
	ContentLayout,
	RightContentLayout,
	RadioLayout,
	radioButtonGroupStyle,
	radioButtonStyle,
	IndividualContentLayout,
	Sign,
	LogotypesLayout,
	Logo,
	LegalEntityContentLayout,
	InfoLayout,
	FormLayout,
	RequisitesInfoLayout,
} from './styled';

const isSberbank = getGlobalConfig().isSberbank;
const payerTypeSource = [
	{
		ID: 0,
		Name: 'Юридическое лицо',
	},
	{
		ID: 1,
		Name: 'Физическое лицо',
	},
];

interface IProps {
	tenant: Tenant;
	tenantIsFetching: boolean;
	tenantBills: Record<string, TenantBill>;

	setPath: (path: string) => void;
	resetPath: () => void;
	updateTenant: (tenant: Tenant) => void;
	createPaymentServiceRequest: (billID: number, paymentServiceCode: string) => void;
}

interface IState {
	selectedTypePayer: number;
}

class SelectTypePayerDisplay extends React.PureComponent<IProps, IState> {
	static displayName = 'SelectTypePayerDisplay';

	state: IState = {
		selectedTypePayer: 0,
	};

	_formRef: Form<Tenant> = null;

	componentDidMount() {
		this.setDrawerRightContent();
		this.setDrawerOnClose();
	}

	setDrawerRightContent = () => {
		getDrawer().setContent({
			rightContent: this.renderRightContent(),
		});
	};

	setDrawerOnClose = () => {
		const { resetPath } = this.props;

		getDrawer().setOnClose(() => {
			resetPath();
		});
	};

	setFormRef = (formRef: Form<Tenant>) => {
		this._formRef = formRef;
	};

	initSubmitForm = () => {
		this._formRef && this._formRef.submitForm();
	};

	initPaymentRequestForPerson = () => {
		const { tenantBills, createPaymentServiceRequest } = this.props;

		if (Object.keys(tenantBills).length > 0) {
			const tenantBillsList = Object.keys(tenantBills).map(key => tenantBills[key]);

			createPaymentServiceRequest(tenantBillsList[0].ID, BillingProvider.YANDEX_KASSA);
		}
	};

	handleNextStep = () => {
		const { selectedTypePayer } = this.state;

		if (selectedTypePayer === 0) {
			this.initSubmitForm();
		}

		if (selectedTypePayer === 1) {
			this.initPaymentRequestForPerson();
		}
	};

	handlePrevStep = () => {
		const { setPath } = this.props;

		setPath(pathTo.selectConditions);
	};

	handleSelectTypePayer = (value: number) => {
		this.setState(
			{
				selectedTypePayer: value,
			},
			this.setDrawerRightContent,
		);
	};

	handleSubmitForm = (formObject: Tenant) => {
		const { updateTenant, setPath } = this.props;
		const editedBriefObject = this._formRef.getEditedBriefObjects();

		if (editedBriefObject && editedBriefObject.LegalData) {
			formObject = {
				...formObject,
				LegalData: {
					...formObject.LegalData,
					...editedBriefObject.LegalData,
				},
			};

			updateTenant(formObject);
		}

		setPath(pathTo.legalEntityPaymentResult);
	};

	renderRightContent = () => {
		const { selectedTypePayer } = this.state;

		return (
			<RightContentLayout>
				<DrawerRightControls
					actionLabel={'Далее'}
					onAction={this.handleNextStep}
					closeLabel={'Назад'}
					onClose={this.handlePrevStep}
				/>
				{selectedTypePayer === 1 && (
					<Alert appearance='warning'>
						После нажатия кнопки «Далее» вы будете переадресованы на страницу платёжного сервиса ЮKassа, где вам
						необходимо будет ввести параметры вашей карты, с которой совершается платёж.
					</Alert>
				)}
			</RightContentLayout>
		);
	};

	renderContentForLegalEntity = () => {
		const { tenant, tenantIsFetching } = this.props;

		return (
			<LegalEntityContentLayout>
				{!isSberbank && (
					<InfoLayout>
						<Sign>
							При выборе типа плательщика «Физическое лицо» оплата производится по карте или любыми другими простыми
							средствами. При выборе «Юридическое лицо» оплата производится по счёту в интернет-банке.
						</Sign>
					</InfoLayout>
				)}
				<FormLayout>
					<Form
						ref={this.setFormRef}
						formObject={tenant}
						isFetching={tenantIsFetching}
						onSubmit={this.handleSubmitForm}>
						<FormGroup>
							<FormGroupTitle>Реквизиты плательщика</FormGroupTitle>
							<RequisitesInfoLayout>
								<Sign>Для оплаты счёта должны быть заполнены все поля</Sign>
							</RequisitesInfoLayout>
							<FormInputField
								name={'LegalName'}
								briefObjectName={'LegalData'}
								labelText={'Наименование юридического лица'}
								hintText={'Например, ООО «Ромашка»'}
								fullWidth
								required
							/>
							<FormInputField
								name={'LegalAddress'}
								briefObjectName={'LegalData'}
								labelText={'Юридический адрес'}
								hintText={'Введите юридический адрес'}
								fullWidth
								required
							/>
							<FormGroupHorizontalLayout>
								<FormMemberWidth width={'33.3333%'}>
									<FormInputField
										name={'TaxReferenceNumber'}
										briefObjectName={'LegalData'}
										labelText={'ИНН'}
										hintText={'7720XXXXX1'}
										fullWidth
										required
									/>
								</FormMemberWidth>
								<FormMemberWidth width={'33.3333%'}>
									<FormInputField
										name={'StateRegNumber'}
										briefObjectName={'LegalData'}
										labelText={'ОГРН'}
										hintText={'111774635XXX8'}
										fullWidth
										required
									/>
								</FormMemberWidth>
								<FormMemberWidth width={'33.3333%'}>
									<FormInputField
										name={'TaxRegReasonCode'}
										briefObjectName={'LegalData'}
										labelText={'КПП'}
										hintText={'7720XXXXX1'}
										fullWidth
									/>
								</FormMemberWidth>
							</FormGroupHorizontalLayout>
							<FormInputField
								name={'SettlementAccountNumber'}
								briefObjectName={'LegalData'}
								labelText={'Номер расчётного счёта'}
								hintText={'40702810XXXXXXXX1234'}
								fullWidth
								required
							/>
							<FormInputField
								name={'BankName'}
								briefObjectName={'LegalData'}
								labelText={'В банке'}
								hintText={'Например, ОАО «Банк»'}
								fullWidth
								required
							/>
							<FormInputField
								name={'BankBIC'}
								briefObjectName={'LegalData'}
								labelText={'БИК'}
								hintText={'0445XXX93'}
								fullWidth
								required
							/>
							<FormInputField
								name={'BankCorrAccNumber'}
								briefObjectName={'LegalData'}
								labelText={'Номер корр. счёта'}
								hintText={'30101810XXXXX0000123'}
								fullWidth
								required
							/>
						</FormGroup>
					</Form>
				</FormLayout>
			</LegalEntityContentLayout>
		);
	};

	renderContentForPerson = () => {
		return (
			<IndividualContentLayout>
				<Sign>Вы можете оплатить картой или любым другим способом.</Sign>
				<LogotypesLayout>
					<Logo type={'APPLE_PAY'} />
					<Logo type={'VISA'} />
					<Logo type={'MASTER_CARD'} />
					<Logo type={'MIR'} />
				</LogotypesLayout>
			</IndividualContentLayout>
		);
	};

	render() {
		const { selectedTypePayer } = this.state;

		return (
			<Root>
				{!isSberbank && (
					<HeaderLayout>
						<FormGroup>
							<FormGroupTitle>Тип плательщика</FormGroupTitle>
							<RadioLayout>
								<RadioButtonGroup
									dataSource={payerTypeSource}
									value={selectedTypePayer}
									name={'typePayer'}
									style={radioButtonGroupStyle}
									radioButtonStyle={radioButtonStyle}
									onChange={this.handleSelectTypePayer}
								/>
							</RadioLayout>
						</FormGroup>
					</HeaderLayout>
				)}
				<ContentLayout>
					{selectedTypePayer === 0 && this.renderContentForLegalEntity()}
					{selectedTypePayer === 1 && this.renderContentForPerson()}
				</ContentLayout>
			</Root>
		);
	}
}

export default SelectTypePayerDisplay;
