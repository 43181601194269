import styled from 'styled-components';

const Root = styled.div`
	position: relative;
	width: 100%;
	height: 40px;
	margin-top: -8px;
`;

const Internal = styled.div`
	position: absolute;
	width: 100%;
`;

export { Root, Internal };