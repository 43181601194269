import { combineReducers } from 'redux';

import { mainMeasureUnitsReducer, MainMeasureUnitsState } from './main.reducer';

export type MeasureUnitsState = {
	main: MainMeasureUnitsState;
};

const measureUnitsReducer = combineReducers<MeasureUnitsState>({
	main: mainMeasureUnitsReducer,
});

export { measureUnitsReducer };
