import React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { IAppState } from '../../../../../../core/store';
import {
	setBillingDisplayPathAction,
	resetBillingDisplayPathAction,
} from '../../../../actions/display-controller.actions';
import { selectBillingDisplayPath } from '../../../../selectors/display-controller.selectors';
import XBillingDisplayController from './billing-display-controller.view';

interface IProps {
	path?: string;

	setPath?: (path: string) => void;
	resetPath?: () => void;
}

class BillingDisplayController extends React.PureComponent<IProps, any> {
	static displayName = 'BillingDisplayController[Container]';

	render() {
		const {
			path,

			setPath,
			resetPath,
		} = this.props;

		return <XBillingDisplayController path={path} setPath={setPath} resetPath={resetPath} />;
	}
}

function mapStateToProps(state: IAppState) {
	return {
		path: selectBillingDisplayPath(state),
	};
}

function mapDispatchToProps(dispatch: Dispatch<any>) {
	return bindActionCreators(
		{
			setPath: setBillingDisplayPathAction,
			resetPath: resetBillingDisplayPathAction,
		},
		dispatch,
	);
}

export default connect<any, any, IProps>(mapStateToProps, mapDispatchToProps)(BillingDisplayController);
