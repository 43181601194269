import { combineReducers } from 'redux';

import mainFundsRegistersReducer, { MainFundsRegistersState } from './main.reducers';


export type FundsRegistersState = {
	main: MainFundsRegistersState;
};

const fundsRegistersReducer = combineReducers<FundsRegistersState>({
	main: mainFundsRegistersReducer,
});

export default fundsRegistersReducer;
