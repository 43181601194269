import { createNotificationMessage } from '@utils/notifications';
import { createStaticAction, createAsyncAction, RECEIVE } from '@flux';
import { PaymentsDirection } from '@payments/models';
import { selectAsyncFundsRegisterStatistics, selectAsyncCashflowOperations } from '@payments/selectors';
import { types, FetchDynamicDataReturnType } from './types';

const actions = {
	fetchDynamicData: createAsyncAction(
		types.FETCH_DYNAMIC_DATA,
		(api, _, __, options: FetchDynamicDataOptions) => {
			const { dateRange, fundsRegisterID } = options;

			return new Promise<FetchDynamicDataReturnType>(resolve => {
				if (!fundsRegisterID) {
					resolve({ cashflowOperations: [], balance: [] });
				} else {
					Promise.all([
						api.paymentsPack.payments.fetchCashflowOperationsBriefs({ dateRange, fundsRegisterID }),
						api.fundsPack.fundsRegister.fetchFundsRegisterBalance({ dateRange, fundsRegisterID }),
					]).then(result =>
						resolve({
							cashflowOperations: result[0],
							balance: result[1],
						}),
					);
				}
			});
		},
		{
			isValidSelector: selectAsyncCashflowOperations.selectIsValid,
			isFetchingSelector: selectAsyncCashflowOperations.selectIsFetching,
		},
	) as (options: FetchDynamicDataOptions) => void,
	invalidatePayments: () => createStaticAction(types.INVALIDATE_PAYMENTS)(),
	addPayment: createAsyncAction(
		types.ADD_PAYMENT,
		(api, _, __, payment: Payment) => {
			return new Promise<Payment>(resolve => {
				api.paymentsPack.payments.addPayment(payment).then(result => {
					resolve(result);
				});
			});
		},
		{
			showMessage: type => type === RECEIVE && createNotificationMessage('Платёж добавлен 😊', 'success'),
		},
	) as (payment: Payment) => void,
	updatePayment: createAsyncAction(
		types.UPDATE_PAYMENT,
		(api, _, __, payment: Payment) => {
			return new Promise<Payment>(resolve => {
				api.paymentsPack.payments.updatePayment(payment).then(result => {
					resolve(result);
				});
			});
		},
		{
			showMessage: type => type === RECEIVE && createNotificationMessage('Платёж изменён 😊', 'success'),
		},
	) as (payment: Payment) => void,
	removePayment: createAsyncAction(
		types.REMOVE_PAYMENT,
		(api, _, __, paymentID: number) => {
			return new Promise<boolean>(resolve => {
				api.paymentsPack.payments.removePayment(paymentID).then(result => {
					resolve(result);
				});
			});
		},
		{
			showMessage: type => type === RECEIVE && createNotificationMessage('Платёж удалён 😲', 'success'),
		},
	) as (paymentID: number) => void,
	markAsTransfer: createAsyncAction(
		types.MARK_AS_TRANSFER,
		(api, _, __, payment: Payment, corrFundsRegisterID: number) =>
			api.paymentsPack.payments.markAsTransfer(payment, corrFundsRegisterID),
		{
			showMessage: type => type === RECEIVE && createNotificationMessage('Операция отмечена как перевод 😊', 'success'),
		},
	) as (payment: Payment, corrFundsRegisterID: number) => void,
	unmarkTransfer: createAsyncAction(
		types.UNMARK_TRANSFER,
		(api, _, __, paymentID: number) => api.paymentsPack.payments.unmarkTransfer(paymentID),
		{
			showMessage: type => type === RECEIVE && createNotificationMessage('Отметка о переводе снята 😊', 'success'),
		},
	) as (paymentID: number) => void,
	mergePaymentsToTransfer: createAsyncAction(
		types.MERGE_PAYMENTS_TO_TRANSFER,
		(api, _, __, primaryID: number, secondaryID: number) =>
			api.paymentsPack.payments.mergePaymentsToTransfer(primaryID, secondaryID),
		{
			showMessage: type => type === RECEIVE && createNotificationMessage('Платежи объединены в перевод 😊', 'success'),
		},
	) as (primaryID: number, secondaryID: number) => void,
	setTextFilter: (value: string) => createStaticAction(types.SET_TEXT_FILTER)(value),
	setDirectionFilter: (value: PaymentsDirection) => createStaticAction(types.SET_DIRECTION_FILTER)(value),
	setFundsRegisterID: (value: number) => createStaticAction(types.SET_FUNDS_REGISTERS_ID)(value),
	setDateRange: (value: DateRange) => createStaticAction(types.SET_DATE_RANGE)(value),
	fetchFundsRegisterStatistics: createAsyncAction(
		types.FETCH_FUNDS_REGISTER_STATISTICS,
		(api, _, __, dateRange: DateRange) => {
			return new Promise<Array<FundsRegisterStatistics>>(resolve => {
				api.fundsPack.fundsRegister.fetchFundsRegisterStatistics(dateRange).then(result => {
					resolve(result);
				});
			});
		},
		{
			isValidSelector: selectAsyncFundsRegisterStatistics.selectIsValid,
			isFetchingSelector: selectAsyncFundsRegisterStatistics.selectIsFetching,
		},
	) as (dateRange: DateRange) => void,
};

type FetchDynamicDataOptions = {
	dateRange: DateRange;
	fundsRegisterID: number;
};

export const mainPaymentsActionsPack = actions;
