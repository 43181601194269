import React from 'react';

import {
	DarkModeSwitch as DarkModeSwitchBase,
	DarkModeSwitchProps as DarkModeSwitchBaseProps,
} from '@ui/dark-mode-switch';

type DarkModeSwitchProps = DarkModeSwitchBaseProps;

const DarkModeSwitch: React.FC<DarkModeSwitchProps> = props => {
	return <DarkModeSwitchBase {...props} />;
};

export { DarkModeSwitch };
