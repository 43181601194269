import * as _ from 'underscore';

import * as paymentspackage from 'paymentspackage-api';
import { coreApi } from '@core/api/core';
import { setRequestTimeout } from '@utils';
import { detectInProgress } from '@utils/server';
import { hasKeys } from '@utils/object';
import { createDefaultPeriod } from '@utils/date';

const paymentsApi = {
	package: paymentspackage,
	payments: {
		client: new paymentspackage.PaymentsServiceClient(),
		fetchCashflowOperationsBriefs: (options: FetchCashflowOperationsOptions) => {
			const { fundsRegisterID = -1, dateRange, paymentStateCodes = [] } = options;

			return new Promise<Array<CashflowOperationBrief>>(resolve => {
				const filter: CashflowOperationFilter = {
					...new paymentsApi.package.CashflowOperationFilter(),
					FundsRegisterID: fundsRegisterID,
					PeriodDateStart: dateRange.dateStart,
					PeriodDateEnd: dateRange.dateEnd,
					PaymentStateCodes: paymentStateCodes,
				};

				paymentsApi.payments.client.getCashflowOperationsBriefs(filter, result => {
					resolve(result);
				});
			});
		},
		fetchPaymentByID: (paymentID: number) => {
			return new Promise<Payment>(resolve => {
				paymentsApi.payments.client.getPaymentByID(paymentID, result => {
					resolve(result);
				});
			});
		},
		addPayment: (payment: Payment) => {
			return new Promise<Payment>(resolve => {
				paymentsApi.payments.client.addFactPayment(payment, result => {
					resolve(result);
				});
			});
		},
		updatePayment: (payment: Payment) => {
			return new Promise<Payment>(resolve => {
				paymentsApi.payments.client.updatePayment(payment, result => {
					resolve(result);
				});
			});
		},
		removePayment: (paymentID: number) => {
			return new Promise<boolean>(resolve => {
				const payment = { ...new paymentsApi.package.Payment(), ID: paymentID };

				paymentsApi.payments.client.removePayment(payment, result => {
					resolve(Boolean(result));
				});
			});
		},
		fetchPaymentRulesByCashflowItems: () => {
			return new Promise<Array<PaymentRule>>(resolve => {
				paymentsApi.payments.client.getPaymentRules(result => {
					resolve(result.filter(x => x.AccountsChartItemID > 0));
				});
			});
		},
		fetchPaymentRulesByProjects: () => {
			return new Promise<Array<PaymentRule>>(resolve => {
				paymentsApi.payments.client.getPaymentRules(result => {
					resolve(result.filter(x => x.ProjectID > 0));
				});
			});
		},
		fetchPaymentRules: () => {
			return new Promise<Array<PaymentRule>>(resolve => {
				paymentsApi.payments.client.getPaymentRules(result => {
					resolve(result);
				});
			});
		},
		fetchPaymentRuleByID: (ID: number) => {
			return new Promise<PaymentRule>(resolve => {
				paymentsApi.payments.client.getPaymentRuleByID(ID, result => {
					resolve(result);
				});
			});
		},
		updatePaymentRule: (rule: PaymentRule) => {
			return new Promise<PaymentRule>(resolve => {
				paymentsApi.payments.client.updatePaymentRule(rule, result => {
					resolve(result);
				});
			});
		},
		addPaymentRule: (rule: PaymentRule) => {
			return new Promise<PaymentRule>(resolve => {
				paymentsApi.payments.client.addPaymentRule(rule, result => {
					resolve(result);
				});
			});
		},
		removePaymentRule: (ID: number) => {
			return new Promise<boolean>(resolve => {
				paymentsApi.payments.client.removePaymentRule(ID, result => {
					resolve(result);
				});
			});
		},
		executePaymentRules: (options: CashflowOperationFilter) => {
			return new Promise<PaymentRuleExecutionResult>(resolve => {
				paymentsApi.payments.client.executePaymentRules(options, (response: PaymentRuleExecutionResult) => {
					const inProgress = detectInProgress(response.Status);

					if (inProgress) {
						setRequestTimeout(
							paymentsApi.payments.client,
							paymentsApi.payments.client.getPaymentRuleExecutionResult,
							response.ExecutionProcessUID,
							(response: PaymentRuleExecutionResult) => {
								const inProgress = detectInProgress(response.Status);
								const isFinished = !inProgress;

								isFinished && resolve(response);

								return isFinished;
							},
							1000,
						);
					} else {
						resolve(response);
					}
				});
			});
		},
		ExecuteMassUpdateVAT: (options: CashflowOperationFilter) => {
			return new Promise<PaymentRuleExecutionResult>(resolve => {
				paymentsApi.payments.client.executeMassUpdateVAT(options, (response: PaymentRuleExecutionResult) => {
					const inProgress = detectInProgress(response.Status);

					if (inProgress) {
						setRequestTimeout(
							paymentsApi.payments.client,
							paymentsApi.payments.client.getMassUpdateVATExecutionResult,
							response.ExecutionProcessUID,
							(response: PaymentRuleExecutionResult) => {
								const inProgress = detectInProgress(response.Status);
								const isFinished = !inProgress;

								isFinished && resolve(response);

								return isFinished;
							},
							1000,
						);
					} else {
						resolve(response);
					}
				});
			});
		},
		fetchCashflowOperationByID: (ID: number) => {
			return new Promise<CashflowOperation>(resolve => {
				paymentsApi.payments.client.getTransferByID(ID, result => {
					if (result && hasKeys(result)) {
						resolve(result);
					} else {
						paymentsApi.payments.client.getPaymentByID(ID, result => {
							resolve(result);
						});
					}
				});
			});
		},
		fetchTransferByID: (transferID: number) => {
			return new Promise<TransferCashflowOperation>(resolve => {
				paymentsApi.payments.client.getTransferByID(transferID, result => {
					resolve(result);
				});
			});
		},
		fetchTransferFundsRegisters: (paymentID: number) => {
			return new Promise<Array<FundsRegisterBrief>>(resolve => {
				paymentsApi.payments.client.getFundsRegisterListForTransfer(paymentID, result => {
					resolve(result);
				});
			});
		},
		markAsTransfer: (payment: Payment, corrFundsRegisterID: number) => {
			return new Promise<TransferCashflowOperation>(resolve => {
				paymentsApi.payments.client.markAsTransfer(payment, corrFundsRegisterID, result => {
					resolve(result);
				});
			});
		},
		unmarkTransfer: (paymentID: number) => {
			return new Promise<Payment>(resolve => {
				paymentsApi.payments.client.markCounterpartyPayment(paymentID, result => {
					resolve(result);
				});
			});
		},
		fetchPaymentsForMerging: (paymentID: number) => {
			return new Promise<Array<Payment>>(resolve => {
				paymentsApi.payments.client.getPaymentsForMerging(paymentID, result => {
					resolve(result);
				});
			});
		},
		mergePaymentsToTransfer: (primaryID: number, secondaryID: number) => {
			return new Promise<Payment>(resolve => {
				paymentsApi.payments.client.mergePaymentsToTransfer(primaryID, secondaryID, result => {
					resolve(result);
				});
			});
		},
		syncPaymentsWithInvoices: (options: SyncPaymentsWithInvoicesOptions) => {
			const { dateRange, issueDate, fundsRegisterID } = options;

			return new Promise<Array<number>>(resolve => {
				paymentsApi.payments.client.massPaymentToInvoiceLinking(
					dateRange.dateStart,
					dateRange.dateEnd,
					issueDate,
					fundsRegisterID,
					result => {
						resolve(result.map(x => x.Value));
					},
				);
			});
		},
		createCashflowOperationFilter: (type: CashflowOperationFilterType, dateRange = createDefaultPeriod('year')) => {
			return {
				...new paymentsApi.package.CashflowOperationFilter(),
				PeriodDateStart: dateRange.dateStart,
				PeriodDateEnd: dateRange.dateEnd,
				FilterType: {
					...new coreApi.package.CodeNaturalKey(),
					Code: type,
				},
			};
		},
	},
};

export enum CashflowOperationFilterType {
	ACCOUNTS_CHART_ITEM = 'ACCOUNTS_CHART_ITEM',
	PROJECT = 'PROJECT',
	ALL = 'ALL',
}

export enum PaymentStateCode {
	DRAFT = 'DRAFT',
	EXECUTED = 'EXECUTED',
	SENT_TO_CMS = 'SENT_TO_CMS',
	IMPLEMENTED_BY_CMS = 'IMPLEMENTED_BY_CMS',
	FAILED_BY_CMS = 'FAILED_BY_CMS',
}

export const paymentStateCodeByIdMap = {
	1: PaymentStateCode.DRAFT,
	2: PaymentStateCode.EXECUTED,
	3: PaymentStateCode.SENT_TO_CMS,
	4: PaymentStateCode.IMPLEMENTED_BY_CMS,
	5: PaymentStateCode.FAILED_BY_CMS,
};

export type FetchCashflowOperationsOptions = {
	dateRange: DateRange;
	fundsRegisterID?: number;
	paymentStateCodes?: Array<PaymentStateCode>;
};

export type SyncPaymentsWithInvoicesOptions = {
	dateRange: DateRange;
	issueDate: string;
	fundsRegisterID: number;
};

export { paymentsApi };
