export enum ProjectStatisticsSliceCode {
	INCOME = 'INCOME',
	EXPENSE = 'EXPENSE',
	RESULT = 'RESULT',
}

export enum ProjectStatisticsRatiosCode {
	INCOME = 'INCOME',
	EXPENSE = 'EXPENSE',
	RESULT = 'RESULT',
	PROFITABILITY = 'PROFITABILITY',
}

export enum ProjectViewCode {
	LIST = 'LIST',
	HIERARCHY = 'HIERARCHY',
}

export enum VirtualListGroupCode {
	LEGAL_ENTITY = 'LEGAL_ENTITY',
	CLIENT = 'CLIENT',
	MANAGER = 'MANAGER',
}

export enum ProjectRelevanceCode {
	ACTIVE = 'ACTIVE',
	IN_ARCHIVE = 'IN_ARCHIVE',
}

export enum OperationDateTypeCode {
	CASHFLOW = 'CASHFLOW',
	ACCRUAL = 'ACCRUAL',
}

export type HierarchyTableRow = {
	ID: number;
	parentID: number;
	childItemIDs: Array<number>;
	name: string;
	columns: Array<HierarchyTableColumn>;
	value: Project;
};

export type HierarchyTableColumn = {
	code: ProjectStatisticsRatiosCode;
	values: Array<number>;
};

export type Sorting = {
	isAscOrder: boolean;
	sortBy: ProjectStatisticsRatiosCode;
};
