import { Store } from 'redux';

import { IAppState } from '@store';
import { isReceiveAction } from '@flux';
import { types as counterpartiesTypes } from '@counterparties/actions/types';
import { types as tenatLegalEntitiesTypes } from '@tenant-legal-entities/actions/types';
import { types as employeesTypes } from '@employees/actions/types';
import { invalidateClients } from '@clients/actions/invalidators';

function askClientsInvalidator(store: Store<IAppState>, action: StoreAction) {
	const { dispatch } = store;
	const syncMap = {
		[counterpartiesTypes.RUN_COUNTERPARTIES_INVALIDATION_EFFECT]: true,
	};
	const asyncMap = {
		[counterpartiesTypes.ADD_COUNTERPARTY]: true,
		[counterpartiesTypes.UPDATE_COUNTERPARTY]: true,
		[counterpartiesTypes.REMOVE_COUNTERPARTY]: true,
		[counterpartiesTypes.TRANSFORM_COUNTERPARTY_TO_TENANT_LEGAL_ENTITY]: true,
		[counterpartiesTypes.LINK_GROUPS_TO_COUNTERPARTY]: true,
		[counterpartiesTypes.LINK_MANAGER_TO_COUNTERPARTY]: true,
		[counterpartiesTypes.UPDATE_COUNTERPARTY_GROUP]: true,
		[counterpartiesTypes.REMOVE_COUNTERPARTY_GROUP]: true,
		[tenatLegalEntitiesTypes.TRANSFORM_TENANT_LEGAL_ENTITY_TO_COUNTERPARTY]: true,
		[employeesTypes.UPDATE_EMPLOYEE]: true,
		[employeesTypes.REMOVE_EMPLOYEE]: true,
	};

	if (syncMap[action.type]) {
		dispatch(invalidateClients());
	} else if (asyncMap[action.type] && isReceiveAction(action)) {
		dispatch(invalidateClients());
	}
}

function createInvalidatorEffect() {
	return (store: Store<IAppState>) => (next: (action: StoreAction) => void) => (action: StoreAction) => {
		next(action);
		askClientsInvalidator(store, action);
	};
}

const runInvalidatorEffect = createInvalidatorEffect();

export { runInvalidatorEffect };
