export enum CashflowLimitsGranulationModeCode {
	DAYS = 'DAY',
	WEEKS = 'WEEK',
	MONTHS = 'MONTH',
}

export enum CashflowLimitsPeriodCode {
	YEAR = 'O',
	QUARTER = 'Q',
}

export enum CashflowLimitsTypeModeCode {
	BUSINESS_UNIT,
	LEGAL_ENTITY,
	PROJECT,
}

export type VirtualTableRow = {
	ID: number;
	name: string;
	parentID: number;
	childItemIDs: Array<number>;
	cashflowIDs: Array<number>;
	hasChildren: boolean;
	isIncome: boolean | null;
	isCashflow?: boolean;
	columns: Array<VirtualTableColumn>;
};

export type VirtualTableColumn = {
	code: string;
	planValue: number | null;
	planPrefix: string;
	factValue: number | null;
	factPrefix: string;
	deviationValue: number | null;
	deviationPrefix: string;
	cashflowLimitID: number;
	isUnsaved?: boolean;
};

export type CellCoords = {
	rowID: number;
	rowIdx: number;
	columnCode: string;
	columnIdx: number;
};

export type UnsavedCell = {
	oldValue: number;
	newValue: number;
	unsavedIDs: Array<number>;
	isIncome: boolean;
	isEditing: boolean;
	rowIdx: number;
	columnsIdxs: Array<number>;
};

export enum SyntheticDataID {
	INCOME_BALANCE = -2,
	INCOME_TOTAL = -3,
	EXPENSE_TOTAL = -4,
	EXPENSE_BALANCE = -5,
	LAST_SYNTHETIC_ID = -14,
	ALL_UNALLOCATED = -15,
	INCOME_UNALLOCATED = -16,
	EXPENSE_UNALLOCATED = -17,
}

export type LimitsColumnData = {
	date: string;
	label: string;
	parentCode: string | null;
};
