import React, { useState, useMemo } from 'react';

import { useMounted } from '@core/hooks/use-mounted';
import { useTheme } from '@theme';
import { Animated } from './styled';

export type RippleProps = {
	during?: number;
	color?: string;
	className?: string;
	skip?: boolean;
	style?: React.CSSProperties;
	children: React.ReactNode;
};

const Ripple: React.FC<RippleProps> = props => {
	const { during, color: sourceColor, skip, style, children, ...rest } = props;
	const [{ top, left, size, inProgress }, setState] = useState({
		top: 0,
		left: 0,
		size: 0,
		inProgress: false,
	});
	const { theme } = useTheme();
	const { mounted } = useMounted();
	const mergedStyle = useMemo(() => ({ ...boxStyle, ...style }), [style]);
	const color = sourceColor || (theme.isDarkMode ? 'rgba(255, 255, 255, 0.4)' : 'rgba(0, 0, 0, 0.4)');

	const deactivate = () =>
		setState({
			top: 0,
			left: 0,
			size: 0,
			inProgress: false,
		});

	const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
		const { pageX, pageY, currentTarget } = e;
		const rect = currentTarget.getBoundingClientRect();
		const left = pageX - (rect.left + window.scrollX);
		const top = pageY - (rect.top + window.scrollY);
		const size = Math.max(rect.width, rect.height);

		const activate = () =>
			!skip &&
			setState({
				top,
				left,
				size,
				inProgress: true,
			});

		if (inProgress) {
			deactivate();
			requestAnimationFrame(() => {
				mounted() && activate();
			});
		} else {
			activate();
		}
	};

	const handleAnimationEnd = () => deactivate();

	return (
		<div {...rest} style={mergedStyle} onClick={handleClick}>
			{children}
			<Animated
				inProgress={inProgress}
				during={during}
				color={color}
				top={top}
				left={left}
				size={size}
				onAnimationEnd={handleAnimationEnd}
			/>
		</div>
	);
};

Ripple.defaultProps = {
	during: 800,
	className: '',
	style: {},
};

const boxStyle: React.CSSProperties = {
	position: 'relative',
	display: 'inline-flex',
	overflow: 'hidden',
};

export { Ripple };
