//@ts-ignore
import * as profilepackage from 'profilepackage-api';


const profileApi = {
	package: profilepackage,
	tenantProfile: {
		client: new profilepackage.TenantProfileServiceClient(),
		fetchTenantProfile: () => {
			return new Promise<TenantProfile>(resolve => {
				profileApi.tenantProfile.client.getTenantProfile(result => {
					resolve(result);
				});
			});
		},
		updateTenantProfile: (profile: TenantProfile) => {
			return new Promise<TenantProfile>(resolve => {
				profileApi.tenantProfile.client.updateTenantProfile(profile, result => {
					resolve(result);
				});
			});
		},
		initializeApplicationByProfile: () => {
			return new Promise<SuccessMessage>(resolve => {
				profileApi.tenantProfile.client.initializeApplicationByProfile(result => {
					resolve(result);
				});
			});
		},
	},
};

export {
	profileApi,
};
