import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import store, { IAppState } from '@store';

type Options<P> = {
	runInDidMount?: boolean;
	detectPropChange?: (props: P) => any;
};

type EffectOptions = {
	fromDidMount: boolean;
	fromUpdate: boolean;
};

type MapperItem<P> = [(s: IAppState, props: P) => boolean, (props: P, options: EffectOptions) => void, Options<P>?];

function withAutoUpdate<P extends object>(
	mapState: (s, p) => Partial<P>,
	mapDispatch: (dispatch: Dispatch<any>) => Partial<P>,
) {
	return (mapper: Record<string, MapperItem<P>>) => (WrappedComponent: React.ComponentType<P>) => {
		class WithAutoUpdate extends React.Component<P> {
			static displayName = `withAutoUpdate(${WrappedComponent.name || WrappedComponent.displayName})`;

			componentDidMount() {
				Object.keys(mapper).forEach(key => {
					const options = this.getOptions(mapper[key]);
					const [invalidateChecker, effect] = mapper[key];
					const state = store.getState();
					const forceRunnning = invalidateChecker(state, this.props);

					if (options.runInDidMount || forceRunnning) {
						effect(this.props, {
							fromDidMount: true,
							fromUpdate: false,
						});
					}
				});
			}

			componentDidUpdate(prevProps: P) {
				Object.keys(mapper).forEach(key => {
					const options = this.getOptions(mapper[key]);

					if (typeof options.detectPropChange === 'function') {
						const prevValue = options.detectPropChange(prevProps);
						const value = options.detectPropChange(this.props);

						if (prevValue !== value) {
							mapper[key][1](this.props, {
								fromDidMount: false,
								fromUpdate: true,
							});
						}
					}

					if (this.props[key] && !prevProps[key]) {
						mapper[key][1](this.props, {
							fromDidMount: false,
							fromUpdate: true,
						});
					}
				});
			}

			getOptions = (mapperItem: MapperItem<P>): Options<P> => {
				const defaultOptions = {
					runInDidMount: true,
					detectPropChange: undefined,
				};
				const options = mapperItem[2] && typeof mapperItem[2] === 'object' ? mapperItem[2] : {};
				const buildedOptions = {
					...defaultOptions,
					...options,
				};

				return buildedOptions;
			};

			render() {
				return <WrappedComponent {...this.props} />;
			}
		}

		const mapStateExtended = (state: IAppState, props: P) => {
			const mappedProps = Object.keys(mapper).reduce((acc, key) => {
				acc[key] = mapper[key][0](state, props);

				return acc;
			}, {});

			return {
				...mappedProps,
				...(mapState(state, props) as {}),
			};
		};

		return connect<any, any, P>(mapStateExtended, mapDispatch)(WithAutoUpdate as React.ComponentClass);
	};
}

export default withAutoUpdate;
