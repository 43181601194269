import styled, { css } from 'styled-components';

import { darken } from '@stylesheet';
import { Theme } from '@theme';
import type { BadgeProps } from './badge';

export enum BadgeColors {
	default = 'default',
	success = 'success',
	warning = 'warning',
	alarm = 'alarm',
	yellow = 'yellow',
}

const getColor = (colorName: BadgeColors, theme: Theme) => {
	const backgroundColorsMap = {
		[BadgeColors.default]: theme.badge.gray.backgroundColor,
		[BadgeColors.success]: theme.badge.green.backgroundColor,
		[BadgeColors.warning]: theme.badge.orange.backgroundColor,
		[BadgeColors.alarm]: theme.badge.red.backgroundColor,
		[BadgeColors.yellow]: theme.badge.yellow.backgroundColor,
	};
	const textColorsMap = {
		[BadgeColors.default]: theme.badge.gray.textColor,
		[BadgeColors.success]: theme.badge.green.textColor,
		[BadgeColors.warning]: theme.badge.orange.textColor,
		[BadgeColors.alarm]: theme.badge.red.textColor,
		[BadgeColors.yellow]: theme.badge.yellow.textColor,
	};

	return {
		backgroundColor: backgroundColorsMap[colorName],
		textColor: textColorsMap[colorName],
	};
};

const Root = styled.div<BadgeProps>`
	display: inline-block;
	position: relative;
	font-size: 12px;
	font-weight: 400;
	border-radius: 2px;
	border: solid 1px transparent;
	padding: 2px 4px;
	text-align: center;

	${p =>
		p.appearance === 'default' &&
		css`
			color: ${getColor(p.color, p.theme).backgroundColor};
			border-color: ${getColor(p.color, p.theme).backgroundColor};
		`}

	${p =>
		p.appearance === 'contained' &&
		css`
			color: ${getColor(p.color, p.theme).textColor};
			background-color: ${getColor(p.color, p.theme).backgroundColor};
		`}

	${p =>
		p.appearance === 'progress-bar' &&
		css`
			width: 100%;
			border-color: ${darken(getColor(getColorByPercentValue(p.value), p.theme).backgroundColor, 0.05)};
			color: ${getColorByPercentValue(p.value) === BadgeColors.success ? '#fff' : 'inherit'};
		`}
`;

const Bar = styled.div<Partial<BadgeProps>>`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: inline-block;
	height: 100%;
	z-index: 0;

	${p => css`
		width: ${p.value}%;
		background-color: ${getColor(getColorByPercentValue(p.value), p.theme).backgroundColor};
	`}
`;

const Content = styled.span`
	position: relative;
	z-index: 1;
`;

function getColorByPercentValue(value: number): BadgeColors {
	return value < 0 || value > 100
		? BadgeColors.default
		: value >= 0 && value < 33.3333
		? BadgeColors.alarm
		: value >= 33.3333 && value < 66.6666
		? BadgeColors.warning
		: BadgeColors.success;
}

export { Root, Bar, Content };
