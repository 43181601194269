import styled, { css, keyframes } from 'styled-components';

import { lighten, darken } from '@utils/color';
import { ProgressBarProps } from './progress-bar';

const createShineEffect = (value: number) => keyframes`
	0% {
		width: 0;
		opacity: 0;
	}
	40% {
		opacity: 0.3;
	}
	100% {
		width: ${value}%;
		opacity: 0;
	}
`;

const Root = styled.div`
	position: relative;
	width: 100%;
	height: auto;
`;

const Bar = styled.div`
	position: relative;
	width: 100%;
	height: 20px;
	z-index: 1;
	border-radius: 2px;

	${p => css`
		background-image: linear-gradient(
			86deg,
			${darken(p.theme.palette.accent, 0.05)},
			${lighten(p.theme.palette.accent, 0.3)}
		);
	`}
`;

const Slider = styled.div<ProgressBarProps>`
	position: absolute;
	top: 0;
	right: 0;
	width: ${p => (p.value !== undefined ? `${100 - p.value}%` : '0px')};
	height: 20px;
	z-index: 2;
	transition: all 0.2s ease-in-out;
	border-radius: 0 2px 2px 0;

	${p => css`
		background-color: ${p.theme.palette.background};
	`}
`;

const Shine = styled.div<ProgressBarProps>`
	opacity: 0;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 10;
	background-color: #fff;
	border-radius: 2px;

	${p => css`
		animation-name: ${createShineEffect(p.value)};
		animation-duration: 3s;
		animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1) 0ms;
		animation-iteration-count: infinite;
	`}
`;

const Text = styled.span`
	position: absolute;
	z-index: 10;
	top: 50%;
	left: 50%;
	font-size: 11px;
	transform: translate(-50%, -50%);

	${p => css`
		background-color: ${p.theme.palette.textContrast};
	`}
`;

export { Root, Bar, Slider, Shine, Text };
