import styled, { css } from 'styled-components';

type RootProps = {
	enablePointer?: boolean;
};

const Root = styled.div<RootProps>`
	position: relative;
	padding: 12px 10px;
	width: 210px;
	height: 70px;
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;
	border-right: 1px solid transparent;
	font-weight: 500;

	${p => css`
		background-color: ${p.theme.palette.canvas};
		border-color: ${p.theme.palette.stealth};
	`}

	${p =>
		p.enablePointer &&
		css`
			cursor: pointer;
		`}
`;

const Label = styled.span`
	${p => css`
		color: ${p.theme.palette.alarm};
		font-size: ${p.theme.fn.pxToRem(12)};
	`}
`;

const Value = styled.div`
	${p => css`
		color: ${p.theme.palette.alarm};
		font-size: ${p.theme.fn.pxToRem(16)};
	`}
`;

export { Root, Label, Value };
