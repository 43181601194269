import styled, { css } from 'styled-components';
import { animated } from 'react-spring';

const Root = styled(animated.div)`
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	${p => css`
		background-color: ${p.theme.overlay.backgroundColor};
	`}
`;

export { Root };
