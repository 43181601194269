import * as moment from 'moment';
import * as taskpackage from 'taskpackage-api';

import { buildTLongArray, setRequestTimeout } from '@core/libs/utils';
import { BASE_DATE_FORMAT } from '@shared/constants/time';

const taskApi = {
	package: taskpackage,
	serverTask: {
		client: new taskpackage.ServerTaskServiceClient(),
		checkTasks: (options: CheckTasksOptions) => {
			const { IDs, onCheck } = options;

			return new Promise<Array<ServerTaskInstance>>(resolve => {
				const taskIDs = buildTLongArray(IDs);
				const dateStart = moment().subtract(1, 'day').format(BASE_DATE_FORMAT);
				const dateEnd = moment().add(1, 'day').format(BASE_DATE_FORMAT);
				const timeout = 5000;

				taskApi.serverTask.client.getTaskInstances(dateStart, dateEnd, taskIDs, instances => {
					const filteredInstances = instances.filter(x =>
						[ServerStatusCode.WAITING, ServerStatusCode.IN_PROGRESS].includes(x.StatusCode as ServerStatusCode),
					);
					const finishedInstances: Array<ServerTaskInstance> = [];

					if (filteredInstances.length === 0) {
						onCheck({
							isFinished: true,
							instances,
							statusCode: ServerStatusCode.SUCCESS,
						});
					} else {
						for (const instance of filteredInstances) {
							setRequestTimeout(
								taskApi.serverTask.client,
								taskApi.serverTask.client.getTaskInstanceByID,
								instance.ID,
								instance => {
									const isFinished = ![ServerStatusCode.WAITING, ServerStatusCode.IN_PROGRESS].includes(
										instance.StatusCode as ServerStatusCode,
									);

									if (isFinished && !finishedInstances.find(x => x.ID === instance.ID)) {
										finishedInstances.push(instance);
									}

									const isAllFinished = finishedInstances.length === filteredInstances.length;

									if (isAllFinished) {
										const hasAnyFailed = filteredInstances.some(x => x.StatusCode === ServerStatusCode.FAILED);

										onCheck({
											isFinished: true,
											instances: filteredInstances,
											statusCode: hasAnyFailed ? ServerStatusCode.FAILED : ServerStatusCode.SUCCESS,
										});
									} else {
										const hasAnyWaiting = filteredInstances.some(x => x.StatusCode === ServerStatusCode.WAITING);

										onCheck({
											isFinished: false,
											instances: filteredInstances,
											statusCode: hasAnyWaiting ? ServerStatusCode.WAITING : ServerStatusCode.IN_PROGRESS,
										});
									}

									return isFinished;
								},
								timeout,
							);
						}
					}

					resolve(instances);
				});
			});
		},
	},
};

type CheckTasksOptions = {
	IDs: Array<number>;
	onCheck: (options: OnCheckOptions) => void;
};

export type OnCheckOptions = {
	isFinished: boolean;
	instances: Array<ServerTaskInstance>;
	statusCode: ServerStatusCode;
};

export enum ServerStatusCode {
	WAITING = 'WAITING',
	IN_PROGRESS = 'IN_PROGRESS',
	SUCCESS = 'SUCCESS',
	FAILED = 'FAILED',
	FINISHED_WITH_WARNING = 'FINISHED_WITH_WARNING',
	DUPLICATE_REQUEST = 'DUPLICATE_REQUEST',
}

export { taskApi };
