import { createTypes } from '@flux';

type ActionTypes = {
	INVALIDATE_MODULE_DATA: string;
	FETCH_ACCRUAL_REPORT: string;
	SET_DATE_RANGE: string;
	SET_TENANT_ENTITIES_FILTER: string;
	SET_BUSINESS_UNITS_FILTER: string;
	SET_PROJECTS_FILTER: string;
	SET_CASHFLOW_ITEMS_FILTER: string;
	SET_FUNDS_REGISTERS_FILTER: string;
	SET_IS_INCLUDES_VAT: string;
	SET_IS_INCLUDES_PLAN: string;
	SET_IS_HEADER_COLLAPSED: string;
	SET_SEARCH_TEXT: string;
	SET_SELECTED_COORDS: string;
	FETCH_DETAILS_OPERATIONS: string;
	SET_OPERATION_DETAILS_OPTIONS: string;
	SET_OPERATION_DETAILS_TITLE: string;
	SET_EXPANDED_HIERARCHY_MAP: string;
	SET_EXPANDED_QUARTERS_MAP: string;
	RESTORE_OPERATION_DETAILS_VALIDATION: string;
};

export const types = createTypes<ActionTypes>(
	[
		'INVALIDATE_MODULE_DATA',
		'FETCH_ACCRUAL_REPORT',
		'SET_DATE_RANGE',
		'SET_TENANT_ENTITIES_FILTER',
		'SET_BUSINESS_UNITS_FILTER',
		'SET_PROJECTS_FILTER',
		'SET_CASHFLOW_ITEMS_FILTER',
		'SET_FUNDS_REGISTERS_FILTER',
		'SET_IS_INCLUDES_VAT',
		'SET_IS_INCLUDES_PLAN',
		'SET_IS_HEADER_COLLAPSED',
		'SET_SEARCH_TEXT',
		'SET_SELECTED_COORDS',
		'FETCH_DETAILS_OPERATIONS',
		'SET_OPERATION_DETAILS_OPTIONS',
		'SET_OPERATION_DETAILS_TITLE',
		'SET_EXPANDED_HIERARCHY_MAP',
		'SET_EXPANDED_QUARTERS_MAP',
		'RESTORE_OPERATION_DETAILS_VALIDATION',
	],
	'ACCRUAL_REPORT 🤑',
);
