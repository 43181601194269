import { IAppState } from '@store';

const selectTenant: (state) => Tenant = (state: IAppState) => {
	return state.platform.tenantAccount.tenant.item;
};

const selectTenantIsFetching: (state) => boolean = (state: IAppState) => {
	return state.platform.tenantAccount.tenant.isFetching;
};

const selectTenantIsLoaded: (state) => boolean = (state: IAppState) => {
	return state.platform.tenantAccount.tenant.isLoaded;
};

const selectTenantDidInvalidate: (state) => boolean = (state: IAppState) => {
	return state.platform.tenantAccount.tenant.didInvalidate;
};

const selectTenantContactEmail = (state: IAppState) => state.platform.tenantAccount.tenant.item?.ContactEMail || '';

const selectTenantContactPhone = (state: IAppState) =>
	state.platform.tenantAccount.tenant.item?.ContactPhoneNumber || '';

export {
	selectTenant,
	selectTenantIsFetching,
	selectTenantIsLoaded,
	selectTenantDidInvalidate,
	selectTenantContactEmail,
	selectTenantContactPhone,
};
