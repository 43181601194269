export enum WizardZone {
	ABOUT_COMPANY = 'ABOUT_COMPANY',
	ATTENTION = 'ATTENTION',
	INITIALIZATION = 'INITIALIZATION',
	SERVICE_VERSION = 'SERVICE_VERSION',
}

export enum SettingsKey {
	ACCOUNTING_CURRENCY_ID = 'accounting_currency_id',
	COMPANY_ACTIVITIES = 'company_activities',
	DISABLE_WIZARD = 'disable_wizard',
	DISABLED_WIZARD = 'disabled_wizard',
	EMPLOYEE_QUANTITY = 'employee_quantity',
	IS_ABOUT_COMPANY_WIZARD_ZONE_COMPLETED = 'is_about_company_wizard_zone_completed',
	IS_ATTENTION_WIZARD_ZONE_COMPLETED = 'is_attention_wizard_zone_completed',
	IS_INITIALIZATION_WIZARD_ZONE_COMPLETED = 'is_initialization_wizard_zone_completed',
	IS_SERVICE_VERSION_WIZARD_ZONE_COMPLETED = 'is_service_version_zone_completed',
	MISSED_WIZARD = 'missed_wizard',
	NEED_PROJECTS = 'need_projects',
	SERVICE_FEATURES = 'service_features',
	SERVICE_VERSION = 'service_version',
	TENANT_LEGAL_DATA = 'tenant_legal_data',
}

export const completedWizardZoneKeyMap = {
	[WizardZone.ATTENTION]: SettingsKey.IS_ATTENTION_WIZARD_ZONE_COMPLETED,
	[WizardZone.ABOUT_COMPANY]: SettingsKey.IS_ABOUT_COMPANY_WIZARD_ZONE_COMPLETED,
	[WizardZone.SERVICE_VERSION]: SettingsKey.IS_SERVICE_VERSION_WIZARD_ZONE_COMPLETED,
	[WizardZone.INITIALIZATION]: SettingsKey.IS_INITIALIZATION_WIZARD_ZONE_COMPLETED,
};

export enum OtpErrorCode {
	SEND_AUTH_CODE_REQUEST_ERROR = 'SEND_AUTH_CODE_REQUEST_ERROR',
	OTP_NOT_FOUND = 'OTP_NOT_FOUND',
	SMS_PROVIDER_ERROR = 'SMS_PROVIDER_ERROR',
	SEND_OTP_REQUEST_ERROR = 'SEND_OTP_REQUEST_ERROR',
	CHECK_OTP_ERROR = 'CHECK_OTP_ERROR',
	USER_NOT_FOUND_ERROR = 'USER_NOT_FOUND_ERROR',
	TOO_MANY_REQUESTS = 'TOO_MANY_REQUESTS',
}

export type SegmentedItem = {
	value: string;
	text: string;
};

export type AboutCompanyPreferences = {
	[SettingsKey.ACCOUNTING_CURRENCY_ID]: number;
	[SettingsKey.COMPANY_ACTIVITIES]: Array<SegmentedItem>;
	[SettingsKey.EMPLOYEE_QUANTITY]: number;
	[SettingsKey.TENANT_LEGAL_DATA]: string;
};
