import api from '@api';
import createAction from '@libs/action-creator';
import {getInvoiceStatuses, getURLParameterByName, sendAnalyticsAction} from '@utils';
import {history} from '../../platform/components/layout/layout.view';
import {fetchInvoiceByIDAction} from './invoice-select.actions';
import {showInvoiceIncomingFormAction} from './invoice-add.actions';
import {emitActionMessage as emitActionMessageAction} from '@shared/actions/action-message.actions';
import {URL_PARAMS} from '../constants';
import {ROUTE_MAP} from '@routes/urls';
import {FinancialDocumentTypeCode} from "@core/api/invoice";

const PURCHASES_URL = ROUTE_MAP.PURCHASES;

const IMPORT_INVOICE = '[INVOICE]: IMPORT_INVOICE';

const importInvoiceAction = createAction(IMPORT_INVOICE, importInvoice);

function importInvoice(api, getState, dispatch, sharedUID: string, withRedirect = true, withMessages = true) {
	return new Promise(resolve => {
		const isTypePay = getURLParameterByName(URL_PARAMS.IMPORT_ACTION_TYPE) === 'pay';
		const makeAnalytics = () => {
			if (isTypePay) {
				sendAnalyticsAction({
					event: 'addEvents_makeConversions',
					conversion_name: 'payment',
					conversion_step: 'import-invoice',
					conversion_param: 'none',
				});
			}
		};
		const makeRedirect = (invoice: Invoice) => {
			const { isPaid } = getInvoiceStatuses(invoice);

			if (!isPaid && isTypePay) {
				dispatch(fetchInvoiceByIDAction(invoice.ID));
				dispatch(showInvoiceIncomingFormAction());
			}

			history.push(PURCHASES_URL);
		};
		const makeImport = (sharedUID: string, resolve) => {
			importDocumentMethod(sharedUID).then((invoice: Invoice) => {
				withRedirect && makeRedirect(invoice);
				withMessages && dispatch(emitActionMessageAction('Счёт импортирован', 'success'));

				resolve(invoice);
			});
		};

		makeAnalytics();

		api.getSharedDocument(sharedUID).then((fd: FinancialDocument) => {
			api.invoicePack.financialDocument.fetchDocumentByNumberInYear(
				fd.Number,
				fd.DateIssued,
			).then((invoice: Invoice) => {
				const isInvoiceExists = invoice && Object.keys(invoice).length > 0;
				if (!isInvoiceExists) {
					makeImport(sharedUID, resolve);
				} else {
					withRedirect && makeRedirect(invoice);
					withMessages && dispatch(emitActionMessageAction('Счёт уже был импортирован ранее', 'warning'));
					resolve(invoice);
				}
			});
		});
	});
}

function importDocumentMethod(invoiceUID: string) {
	return new Promise(resolve => {
		api.financialDocumentServiceClient.importDocument(invoiceUID, (invoice: Invoice) => {
			resolve(invoice);
		});
	});
}

export const methods = {
	importDocumentMethod,
};

export { IMPORT_INVOICE, importInvoiceAction };
