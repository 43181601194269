import { combineReducers } from 'redux';

import { mainTenantLegalEntitiesReducer, MainTenantLegalEntitiesState } from './main.reducers';

export type TenantLegalEntitiesState = {
	main: MainTenantLegalEntitiesState;
};

const tenantLegalEntitiesReducer = combineReducers<TenantLegalEntitiesState>({
	main: mainTenantLegalEntitiesReducer,
});

export { tenantLegalEntitiesReducer };
