import { createReducer } from '@flux';
import { ThemeIdentifier, defaultThemeIdentifier } from '@theme';
import { types } from '@shared/actions/types';

export type ThemeState = {
	identifier: ThemeIdentifier;
};

const initialState: ThemeState = {
	identifier: defaultThemeIdentifier,
};

const themeReducer = createReducer<ThemeState>(initialState, {
	[types.SET_THEME_IDENTIFIER]: (action: StaticAction<ThemeIdentifier>) => {
		return {
			identifier: [...action.value],
		};
	},
});

export { themeReducer };
