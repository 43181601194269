import { ROUTE_MAP } from '@core/routes/urls';

export * from './billing';
export * from './process';
export * from './service-version';

export enum SessionVariables {
	SHOW_ANNUAL_REPORT_251124 = 'show_annual_report_251124',
	SHOW_TARIFF_SELECTOR = 'show_tariff_selector',
}

export type RedirectOptions = {
	isModal: boolean;
	label: string;
	url: string;
};

export const SessionVariablesRedirect = {
	[SessionVariables.SHOW_TARIFF_SELECTOR]: {
		isModal: false,
		url: `${ROUTE_MAP.TENANT_ACCOUNT}/bills-and-services?needPayment=1`,
	},
	[SessionVariables.SHOW_ANNUAL_REPORT_251124]: {
		isModal: true,
		label: 'Сформирован Годовой отчёт за 2024 год',
		url: `${location.origin}/atr-framework-services/pages?show_report=SeenecoCore.SCR_AnnualReport&context-param-year=2024`,
	},
};
