import styled, { css } from 'styled-components';

const Root = styled.div`
	position: relative;
	width: 100%;
	min-height: calc(100vh - 70px);
	background-color: transparent;
	padding: 8px 0;
	display: flex;
	flex-flow: column nowrap;
	justify-content: space-between;

	& ul {
		padding: 0;
		margin: 0;
	}

	${p => css`
		border-top: 1px solid ${p.theme.appMenu.dividerColor};
		border-right: 1px solid ${p.theme.appMenu.dividerColor};
	`}
`;

export { Root };
