import styled from 'styled-components';

import * as colors from '@ui/basic-theme/colors';
import { pxToRem } from '@stylesheet';


const Root = styled.div`
	position: relative;
	width: 100%;
	min-height: 40px;
	background-color: ${colors.purplePrimary};
	color: ${colors.whitePrimary};
	font-size: ${pxToRem(12)};
	padding: 8px 20px;
	display: flex;
	flex-flow: row wrap;
	align-items: center;
`;

export {
	Root,
};
