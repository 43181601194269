import createAction, { createStaticDataAction } from '@libs/action-creator';
import api from '@api';
import { getAuthParams } from '@config';

const LOGOUT = '[PLATFORM]: LOGOUT';
const FETCH_ACCESS_TOKEN = '[PLATFORM]: FETCH_ACCESS_TOKEN';
const GENERATE_ACCESS_TOKEN = '[PLATFORM]: GENERATE_ACCESS_TOKEN';
const REVOKE_ACCESS_TOKEN = '[PLATFORM]: REVOKE_ACCESS_TOKEN';

function logoutAction() {
	const { businessService } = getAuthParams();
	const form = document.createElement('form');
	const attrs = {
		business_service: businessService,
	};
	form.setAttribute('method', 'post');
	form.setAttribute('action', '/atr-framework-services/logout');

	for (const attrName of Object.keys(attrs)) {
		const attrValue = `${attrs[attrName]}`;
		const element = document.createElement('input');

		element.setAttribute('type', 'hidden');
		element.setAttribute('name', attrName);
		element.setAttribute('value', attrValue);
		form.appendChild(element);
	}

	document.body.appendChild(form);
	form.submit();

	return createStaticDataAction(LOGOUT)();
}

const fetchAccessTokenAction = createAction(FETCH_ACCESS_TOKEN, (api, getState, dispatch) => {
	return getAccessTokenMethod();
});

const generateAccessTokenAction = createAction(GENERATE_ACCESS_TOKEN, (api, getState, dispatch) => {
	return generateAccessTokenMethod();
});

const revokeAccessTokenAction = createAction(REVOKE_ACCESS_TOKEN, (api, getState, dispatch, tokenValue: string) => {
	return revokeAccessTokenMethod(tokenValue);
});

function getAccessTokenMethod() {
	return new Promise(resolve => {
		api.authorizationServiceClient.getAccessTokens(false, (result: Array<OAuthAccessToken>) => {
			resolve(result[0] || null);
		});
	});
}

function generateAccessTokenMethod() {
	return new Promise(resolve => {
		api.authorizationServiceClient.generateAccessToken((result: OAuthAccessToken) => {
			resolve(result);
		});
	});
}

function revokeAccessTokenMethod(tokenValue: string) {
	return new Promise(resolve => {
		api.authorizationServiceClient.revokeAccessToken(tokenValue, (result: boolean) => {
			resolve(JSON.parse(result + ''));
		});
	});
}

export const methods = {
	getAccessTokenMethod,
	generateAccessTokenMethod,
};

export const constants = {
	LOGOUT,
	FETCH_ACCESS_TOKEN,
	GENERATE_ACCESS_TOKEN,
	REVOKE_ACCESS_TOKEN,
};

export const actions = {
	logoutAction,
	fetchAccessTokenAction,
	generateAccessTokenAction,
	revokeAccessTokenAction,
};

export { LOGOUT, logoutAction };
