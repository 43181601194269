import React from 'react';

import api from '@api';
import { getPropInSafe } from '@utils';
import Form, { useFormContext } from './form';
import type { FormState, FormRef } from './form';
import { withFormInput } from './inputs';
import { withFormComponent as withForm } from './component';
import { withFormSubmitButton } from './button';
import { TextField, TextFieldProps } from '@ui/input-field';
import { SelectField, SelectFieldProps } from '../select-field';
import { DatePicker, DatePickerProps } from '@ui/date-picker';
import { RaisedButton } from '@ui/raised-button';
import { RadioButtonGroup, RadioButtonGroupProps } from '@ui/radio-button';
import FormDateRangePicker from './form-date-range-picker';
import FormRepeater from './form-repeater';
import { CopyToClipboardTextField, CopyToClipboardTextFieldProps } from '../copy-to-clipboard-field';
export * from './components';
import * as validators from './validators';
import type { FormInputProps } from './inputs';
import type { FormContextProps } from './component';
import { ControlsGroup } from './controls-group';
import {
	FormContent,
	FormLayout,
	FormExtraLayout,
	FormGroup,
	FormGroupTitle,
	FormGroupMemder,
	FormMember,
	FormGroupHorizontalLayout,
	FormMemberWidth,
} from './styled';

const getEditedObject = (context, briefObjectName?) =>
	context.isFetching ? {} : (briefObjectName ? context.editedBriefObjects[briefObjectName] : context.formObject) || {};

const getInputFieldValue = (props, context, wrappedElem) => {
	const name = props.name;
	let object = getEditedObject(context);
	if (props.briefObjectName) {
		const briefObject = context.editedBriefObjects[props.briefObjectName];
		object = (briefObject && name in briefObject ? briefObject : object[props.briefObjectName]) || {};
	}
	return object[name] ? object[name] : '';
};

const setInputFieldValue = (args, props, context, wrappedElem) => {
	const object = getEditedObject(context, props.briefObjectName);
	const value = args[1];
	object[props.name] = value;
};

const FormInputField = withFormInput<Partial<TextFieldProps>, unknown>(
	TextField,
	(props, context, wrappedElem, forcedValue?) => {
		const value = (forcedValue === undefined ? getInputFieldValue(props, context, wrappedElem) : forcedValue) || '';
		return value !== '';
	},
	setInputFieldValue,
	getInputFieldValue,
);

const setNumberInputFieldValue = (args, props, context) => {
	const object = getEditedObject(context, props.briefObjectName);
	const value = args[1];

	object[props.name] = value;
};

const FormCopyToClipboardTextField = withFormInput<Partial<CopyToClipboardTextFieldProps>, unknown>(
	CopyToClipboardTextField,
	(props, context, wrappedElem, forcedValue?) => {
		const value = (forcedValue === undefined ? getInputFieldValue(props, context, wrappedElem) : forcedValue) || '';
		return value !== '';
	},
	setInputFieldValue,
	getInputFieldValue,
);

// возращает id
const getSelectFieldValue = objectResolver => (props, context, wrappedElem) => {
	let object = getEditedObject(context);

	if (props.briefObjectName) {
		const briefObject = context.editedBriefObjects[props.briefObjectName];

		object = (briefObject && props.name in briefObject ? briefObject : object[props.briefObjectName]) || {};
	}

	const element = object[props.name];

	if (props.isFullObject) {
		if (!wrappedElem) return;
		const valueField = wrappedElem.valueField;
		const map = typeof element !== 'undefined' ? (objectResolver ? objectResolver.set(element, api) : element) : {};

		return map ? map[valueField] : null;
	}

	return element;
};

const setSelectFieldValue = objectResolver => (args, props, context, wrappedElem) => {
	const item = args[1];
	const id = args[0];
	const object = getEditedObject(context, props.briefObjectName);

	object[props.name] = props.isFullObject ? (objectResolver ? objectResolver.get(item, api) : item) : id;
};

type ApiType = typeof api;

type ObjectResolver<S> = {
	get: (selectObject, api: ApiType) => any; // from selectObject to formObject
	set: (formObject: S, api: ApiType) => any; // from formObject to selectObject
};

function withFormSelectField<P, S>(WrappedComponent: React.ComponentType<any>, objectResolver?: ObjectResolver<S>) {
	return withFormInput<P, S>(
		WrappedComponent,
		(props, context, wrappedElem) => {
			const elem = getSelectFieldValue(objectResolver)(props, context, wrappedElem);

			return typeof elem === 'number' ? true : Boolean(elem);
		},
		setSelectFieldValue(objectResolver),
		getSelectFieldValue(objectResolver),
	);
}

const FormSelectField = withFormSelectField<Partial<SelectFieldProps<any>>, unknown>(SelectField);

const withFormRadioButtonGroup = withFormSelectField;
const FormRadioButtonGroup = withFormRadioButtonGroup<Partial<RadioButtonGroupProps<any>>, unknown>(RadioButtonGroup);

function withFormItemsPicker<P, S>(WrappedComponent: React.ComponentType<any>, objectResolver?: ObjectResolver<S>) {
	return withFormInput<P, S>(
		WrappedComponent,
		(props, context, wrappedElem) => {
			const elem = getSelectFieldValue(objectResolver)(props, context, wrappedElem);

			return typeof elem === 'number' ? elem > 0 : Boolean(elem);
		},
		setSelectFieldValue(objectResolver),
		getSelectFieldValue(objectResolver),
	);
}

// возращает полный объект
const getAutoCompleteValue = objectResolver => (props, context, wrappedElem) => {
	let object = getEditedObject(context);

	if (props.briefObjectName) {
		const briefObject = context.editedBriefObjects[props.briefObjectName];
		object = (briefObject && props.name in briefObject ? briefObject : object[props.briefObjectName]) || {};
	}

	const elem = object[props.name];

	if (props.isFullObject) {
		return elem && (objectResolver ? objectResolver.set(elem, api) : elem);
	}

	return elem;
};

const setAutoCompleteValue = objectResolver => (args, props, context, wrappedElem) => {
	const item = args[0];
	const object = getEditedObject(context, props.briefObjectName);

	if (props.isFullObject) {
		object[props.name] = objectResolver ? objectResolver.get(item, api) : item;
	} else {
		if (!wrappedElem) {
			return -1;
		}

		object[props.name] = item ? item[wrappedElem.props.dataSourceConfig.value] : -1;
	}
};

type AutocompleteObjectResolver = {
	get: (selectObject, api) => any; // from selectObject to formObject
	set: (formObject, api) => any; // from formObject to selectObject
};

function withFormAutoComplete<T = any>(
	WrappedComponent,
	objectResolver?: AutocompleteObjectResolver,
): React.ComponentType<T & FormInputProps> {
	return withFormInput<T, any>(
		WrappedComponent,
		(props, context, wrappedElem, forcedValue?) => {
			if (!wrappedElem) {
				return false;
			}
			const elem =
				forcedValue === undefined ? getAutoCompleteValue(objectResolver)(props, context, wrappedElem) : forcedValue;
			const valueField = wrappedElem.valueField;

			return typeof elem === 'number' ? elem && elem > 0 : !!(elem && elem[valueField] > 0);
		},
		setAutoCompleteValue(objectResolver),
		getAutoCompleteValue(objectResolver),
	);
}

const getDatePickerValue = (props, context) => {
	let object = getEditedObject(context);
	const name = props.name;

	if (props.briefObjectName) {
		const briefObject = context.editedBriefObjects[props.briefObjectName];
		object = (briefObject && name in briefObject ? briefObject : object[props.briefObjectName]) || {};
	}

	return object[name] !== undefined ? object[name] : '';
};

const setDatePickerValue = (args, props, context) => {
	const value = args[0];
	const object = getEditedObject(context, props.briefObjectName);

	object[props.name] = value.format('DD-MM-YYYY');
};

const FormDatePicker = withFormInput<Partial<DatePickerProps>, unknown>(
	DatePicker,
	(props, context) => getDatePickerValue(props, context) !== '',
	setDatePickerValue,
	getDatePickerValue,
);

const SubmitRaisedButton = withFormSubmitButton<React.ComponentProps<typeof RaisedButton>>(RaisedButton);

const getInlineEditAutoCompleteValue = objectResolver => (props, context, wrappedElem) => {
	const object = getEditedObject(context, props.briefObjectName);
	const elem =
		object[props.name] && object[props.name].ID > 0 ? object[props.name] : context.editedBriefObjects[props.name];
	if (props.isFullObject) {
		return elem && (objectResolver ? objectResolver.set(elem, api) : elem);
	}
	return elem;
};

const setInlineEditAutoCompleteValue =
	(createBriefObject: (term: string, value?) => any, objectResolver, enableUpdateObject = false) =>
	(args, props, context, wrappedElem) => {
		const item = args[0];
		const itemID = wrappedElem && item ? item[wrappedElem.props.dataSourceConfig.value] : -1;
		const searchText = args[1];
		const object = getEditedObject(context, props.briefObjectName);
		let isUpdateEnabled = false;

		if (enableUpdateObject) {
			const value = context.formObject[props.name];
			isUpdateEnabled = value && value.ID > 0;

			if (isUpdateEnabled) {
				const valueName = getPropInSafe(value, o => o.Name, '');

				if (valueName.trim() !== searchText.trim()) {
					context.addBriefObject(props.name, createBriefObject(searchText, value));
				} else {
					searchText && context.deleteBriefObject(props.name);
				}
			}
		}

		const mainObject = object[props.name];
		if (item) {
			if (!mainObject || itemID !== (props.isFullObject ? mainObject.ID : mainObject)) {
				context.deleteBriefObject(props.name);
			}
		} else {
			if (searchText) {
				if (mainObject && mainObject.ID > 0) {
					context.deleteBriefObject(props.name);
				}
				context.addBriefObject(props.name, createBriefObject(searchText));
			} else {
				context.deleteBriefObject(props.name);
			}
		}

		if (props.isFullObject) {
			object[props.name] = objectResolver ? objectResolver.get(item, api) : item;
		} else {
			object[props.name] = itemID;
		}
	};

function withFormInlineEditAutoComplete<P = any, S = any>(
	WrappedComponent: React.ComponentType<P>,
	createBriefObject: (name: string) => S,
	objectResolver: {
		get: (selectObject, api) => any; // from selectObject to formObject
		set: (formObject, api) => any; // from formObject to selectObject
	} = null,
	enableUpdateObject?: boolean,
) {
	const FormAutocomplete = withFormInput<P, S>(
		WrappedComponent,
		(props, context, wrappedElem) => {
			if (!wrappedElem) {
				return false;
			}
			const elem = getInlineEditAutoCompleteValue(objectResolver)(props, context, wrappedElem);
			const valueField = wrappedElem.valueField;
			const editedElem = context.editedBriefObjects[props.name];

			if (elem && elem[valueField] > 0) {
				return true;
			}
			if (editedElem) {
				const createdBriefObject = createBriefObject('');
				const isRequiredFiledsExists = Object.keys(createdBriefObject).every(createdKey => {
					return Object.keys(editedElem).some(editedKey => editedKey === createdKey);
				});

				return isRequiredFiledsExists;
			}
			return false;
		},
		setInlineEditAutoCompleteValue(createBriefObject, objectResolver, enableUpdateObject),
		getInlineEditAutoCompleteValue(objectResolver),
		true, // enableCreateObject
		enableUpdateObject,
	);
	const EnhancedFormAutocomplete: React.FC<P & FormInputProps> = props => <FormAutocomplete {...props} />;

	return EnhancedFormAutocomplete;
}

const getImageUploadValue = (props, context) => {
	const name = props.name;
	let object = getEditedObject(context);
	let uploadedFilePreview;
	if (props.briefObjectName) {
		const briefObject = context.editedBriefObjects[props.briefObjectName];
		object = (briefObject && name in briefObject ? briefObject : object[props.briefObjectName]) || {};
	}
	if (object[name] && object[name].preview) {
		uploadedFilePreview = object[name].preview;
	}
	return uploadedFilePreview || (object[name] ? object[name] : '');
};

const setImageUploadValue = (args, props, context, wrappedElem) => {
	const object = getEditedObject(context, props.briefObjectName);
	const value = args[0];
	object[props.name] = value;
};

const FormTextField = FormInputField;

export type FormTextFieldProps = FormInputProps;

export {
	Form,
	FormState,
	FormRef,
	useFormContext,
	ControlsGroup,
	FormInputField,
	FormSelectField,
	FormDatePicker,
	FormDateRangePicker,
	SubmitRaisedButton,
	FormCopyToClipboardTextField,
	FormRadioButtonGroup,
	FormRepeater,
	withFormSelectField,
	withFormAutoComplete,
	withFormRadioButtonGroup,
	withFormItemsPicker,
	withForm,
	withFormInlineEditAutoComplete,
	FormContent,
	FormLayout,
	FormExtraLayout,
	FormGroup,
	FormGroupTitle,
	FormGroupMemder,
	FormMember,
	FormGroupHorizontalLayout,
	FormMemberWidth,
	getInputFieldValue,
	setInputFieldValue,
	validators,
	FormInputProps,
	FormContextProps,
	FormTextField,
	setNumberInputFieldValue,
};
