import React from 'react';
import { connect } from 'react-redux';

import { IAppState } from '@store';
import { selectIsAppPurpleBarBarOpen } from '@shared/selectors/ui.selectors';
import { mainTenantProfileSelectorsPack } from '@platform/selectors/main-tenant-profile.selectors';
import { openRemoveStandardDataFormDrawer } from '@platform/components/forms/remove-standard-data';
import XAppPurpleBar, { AppPurpleBarProps as XAppPurpleBarProps } from './app-purple-bar.view';


type AppPurpleBarProps = {} & Partial<Pick<XAppPurpleBarProps, 'hasDemoData' | 'isOpen'>>;

class AppPurpleBar extends React.PureComponent<AppPurpleBarProps> {
	static displayName = 'AppPurpleBar[Container]';

	render () {
		const {
			isOpen,
			hasDemoData,
			...rest
		} = this.props;

		return (
			<XAppPurpleBar
				{...rest}
				isOpen={isOpen}
				hasDemoData={hasDemoData}
				onRequestRemoveDemoData={openRemoveStandardDataFormDrawer}
			/>
		);
	}
}

function mapStateToProps(state: IAppState): Partial<AppPurpleBarProps> {
	return {
		isOpen: selectIsAppPurpleBarBarOpen(state),
		hasDemoData: mainTenantProfileSelectorsPack.selectTenantProfileHasStandardData(state),
	};
}

export default connect<any, any, AppPurpleBarProps>(mapStateToProps)(AppPurpleBar);
