import { Store } from 'redux';

import { IAppState } from '@store';
import { isReceiveAction } from '@flux';
import { types as businessUnitsTypes } from '@business-units/actions/types';
import { invalidateBusinessUnits } from '@business-units/actions/invalidators';

function askMergeBusinessUnitsInvalidator(store: Store<IAppState>, action: StoreAction) {
	const { dispatch } = store;
	const invalidateMap = {
		[businessUnitsTypes.RUN_BUSINESS_UNITS_INVALIDATION_EFFECT]: true,
	};

	if (invalidateMap[action.type]) {
		dispatch(invalidateBusinessUnits());
	}
}

function askBusinessUnitsInvalidator(store: Store<IAppState>, action: StoreAction) {
	const { dispatch } = store;
	const invalidateMap = {};

	if (invalidateMap[action.type] && isReceiveAction(action)) {
		dispatch(invalidateBusinessUnits());
	}
}

function createInvalidatorEffect() {
	return (store: Store<IAppState>) => (next: (action: StoreAction) => void) => (action: StoreAction) => {
		next(action);
		askMergeBusinessUnitsInvalidator(store, action);
		askBusinessUnitsInvalidator(store, action);
	};
}

const runInvalidatorEffect = createInvalidatorEffect();

export { runInvalidatorEffect };
