import { createActionMessage } from '../../../core/libs/utils';
import createAction from '../../../core/libs/action-creator';


const FETCH_TENANT_BUSINESS_SERVICE_SETTINGS = '[PLATFORM]: FECTH_TENANT_BUSINESS_SERVICE_SETTINGS';
const UPDATE_TENANT_BUSINESS_SERVICE_SETTINGS = '[PLATFORM]: UPDATE_TENANT_BUSINESS_SERVICE_SETTINGS';

const fetchTenantBusinessServiceSettingsAction = createAction(FETCH_TENANT_BUSINESS_SERVICE_SETTINGS, fetchTenantBusinessServiceSettings);
const updateTenantBusinessServiceSettingsAction = createAction(UPDATE_TENANT_BUSINESS_SERVICE_SETTINGS, updateTenantBusinessServiceSettings, {
	showMessage: type => {
		return type === 'RECEIVE' && createActionMessage('Настройки сохранены', 'success')
	}
});

function fetchTenantBusinessServiceSettings(api, getState, dispatch, businessServiceID: number) {
	return new Promise(resolve => {
		api.businessServiceConfigurationServiceClient.getTenantBusinessServiceSettings(
			businessServiceID,
			(result: TenantBusinessServiceSettings) => {
				resolve(result);
			});
	});
}

function updateTenantBusinessServiceSettings(api, getState, dispatch, businessServiceID: number, url: string) {
	return new Promise(resolve => {
		const settings = new api.configurationpackage.TenantBusinessServiceSettings();

		settings.StartPageURL = url;

		api.businessServiceConfigurationServiceClient.updateTenantBusinessServiceSettings(
			businessServiceID,
			settings,
			(result: TenantBusinessServiceSettings) => {
				resolve(result);
			});
	});
}


export const constants = {
	FETCH_TENANT_BUSINESS_SERVICE_SETTINGS,
	UPDATE_TENANT_BUSINESS_SERVICE_SETTINGS
}

export const actions = {
	fetchTenantBusinessServiceSettingsAction,
	updateTenantBusinessServiceSettingsAction
}
