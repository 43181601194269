import { combineReducers } from 'redux';

import mainCashflowPaymentRulesReducer, { MainCashflowPaymentRulesState } from './main.reducer';

export type CashflowPaymentRulesState = {
	main: MainCashflowPaymentRulesState;
};

const cahflowPaymentRulesReducer = combineReducers<CashflowPaymentRulesState>({
	main: mainCashflowPaymentRulesReducer,
});

export default cahflowPaymentRulesReducer;
