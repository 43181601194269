/***
 * Library contains JavaScript API for interaction with Seeneco Framework Web Services contained in ProjectPackage
 * @author AlexPlex Generated by Seeneco Framework Modeler 
 *
 */
(function(w){
/***
* 
*/
w.Project = function() {
  this.PACKAGE = "PROJECT";
  this.CLASSIFIER = "PROJECT";
  this.CopySupportingElement = false;
  this.ID = -1;
  this.Name = "";
  this.Code = "";
  this.DateFinish = null;
  this.DateStart = null;
  this.ClientID = -1;
  this.TenantLegalEntityID = -1;
  this.ManagerID = -1;
  this.ClientTrademarkID = -1;
  this.GeoRegions = [];
  this.BusinessUnit = null;
  this.BudgetID = -1;
  this.ResponsibilityCenterID = -1;
  this.ParentProject = null;
  this.ChildItems = [];
  this.Description = "";
  this.LimitedAccess = false;
  this.Client = null;
  this.Manager = null;
  this.TenantLegalEntity = null;
};
/***
* 
*/
w.ProjectBudgetDifference = function() {
  this.PACKAGE = "PROJECT";
  this.CLASSIFIER = "PROJECT_BUDGET_DIFFERENCE";
  this.CopySupportingElement = false;
  this.AddedItems = [];
  this.RemovedItems = [];
  this.ChangedItems = [];
};
/***
* 
*/
w.ProjectBudgetImportResult = function() {
  this.PACKAGE = "PROJECT";
  this.CLASSIFIER = "PROJECT_BUDGET_IMPORT_RESULT";
  this.CopySupportingElement = false;
  this.ImportSucess = false;
  this.ImportMessages = [];
};
/***
* 
*/
w.ProjectBudgetItem = function() {
  this.PACKAGE = "PROJECT";
  this.CLASSIFIER = "PROJECT_BUDGET_ITEM";
  this.CopySupportingElement = false;
  this.ID = -1;
  this.Name = "";
  this.Description = "";
  this.Code = "";
  this.Expense = -1;
  this.Income = -1;
};
/***
* 
*/
w.ProjectBudgetItemDifference = function() {
  this.PACKAGE = "PROJECT";
  this.CLASSIFIER = "PROJECT_BUDGET_ITEM_DIFFERENCE";
  this.CopySupportingElement = false;
  this.ItemCode = "";
  this.ProjectBudgetItemID = -1;
  this.FieldsDifference = [];
};
/***
* 
*/
w.ProjectBudgetItemFieldDiff = function() {
  this.PACKAGE = "PROJECT";
  this.CLASSIFIER = "PROJECT_BUDGET_ITEM_FIELD_DIFF";
  this.CopySupportingElement = false;
  this.FieldSystemName = "";
  this.OldValue = "";
  this.NewValue = "";
};
/***
* 
*/
w.ProjectBudgetItemRemoveCandidate = function() {
  this.PACKAGE = "PROJECT";
  this.CLASSIFIER = "PROJECT_BUDGET_ITEM_REMOVE_CANDIDATE";
  this.CopySupportingElement = false;
  this.Item = null;
  this.PaymentsLinked = false;
  this.TreasuryApplicationsLinked = false;
  this.InvoicesLinked = false;
};
/***
* 
*/
w.ProjectEmployeeAccess = function() {
  this.PACKAGE = "PROJECT";
  this.CLASSIFIER = "PROJECT_EMPLOYEE_ACCESS";
  this.CopySupportingElement = false;
  this.AccessLevel = -1;
  this.EmployeeID = -1;
  this.ProjectID = -1;
};
 
 

/***
 * Client API to ProjectServiceWS web service
 */
w.ProjectServiceClient = function() {
  /***
   * System name of target service
   */
  this.serviceName = "ProjectServiceWSImpl";
};

/***
 * [DEFAULT!!DEFAULT]
 * @param project [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.ProjectServiceClient.prototype.addProject = function(project, callbackHandler) {
  var parameterNames = ["project"];
  var projectJSON = JSON.stringify(project);
  var parameterValues = [projectJSON];
  var soapMessage = buildSOAPMessage("addProject", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param project [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.ProjectServiceClient.prototype.changeProject = function(project, callbackHandler) {
  var parameterNames = ["project"];
  var projectJSON = JSON.stringify(project);
  var parameterValues = [projectJSON];
  var soapMessage = buildSOAPMessage("changeProject", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param projectID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.ProjectServiceClient.prototype.getProjectByID = function(projectID, callbackHandler) {
  var parameterNames = ["projectID"];
  var parameterValues = [projectID];
  var soapMessage = buildSOAPMessage("getProjectByID", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param projectID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.ProjectServiceClient.prototype.removeProject = function(projectID, callbackHandler) {
  var parameterNames = ["projectID"];
  var parameterValues = [projectID];
  var soapMessage = buildSOAPMessage("removeProject", parameterNames, parameterValues);
  var resultHasComplexType = false;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param fileImportProcessInstanceID [DEFAULT!ID of process instance that performed import of project budget file to 
system staging DB!DEFAULT]
 * @param projectID [DEFAULT!!DEFAULT]
 * @param uploadedFilePath [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.ProjectServiceClient.prototype.importProjectBudget = function(fileImportProcessInstanceID, projectID, uploadedFilePath, callbackHandler) {
  var parameterNames = ["fileImportProcessInstanceID", "projectID", "uploadedFilePath"];
  var parameterValues = [fileImportProcessInstanceID, projectID, uploadedFilePath];
  var soapMessage = buildSOAPMessage("importProjectBudget", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param projectID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.ProjectServiceClient.prototype.getProjectBudgetItems = function(projectID, callbackHandler) {
  var parameterNames = ["projectID"];
  var parameterValues = [projectID];
  var soapMessage = buildSOAPMessage("getProjectBudgetItems", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param fileImportProcessInstanceID [DEFAULT!!DEFAULT]
 * @param projectID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.ProjectServiceClient.prototype.getProjectBudgetDifference = function(fileImportProcessInstanceID, projectID, callbackHandler) {
  var parameterNames = ["fileImportProcessInstanceID", "projectID"];
  var parameterValues = [fileImportProcessInstanceID, projectID];
  var soapMessage = buildSOAPMessage("getProjectBudgetDifference", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.ProjectServiceClient.prototype.getProjects = function(callbackHandler) {
  var parameterNames = [];
  var parameterValues = [];
  var soapMessage = buildSOAPMessage("getProjects", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = true;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param projectAccessList [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.ProjectServiceClient.prototype.setProjectsAccess = function(projectAccessList, callbackHandler) {
  var parameterNames = ["projectAccessList"];
  var projectAccessListJSON = JSON.stringify(projectAccessList);
  var parameterValues = [projectAccessListJSON];
  var soapMessage = buildSOAPMessage("setProjectsAccess", parameterNames, parameterValues);
  var resultHasComplexType = false;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param projectID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.ProjectServiceClient.prototype.getProjectAccessList = function(projectID, callbackHandler) {
  var parameterNames = ["projectID"];
  var parameterValues = [projectID];
  var soapMessage = buildSOAPMessage("getProjectAccessList", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.ProjectServiceClient.prototype.getCurrentEmployeeProjectAccess = function(callbackHandler) {
  var parameterNames = [];
  var parameterValues = [];
  var soapMessage = buildSOAPMessage("getCurrentEmployeeProjectAccess", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};


/***
 * Package security rights constants
 */

w.ProjectSecurityRights = {

    PROJECT_SUPERVISION_RIGHT:new SecurityRightCheckHandle('712fb798-fc45-4b25-b7b1-f1a928dd4875'),
    PROJECT_VIEW_RIGHT:new SecurityRightCheckHandle('21a1fbf3-db60-4381-82bb-974715856154')
};
})(typeof exports !== 'undefined' ? exports : window);
