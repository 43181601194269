const URL_PARAMS = {
	INVOICE: 'invoice',
	INVOICE_UID: 'invoiceUID',
	IMPORT_ACTION_TYPE: 'type', //pay | save
	CMS_GUID: 'cmsGUID',
	FAST_PAYMENT_MODE: 'fastPaymentMode',
	QR_CODE: 'qrcode'
};

export {
	URL_PARAMS,
};
