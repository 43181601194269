import { useLayoutEffect, useReducer, useMemo } from 'react';

import store from '@store';
import { useMounted } from '@hooks/use-mounted';

type Selector = (...args: any) => any;

function useMapState<
	T1 extends Selector,
	T2 extends Selector,
	T3 extends Selector,
	T4 extends Selector,
	T5 extends Selector,
	T6 extends Selector,
	T7 extends Selector,
	T8 extends Selector,
	T9 extends Selector,
	T10 extends Selector,
	T11 extends Selector,
	T12 extends Selector,
	T13 extends Selector,
	T14 extends Selector,
	T15 extends Selector,
	T16 extends Selector,
	T17 extends Selector,
	T18 extends Selector,
	T19 extends Selector,
	T20 extends Selector,
	T21 extends Selector,
>(
	selectors: [
		T1,
		T2?,
		T3?,
		T4?,
		T5?,
		T6?,
		T7?,
		T8?,
		T9?,
		T10?,
		T11?,
		T12?,
		T13?,
		T14?,
		T15?,
		T16?,
		T17?,
		T18?,
		T19?,
		T20?,
		T21?,
	],
) {
	const state = store.getState();
	const results = selectors.map(selector => selector(state));
	const [, forceUpdate] = useReducer((s: number) => s + 1, 0);
	const scope = useMemo(() => ({ selectors, results }), []);
	const { mounted } = useMounted();

	useLayoutEffect(() => {
		const unsubscribe = store.subscribe(() => {
			if (!mounted()) return;
			const state = store.getState();
			let idx = 0;

			for (const selector of scope.selectors) {
				if (selector(state) !== scope.results[idx]) {
					forceUpdate();
					return;
				}

				idx++;
			}
		});

		return () => unsubscribe();
	}, []);

	scope.results = results;
	scope.selectors = selectors;

	return results as [
		ReturnType<T1>,
		ReturnType<T2>?,
		ReturnType<T3>?,
		ReturnType<T4>?,
		ReturnType<T5>?,
		ReturnType<T6>?,
		ReturnType<T7>?,
		ReturnType<T8>?,
		ReturnType<T9>?,
		ReturnType<T10>?,
		ReturnType<T11>?,
		ReturnType<T12>?,
		ReturnType<T13>?,
		ReturnType<T14>?,
		ReturnType<T15>?,
		ReturnType<T16>?,
		ReturnType<T17>?,
		ReturnType<T18>?,
		ReturnType<T19>?,
		ReturnType<T20>?,
		ReturnType<T21>?,
	];
}

export { useMapState };
