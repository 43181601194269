import { compose } from 'redux';

import { IAppState } from '@store';
import { createAsyncSelector, createSelector } from '@flux';
import { getQuarterName, sortDescBy } from '@utils';
import { prettyDate } from '@utils/date';
import {
	selectSelectedPlanningTableCell,
	selectSelectedPlanningChartPont,
	selectPlanningFilterByText,
} from './planning.selectors';
import { ReportTableCell } from '@analytics/components/reporting/shared/report-table';

const selectAsyncPlOperations = createAsyncSelector<Array<PLOperationBrief>, IAppState>({
	get: s => s.plPlanning.main.plList,
	selector: createSelector(
		s => s.plPlanning.main.plList.item,
		item => item,
	),
});

const selectAsyncPlPlanOperations = createAsyncSelector<Array<PLOperationBrief>, IAppState>({
	get: s => s.plPlanning.main.plPlanList,
	selector: createSelector(
		s => s.plPlanning.main.plPlanList.item,
		item => item,
	),
});

function selectDetalizationOptions(state: IAppState) {
	return state.plPlanning.main.detalizationOptions;
}

function selectIsExpanded(state: IAppState) {
	return state.plPlanning.main.isExpanded;
}

const selectPlanOperationsDetalizationTitle = createSelector(
	selectSelectedPlanningTableCell,
	selectSelectedPlanningChartPont,
	(tableCell, datePoint) => getTitle('plan', tableCell, datePoint),
);

const selectFactOperationsDetalizationTitle = createSelector(
	selectSelectedPlanningTableCell,
	selectSelectedPlanningChartPont,
	(tableCell, datePoint) => getTitle('fact', tableCell, datePoint),
);

function getTitle(type: 'plan' | 'fact', tableCell: ReportTableCell, datePoint: string) {
	const prefix = type === 'fact' ? 'Детализация фактических операций' : 'Детализация плановых операций';

	if (datePoint) {
		return `${prefix} за ${prettyDate(datePoint)} г.`;
	}

	if (!tableCell || tableCell.col === -1) return '';

	const col = tableCell.row.columns[tableCell.col];
	const name = tableCell.row.parentName
		? `«${tableCell.row.name}» по «${tableCell.row.parentName}»`
		: `«${tableCell.row.name}»`;
	const date = col.date
		? col.reduceBy === 'QUARTER'
			? `${getQuarterName(+col.date.split(' ')[0])} кв. ${col.date.split(' ')[1]}`
			: col.date.toLocaleLowerCase()
		: '';
	const title = date ? `${prefix}: ${name} за ${date} г.` : `${prefix}: ${name}`;

	return title;
}

const selectFilteredPlanOperations = createSelector(
	selectAsyncPlPlanOperations.selectItem,
	selectPlanningFilterByText,
	(planOperations, searchText) => {
		const filteredPlanOperations = compose(
			(planOperations: Array<PLOperationBrief>) =>
				sortDescBy(planOperations, [{ fn: item => item.CashflowDate, isDate: true }, { fn: item => item.ID }]),
			(planOperations: Array<PLOperationBrief>) =>
				searchText
					? planOperations.filter(
							x =>
								(x.Counterparty?.Name && x.Counterparty.Name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1) ||
								x.Description?.toLowerCase().indexOf(searchText.toLowerCase()) !== -1,
					  )
					: planOperations,
		)(planOperations);

		return filteredPlanOperations;
	},
);

export const mainPlanningSelectorsPack = {
	selectAsyncPlOperations,
	selectAsyncPlPlanOperations,
	selectFilteredPlanOperations,
	selectDetalizationOptions,
	selectIsExpanded,
	selectPlanOperationsDetalizationTitle,
	selectFactOperationsDetalizationTitle,
};
