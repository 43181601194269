import React from 'react';

import { makeBlankSpace } from '@utils';
import { DesktopIllustration } from '@ui/illustrations/desktop';
import { RaisedButton } from '@ui/raised-button';
import { AppLogo } from '@ui/app-logo';
import { useComputedStyle } from '@utils/dom';
import {
	Root,
	HeaderLayout,
	Header,
	LogoLayout,
	ServiceName,
	UserName,
	ContentLayout,
	Content,
	Status,
	MainLayout,
	IconLayout,
	Text,
	ButtonLayout,
} from './styled';

type MobileEntryProps = {
	tenantName: string;
	serviceName: string;
	onKeep: () => void;
};

const MIN_WIDTH_PROP = 'min-width';

class MobileEntry extends React.PureComponent<MobileEntryProps> {
	static displayName = 'MobileEntry';
	bodyMinWidth = '';
	willMount = (() => {
		const [bodyMinWidth] = useComputedStyle(document.body, [{ name: MIN_WIDTH_PROP, transform: p => p }]);

		this.bodyMinWidth = bodyMinWidth;
		document.body.style.setProperty(MIN_WIDTH_PROP, 'auto');
	})();

	componentWillUnmount() {
		document.body.style.setProperty(MIN_WIDTH_PROP, this.bodyMinWidth);
	}

	handleDownloadMobileApp = () => {};

	render() {
		const { tenantName, serviceName, onKeep } = this.props;

		return (
			<Root>
				<HeaderLayout>
					<Header>
						<LogoLayout>
							<AppLogo appearance='mobile' />
						</LogoLayout>
						<UserName>{tenantName}</UserName>
					</Header>
				</HeaderLayout>
				<ContentLayout>
					<Content>
						<ServiceName>{serviceName}</ServiceName>
						<Status>Сервис подключён</Status>
						<MainLayout>
							<IconLayout>
								<DesktopIllustration color='muted' size={80} />
							</IconLayout>
							<Text>
								Пользоваться сервисом удобнее на{makeBlankSpace()}большом экране: в{makeBlankSpace()}нем много важных
								данных, цифр и{makeBlankSpace()}реквизитов
							</Text>
							<ButtonLayout>
								<RaisedButton label='Всё равно продолжить' fullWidth onClick={onKeep} />
							</ButtonLayout>
						</MainLayout>
					</Content>
				</ContentLayout>
			</Root>
		);
	}
}

export default MobileEntry;
