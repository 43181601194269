import { CSSProperties } from 'react';
import styled, { css, keyframes } from 'styled-components';

import * as statuses from '@shared/constants/statuses';

const rotateMotion = keyframes`
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
`;

const Root = styled.div`
	position: relative;
	display: inline-flex;
	flex-flow: row wrap;
	justify-content: center;
	align-items: center;
	width: auto;
	height: auto;
`;

type TriggerProps = {
	status: string;
};

const Trigger = styled.div<TriggerProps>`
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	align-items: center;
	width: 18px;
	height: 18px;
	border-radius: 50%;
	background-color: transparent;
	transition: all 0.2s ease-in-out;

	${p => css`
		${p.theme.fn.createBoxShadow(1)};

		&:hover {
			background-color: ${p.theme.palette.stealth};
			cursor: pointer;
		}
	`}

	${p =>
		(p.status === statuses.FAILED || p.status === statuses.FINISHED_WITH_WARNING) &&
		css`
			background-color: #fff;
		`}

	${p =>
		p.status === 'NOT_CONNECTED' &&
		css`
			background-color: #ffee58;
		`}
`;

type IconLayoutProps = {
	status: string;
};

const IconLayout = styled.div<IconLayoutProps>`
	display: flex;

	${p => css`
		color: ${p.theme.palette.accent};
	`}

	${p =>
		(p.status === statuses.FAILED || p.status === statuses.FINISHED_WITH_WARNING) &&
		css`
			color: ${p.theme.palette.alarm};
		`}

	${p =>
		(p.status === statuses.SUCCESS || p.status === statuses.DUPLICATE_REQUEST) &&
		css`
			color: ${p.theme.palette.accent};
		`}

	${p =>
		p.status === statuses.IN_PROGRESS &&
		css`
			color: ${p.theme.palette.accent};
			animation: ${rotateMotion} 2s linear infinite;
		`}

	${p =>
		p.status === 'NOT_CONNECTED' &&
		css`
			color: #000;
		`}
`;

const ContentLayout = styled.div`
	position: relative;
	display: flex;
	flex-flow: column wrap;
	justify-content: space-between;
	align-items: flex-start;
	font-size: 12px;
	line-height: 1.25;
	text-align: left;
`;

type SignProps = {
	alarm?: boolean;
};

const Sign = styled.div<SignProps>`
	position: relative;
	margin-bottom: 5px;

	${p =>
		p.alarm &&
		css`
			color: ${p.theme.palette.alarm};
		`}

	&:last-child {
		margin-bottom: 0;
	}
`;

const ButtonLayout = styled.div`
	position: relative;
	left: -5px;

	& span {
		font-size: 12px;
		text-transform: uppercase !important;
	}
`;

const tooltipStyle: CSSProperties = {
	width: 200,
	minHeight: 85,
};

export { Root, Trigger, IconLayout, ContentLayout, Sign, ButtonLayout, tooltipStyle };
