import {
	StoreAsyncItem,
	checkAsyncAction,
	createAsyncInitialState,
	invalidateStateFromAction,
	createReducer,
} from '@flux';
import { types } from '@clients/actions/types';

export type MainClientsState = {
	clients: StoreAsyncItem<Array<CounterpartyBrief>>;
	textFilter: string;
	typesFilter: Record<string, boolean>;
	groupsFilter: Record<string, boolean>;
	managersFilter: Record<string, boolean>;
};

const initialState: MainClientsState = {
	clients: createAsyncInitialState([]),
	textFilter: '',
	typesFilter: {},
	groupsFilter: {},
	managersFilter: {},
};

const mainClientsReducer = createReducer<MainClientsState>(initialState, {
	[types.INVALIDATE_CLIENTS]: (action: StaticAction, state) => {
		return {
			clients: invalidateStateFromAction(action, state.clients),
		};
	},
	[types.INVALIDATE_CLIENT_GROUPS]: (action: StaticAction, state) => {
		return {
			clients: invalidateStateFromAction(action, state.clients),
		};
	},
	[types.SET_FILTER_BY_TEXT]: (action: StaticAction<string>, state) => {
		return {
			textFilter: action.value,
			clients: invalidateStateFromAction(action, state.clients),
		};
	},
	[types.SET_FILTER_BY_CLIENT_TYPES]: (action: StaticAction<Record<string, boolean>>, state) => {
		return {
			typesFilter: { ...action.value },
			clients: invalidateStateFromAction(action, state.clients),
		};
	},
	[types.SET_FILTER_BY_CLIENT_GROUPS]: (action: StaticAction<Record<string, boolean>>, state) => {
		return {
			groupsFilter: { ...action.value },
			clients: invalidateStateFromAction(action, state.clients),
		};
	},
	[types.SET_FILTER_BY_MANAGERS]: (action: StaticAction<Record<string, boolean>>, state) => {
		return {
			managersFilter: { ...action.value },
			clients: invalidateStateFromAction(action, state.clients),
		};
	},
	[types.RESET_FILTERS]: (action: StaticAction, state) => {
		return {
			textFilter: '',
			typesFilter: {},
			groupsFilter: {},
			managersFilter: {},
			clients: invalidateStateFromAction(action, state.clients),
		};
	},
	[types.FETCH_CLIENTS]: (action: AsyncAction<Array<CounterpartyBrief>>, state) => {
		return {
			clients: checkAsyncAction(action, state.clients),
		};
	},
	[types.ADD_CLIENT]: (action: AsyncAction, state) => {
		return {
			clients: invalidateStateFromAction(action, state.clients),
		};
	},
	[types.UPDATE_CLIENT]: (action: AsyncAction, state) => {
		return {
			clients: invalidateStateFromAction(action, state.clients),
		};
	},
	[types.REMOVE_CLIENT]: (action: AsyncAction, state) => {
		return {
			clients: invalidateStateFromAction(action, state.clients),
		};
	},
});

export default mainClientsReducer;
