import styled, { css } from 'styled-components';

const StatisticCardLayout = styled.div`
	position: relative;
	margin-top: 20px;
`;

const StatisticCard = styled.div`
	position: relative;
	width: 100%;
	height: auto;
	padding: 15px 13px;
	border: 1px dashed transparent;
	border-radius: 2px;
	margin-bottom: 18px;

	${p => css`
		border-color: ${p.theme.palette.hint};
	`}
`;

const StatisticCardRow = styled.div`
	width: 100%;
	margin-bottom: 10px;

	&:last-child {
		margin-bottom: 0;
	}
`;

type StatisticCardSignProps = {
	fontSize?: string;
	lineHeight?: number;
};

const StatisticCardSign = styled.div<StatisticCardSignProps>`
	margin-bottom: 4px;
	font-size: ${p => (p.fontSize ? p.fontSize : '12px')};
	line-height: ${p => (p.lineHeight ? p.lineHeight : 1.4)};

	${p => css`
		color: ${p.theme.palette.label};
	`}
`;

const StatisticCardValue = styled.div`
	font-size: 12px;
	font-weight: 500;
	line-height: 1.67;
`;

export { StatisticCardLayout, StatisticCard, StatisticCardRow, StatisticCardSign, StatisticCardValue };
