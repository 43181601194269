// @ts-nocheck
import * as moment from 'moment';
import * as _ from 'underscore';

import { Drawer } from '@ui/drawer';
import { getFundsRegistersAccessFlags } from '@utils/finance';
import { detectIsEmail } from '../utils/contacts';
import api from './api';

export function isLocalhost() {
	return location.hostname === 'localhost' || location.hostname === '127.0.0.1';
}

export function deepClone(obj: any) {
	const isObject = typeof obj === 'object';
	const isFunction = typeof obj === 'function';
	const copyObj = isObject
		? Array.isArray(obj)
			? [...obj]
			: obj
			? { ...obj }
			: obj
		: isFunction
		? function () {
				return obj.apply(this, arguments);
		  }
		: obj;
	const clonePropsFn = (prop: string) => (copyObj[prop] = deepClone(copyObj[prop]));

	Boolean(copyObj) && isObject && Object.keys(copyObj).forEach(clonePropsFn);

	return copyObj;
}

const layout: {
	drawer?: Drawer;
} = {};

export function setDrawer(drawer: Drawer) {
	layout.drawer = drawer;
}
export const getDrawer = () => layout.drawer;

export const declOfNum = (number: number, titles: [string, string, string]) => {
	// [single, few, many]
	const cases = [2, 0, 1, 1, 1, 2];
	return titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]];
};

export function createActionMessage(
	message: string | React.ReactNode,
	type: IActionMessageType = 'default',
): IActionMessage {
	return {
		type,
		receiveAt: Date.now(),
		message,
	};
}

export const transformPath = (path: string) => {
	return path.charAt(path.length - 1) === '/' ? path.slice(0, -1) : path;
};

export const isNumericId = (id: any): boolean => !/\D/gi.test(id);

export const getPeriodsByYears = (years: number, dateStart: string, dateEnd: string) => {
	const periods = [];
	const format = 'DD-MM-YYYY HH:mm:ss';
	let dateStartMoment = moment(dateStart, format);
	const dateEndMoment = moment(dateEnd, format);
	const dateStartPlus = moment(dateStart, format).add(years, 'year');

	while (!dateStartPlus.isAfter(dateEndMoment)) {
		periods.push({
			start: dateStartMoment.format(format),
			end: dateStartPlus.format(format),
		});

		dateStartMoment = dateStartPlus.clone().add(1, 'days');
		dateStartPlus.add(years, 'year');
	}

	periods.push({
		start: dateStartMoment.format(format),
		end: dateEndMoment.format(format),
	});

	return periods;
};

export const formatCurrency = (value: number, code = 'RUR', locale = 'ru', fractions = 2): string => {
	const getCurrencyCode = (symbolicCode: string) => {
		const currencyUnitsByCode = {
			USD: 'USD',
			EUR: 'EUR',
			RUR: 'RUB',
			JPY: 'JPY',
			GBP: 'GBP',
		};

		return currencyUnitsByCode[symbolicCode] ? currencyUnitsByCode[symbolicCode] : currencyUnitsByCode['RUR'];
	};

	return new Intl.NumberFormat(locale, {
		style: 'currency',
		currency: getCurrencyCode(code),
		minimumFractionDigits: fractions,
		maximumFractionDigits: fractions,
	}).format(value);
};

export function formatCurrencyWithoutFraction(value: number, code = 'RUR', locale = 'ru') {
	return formatCurrency(value, code, locale, 0);
}

export const setRequestTimeout = (context, func, requestID, callback, interval = 3000) => {
	(function p() {
		func.call(context, requestID, function (status) {
			if (!callback(status)) {
				setTimeout(p, interval);
			}
		});
	})();
};

export function getPropInSafe<O, T>(obj: O, fn: (x: O) => T, replacer: any = undefined): T {
	try {
		const value = fn(obj);

		return typeof value !== 'undefined' ? value : replacer;
	} catch (err) {
		return replacer;
	}
}

export function isRegisterNumber(candidate: string): boolean {
	const pattern = /^\d{20}$/gi;

	return pattern.test(candidate);
}

export function getFundsRegisterType(
	source: FundsRegister | FundsRegisterBrief,
): 'bank' | 'cash' | 'card' | 'terminal' | 'default' {
	const fr = source as FundsRegister;
	const frBrief = source as FundsRegisterBrief;

	if (fr && fr.CLASSIFIER) {
		if (fr.CLASSIFIER === 'NATURAL_PERSON_CARD_FUNDS_REGISTER') {
			return 'card';
		}
		if (fr.CLASSIFIER === 'BANK_ACCOUNT_FUNDS_REGISTER') {
			return 'bank';
		}
		if (fr.CLASSIFIER === 'CASH_DESK_FUNDS_REGISTER') {
			return 'cash';
		}
		if (fr.CLASSIFIER === 'POS_TERMINAL_FUNDS_REGISTER') {
			return 'terminal';
		}
		if (fr.CLASSIFIER === 'FUNDS_REGISTER') {
			return 'default';
		}
	}

	if (frBrief && frBrief.FundsRegisterType) {
		if (frBrief.FundsRegisterType.Code === 'FUNDS_REGISTER_TYPE_BANK_CARD') {
			return 'card';
		}
		if (frBrief.FundsRegisterType.Code === 'FUNDS_REGISTER_TYPE_BANK_ACCOUNT') {
			return 'bank';
		}
		if (frBrief.FundsRegisterType.Code === 'FUNDS_REGISTER_TYPE_CASH_DESK') {
			return 'cash';
		}
		if (frBrief.FundsRegisterType.Code === 'FUNDS_REGISTER_TYPE_ACQUIRING') {
			return 'terminal';
		}
	}

	return 'default';
}

export function getOperationType(operation: CashflowOperation): 'transfer' | 'payment' | 'default' {
	if (operation.CLASSIFIER === 'TRANSFER_CASHFLOW_OPERATION') {
		return 'transfer';
	}
	if (operation.CLASSIFIER === 'PAYMENT') {
		return 'payment';
	}

	return 'default';
}

export function formatRegisterNumber(candidate: string, separator = '.'): string {
	function format(target) {
		const formatted = target
			.replace(new RegExp(/(\d{5})(.)/), '$1' + separator + '$2')
			.replace(new RegExp(`(.)(\\${separator}\\d{3})(.)`), '$1' + '$2' + separator + '$3')
			.replace(new RegExp(`(.)(\\${separator}\\d)(\\d{11})`), '$1' + '$2' + separator + '$3');

		return formatted;
	}

	return isRegisterNumber(candidate) ? format(candidate) : candidate;
}

export function formatRegisterNumberByMask(candidate: string, separator = '.'): string {
	return candidate
		.replace(new RegExp(`\\${separator}`, 'g'), '')
		.split('')
		.map((letter, index) => (index === 4 || index === 7 || index === 8 ? letter + separator : letter))
		.join('');
}

export function replaceRegisterNumberByAsterisk(registerNumber: string) {
	return registerNumber.replace(/^\d{13}/, '*');
}

export function getCurrencyDigitCode(registerNumber: string): string {
	return isRegisterNumber(registerNumber) ? registerNumber.substr(5, 3) : '-1';
}

export function bankHasCMS(bankName: string) {
	if (/(АЛЬФА-БАНК)|(АЛЬФАБАНК)/gi.test(bankName)) {
		return true;
	}
	if (/МОДУЛЬБАНК/gi.test(bankName)) {
		return true;
	}
	if (/ТОЧКА/gi.test(bankName)) {
		return true;
	}
	if (/СБЕРБАНК/gi.test(bankName)) {
		return true;
	}
	if (/(ТБАНК)|(Т-БАНК)/gi.test(bankName)) {
		return true;
	}
	if (/ТИНЬКОФФ БАНК/gi.test(bankName)) {
		return true;
	}

	return false;
}

export function getCMSGUID(bankName: string) {
	if (/(АЛЬФА-БАНК)|(АЛЬФАБАНК)/gi.test(bankName)) {
		return 'ALFA_BANK_BNK_CMS';
	}
	if (/МОДУЛЬБАНК/gi.test(bankName)) {
		return 'MODULE_BANK_BNK_CMS';
	}
	if (/ТОЧКА/gi.test(bankName)) {
		return 'TOCHKA_BANK_BNK_CMS';
	}
	if (/СБЕР/gi.test(bankName)) {
		return 'SBERBANK_BNK_CMS';
	}
	if (/(ТБАНК)|(Т-БАНК)/gi.test(bankName)) {
		return 'TINKOFF_BANK_BNK_CMS';
	}
	if (/ТИНЬКОФФ БАНК/gi.test(bankName)) {
		return 'TINKOFF_BANK_BNK_CMS';
	}
	if (/ОТКРЫТИЕ/gi.test(bankName)) {
		return 'OPEN_BANK_BNK_CMS';
	}
	if (/УРАЛСИБ/gi.test(bankName)) {
		return 'URALSIB_BNK_CMS';
	}
}

interface IFundsRegisterStatisticsList {
	[prop: string]: FundsRegisterStatistics;
}

export function extractFundsRegisterIdAfterImport(
	frStatistics: IFundsRegisterStatisticsList,
	registerNumber: string,
): number {
	const frStatistic = _.find(frStatistics, fr => {
		const isEqualRegisterNumber = fr.FundsRegister.RegisterNumber === registerNumber;
		const isBankAccount = fr.FundsRegister.CLASSIFIER === 'BANK_ACCOUNT_FUNDS_REGISTER';

		return isEqualRegisterNumber && isBankAccount;
	});

	if (frStatistic) {
		return frStatistic.FundsRegister.ID;
	}

	return -1;
}

export function formatEntity(name = '') {
	const pattern =
		/"|'|«|»|\s?Акционерное общество закрытого типа\s|\s?Общество с ограниченной ответственностью\s|\s?Публичное акционерное общество\s|\s?Закрытое акционерное общество\s|\s?Открытое акционерное общество\s|\s?Акционерное общество\s|\s?Индивидуальный предприниматель\s/gim;
	const formatted = name.replace(pattern, '').trim();

	return formatted;
}

export function makeEllipsis(str = '', symbolsCount = 100) {
	const formatted = str.length > symbolsCount ? str.substr(0, symbolsCount) + '...' : str;

	return formatted;
}

export function isConnectedToCMS(register: FundsRegister) {
	if (register.CashManagementSystem) {
		return true;
	}

	return false;
}

export function formatDate(date: string) {
	return moment(date, 'DD-MM-YYYY HH:mm:ss').format('DD.MM.YYYY');
}

export function sortDescBy<T>(
	items: Array<T>,
	selectors: Array<{
		fn: (item: T) => any;
		isDate?: boolean;
	}>,
): Array<T> {
	const immItems = [...items];
	const compare = (a, b, selector, isDate) => {
		if (!isDate) {
			if (selector(a) === selector(b)) return 0;
			return selector(a) < selector(b) ? 1 : -1;
		} else {
			if (selector(a) === selector(b)) return 0;
			const [dayA, monthA, yearA] = selector(a).split('-').map(Number);
			const [dayB, monthB, yearB] = selector(b).split('-').map(Number);
			const isBefore = yearB * 10000 + monthB * 100 + dayB * 1 - (yearA * 10000 + monthA * 100 + dayA * 1) > 0;

			return isBefore ? 1 : -1;
		}
	};
	const sort = (items, selectors) => {
		items.sort((a, b) => {
			for (let i = 0; i < selectors.length; i++) {
				if (compare(a, b, selectors[i].fn, selectors[i].isDate) === 0 && selectors[i + 1]) {
					return compare(a, b, selectors[i + 1].fn, selectors[i + 1].isDate);
				}
				return compare(a, b, selectors[i].fn, selectors[i].isDate);
			}
		});
	};

	sort(immItems, selectors);

	return immItems;
}

export function sortAscBy<T>(
	items: Array<T>,
	selectors: Array<{
		fn: (item: T) => any;
		isDate?: boolean;
	}>,
): Array<T> {
	const immItems = [...items];
	const compare = (a, b, selector, isDate) => {
		if (!isDate) {
			if (selector(a) === selector(b)) return 0;
			return selector(a) < selector(b) ? -1 : 1;
		} else {
			if (selector(a) === selector(b)) return 0;
			const [dayA, monthA, yearA] = selector(a).split('-').map(Number);
			const [dayB, monthB, yearB] = selector(b).split('-').map(Number);
			const isBefore = yearB * 10000 + monthB * 100 + dayB * 1 - (yearA * 10000 + monthA * 100 + dayA * 1) > 0;

			return isBefore ? -1 : 1;
		}
	};
	const sort = (items, selectors) => {
		items.sort((a, b) => {
			for (let i = 0; i < selectors.length; i++) {
				if (compare(a, b, selectors[i].fn, selectors[i].isDate) === 0 && selectors[i + 1]) {
					return compare(a, b, selectors[i + 1].fn, selectors[i + 1].isDate);
				}
				return compare(a, b, selectors[i].fn, selectors[i].isDate);
			}
		});
	};

	sort(immItems, selectors);

	return immItems;
}

export function sortDescByDate<T>(
	items: Array<T>,
	selectors: Array<{
		fn: (item: T) => any;
	}>,
): Array<T> {
	const immItems = [...items];
	const compare = (a, b, selector) => {
		if (selector(a) === selector(b)) return 0;
		const selectorA = selector(a).slice(0, 10);
		const selectorB = selector(b).slice(0, 10);
		const [dayA, monthA, yearA] = selectorA.split('-').map(Number);
		const [dayB, monthB, yearB] = selectorB.split('-').map(Number);
		const isBefore = yearB * 10000 + monthB * 100 + dayB * 1 - (yearA * 10000 + monthA * 100 + dayA * 1) > 0;

		return isBefore ? 1 : -1;
	};
	const sort = (items, selectors) => {
		items.sort((a, b) => {
			for (let i = 0; i < selectors.length; i++) {
				if (compare(a, b, selectors[i].fn, selectors[i].isDate) === 0 && selectors[i + 1]) {
					return compare(a, b, selectors[i + 1].fn, selectors[i + 1].isDate);
				}
				return compare(a, b, selectors[i].fn, selectors[i].isDate);
			}
		});
	};

	sort(immItems, selectors);

	return immItems;
}

// Create Base64 Object
export const Base64 = {
	encode: (str: string) => window.btoa(str),
	decode: (str: string) => window.atob(str),
};

//deprecated method, use detectIsEmail
export function checkEmail(email: string) {
	return detectIsEmail(email);
}

export function escapeHtmlEntities(text: string) {
	if (typeof text !== 'string') return text;
	const entityTable = {
		60: 'lt',
		62: 'gt',
		34: 'quot',
		38: 'amp',
		39: 'apos',
	};
	return text.replace(/[\u00A0-\u2666<>\&'"]/g, function (c) {
		return '&' + (entityTable[c.charCodeAt(0)] || '#' + c.charCodeAt(0)) + ';';
	});
}

export function getURLParameterByName(name: string) {
	name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
	const regex = new RegExp('[\\?&]' + name + '=([^&#]*)'),
		results = regex.exec(location.search);
	return results == null ? '' : escapeHtmlEntities(decodeURIComponent(results[1].replace(/\+/g, ' ')));
}

export function getInvoiceTotal(invoice: Invoice): number {
	let total = 0;
	let totalTax = 0;
	invoice.Items.forEach(item => {
		const taxRate = Math.abs(item.ItemTaxRate);

		totalTax += (item.ItemAmountTotal * taxRate) / (1 + taxRate);
		total += item.ItemAmountTotal;
	});

	return total;
}

export function getInvoiceStatuses(invoice: Invoice) {
	const totalPaid = invoice.Payments.reduce((acc, el) => acc + el.PaymentAmount, 0);
	const totalInvoice = getInvoiceTotal(invoice);
	const isDraft = invoice.Status === 'DRAFT';
	const isPaymentDraftExist = !!invoice.Payments.find(item => item.PaymentState.Code === 'DRAFT');
	const isSentToCMS = invoice.Status === 'PAYMENT_IN_PROCESS';
	const isPartiallyPaid = totalPaid > 0 && totalPaid < totalInvoice;
	const isPaid = invoice.Status === 'PAID' && !isPartiallyPaid;
	const isNotPaid = invoice.Status === 'ISSUED';
	const isOverdue = invoice.Status === 'OVERDUE';

	return {
		isDraft,
		isPaymentDraftExist,
		isSentToCMS,
		isPartiallyPaid,
		isPaid,
		isNotPaid,
		isOverdue,
	};
}

export function buildTLongArray(values: Array<number>) {
	return values.map(value => {
		const tLong = new api.servicepackage.TLong();
		tLong.Value = value;
		return tLong;
	});
}

export function getIndexOfQuarter(
	currentYear: number,
	startYear: number,
	currentQuarter: number,
	startQuarter: number,
) {
	return (currentYear - startYear) * 4 + (currentQuarter - startQuarter);
}

export function getIndexOfMonth(currentYear: number, startYear: number, currentMonth: number, startMonth: number) {
	return (currentYear - startYear) * 12 + (currentMonth - startMonth);
}

export function getMonthLables(dateStart: string, dateEnd: string, withPrevYear?: boolean) {
	const dateStartMoment = moment(dateStart, 'DD-MM-YYYY').startOf('month');
	const dateEndMoment = moment(dateEnd, 'DD-MM-YYYY').endOf('month').add(1, 'day');
	const diffInMonth = dateEndMoment.diff(dateStartMoment, 'month');
	const startMonth = dateStartMoment.format('MMMM');
	const startYear = dateStartMoment.format('YYYY');

	return Array(diffInMonth)
		.fill('')
		.map((item, index) => {
			if (index === 0) {
				if (withPrevYear) {
					const prevYear = moment(dateStart, 'DD-MM-YYYY').subtract(1, 'year').format('YYYY');

					return `${startMonth} ${prevYear}/${startYear}`;
				}

				return `${startMonth} ${startYear}`;
			}

			const nextDate = dateStartMoment.add(1, 'month');
			const nextMonth = nextDate.format('MMMM');
			const nextYear = nextDate.format('YYYY');

			if (withPrevYear) {
				const prevYear = moment(nextYear, 'YYYY').subtract(1, 'year').format('YYYY');

				return `${nextMonth} ${prevYear}/${nextYear}`;
			}

			return `${nextMonth} ${nextYear}`;
		});
}

export function getQuarterLabels(dateStart: string, dateEnd: string, withPrevYear?: boolean) {
	const dateStartMoment = moment(dateStart, 'DD-MM-YYYY').startOf('quarter');
	const dateEndMoment = moment(dateEnd, 'DD-MM-YYYY').endOf('quarter').add(1, 'day');
	const diffInQuarters = dateEndMoment.diff(dateStartMoment, 'quarter');
	const startQuarter = dateStartMoment.format('Q');
	const startYear = dateStartMoment.format('YYYY');
	const quarters = {
		1: 'I',
		2: 'II',
		3: 'III',
		4: 'IV',
	};

	return Array(diffInQuarters)
		.fill('')
		.map((item, index) => {
			if (index === 0) {
				if (withPrevYear) {
					const prevYear = moment(dateStart, 'DD-MM-YYYY').subtract(1, 'year').format('YYYY');

					return `${quarters[startQuarter]} кв. ${prevYear}/${startYear}`;
				}
				return `${quarters[startQuarter]} кв. ${startYear}`;
			}

			const nextDate = dateStartMoment.add(1, 'quarter');
			const nextQuarter = nextDate.format('Q');
			const nextYear = nextDate.format('YYYY');

			if (withPrevYear) {
				const prevYear = moment(nextYear, 'YYYY').subtract(1, 'year').format('YYYY');

				return `${quarters[nextQuarter]} кв. ${prevYear}/${nextYear}`;
			}

			return `${quarters[nextQuarter]} кв. ${nextYear}`;
		});
}

export function formatNumberToFinancial(value): number {
	return +Number.parseFloat(value).toFixed(2);
}

export function isDifferentArrayElements<T>(arr1: Array<T>, arr2: Array<T>): boolean {
	return _.intersection(arr1, arr2).length !== Math.max(arr1.length, arr2.length);
}

export function isFreemiumTariff(tariff: Tariff): boolean {
	return !tariff.Rates || tariff.Rates.length === 0 || !!_.find(tariff.Rates, rate => rate.Rate === 0);
}

export function escape(text: string) {
	return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

export function sendAnalyticsAction(event) {
	if (typeof sendActionToGoogleTagManager !== 'undefined' && !isLocalhost()) {
		sendActionToGoogleTagManager(event);
	}
}

export function isInteger(value: number) {
	return (value ^ 0) === value;
}

function base64toBlob(base64Data, contentType, sliceSize?) {
	contentType = contentType || '';
	sliceSize = sliceSize || 512;

	const byteCharacters = atob(base64Data);
	const byteArrays = [];

	for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		const slice = byteCharacters.slice(offset, offset + sliceSize);

		const byteNumbers = new Array(slice.length);
		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}

		const byteArray = new Uint8Array(byteNumbers);

		byteArrays.push(byteArray);
	}

	const blob = new Blob(byteArrays, { type: contentType });
	return blob;
}

interface IExportDataToExcelParams {
	fileName: string;
	headerRows?: Array<{ name: string; value: number | string; style?: string }>;
	columnsMetadata?: {};
	rowsMetadata?: Array<{}>;
	sheetName?: string;
	createCustomHeader?: (worksheet, data: Array<any>, stylesheet, styles: Record<string, number>) => void;
}

export async function exportDataToExcel(
	dataSource: Array<{}>,
	dataSourceConfig: Array<{ Name: string; SystemName: string }>,
	params: IExportDataToExcelParams,
) {
	const initialParams = {
		fileName: 'file',
		headerRows: [],
		columnsMetadata: {},
		rowsMetadata: [],
		sheetName: 'Лист',
	};
	const builder = (await import('excel-builder-x')).default;
	const fileSaver = await import('file-saver');
	let { fileName, headerRows, columnsMetadata, rowsMetadata, sheetName, createCustomHeader } = {
		...initialParams,
		...params,
	};
	const workbook = builder.createWorkbook();
	const worksheet = workbook.createWorksheet({ name: sheetName });
	const stylesheet = workbook.getStyleSheet();
	const styles = {
		money: stylesheet.createFormat({
			alignment: { horizontal: 'right' },
			format: '# ### ##0.00',
		}).id,
		moneyBold: stylesheet.createFormat({
			alignment: { horizontal: 'right' },
			format: '# ### ##0.00',
			font: { bold: true },
		}).id,
		alignRight: stylesheet.createFormat({
			alignment: { horizontal: 'right' },
			format: '0',
		}).id,
		bold: stylesheet.createFormat({
			font: { bold: true },
		}).id,
	};

	if (!Array.isArray(dataSource[0])) {
		dataSource = [dataSource];
		columnsMetadata = [columnsMetadata];
	}

	const data = [];
	let maxLengthColumns = null;
	let maxLengthColumnsMetadata = null;
	const columnsArray = [];

	dataSource.forEach((_, i) => {
		const columns = dataSourceConfig.map(column => {
			let excelColumn = { id: column.SystemName, name: column.Name };
			const columnMetadata = columnsMetadata[i] ? columnsMetadata[i][column.SystemName] : null;

			if (columnMetadata) {
				excelColumn = {
					...excelColumn,
					...columnMetadata,
				};
			}

			return excelColumn;
		});

		columnsArray.push(columns);
		if (!maxLengthColumns || maxLengthColumns.length < columns.length) {
			maxLengthColumns = columns;
			maxLengthColumnsMetadata = columnsMetadata[i];
		}
	});

	if (typeof createCustomHeader === 'function') {
		headerRows = [];
		createCustomHeader(worksheet, data, stylesheet, styles);
	}

	dataSource.forEach((source, i) => {
		const columns = columnsArray[i];

		[...headerRows].forEach(row => {
			data.push([
				{ value: row.name, metadata: { style: styles.bold } },
				{ value: row.value, metadata: { style: row.style ? styles[row.style] : '' } },
			]);
		});

		headerRows.length > 0 && data.push(maxLengthColumns.map(() => ' '));
		data.push(columns.map(col => ({ value: col.name, metadata: { style: styles.bold } })));

		[...(source as Array<{}>)].forEach((record, i) => {
			const dataRow = columns.map(column => {
				const dataColumn = {
					value: record[column.id],
					metadata: {
						style: null,
						type: null,
					},
				};
				const columnMetadata = maxLengthColumnsMetadata ? maxLengthColumnsMetadata[column.id] : null;
				if (columnMetadata) {
					if (columnMetadata.style && styles[columnMetadata.style]) {
						dataColumn.metadata.style = styles[columnMetadata.style];
					}
					if (columnMetadata.type) {
						dataColumn.metadata.type = columnMetadata.type;
					}
				}

				let rowMetadata = rowsMetadata ? rowsMetadata[i] : null;
				if (rowMetadata && rowMetadata.length) {
					let cellMetadataIndex = null;

					for (let j = 0; j < rowMetadata.length; j++) {
						if (rowMetadata[j][column.id]) {
							cellMetadataIndex = j;
							break;
						}
					}
					rowMetadata = cellMetadataIndex > -1 ? rowMetadata[cellMetadataIndex][column.id] : null;
				}

				if (rowMetadata) {
					if (rowMetadata.style && styles[rowMetadata.style]) {
						dataColumn.metadata.style = styles[rowMetadata.style];
					}

					if (rowMetadata.type) {
						dataColumn.metadata.type = rowMetadata.type;
					}
				}
				return dataColumn;
			});
			data.push(dataRow);
		});
	});

	worksheet.setData(data);
	worksheet.setColumns(maxLengthColumns);
	workbook.addWorksheet(worksheet);

	const base64Data = builder.createFile(workbook);
	const blob = base64toBlob(base64Data, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
	fileSaver.saveAs(blob, `${fileName}.xlsx`);
}

export function isScrolledIntoView(el: Element, scrollContainer?: HTMLElement): boolean {
	const rect = el.getBoundingClientRect();
	const scrollContainerRect = scrollContainer && scrollContainer.getBoundingClientRect();
	const isVisible = scrollContainer
		? !(rect.top < scrollContainerRect.top || rect.bottom > scrollContainerRect.top + rect.height)
		: rect.top >= 0 && rect.bottom <= window.innerHeight;

	return isVisible;
}

export function formatNumber(value: number, fractions = 2, locale = 'ru') {
	return new Intl.NumberFormat(locale, {
		style: 'decimal',
		minimumFractionDigits: fractions,
		maximumFractionDigits: fractions,
	}).format(value);
}

export function getQuarterName(number: number) {
	const quarters = {
		1: 'I',
		2: 'II',
		3: 'III',
		4: 'IV',
	};

	return quarters[number];
}

export function sortTreeByAlphabet<T>(flatTree: Array<TreeNode<T>>, nameSelector: (el: TreeNode<T>) => string): void {
	const sort = (itemList: Array<TreeNode<T>>) => {
		itemList.sort((a, b) => {
			if (!nameSelector(a) || !nameSelector(b)) {
				return 0;
			}

			return nameSelector(a).toLocaleLowerCase() > nameSelector(b).toLocaleLowerCase() ? 1 : -1;
		});
	};

	sort(flatTree);

	flatTree.forEach(el => {
		if (el.ChildItems && el.ChildItems.length > 0) {
			sortTreeByAlphabet(el.ChildItems, nameSelector);
		}
	});
}

export function getPrepositionalMonthNames(index: number): string {
	const monthNames = [
		'январе',
		'феврале',
		'марте',
		'апреле',
		'мае',
		'июне',
		'июле',
		'августе',
		'сентябре',
		'октябре',
		'ноябре',
		'декабре',
	];

	return monthNames[index];
}

type ICashflowAccessByFrIDParams = {
	fundsRegister: FundsRegister;
	type?: 'ALL' | 'RECEIPT' | 'CHARGE';
	level?: 'WRITE' | 'READ';
};

export function getCashflowAccessByFrID(params: ICashflowAccessByFrIDParams) {
	const { fundsRegister, type = 'ALL', level = 'WRITE' } = params;
	const {
		hasReadAccessToIncome,
		hasReadAccessToExpense,
		hasReadAccessToAllDirections,
		hasWriteAccessToIncome,
		hasWriteAccessToExpense,
		hasWriteAccessToAllDirections,
	} = getFundsRegistersAccessFlags([fundsRegister]);

	if (type === 'ALL') {
		if (level === 'WRITE') {
			return hasWriteAccessToAllDirections;
		}

		if (level === 'READ') {
			return hasReadAccessToAllDirections;
		}
	}

	if (type === 'RECEIPT') {
		if (level === 'WRITE') {
			return hasWriteAccessToIncome;
		}

		if (level === 'READ') {
			return hasReadAccessToIncome;
		}
	}

	if (type === 'CHARGE') {
		if (level === 'WRITE') {
			return hasWriteAccessToExpense;
		}

		if (level === 'READ') {
			return hasReadAccessToExpense;
		}
	}

	return false;
}

export function getFileURL(url: string): string {
	return (/^blob/.test(url) || /^data/.test(url) || url[0] === '/' ? '' : '/') + url;
}

export function logBuildInfo() {
	console.log('mode: ', process.env.NODE_ENV);
	console.log('branch: ', process.env.GIT_BRANCH);
	console.log('commithash: ', process.env.GIT_COMMITHASH);
}

export function makeBlankSpace() {
	return '\u00A0';
}

export function getIsFactPLOperation(pl: PLOperation): boolean {
	return pl.Status === 2;
}

export const Cookie = {
	getCookie: name => {
		const matches = new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)').exec(
			document.cookie,
		);
		return matches ? decodeURIComponent(matches[1]) : undefined;
	},
	setCookie: (name, value, options?) => {
		options = options || {};

		let expires = options.expires;

		if (typeof expires === 'number' && expires) {
			const d = new Date();
			d.setTime(d.getTime() + expires * 1000);
			expires = options.expires = d;
		}
		if (expires && expires.toUTCString) {
			options.expires = expires.toUTCString();
		}

		value = encodeURIComponent(value);

		let updatedCookie = name + '=' + value;

		for (const propName in options) {
			updatedCookie += '; ' + propName;
			const propValue = options[propName];
			if (propValue !== true) {
				updatedCookie += '=' + propValue;
			}
		}

		document.cookie = updatedCookie;
	},
};

export function getFormData(payload: any): FormData {
	const formData = new FormData();

	Object.keys(payload).forEach(key => formData.append(key, payload[key]));

	return formData;
}
