import * as moment from 'moment';

import { BASE_DATE_FORMAT, SQL_TIME_FORMAT } from '@shared/constants/time';
import * as analysispackage from 'analysispackage-api';


export type FetchAbcSegmentsOptions = {
	dateRange: DateRange;
	cashflowTypeIDs: Array<number>;
	legalEntityID: number;
	income: boolean;
};

const analysisApi = {
	package: analysispackage as typeof analysispackageApi,
	client: new analysispackage.SegmentationServiceClient() as SegmentationServiceClient,
	fetchAbcSegments: (options: FetchAbcSegmentsOptions) => {
		const {
			dateRange,
			cashflowTypeIDs,
			legalEntityID,
			income,
		} = options;
	
		return new Promise<Array<ABCAnalysisSegment>>((resolve) => {
			const request = new analysispackage.DatasetSegmentationRequest();
			const hasLegalEntity = legalEntityID > 0;

			request.DatasetSchemaGUID = '94e1be6d-7c61-4e93-93c1-d417ff6363ad';
			request.FactorField = 'PaymentAmount';
			request.MemberUIDField = 'CounterpartyID';
			request.ParameterValues.dateBegin = moment(dateRange.dateStart, BASE_DATE_FORMAT).format(SQL_TIME_FORMAT);
			request.ParameterValues.dateEnd = moment(dateRange.dateEnd, BASE_DATE_FORMAT).endOf('day').format(SQL_TIME_FORMAT);
			request.ParameterValues.direction = income ? 2 : 1;
			request.ParameterValues.legalEntityClause = hasLegalEntity ? `= ${legalEntityID}` : 'IS NOT NULL';
			request.ParameterValues.intercompanyModeList = 0;
			request.ParameterValues.cashFlowTypeList = `(${cashflowTypeIDs.join(',')})`;
			analysisApi.client.doDatasetABCAnalysis(request, result => {
				resolve(result)
			});
		});
	},
};

export {
	analysisApi,
};
