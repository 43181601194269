import styled, { css } from 'styled-components';

const Root = styled.div`
	position: relative;
	width: 100%;
	height: auto;
`;

const HeaderLayout = styled.div`
	width: 100%;
	height: auto;
	margin-bottom: 10px;
`;

const ContentLayout = styled.div``;

const RightContentLayout = styled.div``;

const RadioLayout = styled.div`
	margin-top: 16px;

	& * {
		font-size: 14px !important;
	}
`;

const radioButtonGroupStyle = {
	marginTop: '0',
	display: 'flex',
	flexFlow: 'column nowrap',
	fontSize: '14px',
};

const radioButtonStyle = {
	marginBottom: '10px',
};

const IndividualContentLayout = styled.div``;

const Sign = styled.p`
	font-size: 14px;
	line-height: 1.43;
	text-align: left;
	margin: 0 0 10px 0;

	${p => css`
		color: ${p.theme.palette.label};
	`}
`;

const LogotypesLayout = styled.div`
	width: calc(100% + 20px);
	height: auto;
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;
	align-items: center;
	margin: 0 -10px;
`;

interface ILogo {
	type: 'APPLE_PAY' | 'VISA' | 'MASTER_CARD' | 'MIR';
}

const Logo = styled.div`
	width: 70px;
	height: 50px;
	margin: 10px;
	display: inline-block;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	${(props: ILogo) =>
		props.type === 'APPLE_PAY' &&
		`
		background-image: url(${require('@assets/img/logos/payment-systems/apple-pay.svg')});
	`}

	${(props: ILogo) =>
		props.type === 'VISA' &&
		`
		background-image: url(${require('@assets/img/logos/payment-systems/visa.svg')});
	`}

	${(props: ILogo) =>
		props.type === 'MASTER_CARD' &&
		`
		background-image: url(${require('@assets/img/logos/payment-systems/master-card.svg')});
	`}

	${(props: ILogo) =>
		props.type === 'MIR' &&
		`
		background-image: url(${require('@assets/img/logos/payment-systems/mir.svg')});
	`}
`;

const LegalEntityContentLayout = styled.div``;

const InfoLayout = styled.div`
	margin-bottom: 20px;
`;

const FormLayout = styled.div`
	width: 100%;
	height: auto;
`;

const RequisitesInfoLayout = styled.div`
	margin: 10px 0;
`;

export {
	Root,
	HeaderLayout,
	ContentLayout,
	RightContentLayout,
	RadioLayout,
	radioButtonGroupStyle,
	radioButtonStyle,
	IndividualContentLayout,
	Sign,
	LogotypesLayout,
	Logo,
	LegalEntityContentLayout,
	InfoLayout,
	FormLayout,
	RequisitesInfoLayout,
};
