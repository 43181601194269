import { Store } from 'redux';

import { counterpartyApi } from '@core/api/counterparty';
import { fundsApi } from '@core/api/funds';
import { invoiceApi } from '@core/api/invoice';
import { organizationApi } from '@core/api/organization';
import { runCounterpartiesInvalidationEffect } from '@counterparties/actions/invalidators';
import { invalidateContracts } from '@documents/actions/invalidators';
import { runEmployeesInvalidationEffect } from '@employees/actions/invalidators';
import { invalidateFundsRegisterStatistics } from '@funds-registers/actions/invalidators';
import { securitySelectorsPack } from '@platform/selectors';
import { RunPatchEffectOptions } from '@shared/actions/main.actions';
import { types } from '@shared/actions/types';
import { IAppState } from '@store';
import { runTenantLegalEntitiesInvalidationEffect } from '@tenant-legal-entities/actions/invalidators';
import { detectIsNaturalPerson } from '@utils/counterparty';
import { detectIsUndefined } from '@utils/helpers';

export enum PatchItemType {
	LEGAL_ENTITY_FROM_LEGAL_ENTITY = 'legal_entity_from_legal_entity',
	TENANT_ENTITY_FROM_LEGAL_ENTITY = 'tenant_entity_from_legal_entity',
	TENANT_ENTITY_FROM_COUNTERPARTY_BRIEF = 'tenant_entity_from_counterparty_brief',
	LEGAL_ENTITY_FROM_COUNTERPARTY_BRIEF = 'legal_entity_from_counterparty_brief',
	NATURAL_PERSON_FROM_COUNTERPARTY_BRIEF = 'natural_person_from_counterparty_brief',
	COUNTERPARTY_FROM_COUNTERPARTY_BRIEF = 'counterparty_from_counterparty_brief',
	BANK_ACCOUNT_FUNDS_REGISTER_FROM_FUNDS_REGISTER_BRIEF = 'bank_account_funds_register_from_funds_register_brief',
	EMPLOYEE_FROM_EMPLOYEE_BRIEF = 'employee_from_employee_brief',
	COUNTERPARTY_FROM_COUNTERPARTY_ACCOUNT = 'counterparty_from_counterparty_account',
	CONTRACT_FROM_CONTRACT_BRIEF = 'contract_from_contract_brief',
	COUNTERPARTY_FROM_EMPLOYEE_BRIEF = 'counterparty_from_employee_brief',
	LEGAL_ENTITY_FROM_EMPLOYEE_BRIEFS = 'legal_entity_from_employee_briefs',
	TENANT_ENTITY_FROM_EMPLOYEE_BRIEFS = 'tenant_entity_from_employee_briefs',
}

export type PatchItem<T extends object = {}, S = T> = {
	type: PatchItemType;
	tag: string;
	ownerTags?: Array<string>;
	getObject: () => Partial<T>;
	getFallback: () => Fallback;
	skipInvalidation?: boolean;
	onComplete: (item: S, x?: T) => void;
};

type Fallback = { ID: number };

function createPatchEffect() {
	let cache: Record<string, Fallback> = {};

	return (store: Store<IAppState>) =>
		(next: (action: AsyncAction<unknown>) => void) =>
		(action: AsyncAction<unknown>) => {
			next(action);

			const methodsMap: Record<PatchItemType, (p: PatchItem) => Promise<any>> = {
				[PatchItemType.LEGAL_ENTITY_FROM_LEGAL_ENTITY]: patchLegalEntityFromLegalEntity,
				[PatchItemType.TENANT_ENTITY_FROM_LEGAL_ENTITY]: patchTenantEntityFromLegalEntity,
				[PatchItemType.TENANT_ENTITY_FROM_COUNTERPARTY_BRIEF]: patchTenantEntityFromCounterpartyBrief,
				[PatchItemType.LEGAL_ENTITY_FROM_COUNTERPARTY_BRIEF]: patchLegalEntityFromCounterpartyBrief,
				[PatchItemType.NATURAL_PERSON_FROM_COUNTERPARTY_BRIEF]: patchNaturalPersonFromCounterpartyBrief,
				[PatchItemType.COUNTERPARTY_FROM_COUNTERPARTY_BRIEF]: patchCounterpartyFromCounterpartyBrief,
				[PatchItemType.BANK_ACCOUNT_FUNDS_REGISTER_FROM_FUNDS_REGISTER_BRIEF]:
					patchBankAccountFundsRegisterFromFundsRegisterBrief,
				[PatchItemType.EMPLOYEE_FROM_EMPLOYEE_BRIEF]: patchEmployeeFromEmployeeBrief,
				[PatchItemType.COUNTERPARTY_FROM_COUNTERPARTY_ACCOUNT]: patchCounterpartyFromCounterpartyAccount,
				[PatchItemType.CONTRACT_FROM_CONTRACT_BRIEF]: patchContractFromContractBrief,
				[PatchItemType.COUNTERPARTY_FROM_EMPLOYEE_BRIEF]: patchCounterpartyFromEmployeeBrief,
				[PatchItemType.LEGAL_ENTITY_FROM_EMPLOYEE_BRIEFS]: patchLegalEntityFromEmployeeBriefs,
				[PatchItemType.TENANT_ENTITY_FROM_EMPLOYEE_BRIEFS]: patchTenantEntityFromEmployeeBriefs,
			};

			if (action.type === types.RUN_PATCH_EFFECT) {
				const updateAction = action as StaticAction<RunPatchEffectOptions>;
				const { items, onComplete } = updateAction.value;
				const state = store.getState();
				const canChangeData = securitySelectorsPack.selectCanChangeAnyData(state);
				const makeWorker = (item: PatchItem) => () =>
					new Promise(resolve => {
						const method = methodsMap[item.type];

						method(item).then(resolve);
					});

				if (!canChangeData) {
					onComplete();
					cache = {};

					return;
				}

				(async () => {
					const workers = items.map(x => makeWorker(x));

					for (const worker of workers) {
						await worker();
					}

					onComplete();
					cache = {};
				})();
			}

			function patchLegalEntityFromLegalEntity(patchItem: PatchItem<LegalEntity, LegalEntity>) {
				return new Promise(async resolve => {
					const { tag, skipInvalidation, getObject, getFallback, onComplete } = patchItem;
					const item = getObject();

					cache[tag] = getFallback();
					if (!item) return resolve(null);

					const ID = item.ID || -1;
					const {
						name,
						taxCode,
						additionalTaxCode,
						stateRegistrationCode,
						address,
						isSoleProprietor,
						logoUrl,
						companySealImgURL,
						stateIndustry,
					} = getLegalEntityFields(item);
					let final: LegalEntity = null;

					if (ID > 0) {
						const sourceLegalEntity = (await counterpartyApi.counterparty.fetchCounterpartyByID(ID)) as LegalEntity;

						if (!sourceLegalEntity) return resolve(null);

						const legalEntity: LegalEntity = {
							...sourceLegalEntity,
							Name: name || sourceLegalEntity.Name,
							TaxCode: taxCode || sourceLegalEntity.TaxCode,
							AdditionalTaxCode: additionalTaxCode || sourceLegalEntity.AdditionalTaxCode || '',
							StateRegistrationCode: stateRegistrationCode || sourceLegalEntity.StateRegistrationCode || '',
							LegalAddress: address || sourceLegalEntity.LegalAddress || '',
							SoleProprietor:
								typeof isSoleProprietor === 'boolean' ? isSoleProprietor : sourceLegalEntity.SoleProprietor,
							LogoURL: logoUrl || (logoUrl === null ? '' : sourceLegalEntity.LogoURL),
							CompanySealImageURL:
								companySealImgURL || (companySealImgURL === null ? '' : sourceLegalEntity.CompanySealImageURL),
							StateIndustry: stateIndustry || sourceLegalEntity.StateIndustry,
						};
						final = (await counterpartyApi.counterparty.updateCounterparty(legalEntity)) as LegalEntity;
					} else {
						const legalEntity: LegalEntity = {
							...new counterpartyApi.package.LegalEntity(),
							Name: name,
							TaxCode: taxCode,
							AdditionalTaxCode: additionalTaxCode,
							StateRegistrationCode: stateRegistrationCode,
							LegalAddress: address,
							SoleProprietor: typeof isSoleProprietor === 'boolean' ? isSoleProprietor : false,
							LogoURL: logoUrl,
							CompanySealImageURL: companySealImgURL,
							StaffCount: 0,
							LegalEntityType: {
								...new counterpartyApi.package.LegalEntityType(),
								ID: 1,
							},
							StateIndustry: stateIndustry || null,
						};
						final = (await counterpartyApi.counterparty.addCounterparty(legalEntity)) as LegalEntity;
					}

					if (!skipInvalidation) {
						store.dispatch(runCounterpartiesInvalidationEffect());
					}
					cache[tag] = final;
					onComplete(final);
					resolve(null);
				});
			}

			function patchTenantEntityFromLegalEntity(patchItem: PatchItem<LegalEntity, LegalEntity>) {
				return new Promise(async resolve => {
					const { tag, skipInvalidation, getObject, getFallback, onComplete } = patchItem;
					const item = getObject();

					cache[tag] = getFallback();
					if (!item) return resolve(null);

					const ID = item.ID || -1;
					const {
						name,
						taxCode,
						additionalTaxCode,
						stateRegistrationCode,
						address,
						isSoleProprietor,
						logoUrl,
						companySealImgURL,
						stateIndustry,
					} = getLegalEntityFields(item);
					let final: LegalEntity = null;

					if (ID > 0) {
						const sourceTenantEntity = (await counterpartyApi.counterparty.fetchCounterpartyByID(ID)) as LegalEntity;

						if (!sourceTenantEntity) return resolve(null);

						const tenantEntity: LegalEntity = {
							...sourceTenantEntity,
							TenantLegalEntity: true,
							Name: name || sourceTenantEntity.Name,
							TaxCode: taxCode || sourceTenantEntity.TaxCode,
							AdditionalTaxCode: additionalTaxCode || sourceTenantEntity.AdditionalTaxCode || '',
							StateRegistrationCode: stateRegistrationCode || sourceTenantEntity.StateRegistrationCode || '',
							LegalAddress: address || sourceTenantEntity.LegalAddress || '',
							SoleProprietor:
								typeof isSoleProprietor === 'boolean' ? isSoleProprietor : sourceTenantEntity.SoleProprietor,
							LogoURL: logoUrl || (logoUrl === null ? '' : sourceTenantEntity.LogoURL),
							CompanySealImageURL:
								companySealImgURL || (companySealImgURL === null ? '' : sourceTenantEntity.CompanySealImageURL),
							StateIndustry: stateIndustry || sourceTenantEntity.StateIndustry,
						};
						final = (await counterpartyApi.counterparty.updateCounterparty(tenantEntity)) as LegalEntity;
					} else {
						const tenantEntity: LegalEntity = {
							...new counterpartyApi.package.LegalEntity(),
							TenantLegalEntity: true,
							Name: name,
							TaxCode: taxCode,
							AdditionalTaxCode: additionalTaxCode,
							StateRegistrationCode: stateRegistrationCode,
							LegalAddress: address,
							SoleProprietor: typeof isSoleProprietor === 'boolean' ? isSoleProprietor : false,
							LogoURL: logoUrl,
							CompanySealImageURL: companySealImgURL,
							StaffCount: 0,
							LegalEntityType: {
								...new counterpartyApi.package.LegalEntityType(),
								ID: 1,
							},
							StateIndustry: stateIndustry || null,
						};
						final = (await counterpartyApi.counterparty.addCounterparty(tenantEntity)) as LegalEntity;
					}

					if (!skipInvalidation) {
						store.dispatch(runTenantLegalEntitiesInvalidationEffect());
					}
					cache[tag] = final;
					onComplete(final);
					resolve(null);
				});
			}

			function patchTenantEntityFromCounterpartyBrief(patchItem: PatchItem<CounterpartyBrief, LegalEntity>) {
				return new Promise(async resolve => {
					const { tag, getObject, getFallback, onComplete } = patchItem;
					const item = getObject();

					cache[tag] = getFallback();
					if (!item) return resolve(null);

					const ID = item.ID || -1;
					const {
						name,
						taxCode,
						additionalTaxCode,
						stateRegistrationCode,
						address,
						isSoleProprietor,
						logoUrl,
						companySealImgURL,
					} = getCounterpartyBriefFields(item);
					let final: LegalEntity = null;

					if (ID > 0) {
						const sourceTenantEntity = (await counterpartyApi.counterparty.fetchCounterpartyByID(ID)) as LegalEntity;

						if (!sourceTenantEntity) return resolve(null);

						const tenantEntity: LegalEntity = {
							...sourceTenantEntity,
							Name: name || sourceTenantEntity.Name,
							TaxCode: taxCode || sourceTenantEntity.TaxCode,
							AdditionalTaxCode: additionalTaxCode || sourceTenantEntity.AdditionalTaxCode || '',
							StateRegistrationCode:
								typeof stateRegistrationCode === 'string'
									? stateRegistrationCode
									: sourceTenantEntity.StateRegistrationCode || '',
							LegalAddress: address || sourceTenantEntity.LegalAddress || '',
							SoleProprietor:
								typeof isSoleProprietor === 'boolean' ? isSoleProprietor : sourceTenantEntity.SoleProprietor,
							LogoURL: logoUrl || (logoUrl === null ? '' : sourceTenantEntity.LogoURL),
							CompanySealImageURL:
								companySealImgURL || (companySealImgURL === null ? '' : sourceTenantEntity.CompanySealImageURL),
						};
						final = (await counterpartyApi.counterparty.updateCounterparty(tenantEntity)) as LegalEntity;
					} else {
						const tenantEntity: LegalEntity = {
							...new counterpartyApi.package.LegalEntity(),
							TenantLegalEntity: true,
							Name: name,
							TaxCode: taxCode,
							AdditionalTaxCode: additionalTaxCode,
							StateRegistrationCode: stateRegistrationCode,
							LegalAddress: address,
							SoleProprietor: typeof isSoleProprietor === 'boolean' ? isSoleProprietor : false,
							LogoURL: logoUrl,
							CompanySealImageURL: companySealImgURL,
							StaffCount: 0,
							LegalEntityType: {
								...new counterpartyApi.package.LegalEntityType(),
								ID: 1,
							},
						};
						final = (await counterpartyApi.counterparty.addCounterparty(tenantEntity)) as LegalEntity;
					}

					store.dispatch(runTenantLegalEntitiesInvalidationEffect());
					cache[tag] = final;
					onComplete(final);
					resolve(null);
				});
			}

			function patchLegalEntityFromCounterpartyBrief(patchItem: PatchItem<CounterpartyBrief, LegalEntity>) {
				return new Promise(async resolve => {
					const { tag, getObject, getFallback, onComplete } = patchItem;
					const item = getObject();

					cache[tag] = getFallback();
					if (!item) return resolve(null);

					const ID = item.ID || -1;
					const {
						name,
						taxCode,
						additionalTaxCode,
						stateRegistrationCode,
						address,
						consigneeAddress,
						isSoleProprietor,
						logoUrl,
					} = getCounterpartyBriefFields(item);
					let final: LegalEntity = null;

					if (ID > 0) {
						const sourceLegalEntity = (await counterpartyApi.counterparty.fetchCounterpartyByID(ID)) as LegalEntity;

						if (!sourceLegalEntity) return resolve(null);

						const legalEntity: LegalEntity = {
							...sourceLegalEntity,
							CLASSIFIER: new counterpartyApi.package.LegalEntity().CLASSIFIER,
							Name: name || sourceLegalEntity.Name,
							TaxCode: taxCode || sourceLegalEntity.TaxCode,
							AdditionalTaxCode: additionalTaxCode || sourceLegalEntity.AdditionalTaxCode || '',
							StateRegistrationCode:
								typeof stateRegistrationCode === 'string'
									? stateRegistrationCode
									: sourceLegalEntity.StateRegistrationCode || '',
							LegalAddress: address || sourceLegalEntity.LegalAddress || '',
							ConsigneeAddress: consigneeAddress || sourceLegalEntity.ConsigneeAddress || '',
							SoleProprietor:
								typeof isSoleProprietor === 'boolean' ? isSoleProprietor : sourceLegalEntity.SoleProprietor,
							LogoURL: logoUrl || (logoUrl === null ? '' : sourceLegalEntity.LogoURL),
						};
						final = (await counterpartyApi.counterparty.updateCounterparty(legalEntity)) as LegalEntity;
					} else {
						const legalEntity: LegalEntity = {
							...new counterpartyApi.package.LegalEntity(),
							Name: name,
							TaxCode: taxCode,
							AdditionalTaxCode: additionalTaxCode,
							StateRegistrationCode: stateRegistrationCode,
							LegalAddress: address,
							ConsigneeAddress: consigneeAddress,
							SoleProprietor: typeof isSoleProprietor === 'boolean' ? isSoleProprietor : false,
							LogoURL: logoUrl,
							StaffCount: 0,
							LegalEntityType: {
								...new counterpartyApi.package.LegalEntityType(),
								ID: 1,
							},
						};
						final = (await counterpartyApi.counterparty.addCounterparty(legalEntity)) as LegalEntity;
					}

					store.dispatch(runCounterpartiesInvalidationEffect());
					cache[tag] = final;
					onComplete(final);
					resolve(null);
				});
			}

			function patchNaturalPersonFromCounterpartyBrief(patchItem: PatchItem<CounterpartyBrief, NaturalPerson>) {
				return new Promise(async resolve => {
					const { tag, getObject, getFallback, onComplete } = patchItem;
					const item = getObject();

					cache[tag] = getFallback();
					if (!item) return resolve(null);

					const ID = item.ID || -1;
					const { name, taxCode, logoUrl } = getCounterpartyBriefFields(item);
					let final: NaturalPerson = null;

					if (ID > 0) {
						const sourceNaturalPerson = (await counterpartyApi.counterparty.fetchCounterpartyByID(ID)) as NaturalPerson;

						if (!sourceNaturalPerson) return resolve(null);

						const naturalPerson: NaturalPerson = {
							...sourceNaturalPerson,
							CLASSIFIER: new counterpartyApi.package.NaturalPerson().CLASSIFIER,
							Name: name || sourceNaturalPerson.Name,
							TaxCode: taxCode || sourceNaturalPerson.TaxCode,
							LogoURL: logoUrl || (logoUrl === null ? '' : sourceNaturalPerson.LogoURL),
						};
						final = (await counterpartyApi.counterparty.updateCounterparty(naturalPerson)) as NaturalPerson;
					} else {
						const naturalPerson: NaturalPerson = {
							...new counterpartyApi.package.NaturalPerson(),
							Name: name,
							TaxCode: taxCode,
							LogoURL: logoUrl,
						};
						final = (await counterpartyApi.counterparty.addCounterparty(naturalPerson)) as NaturalPerson;
					}

					store.dispatch(runCounterpartiesInvalidationEffect());
					cache[tag] = final;
					onComplete(final);
					resolve(null);
				});
			}

			function patchCounterpartyFromCounterpartyBrief(patchItem: PatchItem<CounterpartyBrief, Counterparty>) {
				return new Promise(async resolve => {
					const { tag, getObject, getFallback } = patchItem;
					const item = getObject();

					cache[tag] = getFallback();
					if (!item) return resolve(null);
					const ID = item.ID || -1;
					const isTypeUndefined = detectIsUndefined(item.LegalEntity);
					let isNaturalPerson = item.LegalEntity === false;

					if (ID > 0 && isTypeUndefined) {
						const sourceCounterparty = await counterpartyApi.counterparty.fetchCounterpartyByID(ID);

						if (!sourceCounterparty) return resolve(null);
						isNaturalPerson = detectIsNaturalPerson(sourceCounterparty);
					}

					return isNaturalPerson
						? patchNaturalPersonFromCounterpartyBrief(patchItem).then(() => resolve(null))
						: patchLegalEntityFromCounterpartyBrief(patchItem).then(() => resolve(null));
				});
			}

			function patchBankAccountFundsRegisterFromFundsRegisterBrief(
				patchItem: PatchItem<FundsRegisterBrief, BankAccountFundsRegister>,
			) {
				return new Promise(async resolve => {
					const { tag, ownerTags, getObject, getFallback, onComplete } = patchItem;
					const item = getObject();

					cache[tag] = getFallback();
					if (!item) return resolve(null);

					const [ownerTag] = ownerTags;
					const ID = item.ID || -1;
					const tenantEntity = cache[ownerTag] as Fallback;
					const tenantEntityID = tenantEntity?.ID || -1;
					let final: BankAccountFundsRegister = null;

					if (ID > 0) {
						const sourceFundsRegister = (await fundsApi.fundsRegister.fetchFundsRegisterByID(
							ID,
						)) as BankAccountFundsRegister;

						if (!sourceFundsRegister) return resolve(null);

						const fundsRegister: BankAccountFundsRegister = {
							...sourceFundsRegister,
							Name: item.Name,
							LegalEntity: { ...new counterpartyApi.package.CounterpartyBrief(), ID: tenantEntityID },
						};

						final = (await fundsApi.fundsRegister.updateFundsRegister(fundsRegister)) as BankAccountFundsRegister;
					} else {
						const fundsRegister: BankAccountFundsRegister = {
							...new fundsApi.package.BankAccountFundsRegister(),
							Name: item.Name,
							RegisterNumber: item.Number,
							Bank: item.Bank,
							LegalEntity: { ...new counterpartyApi.package.CounterpartyBrief(), ID: tenantEntityID },
						};
						final = (await fundsApi.fundsRegister.addFundsRegister(fundsRegister)) as BankAccountFundsRegister;
					}

					store.dispatch(invalidateFundsRegisterStatistics());
					cache[tag] = final;
					onComplete(final);
					resolve(null);
				});
			}

			function patchEmployeeFromEmployeeBrief(patchItem: PatchItem<EmployeeBrief, Employee>) {
				return new Promise(async resolve => {
					const { tag, ownerTags, getObject, getFallback, onComplete } = patchItem;
					const item = getObject();

					cache[tag] = getFallback();
					if (!item) return resolve(null);

					const [ownerTag] = ownerTags;
					const ID = item.ID || -1;
					const employer = cache[ownerTag] as Fallback;
					const employerID = employer?.ID || -1;
					const { name, taxCode } = getCounterpartyBriefFields(item);
					let final: Employee = null;

					if (ID > 0) {
						const sourceEmployee = (await counterpartyApi.counterparty.fetchCounterpartyByID(ID)) as Employee;

						if (!sourceEmployee) return resolve(null);

						const employee: Employee = {
							...sourceEmployee,
							Name: name || sourceEmployee.Name,
							TaxCode: taxCode || sourceEmployee.TaxCode,
							MainBusinessRole: {
								...new organizationApi.package.EmployeeBusinessRole(),
								ID: item?.BusinessRole?.ID || sourceEmployee?.MainBusinessRole?.ID || -1,
								Name: item?.BusinessRole?.Name || sourceEmployee?.MainBusinessRole?.Name || '',
							},
							SignImageURL: item.SignImageURL || (item.SignImageURL === null ? '' : sourceEmployee.SignImageURL),
						};
						final = (await counterpartyApi.counterparty.updateCounterparty(employee)) as Employee;
					} else {
						const employee: Employee = {
							...new counterpartyApi.package.Employee(),
							Name: name,
							TaxCode: taxCode,
							MainBusinessRole: {
								...new organizationApi.package.EmployeeBusinessRole(),
								ID: item?.BusinessRole?.ID || -1,
								Name: item?.BusinessRole?.Name || '',
							},
							Employer: {
								...new counterpartyApi.package.CounterpartyBrief(),
								ID: employerID,
							},
							SignImageURL: item.SignImageURL || '',
						};
						final = (await counterpartyApi.counterparty.addCounterparty(employee)) as Employee;
					}

					store.dispatch(runEmployeesInvalidationEffect());
					cache[tag] = final;
					onComplete(final);
					resolve(null);
				});
			}

			function patchCounterpartyFromCounterpartyAccount(patchItem: PatchItem<CounterpartyAccount, Counterparty>) {
				return new Promise(async resolve => {
					const { tag, ownerTags, getObject, getFallback, onComplete } = patchItem;
					const item = getObject();

					cache[tag] = getFallback();
					if (!item) return resolve(null);

					const [ownerTag] = ownerTags;
					const owner = cache[ownerTag] as Fallback;
					const ownerID = owner?.ID || -1;
					const accountID = item.ID || -1;
					let final: Counterparty = null;

					if (ownerID > 0) {
						const sourceCounterparty = (await counterpartyApi.counterparty.fetchCounterpartyByID(
							ownerID,
						)) as Counterparty;
						const accounts: Array<CounterpartyAccount> = [];

						if (!sourceCounterparty) return resolve(null);

						if (accountID > 0) {
							const account = sourceCounterparty.Accounts.find(x => x.ID === accountID);
							if (!account) return resolve(null);
							const newAccount: CounterpartyAccount = {
								...account,
								AccountNumber: item.AccountNumber,
								Bank: item.Bank,
							};
							accounts.push(...sourceCounterparty.Accounts.filter(x => x.ID !== accountID), newAccount);
						} else {
							const account: CounterpartyAccount = {
								...new counterpartyApi.package.CounterpartyAccount(),
								AccountNumber: item.AccountNumber,
								Bank: item.Bank,
							};
							accounts.push(...sourceCounterparty.Accounts, account);
						}

						const counterparty: Counterparty = {
							...sourceCounterparty,
							Accounts: accounts,
						};
						final = (await counterpartyApi.counterparty.updateCounterparty(counterparty)) as Counterparty;
						const account: CounterpartyAccount =
							accountID > 0 ? final.Accounts.find(x => x.ID === accountID) : final.Accounts[final.Accounts.length - 1];

						store.dispatch(runCounterpartiesInvalidationEffect());
						cache[tag] = final;
						onComplete(final, account);
					}

					resolve(null);
				});
			}

			function patchContractFromContractBrief(patchItem: PatchItem<ContractBrief, Contract>) {
				return new Promise(async resolve => {
					const { tag, ownerTags, getObject, getFallback, onComplete } = patchItem;
					const item = getObject();

					cache[tag] = getFallback();
					if (!item) return resolve(null);

					const ID = item.ID || -1;
					const [ownerOneTag, ownerTwoTag] = ownerTags;
					const tenantEntity = cache[ownerOneTag] as Fallback;
					const counterparty = cache[ownerTwoTag] as Fallback;
					const tenantEntityID = tenantEntity?.ID || -1;
					const counterpartyID = counterparty?.ID || -1;
					let final: Contract = null;

					if (ID > 0) {
						const sourceContract = (await invoiceApi.financialDocument.fetchDocumentByID(ID)) as Contract;

						if (!sourceContract) return resolve(null);

						const contract: Contract = {
							...sourceContract,
							Number: item.Number || sourceContract.Number,
							DateIssued: item.ContractDate || sourceContract.DateIssued,
							ContractSubject: item.ContractSubject || sourceContract.ContractSubject,
							LegalEntity: { ...new counterpartyApi.package.CounterpartyBrief(), ID: tenantEntityID },
							Counterparty: { ...new counterpartyApi.package.CounterpartyBrief(), ID: counterpartyID },
						};

						final = (await invoiceApi.financialDocument.updateDocument(contract)) as Contract;
					} else {
						const contract: Contract = {
							...new invoiceApi.package.Contract(),
							Number: item.Number,
							DateIssued: item.ContractDate,
							ContractSubject: item.ContractSubject,
							LegalEntity: { ...new counterpartyApi.package.CounterpartyBrief(), ID: tenantEntityID },
							Counterparty: { ...new counterpartyApi.package.CounterpartyBrief(), ID: counterpartyID },
						};
						final = (await invoiceApi.financialDocument.addDocument(contract)) as Contract;
					}

					store.dispatch(invalidateContracts());
					cache[tag] = final;
					onComplete(final);
					resolve(null);
				});
			}

			function patchCounterpartyFromEmployeeBrief(patchItem: PatchItem<EmployeeBrief, LegalEntity>) {
				return new Promise(async resolve => {
					const { tag, ownerTags, getObject, getFallback, onComplete } = patchItem;
					const item = getObject();

					cache[tag] = getFallback();
					if (!item) return resolve(null);

					const [ownerTag] = ownerTags;
					const owner = cache[ownerTag] as Fallback;
					const ownerID = owner?.ID || -1;
					const employeeID = item.ID || -1;
					let final: LegalEntity = null;

					if (ownerID > 0) {
						const sourceLegalEntity = (await counterpartyApi.counterparty.fetchCounterpartyByID(
							ownerID,
						)) as LegalEntity;
						const employees: Array<EmployeeBrief> = [];

						if (!sourceLegalEntity) return resolve(null);

						if (employeeID > 0) {
							const employee = sourceLegalEntity.Employees.find(x => x.ID === employeeID);
							if (!employee) return resolve(null);
							const newEmployee: EmployeeBrief = {
								...employee,
								Name: item.Name,
								BusinessRole: item.BusinessRole,
							};
							employees.push(...sourceLegalEntity.Employees.filter(x => x.ID !== employeeID), newEmployee);
						} else {
							const employee: EmployeeBrief = {
								...new counterpartyApi.package.EmployeeBrief(),
								Name: item.Name,
								BusinessRole: item.BusinessRole,
							};
							employees.push(...sourceLegalEntity.Employees, employee);
						}

						const counterparty: LegalEntity = {
							...sourceLegalEntity,
							Employees: employees,
						};
						final = (await counterpartyApi.counterparty.updateCounterparty(counterparty)) as LegalEntity;
						const employee: EmployeeBrief =
							employeeID > 0
								? final.Employees.find(x => x.ID === employeeID)
								: final.Employees[final.Employees.length - 1];

						store.dispatch(runCounterpartiesInvalidationEffect());
						cache[tag] = final;
						onComplete(final, employee);
					}

					resolve(null);
				});
			}

			function patchLegalEntityFromEmployeeBriefs(patchItem: PatchItem<Array<EmployeeBrief>, LegalEntity>) {
				return new Promise(async resolve => {
					const { tag, ownerTags, getObject, getFallback, onComplete } = patchItem;
					const items = getObject();

					cache[tag] = getFallback();
					if (!items || items.length === 0) return resolve(null);

					const [ownerTag] = ownerTags;
					const owner = cache[ownerTag] as Fallback;
					const ownerID = owner?.ID || -1;
					const employeeIDs = items.map(x => x.ID);
					let final: LegalEntity = null;

					if (ownerID > 0) {
						const sourceLegalEntity = (await counterpartyApi.counterparty.fetchCounterpartyByID(
							ownerID,
						)) as LegalEntity;
						const employees: Array<EmployeeBrief> = [];

						if (!sourceLegalEntity) return resolve(null);

						employees.push(...sourceLegalEntity.Employees.filter(x => !employeeIDs.includes(x.ID)), ...items);

						const counterparty: LegalEntity = {
							...sourceLegalEntity,
							Employees: employees,
						};

						final = (await counterpartyApi.counterparty.updateCounterparty(counterparty)) as LegalEntity;

						store.dispatch(runCounterpartiesInvalidationEffect());
						cache[tag] = final;
						onComplete(final, employees);
					}

					resolve(null);
				});
			}

			function patchTenantEntityFromEmployeeBriefs(patchItem: PatchItem<Array<EmployeeBrief>, LegalEntity>) {
				return new Promise(async resolve => {
					const { tag, ownerTags, getObject, getFallback, onComplete } = patchItem;
					const items = getObject();

					cache[tag] = getFallback();
					if (!items || items.length === 0) return resolve(null);

					const [ownerTag] = ownerTags;
					const owner = cache[ownerTag] as Fallback;
					const ownerID = owner?.ID || -1;
					const employeeIDs = items.map(x => x.ID);
					let final: LegalEntity = null;

					if (ownerID > 0) {
						const sourceLegalEntity = (await counterpartyApi.counterparty.fetchCounterpartyByID(
							ownerID,
						)) as LegalEntity;
						const employees: Array<EmployeeBrief> = [];

						if (!sourceLegalEntity) return resolve(null);

						employees.push(...sourceLegalEntity.Employees.filter(x => !employeeIDs.includes(x.ID)), ...items);

						const counterparty: LegalEntity = {
							...sourceLegalEntity,
							Employees: employees,
						};

						final = (await counterpartyApi.counterparty.updateCounterparty(counterparty)) as LegalEntity;

						store.dispatch(runTenantLegalEntitiesInvalidationEffect());
						store.dispatch(runEmployeesInvalidationEffect());
						cache[tag] = final;
						onComplete(final, employees);
					}

					resolve(null);
				});
			}
		};
}

function getLegalEntityFields(value: Partial<LegalEntity>) {
	const name = value.Name || '';
	const taxCode = value.TaxCode || '';
	const additionalTaxCode = value.AdditionalTaxCode || '';
	const stateRegistrationCode = value.StateRegistrationCode || '';
	const address = value.LegalAddress || '';
	const isSoleProprietor = value.SoleProprietor;
	const logoUrl = value.LogoURL;
	const companySealImgURL = value.CompanySealImageURL;
	const stateIndustry = value.StateIndustry;
	const isTenantLegalEntity = value.TenantLegalEntity;

	return {
		name,
		taxCode,
		additionalTaxCode,
		stateRegistrationCode,
		address,
		isSoleProprietor,
		logoUrl,
		companySealImgURL,
		stateIndustry,
		isTenantLegalEntity,
	};
}

function getCounterpartyBriefFields(brief: Partial<CounterpartyBrief>) {
	const name = brief.Name || '';
	const taxCode = brief.TaxCode || '';
	const additionalTaxCode = brief.AdditionalTaxCode || '';
	const stateRegistrationCode = brief.StateRegistrationCode;
	const address = brief.Address || '';
	const consigneeAddress = brief.ConsigneeAddress || '';
	const isSoleProprietor = brief.SoleProprietor;
	const logoUrl = brief.LogoURL;
	const companySealImgURL = brief.CompanySealImageURL;

	return {
		name,
		taxCode,
		additionalTaxCode,
		stateRegistrationCode,
		address,
		consigneeAddress,
		isSoleProprietor,
		logoUrl,
		companySealImgURL,
	};
}

const runPatchEffect = createPatchEffect();

export { runPatchEffect };
