import styled, { css } from 'styled-components';

import { backgroundLight } from '@ui/basic-theme/colors';
import { GridColumn } from '@ui/grid';
import { ExtraViewProps } from './extra-view';

type ContentProps = {
	appearance?: 'default' | 'drawer';
} & Partial<ExtraViewProps>;

const Root = styled(GridColumn)``;

const Content = styled.div`
	position: relative;
	width: inherit;
	background-color: ${backgroundLight};

	${(props: ContentProps) =>
		props.fullWidth &&
		css`
			width: 100%;
		`}

	${(props: ContentProps) =>
		props.withoutPadding &&
		css`
			padding: 0;
		`}

	${(p: ContentProps) =>
		p.appearance === 'drawer' &&
		css`
			width: 100%;
			background-color: transparent;
			padding: 30px 40px;
		`}
`;

const ButtonLayout = styled.div`
	position: fixed;
	top: 90px;
	right: 18px;
	z-index: 1000;
	width: 32px;
	height: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 2px;

	${p => css`
		background-color: ${p.theme.palette.canvas};
		${p.theme.fn.createBoxShadow(1)}
	`}
`;

export { Root, Content, ButtonLayout };
