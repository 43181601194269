import React, { memo } from 'react';

import withTopShift from '@core/hocs/with-top-shift.hoc';
import { AccountMenuItem } from '@funds-registers/components/accounts-menu-item';
import { accountResolver } from './accounts-menu.resolver';
import { RaisedButton } from '@ui/raised-button';
import { Root, ContentLayout, AccountItemLayout, CloseButtonLayout } from './styled';

type AccountsMenuProps = {
	isOpen: boolean;
	topShift?: number;
	fundsRegisterStatistics: Array<FundsRegisterStatistics>;
	onClose: Function;
	setFundsRegisterID: (ID: number) => void;
	setFundsRegisterIDForRefresh: (ID: number) => void;
	setFundsRegisterIDForAutoInsert: (ID: number) => void;
};

const AccountsMenu: React.FC<AccountsMenuProps> = memo(props => {
	const {
		isOpen,
		topShift,
		fundsRegisterStatistics,
		onClose,
		setFundsRegisterID,
		setFundsRegisterIDForRefresh,
		setFundsRegisterIDForAutoInsert,
	} = props;

	const handleClose = () => onClose();

	const renderAccountItems = () => {
		return fundsRegisterStatistics.map((x, index) => {
			const { accountId, accountName, accountCurrency, accountType, balance, bankName, connectedToCMS } =
				accountResolver(x);
			const fundsRegister = x?.FundsRegister;

			return (
				<AccountMenuItem
					key={index}
					fundsRegister={fundsRegister}
					accountId={accountId}
					accountName={accountName}
					accountCurrency={accountCurrency}
					accountType={accountType}
					balance={balance}
					bankName={bankName}
					connectedToCMS={connectedToCMS}
					setFundsRegisterID={setFundsRegisterID}
					setFundsRegisterIDForRefresh={setFundsRegisterIDForRefresh}
					setFundsRegisterIDForAutoInsert={setFundsRegisterIDForAutoInsert}
				/>
			);
		});
	};

	return (
		<Root isOpen={isOpen} topShift={topShift}>
			<ContentLayout>
				<AccountItemLayout>{renderAccountItems()}</AccountItemLayout>
			</ContentLayout>
			<CloseButtonLayout>
				<RaisedButton appearance='text' size='large' fullWidth onClick={handleClose}>
					Закрыть
				</RaisedButton>
			</CloseButtonLayout>
		</Root>
	);
});

const AccountsMenuWithTopShift = withTopShift<AccountsMenuProps>()(AccountsMenu);

export { AccountsMenuWithTopShift as AccountsMenu };
