import { combineReducers } from 'redux';

import { mainTopCounterpartiesReportReducer, MainTopCounterpartiesReportState } from './main.reducer';

export type TopCounterpartiesReportState = {
	main: MainTopCounterpartiesReportState;
};

const topCounterpartiesReportReducer = combineReducers<TopCounterpartiesReportState>({
	main: mainTopCounterpartiesReportReducer,
});

export { topCounterpartiesReportReducer };
