import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import * as moment from 'moment';

import { getDrawer } from '../../../../../../../core/libs/utils';
import * as statuses from '../../../../../../shared/constants/statuses';
import { BASE_DATE_FORMAT } from '../../../../../../shared/constants/time';
import { DrawerRightControls } from '../../../../../../../core/ui/drawer';
import { FormGroup, FormGroupTitle } from '../../../../../../../core/ui/forms';
import { ResultView } from '@ui/result-view';
import BillingPreview from '../../billing-preview';
import FastBillForm from '../../forms/fast-bill-form';
import { ROUTE_MAP } from '../../../../../../../core/routes/urls';
import {
	Root,
	RightContentLayout,
	ContentLayout,
	BillingPreviewLayout,
	FastBillFormLayout,
	ResultViewLayout,
} from './styled';

const TENANT_ACCOUNT_URL = ROUTE_MAP.TENANT_ACCOUNT;

export type LegalEntityPaymentResultDisplayProps = {
	withCheck: boolean;
	isFetching?: boolean;
	tenantBills?: Record<string, TenantBill>;
	status?: StatusAction;
	expireDate?: string;
	runFastBillResultEffect?: (invoiceUID: string) => void;
	resetPath?: () => void;
} & RouteComponentProps<any>;

type LegalEntityPaymentResultDisplayState = {
	isFastPaymentComplete: boolean;
};

class LegalEntityPaymentResultDisplay extends React.Component<
	LegalEntityPaymentResultDisplayProps,
	LegalEntityPaymentResultDisplayState
> {
	static displayName = 'LegalEntityPaymentResultDisplay';
	state = { isFastPaymentComplete: false };

	componentDidMount() {
		this.setDrawerRightContent();
		this.setDrawerOnClose();
		this.props.withCheck && this.checkFastPayment();
	}

	checkFastPayment = () => {
		const isFastPayment = getURLParameterByName('paymentNotification');
		const invoiceUID = getURLParameterByName('invoiceUID');

		if (isFastPayment && invoiceUID) {
			this.props.runFastBillResultEffect(invoiceUID);
			this.setState({ isFastPaymentComplete: true });
		}
	};

	setDrawerRightContent = () => {
		getDrawer().setContent({
			rightContent: this.renderRightContent(),
		});
	};

	setDrawerOnClose = () => {
		const { resetPath, history } = this.props;

		getDrawer().setOnClose(() => {
			resetPath();
			history.push(`${TENANT_ACCOUNT_URL}/bills-and-services`);
		});
	};

	handleClose = () => {
		getDrawer().close();
	};

	renderRightContent = () => {
		return (
			<RightContentLayout>
				<DrawerRightControls actionLabel={'Закрыть'} onAction={this.handleClose} />
			</RightContentLayout>
		);
	};

	renderFastPaymentResultView = () => {
		const { status, expireDate } = this.props;
		let { isFetching } = this.props;
		const { isFastPaymentComplete } = this.state;

		if (!isFastPaymentComplete && this.isBillInProgress()) {
			isFetching = true;
		}

		const isSuccess = this.isBillPaid() || (status && status.status === statuses.SUCCESS);
		const title = isSuccess
			? `Сервис оплачен до ${moment(expireDate, BASE_DATE_FORMAT).format('DD.MM.YYYY')} г.`
			: status
			? status.message
			: '';

		return (
			<ResultViewLayout>
				<ResultView
					type={status ? status.status : statuses.FAILED}
					title={title}
					isFetching={isFetching}
					fetchingLabel={'Платёж находится в обработке'}
					fetchingLabelDescription={
						'В зависимости от ситуации отправка платежа может занять от нескольких секунд до нескольких минут. Вы можете закрыть это окно. Когда платёж будет обработан, оплаченный период использования сервиса продлится автоматически.'
					}
				/>
			</ResultViewLayout>
		);
	};

	isBillInProgress = () => {
		const tenantBillsList = Object.keys(this.props.tenantBills).map(key => this.props.tenantBills[key]);
		const tenantBillStatus = tenantBillsList[0] && tenantBillsList[0].StatusCode;
		return tenantBillStatus === 'SENT_TO_PAYMENT_GATEWAY' || tenantBillStatus === 'WAITING_FOR_PAYMENT_CONFIRMATION';
	};

	isBillPaid = () => {
		const tenantBillsList = Object.keys(this.props.tenantBills).map(key => this.props.tenantBills[key]);
		const tenantBillStatus = tenantBillsList[0] && tenantBillsList[0].StatusCode;
		return tenantBillStatus === 'PAID' || tenantBillsList.length === 0;
	};

	render() {
		const { tenantBills } = this.props;
		const { isFastPaymentComplete } = this.state;
		const tenantBillList = Object.keys(tenantBills).map(key => tenantBills[key]);

		if (isFastPaymentComplete || this.isBillInProgress() || this.isBillPaid()) {
			return this.renderFastPaymentResultView();
		}

		return (
			<Root>
				<FormGroup>
					<FormGroupTitle fontSize={'14px'} lineHeight={1.43} marginBottom={'20px'}>
						Для вас сформирован счёт на оплату сервиса. После поступления оплаты по указанным реквизитам выбранный вами
						тариф будет активирован.
					</FormGroupTitle>
					<ContentLayout>
						<BillingPreviewLayout>
							<BillingPreview type={'PREVIEW'} tenantBill={tenantBillList[0]} />
						</BillingPreviewLayout>
						<FastBillFormLayout>
							<FastBillForm />
						</FastBillFormLayout>
					</ContentLayout>
				</FormGroup>
			</Root>
		);
	}
}

export default withRouter(LegalEntityPaymentResultDisplay);
