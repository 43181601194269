import { lighten, darken, fade } from '@utils/color';
import { ThemesByBrandsMap, ThemeBrand, ThemeMode, ThemeIdentifier, ShadowElevation } from './models';
import seenecoLogo from '@assets/white-label/seeneco-logo.svg';
import sberbankBusinessAnalyticsLogo from '@assets/white-label/business-analytics-logo.svg';
import sberbankInvoiceLogo from '@assets/white-label/sberbank-invoice-logo.svg';
import openbankLogo from '@assets/white-label/openbank-logo.svg';
import sovcombankLogo from '@assets/white-label/sovcombank-logo.svg';
import sovcombankDarkModeLogo from '@assets/white-label/sovcombank-dark-mode-logo.svg';
import { pxToRem } from '@stylesheet';

const themesMap: ThemesByBrandsMap = {
	[ThemeBrand.SEENECO]: {
		[ThemeMode.LIGHT]: {
			isDarkMode: false,
			isDefaultBrand: true,
			whiteLabelLogo: {
				bfm: seenecoLogo,
				invoice: seenecoLogo,
			},
			fontFamily: 'Roboto, sans-serif',
			fontSize: '14px',
			lineHeight: '1.2',
			palette: {
				accent: '#26d06d',
				warning: '#ffc107',
				alarm: '#ff3a48',
				text: '#262626',
				textContrast: '#fff',
				label: '#8c8c8c',
				hint: '#bfbfbf',
				stealth: '#ebebeb',
				background: '#f5f8fa',
				space: '#fff',
				canvas: '#fff',
				border: '#ccc',
				positive: '#26D06D',
				negative: '#FF3B48',
				neutral: '#1EAAF1',
				superMuted: '#73777b',
			},
			appMenu: {
				logoBackgroundColor: '#3e4757',
				backgroundColor: '#3e4757',
				color: '#b2b9bf',
				activeIconColor: '#26d06d',
				activeTextColor: '#fff',
				dividerColor: '#3e4757',
				boxShadow: '0 0 50px rgba(0, 0, 0, 0.35)',
			},
			button: {
				borderRadius: '2px',
				contained: {
					accent: {
						textColor: '#fff',
						backgroundColor: '#26d06d',
						backgroundColorHover: '#00c853',
						backgroundColorFocus: '#00c853',
						textColorDisabled: '#fff',
						backgroundColorDisabled: '#bfbfbf',
					},
					light: {
						textColor: '#262626',
						backgroundColor: '#fff',
						backgroundColorHover: '#ebebeb',
						backgroundColorFocus: '#00c853',
						textColorDisabled: '#fff',
						backgroundColorDisabled: '#bfbfbf',
					},
				},
				text: {
					accent: {
						textColor: '#26d06d',
						backgroundColor: 'transparent',
						backgroundColorHover: lighten('#26d06d', 0.9),
						backgroundColorFocus: 'rgba(0, 0, 0, 0.08)',
						textColorDisabled: '#fff',
						backgroundColorDisabled: '#bfbfbf',
					},
					muted: {
						textColor: '#8c8c8c',
						backgroundColor: 'transparent',
						backgroundColorHover: lighten('#8c8c8c', 0.9),
						backgroundColorFocus: 'rgba(0, 0, 0, 0.08)',
						textColorDisabled: '#fff',
						backgroundColorDisabled: '#bfbfbf',
					},
					dark: {
						textColor: '#262626',
						backgroundColor: 'transparent',
						backgroundColorHover: 'transparent',
						backgroundColorFocus: 'rgba(0, 0, 0, 0.08)',
						textColorDisabled: '#fff',
						backgroundColorDisabled: '#bfbfbf',
					},
				},
				outlined: {
					accent: {
						textColor: '#26d06d',
						backgroundColor: 'transparent',
						backgroundColorHover: lighten('#51d98a', 0.9),
						backgroundColorFocus: lighten('#51d98a', 0.9),
						textColorDisabled: '#fff',
						backgroundColorDisabled: '#bfbfbf',
					},
					dark: {
						textColor: '#262626',
						backgroundColor: 'transparent',
						backgroundColorHover: lighten('#262626', 0.9),
						backgroundColorFocus: lighten('#262626', 0.9),
						textColorDisabled: '#fff',
						backgroundColorDisabled: '#bfbfbf',
					},
				},
			},
			iconButton: {
				textColor: '#262626',
				textColorDisabled: '#fff',
				backgroundColor: '#fff',
				backgroundColorHover: lighten('#262626', 0.9),
				backgroundColorDisabled: '#bfbfbf',
			},
			alert: {
				borderRadius: '2px',
				default: {
					textColor: '#262626',
					backgroundColor: lighten('#bfbfbf', 0.7),
					borderColor: '#bfbfbf',
				},
				warning: {
					textColor: '#262626',
					backgroundColor: lighten('#ffc107', 0.7),
					borderColor: '#ffc107',
				},
				alarm: {
					textColor: '#262626',
					backgroundColor: lighten('#ff3a48', 0.7),
					borderColor: '#ff3a48',
				},
				success: {
					textColor: '#262626',
					backgroundColor: lighten('#26d06d', 0.7),
					borderColor: '#26d06d',
				},
			},
			materialCard: {
				backgroundColor: '#fff',
			},
			link: {
				textColor: '#26d06d',
				textColorHover: '#2196f3',
				textColorFocus: '#2196f3',
			},
			chart: {
				infinityPalette: [
					'#9575CD',
					'#FFD54F',
					'#1EAAF1',
					'#F06292',
					'#81C784',
					'#EC407A',
					'#BA68C8',
					'#4DD0E1',
					'#FFB74D',
					lighten('#9575CD', 0.4),
					lighten('#FFD54F', 0.4),
					lighten('#1EAAF1', 0.4),
					lighten('#F06292', 0.4),
					lighten('#81C784', 0.4),
					lighten('#EC407A', 0.4),
					lighten('#BA68C8', 0.4),
					lighten('#4DD0E1', 0.4),
					lighten('#FFB74D', 0.4),
				],
				positive: '#26D06D',
				positiveLight: lighten('#26D06D', 0.7),
				negative: '#FF3B48',
				negativeLight: lighten('#FF3B48', 0.7),
				neutral: '#1EAAF1',
				neutralLight: lighten('#1EAAF1', 0.3),
				gridLineColor: '#f2f2f2',
				labelColor: '#8c8c8c',
				scrollbarBackgroundColor: lighten('#bfbfbf', 0.6),
				trackBackgroundColor: lighten('#f5f8fa', 0.05),
			},
			tooltip: {
				backgroundColor: '#372c4d',
				textColor: '#fff',
			},
			badge: {
				gray: {
					backgroundColor: '#a6a6a6',
					textColor: '#fff',
				},
				green: {
					backgroundColor: '#26d06d',
					textColor: '#fff',
				},
				orange: {
					backgroundColor: '#ffc107',
					textColor: '#262626',
				},
				red: {
					backgroundColor: '#ff3a48',
					textColor: '#fff',
				},
				yellow: {
					backgroundColor: '#ffeb3b',
					textColor: '#262626',
				},
			},
			virtualTable: {
				borderColor: '#e1e1e1',
			},
			segmentedBar: {
				backgroundColor: fade('#ebebeb', 0.6),
				disabledTextColor: '#8c8c8c',
				activeBackgroundColor: '#fff',
				activeTextColor: '#262626',
				activeDisabledTextColor: '#8c8c8c',
			},
			overlay: {
				backgroundColor: 'rgba(62, 71, 87, 0.7)',
			},
			dialog: {
				borderRadius: '2px',
				header: {
					fontSize: '20px',
				},
			},
			fn: {
				pxToRem,
				createBoxShadow: createLightModeBoxShadow,
				createInsetBoxShadow: createInsetLightModeBoxShadow,
			},
		},
		[ThemeMode.DARK]: {
			isDarkMode: true,
			isDefaultBrand: true,
			whiteLabelLogo: {
				bfm: seenecoLogo,
				invoice: seenecoLogo,
			},
			fontFamily: 'Roboto, sans-serif',
			fontSize: '14px',
			lineHeight: '1.2',
			palette: {
				accent: '#20b37b',
				warning: '#cc7a00',
				alarm: '#cf2733',
				text: '#c9d1d9',
				textContrast: '#e1eaf2',
				label: '#878787',
				hint: '#82878c',
				stealth: '#383f4a',
				background: '#161b22',
				space: '#0e131a',
				canvas: '#1d2633',
				border: 'rgba(255, 255, 255, 0.22)',
				positive: '#20b37b',
				negative: '#cf2733',
				neutral: '#1a99d9',
				superMuted: '#64686b',
			},
			appMenu: {
				logoBackgroundColor: '#100a1a',
				backgroundColor: '#100a1a',
				color: '#9aa0a6',
				activeIconColor: '#20b37b',
				activeTextColor: '#fff',
				dividerColor: '#100a1a',
				boxShadow: '0 0 50px rgba(0, 0, 0, 1)',
			},
			button: {
				borderRadius: '2px',
				contained: {
					accent: {
						textColor: '#e1eaf2',
						backgroundColor: '#20b37b',
						backgroundColorHover: '#1c9969',
						backgroundColorFocus: '#00c853',
						textColorDisabled: '#d1d1d1',
						backgroundColorDisabled: '#5e5e5e',
					},
					light: {
						textColor: '#e1eaf2',
						backgroundColor: '#323333',
						backgroundColorHover: '#3e4040',
						backgroundColorFocus: '#00c853',
						textColorDisabled: '#d1d1d1',
						backgroundColorDisabled: '#5e5e5e',
					},
				},
				text: {
					accent: {
						textColor: '#20b37b',
						backgroundColor: 'transparent',
						backgroundColorHover: darken('#20b37b', 0.7),
						backgroundColorFocus: '#383f4a',
						textColorDisabled: '#d1d1d1',
						backgroundColorDisabled: '#5e5e5e',
					},
					muted: {
						textColor: '#797979',
						backgroundColor: 'transparent',
						backgroundColorHover: darken('#797979', 0.7),
						backgroundColorFocus: '#383f4a',
						textColorDisabled: '#d1d1d1',
						backgroundColorDisabled: '#5e5e5e',
					},
					dark: {
						textColor: '#c9d1d9',
						backgroundColor: 'transparent',
						backgroundColorHover: 'transparent',
						backgroundColorFocus: '#383f4a',
						textColorDisabled: '#d1d1d1',
						backgroundColorDisabled: '#5e5e5e',
					},
				},
				outlined: {
					accent: {
						textColor: '#20b37b',
						backgroundColor: 'transparent',
						backgroundColorHover: darken('#20b37b', 0.7),
						backgroundColorFocus: darken('#20b37b', 0.7),
						textColorDisabled: '#d1d1d1',
						backgroundColorDisabled: '#5e5e5e',
					},
					dark: {
						textColor: '#c9d1d9',
						backgroundColor: 'transparent',
						backgroundColorHover: darken('#c9d1d9', 0.7),
						backgroundColorFocus: darken('#c9d1d9', 0.7),
						textColorDisabled: '#d1d1d1',
						backgroundColorDisabled: '#5e5e5e',
					},
				},
			},
			iconButton: {
				textColor: '#e1eaf2',
				textColorDisabled: '#d1d1d1',
				backgroundColor: '#323333',
				backgroundColorHover: darken('#e1eaf2', 0.7),
				backgroundColorDisabled: '#5e5e5e',
			},
			alert: {
				borderRadius: '2px',
				default: {
					textColor: '#c9d1d9',
					backgroundColor: darken('#878787', 0.5),
					borderColor: '#878787',
				},
				warning: {
					textColor: '#c9d1d9',
					backgroundColor: darken('#cc7a00', 0.5),
					borderColor: '#cc7a00',
				},
				alarm: {
					textColor: '#c9d1d9',
					backgroundColor: darken('#cf2733', 0.5),
					borderColor: '#cf2733',
				},
				success: {
					textColor: '#c9d1d9',
					backgroundColor: darken('#20b37b', 0.5),
					borderColor: '#20b37b',
				},
			},
			materialCard: {
				backgroundColor: '#293340',
			},
			link: {
				textColor: '#20b37b',
				textColorHover: '#2196f3',
				textColorFocus: '#2196f3',
			},
			chart: {
				infinityPalette: [
					'#5d4980',
					'#ccab3f',
					'#1787bf',
					'#bd4d72',
					'#609463',
					'#ba3260',
					'#894d94',
					'#3ba0ad',
					'#e6a645',
					lighten('#5d4980', 0.4),
					lighten('#ccab3f', 0.4),
					lighten('#1787bf', 0.4),
					lighten('#bd4d72', 0.4),
					lighten('#609463', 0.4),
					lighten('#ba3260', 0.4),
					lighten('#894d94', 0.4),
					lighten('#3ba0ad', 0.4),
					lighten('#e6a645', 0.4),
				],
				positive: '#20b37b',
				positiveLight: lighten('#20b37b', 0.4),
				negative: '#cf2733',
				negativeLight: lighten('#cf2733', 0.4),
				neutral: '#1a99d9',
				neutralLight: lighten('#1a99d9', 0.4),
				gridLineColor: '#384759',
				labelColor: '#d4d4d4',
				scrollbarBackgroundColor: darken('#293340', 0.1),
				trackBackgroundColor: darken('#f5f8fa', 0.75),
			},
			tooltip: {
				backgroundColor: '#372c4d',
				textColor: '#c9d1d9',
			},
			badge: {
				gray: {
					backgroundColor: '#737373',
					textColor: '#e1eaf2',
				},
				green: {
					backgroundColor: '#20b37b',
					textColor: '#e1eaf2',
				},
				orange: {
					backgroundColor: '#cc7a00',
					textColor: '#e1eaf2',
				},
				red: {
					backgroundColor: '#cf2733',
					textColor: '#e1eaf2',
				},
				yellow: {
					backgroundColor: '#e6d435',
					textColor: '#262626',
				},
			},
			virtualTable: {
				borderColor: '#383f4a',
			},
			segmentedBar: {
				backgroundColor: fade('#383f4a', 0.6),
				disabledTextColor: '#878787',
				activeBackgroundColor: '#0e131a',
				activeTextColor: '#c9d1d9',
				activeDisabledTextColor: '#878787',
			},
			overlay: {
				backgroundColor: 'rgba(62, 71, 87, 0.7)',
			},
			dialog: {
				borderRadius: '2px',
				header: {
					fontSize: '20px',
				},
			},
			fn: {
				pxToRem,
				createBoxShadow: createDarkModeBoxShadow,
				createInsetBoxShadow: createInsetDarkModeBoxShadow,
			},
		},
	},
	[ThemeBrand.SBERBANK]: {
		[ThemeMode.LIGHT]: {
			isDarkMode: false,
			isDefaultBrand: false,
			whiteLabelLogo: {
				bfm: sberbankBusinessAnalyticsLogo,
				invoice: sberbankInvoiceLogo,
			},
			fontFamily: 'Roboto, sans-serif',
			fontSize: '14px',
			lineHeight: '1.2',
			palette: {
				accent: '#26d06d',
				warning: '#ffc107',
				alarm: '#ff3a48',
				text: '#262626',
				textContrast: '#fff',
				label: '#8c8c8c',
				hint: '#bfbfbf',
				stealth: '#ebebeb',
				background: '#f5f8fa',
				space: '#fff',
				canvas: '#fff',
				border: '#ccc',
				positive: '#26D06D',
				negative: '#FF3B48',
				neutral: '#1EAAF1',
				superMuted: '#73777b',
			},
			appMenu: {
				logoBackgroundColor: '#3e4757',
				backgroundColor: '#3e4757',
				color: '#b2b9bf',
				activeIconColor: '#26d06d',
				activeTextColor: '#fff',
				dividerColor: '#3e4757',
				boxShadow: '0 0 50px rgba(0, 0, 0, 0.35)',
			},
			button: {
				borderRadius: '2px',
				contained: {
					accent: {
						textColor: '#fff',
						backgroundColor: '#26d06d',
						backgroundColorHover: '#00c853',
						backgroundColorFocus: '#00c853',
						textColorDisabled: '#fff',
						backgroundColorDisabled: '#bfbfbf',
					},
					light: {
						textColor: '#262626',
						backgroundColor: '#fff',
						backgroundColorHover: '#ebebeb',
						backgroundColorFocus: '#00c853',
						textColorDisabled: '#fff',
						backgroundColorDisabled: '#bfbfbf',
					},
				},
				text: {
					accent: {
						textColor: '#26d06d',
						backgroundColor: 'transparent',
						backgroundColorHover: lighten('#26d06d', 0.9),
						backgroundColorFocus: 'rgba(0, 0, 0, 0.08)',
						textColorDisabled: '#fff',
						backgroundColorDisabled: '#bfbfbf',
					},
					muted: {
						textColor: '#8c8c8c',
						backgroundColor: 'transparent',
						backgroundColorHover: lighten('#8c8c8c', 0.9),
						backgroundColorFocus: 'rgba(0, 0, 0, 0.08)',
						textColorDisabled: '#fff',
						backgroundColorDisabled: '#bfbfbf',
					},
					dark: {
						textColor: '#262626',
						backgroundColor: 'transparent',
						backgroundColorHover: 'transparent',
						backgroundColorFocus: 'rgba(0, 0, 0, 0.08)',
						textColorDisabled: '#fff',
						backgroundColorDisabled: '#bfbfbf',
					},
				},
				outlined: {
					accent: {
						textColor: '#26d06d',
						backgroundColor: 'transparent',
						backgroundColorHover: lighten('#51d98a', 0.9),
						backgroundColorFocus: lighten('#51d98a', 0.9),
						textColorDisabled: '#fff',
						backgroundColorDisabled: '#bfbfbf',
					},
					dark: {
						textColor: '#262626',
						backgroundColor: 'transparent',
						backgroundColorHover: lighten('#262626', 0.9),
						backgroundColorFocus: lighten('#262626', 0.9),
						textColorDisabled: '#fff',
						backgroundColorDisabled: '#bfbfbf',
					},
				},
			},
			iconButton: {
				textColor: '#262626',
				textColorDisabled: '#fff',
				backgroundColor: '#fff',
				backgroundColorHover: lighten('#262626', 0.9),
				backgroundColorDisabled: '#bfbfbf',
			},
			alert: {
				borderRadius: '2px',
				default: {
					textColor: '#262626',
					backgroundColor: lighten('#bfbfbf', 0.7),
					borderColor: '#bfbfbf',
				},
				warning: {
					textColor: '#262626',
					backgroundColor: lighten('#ffc107', 0.7),
					borderColor: '#ffc107',
				},
				alarm: {
					textColor: '#262626',
					backgroundColor: lighten('#ff3a48', 0.7),
					borderColor: '#ff3a48',
				},
				success: {
					textColor: '#262626',
					backgroundColor: lighten('#26d06d', 0.7),
					borderColor: '#26d06d',
				},
			},
			materialCard: {
				backgroundColor: '#fff',
			},
			link: {
				textColor: '#26d06d',
				textColorHover: '#2196f3',
				textColorFocus: '#2196f3',
			},
			chart: {
				infinityPalette: [
					'#9575CD',
					'#FFD54F',
					'#1EAAF1',
					'#F06292',
					'#81C784',
					'#EC407A',
					'#BA68C8',
					'#4DD0E1',
					'#FFB74D',
					lighten('#9575CD', 0.4),
					lighten('#FFD54F', 0.4),
					lighten('#1EAAF1', 0.4),
					lighten('#F06292', 0.4),
					lighten('#81C784', 0.4),
					lighten('#EC407A', 0.4),
					lighten('#BA68C8', 0.4),
					lighten('#4DD0E1', 0.4),
					lighten('#FFB74D', 0.4),
				],
				positive: '#26D06D',
				positiveLight: lighten('#26D06D', 0.7),
				negative: '#FF3B48',
				negativeLight: lighten('#FF3B48', 0.7),
				neutral: '#1EAAF1',
				neutralLight: lighten('#1EAAF1', 0.3),
				gridLineColor: '#f2f2f2',
				labelColor: '#8c8c8c',
				scrollbarBackgroundColor: lighten('#bfbfbf', 0.6),
				trackBackgroundColor: lighten('#f5f8fa', 0.05),
			},
			tooltip: {
				backgroundColor: '#372c4d',
				textColor: '#fff',
			},
			badge: {
				gray: {
					backgroundColor: '#a6a6a6',
					textColor: '#fff',
				},
				green: {
					backgroundColor: '#26d06d',
					textColor: '#fff',
				},
				orange: {
					backgroundColor: '#ffc107',
					textColor: '#262626',
				},
				red: {
					backgroundColor: '#ff3a48',
					textColor: '#fff',
				},
				yellow: {
					backgroundColor: '#ffeb3b',
					textColor: '#262626',
				},
			},
			virtualTable: {
				borderColor: '#e1e1e1',
			},
			segmentedBar: {
				backgroundColor: fade('#ebebeb', 0.6),
				disabledTextColor: '#8c8c8c',
				activeBackgroundColor: '#fff',
				activeTextColor: '#262626',
				activeDisabledTextColor: '#8c8c8c',
			},
			overlay: {
				backgroundColor: 'rgba(62, 71, 87, 0.7)',
			},
			dialog: {
				borderRadius: '2px',
				header: {
					fontSize: '20px',
				},
			},
			fn: {
				pxToRem,
				createBoxShadow: createLightModeBoxShadow,
				createInsetBoxShadow: createInsetLightModeBoxShadow,
			},
		},
		[ThemeMode.DARK]: {
			isDarkMode: true,
			isDefaultBrand: false,
			whiteLabelLogo: {
				bfm: sberbankBusinessAnalyticsLogo,
				invoice: sberbankInvoiceLogo,
			},
			fontFamily: 'Roboto, sans-serif',
			fontSize: '14px',
			lineHeight: '1.2',
			palette: {
				accent: '#20b37b',
				warning: '#cc7a00',
				alarm: '#cf2733',
				text: '#c9d1d9',
				textContrast: '#e1eaf2',
				label: '#878787',
				hint: '#82878c',
				stealth: '#383f4a',
				background: '#161b22',
				space: '#0e131a',
				canvas: '#1d2633',
				border: 'rgba(255, 255, 255, 0.22)',
				positive: '#20b37b',
				negative: '#cf2733',
				neutral: '#1a99d9',
				superMuted: '#64686b',
			},
			appMenu: {
				logoBackgroundColor: '#100a1a',
				backgroundColor: '#100a1a',
				color: '#9aa0a6',
				activeIconColor: '#20b37b',
				activeTextColor: '#fff',
				dividerColor: '#100a1a',
				boxShadow: '0 0 50px rgba(0, 0, 0, 1)',
			},
			button: {
				borderRadius: '2px',
				contained: {
					accent: {
						textColor: '#e1eaf2',
						backgroundColor: '#20b37b',
						backgroundColorHover: '#1c9969',
						backgroundColorFocus: '#00c853',
						textColorDisabled: '#d1d1d1',
						backgroundColorDisabled: '#5e5e5e',
					},
					light: {
						textColor: '#e1eaf2',
						backgroundColor: '#323333',
						backgroundColorHover: '#3e4040',
						backgroundColorFocus: '#00c853',
						textColorDisabled: '#d1d1d1',
						backgroundColorDisabled: '#5e5e5e',
					},
				},
				text: {
					accent: {
						textColor: '#20b37b',
						backgroundColor: 'transparent',
						backgroundColorHover: darken('#20b37b', 0.7),
						backgroundColorFocus: '#383f4a',
						textColorDisabled: '#d1d1d1',
						backgroundColorDisabled: '#5e5e5e',
					},
					muted: {
						textColor: '#797979',
						backgroundColor: 'transparent',
						backgroundColorHover: darken('#797979', 0.7),
						backgroundColorFocus: '#383f4a',
						textColorDisabled: '#d1d1d1',
						backgroundColorDisabled: '#5e5e5e',
					},
					dark: {
						textColor: '#c9d1d9',
						backgroundColor: 'transparent',
						backgroundColorHover: 'transparent',
						backgroundColorFocus: '#383f4a',
						textColorDisabled: '#d1d1d1',
						backgroundColorDisabled: '#5e5e5e',
					},
				},
				outlined: {
					accent: {
						textColor: '#20b37b',
						backgroundColor: 'transparent',
						backgroundColorHover: darken('#20b37b', 0.7),
						backgroundColorFocus: darken('#20b37b', 0.7),
						textColorDisabled: '#d1d1d1',
						backgroundColorDisabled: '#5e5e5e',
					},
					dark: {
						textColor: '#c9d1d9',
						backgroundColor: 'transparent',
						backgroundColorHover: darken('#c9d1d9', 0.7),
						backgroundColorFocus: darken('#c9d1d9', 0.7),
						textColorDisabled: '#d1d1d1',
						backgroundColorDisabled: '#5e5e5e',
					},
				},
			},
			iconButton: {
				textColor: '#e1eaf2',
				textColorDisabled: '#d1d1d1',
				backgroundColor: '#323333',
				backgroundColorHover: darken('#e1eaf2', 0.7),
				backgroundColorDisabled: '#5e5e5e',
			},
			alert: {
				borderRadius: '2px',
				default: {
					textColor: '#c9d1d9',
					backgroundColor: darken('#878787', 0.5),
					borderColor: '#878787',
				},
				warning: {
					textColor: '#c9d1d9',
					backgroundColor: darken('#cc7a00', 0.5),
					borderColor: '#cc7a00',
				},
				alarm: {
					textColor: '#c9d1d9',
					backgroundColor: darken('#cf2733', 0.5),
					borderColor: '#cf2733',
				},
				success: {
					textColor: '#c9d1d9',
					backgroundColor: darken('#20b37b', 0.5),
					borderColor: '#20b37b',
				},
			},
			materialCard: {
				backgroundColor: '#293340',
			},
			link: {
				textColor: '#20b37b',
				textColorHover: '#2196f3',
				textColorFocus: '#2196f3',
			},
			chart: {
				infinityPalette: [
					'#5d4980',
					'#ccab3f',
					'#1787bf',
					'#bd4d72',
					'#609463',
					'#ba3260',
					'#894d94',
					'#3ba0ad',
					'#e6a645',
					lighten('#5d4980', 0.4),
					lighten('#ccab3f', 0.4),
					lighten('#1787bf', 0.4),
					lighten('#bd4d72', 0.4),
					lighten('#609463', 0.4),
					lighten('#ba3260', 0.4),
					lighten('#894d94', 0.4),
					lighten('#3ba0ad', 0.4),
					lighten('#e6a645', 0.4),
				],
				positive: '#20b37b',
				positiveLight: lighten('#20b37b', 0.4),
				negative: '#cf2733',
				negativeLight: lighten('#cf2733', 0.4),
				neutral: '#1a99d9',
				neutralLight: lighten('#1a99d9', 0.4),
				gridLineColor: '#384759',
				labelColor: '#d4d4d4',
				scrollbarBackgroundColor: darken('#293340', 0.1),
				trackBackgroundColor: darken('#f5f8fa', 0.75),
			},
			tooltip: {
				backgroundColor: '#372c4d',
				textColor: '#c9d1d9',
			},
			badge: {
				gray: {
					backgroundColor: '#737373',
					textColor: '#e1eaf2',
				},
				green: {
					backgroundColor: '#20b37b',
					textColor: '#e1eaf2',
				},
				orange: {
					backgroundColor: '#cc7a00',
					textColor: '#e1eaf2',
				},
				red: {
					backgroundColor: '#cf2733',
					textColor: '#e1eaf2',
				},
				yellow: {
					backgroundColor: '#e6d435',
					textColor: '#262626',
				},
			},
			virtualTable: {
				borderColor: '#383f4a',
			},
			segmentedBar: {
				backgroundColor: fade('#383f4a', 0.6),
				disabledTextColor: '#878787',
				activeBackgroundColor: '#0e131a',
				activeTextColor: '#c9d1d9',
				activeDisabledTextColor: '#878787',
			},
			overlay: {
				backgroundColor: 'rgba(62, 71, 87, 0.7)',
			},
			dialog: {
				borderRadius: '2px',
				header: {
					fontSize: '20px',
				},
			},
			fn: {
				pxToRem,
				createBoxShadow: createDarkModeBoxShadow,
				createInsetBoxShadow: createInsetDarkModeBoxShadow,
			},
		},
	},
	[ThemeBrand.OPENBANK]: {
		[ThemeMode.LIGHT]: {
			isDarkMode: false,
			isDefaultBrand: false,
			whiteLabelLogo: {
				bfm: openbankLogo,
				invoice: openbankLogo,
			},
			fontFamily: 'Graphik, sans-serif',
			fontSize: '14px',
			lineHeight: '1.2',
			palette: {
				accent: '#00bef0',
				warning: '#D2B9A0',
				alarm: '#ff6e78',
				text: '#000',
				textContrast: '#fff',
				label: '#8c8c8c',
				hint: '#bfbfbf',
				stealth: '#ebebeb',
				background: '#f5f8fa',
				space: '#fff',
				canvas: '#fff',
				border: '#ccc',
				positive: '#53e6ab',
				negative: '#ff6e78',
				neutral: '#00bef0',
				superMuted: '#73777b',
			},
			appMenu: {
				logoBackgroundColor: '#3e4757',
				backgroundColor: '#3e4757',
				color: '#b2b9bf',
				activeIconColor: '#00bef0',
				activeTextColor: '#fff',
				dividerColor: '#3e4757',
				boxShadow: '0 0 50px rgba(0, 0, 0, 0.35)',
			},
			button: {
				borderRadius: '2px',
				contained: {
					accent: {
						textColor: '#fff',
						backgroundColor: '#00bef0',
						backgroundColorHover: '#00c8ff',
						backgroundColorFocus: '#00c8ff',
						textColorDisabled: '#fff',
						backgroundColorDisabled: '#bfbfbf',
					},
					light: {
						textColor: '#000',
						backgroundColor: '#fff',
						backgroundColorHover: '#ebebeb',
						backgroundColorFocus: '#00c8ff',
						textColorDisabled: '#fff',
						backgroundColorDisabled: '#bfbfbf',
					},
				},
				text: {
					accent: {
						textColor: '#00bef0',
						backgroundColor: 'transparent',
						backgroundColorHover: lighten('#00bef0', 0.9),
						backgroundColorFocus: 'rgba(0, 0, 0, 0.08)',
						textColorDisabled: '#fff',
						backgroundColorDisabled: '#bfbfbf',
					},
					muted: {
						textColor: '#8c8c8c',
						backgroundColor: 'transparent',
						backgroundColorHover: lighten('#8c8c8c', 0.9),
						backgroundColorFocus: 'rgba(0, 0, 0, 0.08)',
						textColorDisabled: '#fff',
						backgroundColorDisabled: '#bfbfbf',
					},
					dark: {
						textColor: '#000',
						backgroundColor: 'transparent',
						backgroundColorHover: 'transparent',
						backgroundColorFocus: 'rgba(0, 0, 0, 0.08)',
						textColorDisabled: '#fff',
						backgroundColorDisabled: '#bfbfbf',
					},
				},
				outlined: {
					accent: {
						textColor: '#00bef0',
						backgroundColor: 'transparent',
						backgroundColorHover: lighten('#00bef0', 0.9),
						backgroundColorFocus: lighten('#00bef0', 0.9),
						textColorDisabled: '#fff',
						backgroundColorDisabled: '#bfbfbf',
					},
					dark: {
						textColor: '#000',
						backgroundColor: 'transparent',
						backgroundColorHover: lighten('#000', 0.9),
						backgroundColorFocus: lighten('#000', 0.9),
						textColorDisabled: '#fff',
						backgroundColorDisabled: '#bfbfbf',
					},
				},
			},
			iconButton: {
				textColor: '#000',
				textColorDisabled: '#fff',
				backgroundColor: '#fff',
				backgroundColorHover: lighten('#262626', 0.9),
				backgroundColorDisabled: '#bfbfbf',
			},
			alert: {
				borderRadius: '2px',
				default: {
					textColor: '#000',
					backgroundColor: lighten('#bfbfbf', 0.7),
					borderColor: '#bfbfbf',
				},
				warning: {
					textColor: '#000',
					backgroundColor: lighten('#D2B9A0', 0.7),
					borderColor: '#D2B9A0',
				},
				alarm: {
					textColor: '#000',
					backgroundColor: lighten('#ff6e78', 0.7),
					borderColor: '#ff6e78',
				},
				success: {
					textColor: '#000',
					backgroundColor: lighten('#00bef0', 0.7),
					borderColor: '#00bef0',
				},
			},
			materialCard: {
				backgroundColor: '#fff',
			},
			link: {
				textColor: '#00bef0',
				textColorHover: '#2196f3',
				textColorFocus: '#2196f3',
			},
			chart: {
				infinityPalette: [
					'#968CE1',
					'#00BEF0',
					'#69E6B4',
					'#D2B9A0',
					'#3C3C3C',
					lighten('#968CE1', 0.4),
					lighten('#00BEF0', 0.4),
					lighten('#69E6B4', 0.4),
					lighten('#D2B9A0', 0.4),
					lighten('#3C3C3C', 0.4),
				],
				positive: '#53e6ab',
				positiveLight: lighten('#53e6ab', 0.7),
				negative: '#ff6e78',
				negativeLight: lighten('#ff6e78', 0.7),
				neutral: '#00bef0',
				neutralLight: lighten('#00bef0', 0.3),
				gridLineColor: '#f2f2f2',
				labelColor: '#8c8c8c',
				scrollbarBackgroundColor: lighten('#bfbfbf', 0.6),
				trackBackgroundColor: lighten('#f5f8fa', 0.05),
			},
			tooltip: {
				backgroundColor: '#3C3C3C',
				textColor: '#fff',
			},
			badge: {
				gray: {
					backgroundColor: '#a6a6a6',
					textColor: '#fff',
				},
				green: {
					backgroundColor: '#00bef0',
					textColor: '#fff',
				},
				orange: {
					backgroundColor: '#ffdb6e',
					textColor: '#000',
				},
				red: {
					backgroundColor: '#ff6e78',
					textColor: '#fff',
				},
				yellow: {
					backgroundColor: '#ffeb3b',
					textColor: '#000',
				},
			},
			virtualTable: {
				borderColor: '#e1e1e1',
			},
			segmentedBar: {
				backgroundColor: fade('#ebebeb', 0.6),
				disabledTextColor: '#8c8c8c',
				activeBackgroundColor: '#fff',
				activeTextColor: '#262626',
				activeDisabledTextColor: '#8c8c8c',
			},
			overlay: {
				backgroundColor: 'rgba(62, 71, 87, 0.7)',
			},
			dialog: {
				borderRadius: '2px',
				header: {
					fontSize: '20px',
				},
			},
			fn: {
				pxToRem,
				createBoxShadow: createLightModeBoxShadow,
				createInsetBoxShadow: createInsetLightModeBoxShadow,
				onCreateGlobalStyle: () => import('./css/openbank/root.css').then(() => true),
			},
		},
		[ThemeMode.DARK]: {
			isDarkMode: true,
			isDefaultBrand: false,
			whiteLabelLogo: {
				bfm: openbankLogo,
				invoice: openbankLogo,
			},
			fontFamily: 'Graphik, sans-serif',
			fontSize: '14px',
			lineHeight: '1.2',
			palette: {
				accent: '#00a0cc',
				warning: '#9e8b78',
				alarm: '#cc585f',
				text: '#c9d1d9',
				textContrast: '#e1eaf2',
				label: '#878787',
				hint: '#82878c',
				stealth: '#383f4a',
				background: '#161b22',
				space: '#0e131a',
				canvas: '#1d2633',
				border: 'rgba(255, 255, 255, 0.22)',
				positive: '#40b385',
				negative: '#cc585f',
				neutral: '#00a0cc',
				superMuted: '#64686b',
			},
			appMenu: {
				logoBackgroundColor: '#100a1a',
				backgroundColor: '#100a1a',
				color: '#9aa0a6',
				activeIconColor: '#00bef0',
				activeTextColor: '#fff',
				dividerColor: '#100a1a',
				boxShadow: '0 0 50px rgba(0, 0, 0, 1)',
			},
			button: {
				borderRadius: '2px',
				contained: {
					accent: {
						textColor: '#e1eaf2',
						backgroundColor: '#00a0cc',
						backgroundColorHover: '#00a8d6',
						backgroundColorFocus: '#00a8d6',
						textColorDisabled: '#d1d1d1',
						backgroundColorDisabled: '#5e5e5e',
					},
					light: {
						textColor: '#e1eaf2',
						backgroundColor: '#323333',
						backgroundColorHover: '#3e4040',
						backgroundColorFocus: '#00a8d6',
						textColorDisabled: '#d1d1d1',
						backgroundColorDisabled: '#5e5e5e',
					},
				},
				text: {
					accent: {
						textColor: '#00a0cc',
						backgroundColor: 'transparent',
						backgroundColorHover: darken('#00a0cc', 0.7),
						backgroundColorFocus: '#383f4a',
						textColorDisabled: '#d1d1d1',
						backgroundColorDisabled: '#5e5e5e',
					},
					muted: {
						textColor: '#797979',
						backgroundColor: 'transparent',
						backgroundColorHover: darken('#797979', 0.7),
						backgroundColorFocus: '#383f4a',
						textColorDisabled: '#d1d1d1',
						backgroundColorDisabled: '#5e5e5e',
					},
					dark: {
						textColor: '#c9d1d9',
						backgroundColor: 'transparent',
						backgroundColorHover: 'transparent',
						backgroundColorFocus: '#383f4a',
						textColorDisabled: '#d1d1d1',
						backgroundColorDisabled: '#5e5e5e',
					},
				},
				outlined: {
					accent: {
						textColor: '#00a0cc',
						backgroundColor: 'transparent',
						backgroundColorHover: darken('#00a0cc', 0.7),
						backgroundColorFocus: darken('#00a0cc', 0.7),
						textColorDisabled: '#d1d1d1',
						backgroundColorDisabled: '#5e5e5e',
					},
					dark: {
						textColor: '#c9d1d9',
						backgroundColor: 'transparent',
						backgroundColorHover: darken('#c9d1d9', 0.7),
						backgroundColorFocus: darken('#c9d1d9', 0.7),
						textColorDisabled: '#d1d1d1',
						backgroundColorDisabled: '#5e5e5e',
					},
				},
			},
			iconButton: {
				textColor: '#e1eaf2',
				textColorDisabled: '#d1d1d1',
				backgroundColor: '#323333',
				backgroundColorHover: darken('#e1eaf2', 0.7),
				backgroundColorDisabled: '#5e5e5e',
			},
			alert: {
				borderRadius: '2px',
				default: {
					textColor: '#c9d1d9',
					backgroundColor: darken('#878787', 0.5),
					borderColor: '#878787',
				},
				warning: {
					textColor: '#c9d1d9',
					backgroundColor: darken('#9e8b78', 0.5),
					borderColor: '#9e8b78',
				},
				alarm: {
					textColor: '#c9d1d9',
					backgroundColor: darken('#cc585f', 0.5),
					borderColor: '#cc585f',
				},
				success: {
					textColor: '#c9d1d9',
					backgroundColor: darken('#00a0cc', 0.5),
					borderColor: '#00a0cc',
				},
			},
			materialCard: {
				backgroundColor: '#293340',
			},
			link: {
				textColor: '#00a0cc',
				textColorHover: '#2196f3',
				textColorFocus: '#2196f3',
			},
			chart: {
				infinityPalette: [
					'#847bc7',
					'#00a8d6',
					'#5ecca0',
					'#b8a28c',
					'#575757',
					lighten('#847bc7', 0.4),
					lighten('#00a8d6', 0.4),
					lighten('#5ecca0', 0.4),
					lighten('#b8a28c', 0.4),
					lighten('#575757', 0.4),
				],
				positive: '#40b385',
				positiveLight: lighten('#40b385', 0.4),
				negative: '#cc585f',
				negativeLight: lighten('#cc585f', 0.4),
				neutral: '#00a0cc',
				neutralLight: lighten('#00a0cc', 0.4),
				gridLineColor: '#384759',
				labelColor: '#d4d4d4',
				scrollbarBackgroundColor: darken('#293340', 0.1),
				trackBackgroundColor: darken('#f5f8fa', 0.75),
			},
			tooltip: {
				backgroundColor: '#3C3C3C',
				textColor: '#c9d1d9',
			},
			badge: {
				gray: {
					backgroundColor: '#737373',
					textColor: '#e1eaf2',
				},
				green: {
					backgroundColor: '#00a0cc',
					textColor: '#e1eaf2',
				},
				orange: {
					backgroundColor: '#cc7a00',
					textColor: '#e1eaf2',
				},
				red: {
					backgroundColor: '#cc585f',
					textColor: '#e1eaf2',
				},
				yellow: {
					backgroundColor: '#e6d435',
					textColor: '#262626',
				},
			},
			virtualTable: {
				borderColor: '#383f4a',
			},
			segmentedBar: {
				backgroundColor: fade('#383f4a', 0.6),
				disabledTextColor: '#878787',
				activeBackgroundColor: '#0e131a',
				activeTextColor: '#c9d1d9',
				activeDisabledTextColor: '#878787',
			},
			overlay: {
				backgroundColor: 'rgba(62, 71, 87, 0.7)',
			},
			dialog: {
				borderRadius: '2px',
				header: {
					fontSize: '20px',
				},
			},
			fn: {
				pxToRem,
				createBoxShadow: createDarkModeBoxShadow,
				createInsetBoxShadow: createInsetDarkModeBoxShadow,
				onCreateGlobalStyle: () => import('./css/openbank/root.css').then(() => true),
			},
		},
	},
	[ThemeBrand.SOVCOMBANK]: {
		[ThemeMode.LIGHT]: {
			isDarkMode: false,
			isDefaultBrand: false,
			whiteLabelLogo: {
				bfm: sovcombankLogo,
				invoice: sovcombankLogo,
			},
			fontFamily: 'Roboto, sans-serif',
			fontSize: '14px',
			lineHeight: '1.2',
			palette: {
				accent: '#003790',
				warning: '#D2B9A0',
				alarm: '#fc5055',
				text: '#000',
				textContrast: '#fff',
				label: '#607286',
				hint: '#bfbfbf',
				stealth: '#ebebeb',
				background: '#f6f8fb',
				space: '#fff',
				canvas: '#fff',
				border: '#ccc',
				positive: '#7acd5b',
				negative: '#e05050',
				neutral: '#0f8eff',
				superMuted: '#73777b',
			},
			appMenu: {
				logoBackgroundColor: '#fff',
				backgroundColor: '#fff',
				color: '#003790',
				activeIconColor: '#fc5055',
				activeTextColor: '#fc5055',
				dividerColor: '#ebebeb',
				boxShadow: '0 0 20px rgba(0, 0, 0, 0.2)',
			},
			button: {
				borderRadius: '4px',
				contained: {
					accent: {
						textColor: '#fff',
						backgroundColor: '#003790',
						backgroundColorHover: '#002c74',
						backgroundColorFocus: '#002c74',
						textColorDisabled: '#fff',
						backgroundColorDisabled: '#bfbfbf',
					},
					light: {
						textColor: '#262626',
						backgroundColor: '#fff',
						backgroundColorHover: '#ebebeb',
						backgroundColorFocus: '#ebebeb',
						textColorDisabled: '#fff',
						backgroundColorDisabled: '#bfbfbf',
					},
				},
				text: {
					accent: {
						textColor: '#003790',
						backgroundColor: 'transparent',
						backgroundColorHover: lighten('#003790', 0.9),
						backgroundColorFocus: 'rgba(0, 0, 0, 0.08)',
						textColorDisabled: '#fff',
						backgroundColorDisabled: '#bfbfbf',
					},
					muted: {
						textColor: '#607286',
						backgroundColor: 'transparent',
						backgroundColorHover: lighten('#607286', 0.9),
						backgroundColorFocus: 'rgba(0, 0, 0, 0.08)',
						textColorDisabled: '#fff',
						backgroundColorDisabled: '#bfbfbf',
					},
					dark: {
						textColor: '#000',
						backgroundColor: 'transparent',
						backgroundColorHover: 'transparent',
						backgroundColorFocus: 'rgba(0, 0, 0, 0.08)',
						textColorDisabled: '#fff',
						backgroundColorDisabled: '#bfbfbf',
					},
				},
				outlined: {
					accent: {
						textColor: '#003790',
						backgroundColor: 'transparent',
						backgroundColorHover: lighten('#003790', 0.9),
						backgroundColorFocus: lighten('#003790', 0.9),
						textColorDisabled: '#fff',
						backgroundColorDisabled: '#bfbfbf',
					},
					dark: {
						textColor: '#000',
						backgroundColor: 'transparent',
						backgroundColorHover: lighten('#000', 0.9),
						backgroundColorFocus: lighten('#000', 0.9),
						textColorDisabled: '#fff',
						backgroundColorDisabled: '#bfbfbf',
					},
				},
			},
			iconButton: {
				textColor: '#262626',
				textColorDisabled: '#fff',
				backgroundColor: '#fff',
				backgroundColorHover: lighten('#262626', 0.9),
				backgroundColorDisabled: '#bfbfbf',
			},
			alert: {
				borderRadius: '4px',
				default: {
					textColor: '#000',
					backgroundColor: '#f6f8fb',
					borderColor: darken('#f6f8fb', 0.05),
				},
				warning: {
					textColor: '#000',
					backgroundColor: '#f6f8fb',
					borderColor: darken('#f6f8fb', 0.05),
				},
				alarm: {
					textColor: '#000',
					backgroundColor: lighten('#fc5055', 0.7),
					borderColor: '#fc5055',
				},
				success: {
					textColor: '#fff',
					backgroundColor: lighten('#0f8eff', 0.7),
					borderColor: '#0f8eff',
				},
			},
			materialCard: {
				backgroundColor: '#fff',
			},
			link: {
				textColor: '#0f8eff',
				textColorHover: '#003790',
				textColorFocus: '#003790',
			},
			chart: {
				infinityPalette: [
					'#0f8eff',
					'#fc5055',
					'#003790',
					lighten('#0f8eff', 0.1),
					lighten('#fc5055', 0.1),
					lighten('#003790', 0.1),
					lighten('#0f8eff', 0.2),
					lighten('#fc5055', 0.2),
					lighten('#003790', 0.2),
				],
				positive: '#7acd5b',
				positiveLight: lighten('#7acd5b', 0.7),
				negative: '#e05050',
				negativeLight: lighten('#e05050', 0.7),
				neutral: '#0f8eff',
				neutralLight: lighten('#0f8eff', 0.3),
				gridLineColor: '#f2f2f2',
				labelColor: '#8c8c8c',
				scrollbarBackgroundColor: lighten('#bfbfbf', 0.6),
				trackBackgroundColor: lighten('#f5f8fa', 0.05),
			},
			tooltip: {
				backgroundColor: '#fff',
				textColor: '#000',
				$boxShadow: 'box-shadow: 0 10px 34px 0 rgba(175, 196, 208, 0.57)',
			},
			badge: {
				gray: {
					backgroundColor: '#607286',
					textColor: '#fff',
				},
				green: {
					backgroundColor: '#7acd5b',
					textColor: '#fff',
				},
				orange: {
					backgroundColor: '#ffc107',
					textColor: '#000',
				},
				red: {
					backgroundColor: '#e05050',
					textColor: '#fff',
				},
				yellow: {
					backgroundColor: '#ffeb3b',
					textColor: '#000',
				},
			},
			virtualTable: {
				borderColor: '#e1e1e1',
			},
			segmentedBar: {
				backgroundColor: fade('#ebebeb', 0.6),
				disabledTextColor: '#8c8c8c',
				activeBackgroundColor: '#fff',
				activeTextColor: '#000',
				activeDisabledTextColor: '#8c8c8c',
			},
			overlay: {
				backgroundColor: 'rgba(62, 71, 87, 0.7)',
			},
			dialog: {
				borderRadius: '2px',
				header: {
					fontSize: '20px',
				},
			},
			fn: {
				pxToRem,
				createBoxShadow: createLightModeBoxShadow,
				createInsetBoxShadow: createInsetLightModeBoxShadow,
			},
		},
		[ThemeMode.DARK]: {
			isDarkMode: true,
			isDefaultBrand: false,
			whiteLabelLogo: {
				bfm: sovcombankDarkModeLogo,
				invoice: sovcombankDarkModeLogo,
			},
			fontFamily: 'Roboto, sans-serif',
			fontSize: '14px',
			lineHeight: '1.2',
			palette: {
				accent: '#0f8eff',
				warning: '#cc7a00',
				alarm: '#fc5055',
				text: '#c9d1d9',
				textContrast: '#e1eaf2',
				label: '#7489a1',
				hint: '#607286',
				stealth: '#383f4a',
				background: '#161b22',
				space: '#0e131a',
				canvas: '#1d2633',
				border: 'rgba(255, 255, 255, 0.22)',
				positive: '#5a9943',
				negative: '#ad3e3e',
				neutral: '#0c73cc',
				superMuted: '#64686b',
			},
			appMenu: {
				logoBackgroundColor: '#000a1a',
				backgroundColor: '#000a1a',
				color: '#c9d1d9',
				activeIconColor: '#0f8eff',
				activeTextColor: '#0f8eff',
				dividerColor: '#000a1a',
				boxShadow: '0 0 50px rgba(0, 0, 0, 1)',
			},
			button: {
				borderRadius: '4px',
				contained: {
					accent: {
						textColor: '#e1eaf2',
						backgroundColor: '#0f8eff',
						backgroundColorHover: '#0c73cc',
						backgroundColorFocus: '#0c73cc',
						textColorDisabled: '#d1d1d1',
						backgroundColorDisabled: darken('#f6f8fb', 0.8),
					},
					light: {
						textColor: '#e1eaf2',
						backgroundColor: '#323333',
						backgroundColorHover: '#3e4040',
						backgroundColorFocus: '#3e4040',
						textColorDisabled: '#d1d1d1',
						backgroundColorDisabled: darken('#f6f8fb', 0.8),
					},
				},
				text: {
					accent: {
						textColor: '#0f8eff',
						backgroundColor: 'transparent',
						backgroundColorHover: darken('#0f8eff', 0.7),
						backgroundColorFocus: '#383f4a',
						textColorDisabled: '#d1d1d1',
						backgroundColorDisabled: darken('#f6f8fb', 0.8),
					},
					muted: {
						textColor: '#7489a1',
						backgroundColor: 'transparent',
						backgroundColorHover: darken('#7489a1', 0.7),
						backgroundColorFocus: '#383f4a',
						textColorDisabled: '#d1d1d1',
						backgroundColorDisabled: darken('#f6f8fb', 0.8),
					},
					dark: {
						textColor: '#c9d1d9',
						backgroundColor: 'transparent',
						backgroundColorHover: 'transparent',
						backgroundColorFocus: '#383f4a',
						textColorDisabled: '#d1d1d1',
						backgroundColorDisabled: darken('#f6f8fb', 0.8),
					},
				},
				outlined: {
					accent: {
						textColor: '#0f8eff',
						backgroundColor: 'transparent',
						backgroundColorHover: darken('#0f8eff', 0.7),
						backgroundColorFocus: darken('#0f8eff', 0.7),
						textColorDisabled: '#d1d1d1',
						backgroundColorDisabled: darken('#f6f8fb', 0.8),
					},
					dark: {
						textColor: '#c9d1d9',
						backgroundColor: 'transparent',
						backgroundColorHover: darken('#c9d1d9', 0.7),
						backgroundColorFocus: darken('#c9d1d9', 0.7),
						textColorDisabled: '#d1d1d1',
						backgroundColorDisabled: darken('#f6f8fb', 0.8),
					},
				},
			},
			iconButton: {
				textColor: '#e1eaf2',
				textColorDisabled: '#d1d1d1',
				backgroundColor: '#323333',
				backgroundColorHover: darken('#e1eaf2', 0.7),
				backgroundColorDisabled: darken('#f6f8fb', 0.8),
			},
			alert: {
				borderRadius: '4px',
				default: {
					textColor: '#c9d1d9',
					backgroundColor: darken('#f6f8fb', 0.8),
					borderColor: darken('#f6f8fb', 0.7),
				},
				warning: {
					textColor: '#c9d1d9',
					backgroundColor: darken('#f6f8fb', 0.8),
					borderColor: darken('#f6f8fb', 0.7),
				},
				alarm: {
					textColor: '#c9d1d9',
					backgroundColor: darken('#cf2733', 0.5),
					borderColor: '#cf2733',
				},
				success: {
					textColor: '#c9d1d9',
					backgroundColor: darken('#20b37b', 0.5),
					borderColor: '#20b37b',
				},
			},
			materialCard: {
				backgroundColor: '#293340',
			},
			link: {
				textColor: '#0f8eff',
				textColorHover: '#fc5055',
				textColorFocus: '#fc5055',
			},
			chart: {
				infinityPalette: [
					'#0f8eff',
					'#fc5055',
					'#003790',
					lighten('#0f8eff', 0.1),
					lighten('#fc5055', 0.1),
					lighten('#003790', 0.1),
					lighten('#0f8eff', 0.2),
					lighten('#fc5055', 0.2),
					lighten('#003790', 0.2),
				],
				positive: '#7acd5b',
				positiveLight: lighten('#7acd5b', 0.7),
				negative: '#e05050',
				negativeLight: lighten('#e05050', 0.7),
				neutral: '#0f8eff',
				neutralLight: lighten('#0f8eff', 0.3),
				gridLineColor: '#384759',
				labelColor: '#d4d4d4',
				scrollbarBackgroundColor: darken('#293340', 0.1),
				trackBackgroundColor: darken('#f5f8fa', 0.75),
			},
			tooltip: {
				backgroundColor: darken('#f6f8fb', 0.8),
				textColor: '#c9d1d9',
				$boxShadow: 'box-shadow: 0 0 20px rgba(0, 0, 0, 0.5)',
			},
			badge: {
				gray: {
					backgroundColor: '#607286',
					textColor: '#e1eaf2',
				},
				green: {
					backgroundColor: '#5a9943',
					textColor: '#e1eaf2',
				},
				orange: {
					backgroundColor: '#cc7a00',
					textColor: '#e1eaf2',
				},
				red: {
					backgroundColor: '#e05050',
					textColor: '#e1eaf2',
				},
				yellow: {
					backgroundColor: '#e6d435',
					textColor: '#262626',
				},
			},
			virtualTable: {
				borderColor: '#383f4a',
			},
			segmentedBar: {
				backgroundColor: fade('#383f4a', 0.6),
				disabledTextColor: '#878787',
				activeBackgroundColor: '#0e131a',
				activeTextColor: '#c9d1d9',
				activeDisabledTextColor: '#878787',
			},
			overlay: {
				backgroundColor: 'rgba(62, 71, 87, 0.7)',
			},
			dialog: {
				borderRadius: '2px',
				header: {
					fontSize: '20px',
				},
			},
			fn: {
				pxToRem,
				createBoxShadow: createDarkModeBoxShadow,
				createInsetBoxShadow: createInsetDarkModeBoxShadow,
			},
		},
	},
};

function createTheme(value: ThemeIdentifier) {
	const [themeBrand, themeType] = value;
	const theme = themesMap[themeBrand][themeType];

	return theme;
}

function createMaterialTheme(value: ThemeIdentifier) {
	const { fontFamily, palette } = createTheme(value);
	const materialTheme = {
		fontFamily,
		borderRadius: 0,
		dialog: {
			bodyFontSize: 14,
		},
		stepper: {
			iconColor: palette.accent,
		},
		textField: {
			textColor: palette.text,
			hintColor: palette.hint,
			floatingLabelColor: palette.label,
			disabledTextColor: palette.label,
			errorColor: palette.alarm,
			focusColor: palette.accent,
			borderColor: palette.border,
		},
		checkbox: {
			labelColor: palette.text,
			labelDisabledColor: palette.label,
		},
		palette: {
			textColor: palette.text,
			primary1Color: palette.accent,
			primary2Color: palette.accent,
			accent1Color: palette.accent,
			canvasColor: palette.canvas,
			shadowColor: '#000',
		},
		zIndex: {
			layer: 2100,
		},
	} as any;

	return materialTheme;
}

function createLightModeBoxShadow(elevation: ShadowElevation) {
	const map = {
		1: `box-shadow: 0 1px 4px rgba(0, 0, 0, 0.11), 0 1px 2px rgba(0, 0, 0, 0.11);`,
		2: `box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.12);`,
		3: `box-shadow: 0 1px 8px rgba(0, 0, 0, 0.13), 0 1px 6px rgba(0, 0, 0, 0.13);`,
		4: `box-shadow: 0 1px 10px rgba(0, 0, 0, 0.14), 0 1px 8px rgba(0, 0, 0, 0.14);`,
		5: `box-shadow: 0 1px 12px rgba(0, 0, 0, 0.15), 0 1px 10px rgba(0, 0, 0, 0.15);`,
		6: `box-shadow: 0 1px 14px rgba(0, 0, 0, 0.16), 0 1px 12px rgba(0, 0, 0, 0.16);`,
		7: `box-shadow: 0 1px 16px rgba(0, 0, 0, 0.17), 0 1px 14px rgba(0, 0, 0, 0.17);`,
		8: `box-shadow: 0 1px 18px rgba(0, 0, 0, 0.18), 0 1px 16px rgba(0, 0, 0, 0.18);`,
		9: `box-shadow: 0 1px 20px rgba(0, 0, 0, 0.19), 0 1px 18px rgba(0, 0, 0, 0.19);`,
		10: `box-shadow: 0 1px 22px rgba(0, 0, 0, 0.20), 0 1px 20px rgba(0, 0, 0, 0.20);`,
	};

	return map[elevation] || '';
}

function createDarkModeBoxShadow(elevation: ShadowElevation) {
	const map = {
		1: `box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4), 0 1px 2px rgba(0, 0, 0, 0.4);`,
		2: `box-shadow: 0 1px 6px rgba(0, 0, 0, 0.41), 0 1px 4px rgba(0, 0, 0.41);`,
		3: `box-shadow: 0 1px 8px rgba(0, 0, 0, 0.42), 0 1px 6px rgba(0, 0, 0, 0.42);`,
		4: `box-shadow: 0 1px 10px rgba(0, 0, 0, 0.43), 0 1px 8px rgba(0, 0, 0, 0.43);`,
		5: `box-shadow: 0 1px 12px rgba(0, 0, 0, 0.44), 0 1px 10px rgba(0, 0, 0, 0.44);`,
		6: `box-shadow: 0 1px 14px rgba(0, 0, 0, 0.45), 0 1px 12px rgba(0, 0, 0, 0.45);`,
		7: `box-shadow: 0 1px 16px rgba(0, 0, 0, 0.46), 0 1px 14px rgba(0, 0, 0, 0.46);`,
		8: `box-shadow: 0 1px 18px rgba(0, 0, 0, 0.47), 0 1px 16px rgba(0, 0, 0, 0.47);`,
		9: `box-shadow: 0 1px 20px rgba(0, 0, 0, 0.48), 0 1px 18px rgba(0, 0, 0, 0.48);`,
		10: `box-shadow: 0 1px 22px rgba(0, 0, 0, 0.49), 0 1px 20px rgba(0, 0, 0, 0.49);`,
	};

	return map[elevation] || '';
}

function createInsetLightModeBoxShadow(elevation: ShadowElevation) {
	const map = {
		1: `inset 0 1px 4px rgba(0, 0, 0, 0.2)`,
		2: `inset 0 1px 6px rgba(0, 0, 0, 0.21)`,
	};

	return map[elevation] || '';
}

function createInsetDarkModeBoxShadow(elevation: ShadowElevation) {
	const map = {
		1: `inset 0 1px 4px rgba(0, 0, 0, 0.5)`,
		2: `inset 0 1px 6px rgba(0, 0, 0, 0.51)`,
	};

	return map[elevation] || '';
}

export { themesMap, createTheme, createMaterialTheme };
