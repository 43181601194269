import { Store } from 'redux';

import { IAppState } from '@store';
import { isReceiveAction } from '@flux';
import { types as clientsTypes } from '@clients/actions/types';
import { types as employeesTypes } from '@employees/actions/types';
import { types as counterpartiesTypes } from '@counterparties/actions/types';
import { types as tenantLegalEntitiesTypes } from '@tenant-legal-entities/actions/types';
import { invalidateCounterparties, invalidateCounterpartyGroups } from '@counterparties/actions/invalidators';

// чтобы избежать зацикливания экшенов
// в эффект инвалидации импортируем из сторонних модулей только типы, на которые нужно реагировать инвалидацией
// функции типа invalidateSomething их сторонних модулей импортировать запрещается, кроме отдельных случаев, типа операций

function askCounterpartiesInvalidator(store: Store<IAppState>, action: StoreAction) {
	const { dispatch } = store;
	const syncMap = {
		[counterpartiesTypes.RUN_COUNTERPARTIES_INVALIDATION_EFFECT]: true,
	};
	const asyncMap = {
		[clientsTypes.ADD_CLIENT]: true,
		[clientsTypes.UPDATE_CLIENT]: true,
		[clientsTypes.REMOVE_CLIENT]: true,
		[employeesTypes.UPDATE_EMPLOYEE]: true,
		[employeesTypes.REMOVE_EMPLOYEE]: true,
		[tenantLegalEntitiesTypes.TRANSFORM_TENANT_LEGAL_ENTITY_TO_COUNTERPARTY]: true,
	};

	if (syncMap[action.type]) {
		dispatch(invalidateCounterparties());
	} else if (asyncMap[action.type] && isReceiveAction(action)) {
		dispatch(invalidateCounterparties());
	}
}

function askCounterpartyGroupsInvalidator(store: Store<IAppState>, action: StoreAction) {
	const { dispatch } = store;
	const syncMap = {
		[counterpartiesTypes.RUN_COUNTERPARTY_GROUPS_INVALIDATION_EFFECT]: true,
	};

	if (syncMap[action.type]) {
		dispatch(invalidateCounterpartyGroups());
	}
}

function createInvalidatorEffect() {
	return (store: Store<IAppState>) => (next: (action: StoreAction) => void) => (action: StoreAction) => {
		next(action);
		askCounterpartiesInvalidator(store, action);
		askCounterpartyGroupsInvalidator(store, action);
	};
}

const runInvalidatorEffect = createInvalidatorEffect();

export { runInvalidatorEffect };
