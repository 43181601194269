import styled, { css } from 'styled-components';

import { MaterialCard } from '@ui/material-card';
import { FormRepeaterItemProps } from './form-repeater';

type ItemRootProps = {
	itemAppearance: FormRepeaterItemProps['appearance'];
	variant?: FormRepeaterItemProps['variant'];
};

const ItemRoot = styled(MaterialCard)<ItemRootProps>`
	position: relative;
	width: inherit;
	padding: 16px;
	margin-bottom: 8px;

	${p =>
		p.itemAppearance === 'simple' &&
		css`
			padding: 0 !important;
			box-shadow: none !important;
			margin-bottom: 0;
			background-color: transparent !important;
		`}

	${p =>
		p.variant === 'dark-alternate' &&
		p.theme.isDarkMode &&
		css`
			background-color: transparent !important;
			border: 1px solid ${p.theme.palette.stealth};
		`}
`;

const RemoveButtonLayout = styled.div`
	position: absolute;
	top: 4px;
	right: 4px;
	z-index: 100;

	& svg {
		margin-left: 0px !important;
	}

	& button {
		width: 24px;
		height: 24px;
	}
`;

const ContentLayout = styled.div`
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: center;
`;

const ChildrenLayout = styled.div`
	width: 100%;
`;

const Input = styled.input`
	outline: none;
	width: 100%;
	height: 18px;
	font-size: 14px;
	border: none;
	border-bottom: 1px solid transparent;
	line-height: 16px;
	background-color: transparent;

	${p => css`
		color: ${p.theme.palette.text};
		border-color: ${p.theme.palette.accent};
	`}
`;

interface ITextLayout {
	color: 'success' | 'error' | 'warning';
}

const TextLayout = styled.div<ITextLayout>`
	position: relative;
	line-height: 1.2;
	font-size: 14px;
	padding: 20px;

	${p => {
		const textColorMap = {
			success: p.theme.palette.accent,
			error: p.theme.palette.alarm,
			warning: p.theme.palette.warning,
		};

		return (
			p.color &&
			css`
				color: ${textColorMap[p.color]};
			`
		);
	}}
`;

export { ItemRoot, RemoveButtonLayout, ContentLayout, ChildrenLayout, Input, TextLayout };
