import React from 'react';

import { formatCurrency } from './utils';
import { Root, Integer, Fraction, Symbol } from './styled';

type CurrencyFormaterProps = {
	children: number;
	code?: string;
	locale?: string;
	separator?: string;
	fontSize?: string;
	color?: string;
	prefix?: string | React.ReactElement<any>;
	needFraction?: boolean;
};

const CurrencyFormater: React.FC<CurrencyFormaterProps> = props => {
	const { children, code, locale, separator, fontSize, color, prefix, needFraction } = props;
	const patternSpace = String.fromCharCode(160);
	const formatted = typeof children === 'number' ? formatCurrency(children, code, locale) : '0';
	const digits = formatted.replace(/[^\d\s\.\,\+\-]/g, '');
	const symbol = formatted.replace(/(\d|\s|\.|\,|\+|\-)/g, '');
	const parts = digits.split(/[\.\,]/g);
	const integer = parts[0];
	const fraction = parts[1];

	return (
		<Root fontSize={fontSize} color={color}>
			{prefix || null}
			{prefix && `${patternSpace}`}
			<Integer className='currency-formatter-integer'>{integer}</Integer>
			{needFraction && fraction && separator}
			{needFraction && fraction && (
				<Fraction className='currency-formatter-fraction' fontSize={fontSize}>
					{fraction}
				</Fraction>
			)}
			{patternSpace}
			<Symbol className='currency-formatter-symbol'>{symbol}</Symbol>
		</Root>
	);
};

CurrencyFormater.defaultProps = {
	code: 'RUR',
	locale: 'ru',
	separator: ',',
	needFraction: true,
};

const CurrencyFormaterPrefix: React.FC<{ isExpense: boolean }> = args => {
	return (
		<span style={{ fontSize: 18, verticalAlign: 'middle', color: args.isExpense ? '#ff3a48' : '#00c853' }}>
			{args.isExpense ? String.fromCharCode(8211) : '+'}
		</span>
	);
};

export { CurrencyFormaterPrefix };
export default CurrencyFormater;
