import styled, { css } from 'styled-components';

const Root = styled.div`
	position: relative;
	width: 100%;
	height: auto;
`;

const ContentLayout = styled.div`
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: center;
`;

const Title = styled.span``;

const LinkLayout = styled.div`
	margin-bottom: 8px;
`;

const Link = styled.div`
	display: inline-flex;
	justify-content: flex-start;
	align-items: center;
	font-size: 12px;
	font-weight: 400;
	line-height: 1;
	text-transform: none;
	cursor: pointer;
	text-decoration: none;
	transition: color 0.2s ease-in-out;

	${p => css`
		color: ${p.theme.palette.accent};

		&:hover {
			color: ${p.theme.palette.accent};
		}
	`}
`;

const IconLayout = styled.div`
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	align-items: center;
	margin-right: 8px;
`;

export { Root, LinkLayout, ContentLayout, Title, Link, IconLayout };
