import React, { memo, useMemo } from 'react';

import { useMapState, useMapDispatch, useAutoFetch } from '@flux';
import { fundsApi } from '@core/api/funds';
import { createBooleanMap, extractKeysToArray } from '@utils/object';
import { detectIsBankAccountFundsRegister, detectIsVirtualAccountFundsRegister } from '@utils/finance';
import { withFormAutopicker, defaultAutopickerTransformInput, defaultAutopickerTransformOutput } from '@ui/autopicker';
import { mainFundsRegistersActionsPack } from '@funds-registers/actions';
import { mainFundsRegistersSelectorsPack } from '@funds-registers/selectors';
import {
	transformFundsRegisterBriefToFundsRegister,
	transformFundsRegisterToFundsRegisterBrief,
} from '@funds-registers/transformers';
import type { LevelAccess, DirectionAccess } from '@funds-registers/models';
import {
	FundsRegisterAutopicker as XFundsRegisterAutopicker,
	FundsRegisterAutopickerProps as XFundsRegisterAutopickerProps,
} from './funds-register-autopicker.view';

export type FundsRegisterAutopickerProps = {
	variant?: 'all' | 'bank-accounts' | 'virtual-accounts';
	levelAccess?: LevelAccess;
	directionAccess?: DirectionAccess;
	includeArchived?: boolean;
	filter?: (fundsRegister: FundsRegister) => boolean;
} & Omit<
	XFundsRegisterAutopickerProps,
	'dataSource' | 'isFetching' | 'isLoaded' | 'hasChangeRight' | 'crudTransformer'
> &
	Partial<Pick<XFundsRegisterAutopickerProps, 'crudTransformer'>>;

const FundsRegisterAutopicker: React.FC<FundsRegisterAutopickerProps> = memo(props => {
	const { variant, levelAccess, directionAccess, includeArchived, filter, crudTransformer } = props;
	const [hasChangeRight, isFetching, isLoaded, fundsRegisters] = useMapState([
		mainFundsRegistersSelectorsPack.selectFundsRegistersRight.change,
		mainFundsRegistersSelectorsPack.selectAsyncFundsRegisterStatistics.selectIsFetching,
		mainFundsRegistersSelectorsPack.selectAsyncFundsRegisterStatistics.selectIsLoaded,
		s => mainFundsRegistersSelectorsPack.selectFilteredFundsRegistersByAccessList(s, levelAccess, directionAccess),
	]);
	const [fetchFundsRegisterStatistics] = useMapDispatch([mainFundsRegistersActionsPack.fetchFundsRegisterStatistics]);
	const dataSource: Array<FundsRegisterBrief> = useMemo(() => {
		const items = fundsRegisters
			.filter(x => {
				const filterMap: Record<FundsRegisterAutopickerProps['variant'], (x: FundsRegister) => boolean> = {
					all: () => true,
					'bank-accounts': detectIsBankAccountFundsRegister,
					'virtual-accounts': detectIsVirtualAccountFundsRegister,
				};
				const performArchived = includeArchived || !x.Archived;

				return performArchived && filterMap[variant](x) && filter(x);
			})
			.map(x => ({
				...new fundsApi.package.FundsRegisterBrief(),
				ID: x.ID,
				Name: x.Name,
				Number: x.RegisterNumber,
				Bank: (x as BankAccountFundsRegister).Bank || null,
				CurrencyID: x.CurrencyID,
				Purpose: x.Purpose,
			}));

		return items;
	}, [fundsRegisters, variant, includeArchived, filter]);

	useAutoFetch({
		selector: mainFundsRegistersSelectorsPack.selectAsyncFundsRegisterStatistics.selectDidInvalidate,
		fetch: () => fetchFundsRegisterStatistics(),
	});

	return (
		<XFundsRegisterAutopicker
			{...props}
			hasChangeRight={hasChangeRight}
			isFetching={isFetching && !isLoaded}
			isUpdating={isFetching}
			dataSource={dataSource}
			crudTransformer={crudTransformer}
		/>
	);
});

FundsRegisterAutopicker.defaultProps = {
	variant: 'all',
	directionAccess: 'all',
	levelAccess: 'read',
	filter: () => true,
	transformInput: defaultAutopickerTransformInput,
	transformOutput: defaultAutopickerTransformOutput,
	crudTransformer: transformFundsRegisterToFundsRegisterBrief,
};

const FormFundsRegisterAutopicker = withFormAutopicker<Omit<FundsRegisterAutopickerProps, 'name'>, unknown>(
	FundsRegisterAutopicker,
);

const fundsRegisterAutopickerTransformer = {
	ID: {
		single: {
			transformInput: ({ input }: { input: number }) =>
				typeof input === 'number' && input > 0 ? { [input]: true } : null,
			transformOutput: ({ output }) => Number(extractKeysToArray(output)[0]) || -1,
		},
		multiple: {
			transformInput: ({ input }: { input: Array<number> }) => createBooleanMap(input, x => x),
			transformOutput: ({ output }) => extractKeysToArray(output, Number),
		},
	},
	fundsRegisterBrief: {
		single: {
			transformInput: ({ input }: { input: FundsRegisterBrief | null }) => (input ? { [input.ID]: true } : null),
			transformOutput: ({ items }) => (items[0] as Array<FundsRegisterBrief>) || null,
		},
		multiple: {
			transformInput: ({ input }: { input: Array<FundsRegisterBrief> }) => createBooleanMap(input, x => x.ID),
			transformOutput: ({ items }) => items as Array<FundsRegisterBrief>,
		},
	},
	fundsRegister: {
		single: {
			transformInput: ({ input }: { input: FundsRegister | null }) => (input ? { [input.ID]: true } : null),
			transformOutput: ({ items }) => transformFundsRegisterBriefToFundsRegister(items[0]) || null,
		},
		multiple: {
			transformInput: ({ input }: { input: Array<FundsRegister> }) => createBooleanMap(input, x => x.ID),
			transformOutput: ({ items }) => items.map(x => transformFundsRegisterBriefToFundsRegister(x)),
		},
	},
};

export { FundsRegisterAutopicker, FormFundsRegisterAutopicker, fundsRegisterAutopickerTransformer };
