import { combineReducers } from 'redux';

import { mainDocumentTemplatesReducer, MainDocumentTemplatesState } from './main.reducer';

export type DocumentTemplatesState = {
	main: MainDocumentTemplatesState;
};

const documentTemplatesReducer = combineReducers<DocumentTemplatesState>({
	main: mainDocumentTemplatesReducer,
});

export { documentTemplatesReducer };
