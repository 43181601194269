import React from 'react';

import { CloseIcon } from '@ui/icons/close';
import { Root } from './styled';

export type CloseButtonProps = {
	size?: 'normal' | 'small';
	onClick: (e: React.MouseEvent) => void;
};

const CloseButton: React.FC<CloseButtonProps> = props => {
	const { size, onClick } = props;

	return (
		<Root size={size} onClick={onClick}>
			<CloseIcon color='black' size={sizesMap[size]} />
		</Root>
	);
};

const sizesMap: Record<CloseButtonProps['size'], number> = {
	normal: 24,
	small: 18,
};

CloseButton.defaultProps = {
	size: 'normal',
};

export { CloseButton };
