import styled, { css, keyframes } from 'styled-components';

const enterMotion = keyframes`
	0% {
		opacity: 0;
		transform: scale(1, 0);
	}
	100% {
		opacity: 1;
		transform: scale(1, 1);
	}
`;

const leaveMotion = keyframes`
	0% {
		opacity: 1;
		transform: scale(1, 1);
	}
	100% {
		opacity: 0;
		transform: scale(1, 0);
	}
`;

type RootProps = {
	isClosing: boolean;
};

const Root = styled.div<RootProps>`
	position: fixed;
	top: 0;
	left: 0;
	z-index: 2100;
	transform-origin: 0 0;
	will-change: transform;

	${p => css`
		animation-name: ${p.isClosing ? leaveMotion : enterMotion};
		animation-duration: 0.2s;
		animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
		animation-fill-mode: both;
		background-color: ${p.theme.palette.canvas};
		${p.theme.fn.createBoxShadow(2)}
	`}
`;

export { Root };
