import styled from 'styled-components';

const ReportLayout = styled.div`
	width: 100%;
	min-height: 100vh;
	background-color: #fff;
	overflow-x: hidden;
`;

export { ReportLayout };
