function getTotalAmountBySeries(aciSeriesList: Array<PLOperationAccountsChartItemSeries>): number {
	const total = aciSeriesList.reduce((acc, item) => {
		const total = item.Points.reduce((acc, point) => ((acc += point.Amount), acc), 0);

		return (acc += total);
	}, 0);

	return total;
}

function getNestedSeriesByTree(
	cfID: number,
	aciSeriesMap: Record<string, PLOperationAccountsChartItemSeries>,
	cfiMap: Record<string, CashflowItem>,
	series: Array<PLOperationAccountsChartItemSeries> = [],
): Array<PLOperationAccountsChartItemSeries> {
	let list = [];
	const item = aciSeriesMap[cfID];

	if (item && series.every(s => s.AccountsChartItemID !== item.AccountsChartItemID)) {
		list.push(aciSeriesMap[cfID]);
	}

	if (cfiMap[cfID]) {
		for (const child of cfiMap[cfID].ChildItems) {
			list = [...list, ...getNestedSeriesByTree(child.ID, aciSeriesMap, cfiMap, [...list, ...series])];
		}
	}

	return list;
}

const plUtils = {
	getTotalAmountBySeries,
	getNestedSeriesByTree,
};

export default plUtils;
