import React, { useMemo } from 'react';
import MaskedInput from 'react-text-mask';

import { useMapState, useMapDispatch, useAutoFetch } from '@flux';
import { createObjectMap } from '@utils/object';
import { detectIsFunction } from '@utils/helpers';
import { replaceMaskedValue } from '@utils/strings';
import { extractCurrencyDigitCode, detectIsBankAccountRegisterNumber } from '@utils/currency';
import { TextField, TextFieldProps } from '@ui/input-field';
import { withFormInput } from '@ui/forms/inputs';
import { getFormValue } from '@ui/forms/shared';
import { useFormContext } from '@ui/forms';
import { FormState, FormInputProps, getInputFieldValue, setInputFieldValue } from '@ui/forms';
import { fetchCurrenciesAction } from '@shared/actions/fetch-currencies.action';
import { selectAsyncCurrencies } from '@shared/selectors/currency-list';

export type FundsRegisterTextFieldProps = {
	appearance?: 'bank-account' | 'virtual-account';
	separator?: string;
} & TextFieldProps;

const FundsRegisterTextField: React.FC<FundsRegisterTextFieldProps> = props => {
	const { value, name, appearance, separator, ...rest } = props;
	const hintText = placeholderMap[appearance];
	const isBankAccount = appearance === 'bank-account';
	const mask = useMemo(
		() => [
			/\d/,
			/\d/,
			/\d/,
			/\d/,
			/\d/,
			separator,
			/\d/,
			/\d/,
			/\d/,
			separator,
			/\d/,
			separator,
			/\d/,
			/\d/,
			/\d/,
			/\d/,
			/\d/,
			/\d/,
			/\d/,
			/\d/,
			/\d/,
			/\d/,
			/\d/,
		],
		[separator],
	);

	return (
		<TextField name={name} labelText='ИНН' hintText={hintText} value={value} {...rest}>
			{isBankAccount && (
				<MaskedInput name={name} value={value} mask={mask} placeholderChar={'\u2000'} keepCharPositions />
			)}
		</TextField>
	);
};

FundsRegisterTextField.defaultProps = {
	appearance: 'bank-account',
	separator: ' ',
};

const placeholderMap = {
	'bank-account': 'xxxxx xxx x xxxxxxxxxxx',
	'virtual-account': 'xxxxx',
};

type WithFormProps = {
	allowNonStandardDigitCode?: boolean;
	getCurrencyID?: (formObject: unknown) => number;
	setCurrency?: (formObject: unknown, currency: Currency) => void;
} & Partial<FundsRegisterTextFieldProps> &
	FormInputProps;

const FormFundsRegisterTextFieldBase = withFormInput<Partial<FundsRegisterTextFieldProps>, unknown>(
	FundsRegisterTextField,
	(props, context, wrappedElem) => {
		const value = getInputFieldValue(props, context, wrappedElem) || '';

		return value !== '';
	},
	setInputFieldValue,
	getInputFieldValue,
);

const FormFundsRegisterTextField: React.FC<WithFormProps> = props => {
	const { appearance, onChange, getCurrencyID, setCurrency, separator } = props;
	const formContext = useFormContext<unknown>();
	const [isFetching, currencies] = useMapState([
		selectAsyncCurrencies.selectIsFetching,
		selectAsyncCurrencies.selectItem,
	]);
	const [fetchCurrencies] = useMapDispatch([fetchCurrenciesAction]);

	useAutoFetch({
		selector: selectAsyncCurrencies.selectDidInvalidate,
		fetch: () => fetchCurrencies,
	});

	const currenciesMap = useMemo(() => createObjectMap(currencies, x => x.DigitCode), [currencies]);
	const errorValidations = useMemo(() => createValidations(props, currenciesMap), [appearance, currenciesMap]);
	const errorMsgs = useMemo(() => createErrorMessages(props), [appearance]);
	const scope = useMemo(() => ({ appearance, currenciesMap }), [appearance, currenciesMap]);

	const handleChange = (e: React.SyntheticEvent<HTMLInputElement>, value: string) => {
		const { appearance, currenciesMap } = scope;
		const isBankAccount = appearance === 'bank-account';

		detectIsFunction(onChange) && onChange(e, value);

		if (isBankAccount) {
			const replacedValue = replaceMaskedValue(value, separator);
			const digitCode = extractCurrencyDigitCode(replacedValue);
			const currencyID = currenciesMap[digitCode]?.ID || -1;
			const currency = currencyID > 0 ? currenciesMap[digitCode] : null;
			const formObject = formContext.formObject;

			if (getCurrencyID(formObject) !== currencyID) {
				setCurrency(formObject, currency);
				formContext.handleObjectChange(formObject);
			}
		}
	};

	return (
		<FormFundsRegisterTextFieldBase
			{...props}
			isFetching={isFetching}
			errorValidations={errorValidations}
			errorMsgs={errorMsgs}
			onChange={handleChange}
		/>
	);
};

FormFundsRegisterTextField.defaultProps = {
	appearance: FundsRegisterTextField.defaultProps.appearance,
	getCurrencyID: () => -1,
	setCurrency: () => {},
};

const createValidations = (props: WithFormProps, currenciesMap: Record<string, Currency> = {}) => {
	return [
		(data: FormState<unknown>) => {
			const { appearance, allowNonStandardDigitCode, separator } = props;
			const isBankAccount = appearance === 'bank-account';

			if (isBankAccount) {
				const value = getFormValue({
					formObject: data.formObject,
					editedObject: data.editedBriefObjects,
					props,
				});
				const replacedValue = replaceMaskedValue(value, separator);
				const digitCode = extractCurrencyDigitCode(replacedValue);
				const currency = currenciesMap[digitCode];
				const hasSameCode =
					allowNonStandardDigitCode && digitCode === NON_STANDARD_RUB_DIGIT_CODE ? true : currency?.ID > 0;

				return hasSameCode;
			}

			return true;
		},
		(data: FormState<unknown>) => {
			const isBankAccount = props.appearance === 'bank-account';

			if (isBankAccount) {
				const value = getFormValue({
					formObject: data.formObject,
					editedObject: data.editedBriefObjects,
					props,
				});
				const replacedValue = replaceMaskedValue(value, props.separator) || '';
				const isCorrect = detectIsBankAccountRegisterNumber(replacedValue);

				return isCorrect;
			}

			return true;
		},
		...(props.errorValidations || []),
	];
};

const createErrorMessages = (props: WithFormProps) => {
	return ['Некорректная валюта счёта', 'Банковский счёт должен включать 20 цифр', ...(props.errorMsgs || [])];
};

const NON_STANDARD_RUB_DIGIT_CODE = '643';

export { FundsRegisterTextField, FormFundsRegisterTextField };
