import styled, { css } from 'styled-components';


type RootProps = {
	size: number;
	resourceUrl: any;
};

const Root = styled.i`
	position: relative;
	display: inline-block;
	width: 24px;
	height: 24px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	font-size: 0;

	${(p: RootProps) => p.size && css`
		width: ${p.size}px;
		height: ${p.size}px;
	`}

	${(p: RootProps) => p.resourceUrl && css`
		background-image: url(${p.resourceUrl});
	`}
`;

export {
	Root,
};
