import React from 'react';

import { Root, MessageLayout, MessageCardLayout } from './styled';

type MessageCardProps = {
	message?: string;
	withoutPadding?: boolean;
	withoutMinHeight?: boolean;
	textAlign?: 'center' | 'left' | 'right';
	children: React.ReactNode;
};

const MessageCard: React.FC<MessageCardProps> = props => {
	const { message, withoutPadding, withoutMinHeight, textAlign, children } = props;

	return (
		<Root withoutPadding={withoutPadding} withoutMinHeight={withoutMinHeight}>
			<MessageLayout textAlign={textAlign}>{children ? children : message}</MessageLayout>
		</Root>
	);
};

export { MessageCard, MessageCardLayout };
