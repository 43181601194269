import api from '../../../core/libs/api';
import createAction, { createStaticDataAction } from '../../../core/libs/action-creator';
import { createActionMessage, getCMSGUID, getPropInSafe } from '../../../core/libs/utils';
import { selectInvoice } from '../selectors/invoice-select';
import { fetchInvoiceByIDAction } from './invoice-select.actions';
import { selectCMSCreatePaymentDraft } from '@integrations/selectors';

const ADD_INVOICE_PAYMENT_DRAFT = '[INVOICE]: ADD_INVOICE_PAYMENT_DRAFT';
const SEND_PAYMENT_DRAFT = '[INVOICE]: SEND_PAYMENT_DRAFT';
const FETCH_INVOICE_AFTER_PAYMENT = '[INVOICE]: FETCH_INVOICE_AFTER_PAYMENT';
const SYNCHRONIZE_PAYMENT_STATUS = '[INVOICE]: SYNCHRONIZE_PAYMENT_STATUS';
const LINK_PAYMENTS_TO_INVOICE = '[INVOICE]: LINK_PAYMENT_TO_INVOICE';
const REMOVE_INVOICE_PAYMENTS_LINK = '[INVOICE]: REMOVE_INVOICE_PAYMENTS_LINK';
const RUN_FAST_PAY_INVOICE_EFFECT = '[INVOICE]: RUN_FAST_PAY_INVOICE_EFFECT';
const SET_STATUS_FOR_FAST_PAY_INVOICE = '[INVOICE]: SET_STATUS_FOR_FAST_PAY_INVOICE';
const CONTROL_FAST_PAY_INVOICE_FORM = '[INVOICE]: SHOW_FAST_PAY_INVOICE_FORM';
const SET_FUNDS_REGISTERS_FOR_FAST_PAY_INVOICE = '[INVOICE]: SET_FUNDS_REGISTERS_FOR_FAST_PAY_INVOICE';
const CONTROL_REMOTE_PAYMENT_SIGNATURE_FORM = '[INVOICE]: CONTROL_REMOTE_PAYMENT_SIGNATURE_FORM';

const addInvoicePaymentDraftAction = createAction(ADD_INVOICE_PAYMENT_DRAFT, addInvoicePaymentDraft);
const linkPaymentsToInvoiceAction = createAction(LINK_PAYMENTS_TO_INVOICE, linkPaymentsToInvoice, {
	showMessage: (type, api, getState, ...args) => {
		const title =
			args[1].length === 1
				? 'Платёж привязан к счёту на оплату'
				: args[1].length > 1 && 'Платежи привязаны к счёту на оплату';

		return type === 'RECEIVE' && createActionMessage(title, 'success');
	},
});
const removeInvoicePaymentsLinkAction = createAction(REMOVE_INVOICE_PAYMENTS_LINK, removeInvoicePaymentsLink, {
	showMessage: (type, api, getState, ...args) => {
		const title =
			args[1].length === 1
				? 'Платёж отвязан от счёта на оплату'
				: args[1].length > 1 && 'Платежи отвязаны от счёта на оплату';

		return type === 'RECEIVE' && createActionMessage(title, 'success');
	},
});

function addInvoicePaymentDraft(api, getState, dispatch, invoice: Invoice) {
	const isPaymentExist = invoice.Payments.length > 0;

	return new Promise(resolve => {
		if (!isPaymentExist) {
			api.financialDocumentServiceClient.addInvoicePaymentDraft(invoice, (payment: Payment) => {
				console.log('addInvoicePaymentDraft: ', payment);
				dispatch(fetchInvoiceByIDAction(invoice.ID));
				resolve(payment);
			});
		} else {
			const paymentID = invoice.Payments[0].ID;

			api.paymentsServiceClient.getPaymentByID(paymentID, (payment: Payment) => {
				console.log('addInvoicePaymentDraft -> getPaymentByID: ', payment);
				resolve(payment);
			});
		}
	});
}

function linkPaymentsToInvoice(
	api,
	getState,
	dispatch,
	invoiceID: number,
	paymentIDList: Array<number>,
	cb: Function = () => {},
) {
	return new Promise(resolve => {
		const promises = paymentIDList.map(paymentID => {
			return new Promise(resolve => {
				api.financialDocumentServiceClient.linkPaymentToInvoice(invoiceID, paymentID, (result: Invoice) => {
					resolve(result);
				});
			});
		});

		Promise.all(promises).then(results => {
			resolve(results[0]);
			cb();
		});
	});
}

function removeInvoicePaymentsLink(
	api,
	getState,
	dispatch,
	invoiceID: number,
	paymentIDList: Array<number>,
	cb: Function = () => {},
) {
	return new Promise(resolve => {
		const promises = paymentIDList.map(paymentID => {
			return new Promise(resolve => {
				api.financialDocumentServiceClient.removeInvoicePaymentLink(invoiceID, paymentID, (result: Invoice) => {
					resolve(result);
				});
			});
		});

		Promise.all(promises).then(results => {
			resolve(results[0]);
			cb();
		});
	});
}

const sendPaymentDraft = (api, getState, dispatch) => {
	const invoice = selectInvoice(getState());
	const CMSMap = selectCMSCreatePaymentDraft(getState());
	const CMSList = Object.keys(CMSMap).map(key => CMSMap[key]);
	const bankName = getPropInSafe(invoice, o => o.FundsRegister.Bank.Name, '');
	const CMSGUID = getCMSGUID(bankName);
	const CMS = CMSList.find(item => item.SubsystemInstanceGUID === CMSGUID);

	return new Promise(resolve => {
		const invoicePayment = invoice.Payments.find(payment => payment.PaymentState['Code'] === 'DRAFT');

		if (invoicePayment) {
			const CMSRequest = new api.fundspackage.CMSCreatePaymentDraftRequest();

			CMSRequest.PaymentDraftID = invoicePayment.ID;
			CMSRequest.CashManagementSystemID = CMS.ID;

			api.cashManagementSystemClient.sendPaymentDraft(CMSRequest, (response: CMSCreatePaymentDraftResponse) => {
				console.log('sendPaymentDraft: ', response);
				resolve(response);
			});
		} else {
			resolve(null);
		}
	});
};

const sendPaymentDraftAction = createAction(SEND_PAYMENT_DRAFT, sendPaymentDraft);

function runFastPayInvoiceEffectAction(sharedUID: string, cmsGUID: string) {
	return createStaticDataAction(RUN_FAST_PAY_INVOICE_EFFECT)({ sharedUID, cmsGUID });
}

function setStatusForFastPayInvoiceAction(status: Status<{}>) {
	return createStaticDataAction(SET_STATUS_FOR_FAST_PAY_INVOICE)(status);
}

function controlFastPayInvoiceFormAction(data: { isOpen: boolean; cmsGUID: string }) {
	return createStaticDataAction(CONTROL_FAST_PAY_INVOICE_FORM)(data);
}

function setFundsRegistersForFastPayInvoiceAction(frList: Array<BankAccountFundsRegister> | null) {
	return createStaticDataAction(SET_FUNDS_REGISTERS_FOR_FAST_PAY_INVOICE)(frList);
}

function controlRemotePaymentSignatureFormAction(data: { isOpen: boolean; cmsGUID: string; invoice: Invoice }) {
	return createStaticDataAction(CONTROL_REMOTE_PAYMENT_SIGNATURE_FORM)(data);
}

const fetchInvoiceAfterPayment = (api, getState, dispatch, invoiceUID: string) => {
	return new Promise(resolve => {
		api.financialDocumentServiceClient.getDocumentByUID(invoiceUID, (invoice: Invoice) => {
			console.log('invoiceAfterPayment: ', invoice);
			resolve(invoice);
		});
	});
};

const fetchInvoiceAfterPaymentAction = createAction(FETCH_INVOICE_AFTER_PAYMENT, fetchInvoiceAfterPayment);

const synchronizePaymentStatus = (api, getState, dispatch, paymentID: number, CMSID: number) => {
	return synchronizePaymentStatusMethod(paymentID, CMSID);
};

const synchronizePaymentStatusAction = createAction(SYNCHRONIZE_PAYMENT_STATUS, synchronizePaymentStatus);

function synchronizePaymentStatusMethod(paymentID: number, CMSID: number) {
	return new Promise(resolve => {
		const request = new api.fundspackage.CMSPaymentStatusRequest();

		request.PaymentID = paymentID;
		request.CashManagementSystemID = CMSID;

		api.cashManagementSystemClient.synchronizePaymentStatus(request, (response: CMSPaymentStatusResponse) => {
			resolve(response);
		});
	});
}

function sendPaymentDraftMethod(invoicePaymentID: number, cmsID: number) {
	return new Promise<CMSCreatePaymentDraftResponse>(resolve => {
		const CMSRequest = new api.fundspackage.CMSCreatePaymentDraftRequest();

		CMSRequest.PaymentDraftID = invoicePaymentID;
		CMSRequest.CashManagementSystemID = cmsID;
		api.cashManagementSystemClient.sendPaymentDraft(CMSRequest, response => {
			console.log('sendPaymentDraft: ', response);
			resolve(response);
		});
	});
}

function addInvoicePaymentDraftMethod(invoice: Invoice) {
	const isPaymentExist = invoice.Payments.length > 0;

	return new Promise(resolve => {
		if (!isPaymentExist) {
			api.financialDocumentServiceClient.addInvoicePaymentDraft(invoice, (payment: Payment) => {
				console.log('addInvoicePaymentDraft: ', payment);
				resolve(payment);
			});
		} else {
			const payment = invoice.Payments[0];

			api.paymentsServiceClient.getPaymentByID(payment.ID, (payment: Payment) => {
				console.log('addInvoicePaymentDraft -> getPaymentByID: ', payment);
				resolve(payment);
			});
		}
	});
}

function generatePaymentSignatureMethod(cmsPaymentUID: string, cmsID: number, addInfo: any) {
	return new Promise<CMSGeneratePaymentSignatureResponse>(resolve => {
		const request = {
			...new api.fundspackage.CMSGeneratePaymentSignatureRequest(),
			CmsPaymentUID: cmsPaymentUID,
			CashManagementSystemID: cmsID,
			AddInfo: addInfo,
		};
		api.cashManagementSystemClient.generatePaymentSignature(request, result => {
			resolve(result);
		});
	});
}

function signPaymentMethod(request: CMSSignPaymentRequest) {
	return new Promise<CMSSignPaymentResponse>(resolve => {
		api.cashManagementSystemClient.signPayment(request, result => {
			resolve(result);
		});
	});
}

export const methods = {
	synchronizePaymentStatusMethod,
	sendPaymentDraftMethod,
	addInvoicePaymentDraftMethod,
	generatePaymentSignatureMethod,
	signPaymentMethod,
};

export {
	ADD_INVOICE_PAYMENT_DRAFT,
	SEND_PAYMENT_DRAFT,
	FETCH_INVOICE_AFTER_PAYMENT,
	SYNCHRONIZE_PAYMENT_STATUS,
	LINK_PAYMENTS_TO_INVOICE,
	REMOVE_INVOICE_PAYMENTS_LINK,
	RUN_FAST_PAY_INVOICE_EFFECT,
	SET_STATUS_FOR_FAST_PAY_INVOICE,
	CONTROL_FAST_PAY_INVOICE_FORM,
	SET_FUNDS_REGISTERS_FOR_FAST_PAY_INVOICE,
	CONTROL_REMOTE_PAYMENT_SIGNATURE_FORM,
	addInvoicePaymentDraftAction,
	sendPaymentDraftAction,
	fetchInvoiceAfterPaymentAction,
	synchronizePaymentStatusAction,
	linkPaymentsToInvoiceAction,
	removeInvoicePaymentsLinkAction,
	runFastPayInvoiceEffectAction,
	setStatusForFastPayInvoiceAction,
	controlFastPayInvoiceFormAction,
	setFundsRegistersForFastPayInvoiceAction,
	controlRemotePaymentSignatureFormAction,
};
