import { Link } from '@core/ui/link';
import styled, { css } from 'styled-components';

type UserNameProps = {
	capitalize: boolean;
};

type MenuItemProps = {
	disabled?: boolean;
};

type MenuValueProps = {
	isActive?: boolean;
};

const Root = styled.div`
	position: relative;
	width: auto;
	height: 70px;
	max-width: 280px;
	padding-right: 20px;

	@media (max-width: 800px) {
		max-width: 90px;
	}
`;

const ContentLayout = styled.div`
	width: 100%;
	height: auto;
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;
	background-color: transparent;
	transition: background-color 0.2s ease-in-out;
	cursor: pointer;

	${p => css`
		&:hover {
			background-color: ${p.theme.palette.stealth};
		}
	`}
`;

const UserName = styled.div<UserNameProps>`
	flex-shrink: 1;
	font-style: normal;
	font-weight: 500;
	line-height: 1.1;
	font-size: 14px;
	text-align: left;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;

	@media (max-width: 800px) {
		display: none;
	}

	${p =>
		p.capitalize &&
		css`
			text-transform: capitalize;
		`}
`;

const ProfileIconLayout = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	padding: 10px;

	@media (max-width: 800px) {
		margin-right: 0;
	}
`;

const IconButtonLayout = styled.div`
	flex-shrink: 0;
	height: 70px;
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;
	padding: 8px;
`;

const MenuItem = styled.div<MenuItemProps>`
	width: 260px;
	height: auto;
	padding: 12px 24px;
	font-size: 14px;
	line-height: 1.43;
	text-align: left;
	transition: background-color 0.2s ease-in-out;
	cursor: pointer;

	${p => css`
		&:hover {
			background-color: ${p.theme.palette.stealth};
		}
	`}

	${p =>
		p.disabled &&
		css`
			cursor: default;

			&:hover {
				background-color: transparent;
			}
		`}
`;

const MenuLabel = styled.div`
	font-size: 12px;
	line-height: 1.25;
	text-align: left;
	margin-bottom: 4px;

	${p => css`
		color: ${p.theme.palette.label};
	`}
`;

const MenuValue = styled.div<MenuValueProps>`
	font-size: 14px;
	line-height: 1.43;
	text-align: left;
	user-select: text;

	${p =>
		p.isActive &&
		css`
			font-weight: 700;
		`}
`;

const HelpLink = styled(Link)`
	display: flex;
	align-items: center;
	text-decoration: none !important;

	${p => css`
		color: ${p.theme.palette.text} !important;
	`}
`;

export { Root, ContentLayout, ProfileIconLayout, UserName, IconButtonLayout, MenuItem, MenuLabel, MenuValue, HelpLink };
