import moment from 'moment';

import { getMetrics } from '@config';
import { isReceiveAction } from '@flux';
import { detectIsDevHost } from '@utils/env';
import {
	IdentificationOptions,
	injectGoogleTagManager,
	injectYandexMetrika,
	injectCarrotQuest,
	runCarrotQuestIndetification,
	setYandexMetrikaUserID,
} from '@utils/metrics';
import { BASE_DATE_FORMAT } from '@shared/constants/time';
import { FETCH_SESSION_CONTEXT } from '../actions/session-context.actions';
import { FETCH_BILLING_SERVICE_STATUS, FETCH_TENANT_ACCOUNT } from '../actions/billing.actions';
import { FETCH_TENANT_BY_ID } from '../actions/tenant-account.actions';

const data: IdentificationOptions = {
	name: '',
	tenantID: -1,
	login: '',
	userID: -1,
	isTrial: false,
	trialDays: 0,
	contactPersonName: '',
	contactEmail: '',
	contactPhone: '',
	visitedURL: '',
};
const isDevHost = detectIsDevHost();
const { yandexCounterID, googleCounterID, qqCounterID } = getMetrics();
let isSent = false;
let contextReceived = false;
let billingStatusReceived = false;
let tenantAccountReceived = false;
let tenantReceived = false;

const runIdentifyEffect = store => next => action => {
	if (!isDevHost && !isSent) {
		if (action.type === FETCH_SESSION_CONTEXT && isReceiveAction(action)) {
			const fetchContextAction = action as AsyncAction<ClientSessionContext>;
			const context = fetchContextAction.response;

			data.name = `${context.SessionTenant.Name}_${context.SessionUser.Name}`;
			data.tenantID = context.SessionTenant.ID;
			data.login = context.SessionUser.Login;
			data.userID = context.SessionUser.ID;

			contextReceived = true;
		}

		if (action.type === FETCH_BILLING_SERVICE_STATUS && isReceiveAction(action)) {
			const fetchBillingStatusAction = action as AsyncAction<BillingServiceStatus>;
			const status = fetchBillingStatusAction.response;

			data.isTrial = status.Warning;

			billingStatusReceived = true;
		}

		if (action.type === FETCH_TENANT_ACCOUNT && isReceiveAction(action)) {
			const fetchTenantAccountAction = action as AsyncAction<TenantAccount>;
			const tenantAccount = fetchTenantAccountAction.response;
			const trialExpirationMoment = moment(tenantAccount.TrialPeriodStartDateTime, BASE_DATE_FORMAT).add(
				tenantAccount.TrialDays,
				'days',
			);
			const trialExpiring = trialExpirationMoment.clone().subtract(2, 'week') < moment();
			const daysBeforeTrialExpiration =
				trialExpiring && trialExpirationMoment.diff(moment(), 'day') > 0
					? trialExpirationMoment.diff(moment(), 'day')
					: 0;

			data.trialDays = daysBeforeTrialExpiration;

			tenantAccountReceived = true;
		}

		if (action.type === FETCH_TENANT_BY_ID && isReceiveAction(action)) {
			const fetchTenantStatusAction = action as AsyncAction<Tenant>;
			const tenant = fetchTenantStatusAction.response;

			data.contactPersonName = tenant.ContactPersonName;
			data.contactEmail = tenant.ContactEMail;
			data.contactPhone = tenant.ContactPhoneNumber;

			tenantReceived = true;
		}

		if (contextReceived && billingStatusReceived && tenantAccountReceived && tenantReceived) {
			setTimeout(() => {
				data.visitedURL = location.href;

				if (data.tenantID !== 1) {
					injectYandexMetrika(yandexCounterID);
					setYandexMetrikaUserID(yandexCounterID, data.tenantID, data.userID);
					injectGoogleTagManager(googleCounterID, data.tenantID);
					injectCarrotQuest(qqCounterID);
					runCarrotQuestIndetification(data);
				}
			}, 100);

			isSent = true;
		}
	}

	next(action);
};

export { runIdentifyEffect };
