function getInfinityColor(palette: Array<string>, idx: number) {
	const immPalette = [...palette];
	let color = immPalette[idx];

	while (!color) {
		if (!immPalette[idx]) {
			immPalette.push(...palette);
		}

		color = immPalette[idx];
	}

	return color;
}

function createColorMap(IDs: Array<number>, palette: Array<string>): Record<string, string> {
	const map = {};
	let idx = 0;

	IDs.forEach(ID => {
		map[ID] = getInfinityColor(palette, idx);
		idx++;
		palette.length <= idx && (idx = 0);
	});

	if (!map[-1]) {
		map[-1] = getInfinityColor(palette, idx);
	}

	return map;
}

function clamp(value, min, max) {
	if (value < min) {
		return min;
	}
	if (value > max) {
		return max;
	}
	return value;
}

function convertColorToString(color) {
	let type = color.type,
		values = color.values;

	if (type.indexOf('rgb') > -1) {
		// Only convert the first 3 values to int (i.e. not alpha)
		for (let i = 0; i < 3; i++) {
			values[i] = parseInt(values[i]);
		}
	}

	let colorString = void 0;

	if (type.indexOf('hsl') > -1) {
		colorString = color.type + '(' + values[0] + ', ' + values[1] + '%, ' + values[2] + '%';
	} else {
		colorString = color.type + '(' + values[0] + ', ' + values[1] + ', ' + values[2];
	}

	if (values.length === 4) {
		colorString += ', ' + color.values[3] + ')';
	} else {
		colorString += ')';
	}

	return colorString;
}

function convertHexToRGB(color) {
	if (color.length === 4) {
		let extendedColor = '#';
		for (let i = 1; i < color.length; i++) {
			extendedColor += color.charAt(i) + color.charAt(i);
		}
		color = extendedColor;
	}

	let values = {
		r: parseInt(color.substr(1, 2), 16),
		g: parseInt(color.substr(3, 2), 16),
		b: parseInt(color.substr(5, 2), 16),
	};

	return 'rgb(' + values.r + ', ' + values.g + ', ' + values.b + ')';
}

function decomposeColor(color) {
	if (color.charAt(0) === '#') {
		return decomposeColor(convertHexToRGB(color));
	}

	let marker = color.indexOf('(');

	let type = color.substring(0, marker);
	let values = color.substring(marker + 1, color.length - 1).split(',');
	values = values.map(function (value) {
		return parseFloat(value);
	});

	return { type: type, values: values };
}

function fade(color, value) {
	color = decomposeColor(color);
	value = clamp(value, 0, 1);

	if (color.type === 'rgb' || color.type === 'hsl') {
		color.type += 'a';
	}
	color.values[3] = value;

	return convertColorToString(color);
}

function darken(color, coefficient) {
	color = decomposeColor(color);
	coefficient = clamp(coefficient, 0, 1);

	if (color.type.indexOf('hsl') > -1) {
		color.values[2] *= 1 - coefficient;
	} else if (color.type.indexOf('rgb') > -1) {
		for (let i = 0; i < 3; i++) {
			color.values[i] *= 1 - coefficient;
		}
	}
	return convertColorToString(color);
}

function lighten(color, coefficient) {
	color = decomposeColor(color);
	coefficient = clamp(coefficient, 0, 1);

	if (color.type.indexOf('hsl') > -1) {
		color.values[2] += (100 - color.values[2]) * coefficient;
	} else if (color.type.indexOf('rgb') > -1) {
		for (let i = 0; i < 3; i++) {
			color.values[i] += (255 - color.values[i]) * coefficient;
		}
	}

	return convertColorToString(color);
}

export { getInfinityColor, createColorMap, darken, lighten, fade };
