import * as _ from 'underscore';
import * as moment from 'moment';

import {
	StoreAsyncItem,
	createReducer,
	createAsyncInitialState,
	checkAsyncAction,
	invalidateStateFromAction,
} from '@flux';
import { BASE_DATE_FORMAT } from '@shared/constants/time';
import { mainPlanningMasterActionsPack } from '@pl-planning/actions';

export type PlanningMasterState = {
	dateRange: DateRange;
	plList: StoreAsyncItem<Array<PLOperationBrief>>;
	aciSeries: StoreAsyncItem<Record<string, PLOperationAccountsChartItemSeries>>;
	selectedCfItemID: number;
	isPlanned: boolean;
	isSimilar: boolean;
};

const initialState = {
	dateRange: {
		dateStart: moment().startOf('month').format(BASE_DATE_FORMAT),
		dateEnd: moment().startOf('month').add(1, 'month').endOf('month').format(BASE_DATE_FORMAT),
	},
	plList: createAsyncInitialState([]),
	aciSeries: createAsyncInitialState({}),
	selectedCfItemID: -2,
	isPlanned: true,
	isSimilar: true,
};

const mainPlanningMasterReducer = createReducer<PlanningMasterState>(initialState, {
	[mainPlanningMasterActionsPack.types.INVALIDATE_PL_OPERATIONS]: (action: StaticAction, state) => {
		return {
			plList: invalidateStateFromAction(action, state.plList),
			aciSeries: invalidateStateFromAction(action, state.aciSeries),
		};
	},
	[mainPlanningMasterActionsPack.types.SET_DATE_RANGE]: (action: StaticAction<DateRange>, state) => {
		return {
			dateRange: action.value,
			plList: invalidateStateFromAction(action, state.plList),
			aciSeries: invalidateStateFromAction(action, state.aciSeries),
		};
	},
	[mainPlanningMasterActionsPack.types.FETCH_PLANNING_DATA]: (action: AsyncAction<PLOperationDynamics>, state) => {
		return {
			aciSeries: checkAsyncAction(action, state.aciSeries, response =>
				_.indexBy(response.Series, (s: PLOperationAccountsChartItemSeries) => s.AccountsChartItemID),
			),
		};
	},
	[mainPlanningMasterActionsPack.types.FETCH_PL_OPERATIONS]: (action: AsyncAction<Array<PLOperationBrief>>, state) => {
		return {
			plList: checkAsyncAction(action, state.plList),
		};
	},
	[mainPlanningMasterActionsPack.types.SELECT_CASHFLOW_ITEM]: (action: StaticAction<number>) => {
		return {
			selectedCfItemID: action.value,
		};
	},
	[mainPlanningMasterActionsPack.types.SET_FILTER_BY_PLAN]: (action: StaticAction<boolean>) => {
		return {
			isPlanned: action.value,
		};
	},
	[mainPlanningMasterActionsPack.types.SET_FILTER_BY_SIMILAR]: (action: StaticAction<boolean>) => {
		return {
			isSimilar: action.value,
		};
	},
});

export default mainPlanningMasterReducer;
