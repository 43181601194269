import styled, { css } from 'styled-components';

import { getMeasureProp } from '@stylesheet';
import type { CurrencyFormatterProps } from './currency-formatter';

type RootProps = {} & Pick<CurrencyFormatterProps, 'fontSize' | 'fontWeight' | 'color'>;

type FractionProps = {} & Pick<CurrencyFormatterProps, 'fontSize'>;

const Root = styled.span`
	position: relative;
	display: inline-flex;
	flex-flow: row nowrap;
	align-items: center;
	white-space: nowrap;
	font-weight: 400;

	${(p: RootProps) =>
		p.fontSize &&
		css`
			font-size: ${getMeasureProp(p.fontSize)};
		`};

	${(p: RootProps) =>
		p.fontWeight &&
		css`
			font-weight: ${p.fontWeight};
		`};

	${(p: RootProps) =>
		p.color &&
		css`
			color: ${p.color};
		`};
`;

const Integer = styled.span`
	display: inline-block;
`;

const Fraction = styled.span`
	position: relative;
	display: inline-block;
	line-height: 1;
	font-size: ${({ fontSize }: FractionProps) =>
		fontSize ? `${(parseInt(getMeasureProp(fontSize), 10) / 1.3).toFixed(0)}px` : 'inherit'};

	${p => css`
		color: ${p.theme.palette.label};
	`}
`;

const Symbol = styled.span`
	display: inline-block;
`;

type PrefixProps = {
	isIncome: boolean;
};

const Prefix = styled.span<PrefixProps>`
	font-size: 18px;
	vertical-align: middle;

	${p => css`
		color: ${p.isIncome ? p.theme.palette.positive : p.theme.palette.negative};
	`}
`;

export { Root, Integer, Fraction, Symbol, Prefix };
