import React, { useMemo } from 'react';

import { Autopicker, AutopickerProps } from '@ui/autopicker';

export type OperationTypeAutopickerProps = {} & AutopickerProps<MarketplaceOperationType>;

const OperationTypeAutopicker: React.FC<OperationTypeAutopickerProps> = props => {
	const pluralItems = useMemo(() => ['категория', 'категории', 'категорий'] as [string, string, string], []);

	return (
		<Autopicker
			fullWidth
			hintText='Выберите категорию'
			labelText='Категория маркетплейса'
			pluralItems={pluralItems}
			{...props}
		/>
	);
};

export { OperationTypeAutopicker };
