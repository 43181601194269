// @ts-nocheck
import { getPropInSafe } from '../../../core/libs/utils';
import {
	SHOW_INVOICE_FORM,
	HIDE_INVOICE_FORM,
	SHOW_INVOICE_INCOMING_FORM,
	HIDE_INVOICE_INCOMING_FORM,
	SET_MODE_FOR_INVOICE_FORM
} from '../actions/invoice-add.actions'


export interface IInvoiceWizardState {
	outgoingFormIsOpen: boolean;
	incomingFormIsOpen: boolean;
	showPaymentResult: boolean;
	wizardMode: boolean;
}

const initialState = {
	outgoingFormIsOpen: false,
	incomingFormIsOpen: false,
	showPaymentResult: false,
	wizardMode: true
};

export default function(state: IInvoiceWizardState = initialState, action) {
	switch (action.type) {
	case SHOW_INVOICE_FORM:
		return {
			...state,
			outgoingFormIsOpen: true
		}

	case HIDE_INVOICE_FORM:
		return {
			...state,
			outgoingFormIsOpen: false,
			wizardMode: true
		}

	case SHOW_INVOICE_INCOMING_FORM:
		const showInvoiceIncomingFormAction = action as staticAction<{ showPaymentResult: boolean }>;
		const showPaymentResult = getPropInSafe(showInvoiceIncomingFormAction, o => o.value.showPaymentResult, false);

		return {
			...state,
			incomingFormIsOpen: true,
			showPaymentResult
		}

	case HIDE_INVOICE_INCOMING_FORM:
		return {
			...state,
			incomingFormIsOpen: false,
			showPaymentResult: false
		}

	case SET_MODE_FOR_INVOICE_FORM:
		const setModeAction =  action as staticAction<{wizardMode: boolean;}>;

		return {
			...state,
			wizardMode: setModeAction.value.wizardMode
		}

	default:
		return state
	}
}
