import { IAppState } from '@store';
import {
	selectComponentIsAvailableToTenant,
	selectRightIsAvailableToTenant,
	selectIsTechService,
	selectHasReadonlyRight,
} from '@core/flux/rights';
import { selectCurrentUserLogin } from './context.selectors';

function selectCanChangeAnyData(state: IAppState) {
	const isDemoBusiness = selectIsDemoBusinessAccount(state);
	const hasReadonlyRight = selectHasReadonlyRight(state);

	return !isDemoBusiness && !hasReadonlyRight;
}

function selectIsDemoBusinessAccount(state: IAppState): boolean {
	const whiteList = ['bfm'];
	const isDemoBusiness = whiteList.includes(selectCurrentUserLogin(state));
	const isTechService = securitySelectorsPack.selectIsTechService(state);

	return isTechService ? false : isDemoBusiness;
}

export const securitySelectorsPack = {
	selectIsTechService,
	selectHasReadonlyRight,
	selectCanChangeAnyData,
	selectIsDemoBusinessAccount,
	selectComponentIsAvailableToTenant,
	selectRightIsAvailableToTenant,
};
