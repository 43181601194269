import { Checkbox, CheckboxProps } from '@ui/checkbox';
import { SegmentedList, SegmentedListProps } from '@ui/segmented-list';
import { QrCode, QrCodeProps } from '@ui/qr-code';

import { withFormCheckbox, withFormSegmentedList, withFormQrCode } from './hocs';

const FormCheckbox = withFormCheckbox<Omit<CheckboxProps, 'name'>>(Checkbox);
const FormSegmentedList = withFormSegmentedList<Omit<SegmentedListProps, 'name'>, unknown>(SegmentedList);
const FormQrCode = withFormQrCode<Omit<QrCodeProps, 'name'>, unknown>(QrCode);

export { FormCheckbox, FormSegmentedList, FormQrCode };
