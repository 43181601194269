import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { AnimatedEntrance } from '@ui/animated-entrance';
import { ErrorBoundary } from '@ui/error-boundary';
import { NestedRoutes } from '@ui/nested-routes';
import { getPropInSafe } from '@utils';
import withLazyLoad from '../hocs/with-lazy-load.hoc';
import {
	AppPageRoutes,
	BALANCE,
	BUSINESS_UNITS_MANAGEMENT,
	CARD_TRANSACTIONS,
	CASHFLOW_ITEMS,
	CASHFLOW_LIMITS,
	CLIENTS,
	COUNTERPARTIES,
	DASHBOARD,
	DOCUMENT_TEMPLATES,
	EMPLOYEES,
	FOR_OWNERS_REPORTS,
	FUNDS_REGISTERS,
	INITIALIZATION,
	INVOICES,
	MEASURE_UNITS,
	PAYMENTS,
	PAYMENT_RULES,
	PL_ANALYTICS,
	PL_OPERATIONS,
	PL_PAYMENT_RULES,
	PL_PLANNING,
	PRODUCTS,
	PROJECTS,
	PROJECT_EMPLOYEES,
	PROJECT_PAYMENT_RULES,
	PURCHASES,
	RECONCILIATION_ACT,
	STANDARD_REPORTS,
	TENANT_ACCOUNT,
	TENANT_LEGAL_ENTITIES,
	TRANSFER_RULES,
	filterRoutes,
} from './urls';

const components = {
	[BALANCE]: withLazyLoad(() => import('../../modules/balance/components/entry')),
	[BUSINESS_UNITS_MANAGEMENT]: withLazyLoad(() => import('../../modules/business-units/components/entry')),
	[CARD_TRANSACTIONS]: withLazyLoad(() => import('../../modules/transactions/components/entry')),
	[CASHFLOW_ITEMS]: withLazyLoad(() => import('../../modules/cashflow-items/components/entry')),
	[CASHFLOW_LIMITS]: withLazyLoad(() => import('../../modules/cashflow-limits/components/entry')),
	[CLIENTS]: withLazyLoad(() => import('@clients/components/entry')),
	[COUNTERPARTIES]: withLazyLoad(() => import('@counterparties/components/entry')),
	[DASHBOARD]: withLazyLoad(() => import('../../modules/dashboard/components/entry')),
	[DOCUMENT_TEMPLATES]: withLazyLoad(() => import('../../modules/document-templates/components/entry')),
	[EMPLOYEES]: withLazyLoad(() => import('../../modules/employees/components/entry')),
	[FOR_OWNERS_REPORTS]: withLazyLoad(() => import('../../modules/for-owners-reports/components/entry')),
	[FUNDS_REGISTERS]: withLazyLoad(() => import('../../modules/funds-registers/components/entry')),
	[INITIALIZATION]: withLazyLoad(() => import('../../modules/initialization/components/entry')),
	[INVOICES]: withLazyLoad(() => import('../../modules/invoices/components/entry')),
	[MEASURE_UNITS]: withLazyLoad(() => import('../../modules/measure-units/components/entry')),
	[PAYMENT_RULES]: withLazyLoad(() => import('../../modules/payment-rules/components/entry')),
	[PAYMENTS]: withLazyLoad(() => import('../../modules/payments/components/entry')),
	[PL_ANALYTICS]: withLazyLoad(() => import('../../modules/pl-analytics/components/entry')),
	[PL_OPERATIONS]: withLazyLoad(() => import('../../modules/operations/components/entry')),
	[PL_PAYMENT_RULES]: withLazyLoad(() => import('../../modules/cashflow-payment-rules/components/entry')),
	[PL_PLANNING]: withLazyLoad(() => import('../../modules/planning/components/entry')),
	[PRODUCTS]: withLazyLoad(() => import('../../modules/products/components/entry')),
	[PROJECT_EMPLOYEES]: withLazyLoad(() => import('../../modules/project-employees/components/entry')),
	[PROJECT_PAYMENT_RULES]: withLazyLoad(() => import('../../modules/project-payment-rules/components/entry')),
	[PROJECTS]: withLazyLoad(() => import('../../modules/project-management/components/entry')),
	[PURCHASES]: withLazyLoad(() => import('../../modules/invoice/components/purchases/layout')),
	[RECONCILIATION_ACT]: withLazyLoad(() => import('../../modules/reconciliation-act/components/entry')),
	[STANDARD_REPORTS]: withLazyLoad(() => import('../../modules/standard-reports/components/entry')),
	[TENANT_ACCOUNT]: withLazyLoad(() => import('../../modules/platform/components/tenant-account/tenant-account-view')),
	[TENANT_LEGAL_ENTITIES]: withLazyLoad(() => import('../../modules/tenant-legal-entities/components/entry')),
	[TRANSFER_RULES]: withLazyLoad(() => import('../../modules/transfer-rules/components/entry')),
};

export interface IRenderRouteProps extends IPageRoute {
	availableComponents: Array<ApplicationComponent>;
	isFullAccess?: boolean;
	userRights: Array<SecurityRight>;
}

const RenderRoute: React.FC<IRenderRouteProps> = props => {
	const { path, title } = props;

	useEffect(() => {
		if (title) {
			document.title = props.title;
		}
	}, [title]);

	const renderedRoute = () => {
		const isNested = Boolean(props.nestedRoutes);
		const Workspace = components[props.sysName];

		return (
			<AnimatedEntrance key={path}>
				{isNested ? (
					<Route path={path} render={() => <NestedRoutes {...props} />} />
				) : (
					<Route path={path} component={Workspace} />
				)}
			</AnimatedEntrance>
		);
	};

	return <ErrorBoundary>{renderedRoute()}</ErrorBoundary>;
};

type WorkspaceRoutesProps = {
	availableComponents: Array<ApplicationComponent>;
	userRights: Array<SecurityRight>;
	billingServiceStatus: BillingServiceStatus;
	tenantProfile: TenantProfile;
	isFullAccess: boolean;
};

const WorkspaceRoutes: React.FC<WorkspaceRoutesProps> = props => {
	const { billingServiceStatus, availableComponents, userRights, isFullAccess, tenantProfile } = props;
	const isAccessGranted = getPropInSafe(billingServiceStatus, o => o.AccessGranted, false);
	const isTrialMode = getPropInSafe(billingServiceStatus, o => o.Warning, false);
	const isTenantProfileCompleted = tenantProfile ? tenantProfile.Completed : false;
	const availablePageRoutes = filterRoutes(availableComponents, userRights, AppPageRoutes, isFullAccess, {
		isAccessGranted,
		isTrialMode,
		isTenantProfileCompleted,
	});
	const firstAvailableRoute =
		availablePageRoutes.find(route => route.sysName !== 'tenantAccount' && !route.alwaysAvailableByLink) ||
		availablePageRoutes.find(route => route.sysName === 'tenantAccount');

	return (
		<Switch>
			{availablePageRoutes.map(x => (
				<RenderRoute
					{...x}
					key={x.sysName}
					availableComponents={availableComponents}
					userRights={userRights}
					isFullAccess={isFullAccess}
				/>
			))}
			{firstAvailableRoute && <Redirect to={firstAvailableRoute.path} />}
		</Switch>
	);
};

const titles = {
	sales: null,
	purchases: null,
};

const TitleRoutes: React.FC<WorkspaceRoutesProps> = props => {
	const { billingServiceStatus, availableComponents, userRights, isFullAccess } = props;
	const isAccessGranted = getPropInSafe(billingServiceStatus, o => o.AccessGranted, false);
	const isTrialMode = getPropInSafe(billingServiceStatus, o => o.Warning, false);
	const availablePageRoutes = filterRoutes(availableComponents, userRights, AppPageRoutes, isFullAccess, {
		isAccessGranted,
		isTrialMode,
		isTenantProfileCompleted: true,
	});

	return (
		<Switch>
			{availablePageRoutes.map((link, index) => {
				const Title = titles[link.sysName];

				return <Route key={index} path={link.path} component={Title} />;
			})}
		</Switch>
	);
};

export { RenderRoute, TitleRoutes, WorkspaceRoutes };
