import { createTypes, createAsyncAction, createStaticAction, isReceiveType } from '@flux';
import { createActionMessage } from '@utils';
import { mainTenantProfileSelectorsPack } from '@platform/selectors/main-tenant-profile.selectors';
import { emitActionMessage } from '@shared/actions/action-message.actions';

type ActionTypes = {
	FETCH_TENANT_PROFILE: string;
	UPDATE_TENANT_PROFILE: string;
	REMOVE_DEMO_DATA: string;
	INVALIDATE_TENANT_PROFILE: string;
};

const types = createTypes<ActionTypes>(
	['FETCH_TENANT_PROFILE', 'UPDATE_TENANT_PROFILE', 'REMOVE_DEMO_DATA', 'INVALIDATE_TENANT_PROFILE'],
	'TENANT_PROFILE',
);

const actions = {
	fetchTenantProfile: createAsyncAction(
		types.FETCH_TENANT_PROFILE,
		api => {
			return new Promise<TenantProfile>(resolve => {
				api.profilePack.tenantProfile.fetchTenantProfile().then(result => {
					resolve(result);
				});
			});
		},
		{
			isValidSelector: state =>
				mainTenantProfileSelectorsPack.selectAsyncTenantProfile.selectIsLoaded(state) &&
				!mainTenantProfileSelectorsPack.selectAsyncTenantProfile.selectDidInvalidate(state),
			isFetchingSelector: mainTenantProfileSelectorsPack.selectAsyncTenantProfile.selectIsFetching,
		},
	),
	updateTenantProfile: createAsyncAction(types.UPDATE_TENANT_PROFILE, (api, _, __, profile: TenantProfile) => {
		return new Promise<TenantProfile>(resolve => {
			api.profilePack.tenantProfile.updateTenantProfile(profile).then(result => {
				resolve(result);
			});
		});
	}),
	removeDemoData: createAsyncAction(
		types.REMOVE_DEMO_DATA,
		(api, _, dispatch) => {
			return new Promise<boolean>(resolve => {
				dispatch(emitActionMessage('Процесс удаления демо-данных запущен...', 'success'));

				api.dataPack.tenantStandardData.removeStandardData().then(result => {
					setTimeout(() => resolve(result), 3000);
				});
			});
		},
		{
			showMessage: (type, ...args) => {
				const isSuccess = args[2] === true;

				return (
					isReceiveType(type) &&
					(isSuccess
						? createActionMessage('Демо-данные успешно удалены! 😀', 'success')
						: createActionMessage('Не удалось удалить демо-данные', 'warning'))
				);
			},
		},
	),
	invalidateTenantProfile: () => createStaticAction(types.INVALIDATE_TENANT_PROFILE)(),
};

export const mainTenantProfileActionsPack = {
	types,
	actions,
};
