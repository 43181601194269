import React from 'react';

import { CloseButton } from '@ui/button/close-button';
import { Typography } from '@ui/typography';
import { DefaultHeaderRoot, DefaultHeaderContentLayout, DefaultHeaderCloseLayout } from './styled';

type DefaultHeaderProps = {
	children: React.ReactNode;
	onClose: () => void;
};

const DefaultHeader: React.FC<DefaultHeaderProps> = ({ onClose, children }) => {
	return (
		<DefaultHeaderRoot>
			<DefaultHeaderContentLayout>{children}</DefaultHeaderContentLayout>
			<DefaultHeaderCloseLayout>
				<CloseButton withDrawer onClick={onClose} />
			</DefaultHeaderCloseLayout>
		</DefaultHeaderRoot>
	);
};

type DefaultTitleProps = {
	children: React.ReactNode;
} & DefaultHeaderProps;

const DefaultTitle: React.FC<DefaultTitleProps> = ({ onClose, children }) => {
	return (
		<DefaultHeader onClose={onClose}>
			<Typography.Title fontSize={18} lineHeight='initial'>
				{children}
			</Typography.Title>
		</DefaultHeader>
	);
};

export { DefaultHeader, DefaultTitle };
