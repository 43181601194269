export enum PaymentsDirection {
	ALL,
	RECEIPTS,
	CHARGES,
	TRANSFERS,
}

export enum PaymentsStatisticsSliceCode {
	INCOMING_BALANCE = 'INCOMING_BALANCE',
	RECEIPT = 'RECEIPT',
	CHARGE = 'CHARGE',
	TRANSFER = 'TRANSFER',
	OUTGOING_BALANCE = 'OUTGOING_BALANCE',
}
