import React from 'react';

import { DefaultContentRoot } from './styled';

type DefaultContentProps = {
	children: React.ReactNode;
};

const DefaultContent: React.FC<DefaultContentProps> = props => {
	return <DefaultContentRoot {...props} />;
};

export default DefaultContent;
