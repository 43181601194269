import styled, { css } from 'styled-components';

const dialogRootStyle = {
	padding: 0,
	overflowY: 'visible',
};

const dialogContentStyle = {
	width: '960px',
	maxWidth: '100%',
};

const dialogActionsContainerStyle = {
	padding: 0,
};

const paperStyle = {
	backgroundColor: 'transparent',
	boxShadow: 'none',
};

const ContentLayout = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0;

	${p => css`
		background-color: ${p.theme.palette.canvas};
	`}
`;

interface ICloseButtonLayout {
	innerCloseBtn?: boolean;
}

const CloseButtonLayout = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	z-index: 10;

	${(p: ICloseButtonLayout) =>
		p.innerCloseBtn &&
		css`
			top: 8px;
			right: 8px;
		`}
`;

export {
	dialogRootStyle,
	dialogContentStyle,
	dialogActionsContainerStyle,
	ContentLayout,
	paperStyle,
	CloseButtonLayout,
};
