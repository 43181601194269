import { IAppState } from '@store';
import { createSelector, createAsyncSelector } from '@flux';

const selectAsyncLegalEntityTaxModes = createAsyncSelector<Array<LegalEntityTaxMode>, IAppState>({
	get: s => s.reference.main.taxModes,
	selector: createSelector(
		s => s.reference.main.taxModes.item,
		item => item,
	),
});

const selectAsyncCurrencies = createAsyncSelector<Array<Currency>, IAppState>({
	get: s => s.reference.main.currencies,
	selector: createSelector(
		s => s.reference.main.currencies.item,
		item => item,
	),
});

const selectAsyncCountries = createAsyncSelector<Array<Country>, IAppState>({
	get: s => s.reference.main.countries,
	selector: createSelector(
		s => s.reference.main.countries.item,
		item => item,
	),
});

const selectFilteredLegalEntityTaxModes = createSelector(selectAsyncLegalEntityTaxModes.selectItem, taxModes =>
	taxModes.filter(x => x.ID > 0),
);

const selectFilteredCurrencies = createSelector(selectAsyncCurrencies.selectItem, currencies =>
	currencies.filter(x => x.ID > 0),
);

const selectFilteredCountries = createSelector(selectAsyncCountries.selectItem, countries =>
	countries.filter(x => x.ID > 0),
);

export const mainReferenceSelectorsPack = {
	selectAsyncLegalEntityTaxModes,
	selectAsyncCurrencies,
	selectAsyncCountries,
	selectFilteredLegalEntityTaxModes,
	selectFilteredCurrencies,
	selectFilteredCountries,
};
