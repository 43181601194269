import * as _ from 'underscore';

import { createSelector, createAsyncSelector } from '@flux';
import { IAppState } from '@store';

const selectAsyncCurrencies = createAsyncSelector<Array<Currency>, IAppState>({
	get: s => s.shared.currencyList,
	selector: createSelector(
		s => s.shared.currencyList.item,
		item => {
			const currencies = Object.keys(item).map(key => item[key]);

			return currencies;
		},
	),
});

const selectCurrencyList = createSelector(
	(state: IAppState): Record<string, Currency> => state.shared.currencyList.item,
	currencyList => currencyList,
);

const selectCurrenciesForAutopicker = createSelector(
	(state: IAppState): Record<string, Currency> => state.shared.currencyList.item,
	(currencyList): Array<Currency> =>
		Object.keys(currencyList).map(key => {
			const immutable = { ...currencyList[key] };

			immutable.Name = `${immutable.Name} (${immutable.DigitCode})`;

			return immutable;
		}),
);

const selectCurrencyListByID = createSelector(
	(state: IAppState): Record<string, Currency> => state.shared.currencyList.item,
	currencyList => {
		const currencies = Object.keys(currencyList).map(key => currencyList[key]);
		const currencyListByID = _.indexBy(currencies, cur => cur.ID);

		return currencyListByID;
	},
);

const selectCurrencyListByDigitCode = createSelector(
	(state: IAppState): Record<string, Currency> => state.shared.currencyList.item,
	currencyList => {
		const currencies = Object.keys(currencyList).map(key => currencyList[key]);
		const currencyListByDigitCode = _.indexBy(currencies, cur => cur.DigitCode);

		return currencyListByDigitCode;
	},
);

function selectCurrencyByIdMap(state: IAppState): Record<string, Currency> {
	return selectCurrencyListByID(state);
}

function selectCurrencyListDidInvalidate(state: IAppState): boolean {
	return state.shared.currencyList.didInvalidate;
}

function selectCurrencyLisIsFetching(state: IAppState): boolean {
	return state.shared.currencyList.isFetching;
}

function selectCurrencyListIsLoaded(state: IAppState): boolean {
	return state.shared.currencyList.isLoaded;
}

function selectCurrencyMap(state: IAppState): Record<string, Currency> {
	return state.shared.currencyList.item;
}

export {
	selectAsyncCurrencies,
	selectCurrencyList,
	selectCurrenciesForAutopicker,
	selectCurrencyListByID,
	selectCurrencyListByDigitCode,
	selectCurrencyByIdMap,
	selectCurrencyListDidInvalidate,
	selectCurrencyLisIsFetching,
	selectCurrencyListIsLoaded,
	selectCurrencyMap,
};
