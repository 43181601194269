/// <reference path="../../d.ts-reference/reference.d.ts" />
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import MaterialThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';

import '@stylesheet/css/index.css';
import store from '@store';
import { useMapState } from '@flux/use-map-state';
import routes, { Root } from './routes';
import { logBuildInfo, getURLParameterByName } from '@utils';
import { getCookie, setCookie } from '@utils/storage';
import { pageContext } from '@libs/atr-core-lib';
import { setupMoment } from '@utils/date';
import { initErrorInterceptor } from '@shared/components/error-message/error-interceptor';
import {
	ThemeProvider as AppThemeProvider,
	createTheme,
	createMaterialTheme,
	GlobalStyle as ThemeGlobalStyle,
} from '@theme';
import { AppGlobalStyle } from '@ui/global-style';
import { selectThemeIdentifier } from '@shared/selectors/theme.selectors';

const TICKET_UID = 'ticketuid';

if (process.env.NODE_ENV === 'production') {
	if ('serviceWorker' in navigator) {
		window.addEventListener('load', () => {
			navigator.serviceWorker
				.register(`${location.origin}/bfm/sw.js`)
				.then(reg => console.log(`SW registered: `, reg))
				.catch(err => console.error('SW: ', err));
		});
	}
}

pageContext['web_service_root'] = window.location.origin;

pageContext.ticketuid = getURLParameterByName(TICKET_UID) || getCookie(TICKET_UID);

window.addEventListener('beforeunload', () => {
	if (pageContext.ticketuid) {
		setCookie(TICKET_UID, pageContext.ticketuid, {
			path: '/',
			secure: true,
		});
	}
});

setupMoment();
logBuildInfo();
initErrorInterceptor();

const App: React.FC = () => {
	const [themeIdentifier] = useMapState([selectThemeIdentifier]);
	const styledTheme = {
		...createTheme(themeIdentifier),
	};
	const materialTheme = {
		muiTheme: getMuiTheme(createMaterialTheme(themeIdentifier)),
	};

	return (
		<AppThemeProvider themeIdentifier={themeIdentifier}>
			<StyledThemeProvider theme={styledTheme}>
				<MaterialThemeProvider value={materialTheme} {...({} as any)}>
					<ThemeGlobalStyle />
					<AppGlobalStyle />
					<Root routes={routes} />
				</MaterialThemeProvider>
			</StyledThemeProvider>
		</AppThemeProvider>
	);
};

const root = createRoot(document.getElementById('app'));

root.render(
	<ReduxProvider store={store}>
		<App />
	</ReduxProvider>,
);
