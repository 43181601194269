import styled, { css } from 'styled-components';

import { lighten } from '@utils/color';

const SIDEBAR_WIDTH = 264;

const Root = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	max-width: 100%;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
`;

const HeaderLayout = styled.div`
	flex: 0 0 auto;
	position: relative;
	width: 100%;
	height: auto;
	min-height: 70px;
	display: flex;
	border-bottom: 1px solid transparent;

	${p => css`
		border-color: ${p.theme.palette.stealth};
	`}
`;

const ContentLayout = styled.div.attrs({
	id: 'drawer-content',
})`
	flex: 1 1 auto;
	position: relative;
	width: 100%;
	height: 100%;
	overflow-y: auto;
`;

const MainLayout = styled.div`
	flex: 1 1 auto;
	position: relative;
	width: calc(100% - ${SIDEBAR_WIDTH}px);
	height: 100%;
	display: flex;
	flex-flow: column nowrap;
	justify-content: space-between;

	${p => css`
		background-color: ${lighten(p.theme.palette.canvas, 0.02)};
	`}
`;

const SidebarLayout = styled.div`
	position: relative;
	width: ${SIDEBAR_WIDTH}px;
	min-width: ${SIDEBAR_WIDTH}px;
	height: 100%;
	display: flex;
	flex-flow: column nowrap;
	justify-content: space-between;
	overflow-y: auto;

	${p => css`
		background-color: ${p.theme.palette.background};
	`}
`;

const WideDrawer = {
	Root,
	HeaderLayout,
	ContentLayout,
	MainLayout,
	SidebarLayout,
};

export { WideDrawer };
