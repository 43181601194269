import * as moment from 'moment';
import React, { memo, useLayoutEffect, useMemo, useState } from 'react';

import api from '@api';
import { useMapDispatch, useMapState } from '@core/flux';
import { useAsyncValue } from '@core/hooks/use-async-value';
import { GoalStep, useGoalStep } from '@core/hooks/use-goals';
import { CMSDataType, DrawerZone } from '@funds-registers/models';
import { selectFundsRegisterForRefresh } from '@funds-registers/selectors';
import { mainIntegrationsActionsPack } from '@integrations/actions';
import { selectImportDateRange } from '@integrations/selectors';
import { BASE_DATE_FORMAT } from '@shared/constants/time';
import { selectCurrencyListByID } from '@shared/selectors/currency-list';
import { getPropInSafe } from '@utils';
import { XCmsRefresh } from './cms-refresh.view';
import { FormAppearance } from './model';

type CmsRefreshProps = {
	onSetStatus: React.Dispatch<React.SetStateAction<AsyncProgressStatus>>;
	onSetZone: React.Dispatch<React.SetStateAction<DrawerZone>>;
	status: AsyncProgressStatus;
};

const CmsRefresh: React.FC<CmsRefreshProps> = memo(({ onSetZone, onSetStatus }) => {
	const [appearance, setAppearance] = useState<FormAppearance>(FormAppearance.UNLOADED);
	const [dateRange, fundsRegisterForRefresh, currenciesMap] = useMapState([
		selectImportDateRange,
		selectFundsRegisterForRefresh,
		selectCurrencyListByID,
	]);
	const [setDateRange, setLastImportedAccountData, setLastAccountStatementImport] = useMapDispatch([
		mainIntegrationsActionsPack.setImportDateRange,
		mainIntegrationsActionsPack.setLastImportedAccountData,
		mainIntegrationsActionsPack.setLastAccountStatementImport,
	]);
	const scope = useMemo(() => ({ dateRange }), []);

	scope.dateRange = dateRange;

	const boundaryBalancesDates = useAsyncValue(() =>
		api.fundsPack.fundsRegister.fetchFundsRegisterBoundaryBalances(fundsRegisterForRefresh.ID).then(items => {
			if (items && items.length > 0) {
				const firstPaymentDate = items[0].ValueDate;
				const lastPaymentDate = items[items.length - 1].ValueDate;
				const paymentsIsExists = !(
					firstPaymentDate === lastPaymentDate &&
					items[0].Charge === 0 &&
					items[0].Receipt === 0 &&
					items[items.length - 1].Charge === 0 &&
					items[items.length - 1].Receipt === 0
				);

				return {
					firstPaymentDate,
					lastPaymentDate,
					paymentsIsExists,
				};
			}

			return null;
		}),
	);

	useLayoutEffect(() => {
		const dateStart = getPropInSafe(
			boundaryBalancesDates.value,
			o => o.lastPaymentDate,
			moment().startOf('quarter').format(BASE_DATE_FORMAT),
		);
		const dateEnd = moment().format(BASE_DATE_FORMAT);

		setDateRange({ dateStart, dateEnd });
	}, [boundaryBalancesDates.value]);

	const handleGoalStepOnChoouseUnloaded = useGoalStep(GoalStep.SYNC_CHOOSE_UNLOADED);

	const handleGoalStepOnChoouseDateRange = useGoalStep(GoalStep.SYNC_CHOOSE_DATE_RANGE);

	const handleStartRefreshAccountFromCMS = (cmsAccount: CMSAccount, cmsGUID: string, dataType: CMSDataType) => {
		appearance === FormAppearance.UNLOADED ? handleGoalStepOnChoouseUnloaded() : handleGoalStepOnChoouseDateRange();

		onSetZone(DrawerZone.REFRESH_CMS_PROGRESS);
		setLastImportedAccountData({
			registerNumber: cmsAccount.AccountNumber,
			legalEntityName: cmsAccount.LegalEntityName,
		});
		setLastAccountStatementImport(null);
		api.fundsPack.cashManagementSystem.importAccountStatementFromCMS({
			cmsAccount,
			cmsGUID,
			dateRange: scope.dateRange,
			dataType: dataType,
			onCheck: (_, status, message) => {
				onSetStatus({ status, message });
			},
			onFinish: response => {
				setLastAccountStatementImport(response);
				onSetZone(DrawerZone.REFRESH_CMS_RESULT);
			},
		});
	};

	return (
		<XCmsRefresh
			isFetching={boundaryBalancesDates.isFetching}
			fundsRegisterForRefresh={fundsRegisterForRefresh}
			appearance={appearance}
			currenciesMap={currenciesMap}
			setAppearance={setAppearance}
			setLastImportedAccountData={setLastImportedAccountData}
			onStartRefreshAccountFromCMS={handleStartRefreshAccountFromCMS}
		/>
	);
});

export { CmsRefresh };
