import { createAsyncAction } from '@flux';
import { mainMarketplacesSelectorsPack } from '@marketplaces/selectors';
import { types } from './types';

const actions = {
	addRule: createAsyncAction(types.ADD_RULE, (api, _, __, rule: MarketplaceOperationRule) => {
		return new Promise<MarketplaceOperationRule>(resolve => {
			api.plPack.marketplaceOperationRule.addRule(rule).then(result => {
				resolve(result);
			});
		});
	}) as (rule: MarketplaceOperationRule) => void,
	fetchOperationTypes: createAsyncAction(
		types.FETCH_OPERATION_TYPES,
		api => api.plPack.marketplaceOperationRule.fetchOperationTypes(),
		{
			isValidSelector: mainMarketplacesSelectorsPack.selectAsyncOperationTypes.selectIsValid,
			isFetchingSelector: mainMarketplacesSelectorsPack.selectAsyncOperationTypes.selectIsFetching,
		},
	) as () => void,
	fetchRules: createAsyncAction(types.FETCH_RULES_TYPES, api => api.plPack.marketplaceOperationRule.fetchRules(), {
		isValidSelector: mainMarketplacesSelectorsPack.selectAsyncRules.selectIsValid,
		isFetchingSelector: mainMarketplacesSelectorsPack.selectAsyncRules.selectIsFetching,
	}) as () => void,
};

export const mainMarketplacesActionsPack = actions;
