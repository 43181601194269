/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable prettier/prettier */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-var-requires */
'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _simpleAssign = require('simple-assign');

var _simpleAssign2 = _interopRequireDefault(_simpleAssign);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _TextField = require('../TextField');

var _TextField2 = _interopRequireDefault(_TextField);

var _DropDownMenu = require('../DropDownMenu');

var _DropDownMenu2 = _interopRequireDefault(_DropDownMenu);

function _interopRequireDefault(obj) {
	return obj && obj.__esModule ? obj : {
		default: obj
	};
}

function getStyles(props) {
	return {
		label: {
			paddingLeft: 0,
			top: props.floatingLabelText ? 6 : -4
		},
		icon: {
			right: 0,
			top: props.floatingLabelText ? 8 : 0
		},
		hideDropDownUnderline: {
			borderTop: 'none'
		},
		dropDownMenu: {
			display: 'block'
		}
	};
}

var SelectField = function (_Component) {
	(0, _inherits3.default)(SelectField, _Component);

	function SelectField() {
		(0, _classCallCheck3.default)(this, SelectField);

		this.setTextFieldRef = this.setTextFieldRef.bind(this);
		return (0, _possibleConstructorReturn3.default)(this, (SelectField.__proto__ || (0, _getPrototypeOf2.default)(SelectField)).apply(this, arguments));
	}

	(0, _createClass3.default)(SelectField, [{
		key: 'setTextFieldRef',
		value: function setTextFieldRef(ref) {
			this.textFiledRef = ref;
		}
	}, {
		key: 'componentDidUpdate',
		value: function componentDidUpdate(prevProps) {
			if (this.props.value !== prevProps.value) {
				this.textFiledRef && this.textFiledRef.handleInputBlur();
			}
		}
	}, {
		key: 'render',
		value: function render() {
			var _props = this.props,
				autoWidth = _props.autoWidth,
				multiple = _props.multiple,
				children = _props.children,
				style = _props.style,
				labelStyle = _props.labelStyle,
				iconStyle = _props.iconStyle,
				id = _props.id,
				underlineDisabledStyle = _props.underlineDisabledStyle,
				underlineFocusStyle = _props.underlineFocusStyle,
				menuItemStyle = _props.menuItemStyle,
				selectedMenuItemStyle = _props.selectedMenuItemStyle,
				underlineStyle = _props.underlineStyle,
				dropDownMenuProps = _props.dropDownMenuProps,
				errorStyle = _props.errorStyle,
				disabled = _props.disabled,
				floatingLabelFixed = _props.floatingLabelFixed,
				floatingLabelText = _props.floatingLabelText,
				floatingLabelStyle = _props.floatingLabelStyle,
				hintStyle = _props.hintStyle,
				hintText = _props.hintText,
				fullWidth = _props.fullWidth,
				errorText = _props.errorText,
				listStyle = _props.listStyle,
				maxHeight = _props.maxHeight,
				menuStyle = _props.menuStyle,
				onFocus = _props.onFocus,
				onBlur = _props.onBlur,
				onChange = _props.onChange,
				selectionRenderer = _props.selectionRenderer,
				value = _props.value,
				popoverProps = _props.popoverProps,
				other = (0, _objectWithoutProperties3.default)(_props, ['autoWidth', 'multiple', 'children', 'style', 'labelStyle', 'iconStyle', 'id', 'underlineDisabledStyle', 'underlineFocusStyle', 'menuItemStyle', 'selectedMenuItemStyle', 'underlineStyle', 'dropDownMenuProps', 'errorStyle', 'disabled', 'floatingLabelFixed', 'floatingLabelText', 'floatingLabelStyle', 'hintStyle', 'hintText', 'fullWidth', 'errorText', 'listStyle', 'maxHeight', 'menuStyle', 'onFocus', 'onBlur', 'onChange', 'selectionRenderer', 'value', 'popoverProps']);

			var styles = getStyles(this.props, this.context);

			return _react2.default.createElement(
				_TextField2.default,
				(0, _extends3.default)({}, other, {
					ref: this.setTextFieldRef,
					style: style,
					disabled: disabled,
					floatingLabelFixed: floatingLabelFixed,
					floatingLabelText: floatingLabelText,
					floatingLabelStyle: floatingLabelStyle,
					hintStyle: hintStyle,
					hintText: !hintText && !floatingLabelText ? ' ' : hintText,
					fullWidth: fullWidth,
					errorText: errorText,
					underlineStyle: underlineStyle,
					errorStyle: errorStyle,
					onFocus: onFocus,
					onBlur: onBlur,
					id: id,
					underlineDisabledStyle: underlineDisabledStyle,
					underlineFocusStyle: underlineFocusStyle
				}),
				_react2.default.createElement(
					_DropDownMenu2.default,
					(0, _extends3.default)({
						disabled: disabled,
						style: (0, _simpleAssign2.default)(styles.dropDownMenu, menuStyle),
						labelStyle: (0, _simpleAssign2.default)(styles.label, labelStyle),
						iconStyle: (0, _simpleAssign2.default)(styles.icon, iconStyle),
						menuItemStyle: menuItemStyle,
						selectedMenuItemStyle: selectedMenuItemStyle,
						underlineStyle: styles.hideDropDownUnderline,
						listStyle: listStyle,
						autoWidth: autoWidth,
						value: value,
						onChange: onChange,
						maxHeight: maxHeight,
						multiple: multiple,
						selectionRenderer: selectionRenderer,
						popoverProps: popoverProps
					}, dropDownMenuProps),
					children
				)
			);
		}
	}]);
	return SelectField;
}(_react.Component);

SelectField.defaultProps = {
	autoWidth: false,
	disabled: false,
	fullWidth: false,
	multiple: false
};

var MuiThemeContext = require('../styles/context').default;

SelectField.contextType = MuiThemeContext;

SelectField.propTypes = process.env.NODE_ENV !== "production" ? {
	/**
	 * If true, the width will automatically be set according to the
	 * items inside the menu.
	 * To control the width in CSS instead, leave this prop set to `false`.
	 */
	autoWidth: _propTypes2.default.bool,
	/**
	 * The `MenuItem` elements to populate the select field with.
	 * If the menu items have a `label` prop, that value will
	 * represent the selected menu item in the rendered select field.
	 */
	children: _propTypes2.default.node,
	/**
	 * If true, the select field will be disabled.
	 */
	disabled: _propTypes2.default.bool,
	/**
	 * Object that can handle and override any property of component DropDownMenu.
	 */
	dropDownMenuProps: _propTypes2.default.object,
	/**
	 * Override the inline-styles of the error element.
	 */
	errorStyle: _propTypes2.default.object,
	/**
	 * The error content to display.
	 */
	errorText: _propTypes2.default.node,
	/**
	 * If true, the floating label will float even when no value is selected.
	 */
	floatingLabelFixed: _propTypes2.default.bool,
	/**
	 * Override the inline-styles of the floating label.
	 */
	floatingLabelStyle: _propTypes2.default.object,
	/**
	 * The content of the floating label.
	 */
	floatingLabelText: _propTypes2.default.node,
	/**
	 * If true, the select field will take up the full width of its container.
	 */
	fullWidth: _propTypes2.default.bool,
	/**
	 * Override the inline-styles of the hint element.
	 */
	hintStyle: _propTypes2.default.object,
	/**
	 * The hint content to display.
	 */
	hintText: _propTypes2.default.node,
	/**
	 * Override the inline-styles of the icon element.
	 */
	iconStyle: _propTypes2.default.object,
	/**
	 * The id prop for the text field.
	 */
	id: _propTypes2.default.string,
	/**
	 * Override the label style when the select field is inactive.
	 */
	labelStyle: _propTypes2.default.object,
	/**
	 * Override the inline-styles of the underlying `List` element.
	 */
	listStyle: _propTypes2.default.object,
	/**
	 * Override the default max-height of the underlying `DropDownMenu` element.
	 */
	maxHeight: _propTypes2.default.number,
	/**
	 * Override the inline-styles of menu items.
	 */
	menuItemStyle: _propTypes2.default.object,
	/**
	 * Override the inline-styles of the underlying `DropDownMenu` element.
	 */
	menuStyle: _propTypes2.default.object,
	/**
	 * If true, `value` must be an array and the menu will support
	 * multiple selections.
	 */
	multiple: _propTypes2.default.bool,
	/** @ignore */
	onBlur: _propTypes2.default.func,
	/**
	 * Callback function fired when a menu item is selected.
	 *
	 * @param {object} event TouchTap event targeting the menu item
	 * that was selected.
	 * @param {number} key The index of the selected menu item, or undefined
	 * if `multiple` is true.
	 * @param {any} payload If `multiple` is true, the menu's `value`
	 * array with either the menu item's `value` added (if
	 * it wasn't already selected) or omitted (if it was already selected).
	 * Otherwise, the `value` of the menu item.
	 */
	onChange: _propTypes2.default.func,
	/** @ignore */
	onFocus: _propTypes2.default.func,
	/**
	 * Override the inline-styles of selected menu items.
	 */
	selectedMenuItemStyle: _propTypes2.default.object,
	/**
	 * Customize the rendering of the selected item.
	 *
	 * @param {any} value If `multiple` is true, the menu's `value`
	 * array with either the menu item's `value` added (if
	 * it wasn't already selected) or omitted (if it was already selected).
	 * Otherwise, the `value` of the menu item.
	 * @param {any} menuItem The selected `MenuItem`.
	 * If `multiple` is true, this will be an array with the `MenuItem`s matching the `value`s parameter.
	 */
	selectionRenderer: _propTypes2.default.func,
	/**
	 * Override the inline-styles of the root element.
	 */
	style: _propTypes2.default.object,
	/**
	 * Override the inline-styles of the underline element when the select
	 * field is disabled.
	 */
	underlineDisabledStyle: _propTypes2.default.object,
	/**
	 * Override the inline-styles of the underline element when the select field
	 * is focused.
	 */
	underlineFocusStyle: _propTypes2.default.object,
	/**
	 * Override the inline-styles of the underline element.
	 */
	underlineStyle: _propTypes2.default.object,
	/**
	 * If `multiple` is true, an array of the `value`s of the selected
	 * menu items. Otherwise, the `value` of the selected menu item.
	 * If provided, the menu will be a controlled component.
	 */
	value: _propTypes2.default.any
} : {};
exports.default = SelectField;