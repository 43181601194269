import { checkAsyncItemLoadAction, createDefaultAsyncItem, invalidateAction } from '../../../core/libs/reducer-creator';
import { FETCH_TENANT_BY_ID, UPDATE_TENANT, INVALIDATE_TENANT } from '../actions/tenant-account.actions';

interface ITenantAccountState {
	tenant: StoreAsyncItem<Tenant>;
}

const initialState = {
	tenant: createDefaultAsyncItem({}),
} as ITenantAccountState;

export default function (state: ITenantAccountState = initialState, action) {
	switch (action.type) {
		case FETCH_TENANT_BY_ID:
			const fetchTenantByIDAction = action as AsyncAction<Tenant>;

			return {
				...state,
				tenant: checkAsyncItemLoadAction(fetchTenantByIDAction, state.tenant, response => response),
			};

		case UPDATE_TENANT:
			return {
				...state,
				tenant: invalidateAction(action, state.tenant),
			};

		case INVALIDATE_TENANT:
			return {
				...state,
				tenant: invalidateAction(action, state.tenant),
			};

		default:
			return state;
	}
}

export { ITenantAccountState };
