import React, { useRef, useLayoutEffect, useMemo, memo } from 'react';
import ResizeObserver from 'resize-observer-polyfill'; // polyfill

import { useMounted } from '@core/hooks/use-mounted';
import { useForceUpdate } from '@core/hooks/use-force-update';
import { useDebouncedCallback } from '@core/hooks/use-debounced-callback';

export type ResizeContainerSensorProps = {
	debounce?: number;
	children: (options: ResizeContainerSensorOptions) => React.ReactNode;
};

const ResizeContainerSensor: React.FC<ResizeContainerSensorProps> = props => {
	const { debounce, children } = props;
	const rootRef = useRef<HTMLDivElement>(null);
	const { mounted } = useMounted();
	const { forceUpdate } = useForceUpdate();
	const options = useMemo(
		() => ({
			width: 0,
			height: 0,
		}),
		[],
	);

	useLayoutEffect(() => {
		const parent = rootRef.current.parentElement;
		if (!parent) return;
		const width = parent.clientWidth;
		const height = parent.clientHeight;
		const observer = new ResizeObserver(handleResize);

		observer.observe(parent);

		if (detectIsKeysDifferent(options, { width, height })) {
			options.width = width;
			options.height = height;

			forceUpdate();
		}

		return () => observer.disconnect();
	}, [rootRef.current]);

	const handleResize = useDebouncedCallback(
		(entries: Array<ResizeObserverEntry>) => {
			if (!mounted()) return;
			const [first] = entries;
			if (!first) return;
			const {
				contentRect: { width, height },
			} = first;

			if (detectIsKeysDifferent(options, { width, height })) {
				options.width = width;
				options.height = height;

				forceUpdate();
			}
		},
		debounce,
		[],
	);

	return <div ref={rootRef}>{children({ ...options })}</div>;
};

const detectIsKeysDifferent = (
	prevOptions: ResizeContainerSensorOptions,
	nextOptions: ResizeContainerSensorOptions,
) => {
	const keyOne = `${prevOptions.width}|${prevOptions.height}`;
	const keyTwo = `${nextOptions.width}|${nextOptions.height}`;

	if (keyOne === keyTwo) return false;

	return true;
};

ResizeContainerSensor.defaultProps = {
	debounce: 0,
};

export type ResizeContainerSensorOptions = {
	width: number;
	height: number;
};

export { ResizeContainerSensor };
