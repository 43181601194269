import React, { memo } from 'react';

import { preferencesApi } from '@core/api/preferences';
import { useMapState, useMapDispatch } from '@flux';
import { themeActionsPack } from '@shared/actions/theme.actions';
import { selectCurrentTenantID } from '@platform/selectors/context.selectors';
import { ThemeMode, useTheme } from '@theme';
import { SettingsKey } from '@shared/models';
import { DarkModeSwitch as XDarkModeSwitch } from './dark-mode-switch.view';

type DarkModeSwitchProps = {};

const DarkModeSwitch: React.FC<DarkModeSwitchProps> = memo(() => {
	const { isDarkMode, themeBrand } = useTheme();
	const [tenantID] = useMapState([selectCurrentTenantID]);
	const [setThemeIdentifier] = useMapDispatch([themeActionsPack.setThemeIdentifier]);

	const handleChange = () => {
		const themeMode = isDarkMode ? ThemeMode.LIGHT : ThemeMode.DARK;

		preferencesApi.rest.savePreferences(tenantID, { [SettingsKey.THEME_MODE]: themeMode }).then(() => {});
		setThemeIdentifier([themeBrand, themeMode]);
	};

	return <XDarkModeSwitch isDarkMode={isDarkMode} onChange={handleChange} />;
});

export { DarkModeSwitch };
