import React from 'react';

import { IconBase, IconBaseProps } from './base';

export type ChatIconProps = IconBaseProps;

const ChatIcon: React.FC<ChatIconProps> = props => {
	return (
		<IconBase {...props}>
			<svg
				fill='currentColor'
				stroke='currentColor'
				strokeWidth='0'
				viewBox='0 0 16 16'
				xmlns='http://www.w3.org/2000/svg'>
				<path d='M2.678 11.894C2.7818 11.9982 2.86133 12.124 2.91094 12.2625C2.96055 12.4009 2.97901 12.5486 2.965 12.695C2.89472 13.3726 2.76148 14.0421 2.567 14.695C3.962 14.372 4.814 13.998 5.201 13.802C5.4205 13.6908 5.67329 13.6645 5.911 13.728C6.59243 13.9097 7.29477 14.0011 8 14C11.996 14 15 11.193 15 8C15 4.808 11.996 2 8 2C4.004 2 1 4.808 1 8C1 9.468 1.617 10.83 2.678 11.894ZM2.185 15.799C1.94807 15.846 1.71037 15.889 1.472 15.928C1.272 15.96 1.12 15.752 1.199 15.566C1.28779 15.3566 1.36918 15.1441 1.443 14.929L1.446 14.919C1.694 14.199 1.896 13.371 1.97 12.6C0.743 11.37 0 9.76 0 8C0 4.134 3.582 1 8 1C12.418 1 16 4.134 16 8C16 11.866 12.418 15 8 15C7.20765 15.0011 6.41859 14.8982 5.653 14.694C5.133 14.957 4.014 15.436 2.185 15.799Z' />
				<path d='M4 5.5C4 5.36739 4.05268 5.24021 4.14645 5.14645C4.24021 5.05268 4.36739 5 4.5 5H11.5C11.6326 5 11.7598 5.05268 11.8536 5.14645C11.9473 5.24021 12 5.36739 12 5.5C12 5.63261 11.9473 5.75979 11.8536 5.85355C11.7598 5.94732 11.6326 6 11.5 6H4.5C4.36739 6 4.24021 5.94732 4.14645 5.85355C4.05268 5.75979 4 5.63261 4 5.5ZM4 8C4 7.86739 4.05268 7.74021 4.14645 7.64645C4.24021 7.55268 4.36739 7.5 4.5 7.5H11.5C11.6326 7.5 11.7598 7.55268 11.8536 7.64645C11.9473 7.74021 12 7.86739 12 8C12 8.13261 11.9473 8.25979 11.8536 8.35355C11.7598 8.44732 11.6326 8.5 11.5 8.5H4.5C4.36739 8.5 4.24021 8.44732 4.14645 8.35355C4.05268 8.25979 4 8.13261 4 8ZM4 10.5C4 10.3674 4.05268 10.2402 4.14645 10.1464C4.24021 10.0527 4.36739 10 4.5 10H8.5C8.63261 10 8.75979 10.0527 8.85355 10.1464C8.94732 10.2402 9 10.3674 9 10.5C9 10.6326 8.94732 10.7598 8.85355 10.8536C8.75979 10.9473 8.63261 11 8.5 11H4.5C4.36739 11 4.24021 10.9473 4.14645 10.8536C4.05268 10.7598 4 10.6326 4 10.5Z' />
			</svg>
		</IconBase>
	);
};

export { ChatIcon };
