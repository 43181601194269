import { createTypes } from '@flux';

type ActionTypes = {
	RUN_COUNTERPARTIES_INVALIDATION_EFFECT: string;
	RUN_COUNTERPARTY_GROUPS_INVALIDATION_EFFECT: string;
	INVALIDATE_COUNTERPARTIES: string;
	INVALIDATE_COUNTERPARTY_GROUPS: string;
	SET_FILTER_BY_TEXT: string;
	SET_FILTER_BY_COUNTERPARTY_TYPES: string;
	SET_FILTER_BY_COUNTERPARTY_GROUPS: string;
	SET_FILTER_BY_COUNTERPARTY_MANAGERS: string;
	RESET_FILTERS: string;
	FETCH_COUNTERPARTIES: string;
	ADD_COUNTERPARTY: string;
	UPDATE_COUNTERPARTY: string;
	REMOVE_COUNTERPARTY: string;
	FETCH_COUNTERPARTY_GROUPS: string;
	ADD_COUNTERPARTY_GROUP: string;
	UPDATE_COUNTERPARTY_GROUP: string;
	REMOVE_COUNTERPARTY_GROUP: string;
	LINK_GROUPS_TO_COUNTERPARTY: string;
	FETCH_INDUSTRIES: string;
	FETCH_STATE_INDUSTRIES: string;
	LINK_MANAGER_TO_COUNTERPARTY: string;
	TRANSFORM_COUNTERPARTY_TO_TENANT_LEGAL_ENTITY: string;
	RESET_IS_COUNTERPARTIES_CHANGED: string;
};

export const types = createTypes<ActionTypes>(
	[
		'RUN_COUNTERPARTIES_INVALIDATION_EFFECT',
		'RUN_COUNTERPARTY_GROUPS_INVALIDATION_EFFECT',
		'INVALIDATE_COUNTERPARTIES',
		'INVALIDATE_COUNTERPARTY_GROUPS',
		'SET_FILTER_BY_TEXT',
		'SET_FILTER_BY_COUNTERPARTY_TYPES',
		'SET_FILTER_BY_COUNTERPARTY_GROUPS',
		'SET_FILTER_BY_COUNTERPARTY_MANAGERS',
		'RESET_FILTERS',
		'FETCH_COUNTERPARTIES',
		'ADD_COUNTERPARTY',
		'UPDATE_COUNTERPARTY',
		'REMOVE_COUNTERPARTY',
		'FETCH_COUNTERPARTY_GROUPS',
		'ADD_COUNTERPARTY_GROUP',
		'UPDATE_COUNTERPARTY_GROUP',
		'REMOVE_COUNTERPARTY_GROUP',
		'LINK_GROUPS_TO_COUNTERPARTY',
		'FETCH_INDUSTRIES',
		'FETCH_STATE_INDUSTRIES',
		'LINK_MANAGER_TO_COUNTERPARTY',
		'TRANSFORM_COUNTERPARTY_TO_TENANT_LEGAL_ENTITY',
		'RESET_IS_COUNTERPARTIES_CHANGED',
	],
	'😊COUNTERPARTIES',
);
