import React from 'react';

import { getPropInSafe } from '@utils';
import { LineRendererButton } from '@ui/list/line-renderer';
import { Dialog } from '@ui/dialog';
import { CloseButton } from '@ui/button/close-button';
import { RaisedButton } from '@ui/raised-button';
import { DownloadIcon } from '@ui/icons/download';
import { EyeIcon } from '@ui/icons/eye';
import { PDFExporter } from '@ui/pdf-exporter';
import { InvoiceDocumentViewer } from '@invoices/components/invoice-document-viewer';
import { invoiceResolver } from './billing-preview.resolver';
import {
	Root,
	PreviewLayout,
	DocumentLayout,
	ZoomLayout,
	ControlsLayout,
	ButtonLayout,
	dialogStyle,
	dialogBodyStyle,
	dialogContentStyle,
	dialogTitleStyle,
	HeaderLayout,
	CloseButtonLayout,
	CoverLayout,
} from './styled';
import { prettyDate } from '@core/utils/date';

export type BillingPreviewProps = {
	type?: 'PREVIEW' | 'MODAL';
	tenantBill: TenantBill;
	tenant?: Tenant;
	onSetRef?: (ref) => void;
};

type BillingPreviewState = {
	isOpen: boolean;
};

class BillingPreview extends React.Component<BillingPreviewProps, BillingPreviewState> {
	static displayName = 'BillingPreview';
	state = { isOpen: false };
	pdfExporterRef: PDFExporter = null;

	setPDFExporterRef = ref => {
		this.pdfExporterRef = ref;
	};

	public handleOpenPreview = () => {
		this.setState({ isOpen: true });
	};

	public handleClosePreview = () => {
		this.setState({ isOpen: false });
	};

	public handleExportToPDF = () => {
		this.pdfExporterRef && this.pdfExporterRef.exportToFile();
	};

	renderDialogHeader = () => {
		return (
			<HeaderLayout>
				<RaisedButton onClick={this.handleExportToPDF}>
					<DownloadIcon color='accent' />
					<span>Скачать в формате PDF</span>
				</RaisedButton>
				<CloseButtonLayout>
					<CloseButton onClick={this.handleClosePreview} />
				</CloseButtonLayout>
			</HeaderLayout>
		);
	};

	render() {
		const { type, tenant, tenantBill } = this.props;
		const { isOpen } = this.state;
		const paymentMethodCode = getPropInSafe(tenantBill, o => o.BillingPayment.PaymentMethodCode, 'UNKNOW');

		if (tenant && tenantBill) {
			return (
				<Root>
					{type === 'PREVIEW' && (
						<PreviewLayout>
							<DocumentLayout>
								<ZoomLayout>
									<InvoiceDocumentViewer variant='paper' invoice={invoiceResolver(tenant, tenantBill)} />
								</ZoomLayout>
							</DocumentLayout>
							<ControlsLayout>
								<ButtonLayout>
									<LineRendererButton
										color='primary'
										icon={<EyeIcon color='accent' />}
										label='Просмотреть'
										onClick={this.handleOpenPreview}
									/>
								</ButtonLayout>
							</ControlsLayout>
						</PreviewLayout>
					)}
					<Dialog
						open={isOpen}
						title={this.renderDialogHeader()}
						modal
						repositionOnUpdate={false}
						autoDetectWindowHeight={false}
						autoScrollBodyContent={false}
						style={dialogStyle as React.CSSProperties}
						bodyStyle={dialogBodyStyle as React.CSSProperties}
						titleStyle={dialogTitleStyle as React.CSSProperties}
						contentStyle={dialogContentStyle}>
						<CoverLayout>
							<PDFExporter
								ref={this.setPDFExporterRef}
								fileName={`Счёт №${tenantBill.ID} за период с ${prettyDate(tenantBill.PeriodDateStart)} по ${prettyDate(
									tenantBill.PeriodDateEnd,
								)}`}
								style={{ padding: 20 }}>
								<InvoiceDocumentViewer variant='paper' invoice={invoiceResolver(tenant, tenantBill)} />
							</PDFExporter>
						</CoverLayout>
					</Dialog>
				</Root>
			);
		}

		return null;
	}
}

const paymentMethods = {
	BANK_TRANSFER: 'Банковский перевод',
	CARD_PAYMENT: 'Платёж по карте',
	BANK_INVOICE: 'Автоматическая оплата',
	BANK_MARKETPLACE: 'Автоматическая оплата',
	UNKNOW: '',
};

export default BillingPreview;
