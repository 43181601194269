import styled, { keyframes, css } from 'styled-components';

import type { FetchingPlaceholderProps } from './fetching-placeholder';

const fetchingMotion = keyframes`
	100% {
		background-position: 200px 0;
	}
`;

const ContentLayout = styled.div``;

type PlaceholderProps = {} & Pick<FetchingPlaceholderProps, 'gradientColor' | 'isFetching' | 'maxWidth' | 'display'>;

const Root = styled.div<PlaceholderProps>`
	position: relative;
	display: block;

	${p => css`
		display: ${p.display};
	`}

	${p =>
		p.isFetching &&
		css`
			${ContentLayout} {
				visibility: hidden;
				opacity: 0;
			}

			&:after {
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-image: linear-gradient(90deg, ${p.gradientColor.start} 0%, ${p.gradientColor.stop} 100%);
				background-position: 0 0;
				animation: ${fetchingMotion} 0.8s infinite alternate;
				animation-timing-function: ease-in-out;

				${p.maxWidth ? `max-width: ${p.maxWidth}px` : ''}
			}
		`}
`;

export { ContentLayout, Root };
