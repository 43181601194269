import styled, { css } from 'styled-components';

const Root = styled.div`
	position: relative;
	width: 100%;
	height: auto;
`;

const ContentLayout = styled.div`
	width: calc(100% + 20px);
	height: auto;
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;
	align-items: stretch;
	margin: 0 -10px;
`;

interface IItem {
	itemWidth: string;
	isActive: boolean;
}

const Item = styled.div<IItem>`
	width: ${p => (p.itemWidth ? `calc(${p.itemWidth} - 20px)` : 'calc(33.3333% - 20px)')};
	min-height: 110px;
	border-radius: 2px;
	border: 1px solid transparent;
	transition: all 0.2s ease-in-out;
	cursor: pointer;
	margin: 10px;
	padding: 16px;
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;

	${p => css`
		background-color: ${p.theme.materialCard.backgroundColor};
		${p.theme.fn.createBoxShadow(2)}
	`}

	${p =>
		p.isActive &&
		css`
			border-color: ${p.theme.palette.accent};
			box-shadow: none;
			cursor: default;
		`}
`;

const IconLayout = styled.div`
	margin-bottom: 10px;
`;

const TextLayout = styled.div`
	font-size: 14px;
	line-height: 1.43;
	text-align: center;
`;

export { Root, ContentLayout, Item, IconLayout, TextLayout };
