import { CounterpartyType } from '@core/api/counterparty';

function detectIsLegalEntity(counterparty: Counterparty | LegalEntity | Employee): counterparty is LegalEntity {
	if (counterparty.CLASSIFIER === 'LEGAL_ENTITY') {
		return true;
	}

	return false;
}

function detectIsNaturalPerson(
	counterparty: Counterparty | LegalEntity | Employee | CounterpartyBrief,
): counterparty is NaturalPerson {
	if (counterparty.CLASSIFIER === 'NATURAL_PERSON' || counterparty.CLASSIFIER === 'EMPLOYEE') {
		return true;
	}

	if (counterparty.CLASSIFIER === 'COUNTERPARTY_BRIEF') {
		const brief = counterparty as CounterpartyBrief;

		return !brief.LegalEntity && !brief.SoleProprietor;
	}

	return false;
}

function detectIsEmployee(counterparty: Counterparty | Employee | CounterpartyBrief): counterparty is Employee {
	if (counterparty.CLASSIFIER === 'EMPLOYEE_BRIEF' || counterparty.CLASSIFIER === 'EMPLOYEE') {
		return true;
	}

	return false;
}

function getCounterpartyType(counterparty: CounterpartyBrief) {
	const isNaturalPerson = !counterparty.LegalEntity;
	const isEmployee = counterparty.CLASSIFIER === 'EMPLOYEE_BRIEF';
	const isSoleProprietor = counterparty.LegalEntity && counterparty.SoleProprietor;
	const isLegalEntity = counterparty.LegalEntity && !counterparty.SoleProprietor;
	const isCorporate = isLegalEntity && counterparty.LegalEntityTypeCode === 'CORPORATE';
	const isBank = isLegalEntity && counterparty.LegalEntityTypeCode === 'BANK';
	const isSovereign = isLegalEntity && counterparty.LegalEntityTypeCode === 'SOVEREIGN';

	return {
		isNaturalPerson,
		isEmployee,
		isSoleProprietor,
		isLegalEntity,
		isCorporate,
		isBank,
		isSovereign,
	};
}

function getCounterpartyTypeCode(counterparty: CounterpartyBrief): CounterpartyType {
	const { isNaturalPerson, isEmployee, isSoleProprietor, isCorporate, isBank, isSovereign } =
		getCounterpartyType(counterparty);

	if (isNaturalPerson || isEmployee) return CounterpartyType.NATURAL_PERSON;
	if (isSoleProprietor) return CounterpartyType.SOLE_PROPRIETOR;
	if (isCorporate) return CounterpartyType.CORPORATE;
	if (isBank) return CounterpartyType.BANK;
	if (isSovereign) return CounterpartyType.SOVEREIGN;

	return null;
}

function detectIsAdditionalTaxCode(value = ''): boolean {
	return value.length === 9;
}

function detectIsStateRegistrationCode(value: string): boolean {
	const valueAsString = value.toString();

	if (isNaN(+valueAsString)) {
		return false;
	}

	if (!/^\d{13,15}$/.test(valueAsString)) {
		return false;
	}

	const controlValue = +valueAsString.slice(-1);
	const testValue = +valueAsString.slice(0, -1);

	if (valueAsString.length === 13 && !Number.isNaN(controlValue)) {
		return +(testValue % 11).toString().slice(-1) === controlValue;
	}

	if (valueAsString.length === 15 && !Number.isNaN(controlValue)) {
		return +(testValue % 13).toString().slice(-1) === controlValue;
	}

	return false;
}

function detectIsTaxCode(value: string): boolean {
	const multiplier10 = [2, 4, 10, 3, 5, 9, 4, 6, 8, 0];
	const multiplier11 = [7, 2, 4, 10, 3, 5, 9, 4, 6, 8, 0];
	const multiplier12 = [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8, 0];
	const valueAsString = value.toString();

	if (isNaN(+valueAsString)) {
		return false;
	}

	if (!/^\d{10,12}$/.test(valueAsString)) {
		return false;
	}

	const splitted = valueAsString.split('');

	if (splitted.length === 10 && !Number.isNaN(+splitted[splitted.length - 1])) {
		const lastNumber = +splitted[splitted.length - 1];
		const multiSum = splitted.map((v, index) => +v * multiplier10[index]).reduce((acc, v) => acc + v, 0);
		let controlValue = multiSum % 11;

		if (controlValue > 9) {
			controlValue = controlValue % 10;
		}

		return controlValue === lastNumber;
	}

	if (splitted.length === 12 && !Number.isNaN(+splitted[splitted.length - 1])) {
		const number11 = +splitted[splitted.length - 2];
		const number12 = +splitted[splitted.length - 1];
		const multiSum1 = splitted
			.slice(0, 11)
			.map((v, index) => +v * multiplier11[index])
			.reduce((acc, v) => acc + v, 0);
		let controlValue1 = multiSum1 % 11;

		if (controlValue1 > 9) {
			controlValue1 = controlValue1 % 10;
		}

		const multiSum2 = splitted.map((v, index) => +v * multiplier12[index]).reduce((acc, v) => acc + v, 0);
		let controlValue2 = multiSum2 % 11;

		if (controlValue2 > 9) {
			controlValue2 = controlValue2 % 10;
		}

		return controlValue1 === number11 && controlValue2 === number12;
	}

	return false;
}

function detectIsSoleProprietor(counterparty: CounterpartyBrief, brief?: Partial<CounterpartyBrief>) {
	const isSoleProprietor = counterparty?.SoleProprietor;
	const isBriefSoleProprietor = brief?.SoleProprietor;

	if (typeof isBriefSoleProprietor !== 'undefined') return isBriefSoleProprietor;
	if (typeof isSoleProprietor !== 'undefined') return isSoleProprietor;

	return true;
}

function detectHasAdditionalTaxCode(counterparty: CounterpartyBrief, brief?: Partial<CounterpartyBrief>) {
	const isNaturalPerson = !counterparty?.LegalEntity;
	const isSoleProprietor = detectIsSoleProprietor(counterparty, brief);

	return !isNaturalPerson && !isSoleProprietor;
}

function formatLegalEntityName(name = '') {
	if (!name) return '';
	const map = {
		'Общество\\s*с\\s*ограниченной\\s*ответственностью': 'ООО',
		'Открытое\\s*акционерное\\s*общество': 'ОАО',
		'Закрытое\\s*акционерное\\s*общество': 'ЗАО',
		'Акционерное\\s*общество\\s*закрытого\\s*типа': 'АОЗТ',
		'Публичное\\s*акционерное\\s*общество': 'ПАО',
		'Акционерное\\s*общество': 'АО',
		'Индивидуальный\\s*предприниматель': 'ИП',
	};

	for (const key of Object.keys(map)) {
		const pattern = new RegExp(key, 'igm');

		if (pattern.test(name)) {
			name = name.replace(pattern, map[key]);
			break;
		}
	}

	return name;
}

export {
	detectIsLegalEntity,
	detectIsNaturalPerson,
	detectIsEmployee,
	getCounterpartyType,
	getCounterpartyTypeCode,
	detectIsAdditionalTaxCode,
	detectIsStateRegistrationCode,
	detectIsTaxCode,
	detectIsSoleProprietor,
	detectHasAdditionalTaxCode,
	formatLegalEntityName,
};
