import React, { memo, useState } from 'react';

import { useMapState } from '@core/flux';
import { selectSelectedCMS } from '@integrations/selectors';
import { XCmsSignUp, XCmsSignUpProps } from './cms-signup.view';

type CmsSignUpProps = Pick<XCmsSignUpProps, 'onSetZone'>;

const CmsSignUp: React.FC<CmsSignUpProps> = memo(({ onSetZone }) => {
	const [selectedCMS] = useMapState([selectSelectedCMS]);
	const [account, setAccount] = useState<ExternalSystemAccount>(null);

	return <XCmsSignUp account={account} selectedCMS={selectedCMS} onSetZone={onSetZone} onChangeAccount={setAccount} />;
});

export { CmsSignUp };
