import React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { IAppState } from '@store';
import {
	selectErrorMessageViewIsOpen,
	selectLastErrorMessage,
	IErrorMessage,
} from '../../selectors/error-message.selectors';
import { closeErrorMessageViewAction } from '../../actions/error-message.action';
import XErrorMessage from './error-message.view';

type ErrorMessageProps = {
	errorMessageViewIsOpen?: boolean;
	lastErrorMessage?: IErrorMessage;
	closeErrorMessageView?: () => void;
};

class ErrorMessage extends React.PureComponent<ErrorMessageProps> {
	static displayName = 'ErrorMessage[Container]';

	render() {
		const { errorMessageViewIsOpen, lastErrorMessage, closeErrorMessageView } = this.props;

		return (
			<XErrorMessage
				isOpen={errorMessageViewIsOpen}
				lastErrorMessage={lastErrorMessage}
				onClose={closeErrorMessageView}
			/>
		);
	}
}

function mapStateToProps(state: IAppState) {
	return {
		errorMessageViewIsOpen: selectErrorMessageViewIsOpen(state),
		lastErrorMessage: selectLastErrorMessage(state),
	};
}

function mapDispatchToProps(dispatch: Dispatch) {
	return bindActionCreators(
		{
			closeErrorMessageView: closeErrorMessageViewAction,
		},
		dispatch,
	);
}

export default connect<any, any, ErrorMessageProps>(mapStateToProps, mapDispatchToProps)(ErrorMessage);
