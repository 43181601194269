import React from 'react';

import { SuccessIcon } from '@ui/icons/success';
import { SpinnerAutoLayout, Spinner } from '@ui/spinner';
import {
	Root,
	WrapLayout,
	IconLayout,
	TitleLayout,
	Title,
	Detail,
	ControlsLayout,
	ButtonLayout,
	ActionButton,
	DefaultButton,
} from './styled';

type DrawerConfirmDialogProps = {
	title?: React.ReactNode;
	description?: React.ReactNode;
	actionLabel?: string;
	isFetching?: boolean;
	disabled?: boolean;
	onAction?: (e: React.MouseEvent) => void;
	onClose?: (e: React.MouseEvent) => void;
};

const DrawerConfirmDialog: React.FC<DrawerConfirmDialogProps> = props => {
	const { title, description, actionLabel, isFetching, disabled, onAction, onClose } = props;

	if (isFetching) {
		return (
			<Root>
				<SpinnerAutoLayout appearance='max-drawer'>
					<Spinner />
				</SpinnerAutoLayout>
			</Root>
		);
	}

	return (
		<Root>
			<WrapLayout>
				<IconLayout>
					<SuccessIcon color='alarm' size={100} />
				</IconLayout>
				<TitleLayout>
					<Title>{title}</Title>
					<Detail>{description}</Detail>
				</TitleLayout>
				<ControlsLayout centered={!actionLabel}>
					{actionLabel && (
						<ButtonLayout>
							<ActionButton label={actionLabel} disabled={disabled} onClick={!disabled && onAction} />
						</ButtonLayout>
					)}
					<ButtonLayout>
						<DefaultButton label='Отмена' onClick={onClose} />
					</ButtonLayout>
				</ControlsLayout>
			</WrapLayout>
		</Root>
	);
};

export default DrawerConfirmDialog;
