import styled, { css } from 'styled-components';

const Root = styled.div`
	width: 100%;
	height: auto;
	min-height: 50px;
	border-bottom: 1px solid transparent;
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;

	${p => css`
		background-color: ${p.theme.palette.background};
		border-color: ${p.theme.palette.stealth};
	`}
`;

const Label = styled.div`
	flex-shrink: 1;
	font-size: 12px;
	line-height: 1.25;
	margin-right: 4px;

	${p => css`
		color: ${p.theme.palette.label};
	`}
`;

const PickerLayout = styled.div`
	flex-shrink: 0;
`;

export { Root, Label, PickerLayout };
