import { Store } from 'redux';

import { IAppState } from '@store';
import { isReceiveAction } from '@flux';
import { mainTenantProfileActionsPack } from '@platform/actions/main-tenant-profile.actions';
import { emitActionMessage } from '@shared/actions/action-message.actions';


function createReloadEffect() {
	return (store: Store<IAppState>) => (next: (action: StoreAction) => void) => (action: AsyncAction) => {
		const asyncActionTypesMap = {
			[mainTenantProfileActionsPack.types.REMOVE_DEMO_DATA]: true,
		};

		if (asyncActionTypesMap[action.type] && isReceiveAction(action)) {
			setTimeout(() => {
				store.dispatch(emitActionMessage('Сейчас страница будет перезагружена', 'warning'));
				setTimeout(() => location.reload(), 3000);
			}, 4000);
		}

		next(action);
	}
}

const runReloadEffect = createReloadEffect();

export {
	runReloadEffect,
};
