import styled, { css } from 'styled-components';

const Root = styled.div`
	position: relative;
	width: auto;
	height: auto;
	max-width: 420px;
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: center;
	padding: 16px 22px;
`;

const TitleLayout = styled.div`
	flex-shrink: 0;
	margin-right: 10px;
`;

const Title = styled.h1`
	margin: 0;
	font-size: 13px;
	font-weight: 500;
	line-height: 1.15;
	text-align: left;
	text-transform: uppercase;

	${p => css`
		color: ${p.theme.palette.label};
	`}
`;

const NumberLayout = styled.div``;

const Number = styled.div`
	font-size: 13px;
	font-weight: 700;
	line-height: 1.15;
	text-align: left;
`;

const InfoLayout = styled.div`
	flex-shrink: 1;
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;
	align-items: center;
`;

export { Root, TitleLayout, Title, NumberLayout, Number, InfoLayout };
