import { MainCounterpartyType } from '@counterparties/models';
import { types } from '@document-templates/actions/types';
import {
	StoreAsyncItem,
	checkAsyncAction,
	createAsyncInitialState,
	createReducer,
	invalidateStateFromAction,
} from '@flux';

export type MainDocumentTemplatesState = {
	counterpartyType: MainCounterpartyType;
	searchText: string;
	templates: StoreAsyncItem<Array<FinancialDocumentTemplate>>;
};

const initialState: MainDocumentTemplatesState = {
	counterpartyType: MainCounterpartyType.LEGAL_ENTITY,
	searchText: '',
	templates: createAsyncInitialState([]),
};

const mainDocumentTemplatesReducer = createReducer<MainDocumentTemplatesState>(initialState, {
	[types.FETCH_DOCUMENT_TEMPLATES]: (action: AsyncAction<Array<FinancialDocumentTemplate>>, state) => {
		return {
			templates: checkAsyncAction(action, state.templates),
		};
	},
	[types.INVALIDATE_MODULE_DATA]: (action: StaticAction<string>, state) => {
		return {
			templates: invalidateStateFromAction(action, state.templates),
		};
	},
	[types.SET_COUNTERPARTY_TYPE]: (action: StaticAction<MainCounterpartyType>) => {
		return {
			counterpartyType: action.value,
		};
	},
	[types.SET_SEARCH_TEXT]: (action: StaticAction<string>) => {
		return {
			searchText: action.value,
		};
	},
});

export { mainDocumentTemplatesReducer };
