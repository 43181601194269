import React from 'react';

import { CircularProgress } from '@ui/circular-progress';
import { Root, Text, LoaderLayout } from './styled';

interface IProps {
	text?: string;
	size?: number;
	thickness?: number;
	color?: 'accent' | 'white';
	inline?: boolean;
}

class Loader extends React.Component<IProps> {
	static defaultProps = {
		size: 50,
		thickness: 4,
		color: 'accent',
	} as any;
	static Layout = LoaderLayout;

	render() {
		const { text, size, thickness, color, inline } = this.props;

		return (
			<Root inline={inline}>
				{text && <Text>{text}</Text>}
				<CircularProgress size={size} thickness={thickness} color={color} />
			</Root>
		);
	}
}

export { Loader };
