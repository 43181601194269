import React, { memo } from 'react';

import { useMapState, useMapDispatch, useAutoFetch } from '@flux';
import { withFormAutopicker } from '@ui/autopicker';
import { mainReferenceActionsPack } from '@reference/actions';
import { mainReferenceSelectorsPack } from '@reference/selectors';
import {
	TaxModeAutopicker as XTaxModeAutopicker,
	TaxModeAutopickerProps as XTaxModeAutopickerProps,
} from './tax-mode-autopicker.view';

export type TaxModeAutopickerProps = {} & Omit<XTaxModeAutopickerProps, 'dataSource' | 'isFetching' | 'isLoaded'>;

const TaxModeAutopicker: React.FC<TaxModeAutopickerProps> = memo(props => {
	const [isFetching, isLoaded, dataSource] = useMapState([
		mainReferenceSelectorsPack.selectAsyncLegalEntityTaxModes.selectIsFetching,
		mainReferenceSelectorsPack.selectAsyncLegalEntityTaxModes.selectIsLoaded,
		mainReferenceSelectorsPack.selectFilteredLegalEntityTaxModes,
	]);
	const [fetchLegalEntityTaxModes] = useMapDispatch([mainReferenceActionsPack.fetchLegalEntityTaxModes]);

	useAutoFetch({
		selector: mainReferenceSelectorsPack.selectAsyncLegalEntityTaxModes.selectDidInvalidate,
		fetch: () => fetchLegalEntityTaxModes(),
	});

	return <XTaxModeAutopicker {...props} isFetching={isFetching} isLoaded={isLoaded} dataSource={dataSource} />;
});

const FormTaxModeAutopicker = withFormAutopicker<TaxModeAutopickerProps, unknown>(TaxModeAutopicker);

const taxModeAutopickerTransformer = {
	legalEntityTaxMode: {
		single: {
			transformInput: ({ input }: { input: LegalEntityTaxMode }) => (input ? { [input.ID]: true } : null),
			transformOutput: ({ items }) => (items[0] as LegalEntityTaxMode) || null,
		},
	},
};

export { TaxModeAutopicker, FormTaxModeAutopicker, taxModeAutopickerTransformer };
