import {
	StoreAsyncItem,
	checkAsyncAction,
	createAsyncInitialState,
	invalidateStateFromAction,
	createReducer,
} from '@flux';
import { restoreIncompleteTree } from '@utils/tree';
import { types } from '@counterparties/actions/types';

export type MainCounterpartiesState = {
	counterparties: StoreAsyncItem<Array<CounterpartyBrief>>;
	groups: StoreAsyncItem<Array<CounterpartyGroup>>;
	searchText: string;
	typesFilter: Record<string, boolean>;
	groupsFilter: Record<string, boolean>;
	managersFilter: Record<string, boolean>;
	industries: StoreAsyncItem<Array<SyntheticIndustry>>;
	stateIndustries: StoreAsyncItem<Array<SyntheticIndustry>>;
	isCounterpartiesChanged: boolean;
};

const initialState: MainCounterpartiesState = {
	counterparties: createAsyncInitialState([]),
	groups: createAsyncInitialState([]),
	searchText: '',
	typesFilter: {},
	groupsFilter: {},
	managersFilter: {},
	industries: createAsyncInitialState([]),
	stateIndustries: createAsyncInitialState([]),
	isCounterpartiesChanged: false,
};

const mainCounterpartiesReducer = createReducer(initialState, {
	[types.RESET_IS_COUNTERPARTIES_CHANGED]: () => {
		return {
			isCounterpartiesChanged: false,
		};
	},
	[types.INVALIDATE_COUNTERPARTIES]: (action: StaticAction, state) => {
		return {
			counterparties: invalidateStateFromAction(action, state.counterparties),
			isCounterpartiesChanged: true,
		};
	},
	[types.INVALIDATE_COUNTERPARTY_GROUPS]: (action: StaticAction, state) => {
		return {
			groups: invalidateStateFromAction(action, state.groups),
			counterparties: invalidateStateFromAction(action, state.counterparties),
		};
	},
	[types.FETCH_COUNTERPARTIES]: (action: AsyncAction<Array<CounterpartyBrief>>, state) => {
		return {
			counterparties: checkAsyncAction(action, state.counterparties),
		};
	},
	[types.ADD_COUNTERPARTY]: (action: AsyncAction, state) => {
		return {
			counterparties: invalidateStateFromAction(action, state.counterparties),
			isCounterpartiesChanged: true,
		};
	},
	[types.UPDATE_COUNTERPARTY]: (action: AsyncAction, state) => {
		return {
			counterparties: invalidateStateFromAction(action, state.counterparties),
			isCounterpartiesChanged: true,
		};
	},
	[types.REMOVE_COUNTERPARTY]: (action: AsyncAction, state) => {
		return {
			counterparties: invalidateStateFromAction(action, state.counterparties),
			isCounterpartiesChanged: true,
		};
	},
	[types.FETCH_COUNTERPARTY_GROUPS]: (action: AsyncAction<Array<CounterpartyGroup>>, state) => {
		return {
			groups: checkAsyncAction(action, state.groups),
		};
	},
	[types.ADD_COUNTERPARTY_GROUP]: (action: AsyncAction, state) => {
		return {
			groups: invalidateStateFromAction(action, state.groups),
			counterparties: invalidateStateFromAction(action, state.counterparties),
		};
	},
	[types.UPDATE_COUNTERPARTY_GROUP]: (action: AsyncAction, state) => {
		return {
			groups: invalidateStateFromAction(action, state.groups),
			counterparties: invalidateStateFromAction(action, state.counterparties),
		};
	},
	[types.REMOVE_COUNTERPARTY_GROUP]: (action: AsyncAction, state) => {
		return {
			groups: invalidateStateFromAction(action, state.groups),
			counterparties: invalidateStateFromAction(action, state.counterparties),
		};
	},
	[types.LINK_GROUPS_TO_COUNTERPARTY]: (action: AsyncAction, state) => {
		return {
			counterparties: invalidateStateFromAction(action, state.counterparties),
		};
	},
	[types.TRANSFORM_COUNTERPARTY_TO_TENANT_LEGAL_ENTITY]: (action: AsyncAction, state) => {
		return {
			counterparties: invalidateStateFromAction(action, state.counterparties),
			isCounterpartiesChanged: true,
		};
	},
	[types.LINK_MANAGER_TO_COUNTERPARTY]: (action: AsyncAction, state) => {
		return {
			counterparties: invalidateStateFromAction(action, state.counterparties),
		};
	},
	[types.FETCH_INDUSTRIES]: (action: AsyncAction<Array<SyntheticIndustry>>, state) => {
		return {
			industries: checkAsyncAction(action, state.industries, response => {
				return restoreIncompleteTree<SyntheticIndustry>({
					getID: x => x.ID,
					getParentID: x => x.ParentID,
					setChildItems: (item, childItems) => (item.ChildItems = childItems),
					items: response,
				});
			}),
		};
	},
	[types.FETCH_STATE_INDUSTRIES]: (action: AsyncAction<Array<SyntheticIndustry>>, state) => {
		return {
			stateIndustries: checkAsyncAction(action, state.stateIndustries, response => {
				return restoreIncompleteTree<SyntheticIndustry>({
					getID: x => x.ID,
					getParentID: x => x.ParentID,
					setChildItems: (item, childItems) => (item.ChildItems = childItems),
					items: response,
				});
			}),
		};
	},
	[types.SET_FILTER_BY_TEXT]: (action: StaticAction<string>, state) => {
		return {
			searchText: action.value,
			counterparties: invalidateStateFromAction(action, state.counterparties),
		};
	},
	[types.SET_FILTER_BY_COUNTERPARTY_TYPES]: (action: StaticAction<Record<string, boolean>>, state) => {
		return {
			typesFilter: { ...action.value },
			counterparties: invalidateStateFromAction(action, state.counterparties),
		};
	},
	[types.SET_FILTER_BY_COUNTERPARTY_GROUPS]: (action: StaticAction<Record<string, boolean>>, state) => {
		return {
			groupsFilter: { ...action.value },
			counterparties: invalidateStateFromAction(action, state.counterparties),
		};
	},
	[types.SET_FILTER_BY_COUNTERPARTY_MANAGERS]: (action: StaticAction<Record<string, boolean>>, state) => {
		return {
			managersFilter: { ...action.value },
			counterparties: invalidateStateFromAction(action, state.counterparties),
		};
	},
	[types.RESET_FILTERS]: (action: StaticAction, state) => {
		return {
			textFilter: '',
			typesFilter: {},
			groupsFilter: {},
			managersFilter: {},
			counterparties: invalidateStateFromAction(action, state.counterparties),
		};
	},
});

export { mainCounterpartiesReducer };
