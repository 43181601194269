import React from 'react';

export type PdfExporterProps = {
	style?: React.CSSProperties;
	fileName?: string;
	children: React.ReactNode;
};

class PDFExporter extends React.PureComponent<PdfExporterProps> {
	static defaultProps = {
		fileName: 'document',
		style: {},
	};
	rootRef: HTMLElement = null;

	public exportToFile = () => this.rootRef && this.generateFile();

	private setRootRef = (ref: HTMLDivElement) => (this.rootRef = ref);

	private generateFile = async () => {
		const html2canvas = ((await import('html2canvas')) as any).default;
		const jsPDF = (await import('jspdf')).default;
		const node = this.rootRef.children[0];

		html2canvas(node, { scale: 1 }).then((canvas: HTMLCanvasElement) => {
			const pdf = new jsPDF({
				format: 'a4',
				unit: 'mm',
			});

			pdf.addImage(canvas.toDataURL('image/png'), 'png', 0, 0, undefined, undefined);
			pdf.save(`${this.props.fileName}.pdf`);
		});
	};

	render() {
		return <div ref={this.setRootRef}>{this.props.children}</div>;
	}
}

export { PDFExporter };
