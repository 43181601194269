import { createTypes } from '@flux';

export type ActionTypes = {
	ADD_DOCUMENT: string;
	UPDATE_DOCUMENT: string;
	REMOVE_DOCUMENT: string;
	FETCH_CONTRACTS: string;
	INVALIDATE_CONTRACTS: string;
};

const types = createTypes<ActionTypes>(
	['ADD_DOCUMENT', 'UPDATE_DOCUMENT', 'REMOVE_DOCUMENT', 'FETCH_CONTRACTS', 'INVALIDATE_CONTRACTS'],
	'😀DOCUMENTS',
);

export { types };
