import React from 'react';
import moment from 'moment';

import { getGlobalConfig, getAppName } from '@config';
import { hasKeys } from '@utils/object';
import { plural } from '@utils/plural';
import { getDrawer } from '@utils';
import { Badge, BadgeColors } from '@ui/badge';
import { StatefulTooltip } from '@ui/tooltip';
import { BASE_DATE_FORMAT, BASE_TIME_FORMAT } from '@shared/constants/time';
import BillingWizard from '../billing-wizard';
import { Root } from './styled';

export type BillingAlertProps = {
	status: BillingServiceStatus;
	tenantAccount: TenantAccount;
};

const BillingAlert: React.FC<BillingAlertProps> = props => {
	const { status, tenantAccount } = props;
	const { isLoaded, hasAccess, expirationDateMoment, trialExpirationDateMoment, alertCode } = useOptions(
		status,
		tenantAccount,
	);

	const handlePaymentRequest = () => {
		getDrawer().open({ content: <BillingWizard /> });
	};

	const renderContent = () => {
		const renderMap = {
			[AlertCode.WAITING_PAYMENT_CONFIRMATION]: () => {
				return (
					<Badge appearance='contained' color={BadgeColors.yellow}>
						Ожидается поступление платежа 😊
					</Badge>
				);
			},
			[AlertCode.SENT_TO_PAYMENT_GATEWAY]: () => {
				return (
					<Badge appearance='contained' color={BadgeColors.yellow}>
						Счёт отправлен в оплату 😊
					</Badge>
				);
			},
			[AlertCode.FREMIUM_MODE]: () => {
				return (
					<Badge appearance='contained' color={BadgeColors.warning}>
						Бесплатный режим 😊
						<StatefulTooltip position='bottom'>Ограниченная версия сервиса</StatefulTooltip>
					</Badge>
				);
			},
			[AlertCode.TRIAL_MODE]: () => {
				const diff = trialExpirationDateMoment.diff(moment().endOf('day'), 'day');
				const daysBeforeExpiration = diff > 0 ? diff : 0;

				return (
					<Badge appearance='contained' color={BadgeColors.success}>
						Пробный период 😀
						<StatefulTooltip position='bottom'>
							Доступ к сервису закончится через {daysBeforeExpiration}{' '}
							{plural({ count: daysBeforeExpiration, titles: ['день', 'дня', 'дней'] })}
						</StatefulTooltip>
					</Badge>
				);
			},
			[AlertCode.PAYMENT_EXPIRES_SOON]: () => {
				const diff = expirationDateMoment.diff(moment().endOf('day'), 'day');
				const daysBeforeExpiration = diff > 0 ? diff : 0;

				return (
					<Badge appearance='contained' color={BadgeColors.yellow}>
						Скоро закончится оплата 🙃
						<StatefulTooltip position='bottom'>
							Доступ к сервису закончится через {daysBeforeExpiration}{' '}
							{plural({ count: daysBeforeExpiration, titles: ['день', 'дня', 'дней'] })}
						</StatefulTooltip>
					</Badge>
				);
			},
			[AlertCode.BLOCKED]: () => (
				<Badge appearance='contained' color={BadgeColors.alarm}>
					Доступ заблокирован ☹️
					<StatefulTooltip position='bottom'>Пожалуйста, оплатите доступ к сервису 🙄</StatefulTooltip>
				</Badge>
			),
		};

		return renderMap[alertCode] ? <Root onClick={handlePaymentRequest}>{renderMap[alertCode]()}</Root> : null;
	};

	if (!isLoaded) return null;
	if (isInvoiceApp && isSberbank && hasAccess) return null;

	return renderContent();
};

function useOptions(status: BillingServiceStatus, tenantAccount: TenantAccount) {
	const hasStatus = hasKeys(status);
	const hasTenantAccount = hasKeys(tenantAccount);
	const isLoaded = hasStatus && hasTenantAccount;

	if (!isLoaded) {
		return {
			isLoaded,
			hasAccess: false,
		};
	}

	const isTrialMode = status.Warning;
	const isFreemiumMode = status.FreemiumMode;
	const expirationDateMoment = moment(tenantAccount.ExpireDate, BASE_TIME_FORMAT);
	const hasAccess = status.AccessGranted && expirationDateMoment > moment();
	const isPaymentExpiresSoon = expirationDateMoment.clone().subtract(1, 'week') < moment();
	const trialExpirationDateMoment = moment(tenantAccount.TrialPeriodStartDateTime, BASE_DATE_FORMAT).add(
		tenantAccount.TrialDays,
		'days',
	);
	const isWaitingPaymentConfirmation = status.BillStatusCode == 'WAITING_FOR_PAYMENT_CONFIRMATION';
	const isSentToPaymentGateway = status.BillStatusCode == 'SENT_TO_PAYMENT_GATEWAY';
	const alertCode = isWaitingPaymentConfirmation
		? AlertCode.WAITING_PAYMENT_CONFIRMATION
		: isSentToPaymentGateway
		? AlertCode.SENT_TO_PAYMENT_GATEWAY
		: isFreemiumMode
		? AlertCode.FREMIUM_MODE
		: isTrialMode
		? AlertCode.TRIAL_MODE
		: !hasAccess
		? AlertCode.BLOCKED
		: isPaymentExpiresSoon
		? AlertCode.PAYMENT_EXPIRES_SOON
		: null;

	return {
		isLoaded,
		hasAccess,
		expirationDateMoment,
		trialExpirationDateMoment,
		alertCode,
	};
}

enum AlertCode {
	WAITING_PAYMENT_CONFIRMATION = 'waiting_payment_confirmation',
	SENT_TO_PAYMENT_GATEWAY = 'sent_to_payment_gateway',
	FREMIUM_MODE = 'fremium_mode',
	TRIAL_MODE = 'trial_mode',
	PAYMENT_EXPIRES_SOON = 'payment_expires_soon',
	BLOCKED = 'blocked',
}

const isInvoiceApp = getAppName() === 'invoice';
const { isSberbank } = getGlobalConfig();

export { BillingAlert };
