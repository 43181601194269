import React, { memo } from 'react';
import { useHistory } from 'react-router-dom';

import { useMapState, useMapDispatch, useAutoFetch } from '@flux';
import { today, addMonthsFromToday } from '@utils/date';
import { ROUTE_MAP } from '@routes/urls';
import { mainPlanningActionsPack } from '@planning/actions';
import { selectAsyncIsBalanceHidden } from '@funds-registers/selectors';
import {
	selectPlanningRight,
	selectAsyncCashGapIndicatorData,
	selectAsyncForecastIndicatorData,
	selectIndicatorCashGapPoint,
	selectForecastIndicatorDate,
	selectForecastIndicatorOutgoingBalance,
} from '@planning/selectors';
import { Indicator as XIndicator } from './cashflow-forecast-indicator.view';
import { selectForecastIndicatorRight } from '@dashboard/selectors';

export type IndicatorProps = {};

const Indicator: React.FC<IndicatorProps> = memo(props => {
	const history = useHistory();
	const [
		isForecastFetching,
		isForecastLoaded,
		isCashGapFetching,
		isCashGapLoaded,
		isBalanceHiddenFetching,
		isBalanceHidden,
		date,
		balance,
		cashGapPoint,
		canVisitPlanning,
		forecastIndicatorRight,
	] = useMapState([
		selectAsyncForecastIndicatorData.selectIsFetching,
		selectAsyncForecastIndicatorData.selectIsLoaded,
		selectAsyncCashGapIndicatorData.selectIsFetching,
		selectAsyncCashGapIndicatorData.selectIsLoaded,
		selectAsyncIsBalanceHidden.selectIsFetching,
		selectAsyncIsBalanceHidden.selectItem,
		selectForecastIndicatorDate,
		selectForecastIndicatorOutgoingBalance,
		selectIndicatorCashGapPoint,
		selectPlanningRight.canVisit,
		selectForecastIndicatorRight.view,
	]);
	const [fetchForecastIndicatorData, fetchCashGapIndicatorData, setForecastIndicatorDate] = useMapDispatch([
		mainPlanningActionsPack.fetchForecastIndicatorData,
		mainPlanningActionsPack.fetchCashGapIndicatorData,
		mainPlanningActionsPack.setForecastIndicatorDate,
	]);
	const hasPlanningFeature = canVisitPlanning && forecastIndicatorRight;

	useAutoFetch({
		selector: selectAsyncForecastIndicatorData.selectDidInvalidate,
		fetch: () => {
			hasPlanningFeature &&
				fetchForecastIndicatorData({
					dateRange: {
						dateStart: today(),
						dateEnd: date,
					},
					excludeArchiveRegister: true,
				});
		},
	});

	useAutoFetch({
		selector: selectAsyncCashGapIndicatorData.selectDidInvalidate,
		fetch: () => {
			hasPlanningFeature &&
				fetchCashGapIndicatorData({
					dateRange: {
						dateStart: today(),
						dateEnd: addMonthsFromToday(1),
					},
				});
		},
	});

	const handleNavigateToPlanning = () => history.push(ROUTE_MAP.PL_PLANNING);

	const isLoaded = isForecastLoaded && isCashGapLoaded;

	return (
		<XIndicator
			{...props}
			isForecastFetching={isForecastFetching}
			isCashGapFetching={isCashGapFetching}
			isBalanceHiddenFetching={isBalanceHiddenFetching}
			isLoaded={isLoaded}
			isBalanceHidden={isBalanceHidden}
			date={date}
			balance={balance}
			cashGapPoint={cashGapPoint}
			hasPlanningFeature={hasPlanningFeature}
			onNavigateToPlanning={handleNavigateToPlanning}
			onChangeDate={setForecastIndicatorDate}
		/>
	);
});

export { Indicator as CashflowForecastIndicator };
