import { EmployeeBusinessRoleCode } from '@core/api/organization';

const PROJECT_EMPLOYEE_ROLES = [
	EmployeeBusinessRoleCode.DIRECTOR,
	EmployeeBusinessRoleCode.PROJECT_MANAGER,
	EmployeeBusinessRoleCode.PROJECT_OFFICE_MANAGER,
];

export { PROJECT_EMPLOYEE_ROLES };

export const ProjectEmployeeAccessLevel = {
	NO_ACCESS: 0,
	FULL_ACCESS: 2,
};
