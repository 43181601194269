import React from 'react';
import styled, { css } from 'styled-components';

import { Box } from '@ui/box';
import { WithThemeProps } from '@theme';
import { LinkProps } from './link';

type RootProps = {} & LinkProps & WithThemeProps;

const Root = styled(({ appearance, underline, ...rest }) => <Box {...rest} />)`
	transition: color 0.2s ease-in-out;
	background-color: transparent;
	cursor: pointer;
	outline: none;

	${(p: RootProps) => css`
		color: ${p.theme.link.textColor};

		&:focus {
			color: ${p.theme.link.textColorFocus};
		}

		&:hover {
			color: ${p.theme.link.textColorHover};
			text-decoration: underline;
			border-color: ${p.theme.link.textColorHover};
		}
	`}

	${(p: RootProps) =>
		p.appearance === 'muted' &&
		css`
			color: ${p.theme.palette.label};
		`}

	${(p: RootProps) =>
		p.appearance === 'black' &&
		css`
			color: ${p.theme.palette.text};
		`}

	${(p: RootProps) =>
		p.appearance === 'white' &&
		css`
			color: ${p.theme.palette.textContrast};

			&:hover {
				color: ${p.theme.palette.textContrast};
			}
		`}

	${(p: RootProps) =>
		p.appearance === 'dashed' &&
		css`
			border-bottom: 1px dashed currentColor;

			&:hover {
				text-decoration: none;
			}
		`}

	${(p: RootProps) =>
		p.underline &&
		css`
			text-decoration: underline;
		`}
`;

export { Root };
