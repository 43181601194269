import {
	StoreAsyncItem,
	checkAsyncAction,
	createAsyncInitialState,
	createReducer,
	invalidateStateFromAction,
} from '@flux';
import { types } from '@tenant-legal-entities/actions/types';
import { LegalEntityForm } from '@tenant-legal-entities/models';

export type MainTenantLegalEntitiesState = {
	legalEntities: StoreAsyncItem<Array<CounterpartyBrief>>;
	searchText: string;
	legalEntityFormFilter: Record<LegalEntityForm, boolean>;
};

const initialState: MainTenantLegalEntitiesState = {
	legalEntities: createAsyncInitialState([]),
	searchText: '',
	legalEntityFormFilter: {} as Record<LegalEntityForm, boolean>,
};

const mainTenantLegalEntitiesReducer = createReducer(initialState, {
	[types.ADD_TENANT_LEGAL_ENTITY]: (action: StaticAction, state) => {
		return {
			legalEntities: invalidateStateFromAction(action, state.legalEntities),
		};
	},
	[types.FETCH_TENANT_LEGAL_ENTITIES]: (action: AsyncAction<Array<CounterpartyBrief>>, state) => {
		return {
			legalEntities: checkAsyncAction(action, state.legalEntities),
		};
	},
	[types.INVALIDATE_TENANT_LEGAL_ENTITIES]: (action: StaticAction, state) => {
		return {
			legalEntities: invalidateStateFromAction(action, state.legalEntities),
		};
	},
	[types.REMOVE_TENANT_LEGAL_ENTITY]: (action: StaticAction, state) => {
		return {
			legalEntities: invalidateStateFromAction(action, state.legalEntities),
		};
	},
	[types.RESET_FILTERS]: () => {
		return {
			searchText: '',
			legalEntityFormFilter: {} as Record<LegalEntityForm, boolean>,
		};
	},
	[types.RESET_TENANT_LEGAL_ENTITIES]: () => {
		return {
			legalEntities: createAsyncInitialState([]),
		};
	},
	[types.SET_FILTER_BY_LEGAL_ENTITY_FORM]: (action: StaticAction<Record<LegalEntityForm, boolean>>) => {
		return {
			legalEntityFormFilter: { ...action.value },
		};
	},
	[types.SET_FILTER_BY_TEXT]: (action: StaticAction<string>) => {
		return {
			searchText: action.value,
		};
	},
	[types.TRANSFORM_TENANT_LEGAL_ENTITY_TO_COUNTERPARTY]: (action: StaticAction, state) => {
		return {
			legalEntities: invalidateStateFromAction(action, state.legalEntities),
		};
	},
	[types.UPDATE_TENANT_LEGAL_ENTITY]: (action: StaticAction, state) => {
		return {
			legalEntities: invalidateStateFromAction(action, state.legalEntities),
		};
	},
});

export { mainTenantLegalEntitiesReducer };
