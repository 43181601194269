import {
	StoreAsyncItem,
	checkAsyncAction,
	createAsyncInitialState,
	createReducer,
	invalidateStateFromAction,
} from '@flux';
import { types } from '../actions/types';

export type MainFundsRegisterOpenRequestsState = {
	regions: StoreAsyncItem<Array<Region>>;
	openRequests: StoreAsyncItem<Array<FundsRegisterOpenRequest>>;
};

const initialState: MainFundsRegisterOpenRequestsState = {
	regions: createAsyncInitialState([]),
	openRequests: createAsyncInitialState([]),
};

const mainFundsRegisterOpenRequestsReducer = createReducer<MainFundsRegisterOpenRequestsState>(initialState, {
	[types.FETCH_OPEN_REQUESTS]: (action: AsyncAction<Array<FundsRegisterOpenRequest>>, state) => {
		return {
			openRequests: checkAsyncAction(action, state.openRequests),
		};
	},
	[types.FETCH_REGIONS]: (action: AsyncAction<Array<Product>>, state) => {
		return {
			regions: checkAsyncAction(action, state.regions),
		};
	},
	[types.RESET_OPEN_REQUESTS]: (action: AsyncAction<void>, state) => {
		return {
			openRequests: invalidateStateFromAction(action, state.openRequests),
		};
	},
});

export { mainFundsRegisterOpenRequestsReducer };
