import React from 'react';

import { Box } from '@ui/box';
import { HelpMark } from '@ui/help-mark';

export type FloatingLabelTextProps = {
	labelText: React.ReactNode;
	readonly?: boolean;
	required?: boolean;
	helpMark?: React.ReactNode;
};

const FloatingLabelText: React.FC<FloatingLabelTextProps> = props => {
	const { labelText, readonly, required, helpMark } = props;
	const requiredMark = labelText && labelText !== ' ' && labelText !== '\u00A0' && !readonly && required ? ' *' : '';

	return !readonly && typeof helpMark !== 'undefined' ? (
		<Box display='inline-flex' flexWrap='wrap' alignItems='center'>
			{labelText}
			{requiredMark}
			<Box display='inline-flex' justifyContent='center' alignItems='center' marginLeft={4} marginTop={-2}>
				<HelpMark label={helpMark} />
			</Box>
		</Box>
	) : (
		<>
			{labelText}
			{requiredMark}
		</>
	);
};

export { FloatingLabelText };
