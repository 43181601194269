import * as datamanagementpackage from 'datamanagementpackage-api';

const datamanagementApi = {
	dataset: {
		client: new datamanagementpackage.DatasetServiceClient() as DatasetServiceClient,
		fetchDataset: (datasetName: string, params = {}, transformer: (x: Dataset) => any = x => x) => {
			return new Promise<Dataset>(resolve => {
				const request = new datamanagementpackage.DatasetRequest();

				request.DatasetSchemaSystemName = datasetName;
				Object.keys(params).forEach(key => {
					request.ParameterValues[key] = params[key];
				});

				datamanagementApi.dataset.client.getDataset(request, result => {
					resolve(transformer(result));
				});
			});
		},
	},
};

export { datamanagementApi };
