import React from 'react';

import { IconBase, IconBaseProps } from './base';

export type MenuIconProps = IconBaseProps;

const MenuIcon: React.FC<MenuIconProps> = props => {
	return (
		<IconBase {...props}>
			<svg stroke='currentColor' fill='none' strokeWidth='0' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
				<path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M4 6h16M4 12h16M4 18h16'></path>
			</svg>
		</IconBase>
	);
};

export { MenuIcon };
