import React, { forwardRef } from 'react';

import { getFinancialDocumentItemsParams, formatAmountForQrCode } from '@utils/invoice';
import { QrCode, QrCodeProps, QrCodeRef } from '@ui/qr-code';

export type InvoiceBankQrCodeProps = {
	value: Invoice;
} & Omit<QrCodeProps, 'value'>;

const InvoiceBankQrCode = forwardRef<QrCodeRef, InvoiceBankQrCodeProps>(({ value: invoice, ...rest }, ref) => {
	const value = createInvoiceBankQrCodeData(invoice);

	return <QrCode ref={ref} {...rest} value={value} />;
});

function createInvoiceBankQrCodeData(invoice: Invoice) {
	const { totalAmount } = getFinancialDocumentItemsParams(invoice.Items);
	const value = [
		`ST00012`,
		`Name=${invoice.LegalEntity.Name}`,
		`PersonalAcc=${invoice.FundsRegister.Number}`,
		`BankName=${invoice.FundsRegister.Bank.Name}`,
		`BIC=${invoice.FundsRegister.Bank.Bic}`,
		`CorrespAcc=${invoice.FundsRegister.Bank.CorrAccount}`,
		`Sum=${formatAmountForQrCode(totalAmount)}`,
		`PayeeINN=${invoice.LegalEntity.TaxCode}`,
		!invoice.LegalEntity.SoleProprietor && `KPP=${invoice.LegalEntity.AdditionalTaxCode}`,
		invoice.AccrualIdentifier && `UIN=${invoice.AccrualIdentifier}`,
		`Purpose=Оплата счёта №${invoice.Number} от ${invoice.DateIssued} на сумму ${totalAmount} руб.`,
	]
		.filter(Boolean)
		.join('|');

	return value;
}

export { InvoiceBankQrCode, createInvoiceBankQrCodeData };
