import { createTypes } from '@flux';

type ActionTypes = {
	INVALIDATE_MODULE_DATA: string;
	SET_DATE_RANGE: string;
	SET_FILTER_BY_DIRECTION: string;
	SET_VOLUME_DEPENDENCY_TYPES_FILTER: string;
	SET_FILTER_BY_STATUS: string;
	SET_CASHFLOW_ITEM_EXPLORER_DATA: string;
	SET_PROJECT_EXPLORER_DATA: string;
	SET_OPERATION_DETAILS_OPTIONS: string;
	SET_OPERATION_DETAILS_TITLE: string;
	SET_SPLINE_FILTER_BY_BUSINESS_UNIT: string;
	SET_SPLINE_FILTER_BY_PROJECT: string;
	SET_SPLINE_FILTER_BY_CASHFLOW_ITEM: string;
	SET_SPLINE_FILTER_BY_COUNTERPARTY: string;
	SET_SPLINE_FILTER_BY_ORGANIZATION: string;
	FETCH_DYNAMIC_DATA: string;
	FETCH_OPERATIONS: string;
	RESTORE_OPERATIONS_VALIDATION: string;
	SET_IS_ANALITICS_BY_CASHFLOW_ITEMS: string;
};

export const mainTypes = createTypes<ActionTypes>(
	[
		'INVALIDATE_MODULE_DATA',
		'SET_DATE_RANGE',
		'SET_FILTER_BY_DIRECTION',
		'SET_VOLUME_DEPENDENCY_TYPES_FILTER',
		'SET_FILTER_BY_STATUS',
		'SET_CASHFLOW_ITEM_EXPLORER_DATA',
		'SET_PROJECT_EXPLORER_DATA',
		'SET_OPERATION_DETAILS_OPTIONS',
		'SET_OPERATION_DETAILS_TITLE',
		'SET_SPLINE_FILTER_BY_BUSINESS_UNIT',
		'SET_SPLINE_FILTER_BY_PROJECT',
		'SET_SPLINE_FILTER_BY_CASHFLOW_ITEM',
		'SET_SPLINE_FILTER_BY_COUNTERPARTY',
		'SET_SPLINE_FILTER_BY_ORGANIZATION',
		'FETCH_DYNAMIC_DATA',
		'FETCH_OPERATIONS',
		'RESTORE_OPERATIONS_VALIDATION',
		'SET_IS_ANALITICS_BY_CASHFLOW_ITEMS',
	],
	'🤪PL_ANALYTICS',
);
