import React, { useRef, useEffect, useMemo, forwardRef, useImperativeHandle } from 'react';

import { Root } from './styled';

export type DraggableScrollZoneProps = {
	children: React.ReactNode;
} & React.AllHTMLAttributes<HTMLDivElement>;

const DraggableScrollZone = forwardRef<HTMLDivElement, DraggableScrollZoneProps>((props, ref) => {
	const rootRef = useRef<HTMLDivElement>(null);
	const scope: Scope = useMemo(
		() => ({
			coords: { top: 0, left: 0, x: 0, y: 0 },
		}),
		[],
	);

	useImperativeHandle(ref, () => rootRef.current, [rootRef.current]);

	useEffect(() => {
		const handleMouseDown = (e: MouseEvent) => {
			scope.coords = {
				left: rootRef.current.scrollLeft,
				top: rootRef.current.scrollTop,
				x: e.clientX,
				y: e.clientY,
			};

			rootRef.current.style.cursor = 'grabbing';
			rootRef.current.style.userSelect = 'none';

			document.addEventListener('mousemove', handleMouseMove);
			document.addEventListener('mouseup', handleMouseUp);
		};

		const handleMouseMove = (e: MouseEvent) => {
			const dy = e.clientY - scope.coords.y;
			const dx = e.clientX - scope.coords.x;

			rootRef.current.scrollTop = scope.coords.top - dy;
			rootRef.current.scrollLeft = scope.coords.left - dx;
		};

		const handleMouseUp = () => {
			rootRef.current.style.cursor = 'grab';
			rootRef.current.style.removeProperty('user-select');

			document.removeEventListener('mousemove', handleMouseMove);
			document.removeEventListener('mouseup', handleMouseUp);
		};

		document.addEventListener('mousedown', handleMouseDown);

		return () => document.removeEventListener('mousedown', handleMouseDown);
	}, []);

	return <Root ref={rootRef} {...(props as any)} />;
});

type Scope = {
	coords: Coords;
};

type Coords = {
	left: number;
	top: number;
	x: number;
	y: number;
};

export { DraggableScrollZone };
