import { SendNotificationOptions } from '@core/api/notification';
import store from '@core/store';
import { today } from '@core/utils/date';
import { createAsyncAction, createStaticAction } from '@flux';
import { types } from '@platform/actions/types';
import { selectTenant } from '@platform/selectors/tenant-account.selectors';
import { emitActionMessage } from '@shared/actions/action-message.actions';

const actions = {
	notifyAboutImportAccountsFromCmsTaskCompleted: () =>
		createStaticAction(types.IMPORT_ACCOUNTS_FROM_CMS_TASK_COMPLETED)(),
	notifyAboutImportHistoricalAccountStatementFromCmsTaskCompleted: () =>
		createStaticAction(types.IMPORT_HISTORICAL_ACCOUNT_STATEMENT_FROM_CMS_TASK_COMPLETED)(),
	notifySupportsByEmails: createAsyncAction(
		types.NOTIFY_SUPPORTS_BY_EMAILS,
		(api, _, dispatch, options: NotifySupportsByEmailsOptions) => {
			const { comment, eventCode, onComplete } = options;
			const tenant = selectTenant(store.getState());

			return new Promise<void>(resolve => {
				const sendNotificationOptions: SendNotificationOptions = {
					comment,
					date: today(),
					emails: ['ticket@seeneco.ru'],
					eventCode,
					tenant,
				};
				const successHandle = () => {
					dispatch(emitActionMessage('Ваша заявка принята! Ждите звонка нашего специалиста.', 'success'));
					onComplete();
				};
				const failHandle = () =>
					dispatch(emitActionMessage('Не удалось отправить сообщение! Обратитесь в службу поддержки.', 'alarm'));

				api.notificationPack.notification
					.sendNotification(sendNotificationOptions)
					.then(result => {
						if (result.Sent) {
							successHandle();
						} else {
							failHandle();
						}

						resolve();
					})
					.catch(() => {
						failHandle();
						resolve();
					});
			});
		},
	) as (options: NotifySupportsByEmailsOptions) => void,
	runCheckServerTasksEffect: () => createStaticAction(types.RUN_CHECK_SERVER_TASKS_EFFECT)(),
};

export type NotifySupportsByEmailsOptions = {
	comment: NotifySupportsByEmailsComment;
	eventCode: NotifySupportsByEmailsEventCode;
	onComplete: () => void;
};

export enum NotifySupportsByEmailsEventCode {
	ORDER_DEMO = 'HELP_ORDER_DEMO',
	ORDER_SETTING = 'HELP_ORDER_SETTING',
}

export enum NotifySupportsByEmailsComment {
	ORDER_DEMO = 'Заказать онлайн демонстрацию',
	ORDER_SETTING = 'Заказать настройку сервиса',
}

export const platformActionsPack = actions;
