// @ts-nocheck
import React from 'react';
import { DateRange } from 'react-date-range-updated';
import * as moment from 'moment';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';

import { withTheme, Theme } from '@theme';
import { Box } from '@ui/box';
import { Typography } from '@ui/typography';
import { Popover } from '@ui/popover';
import { Dialog } from '@ui/dialog';
import { TextField } from '@ui/input-field';
import { withMaskHOC } from '@ui/input-field/with-mask';
import { BASE_DATE_FORMAT } from '@shared/constants/time';
import {
	DATE_FORMAT,
	DATE_INPUT,
	DATE_SEPARATOR,
	RANGES,
	formatDateInput,
	validateDateInput,
	validateRangeInput,
} from './ranges';
import {
	Root,
	TriggerLayout,
	DateRangeLayout,
	EnterDateLayout,
	EnterDateSign,
	EnterDateInputsLayout,
	EnterDateSeparator,
	DividerLayout,
	Divider,
	createDateRangeTheme,
	dialogContentStyle,
	inputContainerStyle,
	inputStyle,
	TriggerInputLayout,
	PeriodName,
	PeriodNameLayout,
	MaskedTriggerLayout,
	Expander,
} from './styled';

export type DateRangePickerProps = {
	dateStart?: string;
	dateEnd?: string;
	theme?: Theme;
	modalMode?: boolean;
	frameMode?: boolean;
	withPeriodsName?: boolean;
	titleMode?: 'h1' | 'h2';
	rangePosition?: 'right' | 'left';
	anchorHorizontal?: 'left' | 'middle' | 'right';
	anchorVertical?: 'top' | 'center' | 'bottom';
	targetHorizontal?: 'left' | 'middle' | 'right';
	targetVertical?: 'top' | 'center' | 'bottom';
	rootStyle?: React.CSSProperties;
	inputStyle?: React.CSSProperties;
	fullWidth?: boolean;
	readonly?: boolean;
	labelText?: string;
	syncDates?: boolean;
	dateFormat?: 'string' | 'moment';
	disabled?: boolean;
	onChange: (dateStart: Moment | string, dateEnd: Moment | string) => any;
};

type DateRangePickerState = {
	isOpen: boolean;
	popoverAnchorEl: HTMLElement;
	dateStart: string;
	dateEnd: string;
};

class DateRangePicker extends React.PureComponent<DateRangePickerProps, DateRangePickerState> {
	static defaultProps = {
		modalMode: false,
		dateStart: moment().format(DATE_FORMAT),
		dateEnd: moment().format(DATE_FORMAT),
		rangePosition: 'right',
		anchorHorizontal: 'left',
		anchorVertical: 'bottom',
		targetHorizontal: 'left',
		targetVertical: 'top',
		dateFormat: 'moment',
	} as any;
	state: DateRangePickerState = {
		isOpen: this.props.frameMode || false,
		popoverAnchorEl: null,
		dateStart: moment(this.props.dateStart, 'DD-MM-YYYY').format(DATE_FORMAT) || DateRangePicker.defaultProps.dateStart,
		dateEnd: moment(this.props.dateEnd, 'DD-MM-YYYY').format(DATE_FORMAT) || DateRangePicker.defaultProps.dateEnd,
	};
	containerRef: HTMLElement = null;
	expanderRef: HTMLDivElement = null;
	disposeList: Array<() => void> = [];

	componentDidUpdate() {
		if (this.props.syncDates) {
			if (this.props.dateStart !== this.state.dateStart) {
				this.setState({ dateStart: this.props.dateStart });
			}
			if (this.props.dateEnd !== this.state.dateEnd) {
				this.setState({ dateEnd: this.props.dateEnd });
			}
		}
	}

	componentWillUnmount() {
		this.disposeList.forEach(x => x());
	}

	setContainerRef = (ref: HTMLElement) => {
		this.containerRef = ref;

		if (ref && this.disposeList.length === 0) {
			const nodeList: Array<HTMLLinkElement> = Array.from(ref.querySelectorAll('.rdr-PredefinedRangesItem'));

			nodeList.forEach(node => {
				node.addEventListener('click', this.handleCloseDateRangePicker);
				this.disposeList.push(() => node.removeEventListener('click', this.handleCloseDateRangePicker));
			});
		}
	};

	setExpanderRef = ref => (this.expanderRef = ref);

	handleOpenDateRangePicker = (e: React.MouseEvent) => {
		e.preventDefault();
		this.setState({
			isOpen: true,
			popoverAnchorEl: e.currentTarget as HTMLElement,
		});
	};

	handleCloseDateRangePicker = () => {
		this.disposeList.forEach(x => x());
		this.disposeList = [];
		this.setState({ isOpen: false });
	};

	emitOnChangeEvent = (dateStart: string, dateEnd: string): void => {
		const { onChange, dateFormat } = this.props;
		const dateStartMoment = moment(dateStart, DATE_FORMAT);
		const dateEndMoment = moment(dateEnd, DATE_FORMAT);

		if (dateStartMoment.isSame(dateEndMoment) || dateStartMoment.isBefore(dateEndMoment)) {
			dateFormat === 'moment'
				? onChange(dateStartMoment, dateEndMoment)
				: onChange(dateStartMoment.format(BASE_DATE_FORMAT), dateEndMoment.format(BASE_DATE_FORMAT));
		}
	};

	handleChangeDate = range => {
		const dateStart = range.startDate.format(DATE_FORMAT);
		const dateEnd = range.endDate.format(DATE_FORMAT);

		this.setState({ dateStart, dateEnd });
		this.emitOnChangeEvent(dateStart, dateEnd);
	};

	handleSetDatesWithRanges = e => {
		const { name, value } = e.target;

		if (validateRangeInput(value)) {
			this.setState(
				{
					[name]: value,
				} as any,
				() => {
					const isValidDate = moment(value, DATE_FORMAT).isValid();

					if (isValidDate) {
						const { dateStart, dateEnd } = this.state;

						this.emitOnChangeEvent(dateStart, dateEnd);
					}
				},
			);
		}
	};

	handleSetDatesWithInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;

		if (this.expanderRef) {
			this.expanderRef.innerHTML = value;
		}

		if (validateDateInput(value)) {
			const dates = value.split(DATE_SEPARATOR);
			const dateStart = dates[0].trim();
			const dateEnd = dates[1].trim();

			this.setState(
				{
					dateStart,
					dateEnd,
				},
				() => {
					const isValidDateStart = moment(dateStart, DATE_FORMAT).isValid();
					const isValidDateEnd = moment(dateEnd, DATE_FORMAT).isValid();
					const isValidDates = isValidDateStart && isValidDateEnd;

					if (isValidDates) {
						const { dateStart, dateEnd } = this.state;

						this.emitOnChangeEvent(dateStart, dateEnd);
					}
				},
			);
		}
	};

	renderDateRange = () => {
		const { rangePosition, theme } = this.props;
		const { dateStart, dateEnd } = this.state;
		const dateRangeTheme = createDateRangeTheme(rangePosition, theme);
		const setDateStart = () => dateStart;
		const setDateEnd = () => dateEnd;

		return (
			<div>
				<EnterDateLayout rangePosition={rangePosition}>
					<EnterDateSign>Выбор периода</EnterDateSign>
					<EnterDateInputsLayout>
						<MaskedDateInput
							value={dateStart}
							name='dateStart'
							style={inputContainerStyle as React.CSSProperties}
							inputStyle={inputStyle as React.CSSProperties}
							withoutMarginRight
							onChange={this.handleSetDatesWithRanges}
						/>
						<EnterDateSeparator>—</EnterDateSeparator>
						<MaskedDateInput
							value={dateEnd}
							name='dateEnd'
							style={inputContainerStyle as React.CSSProperties}
							inputStyle={inputStyle as React.CSSProperties}
							withoutMarginRight
							onChange={this.handleSetDatesWithRanges}
						/>
					</EnterDateInputsLayout>
				</EnterDateLayout>
				<DividerLayout rangePosition={rangePosition}>
					<Divider />
				</DividerLayout>
				<DateRange
					startDate={setDateStart}
					endDate={setDateEnd}
					format={DATE_FORMAT}
					lang='ru'
					calendars={2}
					firstDayOfWeek={1}
					ranges={RANGES}
					rangedCalendars
					onChange={this.handleChangeDate}
					theme={dateRangeTheme}
				/>
			</div>
		);
	};

	renderWithPopover = () => {
		const { anchorHorizontal, anchorVertical, targetHorizontal, targetVertical } = this.props;
		const { isOpen, popoverAnchorEl } = this.state;
		const anchorOriginSet = {
			horizontal: anchorHorizontal,
			vertical: anchorVertical,
		};
		const targetOrigin = {
			horizontal: targetHorizontal,
			vertical: targetVertical,
		};

		return (
			<Popover
				open={isOpen}
				anchorEl={popoverAnchorEl}
				anchorOrigin={anchorOriginSet}
				targetOrigin={targetOrigin}
				onRequestClose={this.handleCloseDateRangePicker}>
				<DateRangeLayout ref={this.setContainerRef}>{this.renderDateRange()}</DateRangeLayout>
			</Popover>
		);
	};

	renderWithDialog = () => {
		const { isOpen } = this.state;

		return (
			<Dialog open={isOpen} contentStyle={dialogContentStyle} onRequestClose={this.handleCloseDateRangePicker}>
				{this.renderDateRange()}
			</Dialog>
		);
	};

	renderDefaultTrigger = () => {
		const { withPeriodsName, titleMode, inputStyle, labelText, readonly, disabled } = this.props;
		const { dateStart, dateEnd } = this.state;
		const formattedValue = formatDateInput(dateStart, dateEnd);
		let periodName = '';

		Object.keys(RANGES).forEach(key => {
			if (
				moment(dateStart, BASE_DATE_FORMAT).isSame(RANGES[key].startDate(moment()), 'day') &&
				moment(dateEnd, BASE_DATE_FORMAT).isSame(RANGES[key].endDate(moment()), 'day')
			) {
				periodName = key.toLocaleLowerCase();
			}
		});

		const trigger = (
			<MaskedTrigger
				value={formattedValue}
				name={DATE_INPUT}
				readonly={readonly}
				disabled={disabled}
				labelText={labelText}
				withoutMarginRight
				fullWidth
				style={{ ...inputStyle }}
				onChange={this.handleSetDatesWithInput}
			/>
		);

		return (
			<TriggerInputLayout titleMode={titleMode} disabled={disabled}>
				{withPeriodsName && periodName && (
					<PeriodNameLayout>
						<PeriodName>
							{periodName}
							<Typography.Nbsp />
						</PeriodName>
					</PeriodNameLayout>
				)}
				<Box>
					{titleMode ? (
						<>
							<Expander ref={this.setExpanderRef}>{formattedValue}</Expander>
							<MaskedTriggerLayout>{trigger}</MaskedTriggerLayout>
						</>
					) : (
						trigger
					)}
				</Box>
			</TriggerInputLayout>
		);
	};

	renderWithFrame = () => {
		return <DateRangeLayout>{this.renderDateRange()}</DateRangeLayout>;
	};

	renderWithoutFrame = () => {
		const { modalMode, readonly, disabled, labelText, children } = this.props;

		return (
			<div>
				<TriggerLayout
					withLabelText={!!labelText}
					onClick={!readonly && !disabled ? this.handleOpenDateRangePicker : () => {}}>
					{children || this.renderDefaultTrigger()}
				</TriggerLayout>
				{!readonly && !disabled && modalMode ? this.renderWithDialog() : this.renderWithPopover()}
			</div>
		);
	};

	render() {
		const { frameMode, rootStyle, fullWidth } = this.props;

		return (
			<Root fullWidth={fullWidth} style={rootStyle}>
				{frameMode ? this.renderWithFrame() : this.renderWithoutFrame()}
			</Root>
		);
	}
}

const autoCorrectedDatePipe = createAutoCorrectedDatePipe('dd/mm/yyyy');
const MaskedTrigger = withMaskHOC(
	TextField,
	[
		/[0-3]/,
		/\d/,
		'.',
		/(0|1)/,
		/\d/,
		'.',
		/(1|2)/,
		/\d/,
		/\d/,
		/\d/,
		'—',
		/[0-3]/,
		/\d/,
		'.',
		/(0|1)/,
		/\d/,
		'.',
		/(1|2)/,
		/\d/,
		/\d/,
		/\d/,
	],
	{
		keepCharPositions: true,
		showMask: true,
		pipe: autoCorrectedDatePipe,
	},
);
const MaskedDateInput = withMaskHOC(TextField, [/[0-3]/, /\d/, '.', /(0|1)/, /\d/, '.', /(1|2)/, /\d/, /\d/, /\d/], {
	keepCharPositions: true,
	showMask: true,
	pipe: autoCorrectedDatePipe,
});

const XDateRangePicker = withTheme(DateRangePicker);

export { XDateRangePicker as DateRangePicker };
