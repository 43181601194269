import React from 'react';
import { Dispatch, bindActionCreators } from 'redux';

import api from '@api';
import { paymentsApi } from '@core/api/payments';
import { IAppState } from '@store';
import { getPropInSafe } from '@utils';
import withAutoUpdate from '@core/hocs/with-auto-update.hoc';
import {
	setBillingDisplayPathAction,
	resetBillingDisplayPathAction,
} from '@platform/actions/display-controller.actions';
import * as billingActions from '@platform/actions/billing.actions';
import { selectCurrentTenantID } from '@platform/selectors/context.selectors';
import * as billingSelectors from '@platform/selectors/billing.selectors';
import { selectTenant } from '@platform/selectors/tenant-account.selectors';
import { pathTo } from '../../billing-display-controller/paths';
import XSelectConditionsDisplay, {
	SelectConditionsDisplayProps as XSelectConditionsDisplayProps,
} from './select-conditions-display.view';

type SelectConditionsDisplayProps = {
	fetchTariffs?: (tenantID: number) => void;
	fetchTenantAccount?: (tenantID: number) => void;
	fetchTenantBills?: (tenantID: number) => void;
} & Partial<XSelectConditionsDisplayProps>;

type SelectConditionsDisplayState = {
	isSentToCMS: boolean;
	isFetching: boolean;
};

class SelectConditionsDisplay extends React.PureComponent<SelectConditionsDisplayProps, SelectConditionsDisplayState> {
	static displayName = 'SelectConditionsDisplay[Container]';
	state: SelectConditionsDisplayState = {
		isSentToCMS: false,
		isFetching: true,
	};
	isMountedComponent = false;

	componentDidMount() {
		const { tenantID, fetchTariffs } = this.props;
		this.isMountedComponent = true;

		fetchTariffs(tenantID);
		this.checkPaymentStatus(this.props);
	}

	componentDidUpdate(prevProps: SelectConditionsDisplayProps) {
		if (prevProps.tenantBills !== this.props.tenantBills) {
			this.checkPaymentStatus(this.props);
		}
	}

	componentWillUnmount() {
		this.isMountedComponent = false;
	}

	isBillInProgress = (props = this.props) => {
		const tenantBillsList = Object.keys(props.tenantBills).map(key => props.tenantBills[key]);
		const tenantBillStatus = tenantBillsList[0] && tenantBillsList[0].StatusCode;

		return tenantBillStatus === 'SENT_TO_PAYMENT_GATEWAY' || tenantBillStatus === 'WAITING_FOR_PAYMENT_CONFIRMATION';
	};

	checkPaymentStatus = (props: SelectConditionsDisplayProps) => {
		const { tenantBills, setPath } = props;

		if (this.isBillInProgress(props)) {
			const tenantBillsList = Object.keys(tenantBills).map(key => tenantBills[key]);
			const invoiceNumber = tenantBillsList[0] && tenantBillsList[0].ID.toString();

			this.setState({ isFetching: true });
			invoiceNumber &&
				api.invoicePack.financialDocument.fetchDocumentByNumber(invoiceNumber).then((invoice: Invoice) => {
					console.log('getInvoiceByNumber', invoice);
					if (invoice && Object.keys(invoice).length > 0) {
						const paymentID = getPropInSafe(invoice, o => o.Payments[0].ID, -1);

						paymentID > 0 &&
							paymentsApi.payments.fetchPaymentByID(paymentID).then((payment: Payment) => {
								console.log('getPaymentByID', payment);
								const paymentStateID = getPropInSafe(payment, o => o.PaymentState.ID, -1);
								const isSentToCMS = paymentStateID === 3; // SENT_TO_CMS
								const startedSyncWithCMS = paymentStateID === 6; // STARTED_SYNCH_WITH_CMS

								if (startedSyncWithCMS) {
									setPath(pathTo.legalEntityPaymentResult);
									return;
								}

								this.isMountedComponent &&
									this.setState({
										isSentToCMS,
										isFetching: false,
									});
							});
					}
				});
		} else {
			this.setState({ isFetching: false });
		}
	};

	render() {
		const { fetchTenantAccount, fetchTenantBills, fetchTariffs, isFetching, ...rest } = this.props;
		const { isSentToCMS } = this.state;

		return (
			<XSelectConditionsDisplay
				{...(rest as any)}
				isSentToCMS={isSentToCMS}
				isFetching={isFetching || this.state.isFetching}
			/>
		);
	}
}

const resetStatusAfterBillingAction = () => billingActions.setStatusAfterBillingAction(null);

const selectIsFecthing = (state: IAppState) =>
	billingSelectors.selectTariffsIsFetching(state) ||
	billingSelectors.selectTenantAccountIsFetching(state) ||
	billingSelectors.selectTenantBillsIsFetching(state);

function mapStateToProps(state: IAppState) {
	return {
		tenantID: selectCurrentTenantID(state),
		tariffList: billingSelectors.selectTariffListByAscRate(state),
		isFetching: selectIsFecthing(state),
		selectedTariff: billingSelectors.selectSelectedTariff(state),
		selectedTariffRate: billingSelectors.selectSelectedTariffRate(state),
		requestedTariff: billingSelectors.selectRequestedTariff(state),
		requestedTariffRate: billingSelectors.selectRequestedTariffRate(state),
		activeTariff: billingSelectors.selectActiveTariff(state),
		tenantBills: billingSelectors.selectTenantBills(state),
		billingServiceStatus: billingSelectors.selectBillingServiceStatus(state),
		tenantAccount: billingSelectors.selectTenantAccount(state),
		tenant: selectTenant(state),
	};
}

function mapDispatchToProps(dispatch: Dispatch<any>) {
	return bindActionCreators(
		{
			setPath: setBillingDisplayPathAction,
			resetPath: resetBillingDisplayPathAction,
			fetchTariffs: billingActions.fetchTariffsAction,
			selectTariff: billingActions.selectTariffAction,
			fetchTenantAccount: billingActions.fetchTenantAccountAction,
			fetchTenantBills: billingActions.fetchTenantBillsAction,
			revokeTenantBill: billingActions.revokeTenantBillAction,
			registerServiceRequest: billingActions.registerServiceRequestAction,
			createPaymentServiceRequest: billingActions.createPaymentServiceRequestAction,
			resetStatusAfterBilling: resetStatusAfterBillingAction,
			runForceSyncPaymentStatusEffect: billingActions.runForceSyncPaymentStatusEffectAction,
		},
		dispatch,
	);
}

export default withAutoUpdate<SelectConditionsDisplayProps>(
	mapStateToProps,
	mapDispatchToProps,
)({
	tenantAccountDidInvalidate: [
		billingSelectors.selectTenantAccountDidInvalidate,
		({ tenantID, fetchTenantAccount }) => fetchTenantAccount(tenantID),
	],
	billsDidInvalidate: [
		billingSelectors.selectTenantBillsDidInvalidate,
		({ tenantID, fetchTenantBills }) => fetchTenantBills(tenantID),
	],
})(SelectConditionsDisplay);
