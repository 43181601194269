import { createTypes } from '@flux';

type ActionTypes = {
	RUN_PROJECTS_INVALIDATION_EFFECT: string;
	SET_FILTER_BY_TEXT: string;
	SET_FILTER_BY_TENANT_ENTITIES: string;
	SET_FILTER_BY_BUSINESS_UNITS: string;
	SET_FILTER_BY_CLIENTS: string;
	SET_FILTER_BY_MANAGERS: string;
	SET_FILTER_BY_RELEVANCE: string;
	SET_FUNDS_REGISTERS_FILTER: string;
	RESET_FILTERS: string;
	SET_DATE_RANGE: string;
	SET_IS_INCLUDES_PLAN: string;
	SET_OPERATION_DATE_TYPE: string;
	SET_PROJECT_VIEW: string;
	SET_VIRTUAL_LIST_GROUP: string;
	SET_OPERATIONS_FILTER_BY_DERECTION: string;
	SET_EXPANDED_HIERARCHY_MAP: string;
	INVALIDATE_PROJECTS: string;
	INVALIDATE_OPERATIONS: string;
	FETCH_PROJECTS: string;
	FETCH_ALL_PROJECT_DYNAMIC: string;
	ADD_PROJECT: string;
	UPDATE_PROJECT: string;
	REMOVE_PROJECT: string;
	FETCH_DETAIL_PROJECT_DYNAMIC: string;
	FETCH_PROJECT_OPERATIONS: string;
	LINK_OPERATIONS_TO_PROJECT: string;
	SET_PROJECTS_DATE_RANGE: string;
	SET_PROJECTS_DATE_TYPE: string;
	SET_SORTING: string;
};

export const types = createTypes<ActionTypes>(
	[
		'RUN_PROJECTS_INVALIDATION_EFFECT',
		'SET_FILTER_BY_TEXT',
		'SET_FILTER_BY_TENANT_ENTITIES',
		'SET_FILTER_BY_BUSINESS_UNITS',
		'SET_FILTER_BY_CLIENTS',
		'SET_FILTER_BY_MANAGERS',
		'SET_FILTER_BY_RELEVANCE',
		'SET_FUNDS_REGISTERS_FILTER',
		'RESET_FILTERS',
		'SET_DATE_RANGE',
		'SET_IS_INCLUDES_PLAN',
		'SET_OPERATION_DATE_TYPE',
		'SET_PROJECT_VIEW',
		'SET_VIRTUAL_LIST_GROUP',
		'SET_OPERATIONS_FILTER_BY_DERECTION',
		'SET_EXPANDED_HIERARCHY_MAP',
		'INVALIDATE_PROJECTS',
		'INVALIDATE_OPERATIONS',
		'FETCH_PROJECTS',
		'FETCH_ALL_PROJECT_DYNAMIC',
		'ADD_PROJECT',
		'UPDATE_PROJECT',
		'REMOVE_PROJECT',
		'FETCH_DETAIL_PROJECT_DYNAMIC',
		'FETCH_PROJECT_OPERATIONS',
		'LINK_OPERATIONS_TO_PROJECT',
		'SET_PROJECTS_DATE_RANGE',
		'SET_PROJECTS_DATE_TYPE',
		'SET_SORTING',
	],
	'PROJECT_MANAGEMENT',
);
