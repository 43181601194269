import { createStaticAction } from '@flux';
import { types } from './types';

// чтобы избежать зацикливания экшенов
// runEnffect использовать где угодно
const runCounterpartiesInvalidationEffect = () => createStaticAction(types.RUN_COUNTERPARTIES_INVALIDATION_EFFECT)();

// invalidate использовать только в эффекте инвалидации этого модуля
const invalidateCounterparties = () => createStaticAction(types.INVALIDATE_COUNTERPARTIES)();

const runCounterpartyGroupsInvalidationEffect = () =>
	createStaticAction(types.RUN_COUNTERPARTY_GROUPS_INVALIDATION_EFFECT)();

const invalidateCounterpartyGroups = () => createStaticAction(types.INVALIDATE_COUNTERPARTY_GROUPS)();

export {
	invalidateCounterparties,
	invalidateCounterpartyGroups,
	runCounterpartiesInvalidationEffect,
	runCounterpartyGroupsInvalidationEffect,
};
