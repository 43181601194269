import { combineReducers } from 'redux';

import { mainProductsReducer, MainProductsState } from './main.reducer';

export type ProductsState = {
	main: MainProductsState;
};

const productsReducer = combineReducers<ProductsState>({
	main: mainProductsReducer,
});

export { productsReducer };
