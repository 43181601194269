// @ts-nocheck
import * as _ from 'underscore';
import { createSelector } from 'reselect';

import { IAppState } from '../../../core/store';
import { getPropInSafe } from '../../../core/libs/utils';
import { selectFrWithCMSCreatePaymentDraft } from '@funds-registers/selectors';

function selectInvoiceIsFetching(state: IAppState) {
	return state.invoice.selectedInvoce.isFetching;
}

function selectInvoice(state: IAppState) {
	return state.invoice.selectedInvoce.item;
}

function selectIncomeInvoice(state: IAppState) {
	return state.invoice.selectedInvoce.incomeInvoice.item;
}

const selectInvoiceAmountTotal = createSelector(
	(state: IAppState) => state.invoice.selectedInvoce.item,
	(invoice: Invoice) => {
		let total = 0;
		let totalTax = 0;

		invoice &&
			_.each(invoice.Items, item => {
				const taxRate = Math.abs(item.ItemTaxRate);
				totalTax += (item.ItemAmountTotal * taxRate) / (1 + taxRate);
				total += item.ItemAmountTotal;
			});

		return total;
	},
);

const selectIsPaymentSupported = createSelector(
	(state: IAppState) => selectFrWithCMSCreatePaymentDraft(state),
	(state: IAppState) => state.invoice.selectedInvoce.item,
	(frMap: { [prop: string]: FundsRegister }, invoice: Invoice) => {
		const invoiceLegalEntityID = getPropInSafe(invoice, o => o.LegalEntity.ID, -1);
		const frList = Object.keys(frMap).map(key => frMap[key]);
		const filtered = frList.filter(item => {
			const itemLegalEntityID = getPropInSafe(item, o => o.LegalEntity.ID, -1);

			if (invoiceLegalEntityID === itemLegalEntityID) {
				return true;
			}
		});

		return filtered.length > 0;
	},
);

function selectInvoiceFormIsReadonly(state: IAppState): boolean {
	return state.invoice.selectedInvoce.formIsReadonly;
}

export {
	selectInvoiceIsFetching,
	selectInvoice,
	selectIncomeInvoice,
	selectInvoiceAmountTotal,
	selectIsPaymentSupported,
	selectInvoiceFormIsReadonly,
};
