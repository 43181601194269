import {
	StoreAsyncItem,
	checkAsyncAction,
	createAsyncInitialState,
	createReducer,
	invalidateStateFromAction,
} from '@flux';
import { types } from '@transactions/actions/types';
import { createDefaultPeriod } from '@utils/date';

export type MainTransactionsState = {
	dateRange: DateRange;
	operationTypesFilter: Array<string>;
	terminals: StoreAsyncItem<Array<PosTerminal>>;
	terminalsFilter: Array<string>;
	textFilter: string;
	transactions: StoreAsyncItem<Array<PosTerminalTransaction>>;
	turnovers: StoreAsyncItem<Array<PosTerminalTransactionTurnover>>;
};

const initialState: MainTransactionsState = {
	dateRange: createDefaultPeriod('month'),
	operationTypesFilter: [],
	terminals: createAsyncInitialState([]),
	terminalsFilter: [],
	textFilter: '',
	transactions: createAsyncInitialState([]),
	turnovers: createAsyncInitialState([]),
};

const mainTransactionsReducer = createReducer<MainTransactionsState>(initialState, {
	[types.FETCH_TERMINALS]: (action: AsyncAction<Array<PosTerminal>>, state) => {
		return {
			terminals: checkAsyncAction(action, state.terminals),
		};
	},
	[types.FETCH_TRANSACTIONS]: (action: AsyncAction<Array<PosTerminalTransaction>>, state) => {
		return {
			transactions: checkAsyncAction(action, state.transactions),
		};
	},
	[types.FETCH_TURNOVERS]: (action: AsyncAction<Array<PosTerminalTransactionTurnover>>, state) => {
		return {
			turnovers: checkAsyncAction(action, state.turnovers),
		};
	},
	[types.IMPORT_TERMINALS]: (action: AsyncAction, state) => {
		return {
			terminals: invalidateStateFromAction(action, state.terminals),
		};
	},
	[types.IMPORT_TRANSACTIONS]: (action: AsyncAction, state) => {
		return {
			transactions: invalidateStateFromAction(action, state.transactions),
			turnovers: invalidateStateFromAction(action, state.turnovers),
		};
	},
	[types.RESET_FILTERS]: () => {
		return {
			operationTypesFilter: [],
			terminalsFilter: [],
		};
	},
	[types.SET_DATE_RANGE]: (action: StaticAction<DateRange>, state) => {
		return {
			dateRange: action.value,
			turnovers: invalidateStateFromAction(action, state.turnovers),
			transactions: invalidateStateFromAction(action, state.transactions),
		};
	},
	[types.SET_FILTER_BY_OPERATION_TYPES]: (action: StaticAction<Array<string>>) => {
		return {
			operationTypesFilter: action.value,
		};
	},
	[types.SET_FILTER_BY_TERMINALS]: (action: StaticAction<Array<string>>) => {
		return {
			terminalsFilter: action.value,
		};
	},
	[types.SET_FILTER_BY_TEXT]: (action: StaticAction<string>) => {
		return {
			textFilter: action.value,
		};
	},
});

export default mainTransactionsReducer;
