export enum SortingDirection {
	ASC = 'asc',
	DESC = 'desc',
}

export type SortingSelector<T> = {
	fn: (item: T) => any;
	direction: SortingDirection;
	isDate?: boolean;
};

function sort<T>(sourceItems: Array<T>, selectors: Array<SortingSelector<T>>): Array<T> {
	const items = [...sourceItems];

	const compare = (a: T, b: T, selector: SortingSelector<T>) => {
		const { fn, direction, isDate } = selector;
		const isDesc = direction === SortingDirection.DESC;
		const one = isDesc ? 1 : -1;
		const two = isDesc ? -1 : 1;

		if (!isDate) {
			if (fn(a) === fn(b)) return 0;
			return fn(a) < fn(b) ? one : two;
		} else {
			if (fn(a) === fn(b)) return 0;
			const [dayA, monthA, yearA] = fn(a).split('-').map(Number);
			const [dayB, monthB, yearB] = fn(b).split('-').map(Number);
			const isBefore = yearB * 10000 + monthB * 100 + dayB * 1 - (yearA * 10000 + monthA * 100 + dayA * 1) > 0;

			return isBefore ? one : two;
		}
	};

	const sort = (items: Array<T>, selectors: Array<SortingSelector<T>>) => {
		items.sort((a, b) => {
			let idx = 0;

			while (idx < selectors.length) {
				const selector = selectors[idx];
				const result = compare(a, b, selector);

				if (result !== 0) return result;

				idx++;
			}

			return 0;
		});
	};

	sort(items, selectors);

	return items;
}

function sortDescBy<T>(
	items: Array<T>,
	selectors: Array<{
		fn: (item: T) => any;
		isDate?: boolean;
	}>,
): Array<T> {
	return sort(
		items,
		selectors.map(x => ({ ...x, direction: SortingDirection.DESC })),
	);
}

function sortAscBy<T>(
	items: Array<T>,
	selectors: Array<{
		fn: (item: T) => any;
		isDate?: boolean;
	}>,
): Array<T> {
	return sort(
		items,
		selectors.map(x => ({ ...x, direction: SortingDirection.ASC })),
	);
}

export { sort, sortDescBy, sortAscBy };
