import React, { memo, useEffect, useState } from 'react';

import { useAutoFetch, useMapDispatch, useMapState } from '@core/flux';
import { useTenantID } from '@core/hooks';
import { mainPaymentsActionsPack } from '@payments/actions';
import { mainFundsRegistersActionsPack } from '@funds-registers/actions';
import { mainFundsRegistersSelectorsPack } from '@funds-registers/selectors';
import { selectAsyncLastAccountStatementImports } from '@integrations/selectors';
import { mainIntegrationsActionsPack } from '@integrations/actions';
import { invalidateLastAccountStatementImports } from '@integrations/actions/invalidators';
import { XBalanceTotalIndicator } from './balance-total-indicator.view';
import { selectBalanceIndicatorRight } from '@dashboard/selectors';

export type BalanceTotalIndicatorProps = {};

const BalanceTotalIndicator: React.FC<BalanceTotalIndicatorProps> = memo(() => {
	const tenantID = useTenantID();
	const [isOpen, setIsOpen] = useState(false);
	const [
		hasViewRight,
		isBalanceHiddenFetching,
		isFundsRegisterStatisticsFetching,
		isBalanceHidden,
		balance,
		fundsRegisters,
		fundsRegisterStatistics,
		accountStatementImportFailed,
		isAnyInProgress,
		balanceIndicatorRight,
	] = useMapState([
		mainFundsRegistersSelectorsPack.selectFundsRegistersRight.view,
		mainFundsRegistersSelectorsPack.selectAsyncIsBalanceHidden.selectIsFetching,
		mainFundsRegistersSelectorsPack.selectAsyncFundsRegisterStatistics.selectIsFetching,
		mainFundsRegistersSelectorsPack.selectAsyncIsBalanceHidden.selectItem,
		mainFundsRegistersSelectorsPack.selectBalanceTotal,
		mainFundsRegistersSelectorsPack.selectFundsRegisters,
		mainFundsRegistersSelectorsPack.selectActualFundsRegisterStatistics,
		mainFundsRegistersSelectorsPack.selectFirstAccountStatementImportFailed,
		mainFundsRegistersSelectorsPack.selectIsAnyAccountStatementImportInProgress,
		selectBalanceIndicatorRight.view,
	]);
	const [
		fetchIsBalanceHidden,
		fetchFundsRegisterStatistics,
		refresh,
		setIsBalanceHidden,
		setFundsRegisterID,
		setFundsRegisterIDForRefresh,
		setFundsRegisterIDForAutoInsert,
		fetchLastAccountStatementImports,
		invalidate,
	] = useMapDispatch([
		mainFundsRegistersActionsPack.fetchIsBalanceHidden,
		mainFundsRegistersActionsPack.fetchFundsRegisterStatistics,
		mainFundsRegistersActionsPack.refreshCmsAccounts,
		mainFundsRegistersActionsPack.setIsBalanceHidden,
		mainPaymentsActionsPack.setFundsRegisterID,
		mainFundsRegistersActionsPack.setFundsRegisterIDForRefresh,
		mainFundsRegistersActionsPack.setFundsRegisterIDForAutoInsert,
		mainIntegrationsActionsPack.fetchLastAccountStatementImports,
		invalidateLastAccountStatementImports,
	]);
	const isFetching = isBalanceHiddenFetching || isFundsRegisterStatisticsFetching;

	useAutoFetch({
		selector: mainFundsRegistersSelectorsPack.selectAsyncIsBalanceHidden.selectDidInvalidate,
		fetch: () => hasViewRight && tenantID > 0 && fetchIsBalanceHidden(tenantID),
	});

	useAutoFetch({
		selector: mainFundsRegistersSelectorsPack.selectAsyncFundsRegisterStatistics.selectDidInvalidate,
		fetch: () => hasViewRight && fetchFundsRegisterStatistics(),
	});

	useAutoFetch({
		selector: selectAsyncLastAccountStatementImports.selectDidInvalidate,
		fetch: () => hasViewRight && fetchLastAccountStatementImports(),
	});

	useEffect(() => {
		if (!hasViewRight) return;
		const timerID = setInterval(() => {
			invalidate();
		}, UPDATE_INTERVAL);

		return () => clearInterval(timerID);
	}, []);

	const handleRequestToggleBalanceVisibility = () => setIsBalanceHidden(tenantID, !isBalanceHidden);

	const handleRequestToggleOpen = () => setIsOpen(!isOpen);

	const handleRequestClose = () => setIsOpen(false);

	if (!(hasViewRight && balanceIndicatorRight)) return null;

	return (
		<XBalanceTotalIndicator
			isOpen={isOpen}
			isAnyInProgress={isAnyInProgress}
			isFetching={isFetching}
			balance={balance}
			isBalanceHidden={isBalanceHidden}
			fundsRegisters={fundsRegisters}
			fundsRegisterStatistics={fundsRegisterStatistics}
			accountStatementImportFailed={accountStatementImportFailed}
			setFundsRegisterID={setFundsRegisterID}
			setFundsRegisterIDForRefresh={setFundsRegisterIDForRefresh}
			setFundsRegisterIDForAutoInsert={setFundsRegisterIDForAutoInsert}
			onRequestToggleBalanceVisibility={handleRequestToggleBalanceVisibility}
			onRequestRefresh={refresh}
			onRequestToggleOpen={handleRequestToggleOpen}
			onRequestClose={handleRequestClose}
		/>
	);
});

const UPDATE_INTERVAL = 60000;

export { BalanceTotalIndicator };
