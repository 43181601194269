import React from 'react';
import { createContext } from 'react';
import * as _ from 'underscore';

import { IBaseControl } from '../';
import { ValidationHandler, FormContext, FormContextType } from './form';
import { FormState } from '.';

type BaseProps = IBaseControl.props<any> & IBaseControl.handlers;

type WrappedComponentProps = {
	id?: string;
	onFocus?: Function;
	onBlur?: Function;
	resetIfNotSelected?: boolean;
} & Partial<BaseProps>;

type WithFormInputProps<S = any> = {
	name: string;
	required?: boolean;
	maxLength?: number;
	isFullObject?: boolean; // параметр для сборки связанных объектов - по id или BriefObject
	errorValidations?: Array<ValidationHandler<S>>; // массив функций, принимающих объект {formObject} и возвращающих true, если поле валидно, и false, если ошибка есть
	errorAsyncValidators?: Array<ValidationHandler<S>>;
	informationValidations?: Array<ValidationHandler<S>>;
	successValidations?: Array<ValidationHandler<S>>;
	warningValidations?: Array<ValidationHandler<S>>;
	errorMsgs?: Array<string>;
	errorAsyncMsgs?: Array<string>;
	warningMsg?: Array<string>;
	informationMsgs?: Array<string>;
	successMsgs?: Array<string>;
	warningMsgs?: Array<string>;
	enableOnBlurValidation?: boolean;
	enableOnChangeValidation?: boolean;
	createObjectText?: string;
	updateObjectText?: string;
	briefObjectName?: string; // если указан, то компонент будет контролировать соответствующий BriefObject
	specifyPropText?: string;
	withoutFocusOnInvalid?: boolean;
	resetIfNotSelected?: boolean;
	notSetReadonlyIfFieldComplete?: boolean;
	silentErrors?: boolean;
	getUpdateObjectText?: (formObject: S, editedBriefObject: Partial<S>) => React.ReactNode;
	onChange?: (...args) => void;
	onFocus?: Function;
	onBlur?: Function;
};

export type FormInputProps<S = any> = WrappedComponentProps & WithFormInputProps<S>;

type State = {
	isValid: boolean;
	isFocused: boolean;
	errorText: string;
	warningText: string;
	successText: string;
	informationText: React.ReactNode;
};

function withFormInput<P extends WrappedComponentProps, S>(
	WrappedComponent: React.ComponentType<any>,
	handleRequiredValidation: (props: WithFormInputProps<S>, context, wrappedElem, forcedValue?) => boolean,
	setValue: (args: Array<any>, props: WithFormInputProps<S>, context, wrappedElem) => void,
	getValue: (props: WithFormInputProps<S>, context, wrappedElem) => any,
	enableCreateObject?: boolean,
	enableUpdateObject?: boolean,
): React.ComponentClass<P & WithFormInputProps<S>> {
	return class WithFormInput extends React.Component<P & WithFormInputProps<S>, State> {
		static contextType = FormContext;
		static defaultProps = {
			errorValidations: [],
			errorAsyncValidators: [],
			errorMsgs: [],
			errorAsyncMsgs: [],
			getUpdateObjectText: () => '',
		} as any;
		context!: FormContextType<any>;
		state = {
			isValid: !this.props.required,
			isFocused: false,
			errorText: '',
			warningText: this.props.warningText || '',
			successText: '',
			informationText: '',
		};
		prevValue = null;
		public wrappedElem;
		private onChangeValidationUpdateTimeout = null;
		isMountedComponent = false;

		componentDidMount() {
			this.isMountedComponent = true;
			this.context.addFormValidation(this.validate);
			this.props.enableOnChangeValidation && this.runOnChangeValidation(this.props);
		}

		componentDidUpdate() {
			const formZone = this.getFormZone();
			const value = getValue(this.props, this.context, this.wrappedElem);

			if (!formZone && this.props.disabled && this.state.errorText) {
				this.updateState({
					isValid: !this.props.required,
					errorText: '',
				});
			}

			if (value !== this.prevValue && this.prevValue) {
				this.props.enableOnChangeValidation && this.runOnChangeValidation(this.props);
			}

			this.prevValue = value || null;
		}

		componentWillUnmount() {
			this.isMountedComponent = false;
			this.context.removeFormValidation(this.validate);
		}

		updateState = (state: Partial<Pick<State, keyof State>>, callback?: () => void) => {
			if (!this.isMountedComponent) return;
			this.setState(state as State, callback);
		};

		getFormZone = () => {
			return this.context.formObject
				? this.props.briefObjectName
					? this.context.formObject[this.props.briefObjectName]
					: this.context.formObject[this.props.name]
				: null;
		};

		private setWrappedElem = elem => {
			if (!this.wrappedElem && elem) {
				this.forceUpdate();
			}

			this.wrappedElem = this.wrappedElem || elem;
		};

		private requiredValidation(forcedValue?) {
			return handleRequiredValidation(this.props, this.context, this.wrappedElem, forcedValue);
		}

		private maxLengthValidation() {
			const { name, maxLength } = this.props;
			const value = this.context.formObject[name];

			if (!value) return true;

			return value.length <= maxLength;
		}

		private getValidation(objectToValidate, validations: Array<ValidationHandler<S>>, msgs: Array<string>) {
			let index = -1;
			_.each(validations, (v, i) => {
				const result = v(objectToValidate);

				if ((typeof result === 'boolean' || typeof result === 'undefined') && !result) {
					index = i;
				}
			});
			const text = (msgs || [])[index];
			return index !== -1 ? { index, text } : null;
		}

		private validate = objectToValidate => {
			const {
				withoutFocusOnInvalid,
				errorAsyncValidators = [],
				errorAsyncMsgs = [],
				silentErrors,
				maxLength,
			} = this.props;
			const errorValidations: Array<ValidationHandler<S>> = this.props.errorValidations
				? [...this.props.errorValidations]
				: [];
			const errorMsgs: Array<string> = this.props.errorMsgs ? [...this.props.errorMsgs] : [];

			if (this.props.required) {
				errorValidations.push(() => this.requiredValidation());
				const idx = errorValidations.length - 1;

				errorMsgs[idx] = errorMsgs[idx] || REQUIRED_FIELD;
			}

			if (maxLength > 0) {
				errorValidations.push(() => this.maxLengthValidation());
				const idx = errorValidations.length - 1;

				errorMsgs[idx] = errorMsgs[idx] || `${MAX_LENGTH_FIELD} (${maxLength})`;
			}

			const error = this.getValidation(objectToValidate, errorValidations, errorMsgs);
			const errorText = error ? (silentErrors ? ' ' : error.text || INCORRECT_FIELD) : '';
			const isSyncValid = !error;
			const asyncValidators = [...errorAsyncValidators] as Array<(o: FormState<any>) => boolean | Promise<boolean>>;

			if (!withoutFocusOnInvalid && !isSyncValid && this.wrappedElem) {
				this.wrappedElem.focus();
			}

			this.updateState({
				isValid: isSyncValid,
				errorText,
			});

			if (!isSyncValid) {
				return false;
			}

			if (isSyncValid && errorAsyncValidators.length === 0) {
				return true;
			}

			if (isSyncValid && errorAsyncValidators.length > 0) {
				const asyncQueue = asyncValidators.map(fn => {
					return new Promise(resolve => {
						resolve(fn(objectToValidate));
					});
				});

				return Promise.all(asyncQueue).then(results => {
					const idx = results.findIndex(result => !result);
					const isAsyncValid = idx === -1;

					if (isAsyncValid) {
						this.updateState({ isValid: true });
					} else {
						this.updateState({
							isValid: false,
							errorText: errorAsyncMsgs[idx] || '-',
						});
					}

					return isAsyncValid;
				});
			}
		};

		runOnChangeValidation = (props: P & WithFormInputProps<S>) => {
			if (props.enableOnChangeValidation && props.errorValidations && props.errorValidations.length > 0) {
				const value = getValue(props, this.context, this.wrappedElem);

				if (this.state.isFocused) {
					const { formObject, editedBriefObjects } = this.context;
					const obj = { formObject, editedBriefObjects };
					const errorValidators = [...props.errorValidations].reverse();
					const errorMsgs = [...props.errorMsgs].reverse();
					const idxOfErrorValidation = errorValidators.findIndex(v => v(obj) === false);
					const isValid = value ? idxOfErrorValidation === -1 : true;
					const errorText = !isValid ? errorMsgs[idxOfErrorValidation] || INCORRECT_FIELD : '';

					if (isValid !== this.state.isValid || errorText !== this.state.errorText) {
						if (this.onChangeValidationUpdateTimeout) {
							clearTimeout(this.onChangeValidationUpdateTimeout);
						}

						this.onChangeValidationUpdateTimeout = setTimeout(() => {
							this.updateState({ isValid, errorText }, () => {
								clearTimeout(this.onChangeValidationUpdateTimeout);
							});
						}, 100);
					}
				}
			}
		};

		runOnBlurValidation = (props: P & WithFormInputProps<S>) => {
			const hasValidators = props?.errorValidations.length > 0 || props?.errorAsyncValidators.length > 0;

			if (props.enableOnBlurValidation && hasValidators) {
				const value = getValue(props, this.context, this.wrappedElem);

				if (!value) {
					this.updateState({ errorText: '' });
					return;
				}

				if (!this.state.isFocused) {
					const { formObject, editedBriefObjects } = this.context;
					const obj = { formObject, editedBriefObjects };

					this.validate(obj);
				}
			}
		};

		runOnBlurValidationDebounced = _.debounce(this.runOnBlurValidation, DEBOUNCE_TIMEOUT);

		extendedHandleChange = (...args) => {
			if (enableUpdateObject) {
				const value = args[0];
				const term = args[1];

				if (value && value.ID > 0) {
					this.prevValue = { ...args[0] };
				}

				if (this.prevValue && !value && term) {
					args[0] = { ...this.prevValue };
				}

				if (!term) {
					this.prevValue = null;
				}
			}

			this.state.isValid = true;
			if (this.context.isFetching) {
				return;
			}
			if (this.props.onChange) {
				this.props.onChange(...args);
			}
			if (this.props.briefObjectName && !this.context.editedBriefObjects[this.props.briefObjectName]) {
				this.context.addBriefObject(this.props.briefObjectName, {
					ID: this.context.formObject[this.props.briefObjectName]
						? this.context.formObject[this.props.briefObjectName].ID
						: -1,
				});
			}

			setValue(args, this.props, this.context, this.wrappedElem);
			this.context.handleObjectChange(this.context.formObject);
		};

		private getCreateObjectText = (): string => {
			if (!enableCreateObject) {
				return '';
			}
			const elem = this.context.editedBriefObjects[this.props.name];

			return elem && elem.ID === -1 ? this.props.createObjectText || 'Объект будет добавлен в справочник' : '';
		};

		private getUpdateObjectText = (): string => {
			const { getUpdateObjectText } = this.props;

			if (!enableUpdateObject) return '';

			const editedBriefObject = this.context.editedBriefObjects[this.props.name];
			const formObject = this.context.formObject[this.props.name];
			const text =
				(formObject && editedBriefObject && getUpdateObjectText(formObject, editedBriefObject)) ||
				this.props.updateObjectText ||
				'';

			return editedBriefObject && editedBriefObject.ID > 0 ? (text as string) : '';
		};

		contextValue: FormElementContextType = {
			setWrappedElem: this.setWrappedElem,
		};

		render() {
			const {
				required,
				onChange,
				onFocus,
				onBlur,
				isFullObject,
				name,
				errorValidations,
				errorAsyncValidators,
				informationValidations,
				successValidations,
				warningValidations,
				errorMsgs,
				errorAsyncMsgs,
				warningMsg,
				informationMsgs,
				successMsgs,
				warningMsgs,
				enableOnChangeValidation,
				enableOnBlurValidation,
				notSetReadonlyIfFieldComplete,
				createObjectText,
				briefObjectName,
				specifyPropText,
				silentErrors,
				...rest
			} = this.props;
			let isReadonly = false;
			const formObject = this.context.isFetching ? {} : this.context.formObject || {};
			const providedProps: WrappedComponentProps = {
				value: getValue(this.props, this.context, this.wrappedElem),
				onChange: this.extendedHandleChange,
				id: name + ((formObject.ID as string) || ''),
				name,
				onFocus: (...args) => {
					onFocus && onFocus(...args);

					this.updateState({ isFocused: true });
				},
				onBlur: (...args) => {
					onBlur && onBlur(...args);

					this.updateState(
						{
							isFocused: false,
						},
						() => {
							enableOnBlurValidation && this.runOnBlurValidationDebounced(this.props);
						},
					);
				},
			};

			let customWarningText;
			const obj = {
				formObject: this.context.formObject,
				editedBriefObjects: this.context.editedBriefObjects,
			};

			if (warningValidations && warningValidations.length > 0) {
				const isValid = warningValidations.every(v => v(obj));

				if (!isValid) {
					const index = warningValidations.findIndex(v => !v(obj));

					customWarningText = (warningMsg && warningMsg[index]) || specifyPropText || '';
				}
			}

			if (briefObjectName) {
				// если значение в briefObject невалидно или briefObject создается, то разрешаем его редактировать
				const briefObject = this.context.formObject && this.context.formObject[briefObjectName];
				const isValid = errorValidations ? errorValidations.every(v => v(obj)) : true;

				if (
					briefObject &&
					briefObject.ID > 0 &&
					this.requiredValidation(briefObject[name]) &&
					isValid &&
					!notSetReadonlyIfFieldComplete
				) {
					isReadonly = true;
				}

				// если поле невалидно, то показываем warning
				const isBriefObjectExist =
					(briefObject && briefObject.ID > 0) || this.context.editedBriefObjects[briefObjectName];

				if (isBriefObjectExist && !this.requiredValidation()) {
					customWarningText = specifyPropText || '';
				}
			}

			if (enableCreateObject) {
				providedProps.resetIfNotSelected = false;
			}

			providedProps.errorText = this.state.isValid ? '' : this.state.errorText;
			providedProps.warningText = this.state.warningText || customWarningText;
			providedProps.informationText = this.state.informationText;
			providedProps.successText = this.state.successText || this.getCreateObjectText() || this.getUpdateObjectText();

			return (
				<FormElementContext.Provider value={this.contextValue}>
					<WrappedComponent
						{...(rest as any)}
						{...providedProps}
						readonly={isReadonly || rest.readonly}
						required={required}
						debounce={DEBOUNCE_TIMEOUT} // experimental
					/>
				</FormElementContext.Provider>
			);
		}
	};
}

export type FormElementContextType = {
	setWrappedElem: (intsance: React.ComponentType) => void;
};

const FormElementContext = createContext<FormElementContextType>(null);

const REQUIRED_FIELD = 'Это обязательное поле';
const MAX_LENGTH_FIELD = 'Превышена максимальная длина';
const INCORRECT_FIELD = 'Некорректно заполненное поле';
const DEBOUNCE_TIMEOUT = 150;

export { FormElementContext, withFormInput };
