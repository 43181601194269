import styled, { css } from 'styled-components';

import { IconButtonProps } from './icon-button';

type RootProps = {} & Pick<IconButtonProps, 'variant' | 'shadowed' | 'isSilentHover' | 'isSilentDisabled'>;

const Root = styled.button<RootProps>`
	position: relative;
	background-color: transparent;
	border: none;
	cursor: pointer;
	border-radius: 2px;
	user-select: none;
	overflow: hidden;
	font-size: 0;
	transition: background-color 0.2s ease-in-out;

	${p =>
		p.shadowed &&
		css`
			${p.theme.fn.createBoxShadow(1)}
			background-color: ${p.theme.iconButton.backgroundColor};
		`}

	${p =>
		p.variant === 'rounded' &&
		css`
			border-radius: 50%;
		`}

	${p => css`
		color: ${p.theme.iconButton.textColor};

		&[disabled] {
			cursor: default;
		}
	`}

	${p =>
		!p.isSilentHover &&
		css`
			&:hover {
				background-color: ${p.theme.iconButton.backgroundColorHover};
			}
		`}

	${p =>
		!p.isSilentDisabled &&
		css`
			&[disabled] {
				background-color: ${p.theme.iconButton.backgroundColorDisabled};
				color: ${p.theme.iconButton.textColorDisabled};
			}

			&[disabled] i {
				color: ${p.theme.iconButton.textColorDisabled};
			}
		`}
`;

type ContentLayoutProps = {
	size: number;
};

const ContentLayout = styled.span<ContentLayoutProps>`
	position: relative;
	min-width: 32px;
	min-height: 32px;
	display: inline-flex;
	justify-content: center;
	align-items: center;

	${p => css`
		min-width: ${p.size}px;
		min-height: ${p.size}px;
	`}
`;

export { Root, ContentLayout };
