import { useMemo } from 'react';

type UseSafeSpaceOptions<T> = {
	fn: () => T;
	fallback?: T;
	executeWhen: () => boolean;
	deps: Array<any>;
};

function useSafeSpace<T>(options: UseSafeSpaceOptions<T>): T {
	const { fn, fallback = {}, executeWhen, deps = [] } = options;
	const execute = () => {
		const value = executeWhen() ? fn() : fallback;

		return value;
	};
	const value = useMemo(() => execute(), [...deps]);

	return value as T;
}

export { useSafeSpace };
