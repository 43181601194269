import { combineReducers } from 'redux';

import mainPaymentRulesReducer, { MainPaymentRulesState } from './main.reducer';

export type PaymentRulesState = {
	main: MainPaymentRulesState;
};

const paymentRulesReducer = combineReducers<PaymentRulesState>({
	main: mainPaymentRulesReducer,
});

export default paymentRulesReducer;
