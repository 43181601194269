import { createTypes } from '@flux';

type ActionTypes = {
	INVALIDATE_CLIENTS: string;
	INVALIDATE_CLIENT_GROUPS: string;
	SET_FILTER_BY_TEXT: string;
	SET_FILTER_BY_CLIENT_TYPES: string;
	SET_FILTER_BY_CLIENT_GROUPS: string;
	SET_FILTER_BY_MANAGERS: string;
	RESET_FILTERS: string;
	FETCH_CLIENTS: string;
	ADD_CLIENT: string;
	UPDATE_CLIENT: string;
	REMOVE_CLIENT: string;
};

export const types = createTypes<ActionTypes>(
	[
		'INVALIDATE_CLIENTS',
		'INVALIDATE_CLIENT_GROUPS',
		'SET_FILTER_BY_TEXT',
		'SET_FILTER_BY_CLIENT_TYPES',
		'SET_FILTER_BY_CLIENT_GROUPS',
		'SET_FILTER_BY_MANAGERS',
		'RESET_FILTERS',
		'FETCH_CLIENTS',
		'ADD_CLIENT',
		'UPDATE_CLIENT',
		'REMOVE_CLIENT',
	],
	'CLIENTS',
);
