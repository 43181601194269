import api from '@api';
import { getAuthParams } from '@config';
import { paymentsApi } from '@core/api/payments';
import { BillingProvider } from '@platform/model/billing';
import { emitActionMessage } from '@shared/actions/action-message.actions';
import * as statuses from '@shared/constants/statuses';
import store from '@store';
import { getCMSGUID, getPropInSafe } from '@utils';
import {
	FETCH_BILLING_SERVICE_STATUS,
	INIT_FAST_BILL,
	RUN_FAST_BILL_RESULT_EFFECT,
	RUN_FORCE_SYNC_PAYMENT_STATUS_EFFECT,
	methods as billingMethods,
	createPaymentServiceRequestAction,
	fetchBillingServiceStatusAction,
	fetchTenantAccountAction,
	fetchTenantBillsAction,
	setStatusAfterBillingAction,
} from '../actions/billing.actions';
import { setBillingDisplayPathAction } from '../actions/display-controller.actions';
import { methods as cmsMethods } from '../actions/external-system-account.actions';
import {
	FETCH_TENANT_BY_ID,
	UPDATE_TENANT,
	fetchTenantByIDAction,
	updateTenantAction,
} from '../actions/tenant-account.actions';
import { pathTo } from '../components/tenant-account/billing/billing-display-controller/paths';
import { selectTenantBills, selectTenantBusinessService } from '../selectors/billing.selectors';
import { selectCMSList } from '../selectors/external-system-account.selectors';
import { selectTenant } from '../selectors/tenant-account.selectors';

function createFastBillEffect() {
	let isEffectRunning = false;
	let frID = -1;

	return store => next => action => {
		if (action.type === INIT_FAST_BILL) {
			const initAction = action as StaticAction<{ email: string; frID: number }>;
			const tenant = selectTenant(store.getState());
			frID = initAction.value.frID;

			if (!tenant.ContactEMail) {
				const updatedTenant = {
					...tenant,
					ContactEMail: initAction.value.email,
				};

				store.dispatch(updateTenantAction(updatedTenant));
				isEffectRunning = true;
			} else {
				createPayment(frID);
			}
		}

		if (isEffectRunning) {
			if (action.type === UPDATE_TENANT) {
				const updateTenantAction = action as AsyncAction<Tenant>;
				const tenant = selectTenant(store.getState());

				if (updateTenantAction.status === 'RECEIVE') {
					store.dispatch(fetchTenantByIDAction(tenant.StorageID));
				}
			}

			if (action.type === FETCH_TENANT_BY_ID) {
				const fetchtenantAction = action as AsyncAction<Tenant>;

				if (fetchtenantAction.status === 'RECEIVE') {
					createPayment(frID);
					isEffectRunning = false;
				}
			}
		}

		function createPayment(fundsRegisterID: number) {
			const businessService = selectTenantBusinessService(store.getState());
			const businessServiceID = businessService?.ID || getAuthParams().businessServiceID;
			const tenant = selectTenant(store.getState());
			const tenantID = tenant.StorageID;

			api.billingPack.billing.fetchTenantBills({ businessServiceID, tenantID }).then(tenantBills => {
				const [firsBill] = tenantBills;
				const billID = firsBill?.ID;

				if (billID) {
					store.dispatch(createPaymentServiceRequestAction(billID, BillingProvider.SEENECO, { frID: fundsRegisterID }));
				} else {
					store.dispatch(emitActionMessage('Не найден счёт', 'warning'));
				}
			});
		}

		next(action);
	};
}

function createFastBillResultEffect() {
	let isEffectRunning = false;

	return store => next => action => {
		if (action.type === FETCH_BILLING_SERVICE_STATUS) {
			const fetchBillinStatusAction = action as AsyncAction<BillingServiceStatus>;
			if (fetchBillinStatusAction.status === 'RECEIVE') {
				const billingServiceStatus = fetchBillinStatusAction.response;
				const isBillInProgress =
					billingServiceStatus.BillStatusCode === 'WAITING_FOR_PAYMENT_CONFIRMATION' ||
					billingServiceStatus.BillStatusCode === 'SENT_TO_PAYMENT_GATEWAY';

				isBillInProgress &&
					checkBillingServiceStatus((isSuccess: boolean) => {
						store.dispatch(setStatusAfterBillingAction({ status: isSuccess ? statuses.SUCCESS : statuses.FAILED }));
					});
			}
		}

		// После возврата из банка после подписания платежа
		if (!isEffectRunning && action.type === RUN_FAST_BILL_RESULT_EFFECT) {
			isEffectRunning = true;
			console.log('FastBillResultEffect');
			const runFastBillResultAction = action as StaticAction<string>;
			const invoiceUID = runFastBillResultAction.value;

			cmsMethods.fetchCashManagementSystemsMethod().then((CMSList: Array<CashManagementSystem>) => {
				api.invoicePack.financialDocument.fetchDocumentByUID(invoiceUID).then((invoice: Invoice) => {
					const paymentID = getPropInSafe(invoice, o => o.Payments[0].ID, -1);
					const bankName = getPropInSafe(invoice, o => o.FundsRegister.Bank.Name, '');
					const CMSGUID = getCMSGUID(bankName);
					const CMS = CMSList.find(CMS => CMS.SubsystemInstanceGUID === CMSGUID);
					const CMSID = getPropInSafe(CMS, o => o.ID, -1);

					api.fundsPack.cashManagementSystem
						.synchronizePaymentStatus({
							paymentID,
							cashManagementSystemID: CMSID,
						})
						.then((response: CMSPaymentStatusResponse) => {
							const status = response.Status;
							const authorizationSuccess = response.AuthorizationSuccess;
							const serviceAvailable = response.ServiceAvailable;
							const isFailed =
								status === statuses.FAILED ||
								status === statuses.FINISHED_WITH_WARNING ||
								authorizationSuccess === false ||
								serviceAvailable === false;
							console.log('synchronizePaymentStatus', response);

							if (isFailed) {
								store.dispatch(
									setStatusAfterBillingAction({
										status: statuses.FAILED,
										message:
											authorizationSuccess === false
												? `Ошибка авторизации вызова при обращении к банку. `
												: serviceAvailable === false
												? `Сервис на стороне банка недоступен. `
												: response.Message ||
												  'Неизвестная ошибка при попытке synchronizePaymentStatus. Обратитесь в службу поддержки.',
									}),
								);
								isEffectRunning = false;
							} else {
								updateTenantBillStatus(() => {
									checkPaymentStatus(paymentID, (isSuccess: boolean) => {
										store.dispatch(
											setStatusAfterBillingAction({ status: isSuccess ? statuses.SUCCESS : statuses.FAILED }),
										);
										isEffectRunning = false;
									});
								});
							}
						});
				});
			});
		}

		next(action);
	};
}

function createForceSyncPaymentEffect() {
	let isEffectRunning = false;

	return store => next => action => {
		next(action);

		if (action.type === RUN_FORCE_SYNC_PAYMENT_STATUS_EFFECT) {
			isEffectRunning = true;
			console.log('ForceSyncPaymentEffect');
			const CMSList = selectCMSList(store.getState());
			const tenantBills = selectTenantBills(store.getState());
			const tenantBillsList = Object.keys(tenantBills).map(key => tenantBills[key]);
			const invoiceNumber = tenantBillsList[0].ID.toString();

			api.invoicePack.financialDocument.fetchDocumentByNumber(invoiceNumber).then((invoice: Invoice) => {
				const paymentID = getPropInSafe(invoice, o => o.Payments[0].ID, -1);
				const bankName = getPropInSafe(invoice, o => o.FundsRegister.Bank.Name, '');
				const CMSGUID = getCMSGUID(bankName);
				const CMS = CMSList.find(CMS => CMS.SubsystemInstanceGUID === CMSGUID);
				const CMSID = getPropInSafe(CMS, o => o.ID, -1);

				api.fundsPack.cashManagementSystem
					.synchronizePaymentStatus({
						paymentID,
						cashManagementSystemID: CMSID,
					})
					.then((response: CMSPaymentStatusResponse) => {
						const status = response.Status;
						const authorizationSuccess = response.AuthorizationSuccess;
						const serviceAvailable = response.ServiceAvailable;
						const isFailed =
							status === statuses.FAILED ||
							status === statuses.FINISHED_WITH_WARNING ||
							authorizationSuccess === false ||
							serviceAvailable === false;
						const tenant = selectTenant(store.getState());
						console.log('synchronizePaymentStatus', response);

						store.dispatch(fetchTenantBillsAction(tenant.StorageID));
						store.dispatch(setBillingDisplayPathAction(pathTo.legalEntityPaymentResult));

						if (isFailed) {
							store.dispatch(
								setStatusAfterBillingAction({
									status: statuses.FAILED,
									message:
										authorizationSuccess === false
											? `Ошибка авторизации вызова при обращении к банку. `
											: serviceAvailable === false
											? `Сервис на стороне банка недоступен. `
											: response.Message ||
											  'Неизвестная ошибка при попытке synchronizePaymentStatus. Обратитесь в службу поддержки.',
								}),
							);
						} else {
							updateTenantBillStatus(() => {
								checkPaymentStatus(paymentID, (isSuccess: boolean) => {
									store.dispatch(
										setStatusAfterBillingAction({ status: isSuccess ? statuses.SUCCESS : statuses.FAILED }),
									);
								});
							});
						}
					});
			});
		}
	};
}

let checkPaymentStatusTimeout = null;
let checkBillingServiceStatusTimeout = null;

function checkPaymentStatus(paymentID: number, cb: (isSuccess: boolean) => void) {
	checkPaymentStatusTimeout && clearTimeout(checkPaymentStatusTimeout);

	paymentsApi.payments.fetchPaymentByID(paymentID).then((payment: Payment) => {
		const paymentStateID = getPropInSafe(payment, o => o.PaymentState.ID, -1);
		const isImplementedByCMS = paymentStateID === 4; // IMPLEMENTED_BY_CMS
		const isFailedByCMS = !payment || Object.keys(payment).length === 0 || paymentStateID === 5; // FAILED_BY_CMS

		if (isImplementedByCMS) {
			checkBillingServiceStatus(cb);
			return;
		}

		if (isFailedByCMS) {
			cb(false);
			return;
		}

		store.dispatch(setStatusAfterBillingAction({ status: statuses.IN_PROGRESS }));

		checkPaymentStatusTimeout = setTimeout(() => {
			clearTimeout(checkPaymentStatusTimeout);
			checkPaymentStatus(paymentID, cb);
		}, 20000);
	});
}

function checkBillingServiceStatus(cb: (isSuccess: boolean) => void) {
	const tenant = selectTenant(store.getState());

	checkBillingServiceStatusTimeout && clearTimeout(checkBillingServiceStatusTimeout);

	billingMethods.fetchBillingServiceStatusMethod(tenant.StorageID).then((status: BillingServiceStatus) => {
		const isSuccess = status.AccessGranted && !status.Warning && status.BillStatusCode === 'PAID';
		const isFailed = status.BillStatusCode === 'PAYMENT_FAILED';

		if (isSuccess) {
			cb(true);
			store.dispatch(fetchBillingServiceStatusAction(tenant.StorageID) as any);
			store.dispatch(fetchTenantAccountAction(tenant.StorageID) as any);
			store.dispatch(fetchTenantBillsAction(tenant.StorageID) as any);
			return;
		}

		if (isFailed) {
			cb(false);
			return;
		}

		store.dispatch(setStatusAfterBillingAction({ status: statuses.IN_PROGRESS }));

		checkBillingServiceStatusTimeout = setTimeout(() => {
			clearTimeout(checkBillingServiceStatusTimeout);
			checkBillingServiceStatus(cb);
		}, 20000);
	});
}

function updateTenantBillStatus(cb: () => void) {
	const tenant = selectTenant(store.getState());
	billingMethods.fetchTenantBillsMethod(tenant.StorageID).then(bills => {
		const billStatusCode = bills[0] && bills[0].StatusCode;
		const billID = bills[0] && bills[0].ID;
		if (billID > 0 && billStatusCode === 'SENT_TO_PAYMENT_GATEWAY') {
			billingMethods.updateTenantBillStatusMethod(billID, 'WAITING_FOR_PAYMENT_CONFIRMATION').then(cb);
		} else {
			cb();
		}
	});
}

const runFastBillEffect = createFastBillEffect();
const runFastBillResultEffect = createFastBillResultEffect();
const runForceSyncPaymentEffect = createForceSyncPaymentEffect();

export { runFastBillEffect, runFastBillResultEffect, runForceSyncPaymentEffect };
