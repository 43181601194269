import { combineReducers } from 'redux';

import currencyListReducer, { ICurrencyListState } from './reducers/currency-list.reducers';
import errorMessageReducer, { IErrorMessageState } from './reducers/error-message.reducers';
import actionMessageReducer, { IMessageState } from './reducers/action-message.reducers';
import UIReducer, { IUIState } from './reducers/ui.reducers';
import CurrencyFormater from './components/currency-formater';
import { ThemeState, themeReducer } from './reducers/theme.reducer';

export type SharedState = {
	currencyList: ICurrencyListState;
	errorMessage: IErrorMessageState;
	actionMessage: IMessageState;
	ui: IUIState;
	theme: ThemeState;
};

const sharedReducer = combineReducers<SharedState>({
	currencyList: currencyListReducer,
	errorMessage: errorMessageReducer,
	actionMessage: actionMessageReducer,
	ui: UIReducer,
	theme: themeReducer,
});

export default {
	reducer: sharedReducer,
	components: {
		CurrencyFormater,
	},
	actions: {},
};
