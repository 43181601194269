import styled, { css } from 'styled-components';

type RootProps = {
	type: 'warning' | 'alarm' | 'success' | 'default';
	margin: string;
};

const Root = styled.div<RootProps>`
	width: 100%;
	height: auto;
	padding: 18px;
	border: 1px solid transparent;
	border-radius: 2px;

	${p => css`
		color: ${p.theme.palette.text};
	`}

	${p =>
		p.type === 'default' &&
		css`
			border-color: ${p.theme.palette.stealth};
		`}

	${p =>
		p.type === 'warning' &&
		css`
			background-color: rgba(255, 193, 7, 0.15);
			border-color: ${p.theme.palette.warning};
		`}
		
	${p =>
		p.type === 'alarm' &&
		css`
			background-color: rgba(255, 58, 72, 0.15);
			border-color: ${p.theme.palette.alarm};
		`}
		
	${p =>
		p.type === 'success' &&
		css`
			background-color: rgba(38, 208, 109, 0.15);
			border-color: ${p.theme.palette.accent};
		`}

${p =>
		p.margin &&
		css`
			margin: ${p.margin};
		`}
`;

interface IMessageLayout {
	fontSize: string;
}

const MessageLayout = styled.div`
	font-size: 14px;
	line-height: 1.4;
	text-align: left;

	${(props: IMessageLayout) =>
		props.fontSize &&
		`
		font-size: ${props.fontSize};
	`}
`;

export { Root, MessageLayout };
