import { createStaticAction, createAsyncAction } from '@flux';
import store from '@store';
import { useLocalStorageValue } from '@utils/storage';
import { buildTLongArray } from '@utils';
import { Layout } from '@ui/grid-layout';
import { FetchAccountChartDynamicsOptions, FetchCounterpartiesTopOptions, FetchOperationsOptions } from '@core/api/pl';
import { OperationDateType } from '@pl/model';
import { mainOperationsSelectorsPack } from '@pl/selectors';
import { emitActionMessage } from '@shared/actions/action-message.actions';
import { GridLayoutCardKey, getDefaultCardsExpanded } from '@dashboard/shared/utils';
import { mainBplSelectorsPack } from '@dashboard/selectors';
import { types } from '@dashboard/actions/types';
import { reportsApi } from '@core/api/reports';

const actions = {
	setDateRange: (dateRange: DateRange) => createStaticAction(types.SET_DATE_RANGE)(dateRange),
	setIsIncludesVAT: (value: boolean) => createStaticAction(types.SET_IS_INCLUDES_VAT)(value),
	fetchDashboardData: createAsyncAction(
		types.FETCH_DASHBOARD_DATA,
		(api, _, __, options: FetchDashboardOptions) => {
			const { dateRange, tenantEntitiesIDs, fundsRegistersIDs, projectsIDs, businessUnitsIDs, includingVat, dateType } =
				options;
			const request: DashboardRequest = {
				...new reportsApi.package.DashboardRequest(),
				DateStart: dateRange.dateStart,
				DateFinish: dateRange.dateEnd,
				TenantLegalEntitiesIDs: buildTLongArray(tenantEntitiesIDs),
				FundsRegistersIDs: buildTLongArray(fundsRegistersIDs),
				ProjectsIDs: buildTLongArray(projectsIDs),
				BusinessUnitsIDs: buildTLongArray(businessUnitsIDs),
				IncludingVat: includingVat,
				DateType: dateType,
			};

			return new Promise<DashboardResponse>(resolve => {
				api.reportsPack.reports.fetchDashboard(request).then(result => {
					resolve(result);
				});
			});
		},
		{
			isValidSelector: mainBplSelectorsPack.selectAsyncDashboardData.selectIsValid,
			isFetchingSelector: mainBplSelectorsPack.selectAsyncDashboardData.selectIsFetching,
		},
	) as (options: FetchDashboardOptions) => void,
	fetchDynamicData: createAsyncAction(
		types.FETCH_DYNAMIC_DATA,
		(api, getState, _, options: DynamicOptions) => {
			const { count, ...onlyRootOptions } = options;
			const state = getState();
			const primaryDateType = mainOperationsSelectorsPack.selectDateType(state);
			const cashflowDateAggregation = primaryDateType === OperationDateType.CASHFLOW;
			const inConsolidation = true;
			const inDashboardConsolidation = true;
			const { dateRange } = options;
			const aciDynamicOptions: FetchAccountChartDynamicsOptions = {
				...onlyRootOptions,
				cashflowDateAggregation,
				inConsolidation,
				inDashboardConsolidation,
			};
			const cpTopOptions: FetchCounterpartiesTopOptions = {
				...options,
				cashflowDateAggregation,
				inConsolidation,
				inDashboardConsolidation,
			};

			return new Promise<FetchDynamicDataReturnType>(resolve => {
				Promise.all([
					api.plPack.plOperation.fetchAccountChartDynamics(aciDynamicOptions),
					api.plPack.plOperation.fetchCounterpartiesTop(cpTopOptions),
					api.fundsPack.fundsRegister.fetchFundsRegisterStatistics(dateRange),
				]).then(result => {
					resolve({
						aciSeries: result[0].Series as Array<PLOperationAccountsChartItemSeries>,
						cpTop: result[1],
						fundsRegisterStatistics: result[2],
					});
				});
			});
		},
		{
			isValidSelector: mainBplSelectorsPack.selectAsyncAciDynamicList.selectIsValid,
			isFetchingSelector: mainBplSelectorsPack.selectAsyncAciDynamicList.selectIsFetching,
		},
	),
	fetchCashflowForecast: createAsyncAction(
		types.FETCH_CASHFLOW_FORECAST,
		(api, _, __, dateRange: DateRange) => {
			return new Promise<CashflowForecast>(resolve => {
				api.fundsPack.fundsRegister.fetchCashflowForecast({ dateRange }).then(result => {
					resolve(result);
				});
			});
		},
		{
			isValidSelector: mainBplSelectorsPack.selectAsyncCashflowForecast.selectIsValid,
			isFetchingSelector: mainBplSelectorsPack.selectAsyncCashflowForecast.selectIsFetching,
		},
	) as (dateRange: DateRange) => void,
	fetchDetalizationData: createAsyncAction(
		types.FETCH_DETALIZATION_DATA,
		(api, getState, dispatch, options: DetalizationOptions) => {
			return new Promise<FetchDetalizationDataReturnType>(resolve => {
				const primaryDateType = mainOperationsSelectorsPack.selectDateType(getState());
				const accrual = primaryDateType === OperationDateType.ACCRUAL;
				const inConsolidation = true;
				const extendedOptions: FetchOperationsOptions = {
					...options,
					accrual,
					inConsolidation,
				};

				dispatch(actions.setDetalizationOptions(options));
				api.plPack.plOperation.fetchOperationsBriefs(extendedOptions).then(result => {
					resolve({ plOperations: result });
				});
			});
		},
	),
	setDetalizationOptions: (options: DetalizationOptions) => createStaticAction(types.SET_DETALIZATION_OPTIONS)(options),
	setFilterByCompanies: (idMap: Record<number, boolean>) => createStaticAction(types.SET_FILTER_BY_COMPANIES)(idMap),
	setFilterByFundsRegisters: (idMap: Record<number, boolean>) =>
		createStaticAction(types.SET_FILTER_BY_FUNDS_REGISTERS)(idMap),
	setFilterByBusinessUnits: (idMap: Record<number, boolean>) =>
		createStaticAction(types.SET_FILTER_BY_BUSINESS_UNITS)(idMap),
	setFilterByProjects: (idMap: Record<number, boolean>) => createStaticAction(types.SET_FILTER_BY_PROJECTS)(idMap),
	setSettingsMode: (value: boolean) => {
		value && store.dispatch(emitActionMessage(`Перетащите виджеты или измените их размер 😉`, 'success'));
		return createStaticAction(types.SET_SETTINGS_MODE)(value);
	},
	changeGridLayout: (value: Array<Layout>) => createStaticAction(types.CHANGE_GRID_LAYOUT)(value),
	saveGridLayout: (value: Array<Layout>) => {
		const [_, setValue] = useLocalStorageValue(GRID_LAYOUT_FLAG);

		setValue(value);
		return createStaticAction(types.SAVE_GRID_LAYOUT)(value);
	},
	setTourOpen: (isOpen: boolean) => createStaticAction(types.SET_TOUR_OPEN)(isOpen),
	setIsCardExpanded: (key: GridLayoutCardKey) => {
		const [value, setValue] = useLocalStorageValue(types.SET_IS_CARD_EXPANDED);
		const map = value || getDefaultCardsExpanded();

		map[key] = !map[key];
		setValue(map);
		return createStaticAction(types.SET_IS_CARD_EXPANDED)(map);
	},
	setBusinessUnitFilter: (value: CodeNaturalKey) => createStaticAction(types.SET_BUSINESS_UNIT_ANALYTICS_FILTER)(value),
	setProjectFilter: (value: CodeNaturalKey) => createStaticAction(types.SET_PROJECT_ANALYTICS_FILTER)(value),
	setCashflowItemFilter: (value: CodeNaturalKey) => createStaticAction(types.SET_CAHSFLOW_ITEM_ANALYTICS_FILTER)(value),
	setCounterpartyFilter: (value: CodeNaturalKey) => createStaticAction(types.SET_COUNTERPARTY_ANALYTICS_FILTER)(value),
	fetchAnalyticsDetalizationData: createAsyncAction(
		types.FETCH_ANALYTICS_DETALIZATION_DATA,
		(api, getState, _, options: DetalizationOptions) => {
			return new Promise<FetchDetalizationDataReturnType>(resolve => {
				const primaryDateType = mainOperationsSelectorsPack.selectDateType(getState());
				const accrual = primaryDateType === OperationDateType.ACCRUAL;
				const inConsolidation = true;
				const extendedOptions: FetchOperationsOptions = {
					...options,
					accrual,
					inConsolidation,
				};

				api.plPack.plOperation.fetchOperationsBriefs(extendedOptions).then(result => {
					resolve({ plOperations: result });
				});
			});
		},
	) as (options: DetalizationOptions) => void,
	setAnalyticsDetalizationOptions: (options: DetalizationOptions) =>
		createStaticAction(types.SET_ANALYTICS_DETALIZATION_OPTIONS)(options),
	setAnalyticsDetalizationIDs: (IDs: Array<number>) => createStaticAction(types.SET_ANALYTICS_DETALIZATION_IDS)(IDs),
	setIsAnalyticsDetalizationOpen: (value: boolean) =>
		createStaticAction(types.SET_IS_ANALYTICS_DETALIZATION_OPEN)(value),
};

export const GRID_LAYOUT_FLAG = `${types.SAVE_GRID_LAYOUT}:06-07-2021`;

export type FetchDynamicDataReturnType = {
	aciSeries: Array<PLOperationAccountsChartItemSeries>;
	cpTop: CounterpartiesTopResponse;
	fundsRegisterStatistics: Array<FundsRegisterStatistics>;
};

export type DynamicOptions = FetchAccountChartDynamicsOptions & FetchCounterpartiesTopOptions;

export type FetchDetalizationDataReturnType = {
	plOperations: Array<PLOperationBrief>;
};

export type DetalizationOptions = FetchOperationsOptions;

export type FetchDashboardOptions = {
	dateRange: DateRange;
	tenantEntitiesIDs?: Array<number>;
	fundsRegistersIDs?: Array<number>;
	businessUnitsIDs?: Array<number>;
	projectsIDs?: Array<number>;
	includingVat: boolean;
	dateType: 'CASHFLOW' | 'ACCRUAL';
};

export const mainBplActionsPack = {
	actions,
};
