import React, { memo } from 'react';

import { useMapDispatch, useMapState } from '@core/flux';
import { selectCmsAccountsTextFilter } from '@integrations/selectors';
import { mainIntegrationsActionsPack } from '@integrations/actions';
import { XTextFilter } from './cms-accounts-text-filter.view';

type TextFilterProps = {};

const TextFilter: React.FC<TextFilterProps> = memo(() => {
	const [searchText] = useMapState([selectCmsAccountsTextFilter]);
	const [setSearchText] = useMapDispatch([mainIntegrationsActionsPack.setCmsAccountsTextFilter]);

	return <XTextFilter value={searchText} onChange={setSearchText} />;
});

export { TextFilter as CmsAccountsTextFilter };
