import React from 'react';

import { IconBase, IconBaseProps } from './base';

export type CheckIconProps = IconBaseProps;

const CheckIcon: React.FC<CheckIconProps> = props => {
	return (
		<IconBase {...props}>
			<svg
				stroke='currentColor'
				fill='currentColor'
				strokeWidth='0'
				viewBox='0 0 24 24'
				xmlns='http://www.w3.org/2000/svg'>
				<path d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z'></path>
			</svg>
		</IconBase>
	);
};

export { CheckIcon };
