import { Store } from 'redux';

import { IAppState } from '@store';
import { invalidateCashflowItems } from '@cashflow-items/actions/invalidators';
import { types as cashflowItemsTypes } from '@cashflow-items/actions/types';

function askMergeCashflowItemsInvalidator(store: Store<IAppState>, action: StoreAction) {
	const { dispatch } = store;
	const invalidateMap = {
		[cashflowItemsTypes.RUN_CASHFLOW_ITEMS_INVALIDATION_EFFECT]: true,
	};

	if (invalidateMap[action.type]) {
		dispatch(invalidateCashflowItems());
	}
}

function createInvalidatorEffect() {
	return (store: Store<IAppState>) => (next: (action: StoreAction) => void) => (action: StoreAction) => {
		next(action);
		askMergeCashflowItemsInvalidator(store, action);
	};
}

const runInvalidatorEffect = createInvalidatorEffect();

export { runInvalidatorEffect };
