import createAction from '../../../core/libs/action-creator';

import {
	selectCurrencyListDidInvalidate,
	selectCurrencyLisIsFetching,
	selectCurrencyListIsLoaded,
} from '../selectors/currency-list';

const FETCH_CURRENCIES = '[SHARED]: FETCH_CURRENCIES';

function fetchCurrencies(api, getState, dispatch) {
	return new Promise(resolve => {
		api.currencyServiceClient.getCurrencies((res: Array<Currency>) => {
			resolve(res);
		});
	});
}

const fetchCurrenciesAction = createAction(FETCH_CURRENCIES, fetchCurrencies, {
	isValidSelector: state => selectCurrencyListIsLoaded(state) && !selectCurrencyListDidInvalidate(state),
	isFetchingSelector: selectCurrencyLisIsFetching,
});

export { FETCH_CURRENCIES, fetchCurrenciesAction };

export default fetchCurrenciesAction;
