import { lighten, darken, fade } from '@ui/basic-theme/color-manipulator';

const BASE_FONT_SIZE = 14;
const pxToRem = (size: number | string) => `${((parseFloat(`${size}`) / BASE_FONT_SIZE) * 1).toFixed(3)}rem`;
const getMeasureProp = (v: string | number, fallback?: any) =>
	typeof v === 'string' ? v : typeof v === 'number' ? `${v}px` : !v ? fallback || v : v;

function makeBoxShadows(elevation: number) {
	const map = {
		1: `box-shadow: 0 1px 4px rgba(0, 0, 0, 0.08), 0 1px 2px rgba(0, 0, 0, 0.08);`,
		2: `box-shadow: 0 1px 6px rgba(0, 0, 0, 0.09), 0 1px 4px rgba(0, 0, 0, 0.09);`,
		3: `box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1), 0 1px 6px rgba(0, 0, 0, 0.1);`,
		4: `box-shadow: 0 1px 10px rgba(0, 0, 0, 0.11), 0 1px 8px rgba(0, 0, 0, 0.11);`,
		5: `box-shadow: 0 1px 12px rgba(0, 0, 0, 0.12), 0 1px 10px rgba(0, 0, 0, 0.12);`,
		6: `box-shadow: 0 1px 14px rgba(0, 0, 0, 0.13), 0 1px 12px rgba(0, 0, 0, 0.13);`,
		7: `box-shadow: 0 1px 16px rgba(0, 0, 0, 0.14), 0 1px 14px rgba(0, 0, 0, 0.14);`,
		8: `box-shadow: 0 1px 18px rgba(0, 0, 0, 0.15), 0 1px 16px rgba(0, 0, 0, 0.15);`,
		9: `box-shadow: 0 1px 20px rgba(0, 0, 0, 0.16), 0 1px 18px rgba(0, 0, 0, 0.16);`,
		10: `box-shadow: 0 1px 22px rgba(0, 0, 0, 0.18), 0 1px 20px rgba(0, 0, 0, 0.18);`,
	};

	return map[elevation] || '';
}

export { BASE_FONT_SIZE, pxToRem, getMeasureProp, lighten, darken, fade, makeBoxShadows };
