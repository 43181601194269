function createNotificationMessage(message: React.ReactNode, type: IActionMessageType = 'default'): IActionMessage {
	return {
		type,
		receiveAt: Date.now(),
		message,
	};
}

export {
	createNotificationMessage,
};
