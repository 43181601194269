import { runInvalidatorEffect } from './invalidator.effect';


const invoicesEffects = [
	runInvalidatorEffect,
];

export {
	invoicesEffects,
};
