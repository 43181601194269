export enum ThemeBrand {
	SEENECO = 'seeneco',
	SBERBANK = 'sberbank',
	OPENBANK = 'openbank',
	SOVCOMBANK = 'sovcombank',
}

export enum ThemeMode {
	LIGHT = 'light',
	DARK = 'dark',
}

export type ThemeIdentifier = [ThemeBrand, ThemeMode];

export type ThemesByModesMap = Record<ThemeMode, Theme>;

export type ThemesByBrandsMap = Record<ThemeBrand, ThemesByModesMap>;

export type WithThemeProps = { theme?: Theme };

export type ShadowElevation = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;

type Palette = {
	accent: string;
	warning: string;
	alarm: string;
	text: string;
	textContrast: string;
	label: string;
	hint: string;
	stealth: string;
	background: string;
	space: string;
	canvas: string;
	border: string;
	positive: string;
	negative: string;
	neutral: string;
	superMuted: string;
};

type AppMenuTheme = {
	logoBackgroundColor: string;
	backgroundColor: string;
	color: string;
	activeIconColor: string;
	activeTextColor: string;
	dividerColor: string;
	boxShadow: string;
};

type ButtonColorTheme = {
	textColor: string;
	backgroundColor: string;
	backgroundColorHover: string;
	backgroundColorFocus: string;
	textColorDisabled: string;
	backgroundColorDisabled: string;
};

type ButtonTheme = {
	borderRadius: string;
	contained: {
		accent: ButtonColorTheme;
		light: ButtonColorTheme;
	};
	text: {
		accent: ButtonColorTheme;
		muted: ButtonColorTheme;
		dark: ButtonColorTheme;
	};
	outlined: {
		accent: ButtonColorTheme;
		dark: ButtonColorTheme;
	};
};

type IconButtonTheme = {
	textColor: string;
	textColorDisabled: string;
	backgroundColor: string;
	backgroundColorHover: string;
	backgroundColorDisabled: string;
};

type AlertColorTheme = {
	textColor: string;
	backgroundColor: string;
	borderColor: string;
};

type AlertTheme = {
	borderRadius: string;
	default: AlertColorTheme;
	warning: AlertColorTheme;
	alarm: AlertColorTheme;
	success: AlertColorTheme;
};

type MaterialCardTheme = {
	backgroundColor: string;
};

type LinkTheme = {
	textColor: string;
	textColorHover: string;
	textColorFocus: string;
};

type ChartTheme = {
	infinityPalette: Array<string>;
	positive: string;
	positiveLight: string;
	negative: string;
	negativeLight: string;
	neutral: string;
	neutralLight: string;
	gridLineColor: string;
	labelColor: string;
	scrollbarBackgroundColor: string;
	trackBackgroundColor: string;
};

type TooltipTheme = {
	backgroundColor: string;
	textColor: string;
	$boxShadow?: string;
};

type BadgeColorTheme = {
	backgroundColor: string;
	textColor: string;
};

type BadgeTheme = {
	gray: BadgeColorTheme;
	green: BadgeColorTheme;
	orange: BadgeColorTheme;
	red: BadgeColorTheme;
	yellow: BadgeColorTheme;
};

type VirtualTableTheme = {
	borderColor: string;
};

type SegmentedBarTheme = {
	backgroundColor: string;
	activeBackgroundColor: string;
	disabledTextColor: string;
	activeTextColor: string;
	activeDisabledTextColor: string;
};

type OverlayTheme = {
	backgroundColor: string;
};

type DialogTheme = {
	borderRadius: string;
	header: {
		fontSize: string;
	};
};

export type Theme = {
	isDarkMode: boolean;
	isDefaultBrand: boolean;
	whiteLabelLogo: {
		bfm: string;
		invoice: string;
	};
	fontFamily: string;
	fontSize: string;
	lineHeight: string;
	palette: Palette;
	appMenu: AppMenuTheme;
	button: ButtonTheme;
	iconButton: IconButtonTheme;
	alert: AlertTheme;
	materialCard: MaterialCardTheme;
	link: LinkTheme;
	chart: ChartTheme;
	tooltip: TooltipTheme;
	badge: BadgeTheme;
	virtualTable: VirtualTableTheme;
	segmentedBar: SegmentedBarTheme;
	overlay: OverlayTheme;
	dialog: DialogTheme;
	fn: {
		createBoxShadow: (elevation: ShadowElevation) => string;
		createInsetBoxShadow: (elevation: ShadowElevation) => string;
		pxToRem: (value: number) => string;
		onCreateGlobalStyle?: () => Promise<boolean>;
	};
};
