import React from 'react';

import {
	Root,
	DividerTop,
	DividerBottom,
	ContentLayout,
	IconLayout,
	SecondIconLayout,
	TextLayout,
	Title,
	Text,
} from './styled';
import { HighLightedText } from '@core/ui/highlighted-text';
import { Typography } from '@core/ui/typography';

type DrawerSearchLinkProps = {
	title?: string;
	text?: string;
	preText?: string;
	searchText?: string;
	textColor?: 'primary' | 'secondary';
	icon?: React.ReactElement<any>;
	secondIcon?: React.ReactElement<any>;
	iconScale?: number;
	disabled?: boolean;
	isActive?: boolean;
	render?: () => React.ReactElement<any>;
	withGroupHeader?: boolean;
	textMaxWidth?: string;
	onClick?: () => void;
};

const DrawerSearchLink: React.FC<DrawerSearchLinkProps> = props => {
	const {
		title,
		preText,
		text,
		searchText = '',
		textColor,
		icon,
		secondIcon,
		iconScale,
		disabled,
		isActive,
		render,
		withGroupHeader,
		textMaxWidth,
		onClick,
	} = props;

	const handleClick = (e: React.MouseEvent) => {
		!disabled && onClick && onClick();
		e.stopPropagation();
	};

	return (
		<Root disabled={disabled} isActive={isActive} onClick={handleClick} withGroupHeader={withGroupHeader}>
			<DividerTop />
			{typeof render === 'function' ? (
				<ContentLayout>{render()}</ContentLayout>
			) : (
				<ContentLayout>
					{icon && <IconLayout iconScale={iconScale}>{icon}</IconLayout>}
					<TextLayout textMaxWidth={textMaxWidth}>
						<Title>
							<HighLightedText value={title} query={searchText} />
						</Title>
						{Boolean(text) && (
							<Text color={textColor}>
								{preText}
								<Typography.Nbsp />
								<HighLightedText value={text} query={searchText} />
								{secondIcon && <SecondIconLayout>{secondIcon}</SecondIconLayout>}
							</Text>
						)}
					</TextLayout>
				</ContentLayout>
			)}
			<DividerBottom />
		</Root>
	);
};

export { DrawerSearchLink };
