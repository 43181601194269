import { combineReducers } from 'redux';

import mainProjectPaymentRulesReducer, { MainProjectPaymentRulesState } from './main.reducer';


export type ProjectPaymentRulesState = {
	main: MainProjectPaymentRulesState;
};

const projectPaymentRulesReducer = combineReducers<ProjectPaymentRulesState>({
	main: mainProjectPaymentRulesReducer,
});

export default projectPaymentRulesReducer;
