import React from 'react';
import { Dispatch, bindActionCreators } from 'redux';

import { IAppState } from '../../../../../../core/store';
import { getDrawer } from '../../../../../../core/libs/utils';
import withAutoUpdate from '../../../../../../core/hocs/with-auto-update.hoc';
import {
	setBillingDisplayPathAction,
	resetBillingDisplayPathAction,
} from '../../../../actions/display-controller.actions';
import { fetchTenantAccountAction, fetchTenantBillsAction } from '../../../../actions/billing.actions';
import { selectCurrentTenantID } from '../../../../selectors/context.selectors';
import { selectBillingDisplayPath } from '../../../../selectors/display-controller.selectors';
import {
	selectTenantAccountDidInvalidate,
	selectRequestedTariff,
	selectTenantBillsDidInvalidate,
} from '../../../../selectors/billing.selectors';
import XBillingWizard from './billing-wizard.view';

interface IProps {
	tenantID?: number;
	requestedTariff?: Tariff;
	path?: string;

	fetchTenantAccount?: (tenantID: number) => void;
	fetchTenantBills?: (tenantID: number) => void;
	setPath?: (path: string) => void;
	resetPath?: () => void;
}

class BillingWizard extends React.PureComponent<IProps, any> {
	static displayName = 'BillingWizard[Container]';

	render() {
		const {
			path,
			requestedTariff,

			setPath,
			resetPath,
		} = this.props;

		return <XBillingWizard path={path} requestedTariff={requestedTariff} setPath={setPath} resetPath={resetPath} />;
	}
}

function mapStateToProps(state: IAppState) {
	return {
		tenantID: selectCurrentTenantID(state),
		requestedTariff: selectRequestedTariff(state),
		path: selectBillingDisplayPath(state),
	};
}

function mapDispatchToProps(dispatch: Dispatch<any>) {
	return bindActionCreators(
		{
			fetchTenantAccount: fetchTenantAccountAction,
			fetchTenantBills: fetchTenantBillsAction,
			setPath: setBillingDisplayPathAction,
			resetPath: resetBillingDisplayPathAction,
		},
		dispatch,
	);
}

const BillingWizardContainer = withAutoUpdate<IProps>(
	mapStateToProps,
	mapDispatchToProps,
)({
	tenantAccountDidInvalidate: [selectTenantAccountDidInvalidate, props => props.fetchTenantAccount(props.tenantID)],
	tenantBillsDidInvalidate: [selectTenantBillsDidInvalidate, props => props.fetchTenantBills(props.tenantID)],
})(BillingWizard);

function openBillingWizard() {
	getDrawer().open({
		content: <BillingWizardContainer />,
	});
}

export { openBillingWizard };
export default BillingWizardContainer;
