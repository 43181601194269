import styled, { css } from 'styled-components';

import { Theme } from '@theme';
import { lighten } from '@utils/color';

const Root = styled.div`
	display: inline-block;
	position: relative;
`;

const TriggerLayout = styled.div`
	display: block;
`;

const DatePickerLayout = styled.div`
	position: relative;
	display: inline-flex;
	flex-flow: row wrap;
	justify-content: center;
	align-items: center;
	padding: 10px 0;

	& .rdr-Day {
		transform: scale(1) !important;
	}
`;

const createDatePickerTheme = (theme: Theme) => {
	return {
		DateRange: {
			display: 'inline-flex',
			flexFlow: 'row nowrap',
			justifyContent: 'flex-start',
			alignItems: 'flex-start',
			width: 'auto',
			maxWidth: '900px',
			borderRadius: '0',
			backgroundColor: 'transparent',
		},
		Calendar: {
			width: '268px',
			padding: '0 15px 0 15px',
			backgroundColor: theme.palette.canvas,
		},
		Day: {
			width: '34px',
			height: '34px',
			fontWeight: '400',
			fontSize: '12px',
			lineHeight: '1.25',
			display: 'inline-flex',
			flexFlow: 'row wrap',
			justifyContent: 'center',
			alignItems: 'center',
			backgroundColor: theme.palette.canvas,
			color: theme.palette.text,
		},
		Weekday: {
			width: '34px',
			height: '34px',
			fontSize: '12px',
			fontWeight: '400',
			lineHeight: '1.25',
			marginBottom: '0',
			display: 'inline-flex',
			flexFlow: 'row wrap',
			justifyContent: 'center',
			alignItems: 'center',
			color: theme.palette.text,
		},
		DayHover: {
			backgroundColor: lighten(theme.palette.accent, 0.4),
			color: theme.palette.textContrast,
		},
		DaySelected: {
			backgroundColor: theme.palette.accent,
			color: theme.palette.textContrast,
		},
		MonthAndYear: {
			fontSize: '12px',
			fontWeight: '500',
			lineHeight: '1.25',
			color: theme.palette.text,
		},
		MonthButton: {
			backgroundColor: 'transparent',
			cursor: 'pointer',
		},
		MonthArrowPrev: {
			borderRightColor: theme.palette.text,
		},
		MonthArrowNext: {
			borderLeftColor: theme.palette.text,
		},
	};
};

const dialogContentStyle = {
	width: 'auto',
	maxWidth: 782,
};

const inputContainerStyle = {
	width: 62,
	height: 42,
	marginRight: 0,
};

const inputStyle = {
	fontSize: 12,
};

type InputLayoutProps = {
	disabled: boolean;
	readonly: boolean;
};

const InputLayout = styled.div<InputLayoutProps>`
	position: relative;
	width: 100%;
	height: auto;
	${p =>
		p.disabled &&
		css`
			cursor: not-allowed;

			& svg {
				opacity: 0.5;
			}
		`}

	${p =>
		p.readonly &&
		css`
			& svg {
				opacity: 0.5;
			}
		`}
`;

const FrameLayout = styled.div`
	position: relative;
	display: inline-flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	align-items: flex-start;
`;

const DatePickerLabel = styled.div`
	position: relative;
	width: 100%;
	left: -10px;
	font-size: 14px;
	transform: scale(0.75);
	line-height: 1.25;

	${p => css`
		color: ${p.theme.palette.label};
	`}
`;

type InlineModeRootProps = {
	disabled: boolean;
	fontSize?: number;
	color?: 'black';
	bold?: boolean;
};

const InlineModeRoot = styled.div<InlineModeRootProps>`
	display: inline-flex;
	align-items: center;
	cursor: pointer;
	font-weight: 400;

	${p =>
		p.bold &&
		css`
			font-weight: 500;
		`}

	${p => css`
		font-size: ${p.fontSize || 14}px;
	`}

	${p => css`
		color: ${!p.color ? p.theme.palette.accent : p.theme.palette.text};
	`}

	${p => css`
		&:hover {
			color: ${p.theme.palette.accent};
		}
	`}

	${p =>
		p.disabled &&
		css`
			color: ${p.theme.palette.label};
			cursor: default;
		`}
`;

const IconLayout = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 4px;
`;

export {
	Root,
	TriggerLayout,
	DatePickerLayout,
	createDatePickerTheme,
	dialogContentStyle,
	inputContainerStyle,
	inputStyle,
	InputLayout,
	FrameLayout,
	DatePickerLabel,
	InlineModeRoot,
	IconLayout,
};
