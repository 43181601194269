import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { IAppState } from '@core/store';
import { hasBooleanKeys } from '@utils/object';
import { AsyncSelector } from '@libs/selector-creator';

type UseDefaultValueEffectOptions<R, T1, T2> = {
	value: Record<number | string, boolean>;
	variant: 'first' | 'single';
	fetchers: Array<() => void>;
	asyncSelectors: [AsyncSelector<Array<T1>, IAppState>, AsyncSelector<Array<T2>, IAppState>?];
	transformDataSource?: (dataSources: [Array<T1>, Array<T2>?]) => Array<R>;
	effect: (item: R) => void;
	onFallback?: () => void;
};

function useDefaultValueEffect<R, T1, T2>(options: UseDefaultValueEffectOptions<R, T1, T2>) {
	const {
		value,
		variant,
		fetchers,
		asyncSelectors,
		transformDataSource = ([x]) => x as unknown as Array<R>,
		effect,
		onFallback = () => {},
	} = options;
	const itemsOne = useSelector(asyncSelectors[0].selectItem);
	const itemsTwo = asyncSelectors[1] ? useSelector(asyncSelectors[1].selectItem) : null;

	useEffect(() => {
		for (const fetcher of fetchers) {
			fetcher();
		}
	}, []);

	useEffect(() => {
		let effectItem: R = null;
		if (itemsOne?.length > 0) {
			if (asyncSelectors[1]) {
				if (itemsTwo?.length > 0) {
					effectItem = getEffectItem(transformDataSource([itemsOne, itemsTwo]));
				}
			} else {
				effectItem = getEffectItem(transformDataSource([itemsOne]));
			}
		} else {
			onFallback();
		}

		if (effectItem && !hasBooleanKeys(value)) {
			effect(effectItem);
		}
	}, [itemsOne, itemsTwo]);

	const getEffectItem = (transformedDataSource: Array<R>): R => {
		if (transformedDataSource?.length > 0) {
			if (variant === 'first' || (variant === 'single' && transformedDataSource.length === 1)) {
				return transformedDataSource[0];
			}
		}
		return null;
	};
}

export { useDefaultValueEffect };
