import { getAppName, getAuthParams, getBrandAppName } from '@config';
import { getURLParameterByName } from '@utils';
import { detectIsTechServiceUser } from '@utils/env';

const { baseURL } = getAuthParams();
const isBfmApp = getAppName() === 'bfm';
const isInvoiceApp = getAppName() === 'invoice';
const isInvoiceImport = Boolean(getURLParameterByName('invoice'));
const isPaymentNotification = /paymentNotification/g.test(location.pathname);

export const ACCOUNTS = 'accounts';
export const BALANCE = 'accountsBalance';
export const BUDGETING = 'budgeting';
export const BUSINESS_UNITS_MANAGEMENT = 'businessUnitsManagement';
export const CARD_TRANSACTIONS = 'cardTransactions';
export const CASHFLOW_ITEMS = 'cashflowItems';
export const CASHFLOW_LIMITS = 'cashflowBudgeting';
export const CLIENTS = 'clients';
export const COUNTERPARTIES = 'counterparties';
export const DASHBOARD = 'dashboard';
export const DOCUMENT_TEMPLATES = 'documentTemplates';
export const EMPLOYEES = 'employees';
export const FOR_OWNERS_REPORTS = 'forOwnersReports';
export const FUNDS_REGISTERS = 'accountsFundsRegister';
export const INITIALIZATION = 'initialization';
export const INVOICES = 'invoices';
export const MEASURE_UNITS = 'measureUnits';
export const PAYMENT_RULES = 'paymentRules';
export const PAYMENTS = 'accountsPayments';
export const PL_ANALYTICS = 'plAnalytics';
export const PL_BUDGETING = 'plBudgeting';
export const PL_MANAGEMENT = 'plManagement';
export const PL_OPERATIONS = 'plOperations';
export const PL_PAYMENT_RULES = 'plPaymentRules';
export const PL_PLANNING = 'plPlanning';
export const PRODUCTS = 'products';
export const PROJECT_EMPLOYEES = 'projectEmployees';
export const PROJECT_MANAGEMENT = 'projectManagement';
export const PROJECT_PAYMENT_RULES = 'projectPaymentRules';
export const PROJECTS = 'projects';
export const PURCHASES = 'purchases';
export const RECONCILIATION_ACT = 'reconciliationAct';
export const REFERENCE = 'referenceBooks';
export const REPORTING = 'reporting';
export const SALES = 'sales';
export const STANDARD_REPORTS = 'standardReports';
export const TENANT_ACCOUNT = 'tenantAccount';
export const TENANT_LEGAL_ENTITIES = 'tenantLegalEntities';
export const TRANSFER_RULES = 'accountsTransferRules';

const ROUTE_MAP = {
	ACCOUNTS: `${baseURL}accounts`,
	BALANCE: `${baseURL}accounts/balance`,
	BUDGETING: `${baseURL}budgeting`,
	BUSINESS_UNITS_MANAGEMENT: `${baseURL}reference/business-units`,
	CARD_TRANSACTIONS: `${baseURL}sales/transactions`,
	CASHFLOW_ITEMS: `${baseURL}reference/cashflow-items`,
	CASHFLOW_LIMITS: `${baseURL}budgeting/cashflow`,
	CLIENTS: `${baseURL}sales/clients`,
	COUNTERPARTIES: `${baseURL}counterparties`,
	DASHBOARD: `${baseURL}dashboard`,
	DOCUMENT_TEMPLATES: `${baseURL}reference/document-templates`,
	EMPLOYEES: `${baseURL}reference/employees`,
	FOR_OWNERS_REPORTS: `${baseURL}reporting/for-owners`,
	FUNDS_REGISTERS: `${baseURL}accounts/funds-registers`,
	INITIALIZATION: `${baseURL}initialization`,
	INVOICES: `${baseURL}sales/invoices`,
	MEASURE_UNITS: `${baseURL}reference/measure-units`,
	PAYMENT_RULES: `${baseURL}reference/payment-rules`,
	PAYMENTS: `${baseURL}accounts/payments`,
	PL_ANALYTICS: `${baseURL}pl/analytics`,
	PL_BUDGETING: `${baseURL}budgeting/pl`,
	PL_MANAGEMENT: `${baseURL}pl`,
	PL_OPERATIONS: `${baseURL}pl/operations`,
	PL_PAYMENT_RULES: `${baseURL}pl/rules`,
	PL_PLANNING: `${baseURL}planning`,
	PRODUCTS: `${baseURL}sales/products`,
	PROJECT_EMPLOYEES: `${baseURL}pm/managers`,
	PROJECT_MANAGEMENT: `${baseURL}pm`,
	PROJECT_PAYMENT_RULES: `${baseURL}pm/rules`,
	PROJECTS: `${baseURL}pm/projects`,
	PURCHASES: `${baseURL}purchases`,
	RECONCILIATION_ACT: `${baseURL}sales/reconciliation-act`,
	REFERENCE: `${baseURL}reference`,
	REPORTING: `${baseURL}reporting`,
	SALES: `${baseURL}sales`,
	STANDARD_REPORTS: `${baseURL}reporting/standard`,
	TENANT_ACCOUNT: `${baseURL}tenant-account`,
	TENANT_LEGAL_ENTITIES: `${baseURL}reference/tenant-legal-entities`,
	TRANSFER_RULES: `${baseURL}accounts/transfer-rules`,
};

export type CreateAppRoutesOptions = {
	isProfessionalEdition: boolean;
};

const AppPageRoutes: Array<IPageRoute> = [];

function createAppRoutes(): Array<IPageRoute> {
	return [
		{
			name: 'Инициализация',
			title: transformTitle('Инициализация'),
			sysName: INITIALIZATION,
			components: [],
			rights: [],
			path: ROUTE_MAP.INITIALIZATION,
			showInMenu: false,
		},
		{
			name: 'Личный кабинет',
			title: transformTitle('Личный кабинет'),
			sysName: TENANT_ACCOUNT,
			components: [],
			rights: [],
			path: ROUTE_MAP.TENANT_ACCOUNT,
			showInMenu: false,
		},
		{
			name: 'Dashboard',
			title: transformTitle('Сводная информация'),
			sysName: DASHBOARD,
			components: [],
			rights: [['xAudit_Readonly', 'xDashboard_ViewDashboard']],
			path: ROUTE_MAP.DASHBOARD,
			showInMenu: false,
		},
		{
			name: 'Операции',
			title: transformTitle('Операции'),
			sysName: PL_MANAGEMENT,
			components: ['ProfitLossManagement'],
			rights: [
				[
					'xAudit_Readonly',
					'xPLOperations_ViewOperations',
					'xPLOperations_ViewAnalytics',
					'xCashflowItems_ViewPaymentRules',
				],
			], // одно из прав
			path: ROUTE_MAP.PL_MANAGEMENT,
			showInMenu: true,
			tooltipText: 'Доступно в старших тарифных планах. Для подключения обратитесь в техподдержку.',
			nestedRoutes: [
				{
					name: 'Операции',
					title: transformTitle('Операции'),
					sysName: PL_OPERATIONS,
					components: [],
					rights: [['xAudit_Readonly', 'xPLOperations_ViewOperations']],
					path: ROUTE_MAP.PL_OPERATIONS,
				},
				{
					name: 'Аналитика',
					title: transformTitle('Аналитика'),
					sysName: PL_ANALYTICS,
					components: [],
					rights: [['xAudit_Readonly', 'xPLOperations_ViewAnalytics']],
					path: ROUTE_MAP.PL_ANALYTICS,
				},
				{
					name: 'Правила',
					title: transformTitle('Правила'),
					sysName: PAYMENT_RULES,
					components: [],
					rights: [['xAudit_Readonly', 'xCashflowItems_ViewPaymentRules']],
					path: ROUTE_MAP.PL_PAYMENT_RULES,
				},
			],
		},
		{
			name: 'Счета',
			title: transformTitle('Счета'),
			sysName: ACCOUNTS,
			components: ['CashflowManagement'],
			rights: [['xAudit_Readonly', 'xFundsRegisters_ViewFundsRegisters', 'xPayments_ViewPayments']],
			path: ROUTE_MAP.ACCOUNTS,
			showInMenu: true,
			tooltipText: 'Доступно в старших тарифных планах. Для подключения обратитесь в техподдержку.',
			nestedRoutes: [
				{
					name: 'Баланс',
					title: transformTitle('Баланс'),
					sysName: BALANCE,
					components: [],
					rights: [['xAudit_Readonly', 'xFundsRegisters_ViewFundsRegisters']],
					path: ROUTE_MAP.BALANCE,
				},
				{
					name: 'Счета',
					title: transformTitle('Счета'),
					sysName: FUNDS_REGISTERS,
					components: [],
					rights: [['xAudit_Readonly', 'xFundsRegisters_ViewFundsRegisters']],
					path: ROUTE_MAP.FUNDS_REGISTERS,
				},
				{
					name: 'Выписки',
					title: transformTitle('Выписки'),
					sysName: PAYMENTS,
					components: [],
					rights: [['xAudit_Readonly', 'xPayments_ViewPayments']],
					path: ROUTE_MAP.PAYMENTS,
				},
				{
					name: 'Правила внутренних переводов',
					title: transformTitle('Правила внутренних переводов'),
					sysName: TRANSFER_RULES,
					components: [],
					rights: [['xAudit_Readonly', 'xFundsRegisters_ViewFundsRegisters']],
					path: ROUTE_MAP.TRANSFER_RULES,
				},
			],
		},
		{
			name: 'Планы',
			title: transformTitle('Планирование'),
			sysName: PL_PLANNING,
			components: ['CashflowPlanning'],
			rights: [['xAudit_Readonly', 'xPlanning_ViewPlanning']],
			path: ROUTE_MAP.PL_PLANNING,
			showInMenu: true,
			tooltipText: 'Доступно в старших тарифных планах. Для подключения обратитесь в техподдержку.',
		},
		{
			name: 'Отчёты',
			title: transformTitle('Отчёты'),
			sysName: REPORTING,
			components: ['CFAndPLReports'],
			rights: [['xAudit_Readonly', 'xReports_ViewReports']],
			path: ROUTE_MAP.REPORTING,
			showInMenu: true,
			tooltipText: 'Доступно в старших тарифных планах. Для подключения обратитесь в техподдержку.',
			nestedRoutes: [
				{
					name: 'Стандартные',
					title: transformTitle('Стандартные отчёты'),
					sysName: STANDARD_REPORTS,
					components: [],
					rights: [],
					path: ROUTE_MAP.STANDARD_REPORTS,
				},
				{
					name: 'Для владельцев',
					title: transformTitle('Отчёты для владельцев'),
					sysName: FOR_OWNERS_REPORTS,
					components: [],
					rights: [],
					path: ROUTE_MAP.FOR_OWNERS_REPORTS,
				},
			],
		},
		{
			name: 'Бюджеты',
			title: transformTitle('Бюджеты'),
			sysName: BUDGETING,
			components: ['CashflowBudgeting'],
			rights: [['xAudit_Readonly', 'xCashflowLimits_ViewCashflowLimits']],
			path: ROUTE_MAP.BUDGETING,
			showInMenu: true,
			tooltipText: 'Доступно в старших тарифных планах. Для подключения обратитесь в техподдержку.',
			nestedRoutes: [
				{
					name: 'БДДС',
					title: transformTitle('БДДС'),
					sysName: CASHFLOW_LIMITS,
					components: [],
					rights: [],
					path: ROUTE_MAP.CASHFLOW_LIMITS,
				},
				// {
				// 	name: 'БДДР',
				// 	title: transformTitle('БДДР'),
				// 	sysName: PL_BUDGETING,
				// 	components: [],
				// 	rights: [],
				// 	disabled: true,
				// 	tooltipText: 'Будет доступно в следующих версиях',
				// 	path: ROUTE_MAP.PL_BUDGETING,
				// },
			],
		},
		{
			name: 'Покупки',
			title: transformTitle('Покупки'),
			sysName: PURCHASES,
			components: ['Purchases'],
			rights: [['xAudit_Readonly', 'xInvoices_ViewIncomingInvoices']],
			path: ROUTE_MAP.PURCHASES,
			showInMenu: true,
			tooltipText: 'Доступно в старших тарифных планах. Для подключения обратитесь в техподдержку.',
			alwaysAvailableByLink: true,
		},
		{
			name: 'Продажи',
			title: transformTitle('Продажи'),
			sysName: SALES,
			components: ['Sales'],
			rights: [
				[
					'xAudit_Readonly',
					'xInvoices_ViewOutgoingInvoices',
					'xClients_ViewClients',
					'xProducts_ViewProducts',
					'xTransactions_ViewTransactions',
				],
			],
			path: ROUTE_MAP.SALES,
			showInMenu: true,
			tooltipText: 'Доступно в старших тарифных планах. Для подключения обратитесь в техподдержку.',
			nestedRoutes: [
				{
					name: 'Счета',
					title: transformTitle('Счета'),
					sysName: INVOICES,
					components: [],
					rights: [['xAudit_Readonly', 'xInvoices_ViewOutgoingInvoices']],
					path: ROUTE_MAP.INVOICES,
				},
				{
					name: 'Клиенты',
					title: transformTitle('Клиенты'),
					sysName: CLIENTS,
					components: [],
					rights: [['xAudit_Readonly', 'xClients_ViewClients']],
					path: ROUTE_MAP.CLIENTS,
				},
				{
					name: 'Товары',
					title: transformTitle('Товары'),
					sysName: PRODUCTS,
					components: [],
					rights: [['xAudit_Readonly', 'xProducts_ViewProducts']],
					path: ROUTE_MAP.PRODUCTS,
				},
				{
					name: 'Транзакции',
					title: transformTitle('Транзакции'),
					sysName: CARD_TRANSACTIONS,
					components: ['CardTransactions'],
					rights: [['xAudit_Readonly', 'xTransactions_ViewTransactions']],
					path: ROUTE_MAP.CARD_TRANSACTIONS,
				},
				{
					name: 'Акты сверки',
					title: transformTitle('Акты сверки'),
					sysName: RECONCILIATION_ACT,
					components: [],
					rights: [['xAudit_Readonly', 'xClients_ViewClients']],
					path: ROUTE_MAP.RECONCILIATION_ACT,
				},
			],
		},
		{
			name: 'Проекты',
			title: transformTitle('Проекты'),
			sysName: PROJECT_MANAGEMENT,
			components: ['ProjectFinanceManagement'],
			rights: [['xAudit_Readonly', 'xProjects_ViewProjects', 'xProjects_ViewManagers', 'xProjects_ViewPaymentRules']],
			path: ROUTE_MAP.PROJECT_MANAGEMENT,
			showInMenu: true,
			tooltipText: 'Доступно в старших тарифных планах. Для подключения обратитесь в техподдержку.',
			nestedRoutes: [
				{
					name: 'Проекты',
					title: transformTitle('Проекты'),
					sysName: PROJECTS,
					components: [],
					rights: [['xAudit_Readonly', 'xProjects_ViewProjects']],
					path: ROUTE_MAP.PROJECTS,
				},
				{
					name: 'Менеджеры',
					title: transformTitle('Менеджеры'),
					sysName: PROJECT_EMPLOYEES,
					components: [],
					rights: [['xAudit_Readonly', 'xProjects_ViewManagers']],
					path: ROUTE_MAP.PROJECT_EMPLOYEES,
				},
			],
		},
		{
			name: 'Контрагенты',
			title: transformTitle('Контрагенты'),
			sysName: COUNTERPARTIES,
			components: ['CounterpartyRegister'],
			rights: [['xAudit_Readonly', 'xCounterparties_ViewCounterparties']],
			path: ROUTE_MAP.COUNTERPARTIES,
			showInMenu: true,
			tooltipText: 'Доступно в старших тарифных планах. Для подключения обратитесь в техподдержку.',
		},
		{
			name: 'Справочники',
			title: transformTitle('Справочники'),
			sysName: REFERENCE,
			components: [],
			rights: [
				[
					'xAudit_Readonly',
					'xTenantLegalEntities_ViewTenantLegalEntities',
					'xEmployees_ViewEmployees',
					'xCashflowItems_ViewCashflowItems',
					'xBusinessUnits_ViewBusinessUnits',
					'xDocumentTemplates_ViewDocumentTemplates',
				],
			],
			path: ROUTE_MAP.REFERENCE,
			showInMenu: true,
			tooltipText: 'Доступно в старших тарифных планах. Для подключения обратитесь в техподдержку.',
			nestedRoutes: [
				{
					name: 'Организации',
					title: transformTitle('Организации'),
					sysName: TENANT_LEGAL_ENTITIES,
					components: ['TenantEntityView'],
					rights: [['xAudit_Readonly', 'xTenantLegalEntities_ViewTenantLegalEntities']],
					path: ROUTE_MAP.TENANT_LEGAL_ENTITIES,
				},
				{
					name: 'Правила',
					title: transformTitle('Правила'),
					sysName: PAYMENT_RULES,
					components: [],
					rights: [['xAudit_Readonly', 'xCashflowItems_ViewPaymentRules', 'xProjects_ViewPaymentRules']],
					path: ROUTE_MAP.PAYMENT_RULES,
				},
				{
					name: 'Сотрудники',
					title: transformTitle('Сотрудники'),
					sysName: EMPLOYEES,
					components: ['EmployeeManagement'],
					rights: [['xAudit_Readonly', 'xEmployees_ViewEmployees']],
					path: ROUTE_MAP.EMPLOYEES,
				},
				{
					name: 'Статьи',
					title: transformTitle('Статьи'),
					sysName: CASHFLOW_ITEMS,
					components: [],
					rights: [['xAudit_Readonly', 'xCashflowItems_ViewCashflowItems']],
					path: ROUTE_MAP.CASHFLOW_ITEMS,
				},
				{
					name: 'Бизнес-юниты',
					title: transformTitle('Бизнес-юниты'),
					sysName: BUSINESS_UNITS_MANAGEMENT,
					components: [],
					rights: [['xAudit_Readonly', 'xBusinessUnits_ViewBusinessUnits']],
					path: ROUTE_MAP.BUSINESS_UNITS_MANAGEMENT,
				},
				{
					name: 'Шаблоны документов',
					title: transformTitle('Шаблоны документов'),
					sysName: DOCUMENT_TEMPLATES,
					components: ['InvoiceLinkedDocuments'],
					rights: [['xAudit_Readonly', 'xDocumentTemplates_ViewDocumentTemplates']],
					path: ROUTE_MAP.DOCUMENT_TEMPLATES,
				},
				{
					name: 'Единицы измерения',
					title: transformTitle('Единицы измерения'),
					sysName: MEASURE_UNITS,
					components: [],
					rights: [],
					path: ROUTE_MAP.MEASURE_UNITS,
				},
			],
		},
	];
}

function transformTitle(name: string): string {
	return `${name} :: «${getBrandAppName()}»`;
}

type FilterRoutesOptions = {
	isAccessGranted?: boolean;
	isTrialMode?: boolean;
	isTenantProfileCompleted?: boolean;
};

const isAvailableByComponents = (components: Array<ApplicationComponent>, page: IPageRoute) => {
	const hasComponets =
		components && page.components.every(pageC => components.some(tenantC => pageC === tenantC.SystemName));

	return hasComponets;
};

const isAvailableByRights = (isFullAccess: boolean, page: IPageRoute, rights: Array<SecurityRight>) => {
	const hasRights = !isFullAccess
		? rights &&
		  page.rights.every(pageR => {
				return rights.some(userR => {
					return Array.isArray(pageR)
						? pageR.some(itemPageR => itemPageR === userR.SystemName)
						: pageR === userR.SystemName;
				});
		  })
		: true;

	return hasRights;
};

const filterRoutes = (
	components: Array<ApplicationComponent>,
	rights: Array<SecurityRight>,
	routes: Array<IPageRoute> = AppPageRoutes,
	isFullAccess = false,
	options: FilterRoutesOptions = null,
) => {
	let filteredRoutes = [...routes];
	const isTechSupportUser = detectIsTechServiceUser();
	const checkRoute = (
		route: IPageRoute,
		checker: (route: IPageRoute) => boolean,
		isByComponentsAndRightsChecker = false,
	) => {
		if (route.alwaysAvailableByLink) {
			const hasStaff = components.length > 0 && rights.length > 0;

			if (isByComponentsAndRightsChecker) {
				route.showInMenu = hasStaff ? checker(route) : false;
			}

			return true;
		}

		return checker(route);
	};

	if (isTechSupportUser) {
		return filteredRoutes.filter(route => route.sysName !== INITIALIZATION);
	}

	const isTrialMode = options?.isTrialMode ?? false;

	if (options) {
		const { isAccessGranted, isTenantProfileCompleted } = options;

		if (!isAccessGranted && !isTrialMode) {
			filteredRoutes = filteredRoutes.filter(route => checkRoute(route, route => route.sysName === TENANT_ACCOUNT));
		}

		if (isTenantProfileCompleted || isPaymentNotification || isInvoiceImport || isInvoiceApp) {
			filteredRoutes = filteredRoutes.filter(route => checkRoute(route, route => route.sysName !== INITIALIZATION));
		} else {
			filteredRoutes = filteredRoutes.filter(route =>
				checkRoute(route, route => route.sysName === INITIALIZATION || route.sysName === TENANT_ACCOUNT),
			);
		}
	}

	filteredRoutes = filteredRoutes.filter(route =>
		checkRoute(route, route => isAvailableByRights(isFullAccess, route, rights), true),
	);

	if (isBfmApp) {
		filteredRoutes.forEach(x => {
			x.disabled = !(isTrialMode || checkRoute(x, route => isAvailableByComponents(components, route), true));
		});
	} else {
		filteredRoutes = filteredRoutes.filter(route =>
			checkRoute(route, route => isAvailableByComponents(components, route), true),
		);
	}

	return filteredRoutes;
};

export { AppPageRoutes, ROUTE_MAP, baseURL, createAppRoutes, filterRoutes };
