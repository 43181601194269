import styled, { css } from 'styled-components';

const Root = styled.div`
	position: relative;
	width: 100%;
	height: auto;
	border-bottom: 1px solid transparent;

	${p => css`
		border-color: ${p.theme.palette.stealth};
	`}
`;

export { Root };
