import { createTypes, createAsyncAction, createStaticAction } from '@flux';
import { correctDateRange } from '@utils/date';
import { FetchOperationsOptions as FetchOperationsApiOptions, FetchAccountChartDynamicsOptions } from '@core/api/pl';
import { mainReportingSelectorsPack } from '../selectors';

export type FetchOperationsOptions = FetchOperationsApiOptions;
export type DetalizationOptions = FetchOperationsOptions;

export type FetchReportingDataOptions = FetchAccountChartDynamicsOptions;

export type FetchReportingDataReturnType = {
	plOperationDynamics: PLOperationDynamics;
	cashBalanceRecords: Array<CashBalanceRecord>;
};

type ActionTypes = {
	SET_DATE_RANGE: string;
	FETCH_REPORTING_DATA: string;
	SET_DETALIZATION_OPTIONS: string;
	SET_LEGAL_ENTITY_FILTER: string;
	SET_PROJECT_FILTER: string;
	SET_TEXT_FILTER: string;
	CLICK_ON_TABLE_CELL: string;
	FETCH_PL_OPERATIONS: string;
	INVALIDATE_PL_OPERATIONS: string;
	SET_IS_EXPANDED_CF_REPORT: string;
	SET_IS_EXPANDED_PL_REPORT: string;
	SET_IS_INCLUDES_VAT: string;
};

const types = createTypes<ActionTypes>(
	[
		'SET_DATE_RANGE',
		'FETCH_REPORTING_DATA',
		'SET_DETALIZATION_OPTIONS',
		'SET_LEGAL_ENTITY_FILTER',
		'SET_PROJECT_FILTER',
		'SET_TEXT_FILTER',
		'CLICK_ON_TABLE_CELL',
		'FETCH_PL_OPERATIONS',
		'INVALIDATE_PL_OPERATIONS',
		'SET_IS_EXPANDED_CF_REPORT',
		'SET_IS_EXPANDED_PL_REPORT',
		'SET_IS_INCLUDES_VAT',
	],
	'ANALYTICS_REPORTS',
);

const actions = {
	setDateRange: (dateRange: DateRange) => createStaticAction(types.SET_DATE_RANGE)(dateRange),
	setDetalizationOptions: (options: DetalizationOptions) => createStaticAction(types.SET_DETALIZATION_OPTIONS)(options),
	setLegalEntityFilter: (value: CounterpartyBrief) => createStaticAction(types.SET_LEGAL_ENTITY_FILTER)(value),
	setProjectFilter: (projectID: number) => createStaticAction(types.SET_PROJECT_FILTER)(projectID),
	setTextFilter: (text: string) => createStaticAction(types.SET_TEXT_FILTER)(text),
	clickOnTableCell: (row: any, col: number) => createStaticAction(types.CLICK_ON_TABLE_CELL)({ row, col }),
	setIsIncludesVAT: (value: boolean) => createStaticAction(types.SET_IS_INCLUDES_VAT)(value),
	fetchReportingData: createAsyncAction(
		types.FETCH_REPORTING_DATA,
		(api, getState, _, options: FetchReportingDataOptions) => {
			return new Promise<FetchReportingDataReturnType>(resolve => {
				const {
					dateRange: sourceDateRange,
					cashflowDateAggregation = false,
					inCFConsolidation = false,
					inPLConsolidation = false,
				} = options;
				const state = getState();
				const dateRange = correctDateRange(sourceDateRange, 'quarter');
				const tenantEntityID = mainReportingSelectorsPack.selectLegalEntityFilter(state)?.ID || -1;
				const projectID = mainReportingSelectorsPack.selectProjectFilter(state) || -1;
				const excludingVat = !mainReportingSelectorsPack.selectIsIncludesVAT(state);
				const tenantEntitiesIDs = tenantEntityID > 0 ? [tenantEntityID] : [];
				const projectIDs = projectID > 0 ? [projectID] : [];

				Promise.all([
					api.plPack.plOperation.fetchAccountChartDynamics({
						dateRange,
						cashflowDateAggregation,
						tenantEntitiesIDs,
						inCFConsolidation,
						inPLConsolidation,
						projectIDs,
						inConsolidation: true,
						excludingVat,
					}),
					api.fundsPack.fundsRegister.fetchSummaryCashBalance({
						dateRange,
						leIDs: tenantEntitiesIDs,
						inConsolidation: true,
					}),
				]).then(result => {
					resolve({
						plOperationDynamics: result[0],
						cashBalanceRecords: result[1],
					} as FetchReportingDataReturnType);
				});
			});
		},
	),
	fetchOperations: createAsyncAction(
		types.FETCH_PL_OPERATIONS,
		(api, getState, dispatch, sourceOptions: FetchOperationsOptions) => {
			return new Promise<Array<PLOperationBrief>>(resolve => {
				const state = getState();
				const projectID = mainReportingSelectorsPack.selectProjectFilter(state) || -1;
				const projectIDs = projectID > 0 ? [projectID] : [];
				const excludingVat = !mainReportingSelectorsPack.selectIsIncludesVAT(state);
				const options = {
					...sourceOptions,
					projectIDs,
					inConsolidation: true,
					excludingVat,
				};

				dispatch(actions.setDetalizationOptions(options));
				api.plPack.plOperation.fetchOperationsBriefs(options).then(result => {
					resolve(result);
				});
			});
		},
		{
			isValidSelector: mainReportingSelectorsPack.selectAsyncPlOperations.selectIsValid,
			isFetchingSelector: mainReportingSelectorsPack.selectAsyncPlOperations.selectIsFetching,
		},
	),
	invalidateOperations: () => createStaticAction(types.INVALIDATE_PL_OPERATIONS)(),
	setIsExpandedCfReport: (value: boolean) => createStaticAction(types.SET_IS_EXPANDED_CF_REPORT)(value),
	setIsExpandedPlReport: (value: boolean) => createStaticAction(types.SET_IS_EXPANDED_PL_REPORT)(value),
};

export const mainReportingActionsPack = {
	types,
	actions,
};
