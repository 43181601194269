import React from 'react';
import * as _ from 'underscore';

import store from '@store';
import { selectIsAppProgressBarOpen, selectIsAppPurpleBarBarOpen } from '@shared/selectors/ui.selectors';

type Config = {
	propName: string;
};

type EnhancerProps = {
	isAppProgressBarOpen?: boolean;
};

type EnhancerState = {
	topShift: number;
};

const scope = {
	topShift: 0,
};

function withTopShift<P = any>(config?: Config) {
	const { propName = 'topShift' } = config || {};

	return (WrappedComponent: React.ComponentType<P>) => {
		class Enhancer extends React.Component<P & EnhancerProps, EnhancerState> {
			static displayName = `withTopShift(${WrappedComponent.name || WrappedComponent.displayName})`;
			isMountedComponent = false;
			isAppProgressBarOpen = false;
			isAppPurpleBarOpen = false;
			dispose = null;
			willMount = (() => {
				this.dispose = store.subscribe(() => {
					setImmediate(() => {
						if (!this.isMountedComponent) return;
						const isAppProgressBarOpen = selectIsAppProgressBarOpen(store.getState());
						const isAppPurpleBarOpen = selectIsAppPurpleBarBarOpen(store.getState());

						if (this.isAppProgressBarOpen !== isAppProgressBarOpen || this.isAppPurpleBarOpen !== isAppPurpleBarOpen) {
							this.isAppProgressBarOpen = isAppProgressBarOpen;
							this.isAppPurpleBarOpen = isAppPurpleBarOpen;
							this.detectSize();
						}
					});
				});
			})();

			componentDidMount() {
				this.isMountedComponent = true;
				window.addEventListener('resize', this.handleResizeDebounced);
			}

			componentWillUnmount() {
				this.isMountedComponent = false;
				window.removeEventListener('resize', this.handleResizeDebounced);
				this.dispose && this.dispose();
			}

			detectSize = () => {
				scope.topShift = document.querySelector(`#${DECK_ELEMENT_ID}`)?.clientHeight || 0;
				this.forceUpdate();
			};

			handleResize = (e: Event) => e.isTrusted && this.detectSize();

			handleResizeDebounced = _.debounce(this.handleResize, 300);

			render() {
				const props = {
					...this.props,
					[propName]: scope.topShift,
				};

				return <WrappedComponent {...(props as any)} />;
			}
		}

		return Enhancer;
	};
}

const DECK_ELEMENT_ID = 'app-header-deck';

export { DECK_ELEMENT_ID };
export default withTopShift;
