import React, { useMemo } from 'react';

import { DataSourceItem } from '@core/api/core';
import { Autopicker, AutopickerProps } from '@ui/autopicker';

export type CashflowTypeAutopickerProps = {
	name?: string;
	includeNoData?: boolean;
} & AutopickerProps<DataSourceItem<number>>;

const CashflowTypeAutopicker: React.FC<CashflowTypeAutopickerProps> = props => {
	const { includeNoData } = props;
	const pluralItems = useMemo(() => ['тип', 'типа', 'типов'] as [string, string, string], []);

	const createUnselectItem = useMemo(
		() => () => {
			return new DataSourceItem({ value: -1, text: 'Не указан' });
		},
		[],
	);

	return (
		<Autopicker
			labelText='Тип денежного потока'
			hintText='Выберите тип денежного потока'
			pluralItems={pluralItems}
			createUnselectItem={includeNoData ? createUnselectItem : undefined}
			{...props}
			getID={getID}
			getName={getName}
			saveOriginalSorting
			fullWidth
		/>
	);
};

const getID = (x: DataSourceItem<number>) => x.value;

const getName = (x: DataSourceItem<number>) => x.text;

export { CashflowTypeAutopicker };
