import { createAsyncSelector, createSelector } from '@flux';
import { IAppState } from '@store';

const selectAsyncOperationTypes = createAsyncSelector<Array<MarketplaceOperationType>, IAppState>({
	get: s => s.marketplaces.main.operationTypes,
	selector: createSelector(
		s => s.marketplaces.main.operationTypes.item,
		item => item,
	),
});

const selectAsyncRules = createAsyncSelector<Array<MarketplaceOperationRule>, IAppState>({
	get: s => s.marketplaces.main.rules,
	selector: createSelector(
		s => s.marketplaces.main.rules.item,
		item => item,
	),
});

export const mainMarketplacesSelectorsPack = {
	selectAsyncOperationTypes,
	selectAsyncRules,
};
