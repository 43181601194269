/***
 * Library contains JavaScript API for interaction with Seeneco Framework Web Services contained in ProfilePackage
 * @author AlexPlex Generated by Seeneco Framework Modeler 
 *
 */
(function(w){
/***
* 
*/
w.TenantProfile = function() {
  this.PACKAGE = "PROFILE";
  this.CLASSIFIER = "TENANT_PROFILE";
  this.CopySupportingElement = false;
  this.Parts = [];
  this.ID = -1;
  this.Completed = false;
  this.IndustryCode = "";
  this.HasStandardData = false;
  this.HasReferenceData = false;
};
/***
* 
*/
w.TenantProfileItem = function() {
  this.PACKAGE = "PROFILE";
  this.CLASSIFIER = "TENANT_PROFILE_ITEM";
  this.CopySupportingElement = false;
  this.ID = -1;
  this.Name = "";
  this.Code = "";
  this.ItemTypeCode = "";
  this.Value = -1;
  this.ValueQ1 = -1;
  this.ValueQ2 = -1;
  this.ValueQ3 = -1;
  this.ValueQ4 = -1;
};
/***
* 
*/
w.TenantProfilePart = function() {
  this.PACKAGE = "PROFILE";
  this.CLASSIFIER = "TENANT_PROFILE_PART";
  this.CopySupportingElement = false;
  this.ID = -1;
  this.Name = "";
  this.Code = "";
  this.Items = [];
};
 
 

/***
 * Client API to TenantProfileServiceWS web service
 */
w.TenantProfileServiceClient = function() {
  /***
   * System name of target service
   */
  this.serviceName = "TenantProfileServiceWSImpl";
};

/***
 * [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.TenantProfileServiceClient.prototype.getTenantProfile = function(callbackHandler) {
  var parameterNames = [];
  var parameterValues = [];
  var soapMessage = buildSOAPMessage("getTenantProfile", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = true;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param profile [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.TenantProfileServiceClient.prototype.updateTenantProfile = function(profile, callbackHandler) {
  var parameterNames = ["profile"];
  var profileJSON = JSON.stringify(profile);
  var parameterValues = [profileJSON];
  var soapMessage = buildSOAPMessage("updateTenantProfile", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.TenantProfileServiceClient.prototype.initializeApplicationByProfile = function(callbackHandler) {
  var parameterNames = [];
  var parameterValues = [];
  var soapMessage = buildSOAPMessage("initializeApplicationByProfile", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};


/***
 * Package security rights constants
 */

w.ProfileSecurityRights = {

};
})(typeof exports !== 'undefined' ? exports : window);
