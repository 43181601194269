// @ts-nocheck
import * as _ from 'underscore';

import { createDefaultAsyncItem } from '../../../core/libs/reducer-creator';
import {
	ADD_INVOICE,
	SHOW_INVOICE_FORM,
	HIDE_INVOICE_FORM,
	SET_READONLY_INVOICE_FORM,
	HIDE_INVOICE_INCOMING_FORM
} from '../actions/invoice-add.actions';
import {
	FETCH_INVOICE_BY_ID,
	FETCH_ICOMING_INVOICE_BY_GUID
} from '../actions/invoice-select.actions';


interface IInvoiceSelectState {
	isFetching: boolean;
	didInvalidate: boolean;
	isLoaded: boolean;
	item: Invoice;
	formIsReadonly: boolean;
	incomeInvoice: {
		isFetching: boolean;
		didInvalidate: boolean;
		isLoaded: boolean;
		item: Invoice;
	};
}

const initialState = {
	...createDefaultAsyncItem(null),
	formIsReadonly: false,
	incomeInvoice: createDefaultAsyncItem(null)
};

export default function(state: IInvoiceSelectState = initialState, action) {
	switch (action.type) {
	case ADD_INVOICE:
		let addAction = action as asyncAction<Invoice>;

		if (addAction.status == 'REQUEST') {
			return {...state, isFetching: true}
		}
		else if (addAction.status == 'RECEIVE') {
			return {...state, isFetching: false, item: addAction.response}
		}
		return state
		
	case SHOW_INVOICE_FORM:
		let showAction = action as staticAction<Invoice>;
		return {...state, isFetching: false, item: showAction.value}
	
	case HIDE_INVOICE_FORM:
	case HIDE_INVOICE_INCOMING_FORM:
		return {...state, isFetching: false, item: null, formIsReadonly: false}

	case SET_READONLY_INVOICE_FORM: 
		const setReadonlyAction = action as staticAction<boolean>;

		return {
			...state,
			formIsReadonly: setReadonlyAction.value
		}

	case FETCH_INVOICE_BY_ID:
		let fetchAction = action as asyncAction<Invoice>;

		if (fetchAction.status == 'REQUEST') {
			return {...state, isFetching: true, isLoaded: false}
		}
		else if (fetchAction.status == 'RECEIVE') {
			return {...state, isFetching: false, isLoaded: true, item: fetchAction.response}
		}
		return state

	case FETCH_ICOMING_INVOICE_BY_GUID:
		let fetchIncomeInvoiceAction = action as asyncAction<Invoice>;

		if (fetchIncomeInvoiceAction.status === 'REQUEST') {
			return {
				...state,
				incomeInvoice: {
					...state.incomeInvoice,
					isFetching: true
				}
			}
		}
		else if (fetchIncomeInvoiceAction.status === 'RECEIVE') {
			return {
				...state,
				incomeInvoice: {
					...state.incomeInvoice,
					isFetching: false,
					item: fetchIncomeInvoiceAction.response
				}
			}
		}
		return state;
	
	default:
		return state
	}
}

export {
	IInvoiceSelectState
};
