import React from 'react';

import { FormContextProps, withFormComponent } from '../component';
import { getValueByName } from './shared';

type withFormQrCodeProps<S = any, T = any> = {
	name: string;
	value?: Array<T>;
} & Partial<S>;

function withFormQrCode<S, T>(
	WrappedComponent: React.ComponentType<S>,
): React.ComponentType<withFormQrCodeProps<S, T>> {
	class EnhancedComponent extends React.Component<FormContextProps<any> & withFormQrCodeProps<S, T>> {
		static displayName = `withForm[HOC](${WrappedComponent.displayName || WrappedComponent.name})`;

		render() {
			const {
				name,
				formObject,
				editedBriefObjects,
				isFetching,
				handleObjectChange,
				addFormValidation,
				removeFormValidation,
				submitForm,
				resetForm,
				validateForm,
				getBriefObject,
				addBriefObject,
				deleteBriefObject,
				handleBriefObjectChange,
				...rest
			} = this.props;
			const value = getValueByName(name, formObject);

			return <WrappedComponent {...(rest as any)} value={value} />;
		}
	}

	return withFormComponent<withFormQrCodeProps>(EnhancedComponent);
}

export default withFormQrCode;
