import React from 'react';

import { SearchBar } from '@ui/search-bar';
import { SlimControl } from '@ui/slim-control';
import { mainIntegrationsActionsPack } from '@integrations/actions';

type XTextFilterProps = {
	value: string;
	onChange: typeof mainIntegrationsActionsPack.setCmsAccountsTextFilter;
};

const XTextFilter: React.FC<XTextFilterProps> = props => {
	const { value, onChange } = props;
	const handleChange = (_, value: string) => onChange(value);

	return (
		<SlimControl>
			<SearchBar
				value={value}
				hintText='Поиск по наименованию / ИНН'
				debounce={600}
				withClearBtn
				fullWidth
				onChange={handleChange}
			/>
		</SlimControl>
	);
};

export { XTextFilter };
