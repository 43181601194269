// @ts-nocheck
import * as moment from 'moment';

import api, { SeenecoApi } from '@api';
import { invalidateFundsRegisterStatistics } from '@funds-registers/actions/invalidators';
import createAction, { createLoader, createStaticDataAction } from '../../../core/libs/action-creator';
import { createActionMessage, getInvoiceStatuses } from '../../../core/libs/utils';
import { actions as changeActions, methods as changeMethods } from './invoice-change.actions';
import { addInvoicePaymentDraftAction } from './invoice-payment.actions';
import { fetchInvoiceByIDAction } from './invoice-select.actions';

export const ADD_INVOICE = '[INVOICE]: ADD_INVOICE';
export const SHOW_INVOICE_FORM = '[INVOICE]: SHOW_INVOICE_FORM';
export const HIDE_INVOICE_FORM = '[INVOICE]: HIDE_INVOICE_FORM';
export const SET_READONLY_INVOICE_FORM = '[INVOICE]: SET_READONLY_INVOICE_FORM';
export const SEND_INVOICE_EMAIL = '[INVOICE]: SEND_INVOICE_EMAIL';

export const showInvoiceForm = createStaticDataAction(SHOW_INVOICE_FORM);
export const hideInvoiceForm = createStaticDataAction(HIDE_INVOICE_FORM);
export function setReadonlyInvoiceFormAction(isReadonly: boolean) {
	return createStaticDataAction(SET_READONLY_INVOICE_FORM)(isReadonly);
}

export const createNewInvoice = (): Invoice => {
	const requiredPaymentDate = moment().add(10, 'd').format('DD-MM-YYYY 00:00:00');

	const invoice: Invoice = {
		...new api.invoicepackage.Invoice(),
		Outgoing: true,
		LegalEntity: new api.counterpartypackage.CounterpartyBrief(),
		Counterparty: new api.counterpartypackage.CounterpartyBrief(),
		FundsRegister: {
			...new api.fundspackage.FundsRegisterBrief(),
			Bank: new api.referencepackage.Bank(),
		},
		SignEmployee: {
			...new api.counterpartypackage.EmployeeBrief(),
			BusinessRole: new api.corepackage.CodeNaturalKey(),
		},
		DateIssued: moment().format('DD-MM-YYYY 00:00:00'),
		RequiredPaymentDate: requiredPaymentDate,
		ExpectedPaymentDate: requiredPaymentDate,
		PaymentProbability: 100,
	};

	return invoice;
};

const sendInvoiceEMail = (
	api,
	getState,
	dispatch,
	invoiceID: number,
	from: string,
	to: string,
	mailMessage: string,
): Promise<boolean> => {
	return new Promise((resolve, reject) => {
		if (invoiceID < -1) {
			reject(new Error('Счёт не найден'));
		} else {
			api.financialDocumentServiceClient.sendInvoiceEMail(invoiceID, from, to, mailMessage, resolve);
		}
	});
};

export const sendInvoiceEMailAction = createAction(SEND_INVOICE_EMAIL, sendInvoiceEMail, {
	showMessage: type => {
		return type === 'RECEIVE' && createActionMessage('Счёт отправлен клиенту по e-mail 😊', 'success');
	},
});

export const createNewLegalEntity = (isTenantLegalEntity?: boolean): LegalEntity => {
	const le = new api.counterpartypackage.LegalEntity();
	le.StaffCount = 0;
	le.LegalEntityType = new api.counterpartypackage.LegalEntityType();
	le.LegalEntityType.ID = 1;
	if (isTenantLegalEntity) {
		le.TenantLegalEntity = true;
	}
	return le;
};

function addCounterparty<T extends Counterparty>(api, getState, dispatch, cp?: T) {
	return new Promise<T>(resolve => {
		if (!cp) {
			resolve(null);
		} else {
			const method: keyof CounterpartyServiceClient = cp.ID > 0 ? 'changeCounterparty' : 'addCounterparty';
			api.counterpartyServiceClient[method](cp, cp => resolve(cp as T));
		}
	});
}

function processCounterparty(
	api: SeenecoApi,
	getState,
	dispatch,
	counterparty?: Counterparty,
	changedCounterparty?: Partial<CounterpartyBrief>,
) {
	let newCounterparty: Counterparty = counterparty;
	const keysBlackList = ['CLASSIFIER', 'BirthDate', 'SoleProprietor', 'LegalEntityScale'];
	const filterKeys = (key: string) => !keysBlackList.includes(key);
	const forEachKeys = (key: string, candidate: Counterparty) =>
		typeof counterparty[key] !== 'undefined' && (candidate[key] = counterparty[key]);

	if (changedCounterparty) {
		const isNaturalPerson = changedCounterparty.LegalEntity === false;

		if (isNaturalPerson) {
			const person: NaturalPerson = {
				...new api.counterpartyPack.package.NaturalPerson(),
				BirthDate: (counterparty as NaturalPerson).BirthDate || '01-01-1900 00:00:00',
			};

			Object.keys(person)
				.filter(filterKeys)
				.forEach(key => forEachKeys(key, person));
			newCounterparty = person;
		} else {
			const entity: LegalEntity = {
				...new api.counterpartyPack.package.LegalEntity(),
				LegalEntityScale: new api.counterpartypackage.LegalEntityScale(),
				SoleProprietor:
					typeof changedCounterparty.SoleProprietor !== 'undefined'
						? changedCounterparty.SoleProprietor
						: (counterparty as LegalEntity).SoleProprietor,
			};

			Object.keys(entity)
				.filter(filterKeys)
				.forEach(key => forEachKeys(key, entity));
			newCounterparty = entity;
		}
	}

	return addCounterparty(api, getState, dispatch, newCounterparty);
}

function addEmployee(api, getState, dispatch, empl?: Employee): Promise<Employee> {
	return addCounterparty<Employee>(api, getState, dispatch, empl);
}

function addFundsRegister(
	api,
	getState,
	dispatch,
	fr?: FundsRegister,
	initialBalance = 0 /*null*/,
	initialDate: string = moment().format('DD-MM-YYYY 00:00:00') /*null*/,
): Promise<FundsRegister> {
	return new Promise((resolve, reject) => {
		if (!fr) {
			resolve(null);
		} else if (fr.ID > 0) {
			api.registerServiceClient.changeFundsRegister(fr, resolve);
		} else {
			api.registerServiceClient.createFundsRegister(fr, initialBalance, initialDate, resolve);
		}
	});
}

const getBankAccountFundsRegister = createLoader<BankAccountFundsRegister>((api, getState, dispatch, id: number) => {
	return new Promise((resolve, reject) => {
		if (id > 0) {
			api.registerServiceClient.getFundsRegisterByID(id, fr => resolve(fr as BankAccountFundsRegister));
		} else {
			const fr = new api.fundspackage.BankAccountFundsRegister();
			fr.Bank = new api.referencepackage.Bank();
			fr.LegalEntity = new api.counterpartypackage.CounterpartyBrief();
			resolve(fr);
		}
	});
});

const getLegalEntity = createLoader<LegalEntity>((api, getState, dispatch, id: number) => {
	return new Promise((resolve, reject) => {
		if (id > 0) {
			api.counterpartyServiceClient.getCounterpartyByID(id, cp => resolve(cp as LegalEntity));
		} else {
			resolve(createNewLegalEntity());
		}
	});
});

const getTenantEntity = createLoader<LegalEntity>((api, getState, dispatch, id: number) => {
	return new Promise((resolve, reject) => {
		if (id > 0) {
			api.counterpartyServiceClient.getCounterpartyByID(id, cp => resolve(cp as LegalEntity));
		} else {
			resolve(createNewLegalEntity(true));
		}
	});
});

const getEmployee = createLoader<Employee>((api, getState, dispatch, id: number) => {
	return new Promise((resolve, reject) => {
		if (id > 0) {
			api.counterpartyServiceClient.getCounterpartyByID(id, cp => resolve(cp as Employee));
		} else {
			const employee = new api.counterpartypackage.Employee();
			employee.MainBusinessRole = new api.organizationpackage.EmployeeBusinessRole();
			resolve(employee);
		}
	});
});

function loadFullObjects(invoice: Invoice, changedObjects: Partial<Invoice> = {}) {
	return new Promise((resolve, reject) => {
		const loads = [
			changedObjects.Counterparty ? getLegalEntity(invoice.Counterparty.ID) : Promise.resolve(null),
			changedObjects.LegalEntity ? getTenantEntity(invoice.LegalEntity.ID) : Promise.resolve(null),
			changedObjects.FundsRegister ? getBankAccountFundsRegister(invoice.FundsRegister.ID) : Promise.resolve(null),
			changedObjects.SignEmployee ? getEmployee(invoice.SignEmployee.ID) : Promise.resolve(null),
		];
		Promise.all(loads).then(resolve);
	});
}

function uploadImages(changedObjects: Partial<Invoice> = {}) {
	const companySealImage = changedObjects.LegalEntity && changedObjects.LegalEntity.CompanySealImageURL;
	const companyLogo = changedObjects.LegalEntity && changedObjects.LegalEntity.LogoURL;
	const signImage = changedObjects.SignEmployee && changedObjects.SignEmployee.SignImageURL;
	return new Promise((resolve, reject) => {
		const imageUploads = [
			companySealImage
				? api.uploadFile(companySealImage, 'company_seal_image')
				: companySealImage === null
				? Promise.resolve(null)
				: Promise.resolve(undefined),
			companyLogo
				? api.uploadFile(companyLogo, 'tenant_logo')
				: companyLogo === null
				? Promise.resolve(null)
				: Promise.resolve(undefined),
			signImage
				? api.uploadFile(signImage, 'sign_image')
				: signImage === null
				? Promise.resolve(null)
				: Promise.resolve(undefined),
		];
		Promise.all(imageUploads).then(resolve);
	});
}

interface IAddFullObjectExtraParams {
	changedLegalEntity: Partial<CounterpartyBrief>;
	changedCounterparty: Partial<CounterpartyBrief>;
}

function addFullObjects(
	results: [[LegalEntity, LegalEntity, BankAccountFundsRegister, Employee], [string, string, string]],
	getState,
	dispatch,
	invoice: Invoice,
	extraParams: IAddFullObjectExtraParams,
) {
	return new Promise(resolve => {
		const [counterpartyFull, legalEntityFull, fundsRegisterFull, signEmployeeFull] = results[0];
		const [companySealImageURL, logoURL, signImageURL] = results[1];

		if (counterpartyFull) {
			counterpartyFull.Name = invoice.Counterparty.Name;
			counterpartyFull.TaxCode = invoice.Counterparty.TaxCode;
			counterpartyFull.AdditionalTaxCode = invoice.Counterparty.AdditionalTaxCode;
			counterpartyFull.StateRegistrationCode = invoice.Counterparty.StateRegistrationCode;
		}

		if (legalEntityFull) {
			if (companySealImageURL || companySealImageURL === null) {
				legalEntityFull.CompanySealImageURL = companySealImageURL || '';
			}
			if (logoURL || logoURL === null) {
				legalEntityFull.LogoURL = logoURL || '';
			}
			legalEntityFull.Name = invoice.LegalEntity.Name;
			legalEntityFull.TaxCode = invoice.LegalEntity.TaxCode;
			legalEntityFull.AdditionalTaxCode = invoice.LegalEntity.AdditionalTaxCode;
			legalEntityFull.StateRegistrationCode = invoice.LegalEntity.StateRegistrationCode;
		}

		if (fundsRegisterFull) {
			if (logoURL || logoURL === null) {
				fundsRegisterFull.LogoURL = logoURL || '';
			}
			fundsRegisterFull.Bank = invoice.FundsRegister.Bank;
			fundsRegisterFull.RegisterNumber = invoice.FundsRegister.Number;
			fundsRegisterFull.Name = invoice.FundsRegister.Number;
			fundsRegisterFull.LegalEntity.ID = invoice.LegalEntity.ID;
			fundsRegisterFull.CurrencyID = invoice.FundsRegister.CurrencyID > 0 ? invoice.FundsRegister.CurrencyID : 1;
		}

		if (signEmployeeFull) {
			if (signImageURL || signImageURL === null) {
				signEmployeeFull.SignImageURL = signImageURL || '';
			}
			if (!signEmployeeFull.MainBusinessRole) {
				signEmployeeFull.MainBusinessRole = new api.organizationpackage.EmployeeBusinessRole();
			}
			signEmployeeFull.MainBusinessRole.ID = invoice.SignEmployee.BusinessRole.ID;
			signEmployeeFull.Name = invoice.SignEmployee.Name;
			signEmployeeFull.BirthDate = '01-01-1900 00:00:00';
		}

		const actions = [
			addEmployee(api, getState, dispatch, signEmployeeFull),
			processCounterparty(api, getState, dispatch, counterpartyFull, extraParams.changedCounterparty),
			processCounterparty(api, getState, dispatch, legalEntityFull, extraParams.changedLegalEntity).then(
				legalEntityFull => {
					if (legalEntityFull && fundsRegisterFull) {
						fundsRegisterFull.LegalEntity.ID = legalEntityFull.ID;
					}
					return addFundsRegister(api, getState, dispatch, fundsRegisterFull).then(fundsRegisterFull =>
						Promise.resolve({ fundsRegisterFull, legalEntityFull }),
					);
				},
			),
		] as Array<Promise<any>>;

		Promise.all(actions).then(resolve);
	});
}

function saveInvoice(results, invoice: Invoice, dispatch) {
	return new Promise((resolve, reject) => {
		const [signEmployeeFull, counterpartyFull, { fundsRegisterFull, legalEntityFull }] = results;

		if (fundsRegisterFull) {
			if (invoice.FundsRegister) {
				invoice.FundsRegister.ID = fundsRegisterFull.ID;
			} else {
				invoice.FundsRegister = {
					...new api.fundspackage.FundsRegisterBrief(),
					ID: fundsRegisterFull.ID,
				};
			}

			dispatch(invalidateFundsRegisterStatistics());
		}
		if (signEmployeeFull) {
			invoice.SignEmployee.ID = signEmployeeFull.ID;
			invoice.SignEmployee.SignImageURL = signEmployeeFull.SignImageURL;
		}
		if (counterpartyFull) {
			invoice.Counterparty.ID = counterpartyFull.ID;
		}
		if (legalEntityFull) {
			invoice.LegalEntity.ID = legalEntityFull.ID;
			invoice.LegalEntity.LogoURL = legalEntityFull.LogoURL;
			invoice.LegalEntity.CompanySealImageURL = legalEntityFull.CompanySealImageURL;
		}

		if (invoice.ID > 0) {
			const { isDraft, isPaid } = getInvoiceStatuses(invoice);

			if (invoice.Outgoing && !isDraft && !isPaid) {
				changeMethods.checkInvoiceCanBeModifiedMethod(invoice.ID).then((msg: SuccessMessage) => {
					if (msg.Success) {
						api.financialDocumentServiceClient.changeDocument(invoice, resolve);
					} else {
						dispatch(changeActions.emitInvoiceCanNotBeModifiedAction());
						resolve(invoice);
					}
				});
			} else {
				api.financialDocumentServiceClient.changeDocument(invoice, resolve);
			}
		} else {
			api.financialDocumentServiceClient.addDocument(invoice, -1, resolve);
		}
	});
}

const addInvoice = (
	api: SeenecoApi,
	getState,
	dispatch,
	invoice: Invoice,
	changedObjects: Partial<Invoice> = {},
	addDraft = false,
) => {
	const extraParams: IAddFullObjectExtraParams = {
		changedLegalEntity: changedObjects.LegalEntity ? changedObjects.LegalEntity : null,
		changedCounterparty: changedObjects.Counterparty ? changedObjects.Counterparty : null,
	};

	if (changedObjects.LegalEntity) {
		invoice.LegalEntity = {
			...(invoice.LegalEntity || new api.counterpartypackage.CounterpartyBrief()),
			...changedObjects.LegalEntity,
		};
	}
	if (changedObjects.FundsRegister) {
		invoice.FundsRegister = {
			...(invoice.FundsRegister || new api.fundspackage.FundsRegisterBrief()),
			...changedObjects.FundsRegister,
		};
	}
	if (changedObjects.Counterparty) {
		invoice.Counterparty = {
			...(invoice.Counterparty || new api.counterpartypackage.CounterpartyBrief()),
			...changedObjects.Counterparty,
		};
	}
	if (changedObjects.SignEmployee) {
		invoice.SignEmployee = {
			...(invoice.SignEmployee || new api.counterpartypackage.EmployeeBrief()),
			...changedObjects.SignEmployee,
		};
	}

	const loads = [loadFullObjects(invoice, changedObjects), uploadImages(changedObjects)];
	return Promise.all(loads)
		.then(results => addFullObjects(results, getState, dispatch, invoice, extraParams))
		.then(results => saveInvoice(results, invoice, dispatch))
		.then((invoice: Invoice) => {
			dispatch(fetchInvoiceByIDAction(invoice.ID));

			if (addDraft) {
				dispatch(addInvoicePaymentDraftAction(invoice));
			}

			return invoice;
		});
};

const REMOVE_INVOICE = '[INVOICE]: REMOVE_INVOICE';
const removeInvoice = (api, getState, dispatch, invoiceID: number) => {
	return new Promise(resolve => {
		api.financialDocumentServiceClient.removeDocument(invoiceID, (result: boolean) => {
			resolve(result);
		});
	});
};

const removeInvoiceAction = createAction(REMOVE_INVOICE, removeInvoice, {
	showMessage: type => {
		return type == 'RECEIVE' && createActionMessage('Счёт удалён', 'success');
	},
});

const SHOW_INVOICE_INCOMING_FORM = '[INVOICE]: SHOW_INVOICE_INCOMING_FORM';
function showInvoiceIncomingFormAction(data?: { showPaymentResult: boolean }) {
	return createStaticDataAction(SHOW_INVOICE_INCOMING_FORM)(data);
}

const HIDE_INVOICE_INCOMING_FORM = '[INVOICE]: HIDE_INVOICE_INCOMING_FORM';
function hideInvoiceIncomingFormAction() {
	return createStaticDataAction(HIDE_INVOICE_INCOMING_FORM)();
}

const COPY_INVOICE_LINK = '[INVOICE]: COPY_INVOICE_LINK';
const copyInvoiceLinkAction = createAction(COPY_INVOICE_LINK, () => Promise.resolve(), {
	showMessage: type => type == 'RECEIVE' && createActionMessage('Ссылка скопирована 😊', 'success'),
});

const SET_MODE_FOR_INVOICE_FORM = '[INVOICE]: SET_MODE_FOR_INVOICE_FORM';
function setModeForInvoiceFormAction(params: { wizardMode: boolean }) {
	return createStaticDataAction(SET_MODE_FOR_INVOICE_FORM)(params);
}

export default createAction(ADD_INVOICE, addInvoice);
export {
	COPY_INVOICE_LINK,
	copyInvoiceLinkAction,
	HIDE_INVOICE_INCOMING_FORM,
	hideInvoiceIncomingFormAction,
	REMOVE_INVOICE,
	removeInvoiceAction,
	SET_MODE_FOR_INVOICE_FORM,
	setModeForInvoiceFormAction,
	SHOW_INVOICE_INCOMING_FORM,
	showInvoiceIncomingFormAction,
};
