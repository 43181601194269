import React from 'react';

import { BoxProps } from '@ui/box';
import { Root } from './styled';

export type ContainerProps = {
	appearance?: 'default' | 'shaded';
	children: React.ReactNode;
} & BoxProps;

class Container extends React.Component<ContainerProps> {
	static displayName = 'Container';
	static defaultProps = {
		appearance: 'default',
	};

	render() {
		return <Root {...this.props} />;
	}
}

export { Container };
