/***
 * Library contains JavaScript API for interaction with Seeneco Framework Web Services contained in DataPackage
 * @author AlexPlex Generated by Seeneco Framework Modeler 
 *
 */
(function(w){
/***
* 
*/
w.ImportStandardDataRequest = function() {
  this.PACKAGE = "DATA";
  this.CLASSIFIER = "IMPORT_STANDARD_DATA_REQUEST";
  this.CopySupportingElement = false;
  this.WithDemoDataImport = false;
};
 
 

/***
 * Client API to TenantStandardDataServiceWS web service
 */
w.TenantStandardDataServiceClient = function() {
  /***
   * System name of target service
   */
  this.serviceName = "TenantStandardDataServiceWSImpl";
};

/***
 * [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.TenantStandardDataServiceClient.prototype.removeStandardData = function(callbackHandler) {
  var parameterNames = [];
  var parameterValues = [];
  var soapMessage = buildSOAPMessage("removeStandardData", parameterNames, parameterValues);
  var resultHasComplexType = false;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param request [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.TenantStandardDataServiceClient.prototype.importStandardData = function(request, callbackHandler) {
  var parameterNames = ["request"];
  var requestJSON = JSON.stringify(request);
  var parameterValues = [requestJSON];
  var soapMessage = buildSOAPMessage("importStandardData", parameterNames, parameterValues);
  var resultHasComplexType = false;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};


/***
 * Package security rights constants
 */

w.DataSecurityRights = {

};
})(typeof exports !== 'undefined' ? exports : window);
