import { Store } from 'redux';

import { IAppState } from '@store';
import { createBooleanMap } from '@utils/object';
import { taskApi, ServerStatusCode, OnCheckOptions } from '@core/api/task';
import { types as platformTypes } from '@platform/actions/types';
import { platformActionsPack } from '@platform/actions/main.actions';
import { setAppProgressBarOptionsAction } from '@shared/actions/ui.actions';
import { selectAppProgressBarOptions } from '@shared/selectors/ui.selectors';

function createCheckServerTasksEffect() {
	return (store: Store<IAppState>) => (next: (action: StoreAction) => void) => (action: StoreAction) => {
		const onCheck = (options: OnCheckOptions) => {
			const { isFinished, instances, statusCode } = options;
			const isOpen = selectAppProgressBarOptions(store.getState());
			const isWaiting = statusCode === ServerStatusCode.WAITING;

			if (isFinished) {
				const idsMap = createBooleanMap(instances, x => x?.Task?.ID || -1);
				const hasFirstTask = idsMap[IMPORT_ACCOUNTS_FROM_CMS_TASK_ID];
				const hasSecondTask = idsMap[IMPORT_HISTORICAL_ACCOUNT_STATEMENT_FROM_CMS_TASK_ID];

				hasFirstTask && store.dispatch(platformActionsPack.notifyAboutImportAccountsFromCmsTaskCompleted());
				hasSecondTask &&
					store.dispatch(platformActionsPack.notifyAboutImportHistoricalAccountStatementFromCmsTaskCompleted());

				if (isOpen) {
					store.dispatch(
						setAppProgressBarOptionsAction({
							isOpen: false,
							text: '',
							value: 0,
						}),
					);
				}
			} else {
				store.dispatch(
					setAppProgressBarOptionsAction({
						isOpen: true,
						text: 'Загрузка данных в систему',
						value: isWaiting ? 30 : 60,
					}),
				);
			}
		};

		if (action.type === platformTypes.RUN_CHECK_SERVER_TASKS_EFFECT) {
			taskApi.serverTask
				.checkTasks({
					IDs: [IMPORT_ACCOUNTS_FROM_CMS_TASK_ID, IMPORT_HISTORICAL_ACCOUNT_STATEMENT_FROM_CMS_TASK_ID],
					onCheck,
				})
				.then(() => {});
		}

		next(action);
	};
}

const IMPORT_ACCOUNTS_FROM_CMS_TASK_ID = 1;
const IMPORT_HISTORICAL_ACCOUNT_STATEMENT_FROM_CMS_TASK_ID = 2;

const runCheckServerTasksEffect = createCheckServerTasksEffect();

export { runCheckServerTasksEffect };
