import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { ErrorBoundary } from '@ui/error-boundary';
import { filterRoutes, ROUTE_MAP } from '@routes/urls';
import { RenderRoute, IRenderRouteProps } from '@routes/workspace';
import { NestedLinks } from './nested-links';
import { Root } from './styled';

const NestedRoutes: React.FC<IRenderRouteProps> = args => {
	return (
		<Root>
			<NestedLinks parentRoute={{ ...args }} />
			<ErrorBoundary>
				<NestedWorkspace {...args} />
			</ErrorBoundary>
		</Root>
	);
};

const NestedWorkspace: React.FC<IRenderRouteProps> = args => {
	const links = filterRoutes(args.availableComponents, args.userRights, args.nestedRoutes, args.isFullAccess);
	const redirectUrl = links[0]?.path || ROUTE_MAP.TENANT_ACCOUNT;
	const Routes = links.map(link => (
		<RenderRoute
			{...link}
			key={link.sysName}
			availableComponents={args.availableComponents}
			userRights={args.userRights}
		/>
	));

	return (
		<Switch>
			{Routes}
			<Redirect to={redirectUrl} />
		</Switch>
	);
};

export { NestedRoutes };
