import styled, { css } from 'styled-components';

const Root = styled.div`
	position: relative;
	width: 100%;
	height: auto;
	margin-bottom: 140px;
`;

const HeaderLayout = styled.div``;

const TitleLayout = styled.div`
	position: relative;
	width: 100%;
	height: auto;
	min-height: 70px;
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;
	align-items: center;
`;

const ExtraLayout = styled.div`
	position: relative;
	width: 100%;
	height: auto;
	min-height: 72px;
	max-width: 100%;
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;
	align-items: center;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.07;
	text-align: left;
	text-transform: none;
`;

const ContentLayout = styled.div`
	position: relative;
	width: 100%;
	height: auto;
	padding: 26px;
`;

const Divider = styled.div`
	width: calc(100% + 80px);
	height: 1px;
	margin: 0 -40px;

	${p => css`
		background-color: ${p.theme.palette.stealth};
	`}
`;

export { Root, HeaderLayout, TitleLayout, ExtraLayout, ContentLayout, Divider };
