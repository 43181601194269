import { combineReducers } from 'redux';

import { MainProjectManagementState, mainProjectManagementReducer } from './main.reducer';

export type ProjectManagementState = {
	main: MainProjectManagementState;
};

const projectManagementReducer = combineReducers<ProjectManagementState>({
	main: mainProjectManagementReducer,
});

export default projectManagementReducer;
