import {
	StoreAsyncItem,
	checkAsyncAction,
	createAsyncInitialState,
	createReducer,
	invalidateStateFromAction,
} from '@flux';
import { types } from '@documents/actions/types';

export type mainDocumentsState = {
	contracts: StoreAsyncItem<Array<Contract>>;
};

const initialState: mainDocumentsState = {
	contracts: createAsyncInitialState([]),
};

const mainDocumentsReducer = createReducer<mainDocumentsState>(initialState, {
	[types.INVALIDATE_CONTRACTS]: (action: StaticAction, state) => {
		return {
			contracts: invalidateStateFromAction(action, state.contracts),
		};
	},
	[types.FETCH_CONTRACTS]: (action: AsyncAction<Array<Contract>>, state) => {
		return {
			contracts: checkAsyncAction(action, state.contracts),
		};
	},
});

export default mainDocumentsReducer;
