import React from 'react';

import { IconBase, IconBaseProps } from './base';

export type DeleteIconProps = IconBaseProps;

const DeleteIcon: React.FC<DeleteIconProps> = props => {
	return (
		<IconBase {...props}>
			<svg
				stroke='currentColor'
				fill='currentColor'
				strokeWidth='0'
				viewBox='0 0 24 24'
				xmlns='http://www.w3.org/2000/svg'>
				<path d='M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z'></path>
			</svg>
		</IconBase>
	);
};

export { DeleteIcon };
