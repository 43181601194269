import styled, { css } from 'styled-components';

import type { SlideContentState } from './slide-content';

type RootProps = {} & Pick<SlideContentState, 'isOpen'>;

const Root = styled.div<RootProps>`
	position: fixed;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	max-width: 100%;
	height: 90vh;
	max-height: 100%;
	z-index: 1090;
	transform: translateY(100%);
	will-change: transform;
	transition: transform 400ms cubic-bezier(0, 0, 0.2, 1) 0ms;
	display: flex;
	flex-flow: column;

	${p => css`
		background-color: ${p.theme.palette.space};
		${p.theme.fn.createBoxShadow(8)}
	`}

	${p =>
		p.isOpen &&
		css`
			transform: translate(0, 0);
		`}
`;

const ContentLayout = styled.div`
	position: relative;
	overflow-y: auto;
	flex: 1 1 auto;
	display: flex;
	flex-flow: column nowrap;
`;

export { Root, ContentLayout };
