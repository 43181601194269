function useExternalServiceResponse(response: ExternalServiceResponse) {
	const isAuthorizationSuccess = response.AuthorizationSuccess;
	const isServiceAvailable = response.ServiceAvailable;
	const success = isAuthorizationSuccess && isServiceAvailable;
	const systemMessage = !isAuthorizationSuccess
		? 'Ошибка авторизации вызова при обращении к банку.'
		: !isServiceAvailable
			? 'Сервис на стороне банка недоступен.'
			: '';
	const message = [systemMessage, response.Message].filter(Boolean).join('. ');

	return {
		success,
		message,
		isAuthorizationSuccess,
		isServiceAvailable,
	};
}

export {
	useExternalServiceResponse,
};
