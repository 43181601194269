import { compose } from 'redux';

import { IAppState } from '@store';
import { createSelector, createAsyncSelector, createRightCheck } from '@flux';
import { sort, SortingDirection } from '@utils/sorting';
import { today } from '@utils/date';
import { createObjectMap } from '@utils/object';
import { getOperationsStatistics } from '@utils/operations';
import { OperationsStatisticsSliceCode, OperationDateType, OperationSorting, MODULE_PREFERENCE_CODE } from '@pl/model';

const selectOperationsRight = createRightCheck({
	component: 'ProfitLossManagement',
	view: 'xPLOperations_ViewOperations',
	change: 'xPLOperations_ChangeOperations',
});

const selectAsyncModulePreferences = createAsyncSelector<Record<MODULE_PREFERENCE_CODE, string>, IAppState>({
	get: s => s.operations.main.preferences,
	selector: createSelector(
		s => s.operations.main.preferences.item,
		item => item,
	),
});

const selectAsyncOperations = createAsyncSelector<Array<PLOperationBrief>, IAppState>({
	get: s => s.operations.main.operations,
	selector: createSelector(
		s => s.operations.main.operations.item,
		item => item,
	),
});

const selectAsyncFundsRegisterStatistics = createAsyncSelector<Array<FundsRegisterStatistics>, IAppState>({
	get: s => s.operations.main.fundsRegisterStatistics,
	selector: createSelector(
		s => s.operations.main.fundsRegisterStatistics.item,
		item => item,
	),
});

const selectFilteredOperations = createSelector(
	selectAsyncOperations.selectItem,
	selectDateSorting,
	selectDateType,
	selectTextFilter,
	(sourceOperations, dateSorting, dateType, sourceSearchText) => {
		const dateSortingDirectionsMap = {
			[OperationSorting.DATE_DESC]: SortingDirection.DESC,
			[OperationSorting.DATE_ASC]: SortingDirection.ASC,
		};
		const searchText = sourceSearchText.toLocaleLowerCase().trim();
		const operations = compose(
			(operations: Array<PLOperationBrief>) =>
				sort(operations, [
					{
						fn: x => (dateType === OperationDateType.ACCRUAL ? x.AccrualDate : x.CashflowDate),
						direction: dateSortingDirectionsMap[dateSorting],
						isDate: true,
					},
					{ fn: x => (x.MirrorPaymentID > 0 ? -1 : 1), direction: SortingDirection.DESC },
					{ fn: x => (x.Split ? x.PaymentID || -1 : x.ID), direction: SortingDirection.DESC },
					{ fn: x => x.ID, direction: SortingDirection.DESC },
				]),
			(operations: Array<PLOperationBrief>) =>
				searchText ? operations.filter(x => filterOperationBySearchText(x, searchText)) : operations,
		)(sourceOperations);

		return operations;
	},
);

function filterOperationBySearchText(operation: PLOperationBrief, searchText: string) {
	const isMatchDescription = (operation?.Description || '').toLocaleLowerCase().indexOf(searchText) !== -1;
	const isMatchCounterpartyName = (operation?.Counterparty?.Name || '').toLocaleLowerCase().indexOf(searchText) !== -1;
	const isMatchCounterpartyTaxCode =
		(operation?.Counterparty?.TaxCode || '').toLocaleLowerCase().indexOf(searchText) !== -1;
	const isMatchComment = (operation?.Comment || '').toLocaleLowerCase().indexOf(searchText) !== -1;

	return isMatchDescription || isMatchCounterpartyName || isMatchCounterpartyTaxCode || isMatchComment;
}

function selectDateSorting(state: IAppState) {
	return state.operations.main.dateSorting;
}

function selectDateRange(state: IAppState) {
	return state.operations.main.dateRange;
}

function selectTextFilter(state: IAppState) {
	return state.operations.main.textFilter;
}

function selectDateType(state: IAppState) {
	return state.operations.main.dateType;
}

function selectIsIncludesVAT(state: IAppState) {
	return state.operations.main.isIncludesVAT;
}

function selectIsSeparateVAT(state: IAppState) {
	return state.operations.main.isSeparateVAT;
}

function selectDirectionFilter(state: IAppState) {
	return state.operations.main.directionFilter;
}

function selectStatusFilter(state: IAppState) {
	return state.operations.main.statusFilter;
}

function selectPersonalFilter(state: IAppState) {
	return state.operations.main.personalFilter;
}

function selectCashflowTypesFilter(state: IAppState) {
	return state.operations.main.cashflowTypesFilter;
}

function selectBusinessUnitsFilter(state: IAppState) {
	return state.operations.main.businessUnitsFilter;
}

function selectProjectsFilter(state: IAppState) {
	return state.operations.main.projectsFilter;
}

function selectCashflowItemsFilter(state: IAppState) {
	return state.operations.main.cashflowItemsFilter;
}

function selectCounterpartiesFilter(state: IAppState) {
	return state.operations.main.counterpartiesFilter;
}

function selectFundsRegistersFilter(state: IAppState) {
	return state.operations.main.fundsRegistersFilter;
}

function selectEmployeesFilter(state: IAppState) {
	return state.operations.main.employeesFilter;
}

const selectOperationsStatistics = createSelector(selectFilteredOperations, operations => {
	const now = today();
	const {
		incomeFactTotal,
		expenseFactTotal,
		incomeForecastTotal,
		expenseForecastTotal,
		resultFactTotal,
		resultForecastTotal,
	} = getOperationsStatistics(operations, now);

	return {
		dataFact: {
			[OperationsStatisticsSliceCode.INCOME]: incomeFactTotal,
			[OperationsStatisticsSliceCode.EXPENSE]: expenseFactTotal,
			[OperationsStatisticsSliceCode.RESULT]: resultFactTotal,
		},
		dataForecast: {
			[OperationsStatisticsSliceCode.INCOME]: incomeForecastTotal,
			[OperationsStatisticsSliceCode.EXPENSE]: expenseForecastTotal,
			[OperationsStatisticsSliceCode.RESULT]: resultForecastTotal,
		},
	};
});

const selectFundsRegisterStatisticsMap = createSelector(selectAsyncFundsRegisterStatistics.selectItem, frs =>
	createObjectMap(frs, x => x.FundsRegister.ID),
);

const selectFundsRegistersMap = createSelector(selectAsyncFundsRegisterStatistics.selectItem, frs =>
	createObjectMap(
		frs.map(x => x.FundsRegister),
		x => x.ID,
	),
);

export const mainOperationsSelectorsPack = {
	selectOperationsRight,
	selectAsyncModulePreferences,
	selectAsyncOperations,
	selectAsyncFundsRegisterStatistics,
	selectFilteredOperations,
	selectDateRange,
	selectDateSorting,
	selectDateType,
	selectIsIncludesVAT,
	selectIsSeparateVAT,
	selectTextFilter,
	selectDirectionFilter,
	selectStatusFilter,
	selectPersonalFilter,
	selectCashflowTypesFilter,
	selectBusinessUnitsFilter,
	selectProjectsFilter,
	selectCashflowItemsFilter,
	selectCounterpartiesFilter,
	selectFundsRegistersFilter,
	selectEmployeesFilter,
	selectOperationsStatistics,
	selectFundsRegisterStatisticsMap,
	selectFundsRegistersMap,
};

export { selectOperationsRight };
