import React from 'react';

import { getGlobalConfig } from '@config';
import { AppMenuItem } from '../app-menu-item';
import { Root } from './styled';

export type AppMenuProps = {
	links: Array<IPageRoute>;
	isBfmApp: boolean;
};

const AppMenu: React.FC<AppMenuProps> = ({ links, isBfmApp }) => {
	return (
		<Root>
			<ul>
				{links.map(({ path, sysName, name, disabled, tooltipText }) => {
					return (
						<AppMenuItem
							key={path}
							path={path}
							sysName={sysName}
							name={name}
							disabled={disabled}
							tooltipText={tooltipText}
						/>
					);
				})}
			</ul>
			<ul>
				<AppMenuItem
					path={`${location.origin}/help/${isBfmApp ? 'bfm' : 'invoice'}`}
					sysName='help'
					name='Справка'
					withReload
				/>
				{isSeeneco && <AppMenuItem path={`${location.origin}/hub`} sysName='hub' name='Магазин' withReload />}
				{!isSovcombank && <AppMenuItem path={`${location.origin}/demo`} sysName='demo' name='Демо-бизнес' withReload />}
			</ul>
		</Root>
	);
};

const { isSeeneco, isSovcombank } = getGlobalConfig();

export { AppMenu };
