import React, { useState, useLayoutEffect, useCallback, useMemo, useRef } from 'react';

import { hasBooleanKeys } from '@utils/object';
import { detectIsFunction } from '@utils/helpers';
import { AutopickerProps, AutopickerOnChangeOptions, AutopickerInnerRef } from '@ui/autopicker';
import { useFormContext, FormState } from '@ui/forms';

type WithFormAutopickerProps<Item> = {
	name: string;
	briefObjectName?: string;
	required?: boolean;
	silentErrors?: boolean;
} & AutopickerProps<Item>;

function withFormAutopicker<Props, Item>(WrappedComponent: React.ComponentType<Props>) {
	const EnhancedComponent: React.FC<WithFormAutopickerProps<Item>> = props => {
		const { name, briefObjectName, required, transformInput, silentErrors, onChange, innerRef, ...rest } = props;
		const { formObject, handleObjectChange, deleteBriefObject, addFormValidation, removeFormValidation } =
			useFormContext();
		const [errorText, setErrorText] = useState('');
		const rootRef = innerRef || useRef<AutopickerInnerRef>(null);
		const scope = useMemo(
			() => ({
				name,
				briefObjectName,
				required,
				transformInput,
				silentErrors,
				errorText,
				onChange,
				formObject,
				handleObjectChange,
			}),
			[],
		);
		const value = getValueByName({ name, briefObjectName, formObject });

		scope.name = name;
		scope.briefObjectName = briefObjectName;
		scope.required = required;
		scope.transformInput = transformInput;
		scope.silentErrors = silentErrors;
		scope.errorText = errorText;
		scope.onChange = onChange;
		scope.formObject = formObject;
		scope.handleObjectChange = handleObjectChange;

		// useLayoutEffect is important
		useLayoutEffect(() => {
			addFormValidation(detectHasValue);

			return () => removeFormValidation(detectHasValue);
		}, []);

		const detectHasValue = useCallback((state: FormState<unknown>): boolean => {
			const { name, briefObjectName, required, silentErrors, errorText, transformInput } = scope;
			const value = getValueByName({
				name,
				briefObjectName,
				formObject: state.formObject,
			});
			const transformedValue = transformInput({ input: value });
			const isValid = required ? Boolean(transformedValue && hasBooleanKeys(transformedValue)) : true;

			if (!isValid) {
				setErrorText(silentErrors ? ' ' : 'Это обязательное поле');
				rootRef.current?.scrollIntoView();
			} else if (errorText) {
				setErrorText('');
			}

			return isValid;
		}, []);

		const handleChange = useCallback((options: AutopickerOnChangeOptions<Item>) => {
			const { name, briefObjectName, formObject, errorText, handleObjectChange, onChange } = scope;
			const { newValue } = options;

			deleteBriefObject(name);
			setValueByName({ value: newValue, name, briefObjectName, formObject });
			handleObjectChange(formObject);
			detectIsFunction(onChange) && onChange(options);
			errorText && setErrorText('');
		}, []);

		return (
			<WrappedComponent
				{...(rest as any)}
				innerRef={rootRef}
				name={name}
				briefObjectName={briefObjectName}
				value={value}
				errorText={errorText}
				required={required}
				transformInput={transformInput}
				onChange={handleChange}
			/>
		);
	};

	EnhancedComponent.defaultProps = {
		getID: (item: any) => (item ? item.ID : -1),
		getName: (item: any) => (item ? item.Name : ''),
		transformInput: ({ input }) => input,
		transformOutput: ({ output }) => output,
	};

	type ReturnType = React.ComponentType<
		Omit<Props, 'value' | 'onChange'> &
			Omit<WithFormAutopickerProps<Item>, 'dataSource' | 'isFetching' | 'isLoaded' | 'value' | 'onChange'> &
			Partial<Pick<WithFormAutopickerProps<Item>, 'onChange'>>
	>;

	return EnhancedComponent as unknown as ReturnType;
}

type SetValueByNameOptions<T> = {
	value: any;
	name: string;
	briefObjectName: string;
	formObject: T;
};

function setValueByName<T = any>(options: SetValueByNameOptions<T>) {
	const { value, name, briefObjectName, formObject } = options;

	if (briefObjectName) {
		if (formObject[briefObjectName]) {
			formObject[briefObjectName][name] = value;
		}
	} else {
		formObject[name] = value;
	}
}

type GetValueByNameOptions<T> = {
	name: string;
	briefObjectName: string;
	formObject: T;
};

function getValueByName<T = any>(options: GetValueByNameOptions<T>) {
	const { name, briefObjectName, formObject } = options;

	if (briefObjectName) {
		return formObject[briefObjectName] ? formObject[briefObjectName][name] : null;
	}

	return formObject[name];
}

export { withFormAutopicker };
