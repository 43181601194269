import React from 'react';

import { useMapState } from '@flux';
import { selectBillingServiceStatus, selectTenantAccount } from '@platform/selectors/billing.selectors';
import { BillingAlert as XBillingAlert } from './billing-alert.view';

type BillingAlertProps = {};

const BillingAlert: React.FC<BillingAlertProps> = props => {
	const [status, tenantAccount] = useMapState([selectBillingServiceStatus, selectTenantAccount]);

	return <XBillingAlert status={status} tenantAccount={tenantAccount} />;
};

export { BillingAlert };
