import { Store } from 'redux';

import { IAppState } from '@store';
import { isReceiveAction } from '@flux';
import { FETCH_TENANT_ACCOUNT } from '@platform/actions/billing.actions';
import { routingActionsPack } from '@platform/actions/routing.actions';
import { detectIsProfessionalEdition } from '@platform/shared/utils';

const scope = {
	isProfessionalEdition: null,
};

function createRoutingEffect() {
	return (store: Store<IAppState>) => (next: (action: StoreAction) => void) => (action: AsyncAction) => {
		if (action.type === FETCH_TENANT_ACCOUNT && isReceiveAction(action)) {
			const tenantAccount = action.response as TenantAccount;
			const isProfessionalEdition = detectIsProfessionalEdition(tenantAccount);

			if (scope.isProfessionalEdition === null || scope.isProfessionalEdition !== isProfessionalEdition) {
				store.dispatch(routingActionsPack.actions.injectAppRoutes({ isProfessionalEdition }));
				scope.isProfessionalEdition = isProfessionalEdition;
			}
		}

		next(action);
	};
}

const runRoutingEffect = createRoutingEffect();

export { runRoutingEffect };
