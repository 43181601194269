import React, {
	useRef,
	useState,
	useLayoutEffect,
	useEffect,
	useCallback,
	forwardRef,
	useImperativeHandle,
	useMemo,
} from 'react';
import { createPortal } from 'react-dom';

import { dispatchResizeEvent } from '@utils/dom';
import { detectIsFunction } from '@utils/helpers';
import { useMounted } from '@core/hooks/use-mounted';
import { CloseIcon } from '@ui/icons/close';
import { ANIMATION_DURATION, Root, Content, CloseButtonLayout, CloseButton } from './styled';

export type TooltipProps = {
	appearance?: 'default' | 'box';
	isOpen: boolean;
	position?: TooltipPosition;
	scrollContainer?: Document | HTMLElement;
	style?: React.CSSProperties;
	children: React.ReactNode | (() => React.ReactNode);
	onMouseOverContent?: (e: React.MouseEvent<HTMLDivElement>) => void;
	onMouseLeaveContent?: (e: React.MouseEvent<HTMLDivElement>) => void;
	onRequestClose?: () => void;
};

export type TooltipRef = {
	wrapper: HTMLDivElement;
	updatePosition: () => void;
};

const Tooltip = forwardRef<TooltipRef, TooltipProps>((props, ref) => {
	const {
		appearance,
		isOpen,
		position,
		scrollContainer,
		style,
		children,
		onMouseOverContent,
		onMouseLeaveContent,
		onRequestClose,
	} = props;
	const [parentRect, setParentRect] = useState<DOMRect>(null);
	const [contentRect, setContentRect] = useState<DOMRect>(null);
	const [isOpenAnimated, setIsOpenAnimated] = useState(isOpen);
	const wrapperRef = useRef<HTMLDivElement>(null);
	const [contentRef, updateContentRef] = useState<HTMLDivElement>(null);
	const host = useMemo(() => document.createElement('div'), []);

	useEffect(() => {
		dispatchResizeEvent();
	}, [isOpen]);

	useLayoutEffect(() => {
		if (isOpenAnimated) {
			document.body.appendChild(host);
		} else if (document.body === host.parentElement) {
			document.body.removeChild(host);
		}

		return () => {
			document.body === host.parentElement && document.body.removeChild(host);
		};
	}, [isOpenAnimated]);

	useLayoutEffect(() => {
		const timerID = setTimeout(
			() => {
				setIsOpenAnimated(isOpen);
			},
			isOpen ? 0 : ANIMATION_DURATION,
		);

		return () => clearTimeout(timerID);
	}, [isOpen]);

	useImperativeHandle(
		ref,
		() => ({
			wrapper: wrapperRef.current,
			updatePosition: handleRect,
		}),
		[wrapperRef.current],
	);

	useLayoutEffect(() => {
		handleRect();
	}, [wrapperRef.current, contentRef]);

	useLayoutEffect(() => {
		scrollContainer.addEventListener('scroll', handleRect);

		return () => scrollContainer.removeEventListener('scroll', handleRect);
	}, []);

	useLayoutEffect(() => {
		window.addEventListener('resize', handleRect);

		return () => window.removeEventListener('resize', handleRect);
	}, []);

	const setContentRef = useCallback(
		(ref: HTMLDivElement) => {
			if (contentRef !== ref) {
				updateContentRef(ref);
			}
		},
		[contentRef],
	);

	const handleRect = useCallback(() => {
		makeParentRect();
		makeContentRect();
	}, [wrapperRef.current, contentRef]);

	const makeParentRect = useCallback(() => {
		if (!wrapperRef.current) return;
		const parentElement = wrapperRef.current.parentElement;
		const parentRect = parentElement.getBoundingClientRect();

		setParentRect(parentRect);
	}, [wrapperRef.current]);

	const makeContentRect = useCallback(() => {
		if (!contentRef) return;
		const contentRect = contentRef.getBoundingClientRect();

		setContentRect(contentRect);
	}, [contentRef]);

	const handleRootClick = (e: React.MouseEvent) => {
		e.stopPropagation();
	};

	return (
		<div ref={wrapperRef} onClick={handleRootClick}>
			{isOpenAnimated
				? createPortal(
						<Root
							appearance={appearance}
							position={position}
							parentRect={parentRect}
							contentRect={contentRect}
							zIndex={Number(style.zIndex || 0)}>
							<Content
								ref={setContentRef}
								appearance={appearance}
								isOpen={isOpen}
								parentRect={parentRect}
								position={position}
								style={style}
								onMouseOver={onMouseOverContent}
								onMouseLeave={onMouseLeaveContent}>
								{detectIsFunction(onRequestClose) && (
									<CloseButtonLayout>
										<CloseButton onClick={onRequestClose}>
											<CloseIcon color='light' />
										</CloseButton>
									</CloseButtonLayout>
								)}
								{detectIsFunction(children) ? children() : children}
							</Content>
						</Root>,
						host,
				  )
				: null}
		</div>
	);
});

Tooltip.defaultProps = {
	appearance: 'default',
	position: 'right' as TooltipPosition,
	scrollContainer: document,
	style: {},
};

type StatefulTooltipProps = {
	timeout?: number;
} & Omit<TooltipProps, 'isOpen' | 'onMouseOverRoot' | 'onMouseLeaveRoot'>;

export type StatefulTooltipRef = Pick<TooltipRef, 'updatePosition'>;

const StatefulTooltip = forwardRef<StatefulTooltipRef, StatefulTooltipProps>((props, ref) => {
	const { timeout, ...rest } = props;
	const [isOpen, setIsOpen] = useState(false);
	const { mounted } = useMounted();
	const tooltipRef = useRef<TooltipRef>(null);
	const scope = useMemo(
		() => ({
			isMouseOverContent: false,
			isMouseOverTrigger: false,
			timerContentID: null,
			timerTriggerID: null,
		}),
		[],
	);

	useImperativeHandle(
		ref,
		() => ({
			updatePosition: tooltipRef.current.updatePosition,
		}),
		[tooltipRef.current],
	);

	useEffect(() => {
		if (!tooltipRef.current) return;
		const trigger = tooltipRef.current.wrapper.parentElement;

		trigger.addEventListener('mouseover', handleOpen);
		trigger.addEventListener('mouseout', handleClose);

		return () => {
			trigger.removeEventListener('mouseover', handleOpen);
			trigger.removeEventListener('mouseout', handleClose);
		};
	}, [tooltipRef.current]);

	const handleOpen = useCallback(() => {
		scope.isMouseOverTrigger = true;
		scope.isMouseOverContent = false;
		scope.timerTriggerID && clearTimeout(scope.timerTriggerID);
		scope.timerContentID && clearTimeout(scope.timerContentID);
		setIsOpen(true);
	}, []);

	const handleClose = useCallback(() => {
		scope.isMouseOverTrigger = false;
		scope.timerContentID = setTimeout(() => {
			if (!mounted()) return;
			!scope.isMouseOverContent && setIsOpen(false);
		}, timeout);
	}, []);

	const handleMouseOverContent = useCallback(() => {
		scope.isMouseOverContent = true;
	}, []);

	const handleMouseLeaveContent = useCallback(() => {
		scope.isMouseOverContent = false;
		scope.timerTriggerID = setTimeout(() => {
			if (!mounted()) return;
			!scope.isMouseOverTrigger && setIsOpen(false);
		}, timeout);
	}, []);

	return (
		<Tooltip
			{...rest}
			ref={tooltipRef}
			isOpen={isOpen}
			onMouseOverContent={handleMouseOverContent}
			onMouseLeaveContent={handleMouseLeaveContent}
		/>
	);
});

StatefulTooltip.defaultProps = {
	timeout: 100,
};

export type TooltipPosition = 'top' | 'right' | 'bottom' | 'left';

export { Tooltip, StatefulTooltip };
