import { OperationDateType } from '@pl/model';
import { buildTLongArray } from '@utils';
import { fromStringToBoolean } from '@utils/serializer';
import * as accountingpackage from 'accountingpackage-api';
import * as counterpartypackage from 'counterpartypackage-api';
import * as plpackage from 'plpackage-api';

const plApi = {
	package: plpackage,
	marketplaceOperationRule: {
		client: new plpackage.MarketplaceOperationRuleServiceClient(),
		addRule: (rule: MarketplaceOperationRule) => {
			return new Promise<MarketplaceOperationRule>(resolve => {
				plApi.marketplaceOperationRule.client.addRule(rule, result => {
					resolve(result);
				});
			});
		},
		fetchOperationTypes: () => {
			return new Promise<Array<MarketplaceOperationType>>(resolve => {
				plApi.marketplaceOperationRule.client.getOperationTypes(result => {
					resolve(result);
				});
			});
		},
		fetchRules: () => {
			return new Promise<Array<MarketplaceOperationRule>>(resolve => {
				plApi.marketplaceOperationRule.client.getRules(result => {
					resolve(result);
				});
			});
		},
	},
	plOperation: {
		client: new plpackage.PLOperationServiceClient(),
		fetchOperationsBriefs: (options: FetchOperationsOptions) => {
			const {
				dateRange,
				takeBy = 'all',
				accrual = false,
				income = true,
				expense = true,
				transfer = false,
				cashflowTypeIDs = [],
				aciIDs = [],
				leIDs = [],
				cpIDs = [],
				businessUnitsIDs = [],
				projectIDs = [],
				frIDs = [],
				employeeIDs = [],
				inConsolidation = false,
				inCFConsolidation = false,
				inPLConsolidation = false,
				inProjectConsolidation = false,
				inAbcAnalysis = false,
				excludingVat = false,
				personal = 'all',
			} = options;

			return new Promise<Array<PLOperationBrief>>(resolve => {
				const request = new plApi.package.PLOperationListRequest();

				request.DateFrom = dateRange.dateStart;
				request.DateTo = dateRange.dateEnd;
				request.StatusFilter = new plpackage.StatusFilter();
				request.StatusFilter.Fact = takeBy === 'all' || takeBy === 'fact';
				request.StatusFilter.Plan = takeBy === 'all' || takeBy === 'plan';
				request.DirectionFilter = new plpackage.DirectionFilter();
				request.DirectionFilter.Income = income;
				request.DirectionFilter.Expense = expense;
				request.DirectionFilter.Transfer = transfer;
				request.AccrualDateFilter = accrual;
				request.CashflowTypeIDList = buildTLongArray(cashflowTypeIDs);
				request.AccountsChartItemIDList = buildTLongArray(aciIDs);
				request.TenantLegalEntityIDList = buildTLongArray(leIDs);
				request.CounterpartyIDList = buildTLongArray(cpIDs);
				request.BusinessUnitsIDs = buildTLongArray(businessUnitsIDs);
				request.ProjectIDList = buildTLongArray(projectIDs);
				request.FundsRegisterIDList = buildTLongArray(frIDs);
				request.ResponsibleEmployeeIDList = buildTLongArray(employeeIDs);
				request.InConsolidation = inConsolidation;
				request.InCFConsolidation = inCFConsolidation;
				request.InPLConsolidation = inPLConsolidation;
				request.InProjectConsolidation = inProjectConsolidation;
				request.InABCAnalysis = inAbcAnalysis;
				request.ExcludingVat = excludingVat;
				request.Personal = requestPersonalMap[personal];
				plApi.plOperation.client.getOperationsBriefsList(request, result => {
					const isEmptyResponse = (!income && !expense && !transfer) || takeBy === null || personal === null;

					if (isEmptyResponse) {
						resolve([]);
					} else {
						resolve(result);
					}
				});
			});
		},
		addOperation: (operation: PLOperation) => {
			return new Promise<PLOperation>(resolve => {
				if (operation.Status === 2) {
					plApi.plOperation.client.addFactOperation(operation, result => {
						resolve(result);
					});
				} else {
					plApi.plOperation.client.addOperation(operation, result => {
						resolve(result);
					});
				}
			});
		},
		updateOperation: (operation: PLOperation) => {
			return new Promise<PLOperation>(resolve => {
				if (operation.Status === 2) {
					plApi.plOperation.client.updateFactOperation(operation, result => {
						resolve(result);
					});
				} else {
					plApi.plOperation.client.updateOperation(operation, result => {
						resolve(result);
					});
				}
			});
		},
		removeOperation: (ID: number) => {
			return new Promise<boolean>(resolve => {
				plApi.plOperation.client.removeOperation(ID, result => {
					resolve(result);
				});
			});
		},
		removeFactOperation: (ID: number) => {
			return new Promise<boolean>(resolve => {
				plApi.plOperation.client.removeFactOperation(ID, result => {
					resolve(result);
				});
			});
		},
		removePlannedOperations: (ID: number) => {
			return new Promise<boolean>(resolve => {
				plApi.plOperation.client.removePlannedOperationsSeriesByOperationID(ID, result => {
					resolve(result);
				});
			});
		},
		removeOperations: (IDs: Array<number>) => {
			return new Promise<boolean>(async resolve => {
				const results = [];

				for (const ID of IDs) {
					const operation = await plApi.plOperation.fetchOperationByID(ID);
					const isFactOperation = operation.Status === 2;
					const result = isFactOperation
						? await plApi.plOperation.removeFactOperation(ID)
						: await plApi.plOperation.removeOperation(ID);

					results.push(result);
				}

				const isSuccess = results.every(x => x === true);

				resolve(isSuccess);
			});
		},
		fetchOperationByID: (ID: number) => {
			return new Promise<PLOperation>(resolve => {
				plApi.plOperation.client.getOperationByID(ID, result => {
					resolve(result);
				});
			});
		},
		fetchSplitOperations(operationID: number) {
			return new Promise<Array<PLOperation>>(resolve => {
				plApi.plOperation.client.getSplitOperations(operationID, result => {
					resolve(result);
				});
			});
		},
		splitOperation(options: SplitOperationOptions) {
			const { sourcePayment, targetOperations, onComplete = () => {} } = options;

			return new Promise<Array<PLOperation>>(resolve => {
				plApi.plOperation.client.splitOperation(sourcePayment, targetOperations, result => {
					resolve(result);
					onComplete(result);
				});
			});
		},
		updateSplitOperations(options: UpdateSplitOperationsOptions) {
			const { sourcePayment, changeSet, onComplete = () => {} } = options;

			return new Promise<PLOperationChangeSet>(resolve => {
				plApi.plOperation.client.updateSplitOperations(changeSet, sourcePayment, result => {
					resolve(result);
					onComplete(result);
				});
			});
		},
		fetchAccountChartDynamics: (options: FetchAccountChartDynamicsOptions) => {
			const {
				dateRange,
				intervalType = IntervalType.MONTH,
				cashflowDateAggregation = true,
				cashflowTypes = [],
				tenantEntitiesIDs = [],
				fundsRegisterIDs = [],
				businessUnitIDs = [],
				projectIDs = [],
				inConsolidation = false,
				inCFConsolidation = false,
				inPLConsolidation = false,
				excludingVat = false,
				inDashboardConsolidation = false,
				excludingTransfers = true,
			} = options;

			return new Promise<PLOperationDynamics>(resolve => {
				const request: PLOperationAccountsChartItemDynamicsRequest = {
					...new plApi.package.PLOperationAccountsChartItemDynamicsRequest(),
					DateStart: dateRange.dateStart,
					DateFinish: dateRange.dateEnd,
					IntervalType: intervalType,
					CashflowDateAggregation: cashflowDateAggregation,
					CashflowTypes: cashflowTypes.map(ID => ({ ...new accountingpackage.CashflowType(), ID })),
					TenantLegalEntities: tenantEntitiesIDs.map(ID => ({ ...new counterpartypackage.LegalEntity(), ID })),
					FundsRegisterIDList: buildTLongArray(fundsRegisterIDs),
					BusinessUnitsIDs: buildTLongArray(businessUnitIDs),
					ProjectIDList: buildTLongArray(projectIDs),
					InConsolidation: inConsolidation,
					InCFConsolidation: inCFConsolidation,
					InPLConsolidation: inPLConsolidation,
					ExcludingVat: excludingVat,
					InDashboardConsolidation: inDashboardConsolidation,
					ExcludingInternalCashflow: excludingTransfers,
				};

				plApi.plOperation.client.getAccountsChartDynamics(request, result => {
					resolve(result);
				});
			});
		},
		fetchCounterpartyDynamics: (options: FetchCounterpartyDynamicsOptions) => {
			const {
				dateRange,
				intervalType = IntervalType.MONTH,
				cashflowDateAggregation = true,
				cashflowTypes = [],
				tenantEntitiesIDs = [],
				fundsRegisterIDs = [],
				inConsolidation = false,
				inCFConsolidation = false,
				inPLConsolidation = false,
				counterpartyIDs = [],
				excludingTransfers = true,
			} = options;

			return new Promise<PLOperationDynamics>(resolve => {
				const request: PLOperationCounterpartyDynamicsRequest = {
					...new plApi.package.PLOperationCounterpartyDynamicsRequest(),
					DateStart: dateRange.dateStart,
					DateFinish: dateRange.dateEnd,
					IntervalType: intervalType,
					CashflowDateAggregation: cashflowDateAggregation,
					CashflowTypes: cashflowTypes.map(ID => ({ ...new accountingpackage.CashflowType(), ID })),
					TenantLegalEntities: tenantEntitiesIDs.map(ID => ({ ...new counterpartypackage.LegalEntity(), ID })),
					FundsRegisterIDList: buildTLongArray(fundsRegisterIDs),
					InConsolidation: inConsolidation,
					InCFConsolidation: inCFConsolidation,
					InPLConsolidation: inPLConsolidation,
					CounterpartyList: counterpartyIDs.map(ID => ({ ...new counterpartypackage.Counterparty(), ID })),
					ExcludingInternalCashflow: excludingTransfers,
				};

				plApi.plOperation.client.getCounterpartyDynamics(request, result => {
					resolve(result);
				});
			});
		},
		fetchBusinessUnitDynamics: (options: FetchBusinessUnitDynamicsOptions) => {
			const {
				dateRange,
				intervalType = IntervalType.MONTH,
				cashflowDateAggregation = true,
				inConsolidation = false,
				cashflowTypes = [],
				businessUnitIDs = [],
				tenantEntitiesIDs = [],
				excludingTransfers = true,
			} = options;
			return new Promise<PLOperationDynamics>(resolve => {
				const request: PLOperationBusinessUnitDynamicsRequest = {
					...new plApi.package.PLOperationBusinessUnitDynamicsRequest(),
					DateStart: dateRange.dateStart,
					DateFinish: dateRange.dateEnd,
					IntervalType: intervalType,
					CashflowTypes: cashflowTypes.map(ID => ({ ...new accountingpackage.CashflowType(), ID })),
					CashflowDateAggregation: cashflowDateAggregation,
					BusinessUnitList: buildTLongArray(businessUnitIDs),
					TenantLegalEntities: tenantEntitiesIDs.map(ID => ({ ...new counterpartypackage.LegalEntity(), ID })),
					InConsolidation: inConsolidation,
					ExcludingInternalCashflow: excludingTransfers,
				};

				plApi.plOperation.client.getBusinessUnitDynamics(request, result => {
					resolve(result);
				});
			});
		},
		fetchProjectDynamics: (options: FetchProjectDynamicsOptions) => {
			const {
				dateRange,
				intervalType = IntervalType.MONTH,
				cashflowDateAggregation = true,
				inConsolidation = false,
				cashflowTypes = [],
				projectIDs = [],
				tenantEntitiesIDs = [],
				excludingTransfers = true,
				fundsRegisterIDs = [],
			} = options;
			return new Promise<PLOperationDynamics>(resolve => {
				const request: PLOperationProjectDynamicsRequest = {
					...new plApi.package.PLOperationProjectDynamicsRequest(),
					DateStart: dateRange.dateStart,
					DateFinish: dateRange.dateEnd,
					IntervalType: intervalType,
					CashflowTypes: cashflowTypes.map(ID => ({ ...new accountingpackage.CashflowType(), ID })),
					CashflowDateAggregation: cashflowDateAggregation,
					ProjectList: buildTLongArray(projectIDs),
					TenantLegalEntities: tenantEntitiesIDs.map(ID => ({ ...new counterpartypackage.LegalEntity(), ID })),
					InConsolidation: inConsolidation,
					InProjectConsolidation: true,
					ExcludingInternalCashflow: excludingTransfers,
					FundsRegisterIDList: buildTLongArray(fundsRegisterIDs),
				};

				plApi.plOperation.client.getProjectDynamics(request, result => {
					resolve(result);
				});
			});
		},
		fetchCounterpartiesTop: (options: FetchCounterpartiesTopOptions) => {
			const {
				dateRange,
				intervalType = IntervalType.MONTH,
				cashflowDateAggregation = true,
				cashflowTypes = [],
				tenantEntitiesIDs = [],
				fundsRegisterIDs = [],
				businessUnitIDs = [],
				projectIDs = [],
				inConsolidation = false,
				inCFConsolidation = false,
				inPLConsolidation = false,
				inDashboardConsolidation = false,
				excludingVat = false,
				count = 5,
				excludingTransfers = true,
				ownLegalEntityAggregation = false,
			} = options;

			return new Promise<CounterpartiesTopResponse>(resolve => {
				const request: CounterpartiesTopRequest = {
					...new plApi.package.CounterpartiesTopRequest(),
					DateStart: dateRange.dateStart,
					DateFinish: dateRange.dateEnd,
					IntervalType: intervalType,
					CashflowDateAggregation: cashflowDateAggregation,
					CashflowTypes: cashflowTypes.map(ID => ({ ...new accountingpackage.CashflowType(), ID })),
					TenantLegalEntities: tenantEntitiesIDs.map(ID => ({ ...new counterpartypackage.LegalEntity(), ID })),
					FundsRegisterIDList: buildTLongArray(fundsRegisterIDs),
					BusinessUnitsIDs: buildTLongArray(businessUnitIDs),
					ProjectIDList: buildTLongArray(projectIDs),
					InConsolidation: inConsolidation,
					InCFConsolidation: inCFConsolidation,
					InPLConsolidation: inPLConsolidation,
					ExcludingVat: excludingVat,
					Count: count,
					InDashboardConsolidation: inDashboardConsolidation,
					ExcludingInternalCashflow: excludingTransfers,
					OwnLegalEntityAggregation: ownLegalEntityAggregation,
				};
				plApi.plOperation.client.getCounterpartiesTop(request, result => {
					resolve(result);
				});
			});
		},
		fetchPlanningReport: (options: FetchPlanningReportOptions) => {
			const {
				dateRange,
				dateType,
				groupBy,
				businessUnitIDs = [],
				fundsRegisterIDs = [],
				projectIDs = [],
				accountsChartItemIDs = [],
				tenantLegalEntityIDs = [],
			} = options;

			return new Promise<Array<PLOperationPlanningReportItem>>(resolve => {
				const request: PLOperationPlanningReportRequest = {
					...new plApi.package.PLOperationPlanningReportRequest(),
					DateStart: dateRange.dateStart,
					DateFinish: dateRange.dateEnd,
					DateType: dateType,
					GroupingType: groupBy,
					BusinessUnitIDList: buildTLongArray(businessUnitIDs),
					FundsRegisterIDList: buildTLongArray(fundsRegisterIDs),
					ProjectIDList: buildTLongArray(projectIDs),
					AccountsChartItemIDList: buildTLongArray(accountsChartItemIDs),
					TenantLegalEntityIDList: buildTLongArray(tenantLegalEntityIDs),
				};

				plApi.plOperation.client.getPlanningReportData(request, result => {
					resolve(result.Items);
				});
			});
		},
		transformPlanOperationsToFact: (operations: Array<PLOperation>) => {
			return new Promise<boolean>(resolve => {
				plApi.plOperation.client.transformPlanOperationsToFact(operations, result => {
					resolve(fromStringToBoolean(result));
				});
			});
		},
		massUpdateOperations: (operationsIDs: Array<number>, props: MassUpdateOperationsProps) => {
			return new Promise<boolean>(resolve => {
				const IDs = buildTLongArray(operationsIDs);

				plApi.plOperation.client.massUpdateOperations(IDs, props, result => {
					resolve(fromStringToBoolean(result));
				});
			});
		},
		fetchOperationTemplateByID: (ID: number) => {
			return new Promise<PLOperationTemplate>(resolve => {
				plApi.plOperation.client.getOperationTemplateByID(ID, result => {
					resolve(result);
				});
			});
		},
		addOperationsByTemplate: (template: PLOperationTemplate) => {
			return new Promise<PLOperationTemplate>(resolve => {
				plApi.plOperation.client.createOperationsByTemplate(template, result => {
					resolve(result);
				});
			});
		},
		updateOperationSchedule: (template: PLOperationTemplate, schedule: PLOperationSchedulePart) => {
			return new Promise<PLOperationTemplate>(resolve => {
				plApi.plOperation.client.changeOperationSchedule(template, schedule, result => {
					resolve(result);
				});
			});
		},
		updateOperationTemplateParameters: (template: PLOperationTemplate) => {
			return new Promise<PLOperationTemplate>(resolve => {
				plApi.plOperation.client.changeOperationTemplateParameters(template, result => {
					resolve(result);
				});
			});
		},
		updateOperationsByTemplate: (template: PLOperationTemplate, schedule?: PLOperationSchedulePart) => {
			return new Promise<PLOperationTemplate>(resolve => {
				if (schedule) {
					plApi.plOperation.updateOperationSchedule(template, schedule).then(result => {
						resolve(result);
					});
				} else {
					plApi.plOperation.updateOperationTemplateParameters(template).then(result => {
						resolve(result);
					});
				}
			});
		},
		sendPaymentsDraftsToCMS: (operationsIDs: Array<number>) => {
			return new Promise<Array<SendPaymentDraftResponse>>(resolve => {
				plApi.plOperation.client.sendPaymentsDraftsToCMS(buildTLongArray(operationsIDs), result => {
					resolve(result);
				});
			});
		},
	},
};

export enum IntervalType {
	DAY = 'DAY',
	MONTH = 'MONTH',
}

type RequestPersonal = 'all' | 'personal' | 'business';

export type FetchOperationsOptions = {
	dateRange: DateRange;
	takeBy?: 'all' | 'plan' | 'fact';
	accrual?: boolean;
	income?: boolean;
	expense?: boolean;
	transfer?: boolean;
	cashflowTypeIDs?: Array<number>;
	aciIDs?: Array<number>;
	leIDs?: Array<number>;
	cpIDs?: Array<number>;
	businessUnitsIDs?: Array<number>;
	projectIDs?: Array<number>;
	frIDs?: Array<number>;
	employeeIDs?: Array<number>;
	inConsolidation?: boolean;
	inCFConsolidation?: boolean;
	inPLConsolidation?: boolean;
	inProjectConsolidation?: boolean;
	inAbcAnalysis?: boolean;
	excludingVat?: boolean;
	personal?: RequestPersonal;
};

type FetchAggregatedDataOptions = {
	dateRange: DateRange;
	intervalType?: IntervalType;
	cashflowDateAggregation?: boolean;
	cashflowTypes?: Array<number>;
	tenantEntitiesIDs?: Array<number>;
	fundsRegisterIDs?: Array<number>;
	businessUnitIDs?: Array<number>;
	projectIDs?: Array<number>;
	inConsolidation?: boolean;
	inCFConsolidation?: boolean;
	inPLConsolidation?: boolean;
	excludingVat?: boolean;
	excludingTransfers?: boolean;
	inDashboardConsolidation?: boolean;
};

export type FetchAccountChartDynamicsOptions = {} & FetchAggregatedDataOptions;

export type FetchCounterpartyDynamicsOptions = {
	counterpartyIDs?: Array<number>;
} & FetchAggregatedDataOptions;

export type FetchBusinessUnitDynamicsOptions = {
	businessUnitIDs?: Array<number>;
} & FetchAggregatedDataOptions;

export type FetchProjectDynamicsOptions = {
	projectIDs?: Array<number>;
} & FetchAggregatedDataOptions;

export type FetchCounterpartiesTopOptions = {
	count: number;
	ownLegalEntityAggregation?: boolean;
} & FetchAggregatedDataOptions;

export type SplitOperationOptions = {
	sourcePayment: Payment;
	targetOperations: Array<PLOperation>;
	onComplete?: (result: Array<PLOperation>) => void;
};

export type UpdateSplitOperationsOptions = {
	sourcePayment: Payment;
	changeSet: PLOperationChangeSet;
	onComplete?: (result: PLOperationChangeSet) => void;
};

export type SendPaymentsDraftsToCmsReturnType = {
	successCount: number;
	unsuccessCount: number;
};

export type FetchPlanningReportOptions = {
	dateRange: DateRange;
	dateType: OperationDateType;
	groupBy: 'DAY' | 'MONTH';
	businessUnitIDs?: Array<number>;
	fundsRegisterIDs?: Array<number>;
	projectIDs?: Array<number>;
	accountsChartItemIDs?: Array<number>;
	tenantLegalEntityIDs?: Array<number>;
};

const requestPersonalMap: Record<RequestPersonal, number> = {
	all: -1,
	business: 0,
	personal: 1,
};

export { plApi };
