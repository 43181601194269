import React from 'react';

import { IconBase, IconBaseProps } from './base';

export type UnitFundsRegistersIconProps = IconBaseProps;

const UnitFundsRegistersIcon: React.FC<UnitFundsRegistersIconProps> = props => {
	return (
		<IconBase {...props}>
			<svg
				stroke='currentColor'
				fill='currentColor'
				strokeWidth='0'
				viewBox='0 0 24 24'
				xmlns='http://www.w3.org/2000/svg'>
				<g>
					<path fill='none' d='M0 0h24v24H0z'></path>
					<path d='M8 4h13v2H8V4zm-5-.5h3v3H3v-3zm0 7h3v3H3v-3zm0 7h3v3H3v-3zM8 11h13v2H8v-2zm0 7h13v2H8v-2z'></path>
				</g>
			</svg>
		</IconBase>
	);
};

export { UnitFundsRegistersIcon };
