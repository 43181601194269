import React from 'react';

import { IconBase, IconBaseProps } from './base';

export type CircleIconProps = IconBaseProps;

const CircleIcon: React.FC<CircleIconProps> = props => {
	return (
		<IconBase {...props}>
			<svg width='6' height='6' viewBox='0 0 6 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<circle cx='3' cy='3' r='3' fill='white' />
			</svg>
		</IconBase>
	);
};

export { CircleIcon };
