import { combineReducers } from 'redux';

import mainMarketplacesReducer, { MainMarketplacesState } from './main.reducers';

export type MarketplacesState = {
	main: MainMarketplacesState;
};

const marketplacesReducer = combineReducers<MarketplacesState>({
	main: mainMarketplacesReducer,
});

export default marketplacesReducer;
