/***
 * Library contains JavaScript API for interaction with Seeneco Framework Web Services contained in DatamanagementPackage
 * @author AlexPlex Generated by Seeneco Framework Modeler 
 *
 */
(function(w){
/***
* Data template defines a structure for data exchange with external systems 
(data import and export)
*/
w.DataExchangeTemplate = function() {
  this.PACKAGE = "DATAMANAGEMENT";
  this.CLASSIFIER = "DATA_EXCHANGE_TEMPLATE";
  this.CopySupportingElement = false;
  this.TemplateFields = [];
  this.Module = null;
  this.ContainerPackage = null;
  this.Sections = [];
  this.SystemName = "";
  this.Brief = "";
  this.Name = "";
  this.Description = "";
  this.GUID = "";
  this.setModule = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.Module = ref;
  };
  this.setContainerPackage = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.ContainerPackage = ref;
  };
};
/***
* Defines Data exchange template field
*/
w.DataExchangeTemplateField = function() {
  this.PACKAGE = "DATAMANAGEMENT";
  this.CLASSIFIER = "DATA_EXCHANGE_TEMPLATE_FIELD";
  this.CopySupportingElement = false;
  this.Type = null;
  this.SystemName = "";
  this.Brief = "";
  this.Name = "";
  this.Description = "";
  this.GUID = "";
  this.setType = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.Type = ref;
  };
};
/***
* 
*/
w.DataExchangeTemplateSection = function() {
  this.PACKAGE = "DATAMANAGEMENT";
  this.CLASSIFIER = "DATA_EXCHANGE_TEMPLATE_SECTION";
  this.CopySupportingElement = false;
  this.TemplateFields = [];
  this.SectionStartMarker = "";
  this.SectionEndMarker = "";
  this.SystemName = "";
  this.Brief = "";
  this.Name = "";
  this.Description = "";
  this.GUID = "";
};
/***
* Field in some data structure that can be addresed externally in expression
*/
w.DataField = function() {
  this.PACKAGE = "DATAMANAGEMENT";
  this.CLASSIFIER = "DATA_FIELD";
  this.CopySupportingElement = false;
  this.ValueUIComponent = null;
  this.SystemName = "";
  this.Brief = "";
  this.Name = "";
  this.Description = "";
  this.GUID = "";
  this.setValueUIComponent = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.ValueUIComponent = ref;
  };
};
/***
* Value that must be set to a data field
*/
w.DataFieldValue = function() {
  this.PACKAGE = "DATAMANAGEMENT";
  this.CLASSIFIER = "DATA_FIELD_VALUE";
  this.CopySupportingElement = false;
  this.DataField = null;
  this.Value = "";
  this.GUID = "";
  this.setDataField = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.DataField = ref;
  };
};
/***
* Bundle of settings for performing data markup that is comprised of data selection algorithm,
markup application, markup logging algorithms and list of rules
*/
w.DataMarkupBundle = function() {
  this.PACKAGE = "DATAMANAGEMENT";
  this.CLASSIFIER = "DATA_MARKUP_BUNDLE";
  this.CopySupportingElement = false;
  this.DatasetSchema = null;
  this.MarkupApplicationQuery = null;
  this.MarkupSaveQuery = null;
  this.Module = null;
  this.ContainerPackage = null;
  this.TargetFields = [];
  this.DefaultRules = [];
  this.RulesDatasetSchema = null;
  this.SystemName = "";
  this.Brief = "";
  this.Name = "";
  this.Description = "";
  this.GUID = "";
  this.setDatasetSchema = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.DatasetSchema = ref;
  };
  this.setMarkupApplicationQuery = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.MarkupApplicationQuery = ref;
  };
  this.setMarkupSaveQuery = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.MarkupSaveQuery = ref;
  };
  this.setModule = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.Module = ref;
  };
  this.setContainerPackage = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.ContainerPackage = ref;
  };
  this.setRulesDatasetSchema = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.RulesDatasetSchema = ref;
  };
};
/***
* 
*/
w.DataMarkupRule = function() {
  this.PACKAGE = "DATAMANAGEMENT";
  this.CLASSIFIER = "DATA_MARKUP_RULE";
  this.CopySupportingElement = false;
  this.ConditionExpression = null;
  this.TargetFieldValues = [];
  this.Priority = -1;
  this.BuiltIn = false;
  this.SystemName = "";
  this.Brief = "";
  this.Name = "";
  this.Description = "";
  this.GUID = "";
};
/***
* Rule defining execution of a number of SQL queries by specifying substitutes for query template fragments
*/
w.DataProcessingRule = function() {
  this.PACKAGE = "DATAMANAGEMENT";
  this.CLASSIFIER = "DATA_PROCESSING_RULE";
  this.CopySupportingElement = false;
  this.Module = null;
  this.ContainerPackage = null;
  this.QuerySubstitutions = [];
  this.SystemName = "";
  this.Brief = "";
  this.Name = "";
  this.Description = "";
  this.GUID = "";
  this.setModule = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.Module = ref;
  };
  this.setContainerPackage = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.ContainerPackage = ref;
  };
};
/***
* Defines set of data records organized as a list of hashtables 
*/
w.Dataset = function() {
  this.PACKAGE = "DATAMANAGEMENT";
  this.CLASSIFIER = "DATASET";
  this.CopySupportingElement = false;
  this.Records = [];
  this.Schema = null;
  this.setSchema = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.Schema = ref;
  };
};
/***
* 
*/
w.DatasetPageRequest = function() {
  this.PACKAGE = "DATAMANAGEMENT";
  this.CLASSIFIER = "DATASET_PAGE_REQUEST";
  this.CopySupportingElement = false;
  this.LastReturnedKeyValue = -1;
  this.DatasetSchemaSystemName = "";
  this.ParameterValues = {};
  this.DatasetSchemaGUID = "";
};
/***
* Defines a request for dataset containing data extracted from DB.
*/
w.DatasetRequest = function() {
  this.PACKAGE = "DATAMANAGEMENT";
  this.CLASSIFIER = "DATASET_REQUEST";
  this.CopySupportingElement = false;
  this.DatasetSchemaSystemName = "";
  this.ParameterValues = {};
  this.DatasetSchemaGUID = "";
};
/***
* Defines Dataset schema
*/
w.DatasetSchema = function() {
  this.PACKAGE = "DATAMANAGEMENT";
  this.CLASSIFIER = "DATASET_SCHEMA";
  this.CopySupportingElement = false;
  this.SchemaFields = [];
  this.SQLQuery = null;
  this.Module = null;
  this.ContainerPackage = null;
  this.SystemName = "";
  this.Brief = "";
  this.Name = "";
  this.Description = "";
  this.GUID = "";
  this.setSQLQuery = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.SQLQuery = ref;
  };
  this.setModule = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.Module = ref;
  };
  this.setContainerPackage = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.ContainerPackage = ref;
  };
};
/***
* Defines Dataset schema field
*/
w.DatasetSchemaField = function() {
  this.PACKAGE = "DATAMANAGEMENT";
  this.CLASSIFIER = "DATASET_SCHEMA_FIELD";
  this.CopySupportingElement = false;
  this.PrimaryKeyField = false;
  this.SystemName = "";
  this.Brief = "";
  this.Name = "";
  this.Description = "";
  this.GUID = "";
};
/***
* 
*/
w.IdSequence = function() {
  this.PACKAGE = "DATAMANAGEMENT";
  this.CLASSIFIER = "ID_SEQUENCE";
  this.CopySupportingElement = false;
  this.UID = "";
  this.TableName = "";
  this.StartValue = -1;
  this.MaxValue = -1;
  this.DatabaseHost = "";
  this.DatabasePort = "";
  this.DatabaseSchema = "";
};
/***
* 
*/
w.ImportedFileReference = function() {
  this.PACKAGE = "DATAMANAGEMENT";
  this.CLASSIFIER = "IMPORTED_FILE_REFERENCE";
  this.CopySupportingElement = false;
  this.FileTemplateGUID = "";
  this.UploadedFileName = "";
  this.UploadDateTime = null;
};
/***
* Defines a named native SQL query
*/
w.SQLQuery = function() {
  this.PACKAGE = "DATAMANAGEMENT";
  this.CLASSIFIER = "SQLQUERY";
  this.CopySupportingElement = false;
  this.QueryText = "";
  this.Module = null;
  this.ContainerPackage = null;
  this.Template = false;
  this.SystemName = "";
  this.Brief = "";
  this.Name = "";
  this.Description = "";
  this.GUID = "";
  this.setModule = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.Module = ref;
  };
  this.setContainerPackage = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.ContainerPackage = ref;
  };
};
/***
* Defines column in user interface table control
*/
w.TableColumn = function() {
  this.PACKAGE = "DATAMANAGEMENT";
  this.CLASSIFIER = "TABLE_COLUMN";
  this.CopySupportingElement = false;
  this.DisplayName = "";
  this.FieldName = "";
  this.RowHeader = false;
  this.RowID = false;
  this.Visible = false;
  this.ValueFormat = "";
};
/***
* Defines a self-descriptive set of data organized as a table and ready to be displayed
in user interface table
*/
w.TableData = function() {
  this.PACKAGE = "DATAMANAGEMENT";
  this.CLASSIFIER = "TABLE_DATA";
  this.CopySupportingElement = false;
  this.Columns = [];
  this.Rows = [];
  this.VPageNumber = -1;
  this.HPageNumber = -1;
  this.LastVPage = false;
  this.LastHPage = false;
};
/***
* Set of rules defining values that will substitute fragments in query template
*/
w.TemplateQuerySubstitution = function() {
  this.PACKAGE = "DATAMANAGEMENT";
  this.CLASSIFIER = "TEMPLATE_QUERY_SUBSTITUTION";
  this.CopySupportingElement = false;
  this.SubstitutedStrings = [];
  this.SQLQuery = null;
  this.GUID = "";
  this.setSQLQuery = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.SQLQuery = ref;
  };
};
/***
* Linear dataset containing of map of key-value pairs, where key defines a dimension value(i.e. column) and value is a target measure value(i.e. vector column cell data)
*/
w.VectorData = function() {
  this.PACKAGE = "DATAMANAGEMENT";
  this.CLASSIFIER = "VECTOR_DATA";
  this.CopySupportingElement = false;
  this.Data = {};
  this.PageNumber = -1;
  this.LastPage = false;
  this.Columns = [];
};
 
 

/***
 * Client API to DataExchangeServiceWS web service
 */
w.DataExchangeServiceClient = function() {
  /***
   * System name of target service
   */
  this.serviceName = "DataExchangeServiceWSImpl";
};

/***
 * Returns list of {@link DataExchangeTemplate} objects available for
  tenant that peforms the request
 * @param callbackHandler Function to service callback result
 */
w.DataExchangeServiceClient.prototype.getExchangeTemplatesForTenant = function(callbackHandler) {
  var parameterNames = [];
  var parameterValues = [];
  var soapMessage = buildSOAPMessage("getExchangeTemplatesForTenant", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * Defines getExchangeTemplateByID operation
 * @param templateID 
 * @param callbackHandler Function to service callback result
 */
w.DataExchangeServiceClient.prototype.getExchangeTemplateByID = function(templateID, callbackHandler) {
  var parameterNames = ["templateID"];
  var parameterValues = [templateID];
  var soapMessage = buildSOAPMessage("getExchangeTemplateByID", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * Checks if {@link DataExchangeTemplate} with given GUID exists in storage and 
updates it if found or adds to storage otherwise.
Returns {@link DataExchangeTemplate} after publishing it in storage
 * @param template Template to publish in storage
 * @param callbackHandler Function to service callback result
 */
w.DataExchangeServiceClient.prototype.publishTemplate = function(template, callbackHandler) {
  var parameterNames = ["template"];
  var templateJSON = JSON.stringify(template);
  var parameterValues = [templateJSON];
  var soapMessage = buildSOAPMessage("publishTemplate", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * Returns {@link DataExchangeTemplate} by template internal GUID
 * @param templateGUID GUID of target template
 * @param callbackHandler Function to service callback result
 */
w.DataExchangeServiceClient.prototype.getExchangeTemplateByGUID = function(templateGUID, callbackHandler) {
  var parameterNames = ["templateGUID"];
  var parameterValues = [templateGUID];
  var soapMessage = buildSOAPMessage("getExchangeTemplateByGUID", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * Client API to DatasetServiceWS web service
 */
w.DatasetServiceClient = function() {
  /***
   * System name of target service
   */
  this.serviceName = "DatasetServiceWSImpl";
};

/***
 * Publishes given {@link DatasetSchema} in storage. Operation checks if dataset schema with given GUID 
exists in storage and updates it if found or adds new one otherwise.
Operation returns an instance of {@link DatasetSchema} after publishing
 * @param datasetSchema 
 * @param callbackHandler Function to service callback result
 */
w.DatasetServiceClient.prototype.publishDatasetSchema = function(datasetSchema, callbackHandler) {
  var parameterNames = ["datasetSchema"];
  var datasetSchemaJSON = JSON.stringify(datasetSchema);
  var parameterValues = [datasetSchemaJSON];
  var soapMessage = buildSOAPMessage("publishDatasetSchema", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * Returns schema of dataset by by GUID
 * @param datasetSchemaGUID 
 * @param callbackHandler Function to service callback result
 */
w.DatasetServiceClient.prototype.getDatasetSchemaByGUID = function(datasetSchemaGUID, callbackHandler) {
  var parameterNames = ["datasetSchemaGUID"];
  var parameterValues = [datasetSchemaGUID];
  var soapMessage = buildSOAPMessage("getDatasetSchemaByGUID", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * [DEFAULT!Returns dataset filled with data  according to given {@link DatasetRequest}!DEFAULT]
 * @param request Request containing necessary information for obtaining dataset from db
 * @param callbackHandler Function to service callback result
 */
w.DatasetServiceClient.prototype.getDataset = function(request, callbackHandler) {
  var parameterNames = ["request"];
  var requestJSON = JSON.stringify(request);
  var parameterValues = [requestJSON];
  var soapMessage = buildSOAPMessage("getDataset", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = true;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param datasetSchemaID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.DatasetServiceClient.prototype.getDatasetSchemaByID = function(datasetSchemaID, callbackHandler) {
  var parameterNames = ["datasetSchemaID"];
  var parameterValues = [datasetSchemaID];
  var soapMessage = buildSOAPMessage("getDatasetSchemaByID", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * Client API to QueryServiceWS web service
 */
w.QueryServiceClient = function() {
  /***
   * System name of target service
   */
  this.serviceName = "QueryServiceWSImpl";
};

/***
 * Returns {@link SQLQuery} by it's local storage identifier
 * @param queryID Local ID of target SQL Query in storage
 * @param callbackHandler Function to service callback result
 */
w.QueryServiceClient.prototype.getQueryByID = function(queryID, callbackHandler) {
  var parameterNames = ["queryID"];
  var parameterValues = [queryID];
  var soapMessage = buildSOAPMessage("getQueryByID", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * Publishes given {@link SQLQuery} in storage. Operation checks if query with given GUID 
exists in storage and updates it if found or adds new one otherwise.
Operation returns an instance of {@link SQLQuery} after publishing
 * @param sqlQuery SQL Query to publish
 * @param callbackHandler Function to service callback result
 */
w.QueryServiceClient.prototype.publishQuery = function(sqlQuery, callbackHandler) {
  var parameterNames = ["sqlQuery"];
  var sqlQueryJSON = JSON.stringify(sqlQuery);
  var parameterValues = [sqlQueryJSON];
  var soapMessage = buildSOAPMessage("publishQuery", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * Return {@link SQLQuery} by query GUID
 * @param queryGUID GUID of target query
 * @param callbackHandler Function to service callback result
 */
w.QueryServiceClient.prototype.getQueryByGUID = function(queryGUID, callbackHandler) {
  var parameterNames = ["queryGUID"];
  var parameterValues = [queryGUID];
  var soapMessage = buildSOAPMessage("getQueryByGUID", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param rule [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.QueryServiceClient.prototype.publishDataProcessingRule = function(rule, callbackHandler) {
  var parameterNames = ["rule"];
  var ruleJSON = JSON.stringify(rule);
  var parameterValues = [ruleJSON];
  var soapMessage = buildSOAPMessage("publishDataProcessingRule", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param ruleID [DEFAULT!Local identifier or rule in repository!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.QueryServiceClient.prototype.getDataProcessingRuleByID = function(ruleID, callbackHandler) {
  var parameterNames = ["ruleID"];
  var parameterValues = [ruleID];
  var soapMessage = buildSOAPMessage("getDataProcessingRuleByID", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * [DEFAULT!Returns list of {@link DataProcessingRule} objects applicable to current tenant.
Rule is applicable to tenant if 
- rule is not already assigned to tenant
- tenant has no assigned rules with the same queries
!DEFAULT]
 * @param tenantID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.QueryServiceClient.prototype.getApplicableDataProcessingRules = function(tenantID, callbackHandler) {
  var parameterNames = ["tenantID"];
  var parameterValues = [tenantID];
  var soapMessage = buildSOAPMessage("getApplicableDataProcessingRules", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * [DEFAULT!Assigns {@link DataProcessingRule} to be used by tenant!DEFAULT]
 * @param ruleID [DEFAULT!Local identifier or rule in repository!DEFAULT]
 * @param tenantID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.QueryServiceClient.prototype.assignRuleToTenant = function(ruleID, tenantID, callbackHandler) {
  var parameterNames = ["ruleID", "tenantID"];
  var parameterValues = [ruleID, tenantID];
  var soapMessage = buildSOAPMessage("assignRuleToTenant", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * [DEFAULT!Unassigns rule from tenant!DEFAULT]
 * @param ruleID [DEFAULT!Local identifier or rule in repository!DEFAULT]
 * @param tenantID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.QueryServiceClient.prototype.unassignRuleFromTenant = function(ruleID, tenantID, callbackHandler) {
  var parameterNames = ["ruleID", "tenantID"];
  var parameterValues = [ruleID, tenantID];
  var soapMessage = buildSOAPMessage("unassignRuleFromTenant", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * [DEFAULT!Returns list of {@link DataProcessingRule} assigned to current tenant!DEFAULT]
 * @param tenantID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.QueryServiceClient.prototype.getAssignedDataProcessingRules = function(tenantID, callbackHandler) {
  var parameterNames = ["tenantID"];
  var parameterValues = [tenantID];
  var soapMessage = buildSOAPMessage("getAssignedDataProcessingRules", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * [DEFAULT!Returns list of  {@link TemplateQuerySubstitution} for given SQL query 
assigned to current tenant by means of data processing rules!DEFAULT]
 * @param queryID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.QueryServiceClient.prototype.getSubstitutionsForQuery = function(queryID, callbackHandler) {
  var parameterNames = ["queryID"];
  var parameterValues = [queryID];
  var soapMessage = buildSOAPMessage("getSubstitutionsForQuery", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param bundle [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.QueryServiceClient.prototype.publishDataMarkupBundle = function(bundle, callbackHandler) {
  var parameterNames = ["bundle"];
  var bundleJSON = JSON.stringify(bundle);
  var parameterValues = [bundleJSON];
  var soapMessage = buildSOAPMessage("publishDataMarkupBundle", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param bundleID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.QueryServiceClient.prototype.getDataMarkupBundleByID = function(bundleID, callbackHandler) {
  var parameterNames = ["bundleID"];
  var parameterValues = [bundleID];
  var soapMessage = buildSOAPMessage("getDataMarkupBundleByID", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param bundleGUID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.QueryServiceClient.prototype.getDataMarkupBundleByGUID = function(bundleGUID, callbackHandler) {
  var parameterNames = ["bundleGUID"];
  var parameterValues = [bundleGUID];
  var soapMessage = buildSOAPMessage("getDataMarkupBundleByGUID", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param ruleID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.QueryServiceClient.prototype.getDataMarkupRuleByID = function(ruleID, callbackHandler) {
  var parameterNames = ["ruleID"];
  var parameterValues = [ruleID];
  var soapMessage = buildSOAPMessage("getDataMarkupRuleByID", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param rule [DEFAULT!!DEFAULT]
 * @param bundleGUID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.QueryServiceClient.prototype.addDataMarkupRule = function(rule, bundleGUID, callbackHandler) {
  var parameterNames = ["rule", "bundleGUID"];
  var ruleJSON = JSON.stringify(rule);
  var parameterValues = [ruleJSON, bundleGUID];
  var soapMessage = buildSOAPMessage("addDataMarkupRule", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param rule [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.QueryServiceClient.prototype.changeDataMarkupRule = function(rule, callbackHandler) {
  var parameterNames = ["rule"];
  var ruleJSON = JSON.stringify(rule);
  var parameterValues = [ruleJSON];
  var soapMessage = buildSOAPMessage("changeDataMarkupRule", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param rule [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.QueryServiceClient.prototype.removeDataMarkupRule = function(rule, callbackHandler) {
  var parameterNames = ["rule"];
  var ruleJSON = JSON.stringify(rule);
  var parameterValues = [ruleJSON];
  var soapMessage = buildSOAPMessage("removeDataMarkupRule", parameterNames, parameterValues);
  var resultHasComplexType = false;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};


/***
 * Package security rights constants
 */

w.DatamanagementSecurityRights = {

    DATA_CHANGE_PROHIBITION_RIGHT:new SecurityRightCheckHandle('9ddd3681-91f5-4fdf-853c-151a1a5093b5')
};
})(typeof exports !== 'undefined' ? exports : window);
