import { plural } from '@utils/plural';

type FormatNumber = {
	fractions?: number;
	locale?: string;
};

function detectIsInteger(value: number) {
	return (value ^ 0) === value;
}

function formatInputNumber(value = '', precision = 2) {
	if (precision === 0) return value.replace(/[^\d]/g, '');
	const splitters = ['.', ','];
	const pattern = /[^\d.,]/g;
	const isMatchPoint = /\./g.test(value);
	const isMatchComma = /,/g.test(value);
	const separator = isMatchPoint ? splitters[0] : isMatchComma ? splitters[1] : '';
	let replacedValue = value.replace(pattern, '');
	const splittedValue = replacedValue.split(separator);
	const fraction = splittedValue[1];

	if (value.length > 1 && value[0] === '0' && value[1] === '0') {
		return '0';
	}

	if (
		value.length > 1 &&
		value[0] === '0' &&
		!splitters.includes(value[1]) &&
		Number(value[1]) > 0 &&
		Number(value[1]) < 10
	) {
		return value[1];
	}

	if (splitters.includes(value[0])) {
		return `${0}${separator}`;
	}

	if ((value.match(/(\.|,)/g) || []).length > 1) {
		return value.substr(0, value.length - 1);
	}

	if (fraction && fraction.length > precision) {
		replacedValue = `${splittedValue[0]}${separator}${fraction.substr(0, precision)}`;
	}

	return replacedValue;
}

function formatNumber(value: number, options: FormatNumber = {}) {
	const { fractions = 2, locale = 'ru' } = options;

	return new Intl.NumberFormat(locale, {
		style: 'decimal',
		minimumFractionDigits: fractions,
		maximumFractionDigits: fractions,
	}).format(value);
}

function formatNumberAsLabel(value: number) {
	const abs = Math.abs(value);

	if (abs >= 1000000000) {
		return `${safeNumber(value / 1000000000)} млрд.`;
	}

	if (abs >= 1000000) {
		return `${safeNumber(value / 1000000)} млн.`;
	}

	if (abs >= 1000) {
		return `${safeNumber(value / 1000)} тыс.`;
	}

	return safeNumber(value);
}

function formatNumberAsText(value = 0) {
	const unitsMap = {
		'0': 'ноль',
		'1': 'один',
		'2': 'два',
		'3': 'три',
		'4': 'четыре',
		'5': 'пять',
		'6': 'шесть',
		'7': 'семь',
		'8': 'восемь',
		'9': 'девять',
	};
	const decimalsMap = {
		'10': 'десять',
		'11': 'одиннадцать',
		'12': 'двенадцать',
		'13': 'тринадцать',
		'14': 'четырнадцать',
		'15': 'пятнадцать',
		'16': 'шестнадцать',
		'17': 'семнадцать',
		'18': 'восемнадцать',
		'19': 'девятнадцать',
		'2': 'двадцать',
		'20': 'двадцать',
		'3': 'тридцать',
		'30': 'тридцать',
		'4': 'сорок',
		'40': 'сорок',
		'5': 'пятьдесят',
		'50': 'пятьдесят',
		'6': 'шестьдесят',
		'60': 'шестьдесят',
		'7': 'семьдесят',
		'70': 'семьдесят',
		'8': 'восемьдесят',
		'80': 'восемьдесят',
		'9': 'девяносто',
		'90': 'девяносто',
	};
	const hundredsMap = {
		'1': 'сто',
		'100': 'сто',
		'2': 'двести',
		'200': 'двести',
		'3': 'триста',
		'300': 'триста',
		'4': 'четыреста',
		'400': 'четыреста',
		'5': 'пятьсот',
		'500': 'пятьсот',
		'6': 'шестьсот',
		'600': 'шестьсот',
		'7': 'семьсот',
		'700': 'семьсот',
		'8': 'восемьсот',
		'800': 'восемьсот',
		'9': 'девятьсот',
		'900': 'девятьсот',
	};

	const join = (items: Array<string>) => items.filter(Boolean).join(' ');

	const normalizeRussian = (value: string) => {
		return value.replace(/один тысяча/gi, 'одна тысяча').replace(/два тысячи/gi, 'две тысячи');
	};

	const getUnit = (x: string) => {
		return unitsMap[x];
	};

	const getDecimal = (x: string, y: string) => {
		const first = `${x}${y}`;

		if (decimalsMap[first]) {
			return decimalsMap[first];
		}

		const one = decimalsMap[x];
		const two = unitsMap[y];

		if (one && two) {
			return join([one, two]);
		}

		return '';
	};

	const getHundred = (x: string, y: string, z: string) => {
		const first = `${x}${y}${z}`;

		if (hundredsMap[first]) {
			return hundredsMap[first];
		}

		const one = hundredsMap[x];
		const two = getDecimal(y, z) || getUnit(z);

		return join([one, two]);
	};

	const x = value.toFixed(0);
	const initialLength = x.split('').length;
	const first = x.substring(0, 3);
	const last = x.replace(first, '');
	const splitted = first.split('');

	let str = '';

	if (splitted.length === 1) {
		str = getUnit(splitted[0]);
	} else if (splitted.length === 2) {
		str = getDecimal(splitted[0], splitted[1]);
	} else if (splitted.length === 3) {
		str = getHundred(splitted[0], splitted[1], splitted[2]);
	}

	const length = last.length > 0 ? last.length % 3 || 3 : last.length;

	if (length > 0) {
		const ordersList = [
			{ min: 4, max: 6, titles: ['тысяча', 'тысячи', 'тысяч'] },
			{ min: 7, max: 9, titles: ['миллион', 'миллиона', 'миллионов'] },
			{ min: 8, max: 12, titles: ['миллиард', 'миллиарда', 'миллиардов'] },
		];
		const first = x.substring(0, length);
		const last = x.replace(first, '');
		const titles = ordersList.find(x => initialLength >= x.min && initialLength <= x.max)?.titles as [
			string,
			string,
			string,
		];
		const name = plural({ count: Number(first), titles });
		const fnMap = {
			1: () => getUnit(splitted[0]),
			2: () => getDecimal(splitted[0], splitted[1]),
			3: () => getHundred(splitted[0], splitted[1], splitted[2]),
		};
		const unit = fnMap[length]();
		const formattedLast = Number(last) > 0 ? formatNumberAsText(Number(last)) : '';

		return normalizeRussian(`${unit} ${name} ${formattedLast}`.trim());
	}

	return str;
}

function getFraction(value: string) {
	const splitters = ['.', ','];
	const isMatchPoint = /\./g.test(value);
	const isMatchComma = /,/g.test(value);

	if (!isMatchPoint && !isMatchComma) {
		return '';
	}

	const separator = isMatchPoint ? splitters[0] : splitters[1];
	const splittedValue = value.split(separator);
	const fraction = splittedValue.length === 2 ? splittedValue[1] : '';

	return fraction;
}

function safeNumber(source: string | number, precision = 2): number {
	return Number(Number(source).toFixed(precision));
}

export {
	detectIsInteger,
	formatInputNumber,
	formatNumber,
	formatNumberAsLabel,
	formatNumberAsText,
	getFraction,
	safeNumber,
};
