import { SUCCESS, WAITING, IN_PROGRESS, FINISHED_WITH_WARNING, FAILED } from '@shared/constants/statuses';

const detectInProgress = (status: string): boolean => [WAITING, IN_PROGRESS].includes(status);

function detectIsFinishStatus(status: string) {
	const whiteList = [SUCCESS, FINISHED_WITH_WARNING, FAILED];

	return whiteList.includes(status);
}

export { detectInProgress, detectIsFinishStatus };
