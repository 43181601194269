import styled from 'styled-components';

interface IRoot {
	fullWidth?: boolean;
}

const Root = styled.div`
	position: relative;
	width: ${(props: IRoot) => (props.fullWidth ? '100%' : '310px')};
	line-height: 0;

	&:last-child {
		margin-right: 0px;
	}

	& .select {
		margin-right: 0px !important;
	}
`;

const ClearButtonLayout = styled.div`
	position: absolute;
	top: 30px;
	right: 20px;
`;

export { Root, ClearButtonLayout };
