import api from '@api';
import createAction, { createStaticDataAction } from '@libs/action-creator';
import { createActionMessage } from '@utils';
import {
	selectTenantDidInvalidate,
	selectTenantIsFetching,
	selectTenantIsLoaded,
} from '../selectors/tenant-account.selectors';

const FETCH_TENANT_BY_ID = '[PLATFORM]: FETCH_TENANT_BY_ID';
const INVALIDATE_TENANT = '[PLATFORM]: INVALIDATE_TENANT';
const UPDATE_TENANT = '[PLATFORM]: UPDATE_TENANT';

const fetchTenantByIDAction = createAction(FETCH_TENANT_BY_ID, fetchTenantByID, {
	isValidSelector: state => selectTenantIsLoaded(state) && !selectTenantDidInvalidate(state),
	isFetchingSelector: state => selectTenantIsFetching(state),
});

function invalidateTenantAction() {
	return createStaticDataAction(INVALIDATE_TENANT)();
}

const updateSilentTenantAction = createAction(UPDATE_TENANT, updateTenant);

const updateTenantAction = createAction(UPDATE_TENANT, updateTenant, {
	showMessage: type => {
		return type === 'RECEIVE' && createActionMessage('Данные обновлены', 'success');
	},
});

function fetchTenantByID(api, getState, dispatch, tenantID: number) {
	return new Promise(resolve => {
		api.directoryServiceClient.getTenantByID(tenantID, (tenant: Tenant) => {
			resolve(tenant);
		});
	});
}

function updateTenant(api, getState, dispatch, tenant: Tenant) {
	return updateTenantMethod(tenant);
}

function updateTenantMethod(tenant: Tenant) {
	return new Promise(resolve => {
		api.directoryServiceClient.updateTenant(tenant, (tenant: Tenant) => {
			resolve(tenant);
		});
	});
}

function validateContactsMethod(name: string, email: string, phone: string) {
	return new Promise<boolean>(resolve => {
		api.directoryServiceClient.validateContacts(name, email, phone, result => {
			resolve(JSON.parse(`${result}`));
		});
	});
}

export const methods = {
	updateTenantMethod,
	validateContactsMethod,
};

export {
	FETCH_TENANT_BY_ID,
	INVALIDATE_TENANT,
	UPDATE_TENANT,
	fetchTenantByIDAction,
	invalidateTenantAction,
	updateSilentTenantAction,
	updateTenantAction,
};
