import styled, { css } from 'styled-components';

import { blackSecondary } from '@ui/basic-theme/colors';
import { CloseButtonProps } from './close-button';

type IconButtonProps = {} & Pick<CloseButtonProps, 'appearance'>;

const Root = styled.div``;

const ButtonStyled = styled.div`
	background-color: rgba(62, 71, 87, 0.47);
	margin: 0;
	width: 30px;
	height: 30px;
	border-radius: 2px;
	outline: none;
	cursor: pointer;
	transition: background-color 0.2s ease-in-out;

	&:hover {
		background-color: rgba(62, 71, 87, 0.8);
	}
`;

const ButtonSign = styled.div`
	font-size: 12px;
	letter-spacing: 1px;
	text-align: center;
	color: ${blackSecondary};
	margin-top: 3px;
`;

const IconButton = styled.button`
	position: relative;
	overflow: hidden;
	border: 0;
	background-color: transparent;
	text-align: center;
	transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	border-radius: 50%;
	outline: none;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	&:hover,
	&:focus {
		background-color: rgba(0, 0, 0, 0.1);
	}

	${(p: IconButtonProps) =>
		p.appearance === 'white' &&
		css`
			&:hover,
			&:focus {
				background-color: rgba(255, 255, 255, 0.2);
			}
		`}
`;

const IconButtonContent = styled.span`
	width: 44px;
	height: 44px;
	padding: 8px;
`;

export { Root, ButtonStyled, ButtonSign, IconButton, IconButtonContent };
