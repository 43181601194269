import { IAppState } from '@store';
import { createAsyncSelector, createSelector } from '@flux';


const selectAsyncTenantProfile = createAsyncSelector<TenantProfile, IAppState>({
	get: s => s.platform.tenantProfile.tenantProfile,
	selector: createSelector(
		s => s.platform.tenantProfile.tenantProfile.item,
		item => item,
	),
});

const selectTenantProfileHasStandardData = (state: IAppState) => Boolean(state.platform.tenantProfile.tenantProfile.item?.HasStandardData);

export const mainTenantProfileSelectorsPack = {
	selectAsyncTenantProfile,
	selectTenantProfileHasStandardData,
};
