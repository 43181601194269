import React from 'react';
import { useCallback, useContext } from 'react';

import { FormContext } from './form';

type Props = {
	onClick?: (...args) => void;
};

function withFormSubmitButton<P extends Props>(WrappedComponent: React.ComponentType<P>) {
	return (props: P) => {
		const context = useContext(FormContext);
		const handleClick = useCallback(
			(...args) => {
				props.onClick && props.onClick(...args);
				context.submitForm();
			},
			[props.onClick, context],
		);

		return <WrappedComponent {...props} onClick={handleClick} />;
	};
}

export { withFormSubmitButton };
