import styled, { css } from 'styled-components';

type RootProps = {
	inline?: boolean;
};

const Root = styled.div<RootProps>`
	position: relative;
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;

	${p =>
		p.inline &&
		css`
			display: inline-flex;
		`}
`;

const Text = styled.div`
	${p =>
		css`
			color: ${p.theme.palette.label};
		`}
	font-size: 13px;
	margin-bottom: 20px;
`;

type LoaderLayoutProps = {
	height?: string;
	withoutPaddings?: boolean;
};

const LoaderLayout = styled.div<LoaderLayoutProps>`
	width: 100%;
	height: 300px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 16px;

	${p =>
		p.height &&
		css`
			height: ${p.height};
		`}

	${p =>
		p.withoutPaddings &&
		css`
			padding: 0;
		`}
`;

export { Root, Text, LoaderLayout };
