'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _simpleAssign = require('simple-assign');

var _simpleAssign2 = _interopRequireDefault(_simpleAssign);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactDom = require('react-dom');

var _reactDom2 = _interopRequireDefault(_reactDom);

var _shallowEqual = require('recompose/shallowEqual');

var _shallowEqual2 = _interopRequireDefault(_shallowEqual);

var _Popover = require('../Popover/Popover');

var _Popover2 = _interopRequireDefault(_Popover);

var _check = require('../svg-icons/navigation/check');

var _check2 = _interopRequireDefault(_check);

var _ListItem = require('../List/ListItem');

var _ListItem2 = _interopRequireDefault(_ListItem);

var _Menu = require('../Menu/Menu');

var _Menu2 = _interopRequireDefault(_Menu);

var _propTypes3 = require('../utils/propTypes');

var _propTypes4 = _interopRequireDefault(_propTypes3);

function _interopRequireDefault(obj) {
	return obj && obj.__esModule ? obj : {
		default: obj
	};
}

var nestedMenuStyle = {
	position: 'relative'
};

function getStyles(props, context) {
	var disabledColor = context.muiTheme.baseTheme.palette.disabledColor;
	var textColor = context.muiTheme.baseTheme.palette.textColor;
	var indent = props.desktop ? 64 : 72;
	var sidePadding = props.desktop ? 24 : 16;

	var styles = {
		root: {
			color: props.disabled ? disabledColor : textColor,
			cursor: props.disabled ? 'default' : 'pointer',
			minHeight: props.desktop ? '32px' : '48px',
			lineHeight: props.desktop ? '32px' : '48px',
			fontSize: props.desktop ? 15 : 16,
			whiteSpace: 'nowrap'
		},

		innerDivStyle: {
			paddingLeft: props.leftIcon || props.insetChildren || props.checked ? indent : sidePadding,
			paddingRight: props.rightIcon ? indent : sidePadding,
			paddingBottom: 0,
			paddingTop: 0
		},

		secondaryText: {
			float: 'right'
		},

		leftIconDesktop: {
			margin: 0,
			left: 24,
			top: 4
		},

		rightIconDesktop: {
			margin: 0,
			right: 24,
			top: 4,
			fill: context.muiTheme.menuItem.rightIconDesktopFill
		}
	};

	return styles;
}

var MenuItem = function (_Component) {
	(0, _inherits3.default)(MenuItem, _Component);

	function MenuItem() {
		var _ref;

		var _temp, _this, _ret;

		(0, _classCallCheck3.default)(this, MenuItem);

		for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
			args[_key] = arguments[_key];
		}

		return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref = MenuItem.__proto__ || (0, _getPrototypeOf2.default)(MenuItem)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
			open: false
		}, _this.cloneMenuItem = function (item) {
			return _react2.default.cloneElement(item, {
				onClick: function onClick(event) {
					if (!item.props.menuItems) {
						_this.handleRequestClose();
					}

					if (item.props.onClick) {
						item.props.onClick(event);
					}
				}
			});
		}, _this.handleTouchTap = function (event) {
			event.preventDefault();

			_this.setState({
				open: true,
				anchorEl: _reactDom2.default.findDOMNode(_this)
			});

			if (_this.props.onClick) {
				_this.props.onClick(event);
			}
		}, _this.handleRequestClose = function () {
			_this.setState({
				open: false,
				anchorEl: null
			});
		}, _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
	}

	(0, _createClass3.default)(MenuItem, [{
		key: 'componentDidMount',
		value: function componentDidMount() {
			this.applyFocusState();
		}
	}, {
		key: 'componentDidUpdate',
		value: function componentDidUpdate(prevProps) {
			this.applyFocusState();

			if (this.state.open && prevProps.focusState === 'none') {
				this.handleRequestClose();
			}

		}
	}, {
		key: 'shouldComponentUpdate',
		value: function shouldComponentUpdate(nextProps, nextState, nextContext) {
			return !(0, _shallowEqual2.default)(this.props, nextProps) || !(0, _shallowEqual2.default)(this.state, nextState) || !(0, _shallowEqual2.default)(this.context, nextContext);
		}
	}, {
		key: 'componentWillUnmount',
		value: function componentWillUnmount() {
			if (this.state.open) {
				this.setState({
					open: false
				});
			}
		}
	}, {
		key: 'applyFocusState',
		value: function applyFocusState() {
			this.refs.listItem.applyFocusState(this.props.focusState);
		}
	}, {
		key: 'render',
		value: function render() {
			var _props = this.props,
				checked = _props.checked,
				children = _props.children,
				desktop = _props.desktop,
				disabled = _props.disabled,
				focusState = _props.focusState,
				innerDivStyle = _props.innerDivStyle,
				insetChildren = _props.insetChildren,
				leftIcon = _props.leftIcon,
				menuItems = _props.menuItems,
				rightIcon = _props.rightIcon,
				secondaryText = _props.secondaryText,
				style = _props.style,
				animation = _props.animation,
				anchorOrigin = _props.anchorOrigin,
				targetOrigin = _props.targetOrigin,
				value = _props.value,
				other = (0, _objectWithoutProperties3.default)(_props, ['checked', 'children', 'desktop', 'disabled', 'focusState', 'innerDivStyle', 'insetChildren', 'leftIcon', 'menuItems', 'rightIcon', 'secondaryText', 'style', 'animation', 'anchorOrigin', 'targetOrigin', 'value']);
			var prepareStyles = this.context.muiTheme.prepareStyles;

			var styles = getStyles(this.props, this.context);
			var mergedRootStyles = (0, _simpleAssign2.default)(styles.root, style);
			var mergedInnerDivStyles = (0, _simpleAssign2.default)(styles.innerDivStyle, innerDivStyle);

			// Left Icon
			var leftIconElement = leftIcon ? leftIcon : checked ? _react2.default.createElement(_check2.default, null) : null;
			if (leftIconElement) {
				var mergedLeftIconStyles = desktop ? (0, _simpleAssign2.default)(styles.leftIconDesktop, leftIconElement.props.style) : leftIconElement.props.style;
				leftIconElement = _react2.default.cloneElement(leftIconElement, {
					style: mergedLeftIconStyles
				});
			}

			// Right Icon
			var rightIconElement = void 0;
			if (rightIcon) {
				var mergedRightIconStyles = desktop ? (0, _simpleAssign2.default)(styles.rightIconDesktop, rightIcon.props.style) : rightIcon.props.style;
				rightIconElement = _react2.default.cloneElement(rightIcon, {
					style: mergedRightIconStyles
				});
			}

			// Secondary Text
			var secondaryTextElement = void 0;
			if (secondaryText) {
				var secondaryTextIsAnElement = _react2.default.isValidElement(secondaryText);
				var mergedSecondaryTextStyles = secondaryTextIsAnElement ? (0, _simpleAssign2.default)(styles.secondaryText, secondaryText.props.style) : null;

				secondaryTextElement = secondaryTextIsAnElement ? _react2.default.cloneElement(secondaryText, {
					style: mergedSecondaryTextStyles
				}) : _react2.default.createElement(
					'div', {
						style: prepareStyles(styles.secondaryText)
					},
					secondaryText
				);
			}
			var childMenuPopover = void 0;
			if (menuItems) {
				childMenuPopover = _react2.default.createElement(
					_Popover2.default, {
						animation: animation,
						anchorOrigin: anchorOrigin,
						anchorEl: this.state.anchorEl,
						open: this.state.open,
						targetOrigin: targetOrigin,
						useLayerForClickAway: false,
						onRequestClose: this.handleRequestClose
					},
					_react2.default.createElement(
						_Menu2.default, {
							desktop: desktop,
							disabled: disabled,
							style: nestedMenuStyle
						},
						_react2.default.Children.map(menuItems, this.cloneMenuItem)
					)
				);
				other.onClick = this.handleTouchTap;
			}

			return _react2.default.createElement(
				_ListItem2.default,
				(0, _extends3.default)({}, other, {
					disabled: disabled,
					hoverColor: this.context.muiTheme.menuItem.hoverColor,
					innerDivStyle: mergedInnerDivStyles,
					insetChildren: insetChildren,
					leftIcon: leftIconElement,
					ref: 'listItem',
					rightIcon: rightIconElement,
					role: 'menuitem',
					style: mergedRootStyles
				}),
				children,
				secondaryTextElement,
				childMenuPopover
			);
		}
	}]);
	return MenuItem;
}(_react.Component);

MenuItem.muiName = 'MenuItem';
MenuItem.defaultProps = {
	anchorOrigin: {
		horizontal: 'right',
		vertical: 'top'
	},
	checked: false,
	desktop: false,
	disabled: false,
	focusState: 'none',
	insetChildren: false,
	targetOrigin: {
		horizontal: 'left',
		vertical: 'top'
	}
};

var MuiThemeContext = require('../styles/context').default;

MenuItem.contextType = MuiThemeContext;

MenuItem.propTypes = process.env.NODE_ENV !== "production" ? {
	/**
	 * Location of the anchor for the popover of nested `MenuItem`
	 * elements.
	 * Options:
	 * horizontal: [left, middle, right]
	 * vertical: [top, center, bottom].
	 */
	anchorOrigin: _propTypes4.default.origin,
	/**
	 * Override the default animation component used.
	 */
	animation: _propTypes2.default.func,
	/**
	 * If true, a left check mark will be rendered.
	 */
	checked: _propTypes2.default.bool,
	/**
	 * Elements passed as children to the underlying `ListItem`.
	 */
	children: _propTypes2.default.node,
	/**
	 * @ignore
	 * If true, the menu item will render with compact desktop
	 * styles.
	 */
	desktop: _propTypes2.default.bool,
	/**
	 * If true, the menu item will be disabled.
	 */
	disabled: _propTypes2.default.bool,
	/**
	 * The focus state of the menu item. This prop is used to set the focus
	 * state of the underlying `ListItem`.
	 */
	focusState: _propTypes2.default.oneOf(['none', 'focused', 'keyboard-focused']),
	/**
	 * Override the inline-styles of the inner div.
	 */
	innerDivStyle: _propTypes2.default.object,
	/**
	 * If true, the children will be indented.
	 * This is only needed when there is no `leftIcon`.
	 */
	insetChildren: _propTypes2.default.bool,
	/**
	 * The `SvgIcon` or `FontIcon` to be displayed on the left side.
	 */
	leftIcon: _propTypes2.default.element,
	/**
	 * `MenuItem` elements to nest within the menu item.
	 */
	menuItems: _propTypes2.default.node,
	/**
	 * Callback function fired when the menu item is touch-tapped.
	 *
	 * @param {object} event TouchTap event targeting the menu item.
	 */
	onClick: _propTypes2.default.func,
	/**
	 * Can be used to render primary text within the menu item.
	 */
	primaryText: _propTypes2.default.node,
	/**
	 * The `SvgIcon` or `FontIcon` to be displayed on the right side.
	 */
	rightIcon: _propTypes2.default.element,
	/**
	 * Can be used to render secondary text within the menu item.
	 */
	secondaryText: _propTypes2.default.node,
	/**
	 * Override the inline-styles of the root element.
	 */
	style: _propTypes2.default.object,
	/**
	 * Location on the popover of nested `MenuItem` elements that will attach
	 * to the anchor's origin.
	 * Options:
	 * horizontal: [left, middle, right]
	 * vertical: [top, center, bottom].
	 */
	targetOrigin: _propTypes4.default.origin,
	/**
	 * The value of the menu item.
	 */
	value: _propTypes2.default.any
} : {};
exports.default = MenuItem;