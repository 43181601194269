import {
	checkAsyncAction,
	createAsyncInitialState,
	createReducer,
	invalidateStateFromAction,
	StoreAsyncItem,
} from '@flux';
import { ComplexFilter } from '@core/api/core';
import { createDefaultPeriod } from '@utils/date';
import { types } from '@project-management/actions/types';
import {
	OperationDateTypeCode,
	ProjectRelevanceCode,
	ProjectStatisticsRatiosCode,
	ProjectStatisticsSliceCode,
	ProjectViewCode,
	Sorting,
	VirtualListGroupCode,
} from '@project-management/models';

export type MainProjectManagementState = {
	projects: StoreAsyncItem<Array<Project>>;
	allDynamicSeries: StoreAsyncItem<Array<PLOperationProjectSeries>>;
	textFilter: string;
	tenantEntitiesFilter: Record<string, boolean>;
	businessUnitsFilter: Record<string, boolean>;
	clientsFilter: Record<string, boolean>;
	managersFilter: Record<string, boolean>;
	relevanceFilter: Partial<Record<ProjectRelevanceCode, boolean>>;
	fundsRegistersFilter: ComplexFilter;
	projectView: ProjectViewCode;
	virtualListGroup: VirtualListGroupCode;
	dateRange: DateRange;
	operationDateType: OperationDateTypeCode;
	operationsDirectionFilter: ProjectStatisticsSliceCode | null;
	expandedHierarchyMap: Record<string, boolean>;
	detailDynamicSeries: StoreAsyncItem<Array<PLOperationProjectSeries>>;
	operations: StoreAsyncItem<Array<PLOperationBrief>>;
	projectsDateRange: DateRange;
	projectsDateType: OperationDateTypeCode;
	isIncludesPlan: boolean;
	sorting: Sorting;
};

const initialState: MainProjectManagementState = {
	projects: createAsyncInitialState([]),
	allDynamicSeries: createAsyncInitialState([]),
	textFilter: '',
	tenantEntitiesFilter: {},
	businessUnitsFilter: {},
	clientsFilter: {},
	managersFilter: {},
	relevanceFilter: {},
	fundsRegistersFilter: new ComplexFilter(),
	projectView: ProjectViewCode.LIST,
	virtualListGroup: VirtualListGroupCode.LEGAL_ENTITY,
	dateRange: createDefaultPeriod('month'),
	operationDateType: OperationDateTypeCode.CASHFLOW,
	operationsDirectionFilter: null,
	expandedHierarchyMap: {},
	detailDynamicSeries: createAsyncInitialState([]),
	operations: createAsyncInitialState([]),
	projectsDateRange: createDefaultPeriod('month'),
	projectsDateType: OperationDateTypeCode.CASHFLOW,
	isIncludesPlan: false,
	sorting: { sortBy: ProjectStatisticsRatiosCode.INCOME, isAscOrder: true },
};

const mainProjectManagementReducer = createReducer<MainProjectManagementState>(initialState, {
	[types.INVALIDATE_PROJECTS]: (action: StaticAction, state) => {
		return {
			projects: invalidateStateFromAction(action, state.projects),
			detailDynamicSeries: invalidateStateFromAction(action, state.detailDynamicSeries),
			operations: invalidateStateFromAction(action, state.operations),
		};
	},
	[types.INVALIDATE_OPERATIONS]: (action: StaticAction, state) => {
		return {
			allDynamicSeries: invalidateStateFromAction(action, state.allDynamicSeries),
			detailDynamicSeries: invalidateStateFromAction(action, state.detailDynamicSeries),
			operations: invalidateStateFromAction(action, state.operations),
		};
	},
	[types.FETCH_PROJECTS]: (action: AsyncAction<Array<Project>>, state) => {
		return {
			projects: checkAsyncAction(action, state.projects),
			allDynamicSeries: invalidateStateFromAction(action, state.allDynamicSeries),
			operations: invalidateStateFromAction(action, state.operations),
		};
	},
	[types.FETCH_ALL_PROJECT_DYNAMIC]: (action: AsyncAction<PLOperationDynamics>, state) => {
		return {
			allDynamicSeries: checkAsyncAction(action, state.allDynamicSeries, result => result.Series),
		};
	},
	[types.ADD_PROJECT]: (action: AsyncAction, state) => {
		return {
			projects: invalidateStateFromAction(action, state.projects),
			detailDynamicSeries: invalidateStateFromAction(action, state.detailDynamicSeries),
			operations: invalidateStateFromAction(action, state.operations),
		};
	},
	[types.UPDATE_PROJECT]: (action: AsyncAction, state) => {
		return {
			projects: invalidateStateFromAction(action, state.projects),
			detailDynamicSeries: invalidateStateFromAction(action, state.detailDynamicSeries),
			operations: invalidateStateFromAction(action, state.operations),
		};
	},
	[types.REMOVE_PROJECT]: (action: AsyncAction, state) => {
		return {
			projects: invalidateStateFromAction(action, state.projects),
			detailDynamicSeries: invalidateStateFromAction(action, state.detailDynamicSeries),
			operations: invalidateStateFromAction(action, state.operations),
		};
	},
	[types.SET_FILTER_BY_TEXT]: (action: StaticAction<string>) => {
		return {
			textFilter: action.value,
			expandedHierarchyMap: {},
		};
	},
	[types.SET_FILTER_BY_TENANT_ENTITIES]: (action: StaticAction<Record<string, boolean>>) => {
		return {
			tenantEntitiesFilter: { ...action.value },
			expandedHierarchyMap: {},
		};
	},
	[types.SET_FILTER_BY_BUSINESS_UNITS]: (action: StaticAction<Record<string, boolean>>) => {
		return {
			businessUnitsFilter: { ...action.value },
			expandedHierarchyMap: {},
		};
	},
	[types.SET_FILTER_BY_CLIENTS]: (action: StaticAction<Record<string, boolean>>) => {
		return {
			clientsFilter: { ...action.value },
			expandedHierarchyMap: {},
		};
	},
	[types.SET_FILTER_BY_MANAGERS]: (action: StaticAction<Record<string, boolean>>) => {
		return {
			managersFilter: { ...action.value },
			expandedHierarchyMap: {},
		};
	},
	[types.SET_FILTER_BY_RELEVANCE]: (action: StaticAction<Record<ProjectRelevanceCode, boolean>>) => {
		return {
			relevanceFilter: { ...action.value },
			expandedHierarchyMap: {},
		};
	},
	[types.SET_FUNDS_REGISTERS_FILTER]: (action: StaticAction<ComplexFilter>, state) => {
		return {
			fundsRegistersFilter: new ComplexFilter({ ...action.value }),
			expandedHierarchyMap: {},
			allDynamicSeries: invalidateStateFromAction(action, state.allDynamicSeries),
			detailDynamicSeries: invalidateStateFromAction(action, state.detailDynamicSeries),
			operations: invalidateStateFromAction(action, state.operations),
		};
	},
	[types.RESET_FILTERS]: () => {
		return {
			textFilter: '',
			tenantEntitiesFilter: {},
			businessUnitsFilter: {},
			clientsFilter: {},
			managersFilter: {},
			relevanceFilter: {},
			fundsRegistersFilter: new ComplexFilter(),
			expandedHierarchyMap: {},
		};
	},
	[types.SET_PROJECT_VIEW]: (action: StaticAction<ProjectViewCode>) => {
		return {
			projectView: action.value,
		};
	},
	[types.SET_VIRTUAL_LIST_GROUP]: (action: StaticAction<VirtualListGroupCode>) => {
		return {
			virtualListGroup: action.value,
		};
	},
	[types.SET_DATE_RANGE]: (action: StaticAction<DateRange>, state) => {
		return {
			dateRange: action.value,
			detailDynamicSeries: invalidateStateFromAction(action, state.detailDynamicSeries),
			operations: invalidateStateFromAction(action, state.operations),
		};
	},
	[types.SET_IS_INCLUDES_PLAN]: (action: StaticAction<boolean>) => {
		return {
			isIncludesPlan: action.value,
		};
	},
	[types.SET_OPERATION_DATE_TYPE]: (action: StaticAction<OperationDateTypeCode>, state) => {
		return {
			operationDateType: action.value,
			detailDynamicSeries: invalidateStateFromAction(action, state.detailDynamicSeries),
			operations: invalidateStateFromAction(action, state.operations),
		};
	},
	[types.SET_OPERATIONS_FILTER_BY_DERECTION]: (action: StaticAction<ProjectStatisticsSliceCode>, state) => {
		return {
			operationsDirectionFilter: state.operationsDirectionFilter === action.value ? null : action.value,
		};
	},
	[types.SET_EXPANDED_HIERARCHY_MAP]: (action: StaticAction<Record<string, boolean>>) => {
		return {
			expandedHierarchyMap: { ...action.value },
		};
	},
	[types.FETCH_DETAIL_PROJECT_DYNAMIC]: (action: AsyncAction<PLOperationDynamics>, state) => {
		return {
			detailDynamicSeries: checkAsyncAction(action, state.detailDynamicSeries, result => result.Series),
		};
	},
	[types.FETCH_PROJECT_OPERATIONS]: (action: AsyncAction<Array<PLOperationBrief>>, state) => {
		return {
			operations: checkAsyncAction(action, state.operations),
		};
	},
	[types.LINK_OPERATIONS_TO_PROJECT]: (action: AsyncAction<boolean>, state) => {
		return {
			allDynamicSeries: invalidateStateFromAction(action, state.allDynamicSeries),
			detailDynamicSeries: invalidateStateFromAction(action, state.detailDynamicSeries),
			operations: invalidateStateFromAction(action, state.operations),
		};
	},
	[types.SET_PROJECTS_DATE_RANGE]: (action: StaticAction<DateRange>, state) => {
		return {
			projectsDateRange: action.value,
			allDynamicSeries: invalidateStateFromAction(action, state.allDynamicSeries),
			operations: invalidateStateFromAction(action, state.operations),
		};
	},
	[types.SET_PROJECTS_DATE_TYPE]: (action: StaticAction<OperationDateTypeCode>, state) => {
		return {
			projectsDateType: action.value,
			allDynamicSeries: invalidateStateFromAction(action, state.allDynamicSeries),
			operations: invalidateStateFromAction(action, state.operations),
		};
	},
	[types.SET_SORTING]: (action: StaticAction<ProjectStatisticsRatiosCode>, state) => {
		return {
			sorting:
				state.sorting.sortBy === action.value
					? { ...state.sorting, isAscOrder: !state.sorting.isAscOrder }
					: { sortBy: action.value, isAscOrder: true },
		};
	},
});

export { mainProjectManagementReducer };
