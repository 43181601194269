// @ts-nocheck
import * as _ from 'underscore';
import * as moment from 'moment';

import { BASE_DATE_FORMAT } from '../../shared/constants/time';
import createAction from '../../../core/libs/action-creator';


const FETCH_INVOICE_LIST = '[INVOICE]: FETCH_INVOICE_LIST';
const FETCH_INVOICE_LIST_PREV = '[INVOICE]: FETCH_INVOICE_LIST_PREV';
const FETCH_INVOICE_LIST_FOR_ANALYTICS = '[INVOICE]: FETCH_INVOICE_LIST_FOR_ANALYTICS';

const fetchInvoiceListAction = createAction<Array<FinancialDocument>>(FETCH_INVOICE_LIST, updateInvoiceList);
const fetchInvoiceListPrevAction = createAction(FETCH_INVOICE_LIST_PREV, updateInvoiceList);
const fetchInvoiceListForAnalyticsAction = createAction(FETCH_INVOICE_LIST_FOR_ANALYTICS, fetchInvoiceListForAnalytics);

function updateInvoiceList(api,  getState, dispatch, dataStart: Moment, dataEnd: Moment) {
	return new Promise<Array<FinancialDocument>>((resolve, reject) => {
		if (!dataStart || !dataEnd) {
			reject(new Error('Не выбран период получаемых счетов'));
			return;
		}
		var request = new api.invoicepackage.FinancialDocumentFilter();
		request.PeriodDateStart = dataStart.format('DD-MM-YYYY');
		request.PeriodDateEnd = dataEnd.format('DD-MM-YYYY');
		request.FinancialDocumentType = 'Invoice';

		api.financialDocumentServiceClient.getDocumentList(request, resolve);
	});
};

function fetchInvoiceListForAnalytics(api, getState, dispatch, dateStart: string, dateEnd: string) {
	return new Promise(resolve => {
		const dateStartMoment = moment(dateStart, BASE_DATE_FORMAT);
		const dateEndMoment = moment(dateEnd, BASE_DATE_FORMAT);
		const diffInDays = dateEndMoment.diff(dateStartMoment, 'days');
		const dateStartMomentPrev = dateStartMoment.clone().subtract(diffInDays, 'day');
		const dateEndMomentPrev = dateEndMoment.clone().subtract(diffInDays, 'day');

		dispatch(fetchInvoiceListAction(dateStartMoment, dateEndMoment));
		dispatch(fetchInvoiceListPrevAction(dateStartMomentPrev, dateEndMomentPrev));
		resolve();
	});
}

export {
	FETCH_INVOICE_LIST,
	FETCH_INVOICE_LIST_PREV,
	FETCH_INVOICE_LIST_FOR_ANALYTICS,
	fetchInvoiceListAction,
	fetchInvoiceListPrevAction,
	fetchInvoiceListForAnalyticsAction
}

export default fetchInvoiceListAction;
