import {
	ALFA_BANK_BNK_CMS,
	MODULE_BANK_BNK_CMS,
	TOCHKA_BANK_BNK_CMS,
	SBERBANK_BNK_CMS,
	TINKOFF_BANK_BNK_CMS,
	OPEN_BANK_BNK_CMS,
	URALSIB_BNK_CMS,
	VTB_BANK_CMS,
} from '@shared/constants/cms-guids';

const bankBrandColorsMap = {
	[ALFA_BANK_BNK_CMS]: {
		accentPrimary: '#EA2C14',
		textAccentColor: '#EA2C14',
		contrastAccent: '#fff',
	},
	[MODULE_BANK_BNK_CMS]: {
		accentPrimary: '#8cca11',
		textAccentColor: '#8cca11',
		contrastAccent: '#fff',
	},
	[TOCHKA_BANK_BNK_CMS]: {
		accentPrimary: '#E3AEFF',
		textAccentColor: '#222',
		contrastAccent: '#222',
	},
	[SBERBANK_BNK_CMS]: {
		accentPrimary: '#21a038',
		textAccentColor: '#19BB4F',
		contrastAccent: '#fff',
	},
	[TINKOFF_BANK_BNK_CMS]: {
		accentPrimary: '#ffcd33',
		textAccentColor: '#1464cc',
		contrastAccent: '#333',
	},
	[OPEN_BANK_BNK_CMS]: {
		accentPrimary: '#17CFFF',
		textAccentColor: '#17CFFF',
		contrastAccent: '#fff',
	},
	[URALSIB_BNK_CMS]: {
		accentPrimary: '#fedb31',
		textAccentColor: '#000',
		contrastAccent: '#000',
	},
	[VTB_BANK_CMS]: {
		accentPrimary: '#1e4bd2',
		textAccentColor: '#1e4bd2',
		contrastAccent: '#fff',
	},
};

export { bankBrandColorsMap };
