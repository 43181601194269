import {
	StoreAsyncItem,
	createReducer,
	createAsyncInitialState,
	checkAsyncAction,
	invalidateStateFromAction,
	restoreValidationState,
} from '@flux';
import { FetchOperationsOptions } from '@core/api/pl';
import { getMirroredDateRange, addMonthsFromToday } from '@utils/date';
import { types } from '@planning/actions/types';
import { PlanningViewModeCode, PlanningGranulationModeCode, CellCoords } from '@planning/models';
import { ComplexFilter } from '@core/api/core';
import { OperationDateType } from '@pl/model';

export type MainPlanningState = {
	dateRange: DateRange;
	reportItems: StoreAsyncItem<Array<PLOperationPlanningReportItem>>;
	cashflowForecast: StoreAsyncItem<CashflowForecast>;
	forecastIndicatorDate: string;
	forecastIndicatorData: StoreAsyncItem<CashflowForecast>;
	cashGapIndicatorData: StoreAsyncItem<CashflowForecast>;
	planOperations: StoreAsyncItem<Array<PLOperationBrief>>;
	detailsOperations: StoreAsyncItem<Array<PLOperationBrief>>;
	viewMode: PlanningViewModeCode;
	granulationMode: PlanningGranulationModeCode;
	searchText: string;
	isChartVisible: boolean;
	expandedHierarchyMap: Record<string, boolean>;
	coords: CellCoords;
	detailsOptions: FetchOperationsOptions | null;
	detailsTitle: string | null;
	dateType: OperationDateType;
	tenantEntitiesFilter: ComplexFilter;
	businessUnitsFilter: ComplexFilter;
	projectsFilter: ComplexFilter;
	cashflowItemsFilter: ComplexFilter;
	fundsRegistersFilter: ComplexFilter;
};

const initialState: MainPlanningState = {
	dateRange: getMirroredDateRange({
		past: {
			value: 5,
			type: 'day',
		},
		future: {
			value: 1,
			type: 'month',
		},
	}),
	reportItems: createAsyncInitialState([]),
	cashflowForecast: createAsyncInitialState(null),
	forecastIndicatorDate: addMonthsFromToday(1),
	forecastIndicatorData: createAsyncInitialState(null),
	cashGapIndicatorData: createAsyncInitialState(null),
	planOperations: createAsyncInitialState([]),
	detailsOperations: createAsyncInitialState([]),
	viewMode: PlanningViewModeCode.TABLE,
	granulationMode: PlanningGranulationModeCode.DAYS,
	searchText: '',
	isChartVisible: true,
	expandedHierarchyMap: {},
	coords: null,
	detailsOptions: null,
	detailsTitle: null,
	dateType: OperationDateType.CASHFLOW,
	tenantEntitiesFilter: new ComplexFilter(),
	businessUnitsFilter: new ComplexFilter(),
	projectsFilter: new ComplexFilter(),
	cashflowItemsFilter: new ComplexFilter(),
	fundsRegistersFilter: new ComplexFilter(),
};

const mainPlanningReducer = createReducer<MainPlanningState>(initialState, {
	[types.INVALIDATE_MODULE_DATA]: (action: StaticAction, state) => {
		return {
			reportItems: invalidateStateFromAction(action, state.reportItems),
			cashflowForecast: invalidateStateFromAction(action, state.cashflowForecast),
			forecastIndicatorData: invalidateStateFromAction(action, state.forecastIndicatorData),
			cashGapIndicatorData: invalidateStateFromAction(action, state.cashGapIndicatorData),
			planOperations: invalidateStateFromAction(action, state.planOperations),
			detailsOperations: invalidateStateFromAction(action, state.detailsOperations),
		};
	},
	[types.SET_DATE_RANGE]: (action: StaticAction<DateRange>, state) => {
		return {
			dateRange: action.value,
			reportItems: invalidateStateFromAction(action, state.reportItems),
			cashflowForecast: invalidateStateFromAction(action, state.cashflowForecast),
			planOperations: invalidateStateFromAction(action, state.planOperations),
			detailsOperations: invalidateStateFromAction(action, state.detailsOperations),
			coords: null,
			detailsOptions: null,
			detailsTitle: null,
		};
	},
	[types.SET_FORECAST_INDICATOR_DATE]: (action: StaticAction<string>, state) => {
		return {
			forecastIndicatorDate: action.value,
			forecastIndicatorData: invalidateStateFromAction(action, state.forecastIndicatorData),
		};
	},
	[types.SET_VIEW_MODE]: (action: StaticAction<PlanningViewModeCode>) => {
		return {
			viewMode: action.value,
		};
	},
	[types.SET_GRANULATION_MODE]: (action: StaticAction<PlanningGranulationModeCode>, state) => {
		return {
			granulationMode: action.value,
			reportItems: invalidateStateFromAction(action, state.reportItems),
			coords: null,
			detailsOptions: null,
			detailsTitle: null,
		};
	},
	[types.SET_SEARCH_TEXT]: (action: StaticAction<string>) => {
		return {
			searchText: action.value,
			expandedHierarchyMap: {},
		};
	},
	[types.SET_CHART_VISIBILITY]: (action: StaticAction<boolean>) => {
		return {
			isChartVisible: action.value,
		};
	},
	[types.SET_SELECTED_COORDS]: (action: StaticAction<CellCoords>) => {
		return {
			coords: action.value,
		};
	},
	[types.SET_OPERATION_DETAILS_OPTIONS]: (action: StaticAction<FetchOperationsOptions | null>, state) => {
		return {
			detailsOptions: action.value,
			detailsTitle: action.value ? state.detailsTitle : null,
			coords: action.value ? state.coords : null,
			detailsOperations: action.value
				? invalidateStateFromAction(action, state.detailsOperations)
				: createAsyncInitialState([]),
		};
	},
	[types.SET_OPERATION_DETAILS_TITLE]: (action: StaticAction<string | null>, state) => {
		return {
			detailsTitle: action.value,
		};
	},
	[types.RESTORE_OPERATION_DETAILS_VALIDATION]: (_, state) => {
		return {
			detailsOperations: restoreValidationState(state.detailsOperations),
		};
	},
	[types.FETCH_PLANNING_REPORT]: (action: AsyncAction<Array<PLOperationPlanningReportItem>>, state) => {
		return {
			reportItems: checkAsyncAction(action, state.reportItems),
		};
	},
	[types.FETCH_CASHFLOW_FORECAST]: (action: AsyncAction<CashflowForecast>, state) => {
		return {
			cashflowForecast: checkAsyncAction(action, state.cashflowForecast),
		};
	},
	[types.FETCH_FORECAST_INDICATOR_DATA]: (action: AsyncAction<CashflowForecast>, state) => {
		return {
			forecastIndicatorData: checkAsyncAction(action, state.forecastIndicatorData),
		};
	},
	[types.FETCH_CASH_GAP_INDICATOR_DATA]: (action: AsyncAction<CashflowForecast>, state) => {
		return {
			cashGapIndicatorData: checkAsyncAction(action, state.cashGapIndicatorData),
		};
	},
	[types.FETCH_PLAN_OPERATIONS]: (action: AsyncAction<Array<PLOperationBrief>>, state) => {
		return {
			planOperations: checkAsyncAction(action, state.planOperations),
		};
	},
	[types.FETCH_DETAILS_OPERATIONS]: (action: AsyncAction<Array<PLOperationBrief>>, state) => {
		return {
			detailsOperations: checkAsyncAction(action, state.detailsOperations),
		};
	},
	[types.SET_EXPANDED_HIERARCHY_MAP]: (action: StaticAction<Record<string, boolean>>, state) => {
		return {
			expandedHierarchyMap: { ...action.value },
		};
	},
	[types.SET_DATE_TYPE]: (action: StaticAction<OperationDateType>, state) => {
		return {
			dateType: action.value,
			reportItems: invalidateStateFromAction(action, state.reportItems),
			cashflowForecast: invalidateStateFromAction(action, state.cashflowForecast),
			planOperations: invalidateStateFromAction(action, state.planOperations),
			coords: null,
			detailsTitle: null,
			detailsOptions: null,
			detailsOperations: createAsyncInitialState([]),
		};
	},
	[types.SET_TENANT_ENTITIES_FILTER]: (action: StaticAction<ComplexFilter>, state) => {
		return {
			tenantEntitiesFilter: action.value,
			fundsRegistersFilter: new ComplexFilter(),
			reportItems: invalidateStateFromAction(action, state.reportItems),
			cashflowForecast: invalidateStateFromAction(action, state.cashflowForecast),
			planOperations: invalidateStateFromAction(action, state.planOperations),
			coords: null,
			detailsTitle: null,
			detailsOptions: null,
			detailsOperations: createAsyncInitialState([]),
		};
	},
	[types.SET_BUSINESS_UNITS_FILTER]: (action: StaticAction<ComplexFilter>, state) => {
		return {
			businessUnitsFilter: action.value,
			reportItems: invalidateStateFromAction(action, state.reportItems),
			cashflowForecast: invalidateStateFromAction(action, state.cashflowForecast),
			planOperations: invalidateStateFromAction(action, state.planOperations),
			coords: null,
			detailsTitle: null,
			detailsOptions: null,
			detailsOperations: createAsyncInitialState([]),
		};
	},
	[types.SET_PROJECTS_FILTER]: (action: StaticAction<ComplexFilter>, state) => {
		return {
			projectsFilter: action.value,
			reportItems: invalidateStateFromAction(action, state.reportItems),
			cashflowForecast: invalidateStateFromAction(action, state.cashflowForecast),
			planOperations: invalidateStateFromAction(action, state.planOperations),
			coords: null,
			detailsTitle: null,
			detailsOptions: null,
			detailsOperations: createAsyncInitialState([]),
		};
	},
	[types.SET_CASHFLOW_ITEMS_FILTER]: (action: StaticAction<ComplexFilter>, state) => {
		return {
			cashflowItemsFilter: action.value,
			reportItems: invalidateStateFromAction(action, state.reportItems),
			cashflowForecast: invalidateStateFromAction(action, state.cashflowForecast),
			planOperations: invalidateStateFromAction(action, state.planOperations),
			coords: null,
			detailsTitle: null,
			detailsOptions: null,
			detailsOperations: createAsyncInitialState([]),
		};
	},
	[types.SET_FUNDS_REGISTERS_FILTER]: (action: StaticAction<ComplexFilter>, state) => {
		return {
			fundsRegistersFilter: action.value,
			reportItems: invalidateStateFromAction(action, state.reportItems),
			cashflowForecast: invalidateStateFromAction(action, state.cashflowForecast),
			planOperations: invalidateStateFromAction(action, state.planOperations),
			coords: null,
			detailsTitle: null,
			detailsOptions: null,
			detailsOperations: createAsyncInitialState([]),
		};
	},
});

export { mainPlanningReducer };
