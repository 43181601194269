import React, { CSSProperties } from 'react';
import styled, { css, keyframes } from 'styled-components';

type WithSoftFetchingOptions<P> = {
	enableCover?:
		| boolean
		| {
				compensateMargins: boolean;
		  };
	enableSpinner?:
		| boolean
		| {
				fixed?: boolean;
				topShift?: number;
		  };
	forcePaint?: boolean;
	style?: CSSProperties;
	shouldUpdateWhen?: (props: P, nextProps: P) => boolean;
};

function withSoftFetching<P extends RequiredComponentProps>(
	options: WithSoftFetchingOptions<P & RequiredComponentProps> = {},
) {
	const {
		shouldUpdateWhen = () => false,
		enableCover = false,
		enableSpinner = false,
		forcePaint,
		style = {},
	} = options;
	return (WrappedComponent: React.ComponentType<P>) => {
		class UpdateScheduler extends React.Component<P> {
			static displayName = `withSoftFetching(${WrappedComponent.name || WrappedComponent.displayName})`;

			shouldComponentUpdate(nextProps: P) {
				if (nextProps.isFetching) {
					return Boolean(shouldUpdateWhen(this.props, nextProps));
				}

				return true;
			}

			render() {
				const { onSetRef } = this.props;

				return <WrappedComponent ref={onSetRef} {...this.props} />;
			}
		}

		class SoftFetching extends React.Component<P> {
			isNextUpdateAvailable = true;
			static defaultProps = {
				onSetRef: ref => {},
			};

			getSnapshotBeforeUpdate(prevProps: P) {
				this.isNextUpdateAvailable = Boolean(shouldUpdateWhen(prevProps, this.props));

				return null;
			}

			componentDidUpdate() {}

			render() {
				const { isFetching } = this.props;
				const isSpinnerEnabled = Boolean(enableSpinner);
				const isFixedSpinner = typeof enableSpinner === 'object' && enableSpinner.fixed === true;
				const topShift =
					typeof enableSpinner === 'object' && typeof enableSpinner.topShift === 'number' ? enableSpinner.topShift : 0;
				const needCompensateMargin = typeof enableCover === 'object' && enableCover.compensateMargins === true;
				const hasSpinner = isFetching && isSpinnerEnabled && (forcePaint || !this.isNextUpdateAvailable);

				if (!enableCover) {
					return <UpdateScheduler style={style} {...this.props} />;
				}

				return (
					<Root style={style}>
						{hasSpinner && (
							<SpinnerLayout isFixed={isFixedSpinner} topShift={topShift}>
								<UpdatingSpinner />
							</SpinnerLayout>
						)}
						<Cover
							style={style}
							isFetching={isFetching}
							isNextUpdateAvailable={forcePaint ? false : this.isNextUpdateAvailable}
							needCompensateMargin={needCompensateMargin}>
							<UpdateScheduler {...this.props} />
						</Cover>
					</Root>
				);
			}
		}

		return SoftFetching;
	};
}

type RequiredComponentProps = {
	isFetching?: boolean;
	onSetRef?: (ref: any) => void;
};

type CoverProps = {
	isFetching: boolean;
	isNextUpdateAvailable: boolean;
	needCompensateMargin: boolean;
};

const Root = styled.div`
	position: relative;
`;

const Cover = styled.div<CoverProps>`
	position: relative;

	&::after {
		display: block;
		content: '';
		position: absolute;
		z-index: 100;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		pointer-events: none;
		transition: background-color 200ms ease-in-out;

		${p =>
			p.needCompensateMargin &&
			css`
				right: -30px;
				left: -30px;
			`}
	}

	${p =>
		p.isFetching &&
		!p.isNextUpdateAvailable &&
		css`
			user-select: none;
			opacity: 0.4;

			&::after {
				pointer-events: all;
			}
		`}
`;

const pulseMotion = keyframes`
	0%,
	80%,
	100% {
		box-shadow: 0 0;
		height: 4em;
	}
	40% {
		box-shadow: 0 -2em;
		height: 5em;
	}
`;

const UpdatingSpinner = styled.div`
	position: absolute;
	font-size: 8px;
	transform: translateZ(0);
	animation: ${pulseMotion} 1s infinite ease-in-out;
	width: 1em;
	height: 4em;
	animation-delay: -0.16s;
	zoom: 0.8;

	${p => css`
		color: ${p.theme.palette.accent};
		background-color: ${p.theme.palette.accent};

		&::before,
		&::after {
			position: absolute;
			top: 0;
			content: '';
			background-color: ${p.theme.palette.accent};
			animation: ${pulseMotion} 1s infinite ease-in-out;
			width: 1em;
			height: 4em;
		}

		&::before {
			left: -1.5em;
			animation-delay: -0.32s;
		}

		&::after {
			left: 1.5em;
		}
	`}
`;

type SpinnerLayoutProps = {
	isFixed: boolean;
	topShift: number;
};

const SpinnerLayout = styled.div<SpinnerLayoutProps>`
	position: ${p => (p.isFixed ? 'fixed' : 'absolute')};
	top: 50%;
	left: 50%;
	width: 6px;
	height: 30px;
	transform: translate(-50%, -50%);
	z-index: 110;
	opacity: 0.8;

	${p =>
		p.topShift &&
		css`
			top: calc(50% + ${p.topShift}px);
		`}
`;

export default withSoftFetching;
