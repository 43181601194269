import React, { memo } from 'react';

import { useMapState, useMapDispatch, useAutoFetch } from '@flux';
import { createBooleanMap } from '@utils/object';
import { withFormAutopicker } from '@ui/autopicker';
import { mainFundsRegistersActionsPack } from '@funds-registers/actions';
import { mainFundsRegistersSelectorsPack } from '@funds-registers/selectors';
import { BankAutopicker as XBankAutopicker, BankAutopickerProps as XBankAutopickerProps } from './bank-autopicker.view';

export type BankAutopickerProps = {
	name?: string;
} & Omit<XBankAutopickerProps, 'dataSource' | 'isFetching'>;

const BankAutopicker: React.FC<BankAutopickerProps> = memo(props => {
	const [isFetching, isLoaded, dataSource] = useMapState([
		mainFundsRegistersSelectorsPack.selectAsyncBanks.selectIsFetching,
		mainFundsRegistersSelectorsPack.selectAsyncBanks.selectIsLoaded,
		mainFundsRegistersSelectorsPack.selectAsyncBanks.selectItem,
	]);
	const [fetchBanks] = useMapDispatch([mainFundsRegistersActionsPack.fetchBanks]);

	useAutoFetch({
		selector: mainFundsRegistersSelectorsPack.selectAsyncBanks.selectDidInvalidate,
		fetch: () => fetchBanks(),
	});

	return <XBankAutopicker {...props} isFetching={isFetching} isLoaded={isLoaded} dataSource={dataSource} />;
});

const FormBankAutopicker = withFormAutopicker<Omit<BankAutopickerProps, 'name'>, unknown>(BankAutopicker);

const bankAutopickerTransformer = {
	bank: {
		single: {
			transformInput: ({ input }: { input: Bank | null }) => (input ? { [input.ID]: true } : null),
			transformOutput: ({ items }) => (items[0] as Bank) || null,
		},
		multiple: {
			transformInput: ({ input }: { input: Array<Bank> }) => createBooleanMap(input, x => x.ID),
			transformOutput: ({ items }) => items as Array<Bank>,
		},
	},
};

export { BankAutopicker, FormBankAutopicker, bankAutopickerTransformer };
