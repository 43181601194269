import styled, { css } from 'styled-components';

const Root = styled.div`
	position: relative;
	width: 100%;
	height: auto;
	background-color: transparent;
`;

const ContentLayout = styled.div`
	position: relative;
	width: 100%;
	height: auto;
`;

const MessageLayout = styled.div`
	display: flex;
	flex-flow: column wrap;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 30px 40px;
`;

const ControlsLayout = styled.div`
	position: relative;
	width: 100%;
	height: auto;
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	align-items: center;
	border-top: 1px solid transparent;

	${p => css`
		border-color: ${p.theme.palette.stealth};
	`}
`;

const InfoLinkLayout = styled.div`
	width: 100%;
	margin-top: 20px;
`;

export { Root, ContentLayout, ControlsLayout, MessageLayout, InfoLinkLayout };
