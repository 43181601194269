import React from 'react';

import { getGlobalConfig, getAppName } from '@config';
import { getFundsRegisterType, getDrawer, getPropInSafe } from '@libs/utils';
import * as statuses from '@shared/constants/statuses';
import { Form, FormInputField, SubmitRaisedButton, validators } from '@ui/forms';
import { RaisedButton } from '@ui/raised-button';
import { MessageCard, AlertCard } from '@ui/cards';
import { RouterLink } from '@ui/router-link';
import { Loader } from '@ui/loader';
import { Alert } from '@ui/alert';
import { Box } from '@ui/box';
import { ROUTE_MAP } from '@routes/urls';
import { Shape } from '@ui/units';
import {
	FormFundsRegisterAutopicker,
	fundsRegisterAutopickerTransformer,
} from '@funds-registers/components/funds-register-autopicker';
import { Root, FormLayout, ButtonLayout, EmailLayout, SignLayout } from './styled';

const ACCOUNTS_URL = ROUTE_MAP.ACCOUNTS;
const isSberbank = getGlobalConfig().isSberbank;
const isInvoiceApp = getAppName() === 'invoice';

const SBERBANK_BNK_CMS = 'SBERBANK_BNK_CMS';

export type FastBillFormProps = {
	tenantBills: Record<string, TenantBill>;
	tenant: Tenant;
	status: StatusAction;
	frList: Array<FundsRegister>;
	CMSPaymentResponse: CMSCreatePaymentDraftResponse;
	isFetching: boolean;
	initFastBillEffect: ({ email: string, frID: number }) => void;
};

type FastBillFormState = {
	isFormOpen: boolean;
};

class FastBillForm extends React.Component<FastBillFormProps, FastBillFormState> {
	static displayName = 'FastBillForm';
	state: FastBillFormState = { isFormOpen: false };
	formObject = {
		FundsRegisterID: null,
		EMail: '',
	};

	handleClose = () => {
		setTimeout(() => getDrawer().close(), 1000);
	};

	handleOpenForm = () => {
		this.setState({ isFormOpen: true });
	};

	handleSubmitForm = ({ FundsRegisterID, EMail }) => {
		const { initFastBillEffect } = this.props;

		initFastBillEffect({ email: EMail, frID: FundsRegisterID });
	};

	handleSignPayment = () => {
		const { CMSPaymentResponse } = this.props;
		const signBankURL = getPropInSafe(CMSPaymentResponse, o => o.CMSPaymentDraft.SignBankURL, '');
		const isFastSignSupport = getPropInSafe(CMSPaymentResponse, o => o.CMSPaymentDraft.FastSignSupport, false);

		if (signBankURL) {
			if (isFastSignSupport) {
				window.location.assign(signBankURL);
			} else {
				window.open(signBankURL, '_blank');
			}
		}
	};

	filterFundsRegisters = (fr: FundsRegister) => {
		const isConnectedToSberbank =
			getFundsRegisterType(fr) === 'bank' &&
			fr.CashManagementSystem &&
			fr.CashManagementSystem.SubsystemInstanceGUID === SBERBANK_BNK_CMS;

		return isConnectedToSberbank;
	};

	renderForm = () => {
		const { tenant } = this.props;

		return (
			<FormLayout>
				<Form formObject={this.formObject} onSubmit={this.handleSubmitForm}>
					{!tenant.ContactEMail && (
						<EmailLayout>
							<MessageCard withoutPadding withoutMinHeight>
								В вашем личном кабинете не указана электронная почта.Укажите почту и на этот адрес будет отправлено
								письмо со счётом.
							</MessageCard>
							<FormInputField
								name={'EMail'}
								labelText={'E-Mail'}
								hintText={'Введите ваш E-mail'}
								errorValidations={[validators.isEmail<{ EMail: string }>(data => data.formObject.EMail)]}
								errorMsgs={['Некорректный E-Mail']}
								fullWidth
								required
							/>
						</EmailLayout>
					)}
					<MessageCard withoutPadding withoutMinHeight>
						Выберите счёт с которого будет произведена оплата
					</MessageCard>
					<FormFundsRegisterAutopicker
						name='FundsRegisterID'
						labelText='Подключённые счета'
						variant='bank-accounts'
						setDefaultValue='single'
						filter={this.filterFundsRegisters}
						disableAdd
						disableEdit
						fullWidth
						required
						{...fundsRegisterAutopickerTransformer.ID.single}
					/>
					<ButtonLayout>
						<SubmitRaisedButton type={'primary'} label={'Продолжить оплату'} fullWidth />
					</ButtonLayout>
				</Form>
			</FormLayout>
		);
	};

	renderStatusMessage = () => {
		const { status, CMSPaymentResponse } = this.props;

		if (status && status.status === statuses.FAILED) {
			const isInvoiceAlreadyExists =
				status.type === 'invalidParameter' && status.message.match(/Invoice.*\s(\d*)\s.*already.*exists/i);
			const message = isInvoiceAlreadyExists ? 'Счёт уже был отправлен на почту' : status.message;

			if (message) {
				return <AlertCard type={'alarm'}>{message}</AlertCard>;
			}
		}

		if (CMSPaymentResponse && CMSPaymentResponse.Status === statuses.FAILED) {
			const message =
				CMSPaymentResponse.AuthorizationSuccess === false
					? `Ошибка авторизации вызова при обращении к банку. ${CMSPaymentResponse.Message || ''}`
					: CMSPaymentResponse.ServiceAvailable === false
					? `Сервис на стороне банка недоступен. ${CMSPaymentResponse.Message || ''}`
					: `${CMSPaymentResponse.Message || ''}`;

			return <AlertCard type={'alarm'}>{message}</AlertCard>;
		}

		return null;
	};

	renderSignButton = () => {
		return (
			<SignLayout>
				<Alert fullWidth>
					После нажатия на кнопку «Подписать» Вы будете перенаправлены на страницу банка для подписания платежа. После
					подписания платежа, вы вернетесь обратно в сервис.
				</Alert>
				<Box marginTop={20}>
					<RaisedButton type={'primary'} label={'Подписать'} fullWidth onClick={this.handleSignPayment} />
				</Box>
			</SignLayout>
		);
	};

	renderContent = () => {
		const { frList, isFetching, CMSPaymentResponse } = this.props;
		const checkFn = (fr: FundsRegister) =>
			getFundsRegisterType(fr) === 'bank' &&
			fr.CashManagementSystem &&
			fr.CashManagementSystem.SubsystemInstanceGUID === SBERBANK_BNK_CMS;
		const isAnyConnected = frList.some(checkFn);
		const isResponseExistsAndNotFailed =
			Object.keys(CMSPaymentResponse).length > 0 && CMSPaymentResponse.Status !== statuses.FAILED;

		if (isFetching) {
			return (
				<FormLayout>
					<Loader.Layout>
						<Loader />
					</Loader.Layout>
				</FormLayout>
			);
		}

		if (isResponseExistsAndNotFailed) {
			return this.renderSignButton();
		}

		if (isAnyConnected) {
			return this.renderForm();
		}

		return (
			<FormLayout>
				<Alert fullWidth>
					У вас нет подключенных счетов. Подключите интеграцию с системой «Сбербанк Бизнес Онлайн» в разделе {'\u00A0'}
					<RouterLink to={ACCOUNTS_URL} onClick={this.handleClose}>
						Денежные средства
					</RouterLink>
				</Alert>
			</FormLayout>
		);
	};

	render() {
		const { CMSPaymentResponse } = this.props;
		const { isFormOpen } = this.state;
		const isResponseExists = Object.keys(CMSPaymentResponse).length > 0;

		if (isSberbank || isInvoiceApp) {
			return (
				<Root>
					{!isResponseExists && (
						<Shape marginBottom={isFormOpen ? 20 : 0}>
							<RaisedButton color='primary' disabled={isFormOpen} fullWidth onClick={this.handleOpenForm}>
								Сбербанк Бизнес Онлайн
							</RaisedButton>
						</Shape>
					)}
					{isFormOpen && this.renderContent()}
					{this.renderStatusMessage()}
				</Root>
			);
		}

		return null;
	}
}

export default FastBillForm;
