import React, { memo, useLayoutEffect, useState } from 'react';

import { useAutoFetch, useMapDispatch, useMapState } from '@core/flux';
import { getDrawer } from '@utils';
import { mainFundsRegistersSelectorsPack, selectAsyncFundsRegisterStatistics } from '@funds-registers/selectors';
import { mainIntegrationsActionsPack } from '@integrations/actions';
import {
	selectAsyncCashManagementSystems,
	selectAsyncExternalSystemAccounts,
	selectAsyncSubSystemInstances,
} from '@integrations/selectors';
import { XCmsConnectForm, XCmsConnectFormProps } from './cms-connect-form.view';
import { CMSDataType, DrawerZone } from '@funds-registers/models';
import { mainFundsRegistersActionsPack } from '@funds-registers/actions';

type CmsConnectFormProps = Pick<XCmsConnectFormProps, 'zone' | 'dataType'>;

const CmsConnectForm: React.FC<CmsConnectFormProps> = memo(({ zone: initZone, dataType: initDataType }) => {
	const [isFetching] = useMapState([mainFundsRegistersSelectorsPack.selectIsCmsConnectDataFetching]);
	const [fetchFundsRegisterStatistics, fetchCashManagementSystems, fetchExternalSystemAccounts, fetchIntegrationsData] =
		useMapDispatch([
			mainFundsRegistersActionsPack.fetchFundsRegisterStatistics,
			mainIntegrationsActionsPack.fetchCashManagementSystems,
			mainIntegrationsActionsPack.fetchExternalSystemAccounts,
			mainIntegrationsActionsPack.fetchIntegrationsData,
		]);
	const [zone, setZone] = useState<DrawerZone>(initZone);
	const [dataType, setDataType] = useState<CMSDataType>(initDataType);
	const [status, setStatus] = useState<AsyncProgressStatus>({ status: '', message: '' });

	useLayoutEffect(() => {
		setZone(initZone);
		setDataType(initDataType);
	}, [initZone, initDataType]);

	useAutoFetch({
		selector: selectAsyncFundsRegisterStatistics.selectDidInvalidate,
		fetch: () => fetchFundsRegisterStatistics(),
	});
	useAutoFetch({
		selector: selectAsyncCashManagementSystems.selectDidInvalidate,
		fetch: () => fetchCashManagementSystems(),
	});
	useAutoFetch({
		selector: selectAsyncExternalSystemAccounts.selectDidInvalidate,
		fetch: () => fetchExternalSystemAccounts(),
	});
	useAutoFetch({
		selector: selectAsyncSubSystemInstances.selectDidInvalidate,
		fetch: () => fetchIntegrationsData(),
	});

	return (
		<XCmsConnectForm
			isFetching={isFetching}
			dataType={dataType}
			zone={zone}
			setZone={setZone}
			status={status}
			setStatus={setStatus}
		/>
	);
});

type DrawerOptions = {
	zone: DrawerZone;
	dataType: CMSDataType;
};

const openCmsConnectFormDrawer = (options: DrawerOptions) => {
	getDrawer().open({
		overlay: true,
		content: <CmsConnectForm {...options} />,
	});
};

export { openCmsConnectFormDrawer };
