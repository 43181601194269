import { RequestAction, receiveAction, errorAction, REQUEST, RECEIVE } from '@libs/action-creator';

function createAsyncInitializerEffect() {
	return () => (next: (action: AsyncAction<unknown>) => void) => (action: RequestAction) => {
		if (!action) return;

		if (action.status === REQUEST) {
			const asyncFn = action.asyncPack.fn;
			const type = action.type;
			const addons = action.asyncPack.addons;
			const [apiBox, getState, dispatch, ...args] = action.asyncPack.args;

			asyncFn(apiBox, getState, dispatch, ...args)
				.then(response => {
					const argsWithResponse = [...args, response];
					const receiveMsg = addons.showMessage && addons.showMessage(RECEIVE, apiBox, getState, ...argsWithResponse);

					dispatch(receiveAction(type, response, receiveMsg));
				})
				.catch((error: Error) => errorAction(type, error));
		}

		next(action);
	};
}

const runAsyncInitializerEffect = createAsyncInitializerEffect();

export { runAsyncInitializerEffect };
