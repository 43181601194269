import React from 'react';

import withTopShift from '@core/hocs/with-top-shift.hoc';
import { AppKpi } from '../app-kpi';
import AppSession from '../app-session';
import { Help } from '../help';
import { BillingAlert } from '../tenant-account/billing/billing-alert';
import { ControlsLayout, KPILayout, Root, TitleLayout } from './styled';

type AppHeaderProps = {
	topShift?: number;
	sessionContext: ClientSessionContext;
	billingServiceStatus: BillingServiceStatus;
	kpi: React.ComponentType;
	renderLayout: boolean;
	withoutSession: boolean;
	title: React.ComponentType<any>;
	isFullAccess: boolean;
};

class AppHeader extends React.Component<AppHeaderProps> {
	static displayName = 'AppHeader';

	render() {
		const { topShift, kpi, renderLayout, sessionContext, withoutSession, title, billingServiceStatus, isFullAccess } =
			this.props;
		const Title = title || null;
		const needRenderIndicators = !withoutSession && renderLayout;

		return (
			<Root id='app-header' topShift={topShift}>
				<KPILayout>
					<AppKpi KPIComponent={renderLayout && kpi} renderLayout={renderLayout} sessionContext={sessionContext} />
				</KPILayout>
				<TitleLayout>
					<Title
						availableComponents={sessionContext ? sessionContext.AvailableComponents || [] : []}
						userRights={sessionContext ? sessionContext.UserRights || [] : []}
						billingServiceStatus={billingServiceStatus}
						isFullAccess={isFullAccess}
					/>
				</TitleLayout>
				<ControlsLayout>
					<Help />
					{needRenderIndicators && <BillingAlert />}
					{needRenderIndicators && <AppSession />}
				</ControlsLayout>
			</Root>
		);
	}
}

export default withTopShift<AppHeaderProps>()(AppHeader);
