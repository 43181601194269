import React from 'react';

import { Root, Bar, Slider, Shine, Text } from './styled';

export type ProgressBarProps = {
	value: number;
	text?: string;
};

const ProgressBar: React.FC<ProgressBarProps> = ({ value, text }) => {
	return (
		<Root>
			<Bar />
			<Slider value={value} />
			<Shine value={value} />
			{text && <Text>{text}</Text>}
		</Root>
	);
};

export { ProgressBar };
