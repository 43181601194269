import { createTypes } from '@flux';

type ActionTypes = {
	ADD_TENANT_LEGAL_ENTITY: string;
	FETCH_TENANT_LEGAL_ENTITIES: string;
	INVALIDATE_TENANT_LEGAL_ENTITIES: string;
	REMOVE_TENANT_LEGAL_ENTITY: string;
	RESET_FILTERS: string;
	RESET_TENANT_LEGAL_ENTITIES: string;
	RUN_TENANT_LEGAL_ENTITIES_INVALIDATION_EFFECT: string;
	SET_FILTER_BY_LEGAL_ENTITY_FORM: string;
	SET_FILTER_BY_TEXT: string;
	TRANSFORM_TENANT_LEGAL_ENTITY_TO_COUNTERPARTY: string;
	UPDATE_TENANT_LEGAL_ENTITY: string;
};

export const types = createTypes<ActionTypes>(
	[
		'ADD_TENANT_LEGAL_ENTITY',
		'FETCH_TENANT_LEGAL_ENTITIES',
		'INVALIDATE_TENANT_LEGAL_ENTITIES',
		'REMOVE_TENANT_LEGAL_ENTITY',
		'RESET_FILTERS',
		'RESET_TENANT_LEGAL_ENTITIES',
		'RUN_TENANT_LEGAL_ENTITIES_INVALIDATION_EFFECT',
		'SET_FILTER_BY_LEGAL_ENTITY_FORM',
		'SET_FILTER_BY_TEXT',
		'TRANSFORM_TENANT_LEGAL_ENTITY_TO_COUNTERPARTY',
		'UPDATE_TENANT_LEGAL_ENTITY',
	],
	'🌟TENANT_LEGAL_ENTITIES',
);
