import { createTypes } from '@flux';

type ActionTypes = {
	INVALIDATE_REPORT_DATA: string;
	FETCH_REPORT_DATA: string;
	SET_DATE_RANGE: string;
	SET_DIRECTION: string;
	SET_SPLINE_FILTER: string;
};

export const types = createTypes<ActionTypes>(
	['INVALIDATE_REPORT_DATA', 'FETCH_REPORT_DATA', 'SET_DATE_RANGE', 'SET_DIRECTION', 'SET_SPLINE_FILTER'],
	'TOP_COUNTERPARTIES_REPORT',
);
