import { createSelector } from 'reselect';

import { IAppState } from '../../../core/store';
import { IErrorMessage } from '../reducers/error-message.reducers';


const selectErrorMessageViewIsOpen = createSelector(
	(state: IAppState): boolean => state.shared.errorMessage.errorMessageViewIsOpen,
	isOpen => isOpen
);

const selectLastErrorMessage = createSelector(
	(state: IAppState): Array<IErrorMessage> => state.shared.errorMessage.errors,
	errors => errors[errors.length - 1]
);


export {
	selectErrorMessageViewIsOpen,
	selectLastErrorMessage
};

export type { IErrorMessage };
