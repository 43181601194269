import axios from 'axios';

import api from '../../../core/libs/api';
import createAction from '../../../core/libs/action-creator';
import { selectWebServicesRootPath } from '../../platform/selectors/context.selectors';

const FETCH_INVOICE_BY_ID = '[INVOICE]: FETCH_INVOICE_BY_ID';
const FETCH_ICOMING_INVOICE_BY_GUID = '[INVOICE]: FETCH_ICOMING_INVOICE_BY_GUID';

const fetchInvoiceByIDAction = createAction(FETCH_INVOICE_BY_ID, fetchInvoiceByID);
const fetchIncomingInvoiceByGUIDAction = createAction(FETCH_ICOMING_INVOICE_BY_GUID, fetchIncomingInvoiceByGUID);

function fetchInvoiceByID(api, getState, dispatch, invoiceID: number) {
	return new Promise(resolve => {
		api.financialDocumentServiceClient.getDocumentByID(invoiceID, (invoice: Invoice) => {
			if (!invoice.Outgoing) {
				dispatch(fetchIncomingInvoiceByGUIDAction(invoice.SharedDocumentUID));
			}

			if (!invoice.ExpectedPaymentDate) {
				invoice.ExpectedPaymentDate = invoice.RequiredPaymentDate;
			}

			resolve(invoice);
		});
	});
}

function fetchIncomingInvoiceByGUID(api, getState, dispatch, invoiceGUID: string) {
	return new Promise(resolve => {
		const webServicesRootPath = selectWebServicesRootPath(getState());
		const apiEndpoint = `${webServicesRootPath}/atr-document-services/api/documents/${invoiceGUID}`;

		axios({
			method: 'get',
			url: apiEndpoint,
		}).then(result => {
			const invoice: Invoice = result.data;

			resolve(invoice);
		});
	});
}

function getInvoiceByIDMethod(invoiceID: number) {
	return new Promise(resolve => {
		api.financialDocumentServiceClient.getDocumentByID(invoiceID, (invoice: Invoice) => {
			resolve(invoice);
		});
	});
}

function fetchInvoiceByUIDMethod(invoiceUID: string) {
	return new Promise(resolve => {
		api.financialDocumentServiceClient.getDocumentByUID(invoiceUID, (invoice: Invoice) => {
			resolve(invoice);
		});
	});
}

function fetchIncomingInvoiceByGUIDMethod(invoiceGUID: string) {
	return new Promise(resolve => {
		const apiEndpoint = `${location.origin}/atr-document-services/api/documents/${invoiceGUID}`;

		axios({
			method: 'get',
			url: apiEndpoint,
		}).then(result => {
			const invoice: Invoice = result.data;

			resolve(invoice);
		});
	});
}

export const methods = {
	getInvoiceByIDMethod,
	fetchInvoiceByUIDMethod,
	fetchIncomingInvoiceByGUIDMethod,
};

export { FETCH_INVOICE_BY_ID, FETCH_ICOMING_INVOICE_BY_GUID, fetchInvoiceByIDAction, fetchIncomingInvoiceByGUIDAction };
