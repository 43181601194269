import styled from 'styled-components';

import * as c from '../../../../core/ui/basic-theme/colors';


interface IRootProps {
	fontSize?: string;
	color?: string;
};

const Root = styled.span`
	position: relative;
	display: inline-flex;
	flex-flow: row nowrap;
	align-items: center;
	white-space: nowrap;
	font-size: ${({ fontSize }: IRootProps) => fontSize ? fontSize : 'inherit'};
	color: ${({ color }: IRootProps) => color ? color : 'inherit'};
`;

const Integer = styled.span`
	display: inline-block;
`;

interface IFractionProps {
	fontSize?: string;
};

const Fraction = styled.span`
	position: relative;
	display: inline-block;
	line-height: 1;
	font-size: ${({ fontSize }: IFractionProps) => fontSize
		? `${(parseInt(fontSize) / 1.3).toFixed(0)}px`
		: 'inherit'};
	color: ${c.blackSecondary};
`;

const Symbol = styled.span`
	display: inline-block;
`;

export {
	Root,
	Integer,
	Fraction,
	Symbol
};
