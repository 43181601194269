import React from 'react';

import { getDrawer } from '@utils';
import { Step, Stepper, StepperItem, StepperLabel, RenderItemOptions } from '@ui/stepper-viewer';
import { Typography } from '@ui/typography';
import BillingDisplayController from '../billing-display-controller';
import { pathTo } from '../billing-display-controller/paths';
import { Root, HeaderLayout, TitleLayout, ExtraLayout, ContentLayout, Divider } from './styled';

interface IProps {
	requestedTariff: Tariff;
	path: string;

	setPath: (path: string) => void;
	resetPath: () => void;
}

interface IState {
	activeIndex: number;
}

class BillingWizard extends React.PureComponent<IProps, IState> {
	static displayName = 'BillingWizard';

	state: IState = {
		activeIndex: 0,
	};

	componentDidMount() {
		const { path, requestedTariff } = this.props;

		getDrawer().setContent({
			title: this.renderHeader(path, requestedTariff),
			overlay: true,
		});

		this.setActiveIndexStep(path, requestedTariff);
	}

	componentDidUpdate(prevProps: IProps) {
		if (this.props.path && prevProps.path !== this.props.path) {
			this.setActiveIndexStep(this.props.path, this.props.requestedTariff);
		}
		if (prevProps.requestedTariff !== this.props.requestedTariff) {
			this.setActiveIndexStep(this.props.path, this.props.requestedTariff);
		}
	}

	setActiveIndexStep = (path: string, requestedTariff: Tariff) => {
		if (path === pathTo.selectConditions) {
			this.setState(
				{
					activeIndex: 0,
				},
				() => this.setDrawerTitle(path, requestedTariff),
			);
		}

		if (path === pathTo.selectTypePayer) {
			this.setState(
				{
					activeIndex: 1,
				},
				() => this.setDrawerTitle(path, requestedTariff),
			);
		}

		if (
			path === pathTo.personPaymentResult ||
			path === pathTo.legalEntityPaymentResult ||
			path === pathTo.legalEntityPaymentResultWithCheck
		) {
			this.setState(
				{
					activeIndex: 2,
				},
				() => this.setDrawerTitle(path, requestedTariff),
			);
		}
	};

	setDrawerTitle = (path: string, requestedTariff: Tariff) => {
		getDrawer().setContent({
			title: this.renderHeader(path, requestedTariff),
		});
	};

	renderHeader = (path: string, requestedTariff: Tariff) => {
		const needRenderStepper = path !== pathTo.selectConditions || !requestedTariff;

		return (
			<HeaderLayout>
				<TitleLayout>Оплата сервиса</TitleLayout>
				{needRenderStepper ? (
					<div>
						<Divider />
						<ExtraLayout>{this.renderStepper()}</ExtraLayout>
					</div>
				) : null}
			</HeaderLayout>
		);
	};

	renderStepperItem = (options: RenderItemOptions) => {
		const { item, idx, isBefore } = options;

		return (
			<StepperItem>
				<StepperLabel isBefore={isBefore}>{idx + 1}</StepperLabel>
				<Typography.Text fontWeight={500}>{item.text}</Typography.Text>
			</StepperItem>
		);
	};

	renderStepper = () => {
		const { activeIndex } = this.state;
		const value = stepperDataSource[activeIndex].value;

		return (
			<Stepper
				appearance='horizontal'
				value={value}
				dataSource={stepperDataSource}
				renderItem={this.renderStepperItem}
			/>
		);
	};

	render() {
		return (
			<Root>
				<ContentLayout>
					<BillingDisplayController />
				</ContentLayout>
			</Root>
		);
	}
}

const stepperDataSource: Array<Step> = [
	{ text: 'Тариф и период продления', value: 'tariff' },
	{ text: 'Тип плательщика', value: 'payerType' },
	{ text: 'Завершение', value: 'finish' },
];

export default BillingWizard;
