import styled from 'styled-components';

const Root = styled.div`
	position: relative;
	width: 100%;
	height: auto;
`;

const ContentLayout = styled.div`
	width: 100%;
	height: auto;
	display: flex;
	flex-flow: column wrap;
	justify-content: center;
	align-items: center;
`;

const ErrorSignLayout = styled.div`
	width: 100%;
	text-align: center;
	margin-bottom: 20px;
`;

const ErrorSign = styled.div`
	font-size: 16px;
	line-height: 1.25;
	text-align: left;
`;

const ControlsLayout = styled.div`
	width: 100%;
	height: auto;
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;
	align-items: center;

	@media (max-width: 1024px) {
		flex-flow: column wrap;
		justify-content: center;
	}

	@media (max-width: 640px) {
		flex-flow: row wrap;
		justify-content: space-between;
	}
`;

const dialogRootStyle = {
	zIndex: 2000,
};

export { Root, ContentLayout, ErrorSignLayout, ErrorSign, ControlsLayout, dialogRootStyle };
