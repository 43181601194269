import React from 'react';
import styled, { css } from 'styled-components';

export type ControlsGroupProps = {
	fullWidth: boolean;
	label: string;
	children: React.ReactNode;
};

const ControlsGroup: React.FC<ControlsGroupProps> = props => {
	const { fullWidth, label, children } = props;

	return (
		<Root fullWidth={fullWidth}>
			<Label>
				<Title>{label}</Title>
				<Divider />
			</Label>
			<Controls>{children}</Controls>
		</Root>
	);
};

type RootProps = {
	fullWidth: boolean;
};

const Root = styled.div<RootProps>`
	position: relative;
	margin: 30px 0;
	display: inline-block;

	${p =>
		p.fullWidth &&
		css`
			width: 100%;
		`}
`;

const Label = styled.div`
	display: flex;
	flex-direction: row;
	padding: 8px 0;
	margin-bottom: 12px;
`;

const Title = styled.div`
	white-space: nowrap;
	font-size: 14px;
	line-height: 1.07;
	font-weight: 500;
	letter-spacing: 0.1px;
	text-transform: uppercase;
	text-align: left;
`;

const Divider = styled.div`
	width: 100%;
	border-top: dashed 1px transparent;
	margin: 6px 0 6px 10px;
	background-color: transparent;

	${p => css`
		border-color: ${p.theme.palette.hint};
	`}
`;

const Controls = styled.div`
	width: 100%;
`;

export { ControlsGroup };
