import React from 'react';

import { useTheme } from '@theme';
import { Root, ContentLayout } from './styled';

export type FetchingPlaceholderProps = {
	isFetching: boolean;
	maxWidth?: number;
	display?: 'inline-block' | 'block';
	gradientColor?: {
		start: string;
		stop: string;
	};
	children: React.ReactNode;
};

const FetchingPlaceholder: React.FC<FetchingPlaceholderProps> = props => {
	const { isFetching, gradientColor: sourceGradientColor, maxWidth, display, children } = props;
	const { theme } = useTheme();
	const gradientColor = sourceGradientColor || {
		start: theme.palette.stealth,
		stop: theme.palette.space,
	};

	return (
		<Root isFetching={isFetching} gradientColor={gradientColor} display={display} maxWidth={maxWidth}>
			<ContentLayout>{children}</ContentLayout>
		</Root>
	);
};

FetchingPlaceholder.defaultProps = {
	maxWidth: 200,
};

export { FetchingPlaceholder };
