import React from 'react';

import { Root } from './styled';

export type DividerProps = {};

const Divider: React.FC<DividerProps> = () => {
	return <Root />;
};

export { Divider };
