import React from 'react';

import { RaisedButton } from '@ui/raised-button';
import { Root, ButtonLayout } from './styled';

interface IProps {
	canSubmit?: boolean;
	secondCanSubmit?: boolean;
	actionLabel?: string;
	secondActionLabel?: string;
	closeLabel?: string;
	onAction?: (ev) => void;
	onSecondAction?: (ev) => void;
	onClose?: (ev) => void;
}

const DrawerRightControls: React.FC<IProps> = props => {
	const {
		canSubmit,
		secondCanSubmit,
		actionLabel,
		secondActionLabel,
		closeLabel,
		onAction,
		onSecondAction,
		onClose,
	} = props;

	return (
		<Root>
			{onAction && actionLabel && (
				<ButtonLayout>
					<RaisedButton color='primary' disabled={!canSubmit} fullWidth onClick={onAction}>
						{actionLabel}
					</RaisedButton>
				</ButtonLayout>
			)}
			{onSecondAction && secondActionLabel && (
				<ButtonLayout>
					<RaisedButton disabled={!secondCanSubmit} fullWidth onClick={onSecondAction}>
						{secondActionLabel}
					</RaisedButton>
				</ButtonLayout>
			)}
			{onClose && (
				<ButtonLayout>
					<RaisedButton fullWidth onClick={onClose}>
						{closeLabel || 'Отмена'}
					</RaisedButton>
				</ButtonLayout>
			)}
		</Root>
	);
};

DrawerRightControls.defaultProps = {
	canSubmit: true,
	secondCanSubmit: true,
};

export default DrawerRightControls;
