/***
 * Library contains JavaScript API for interaction with Seeneco Framework Web Services contained in ReferencePackage
 * @author akotov Generated by Seeneco Framework Modeler 
 *
 */
(function(w){
  /***
  * 
  */
  w.Bank = function() {
    this.PACKAGE = "REFERENCE";
    this.CLASSIFIER = "BANK";
    this.CopySupportingElement = false;
    this.Name = "";
    this.ID = -1;
    this.Bic = "";
    this.CorrAccount = "";
    this.LogoURL = "";
  };
  /***
  * 
  */
  w.BusinessUnit = function() {
    this.PACKAGE = "REFERENCE";
    this.CLASSIFIER = "BUSINESS_UNIT";
    this.CopySupportingElement = false;
    this.ID = -1;
    this.Name = "";
    this.ProjectCount = -1;
    this.OperationCount = -1;
  };
  /***
  * 
  */
  w.CalculateCrossCurrencyRateRequest = function() {
    this.PACKAGE = "REFERENCE";
    this.CLASSIFIER = "CALCULATE_CROSS_CURRENCY_RATE_REQUEST";
    this.CopySupportingElement = false;
    this.SourceCurrencyID = -1;
    this.TargetCurrencyID = -1;
    this.CurrencyRatePeriodTypeID = -1;
    this.DateTo = null;
    this.DateFrom = null;
    this.CrossCurrencyID = -1;
    this.OperationUID = -1;
  };
  /***
  * 
  */
  w.Country = function() {
    this.PACKAGE = "REFERENCE";
    this.CLASSIFIER = "COUNTRY";
    this.CopySupportingElement = false;
    this.TextBlocks = [];
    this.Capital = "";
    this.Area = "";
    this.Population = "";
    this.Language = "";
    this.NationalCurrencyID = -1;
    this.CountryAmounts = [];
    this.FullName = "";
    this.DigitCode = "";
    this.LetterCode = "";
    this.ID = -1;
    this.Name = "";
    this.GUID = "";
  };
  /***
  * 
  */
  w.CountryAmount = function() {
    this.PACKAGE = "REFERENCE";
    this.CLASSIFIER = "COUNTRY_AMOUNT";
    this.CopySupportingElement = false;
    this.Amount = -1;
    this.CurrencyID = -1;
    this.CountryAttribute = null;
  };
  /***
  * 
  */
  w.CountryAttribute = function() {
    this.PACKAGE = "REFERENCE";
    this.CLASSIFIER = "COUNTRY_ATTRIBUTE";
    this.CopySupportingElement = false;
    this.Name = "";
    this.ID = -1;
    this.Code = "";
    this.DataType = "";
    this.AmountFlag = false;
  };
  /***
  * 
  */
  w.CountryAttributeValue = function() {
    this.PACKAGE = "REFERENCE";
    this.CLASSIFIER = "COUNTRY_ATTRIBUTE_VALUE";
    this.CopySupportingElement = false;
    this.CountryAttribute = null;
  };
  /***
  * 
  */
  w.CountryBrief = function() {
    this.PACKAGE = "REFERENCE";
    this.CLASSIFIER = "COUNTRY_BRIEF";
    this.CopySupportingElement = false;
    this.FullName = "";
    this.DigitCode = "";
    this.LetterCode = "";
    this.ID = -1;
    this.Name = "";
    this.GUID = "";
  };
  /***
  * 
  */
  w.Currency = function() {
    this.PACKAGE = "REFERENCE";
    this.CLASSIFIER = "CURRENCY";
    this.CopySupportingElement = false;
    this.DigitCode = "";
    this.LetterCode = "";
    this.ID = -1;
    this.Name = "";
    this.GUID = "";
  };
  /***
  * 
  */
  w.CurrencyRate = function() {
    this.PACKAGE = "REFERENCE";
    this.CLASSIFIER = "CURRENCY_RATE";
    this.CopySupportingElement = false;
    this.RateDate = null;
    this.SourceCurrencyID = -1;
    this.SourceCurrencyUnits = -1;
    this.TargetCurrencyID = -1;
    this.TargetCurrencyUnits = -1;
    this.ID = -1;
    this.CurrencyRatePeriodTypeID = -1;
  };
  /***
  * 
  */
  w.CurrencyRateImportRequest = function() {
    this.PACKAGE = "REFERENCE";
    this.CLASSIFIER = "CURRENCY_RATE_IMPORT_REQUEST";
    this.CopySupportingElement = false;
    this.Rates = [];
    this.OperationUID = -1;
  };
  /***
  * 
  */
  w.ExternalCurrencyRateImportRequest = function() {
    this.PACKAGE = "REFERENCE";
    this.CLASSIFIER = "EXTERNAL_CURRENCY_RATE_IMPORT_REQUEST";
    this.CopySupportingElement = false;
    this.CurrencyLetterCode = "";
    this.RateDateStart = null;
    this.RateDateFinish = null;
    this.OperationUID = -1;
  };
  /***
  * 
  */
  w.GeoRegion = function() {
    this.PACKAGE = "REFERENCE";
    this.CLASSIFIER = "GEO_REGION";
    this.CopySupportingElement = false;
    this.ID = -1;
    this.Name = "";
  };
  /***
  * 
  */
  w.Industry = function() {
    this.PACKAGE = "REFERENCE";
    this.CLASSIFIER = "INDUSTRY";
    this.CopySupportingElement = false;
    this.Name = "";
    this.ID = -1;
    this.FullName = "";
    this.DigitCode = "";
    this.LetterCode = "";
    this.TextBlocks = [];
    this.ParentID = -1;
    this.IndustryTypeID = -1;
  };
  /***
  * 
  */
  w.MeasureUnit = function() {
    this.PACKAGE = "REFERENCE";
    this.CLASSIFIER = "MEASURE_UNIT";
    this.CopySupportingElement = false;
    this.Brief = "";
    this.IsFavorite = false;
    this.Code = "";
    this.ID = -1;
    this.Name = "";
    this.GUID = "";
  };
  /***
  * 
  */
  w.Month = function() {
    this.PACKAGE = "REFERENCE";
    this.CLASSIFIER = "MONTH";
    this.CopySupportingElement = false;
    this.PeriodDateStart = null;
    this.PeriodDateEnd = null;
    this.OrderNumAbs = -1;
    this.OrderNumYear = -1;
    this.ID = -1;
    this.Name = "";
    this.GUID = "";
  };
  /***
  * 
  */
  w.Product = function() {
    this.PACKAGE = "REFERENCE";
    this.CLASSIFIER = "PRODUCT";
    this.CopySupportingElement = false;
    this.ID = -1;
    this.Name = "";
    this.MeasureUnit = null;
    this.ProductType = null;
    this.ItemPrice = -1;
    this.ItemTaxRate = -1;
    this.Group = false;
    this.ParentID = -1;
    this.ChildItems = [];
    this.Comment = "";
    this.Code = "";
  };
  /***
  * 
  */
  w.ProductFilter = function() {
    this.PACKAGE = "REFERENCE";
    this.CLASSIFIER = "PRODUCT_FILTER";
    this.CopySupportingElement = false;
    this.Limit = -1;
    this.SearchText = "";
    this.SortBy = "";
  };
  /***
  * 
  */
  w.ProductType = function() {
    this.PACKAGE = "REFERENCE";
    this.CLASSIFIER = "PRODUCT_TYPE";
    this.CopySupportingElement = false;
    this.ID = -1;
    this.Name = "";
    this.Service = false;
  };
  /***
  * 
  */
  w.StateIndustryClassifier = function() {
    this.PACKAGE = "REFERENCE";
    this.CLASSIFIER = "STATE_INDUSTRY_CLASSIFIER";
    this.CopySupportingElement = false;
    this.Code = "";
    this.Name = "";
  };
  /***
  * 
  */
  w.TextBlock = function() {
    this.PACKAGE = "REFERENCE";
    this.CLASSIFIER = "TEXT_BLOCK";
    this.CopySupportingElement = false;
    this.Content = "";
    this.ContentType = "";
  };
  /***
  * 
  */
  w.TypedField = function() {
    this.PACKAGE = "REFERENCE";
    this.CLASSIFIER = "TYPED_FIELD";
    this.CopySupportingElement = false;
    this.Code = "";
    this.ID = -1;
    this.Name = "";
    this.GUID = "";
  };
  /***
  * 
  */
  w.TypedFieldOption = function() {
    this.PACKAGE = "REFERENCE";
    this.CLASSIFIER = "TYPED_FIELD_OPTION";
    this.CopySupportingElement = false;
    this.Content = "";
    this.NumOrder = -1;
    this.Code = "";
    this.ID = -1;
    this.Name = "";
    this.GUID = "";
  };
  /***
  * 
  */
  w.Region = function() {
    this.PACKAGE = "REFERENCE";
    this.CLASSIFIER = "REGION";
    this.CopySupportingElement = false;
    this.Code = "";
    this.ID = -1;
    this.Name = "";
    this.GUID = "";
  };
   
   
  
  /***
   * Client API to BankServiceWS web service
   */
  w.BankServiceClient = function() {
    /***
     * System name of target service
     */
    this.serviceName = "BankServiceWSImpl";
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param queryString [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.BankServiceClient.prototype.getBanksByQueryString = function(queryString, callbackHandler) {
    var parameterNames = ["queryString"];
    var parameterValues = [queryString];
    var soapMessage = buildSOAPMessage("getBanksByQueryString", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = true;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * Client API to BusinessUnitServiceWS web service
   */
  w.BusinessUnitServiceClient = function() {
    /***
     * System name of target service
     */
    this.serviceName = "BusinessUnitServiceWSImpl";
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param businessUnit [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.BusinessUnitServiceClient.prototype.addBusinessUnit = function(businessUnit, callbackHandler) {
    var parameterNames = ["businessUnit"];
    var businessUnitJSON = JSON.stringify(businessUnit);
    var parameterValues = [businessUnitJSON];
    var soapMessage = buildSOAPMessage("addBusinessUnit", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param businessUnit [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.BusinessUnitServiceClient.prototype.changeBusinessUnit = function(businessUnit, callbackHandler) {
    var parameterNames = ["businessUnit"];
    var businessUnitJSON = JSON.stringify(businessUnit);
    var parameterValues = [businessUnitJSON];
    var soapMessage = buildSOAPMessage("changeBusinessUnit", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param tenantID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.BusinessUnitServiceClient.prototype.changeTenant = function(tenantID, callbackHandler) {
    var parameterNames = ["tenantID"];
    var parameterValues = [tenantID];
    var soapMessage = buildSOAPMessage("changeTenant", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.BusinessUnitServiceClient.prototype.getBusinessUnits = function(callbackHandler) {
    var parameterNames = [];
    var parameterValues = [];
    var soapMessage = buildSOAPMessage("getBusinessUnits", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param businessUnitID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.BusinessUnitServiceClient.prototype.removeBusinessUnit = function(businessUnitID, callbackHandler) {
    var parameterNames = ["businessUnitID"];
    var parameterValues = [businessUnitID];
    var soapMessage = buildSOAPMessage("removeBusinessUnit", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param businessUnitID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.BusinessUnitServiceClient.prototype.getBusinessUnitByID = function(businessUnitID, callbackHandler) {
    var parameterNames = ["businessUnitID"];
    var parameterValues = [businessUnitID];
    var soapMessage = buildSOAPMessage("getBusinessUnitByID", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * Client API to CalendarServiceWS web service
   */
  w.CalendarServiceClient = function() {
    /***
     * System name of target service
     */
    this.serviceName = "CalendarServiceWSImpl";
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param dateValue [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CalendarServiceClient.prototype.getMonthByDate = function(dateValue, callbackHandler) {
    var parameterNames = ["dateValue"];
    var parameterValues = [dateValue];
    var soapMessage = buildSOAPMessage("getMonthByDate", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param startMonthID [DEFAULT!!DEFAULT]
   * @param finishMonthID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CalendarServiceClient.prototype.getMonthesInPeriod = function(startMonthID, finishMonthID, callbackHandler) {
    var parameterNames = ["startMonthID", "finishMonthID"];
    var parameterValues = [startMonthID, finishMonthID];
    var soapMessage = buildSOAPMessage("getMonthesInPeriod", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param idList [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CalendarServiceClient.prototype.getMonthesByIDList = function(idList, callbackHandler) {
    var parameterNames = ["idList"];
    var idListJSON = JSON.stringify(idList);
    var parameterValues = [idListJSON];
    var soapMessage = buildSOAPMessage("getMonthesByIDList", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * Client API to CountryServiceWS web service
   */
  w.CountryServiceClient = function() {
    /***
     * System name of target service
     */
    this.serviceName = "CountryServiceWSImpl";
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param country [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CountryServiceClient.prototype.addCountry = function(country, callbackHandler) {
    var parameterNames = ["country"];
    var countryJSON = JSON.stringify(country);
    var parameterValues = [countryJSON];
    var soapMessage = buildSOAPMessage("addCountry", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param country [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CountryServiceClient.prototype.updateCountry = function(country, callbackHandler) {
    var parameterNames = ["country"];
    var countryJSON = JSON.stringify(country);
    var parameterValues = [countryJSON];
    var soapMessage = buildSOAPMessage("updateCountry", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param countryID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CountryServiceClient.prototype.removeCountry = function(countryID, callbackHandler) {
    var parameterNames = ["countryID"];
    var parameterValues = [countryID];
    var soapMessage = buildSOAPMessage("removeCountry", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param countryID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CountryServiceClient.prototype.getCountryByID = function(countryID, callbackHandler) {
    var parameterNames = ["countryID"];
    var parameterValues = [countryID];
    var soapMessage = buildSOAPMessage("getCountryByID", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = true;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CountryServiceClient.prototype.getCountryAttributes = function(callbackHandler) {
    var parameterNames = [];
    var parameterValues = [];
    var soapMessage = buildSOAPMessage("getCountryAttributes", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = true;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param countryCode [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CountryServiceClient.prototype.getCountryByCode = function(countryCode, callbackHandler) {
    var parameterNames = ["countryCode"];
    var parameterValues = [countryCode];
    var soapMessage = buildSOAPMessage("getCountryByCode", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param countryIDs [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CountryServiceClient.prototype.getCountryBriefs = function(countryIDs, callbackHandler) {
    var parameterNames = ["countryIDs"];
    var countryIDsJSON = JSON.stringify(countryIDs);
    var parameterValues = [countryIDsJSON];
    var soapMessage = buildSOAPMessage("getCountryBriefs", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * Client API to CurrencyServiceWS web service
   */
  w.CurrencyServiceClient = function() {
    /***
     * System name of target service
     */
    this.serviceName = "CurrencyServiceWSImpl";
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param rateDate [DEFAULT!!DEFAULT]
   * @param targetCurrencyID [DEFAULT!!DEFAULT]
   * @param sourceCurrencyID [DEFAULT!!DEFAULT]
   * @param periodTypeID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CurrencyServiceClient.prototype.getCurrencyRate = function(rateDate, targetCurrencyID, sourceCurrencyID, periodTypeID, callbackHandler) {
    var parameterNames = ["rateDate", "targetCurrencyID", "sourceCurrencyID", "periodTypeID"];
    var parameterValues = [rateDate, targetCurrencyID, sourceCurrencyID, periodTypeID];
    var soapMessage = buildSOAPMessage("getCurrencyRate", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = true;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param currencyRate [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CurrencyServiceClient.prototype.addCurrencyRate = function(currencyRate, callbackHandler) {
    var parameterNames = ["currencyRate"];
    var currencyRateJSON = JSON.stringify(currencyRate);
    var parameterValues = [currencyRateJSON];
    var soapMessage = buildSOAPMessage("addCurrencyRate", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param currencyRateID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CurrencyServiceClient.prototype.getCurrencyRateByID = function(currencyRateID, callbackHandler) {
    var parameterNames = ["currencyRateID"];
    var parameterValues = [currencyRateID];
    var soapMessage = buildSOAPMessage("getCurrencyRateByID", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = true;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param currencyRate [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CurrencyServiceClient.prototype.updateCurrencyRate = function(currencyRate, callbackHandler) {
    var parameterNames = ["currencyRate"];
    var currencyRateJSON = JSON.stringify(currencyRate);
    var parameterValues = [currencyRateJSON];
    var soapMessage = buildSOAPMessage("updateCurrencyRate", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param rateID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CurrencyServiceClient.prototype.removeCurrencyRate = function(rateID, callbackHandler) {
    var parameterNames = ["rateID"];
    var parameterValues = [rateID];
    var soapMessage = buildSOAPMessage("removeCurrencyRate", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param sourceCurrencyID [DEFAULT!!DEFAULT]
   * @param targetCurrencyID [DEFAULT!!DEFAULT]
   * @param dateStart [DEFAULT!!DEFAULT]
   * @param dateFinish [DEFAULT!!DEFAULT]
   * @param periodTypeID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CurrencyServiceClient.prototype.getCurrencyRatesForPeriod = function(sourceCurrencyID, targetCurrencyID, dateStart, dateFinish, periodTypeID, callbackHandler) {
    var parameterNames = ["sourceCurrencyID", "targetCurrencyID", "dateStart", "dateFinish", "periodTypeID"];
    var parameterValues = [sourceCurrencyID, targetCurrencyID, dateStart, dateFinish, periodTypeID];
    var soapMessage = buildSOAPMessage("getCurrencyRatesForPeriod", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = true;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param sourceCurrencyID [DEFAULT!!DEFAULT]
   * @param targetCurrencyID [DEFAULT!!DEFAULT]
   * @param dateFrom [DEFAULT!!DEFAULT]
   * @param dateTo [DEFAULT!!DEFAULT]
   * @param periodTypeID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CurrencyServiceClient.prototype.getCurrencyRatesWithoutGaps = function(sourceCurrencyID, targetCurrencyID, dateFrom, dateTo, periodTypeID, callbackHandler) {
    var parameterNames = ["sourceCurrencyID", "targetCurrencyID", "dateFrom", "dateTo", "periodTypeID"];
    var parameterValues = [sourceCurrencyID, targetCurrencyID, dateFrom, dateTo, periodTypeID];
    var soapMessage = buildSOAPMessage("getCurrencyRatesWithoutGaps", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param currencyID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CurrencyServiceClient.prototype.getCurrencyByID = function(currencyID, callbackHandler) {
    var parameterNames = ["currencyID"];
    var parameterValues = [currencyID];
    var soapMessage = buildSOAPMessage("getCurrencyByID", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = true;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param currencyLetterCode [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CurrencyServiceClient.prototype.getCurrencyByLetterCode = function(currencyLetterCode, callbackHandler) {
    var parameterNames = ["currencyLetterCode"];
    var parameterValues = [currencyLetterCode];
    var soapMessage = buildSOAPMessage("getCurrencyByLetterCode", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = true;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param rateDate [DEFAULT!!DEFAULT]
   * @param targetCurrencyID [DEFAULT!!DEFAULT]
   * @param sourceCurrencyID [DEFAULT!!DEFAULT]
   * @param periodTypeID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CurrencyServiceClient.prototype.getLastCurrencyRateForDate = function(rateDate, targetCurrencyID, sourceCurrencyID, periodTypeID, callbackHandler) {
    var parameterNames = ["rateDate", "targetCurrencyID", "sourceCurrencyID", "periodTypeID"];
    var parameterValues = [rateDate, targetCurrencyID, sourceCurrencyID, periodTypeID];
    var soapMessage = buildSOAPMessage("getLastCurrencyRateForDate", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = true;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param currencyDigitCode [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CurrencyServiceClient.prototype.getCurrencyByDigitCode = function(currencyDigitCode, callbackHandler) {
    var parameterNames = ["currencyDigitCode"];
    var parameterValues = [currencyDigitCode];
    var soapMessage = buildSOAPMessage("getCurrencyByDigitCode", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param currencyLetterCode [DEFAULT!!DEFAULT]
   * @param rateDate [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CurrencyServiceClient.prototype.importExternalCurrencyRate = function(currencyLetterCode, rateDate, callbackHandler) {
    var parameterNames = ["currencyLetterCode", "rateDate"];
    var parameterValues = [currencyLetterCode, rateDate];
    var soapMessage = buildSOAPMessage("importExternalCurrencyRate", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param currencyLetterCode [DEFAULT!!DEFAULT]
   * @param rateDateStart [DEFAULT!!DEFAULT]
   * @param rateDateFinish [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CurrencyServiceClient.prototype.importExternalCurencyRatesForPeriod = function(currencyLetterCode, rateDateStart, rateDateFinish, callbackHandler) {
    var parameterNames = ["currencyLetterCode", "rateDateStart", "rateDateFinish"];
    var parameterValues = [currencyLetterCode, rateDateStart, rateDateFinish];
    var soapMessage = buildSOAPMessage("importExternalCurencyRatesForPeriod", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CurrencyServiceClient.prototype.resfreshExternalCurrencyRates = function(callbackHandler) {
    var parameterNames = [];
    var parameterValues = [];
    var soapMessage = buildSOAPMessage("resfreshExternalCurrencyRates", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param operationUID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CurrencyServiceClient.prototype.getExternalCurrencyRateImportStatus = function(operationUID, callbackHandler) {
    var parameterNames = ["operationUID"];
    var parameterValues = [operationUID];
    var soapMessage = buildSOAPMessage("getExternalCurrencyRateImportStatus", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param rates [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CurrencyServiceClient.prototype.importRates = function(rates, callbackHandler) {
    var parameterNames = ["rates"];
    var ratesJSON = JSON.stringify(rates);
    var parameterValues = [ratesJSON];
    var soapMessage = buildSOAPMessage("importRates", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param operationUID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CurrencyServiceClient.prototype.getImportRateStatus = function(operationUID, callbackHandler) {
    var parameterNames = ["operationUID"];
    var parameterValues = [operationUID];
    var soapMessage = buildSOAPMessage("getImportRateStatus", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CurrencyServiceClient.prototype.getCurrencies = function(callbackHandler) {
    var parameterNames = [];
    var parameterValues = [];
    var soapMessage = buildSOAPMessage("getCurrencies", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param sourceCurrencyID [DEFAULT!!DEFAULT]
   * @param targetCurrencyID [DEFAULT!!DEFAULT]
   * @param periodTypeID [DEFAULT!!DEFAULT]
   * @param crossCurrencyID [DEFAULT!!DEFAULT]
   * @param dateFrom [DEFAULT!!DEFAULT]
   * @param dateTo [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CurrencyServiceClient.prototype.calculateCrossCurrencyRate = function(sourceCurrencyID, targetCurrencyID, periodTypeID, crossCurrencyID, dateFrom, dateTo, callbackHandler) {
    var parameterNames = ["sourceCurrencyID", "targetCurrencyID", "periodTypeID", "crossCurrencyID", "dateFrom", "dateTo"];
    var parameterValues = [sourceCurrencyID, targetCurrencyID, periodTypeID, crossCurrencyID, dateFrom, dateTo];
    var soapMessage = buildSOAPMessage("calculateCrossCurrencyRate", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param operationUID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CurrencyServiceClient.prototype.getCrossCurrencyRateCalculationStatus = function(operationUID, callbackHandler) {
    var parameterNames = ["operationUID"];
    var parameterValues = [operationUID];
    var soapMessage = buildSOAPMessage("getCrossCurrencyRateCalculationStatus", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * Client API to IndustryServiceWS web service
   */
  w.IndustryServiceClient = function() {
    /***
     * System name of target service
     */
    this.serviceName = "IndustryServiceWSImpl";
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param industry [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.IndustryServiceClient.prototype.addIndustry = function(industry, callbackHandler) {
    var parameterNames = ["industry"];
    var industryJSON = JSON.stringify(industry);
    var parameterValues = [industryJSON];
    var soapMessage = buildSOAPMessage("addIndustry", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param industry [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.IndustryServiceClient.prototype.updateIndustry = function(industry, callbackHandler) {
    var parameterNames = ["industry"];
    var industryJSON = JSON.stringify(industry);
    var parameterValues = [industryJSON];
    var soapMessage = buildSOAPMessage("updateIndustry", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param industryID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.IndustryServiceClient.prototype.getIndustryByID = function(industryID, callbackHandler) {
    var parameterNames = ["industryID"];
    var parameterValues = [industryID];
    var soapMessage = buildSOAPMessage("getIndustryByID", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = true;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param industryID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.IndustryServiceClient.prototype.removeIndustry = function(industryID, callbackHandler) {
    var parameterNames = ["industryID"];
    var parameterValues = [industryID];
    var soapMessage = buildSOAPMessage("removeIndustry", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param industryTypeID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.IndustryServiceClient.prototype.getIndustries = function(industryTypeID, callbackHandler) {
    var parameterNames = ["industryTypeID"];
    var parameterValues = [industryTypeID];
    var soapMessage = buildSOAPMessage("getIndustries", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * Client API to MeasureUnitServiceWS web service
   */
  w.MeasureUnitServiceClient = function() {
    /***
     * System name of target service
     */
    this.serviceName = "MeasureUnitServiceWSImpl";
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.MeasureUnitServiceClient.prototype.getMeasureUnits = function(callbackHandler) {
    var parameterNames = [];
    var parameterValues = [];
    var soapMessage = buildSOAPMessage("getMeasureUnits", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param measureUnitID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.MeasureUnitServiceClient.prototype.addToFavorites = function(measureUnitID, callbackHandler) {
    var parameterNames = ["measureUnitID"];
    var parameterValues = [measureUnitID];
    var soapMessage = buildSOAPMessage("addToFavorites", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param measureUnitID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.MeasureUnitServiceClient.prototype.removeFromFavorites = function(measureUnitID, callbackHandler) {
    var parameterNames = ["measureUnitID"];
    var parameterValues = [measureUnitID];
    var soapMessage = buildSOAPMessage("removeFromFavorites", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * Client API to ProductServiceWS web service
   */
  w.ProductServiceClient = function() {
    /***
     * System name of target service
     */
    this.serviceName = "ProductServiceWSImpl";
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.ProductServiceClient.prototype.getProductTypes = function(callbackHandler) {
    var parameterNames = [];
    var parameterValues = [];
    var soapMessage = buildSOAPMessage("getProductTypes", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param productFilter [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.ProductServiceClient.prototype.getProducts = function(productFilter, callbackHandler) {
    var parameterNames = ["productFilter"];
    var productFilterJSON = JSON.stringify(productFilter);
    var parameterValues = [productFilterJSON];
    var soapMessage = buildSOAPMessage("getProducts", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param productID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.ProductServiceClient.prototype.getProductByID = function(productID, callbackHandler) {
    var parameterNames = ["productID"];
    var parameterValues = [productID];
    var soapMessage = buildSOAPMessage("getProductByID", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param product [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.ProductServiceClient.prototype.updateProduct = function(product, callbackHandler) {
    var parameterNames = ["product"];
    var productJSON = JSON.stringify(product);
    var parameterValues = [productJSON];
    var soapMessage = buildSOAPMessage("updateProduct", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param productID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.ProductServiceClient.prototype.removeProduct = function(productID, callbackHandler) {
    var parameterNames = ["productID"];
    var parameterValues = [productID];
    var soapMessage = buildSOAPMessage("removeProduct", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param product [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.ProductServiceClient.prototype.addProduct = function(product, callbackHandler) {
    var parameterNames = ["product"];
    var productJSON = JSON.stringify(product);
    var parameterValues = [productJSON];
    var soapMessage = buildSOAPMessage("addProduct", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * Client API to ReferenceServiceWS web service
   */
  w.ReferenceServiceClient = function() {
    /***
     * System name of target service
     */
    this.serviceName = "ReferenceServiceWSImpl";
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param typedFieldID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.ReferenceServiceClient.prototype.getTypedFieldOptionList = function(typedFieldID, callbackHandler) {
    var parameterNames = ["typedFieldID"];
    var parameterValues = [typedFieldID];
    var soapMessage = buildSOAPMessage("getTypedFieldOptionList", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param option [DEFAULT!!DEFAULT]
   * @param typedFieldID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.ReferenceServiceClient.prototype.addTypedFieldOption = function(option, typedFieldID, callbackHandler) {
    var parameterNames = ["option", "typedFieldID"];
    var optionJSON = JSON.stringify(option);
    var parameterValues = [optionJSON, typedFieldID];
    var soapMessage = buildSOAPMessage("addTypedFieldOption", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param option [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.ReferenceServiceClient.prototype.updateTypedFieldOption = function(option, callbackHandler) {
    var parameterNames = ["option"];
    var optionJSON = JSON.stringify(option);
    var parameterValues = [optionJSON];
    var soapMessage = buildSOAPMessage("updateTypedFieldOption", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!Mark option as deleted!DEFAULT]
   * @param option [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.ReferenceServiceClient.prototype.removeTypedFieldOption = function(option, callbackHandler) {
    var parameterNames = ["option"];
    var optionJSON = JSON.stringify(option);
    var parameterValues = [optionJSON];
    var soapMessage = buildSOAPMessage("removeTypedFieldOption", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param id [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.ReferenceServiceClient.prototype.getTypedFieldByID = function(id, callbackHandler) {
    var parameterNames = ["id"];
    var parameterValues = [id];
    var soapMessage = buildSOAPMessage("getTypedFieldByID", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param code [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.ReferenceServiceClient.prototype.getTypedFieldByCode = function(code, callbackHandler) {
    var parameterNames = ["code"];
    var parameterValues = [code];
    var soapMessage = buildSOAPMessage("getTypedFieldByCode", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param id [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.ReferenceServiceClient.prototype.getTypedFieldOptionByID = function(id, callbackHandler) {
    var parameterNames = ["id"];
    var parameterValues = [id];
    var soapMessage = buildSOAPMessage("getTypedFieldOptionByID", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * Client API to RegionServiceWS web service
   */
  w.RegionServiceClient = function() {
    /***
     * System name of target service
     */
    this.serviceName = "RegionServiceWSImpl";
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.RegionServiceClient.prototype.getRegions = function(callbackHandler) {
    var parameterNames = [];
    var parameterValues = [];
    var soapMessage = buildSOAPMessage("getRegions", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  
  /***
   * Package security rights constants
   */
  
  w.ReferenceSecurityRights = {
  
      BUSINESS_UNIT_SUPERVISION_RIGHT:new SecurityRightCheckHandle('7c60e36b-5eaa-44c8-a778-6fc31a25156f'),
      COUNTRY_REFERENCE_DATA_INPUT_RIGHT:new SecurityRightCheckHandle('3e94ef71-3738-41a5-a8ce-0a2ae4ce2ae5'),
      CURRENCY_RATE_INPUT_RIGHT:new SecurityRightCheckHandle('3012dd25-065c-4525-a8cf-a490e3c87fab'),
      INDUSTRY_REFERENCE_DATA_INPUT_RIGHT:new SecurityRightCheckHandle('1ca9424d-6e44-42d5-82a4-65edcf62dd4f'),
      X_BUSINESS_UNITS__CHANGE_BUSINESS_UNITS:new SecurityRightCheckHandle('6e3b1700-7b85-4414-807d-de15623b27f9'),
      X_BUSINESS_UNITS__VIEW_BUSINESS_UNITS:new SecurityRightCheckHandle('95746ffe-f7a5-4a65-9c6a-aede02c3cdba')
  };
  })(typeof exports !== 'undefined' ? exports : window);
  