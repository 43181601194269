function base64toBlob(base64Data, contentType, sliceSize?) {
	contentType = contentType || '';
	sliceSize = sliceSize || 512;

	const byteCharacters = atob(base64Data);
	const byteArrays = [];

	for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		const slice = byteCharacters.slice(offset, Number(offset) + Number(sliceSize));
		const byteNumbers = new Array(slice.length);

		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}

		const byteArray = new Uint8Array(byteNumbers);

		byteArrays.push(byteArray);
	}

	return new Blob(byteArrays, { type: contentType });
}

function stringToBase64(str: string): string {
	return window.btoa(str);
}

function base64ToString(str: string): string {
	return window.atob(str);
}

function encryptVernam(str = '', sourceKey = ''): string {
	return stringToBase64(stringFromBytes(applyXOR(encodeURIComponent(str), sourceKey)));
}

function decryptVernam(str = '', sourceKey = ''): string {
	return decodeURIComponent(stringFromBytes(applyXOR(base64ToString(str), sourceKey)));
}

function applyXOR(str = '', sourceKey = ''): Array<number> {
	const key = prepareKey(str, sourceKey);
	const sourceBytes = bytesFromString(str);
	const keyBytes = bytesFromString(key);
	const resultBytes = [];

	for (let i = 0; i < keyBytes.length; i++) {
		resultBytes[i] = sourceBytes[i] ^ keyBytes[i];
	}

	return resultBytes;
}

function prepareKey(str: string, sourceKey: string): string {
	let key = sourceKey;

	while (key.length < str.length) {
		key += sourceKey;
	}

	if (key.length > str.length) {
		key = key.substring(0, str.length);
	}

	return key;
}

function bytesFromString(str: string): Array<number> {
	const bytes = [];

	for (const symbol of str) {
		bytes.push(symbol.charCodeAt(0));
	}

	return bytes;
}

function stringFromBytes(bytes: Array<number>): string {
	let str = '';

	for (const byte of bytes) {
		str += String.fromCharCode(byte);
	}

	return str;
}

export { base64toBlob, stringToBase64, base64ToString, encryptVernam, decryptVernam };
