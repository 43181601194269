/***
 * Library contains JavaScript API for interaction with Seeneco Framework Web Services contained in FundsPackage
 * @author akotov Generated by Seeneco Framework Modeler 
 *
 */
(function(w){
  /***
  * 
  */
  w.AccountStatement = function() {
    this.PACKAGE = "FUNDS";
    this.CLASSIFIER = "ACCOUNT_STATEMENT";
    this.CopySupportingElement = false;
    this.AccountNumber = "";
    this.DateStart = null;
    this.DateFinish = null;
    this.BalanceStart = -1;
    this.BalanceFinish = -1;
    this.Items = [];
    this.AccountType = "";
    this.AccountCurrencyLetterCode = "";
    this.ID = -1;
    this.FundsRegisterID = -1;
    this.AccountSourceUID = "";
    this.SourceSystemID = -1;
    this.LegalEntityUID = "";
    this.LegalEntityName = "";
    this.LegalEntityTaxCode = "";
    this.AccountName = "";
    this.BIC = "";
    this.SourceTypeID = -1;
  };
  /***
  * 
  */
  w.AccountStatementFileReference = function() {
    this.PACKAGE = "FUNDS";
    this.CLASSIFIER = "ACCOUNT_STATEMENT_FILE_REFERENCE";
    this.CopySupportingElement = false;
    this.AccountStatementID = -1;
    this.AccountStatementSubReferences = [];
    this.IgnoreCheck = false;
    this.FileTemplateGUID = "";
    this.UploadedFileName = "";
    this.UploadDateTime = null;
  };
  /***
  * 
  */
  w.AccountStatementImport = function() {
    this.PACKAGE = "FUNDS";
    this.CLASSIFIER = "ACCOUNT_STATEMENT_IMPORT";
    this.CopySupportingElement = false;
    this.StatementValid = false;
    this.AddedItemsCount = -1;
    this.AccountCreated = false;
    this.ImportStatusCode = "";
    this.AccountStatementID = -1;
    this.Message = "";
    this.ChangedItemsCount = -1;
    this.RemovedItemsCount = -1;
    this.ImportStartDate = null;
    this.ImportFinishDate = null;
    this.StatementPeriodStart = null;
    this.StatementPeriodFinish = null;
    this.FundsRegisterID = -1;
    this.SourceTypeID = -1;
  };
  /***
  * 
  */
  w.AccountStatementItem = function() {
    this.PACKAGE = "FUNDS";
    this.CLASSIFIER = "ACCOUNT_STATEMENT_ITEM";
    this.CopySupportingElement = false;
    this.ItemNumber = "";
    this.ItemDate = null;
    this.Amount = -1;
    this.PayerName = "";
    this.PayerTaxCode = "";
    this.PayerAccountNumber = "";
    this.RecipientName = "";
    this.RecipientTaxCode = "";
    this.RecipientAccountNumber = "";
    this.Reference = "";
    this.ItemUID = "";
    this.Charge = false;
    this.PayerID = -1;
    this.RecipientID = -1;
    this.AccountsChartItemID = -1;
    this.PayerBankBIC = "";
    this.RecipientBankBIC = "";
    this.OptionalPaymentUID = "";
    this.BankUID = "";
    this.BudgetClsCode = "";
    this.BudgetTerritoryCode = "";
    this.TaxPayerStatus = "";
    this.BudgetPaymentUID = "";
    this.BudgetPaymentReason = "";
    this.BudgetPaymentPeriod = "";
    this.BudgetDocumentNumber = "";
    this.BudgetPaymentDate = null;
    this.BudgetPaymentType = "";
    this.BankDocumentNumber = "";
    this.DocumentDate = null;
    this.BankOperationType = "";
    this.ProjectID = -1;
    this.BusinessUnitID = -1;
  };
  /***
  * 
  */
  w.APIAccountStatement = function() {
    this.PACKAGE = "FUNDS";
    this.CLASSIFIER = "APIACCOUNT_STATEMENT";
    this.CopySupportingElement = false;
    this.PeriodStart = null;
    this.PeriodFinish = null;
    this.BalanceStart = -1;
    this.BalanceFinish = -1;
    this.Transactions = [];
    this.Account = null;
    this.SynchronizationStatus = "";
    this.SynchronizationMessage = "";
    this.SynchronizationStartDateTime = null;
    this.SynchronizationFinishDateTime = null;
  };
  /***
  * 
  */
  w.APIAuthorizationRequest = function() {
    this.PACKAGE = "FUNDS";
    this.CLASSIFIER = "APIAUTHORIZATION_REQUEST";
    this.CopySupportingElement = false;
    this.IntegrationUID = "";
    this.AuthorizationEndpoint = "";
    this.RequestUID = "";
  };
  /***
  * 
  */
  w.APIAuthorizationRequestStatus = function() {
    this.PACKAGE = "FUNDS";
    this.CLASSIFIER = "APIAUTHORIZATION_REQUEST_STATUS";
    this.CopySupportingElement = false;
    this.Status = "";
    this.Message = "";
  };
  /***
  * 
  */
  w.APIBudgetCashTransaction = function() {
    this.PACKAGE = "FUNDS";
    this.CLASSIFIER = "APIBUDGET_CASH_TRANSACTION";
    this.CopySupportingElement = false;
    this.BudgetClsCode = "";
    this.BudgetTerritoryCode = "";
    this.TaxPayerStatus = "";
    this.BudgetPaymentUID = "";
    this.BudgetPaymentReason = "";
    this.BudgetPaymentPeriod = "";
    this.BudgetDocumentNumber = "";
    this.BudgetPaymentDate = null;
    this.BudgetPaymentType = "";
    this.TransactionDate = null;
    this.Amount = -1;
    this.Reference = "";
    this.TransactionUID = "";
    this.Charge = false;
    this.Recipient = null;
    this.Payer = null;
    this.RecipientAccount = null;
    this.PayerAccount = null;
    this.InternalTransfer = false;
    this.BudgetPayment = false;
    this.DocumentNumber = "";
    this.DocumentDate = null;
    this.BankOperationType = "";
    this.CurrencyAmount = -1;
  };
  /***
  * 
  */
  w.APICashAccount = function() {
    this.PACKAGE = "FUNDS";
    this.CLASSIFIER = "APICASH_ACCOUNT";
    this.CopySupportingElement = false;
    this.Name = "";
    this.Number = "";
    this.LegalEntity = null;
    this.CashAccountUID = "";
    this.CurrentBalance = -1;
    this.AccountType = "";
    this.AccountCurrencyLetterCode = "";
    this.BankName = "";
    this.BankBIC = "";
    this.LastSynchronizationDateTime = null;
    this.LastSynchronizationStatus = "";
    this.AutoSynchronization = false;
    this.OpenDate = null;
  };
  /***
  * 
  */
  w.APICashAccountAnalysis = function() {
    this.PACKAGE = "FUNDS";
    this.CLASSIFIER = "APICASH_ACCOUNT_ANALYSIS";
    this.CopySupportingElement = false;
    this.TransactionClassTimeSeries = [];
    this.BalanceTimeSeries = null;
    this.ChargeTimeSeries = null;
    this.IncomeTimeSeries = null;
    this.RecipientsTimeSeries = [];
    this.PayersTimeSeries = [];
  };
  /***
  * 
  */
  w.APICashTransaction = function() {
    this.PACKAGE = "FUNDS";
    this.CLASSIFIER = "APICASH_TRANSACTION";
    this.CopySupportingElement = false;
    this.TransactionDate = null;
    this.Amount = -1;
    this.Reference = "";
    this.TransactionUID = "";
    this.Charge = false;
    this.Recipient = null;
    this.Payer = null;
    this.RecipientAccount = null;
    this.PayerAccount = null;
    this.InternalTransfer = false;
    this.BudgetPayment = false;
    this.DocumentNumber = "";
    this.DocumentDate = null;
    this.BankOperationType = "";
    this.CurrencyAmount = -1;
  };
  /***
  * 
  */
  w.APICashTransactionAnalysis = function() {
    this.PACKAGE = "FUNDS";
    this.CLASSIFIER = "APICASH_TRANSACTION_ANALYSIS";
    this.CopySupportingElement = false;
    this.Transaction = null;
    this.Classes = [];
  };
  /***
  * 
  */
  w.APICashTransactionClassTimeSeries = function() {
    this.PACKAGE = "FUNDS";
    this.CLASSIFIER = "APICASH_TRANSACTION_CLASS_TIME_SERIES";
    this.CopySupportingElement = false;
    this.TransactionClass = null;
    this.Charge = false;
    this.Points = [];
    this.TotalValue = -1;
  };
  /***
  * 
  */
  w.APICounterpartyTurnoverTimeSeries = function() {
    this.PACKAGE = "FUNDS";
    this.CLASSIFIER = "APICOUNTERPARTY_TURNOVER_TIME_SERIES";
    this.CopySupportingElement = false;
    this.CounterpartyName = "";
    this.CounterpartyTaxCode = "";
    this.Charge = false;
    this.Points = [];
    this.TotalValue = -1;
  };
  /***
  * 
  */
  w.APITimeSeries = function() {
    this.PACKAGE = "FUNDS";
    this.CLASSIFIER = "APITIME_SERIES";
    this.CopySupportingElement = false;
    this.Points = [];
    this.TotalValue = -1;
  };
  /***
  * 
  */
  w.APITimeSeriesPoint = function() {
    this.PACKAGE = "FUNDS";
    this.CLASSIFIER = "APITIME_SERIES_POINT";
    this.CopySupportingElement = false;
    this.Value = -1;
    this.ValueDate = null;
  };
  /***
  * 
  */
  w.AssessmentCriterion = function() {
    this.PACKAGE = "FUNDS";
    this.CLASSIFIER = "ASSESSMENT_CRITERION";
    this.CopySupportingElement = false;
    this.Name = "";
    this.Description = "";
    this.Recommendation = "";
    this.Value = -1;
    this.Rating = -1;
    this.ID = -1;
    this.FundsRegisterID = -1;
  };
  /***
  * 
  */
  w.BalanceTurnoverRecalculationRequest = function() {
    this.PACKAGE = "FUNDS";
    this.CLASSIFIER = "BALANCE_TURNOVER_RECALCULATION_REQUEST";
    this.CopySupportingElement = false;
    this.FundsRegisterID = -1;
    this.StartBalance = -1;
    this.OperationUID = -1;
  };
  /***
  * 
  */
  w.BankAccountFundsRegister = function() {
    this.PACKAGE = "FUNDS";
    this.CLASSIFIER = "BANK_ACCOUNT_FUNDS_REGISTER";
    this.CopySupportingElement = false;
    this.Bank = null;
    this.ID = -1;
    this.Name = "";
    this.RegisterNumber = "";
    this.LegalEntity = null;
    this.CurrencyID = -1;
    this.SourceUID = "";
    this.CashManagementSystem = null;
    this.LimitedAccess = false;
    this.ExternalSystemAccountID = -1;
    this.Archived = false;
    this.InConsolidation = false;
    this.Purpose = null;
    this.LimitedAccessCharge = false;
    this.LimitedAccessReceipt = false;
    this.CurrentEmployeeAccess = null;
    this.OpenDate = null;
    this.LogoURL = "";
    this.SourceType = null;
  };
  /***
  * 
  */
  w.CashBalanceRecord = function() {
    this.PACKAGE = "FUNDS";
    this.CLASSIFIER = "CASH_BALANCE_RECORD";
    this.CopySupportingElement = false;
    this.IncomingBalance = -1;
    this.OutgoingBalance = -1;
    this.ValueDate = null;
    this.Plan = false;
    this.Receipt = -1;
    this.Charge = -1;
    this.CurrencyCharge = -1;
    this.InCurrencyBalance = -1;
    this.OutCurrencyBalance = -1;
    this.CurrencyReceipt = -1;
    this.PaymentsCount = -1;
  };
  /***
  * 
  */
  w.CashDeskFundsRegister = function() {
    this.PACKAGE = "FUNDS";
    this.CLASSIFIER = "CASH_DESK_FUNDS_REGISTER";
    this.CopySupportingElement = false;
    this.ID = -1;
    this.Name = "";
    this.RegisterNumber = "";
    this.LegalEntity = null;
    this.CurrencyID = -1;
    this.SourceUID = "";
    this.CashManagementSystem = null;
    this.LimitedAccess = false;
    this.ExternalSystemAccountID = -1;
    this.Archived = false;
    this.InConsolidation = false;
    this.Purpose = null;
    this.LimitedAccessCharge = false;
    this.LimitedAccessReceipt = false;
    this.CurrentEmployeeAccess = null;
    this.OpenDate = null;
    this.LogoURL = "";
    this.SourceType = null;
  };
  /***
  * 
  */
  w.CashflowForecast = function() {
    this.PACKAGE = "FUNDS";
    this.CLASSIFIER = "CASHFLOW_FORECAST";
    this.CopySupportingElement = false;
    this.AnalysisDate = null;
    this.CashBalances = [];
    this.Incomes = [];
    this.Expenses = [];
  };
  /***
  * 
  */
  w.CashflowForecastRequest = function() {
    this.PACKAGE = "FUNDS";
    this.CLASSIFIER = "CASHFLOW_FORECAST_REQUEST";
    this.CopySupportingElement = false;
    this.AnalysisDate = null;
    this.StartDate = null;
    this.FinishDate = null;
    this.InConsolidation = false;
    this.BusinessUnitIDList = [];
    this.FundsRegisterIDList = [];
    this.ProjectIDList = [];
    this.AccountsChartItemIDList = [];
    this.TenantLegalEntityIDList = [];
    this.AccrualDateAggregation = false;
    this.ExcludeArchiveRegister = false;
  };
  /***
  * 
  */
  w.CashManagementSystem = function() {
    this.PACKAGE = "FUNDS";
    this.CLASSIFIER = "CASH_MANAGEMENT_SYSTEM";
    this.CopySupportingElement = false;
    this.Name = "";
    this.ID = -1;
    this.SubsystemInstanceGUID = "";
    this.TenantHasValidExternalAccount = false;
    this.SupportGetAccountList = false;
    this.RequestLegalEntityToGetAccountsList = false;
    this.SupportCreatePaymentDraft = false;
    this.SupportFastPayment = false;
    this.SupportRemotePaymentSignature = false;
    this.SupportsBudgetPayment = false;
    this.ProvidedData = [];
  };
  /***
  * 
  */
  w.CMSAccount = function() {
    this.PACKAGE = "FUNDS";
    this.CLASSIFIER = "CMSACCOUNT";
    this.CopySupportingElement = false;
    this.AccountNumber = "";
    this.AccountType = "";
    this.AccountCurrencyLetterCode = "";
    this.UID = "";
    this.LegalEntityUID = "";
    this.LegalEntityName = "";
    this.AccountName = "";
    this.LegalEntityTaxCode = "";
    this.Status = -1;
    this.BIC = "";
    this.ExternalSystemAccountID = -1;
    this.OpenDate = null;
    this.CashManagementSystem = null;
    this.LegalEntityAdditionalTaxCode = "";
  };
  /***
  * 
  */
  w.CMSAccountImportResult = function() {
    this.PACKAGE = "FUNDS";
    this.CLASSIFIER = "CMSACCOUNT_IMPORT_RESULT";
    this.CopySupportingElement = false;
    this.CMSAccount = null;
    this.AccountStatementImport = null;
  };
  /***
  * 
  */
  w.CMSAccountListRequest = function() {
    this.PACKAGE = "FUNDS";
    this.CLASSIFIER = "CMSACCOUNT_LIST_REQUEST";
    this.CopySupportingElement = false;
    this.LegalEntityUID = "";
    this.LegalEntityName = "";
    this.LegalEntityTaxCode = "";
    this.UID = "";
    this.SandboxMode = false;
    this.CashManagementSystemID = -1;
    this.ExternalSystemAccountGUID = "";
    this.ExternalSystemDataType = "";
  };
  /***
  * 
  */
  w.CMSAccountsResponse = function() {
    this.PACKAGE = "FUNDS";
    this.CLASSIFIER = "CMSACCOUNTS_RESPONSE";
    this.CopySupportingElement = false;
    this.Accounts = [];
    this.ServiceAvailable = false;
    this.AuthorizationSuccess = false;
    this.Message = "";
    this.UID = "";
    this.Status = "";
    this.APIRequest = null;
  };
  /***
  * 
  */
  w.CMSAccountStatementListRequest = function() {
    this.PACKAGE = "FUNDS";
    this.CLASSIFIER = "CMSACCOUNT_STATEMENT_LIST_REQUEST";
    this.CopySupportingElement = false;
    this.RequestList = [];
  };
  /***
  * 
  */
  w.CMSAccountStatementRequest = function() {
    this.PACKAGE = "FUNDS";
    this.CLASSIFIER = "CMSACCOUNT_STATEMENT_REQUEST";
    this.CopySupportingElement = false;
    this.CMSAccount = null;
    this.AccountStatementID = -1;
    this.DateStart = null;
    this.DateFinish = null;
    this.ServerTaskInstanceID = -1;
    this.UID = "";
    this.SandboxMode = false;
    this.CashManagementSystemID = -1;
    this.ExternalSystemAccountGUID = "";
    this.ExternalSystemDataType = "";
  };
  /***
  * 
  */
  w.CMSAccountStatementRequestMessage = function() {
    this.PACKAGE = "FUNDS";
    this.CLASSIFIER = "CMSACCOUNT_STATEMENT_REQUEST_MESSAGE";
    this.CopySupportingElement = false;
    this.UID = "";
    this.SendDateTime = null;
    this.State = "";
    this.DateStart = null;
    this.DateFinish = null;
    this.StatusCheckDateTime = null;
  };
  /***
  * 
  */
  w.CMSAccountStatementResponse = function() {
    this.PACKAGE = "FUNDS";
    this.CLASSIFIER = "CMSACCOUNT_STATEMENT_RESPONSE";
    this.CopySupportingElement = false;
    this.AccountStatement = null;
    this.ServiceAvailable = false;
    this.AuthorizationSuccess = false;
    this.Message = "";
    this.UID = "";
    this.Status = "";
    this.APIRequest = null;
  };
  /***
  * 
  */
  w.CMSCardTransactionsResponse = function() {
    this.PACKAGE = "FUNDS";
    this.CLASSIFIER = "CMSCARD_TRANSACTIONS_RESPONSE";
    this.CopySupportingElement = false;
    this.Items = [];
    this.ServiceAvailable = false;
    this.AuthorizationSuccess = false;
    this.Message = "";
    this.UID = "";
    this.Status = "";
    this.APIRequest = null;
  };
  /***
  * 
  */
  w.CMSCreatePaymentDraftRequest = function() {
    this.PACKAGE = "FUNDS";
    this.CLASSIFIER = "CMSCREATE_PAYMENT_DRAFT_REQUEST";
    this.CopySupportingElement = false;
    this.PaymentDraftID = -1;
    this.UID = "";
    this.SandboxMode = false;
    this.CashManagementSystemID = -1;
    this.ExternalSystemAccountGUID = "";
    this.ExternalSystemDataType = "";
  };
  /***
  * 
  */
  w.CMSCreatePaymentDraftResponse = function() {
    this.PACKAGE = "FUNDS";
    this.CLASSIFIER = "CMSCREATE_PAYMENT_DRAFT_RESPONSE";
    this.CopySupportingElement = false;
    this.CMSPaymentDraft = null;
    this.AddInfo = {};
    this.ServiceAvailable = false;
    this.AuthorizationSuccess = false;
    this.Message = "";
    this.UID = "";
    this.Status = "";
    this.APIRequest = null;
  };
  /***
  * 
  */
  w.CMSGeneratePaymentSignatureRequest = function() {
    this.PACKAGE = "FUNDS";
    this.CLASSIFIER = "CMSGENERATE_PAYMENT_SIGNATURE_REQUEST";
    this.CopySupportingElement = false;
    this.CmsPaymentUID = "";
    this.AddInfo = {};
    this.UID = "";
    this.SandboxMode = false;
    this.CashManagementSystemID = -1;
    this.ExternalSystemAccountGUID = "";
    this.ExternalSystemDataType = "";
  };
  /***
  * 
  */
  w.CMSGeneratePaymentSignatureResponse = function() {
    this.PACKAGE = "FUNDS";
    this.CLASSIFIER = "CMSGENERATE_PAYMENT_SIGNATURE_RESPONSE";
    this.CopySupportingElement = false;
    this.SignSessionUID = "";
    this.OtpTimeout = -1;
    this.OtpValidityStatus = "";
    this.OtpAttempts = -1;
    this.BlockedFor = -1;
    this.AddInfo = {};
    this.ServiceAvailable = false;
    this.AuthorizationSuccess = false;
    this.Message = "";
    this.UID = "";
    this.Status = "";
    this.APIRequest = null;
  };
  /***
  * 
  */
  w.CMSIntegrationStatistics = function() {
    this.PACKAGE = "FUNDS";
    this.CLASSIFIER = "CMSINTEGRATION_STATISTICS";
    this.CopySupportingElement = false;
    this.CashManagementSystemID = -1;
    this.CashManagementSystemName = "";
    this.LastImportResults = [];
  };
  /***
  * 
  */
  w.CMSMarketplaceTransactionsRequest = function() {
    this.PACKAGE = "FUNDS";
    this.CLASSIFIER = "CMSMARKETPLACE_TRANSACTIONS_REQUEST";
    this.CopySupportingElement = false;
    this.OperationID = -1;
    this.CMSAccount = null;
    this.AccountStatementID = -1;
    this.DateStart = null;
    this.DateFinish = null;
    this.ServerTaskInstanceID = -1;
    this.UID = "";
    this.SandboxMode = false;
    this.CashManagementSystemID = -1;
    this.ExternalSystemAccountGUID = "";
    this.ExternalSystemDataType = "";
  };
  /***
  * 
  */
  w.CMSPayment = function() {
    this.PACKAGE = "FUNDS";
    this.CLASSIFIER = "CMSPAYMENT";
    this.CopySupportingElement = false;
    this.PaymentAmount = -1;
    this.PaymentDate = null;
    this.Reference = "";
    this.CMSPaymentUID = "";
    this.Payer = null;
    this.Recipient = null;
    this.PayerAccount = null;
    this.RecipientAccount = null;
    this.InvoiceNumber = "";
    this.InvoiceRequiredPaymentDate = null;
    this.InternalInvoicePayment = false;
    this.SignBankURL = "";
    this.RecipientBankSystemAccountGUID = "";
    this.VatAmountIncluded = -1;
    this.VatRate = -1;
    this.SubjectToVat = false;
    this.FastSignSupport = false;
    this.InvoiceUID = "";
    this.DocumentDate = null;
    this.BudgetClsCode = "";
    this.BudgetTerritoryCode = "";
    this.TaxPayerStatus = "";
    this.BudgetPaymentUID = "";
    this.BudgetPaymentReason = "";
    this.BudgetPaymentPeriod = "";
    this.BudgetDocumentNumber = "";
    this.BudgetPaymentDate = null;
    this.BudgetPaymentType = "";
  };
  /***
  * 
  */
  w.CMSPaymentStatusRequest = function() {
    this.PACKAGE = "FUNDS";
    this.CLASSIFIER = "CMSPAYMENT_STATUS_REQUEST";
    this.CopySupportingElement = false;
    this.PaymentID = -1;
    this.UID = "";
    this.SandboxMode = false;
    this.CashManagementSystemID = -1;
    this.ExternalSystemAccountGUID = "";
    this.ExternalSystemDataType = "";
  };
  /***
  * 
  */
  w.CMSPaymentStatusResponse = function() {
    this.PACKAGE = "FUNDS";
    this.CLASSIFIER = "CMSPAYMENT_STATUS_RESPONSE";
    this.CopySupportingElement = false;
    this.PaymentStatus = "";
    this.CMSPaymentUID = "";
    this.ServiceAvailable = false;
    this.AuthorizationSuccess = false;
    this.Message = "";
    this.UID = "";
    this.Status = "";
    this.APIRequest = null;
  };
  /***
  * 
  */
  w.CMSPaymentStatusSynchJob = function() {
    this.PACKAGE = "FUNDS";
    this.CLASSIFIER = "CMSPAYMENT_STATUS_SYNCH_JOB";
    this.CopySupportingElement = false;
    this.LastCheckDateTime = null;
    this.FirstCheckDateTime = null;
    this.StartedSecurityAgent = null;
    this.SessionTicketUID = "";
    this.ExternalSystemAccount = null;
    this.ID = -1;
    this.PaymentID = -1;
    this.UID = "";
    this.SandboxMode = false;
    this.CashManagementSystemID = -1;
    this.ExternalSystemAccountGUID = "";
    this.ExternalSystemDataType = "";
  };
  /***
  * 
  */
  w.CMSRequest = function() {
    this.PACKAGE = "FUNDS";
    this.CLASSIFIER = "CMSREQUEST";
    this.CopySupportingElement = false;
    this.UID = "";
    this.SandboxMode = false;
    this.CashManagementSystemID = -1;
    this.ExternalSystemAccountGUID = "";
    this.ExternalSystemDataType = "";
  };
  /***
  * 
  */
  w.CMSSendInvoiceRequest = function() {
    this.PACKAGE = "FUNDS";
    this.CLASSIFIER = "CMSSEND_INVOICE_REQUEST";
    this.CopySupportingElement = false;
    this.DocumentUID = "";
    this.FundsRegisterID = -1;
    this.UID = "";
    this.SandboxMode = false;
    this.CashManagementSystemID = -1;
    this.ExternalSystemAccountGUID = "";
    this.ExternalSystemDataType = "";
  };
  /***
  * 
  */
  w.CMSSignPaymentRequest = function() {
    this.PACKAGE = "FUNDS";
    this.CLASSIFIER = "CMSSIGN_PAYMENT_REQUEST";
    this.CopySupportingElement = false;
    this.CMSSignatureSessionUID = "";
    this.OtpCode = "";
    this.CMSPaymentUID = "";
    this.AddInfo = {};
    this.UID = "";
    this.SandboxMode = false;
    this.CashManagementSystemID = -1;
    this.ExternalSystemAccountGUID = "";
    this.ExternalSystemDataType = "";
  };
  /***
  * 
  */
  w.CMSSignPaymentResponse = function() {
    this.PACKAGE = "FUNDS";
    this.CLASSIFIER = "CMSSIGN_PAYMENT_RESPONSE";
    this.CopySupportingElement = false;
    this.OtpTimeout = -1;
    this.OtpValidityStatus = "";
    this.BlockedFor = -1;
    this.AvailableAttempts = -1;
    this.ServiceAvailable = false;
    this.AuthorizationSuccess = false;
    this.Message = "";
    this.UID = "";
    this.Status = "";
    this.APIRequest = null;
  };
  /***
  * получить полный список факт балансов
  */
  w.FactBalancesTotalRequest = function() {
    this.PACKAGE = "FUNDS";
    this.CLASSIFIER = "FACT_BALANCES_TOTAL_REQUEST";
    this.CopySupportingElement = false;
    this.StartDate = null;
    this.FinishDate = null;
    this.InConsolidation = false;
    this.FundsRegisterIDList = [];
    this.TenantLegalEntityIDList = [];
    this.ExcludeArchiveRegister = false;
  };
  /***
  * 
  */
  w.FundsRegister = function() {
    this.PACKAGE = "FUNDS";
    this.CLASSIFIER = "FUNDS_REGISTER";
    this.CopySupportingElement = false;
    this.ID = -1;
    this.Name = "";
    this.RegisterNumber = "";
    this.LegalEntity = null;
    this.CurrencyID = -1;
    this.SourceUID = "";
    this.CashManagementSystem = null;
    this.LimitedAccess = false;
    this.ExternalSystemAccountID = -1;
    this.Archived = false;
    this.InConsolidation = false;
    this.Purpose = null;
    this.LimitedAccessCharge = false;
    this.LimitedAccessReceipt = false;
    this.CurrentEmployeeAccess = null;
    this.OpenDate = null;
    this.LogoURL = "";
    this.SourceType = null;
  };
  /***
  * 
  */
  w.FundsRegisterBrief = function() {
    this.PACKAGE = "FUNDS";
    this.CLASSIFIER = "FUNDS_REGISTER_BRIEF";
    this.CopySupportingElement = false;
    this.Number = "";
    this.Bank = null;
    this.FundsRegisterType = null;
    this.Archived = false;
    this.InConsolidation = false;
    this.Purpose = null;
    this.CurrencyID = -1;
    this.SourceType = null;
    this.ID = -1;
    this.Name = "";
    this.GUID = "";
  };
  /***
  * 
  */
  w.FundsRegisterEmployeeAccess = function() {
    this.PACKAGE = "FUNDS";
    this.CLASSIFIER = "FUNDS_REGISTER_EMPLOYEE_ACCESS";
    this.CopySupportingElement = false;
    this.ChargeAccessLevel = -1;
    this.ReceiptAccessLevel = -1;
    this.EmployeeID = -1;
    this.FundsRegisterID = -1;
  };
  /***
  * 
  */
  w.FundsRegisterOpenRequest = function() {
    this.PACKAGE = "FUNDS";
    this.CLASSIFIER = "FUNDS_REGISTER_OPEN_REQUEST";
    this.CopySupportingElement = false;
    this.ID = -1;
    this.LegalEntity = null;
    this.ExternalUID = "";
    this.ExternalStatus = "";
    this.RequestDateTime = null;
    this.CashManagementSystemGUID = "";
    this.EMail = "";
    this.Phone = "";
    this.FirstName = "";
    this.MiddleName = "";
    this.LastName = "";
    this.Region = null;
    this.Comment = "";
    this.AssignedAccountNumber = "";
  };
  /***
  * 
  */
  w.FundsRegisterOpenResponse = function() {
    this.PACKAGE = "FUNDS";
    this.CLASSIFIER = "FUNDS_REGISTER_OPEN_RESPONSE";
    this.CopySupportingElement = false;
    this.Success = false;
    this.Message = "";
    this.Status = "";
  };
  /***
  * 
  */
  w.FundsRegisterPurpose = function() {
    this.PACKAGE = "FUNDS";
    this.CLASSIFIER = "FUNDS_REGISTER_PURPOSE";
    this.CopySupportingElement = false;
    this.ID = -1;
    this.Name = "";
    this.Code = "";
    this.Description = "";
    this.NumOrder = -1;
    this.Type = null;
  };
  /***
  * 
  */
  w.FundsRegisterSourceType = function() {
    this.PACKAGE = "FUNDS";
    this.CLASSIFIER = "FUNDS_REGISTER_SOURCE_TYPE";
    this.CopySupportingElement = false;
    this.ID = -1;
    this.Code = "";
    this.Name = "";
  };
  /***
  * 
  */
  w.FundsRegisterStatistics = function() {
    this.PACKAGE = "FUNDS";
    this.CLASSIFIER = "FUNDS_REGISTER_STATISTICS";
    this.CopySupportingElement = false;
    this.FundsRegister = null;
    this.OutgoingBalance = -1;
    this.Receipt = -1;
    this.Charge = -1;
    this.IncomingBalance = -1;
    this.TransferIn = -1;
    this.TransferOut = -1;
  };
  /***
  * 
  */
  w.FundsRegisterTransferRule = function() {
    this.PACKAGE = "FUNDS";
    this.CLASSIFIER = "FUNDS_REGISTER_TRANSFER_RULE";
    this.CopySupportingElement = false;
    this.ID = -1;
    this.SourceFundsRegister = null;
    this.TargetFundsRegister = null;
    this.Active = false;
    this.UseForReverseTransfer = false;
  };
  /***
  * 
  */
  w.FundsRegisterType = function() {
    this.PACKAGE = "FUNDS";
    this.CLASSIFIER = "FUNDS_REGISTER_TYPE";
    this.CopySupportingElement = false;
    this.ID = -1;
    this.Name = "";
    this.Code = "";
  };
  /***
  * 
  */
  w.GetCMSAccountStatementJob = function() {
    this.PACKAGE = "FUNDS";
    this.CLASSIFIER = "GET_CMSACCOUNT_STATEMENT_JOB";
    this.CopySupportingElement = false;
    this.Request = null;
    this.AccountStatements = [];
    this.CurrentRequestMessage = null;
    this.ID = -1;
    this.StartedSecurityAgent = null;
    this.AuditEntry = null;
    this.Completed = false;
    this.SessionTicketUID = "";
  };
  /***
  * 
  */
  w.MarketplaceFundsRegister = function() {
    this.PACKAGE = "FUNDS";
    this.CLASSIFIER = "MARKETPLACE_FUNDS_REGISTER";
    this.CopySupportingElement = false;
    this.ID = -1;
    this.Name = "";
    this.RegisterNumber = "";
    this.LegalEntity = null;
    this.CurrencyID = -1;
    this.SourceUID = "";
    this.CashManagementSystem = null;
    this.LimitedAccess = false;
    this.ExternalSystemAccountID = -1;
    this.Archived = false;
    this.InConsolidation = false;
    this.Purpose = null;
    this.LimitedAccessCharge = false;
    this.LimitedAccessReceipt = false;
    this.CurrentEmployeeAccess = null;
    this.OpenDate = null;
    this.LogoURL = "";
    this.SourceType = null;
  };
  /***
  * 
  */
  w.NaturalPersonCardFundsRegister = function() {
    this.PACKAGE = "FUNDS";
    this.CLASSIFIER = "NATURAL_PERSON_CARD_FUNDS_REGISTER";
    this.CopySupportingElement = false;
    this.Bank = null;
    this.ID = -1;
    this.Name = "";
    this.RegisterNumber = "";
    this.LegalEntity = null;
    this.CurrencyID = -1;
    this.SourceUID = "";
    this.CashManagementSystem = null;
    this.LimitedAccess = false;
    this.ExternalSystemAccountID = -1;
    this.Archived = false;
    this.InConsolidation = false;
    this.Purpose = null;
    this.LimitedAccessCharge = false;
    this.LimitedAccessReceipt = false;
    this.CurrentEmployeeAccess = null;
    this.OpenDate = null;
    this.LogoURL = "";
    this.SourceType = null;
  };
  /***
  * 
  */
  w.PosTerminal = function() {
    this.PACKAGE = "FUNDS";
    this.CLASSIFIER = "POS_TERMINAL";
    this.CopySupportingElement = false;
    this.ID = -1;
    this.Name = "";
    this.Active = false;
    this.Address = "";
    this.UID = "";
  };
  /***
  * 
  */
  w.PosTerminalFundsRegister = function() {
    this.PACKAGE = "FUNDS";
    this.CLASSIFIER = "POS_TERMINAL_FUNDS_REGISTER";
    this.CopySupportingElement = false;
    this.ID = -1;
    this.Name = "";
    this.RegisterNumber = "";
    this.LegalEntity = null;
    this.CurrencyID = -1;
    this.SourceUID = "";
    this.CashManagementSystem = null;
    this.LimitedAccess = false;
    this.ExternalSystemAccountID = -1;
    this.Archived = false;
    this.InConsolidation = false;
    this.Purpose = null;
    this.LimitedAccessCharge = false;
    this.LimitedAccessReceipt = false;
    this.CurrentEmployeeAccess = null;
    this.OpenDate = null;
    this.LogoURL = "";
    this.SourceType = null;
  };
  /***
  * 
  */
  w.PosTerminalTransaction = function() {
    this.PACKAGE = "FUNDS";
    this.CLASSIFIER = "POS_TERMINAL_TRANSACTION";
    this.CopySupportingElement = false;
    this.TerminalUID = "";
    this.TransactionDate = null;
    this.ProcessingDate = null;
    this.Chargeback = false;
    this.Success = false;
    this.Amount = -1;
    this.CommissionAmount = -1;
    this.Reference = "";
    this.MaskedCardNumber = "";
    this.CardType = "";
    this.CurrencyCode = "";
    this.Customer = "";
    this.FailReason = "";
    this.Issuer = "";
    this.ID = -1;
    this.CardProductType = "";
    this.PaymentSystem = "";
    this.OperationType = "";
    this.TerminalName = "";
  };
  /***
  * 
  */
  w.PosTerminalTransactionListRequest = function() {
    this.PACKAGE = "FUNDS";
    this.CLASSIFIER = "POS_TERMINAL_TRANSACTION_LIST_REQUEST";
    this.CopySupportingElement = false;
    this.Terminal = null;
    this.PeriodStart = null;
    this.PeriodFinish = null;
  };
  /***
  * 
  */
  w.PosTerminalTransactionTurnover = function() {
    this.PACKAGE = "FUNDS";
    this.CLASSIFIER = "POS_TERMINAL_TRANSACTION_TURNOVER";
    this.CopySupportingElement = false;
    this.TerminalUID = "";
    this.ChargebackAmount = -1;
    this.CommissionAmount = -1;
    this.FailedAmount = -1;
    this.IncomeAmount = -1;
    this.ChargebackCount = -1;
    this.CommissionCount = -1;
    this.FailedCount = -1;
    this.IncomeCount = -1;
    this.TransactionDate = null;
  };
  /***
  * 
  */
  w.SummaryCashBalanceForPeriodRequest = function() {
    this.PACKAGE = "FUNDS";
    this.CLASSIFIER = "SUMMARY_CASH_BALANCE_FOR_PERIOD_REQUEST";
    this.CopySupportingElement = false;
    this.DateFrom = null;
    this.DateTo = null;
    this.OrganizationIDs = [];
    this.InConsolidation = false;
  };
   
   
  
  /***
   * Client API to AcquiringServiceWS web service
   */
  w.AcquiringServiceClient = function() {
    /***
     * System name of target service
     */
    this.serviceName = "AcquiringServiceWSImpl";
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.AcquiringServiceClient.prototype.getTerminals = function(callbackHandler) {
    var parameterNames = [];
    var parameterValues = [];
    var soapMessage = buildSOAPMessage("getTerminals", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param request [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.AcquiringServiceClient.prototype.getTransactions = function(request, callbackHandler) {
    var parameterNames = ["request"];
    var requestJSON = JSON.stringify(request);
    var parameterValues = [requestJSON];
    var soapMessage = buildSOAPMessage("getTransactions", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param request [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.AcquiringServiceClient.prototype.getTransactionTurnovers = function(request, callbackHandler) {
    var parameterNames = ["request"];
    var requestJSON = JSON.stringify(request);
    var parameterValues = [requestJSON];
    var soapMessage = buildSOAPMessage("getTransactionTurnovers", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.AcquiringServiceClient.prototype.importTerminals = function(callbackHandler) {
    var parameterNames = [];
    var parameterValues = [];
    var soapMessage = buildSOAPMessage("importTerminals", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param request [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.AcquiringServiceClient.prototype.importTransactions = function(request, callbackHandler) {
    var parameterNames = ["request"];
    var requestJSON = JSON.stringify(request);
    var parameterValues = [requestJSON];
    var soapMessage = buildSOAPMessage("importTransactions", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * Client API to CashManagementSystemServiceWS web service
   */
  w.CashManagementSystemServiceClient = function() {
    /***
     * System name of target service
     */
    this.serviceName = "CashManagementSystemServiceWSImpl";
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param request [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CashManagementSystemServiceClient.prototype.getAccounts = function(request, callbackHandler) {
    var parameterNames = ["request"];
    var requestJSON = JSON.stringify(request);
    var parameterValues = [requestJSON];
    var soapMessage = buildSOAPMessage("getAccounts", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = true;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param request [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CashManagementSystemServiceClient.prototype.importAccountStatementFromCMS = function(request, callbackHandler) {
    var parameterNames = ["request"];
    var requestJSON = JSON.stringify(request);
    var parameterValues = [requestJSON];
    var soapMessage = buildSOAPMessage("importAccountStatementFromCMS", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CashManagementSystemServiceClient.prototype.getCashManagementSystems = function(callbackHandler) {
    var parameterNames = [];
    var parameterValues = [];
    var soapMessage = buildSOAPMessage("getCashManagementSystems", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = true;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CashManagementSystemServiceClient.prototype.refreshCMSAccounts = function(callbackHandler) {
    var parameterNames = [];
    var parameterValues = [];
    var soapMessage = buildSOAPMessage("refreshCMSAccounts", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CashManagementSystemServiceClient.prototype.getCMSIntegrationStatistics = function(callbackHandler) {
    var parameterNames = [];
    var parameterValues = [];
    var soapMessage = buildSOAPMessage("getCMSIntegrationStatistics", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param request [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CashManagementSystemServiceClient.prototype.sendPaymentDraft = function(request, callbackHandler) {
    var parameterNames = ["request"];
    var requestJSON = JSON.stringify(request);
    var parameterValues = [requestJSON];
    var soapMessage = buildSOAPMessage("sendPaymentDraft", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param job [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CashManagementSystemServiceClient.prototype.processGetCMSAccountStatementJob = function(job, callbackHandler) {
    var parameterNames = ["job"];
    var jobJSON = JSON.stringify(job);
    var parameterValues = [jobJSON];
    var soapMessage = buildSOAPMessage("processGetCMSAccountStatementJob", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param request [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CashManagementSystemServiceClient.prototype.synchronizePaymentStatus = function(request, callbackHandler) {
    var parameterNames = ["request"];
    var requestJSON = JSON.stringify(request);
    var parameterValues = [requestJSON];
    var soapMessage = buildSOAPMessage("synchronizePaymentStatus", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT][RUS!Импортировать инвойс, создать черновик платежки и отправить счет в банк!RUS]
   * @param request [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CashManagementSystemServiceClient.prototype.sendInvoicePaymentToCMS = function(request, callbackHandler) {
    var parameterNames = ["request"];
    var requestJSON = JSON.stringify(request);
    var parameterValues = [requestJSON];
    var soapMessage = buildSOAPMessage("sendInvoicePaymentToCMS", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param request [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CashManagementSystemServiceClient.prototype.generatePaymentSignature = function(request, callbackHandler) {
    var parameterNames = ["request"];
    var requestJSON = JSON.stringify(request);
    var parameterValues = [requestJSON];
    var soapMessage = buildSOAPMessage("generatePaymentSignature", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param request [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CashManagementSystemServiceClient.prototype.signPayment = function(request, callbackHandler) {
    var parameterNames = ["request"];
    var requestJSON = JSON.stringify(request);
    var parameterValues = [requestJSON];
    var soapMessage = buildSOAPMessage("signPayment", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param paymentIDs [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CashManagementSystemServiceClient.prototype.synchronizeInvoicePaymentStatus = function(paymentIDs, callbackHandler) {
    var parameterNames = ["paymentIDs"];
    var paymentIDsJSON = JSON.stringify(paymentIDs);
    var parameterValues = [paymentIDsJSON];
    var soapMessage = buildSOAPMessage("synchronizeInvoicePaymentStatus", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param request [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CashManagementSystemServiceClient.prototype.importCardTransactionsFromCMS = function(request, callbackHandler) {
    var parameterNames = ["request"];
    var requestJSON = JSON.stringify(request);
    var parameterValues = [requestJSON];
    var soapMessage = buildSOAPMessage("importCardTransactionsFromCMS", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param request [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CashManagementSystemServiceClient.prototype.importMarketplaceTransactionFromCMS = function(request, callbackHandler) {
    var parameterNames = ["request"];
    var requestJSON = JSON.stringify(request);
    var parameterValues = [requestJSON];
    var soapMessage = buildSOAPMessage("importMarketplaceTransactionFromCMS", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param operationUID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CashManagementSystemServiceClient.prototype.getImportMarketplaceTransactionStatus = function(operationUID, callbackHandler) {
    var parameterNames = ["operationUID"];
    var parameterValues = [operationUID];
    var soapMessage = buildSOAPMessage("getImportMarketplaceTransactionStatus", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * Client API to FundsRegisterServiceWS web service
   */
  w.FundsRegisterServiceClient = function() {
    /***
     * System name of target service
     */
    this.serviceName = "FundsRegisterServiceWSImpl";
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param register [DEFAULT!!DEFAULT]
   * @param initialBalance [DEFAULT!!DEFAULT]
   * @param initialDate [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FundsRegisterServiceClient.prototype.createFundsRegister = function(register, initialBalance, initialDate, callbackHandler) {
    var parameterNames = ["register", "initialBalance", "initialDate"];
    var registerJSON = JSON.stringify(register);
    var parameterValues = [registerJSON, initialBalance, initialDate];
    var soapMessage = buildSOAPMessage("createFundsRegister", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param registerID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FundsRegisterServiceClient.prototype.getFundsRegisterByID = function(registerID, callbackHandler) {
    var parameterNames = ["registerID"];
    var parameterValues = [registerID];
    var soapMessage = buildSOAPMessage("getFundsRegisterByID", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = true;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param registerID [DEFAULT!!DEFAULT]
   * @param amount [DEFAULT!!DEFAULT]
   * @param isRegisterCharged [DEFAULT!Flag  defining is register is charged by the given amoun!DEFAULT]
   * @param transactionDate [DEFAULT!!DEFAULT]
   * @param description [DEFAULT!!DEFAULT][ENG!Optional description of transaction!ENG]
   * @param callbackHandler Function to service callback result
   */
  w.FundsRegisterServiceClient.prototype.doTransaction = function(registerID, amount, isRegisterCharged, transactionDate, description, callbackHandler) {
    var parameterNames = ["registerID", "amount", "isRegisterCharged", "transactionDate", "description"];
    var parameterValues = [registerID, amount, isRegisterCharged, transactionDate, description];
    var soapMessage = buildSOAPMessage("doTransaction", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param balanceDate [DEFAULT!!DEFAULT]
   * @param registerID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FundsRegisterServiceClient.prototype.getOutgoingBalanceForDate = function(balanceDate, registerID, callbackHandler) {
    var parameterNames = ["balanceDate", "registerID"];
    var parameterValues = [balanceDate, registerID];
    var soapMessage = buildSOAPMessage("getOutgoingBalanceForDate", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param register [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FundsRegisterServiceClient.prototype.changeFundsRegister = function(register, callbackHandler) {
    var parameterNames = ["register"];
    var registerJSON = JSON.stringify(register);
    var parameterValues = [registerJSON];
    var soapMessage = buildSOAPMessage("changeFundsRegister", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param periodStart [DEFAULT!!DEFAULT]
   * @param periodEnd [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FundsRegisterServiceClient.prototype.getFundsRegisterStatistics = function(periodStart, periodEnd, callbackHandler) {
    var parameterNames = ["periodStart", "periodEnd"];
    var parameterValues = [periodStart, periodEnd];
    var soapMessage = buildSOAPMessage("getFundsRegisterStatistics", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = true;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param fundsRegisterID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FundsRegisterServiceClient.prototype.removeFundsRegister = function(fundsRegisterID, callbackHandler) {
    var parameterNames = ["fundsRegisterID"];
    var parameterValues = [fundsRegisterID];
    var soapMessage = buildSOAPMessage("removeFundsRegister", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param registerNumber [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FundsRegisterServiceClient.prototype.getFundsRegisterByNumber = function(registerNumber, callbackHandler) {
    var parameterNames = ["registerNumber"];
    var parameterValues = [registerNumber];
    var soapMessage = buildSOAPMessage("getFundsRegisterByNumber", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = true;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param balanceDate [DEFAULT!!DEFAULT]
   * @param registerID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FundsRegisterServiceClient.prototype.getIncomingBalanceForDate = function(balanceDate, registerID, callbackHandler) {
    var parameterNames = ["balanceDate", "registerID"];
    var parameterValues = [balanceDate, registerID];
    var soapMessage = buildSOAPMessage("getIncomingBalanceForDate", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = true;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param accountStatement [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FundsRegisterServiceClient.prototype.importAccountStatement = function(accountStatement, callbackHandler) {
    var parameterNames = ["accountStatement"];
    var accountStatementJSON = JSON.stringify(accountStatement);
    var parameterValues = [accountStatementJSON];
    var soapMessage = buildSOAPMessage("importAccountStatement", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param accountStatementID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FundsRegisterServiceClient.prototype.getAccountStatementImport = function(accountStatementID, callbackHandler) {
    var parameterNames = ["accountStatementID"];
    var parameterValues = [accountStatementID];
    var soapMessage = buildSOAPMessage("getAccountStatementImport", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = true;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param fileReference [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FundsRegisterServiceClient.prototype.importAccountStatementFile = function(fileReference, callbackHandler) {
    var parameterNames = ["fileReference"];
    var fileReferenceJSON = JSON.stringify(fileReference);
    var parameterValues = [fileReferenceJSON];
    var soapMessage = buildSOAPMessage("importAccountStatementFile", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param request [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FundsRegisterServiceClient.prototype.getCashflowForecast = function(request, callbackHandler) {
    var parameterNames = ["request"];
    var requestJSON = JSON.stringify(request);
    var parameterValues = [requestJSON];
    var soapMessage = buildSOAPMessage("getCashflowForecast", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = true;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param fundsRegisterID [DEFAULT!!DEFAULT]
   * @param periodDateStart [DEFAULT!!DEFAULT]
   * @param periodDateEnd [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FundsRegisterServiceClient.prototype.getAccountStatementImportsForPeriod = function(fundsRegisterID, periodDateStart, periodDateEnd, callbackHandler) {
    var parameterNames = ["fundsRegisterID", "periodDateStart", "periodDateEnd"];
    var parameterValues = [fundsRegisterID, periodDateStart, periodDateEnd];
    var soapMessage = buildSOAPMessage("getAccountStatementImportsForPeriod", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = true;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param fundsRegisterID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FundsRegisterServiceClient.prototype.getLastAccountStatementImport = function(fundsRegisterID, callbackHandler) {
    var parameterNames = ["fundsRegisterID"];
    var parameterValues = [fundsRegisterID];
    var soapMessage = buildSOAPMessage("getLastAccountStatementImport", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = true;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param fundsRegisterID [DEFAULT!!DEFAULT]
   * @param startBalance [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FundsRegisterServiceClient.prototype.recalculateFundsRegisterBalances = function(fundsRegisterID, startBalance, callbackHandler) {
    var parameterNames = ["fundsRegisterID", "startBalance"];
    var parameterValues = [fundsRegisterID, startBalance];
    var soapMessage = buildSOAPMessage("recalculateFundsRegisterBalances", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = true;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param operationUID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FundsRegisterServiceClient.prototype.getBalanceRecalculationStatus = function(operationUID, callbackHandler) {
    var parameterNames = ["operationUID"];
    var parameterValues = [operationUID];
    var soapMessage = buildSOAPMessage("getBalanceRecalculationStatus", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = true;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param fundsRegisterID [DEFAULT!!DEFAULT]
   * @param employeeAccessList [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FundsRegisterServiceClient.prototype.grantFundsRegisterAccess = function(fundsRegisterID, employeeAccessList, callbackHandler) {
    var parameterNames = ["fundsRegisterID", "employeeAccessList"];
    var employeeAccessListJSON = JSON.stringify(employeeAccessList);
    var parameterValues = [fundsRegisterID, employeeAccessListJSON];
    var soapMessage = buildSOAPMessage("grantFundsRegisterAccess", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param fundsRegisterID [DEFAULT!!DEFAULT]
   * @param employeeAccessList [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FundsRegisterServiceClient.prototype.denyFundsRegisterAccess = function(fundsRegisterID, employeeAccessList, callbackHandler) {
    var parameterNames = ["fundsRegisterID", "employeeAccessList"];
    var employeeAccessListJSON = JSON.stringify(employeeAccessList);
    var parameterValues = [fundsRegisterID, employeeAccessListJSON];
    var soapMessage = buildSOAPMessage("denyFundsRegisterAccess", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param fundsRegisterID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FundsRegisterServiceClient.prototype.getFundsRegisterAccessList = function(fundsRegisterID, callbackHandler) {
    var parameterNames = ["fundsRegisterID"];
    var parameterValues = [fundsRegisterID];
    var soapMessage = buildSOAPMessage("getFundsRegisterAccessList", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FundsRegisterServiceClient.prototype.getCurrentEmployeeFundsRegisterAccess = function(callbackHandler) {
    var parameterNames = [];
    var parameterValues = [];
    var soapMessage = buildSOAPMessage("getCurrentEmployeeFundsRegisterAccess", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = true;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!Return balances for dates at period!DEFAULT]
   * @param fundsRegisterID [DEFAULT!!DEFAULT]
   * @param dateFrom [DEFAULT!!DEFAULT]
   * @param dateTo [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FundsRegisterServiceClient.prototype.getCashBalancesForPeriod = function(fundsRegisterID, dateFrom, dateTo, callbackHandler) {
    var parameterNames = ["fundsRegisterID", "dateFrom", "dateTo"];
    var parameterValues = [fundsRegisterID, dateFrom, dateTo];
    var soapMessage = buildSOAPMessage("getCashBalancesForPeriod", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param fundsRegisterID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FundsRegisterServiceClient.prototype.getFundsRegisterBoundaryBalances = function(fundsRegisterID, callbackHandler) {
    var parameterNames = ["fundsRegisterID"];
    var parameterValues = [fundsRegisterID];
    var soapMessage = buildSOAPMessage("getFundsRegisterBoundaryBalances", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = true;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FundsRegisterServiceClient.prototype.getLastAccountStatementImportList = function(callbackHandler) {
    var parameterNames = [];
    var parameterValues = [];
    var soapMessage = buildSOAPMessage("getLastAccountStatementImportList", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param request [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FundsRegisterServiceClient.prototype.getSummaryCashBalanceForPeriod = function(request, callbackHandler) {
    var parameterNames = ["request"];
    var requestJSON = JSON.stringify(request);
    var parameterValues = [requestJSON];
    var soapMessage = buildSOAPMessage("getSummaryCashBalanceForPeriod", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = true;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FundsRegisterServiceClient.prototype.clearFundsRegisterCache = function(callbackHandler) {
    var parameterNames = [];
    var parameterValues = [];
    var soapMessage = buildSOAPMessage("clearFundsRegisterCache", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param cmsAccount [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FundsRegisterServiceClient.prototype.createFundsRegisterFromCMSAccount = function(cmsAccount, callbackHandler) {
    var parameterNames = ["cmsAccount"];
    var cmsAccountJSON = JSON.stringify(cmsAccount);
    var parameterValues = [cmsAccountJSON];
    var soapMessage = buildSOAPMessage("createFundsRegisterFromCMSAccount", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param periodStart [DEFAULT!!DEFAULT]
   * @param periodEnd [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FundsRegisterServiceClient.prototype.getAssessmentCriteria = function(periodStart, periodEnd, callbackHandler) {
    var parameterNames = ["periodStart", "periodEnd"];
    var parameterValues = [periodStart, periodEnd];
    var soapMessage = buildSOAPMessage("getAssessmentCriteria", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FundsRegisterServiceClient.prototype.resetFundsRegisterCache = function(callbackHandler) {
    var parameterNames = [];
    var parameterValues = [];
    var soapMessage = buildSOAPMessage("resetFundsRegisterCache", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FundsRegisterServiceClient.prototype.getFundsRegisterPurposes = function(callbackHandler) {
    var parameterNames = [];
    var parameterValues = [];
    var soapMessage = buildSOAPMessage("getFundsRegisterPurposes", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param fundsRegisterAccessList [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FundsRegisterServiceClient.prototype.setFundsRegistersAccess = function(fundsRegisterAccessList, callbackHandler) {
    var parameterNames = ["fundsRegisterAccessList"];
    var fundsRegisterAccessListJSON = JSON.stringify(fundsRegisterAccessList);
    var parameterValues = [fundsRegisterAccessListJSON];
    var soapMessage = buildSOAPMessage("setFundsRegistersAccess", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param onlyAccessed [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FundsRegisterServiceClient.prototype.getFundsRegisters = function(onlyAccessed, callbackHandler) {
    var parameterNames = ["onlyAccessed"];
    var parameterValues = [onlyAccessed];
    var soapMessage = buildSOAPMessage("getFundsRegisters", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param transferRule [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FundsRegisterServiceClient.prototype.addTransferRule = function(transferRule, callbackHandler) {
    var parameterNames = ["transferRule"];
    var transferRuleJSON = JSON.stringify(transferRule);
    var parameterValues = [transferRuleJSON];
    var soapMessage = buildSOAPMessage("addTransferRule", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param transferRuleID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FundsRegisterServiceClient.prototype.getTransferRuleByID = function(transferRuleID, callbackHandler) {
    var parameterNames = ["transferRuleID"];
    var parameterValues = [transferRuleID];
    var soapMessage = buildSOAPMessage("getTransferRuleByID", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param transferRuleID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FundsRegisterServiceClient.prototype.removeTransferRule = function(transferRuleID, callbackHandler) {
    var parameterNames = ["transferRuleID"];
    var parameterValues = [transferRuleID];
    var soapMessage = buildSOAPMessage("removeTransferRule", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param transferRule [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FundsRegisterServiceClient.prototype.updateTransferRule = function(transferRule, callbackHandler) {
    var parameterNames = ["transferRule"];
    var transferRuleJSON = JSON.stringify(transferRule);
    var parameterValues = [transferRuleJSON];
    var soapMessage = buildSOAPMessage("updateTransferRule", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FundsRegisterServiceClient.prototype.getTransferRuleList = function(callbackHandler) {
    var parameterNames = [];
    var parameterValues = [];
    var soapMessage = buildSOAPMessage("getTransferRuleList", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param dateFrom [DEFAULT!!DEFAULT]
   * @param dateTo [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FundsRegisterServiceClient.prototype.applyTransferRules = function(dateFrom, dateTo, callbackHandler) {
    var parameterNames = ["dateFrom", "dateTo"];
    var parameterValues = [dateFrom, dateTo];
    var soapMessage = buildSOAPMessage("applyTransferRules", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param request [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FundsRegisterServiceClient.prototype.getFactBalancesTotalList = function(request, callbackHandler) {
    var parameterNames = ["request"];
    var requestJSON = JSON.stringify(request);
    var parameterValues = [requestJSON];
    var soapMessage = buildSOAPMessage("getFactBalancesTotalList", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param request [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FundsRegisterServiceClient.prototype.createOpenBankAccountRequest = function(request, callbackHandler) {
    var parameterNames = ["request"];
    var requestJSON = JSON.stringify(request);
    var parameterValues = [requestJSON];
    var soapMessage = buildSOAPMessage("createOpenBankAccountRequest", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param requestID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FundsRegisterServiceClient.prototype.getOpenBankAccountRequestByID = function(requestID, callbackHandler) {
    var parameterNames = ["requestID"];
    var parameterValues = [requestID];
    var soapMessage = buildSOAPMessage("getOpenBankAccountRequestByID", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FundsRegisterServiceClient.prototype.getOpenBankAccountRequestList = function(callbackHandler) {
    var parameterNames = [];
    var parameterValues = [];
    var soapMessage = buildSOAPMessage("getOpenBankAccountRequestList", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param cmsAccount [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FundsRegisterServiceClient.prototype.createCommonFundsRegisterFromCMSAccount = function(cmsAccount, callbackHandler) {
    var parameterNames = ["cmsAccount"];
    var cmsAccountJSON = JSON.stringify(cmsAccount);
    var parameterValues = [cmsAccountJSON];
    var soapMessage = buildSOAPMessage("createCommonFundsRegisterFromCMSAccount", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FundsRegisterServiceClient.prototype.getFundsRegisterSourceTypes = function(callbackHandler) {
    var parameterNames = [];
    var parameterValues = [];
    var soapMessage = buildSOAPMessage("getFundsRegisterSourceTypes", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  
  /***
   * Package security rights constants
   */
  
  w.FundsSecurityRights = {
  
      REGISTER_EMPLOYEE_ACCESS_EDIT_RIGHT:new SecurityRightCheckHandle('0b0e1f6d-a647-4592-987a-67ca6d1a2e3a'),
      REGISTER_SUPERVISION_RIGHT:new SecurityRightCheckHandle('84f4d3df-1acc-4487-ad1b-88775af9af74'),
      X_FUNDS_REGISTERS__CHANGE_FUNDS_REGISTERS:new SecurityRightCheckHandle('9c74dd59-d612-4b8f-be19-1e3832f7cb30'),
      X_FUNDS_REGISTERS__CHANGE_FUNDS_REGISTERS_ACCESS:new SecurityRightCheckHandle('dcd35f11-679e-4fcd-85f4-bc4545ae0bb8'),
      X_FUNDS_REGISTERS__VIEW_FUNDS_REGISTERS:new SecurityRightCheckHandle('e6ace753-d6bc-4baa-812c-2e5607861790')
  };
  })(typeof exports !== 'undefined' ? exports : window);
  