import React from 'react';

import { Root, Label } from './styled';

export type DocumentPlaceholderProps = {
	label?: React.ReactNode;
	noWrapLabel?: boolean;
	textAlign?: 'center';
	children?: React.ReactNode;
};

const DocumentPlaceholder: React.FC<DocumentPlaceholderProps> = props => {
	const { label, noWrapLabel, textAlign, children } = props;
	const isCentered = textAlign === 'center';

	return (
		<Root textAlign={textAlign}>
			<span style={{ opacity: children ? 1 : 0, paddingTop: 4, width: isCentered ? undefined : '100%' }}>
				{children || 'x'}
			</span>
			{label && <Label noWrapLabel={noWrapLabel}>{label}</Label>}
		</Root>
	);
};

export type DocumentEmptyLineProps = {};

const DocumentEmptyLine: React.FC<DocumentEmptyLineProps> = props => {
	return <span style={{ opacity: 0 }}>x</span>;
};

export { DocumentPlaceholder, DocumentEmptyLine };
