import { ComplexFilter } from '@core/api/core';
import { today } from '@utils/date';
import {
	StoreAsyncItem,
	checkAsyncAction,
	createAsyncInitialState,
	invalidateStateFromAction,
	createReducer,
} from '@flux';
import { types } from '../actions/types';

export type MainBalanceReportState = {
	report: StoreAsyncItem<BalanceReportResponse>;
	date: string;
	tenantEntitiesFilter: ComplexFilter;
	isAssetsItemsExpanded: boolean;
	isLiabilitiesItemsExpanded: boolean;
};

const initialState: MainBalanceReportState = {
	report: createAsyncInitialState(null),
	date: today(),
	tenantEntitiesFilter: new ComplexFilter(),
	isAssetsItemsExpanded: true,
	isLiabilitiesItemsExpanded: true,
};

const mainBalanceReportReducer = createReducer<MainBalanceReportState>(initialState, {
	[types.INVALIDATE]: (action: StaticAction, state) => {
		return {
			report: invalidateStateFromAction(action, state.report),
		};
	},
	[types.FETCH]: (action: AsyncAction<BalanceReportResponse>, state) => {
		return {
			report: checkAsyncAction(action, state.report),
		};
	},
	[types.SET_DATE]: (action: StaticAction<string>, state) => {
		return {
			date: action.value,
			report: invalidateStateFromAction(action, state.report),
		};
	},
	[types.SET_TENANT_ENTITIES_FILTER]: (action: StaticAction<ComplexFilter>, state) => {
		return {
			tenantEntitiesFilter: action.value,
			report: invalidateStateFromAction(action, state.report),
		};
	},
	[types.EXPAND_ASSETS_ITEMS]: (action: StaticAction<boolean>) => {
		return {
			isAssetsItemsExpanded: action.value,
		};
	},
	[types.EXPAND_LIABILITIES_ITEMS]: (action: StaticAction<boolean>) => {
		return {
			isLiabilitiesItemsExpanded: action.value,
		};
	},
});

export { mainBalanceReportReducer };
