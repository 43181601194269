import React, { useLayoutEffect, useRef } from 'react';

import { ExternalServiceGUID } from '@core/api/sso';
import { useFormVariant } from '@core/hooks/use-form-variant';
import { CopyToClipboardTextField } from '@core/ui/copy-to-clipboard-field';
import { FormAdditionalTaxCodeTextField } from '@counterparties/components/additional-tax-code-text-field';
import { FormTaxCodeTextField } from '@counterparties/components/tax-code-text-field';
import { AuthMethodCode } from '@integrations/models';
import { providerRoleNamesMap } from '@integrations/utils';
import { integrationHelpLinks } from '@shared/constants/cms-guids';
import { Box } from '@ui/box';
import { DrawerFooterControls } from '@ui/drawer';
import { Form, FormContent, FormCopyToClipboardTextField, FormInputField as FormTextField } from '@ui/forms';
import { TextField } from '@ui/input-field';
import { Link } from '@ui/link';
import { Loader } from '@ui/loader';
import { ProviderIcon } from '@ui/provider-icon';
import { RaisedButton } from '@ui/raised-button';
import { Typography } from '@ui/typography';
import { getDrawer } from '@utils';
import { comma } from '@utils/strings';

export type UpdateFormProps = {
	account: ExternalSystemAccount;
	authMethodCode: AuthMethodCode;
	embedded: boolean;
	integrationEmail?: string;
	isFetching: boolean;
	onClose: () => void;
	onCopyGUID: () => void;
	onSubmitForm: (account: ExternalSystemAccount, authorization?: boolean) => void;
	resMap: Record<string, RemoteExternalService>;
	ssiGUID: string;
	ssiMap: Record<string, SubsystemInstance>;
	variant: 'add' | 'edit';
};

const UpdateForm: React.FC<UpdateFormProps> = props => {
	const {
		account,
		authMethodCode,
		embedded,
		integrationEmail,
		isFetching,
		onClose,
		onCopyGUID,
		onSubmitForm,
		resMap,
		ssiGUID,
		ssiMap,
		variant,
	} = props;
	const formRef = useRef<Form<ExternalSystemAccount>>(null);
	const { isAdd, isEdit } = useFormVariant(variant);

	useLayoutEffect(() => {
		getDrawer().setContent(
			embedded
				? {
						footer: renderFooter(),
				  }
				: {
						title: renderHeader(),
						footer: renderFooter(),
				  },
		);
	}, [isFetching]);

	const renderHeader = () => {
		const title = isAdd ? 'Добавить учётную запись' : 'Изменить учётную запись';
		const ssi = !isFetching ? ssiMap[ssiGUID] : null;
		const bankName = ssi?.Name || '';
		return (
			<div>
				<Typography.Subheading fontSize={14} fontWeight={500} marginBottom={10}>
					{title}
				</Typography.Subheading>
				<Box display='flex' alignItems='center'>
					<Box display='flex' alignItems='center' marginRight={10}>
						<ProviderIcon providerName={bankName} size={30} fallback='bank-account' />
					</Box>
					<div>
						<Typography.Text component='div' fontSize={12} fontWeight={500} textTransform='uppercase' marginBottom={2}>
							{bankName}
						</Typography.Text>
						<Typography.Text component='div' fontSize={12} fontWeight={400} textTransform='none'>
							<Link
								href={integrationHelpLinks[ssi?.GUID] || '/help/bfm'}
								appearance='muted'
								target='_blank'
								rel='noopener'>
								Как подключить?
							</Link>
						</Typography.Text>
					</div>
				</Box>
			</div>
		);
	};

	const renderFooter = () => {
		const { isClientIdApiKey, isExternalAccountID, isApiKey } = getAuthMethodFlags(authMethodCode);

		if (isAdd && !isApiKey && !isClientIdApiKey && !isEmail && !isExternalAccountID) {
			return <DrawerFooterControls onClose={onClose} />;
		}

		return (
			<DrawerFooterControls
				canSubmit={!isFetching}
				actionLabel={isAdd ? 'Добавить' : 'Изменить'}
				onAction={handleInitSubmitForm}
				onClose={onClose}
			/>
		);
	};

	const handleInitSubmitForm = () => formRef?.current?.submitForm();

	const handleInitSubmitFormWithAuthorization = () => formRef?.current?.submitForm(true);

	if (isFetching) {
		return (
			<Loader.Layout height='calc(100vh - 100px)'>
				<Loader />
			</Loader.Layout>
		);
	}

	const ssi = ssiMap[ssiGUID];
	const res = resMap[ssi.ID];
	const servicesValue = comma(res.Roles.map(x => providerRoleNamesMap[x.CLASSIFIER] || ''));
	const { isApiKey, isClientIdApiKey, isEmail, isExternalAccountID, isLoginPassword, isOAuthToken } =
		getAuthMethodFlags(authMethodCode);
	const hasGuid = Boolean(account.GUID);
	const isRequiredTaxCode = requiredTaxCodeWhiteList.includes(ssi.GUID as ExternalServiceGUID);
	const isRequiredLoginPassword = requiredLoginPassword.includes(ssi.GUID as ExternalServiceGUID);

	return (
		<FormContent>
			<Form ref={formRef} formObject={account} isFetching={isFetching} fullWidth onSubmit={onSubmitForm}>
				{(isEdit || isExternalAccountID || isClientIdApiKey || isApiKey || isEmail) && (
					<FormTextField
						name='Name'
						labelText='Имя учётной записи'
						hintText={(isEmail && ssi.Name) || 'ООО «Ромашка»'}
						autoFocus={!isEdit}
						withClearBtn
						required
						fullWidth
					/>
				)}
				{isEdit && hasGuid && (
					<FormCopyToClipboardTextField
						name='GUID'
						labelText='Идентификатор учётной записи'
						fullWidth
						disabled
						onCopy={onCopyGUID}
					/>
				)}
				<TextField labelText='Предоставляемые услуги' value={servicesValue} multiLine readonly fullWidth />
				{isOAuthToken && (
					<TextField
						labelText='Статус учётной записи'
						value={account.Validated ? 'Действующая' : 'Неавторизованная'}
						readonly
						fullWidth
					/>
				)}
				{isEdit && <TextField labelText='Срок действия' value={account.ValidTo} readonly fullWidth />}
				{isRequiredTaxCode && (
					<Box>
						<FormTaxCodeTextField
							name='TaxCode'
							labelText='ИНН'
							enableOnChangeValidation
							withClearBtn
							required
							fullWidth
						/>
						<FormAdditionalTaxCodeTextField
							name='AdditionalTaxCode'
							labelText='КПП (если есть)'
							enableOnChangeValidation
							withClearBtn
							fullWidth
						/>
					</Box>
				)}
				{(isLoginPassword || isRequiredLoginPassword) && (
					<FormTextField name='AdminUser' labelText='Имя пользователя' withClearBtn fullWidth required />
				)}
				{(isLoginPassword || isRequiredLoginPassword) && (
					<FormTextField name='AdminPassword' labelText='Пароль' type='password' withClearBtn fullWidth required />
				)}
				{isClientIdApiKey && (
					<FormTextField name='TokenClientID' labelText='Client Id' withClearBtn fullWidth required />
				)}
				{(isClientIdApiKey || isApiKey) && (
					<FormTextField name='ApiKey' labelText='Ключ API' withClearBtn fullWidth required />
				)}
				{isEmail && (
					<CopyToClipboardTextField fullWidth labelText='Email' readonly type='text' value={integrationEmail} />
				)}
				{isOAuthToken && (
					<Box marginTop={30}>
						<RaisedButton type='primary' label='Авторизовать' onClick={handleInitSubmitFormWithAuthorization} />
					</Box>
				)}
			</Form>
		</FormContent>
	);
};

const getAuthMethodFlags = (authMethodCode: AuthMethodCode) => {
	return {
		isApiKey: authMethodCode === AuthMethodCode.API_KEY,
		isClientIdApiKey: authMethodCode === AuthMethodCode.CLIENT_ID_API_KEY,
		isEmail: authMethodCode === AuthMethodCode.EMAIL,
		isExternalAccountID: authMethodCode === AuthMethodCode.EXTERNAL_ACCOUNT_ID,
		isLoginPassword: authMethodCode === AuthMethodCode.LOGIN_PASSWORD,
		isOAuthToken: authMethodCode === AuthMethodCode.OAUTH_TOKEN,
	};
};

const requiredTaxCodeWhiteList = [];
const requiredLoginPassword = [ExternalServiceGUID.ZEN_MONEY_CMS];

export { UpdateForm };
