import { css } from 'styled-components';

const textClip = () => {
	return css`
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	`;
};

export { textClip };
