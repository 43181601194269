import { checkAsyncAction, createAsyncInitialState, createReducer, invalidateStateFromAction } from '@flux';
import { initializationActionsPack } from '@initialization/actions';
import { ServiceTaskKey } from '@initialization/components/service-tasks/model';
import { AboutCompanyPreferences } from '@initialization/models';

export type InitializationState = {
	aboutCompanyPreferences: StoreAsyncItem<AboutCompanyPreferences>;
	inProcess: boolean;
	isBackgroundImportInProcess: boolean;
	isBackgroundImportIsComplete: boolean;
	isComplete: boolean;
	serviceFeatures: StoreAsyncItem<Array<ServiceTaskKey>>;
};

const initialState = {
	aboutCompanyPreferences: createAsyncInitialState(null),
	inProcess: false,
	isBackgroundImportInProcess: false,
	isBackgroundImportIsComplete: false,
	isComplete: false,
	serviceFeatures: createAsyncInitialState([]),
};

const initializationReducer = createReducer<InitializationState>(initialState, {
	[initializationActionsPack.types.FETCH_ABOUT_COMPANY_PREFERENCES]: (
		action: AsyncAction<AboutCompanyPreferences>,
		state,
	) => {
		return {
			aboutCompanyPreferences: checkAsyncAction(action, state.aboutCompanyPreferences),
		};
	},
	[initializationActionsPack.types.FETCH_SERVICE_FEATURES]: (action: AsyncAction<Array<ServiceTaskKey>>, state) => {
		return {
			serviceFeatures: checkAsyncAction(action, state.serviceFeatures),
		};
	},
	[initializationActionsPack.types.SET_IMPORT_DATA_IN_BACKGROUND_IN_PROCESS]: (action: StaticAction<boolean>) => {
		return {
			isBackgroundImportInProcess: action.value,
		};
	},
	[initializationActionsPack.types.SET_IMPORT_DATA_IN_BACKGROUND_IS_COMPLETE]: (action: StaticAction<boolean>) => {
		return {
			isBackgroundImportIsComplete: action.value,
		};
	},
	[initializationActionsPack.types.SET_INITIALIZATION_IN_PROCESS]: (action: StaticAction<boolean>) => {
		return {
			inProcess: action.value,
		};
	},
	[initializationActionsPack.types.SET_INITIALIZATION_IS_COMPLETE]: (action: StaticAction<boolean>) => {
		return {
			isComplete: action.value,
		};
	},
	[initializationActionsPack.types.SET_SETTINGS]: (action: StaticAction, state) => {
		return {
			aboutCompanyPreferences: invalidateStateFromAction(action, state.aboutCompanyPreferences),
			serviceFeatures: invalidateStateFromAction(action, state.serviceFeatures),
		};
	},
});

export default initializationReducer;
