import { combineReducers } from 'redux';

import mainProjectEmployeesReducer, { MainProjectEmployeesState } from './main.reducer';


export type ProjectEmployeesState = {
	main: MainProjectEmployeesState;
};

const projectEmployeesReducer = combineReducers<ProjectEmployeesState>({
	main: mainProjectEmployeesReducer,
});

export default projectEmployeesReducer;
