import * as moment from 'moment';

const DATE_FORMAT = 'DD.MM.YYYY';
const DATE_INPUT = 'DATE_INPUT';
const DATE_SEPARATOR = '—';

const RANGES = {
	'Текущий месяц': {
		startDate: now => {
			return now.startOf('month');
		},
		endDate: now => {
			return now.endOf('month');
		},
	},
	'Предыдущий месяц': {
		startDate: now => {
			return now.startOf('month').subtract(1, 'months');
		},
		endDate: now => {
			return now.startOf('month').subtract(1, 'months').endOf('month');
		},
	},
	'Текущий квартал': {
		startDate: now => {
			return now.startOf('quarter');
		},
		endDate: now => {
			return now.endOf('quarter');
		},
	},
	'Предыдущий квартал': {
		startDate: now => {
			return now.startOf('quarter').subtract(1, 'quarters');
		},
		endDate: now => {
			return now.startOf('quarter').subtract(1, 'quarters').endOf('quarter');
		},
	},
	'Текущий год': {
		startDate: now => {
			return now.startOf('year');
		},
		endDate: now => {
			return now.endOf('year');
		},
	},
	'Предыдущий год': {
		startDate: now => {
			return now.startOf('year').subtract(1, 'years');
		},
		endDate: now => {
			return now.startOf('year').subtract(1, 'years').endOf('year');
		},
	},
};

const formatDateInput = (dateStart: string, dateEnd: string): string => {
	return `${dateStart}${DATE_SEPARATOR}${dateEnd}`;
};

const validateDateInput = (value: string): boolean => {
	const pattern = /^\d{2}\.\d{2}.\d{4}.\d{2}\.\d{2}.\d{4}$/g;

	return pattern.test(value);
};

const validateRangeInput = (value: string): boolean => {
	const pattern = /^\d{0,2}\.\d{0,2}\.\d{0,4}$/g;

	return pattern.test(value);
};

export { DATE_FORMAT, DATE_INPUT, DATE_SEPARATOR, RANGES, formatDateInput, validateDateInput, validateRangeInput };
