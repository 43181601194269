import { createDefaultPeriod } from '@core/utils/date';
import {
	StoreAsyncItem,
	createReducer,
	createAsyncInitialState,
	checkAsyncAction,
	invalidateStateFromAction,
} from '@flux';
import { types } from '../actions/types';

export type MainTransferRulesState = {
	transferRules: StoreAsyncItem<Array<FundsRegisterTransferRule>>;
	transferRule: StoreAsyncItem<FundsRegisterTransferRule>;
	textFilter: string;
	isAutoApplyRules: boolean;
	applyDateRange: DateRange;
};

const initialState: MainTransferRulesState = {
	transferRules: createAsyncInitialState([]),
	transferRule: createAsyncInitialState(null),
	textFilter: '',
	isAutoApplyRules: false,
	applyDateRange: createDefaultPeriod('year'),
};

const mainTransferRulesReducer = createReducer<MainTransferRulesState>(initialState, {
	[types.INVALIDATE]: (action: StaticAction, state) => {
		return {
			transferRules: invalidateStateFromAction(action, state.transferRules),
		};
	},
	[types.FETCH]: (action: AsyncAction<Array<FundsRegisterTransferRule>>, state) => {
		return {
			transferRules: checkAsyncAction(action, state.transferRules),
		};
	},
	[types.FETCH_BY_ID]: (action: AsyncAction<FundsRegisterTransferRule>, state) => {
		return {
			transferRule: checkAsyncAction(action, state.transferRule),
		};
	},
	[types.CLEAR_SINGLE]: () => {
		return {
			transferRule: createAsyncInitialState(null),
		};
	},
	[types.ADD]: (action: AsyncAction, state) => {
		return {
			transferRules: invalidateStateFromAction(action, state.transferRules),
		};
	},
	[types.UPDATE]: (action: AsyncAction, state) => {
		return {
			transferRules: invalidateStateFromAction(action, state.transferRules),
		};
	},
	[types.REMOVE]: (action: AsyncAction, state) => {
		return {
			transferRules: invalidateStateFromAction(action, state.transferRules),
		};
	},
	[types.SET_TEXT_FILTER]: (action: StaticAction<string>) => {
		return {
			textFilter: action.value,
		};
	},
	[types.SET_IS_AUTO_APPLY_RULES]: (action: StaticAction<boolean>) => {
		return {
			isAutoApplyRules: action.value,
		};
	},
	[types.SET_APPLY_DATE_RANGE]: (action: StaticAction<DateRange>) => {
		return {
			applyDateRange: action.value,
		};
	},
});

export { mainTransferRulesReducer };
