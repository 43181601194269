import { combineReducers } from 'redux';

import { mainBalanceReportReducer, MainBalanceReportState } from './main.reducer';

export type BalanceReportState = {
	main: MainBalanceReportState;
};

const balanceReportReducer = combineReducers<BalanceReportState>({
	main: mainBalanceReportReducer,
});

export { balanceReportReducer };
