import React, { useMemo } from 'react';

import { accountingApi } from '@core/api/accounting';
import { Box } from '@core/ui/box';
import { HighLightedText } from '@core/ui/highlighted-text';
import { Autopicker, AutopickerProps, AutopickerRenderItemContentOptions, AutopickerTreeList } from '@ui/autopicker';
import { CashflowItemAutopickerCRUD } from '../cashflow-item-autopicker-crud';
import { IncomeIndicator } from './styled';

export type CashflowItemAutopickerProps = {
	name?: string;
	isIncome?: boolean;
	hasChangeRight: boolean;
	disableAdd?: boolean;
	disableEdit?: boolean;
	disableRemove?: boolean;
	includeNoData?: boolean;
	crudTransformer?: (value: CashflowItem) => any;
} & AutopickerProps<CashflowItem>;

const CashflowItemAutopicker: React.FC<CashflowItemAutopickerProps> = props => {
	const {
		name: formName,
		isIncome,
		hasChangeRight,
		disableAdd,
		disableEdit,
		disableRemove,
		includeNoData,
		crudTransformer,
	} = props;
	const pluralItems = useMemo(() => ['статья', 'статьи', 'статей'] as [string, string, string], []);

	const createUnselectItem = useMemo(
		() => () => {
			return {
				...new accountingApi.package.CashflowItem(),
				Name: 'Не указана',
			};
		},
		[],
	);

	const renderItemContent = ({ item, searchText }: AutopickerRenderItemContentOptions<CashflowItem>) => {
		return (
			<Box>
				<IncomeIndicator isIncome={item?.Incoming} />
				<HighLightedText value={item?.Name || 'Не указана'} query={searchText} />
			</Box>
		);
	};

	return (
		<Autopicker
			labelText='Статья'
			hintText='Выберите статью'
			pluralItems={pluralItems}
			createUnselectItem={includeNoData ? createUnselectItem : undefined}
			renderAddTrigger={
				!disableAdd && hasChangeRight
					? ({ searchText, isSmallContainer, onRelatedPopupChange }) => {
							return (
								<CashflowItemAutopickerCRUD
									variant='add'
									isIncome={isIncome}
									formName={formName}
									searchText={searchText}
									transformer={crudTransformer}
									isSmallContainer={isSmallContainer}
									onRelatedPopupChange={onRelatedPopupChange}
								/>
							);
					  }
					: undefined
			}
			renderEditTrigger={
				!disableEdit && hasChangeRight
					? ({ ID, onRelatedPopupChange }) => {
							return (
								<CashflowItemAutopickerCRUD
									variant='edit'
									isIncome={isIncome}
									formName={formName}
									cashflowItemID={ID}
									transformer={crudTransformer}
									onRelatedPopupChange={onRelatedPopupChange}
								/>
							);
					  }
					: undefined
			}
			renderRemoveTrigger={
				!disableRemove && hasChangeRight
					? ({ ID, name: cashflowItemName, onRelatedPopupChange }) => {
							return (
								<CashflowItemAutopickerCRUD
									variant='remove'
									isIncome={isIncome}
									formName={formName}
									cashflowItemID={ID}
									cashflowItemName={cashflowItemName}
									transformer={crudTransformer}
									onRelatedPopupChange={onRelatedPopupChange}
								/>
							);
					  }
					: undefined
			}
			renderAddNestedTrigger={
				!disableAdd && hasChangeRight
					? ({ ID, isSmallContainer, onRelatedPopupChange }) => {
							return (
								<CashflowItemAutopickerCRUD
									variant='nested'
									isIncome={isIncome}
									formName={formName}
									cashflowItemID={ID}
									transformer={crudTransformer}
									isSmallContainer={isSmallContainer}
									onRelatedPopupChange={onRelatedPopupChange}
								/>
							);
					  }
					: undefined
			}
			{...props}
			fullWidth>
			{props => (
				<AutopickerTreeList
					{...props}
					getChildItems={getChildItems}
					getParentID={getParentID}
					detectIsItemDisabled={detectIsItemDisabled}
					isAllItemsExpanded
					renderItemContent={renderItemContent}
				/>
			)}
		</Autopicker>
	);
};

const getChildItems = (x: CashflowItem) => x.ChildItems;

const getParentID = (x: CashflowItem) => x.ParentID || -1;

const detectIsItemDisabled = (x: CashflowItem) => x?.LinkToPLDisabled || false;

export { CashflowItemAutopicker };
