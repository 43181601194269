import { Store } from 'redux';

import { IAppState } from '@store';
import { isReceiveAction } from '@flux';
import { invalidateProjects } from '@project-management/actions/invalidators';
import { mainProjectEmployeesActionsPack } from '@project-employees/actions';
import { types as projectsTypes } from '@project-management/actions/types';

function askMergeProjectsInvalidator(store: Store<IAppState>, action: StoreAction) {
	const { dispatch } = store;
	const invalidateMap = {
		[projectsTypes.RUN_PROJECTS_INVALIDATION_EFFECT]: true,
	};

	if (invalidateMap[action.type]) {
		dispatch(invalidateProjects());
	}
}

function askProjectsInvalidator(store: Store<IAppState>, action: StoreAction) {
	const { dispatch } = store;
	const invalidateMap = {
		[mainProjectEmployeesActionsPack.types.ADD_EMPLOYEE]: true,
		[mainProjectEmployeesActionsPack.types.UPDATE_EMPLOYEE]: true,
		[mainProjectEmployeesActionsPack.types.REMOVE_EMPLOYEE]: true,
		[mainProjectEmployeesActionsPack.types.SET_PROJECS_ACCESS]: true,
	};

	if (invalidateMap[action.type] && isReceiveAction(action)) {
		dispatch(invalidateProjects());
	}
}

function createInvalidatorEffect() {
	return (store: Store<IAppState>) => (next: (action: StoreAction) => void) => (action: StoreAction) => {
		next(action);
		askMergeProjectsInvalidator(store, action);
		askProjectsInvalidator(store, action);
	};
}

const runInvalidatorEffect = createInvalidatorEffect();

export { runInvalidatorEffect };
