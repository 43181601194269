import {
	StoreAsyncItem,
	createReducer,
	createAsyncInitialState,
	checkAsyncAction,
	invalidateStateFromAction,
} from '@flux';
import { FETCH_SESSION_CONTEXT } from '../actions/session-context.actions';
import { mainTenantProfileActionsPack } from '@platform/actions/main-tenant-profile.actions';

export type TenantProfileState = {
	tenantProfile: StoreAsyncItem<TenantProfile>;
};

const initialState = {
	tenantProfile: createAsyncInitialState(null),
};

const mainTenantProfileReducer = createReducer<TenantProfileState>(initialState, {
	[mainTenantProfileActionsPack.types.FETCH_TENANT_PROFILE]: (action: AsyncAction<TenantProfile>, state) => {
		return {
			tenantProfile: checkAsyncAction(action, state.tenantProfile),
		};
	},
	[FETCH_SESSION_CONTEXT]: (action: AsyncAction, state) => {
		return {
			tenantProfile: invalidateStateFromAction(action, state.tenantProfile),
		};
	},
	[mainTenantProfileActionsPack.types.UPDATE_TENANT_PROFILE]: (action: AsyncAction, state) => {
		return {
			tenantProfile: invalidateStateFromAction(action, state.tenantProfile),
		};
	},
	[mainTenantProfileActionsPack.types.REMOVE_DEMO_DATA]: (action: AsyncAction, state) => {
		return {
			tenantProfile: invalidateStateFromAction(action, state.tenantProfile),
		};
	},
	[mainTenantProfileActionsPack.types.INVALIDATE_TENANT_PROFILE]: (action: StaticAction, state) => {
		return {
			tenantProfile: invalidateStateFromAction(action, state.tenantProfile),
		};
	},
});

export default mainTenantProfileReducer;
