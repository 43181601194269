import React, { JSXElementConstructor } from 'react';
import { Link, NavLink } from 'react-router-dom';

import { Root, SimpleLink } from './styled';

export type RouterLinkProps = {
	to: string;
	withReload?: boolean;
	tag?: 'Link' | 'NavLink';
	target?: string;
	rel?: string;
	download?: boolean | string;
	style?: React.CSSProperties;
	children: React.ReactNode;
	onClick?: (e) => void;
};

const RouterLink: React.FC<RouterLinkProps> = props => {
	const { to, tag, withReload, download, children, ...accProps } = props;
	const Tag: React.JSXElementConstructor<any> = tag === 'Link' ? (withReload || download ? SimpleLink : Link) : NavLink;

	const renderWithReload = () => {
		return (
			<Tag href={to} download={download} {...accProps}>
				{children}
			</Tag>
		);
	};

	const renderWithoutReload = () => {
		return (
			<Tag to={to} {...accProps}>
				{children}
			</Tag>
		);
	};

	return <Root onClick={props.onClick}>{withReload || download ? renderWithReload() : renderWithoutReload()}</Root>;
};

RouterLink.defaultProps = {
	withReload: false,
	tag: 'Link',
};

export { RouterLink };
