import * as moment from 'moment';

//@ts-ignore
import * as budgetpackage from 'budgetpackage-api';
import { BASE_DATE_FORMAT } from '@shared/constants/time';


const budgetingApi = {
	package: budgetpackage as typeof budgetpackageApi,
	budget: {
		client: new budgetpackage.BudgetServiceClient() as BudgetServiceClient,
		fetchBudgetPeriodIntervals: (budgetID: number, dateStart: string, dateEnd: string) => {
			return new Promise<Array<BudgetPeriodInterval>>(resolve => {
				const dateStartCorrectedMoment = moment(dateStart, BASE_DATE_FORMAT).startOf('quarter');
				const dateEndCorrectedMoment = moment(dateEnd, BASE_DATE_FORMAT).endOf('quarter');
				const dateStartCorrected = dateStartCorrectedMoment.format(BASE_DATE_FORMAT);
				const dateEndCorrected = dateEndCorrectedMoment.format(BASE_DATE_FORMAT);

				budgetingApi.budget.client.getBudgetPeriodIntervals(dateStartCorrected, dateEndCorrected, budgetID, intervals => {
					intervals = intervals || [];
					const [first] = intervals;
					const [last] = intervals.slice(-1);
					const needCreateIntervals = !first
						|| !last
						|| !moment(first.EndDate, BASE_DATE_FORMAT).startOf('quarter').isSame(dateStartCorrectedMoment, 'day') 
						|| !moment(last.EndDate, BASE_DATE_FORMAT).endOf('quarter').isSame(dateEndCorrectedMoment, 'day');
	
					if (needCreateIntervals) {
						budgetingApi.budget.client.createBudgetPeriodIntervals(dateStartCorrected, dateEndCorrected, budgetID, intervals => {
							resolve(intervals);
						});
					} else {
						resolve(intervals);
					}
				});
			});
		},
		createCounterpartyBudget: (counterpartyID: number) => {
			return new Promise<Budget>(resolve => {
				budgetingApi.budget.client.createCounterpartyBudget(counterpartyID, -1, 1, 1, -1, budget => {
					resolve(budget);
				});
			});
		},
		updateBudget: (budget: Budget) => {
			return new Promise<Budget>(resolve => {
				budgetingApi.budget.client.updateBudget(budget, result => {
					resolve(result);
				});
			});
		},
		removeCounterpartyBudget: (counterpartyID: number, budgetID: number) => {
			return new Promise<boolean>(resolve => {
				budgetingApi.budget.client.removeCounterpartyBudget(counterpartyID, budgetID, result => {
					resolve(JSON.parse(result.toString()));
				});
			});
		},
		fetchBudgetByID: (budgetID: number) => {
			return new Promise<Budget>(resolve => {
				budgetingApi.budget.client.getBudgetByID(budgetID, result => {
					resolve(result);
				});
			});
		},
		fetchCounterpartyBudgetList: (counterpartyID: number) => {
			return new Promise<Array<Budget>>(resolve => {
				budgetingApi.budget.client.getCounterpartyBudgetList(counterpartyID, result => {
					resolve(result);
				})
			});
		},
		fetchCounterpartyBudget: (counterpartyID: number) => {
			return new Promise<Budget>(resolve => {
				budgetingApi.budget.fetchCounterpartyBudgetList(counterpartyID).then(budgets => {
					if (!budgets || budgets.length === 0) {
						budgetingApi.budget.createCounterpartyBudget(counterpartyID).then(budget => {
							resolve(budget);
						});
					} else {
						budgetingApi.budget.client.getBudgetByID(budgets[0].ID, budget => {
							resolve(budget);
						});
					}
				});
			});
		},
		updateBudgetContents: (budgetID: number, changeSet: BudgetChangeSet) => {
			return new Promise<BudgetChangeSet>(resolve => {
				budgetingApi.budget.client.updateBudgetContents(budgetID, changeSet, result => {
					resolve(result);
				});
			});
		},
	},
}

export {
	budgetingApi,
};
