import * as dataPackage from 'datapackage-api';
import { fromStringToBoolean } from '@utils/serializer';

export type ImportStandardDataOptions = {
	withDemoDataImport: boolean;
};

const dataApi = {
	package: dataPackage,
	tenantStandardData: {
		client: new dataPackage.TenantStandardDataServiceClient(),
		importStandardData: (options: ImportStandardDataOptions) => {
			const { withDemoDataImport } = options;
			const request: ImportStandardDataRequest = {
				...new dataApi.package.ImportStandardDataRequest(),
				WithDemoDataImport: withDemoDataImport,
			};

			return new Promise<boolean>(resolve => {
				dataApi.tenantStandardData.client.importStandardData(request, result => {
					resolve(fromStringToBoolean(result));
				});
			});
		},
		removeStandardData: () => {
			return new Promise<boolean>(resolve => {
				dataApi.tenantStandardData.client.removeStandardData(result => {
					resolve(fromStringToBoolean(result));
				});
			});
		},
	},
};

export { dataApi };
