import * as moment from 'moment';

import { createNewInvoice } from '@invoice/actions/invoice-add.actions';
import api from '@api';
import seenecoLogo from '@assets/img/logo.png';

interface InvoiceWithMeta extends Invoice {
	metadata: {
		LegalAddress: string;
		CounterpartyData: string;
	};
}

function invoiceResolver(tenant: Tenant, bill: TenantBill) {
	const invoice = createNewInvoice() as InvoiceWithMeta;
	const invoiceItem = new api.invoicepackage.FinancialDocumentItem();
	const code = bill?.Tariff?.Code || '';
	const indexOfSber = code.toLowerCase().indexOf('sber');
	const indexOfSbrf = code.toLowerCase().indexOf('sbrf');
	const isSber = indexOfSber >= 0 || indexOfSbrf >= 0;
	const description = `Оплата данного счёта является акцептом Договора-оферты, размещённого по адресу ${
		isSber ? 'https://sbbfm.ru/offer.pdf' : 'https://seeneco.ru/ru/offer.html'
	}`;
	invoice.SharedDocumentUID = '';
	invoice.Status = bill.Paid ? 'PAID' : 'ISSUED';
	invoice.Payments = bill.Paid
		? [
				{
					...new api.invoicepackage.InvoicePayment(),
					PaymentAmount: bill.Amount,
					PaymentDate: bill.PeriodDateStart,
					PaymentState: new api.corepackage.NaturalKey(),
				},
		  ]
		: [];
	invoice.LegalEntity.Name = bill.PaymentRequisites.LegalName;
	invoice.LegalEntity.LogoURL = seenecoLogo;
	invoice.LegalEntity.TaxCode = bill.PaymentRequisites.TaxReferenceNumber;
	invoice.LegalEntity.AdditionalTaxCode = bill.PaymentRequisites.TaxRegReasonCode;
	invoice.LegalEntity.Address = bill.PaymentRequisites.LegalAddress;
	invoice.LegalEntity.CompanySealImageURL = '../atr-framework-ui/images/stamp.png';
	invoice.FundsRegister.Number = bill.PaymentRequisites.SettlementAccountNumber;
	invoice.FundsRegister.Bank.Name = bill.PaymentRequisites.BankName;
	invoice.FundsRegister.Bank.Bic = bill.PaymentRequisites.BankBIC;
	invoice.FundsRegister.Bank.CorrAccount = bill.PaymentRequisites.BankCorrAccNumber;
	invoice.Number = bill.ID.toString();
	invoice.DateIssued = bill.IssueDateTime;
	invoiceItem.ItemAmount = bill.Amount;
	invoiceItem.ItemAmountTotal = bill.Amount;
	invoiceItem.ItemPrice = bill.Amount;
	invoiceItem.Qty = 1;
	invoiceItem.ItemTaxRate = 0;
	invoiceItem.Description = bill.Reference;
	invoice.Items.push(invoiceItem);
	invoice.RequiredPaymentDate = moment(bill.IssueDateTime, 'DD-MM-YYYY').add(3, 'day').format('DD-MM-YYYY');
	invoice.Description = description;
	invoice.SignEmployee.BusinessRole.Name = 'Генеральный директор';
	invoice.SignEmployee.Name = 'Кузнецов И. В.';
	invoice.SignEmployee.SignImageURL = '../atr-framework-ui/images/signature.png';
	invoice.Counterparty.Name = tenant?.LegalData?.LegalName || '';
	invoice.Counterparty.Address = tenant?.LegalData?.LegalAddress || '';
	invoice.Counterparty.TaxCode = tenant?.LegalData?.TaxReferenceNumber || '';
	invoice.Counterparty.AdditionalTaxCode = tenant?.LegalData?.TaxRegReasonCode || '';
	invoice.Counterparty.LegalEntity = true;

	return invoice;
}

export { invoiceResolver };
