import styled, { css } from 'styled-components';

type RootProps = {
	textAlign: 'center';
};

const Root = styled.div<RootProps>`
	position: relative;
	width: 100%;
	line-height: 1.2;
	border-bottom: 1px solid #000;
	display: inline-flex;
	align-items: flex-end;

	${p =>
		p.textAlign === 'center' &&
		css`
			justify-content: center; ;
		`}
`;

type LabelProps = {
	noWrapLabel: boolean;
};

const Label = styled.span<LabelProps>`
	position: absolute;
	left: 50%;
	bottom: 0;
	transform: translate(-50%, 100%) scale(0.8);

	${p =>
		p.noWrapLabel &&
		css`
			white-space: nowrap;
		`}
`;

export { Root, Label };
