import styled, { css, keyframes } from 'styled-components';

const rotateMotion = keyframes`
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
`;

type RootProps = {
	isOpen: boolean;
};

const Root = styled.div<RootProps>`
	position: relative;
	width: 210px;
	height: 70px;
	transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
	cursor: pointer;

	${p =>
		p.isOpen &&
		css`
			box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.08);
		`}

	${p =>
		!p.theme.isDefaultBrand &&
		css`
			background-color: ${p.theme.palette.accent};
		`}

	${p =>
		p.theme.isDefaultBrand &&
		css`
			background-image: linear-gradient(56deg, ${p.theme.palette.accent} 13%, #8bc34a);
		`}
`;

const ContentLayout = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	color: #fff;
	padding: 8px 8px 8px 46px;
	overflow: hidden;
`;

type AnimatedLayoutProps = {
	isAnimated: boolean;
};

const AnimatedLayout = styled.div<AnimatedLayoutProps>`
	transform-origin: center center;
	font-size: 0;

	${p =>
		p.isAnimated &&
		css`
			animation: ${rotateMotion} 2s linear infinite;
		`}
`;

export { Root, ContentLayout, AnimatedLayout };
