import React from 'react';

import { getMobileAppLinks } from '@config';
import { Box } from '@ui/box';
import { Link } from '@ui/link';
import { QrCode } from '@ui/qr-code';
import { AppStoreIcon } from '@ui/icons/app-store';
import { GooglePlayIcon } from '@ui/icons/google-play';

export type LinkToMobileAppsProps = {
	appearance: 'qr-code' | 'simple';
	app?: 'bfm' | 'invoice';
};

const LinkToMobileApps: React.FC<LinkToMobileAppsProps> = ({ appearance, app }) => {
	const isQrCode = appearance === 'qr-code';
	const hasAnyLinks = detectHasAnyMobileAppLinks(app);
	const linksMap = createMobileLinksMap();
	const links = linksMap[app];

	if (!hasAnyLinks) return null;

	const renderLinkToAppStore = () => (
		<Link href={links.appStore} target='_blank' rel='noopener' display='inline-flex' color='currentColor'>
			<AppStoreIcon />
		</Link>
	);

	const renderLinkToGooglePlay = () => (
		<Link href={links.googlePlay} target='_blank' rel='noopener' display='inline-flex' color='currentColor'>
			<GooglePlayIcon />
		</Link>
	);

	const renderQrCodeVersion = () => {
		return (
			<Box display='inline-flex'>
				{links.appStore && (
					<Box marginRight={10}>
						<Box marginBottom={10}>{renderLinkToAppStore()}</Box>
						<QrCode size={90} value={links.appStore} />
					</Box>
				)}
				{links.googlePlay && (
					<Box>
						<Box marginBottom={10}>{renderLinkToGooglePlay()}</Box>
						<QrCode size={90} value={links.googlePlay} />
					</Box>
				)}
			</Box>
		);
	};

	const renderSimpleVersion = () => {
		return (
			<Box display='inline-flex'>
				{links.appStore && <Box marginRight={20}>{renderLinkToAppStore()}</Box>}
				{links.googlePlay && <Box>{renderLinkToGooglePlay()}</Box>}
			</Box>
		);
	};

	return isQrCode ? renderQrCodeVersion() : renderSimpleVersion();
};

LinkToMobileApps.defaultProps = {
	app: 'bfm',
};

function createMobileLinksMap() {
	const { appStoreLinkToBfm, googlePlayLinkToBfm, appStoreLinkToInvoice, googlePlayLinkToInvoice } =
		getMobileAppLinks();
	const linksMap = {
		bfm: {
			googlePlay: googlePlayLinkToBfm,
			appStore: appStoreLinkToBfm,
		},
		invoice: {
			googlePlay: googlePlayLinkToInvoice,
			appStore: appStoreLinkToInvoice,
		},
	};

	return linksMap;
}

function detectHasAnyMobileAppLinks(appName: LinkToMobileAppsProps['app']) {
	const linksMap = createMobileLinksMap();
	const links = linksMap[appName];
	const hasAnyLinks = links.appStore || links.googlePlay;

	return hasAnyLinks;
}

export { LinkToMobileApps, detectHasAnyMobileAppLinks };
