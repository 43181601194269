import moment from 'moment';

import { deepClone } from '@utils';
import { createDefaultPeriod } from '@utils/date';
import { useLocalStorageValue } from '@utils/storage';
import { hasKeys } from '@utils/object';
import { Layout } from '@ui/grid-layout';
import {
	StoreAsyncItem,
	createReducer,
	createAsyncInitialState,
	checkAsyncAction,
	invalidateStateFromAction,
} from '@flux';
import { BASE_DATE_FORMAT } from '@shared/constants/time';
import { getDefaultGridLayout, getDefaultCardsExpanded } from '@dashboard/shared/utils';
import {
	GRID_LAYOUT_FLAG,
	DetalizationOptions,
	FetchDetalizationDataReturnType,
	FetchDynamicDataReturnType,
} from '@dashboard/actions';
import { types } from '@dashboard/actions/types';

const [gridLayoutInitial] = useLocalStorageValue(GRID_LAYOUT_FLAG);
const [cardsExpandedInitial] = useLocalStorageValue(types.SET_IS_CARD_EXPANDED);
export type MainBplState = {
	dashboardData: StoreAsyncItem<DashboardResponse>;
	dateRange: DateRange;
	forecastDateRange: DateRange;
	aciSeries: StoreAsyncItem<Array<PLOperationAccountsChartItemSeries>>;
	cpTop: StoreAsyncItem<CounterpartiesTopResponse>;
	fundsRegisterStatistics: StoreAsyncItem<Array<FundsRegisterStatistics>>;
	cashflowForecast: StoreAsyncItem<CashflowForecast>;
	detalization: {
		plOperations: StoreAsyncItem<Array<PLOperationBrief>>;
		options: DetalizationOptions | null;
	};
	tenantEntityIdMap: Record<number, boolean>;
	fundsRegisterIdMap: Record<number, boolean>;
	businessUnitIdMap: Record<number, boolean>;
	projectIdMap: Record<number, boolean>;
	isSettingsMode: boolean;
	gridLayout: Array<Layout>;
	gridLayoutRestored: Array<Layout>;
	isTourOpen: boolean;
	expanded: Record<string, boolean>;
	analytics: {
		businessUnitFilter: CodeNaturalKey | null;
		projectFilter: CodeNaturalKey | null;
		cashflowItemFilter: CodeNaturalKey | null;
		counterpartyFilter: CodeNaturalKey | null;
		plOperations: StoreAsyncItem<Array<PLOperationBrief>>;
		options: DetalizationOptions | null;
		operationIDs: Array<number>;
		isDetalizationOpen: boolean;
	};
	isIncludesVAT: boolean;
};

const initialState: MainBplState = {
	dashboardData: createAsyncInitialState(null),
	dateRange: createDefaultPeriod('year'),
	forecastDateRange: {
		dateStart: moment().format(BASE_DATE_FORMAT),
		dateEnd: moment().add(30, 'days').format(BASE_DATE_FORMAT),
	},
	aciSeries: createAsyncInitialState([]),
	cpTop: createAsyncInitialState(null),
	fundsRegisterStatistics: createAsyncInitialState([]),
	cashflowForecast: createAsyncInitialState(null),
	detalization: {
		plOperations: createAsyncInitialState([]),
		options: null,
	},
	tenantEntityIdMap: {},
	fundsRegisterIdMap: {},
	businessUnitIdMap: {},
	projectIdMap: {},
	isSettingsMode: false,
	gridLayout: gridLayoutInitial || getDefaultGridLayout(),
	gridLayoutRestored: deepClone(gridLayoutInitial || getDefaultGridLayout()),
	isTourOpen: false,
	expanded: cardsExpandedInitial || getDefaultCardsExpanded(),
	analytics: {
		businessUnitFilter: null,
		projectFilter: null,
		cashflowItemFilter: null,
		counterpartyFilter: null,
		plOperations: createAsyncInitialState([]),
		options: null,
		operationIDs: [],
		isDetalizationOpen: false,
	},
	isIncludesVAT: true,
};

const mainBplReducer = createReducer<MainBplState>(initialState, {
	[types.SET_DATE_RANGE]: (action: StaticAction<DateRange>, state) => {
		return {
			dateRange: action.value,
			dashboardData: invalidateStateFromAction(action, state.dashboardData),
			aciSeries: invalidateStateFromAction(action, state.aciSeries),
			cpTop: invalidateStateFromAction(action, state.cpTop),
			fundsRegisterStatistics: invalidateStateFromAction(action, state.fundsRegisterStatistics),
			detalization: {
				plOperations: createAsyncInitialState([]),
				options: null,
			},
		};
	},
	[types.INVALIDATE_DASHBOARD_DATA]: (action: StaticAction, state) => {
		return {
			dashboardData: invalidateStateFromAction(action, state.dashboardData),
			aciSeries: invalidateStateFromAction(action, state.aciSeries),
			cpTop: invalidateStateFromAction(action, state.cpTop),
			fundsRegisterStatistics: invalidateStateFromAction(action, state.fundsRegisterStatistics),
			cashflowForecast: invalidateStateFromAction(action, state.cashflowForecast),
			detalization: {
				...state.detalization,
				plOperations: invalidateStateFromAction(action, state.detalization.plOperations),
			},
			analytics: {
				...state.analytics,
				plOperations: invalidateStateFromAction(action, state.analytics.plOperations),
			},
		};
	},
	[types.FETCH_DASHBOARD_DATA]: (action: AsyncAction<DashboardResponse>, state) => {
		return {
			dashboardData: checkAsyncAction(action, state.dashboardData),
		};
	},
	[types.FETCH_DYNAMIC_DATA]: (action: AsyncAction<FetchDynamicDataReturnType>, state) => {
		return {
			aciSeries: checkAsyncAction(action, state.aciSeries, x => x.aciSeries),
			cpTop: checkAsyncAction(action, state.cpTop, x => x.cpTop),
			fundsRegisterStatistics: checkAsyncAction(action, state.fundsRegisterStatistics, x => x.fundsRegisterStatistics),
		};
	},
	[types.FETCH_CASHFLOW_FORECAST]: (action: AsyncAction<CashflowForecast>, state) => {
		return {
			cashflowForecast: checkAsyncAction(action, state.cashflowForecast),
		};
	},
	[types.FETCH_DETALIZATION_DATA]: (action: AsyncAction<FetchDetalizationDataReturnType>, state) => {
		return {
			detalization: {
				...state.detalization,
				plOperations: checkAsyncAction(action, state.detalization.plOperations, res => res.plOperations),
			},
		};
	},
	[types.SET_DETALIZATION_OPTIONS]: (action: StaticAction<DetalizationOptions>, state) => {
		return {
			detalization: {
				...state.detalization,
				plOperations: action.value === null ? createAsyncInitialState([]) : state.detalization.plOperations,
				options: action.value === null ? null : { ...action.value, excludingVat: !state.isIncludesVAT },
			},
		};
	},
	[types.SET_FILTER_BY_COMPANIES]: (action: StaticAction<Record<number, boolean>>, state) => {
		const hasFilter = action.value && Object.keys(action.value).length > 0;

		return {
			tenantEntityIdMap: hasFilter ? { ...action.value } : {},
			fundsRegisterIdMap: {},
			dashboardData: invalidateStateFromAction(action, state.dashboardData),
			aciSeries: invalidateStateFromAction(action, state.aciSeries),
			cpTop: invalidateStateFromAction(action, state.cpTop),
			fundsRegisterStatistics: invalidateStateFromAction(action, state.fundsRegisterStatistics),
			detalization: {
				plOperations: createAsyncInitialState([]),
				options: null,
			},
		};
	},
	[types.SET_FILTER_BY_FUNDS_REGISTERS]: (action: StaticAction<Record<number, boolean>>, state) => {
		return {
			fundsRegisterIdMap: action.value ? { ...action.value } : {},
			dashboardData: invalidateStateFromAction(action, state.dashboardData),
			aciSeries: invalidateStateFromAction(action, state.aciSeries),
			cpTop: invalidateStateFromAction(action, state.cpTop),
			fundsRegisterStatistics: invalidateStateFromAction(action, state.fundsRegisterStatistics),
			detalization: {
				plOperations: createAsyncInitialState([]),
				options: null,
			},
		};
	},
	[types.SET_FILTER_BY_BUSINESS_UNITS]: (action: StaticAction<Record<number, boolean>>, state) => {
		const hasFilter = action.value && Object.keys(action.value).length > 0;

		return {
			businessUnitIdMap: hasFilter ? { ...action.value } : {},
			dashboardData: invalidateStateFromAction(action, state.dashboardData),
			aciSeries: invalidateStateFromAction(action, state.aciSeries),
			cpTop: invalidateStateFromAction(action, state.cpTop),
			fundsRegisterStatistics: invalidateStateFromAction(action, state.fundsRegisterStatistics),
			detalization: {
				plOperations: createAsyncInitialState([]),
				options: null,
			},
		};
	},
	[types.SET_FILTER_BY_PROJECTS]: (action: StaticAction<Record<number, boolean>>, state) => {
		const hasFilter = action.value && Object.keys(action.value).length > 0;

		return {
			projectIdMap: hasFilter ? { ...action.value } : {},
			dashboardData: invalidateStateFromAction(action, state.dashboardData),
			aciSeries: invalidateStateFromAction(action, state.aciSeries),
			cpTop: invalidateStateFromAction(action, state.cpTop),
			fundsRegisterStatistics: invalidateStateFromAction(action, state.fundsRegisterStatistics),
			detalization: {
				plOperations: createAsyncInitialState([]),
				options: null,
			},
		};
	},
	[types.SET_SETTINGS_MODE]: (action: StaticAction<boolean>) => {
		return {
			isSettingsMode: action.value,
		};
	},
	[types.CHANGE_GRID_LAYOUT]: (action: StaticAction<Array<Layout>>) => {
		return {
			gridLayout: [...action.value],
		};
	},
	[types.SAVE_GRID_LAYOUT]: (action: StaticAction<Array<Layout>>) => {
		return {
			gridLayout: [...action.value],
			gridLayoutRestored: deepClone(action.value),
		};
	},
	[types.SET_TOUR_OPEN]: (action: StaticAction<boolean>) => {
		return {
			isTourOpen: action.value,
		};
	},
	[types.SET_IS_CARD_EXPANDED]: (action: StaticAction<Record<string, boolean>>) => {
		return {
			expanded: action.value,
		};
	},
	[types.SET_BUSINESS_UNIT_ANALYTICS_FILTER]: (action: StaticAction<CodeNaturalKey>, state) => {
		return {
			analytics: {
				...state.analytics,
				businessUnitFilter: action.value,
				projectFilter: null,
				cashflowItemFilter: null,
				counterpartyFilter: null,
			},
		};
	},
	[types.SET_PROJECT_ANALYTICS_FILTER]: (action: StaticAction<CodeNaturalKey>, state) => {
		return {
			analytics: {
				...state.analytics,
				businessUnitFilter: null,
				projectFilter: action.value,
				cashflowItemFilter: null,
				counterpartyFilter: null,
			},
		};
	},
	[types.SET_CAHSFLOW_ITEM_ANALYTICS_FILTER]: (action: StaticAction<CodeNaturalKey>, state) => {
		return {
			analytics: {
				...state.analytics,
				businessUnitFilter: null,
				projectFilter: null,
				cashflowItemFilter: action.value,
				counterpartyFilter: null,
			},
		};
	},
	[types.SET_COUNTERPARTY_ANALYTICS_FILTER]: (action: StaticAction<CodeNaturalKey>, state) => {
		return {
			analytics: {
				...state.analytics,
				businessUnitFilter: null,
				projectFilter: null,
				cashflowItemFilter: null,
				counterpartyFilter: action.value,
			},
		};
	},
	[types.FETCH_ANALYTICS_DETALIZATION_DATA]: (action: AsyncAction<FetchDetalizationDataReturnType>, state) => {
		return {
			analytics: {
				...state.analytics,
				plOperations: checkAsyncAction(action, state.analytics.plOperations, res => res.plOperations),
			},
		};
	},
	[types.SET_ANALYTICS_DETALIZATION_OPTIONS]: (action: StaticAction<DetalizationOptions>, state) => {
		const hasOptions = Boolean(action.value);

		return {
			analytics: {
				...state.analytics,
				options: action.value,
				businessUnitFilter: hasOptions ? state.analytics.businessUnitFilter : null,
				projectFilter: hasOptions ? state.analytics.projectFilter : null,
				cashflowItemFilter: hasOptions ? state.analytics.cashflowItemFilter : null,
				counterpartyFilter: hasOptions ? state.analytics.counterpartyFilter : null,
				plOperations: hasOptions ? state.analytics.plOperations : createAsyncInitialState([]),
			},
		};
	},
	[types.SET_ANALYTICS_DETALIZATION_IDS]: (action: StaticAction<Array<number>>, state) => {
		return {
			analytics: {
				...state.analytics,
				operationIDs: action.value,
			},
		};
	},
	[types.SET_IS_ANALYTICS_DETALIZATION_OPEN]: (action: StaticAction<boolean>, state) => {
		return {
			analytics: {
				...state.analytics,
				isDetalizationOpen: action.value,
			},
		};
	},
	[types.SET_IS_INCLUDES_VAT]: (action: StaticAction<boolean>, state) => {
		return {
			isIncludesVAT: action.value,
			dashboardData: invalidateStateFromAction(action, state.dashboardData),
			aciSeries: invalidateStateFromAction(action, state.aciSeries),
			cpTop: invalidateStateFromAction(action, state.cpTop),
			fundsRegisterStatistics: invalidateStateFromAction(action, state.fundsRegisterStatistics),
			cashflowForecast: invalidateStateFromAction(action, state.cashflowForecast),
			detalization: {
				...state.detalization,
				options:
					state.detalization.options === null ? null : { ...state.detalization.options, excludingVat: !action.value },
				plOperations:
					state.detalization.options === null
						? createAsyncInitialState([])
						: invalidateStateFromAction(action, state.detalization.plOperations),
			},
		};
	},
});

export default mainBplReducer;
