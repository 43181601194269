import { createTypes } from '@flux';

type ActionTypes = {
	RUN_INVALIDATION_EFFECT: string;
	INVALIDATE: string;
	FETCH: string;
	SET_SEARCH_TEXT: string;
	SET_MEASURE_UNITS: string;
	SET_UNSAVED_MEASURE_UNITS: string;
	SET_UNSAVED_MEASURE_UNITS_LENGTH: string;
	SET_LAST_MEASURE_UNIT_ID: string;
};

export const types = createTypes<ActionTypes>(
	[
		'RUN_INVALIDATION_EFFECT',
		'INVALIDATE',
		'FETCH',
		'SET_SEARCH_TEXT',
		'SET_MEASURE_UNITS',
		'SET_UNSAVED_MEASURE_UNITS',
		'SET_UNSAVED_MEASURE_UNITS_LENGTH',
		'SET_LAST_MEASURE_UNIT_ID',
	],
	'MEASURE_UNITS',
);
