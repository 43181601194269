import styled, { css } from 'styled-components';

import { textClip } from '@ui/basic-theme/styled-utils';
import * as c from '@ui/basic-theme/colors';

type SharedProps = {
	isActive: boolean;
};

const Root = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
`;

type ContentLayoutProps = {
	minHeight: number;
	noExtend: boolean;
	noClickable: boolean;
} & SharedProps;

const ContentLayout = styled.div<ContentLayoutProps>`
	position: relative;
	width: 100%;
	height: 100%;
	min-height: 60px;
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	padding: 8px 20px;
	border-bottom: 1px solid transparent;
	border-left: 1px solid transparent;
	border-right: 1px solid transparent;

	${p =>
		p.noClickable &&
		css`
			cursor: default !important;
		`}

	${p => css`
		background-color: ${p.theme.palette.space};
		border-color: ${p.theme.palette.stealth};
	`}

	${p =>
		p.isActive &&
		css`
			justify-content: space-between;
			z-index: 1;
			border: 1px solid ${p.theme.palette.accent};
			${p.theme.fn.createBoxShadow(2)}
		`};

	${p =>
		p.isActive &&
		!p.noExtend &&
		css`
			width: calc(100% + 20px);
			margin: 0 -10px;
		`};

	${p =>
		p.minHeight &&
		css`
			min-height: ${p.minHeight}px;
		`};
`;

type MainLayoutProps = {
	alignTop?: boolean;
	align?: 'flex-start' | 'center' | 'flex-end';
} & SharedProps;

const MainLayout = styled.div<MainLayoutProps>`
	width: 100%;
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: flex-start;

	${p =>
		(p.isActive || p.alignTop) &&
		css`
			align-items: flex-start;
		`};

	${p =>
		!p.isActive &&
		p.align &&
		css`
			align-items: ${p.align};
		`};
`;

const ControlsLayout = styled.div`
	width: 100%;
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-end;
	align-items: center;
	margin-top: 4px;
`;

const ButtonLayout = styled.div`
	display: flex;
	margin-left: 1px;

	&:first-child {
		margin-left: 0;
	}
`;

const IconLayout = styled.div`
	display: flex;
	flex-flow: column wrap;
	justify-content: center;
	align-items: center;
	margin-right: 20px;
`;

const ChekboxLayout = styled.div`
	width: 24px;
	height: 24px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const DetailLayout = styled.div`
	width: calc(80% - 50px);
`;

interface ILineRendererCellProps {
	isActive?: boolean;
	width?: string;
	minWidth?: string;
	flexAuto?: boolean;
	align?: 'flex-start' | 'center' | 'flex-end';
	alignIsActive?: 'flex-start' | 'center' | 'flex-end';
}

const LineRendererCell = styled.div`
	flex: 0 0 auto;
	width: ${(props: ILineRendererCellProps) => (props.width ? props.width : 'auto')};
	${(props: ILineRendererCellProps) =>
		props.flexAuto &&
		css`
			flex: 1 1 auto;
			overflow: hidden;
		`}

	${(props: ILineRendererCellProps) =>
		props.align &&
		css`
			align-self: ${props.align};
		`}

	${(props: ILineRendererCellProps) =>
		props.isActive &&
		props.alignIsActive &&
		css`
			align-self: ${props.alignIsActive};
		`}

	${(props: ILineRendererCellProps) =>
		props.minWidth &&
		css`
			min-width: ${props.minWidth};
		`}
`;

interface ILineRendererSign {
	isActive?: boolean;
	isMuted?: boolean;
	isInline?: boolean;
	textClip?: boolean;
	textRight?: boolean;
	textLeft?: boolean;
	withoutMargin?: boolean;
	withoutPadding?: boolean;
	isHidden?: boolean;
	onClick?: Function;
}

const LineRendererSign = styled.div<ILineRendererSign>`
	position: relative;
	font-size: 14px;
	line-height: 1.25;
	font-weight: 400;
	margin-bottom: 5px;
	padding-right: 10px;
	visibility: visible;
	transition: color 0.2s ease-in-out;

	${p =>
		p.isActive &&
		css`
			font-weight: 500;
		`}

	${p =>
		p.isMuted &&
		css`
			color: ${p.theme.palette.label};
		`}

	${p =>
		p.isInline &&
		css`
			display: inline-block;
			margin-right: 4px;
			margin-bottom: 0;
		`}

	${p =>
		p.textClip &&
		css`
			max-width: 100%;
			${textClip()}
		`}

	${p =>
		p.textRight &&
		css`
			text-align: right;
		`}

	${p =>
		p.withoutMargin &&
		css`
			margin: 0;
		`}

	${p =>
		p.withoutPadding &&
		css`
			padding: 0;
		`}

	${p =>
		p.isHidden &&
		css`
			position: absolute;
			visibility: hidden;
		`}

	${p =>
		p.onClick &&
		css`
			&:hover {
				color: ${p.theme.palette.accent};
			}
		`}
`;

const LineRendererValue = styled.div<ILineRendererSign>`
	font-size: 14px;
	line-height: 1.43;
	text-align: right;
	word-wrap: break-word;
	padding-left: 10px;

	${p =>
		p.isActive &&
		css`
			font-weight: 500;
		`}

	${p =>
		p.isMuted &&
		css`
			color: ${p.theme.palette.label};
		`}

	${p =>
		p.isInline &&
		css`
			display: inline-block;
			margin-right: 4px;
			margin-bottom: 0;
		`}

	${p =>
		p.textClip &&
		css`
			max-width: 100%;
			${textClip()}
		`}

	${p =>
		p.textLeft &&
		css`
			text-align: left;
		`}
`;

interface ILineRendererStatus {
	color: 'gray' | 'orange' | 'red' | 'green' | 'yellow';
}

const LineRendererStatus = styled.span`
	background-color: #e5e7e9;
	color: ${c.blackSecondary};
	font-size: 10px;
	font-weight: 500;
	text-align: center;
	padding: 3px 9px;
	text-transform: uppercase;

	${(props: ILineRendererStatus) =>
		props.color === 'gray' &&
		css`
			background-color: #e5e7e9;
			color: ${c.blackSecondary};
		`}

	${(props: ILineRendererStatus) =>
		props.color === 'orange' &&
		css`
			background-color: #ffcc01;
			color: ${c.blackPrimary};
		`}

	${(props: ILineRendererStatus) =>
		props.color === 'red' &&
		css`
			background-color: #ff3b48;
			color: #fff;
		`}

	${(props: ILineRendererStatus) =>
		props.color === 'green' &&
		css`
			background-color: ${c.accentPrimary};
			color: #fff;
		`}

	${(props: ILineRendererStatus) =>
		props.color === 'yellow' &&
		css`
			background-color: ${c.yellow300};
			color: ${c.blackPrimary};
		`}
`;

interface ILineRendererDocket {
	isActive?: boolean;
}

const LineRendererDocket = styled.div<ILineRendererDocket>`
	position: relative;
	max-height: 3rem;
	overflow: hidden;

	${p => css`
		&:after {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background-image: linear-gradient(to bottom, transparent 2rem, ${p.theme.palette.space});
			pointer-events: none;
		}
	`}

	${p =>
		p.isActive &&
		css`
			max-height: none;

			&:after {
				display: none;
			}
		`}
`;

type LineRendererDocketItemProps = {
	disabled?: boolean;
};

const LineRendererDocketItem = styled.button<LineRendererDocketItemProps>`
	display: inline-block;
	margin-right: 10px;
	text-align: left;
	transition: color 0.2s ease-in-out;
	background-color: transparent;
	cursor: pointer;
	outline: none;

	&:last-child {
		margin-right: 0;
	}

	${p =>
		!p.disabled &&
		css`
			&:hover {
				color: ${p.theme.palette.accent};
			}
		`}
`;

const LineRendererImage = styled.img`
	width: 30px;
	height: 30px;
	object-fit: cover;
`;

const ExtraContentLayout = styled.div`
	position: relative;
	width: 100%;
	margin-top: 10px;
`;

export {
	Root,
	ContentLayout,
	MainLayout,
	ControlsLayout,
	ButtonLayout,
	IconLayout,
	ChekboxLayout,
	DetailLayout,
	LineRendererCell,
	LineRendererSign,
	LineRendererValue,
	LineRendererStatus,
	LineRendererDocket,
	LineRendererDocketItem,
	LineRendererImage,
	ExtraContentLayout,
};
