import { MainCounterpartyType } from '@counterparties/models';
import { createTypes } from '@flux';

type ActionTypes = {
	ADD_DOCUMENT_TEMPLATE: string;
	FETCH_DOCUMENT_TEMPLATES: string;
	INVALIDATE_MODULE_DATA: string;
	REMOVE_DOCUMENT_TEMPLATE: string;
	SET_COUNTERPARTY_TYPE: MainCounterpartyType;
	SET_SEARCH_TEXT: string;
	UPDATE_DOCUMENT_TEMPLATE: string;
};

export const types = createTypes<ActionTypes>(
	[
		'ADD_DOCUMENT_TEMPLATE',
		'FETCH_DOCUMENT_TEMPLATES',
		'INVALIDATE_MODULE_DATA',
		'REMOVE_DOCUMENT_TEMPLATE',
		'SET_COUNTERPARTY_TYPE',
		'SET_SEARCH_TEXT',
		'UPDATE_DOCUMENT_TEMPLATE',
	],
	'DOCUMENT_TEMPLATES',
);
