import React, { useLayoutEffect } from 'react';

import { getDrawer } from '@utils';
import { DrawerTitle } from '@ui/drawer';
import { ProviderIcon } from '@ui/provider-icon';
import { DrawerZone } from '@funds-registers/models';
import { UpdateExternalSystemAccountForm } from '@integrations/components/update-external-system-account-form';

export type XCmsSignUpProps = {
	account: ExternalSystemAccount;
	selectedCMS: CashManagementSystem;
	onSetZone: React.Dispatch<React.SetStateAction<DrawerZone>>;
	onChangeAccount: (account: ExternalSystemAccount) => void;
};

const XCmsSignUp: React.FC<XCmsSignUpProps> = props => {
	const { account, selectedCMS, onSetZone, onChangeAccount } = props;

	useLayoutEffect(() => {
		getDrawer().setContent({
			title: (
				<DrawerTitle
					title={selectedCMS.Name}
					linkLabel='Назад'
					onNavigate={handleBack}
					icon={<ProviderIcon providerName={selectedCMS.Name} size={30} fallback='bank-account' />}
				/>
			),
		});
	}, []);

	const handleBack = () => onSetZone(DrawerZone.ACOUNTS);
	const handleClose = () => getDrawer().close();
	const handleAddAccount = (account: ExternalSystemAccount) => onChangeAccount(account);
	const ssiGUID = selectedCMS.SubsystemInstanceGUID;

	if (account) {
		return (
			<UpdateExternalSystemAccountForm
				variant='edit'
				ssiGUID={ssiGUID}
				accountID={account.ID}
				embedded
				onClose={handleClose}
			/>
		);
	}

	return (
		<UpdateExternalSystemAccountForm
			variant='add'
			ssiGUID={ssiGUID}
			embedded
			onAddAccount={handleAddAccount}
			onClose={handleClose}
		/>
	);
};

export { XCmsSignUp };
