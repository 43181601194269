import React from 'react';
import { connect } from 'react-redux';

import { IAppState } from '@store';
import { selectTenant } from '../../../../selectors/tenant-account.selectors';
import XBillingPreview, { BillingPreviewProps as XBillingPreviewProps } from './billing-preview.view';

type BillingPreviewProps = XBillingPreviewProps;

class BillingPreview extends React.Component<BillingPreviewProps> {
	static displayName = 'BillingPreview[Container]';
	static defaultProps = {
		onSetRef: () => {},
	};

	render() {
		return <XBillingPreview ref={this.props.onSetRef} {...(this.props as any)} />;
	}
}

function mapStateToProps(state: IAppState) {
	return {
		tenant: selectTenant(state),
	};
}

export default connect<any, any, BillingPreviewProps>(mapStateToProps)(BillingPreview);
