import React from 'react';
import { Dispatch, bindActionCreators } from 'redux';

import { IAppState } from '../../../../../../../core/store';
import withAutoUpdate from '../../../../../../../core/hocs/with-auto-update.hoc';
import { initFastBillEffectAction } from '../../../../../actions/billing.actions';
import * as billingSelectors from '../../../../../selectors/billing.selectors';
import { selectTenant } from '../../../../../selectors/tenant-account.selectors';
import XFastBillForm, { FastBillFormProps as XFastBillFormProps } from './fast-bill-form.view';
import { mainFundsRegistersActionsPack } from '@funds-registers/actions';
import { selectAsyncFundsRegisterStatistics, selectFundsRegisters } from '@funds-registers/selectors';

interface IProps extends Partial<XFastBillFormProps> {
	fetchFundsRegisters?: () => void;
}

class FastBillForm extends React.Component<IProps> {
	static displayName = 'FastBillForm[Container]';

	render() {
		return <XFastBillForm {...(this.props as any)} />;
	}
}

function mapStateToProps(state: IAppState) {
	return {
		tenant: selectTenant(state),
		tenantBills: billingSelectors.selectTenantBills(state),
		status: billingSelectors.selectStatusAfterBilling(state),
		frList: selectFundsRegisters(state),
		CMSPaymentResponse: billingSelectors.selectCMSPaymentResponse(state),
		isFetching: billingSelectors.selectCMSPaymentResponseIsFetching(state),
	};
}

function mapDispatchToProps(dispatch: Dispatch<any>) {
	return bindActionCreators(
		{
			initFastBillEffect: initFastBillEffectAction,
			fetchFundsRegisters: mainFundsRegistersActionsPack.fetchFundsRegisterStatistics,
		},
		dispatch,
	);
}

export default withAutoUpdate<IProps>(
	mapStateToProps,
	mapDispatchToProps,
)({
	frDidInvalidate: [selectAsyncFundsRegisterStatistics.selectDidInvalidate, props => props.fetchFundsRegisters()],
})(FastBillForm);
