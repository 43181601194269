import React from 'react';

import { Box } from '@ui/box';
import { Checkbox } from '@ui/checkbox';
import { PlusIcon } from '@ui/icons/plus';
import { MinusIcon } from '@ui/icons/minus';

import { ItemSelectRendererProps } from './';

export default function <S extends { ID: number }>(props: ItemSelectRendererProps<S>) {
	const renderIcon = () => {
		if (!props.icon) return null;

		return props.icon.type === 'add' ? (
			<PlusIcon color='positive' size={22} className='items-list-repeater__icon' />
		) : (
			<MinusIcon color='negative' size={22} className='items-list-repeater__icon' />
		);
	};

	return (
		<Box display='flex' justifyContent='center' alignItems='center'>
			{renderIcon()}
			<Checkbox
				onClick={e => e.stopPropagation()}
				onCheck={(e, isInputChecked) => {
					props.selectHandler(props.item.ID, isInputChecked);
				}}
				checked={props.checked}
				className={'items-list-repeater__checkbox' + (props.icon ? ' items-list-repeater__checkbox_hidden' : '')}
			/>
		</Box>
	);
}
