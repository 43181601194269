import styled, { css } from 'styled-components';

type RootProps = {
	isOpen: boolean;
	topShift: number;
};

const Root = styled.div<RootProps>`
	position: fixed;
	left: 80px;
	width: 210px;
	box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.08);
	transition: all 0.2s ease-in-out;
	transform: ${p => (p.isOpen ? 'translate(0, 0)' : 'translate(-290px, 0)')};

	& ::-webkit-scrollbar {
		width: 4px;
		height: 4px;
	}

	${p => css`
		top: ${70 + p.topShift}px;
		height: calc(100% - ${70 + p.topShift}px);
		background-color: ${p.theme.palette.space};
	`}
`;

const ContentLayout = styled.div`
	position: relative;
	width: 100%;
	height: auto;
	max-height: calc(100% - 58px);
	overflow-y: auto;
	overflow-x: hidden;
`;

const AccountItemLayout = styled.div`
	position: relative;
	width: 100%;
	height: auto;
`;

const CloseButtonLayout = styled.div`
	position: absolute;
	bottom: 0;
	width: 100%;
`;

export { Root, ContentLayout, AccountItemLayout, CloseButtonLayout };
