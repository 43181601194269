import React from 'react';

import { MobileAppsButton } from '../mobile-apps-button';
import { Root, KPIComponentLayout } from './styled';

export type AppKpiProps = {
	KPIComponent: React.ComponentType<any>;
	sessionContext: ClientSessionContext;
	renderLayout: boolean;
};

const AppKpi: React.FC<AppKpiProps> = props => {
	const { KPIComponent, sessionContext, renderLayout } = props;
	const availableComponents = sessionContext ? sessionContext.AvailableComponents || [] : [];
	const needRenderButton = renderLayout;

	return (
		<Root>
			{KPIComponent && (
				<KPIComponentLayout>
					<KPIComponent
						availableComponents={availableComponents}
						userRights={sessionContext ? sessionContext.UserRights || [] : []}
					/>
				</KPIComponentLayout>
			)}
			{needRenderButton && <MobileAppsButton />}
		</Root>
	);
};

export { AppKpi };
