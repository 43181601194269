import React from 'react';

import { DisplayController, DisplayStep } from '@ui/display-controller';
import { pathTo } from './paths';
import SelectConditionsDisplay from '../billing-displays/select-conditions-display';
import SelectTypePayerDisplay from '../billing-displays/select-type-payer-display';
import PersonPaymentResultDisplay from '../billing-displays/person-payment-result-display';
import LegalEntityPaymentResultDisplay from '../billing-displays/legal-entity-payment-result-display';

interface IProps {
	path: string;

	setPath: (path: string) => void;
	resetPath: () => void;
}

class BillingDisplayController extends React.PureComponent<IProps> {
	static displayName = 'BillingDisplayController';

	render() {
		const {
			path,

			setPath,
			resetPath,
		} = this.props;

		return (
			<DisplayController currentPath={path}>
				<DisplayStep path={pathTo.selectConditions}>
					<SelectConditionsDisplay />
				</DisplayStep>
				<DisplayStep path={pathTo.selectTypePayer}>
					<SelectTypePayerDisplay />
				</DisplayStep>
				<DisplayStep path={pathTo.personPaymentResult}>
					<PersonPaymentResultDisplay />
				</DisplayStep>
				<DisplayStep path={pathTo.legalEntityPaymentResult}>
					<LegalEntityPaymentResultDisplay />
				</DisplayStep>
				<DisplayStep path={pathTo.legalEntityPaymentResultWithCheck}>
					<LegalEntityPaymentResultDisplay withCheck />
				</DisplayStep>
			</DisplayController>
		);
	}
}

export default BillingDisplayController;
