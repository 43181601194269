export type SetAppProgressBarOptions = {
	isOpen: boolean;
	value: number;
	text?: string;
};

export enum SettingsKey {
	THEME_MODE = 'theme_mode',
	THEME_BRAND = 'theme_brand',
}
