import React from 'react';

import { SegmentedListProps } from '@ui/segmented-list';
import { FormContextProps, withFormComponent } from '../component';
import { FormState } from '../form';

type withFormSegmentedListProps<S = any, T = any> = {
	name: string;
	value?: Array<T>;
	required?: boolean;
	onChange?: (e: React.SyntheticEvent<{}>, value: Array<T>) => void;
} & Partial<S> &
	Partial<SegmentedListProps<T>>;

type EnhancedComponentState = {
	errorText: string;
};

function withFormSegmentedList<S, T>(
	WrappedComponent: React.ComponentType<S>,
): React.ComponentType<withFormSegmentedListProps<S, T>> {
	class EnhancedComponent extends React.Component<
		FormContextProps<any> & withFormSegmentedListProps<S, T>,
		EnhancedComponentState
	> {
		static displayName = `withForm[HOC](${WrappedComponent.displayName || WrappedComponent.name})`;
		state: EnhancedComponentState = {
			errorText: '',
		};
		static defaultProps = {
			dataSourceConfig: {
				value: 'value',
				text: 'text',
			},
		};

		componentDidMount() {
			this.props.addFormValidation(this.validateField);
		}

		componentWillUnmount() {
			this.props.removeFormValidation(this.validateField);
		}

		validateField = (state: FormState<S>): boolean => {
			const { name, required } = this.props;
			const value: Array<T> = getValueByName(name, state.formObject) || [];
			const isValid = required ? value.length > 0 : true;

			if (!isValid) {
				this.setState({
					errorText: 'Выберите элементы',
				});
			}

			return isValid;
		};

		handleChange = (e: React.MouseEvent<{}>, item: Array<T>) => {
			const { name, formObject, onChange, handleObjectChange, dataSourceConfig } = this.props;
			const isList = Array.isArray(item);
			const value = isList ? item : item[dataSourceConfig.value];

			setValueByName(value, name, formObject);
			handleObjectChange(formObject);
			typeof onChange === 'function' && onChange(e, value);
			const { errorText } = this.state;

			if (errorText) {
				this.setState({ errorText: '' });
			}
		};

		render() {
			const {
				name,
				formObject,
				editedBriefObjects,
				isFetching,
				required,
				handleObjectChange,
				addFormValidation,
				removeFormValidation,
				submitForm,
				resetForm,
				validateForm,
				getBriefObject,
				addBriefObject,
				deleteBriefObject,
				handleBriefObjectChange,
				...rest
			} = this.props;
			const { errorText } = this.state;
			const value = getValueByName(name, formObject);

			return <WrappedComponent {...(rest as any)} value={value} errorText={errorText} onChange={this.handleChange} />;
		}
	}

	return withFormComponent<withFormSegmentedListProps>(EnhancedComponent);
}

function setValueByName(value: any, name: string, formObject: any) {
	formObject[name] = value;
}

function getValueByName(name: string, formObject: any) {
	return formObject[name];
}

export default withFormSegmentedList;
