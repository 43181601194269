import { ImportStandardDataOptions } from '@core/api/data';
import { createAsyncAction, createStaticAction, createTypes } from '@flux';
import { ServiceTaskKey } from '@initialization/components/service-tasks/model';
import { AboutCompanyPreferences, SettingsKey } from '@initialization/models';
import { initializationSelectorsPack } from '@initialization/selectors';

type ActionTypes = {
	FETCH_ABOUT_COMPANY_PREFERENCES: string;
	FETCH_SERVICE_FEATURES: string;
	IMPORT_STANDARD_DATA: string;
	INITIALIZE_APPLICATION_BY_PROFILE: string;
	SET_IMPORT_DATA_IN_BACKGROUND_IN_PROCESS: string;
	SET_IMPORT_DATA_IN_BACKGROUND_IS_COMPLETE: string;
	SET_INITIALIZATION_IN_PROCESS: string;
	SET_INITIALIZATION_IS_COMPLETE: string;
	SET_SETTINGS: string;
	START_INITIALIZATION_EFFECT: string;
};

const types = createTypes<ActionTypes>(
	[
		'FETCH_ABOUT_COMPANY_PREFERENCES',
		'FETCH_SERVICE_FEATURES',
		'IMPORT_STANDARD_DATA',
		'INITIALIZE_APPLICATION_BY_PROFILE',
		'SET_IMPORT_DATA_IN_BACKGROUND_IN_PROCESS',
		'SET_IMPORT_DATA_IN_BACKGROUND_IS_COMPLETE',
		'SET_INITIALIZATION_IN_PROCESS',
		'SET_INITIALIZATION_IS_COMPLETE',
		'SET_SETTINGS',
		'START_INITIALIZATION_EFFECT',
	],
	'INITIALIZATION',
);

const actions = {
	fetchAboutCompanyPreferences: createAsyncAction(
		types.FETCH_ABOUT_COMPANY_PREFERENCES,
		(api, _, __, tenantID: number) => {
			return new Promise<AboutCompanyPreferences>(resolve => {
				const { ACCOUNTING_CURRENCY_ID, COMPANY_ACTIVITIES, EMPLOYEE_QUANTITY, TENANT_LEGAL_DATA } = SettingsKey;
				const keys = [ACCOUNTING_CURRENCY_ID, COMPANY_ACTIVITIES, EMPLOYEE_QUANTITY, TENANT_LEGAL_DATA];

				api.preferencesPack.rest.fetchPreferences(tenantID, keys).then(result => {
					resolve({
						[ACCOUNTING_CURRENCY_ID]: result[ACCOUNTING_CURRENCY_ID]?.value,
						[COMPANY_ACTIVITIES]: result[COMPANY_ACTIVITIES]?.value,
						[EMPLOYEE_QUANTITY]: result[EMPLOYEE_QUANTITY]?.value,
						[TENANT_LEGAL_DATA]: result[TENANT_LEGAL_DATA]?.value,
					});
				});
			});
		},
		{
			isValidSelector: initializationSelectorsPack.selectAsyncAboutCompanyPreferences.selectIsValid,
			isFetchingSelector: initializationSelectorsPack.selectAsyncAboutCompanyPreferences.selectIsFetching,
		},
	) as (tenantID: number) => void,
	fetchServiceFeatures: createAsyncAction(
		types.FETCH_SERVICE_FEATURES,
		(api, _, __, tenantID: number) => {
			return new Promise<Array<ServiceTaskKey>>(resolve => {
				const key = SettingsKey.SERVICE_FEATURES;

				api.preferencesPack.rest.fetchPreferences(tenantID, [key]).then(result => {
					resolve(result[key]?.value || []);
				});
			});
		},
		{
			isValidSelector: initializationSelectorsPack.selectAsyncServiceFeatures.selectIsValid,
			isFetchingSelector: initializationSelectorsPack.selectAsyncServiceFeatures.selectIsFetching,
		},
	) as (tenantID: number) => void,
	importStandardData: createAsyncAction(
		types.IMPORT_STANDARD_DATA,
		(api, _, __, options: ImportStandardDataOptions) => {
			return new Promise<boolean>(resolve => {
				const timeout = 5 * 1000;
				const { withDemoDataImport } = options;

				api.dataPack.tenantStandardData.importStandardData(options).then(success => {
					if (success) {
						const makeRequest = () => {
							api.profilePack.tenantProfile.fetchTenantProfile().then(tenantProfile => {
								const ok =
									tenantProfile.HasReferenceData && (withDemoDataImport ? tenantProfile.HasStandardData : true);

								if (ok) {
									resolve(true);
								} else {
									setTimeout(() => makeRequest(), timeout);
								}
							});
						};

						makeRequest();
					} else {
						resolve(false);
					}
				});
			});
		},
	) as (options: ImportStandardDataOptions) => any,
	initializeApplicationByProfile: createAsyncAction(types.INITIALIZE_APPLICATION_BY_PROFILE, api => {
		return new Promise<SuccessMessage>(resolve => {
			api.profilePack.tenantProfile.initializeApplicationByProfile().then(result => {
				resolve(result);
			});
		});
	}),
	setImportDataInBackgroundInProcess: (inProcess: boolean) =>
		createStaticAction(types.SET_IMPORT_DATA_IN_BACKGROUND_IN_PROCESS)(inProcess),
	setImportDataInBackgroundIsComplete: (isComplete: boolean) =>
		createStaticAction(types.SET_IMPORT_DATA_IN_BACKGROUND_IS_COMPLETE)(isComplete),
	setInitializationInProcess: (inProcess: boolean) =>
		createStaticAction(types.SET_INITIALIZATION_IN_PROCESS)(inProcess),
	setInitializationIsComplete: (isComplete: boolean) =>
		createStaticAction(types.SET_INITIALIZATION_IS_COMPLETE)(isComplete),
	setSettings: createAsyncAction(types.SET_SETTINGS, (api, _, __, options: SetSettingsOptions) => {
		const { onComplete, settingsMap, tenantID } = options;

		return new Promise<void>(resolve => {
			api.preferencesPack.rest.savePreferences(tenantID, settingsMap).then(() => {
				onComplete();
				resolve();
			});
		});
	}) as (options: SetSettingsOptions) => void,
	startInitializationEffect: (options: StartInitializationOptions) =>
		createStaticAction(types.START_INITIALIZATION_EFFECT)(options),
};

export type SetSettingsOptions = {
	onComplete: () => void;
	settingsMap: Record<string, any>;
	tenantID: number;
};

export type StartInitializationOptions = {
	onError: (msg: string) => void;
	onSuccess: () => void;
	withDemoDataImport: boolean;
	withHistoricalDataImport: boolean;
};

export const initializationActionsPack = {
	actions,
	types,
};
