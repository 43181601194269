import React, { memo, forwardRef } from 'react';

import { Spinner, SpinnerProps } from '@ui/spinner';
import { Root, Cover, UpdatingIndicator, IndicatorLayout, DefaultFallback } from './styled';

export type SoftFetchingProps = {
	variant?: 'fixed-indicator';
	isFetching: boolean;
	isLoaded?: boolean;
	isSilent?: boolean;
	SpinnerProps?: Partial<Pick<SpinnerProps, 'size' | 'thickness' | 'color'>>;
	IndicatorLayoutProps?: Partial<IndicatorLayoutProps>;
	renderFallback?: () => React.ReactNode;
	children: React.ReactNode;
};

const SoftFetching = memo(
	forwardRef<HTMLDivElement, SoftFetchingProps>((props, ref) => {
		const {
			isFetching,
			isLoaded = true,
			isSilent,
			variant,
			renderFallback = () => <DefaultFallback />,
			children,
			SpinnerProps = {},
			IndicatorLayoutProps = {},
		} = props;
		const isCoverActive = isSilent ? false : !isLoaded || isFetching;

		const renderIndicatorLayout = (children: React.ReactNode) => {
			const isFixed = variant === 'fixed-indicator';

			return (
				<IndicatorLayout isFixed={isFixed} {...IndicatorLayoutProps}>
					{children}
				</IndicatorLayout>
			);
		};

		const renderIndicator = () => {
			if (isSilent) return null;
			if (!isLoaded) return renderIndicatorLayout(<Spinner {...SpinnerProps} />);
			if (isFetching) return renderIndicatorLayout(<UpdatingIndicator />);

			return null;
		};

		return (
			<Root ref={ref}>
				<Cover isCoverActive={isCoverActive}>
					{isLoaded ? <Content isFetching={isFetching}>{children}</Content> : renderFallback()}
				</Cover>
				{renderIndicator()}
			</Root>
		);
	}),
);

type ContentProps = {
	isFetching: boolean;
	children: React.ReactNode;
};

const Content: React.FC<ContentProps> = memo(
	({ children }) => {
		return <>{children}</>;
	},
	(_, nextProps) => {
		if (nextProps.isFetching) return true;

		return false;
	},
);

type IndicatorLayoutProps = {
	style: React.CSSProperties;
};

export { SoftFetching };
