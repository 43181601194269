import styled, { css } from 'styled-components';

type RootProps = {
	padding: string;
	flexCenter?: boolean;
	fullMode?: boolean;
};

const Root = styled.div<RootProps>`
	position: relative;
	width: 100%;
	min-height: 90px;
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	align-items: center;
	padding: 0 40px;

	${p =>
		p.padding &&
		css`
			padding: ${p.padding};
		`}

	${p =>
		p.flexCenter &&
		css`
			justify-content: center;
		`}

	${p =>
		p.fullMode &&
		css`
			min-height: 50px;
			padding: 0;
		`}
`;

const ButtonLayout = styled.div``;

const CheckboxLayout = styled.div`
	width: 100%;
`;

const actionButtonStyle = {
	minWidth: '176px',
};

const buttonStyle = {
	minWidth: '160px',
};

const highlightStyle: React.CSSProperties = {
	fontWeight: 500,
	textTransform: 'uppercase',
};

export { Root, ButtonLayout, CheckboxLayout, actionButtonStyle, buttonStyle, highlightStyle };
