import { createAsyncAction, createStaticAction } from '@flux';
import { mainMeasureUnitsSelectorsPack } from '@measure-units/selectors';
import { types } from './types';

const actions = {
	setSearchText: (value: string) => createStaticAction(types.SET_SEARCH_TEXT)(value),
	setMeasureUnits: (value: Array<MeasureUnit>) => createStaticAction(types.SET_MEASURE_UNITS)(value),
	setUnsavedMeasureUnits: (value: Array<MeasureUnit>) => createStaticAction(types.SET_UNSAVED_MEASURE_UNITS)(value),
	setUnsavedMeasureUnitsLength: (value: number) => createStaticAction(types.SET_UNSAVED_MEASURE_UNITS_LENGTH)(value),
	setLastMeasureUnitID: (value: number) => createStaticAction(types.SET_LAST_MEASURE_UNIT_ID)(value),
	fetchMeasureUnits: createAsyncAction(
		types.FETCH,
		api => {
			return new Promise<Array<MeasureUnit>>(resolve => {
				api.referencePack.measureUnit.fetchMeasureUnits().then(result => {
					resolve(result);
				});
			});
		},
		{
			isValidSelector: mainMeasureUnitsSelectorsPack.selectAsyncMeasureUnits.selectIsValid,
			isFetchingSelector: mainMeasureUnitsSelectorsPack.selectAsyncMeasureUnits.selectIsFetching,
		},
	) as () => void,
};

export const mainMeasureUnitsActionsPack = actions;
