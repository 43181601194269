/***
 * Library contains JavaScript API for interaction with Seeneco Framework Web Services contained in ProcessPackage
 * @author AlexPlex Generated by Seeneco Framework Modeler 
 *
 */
(function(w){
/***
* Contains information about instance of activity running in some process
*/
w.ActivityInstance = function() {
  this.PACKAGE = "PROCESS";
  this.CLASSIFIER = "ACTIVITY_INSTANCE";
  this.CopySupportingElement = false;
  this.StatusCode = "";
  this.StatusDescription = "";
  this.ID = -1;
  this.ResultMessage = "";
  this.StartDateTime = null;
  this.FinishDateTime = null;
  this.Activity = null;
  this.FinishedWithWarnings = false;
  this.LogEntries = [];
  this.setActivity = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.Activity = ref;
  };
};
/***
* Entry of a log that is created in the process of executing an activity and provided to end user
*/
w.ActivityInstanceLogEntry = function() {
  this.PACKAGE = "PROCESS";
  this.CLASSIFIER = "ACTIVITY_INSTANCE_LOG_ENTRY";
  this.CopySupportingElement = false;
  this.Message = "";
};
/***
* Set of activity-specific settings affecting activity execution logic. These settings are not defined in process model, but configured when process is deployed
*/
w.ActivitySettings = function() {
  this.PACKAGE = "PROCESS";
  this.CLASSIFIER = "ACTIVITY_SETTINGS";
  this.CopySupportingElement = false;
  this.SkipExecution = false;
  this.ActivityID = -1;
};
/***
* Activity executing data mark-up by a set of standard or tenant-specific rules
*/
w.DataMarkupActivity = function() {
  this.PACKAGE = "PROCESS";
  this.CLASSIFIER = "DATA_MARKUP_ACTIVITY";
  this.CopySupportingElement = false;
  this.DataMarkupBundle = null;
  this.Settings = [];
  this.DiagramElement = null;
  this.IncomingSignals = [];
  this.OutgoingSignals = [];
  this.SystemName = "";
  this.Brief = "";
  this.Name = "";
  this.Description = "";
  this.GUID = "";
  this.setDataMarkupBundle = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.DataMarkupBundle = ref;
  };
  this.setIncomingSignals = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.IncomingSignals = ref;
  };
  this.setOutgoingSignals = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.OutgoingSignals = ref;
  };
};
/***
* Activity executing SQL statement (query or DML statement) against database of 
business application
*/
w.ExecuteSQLActivity = function() {
  this.PACKAGE = "PROCESS";
  this.CLASSIFIER = "EXECUTE_SQLACTIVITY";
  this.CopySupportingElement = false;
  this.SQLQuery = null;
  this.Settings = [];
  this.DiagramElement = null;
  this.IncomingSignals = [];
  this.OutgoingSignals = [];
  this.SystemName = "";
  this.Brief = "";
  this.Name = "";
  this.Description = "";
  this.GUID = "";
  this.setSQLQuery = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.SQLQuery = ref;
  };
  this.setIncomingSignals = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.IncomingSignals = ref;
  };
  this.setOutgoingSignals = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.OutgoingSignals = ref;
  };
};
/***
* Activity defining execution of subprocess
*/
w.ExecuteSubProcessActivity = function() {
  this.PACKAGE = "PROCESS";
  this.CLASSIFIER = "EXECUTE_SUB_PROCESS_ACTIVITY";
  this.CopySupportingElement = false;
  this.Process = null;
  this.Settings = [];
  this.DiagramElement = null;
  this.IncomingSignals = [];
  this.OutgoingSignals = [];
  this.SystemName = "";
  this.Brief = "";
  this.Name = "";
  this.Description = "";
  this.GUID = "";
  this.setProcess = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.Process = ref;
  };
  this.setIncomingSignals = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.IncomingSignals = ref;
  };
  this.setOutgoingSignals = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.OutgoingSignals = ref;
  };
};
/***
* Node marking finish of process execution
*/
w.FinishNode = function() {
  this.PACKAGE = "PROCESS";
  this.CLASSIFIER = "FINISH_NODE";
  this.CopySupportingElement = false;
  this.DiagramElement = null;
  this.IncomingSignals = [];
  this.OutgoingSignals = [];
  this.SystemName = "";
  this.Brief = "";
  this.Name = "";
  this.Description = "";
  this.GUID = "";
  this.setIncomingSignals = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.IncomingSignals = ref;
  };
  this.setOutgoingSignals = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.OutgoingSignals = ref;
  };
};
/***
* Defines a node in a pageflow process that show a {@link UIForm} to the user
*/
w.FormShowNode = function() {
  this.PACKAGE = "PROCESS";
  this.CLASSIFIER = "FORM_SHOW_NODE";
  this.CopySupportingElement = false;
  this.UIForm = null;
  this.DiagramElement = null;
  this.IncomingSignals = [];
  this.OutgoingSignals = [];
  this.SystemName = "";
  this.Brief = "";
  this.Name = "";
  this.Description = "";
  this.GUID = "";
  this.setUIForm = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.UIForm = ref;
  };
  this.setIncomingSignals = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.IncomingSignals = ref;
  };
  this.setOutgoingSignals = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.OutgoingSignals = ref;
  };
};
/***
* Defines Import file activity
*/
w.ImportFileActivity = function() {
  this.PACKAGE = "PROCESS";
  this.CLASSIFIER = "IMPORT_FILE_ACTIVITY";
  this.CopySupportingElement = false;
  this.ImportTemplate = null;
  this.Settings = [];
  this.DiagramElement = null;
  this.IncomingSignals = [];
  this.OutgoingSignals = [];
  this.SystemName = "";
  this.Brief = "";
  this.Name = "";
  this.Description = "";
  this.GUID = "";
  this.setImportTemplate = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.ImportTemplate = ref;
  };
  this.setIncomingSignals = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.IncomingSignals = ref;
  };
  this.setOutgoingSignals = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.OutgoingSignals = ref;
  };
};
/***
* Settings for import file activity defining upload method, file reading algorithm and other file processing specifics.
*/
w.ImportFileActivitySettings = function() {
  this.PACKAGE = "PROCESS";
  this.CLASSIFIER = "IMPORT_FILE_ACTIVITY_SETTINGS";
  this.CopySupportingElement = false;
  this.Autoupload = false;
  this.UploadPath = "";
  this.RecordDelimiter = "";
  this.SkipExecution = false;
  this.ActivityID = -1;
};
/***
* Defines a basic node concept in a pageflow process
*/
w.PageflowNode = function() {
  this.PACKAGE = "PROCESS";
  this.CLASSIFIER = "PAGEFLOW_NODE";
  this.CopySupportingElement = false;
  this.DiagramElement = null;
  this.IncomingSignals = [];
  this.OutgoingSignals = [];
  this.SystemName = "";
  this.Brief = "";
  this.Name = "";
  this.Description = "";
  this.GUID = "";
  this.setIncomingSignals = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.IncomingSignals = ref;
  };
  this.setOutgoingSignals = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.OutgoingSignals = ref;
  };
};
/***
* Process defining application UI form transition logic
*/
w.PageflowProcess = function() {
  this.PACKAGE = "PROCESS";
  this.CLASSIFIER = "PAGEFLOW_PROCESS";
  this.CopySupportingElement = false;
  this.Nodes = [];
  this.Signals = [];
  this.Module = null;
  this.ContainerPackage = null;
  this.SystemName = "";
  this.Brief = "";
  this.Name = "";
  this.Description = "";
  this.GUID = "";
  this.setModule = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.Module = ref;
  };
  this.setContainerPackage = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.ContainerPackage = ref;
  };
};
/***
* Defines a basic concept of a process.
*/
w.Process = function() {
  this.PACKAGE = "PROCESS";
  this.CLASSIFIER = "PROCESS";
  this.CopySupportingElement = false;
  this.Nodes = [];
  this.Signals = [];
  this.Module = null;
  this.ContainerPackage = null;
  this.SystemName = "";
  this.Brief = "";
  this.Name = "";
  this.Description = "";
  this.GUID = "";
  this.setModule = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.Module = ref;
  };
  this.setContainerPackage = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.ContainerPackage = ref;
  };
};
/***
* Process node executing some activity in process
*/
w.ProcessActivity = function() {
  this.PACKAGE = "PROCESS";
  this.CLASSIFIER = "PROCESS_ACTIVITY";
  this.CopySupportingElement = false;
  this.Settings = [];
  this.DiagramElement = null;
  this.IncomingSignals = [];
  this.OutgoingSignals = [];
  this.SystemName = "";
  this.Brief = "";
  this.Name = "";
  this.Description = "";
  this.GUID = "";
  this.setIncomingSignals = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.IncomingSignals = ref;
  };
  this.setOutgoingSignals = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.OutgoingSignals = ref;
  };
};
/***
* Task for execution of a process that could be be invoked by some external routine.
This routine can invoke multiple simultaneous tasks by priorities
*/
w.ProcessExecutionTask = function() {
  this.PACKAGE = "PROCESS";
  this.CLASSIFIER = "PROCESS_EXECUTION_TASK";
  this.CopySupportingElement = false;
  this.Process = null;
  this.Priority = -1;
  this.GUID = "";
  this.setProcess = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.Process = ref;
  };
};
/***
* 
*/
w.ProcessExecutionTaskInstance = function() {
  this.PACKAGE = "PROCESS";
  this.CLASSIFIER = "PROCESS_EXECUTION_TASK_INSTANCE";
  this.CopySupportingElement = false;
  this.ProcessInstance = null;
  this.StatusCode = "";
  this.StatusDescription = "";
  this.ID = -1;
  this.StartDateTime = null;
  this.FinishDateTime = null;
  this.Task = null;
  this.setTask = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.Task = ref;
  };
};
/***
* Contains information about started instance of a {@link Process}
*/
w.ProcessInstance = function() {
  this.PACKAGE = "PROCESS";
  this.CLASSIFIER = "PROCESS_INSTANCE";
  this.CopySupportingElement = false;
  this.StatusCode = "";
  this.StatusDescription = "";
  this.ID = -1;
  this.InstanceDescription = "";
  this.ActivityInstances = [];
  this.StartDateTime = null;
  this.FinishDateTime = null;
  this.FinishedWithWarnings = false;
  this.Process = null;
  this.setProcess = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.Process = ref;
  };
};
/***
* Brief representation of info for process instance
*/
w.ProcessInstanceInfo = function() {
  this.PACKAGE = "PROCESS";
  this.CLASSIFIER = "PROCESS_INSTANCE_INFO";
  this.CopySupportingElement = false;
  this.StatusCode = "";
  this.StatusDescription = "";
  this.ID = -1;
  this.InstanceDescription = "";
  this.StartDateTime = null;
  this.FinishDateTime = null;
  this.FinishedWithWarnings = false;
  this.ProcessName = "";
  this.UserStartedProcess = "";
  this.TenantStartedProcess = "";
  this.TenantGUID = "";
  this.TenantID = -1;
};
/***
* Request for obtaining information about process instances
*/
w.ProcessInstanceRequest = function() {
  this.PACKAGE = "PROCESS";
  this.CLASSIFIER = "PROCESS_INSTANCE_REQUEST";
  this.CopySupportingElement = false;
  this.TenantID = -1;
  this.ProcessID = -1;
  this.StartDateFrom = null;
  this.StartDateTo = null;
  this.StatusCode = "";
};
/***
* Defines a basic abstract concept of a process node
*/
w.ProcessNode = function() {
  this.PACKAGE = "PROCESS";
  this.CLASSIFIER = "PROCESS_NODE";
  this.CopySupportingElement = false;
  this.DiagramElement = null;
  this.IncomingSignals = [];
  this.OutgoingSignals = [];
  this.SystemName = "";
  this.Brief = "";
  this.Name = "";
  this.Description = "";
  this.GUID = "";
  this.setIncomingSignals = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.IncomingSignals = ref;
  };
  this.setOutgoingSignals = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.OutgoingSignals = ref;
  };
};
/***
* Defines a concept of process signal - element that connects to {@link ProcessNode} 
objects
*/
w.ProcessSignal = function() {
  this.PACKAGE = "PROCESS";
  this.CLASSIFIER = "PROCESS_SIGNAL";
  this.CopySupportingElement = false;
  this.SystemName = "";
  this.Brief = "";
  this.Name = "";
  this.Description = "";
  this.GUID = "";
};
/***
* 
*/
w.RegisterEventActivity = function() {
  this.PACKAGE = "PROCESS";
  this.CLASSIFIER = "REGISTER_EVENT_ACTIVITY";
  this.CopySupportingElement = false;
  this.EventType = "";
  this.EventMessage = null;
  this.Settings = [];
  this.DiagramElement = null;
  this.IncomingSignals = [];
  this.OutgoingSignals = [];
  this.SystemName = "";
  this.Brief = "";
  this.Name = "";
  this.Description = "";
  this.GUID = "";
  this.setIncomingSignals = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.IncomingSignals = ref;
  };
  this.setOutgoingSignals = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.OutgoingSignals = ref;
  };
};
/***
* Server process defines a concept of a process that is executed 
on the side of the server. It performs some calculations,data processing and 
loading, checks, service calls and other forms of activities that do not need interaction with user
*/
w.ServerProcess = function() {
  this.PACKAGE = "PROCESS";
  this.CLASSIFIER = "SERVER_PROCESS";
  this.CopySupportingElement = false;
  this.Nodes = [];
  this.Signals = [];
  this.Module = null;
  this.ContainerPackage = null;
  this.SystemName = "";
  this.Brief = "";
  this.Name = "";
  this.Description = "";
  this.GUID = "";
  this.setModule = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.Module = ref;
  };
  this.setContainerPackage = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.ContainerPackage = ref;
  };
};
/***
* Node marking start process point
*/
w.StartNode = function() {
  this.PACKAGE = "PROCESS";
  this.CLASSIFIER = "START_NODE";
  this.CopySupportingElement = false;
  this.DiagramElement = null;
  this.IncomingSignals = [];
  this.OutgoingSignals = [];
  this.SystemName = "";
  this.Brief = "";
  this.Name = "";
  this.Description = "";
  this.GUID = "";
  this.setIncomingSignals = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.IncomingSignals = ref;
  };
  this.setOutgoingSignals = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.OutgoingSignals = ref;
  };
};
/***
* Task that can be executed by some external client or automatically by some schedule
*/
w.Task = function() {
  this.PACKAGE = "PROCESS";
  this.CLASSIFIER = "TASK";
  this.CopySupportingElement = false;
  this.Priority = -1;
  this.GUID = "";
};
/***
* 
*/
w.TaskInstance = function() {
  this.PACKAGE = "PROCESS";
  this.CLASSIFIER = "TASK_INSTANCE";
  this.CopySupportingElement = false;
  this.StatusCode = "";
  this.StatusDescription = "";
  this.ID = -1;
  this.StartDateTime = null;
  this.FinishDateTime = null;
  this.Task = null;
  this.setTask = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.Task = ref;
  };
};
/***
* 
*/
w.TenantListCreateActivity = function() {
  this.PACKAGE = "PROCESS";
  this.CLASSIFIER = "TENANT_LIST_CREATE_ACTIVITY";
  this.CopySupportingElement = false;
  this.Settings = [];
  this.DiagramElement = null;
  this.IncomingSignals = [];
  this.OutgoingSignals = [];
  this.SystemName = "";
  this.Brief = "";
  this.Name = "";
  this.Description = "";
  this.GUID = "";
  this.setIncomingSignals = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.IncomingSignals = ref;
  };
  this.setOutgoingSignals = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.OutgoingSignals = ref;
  };
};
/***
* Map containing variables values that must be applied when executing process for given tenant
*/
w.TenantVariableValues = function() {
  this.PACKAGE = "PROCESS";
  this.CLASSIFIER = "TENANT_VARIABLE_VALUES";
  this.CopySupportingElement = false;
  this.VariableValues = {};
  this.TenantID = -1;
};
/***
* User task is tak assigned to user by application automatically. 
These tasks is a tool used to tell the user what he must do in a certain moment to get the best out of a system.
For example in payments module - to import payments, to create cashdesk, mark payments with type etc.
*/
w.UserTask = function() {
  this.PACKAGE = "PROCESS";
  this.CLASSIFIER = "USER_TASK";
  this.CopySupportingElement = false;
  this.ID = -1;
  this.StatusCode = "";
  this.StatusDescription = "";
  this.Name = "";
  this.Description = "";
  this.CreateDateTime = null;
  this.TaskTypeCode = "";
};
 
 

/***
 * Client API to ProcessServiceWS web service
 */
w.ProcessServiceClient = function() {
  /***
   * System name of target service
   */
  this.serviceName = "ProcessServiceWSImpl";
};

/***
 * Starts new instance of given process and returns identifier set to the new  instance 
 * @param processGUID GUID of process to start
 * @param variableValues Values of variables passed to the process on start
 * @param callbackHandler Function to service callback result
 */
w.ProcessServiceClient.prototype.startProcess = function(processGUID, variableValues, callbackHandler) {
  var parameterNames = ["processGUID", "variableValues"];
  var variableValuesJSON = JSON.stringify(variableValues);
  var parameterValues = [processGUID, variableValuesJSON];
  var soapMessage = buildSOAPMessage("startProcess", parameterNames, parameterValues);
  var resultHasComplexType = false;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * [DEFAULT!Returns information about started process instance!DEFAULT]
 * @param processInstanceID Identifier if process instance generated when process was started
 * @param callbackHandler Function to service callback result
 */
w.ProcessServiceClient.prototype.getProcessInstance = function(processInstanceID, callbackHandler) {
  var parameterNames = ["processInstanceID"];
  var parameterValues = [processInstanceID];
  var soapMessage = buildSOAPMessage("getProcessInstance", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = true;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * [DEFAULT!Returns full process schema with activities by given GUID!DEFAULT]
 * @param processGUID GUID of target process
 * @param includeSubprocesses Flag indicating if subprocesses schema is included into response. 
 * @param callbackHandler Function to service callback result
 */
w.ProcessServiceClient.prototype.getProcessSchema = function(processGUID, includeSubprocesses, callbackHandler) {
  var parameterNames = ["processGUID", "includeSubprocesses"];
  var parameterValues = [processGUID, includeSubprocesses];
  var soapMessage = buildSOAPMessage("getProcessSchema", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = true;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * [DEFAULT!Returns list of calculation processes available to current user!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.ProcessServiceClient.prototype.getCalculationProcessList = function(callbackHandler) {
  var parameterNames = [];
  var parameterValues = [];
  var soapMessage = buildSOAPMessage("getCalculationProcessList", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * [DEFAULT!Returns aggregated information about all executed by the current tenant data loading processes, providing information about their last instances!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.ProcessServiceClient.prototype.getLastDataLoadProcessInstances = function(callbackHandler) {
  var parameterNames = [];
  var parameterValues = [];
  var soapMessage = buildSOAPMessage("getLastDataLoadProcessInstances", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * Checks if {@link Process} with given GUID exists in storage and 
updates it if found or adds to storage otherwise.
Returns {@link Process} after publishing it in storage
 * @param processSchema Target process to publish
 * @param processTypeCode [DEFAULT!System name  of built-in process type  !DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.ProcessServiceClient.prototype.publishProcessSchema = function(processSchema, processTypeCode, callbackHandler) {
  var parameterNames = ["processSchema", "processTypeCode"];
  var processSchemaJSON = JSON.stringify(processSchema);
  var parameterValues = [processSchemaJSON, processTypeCode];
  var soapMessage = buildSOAPMessage("publishProcessSchema", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param processID 
 * @param callbackHandler Function to service callback result
 */
w.ProcessServiceClient.prototype.getProcessByID = function(processID, callbackHandler) {
  var parameterNames = ["processID"];
  var parameterValues = [processID];
  var soapMessage = buildSOAPMessage("getProcessByID", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = true;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * Saves a collection of setting for process activities
 * @param settings List of setting to save
 * @param callbackHandler Function to service callback result
 */
w.ProcessServiceClient.prototype.saveSettings = function(settings, callbackHandler) {
  var parameterNames = ["settings"];
  var settingsJSON = JSON.stringify(settings);
  var parameterValues = [settingsJSON];
  var soapMessage = buildSOAPMessage("saveSettings", parameterNames, parameterValues);
  var resultHasComplexType = false;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * [DEFAULT!Returns information about all process instances according to given request filter!DEFAULT]
 * @param request [DEFAULT!Request for filtering all available process instances!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.ProcessServiceClient.prototype.getProcessInstancesInfo = function(request, callbackHandler) {
  var parameterNames = ["request"];
  var requestJSON = JSON.stringify(request);
  var parameterValues = [requestJSON];
  var soapMessage = buildSOAPMessage("getProcessInstancesInfo", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = true;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * [DEFAULT!Stops instance of process with given identifier!DEFAULT]
 * @param processInstanceID [DEFAULT!Identifier of process instance to stop!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.ProcessServiceClient.prototype.stopProcess = function(processInstanceID, callbackHandler) {
  var parameterNames = ["processInstanceID"];
  var parameterValues = [processInstanceID];
  var soapMessage = buildSOAPMessage("stopProcess", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param processTypeCode [DEFAULT!!DEFAULT]
 * @param subsystemID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.ProcessServiceClient.prototype.getProcessesByType = function(processTypeCode, subsystemID, callbackHandler) {
  var parameterNames = ["processTypeCode", "subsystemID"];
  var parameterValues = [processTypeCode, subsystemID];
  var soapMessage = buildSOAPMessage("getProcessesByType", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = true;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param processGUID [DEFAULT!!DEFAULT]
 * @param agentTickets [DEFAULT!!DEFAULT]
 * @param tenantVariableValues [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.ProcessServiceClient.prototype.startMultipleProcessInstances = function(processGUID, agentTickets, tenantVariableValues, callbackHandler) {
  var parameterNames = ["processGUID", "agentTickets", "tenantVariableValues"];
  var agentTicketsJSON = JSON.stringify(agentTickets);
  var tenantVariableValuesJSON = JSON.stringify(tenantVariableValues);
  var parameterValues = [processGUID, agentTicketsJSON, tenantVariableValuesJSON];
  var soapMessage = buildSOAPMessage("startMultipleProcessInstances", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param processInstanceIDList [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.ProcessServiceClient.prototype.getProcessInstancesInfoByIDList = function(processInstanceIDList, callbackHandler) {
  var parameterNames = ["processInstanceIDList"];
  var processInstanceIDListJSON = JSON.stringify(processInstanceIDList);
  var parameterValues = [processInstanceIDListJSON];
  var soapMessage = buildSOAPMessage("getProcessInstancesInfoByIDList", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * Client API to UserTaskServiceWS web service
 */
w.UserTaskServiceClient = function() {
  /***
   * System name of target service
   */
  this.serviceName = "UserTaskServiceWSImpl";
};

/***
 * [DEFAULT!!DEFAULT]
 * @param task [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.UserTaskServiceClient.prototype.createTask = function(task, callbackHandler) {
  var parameterNames = ["task"];
  var taskJSON = JSON.stringify(task);
  var parameterValues = [taskJSON];
  var soapMessage = buildSOAPMessage("createTask", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param taskID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.UserTaskServiceClient.prototype.markTaskAsCompleted = function(taskID, callbackHandler) {
  var parameterNames = ["taskID"];
  var parameterValues = [taskID];
  var soapMessage = buildSOAPMessage("markTaskAsCompleted", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param taskStatus [DEFAULT!Task status code string!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.UserTaskServiceClient.prototype.getTaskList = function(taskStatus, callbackHandler) {
  var parameterNames = ["taskStatus"];
  var parameterValues = [taskStatus];
  var soapMessage = buildSOAPMessage("getTaskList", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param taskID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.UserTaskServiceClient.prototype.getTaskByID = function(taskID, callbackHandler) {
  var parameterNames = ["taskID"];
  var parameterValues = [taskID];
  var soapMessage = buildSOAPMessage("getTaskByID", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param taskTypeID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.UserTaskServiceClient.prototype.getTasksByType = function(taskTypeID, callbackHandler) {
  var parameterNames = ["taskTypeID"];
  var parameterValues = [taskTypeID];
  var soapMessage = buildSOAPMessage("getTasksByType", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};


/***
 * Package security rights constants
 */

w.ProcessSecurityRights = {

    MANAGE_EXECUTION_PROCESS_RIGHT:new SecurityRightCheckHandle('47662890-9eae-4dfb-8d55-bc65b8a66090'),
    PUBLISH_PROCESS_RIGHT:new SecurityRightCheckHandle('89ca001e-fb26-4e17-8e74-374f5993e259')
};
})(typeof exports !== 'undefined' ? exports : window);
