import styled, { css } from 'styled-components';

type RootProps = {
	isHidden: boolean;
};

const Root = styled.div<RootProps>`
	width: 100%;

	${p =>
		p.isHidden &&
		css`
			filter: blur(4px);
		`}
`;

export { Root };
