import { Store } from 'redux';

import { IAppState } from '@store';
import { isReceiveAction } from '@flux';
import { types as projectsTypes } from '@project-management/actions/types';
import { invalidatePaymentRules } from '@project-payment-rules/actions/invalidators';

function askProjectPaymentRulesInvalidator(store: Store<IAppState>, action: StoreAction) {
	const { dispatch } = store;
	const invalidateMap = {
		[projectsTypes.UPDATE_PROJECT]: true,
		[projectsTypes.REMOVE_PROJECT]: true,
	};

	if (invalidateMap[action.type] && isReceiveAction(action)) {
		dispatch(invalidatePaymentRules());
	}
}

function createInvalidatorEffect() {
	return (store: Store<IAppState>) => (next: (action: StoreAction) => void) => (action: StoreAction) => {
		next(action);
		askProjectPaymentRulesInvalidator(store, action);
	};
}

const runInvalidatorEffect = createInvalidatorEffect();

export { runInvalidatorEffect };
