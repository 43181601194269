import { combineReducers } from 'redux';

import mainOperationsReducer, { MainOperationsState } from './main.reducer';

export type OperationsState = {
	main: MainOperationsState;
};

const operationsReducer = combineReducers<OperationsState>({
	main: mainOperationsReducer,
});

export default operationsReducer;
