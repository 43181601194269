import { ThemeBrand } from '@theme';
import { getAuthParams } from '@config';

const brandMap = Object.keys(ThemeBrand).reduce((acc, x) => ((acc[ThemeBrand[x]] = true), acc), {});

function detectIsValidBrand(brand: string) {
	const isValidBrand = brandMap[brand];

	return isValidBrand;
}

function extractThemeBrandFromBuild(): ThemeBrand {
	const { domain, domainsMap } = getAuthParams();
	const brandsByDomainMap = {
		[domainsMap.seeneco_bfm]: ThemeBrand.SEENECO,
		[domainsMap.sberbank_bfm]: ThemeBrand.SBERBANK,
		[domainsMap.sberbank_invoice]: ThemeBrand.SBERBANK,
		[domainsMap.sovcombank_invoice]: ThemeBrand.SOVCOMBANK,
	};
	const brand = brandsByDomainMap[domain];

	return detectIsValidBrand(brand) ? brand : null;
}

function detectIsContentOnlyForOurBrand() {
	const { domain, domainsMap } = getAuthParams();
	const isSeenecoDomain = domain === domainsMap.seeneco_bfm;

	return isSeenecoDomain;
}

export { detectIsValidBrand, extractThemeBrandFromBuild, detectIsContentOnlyForOurBrand };
