import React from 'react';

import { SwitchCard, SwitchCardLayout } from '../cards';
import { Root, GroupHeaderLayout } from './styled';

interface ISwitchPickerSourceItem<T> {
	value: number;
	text?: string;
	item: T;
}

interface IProps {
	dataSource: Array<ISwitchPickerSourceItem<any>> | Record<string, Array<any>>;
	value: number;
	render: (item: any) => React.ReactElement<any>;
	renderGroupHeader?: (key: string) => React.ReactElement<any>;
	onChange: (source: ISwitchPickerSourceItem<any> | any, index?: number) => void;
}

class SwitchPicker extends React.PureComponent<IProps> {
	static displayName = 'SwitchPicker';

	handleClick = (source, value: number) => ev => {
		this.props.onChange(source, value);

		ev.stopPropagation();
	};

	render() {
		const { dataSource, value, render, renderGroupHeader } = this.props;
		const dataSourceMap = dataSource as Record<string, any>;
		const dataSourceList = dataSource as Array<ISwitchPickerSourceItem<any>>;

		if (typeof renderGroupHeader === 'function') {
			let lastIndex = -1;

			return (
				<Root>
					{Object.keys(dataSourceMap).map((key, index) => {
						const itemList = dataSource[key];

						return (
							<Root key={index}>
								<GroupHeaderLayout>{renderGroupHeader(key)}</GroupHeaderLayout>
								{itemList.map(source => {
									lastIndex++;

									return (
										<SwitchCard
											key={lastIndex}
											isActive={value === lastIndex}
											hover
											onClick={this.handleClick(source, lastIndex)}>
											{render(source)}
										</SwitchCard>
									);
								})}
							</Root>
						);
					})}
				</Root>
			);
		}

		return (
			<Root>
				{dataSourceList &&
					dataSourceList.map((source, index) => {
						return (
							<SwitchCardLayout key={index}>
								<SwitchCard
									isActive={value === source.value}
									renderSwitch={dataSourceList.length > 2}
									onClick={this.handleClick(source, source.value)}>
									{render(source.item)}
								</SwitchCard>
							</SwitchCardLayout>
						);
					})}
			</Root>
		);
	}
}

export { SwitchPicker, ISwitchPickerSourceItem };
