import React from 'react';
import { compose } from 'redux';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { IAppState } from '../../../../../core/store';
import { withInvalidateFlag, withValidData } from '../../../../../core/hocs/with-valid-data.hoc';
import { fetchTenantByIDAction } from '../../../actions/tenant-account.actions';
import { selectCurrentTenantID } from '../../../selectors/context.selectors';
import { selectTenant, selectTenantDidInvalidate } from '../../../selectors/tenant-account.selectors';
import XTenantAccountKPI from './tenant-account-kpi.view';

interface IProps {
	tenantID?: number;
	tenant?: Tenant;

	fetchTenantByID?: (tenantID: number) => void;
}

class TenantAccountKPI extends React.PureComponent<IProps> {
	static displayName = 'TenantAccountKPI[Container]';

	render() {
		const { tenant } = this.props;

		return <XTenantAccountKPI tenant={tenant} />;
	}
}

function mapStateToProps(state: IAppState) {
	return {
		tenantID: selectCurrentTenantID(state),
		tenant: selectTenant(state),
	};
}

function mapDispatchToProps(dispatch: Dispatch<any>) {
	return bindActionCreators(
		{
			fetchTenantByID: fetchTenantByIDAction,
		},
		dispatch,
	);
}

export default compose(
	connect<any, any, IProps>(withInvalidateFlag(mapStateToProps, selectTenantDidInvalidate), mapDispatchToProps),
	withValidData<IProps>(props => {
		const { tenantID, fetchTenantByID } = props;

		fetchTenantByID(tenantID);
	}),
)(TenantAccountKPI);
