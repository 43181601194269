import React from 'react';
import * as _ from 'underscore';

import { Box } from '@core/ui/box';
import { HelpIcon } from '@core/ui/icons/help';
import { MenuIcon } from '@core/ui/icons/menu';
import { BusinessServiceType } from '@platform/model';
import { ROUTE_MAP } from '@routes/urls';
import { DarkModeSwitch } from '@shared/components/dark-mode-switch';
import { Divider } from '@ui/divider';
import { IconMenu } from '@ui/icon-menu';
import { UserCircleIcon } from '@ui/icons/user-circle';
import { formatEntity, getPropInSafe, makeEllipsis } from '@utils';
import { detectIsEmail } from '@utils/contacts';
import { history } from '../layout/layout.view';
import {
	ContentLayout,
	HelpLink,
	IconButtonLayout,
	MenuItem,
	MenuLabel,
	MenuValue,
	ProfileIconLayout,
	Root,
	UserName,
} from './styled';

const TENANT_ACCOUNT_URL = ROUTE_MAP.TENANT_ACCOUNT;

export type AppSessionViewProps = {
	businessService: BusinessService;
	businessServiceType: BusinessServiceType;
	login: string;
	logout: () => void;
	tenant: Tenant;
	tenantID: number;
};

type AppSessionPropsState = {
	isOpen: boolean;
};

class AppSession extends React.PureComponent<AppSessionViewProps, AppSessionPropsState> {
	static displayName = 'AppSession';
	state = {
		isOpen: false,
	};

	handleOpenMenu = () => {
		this.setState({
			isOpen: true,
		});
	};

	handleCloseMenu = () => {
		this.setState({
			isOpen: false,
		});
	};

	handleRouteToTenantAccount = ev => {
		history.push(`${TENANT_ACCOUNT_URL}/contact-data`);
		this.handleCloseMenu();
		ev.stopPropagation();
	};

	handleLogout = () => {
		this.props.logout();
	};

	renderIconMenu = () => {
		const { businessService, businessServiceType, login, tenant, tenantID } = this.props;
		const { isOpen } = this.state;
		const tenantName = getPropInSafe(tenant, o => o.Name, '');
		const isBfmApp = businessServiceType === BusinessServiceType.BFM;
		const helpHref = `${location.origin}/help/${isBfmApp ? 'bfm' : 'invoice'}`;

		return (
			<IconMenu
				open={isOpen}
				iconButtonElement={
					<IconButtonLayout>
						<MenuIcon color='black' size={20} title='Меню' />
					</IconButtonLayout>
				}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
				targetOrigin={{ horizontal: 'right', vertical: 'top' }}
				autoWidth={false}
				onRequestChange={this.handleCloseMenu}>
				<MenuItem onClick={this.handleRouteToTenantAccount}>Личный кабинет</MenuItem>
				<MenuItem onClick={this.handleLogout}>Выйти</MenuItem>
				<Divider />
				<MenuItem disabled>
					<MenuLabel>Номер личного кабинета</MenuLabel>
					<MenuValue isActive># {tenantID}</MenuValue>
				</MenuItem>
				<MenuItem disabled>
					<MenuLabel>Наименование личного кабинета</MenuLabel>
					<MenuValue title={tenantName}>{makeEllipsis(tenantName, 25)}</MenuValue>
				</MenuItem>
				<MenuItem disabled>
					<MenuLabel>Логин</MenuLabel>
					<MenuValue title={login}>{makeEllipsis(login, 25)}</MenuValue>
				</MenuItem>
				<MenuItem disabled>
					<MenuLabel>Бизнес-сервис</MenuLabel>
					<MenuValue>{businessService ? businessService.Name : 'Не определен'}</MenuValue>
				</MenuItem>
				<MenuItem>
					<HelpLink href={helpHref} appearance='black' target='_blank' rel='noopener'>
						<HelpIcon color='black' size={18} />
						<Box marginTop={2} marginLeft={4}>
							Справка
						</Box>
					</HelpLink>
				</MenuItem>
				<MenuItem disabled>
					<MenuLabel>Тема</MenuLabel>
					<MenuValue>
						<DarkModeSwitch />
					</MenuValue>
				</MenuItem>
			</IconMenu>
		);
	};

	render() {
		const { tenant } = this.props;
		const tenantName = getPropInSafe(tenant, o => o.Name, '');
		const formattedTenantName = _.compose(
			name => makeEllipsis(name, 20),
			formatEntity,
			name => name.toLocaleLowerCase(),
		)(tenantName);
		const capitalize = !detectIsEmail(formattedTenantName);

		return (
			<Root>
				<ContentLayout className='app-session' onClick={this.handleOpenMenu}>
					<ProfileIconLayout>
						<UserCircleIcon color='muted' size={20} />
					</ProfileIconLayout>
					<UserName capitalize={capitalize} title={tenantName}>
						{formattedTenantName}
					</UserName>
					{this.renderIconMenu()}
				</ContentLayout>
			</Root>
		);
	}
}

export default AppSession;
