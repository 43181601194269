import React from 'react';

import { Spinner, SpinnerAutoLayout } from '@ui/spinner';

const moduleDefaultExport = module => module.default || module;

type State = {
	component: typeof React.Component;
};

const withLazyLoad = (getComponent: () => Promise<any>, initialProps = {}) => {
	return class WithLazyLoad extends React.Component<any, State> {
		static displayName = `WithLazyLoad`;
		static component = null;
		state: State = {
			component: WithLazyLoad.component,
		};
		isMountedComponent = false;
		willMount = (() => {
			if (!this.state.component) {
				getComponent()
					.then(moduleDefaultExport)
					.then(component => {
						WithLazyLoad.component = component;
						this.isMountedComponent && this.setState({ component });
					});
			}
		})();

		componentDidMount() {
			this.isMountedComponent = true;
		}

		componentWillUnmount() {
			this.isMountedComponent = false;
		}

		render() {
			const { component: LazyComponent } = this.state;

			if (LazyComponent) {
				return <LazyComponent {...initialProps} {...this.props} />;
			}

			return (
				<SpinnerAutoLayout appearance='max-workspace'>
					<Spinner />
				</SpinnerAutoLayout>
			);
		}
	};
};

export default withLazyLoad;
