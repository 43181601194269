import { useLayoutEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

type Activity = {
	pathname: string;
	callback: () => void;
};

type Options = {
	activities: Array<Activity>;
};

function useRouteCallback(options: Options) {
	const { activities } = options;
	const scope = useMemo(() => ({ activities }), []);
	const history = useHistory();
	const fireCallback = (pathname: string) => {
		const map = scope.activities.reduce((acc, x) => ((acc[x.pathname] = x), acc), {});
		const activity = map[pathname];

		activity && activity.callback();
	};

	useLayoutEffect(() => {
		const unsubscribe = history.listen(() => fireCallback(location.pathname));

		fireCallback(location.pathname);

		return () => unsubscribe();
	}, []);

	scope.activities = activities;
}

export { useRouteCallback };
