import React from 'react';
import { forwardRef } from 'react';

import { Root } from './styled';


type WideContentProps = {
	children: React.ReactNode;
};

const WideContent = forwardRef<HTMLDivElement, WideContentProps>((props, ref) => {
	return (
		<Root ref={ref} {...props} />
	);
});

export default WideContent;
