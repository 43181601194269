import React from 'react';

import { Root } from './styled';

export type SidebarProps = {
	children: React.ReactNode;
};

const WideSidebar: React.FC<SidebarProps> = ({ children }) => {
	return <Root>{children}</Root>;
};

export default WideSidebar;
