import styled, { css } from 'styled-components';

import { fade, darken, lighten } from '@utils/color';

type SegmentProps = {
	isActive: boolean;
	readonly: boolean;
	disabled: boolean;
};

const Root = styled.div`
	position: relative;
	display: inline-flex;
	flex-flow: row wrap;
`;

const Segment = styled.button<SegmentProps>`
	position: relative;
	z-index: 1;
	width: auto;
	border-radius: 1px;
	outline: none;
	cursor: pointer;
	text-transform: uppercase;
	font-weight: 500;
	border: 1px solid transparent;
	padding: 8px 16px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
		border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

	${p => css`
		background-color: transparent;
		border-color: ${p.theme.palette.stealth};
		font-size: ${p.theme.fn.pxToRem(13)};
	`}

	${p =>
		p.isActive &&
		css`
			border-color: ${p.theme.palette.accent};
			color: ${p.theme.palette.accent};
			background-color: ${fade(p.theme.palette.accent, 0.1)};
			z-index: 2;
		`}


	&:focus {
		z-index: 2;
	}

	${p =>
		(p.readonly || p.disabled) &&
		css`
			cursor: default;
		`}

	${p =>
		(p.readonly || p.disabled) &&
		!p.isActive &&
		css`
			color: ${p.theme.palette.label};
		`}
`;

const SegmentLayout = styled.div`
	margin-bottom: 12px;

	&:not(:last-child) {
		margin-right: 12px;
	}
`;

type SegmentedItemRootProps = {
	isActive: boolean;
	multiple: boolean;
};

const SegmentedItemRoot = styled.button<SegmentedItemRootProps>`
	position: relative;
	z-index: 1;
	margin-right: -1px;
	cursor: pointer;
	font-weight: 500;
	border: 1px solid transparent;
	transition: opacity 0.2s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

	${p => css`
		background-color: ${p.theme.palette.space};
		border-color: ${p.theme.isDarkMode ? lighten(p.theme.palette.stealth, 0.1) : 'transparent'};
		${p.theme.fn.createBoxShadow(1)};
	`}

	&:first-child {
		border-radius: 2px 0 0 2px;
	}

	&:last-child {
		border-radius: 0 2px 2px 0;
	}

	${p =>
		p.isActive &&
		css`
			border-color: ${p.theme.isDarkMode
				? lighten(p.theme.palette.stealth, 0.1)
				: darken(p.theme.palette.stealth, 0.1)};
			background-color: ${fade(p.theme.palette.stealth, 0.7)};
			box-shadow: none;
			z-index: 2;
		`}

	${p =>
		p.isActive &&
		!p.multiple &&
		css`
			cursor: default;
		`}

	${p =>
		p.disabled &&
		css`
			opacity: 0.5;
			cursor: not-allowed;
		`}

	&:focus {
		z-index: 3;
	}
`;

const SegmentedItemContent = styled.div`
	min-height: 30px;
	padding: 6px 16px;
	line-height: initial;
`;

const ErrorText = styled.span`
	${p => css`
		font-size: ${p.theme.fn.pxToRem(12)};
		color: ${p.theme.palette.alarm};
	`}
`;

export { Root, Segment, SegmentLayout, SegmentedItemRoot, SegmentedItemContent, ErrorText };
