import { BASE_DATE_FORMAT } from '@shared/constants/time';
import * as billingpackage from 'billingpackage-api';
import * as moment from 'moment';

export type RequestTariffLevelOptions = {
	dateStart: string;
	tariffLevelID: number;
};

const billingApi = {
	package: billingpackage as typeof billingpackageApi,
	billing: {
		client: new billingpackage.BillingServiceClient() as BillingServiceClient,
		changeTariffLevel: (options: RequestTariffLevelOptions) => {
			return new Promise<CodeNaturalKey>(resolve => {
				const { tariffLevelID, dateStart } = options;
				const request = {
					...new billingApi.package.ChangeTariffLevelRequest(),
					TariffLevelID: tariffLevelID,
					StartDate: dateStart,
				};

				billingApi.billing.client.changeTariffLevel(request, result => {
					resolve(result);
				});
			});
		},
		fetchTenantBills: (options: FetchTenantBillsOptions) => {
			const {
				businessServiceID,
				dateRange = {
					dateStart: moment().subtract(12, 'months').format(BASE_DATE_FORMAT),
					dateEnd: moment().add(1, 'months').format(BASE_DATE_FORMAT),
				},
				onlyUnpaid = true,
				tenantID,
			} = options;

			return new Promise<Array<TenantBill>>(resolve => {
				const filter = {
					...new billingApi.package.TenantBillFilterCriteria(),
					BusinessServiceID: businessServiceID,
					IssueDateTimeFinish: dateRange.dateEnd,
					IssueDateTimeStart: dateRange.dateStart,
					OnlyUnpaid: onlyUnpaid,
					TenantIDList: tenantID.toString(),
				};

				billingApi.billing.client.getTenantBills(filter, bills => {
					resolve(bills);
				});
			});
		},
	},
};

type FetchTenantBillsOptions = {
	businessServiceID: number;
	dateRange?: DateRange;
	onlyUnpaid?: boolean;
	tenantID: number;
};

export { billingApi };
