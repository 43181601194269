export enum ProductsViewCode {
	LIST = 'LIST',
	HIERARCHY = 'HIERARCHY',
}

export enum ProductsSorting {
	ID_ASC = 'id_asc',
	ID_DESC = 'id_desc',
	PRICE_ASC = 'price_asc',
	PRICE_DESC = 'price_desc',
	NAME_ASC = 'name_asc',
	NAME_DESC = 'name_desc',
}
