// @ts-nocheck
import React from 'react';
import { findDOMNode } from 'react-dom';
import * as _ from 'underscore';

import TextFieldMUI from 'material-ui/TextField';
import { detectIsFunction } from '@utils/helpers';
import * as inputUtils from '../basic-theme/input-styles';
import { sizes, ISizes } from '../basic-theme/sizes';
import { IBaseControl } from '../';
import { IconButton } from '@ui/icon-button';
import { CloseIcon } from '@ui/icons/close';
import { FormElementContext } from '@ui/forms/inputs';
import { FloatingLabelText } from '@ui/internal/floating-label-text';
import { Spinner } from '@ui/spinner';
import { TextFieldGlobalStyle, Root, ClearButtonLayout, SpinnerLayout } from './styled';
import { withTheme, WithThemeProps } from '@theme';
import './style.css';

export type TextFieldProps = {
	debounce?: number;
	className?: string;
	rows?: number;
	rowsMax?: number;
	multiLine?: boolean;
	type?: string;
	icon?: React.ReactElement<any>;
	iconPosition?: 'left' | 'right';
	style?: React.CSSProperties;
	inputStyle?: React.CSSProperties;
	helpMark?: React.ReactNode;
	disableSetRef?: boolean;
	autoFocus?: boolean;
	withoutMarginRight?: boolean;
	welcomeText?: string;
	required?: boolean;
	isFetching?: boolean;
	disableShowTextError?: boolean;
	children?: React.ReactNode;
	changeValueChecker?: (value: string) => boolean;
	formatValue?: (value: React.ReactText) => string;
	onChange?: (e: React.SyntheticEvent<HTMLInputElement>, newValue: string | number, matched?: boolean) => void;
	onFocus?: React.FocusEventHandler<{}>;
	onBlur?: React.FocusEventHandler<{}>;
	onKeyDown?: (ev) => void;
	onReset?: () => void;
} & IBaseControl.props<string | number> &
	IBaseControl.handlers &
	WithThemeProps;

type TextFieldState = {
	value: React.ReactText;
};

export type TextFieldRef = {
	focus: () => void;
};

class TextFieldBase extends React.PureComponent<TextFieldProps, TextFieldState> {
	static contextType = FormElementContext;
	static defaultProps = {
		iconPosition: 'left',
		debounce: 0,
		formatValue: x => x,
	} as any;
	state: TextFieldState = {
		value: this.props.value,
	};
	private classNames = inputUtils.getClassNameMap('text');
	public textInput;

	componentDidMount() {
		this.setRef();

		if (this.props.autoFocus && this.textInput) {
			const inputRef = (findDOMNode(this.textInput) as HTMLElement)?.querySelector('input');

			if (inputRef) {
				inputRef.focus();
			}
		}
	}

	componentDidUpdate(prevProps: TextFieldProps) {
		if (prevProps.value !== this.props.value && this.props.value !== this.state.value) {
			this.setState({ value: this.props.value });
		}
	}

	focus = () => {
		if (this.textInput && this.textInput.input) {
			const inputElement =
				(this.textInput.input.refs && this.textInput.input.refs.input) ||
				this.textInput.input.inputElement ||
				this.textInput.input;
			inputElement.scrollIntoView({ block: 'center', behavior: 'smooth' });
			inputElement.focus({ preventScroll: true });
		}
	};

	blur = () => {
		if (this.textInput && this.textInput.input) {
			const inputElement =
				(this.textInput.input.refs && this.textInput.input.refs.input) ||
				this.textInput.input.inputElement ||
				this.textInput.input;
			inputElement.blur();
		}
	};

	setRef() {
		if (!this.props.disableSetRef && this.context && this.context.setWrappedElem) {
			this.context.setWrappedElem(this);
		}
	}

	handleChangeDebounced =
		this.props.debounce && !this.props.children
			? _.debounce(this.props.onChange, this.props.debounce)
			: this.props.onChange;

	protected handleChange = (e: React.SyntheticEvent<HTMLInputElement>, value: string) => {
		const { onChange, changeValueChecker, formatValue } = this.props;
		const formattedValue = formatValue(value);

		e.persist();
		!this.props.children && this.setState({ value: formattedValue });

		if (onChange) {
			if (typeof changeValueChecker === 'function') {
				changeValueChecker(formattedValue) && this.handleChangeDebounced(e, formattedValue);
				return;
			}
			this.handleChangeDebounced(e, formattedValue);
		}
	};

	protected buildInputProps() {
		const { readonly, required, labelText, helpMark, isFetching, disableShowTextError, theme } = this.props;
		const size: keyof ISizes<number> = this.props.size;
		const style = this.props.style || {};
		if (size && !this.props.fullWidth) {
			style.width = sizes[size];
		}
		if (this.props.icon && this.props.iconPosition === 'left') {
			style.paddingLeft = 30;
		}
		if (this.props.icon && this.props.iconPosition === 'right') {
			style.paddingRight = 30;
		}
		if (this.props.withClearBtn) {
			style.paddingRight = 30;
		}
		if (this.props.withClearBtn && this.props.icon && this.props.iconPosition === 'right') {
			style.paddingRight = 50;
		}
		style.fontSize = 14;
		const status = inputUtils.getHintStatus({
			isError: !!this.props.errorText,
			isInformation: !!this.props.informationText,
			isSuccess: !!this.props.successText,
			isWarning: !!this.props.warningText,
		});
		const statusText = inputUtils.getHintStatusText(this.props);
		const statusStyle = inputUtils.getHintStatusStyles(status, { danger: this.props.errorStyle }, theme);
		const displayType = inputUtils.getDisplayStatuses({
			disabled: this.props.disabled || isFetching,
			readonly: this.props.readonly,
		});

		const underlineStyleWithIcon =
			this.props.iconPosition === 'left' ? { margin: '0 0 0 -30px' } : { margin: '0 -30px 0 0' };
		const floatingLabelText = labelText ? (
			<FloatingLabelText labelText={labelText} readonly={readonly} required={required} helpMark={helpMark} />
		) : undefined;
		const welcomeText = this.isFocused() && !this.state.value && !statusText && this.props.welcomeText;
		const welcomeTextStyle = welcomeText ? inputUtils.getHintStatusStyles('success', {}, theme) : {};
		const props: __MaterialUI.TextFieldProps = {
			value: (this.props.children ? this.props.value : this.state.value) || '',
			name: this.props.name || '',
			hintText: !this.props.readonly ? this.props.hintText : '',
			disabled: this.props.disabled || this.props.readonly || isFetching,
			onChange: this.props.disabled || this.props.readonly || isFetching ? () => {} : this.handleChange,
			onBlur: this.props.onBlur,
			onFocus: this.props.onFocus,
			floatingLabelText,
			floatingLabelFixed: true,
			floatingLabelFocusStyle: statusStyle,
			floatingLabelStyle: { width: '133%', zIndex: 2, whiteSpace: 'nowrap' },
			errorText: statusText || welcomeText,
			errorStyle: {
				display: disableShowTextError ? 'none' : undefined,
				position: 'relative',
				top: 'auto',
				bottom: 'auto',
				marginTop: this.props.multiLine ? 0 : -15,
				...welcomeTextStyle,
				...statusStyle,
			},
			style,
			rows: this.props.rows,
			rowsMax: this.props.rowsMax,
			multiLine: this.props.multiLine,
			inputStyle: this.props.inputStyle,
			fullWidth: this.props.fullWidth,
			className: this.props.className
				? this.props.className + ' ' + this.classNames[displayType]
				: this.classNames[displayType],
			type: this.props.type,
			underlineStyle: this.props.icon ? underlineStyleWithIcon : {},
			onKeyDown: this.props.onKeyDown,
			autoFocus: this.props.autoFocus,
		};

		return props;
	}

	handleClear = (e: React.MouseEvent) => {
		const { onReset } = this.props;

		this.setState({ value: '' });
		this.handleChangeDebounced(null, '');
		detectIsFunction(onReset) && onReset();
	};

	isFocused = () => document.activeElement === (this.textInput && this.textInput.input);

	renderInputAdornment = () => {
		const { labelText, withClearBtn, readonly, disabled, isFetching } = this.props;

		if (isFetching) {
			return (
				<SpinnerLayout>
					<Spinner size={16} />
				</SpinnerLayout>
			);
		}

		const { value } = this.state;
		const hasValue = value !== '';
		const isFocused = this.isFocused();

		if (hasValue && isFocused && withClearBtn && !readonly && !disabled) {
			return (
				<ClearButtonLayout withLabelText={!!labelText}>
					<IconButton variant='rounded' isSilentHover stopPropagation onClick={this.handleClear}>
						<CloseIcon size={16} />
					</IconButton>
				</ClearButtonLayout>
			);
		}

		return null;
	};

	renderInputAdornmentIcon = () => {
		const { icon, labelText, withClearBtn, readonly, disabled, isFetching, iconPosition } = this.props;

		if (isFetching) {
			return (
				<SpinnerLayout>
					<Spinner size={16} />
				</SpinnerLayout>
			);
		}

		const { value } = this.state;
		const hasValue = value !== '';
		const iconLayoutStyle: React.CSSProperties =
			iconPosition === 'left'
				? {
						margin: 'auto 0',
						height: 24,
						position: 'absolute',
						top: 0,
						bottom: 0,
				  }
				: {
						margin: 'auto 0',
						height: 24,
						position: 'absolute',
						top: 30,
						right: 0,
				  };

		if (hasValue && withClearBtn && !readonly && !disabled) {
			return (
				<>
					<ClearButtonLayout withLabelText={!!labelText} withRightIcon={iconPosition === 'right'}>
						<IconButton variant='rounded' isSilentHover stopPropagation onClick={this.handleClear}>
							<CloseIcon size={16} />
						</IconButton>
					</ClearButtonLayout>
					<div style={iconLayoutStyle}>{icon}</div>
				</>
			);
		}

		return <div style={iconLayoutStyle}>{icon}</div>;
	};

	render() {
		const { fullWidth, withoutMarginRight } = this.props;
		const inputProps = this.buildInputProps();

		if (!this.props.icon) {
			return (
				<>
					<Root
						fullWidth={fullWidth}
						withoutMarginRight={withoutMarginRight}
						{...inputProps.style}
						{...this.props.style}>
						<TextFieldMUI
							ref={input => {
								this.textInput = input;
							}}
							{...inputProps}>
							{this.props.children}
						</TextFieldMUI>
						{this.renderInputAdornment()}
					</Root>
				</>
			);
		}

		return (
			<>
				<Root fullWidth={fullWidth} withoutMarginRight={withoutMarginRight} {...inputProps.style} {...this.props.style}>
					<TextFieldMUI
						ref={input => {
							this.textInput = input;
						}}
						{...this.buildInputProps()}>
						{this.props.children}
					</TextFieldMUI>
					{this.renderInputAdornmentIcon()}
				</Root>
			</>
		);
	}
}

const TextField = withTheme(TextFieldBase);

export { TextField, TextFieldGlobalStyle };
