function setLocalStorageValue(name: string, value: any) {
	localStorage.setItem(name, JSON.stringify(value));
}

function useLocalStorageValue(name: string): [any, (value: any) => void] {
	const value = JSON.parse(localStorage.getItem(name));
	const setValue = (value: any) => setLocalStorageValue(name, value);

	return [value, setValue];
}

function useLocalStorageFlag(name: string): [boolean, (value: any) => void, any] {
	const value = JSON.parse(localStorage.getItem(name));
	const setValue = (value: any) => setLocalStorageValue(name, value);

	return [Boolean(value), setValue, value];
}

function getTicketUid() {
	return getCookie('ticketuid');
}

function getCookie(name: string) {
	const matches = document.cookie.match(
		new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'),
	);

	return matches ? decodeURIComponent(matches[1]) : undefined;
}

function setCookie(name, value, options) {
	options = options || {};
	let expires = options.expires;

	if (typeof expires == 'number' && expires) {
		const d = new Date();
		d.setTime(d.getTime() + expires * 1000);
		expires = options.expires = d;
	}
	if (expires && expires.toUTCString) {
		options.expires = expires.toUTCString();
	}

	value = encodeURIComponent(value);

	let updatedCookie = `${name}=${value}`;

	for (const propName in options) {
		updatedCookie += '; ' + propName;
		const propValue = options[propName];
		if (propValue !== true) {
			updatedCookie += `=${propValue}`;
		}
	}

	document.cookie = updatedCookie;
}

export { setLocalStorageValue, useLocalStorageValue, useLocalStorageFlag, getTicketUid, getCookie, setCookie };
