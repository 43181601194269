import { Store } from 'redux';

import { IAppState } from '@store';
import { isReceiveAction } from '@flux';
import { types as employeeTypes } from '@employees/actions/types';
import { mainProjectEmployeesActionsPack } from '@project-employees/actions';

function askInvalidator(store: Store<IAppState>, action: StoreAction) {
	const { dispatch } = store;
	const invalidateMap = {
		[employeeTypes.ADD_EMPLOYEE]: true,
		[employeeTypes.UPDATE_EMPLOYEE]: true,
		[employeeTypes.REMOVE_EMPLOYEE]: true,
	};

	if (invalidateMap[action.type] && isReceiveAction(action)) {
		dispatch(mainProjectEmployeesActionsPack.actions.invalidateEmployees());
	}
}

function createInvalidatorEffect() {
	return (store: Store<IAppState>) => (next: (action: StoreAction) => void) => (action: StoreAction) => {
		next(action);
		askInvalidator(store, action);
	};
}

const runInvalidatorEffect = createInvalidatorEffect();

export { runInvalidatorEffect };
