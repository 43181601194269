import * as _ from 'underscore';

import { createReducer } from '@flux';
import { ModifyInvoiceBeforePaymentOptions, mainInvoiceActionsPack } from '../actions';

export interface IMainInvoiceState {
	modifyInvoiceOptions: ModifyInvoiceBeforePaymentOptions | null;
	autoinsert: Partial<Invoice> | null;
}

const initialState = {
	modifyInvoiceOptions: null,
	autoinsert: null,
};

const mainInvoiceReducer = createReducer<IMainInvoiceState>(initialState, {
	[mainInvoiceActionsPack.types.MODIFY_INVOCE_BEFORE_PAYMENT]: (
		action: ReturnType<typeof mainInvoiceActionsPack.actions.modifyInvoiceBeforePayment>,
	) => {
		return {
			modifyInvoiceOptions: action.value,
		};
	},
	[mainInvoiceActionsPack.types.MODIFICATION_COMPLETED]: () => {
		return {
			modifyInvoiceOptions: null,
		};
	},
	[mainInvoiceActionsPack.types.AUTOINSERT_TO_NEW_INVOICE]: (action: StaticAction<Partial<Invoice>>) => {
		return {
			autoinsert: action.value,
		};
	},
});

export default mainInvoiceReducer;
