import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import { AppLogoProps } from './app-logo';

type RootProps = {
	appearance: AppLogoProps['appearance'];
};

const Root = styled.div<RootProps>`
	display: inline-block;
	position: relative;
	width: 80px;
	height: 70px;
	min-width: 80px;

	${p => css`
		background-color: ${p.theme.appMenu.logoBackgroundColor};
		border-right: 1px solid ${p.theme.appMenu.dividerColor};
	`}

	${p =>
		p.appearance === 'mobile' &&
		css`
			background-color: #fff;
			width: auto;
			height: auto;
			min-width: 0;
		`}
`;

const LinkStyled = styled(Link)`
	width: 100%;
	height: 100%;
	display: inline-flex;
	flex-flow: row wrap;
	justify-content: center;
	align-items: center;
`;

type LogoProps = {
	isInvoiceApp: boolean;
};

const Logo = styled.div<LogoProps>`
	width: 36px;
	height: 36px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	${p => css`
		background-image: url(${p.isInvoiceApp ? p.theme.whiteLabelLogo.invoice : p.theme.whiteLabelLogo.bfm});
	`}
`;

export { Root, LinkStyled, Logo };
