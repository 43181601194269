/***
 * Library contains JavaScript API for interaction with Seeneco Framework Web Services contained in ServicePackage
 * @author name Generated by Seeneco Framework Modeler 
 *
 */
(function(w){
/***
* Defines Service
*/
w.Service = function() {
  this.PACKAGE = "SERVICE";
  this.CLASSIFIER = "SERVICE";
  this.CopySupportingElement = false;
  this.Module = null;
  this.ContainerPackage = null;
  this.Operations = [];
  this.Virtual = false;
  this.SystemName = "";
  this.Brief = "";
  this.Name = "";
  this.Description = "";
  this.GUID = "";
  this.setModule = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.Module = ref;
  };
  this.setContainerPackage = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.ContainerPackage = ref;
  };
};
/***
* Defines Service operation
*/
w.ServiceOperation = function() {
  this.PACKAGE = "SERVICE";
  this.CLASSIFIER = "SERVICE_OPERATION";
  this.CopySupportingElement = false;
  this.InputParameters = [];
  this.OutputValueType = null;
  this.OutputValueTypeMultiple = false;
  this.ImplementationProcess = null;
  this.AutoRestartOnError = false;
  this.SystemName = "";
  this.Brief = "";
  this.Name = "";
  this.Description = "";
  this.GUID = "";
  this.setOutputValueType = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.OutputValueType = ref;
  };
};
/***
* Long value type wrapper for using in remote service requests
*/
w.TLong = function() {
  this.PACKAGE = "SERVICE";
  this.CLASSIFIER = "TLONG";
  this.CopySupportingElement = false;
  this.Value = -1;
};
 
 

/***
 * Client API to ServiceRegistryServiceWS web service
 */
w.ServiceRegistryServiceClient = function() {
  /***
   * System name of target service
   */
  this.serviceName = "ServiceRegistryServiceWSImpl";
};

/***
 * Defines publishService operation
 * @param service 
 * @param callbackHandler Function to service callback result
 */
w.ServiceRegistryServiceClient.prototype.publishService = function(service, callbackHandler) {
  var parameterNames = ["service"];
  var serviceJSON = JSON.stringify(service);
  var parameterValues = [serviceJSON];
  var soapMessage = buildSOAPMessage("publishService", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * Defines getServiceByGUID operation
 * @param serviceGUID 
 * @param callbackHandler Function to service callback result
 */
w.ServiceRegistryServiceClient.prototype.getServiceByGUID = function(serviceGUID, callbackHandler) {
  var parameterNames = ["serviceGUID"];
  var parameterValues = [serviceGUID];
  var soapMessage = buildSOAPMessage("getServiceByGUID", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * Client API to VirtualOperationExecutionServiceWS web service
 */
w.VirtualOperationExecutionServiceClient = function() {
  /***
   * System name of target service
   */
  this.serviceName = "VirtualOperationExecutionServiceWSImpl";
};

/***
 * Executes operation of virtual service
 * @param operationGUID GUID of service operation to execute
 * @param serviceGUID GUID of target virtual service
 * @param parametersMap Map containing values of operation parameters. Key holds parameter system name
and value holds parameter value in form of object
 * @param callbackHandler Function to service callback result
 */
w.VirtualOperationExecutionServiceClient.prototype.executeOperation = function(operationGUID, serviceGUID, parametersMap, callbackHandler) {
  var parameterNames = ["operationGUID", "serviceGUID", "parametersMap"];
  var parametersMapJSON = JSON.stringify(parametersMap);
  var parameterValues = [operationGUID, serviceGUID, parametersMapJSON];
  var soapMessage = buildSOAPMessage("executeOperation", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};


/***
 * Package security rights constants
 */

w.ServiceSecurityRights = {

};
})(typeof exports !== 'undefined' ? exports : window);
