import React from 'react';

import { Root, Internal } from './styled';

// Хак для уменьшения высоты инпутов. Использовать только в крайних случаях
export type SlimControlProps = {
	children: React.ReactNode;
};

const SlimControl: React.FC<SlimControlProps> = props => {
	const { children } = props;

	return (
		<Root>
			<Internal>{children}</Internal>
		</Root>
	);
};

export { SlimControl };
