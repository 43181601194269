// @ts-nocheck
import * as moment from 'moment'

import * as actions from '../actions/invoice-filter.actions'
import { IInvoiceStore } from '../'


const initialState = {
	dateRange: {start: moment().startOf('month'), end: moment().endOf('month')},
	purchases: {
		counterparty: {},
		substr: '',
		byAll: true,
		byNotPaid: false,
		byPaid: false,
		byOverdue: false,
		byDraft: false,
		numberSubstr: '',
		descSubstr: '',
	},
	sales: {
		counterparty: {},
		substr: '',
		byAll: true,
		byNotPaid: false,
		byPaid: false,
		byOverdue: false,
		byDraft: false,
		numberSubstr: '',
		descSubstr: '',
		manager: {},
		linkedDocuments: null
	}
};

export default function(state: IInvoiceStore.invoiceListFilters = initialState, action): IInvoiceStore.invoiceListFilters {
	const filterAction = action as staticAction<any>;
	switch (filterAction.type) {

	case actions.SET_INVOICE_FILTER_BY_DATE:
		return {
			...state,
			dateRange: {...filterAction.value}
		}

	case actions.SET_PURCHASES_FILTER_BY_COUNTERPARTY:
		return {
			...state,
			purchases: {
				...state.purchases,
				counterparty: {...filterAction.value}
			}
		}

	case actions.SET_PURCHASES_FILTER_BY_SUBSTR:
		return {
			...state,
			purchases: {
				...state.purchases,
				substr: filterAction.value
			}
		}

	case actions.SET_PURCHASES_FILTER_BY_NUMBER:
		return {
			...state,
			purchases: {
				...state.purchases,
				numberSubstr: filterAction.value
			}
		}

	case actions.SET_PURCHASES_FILTER_BY_DESCRIPTION:
		return {
			...state,
			purchases: {
				...state.purchases,
				descSubstr: filterAction.value
			}
		}

		case actions.SET_PURCHASES_FILTER_BY_ALL:
			return {
				...state,
				purchases: {
					...state.purchases,
					byAll: true,
					byNotPaid: false,
					byPaid: false,
					byOverdue: false,
					byDraft: false
				}
			}

		case actions.SET_PURCHASES_FILTER_BY_NOT_PAID:
			return {
				...state,
				purchases: {
					...state.purchases,
					byAll: false,
					byNotPaid: true,
					byPaid: false,
					byOverdue: false,
					byDraft: false
				}
			}

		case actions.SET_PURCHASES_FILTER_BY_PAID:
			return {
				...state,
				purchases: {
					...state.purchases,
					byAll: false,
					byNotPaid: false,
					byPaid: true,
					byOverdue: false,
					byDraft: false
				}
			}

		case actions.SET_PURCHASES_FILTER_BY_OVERDUE:
			return {
				...state,
				purchases: {
					...state.purchases,
					byAll: false,
					byNotPaid: false,
					byPaid: false,
					byOverdue: true,
					byDraft: false
				}
			}

		case actions.SET_PURCHASES_FILTER_BY_DRAFT:
			return {
				...state,
				purchases: {
					...state.purchases,
					byAll: false,
					byNotPaid: false,
					byPaid: false,
					byOverdue: false,
					byDraft: true
				}
			}

		case actions.CLEAR_ALL_PURCHASES_FILTERS:
			return {
				...state,
				purchases: {
					...state.purchases,
					counterparty: {},
					substr: '',
					byAll: true,
					byNotPaid: false,
					byPaid: false,
					byOverdue: false,
					byDraft: false,
					numberSubstr: '',
					descSubstr: ''
				}
			}

		case actions.SET_SALES_FILTER_BY_COUNTERPARTY:
			return {
				...state,
				sales: {
					...state.sales,
					counterparty: {...filterAction.value}
				}
			}
	
		case actions.SET_SALES_FILTER_BY_SUBSTR:
			return {
				...state,
				sales: {
					...state.sales,
					substr: filterAction.value
				}
			}
	
		case actions.SET_SALES_FILTER_BY_NUMBER:
			return {
				...state,
				sales: {
					...state.sales,
					numberSubstr: filterAction.value
				}
			}
	
		case actions.SET_SALES_FILTER_BY_DESCRIPTION:
			return {
				...state,
				sales: {
					...state.sales,
					descSubstr: filterAction.value
				}
			}
	
		case actions.SET_SALES_FILTER_BY_ALL:
			return {
				...state,
				sales: {
					...state.sales,
					byAll: true,
					byNotPaid: false,
					byPaid: false,
					byOverdue: false,
					byDraft: false
				}
			}

		case actions.SET_SALES_FILTER_BY_NOT_PAID:
			return {
				...state,
				sales: {
					...state.sales,
					byAll: false,
					byNotPaid: true,
					byPaid: false,
					byOverdue: false,
					byDraft: false
				}
			}

		case actions.SET_SALES_FILTER_BY_PAID:
			return {
				...state,
				sales: {
					...state.sales,
					byAll: false,
					byNotPaid: false,
					byPaid: true,
					byOverdue: false,
					byDraft: false
				}
			}

		case actions.SET_SALES_FILTER_BY_OVERDUE:
			return {
				...state,
				sales: {
					...state.sales,
					byAll: false,
					byNotPaid: false,
					byPaid: false,
					byOverdue: true,
					byDraft: false
				}
			}

		case actions.SET_SALES_FILTER_BY_DRAFT:
			return {
				...state,
				sales: {
					...state.sales,
					byAll: false,
					byNotPaid: false,
					byPaid: false,
					byOverdue: false,
					byDraft: true
				}
			}

		case actions.SET_SALES_FILTER_BY_MANAGER:
			return {
				...state,
				sales: {
					...state.sales,
					manager: {...filterAction.value}
				}
			}

		case actions.SET_SALES_FILTER_BY_LINKED_DOCUMENTS_EXISTS:
			return {
				...state,
				sales: {
					...state.sales,
					linkedDocuments: filterAction.value
				}
			}
		
		case actions.CLEAR_ALL_SALES_FILTERS:
			return {
				...state,
				sales: {
					...state.sales,
					counterparty: {},
					substr: '',
					byAll: true,
					byNotPaid: false,
					byPaid: false,
					byOverdue: false,
					byDraft: false,
					numberSubstr: '',
					descSubstr: '',
					manager: {},
					linkedDocuments: null
				}
			}

	default:
		return state
	}
}