import React, { useContext, createContext } from 'react';

import { deepClone } from '@utils';

export type FormRef<T> = {
	setFormObject: (o: T) => void;
	getFormObject: () => T;
	submitForm: (customParam?: any) => void;
	getEditedBriefObjects: () => Partial<T>;
};

export type FormProps<S> = {
	formObject: S;
	isFetching?: boolean;
	readonly?: boolean;
	onSubmit?: (obj: S, customParam?: any) => void;
	onInvalid?: (obj: S, customParam?: any) => void;
	className?: string;
	style?: React.CSSProperties;
	id?: string;
	onChange?: (formObject?: S, editedBriefObjects?: Partial<S>) => void;
	onValidationStart?: () => void;
	onValidationDone?: () => void;
	disableCloneObject?: boolean; // для использования мутабельности
	preventSetState?: boolean;
	fullWidth?: boolean;
	enableLogs?: boolean;
	children: React.ReactNode;
};

export type FormState<S> = {
	formObject: S;
	editedBriefObjects?: Partial<S>;
};

class Form<S> extends React.Component<FormProps<S>, FormState<S>> {
	state: FormState<S> = {
		formObject: this.props.disableCloneObject ? this.props.formObject : deepClone(this.props.formObject),
		editedBriefObjects: {},
	};
	private errorValidations: Array<ValidationHandler<S>> = [];
	private validationInProcess = false;
	private submitTimeout = null;
	isMountedComponent = false;

	componentDidMount() {
		this.isMountedComponent = true;
	}

	componentDidUpdate(prevProps: FormProps<S>) {
		if (this.props.formObject !== prevProps.formObject) {
			this.setFormObject(this.props.formObject);
		}
	}

	componentWillUnmount() {
		this.isMountedComponent = false;
	}

	public getBriefObject = (type: keyof S) => this.state.editedBriefObjects[type];

	public addBriefObject = (type: keyof S, briefObject: any) => {
		const briefObjects = this.state.editedBriefObjects;

		briefObjects[type] = { ...(briefObjects[type] || {}), ...briefObject };
	};

	public deleteBriefObject = (type: keyof S) => {
		const briefObjects = this.state.editedBriefObjects;

		delete briefObjects[type];
	};

	public handleBriefObjectChange = (type: keyof S, briefObject: any) => {
		if (!this.isMountedComponent) return;
		const { editedBriefObjects } = this.state;

		this.setState(
			{
				editedBriefObjects: {
					...(editedBriefObjects as {}),
					[type]: briefObject,
				},
			},
			() => {
				this.handleObjectChange(this.state.formObject);
			},
		);
	};

	private setFormObject(formObject) {
		if (!this.isMountedComponent) return;

		this.setState({
			formObject: this.props.disableCloneObject ? formObject : deepClone(formObject),
			editedBriefObjects: {},
		});
	}

	public handleObjectChange = (formObject: S) => {
		if (!this.isMountedComponent) return;
		const { enableLogs, preventSetState, onChange } = this.props;
		const { editedBriefObjects } = this.state;

		if (typeof onChange === 'function') {
			onChange(formObject, editedBriefObjects);
		}

		if (preventSetState) return;

		if (enableLogs) {
			// tslint:disable-next-line: no-console
			console.log('formObject: ', deepClone(formObject));
			// tslint:disable-next-line: no-console
			Object.keys(editedBriefObjects).length > 0 && console.log('editedBriefObjects: ', deepClone(editedBriefObjects));
		}

		this.setState({ formObject });
	};

	public submitForm = (customParam?: any, onValidation: (inProcess: boolean) => void = () => {}) => {
		if (!this.validationInProcess) {
			const validationResult = this.validateForm();

			onValidation(true);

			if (typeof validationResult === 'boolean') {
				const isValid = validationResult;

				if (isValid && this.props.onSubmit) {
					this.props.onSubmit(this.state.formObject, customParam);
				}

				if (!isValid && this.props.onInvalid) {
					this.props.onInvalid(this.state.formObject, customParam);
				}

				onValidation(false);
			} else if (validationResult instanceof Promise) {
				validationResult.then((isAsyncValid: boolean) => {
					if (isAsyncValid && this.props.onSubmit) {
						this.props.onSubmit(this.state.formObject, customParam);
					}

					if (!isAsyncValid && this.props.onInvalid) {
						this.props.onInvalid(this.state.formObject, customParam);
					}

					onValidation(false);
				});
			}
		} else {
			if (this.submitTimeout) {
				clearTimeout(this.submitTimeout);
			}

			this.submitTimeout = setTimeout(() => {
				this.submitForm(customParam);
			}, 300);
		}
	};

	public getFormObject = () => {
		return this.state.formObject;
	};

	public getEditedBriefObjects = () => {
		return this.state.editedBriefObjects;
	};

	public resetForm = () => {
		this.setFormObject(this.props.formObject);
	};

	public addFormValidation = (validation: ValidationHandler<S>) => {
		this.errorValidations.push(validation);
	};

	public removeFormValidation = (validation: ValidationHandler<S>) => {
		this.errorValidations = this.errorValidations.filter(x => x !== validation);
	};

	public validateForm = (cb?: (isValid: boolean) => void) => {
		const { onValidationStart, onValidationDone } = this.props;
		const promises: Array<Promise<boolean>> = [];
		let isValid = false;
		this.validationInProcess = true;
		onValidationStart && onValidationStart();
		isValid = this.errorValidations.every(fn => {
			const result: boolean | Promise<boolean> = fn(this.state);

			if (result instanceof Promise) {
				promises.push(result);
				return true;
			}

			return result;
		});

		if (!isValid) {
			this.validationInProcess = false;
			onValidationDone && onValidationDone();
			cb && cb(false);

			return false;
		}

		if (isValid && promises.length === 0) {
			this.validationInProcess = false;
			onValidationDone && onValidationDone();
			cb && cb(true);

			return true;
		}

		if (isValid && promises.length > 0) {
			return Promise.all(promises).then(results => {
				const indexOfFirstNotValid = results.findIndex(result => !result);
				const isAsyncValid = indexOfFirstNotValid === -1;

				this.validationInProcess = false;
				onValidationDone && onValidationDone();
				cb && cb(isAsyncValid);

				return isAsyncValid;
			});
		}
	};

	getContextValue = () => {
		const context: FormContextType<S> = {
			isFetching: this.props.isFetching,
			formObject: this.state.formObject,
			editedBriefObjects: this.state.editedBriefObjects,
			readonly: this.props.readonly,
			handleObjectChange: this.handleObjectChange,
			addFormValidation: this.addFormValidation,
			removeFormValidation: this.removeFormValidation,
			submitForm: this.submitForm,
			resetForm: this.resetForm,
			validateForm: this.validateForm,
			addBriefObject: this.addBriefObject,
			deleteBriefObject: this.deleteBriefObject,
			getBriefObject: this.getBriefObject,
			handleBriefObjectChange: this.handleBriefObjectChange,
		};

		return context;
	};

	render() {
		const { fullWidth } = this.props;

		return (
			<FormContext.Provider value={this.getContextValue()}>
				<div
					id={this.props.id}
					className={this.props.className}
					style={{
						...{ width: fullWidth ? '100%' : 'auto' },
						...this.props.style,
					}}>
					{this.props.isFetching || !this.state.formObject ? null : this.props.children}
				</div>
			</FormContext.Provider>
		);
	}
}

export type ValidationHandler<S> = (obj: FormState<S>) => boolean | Promise<boolean>;

export type FormContextType<S> = {
	isFetching: boolean;
	formObject: S;
	editedBriefObjects: Partial<S>;
	readonly: boolean;
	handleObjectChange: (formObject: S) => void;
	addFormValidation: (validation: ValidationHandler<S>) => void;
	removeFormValidation: (validation: ValidationHandler<S>) => void;
	submitForm: (customParam?: any) => void;
	resetForm: () => void;
	validateForm: (cb?: (isValid: boolean) => void) => void;
	addBriefObject: (type: keyof S, briefObject: any) => void;
	getBriefObject: (type: keyof S) => any;
	deleteBriefObject: (type: keyof S) => void;
	handleBriefObjectChange: (type: keyof S, briefObject: any) => void;
};

export const FormContext = createContext<FormContextType<unknown>>(null);

function useFormContext<T = any>() {
	const formContext = useContext(FormContext);

	return formContext as FormContextType<T>;
}

export { useFormContext };
export default Form;
