import * as accountingpackage from 'accountingpackage-api';
import { fromStringToBoolean } from '@utils/serializer';

const accountingApi = {
	package: accountingpackage,
	accountsChart: {
		client: new accountingpackage.AccountsChartServiceClient(),
		fetchCashflowItems: () => {
			return new Promise<Array<CashflowItem>>(resolve => {
				accountingApi.accountsChart.client.getAccountsChart('CF', result => {
					resolve(result as Array<CashflowItem>);
				});
			});
		},
		fetchCashflowItemByID: (ID: number) => {
			return new Promise<CashflowItem>(resolve => {
				accountingApi.accountsChart.client.getItemByID(ID, result => {
					resolve(result as CashflowItem);
				});
			});
		},
		addCashflowItem: (value: CashflowItem) => {
			return new Promise<CashflowItem>(resolve => {
				accountingApi.accountsChart.client.addItem(value, result => {
					resolve(result as CashflowItem);
				});
			});
		},
		updateCashflowItem: (value: CashflowItem) => {
			return new Promise<CashflowItem>(resolve => {
				accountingApi.accountsChart.client.updateItem(value, result => {
					resolve(result as CashflowItem);
				});
			});
		},
		removeCashflowItem: (ID: number) => {
			return new Promise<boolean>(resolve => {
				accountingApi.accountsChart.client.removeItem(ID, result => {
					resolve(fromStringToBoolean(result));
				});
			});
		},
		fetchAccountsChartItemRoles: () => {
			return new Promise<Array<AccountsChartItemRole>>(resolve => {
				accountingApi.accountsChart.client.getAllAccountsChartItemRoles(result => {
					resolve(result);
				});
			});
		},
	},
};

export { accountingApi };
