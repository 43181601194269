export enum AuthMethodCode {
	API_KEY = 'API_KEY',
	CLIENT_ID_API_KEY = 'CLIENT_ID_API_KEY',
	EMAIL = 'EMAIL',
	EXTERNAL_ACCOUNT_ID = 'EXTERNAL_ACCOUNT_ID',
	LOGIN_PASSWORD = 'LOGIN_PASSWORD',
	OAUTH_TOKEN = 'OAUTH_TOKEN',
}

export enum ProviderRole {
	DATA_PROVIDER_ROLE = 'DATA_PROVIDER_ROLE',
	IDENTITY_PROVIDER_ROLE = 'IDENTITY_PROVIDER_ROLE',
}
