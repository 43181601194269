/***
 * Library contains JavaScript API for interaction with Seeneco Framework Web Services contained in CorePackage
 * @author elhini Generated by Seeneco Framework Modeler 
 *
 */
(function(w){
/***
* Accessible element of domain model.
Accessible element is a basic abstraction for all elements that are coarse-grained and could be accessed by user (have meaningful name, code, description etc).
*/
w.AccessibleElement = function() {
  this.PACKAGE = "CORE";
  this.CLASSIFIER = "ACCESSIBLE_ELEMENT";
  this.CopySupportingElement = false;
  this.SystemName = "";
  this.Brief = "";
  this.Name = "";
  this.Description = "";
  this.GUID = "";
};
/***
* 
*/
w.AsyncOperationRequest = function() {
  this.PACKAGE = "CORE";
  this.CLASSIFIER = "ASYNC_OPERATION_REQUEST";
  this.CopySupportingElement = false;
  this.OperationUID = -1;
};
/***
* 
*/
w.AsyncOperationResponse = function() {
  this.PACKAGE = "CORE";
  this.CLASSIFIER = "ASYNC_OPERATION_RESPONSE";
  this.CopySupportingElement = false;
  this.OperationUID = -1;
  this.ActualProgress = -1;
  this.ProgressTotalSteps = -1;
  this.Status = "";
  this.Message = "";
  this.ErrorCode = "";
};
/***
* 
*/
w.CodeNaturalKey = function() {
  this.PACKAGE = "CORE";
  this.CLASSIFIER = "CODE_NATURAL_KEY";
  this.CopySupportingElement = false;
  this.Code = "";
  this.ID = -1;
  this.Name = "";
  this.GUID = "";
};
/***
* Defines properties of element on the diagram - coordinates and dimensions
*/
w.DiagramElement = function() {
  this.PACKAGE = "CORE";
  this.CLASSIFIER = "DIAGRAM_ELEMENT";
  this.CopySupportingElement = false;
  this.X = -1;
  this.Y = -1;
  this.Width = -1;
  this.Height = -1;
  this.GUID = "";
};
/***
* Defines Domain element
*/
w.DomainElement = function() {
  this.PACKAGE = "CORE";
  this.CLASSIFIER = "DOMAIN_ELEMENT";
  this.CopySupportingElement = false;
  this.GUID = "";
};
/***
* {@link DomainModule} represents part of domain that contains composite domain elements implementing {@link ModuleLevelElement}
{@link DomainModule} is a root container of every {@link DomainElement}
*/
w.DomainModule = function() {
  this.PACKAGE = "CORE";
  this.CLASSIFIER = "DOMAIN_MODULE";
  this.CopySupportingElement = false;
  this.SourceCodeFolderName = "";
  this.SourceCodeRootPackageName = "";
  this.TablePrefix = "";
  this.ServiceApplicationName = "";
  this.Version = "";
  this.SystemName = "";
  this.Brief = "";
  this.Name = "";
  this.Description = "";
  this.GUID = "";
};
/***
* {@link DomainPackage} represents concept of folder in {@link DomainModule} containing several {@link DomainElement} objects.
 Given {@link DomainElement} can be contained by one and only one package.
 {@link DomainPackage} can have hierarchical structure
*/
w.DomainPackage = function() {
  this.PACKAGE = "CORE";
  this.CLASSIFIER = "DOMAIN_PACKAGE";
  this.CopySupportingElement = false;
  this.Parent = null;
  this.Module = null;
  this.SystemName = "";
  this.Brief = "";
  this.Name = "";
  this.Description = "";
  this.GUID = "";
  this.setParent = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.Parent = ref;
  };
  this.setModule = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.Module = ref;
  };
};
/***
* Source data to generate i18n message
*/
w.GeneratedI18nMessage = function() {
  this.PACKAGE = "CORE";
  this.CLASSIFIER = "GENERATED_I18N_MESSAGE";
  this.CopySupportingElement = false;
  this.MessageCode = "";
  this.ResourceBundle = "";
  this.VariableValues = [];
};
/***
* 
*/
w.NaturalKey = function() {
  this.PACKAGE = "CORE";
  this.CLASSIFIER = "NATURAL_KEY";
  this.CopySupportingElement = false;
  this.ID = -1;
  this.Name = "";
  this.GUID = "";
};
/***
* 
*/
w.OperationResponse = function() {
  this.PACKAGE = "CORE";
  this.CLASSIFIER = "OPERATION_RESPONSE";
  this.CopySupportingElement = false;
  this.Status = "";
  this.Message = "";
  this.ErrorCode = "";
};
/***
* Defines Parameter
*/
w.Parameter = function() {
  this.PACKAGE = "CORE";
  this.CLASSIFIER = "PARAMETER";
  this.CopySupportingElement = false;
  this.Type = null;
  this.Multiple = false;
  this.SystemName = "";
  this.Brief = "";
  this.Name = "";
  this.Description = "";
  this.GUID = "";
  this.setType = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.Type = ref;
  };
};
/***
* 
*/
w.ParameterValue = function() {
  this.PACKAGE = "CORE";
  this.CLASSIFIER = "PARAMETER_VALUE";
  this.CopySupportingElement = false;
  this.Parameter = null;
  this.Value = "";
  this.setParameter = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.Parameter = ref;
  };
};
/***
* Common response that is returned as a result of executing an operation. 
It holds success flag and internationalized description of error if any
*/
w.SuccessMessage = function() {
  this.PACKAGE = "CORE";
  this.CLASSIFIER = "SUCCESS_MESSAGE";
  this.CopySupportingElement = false;
  this.Success = false;
  this.Message = "";
  this.ErrorCode = "";
};
 
 

/***
 * Client API to DomainModuleServiceWS web service
 */
w.DomainModuleServiceClient = function() {
  /***
   * System name of target service
   */
  this.serviceName = "DomainModuleServiceWSImpl";
};

/***
 * Returns {@link DomainModule} by it's local identifier
 * @param id Local identifier of {@link DomainModule}
 * @param callbackHandler Function to service callback result
 */
w.DomainModuleServiceClient.prototype.getModuleByID = function(id, callbackHandler) {
  var parameterNames = ["id"];
  var parameterValues = [id];
  var soapMessage = buildSOAPMessage("getModuleByID", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * Publishes {@link DomainModule} in repository. Operation checks if module 
with given GUID exists in repository if found - it updates the module, otherwise
adds new module
 * @param module 
 * @param callbackHandler Function to service callback result
 */
w.DomainModuleServiceClient.prototype.publishModule = function(module, callbackHandler) {
  var parameterNames = ["module"];
  var moduleJSON = JSON.stringify(module);
  var parameterValues = [moduleJSON];
  var soapMessage = buildSOAPMessage("publishModule", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * Returns {@link DomainModule} by it's GUID
 * @param moduleGUID GUID of target Domain module
 * @param callbackHandler Function to service callback result
 */
w.DomainModuleServiceClient.prototype.getModuleByGUID = function(moduleGUID, callbackHandler) {
  var parameterNames = ["moduleGUID"];
  var parameterValues = [moduleGUID];
  var soapMessage = buildSOAPMessage("getModuleByGUID", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};


/***
 * Package security rights constants
 */

w.CoreSecurityRights = {

};
})(typeof exports !== 'undefined' ? exports : window);
