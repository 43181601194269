/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-var-requires */
/*const PATH_TO_PLATFORM = '/opt/seeneco/for_jenkins/build_bfm/platform';
const PATH_TO_FINANCE = '/opt/seeneco/for_jenkins/build_bfm/finance'; */
const BASE_URL = '/bfm';
const staticPath = `/opt/seeneco/for_jenkins/build_bfm/snc-bfm-ui`;
/*const sslKeyPath = `D:/nginx/conf/ssl/seeneco-selfsigned.key`;
const sslCrtPath = `D:/nginx/conf/ssl/seeneco-selfsigned.crt`;*/
const domainsMap = {
	seeneco_bfm: 'seeneco.com',
	sberbank_bfm: 'sbbfm.ru',
	sberbank_invoice: 'invoice.sbbfm.ru',
	sovcombank_invoice: 'invoice.sovcombank.ru',
};

module.exports = {
	BASE_URL,
	domainsMap,
	staticPath
};
