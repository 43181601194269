import { Base64 } from '@utils';
import { formatNumber, safeNumber } from '@utils/numbers';

function getAmountByItem(item: FinancialDocumentItem) {
	return safeNumber(item.Qty * item.ItemPrice);
}

function getTaxAmountByItem(item: FinancialDocumentItem) {
	const amount = item.Qty * item.ItemPrice;
	if (item.ItemTaxRate === -1) return 0;
	const tax = item.ItemTaxRate > 0 ? amount * item.ItemTaxRate : amount - amount / (1 + Math.abs(item.ItemTaxRate));
	const value = safeNumber(tax);

	return safeNumber(value);
}

function getTaxPriceByItem(item: FinancialDocumentItem) {
	if (item.ItemTaxRate === -1) {
		return 0;
	}

	const tax =
		item.ItemTaxRate > 0
			? item.ItemPrice * item.ItemTaxRate
			: item.ItemPrice - item.ItemPrice / (1 + Math.abs(item.ItemTaxRate));
	const value = safeNumber(tax);

	return value;
}

function getClearPriceByItem(item: FinancialDocumentItem) {
	const fullPrice = item.ItemPrice * (item.ItemTaxRate > 0 ? 1 + item.ItemTaxRate : 1);
	const safeFullPrice = safeNumber(fullPrice);
	const taxPrice = getTaxPriceByItem(item);
	const value = safeFullPrice - taxPrice;

	return safeNumber(value);
}

function getClearAmountByItem(item: FinancialDocumentItem) {
	const value = getTotalAmountByItem(item) - getTaxAmountByItem(item);

	return safeNumber(value);
}

function getTotalAmount(items: Array<FinancialDocumentItem>) {
	return items.reduce((acc, x) => {
		const amount = x.Qty * x.ItemPrice;
		const tax = x.ItemTaxRate > 0 ? amount * x.ItemTaxRate : 0;
		const value = safeNumber(amount + tax);

		return (acc += value);
	}, 0);
}

function getTotalTaxAmount(items: Array<FinancialDocumentItem>) {
	return items.reduce((acc, x) => {
		const value = getTaxAmountByItem(x);

		return (acc += value);
	}, 0);
}

function getTotalAmountByItem(item: FinancialDocumentItem) {
	const total = getAmountByItem(item) * (item.ItemTaxRate > 0 ? 1 + item.ItemTaxRate : 1);

	return safeNumber(total);
}

function useInvoiceItems(items: Array<FinancialDocumentItem>) {
	const totalAmount = getTotalAmount(items);
	const totalTaxAmount = getTotalTaxAmount(items);
	const totalClearAmount = totalAmount - totalTaxAmount;
	const qtyTotal = items.reduce((acc, x) => ((acc += x.Qty), acc), 0);

	return {
		totalAmount,
		totalTaxAmount,
		totalClearAmount,
		qtyTotal,
	};
}

const getInvoiceItems = useInvoiceItems;

const getFinancialDocumentItemsParams = useInvoiceItems;

function getTotalByPayments(invoice: Invoice) {
	return safeNumber(invoice.Payments.reduce((acc, x) => acc + x.PaymentAmount, 0));
}

function useInvoiceStatuses(invoice: Invoice) {
	const totalPaid = getTotalByPayments(invoice);
	const totalAmount = safeNumber(getTotalAmount(invoice.Items));
	const isDraft = invoice.Status === 'DRAFT';
	const isPaymentDraftExist = Boolean(invoice.Payments.find(item => (item.PaymentState as any).Code === 'DRAFT'));
	const isSentToCMS = invoice.Status === 'PAYMENT_IN_PROCESS';
	const isPartiallyPaid = totalPaid > 0 && totalPaid < totalAmount;
	const isPaid = invoice.Status === 'PAID' && !isPartiallyPaid;
	const isNotPaid = invoice.Status === 'ISSUED';
	const isOverdue = invoice.Status === 'OVERDUE';

	return {
		isDraft,
		isPaymentDraftExist,
		isSentToCMS,
		isPartiallyPaid,
		isPaid,
		isNotPaid,
		isOverdue,
	};
}

const getInvoiceStatuses = useInvoiceStatuses;

type GenerateSharedDocumentUrlOptions = {
	type: 'invoice';
	uid: string;
	emailTo?: string;
};

function generateSharedDocumentUrl(options: GenerateSharedDocumentUrlOptions) {
	const url = `${location.origin}/shared-documents/?hash=${Base64.encode(JSON.stringify(options))}`;

	return url;
}

type GenerateInvoiceViewerUrlOptions = {
	sharedDocumentUID: string;
	emailTo?: string;
};

function generateInvoiceViewerUrl(options: GenerateInvoiceViewerUrlOptions) {
	const { sharedDocumentUID, emailTo } = options;
	const hash = Base64.encode(
		JSON.stringify({
			type: 'invoice',
			uid: sharedDocumentUID,
			emailTo,
		}),
	);
	const url = `${location.origin}/shared-documents/invoice/viewer?hash=${hash}`;

	return url;
}

export enum InvoiceStatusCode {
	DRAFT = 'DRAFT',
	NOT_PAID = 'NOT_PAID',
	SENT_TO_CMS = 'SENT_TO_CMS',
	PARTIALLY_PAID = 'PARTIALLY_PAID',
	PAID = 'PAID',
	OVERDUE = 'OVERDUE',
}

function getInvoiceStatusCode(invoice: Invoice): InvoiceStatusCode {
	const { isDraft, isPartiallyPaid, isPaid, isSentToCMS, isNotPaid, isOverdue } = useInvoiceStatuses(invoice);

	if (isDraft) return InvoiceStatusCode.DRAFT;
	if (isNotPaid) return InvoiceStatusCode.NOT_PAID;
	if (isSentToCMS) return InvoiceStatusCode.SENT_TO_CMS;
	if (isPartiallyPaid) return InvoiceStatusCode.PARTIALLY_PAID;
	if (isPaid) return InvoiceStatusCode.PAID;
	if (isOverdue) return InvoiceStatusCode.OVERDUE;
}

function createInvoiceQrCodeUrl(base: string) {
	return base.indexOf('qrcode') !== -1 ? base : `${base}&qrcode=true`;
}

function formatAmountForQrCode(sourceValue: number) {
	const value = Number(sourceValue).toFixed(2);
	const formatted = value.replace(/(\.|,)/g, '');

	return formatted;
}

function detectHasAnyVat(items: Array<FinancialDocumentItem>) {
	return items.some(x => x.ItemTaxRate > -1);
}

const getFormattedQty = (item: FinancialDocumentItem) =>
	formatNumber(item.Qty, { fractions: item.MeasureUnit?.ID in IntegerMeasureUnit ? 0 : 3 });

enum IntegerMeasureUnit {
	UNKNOWN = -1,
	PIECE = 1,
	CLICK = 31,
	SERVICE = 17,
	MONTH = 14,
	CONVENTIONAL_UNIT = 15,
	UNIT = 299,
	KIT = 19,
	FLIGHT = 20,
	NUMBER = 366,
	LEAD = 30,
	DAY = 10,
	PERSON = 317,
	CONDITIONAL_THING = 337,
	DAYS = 11,
	SUBSCRIBER = 467,
	UNITS_PER_YEAR = 492,
	PLACE = 304,
	ROLL = 147,
	PACKAGING = 151,
	JOINT = 581,
	SECOND = 121,
	COUPLE = 26,
	PACK = 549,
	TEU = 411,
}

export {
	createInvoiceQrCodeUrl,
	detectHasAnyVat,
	formatAmountForQrCode,
	generateInvoiceViewerUrl,
	generateSharedDocumentUrl,
	getAmountByItem,
	getClearAmountByItem,
	getClearPriceByItem,
	getFinancialDocumentItemsParams,
	getFormattedQty,
	getInvoiceItems,
	getInvoiceStatusCode,
	getInvoiceStatuses,
	getTaxAmountByItem,
	getTotalAmount,
	getTotalAmountByItem,
	getTotalByPayments,
	getTotalTaxAmount,
	useInvoiceItems,
	useInvoiceStatuses,
};
