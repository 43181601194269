import React, { useMemo } from 'react';

import { Autopicker, AutopickerProps } from '@ui/autopicker';

export type TaxModeAutopickerProps = {
	name?: string;
} & AutopickerProps<LegalEntityTaxMode>;

const TaxModeAutopicker: React.FC<TaxModeAutopickerProps> = props => {
	const pluralItems = useMemo(() => ['форма', 'формы', 'форм'] as [string, string, string], []);

	return (
		<Autopicker
			labelText='Форма налогообложения'
			hintText='Выберите форму...'
			pluralItems={pluralItems}
			{...props}
			fullWidth
		/>
	);
};

export { TaxModeAutopicker };
