/* eslint-disable no-var */
import { domainsMap } from '../config';

declare var __CONFIG_KEY__: ConfigKey;

type AppName = 'invoice' | 'bfm';

type DomainsMap = {
	seeneco_bfm: string;
	sberbank_bfm: string;
	sberbank_invoice: string;
	sovcombank_invoice: string;
};

type ConfigKey = {
	app: AppName;
	domain: string;
	baseURL: string;
	provider: string;
	openIDProfile: string;
	applicationScope: string;
	businessServiceID: number;
	businessService: string;
	clientApplication: string;
	tariffCode: string;
	yandexCounterID: number;
	googleCounterID: string;
	qqCounterID: string;
	appStoreLinkToBfm: string;
	googlePlayLinkToBfm: string;
	appStoreLinkToInvoice: string;
	googlePlayLinkToInvoice: string;
};

type AuthParams = {
	domainsMap: DomainsMap;
	yandexClientID: string;
} & Pick<
	ConfigKey,
	| 'app'
	| 'domain'
	| 'openIDProfile'
	| 'applicationScope'
	| 'businessServiceID'
	| 'tariffCode'
	| 'provider'
	| 'businessService'
	| 'clientApplication'
	| 'baseURL'
>;

const configKey = __CONFIG_KEY__;

function getAuthParams(): AuthParams {
	return {
		domain: configKey.domain,
		domainsMap,
		app: configKey.app,
		openIDProfile: configKey.openIDProfile,
		applicationScope: configKey.applicationScope,
		businessServiceID: configKey.businessServiceID,
		tariffCode: configKey.tariffCode,
		provider: configKey.provider,
		businessService: configKey.businessService,
		clientApplication: configKey.clientApplication,
		baseURL: configKey.baseURL,
		yandexClientID,
	};
}

function getGlobalConfig() {
	const { domain, domainsMap } = getAuthParams();
	const seenecoDomainsMap = {
		[domainsMap.seeneco_bfm]: true,
	};
	const sberbankDomainsMap = {
		[domainsMap.sberbank_bfm]: true,
		[domainsMap.sberbank_invoice]: true,
	};
	const sovcombankDomainsMap = {
		[domainsMap.sovcombank_invoice]: true,
	};
	const isSeeneco = Boolean(seenecoDomainsMap[domain]);
	const isSberbank = Boolean(sberbankDomainsMap[domain]);
	const isSovcombank = Boolean(sovcombankDomainsMap[domain]);

	return {
		isSeeneco,
		isSberbank,
		isSovcombank,
	};
}

function getMetrics() {
	return {
		yandexCounterID: configKey.yandexCounterID,
		googleCounterID: configKey.googleCounterID,
		qqCounterID: configKey.qqCounterID,
	};
}

function getMobileAppLinks() {
	return {
		appStoreLinkToBfm: configKey.appStoreLinkToBfm,
		googlePlayLinkToBfm: configKey.googlePlayLinkToBfm,
		appStoreLinkToInvoice: configKey.appStoreLinkToInvoice,
		googlePlayLinkToInvoice: configKey.googlePlayLinkToInvoice,
	};
}

function getServiceName(): string {
	const isSberbank = getGlobalConfig().isSberbank;
	const appName = getAppName();
	const isInvoice = appName === 'invoice';
	const serviceName = isSberbank
		? isInvoice
			? 'Сервис выставления счетов'
			: 'Бизнес Аналитика'
		: isInvoice
		? 'Сервис бизнес расчётов'
		: 'БФМ';

	return serviceName;
}

let yandexClientID = null;

function setYandexClientID(clientID: number) {
	yandexClientID = clientID;
}

function detectIsInvoiceApp() {
	return getAppName() === 'invoice';
}

function getAppName(): AppName {
	return configKey.app;
}

function detectIsSberbank() {
	const { isSberbank } = getGlobalConfig();

	return isSberbank;
}

function detectIsSovcombank() {
	const { isSovcombank } = getGlobalConfig();

	return isSovcombank;
}

function getBrandAppName(): string {
	const { isSberbank } = getGlobalConfig();
	const { app } = getAuthParams();

	if (isSberbank) {
		const map: Record<typeof app, string> = {
			bfm: 'Бизнес Аналитика',
			invoice: 'Сервис выставления счетов',
		};

		return map[app] || '';
	}

	const map: Record<typeof app, string> = {
		bfm: 'BFM - Управление финансами',
		invoice: 'Сервис бизнес-расчётов',
	};

	return map[app] || '';
}

export {
	getGlobalConfig,
	getAppName,
	getAuthParams,
	getMetrics,
	getServiceName,
	getMobileAppLinks,
	setYandexClientID,
	detectIsSberbank,
	detectIsSovcombank,
	detectIsInvoiceApp,
	getBrandAppName,
};
