import { accountingApi } from './accounting';
import { analysisApi } from './analysis';
import { billingApi } from './billing';
import { budgetingApi } from './budgeting';
import { cashflowLimitsApi } from './cashflowlimits';
import { configurationApi } from './configuration';
import { coreApi } from './core';
import { counterpartyApi } from './counterparty';
import { dataApi } from './data';
import { datamanagementApi } from './datamanagement';
import { documentApi } from './document';
import { fileApi } from './file';
import { fundsApi } from './funds';
import { invoiceApi } from './invoice';
import { notificationApi } from './notification';
import { organizationApi } from './organization';
import { paymentsApi } from './payments';
import { plApi } from './pl';
import { preferencesApi } from './preferences';
import { profileApi } from './profile';
import { projectApi } from './project';
import { referenceApi } from './reference';
import { reportsApi } from './reports';
import { securityApi } from './security';
import { ssoApi } from './sso';

const api = {
	accountingPack: accountingApi,
	analysisPack: analysisApi,
	billingPack: billingApi,
	budgetingPack: budgetingApi,
	cashflowLimitsPack: cashflowLimitsApi,
	configurationPack: configurationApi,
	corePack: coreApi,
	counterpartyPack: counterpartyApi,
	datamanagementPack: datamanagementApi,
	dataPack: dataApi,
	documentPack: documentApi,
	filePack: fileApi,
	fundsPack: fundsApi,
	invoicePack: invoiceApi,
	notificationPack: notificationApi,
	organizationPack: organizationApi,
	paymentsPack: paymentsApi,
	plPack: plApi,
	preferencesPack: preferencesApi,
	profilePack: profileApi,
	projectPack: projectApi,
	referencePack: referenceApi,
	reportsPack: reportsApi,
	securityPack: securityApi,
	ssoPack: ssoApi,
};

export { api };
