import React from 'react';
import ReactDOM from 'react-dom';
import { Calendar } from 'react-date-range-updated';
import * as moment from 'moment';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';

import { formatDate } from '@utils';
import { Popover } from '@ui/popover';
import { Dialog } from '@ui/dialog';
import { TextField } from '@ui/input-field';
import { withMaskHOC } from '@ui/input-field/with-mask';
import { DropdownIcon } from '@ui/icons/dropdown';
import { ClickOutsideTracker } from '@ui/click-outside-tracker';
import { IBaseControl } from '../';
import { Theme, withTheme } from '@theme';
import {
	Root,
	TriggerLayout,
	DatePickerLayout,
	createDatePickerTheme,
	dialogContentStyle,
	InputLayout,
	FrameLayout,
	DatePickerLabel,
	InlineModeRoot,
	IconLayout,
} from './styled';

export type DatePickerProps = {
	modalMode?: boolean;
	frameMode?: boolean;
	separateMode?: boolean;
	inlineMode?: boolean;
	disablePastDates?: boolean;
	disableFutureDates?: boolean;
	anchorHorizontal?: 'left' | 'middle' | 'right';
	anchorVertical?: 'top' | 'center' | 'bottom';
	targetHorizontal?: 'left' | 'middle' | 'right';
	targetVertical?: 'top' | 'center' | 'bottom';
	rootStyle?: React.CSSProperties;
	inputStyle?: React.CSSProperties;
	connectedRef?: React.ReactInstance;
	labelForFrame?: string;
	minWidth?: boolean;
	required?: boolean;
	theme?: Theme;
	fontSize?: number;
	color?: 'black';
	bold?: boolean;
	children?: React.ReactNode;
	onClickOutside?: () => void;
	onChange: (date: Moment) => void;
	onFocus?: () => void;
} & IBaseControl.props<string> &
	IBaseControl.handlers;

type DatePickerState = {
	isOpen: boolean;
	popoverAnchorEl: HTMLElement;
	date: string;
	mountCalendar: boolean;
	triggerIsFocused: boolean;
	mountInput: boolean;
};

const autoCorrectedDatePipe = createAutoCorrectedDatePipe('dd/mm/yyyy');
const MaskedTextInputFiled = withMaskHOC(
	TextField,
	[/[0-3]/, /\d/, '.', /(0|1)/, /\d/, '.', /(1|2)/, /\d/, /\d/, /\d/],
	{
		keepCharPositions: true,
		guide: true,
		pipe: autoCorrectedDatePipe,
	},
);

const DATE_FORMAT = 'DD.MM.YYYY';
const DATE_INPUT = 'DATE_INPUT';

const validateDateInput = (value: string): boolean => {
	const pattern = /^\d{0,2}\.\d{0,2}\.\d{0,4}$/g;

	return pattern.test(value);
};

const validateDateInputForSeparateMode = (value: string): boolean => {
	const pattern = /^\d{2}\.\d{2}\.\d{4}$/g;

	return pattern.test(value);
};

const checkDate = (value: string): boolean => {
	const currentDate = moment(value, DATE_FORMAT);
	const checkDate = moment('01-01-1970', 'DD-MM-YYYY');

	return currentDate.isAfter(checkDate);
};

class DatePicker extends React.PureComponent<DatePickerProps, DatePickerState> {
	static defaultProps = {
		modalMode: false,
		date: moment().format(DATE_FORMAT),
		rangePosition: 'right',
		anchorHorizontal: 'left',
		anchorVertical: 'bottom',
		targetHorizontal: 'left',
		targetVertical: 'top',
	} as any;

	state = {
		isOpen: this.props.frameMode || false,
		popoverAnchorEl: null,
		date: this.props.value ? moment(this.props.value, 'DD-MM-YYYY').format(DATE_FORMAT) : DatePicker.defaultProps.date,
		mountCalendar: true,
		triggerIsFocused: false,
		mountInput: true,
	};

	inputRef = null;

	componentDidUpdate(prevProps: DatePickerProps) {
		const formattedDate = moment(this.props.value, 'DD-MM-YYYY').format(DATE_FORMAT);

		if (this.props.value && checkDate(formattedDate) && formattedDate !== this.state.date && this.state.date) {
			this.setState(
				{
					date: formattedDate,
					mountCalendar: false,
				},
				() => {
					this.setState({
						mountCalendar: true,
					});
				},
			);
		}
	}

	setInputRef = node => {
		this.inputRef = node;
	};

	setInputFocus = () => {
		const node = ReactDOM.findDOMNode(this.inputRef) as HTMLElement;
		const input = node.querySelector('input');
		input.focus();
	};

	handleResetDate = () => {
		this.setState(
			{
				date: '',
				mountInput: false,
			},
			() => {
				this.setState(
					{
						mountInput: true,
					},
					() => {
						this.setInputFocus();
					},
				);
			},
		);
	};

	handleOpenDateRangePicker = ev => {
		ev.preventDefault();
		ev.stopPropagation();

		this.setState({
			isOpen: true,
			popoverAnchorEl: ev.currentTarget,
		});
	};

	handleCloseDatePicker = () => {
		setTimeout(() => {
			this.setState({
				isOpen: false,
			});
		});
	};

	emitOnChangeEvent = (date: string): void => {
		const { onChange } = this.props;
		const dateMoment = moment(date, DATE_FORMAT);

		onChange(dateMoment);
		this.handleCloseDatePicker();
	};

	handleChangeDate = date => {
		const dateFormatted = date.format(DATE_FORMAT);

		this.setState({
			date: dateFormatted,
		});

		this.emitOnChangeEvent(dateFormatted);
	};

	handleEnterDate = (ev, str) => {
		if (!ev && !str) {
			this.handleResetDate();
			return;
		}

		const setDatesWithInput = (value: string) => {
			if (validateDateInput(value)) {
				const date = value;

				this.setState(
					{
						date,
						mountCalendar: false,
					},
					() => {
						const isValidDate = moment(date, DATE_FORMAT).isValid();

						if (isValidDate) {
							const { separateMode } = this.props;
							const { date } = this.state;

							if (separateMode && validateDateInputForSeparateMode(value)) {
								this.emitOnChangeEvent(date);
							}

							if (!separateMode) {
								this.emitOnChangeEvent(date);
							}
						}

						this.setState({
							mountCalendar: true,
						});
					},
				);
			}
		};

		const { name, value } = ev.target;

		setDatesWithInput(value);
	};

	handleOnFocus = () => {
		const { onFocus } = this.props;

		this.setInputFocus();
		this.setState(
			{
				triggerIsFocused: true,
			},
			() => {
				onFocus && onFocus();
			},
		);
	};

	handleOnBlur = () => {
		const { value } = this.props;
		const { date } = this.state;

		if (!date) {
			this.setState({
				date: value ? moment(value, 'DD-MM-YYYY').format(DATE_FORMAT) : DatePicker.defaultProps.date,
				triggerIsFocused: false,
			});
		} else {
			this.setState({
				triggerIsFocused: false,
			});
		}
	};

	renderCalendar = () => {
		const { date, mountCalendar } = this.state;
		const { disablePastDates, disableFutureDates, theme } = this.props;
		const datePickerTheme = createDatePickerTheme(theme);
		const minDate = disablePastDates ? moment() : undefined;
		const maxDate = disableFutureDates ? moment() : undefined;
		const setDate = () => date;

		if (mountCalendar) {
			return (
				<Calendar
					date={setDate}
					format={DATE_FORMAT}
					lang='ru'
					firstDayOfWeek={1}
					minDate={minDate}
					maxDate={maxDate}
					theme={datePickerTheme}
					onChange={this.handleChangeDate}
				/>
			);
		}

		return null;
	};

	renderWithPopover = () => {
		const { anchorHorizontal, anchorVertical, targetHorizontal, targetVertical } = this.props;
		const { isOpen, popoverAnchorEl } = this.state;
		const anchorOriginSet = {
			horizontal: anchorHorizontal,
			vertical: anchorVertical,
		};
		const targetOrigin = {
			horizontal: targetHorizontal,
			vertical: targetVertical,
		};

		return (
			<Popover
				open={isOpen}
				anchorEl={popoverAnchorEl}
				anchorOrigin={anchorOriginSet}
				targetOrigin={targetOrigin}
				onRequestClose={this.handleCloseDatePicker}>
				<DatePickerLayout>{this.renderCalendar()}</DatePickerLayout>
			</Popover>
		);
	};

	renderWithDialog = () => {
		const { isOpen } = this.state;

		return (
			<Dialog open={isOpen} contentStyle={dialogContentStyle} onRequestClose={this.handleCloseDatePicker}>
				{this.renderCalendar()}
			</Dialog>
		);
	};

	renderDefaultTrigger = () => {
		const { disabled, readonly, required, minWidth } = this.props;
		const { mountInput } = this.state;
		const blocked = disabled || readonly;
		const inputStyle = this.props.inputStyle ? { ...this.props.inputStyle } : {};
		if (minWidth) {
			inputStyle.width = 72;
		}

		return (
			<InputLayout
				onClick={!blocked ? this.handleOnFocus : () => {}}
				disabled={this.props.disabled}
				readonly={this.props.readonly}>
				{mountInput && (
					<MaskedTextInputFiled
						ref={this.setInputRef}
						value={this.state.date}
						labelText={this.props.labelText}
						name={DATE_INPUT}
						onChange={this.handleEnterDate}
						fullWidth={this.props.fullWidth}
						style={inputStyle}
						size={this.props.size}
						hintText={this.props.hintText}
						disabled={this.props.disabled}
						readonly={this.props.readonly}
						errorText={this.props.errorText}
						informationText={this.props.informationText}
						successText={this.props.successText}
						warningText={this.props.warningText}
						errorStyle={this.props.errorStyle}
						required={required}
						onBlur={this.handleOnBlur}
						withClearBtn={!minWidth && !blocked}
					/>
				)}
			</InputLayout>
		);
	};

	renderWithFrame = () => {
		const { labelForFrame, onClickOutside } = this.props;

		return (
			<ClickOutsideTracker onClickOutside={onClickOutside}>
				<FrameLayout>
					{labelForFrame && <DatePickerLabel>{labelForFrame}</DatePickerLabel>}
					<DatePickerLayout>{this.renderCalendar()}</DatePickerLayout>
				</FrameLayout>
			</ClickOutsideTracker>
		);
	};

	renderWithoutFrame = () => {
		const { modalMode, children, disabled, readonly } = this.props;

		const blocked = disabled || readonly;

		return (
			<div>
				<TriggerLayout onClick={!blocked ? this.handleOpenDateRangePicker : () => {}}>
					{children || this.renderDefaultTrigger()}
				</TriggerLayout>
				{modalMode ? this.renderWithDialog() : this.renderWithPopover()}
			</div>
		);
	};

	handleRootClick = (e: React.SyntheticEvent) => {
		const { connectedRef } = this.props;

		if (connectedRef) {
			const node = ReactDOM.findDOMNode(connectedRef) as HTMLElement;
			const input = node.querySelector('input');
			input.focus();
		}

		e.stopPropagation();
	};

	render() {
		const { frameMode, separateMode, inlineMode, value, fullWidth, disabled, rootStyle, fontSize, color, bold } =
			this.props;
		const { date } = this.state;
		const rootStyleComputed = {
			...rootStyle,
			display: fullWidth ? 'block' : 'inline-block',
			width: fullWidth ? '100%' : 'auto',
		};

		if (separateMode) {
			return (
				<Root className='input input_type_date' style={rootStyleComputed}>
					{this.renderDefaultTrigger()}
				</Root>
			);
		}

		if (inlineMode) {
			return (
				<InlineModeRoot
					disabled={disabled}
					fontSize={fontSize}
					color={color}
					bold={bold}
					onClick={disabled ? () => {} : this.handleOpenDateRangePicker}>
					{formatDate(date || value)}
					<IconLayout>
						<DropdownIcon color={disabled ? 'muted' : color === 'black' ? 'black' : 'accent'} size={16} />
					</IconLayout>
					{this.renderWithPopover()}
				</InlineModeRoot>
			);
		}

		return (
			<Root onClick={this.handleRootClick} className='input input_type_date' style={rootStyleComputed}>
				{frameMode ? this.renderWithFrame() : this.renderWithoutFrame()}
			</Root>
		);
	}
}

const XDatePicker = withTheme(DatePicker);

export { XDatePicker as DatePicker };
