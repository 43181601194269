import React from 'react';

interface IDisplayControllerProps {
	currentPath?: string;
	children: React.ReactNode;
}

interface IDisplayStepProps {
	path?: string;
	params?: Array<{ name: string; value: string }>;
	children: React.ReactNode;
}

class DisplayController extends React.Component<IDisplayControllerProps> {
	private params: Array<{ name: string; value: string }> = [];

	matchPath = (currentPath, candidatePath) => {
		let match = false;
		const splitCurrentPath = currentPath.split('/');
		const splitCandidatePath = candidatePath.split('/');

		if (splitCurrentPath.length !== splitCandidatePath.length) {
			return false;
		}

		for (let i = 0; i < splitCurrentPath.length; i++) {
			if (splitCurrentPath[i] === splitCandidatePath[i]) {
				match = true;
				continue;
			}

			if (splitCurrentPath[i] !== splitCandidatePath[i] && splitCandidatePath[i][0] === ':') {
				match = true;
				this.params = this.params.concat({
					name: splitCandidatePath[i].replace(':', ''),
					value: splitCurrentPath[i],
				});
			} else {
				match = false;
				break;
			}
		}

		return match;
	};

	renderStep = () => {
		const { currentPath, children } = this.props;

		if (children) {
			const childrenArray = React.Children.map(children, child => child);
			const filteredChildrenArray = childrenArray.filter((child: React.ReactElement<IDisplayStepProps>) =>
				this.matchPath(currentPath, child.props.path),
			);

			return React.Children.map(filteredChildrenArray, (child: React.ReactElement<any>) => {
				return React.cloneElement(child, {
					params: this.params,
				});
			});
		}

		return null;
	};

	render() {
		return <div style={{ width: '100%' }}>{this.renderStep()}</div>;
	}
}

class DisplayStep extends React.Component<IDisplayStepProps> {
	renderStep = () => {
		const { params, children } = this.props;

		if (children) {
			if (typeof children === 'function' || typeof children === 'object') {
				return React.Children.map(children, (child: React.ReactElement<any>) => {
					return React.cloneElement(child, {
						displayCtrlParams: params,
					});
				});
			}

			return children;
		}

		return null;
	};

	render() {
		return <div style={{ width: '100%' }}>{this.renderStep()}</div>;
	}
}

export { DisplayController, DisplayStep };
