import React from 'react';
import styled, { css } from 'styled-components';

import { Theme } from '@theme';

export type IconBaseProps = {
	color?: IconColor;
	size?: number;
} & React.HTMLAttributes<unknown>;

const IconBase: React.FC<IconBaseProps> = props => {
	return <IconStyled {...props} />;
};

IconBase.defaultProps = {
	color: 'muted',
	size: 16,
};

type IconStyledProps = IconBaseProps;

const blackListForwardPropsMap = {
	size: true,
	color: true,
};

const shouldForwardProp = (prop: string, defaultValidatorFn: Function) => {
	return !blackListForwardPropsMap[prop] && defaultValidatorFn(prop);
};

const IconStyled = styled.i.withConfig({
	shouldForwardProp,
})<IconStyledProps>`
	display: inline-flex;
	justify-content: center;
	align-items: center;
	color: currentColor;

	${p =>
		p.color &&
		css`
			color: ${getColor(p.color, p.theme)};
		`}

	${p =>
		p.size &&
		css`
			width: ${p.size}px;
			height: ${p.size}px;

			& > svg {
				width: ${p.size}px;
				height: ${p.size}px;
			}
		`}
`;

type IconColor =
	| 'accent'
	| 'muted'
	| 'super-muted'
	| 'black'
	| 'alarm'
	| 'warning'
	| 'light'
	| 'positive'
	| 'negative'
	| 'current'
	| 'black-always';

const getColor = (colorAlias: string, theme: Theme) => {
	const colorMap: Record<IconColor, string> = {
		accent: theme.palette.accent,
		muted: theme.palette.label,
		'super-muted': theme.palette.superMuted,
		black: theme.palette.text,
		alarm: theme.palette.alarm,
		warning: theme.palette.warning,
		light: theme.palette.textContrast,
		positive: theme.palette.positive,
		negative: theme.palette.negative,
		current: 'currentColor',
		'black-always': '#000',
	};

	return colorMap[colorAlias] || 'currentColor';
};

export { IconBase };
