import { createSelector } from 'reselect';
import * as _ from 'underscore';

import { IAppState } from '@store';


function selectCMSMap(state: IAppState) {
	return state.platform.externalSystemAccount.cashManagementSystems.item;
}

const selectCMSList = createSelector(
	selectCMSMap,
	(cmsMap: Record<string, CashManagementSystem>) => Object.keys(cmsMap).map(key => cmsMap[key]),
);

const selectCMSByGUIDMap = createSelector(
	selectCMSList,
	(cmsList: Array<CashManagementSystem>) => _.indexBy(cmsList, el => el.SubsystemInstanceGUID),
);

function selectCMSIsFetching(state: IAppState): boolean {
	return state.platform.externalSystemAccount.cashManagementSystems.isFetching;
}

function selectCMSIsLoaded(state: IAppState): boolean {
	return state.platform.externalSystemAccount.cashManagementSystems.isLoaded;
}

function selectCMSDidInvalidate(state: IAppState): boolean {
	return state.platform.externalSystemAccount.cashManagementSystems.didInvalidate;
}

export {
	selectCMSMap,
	selectCMSByGUIDMap,
	selectCMSList,
	selectCMSIsFetching,
	selectCMSIsLoaded,
	selectCMSDidInvalidate,
};
