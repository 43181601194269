import { createSelector } from 'reselect';
import * as _ from 'underscore';
import * as moment from 'moment';
import { compose } from 'redux';

import { IAppState } from '@store';
import { sortDescBy, getPropInSafe, sortTreeByAlphabet } from '@utils';
import { BASE_DATE_FORMAT } from '@shared/constants/time';
import { mainCashflowItemsSelectorsPack } from '@cashflow-items/selectors';
import { mainPlanningSelectorsPack } from './main-planning.selectors';
import {
	getCashflowItemsForTable,
	getEmptyReportData,
	getCompletedBalanceList,
	filterCashflowReportDataByText,
	filterCashflowReportDataByCell,
	buildReportTableColumns,
	getCompletedAciSeriesMap,
	buildReportTableColumnsWithTree,
	buildReportTableColumnsForNetCashflow,
	filterPLOperationsByCell,
} from '../../analytics/selectors/reporting.selectors';
import { ReportCashGape, ReportTableCell, ReportTableRow } from '@analytics/components/reporting/shared/report-table';

function selectPlanningPeriod(state: IAppState) {
	return state.plPlanning.main.period;
}

function selectPlanningCashflowForecast(state: IAppState) {
	return state.plPlanning.main.cfForecast.item;
}

function selectPlanningCashflowForecastIsFetching(state: IAppState) {
	return state.plPlanning.main.cfForecast.isFetching;
}

function selectPlanningCashflowForecastIsLoaded(state: IAppState) {
	return state.plPlanning.main.cfForecast.isLoaded;
}

function selectPlanningCashflowForecastDidInvalidate(state: IAppState) {
	return state.plPlanning.main.cfForecast.didInvalidate;
}

function selectPlanningAciSeriesMap(state: IAppState) {
	return state.plPlanning.main.aciSeries.item;
}

function selectPlanningAciSeriesMapIsFetching(state: IAppState) {
	return state.plPlanning.main.aciSeries.isFetching;
}

function selectPlanningAciSeriesMapIsLoaded(state: IAppState) {
	return state.plPlanning.main.aciSeries.isLoaded;
}

function selectPlanningAciSeriesMapDidInvalidate(state: IAppState) {
	return state.plPlanning.main.aciSeries.didInvalidate;
}

function selectPlanningFilterByText(state: IAppState) {
	return state.plPlanning.main.substr;
}

function selectSelectedPlanningTableCell(state: IAppState) {
	return state.plPlanning.main.selectedCell;
}

function selectSelectedPlanningChartPont(state: IAppState) {
	return state.plPlanning.main.selectedPoint;
}

export type PlanningChartPoint = {
	date: string;
	value: number;
	cashGap: boolean;
};

export type PlanningChartData = {
	factBalances: Array<PlanningChartPoint>;
	planBalances: Array<PlanningChartPoint>;
};

const selectDataForPlanningChart: (s) => PlanningChartData = createSelector(
	selectPlanningCashflowForecast,
	(cfForecast: CashflowForecast) => {
		const data = {
			factBalances: [],
			planBalances: [],
		};
		const balances = getPropInSafe(cfForecast, o => o.CashBalances, []);
		const factBalances = balances.filter(el => !el.Plan);
		const planBalances = balances.filter(el => el.Plan);

		data.factBalances = factBalances.map(el => {
			return {
				date: el.ValueDate,
				value: el.OutgoingBalance,
				cashGap: false,
			};
		});

		for (let i = 0; i < planBalances.length; i++) {
			const prevValue = planBalances[i - 1]
				? planBalances[i - 1].OutgoingBalance
				: factBalances[factBalances.length - 1]
				? factBalances[factBalances.length - 1].OutgoingBalance
				: 0;

			if (prevValue >= 0 && planBalances[i].OutgoingBalance < 0) {
				data.planBalances.push({
					date: planBalances[i].ValueDate,
					value: 0,
					cashGap: true,
				});
			}

			data.planBalances.push({
				date: planBalances[i].ValueDate,
				value: planBalances[i].OutgoingBalance,
				cashGap: false,
			});
		}

		if (data.factBalances.length > 0) {
			data.planBalances.unshift(data.factBalances[data.factBalances.length - 1]);
		}

		return data;
	},
);

function buildPlanningTableCashflowTreeList(ciMap: Record<string, CashflowItem>): Array<any> {
	const list = [];
	const ciList = Object.keys(ciMap).map(key => ciMap[key]);
	sortTreeByAlphabet<CashflowItem | AccountsChartItem>(ciList, el => el.Name);

	const incomeCiList = ciList.filter(ci => ci.ID > 0 && ci.Incoming);
	const expenseCiList = ciList.filter(ci => ci.ID > 0 && !ci.Incoming);
	const incomeCiChildItemsList = getCashflowItemsForTable(incomeCiList, -3);
	const expenseCiChildItemsList = getCashflowItemsForTable(expenseCiList, -5);

	list.push({
		ID: -2,
		parentID: -1,
		childItemsIDList: [],
		name: 'Входящий остаток',
		columns: [],
	});

	list.push({
		ID: -3,
		parentID: -1,
		childItemsIDList: [-4, ...incomeCiChildItemsList.map(i => i.ID)],
		name: 'Поступления',
		columns: [],
	});

	list.push({
		ID: -4,
		parentID: -3,
		childItemsIDList: [],
		name: 'Нераспределенные поступления',
		columns: [],
		parentName: 'Поступления',
	});

	list.push(...incomeCiChildItemsList);

	list.push({
		ID: -5,
		parentID: -1,
		childItemsIDList: [-6, ...expenseCiChildItemsList.map(i => i.ID)],
		name: 'Выплаты',
		columns: [],
	});

	list.push({
		ID: -6,
		parentID: -5,
		childItemsIDList: [],
		name: 'Нераспределенные выплаты',
		columns: [],
		parentName: 'Выплаты',
	});

	list.push(...expenseCiChildItemsList);

	list.push({
		ID: -7,
		parentID: -1,
		childItemsIDList: [],
		name: 'Чистый денежный поток',
		columns: [],
	});

	list.push({
		ID: -8,
		parentID: -1,
		childItemsIDList: [],
		name: 'Исходящий остаток',
		columns: [],
	});

	return list;
}

function checkRowIDForDetalization(cell: ReportTableCell) {
	return cell && cell.row && cell.row.ID !== -2 && cell.row.ID !== -8;
}

const selectRowsForPlanningTable = createSelector(
	selectPlanningPeriod,
	selectPlanningAciSeriesMap,
	selectPlanningCashflowForecast,
	mainCashflowItemsSelectorsPack.selectCashflowItemsMap,
	selectPlanningFilterByText,
	selectSelectedPlanningTableCell,
	(period, aciSeriesMap, cfForecast, ciMap, substr, tableCell) => {
		const data = buildPlanningTableCashflowTreeList(ciMap);
		const dataMap = _.indexBy(data, d => d.ID);
		const dateStartMoment = moment(period.dateStart, BASE_DATE_FORMAT).startOf('quarter');
		const dateEndMoment = moment(period.dateEnd, BASE_DATE_FORMAT).endOf('quarter');
		const cashBalanceList = cfForecast.CashBalances || [];
		const completedBalanceList = getCompletedBalanceList(dateStartMoment, dateEndMoment, cashBalanceList);
		const emptyData = getEmptyReportData(dateStartMoment, dateEndMoment);
		const immutableAciSeriesMap = { ...aciSeriesMap };

		if (immutableAciSeriesMap[-1]) {
			immutableAciSeriesMap[-4] = { ...immutableAciSeriesMap[-1] };
			immutableAciSeriesMap[-6] = { ...immutableAciSeriesMap[-1] };
			immutableAciSeriesMap[-4].Points = immutableAciSeriesMap[-4].Points.filter(p => p.DirectionIncoming);
			immutableAciSeriesMap[-6].Points = immutableAciSeriesMap[-6].Points.filter(p => !p.DirectionIncoming);
		}

		const completedAciSeriesMap = getCompletedAciSeriesMap({
			dateStartMoment,
			dateEndMoment,
			aciSeriesMap: immutableAciSeriesMap,
			ciMap,
			planningMode: true,
			undistributedIncomeKey: '-4',
		});

		for (let i = 0; i < data.length; i++) {
			const d = data[i];
			d.columns = emptyData;

			if (d.ID === -2) {
				const columns = buildReportTableColumns({
					list: completedBalanceList,
					dateSelector: (b: CashBalanceRecord) => b.ValueDate,
					amountSelector: (b: CashBalanceRecord) => b.IncomingBalance,
					forIncomingBalance: true,
					planningMode: true,
				});

				d.columns = columns.length > 0 ? columns : emptyData;
			}

			if (d.ID === -3 || d.ID == -5 || d.ID > 0) {
				const columns = buildReportTableColumnsWithTree(d.ID, completedAciSeriesMap, dataMap, false, true);

				d.columns = columns.length > 0 ? columns : emptyData;
			}

			if (d.ID === -4) {
				const points = completedAciSeriesMap[-4] ? [...completedAciSeriesMap[-4].Points] : [];

				const columns = buildReportTableColumns({
					list: points,
					dateSelector: (p: PLOperationTimeSeriesPoint) => p.ValueDate,
					amountSelector: (p: PLOperationTimeSeriesPoint) => p.Amount,
					incomeSelector: (p: PLOperationTimeSeriesPoint) => true,
					planningMode: true,
				});

				d.columns = columns.length > 0 ? columns : emptyData;
			}

			if (d.ID === -6) {
				const points = completedAciSeriesMap[-6] ? [...completedAciSeriesMap[-6].Points] : [];

				const columns = buildReportTableColumns({
					list: points,
					dateSelector: (p: PLOperationTimeSeriesPoint) => p.ValueDate,
					amountSelector: (p: PLOperationTimeSeriesPoint) => p.Amount,
					incomeSelector: (p: PLOperationTimeSeriesPoint) => false,
					planningMode: true,
				});

				d.columns = columns.length > 0 ? columns : emptyData;
			}

			if (d.ID === -8) {
				const columns = buildReportTableColumns({
					list: completedBalanceList,
					dateSelector: (b: CashBalanceRecord) => b.ValueDate,
					amountSelector: (b: CashBalanceRecord) => b.IncomingBalance,
					forOutgoingBalance: true,
					planningMode: true,
				});

				d.columns = columns.length > 0 ? columns : emptyData;
			}

			if (d.ID === -7) {
				const columns = buildReportTableColumnsForNetCashflow({
					ID: d.ID,
					IDList: [-3, -5],
					dataMap,
				});

				d.columns = columns.length > 0 ? columns : emptyData;
			}
		}

		const filteredData = compose(
			(data: Array<ReportTableRow>) => filterCashflowReportDataByText(substr, data),
			(data: Array<ReportTableRow>) =>
				filterCashflowReportDataByCell({
					cell: tableCell,
					listMap: dataMap,
					list: data,
					checkRowIDForDetalization,
				}),
		)(data);

		return filteredData;
	},
);

const selectCashGapes: (s) => Array<ReportCashGape> = createSelector(
	selectPlanningCashflowForecast,
	(cfForecast: CashflowForecast) => {
		const cashGapes: Array<ReportCashGape> = [];
		const balances = cfForecast.CashBalances || [];

		for (let i = 0; i < balances.length; i++) {
			const balanceRecord = balances[i];

			if (balanceRecord.Plan && balances[i - 1]) {
				if (balances[i - 1].IncomingBalance >= 0 && balances[i].IncomingBalance < 0) {
					cashGapes.push({
						date: balances[i - 1].ValueDate,
						value: balances[i].IncomingBalance,
						isOutgoing: false,
					});
				}
				if (balances[i - 1].OutgoingBalance >= 0 && balances[i].OutgoingBalance < 0) {
					cashGapes.push({
						date: balances[i].ValueDate,
						value: balances[i].OutgoingBalance,
						isOutgoing: true,
					});
				}
			}
		}

		return cashGapes;
	},
);

const selectHeaderForPlanningTable = createSelector(selectPlanningPeriod, (period: DateRange) => {
	const columns = [];
	const dateStartMoment = moment(period.dateStart, BASE_DATE_FORMAT).startOf('quarter');
	const dateEndMoment = moment(period.dateEnd, BASE_DATE_FORMAT).endOf('quarter');
	const diffInMonths = dateEndMoment.clone().add(1, 'day').diff(dateStartMoment, 'months');
	const curMonthMoment = moment().endOf('month');
	const currQuarterMoment = moment().endOf('quarter');
	const dates = [];

	for (let i = 0; i < diffInMonths; i++) {
		let date = null;

		if (dates[i - 1]) {
			date = moment(dates[i - 1], BASE_DATE_FORMAT)
				.add(1, 'month')
				.endOf('month')
				.format(BASE_DATE_FORMAT);
		} else {
			date = dateStartMoment.endOf('month').format(BASE_DATE_FORMAT);
		}

		dates.push(date);
	}

	for (let i = 0; i < dates.length; i++) {
		const dateMoment = moment(dates[i], BASE_DATE_FORMAT);
		const dateEndOfMonth = dateMoment.clone().endOf('month');
		const dateEndOfQuarter = dateMoment.clone().endOf('quarter');
		const dateEndOfYear = dateMoment.clone().endOf('year');

		if (dateMoment.isSame(dateEndOfMonth, 'day')) {
			columns.push({
				reduceBy: 'MONTH',
				date: dateMoment.format('MMMM YYYY'),
				name: dateMoment.isBefore(curMonthMoment, 'month')
					? `${dateMoment.format('MMMM')} (Факт)`
					: dateMoment.isSame(curMonthMoment, 'month')
					? `${dateMoment.format('MMMM')} (Факт + План)`
					: `${dateMoment.format('MMMM')} (План)`,
			});
		}

		if (dateMoment.isSame(dateEndOfQuarter, 'day')) {
			columns.push({
				reduceBy: 'QUARTER',
				date: dateMoment.format('Q YYYY'),
				name: dateMoment.isBefore(currQuarterMoment, 'month')
					? 'Итого (Факт)'
					: dateMoment.isSame(currQuarterMoment, 'month')
					? 'Итого (Факт + План)'
					: 'Итого (План)',
			});
		}

		if (dateMoment.isSame(dateEndOfYear, 'day')) {
			columns.push({
				reduceBy: 'YEAR',
				date: dateMoment.format('YYYY'),
				name: dateMoment.isBefore(currQuarterMoment, 'year')
					? 'Итого за год (Факт)'
					: dateMoment.isSame(currQuarterMoment, 'year')
					? 'Итого за год (Факт + План)'
					: 'Итого за год (План)',
			});
		}
	}

	return columns;
});

const selectPLOperationsListForPlanning = (isPlan: boolean) =>
	createSelector(
		mainPlanningSelectorsPack.selectAsyncPlOperations.selectItem,
		selectSelectedPlanningTableCell,
		selectSelectedPlanningChartPont,
		mainCashflowItemsSelectorsPack.selectCashflowItemsMap,
		(plList = [], cell, datePoint, ciMap) => {
			const currMonthMoment = moment().endOf('month');
			const filteredList = plList.filter(pl => {
				const dateMoment = moment(pl.CashflowDate, BASE_DATE_FORMAT);

				return isPlan
					? pl.Status === 1 &&
							(dateMoment.isAfter(currMonthMoment, 'month') || dateMoment.isSame(currMonthMoment, 'month'))
					: pl.Status === 2 &&
							(dateMoment.isBefore(currMonthMoment, 'month') || dateMoment.isSame(currMonthMoment, 'month'));
			});
			const sorterdList = sortDescBy(filteredList, [
				{ fn: item => item.CashflowDate, isDate: true },
				{ fn: item => item.ID },
			]);
			let selectedList = [];

			if (cell.row) {
				const col = cell.row.columns[cell.col];
				const rowID = cell.row.ID;

				if (rowID === -3) {
					selectedList = filterPLOperationsByCell(
						false,
						sorterdList,
						rowID,
						ciMap,
						col,
						(pl: PLOperation) => !pl.Expense,
						undefined,
						true,
					);
				}

				if (rowID === -4) {
					selectedList = filterPLOperationsByCell(
						false,
						sorterdList,
						rowID,
						ciMap,
						col,
						(pl: PLOperation) => !pl.Expense,
					);
				}

				if (rowID === -5) {
					selectedList = filterPLOperationsByCell(
						false,
						sorterdList,
						rowID,
						ciMap,
						col,
						(pl: PLOperation) => pl.Expense,
						undefined,
						true,
					);
				}

				if (rowID === -6) {
					selectedList = filterPLOperationsByCell(
						false,
						sorterdList,
						rowID,
						ciMap,
						col,
						(pl: PLOperation) => pl.Expense,
					);
				}

				if (rowID === -7) {
					selectedList = filterPLOperationsByCell(false, sorterdList, rowID, ciMap, col, undefined, undefined, true);
				}

				if (rowID > 0) {
					selectedList = filterPLOperationsByCell(false, sorterdList, rowID, ciMap, col);
				}
			}

			if (datePoint) {
				selectedList = sorterdList.filter(pl => pl.CashflowDate === datePoint);
			}

			return selectedList;
		},
	);

const selectDataForCPWidgetDetalization = (isPlan: boolean) =>
	createSelector(selectPLOperationsListForPlanning(isPlan), plList => {
		const grouppedMap = _.groupBy(plList, pl => getPropInSafe(pl, o => o.Counterparty.ID, -1));

		const list = Object.keys(grouppedMap).map(key => {
			return {
				cp: grouppedMap[key][0].Counterparty ? grouppedMap[key][0].Counterparty : null,
				totalAmount: grouppedMap[key].reduce(
					(acc, pl) => (!pl.Expense ? Number(acc) + Number(pl.Amount) : acc - pl.Amount),
					0,
				),
			};
		});

		return list;
	});

const selectDataForStatsWidgetDetalization = createSelector(
	selectSelectedPlanningTableCell,
	(cell: ReportTableCell) => {
		const index = cell.col;
		const columns = getPropInSafe(cell, o => o.row.columns, []);
		const col = columns[index];
		let data = null;

		if (col) {
			const prevCol = columns
				.slice(0, index)
				.reverse()
				.find(el => el.reduceBy === col.reduceBy);

			if (prevCol) {
				data = {
					reduceBy: prevCol.reduceBy,
					date: prevCol.date,
					totalAmountCurr: col.value,
					totalAmountPrev: prevCol.value,
				};
			}
		}

		return data;
	},
);

function selectCashflowForecastForForecastKPI(state: IAppState): CashflowForecast {
	return state.plPlanning.main.cfForecastForForecastKPI.item;
}

function selectCashflowForecastForForecastKPIIsFetching(state: IAppState): boolean {
	return state.plPlanning.main.cfForecastForForecastKPI.isFetching;
}

function selectCashflowForecastForForecastKPIIsLoaded(state: IAppState): boolean {
	return state.plPlanning.main.cfForecastForForecastKPI.isLoaded;
}

function selectCashflowForecastForForecastKPIDidInvalidate(state: IAppState): boolean {
	return state.plPlanning.main.cfForecastForForecastKPI.didInvalidate;
}

function selectAciSeriesForKPI(state: IAppState) {
	return state.plPlanning.main.aciSeriesForKPI.item;
}

function selectCashflowForecastKPIDate(state: IAppState): string {
	return state.plPlanning.main.forecastKPIDate;
}

const selectCashflowForecastKPIValue: (s) => number = createSelector(
	selectCashflowForecastForForecastKPI,
	selectCashflowForecastKPIDate,
	(cfForecast: CashflowForecast, date: string) => {
		const balancesByDateMap: Record<string, CashBalanceRecord> = _.indexBy(
			cfForecast.CashBalances || [],
			el => el.ValueDate,
		);

		return balancesByDateMap[date] ? balancesByDateMap[date].OutgoingBalance : 0;
	},
);

const selectCashflowForecastKPIimprecision: (s) => boolean = createSelector(
	selectCashflowForecastForForecastKPI,
	selectAciSeriesForKPI,
	selectCashflowForecastKPIDate,
	(cfForecast, aciSeries, date) => {
		const dateMoment = moment(date, BASE_DATE_FORMAT);
		const expensePlannedValue =
			cfForecast.Expenses &&
			cfForecast.Expenses.filter(el => {
				const pointDateMoment = moment(el.ValueDate, BASE_DATE_FORMAT);

				return el.Plan && pointDateMoment.isSame(dateMoment, 'month');
			}).reduce((acc, el) => acc + el.Amount, 0);
		const expensePoints: Array<PLOperationTimeSeriesPoint> = [];

		Object.keys(aciSeries).forEach(key => {
			const points = aciSeries[key].Points.filter(point => {
				return !point.Plan && !point.DirectionIncoming;
			});

			expensePoints.push(...points);
		});
		const expenseFactValue = expensePoints.reduce((acc, el) => acc + el.Amount, 0);
		const percent = expenseFactValue !== 0 ? (expensePlannedValue / expenseFactValue) * 100 : 0;

		return percent < 30;
	},
);

function selectCashflowForecastForCashGapKPI(state: IAppState): CashflowForecast {
	return state.plPlanning.main.cfForecastForCashGapKPI.item;
}

function selectCashflowForecastForCashGapKPIIsFetching(state: IAppState): boolean {
	return state.plPlanning.main.cfForecastForCashGapKPI.isFetching;
}

function selectCashflowForecastForCashGapKPIIsLoaded(state: IAppState): boolean {
	return state.plPlanning.main.cfForecastForCashGapKPI.isLoaded;
}

function selectCashflowForecastForCashGapKPIDidInvalidate(state: IAppState): boolean {
	return state.plPlanning.main.cfForecastForCashGapKPI.didInvalidate;
}

const selectValueForCashGapKPI: (s) => { date: string; value: number } = createSelector(
	selectCashflowForecastForCashGapKPI,
	(cfForecast: CashflowForecast) => {
		let cashGap = null;
		const balances = cfForecast.CashBalances || [];

		for (let i = 0; i < balances.length; i++) {
			const balanceRecord = balances[i];

			if (balanceRecord.Plan && balances[i - 1]) {
				if (balances[i - 1].OutgoingBalance >= 0 && balances[i].OutgoingBalance < 0) {
					cashGap = {
						date: balances[i].ValueDate,
						value: balances[i].OutgoingBalance,
					};

					break;
				}
			}
		}

		return cashGap;
	},
);

export {
	checkRowIDForDetalization,
	selectPlanningPeriod,
	selectPlanningCashflowForecast,
	selectPlanningCashflowForecastIsFetching,
	selectPlanningCashflowForecastIsLoaded,
	selectPlanningCashflowForecastDidInvalidate,
	selectPlanningAciSeriesMap,
	selectPlanningAciSeriesMapIsFetching,
	selectPlanningAciSeriesMapIsLoaded,
	selectPlanningAciSeriesMapDidInvalidate,
	selectPlanningFilterByText,
	selectSelectedPlanningTableCell,
	selectSelectedPlanningChartPont,
	selectDataForPlanningChart,
	selectRowsForPlanningTable,
	selectHeaderForPlanningTable,
	selectPLOperationsListForPlanning,
	selectCashGapes,
	selectDataForCPWidgetDetalization,
	selectDataForStatsWidgetDetalization,
	selectCashflowForecastForForecastKPI,
	selectCashflowForecastForForecastKPIIsFetching,
	selectCashflowForecastForForecastKPIIsLoaded,
	selectCashflowForecastForForecastKPIDidInvalidate,
	selectCashflowForecastKPIDate,
	selectCashflowForecastKPIValue,
	selectCashflowForecastKPIimprecision,
	selectCashflowForecastForCashGapKPI,
	selectCashflowForecastForCashGapKPIIsFetching,
	selectCashflowForecastForCashGapKPIIsLoaded,
	selectCashflowForecastForCashGapKPIDidInvalidate,
	selectValueForCashGapKPI,
};
