import { createTypes } from '@flux';

type ActionTypes = {
	FETCH_LEGAL_ENTITY_TAX_MODES: string;
	FETCH_CURRENCIES: string;
	FETCH_COUNTRIES: string;
};

export const mainTypes = createTypes<ActionTypes>(
	['FETCH_LEGAL_ENTITY_TAX_MODES', 'FETCH_CURRENCIES', 'FETCH_COUNTRIES'],
	'REFERENCE',
);
