import { Store } from 'redux';

import { IAppState } from '@store';
import { isReceiveAction } from '@flux';
import { types as tenantLegalEntitiesTypes } from '@tenant-legal-entities/actions/types';
import { types as counterpartiesTypes } from '@counterparties/actions/types';
import { invalidateTenantLegalEntities } from '@tenant-legal-entities/actions/invalidators';

function askTenantLegalEntitiesInvalidator(store: Store<IAppState>, action: StoreAction) {
	const { dispatch } = store;
	const syncMap = {
		[tenantLegalEntitiesTypes.RUN_TENANT_LEGAL_ENTITIES_INVALIDATION_EFFECT]: true,
	};
	const asyncMap = {
		[counterpartiesTypes.TRANSFORM_COUNTERPARTY_TO_TENANT_LEGAL_ENTITY]: true,
	};

	if (syncMap[action.type]) {
		dispatch(invalidateTenantLegalEntities());
	} else if (asyncMap[action.type] && isReceiveAction(action)) {
		dispatch(invalidateTenantLegalEntities());
	}
}

function createInvalidatorEffect() {
	return (store: Store<IAppState>) => (next: (action: StoreAction) => void) => (action: StoreAction) => {
		next(action);
		askTenantLegalEntitiesInvalidator(store, action);
	};
}

const runInvalidatorEffect = createInvalidatorEffect();

export { runInvalidatorEffect };
