import { coreApi } from '@core/api/core';
import { counterpartyApi } from '@core/api/counterparty';
import { detectIsLegalEntity } from '@utils/counterparty';

function transformCounterpartyToCounterpartyBrief(source: Counterparty): CounterpartyBrief {
	const object: CounterpartyBrief = {
		...new counterpartyApi.package.CounterpartyBrief(),
		ID: source.ID,
		Name: source.Name,
		TaxCode: source.TaxCode,
		AdditionalTaxCode: (source as LegalEntity).AdditionalTaxCode || '',
		StateRegistrationCode: (source as LegalEntity).StateRegistrationCode || '',
		SoleProprietor: (source as LegalEntity).SoleProprietor,
		Address: (source as LegalEntity).LegalAddress || '',
		LegalEntity: detectIsLegalEntity(source),
	};

	return object;
}

function transformLegalEntityToCounterpartyBrief(source: LegalEntity): CounterpartyBrief {
	const object: CounterpartyBrief = {
		...new counterpartyApi.package.CounterpartyBrief(),
		ID: source.ID,
		Name: source.Name,
		TaxCode: source.TaxCode,
		AdditionalTaxCode: source.AdditionalTaxCode,
		StateRegistrationCode: source.StateRegistrationCode || '',
		SoleProprietor: source.SoleProprietor,
		CompanySealImageURL: source.CompanySealImageURL,
		Address: source.LegalAddress,
		LegalEntity: true,
	};

	return object;
}

function transformCounterpartyBriefToNaturalKey(source: CounterpartyBrief): NaturalKey {
	const object: NaturalKey = {
		...new coreApi.package.NaturalKey(),
		ID: source.ID,
		Name: source.Name,
	};

	return object;
}

export {
	transformCounterpartyToCounterpartyBrief,
	transformLegalEntityToCounterpartyBrief,
	transformCounterpartyBriefToNaturalKey,
};
