import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose, Dispatch } from 'redux';

import { withInvalidateFlag, withValidData } from '../../../../core/hocs/with-valid-data.hoc';
import { IAppState } from '../../../../core/store';
import { logoutAction } from '../../actions/auth.actions';
import { fetchTenantByIDAction } from '../../actions/tenant-account.actions';
import { selectBusinessServiceType, selectTenantBusinessService } from '../../selectors/billing.selectors';
import { selectCurrentTenantID, selectCurrentUserLogin } from '../../selectors/context.selectors';
import { selectTenant, selectTenantDidInvalidate } from '../../selectors/tenant-account.selectors';
import XAppSession, { AppSessionViewProps } from './app-session.view';

type AppSessionProps = {
	fetchTenantByID?: (tenantID: number) => void;
} & Partial<AppSessionViewProps>;

class AppSession extends React.PureComponent<AppSessionProps> {
	static displayName = 'AppSession[Container]';

	render() {
		const { fetchTenantByID, ...rest } = this.props;

		return <XAppSession {...(rest as AppSessionViewProps)} />;
	}
}

function mapStateToProps(state: IAppState) {
	return {
		businessService: selectTenantBusinessService(state),
		businessServiceType: selectBusinessServiceType(state),
		login: selectCurrentUserLogin(state),
		tenant: selectTenant(state),
		tenantID: selectCurrentTenantID(state),
	};
}

function mapDispatchToProps(dispatch: Dispatch<any>) {
	return bindActionCreators(
		{
			logout: logoutAction,
			fetchTenantByID: fetchTenantByIDAction,
		},
		dispatch,
	);
}

export default compose(
	connect<any, any, AppSessionProps>(
		withInvalidateFlag(mapStateToProps, selectTenantDidInvalidate),
		mapDispatchToProps,
	),
	withValidData<AppSessionProps>(props => {
		const { tenantID, fetchTenantByID } = props;

		fetchTenantByID(tenantID);
	}),
)(AppSession);
