// @ts-nocheck
import createAction from '../../../core/libs/action-creator';
import api from '../../../core/libs/api';
import store from '../../../core/store';
import { createActionMessage, getInvoiceStatuses } from '../../../core/libs/utils';

const REVOKE_INVOICE = '[INVOICE]: REVOKE_INVOICE';
const EMIT_INVOICE_CAN_NOT_BE_MODIFIED = '[INVOICE]: EMIT_INVOICE_CAN_NOT_BE_MODIFIED';
const CHANGE_INVOICE = '[INVOICE]: CHANGE_INVOICE';
const SET_RESPONSIBLE_EMPLOYEE = '[INVOICE]: SET_RESPONSIBLE_EMPLOYEE';

const revokeInvoiceAction = createAction(REVOKE_INVOICE, revokeInvoice, {
	showMessage: type => type === 'RECEIVE' && createActionMessage('Счёт отозван', 'success'),
});
const emitInvoiceCanNotBeModifiedAction = createAction(EMIT_INVOICE_CAN_NOT_BE_MODIFIED, () => Promise.resolve(), {
	showMessage: type =>
		type === 'RECEIVE' &&
		createActionMessage('Счёт не может быть изменён, так как он уже передан покупателем в оплату', 'warning'),
});
const changeInvoiceAction = createAction(CHANGE_INVOICE, changeInvoice, {
	showMessage: type => type === 'RECEIVE' && createActionMessage('Счёт изменён', 'success'),
});

function revokeInvoice(api, getState, dispatch, invoiceID: number, from: string, to: string, mailMessage: string) {
	return revokeInvoiceMethod(invoiceID, from, to, mailMessage, true);
}

function changeInvoice(api, getState, dispatch, invoice: Invoice, withEmit: boolean = false) {
	return changeInvoiceMethod(invoice, withEmit);
}

function revokeInvoiceMethod(
	invoiceID: number,
	from: string,
	to: string,
	mailMessage: string = ' ',
	withEmit: boolean = false,
) {
	return new Promise(resolve => {
		checkInvoiceCanBeModifiedMethod(invoiceID).then((msg: SuccessMessage) => {
			if (msg.Success) {
				api.financialDocumentServiceClient.revokeDocument(invoiceID, from, to, mailMessage, (msg: SuccessMessage) => {
					resolve(msg);
				});
			} else {
				withEmit && store.dispatch(emitInvoiceCanNotBeModifiedAction());
				resolve(null);
			}
		});
	});
}

function checkInvoiceCanBeModifiedMethod(invoiceID: number) {
	return new Promise(resolve => {
		api.financialDocumentServiceClient.checkInvoiceCanBeModified(invoiceID, (msg: SuccessMessage) => {
			resolve(msg);
		});
	});
}

function changeInvoiceMethod(invoice: Invoice, withEmit: boolean = false) {
	return new Promise(resolve => {
		const { isDraft, isPaid } = getInvoiceStatuses(invoice);

		if (invoice.Outgoing && !isDraft && !isPaid) {
			checkInvoiceCanBeModifiedMethod(invoice.ID).then((msg: SuccessMessage) => {
				if (msg.Success) {
					api.financialDocumentServiceClient.changeDocument(invoice, resolve);
				} else {
					withEmit && store.dispatch(emitInvoiceCanNotBeModifiedAction());
					resolve(invoice);
				}
			});
		} else {
			api.financialDocumentServiceClient.changeDocument(invoice, resolve);
		}
	});
}

function setResponsibleEmplMethod(invoiceID: number, emplID: number) {
	return new Promise(resolve => {
		api.financialDocumentServiceClient.setResponsibleEmployee(invoiceID, emplID, (result: boolean) => {
			resolve(JSON.parse(result + ''));
		});
	});
}

export const methods = {
	revokeInvoiceMethod,
	checkInvoiceCanBeModifiedMethod,
	changeInvoiceMethod,
	setResponsibleEmplMethod,
};

export const constants = {
	REVOKE_INVOICE,
	EMIT_INVOICE_CAN_NOT_BE_MODIFIED,
	CHANGE_INVOICE,
	SET_RESPONSIBLE_EMPLOYEE,
};

export const actions = {
	revokeInvoiceAction,
	emitInvoiceCanNotBeModifiedAction,
	changeInvoiceAction,
};
