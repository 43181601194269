import React from 'react';

import { IconBase, IconBaseProps } from './base';

export type UserAddIconProps = IconBaseProps;

const UserAddIcon: React.FC<UserAddIconProps> = props => {
	return (
		<IconBase {...props}>
			<svg
				stroke='currentColor'
				fill='none'
				strokeWidth='2'
				viewBox='0 0 24 24'
				strokeLinecap='round'
				strokeLinejoin='round'
				xmlns='http://www.w3.org/2000/svg'>
				<path d='M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2'></path>
				<circle cx='8.5' cy='7' r='4'></circle>
				<line x1='20' y1='8' x2='20' y2='14'></line>
				<line x1='23' y1='11' x2='17' y2='11'></line>
			</svg>
		</IconBase>
	);
};

export { UserAddIcon };
