import styled from 'styled-components';

const Root = styled.div`
	display: inline-block;
	width: 100%;

	& .input {
		margin-right: 0;
	}
`;

const IconLayout = styled.div`
	& svg {
		position: absolute;
		top: 50%;
		left: 4px;
		transform: translate(0, -50%);
	}
`;

interface ISearchBarLayout {
	width: string;
}

const SearchBarLayout = styled.div`
	width: 100%;
`;

export { Root, IconLayout, SearchBarLayout };
