import React from 'react';
import styled, { css } from 'styled-components';

import { getAppName } from '@config';
import { Box } from '@ui/box';
import { LinkToMobileApps, detectHasAnyMobileAppLinks } from '@ui/link-to-mobile-apps';
import { ModalViewer } from '@ui/modal-viewer';
import { RaisedButton } from '@ui/raised-button';

export type MobileAppsButtonProps = {
	isOpen: boolean;
	onRequestOpen: () => void;
	onRequestClose: () => void;
};

const MobileAppsButton: React.FC<MobileAppsButtonProps> = ({ isOpen, onRequestOpen, onRequestClose }) => {
	const appName = getAppName();
	const hasAnyLinks = detectHasAnyMobileAppLinks(appName);

	if (!hasAnyLinks) return null;

	return (
		<Box padding={10}>
			<RoundedButton onClick={onRequestOpen}>Установить моб. приложение по QR-коду</RoundedButton>
			<ModalViewer isOpen={isOpen} width={800} innerCloseBtn onClose={onRequestClose}>
				<Box fullWidth>
					<Cover>Установите мобильное приложение...</Cover>
					<Box padding={40}>
						<Box display='flex' justifyContent='center' alignItems='center'>
							<LinkToMobileApps appearance='qr-code' app={appName} />
						</Box>
					</Box>
				</Box>
			</ModalViewer>
		</Box>
	);
};

const ButtonStyled = styled(RaisedButton)`
	border-radius: 20px;
`;

const Cover = styled.div`
	position: relative;
	width: 100%;
	height: 150px;
	background: rgb(213, 102, 255);
	background: linear-gradient(90deg, rgba(213, 102, 255, 1) 0%, rgba(47, 78, 205, 1) 80%);
	padding: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;

	${p => css`
		font-size: ${p.theme.fn.pxToRem(20)};

		&:after {
			content: '';
			position: absolute;
			top: -1px;
			right: -1px;
			bottom: -1px;
			left: -1px;
			z-index: 1;
			background-color: ${p.theme.isDarkMode ? 'rgba(0, 0, 0, 0.1)' : 'transparent'};
		}
	`}
`;

const RoundedButton = props => (
	<ButtonStyled
		appearance='text'
		color='primary'
		size='small'
		RippleProps={{ style: { borderRadius: 20 } }}
		{...props}
	/>
);

export { MobileAppsButton };
