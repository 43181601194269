import { tariffRestrictionNames } from '@shared/constants/string-constants';

// Из действующих ограницений тарифа находит необходимое по наименованию
function findRestrictionBySystemName(
	activeTariffRestrictions: ObjectCountTariffRestrictionValue[],
	restrictionName: string,
): ObjectCountTariffRestrictionValue {
	return (
		activeTariffRestrictions &&
		activeTariffRestrictions.find(({ Restriction: { SystemName } }) => SystemName === restrictionName)
	);
}

// Проверяет, доступно ли клиенту создание расчётного счёта
function checkAddBankAccountRestriction(
	activeTariffRestrictions: ObjectCountTariffRestrictionValue[],
	bankAccountsAmount: number,
): boolean {
	const activeTariffRestriction = findRestrictionBySystemName(
		activeTariffRestrictions,
		tariffRestrictionNames.BankAccountCountRestriction,
	);
	const countLimit =
		activeTariffRestriction && activeTariffRestriction.Enabled && activeTariffRestriction.ObjectCountLimit;

	return !activeTariffRestriction || bankAccountsAmount < countLimit;
}

function detectIsProfessionalEdition(tenantAccount: TenantAccount): boolean {
	const activeTariffLevel = tenantAccount?.ActiveTariff?.Level?.ID || -1;
	const requestedTariffLevel = tenantAccount?.RequestedTariff?.Level?.ID || -1;
	const isProfessionalEdition =
		activeTariffLevel === 2 || (activeTariffLevel !== 1 && requestedTariffLevel === 2) || false;

	return isProfessionalEdition;
}

export { findRestrictionBySystemName, checkAddBankAccountRestriction, detectIsProfessionalEdition };
