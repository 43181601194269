import { fromStringToBoolean } from '@utils/serializer';
import * as referencepackage from 'referencepackage-api';
import { datamanagementApi } from '../datamanagement';

const referenceApi = {
	package: referencepackage,
	bank: {
		client: new referencepackage.BankServiceClient(),
		fetchBanksByTerm: (term: string) => {
			return new Promise<Array<Bank>>(resolve => {
				referenceApi.bank.client.getBanksByQueryString(term, result => {
					resolve(result);
				});
			});
		},
	},
	businessUnit: {
		client: new referencepackage.BusinessUnitServiceClient(),
		fetchBusinessUnits: () => {
			return new Promise<Array<BusinessUnit>>(resolve => {
				referenceApi.businessUnit.client.getBusinessUnits(result => {
					resolve(result);
				});
			});
		},
		fetchBusinessUnitByID: (ID: number) => {
			return new Promise<BusinessUnit>(resolve => {
				referenceApi.businessUnit.client.getBusinessUnitByID(ID, result => {
					resolve(result);
				});
			});
		},
		addBusinessUnit: (businessUnit: BusinessUnit) => {
			return new Promise<BusinessUnit>(resolve => {
				referenceApi.businessUnit.client.addBusinessUnit(businessUnit, result => {
					resolve(result);
				});
			});
		},
		updateBusinessUnit: (businessUnit: BusinessUnit) => {
			return new Promise<BusinessUnit>(resolve => {
				referenceApi.businessUnit.client.changeBusinessUnit(businessUnit, result => {
					resolve(result);
				});
			});
		},
		removeBusinessUnit: (ID: number) => {
			return new Promise<boolean>(resolve => {
				referenceApi.businessUnit.client.removeBusinessUnit(ID, result => {
					resolve(fromStringToBoolean(result));
				});
			});
		},
	},
	country: {
		client: new referencepackage.CountryServiceClient(),
		fetchCountries: () => {
			return new Promise<Array<Country>>(resolve => {
				datamanagementApi.dataset.fetchDataset('DS_Country_GetAll', {}, dataset => {
					const countries: Array<Country> = dataset.Records.map(x => {
						const country: Country = {
							...new referenceApi.package.Country(),
							ID: x.ID,
							Name: x.NAME,
							FullName: x.FULL_NAME,
							DigitCode: x.DIGIT_CODE,
							LetterCode: x.LETTER_CODE,
						};

						return country;
					});

					resolve(countries);
				});
			});
		},
	},
	currency: {
		client: new referencepackage.CurrencyServiceClient(),
		fetchCurrencies: () => {
			return new Promise<Array<Currency>>(resolve => {
				referenceApi.currency.client.getCurrencies(result => {
					resolve(result);
				});
			});
		},
	},
	industry: {
		client: new referencepackage.IndustryServiceClient(),
		fetchIndustries: () => {
			return new Promise<Array<SyntheticIndustry>>(resolve => {
				referenceApi.industry.client.getIndustries(1, result => {
					resolve(result as Array<SyntheticIndustry>);
				});
			});
		},
		fetchStateIndustries: () => {
			return new Promise<Array<SyntheticIndustry>>(resolve => {
				referenceApi.industry.client.getIndustries(2, result => {
					resolve(result as Array<SyntheticIndustry>);
				});
			});
		},
	},
	measureUnit: {
		client: new referencepackage.MeasureUnitServiceClient(),
		fetchMeasureUnits: () => {
			return new Promise<Array<MeasureUnit>>(resolve => {
				referenceApi.measureUnit.client.getMeasureUnits(result => {
					resolve(result);
				});
			});
		},
		addToFavorites: (ID: number) => {
			return new Promise<boolean>(resolve => {
				referenceApi.measureUnit.client.addToFavorites(ID, result => {
					resolve(result);
				});
			});
		},
		removeFromFavorites: (ID: number) => {
			return new Promise<boolean>(resolve => {
				referenceApi.measureUnit.client.removeFromFavorites(ID, result => {
					resolve(result);
				});
			});
		},
	},
	products: {
		client: new referencepackage.ProductServiceClient(),
		fetchProducts: () => {
			return new Promise<Array<Product>>(resolve => {
				const filter = new referenceApi.package.ProductFilter();

				referenceApi.products.client.getProducts(filter, result => {
					resolve(result);
				});
			});
		},
		fetchProductTypes: () => {
			return new Promise<Array<ProductType>>(resolve => {
				referenceApi.products.client.getProductTypes(result => {
					resolve(result);
				});
			});
		},
		fetchProductByID: (ID: number) => {
			return new Promise<Product>(resolve => {
				referenceApi.products.client.getProductByID(ID, result => {
					resolve(result);
				});
			});
		},
		addProduct: (product: Product) => {
			return new Promise<Product>(resolve => {
				referenceApi.products.client.addProduct(product, result => {
					resolve(result);
				});
			});
		},
		updateProduct: (product: Product) => {
			return new Promise<Product>(resolve => {
				referenceApi.products.client.updateProduct(product, result => {
					resolve(result);
				});
			});
		},
		linkProductsToProductGroup: (productIDs: Array<number>, parentID: number) => {
			return new Promise<boolean>(async resolve => {
				const result = await Promise.all(
					productIDs.map(async ID => {
						const product = await referenceApi.products.fetchProductByID(ID);

						product.ParentID = parentID;

						const result = referenceApi.products.updateProduct(product);

						return Boolean(result);
					}),
				);

				resolve(result.every(x => x));
			});
		},
		removeProduct: (ID: number) => {
			return new Promise<boolean>(resolve => {
				referenceApi.products.client.removeProduct(ID, result => {
					resolve(fromStringToBoolean(result));
				});
			});
		},
	},
	region: {
		client: new referencepackage.RegionServiceClient(),
		fetchRegions: () => {
			return new Promise<Array<Region>>(resolve => {
				referenceApi.region.client.getRegions(result => {
					resolve(result);
				});
			});
		},
	},
};

export { referenceApi };
