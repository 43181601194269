import React from 'react';
import styled, { css } from 'styled-components';

import { Box } from '@ui/box';
import { MaterialCardProps } from './material-card';
import { makeFullWidth } from '@stylesheet';

type RootProps = {} & MaterialCardProps;

const Root = styled(({ elevation, hoverElevation, appearance, fullWidth, ...rest }) => <Box {...rest} />)<RootProps>`
	position: relative;
	transition: box-shadow 0.2s ease-in-out;
	${p => makeFullWidth(p)}
	${p => p.theme.fn.createBoxShadow(p.elevation)}

	${p => css`
		background-color: ${p.theme.materialCard.backgroundColor};
	`}

	&:hover {
		${p => p.theme.fn.createBoxShadow(p.hoverElevation)}
		z-index: 1;
	}
` as React.FunctionComponent<RootProps>;

export { Root };
