import styled, { css, keyframes } from 'styled-components';

import { darken } from '@utils/color';

const enterMotion = keyframes`
	0% {
		transform: translateX(-80px);
		opacity: 0;
	}
	100% {
		transform: translateX(0);
		opacity: 1;
	}
`;

type RootProps = {
	fullWidth: boolean;
};

const Root = styled.div<RootProps>`
	position: relative;
	display: inline-flex;
	flex-flow: column nowrap;

	${p =>
		p.fullWidth &&
		css`
			width: 100%;
		`}
`;

const Anchor = styled.div`
	position: absolute;
	left: 0;
	top: 62px;
	width: 100%;
	height: 2px;
`;

const TextFieldCover = styled.div`
	position: absolute;
	top: 32px;
	width: calc(100% - 48px);
	height: 32px;
	z-index: 1;
	cursor: pointer;
`;

type UnderlinedTextLayoutProps = {
	readonly: boolean;
};

const UnderlinedTextLayout = styled.span<UnderlinedTextLayoutProps>`
	font-size: 11px;
	line-height: 1;

	${p =>
		p.readonly &&
		css`
			* {
				color: ${p.theme.palette.text} !important;
			}
		`}
`;

const ScrollZone = styled.ul`
	width: 100%;
	max-height: 250px;
	overflow-x: hidden;
	overflow-y: auto;
	padding: 0;
	margin: 0;
	list-style: none;
`;

type PopupContentRootProps = {
	minHeight: number;
};

const PopupContentRoot = styled.div<PopupContentRootProps>`
	width: 400px;
	display: flex;
	flex-flow: column nowrap;
	justify-content: space-between;

	${p =>
		p.minHeight &&
		css`
			min-height: ${p.minHeight}px;
		`}

	@media(max-width: 500px) {
		width: auto;
	}
`;

type ListItemControlsLayoutProps = {
	count: number;
};

const ListItemControlsLayout = styled.div<ListItemControlsLayoutProps>`
	flex: 0 0 auto;
	display: flex;
	justify-content: flex-end;
	align-items: center;

	${p =>
		p.count > 0 &&
		css`
			flex-basis: ${p.count * 32}px;
		`}
`;

type HoverControlsLayoutLayoutProps = {
	isNavigated: boolean;
};

const VisibilityControlsLayout = styled.div<HoverControlsLayoutLayoutProps>`
	flex: 1 1 auto;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	visibility: hidden;
	opacity: 0;
	transition: opacity 0.2s ease-in-out;

	${p =>
		p.isNavigated &&
		css`
			opacity: 1;
			visibility: visible;
		`}
`;

const AdditionalControlsLayout = styled.div`
	font-size: 0;
	display: flex;
	align-items: center;
`;

type ListItemProps = {
	isMuted: boolean;
	isDisabled: boolean;
	isSelected: boolean;
	isNavigated: boolean;
	scrollWidth: number;
	isSmallContainer: boolean;
};

const ListItem = styled.li<ListItemProps>`
	position: relative;
	width: 100%;
	min-height: 32px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-right: 8px;
	transition: background-color 0.2s ease-in-out;
	animation-name: ${enterMotion};
	animation-duration: 0.2s;
	animation-iteration-count: 1;
	animation-timing-function: ease-in-out;
	word-break: break-word;

	${p => css`
		&:hover {
			background-color: ${p.theme.palette.stealth};

			${VisibilityControlsLayout} {
				opacity: 1;
				visibility: visible;
			}
		}
	`}

	${p =>
		!p.isDisabled &&
		css`
			cursor: pointer;
		`}

	${p =>
		p.isSelected &&
		css`
			color: ${p.theme.palette.accent};
		`}

	${p =>
		(p.isMuted || p.isDisabled) &&
		css`
			color: ${p.theme.palette.label};
		`}

	${p =>
		p.isSmallContainer &&
		css`
			font-size: ${p.theme.fn.pxToRem(12)};
		`}



	${p =>
		p.isNavigated &&
		css`
			background-color: ${darken(p.theme.palette.stealth, 0.05)};
		`}

	${p =>
		p.scrollWidth &&
		css`
			padding-right: ${8 - p.scrollWidth > 0 ? 8 - p.scrollWidth : 0}px;
		`}
`;

const ListItemTextLayout = styled.div`
	align-self: stretch;
	width: 100%;
	display: flex;
	align-items: center;
	padding: 6px 0 6px 38px;
`;

const CheckIconLayout = styled.div`
	position: absolute;
	top: 5px;
	left: 12px;
	font-size: 0;
`;

type ExpandButtonLayoutProps = {
	isExpanded: boolean;
};

const ExpandButtonLayout = styled.div<ExpandButtonLayoutProps>`
	font-size: 0;
	transition: transform 0.2s ease-in-out;

	${p =>
		p.isExpanded &&
		css`
			transform: rotate(-180deg);
		`}
`;

type NestingIndicatorProps = {
	shift: number;
};

const NestingIndicator = styled.div<NestingIndicatorProps>`
	flex: 0 0 auto;

	${p => css`
		width: 10px;
		height: 8px;
		margin-left: ${p.shift > 0 ? p.shift - 20 : 0}px;
		margin-right: 10px;
		border-left: 1px solid transparent;
		border-bottom: 1px solid transparent;
		border-color: ${p.theme.palette.hint};
	`}
`;

export {
	Root,
	Anchor,
	TextFieldCover,
	UnderlinedTextLayout,
	ScrollZone,
	PopupContentRoot,
	ListItem,
	ListItemControlsLayout,
	VisibilityControlsLayout,
	AdditionalControlsLayout,
	ListItemTextLayout,
	CheckIconLayout,
	ExpandButtonLayout,
	NestingIndicator,
};
