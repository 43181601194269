import { IAppState } from '@store';
import { createSelector } from '@flux';
import { createTheme } from '@theme';

function selectThemeIdentifier(state: IAppState) {
	return state.shared.theme.identifier;
}

const selectTheme = createSelector(selectThemeIdentifier, themeIdentifier => {
	return createTheme(themeIdentifier);
});

export const themeSelectorsPack = {
	selectThemeIdentifier,
	selectTheme,
};

export { selectThemeIdentifier, selectTheme };
