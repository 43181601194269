import { getAuthParams } from '@config';
import { getUserData } from '@core/utils/app';
import { Base64 } from '@utils';
import { encryptVernam } from '@utils/crypto';
import { getClearedUrl, paramsToSearch } from '@utils/url';
import { ExternalServiceGUID } from './model';

const ssoApi = {
	integrations: {
		auth: (options: IntegrationsAuthOptions) => {
			const {
				subsystemInstanceGUID,
				externalSystemAccountID,
				ticketUID,
				postActionURL,
				taxCode,
				additionalTaxCode = 0,
				loginAPI,
				passwordAPI,
			} = options;
			const {
				openIDProfile,
				applicationScope,
				provider,
				businessServiceID,
				businessService,
				clientApplication,
				yandexClientID,
			} = getAuthParams();
			const { tenantID } = getUserData();
			const authMethodCode = authMethodCodesMap[subsystemInstanceGUID];
			return new Promise(resolve => {
				resolve(null);
				const redirectMap = {
					[ExternalServiceGUID.ALFA_BANK_BNK_CMS]: () => {
						const search = paramsToSearch({
							stage: STAGE,
							state: Base64.encode(
								paramsToSearch({
									acquire_token_mode: true,
									application_scope: applicationScope,
									auth_method: authMethodCode,
									business_service: businessService,
									client_app: clientApplication,
									external_service_guid: subsystemInstanceGUID,
									external_system_account_id: externalSystemAccountID,
									has_parameters: true,
									open_id_profile: OPEN_ID_PROFILE_MAP[tenantID] || OPEN_ID_PROFILE_MAP.default,
									provider,
									reg_method: REG_METHOD,
									reg_url: encodeURIComponent(getClearedUrl(location.href)),
									scope: applicationScope,
									start_url_context: postActionURL,
									ticketuid: ticketUID,
									ym_client_id: yandexClientID,
								}),
							),
						});
						const url = `${SSO_API_ENDPOINT}?${search}`;

						location.replace(url);
					},
					[ExternalServiceGUID.MODULE_BANK_BNK_CMS]: () => {
						const search = paramsToSearch({
							stage: STAGE,
							state: Base64.encode(
								paramsToSearch({
									ticketuid: ticketUID,
									application_scope: applicationScope,
									scope: applicationScope,
									start_url_context: postActionURL,
									acquire_token_mode: true,
									external_service_guid: subsystemInstanceGUID,
									external_system_account_id: externalSystemAccountID,
									has_parameters: true,
									reg_method: REG_METHOD,
									provider,
									business_service: businessService,
									client_app: clientApplication,
									ym_client_id: yandexClientID,
									auth_method: authMethodCode,
									reg_url: encodeURIComponent(getClearedUrl(location.href)),
								}),
							),
						});
						const url = `${SSO_API_ENDPOINT}?${search}`;

						location.replace(url);
					},
					[ExternalServiceGUID.OPEN_BANK_BNK_CMS]: () => {
						const search = paramsToSearch({
							stage: STAGE,
							state: Base64.encode(
								paramsToSearch({
									provider,
									acquire_token_mode: true,
									reg_method: REG_METHOD,
									has_parameters: true,
									ticketuid: ticketUID,
									external_service_guid: subsystemInstanceGUID,
									external_system_account_id: externalSystemAccountID,
									start_url_context: postActionURL,
									business_service: businessService,
									client_app: clientApplication,
									ym_client_id: yandexClientID,
									auth_method: authMethodCode,
									reg_url: encodeURIComponent(getClearedUrl(location.href)),
								}),
							),
						});
						const url = `${SSO_API_ENDPOINT}?${search}`;

						location.replace(url);
					},
					[ExternalServiceGUID.SBERBANK_BNK_CMS]: () => {
						const search = paramsToSearch({
							stage: STAGE,
							state: Base64.encode(
								paramsToSearch({
									provider,
									acquire_token_mode: true,
									reg_method: REG_METHOD,
									has_parameters: true,
									ticketuid: ticketUID,
									external_service_guid: subsystemInstanceGUID,
									external_system_account_id: externalSystemAccountID,
									application_scope: applicationScope,
									scope: applicationScope,
									start_url_context: postActionURL,
									open_id_profile: openIDProfile,
									business_service: businessService,
									client_app: clientApplication,
									ym_client_id: yandexClientID,
									auth_method: authMethodCode,
									reg_url: encodeURIComponent(getClearedUrl(location.href)),
									business_service_id: businessServiceID, // deprecated
									serialized_parameters: true, // deprecated
								}),
							),
						});
						const url = `${SSO_API_ENDPOINT}?${search}`;

						location.replace(url);
					},
					[ExternalServiceGUID.TINKOFF_BANK_BNK_CMS]: () => {
						const search = paramsToSearch({
							stage: STAGE,
							cmp_tax_code: taxCode,
							cmp_additional_tax_code: additionalTaxCode,
							state: Base64.encode(
								paramsToSearch({
									ticketuid: ticketUID,
									application_scope: applicationScope,
									scope: applicationScope,
									start_url_context: postActionURL,
									acquire_token_mode: true,
									external_service_guid: subsystemInstanceGUID,
									external_system_account_id: externalSystemAccountID,
									has_parameters: true,
									reg_method: REG_METHOD,
									provider,
									business_service: businessService,
									client_app: clientApplication,
									ym_client_id: yandexClientID,
									auth_method: authMethodCode,
									reg_url: encodeURIComponent(getClearedUrl(location.href)),
								}),
							),
						});
						const url = `${SSO_API_ENDPOINT}?${search}`;

						location.replace(url);
					},
					[ExternalServiceGUID.URALSIB_BNK_CMS]: () => {
						const search = paramsToSearch({
							stage: STAGE,
							state: Base64.encode(
								paramsToSearch({
									provider,
									acquire_token_mode: true,
									reg_method: REG_METHOD,
									has_parameters: true,
									ticketuid: ticketUID,
									application_scope: 'offline_access+profile+legal_info+apiResource_LK+apiResource_Bank',
									external_service_guid: subsystemInstanceGUID,
									external_system_account_id: externalSystemAccountID,
									start_url_context: postActionURL,
									business_service: businessService,
									client_app: clientApplication,
									ym_client_id: yandexClientID,
									auth_method: authMethodCode,
									reg_url: encodeURIComponent(getClearedUrl(location.href)),
								}),
							),
						});
						const url = `${SSO_API_ENDPOINT}?${search}`;

						location.replace(url);
					},
					[ExternalServiceGUID.YOU_KASSA_CMS]: () => {
						const search = paramsToSearch({
							stage: STAGE,
							state: Base64.encode(
								paramsToSearch({
									ticketuid: ticketUID,
									application_scope: applicationScope,
									scope: applicationScope,
									start_url_context: postActionURL,
									acquire_token_mode: true,
									external_service_guid: subsystemInstanceGUID,
									external_system_account_id: externalSystemAccountID,
									has_parameters: true,
									reg_method: REG_METHOD,
									provider,
									business_service: businessService,
									client_app: clientApplication,
									ym_client_id: yandexClientID,
									auth_method: authMethodCode,
									reg_url: encodeURIComponent(getClearedUrl(location.href)),
								}),
							),
						});
						const url = `${SSO_API_ENDPOINT}?${search}`;

						location.replace(url);
					},
					[ExternalServiceGUID.ZEN_MONEY_CMS]: () => {
						const search = paramsToSearch({
							stage: STAGE,
							login_api: loginAPI,
							password_api: encryptVernam(passwordAPI, ticketUID),
							state: Base64.encode(
								paramsToSearch({
									ticketuid: ticketUID,
									application_scope: applicationScope,
									scope: applicationScope,
									start_url_context: postActionURL,
									acquire_token_mode: true,
									external_service_guid: subsystemInstanceGUID,
									external_system_account_id: externalSystemAccountID,
									has_parameters: true,
									reg_method: REG_METHOD,
									provider,
									business_service: businessService,
									client_app: clientApplication,
									ym_client_id: yandexClientID,
									auth_method: authMethodCode,
									reg_url: encodeURIComponent(getClearedUrl(location.href)),
								}),
							),
						});
						const url = `${SSO_API_ENDPOINT}?${search}`;

						location.replace(url);
					},
					default: () => {
						const search = paramsToSearch({
							stage: STAGE,
							state: Base64.encode(
								paramsToSearch({
									acquire_token_mode: true,
									application_scope: applicationScope,
									auth_method: authMethodCode,
									business_service: businessService,
									client_app: clientApplication,
									external_service_guid: subsystemInstanceGUID,
									external_system_account_id: externalSystemAccountID,
									has_parameters: true,
									provider,
									reg_method: REG_METHOD,
									reg_url: encodeURIComponent(getClearedUrl(location.href)),
									scope: applicationScope,
									start_url_context: postActionURL,
									ticketuid: ticketUID,
									ym_client_id: yandexClientID,
								}),
							),
						});
						const url = `${SSO_API_ENDPOINT}?${search}`;

						location.replace(url);
					},
				};
				const redirect = redirectMap[subsystemInstanceGUID] || redirectMap.default;

				redirect();
			});
		},
	},
};

export const SEENECO_MAIN_TENANT_ID = 75851;
const OPEN_ID_PROFILE_MAP = {
	[SEENECO_MAIN_TENANT_ID]: 'seeneco_client',
	default: 'seeneco_partner',
};
const SSO_API_ENDPOINT = `${location.origin}/atr-framework-services/externalSystemOpenID`;
const REG_METHOD = 'sso';
const STAGE = 'auth_code';
const authMethodCodesMap = {
	[ExternalServiceGUID.ALFA_BANK_BNK_CMS]: 'alfabank_sso',
	[ExternalServiceGUID.MODULE_BANK_BNK_CMS]: 'modulebank_sso',
	[ExternalServiceGUID.OPEN_BANK_BNK_CMS]: 'openbank_sso',
	[ExternalServiceGUID.SBERBANK_BNK_CMS]: 'sberbank_sso_sms',
	[ExternalServiceGUID.TINKOFF_BANK_BNK_CMS]: 'tinkoff_sso',
	[ExternalServiceGUID.TOCHKA_BANK_BNK_CMS]: 'tochkabank_sso',
	[ExternalServiceGUID.URALSIB_BNK_CMS]: 'uralsibbank_sso',
	[ExternalServiceGUID.VTB_BANK_CMS]: 'vtb_sso',
	[ExternalServiceGUID.YOU_KASSA_CMS]: 'yandex_kassa_sso',
};

export type IntegrationsAuthOptions = {
	additionalTaxCode?: string;
	externalSystemAccountID: number;
	loginAPI?: string;
	passwordAPI?: string;
	postActionURL: string;
	subsystemInstanceGUID: string;
	subsystemInstanceID?: number;
	taxCode?: string;
	ticketUID: string;
};

export { ExternalServiceGUID, ssoApi };
