import { compose } from 'redux';

import { IAppState } from '@store';
import { createSelector, createAsyncSelector, createRightCheck } from '@flux';
import { sortDescBy } from '@utils/sorting';
import { hasBooleanKeys, createObjectMap } from '@utils/object';
import { LegalEntityForm } from '@tenant-legal-entities/models';

const selectTenantLegalEntitiesRight = createRightCheck({
	component: 'TenantEntityView',
	view: 'xTenantLegalEntities_ViewTenantLegalEntities',
	change: 'xTenantLegalEntities_ChangeTenantLegalEntities',
});

const selectAsyncTenantLegalEntities = createAsyncSelector<Array<CounterpartyBrief>, IAppState>({
	get: s => s.tenantLegalEntities.main.legalEntities,
	selector: createSelector(
		s => s.tenantLegalEntities.main.legalEntities.item,
		item => item,
	),
});

const selectTenantLegalEntitiesMap = createSelector(selectAsyncTenantLegalEntities.selectItem, legalEntitites => {
	return createObjectMap(legalEntitites, x => x.ID);
});

const selectFilteredTenantLegalEntities = createSelector(
	selectAsyncTenantLegalEntities.selectItem,
	selectSearchTextFilter,
	selectLegalEntityFormFilter,
	(legalEntities, searchTextFilter, formFilter) => {
		const searchText = searchTextFilter.toLocaleLowerCase();
		const detectIsMatchName = (x: CounterpartyBrief) => (x.Name || '').toLowerCase().indexOf(searchText) !== -1;
		const detectIsMatchCode = (x: CounterpartyBrief) => (x.TaxCode || '').toLowerCase().indexOf(searchText) !== -1;
		const filteredLegalEntities = compose(
			(legalEntities: Array<CounterpartyBrief>) => sortDescBy(legalEntities, [{ fn: item => item.ID }]),
			(legalEntities: Array<CounterpartyBrief>) =>
				hasBooleanKeys(formFilter)
					? legalEntities.filter(x => {
							const isPassed = compose(
								(isPassed: boolean) => isPassed || (formFilter[LegalEntityForm.SOLE_PROPRIETOR] && x.SoleProprietor),
								(isPassed: boolean) => isPassed || (formFilter[LegalEntityForm.LEGAL_ENTITY] && !x.SoleProprietor),
							)(false);

							return isPassed;
					  })
					: legalEntities,
			(legalEntities: Array<CounterpartyBrief>) =>
				searchText ? legalEntities.filter(x => detectIsMatchName(x) || detectIsMatchCode(x)) : legalEntities,
		)(legalEntities);

		return filteredLegalEntities;
	},
);

function selectSearchTextFilter(state: IAppState) {
	return state.tenantLegalEntities.main.searchText;
}

function selectLegalEntityFormFilter(state: IAppState) {
	return state.tenantLegalEntities.main.legalEntityFormFilter;
}

export const mainTenantLegalEntitiesSelectorsPack = {
	selectTenantLegalEntitiesRight,
	selectAsyncTenantLegalEntities,
	selectTenantLegalEntitiesMap,
	selectFilteredTenantLegalEntities,
	selectSearchTextFilter,
	selectLegalEntityFormFilter,
};

export { selectTenantLegalEntitiesRight };
