import React from 'react';

import { formatCurrency } from '@utils/currency';
import { Box } from '@ui/box';
import { prettyDate } from '@utils/date';
import { Root, StampImage, SignLayout, Sign } from './styled';
import paidStampPath from './resources/paid.png';
import revokedStampPath from './resources/revoked.png';

export type StampProps = {
	appearance: 'paid' | 'revoked';
	sum?: number;
	date?: string;
	payMethod?: string;
};

const Stamp: React.FC<StampProps> = props => {
	const { appearance, sum, date, payMethod } = props;
	const source = pathesMap[appearance];

	return (
		<Root appearance={appearance}>
			<StampImage alt='Печать' src={source} />
			{appearance === 'paid' && (
				<SignLayout>
					<Box flexDirection='row' justifyContent='center' alignItems='center'>
						<Sign>{formatCurrency(sum)}</Sign>
						<Sign>{`${prettyDate(date)} г.`}</Sign>
					</Box>
					{payMethod && (
						<Box flexDirection='row' justifyContent='center' alignItems='center'>
							<Sign>{payMethod}</Sign>
						</Box>
					)}
				</SignLayout>
			)}
		</Root>
	);
};

const pathesMap = {
	paid: paidStampPath,
	revoked: revokedStampPath,
};

export { Stamp };
