import { useSelector } from 'react-redux';

import { IAppState } from '@store';

function useTenantID() {
	const tenantID = useSelector<IAppState>(x => x.platform.sessionContext.item?.SessionTenant?.ID || -1) as number;

	return tenantID;
}

export { useTenantID };
