import { useState, useCallback } from 'react';

function useForceUpdate() {
	const [_, setState] = useState(0);
	const forceUpdate = useCallback(() => setState(x => x + 1), []);

	return { forceUpdate };
}

export { useForceUpdate };
