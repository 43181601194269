import styled from 'styled-components';

import * as c from '../../basic-theme/colors';


interface IRoot {
	withoutPadding: boolean;
	withoutMinHeight: boolean;
}

const Root = styled.div`
	width: 100%;
	height: auto;
	min-height: 50px;
	padding: 17px;
	border-radius: 2px;

	${(props: IRoot) => props.withoutPadding && `
		padding: 0;
	`}

	${(props: IRoot) => props.withoutMinHeight && `
		min-height: 0;
	`}

	${(props: IRoot) => props.withoutMinHeight && `
		min-height: 0;
	`}
`;

interface IMessageLayout {
	textAlign: 'center' | 'left' | 'right';
}

const MessageLayout = styled.div`
	flex-shrink: 1;
	font-size: 14px;
  line-height: 1.43;
  text-align: ${(props: IMessageLayout) => props.textAlign || 'left'};
  color: ${c.blackSecondary};
`;

interface IMessageCardLayout {
	width?: string;
	height?: string;
}

const MessageCardLayout = styled.div`
	width: ${(props: IMessageCardLayout) => props.width ? props.width : '100%'};
	height: ${(props: IMessageCardLayout) => props.height ? props.height : 'calc(100vh - 200px)'};
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	align-items: center;
`;

export {
	Root,
	MessageLayout,
	MessageCardLayout
};
