import React, { useMemo } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

import { useTheme } from '@theme';
import { darken } from '@utils/color';

export type ScrollableViewProps = {
	appearance?: 'default' | 'mini';
	style?: React.CSSProperties;
	children: React.ReactNode;
};

const ScrollableView: React.FC<ScrollableViewProps> = props => {
	const { style, appearance, children, ...rest } = props;
	const { theme } = useTheme();
	const styles = useMemo(
		() => ({
			thumbVertical: {
				width: 8,
				right: 0,
				zIndex: 1000,
				borderRadius: 10,
				backgroundColor: darken(theme.palette.hint, 0.1),
			},
			thumbVerticalMini: {
				width: 4,
				right: -4,
				zIndex: 1000,
				borderRadius: 10,
				backgroundColor: darken(theme.palette.hint, 0.1),
			},
		}),
		[theme],
	);

	const renderThumbVertical = props => {
		const calculatedStyles = appearance === 'mini' ? styles.thumbVerticalMini : styles.thumbVertical;

		return (
			<div
				{...props}
				style={{
					...props.style,
					...calculatedStyles,
				}}
			/>
		);
	};

	return (
		<Scrollbars style={style} renderThumbVertical={renderThumbVertical} {...rest}>
			{children}
		</Scrollbars>
	);
};

ScrollableView.defaultProps = {
	appearance: 'default',
};

export { ScrollableView };
