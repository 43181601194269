import React from 'react';
import MaterialSpinner from 'react-md-spinner';

import { useTheme } from '@theme';

export type CircularProgressProps = {
	size?: number;
	thickness?: number;
	color?: 'accent' | 'white' | 'light';
};

const CircularProgress: React.FC<CircularProgressProps> = props => {
	const { size, color } = props;
	const { theme } = useTheme();
	const colorMap = {
		accent: theme.palette.accent,
		white: theme.palette.textContrast,
		light: theme.palette.textContrast,
	};

	return <MaterialSpinner size={size} singleColor={colorMap[color]} />;
};

CircularProgress.defaultProps = {
	size: 40,
	color: 'accent',
};

export { CircularProgress };
