import React, { useMemo } from 'react';

import { DataSourceItem } from '@core/api/core';
import { Autopicker, AutopickerProps } from '@ui/autopicker';
import { LegalEntityForm } from '@counterparties/models';

export type LegalEntityFormAutopickerProps = {
	name?: string;
} & AutopickerProps<DataSourceItem<LegalEntityForm>>;

const LegalEntityFormAutopicker: React.FC<LegalEntityFormAutopickerProps> = props => {
	const pluralItems = useMemo(() => ['форма', 'формы', 'форм'] as [string, string, string], []);

	return (
		<Autopicker
			labelText='Форма ведения деятельности'
			hintText='Выберите форму...'
			pluralItems={pluralItems}
			{...props}
			getID={getID}
			getName={getName}
			saveOriginalSorting
			fullWidth
		/>
	);
};

const getID = (x: DataSourceItem) => x.value;

const getName = (x: DataSourceItem) => x.text;

export { LegalEntityFormAutopicker };
