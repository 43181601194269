import { combineReducers } from 'redux';

import { MainFundsRegisterOpenRequestsState, mainFundsRegisterOpenRequestsReducer } from './main.reducer';

export type FundsRegisterOpenRequestsState = {
	main: MainFundsRegisterOpenRequestsState;
};

const fundsRegisterOpenRequestsReducer = combineReducers<FundsRegisterOpenRequestsState>({
	main: mainFundsRegisterOpenRequestsReducer,
});

export { fundsRegisterOpenRequestsReducer };
