import { runRoutingEffect } from './routing.effect';
import { runCensorInterceptorEffect } from './censor-interceptor.effect';
import { runAsyncInitializerEffect } from './async-initializer.effect';
import { runCheckServerTasksEffect } from './task.effect';
import { runIdentifyEffect } from './identify.effect';
import { runFastBillEffect, runFastBillResultEffect, runForceSyncPaymentEffect } from './billing.effect';
import { runReloadEffect } from './reload.effect';
import { runGoalsEffect } from './goals.effect';

const platformEffects = [
	runRoutingEffect,
	runCensorInterceptorEffect,
	runAsyncInitializerEffect,
	runCheckServerTasksEffect,
	runFastBillEffect,
	runFastBillResultEffect,
	runForceSyncPaymentEffect,
	runIdentifyEffect,
	runReloadEffect,
	runGoalsEffect,
];

export { platformEffects };
