import React from 'react';
import styled from 'styled-components';

import { IconBase, IconBaseProps } from './base';

export type OkIconProps = IconBaseProps;

const OkIcon: React.FC<OkIconProps> = props => {
	return (
		<IconBase {...props}>
			<svg viewBox='0 0 24 24' fill='currentColor'>
				<Text x={1} y={18}>
					OK
				</Text>
			</svg>
		</IconBase>
	);
};

const Text = styled.text`
	font-size: 16px;
	font-weight: 700;
`;

export { OkIcon };
