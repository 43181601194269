import React from 'react';
import { isChrome, isChromium, isEdgeChromium, isYandex } from 'react-device-detect';

import { PageOrientation } from './models';
import { DocumentPage as DocumentPageModern } from './modern/document-page';
import { DocumentPage as DocumentPageLegacy } from './legacy/document-page';

export type DocumentPageProps = {
	orientation?: PageOrientation;
	children: React.ReactNode;
};

const DocumentPage: React.FC<DocumentPageProps> = props => {
	return isChromiumBaseBrowsers ? <DocumentPageModern {...props} /> : <DocumentPageLegacy {...props} />;
};

const isChromiumBaseBrowsers = isChrome || isChromium || isEdgeChromium || isYandex;

export { DocumentPage };
