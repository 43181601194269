import React from 'react';

import { Snackbar } from '@ui/snackbar';
import { Box } from '@ui/box';
import { Dialog } from '@ui/dialog';
import { RaisedButton } from '@ui/raised-button';
import { Divider } from '@ui/divider';
import { Root, ContentLayout, ErrorSignLayout, ErrorSign, ControlsLayout, dialogRootStyle } from './styled';

type ErrorMessageProps = {
	isOpen: boolean;
	lastErrorMessage: {
		title: string;
		message: string;
	};
	onClose: () => void;
};

type ErrorMessageState = {
	dialogIsOpen: boolean;
};

class ErrorMessage extends React.Component<ErrorMessageProps, ErrorMessageState> {
	state: ErrorMessageState = {
		dialogIsOpen: false,
	};

	handleCloseSnackbar = () => this.props.onClose();

	handleOpenDialog = () => this.setState({ dialogIsOpen: true });

	handleCloseDialog = () => this.setState({ dialogIsOpen: false });

	renderErrorText = () => {
		const { lastErrorMessage } = this.props;

		if (lastErrorMessage) {
			return (
				<>
					{lastErrorMessage.title}
					<Box margin='10px 0'>
						<Divider />
					</Box>
					{lastErrorMessage.message.split('\n').map((item, key) => {
						return (
							<span key={key}>
								{item}
								<br />
							</span>
						);
					})}
				</>
			);
		}

		return null;
	};

	render() {
		return (
			<Root>
				<Snackbar isOpen={this.props.isOpen} type='alarm' onClose={this.handleCloseSnackbar}>
					<ContentLayout>
						<ErrorSignLayout>
							<ErrorSign>Мы обнаружили ошибку 😢</ErrorSign>
							<ErrorSign>Обратитесь в службу технической поддержки.</ErrorSign>
						</ErrorSignLayout>
						<ControlsLayout>
							<RaisedButton appearance='outlined' color='primary' size='small' onClick={this.handleOpenDialog}>
								Техническая информация об ошибке
							</RaisedButton>
						</ControlsLayout>
					</ContentLayout>
				</Snackbar>
				<Dialog open={this.state.dialogIsOpen} style={dialogRootStyle} onRequestClose={this.handleCloseDialog}>
					{this.renderErrorText()}
				</Dialog>
			</Root>
		);
	}
}

export default ErrorMessage;
