import { combineReducers } from 'redux';

import { mainCashflowItemsReducer, MainCashflowItemsState } from './main.reducer';

export type CashflowItemsState = {
	main: MainCashflowItemsState;
};

const cashflowItemsReducer = combineReducers<CashflowItemsState>({
	main: mainCashflowItemsReducer,
});

export { cashflowItemsReducer };
