import React, { memo, useState, useMemo } from 'react';

import { counterpartyApi } from '@core/api/counterparty';
import { useMounted } from '@core/hooks/use-mounted';
import { useMapState, useMapDispatch } from '@flux';
import { useFormContext } from '@ui/forms';
import { runTenantLegalEntitiesInvalidationEffect } from '@tenant-legal-entities/actions/invalidators';
import { emitActionMessage as emitActionMessageAction } from '@shared/actions/action-message.actions';
import { securitySelectorsPack } from '@platform/selectors';
import {
	TenantLegalEntityAutopickerCRUD as XTenantLegalEntityAutopickerCRUD,
	TenantLegalEntityAutopickerCrudProps as XTenantLegalEntityAutopickerCrudProps,
} from './tenant-legal-entity-autopicker-crud.view';

export type TenantLegalEntityAutopickerCrudProps = {
	legalEntityID?: number;
	formName?: string;
	searchText?: string;
	transformer?: (value: LegalEntity) => any;
} & Pick<XTenantLegalEntityAutopickerCrudProps, 'variant' | 'isSmallContainer' | 'onRelatedPopupChange'> &
	Partial<Pick<XTenantLegalEntityAutopickerCrudProps, 'disabled'>>;

const TenantLegalEntityAutopickerCRUD: React.FC<TenantLegalEntityAutopickerCrudProps> = memo(props => {
	const { variant, formName, searchText, legalEntityID, transformer, disabled, ...rest } = props;
	const { mounted } = useMounted();
	const formContext = useFormContext<unknown>();
	const [isFetching, setIsFetching] = useState(false);
	const [initialFormObject, setInitialFormObject] = useState(createInitialFormObject());
	const [canChangeAnyData] = useMapState([securitySelectorsPack.selectCanChangeAnyData]);
	const [invalidateItems, emitActionMessage] = useMapDispatch([
		runTenantLegalEntitiesInvalidationEffect,
		emitActionMessageAction,
	]);
	const scope = useMemo(() => ({ isAdd: true }), []);

	const handleAddPopupOpen = () => {
		scope.isAdd = true;
		setIsFetching(false);
		setInitialFormObject(createInitialFormObject(searchText));
	};

	const handleEditPopupOpen = async () => {
		scope.isAdd = false;

		if (legalEntityID < 0) return;
		setIsFetching(true);

		const legalEntity = (await counterpartyApi.counterparty.fetchCounterpartyByID(legalEntityID)) as LegalEntity;

		if (legalEntity.LegalEntityTaxMode?.ID < 0) {
			legalEntity.LegalEntityTaxMode = null;
		}

		if (!mounted() || scope.isAdd) return;
		setInitialFormObject(legalEntity);
		setIsFetching(false);
	};

	const injectToParentForm = (legalEntity: LegalEntity) => {
		const parentFormObject = formContext?.formObject;

		if (formName && parentFormObject) {
			parentFormObject[formName] = legalEntity ? transformer(legalEntity) : null;
			formContext.handleObjectChange(parentFormObject);
		}
	};

	const handleRequestAdd = async (legalEntity: LegalEntity) => {
		const addedLegalEntity = (await counterpartyApi.counterparty.addCounterparty(legalEntity)) as LegalEntity;

		invalidateItems();
		emitActionMessage('Организация добавлена 🙃', 'success');
		injectToParentForm(addedLegalEntity);
	};

	const handleRequestEdit = async (legalEntity: LegalEntity) => {
		const updatedLegalEntity = (await counterpartyApi.counterparty.updateCounterparty(legalEntity)) as LegalEntity;

		invalidateItems();
		emitActionMessage('Организация изменена 😊', 'success');
		injectToParentForm(updatedLegalEntity);
	};

	const handleResetForm = () => injectToParentForm(null);

	return (
		<XTenantLegalEntityAutopickerCRUD
			{...rest}
			isFetching={isFetching}
			variant={variant}
			disabled={disabled || !canChangeAnyData}
			initialFormObject={initialFormObject}
			onAddPopupOpen={handleAddPopupOpen}
			onEditPopupOpen={handleEditPopupOpen}
			onRequestAdd={handleRequestAdd}
			onRequestEdit={handleRequestEdit}
			onResetForm={handleResetForm}
		/>
	);
});

TenantLegalEntityAutopickerCRUD.defaultProps = {
	transformer: x => x,
};

function createInitialFormObject(name = '') {
	const legalEntity: LegalEntity = {
		...new counterpartyApi.package.LegalEntity(),
		Name: name,
		TenantLegalEntity: true,
		LegalEntityType: {
			...new counterpartyApi.package.LegalEntityType(),
			ID: 1,
		},
	};

	return legalEntity;
}

export { TenantLegalEntityAutopickerCRUD };
