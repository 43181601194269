import React from 'react';

export type AppStoreIconProps = {
	appearance?: 'black' | 'white';
};

const AppStoreIcon: React.FC<AppStoreIconProps> = props => {
	const { appearance } = props;

	if (appearance === 'white') {
		return (
			<svg width='275' height='64' viewBox='0 0 275 64' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<g clipPath='url(#clip0_2437_5654)'>
					<path
						d='M259.377 0.000124823H15.2983C14.7099 0.000124823 14.1286 0.000124824 13.5418 0.00332482C13.0506 0.00652482 12.5633 0.0158208 12.0674 0.0236448C10.99 0.0362877 9.91511 0.130807 8.85213 0.30638C7.79064 0.485776 6.7624 0.823989 5.80219 1.30958C4.84315 1.79928 3.96685 2.43559 3.20508 3.19543C2.43931 3.95314 1.80098 4.82895 1.31464 5.78917C0.826955 6.74749 0.488792 7.77442 0.311832 8.83448C0.13319 9.89321 0.0370613 10.9641 0.0243082 12.0377C0.00941854 12.5282 0.00784595 13.0204 0 13.5111V50.4938C0.00784595 50.9906 0.00941854 51.4719 0.0243082 51.9689C0.0370652 53.0423 0.133194 54.1132 0.311832 55.1719C0.488304 56.2326 0.826486 57.2601 1.31464 58.2188C1.80076 59.1759 2.43918 60.0482 3.20508 60.8017C3.96396 61.5649 4.84083 62.2016 5.80219 62.6876C6.76239 63.1745 7.79052 63.5147 8.85213 63.6969C9.9153 63.871 10.99 63.9656 12.0674 63.9797C12.5633 63.9906 13.0506 63.9969 13.5418 63.9969C14.1286 64.0001 14.71 64 15.2983 64H259.377C259.953 64 260.539 64.0001 261.116 63.9969C261.605 63.9969 262.106 63.9906 262.595 63.9797C263.67 63.9663 264.743 63.8718 265.804 63.6969C266.869 63.5134 267.901 63.1733 268.866 62.6876C269.826 62.2013 270.702 61.5646 271.461 60.8017C272.225 60.0452 272.864 59.1736 273.356 58.2188C273.841 57.2594 274.176 56.232 274.35 55.1719C274.529 54.1131 274.628 53.0424 274.648 51.9689C274.654 51.4719 274.654 50.9906 274.654 50.4938C274.666 49.9125 274.666 49.3345 274.666 48.7437V15.2579C274.666 14.672 274.666 14.0907 274.654 13.5111C274.654 13.0204 274.654 12.5282 274.648 12.0376C274.628 10.964 274.529 9.89329 274.35 8.83442C274.175 7.77497 273.841 6.74812 273.356 5.78911C272.366 3.86443 270.796 2.29776 268.866 1.30944C267.901 0.825032 266.869 0.486909 265.804 0.306236C264.743 0.129889 263.671 0.0353372 262.595 0.0234208C262.106 0.0156128 261.605 0.00623682 261.116 0.00311682C260.539 -8.31764e-05 259.953 0.000124823 259.377 0.000124823Z'
						fill='#262626'
					/>
					<path
						d='M13.5503 62.6C13.0614 62.6 12.5844 62.5937 12.0993 62.5828C11.0946 62.5698 10.0922 62.4826 9.10033 62.3219C8.17549 62.163 7.27957 61.8676 6.44211 61.4452C5.61234 61.0264 4.85552 60.4772 4.20064 59.8187C3.53629 59.1679 2.98318 58.4132 2.56324 57.5843C2.13868 56.75 1.84485 55.8558 1.692 54.9328C1.52694 53.941 1.43764 52.9381 1.42486 51.9328C1.41468 51.5953 1.40137 50.4718 1.40137 50.4718V13.511C1.40137 13.511 1.41555 12.4047 1.42494 12.0797C1.43717 11.076 1.52596 10.0747 1.69053 9.08438C1.84366 8.15882 2.13771 7.26202 2.5625 6.42503C2.9809 5.59673 3.53093 4.8414 4.19129 4.18831C4.8509 3.52902 5.61014 2.97699 6.44133 2.55236C7.27686 2.13137 8.17109 1.838 9.09406 1.68206C10.0892 1.51976 11.0951 1.43202 12.1033 1.41956L13.5511 1.40002H261.109L262.574 1.42034C263.573 1.43218 264.57 1.51914 265.556 1.68049C266.488 1.83838 267.391 2.13381 268.237 2.55705C269.902 3.4128 271.257 4.76668 272.111 6.42893C272.529 7.26014 272.819 8.14963 272.97 9.0672C273.137 10.0656 273.23 11.0748 273.249 12.0867C273.254 12.5399 273.254 13.0266 273.254 13.511C273.266 14.111 273.266 14.682 273.266 15.2578V48.7437C273.266 49.3249 273.266 49.8921 273.254 50.464C273.254 50.9843 273.254 51.4609 273.247 51.9515C273.229 52.9454 273.137 53.9366 272.973 54.9171C272.823 55.8468 272.531 56.748 272.107 57.5891C271.684 58.409 271.134 59.1572 270.477 59.8062C269.822 60.4683 269.064 61.0207 268.232 61.4422C267.389 61.8678 266.487 62.1643 265.556 62.3219C264.564 62.4834 263.561 62.5706 262.556 62.5828C262.087 62.5937 261.594 62.6 261.117 62.6L259.377 62.6032L13.5503 62.6Z'
						fill='white'
					/>
					<path
						d='M85.6004 31.834C85.6172 30.5321 85.964 29.2556 86.6085 28.1232C87.253 26.9908 88.1743 26.0394 89.2866 25.3575C88.58 24.3511 87.6478 23.523 86.564 22.9387C85.4803 22.3545 84.2749 22.0303 83.0435 21.9918C80.4168 21.7169 77.8703 23.5592 76.5318 23.5592C75.1674 23.5592 73.1065 22.0191 70.8873 22.0647C69.4518 22.1109 68.0529 22.5272 66.8267 23.2729C65.6006 24.0186 64.589 25.0683 63.8906 26.3197C60.8655 31.5427 63.122 39.2188 66.0199 43.4407C67.4697 45.5081 69.1642 47.8173 71.3814 47.7354C73.5511 47.6457 74.3614 46.3558 76.9804 46.3558C79.5751 46.3558 80.3353 47.7354 82.5975 47.6834C84.9257 47.6457 86.3925 45.6068 87.7916 43.5199C88.8333 42.0469 89.6349 40.4188 90.1667 38.6961C88.8141 38.1256 87.6599 37.1707 86.8478 35.9504C86.0358 34.7302 85.602 33.2985 85.6004 31.834Z'
						fill='#262626'
					/>
					<path
						d='M81.3273 19.2152C82.5968 17.6956 83.2222 15.7424 83.0707 13.7704C81.1313 13.9735 79.3399 14.8978 78.0534 16.3591C77.4243 17.073 76.9425 17.9035 76.6356 18.8032C76.3286 19.7029 76.2024 20.654 76.2643 21.6024C77.2344 21.6123 78.194 21.4027 79.071 20.9892C79.948 20.5757 80.7195 19.9691 81.3273 19.2152Z'
						fill='#262626'
					/>
					<path
						d='M111.631 42.7094H104.511L102.801 47.7441H99.7852L106.529 29.1171H109.662L116.406 47.7441H113.339L111.631 42.7094ZM105.248 40.3861H110.892L108.11 32.2152H108.032L105.248 40.3861Z'
						fill='#262626'
					/>
					<path
						d='M130.971 40.9545C130.971 45.1748 128.706 47.8862 125.288 47.8862C124.422 47.9313 123.561 47.7324 122.803 47.3122C122.045 46.892 121.421 46.2675 121.003 45.5102H120.938V52.2367H118.143V34.1635H120.848V36.4223H120.9C121.337 35.6686 121.972 35.0473 122.735 34.6246C123.499 34.2018 124.363 33.9934 125.236 34.0214C128.693 34.0214 130.971 36.746 130.971 40.9545ZM128.098 40.9545C128.098 38.205 126.673 36.3974 124.499 36.3974C122.363 36.3974 120.926 38.2431 120.926 40.9545C120.926 43.6909 122.363 45.5234 124.499 45.5234C126.673 45.5234 128.098 43.729 128.098 40.9545H128.098Z'
						fill='#262626'
					/>
					<path
						d='M145.961 40.9545C145.961 45.1748 143.695 47.8862 140.277 47.8862C139.411 47.9313 138.55 47.7324 137.792 47.3122C137.034 46.892 136.41 46.2675 135.992 45.5102H135.927V52.2367H133.132V34.1635H135.838V36.4223H135.889C136.327 35.6686 136.961 35.0473 137.725 34.6246C138.488 34.2018 139.352 33.9934 140.225 34.0214C143.682 34.0214 145.961 36.746 145.961 40.9545ZM143.087 40.9545C143.087 38.205 141.662 36.3974 139.488 36.3974C137.352 36.3974 135.916 38.2431 135.916 40.9545C135.916 43.6909 137.352 45.5234 139.488 45.5234C141.662 45.5234 143.087 43.729 143.087 40.9545Z'
						fill='#262626'
					/>
					<path
						d='M155.866 42.5542C156.074 44.4013 157.873 45.6142 160.332 45.6142C162.688 45.6142 164.383 44.4013 164.383 42.7357C164.383 41.2899 163.361 40.4242 160.94 39.8309L158.519 39.2494C155.089 38.4232 153.497 36.8236 153.497 34.2279C153.497 31.014 156.306 28.8065 160.292 28.8065C164.241 28.8065 166.947 31.014 167.038 34.2279H164.216C164.047 32.369 162.506 31.247 160.254 31.247C158.002 31.247 156.461 32.3822 156.461 34.0346C156.461 35.3515 157.446 36.1264 159.853 36.7196L161.911 37.2235C165.744 38.1273 167.335 39.6625 167.335 42.387C167.335 45.8719 164.552 48.0545 160.125 48.0545C155.982 48.0545 153.186 45.9232 153.005 42.554L155.866 42.5542Z'
						fill='#262626'
					/>
					<path
						d='M173.37 30.9497V34.1636H175.96V36.3711H173.37V43.8579C173.37 45.021 173.888 45.563 175.027 45.563C175.334 45.5576 175.641 45.5361 175.946 45.4985V47.6928C175.435 47.7882 174.914 47.8314 174.394 47.8217C171.636 47.8217 170.561 46.789 170.561 44.1552V36.3711H168.581V34.1636H170.561V30.9497H173.37Z'
						fill='#262626'
					/>
					<path
						d='M177.458 40.9546C177.458 36.6816 179.982 33.9966 183.917 33.9966C187.866 33.9966 190.377 36.6816 190.377 40.9546C190.377 45.2393 187.879 47.9126 183.917 47.9126C179.957 47.9126 177.458 45.2392 177.458 40.9546ZM187.529 40.9546C187.529 38.0234 186.182 36.2935 183.917 36.2935C181.652 36.2935 180.305 38.0366 180.305 40.9546C180.305 43.8975 181.652 45.6143 183.917 45.6143C186.182 45.6143 187.529 43.8975 187.529 40.9546H187.529Z'
						fill='#262626'
					/>
					<path
						d='M192.683 34.1635H195.349V36.475H195.413C195.594 35.7531 196.018 35.1151 196.615 34.6683C197.211 34.2214 197.944 33.993 198.689 34.0214C199.011 34.0203 199.333 34.0552 199.647 34.1254V36.7328C199.24 36.6089 198.816 36.552 198.391 36.5644C197.985 36.548 197.58 36.6194 197.204 36.7737C196.828 36.928 196.491 37.1616 196.214 37.4585C195.937 37.7553 195.728 38.1083 195.601 38.4933C195.474 38.8783 195.432 39.2862 195.478 39.6889V47.7441H192.683L192.683 34.1635Z'
						fill='#262626'
					/>
					<path
						d='M212.536 43.7554C212.16 46.2207 209.752 47.9126 206.672 47.9126C202.71 47.9126 200.251 45.2656 200.251 41.019C200.251 36.7593 202.723 33.9966 206.554 33.9966C210.322 33.9966 212.692 36.5776 212.692 40.6953V41.6504H203.073V41.8188C203.028 42.3186 203.091 42.8221 203.257 43.2959C203.423 43.7697 203.688 44.2028 204.034 44.5667C204.381 44.9305 204.801 45.2166 205.267 45.4061C205.733 45.5955 206.234 45.684 206.736 45.6655C207.397 45.7272 208.06 45.5747 208.627 45.2305C209.193 44.8864 209.633 44.369 209.881 43.7554L212.536 43.7554ZM203.086 39.7021H209.895C209.92 39.2528 209.851 38.803 209.694 38.3812C209.537 37.9594 209.293 37.5746 208.98 37.251C208.666 36.9273 208.289 36.6718 207.871 36.5005C207.454 36.3292 207.006 36.2457 206.554 36.2554C206.099 36.2527 205.648 36.3399 205.227 36.512C204.805 36.6841 204.422 36.9377 204.1 37.2582C203.778 37.5786 203.522 37.9596 203.348 38.379C203.174 38.7985 203.085 39.2482 203.086 39.7022V39.7021Z'
						fill='#262626'
					/>
					<path
						d='M103.262 18.8801H104.462C104.669 18.9057 104.879 18.8884 105.078 18.8293C105.278 18.7701 105.463 18.6704 105.622 18.5363C105.781 18.4023 105.91 18.2368 106.002 18.0505C106.094 17.8641 106.146 17.6607 106.155 17.4533C106.155 16.6279 105.532 16.0947 104.476 16.0947C104.264 16.0652 104.048 16.0783 103.842 16.1332C103.635 16.1881 103.442 16.2837 103.273 16.4144C103.104 16.5451 102.964 16.7082 102.859 16.894C102.754 17.0799 102.688 17.2848 102.664 17.4965H101.347C101.369 17.1121 101.471 16.7364 101.646 16.3929C101.82 16.0494 102.064 15.7454 102.361 15.4998C102.659 15.2543 103.004 15.0724 103.375 14.9656C103.746 14.8588 104.135 14.8292 104.518 14.8789C106.285 14.8789 107.53 15.8654 107.53 17.2856C107.534 17.7614 107.374 18.2242 107.077 18.5967C106.781 18.9693 106.365 19.2291 105.899 19.3327V19.4382C106.451 19.4626 106.971 19.704 107.345 20.1095C107.719 20.5151 107.917 21.0519 107.895 21.6025C107.854 21.9983 107.735 22.3821 107.543 22.7312C107.352 23.0803 107.092 23.3878 106.78 23.6356C106.468 23.8834 106.109 24.0666 105.725 24.1744C105.341 24.2822 104.939 24.3125 104.543 24.2634C104.146 24.3173 103.743 24.2911 103.356 24.1861C102.97 24.0812 102.609 23.8997 102.294 23.6526C101.979 23.4054 101.718 23.0977 101.525 22.7476C101.332 22.3975 101.212 22.0123 101.172 21.615H102.51C102.591 22.4712 103.38 23.0235 104.543 23.0235C105.707 23.0235 106.466 22.4031 106.466 21.5286C106.466 20.6416 105.751 20.0952 104.501 20.0952H103.262V18.8801Z'
						fill='#262626'
					/>
					<path
						d='M109.416 22.1365C109.416 20.9206 110.325 20.2197 111.935 20.1201L113.77 20.0146V19.4316C113.77 18.7183 113.298 18.3154 112.383 18.3154C111.637 18.3154 111.121 18.5886 110.972 19.0662H109.677C109.814 17.906 110.91 17.1619 112.446 17.1619C114.145 17.1619 115.102 18.0049 115.102 19.4316V24.0466H113.814V23.0974H113.708C113.494 23.4379 113.192 23.7155 112.835 23.9018C112.477 24.0881 112.077 24.1765 111.674 24.1579C111.389 24.1874 111.102 24.1572 110.83 24.0691C110.558 23.9811 110.308 23.8372 110.095 23.6467C109.882 23.4563 109.712 23.2235 109.595 22.9633C109.478 22.7032 109.417 22.4215 109.416 22.1365ZM113.77 21.5593V20.9946L112.116 21.1001C111.183 21.1623 110.76 21.4787 110.76 22.0742C110.76 22.6821 111.289 23.0359 112.016 23.0359C112.229 23.0574 112.444 23.0359 112.649 22.9728C112.854 22.9097 113.043 22.8061 113.207 22.6684C113.371 22.5306 113.505 22.3614 113.601 22.1709C113.698 21.9804 113.756 21.7724 113.77 21.5593Z'
						fill='#262626'
					/>
					<path d='M118.649 18.4142V24.0465H117.312V17.2921H121.772V18.4141L118.649 18.4142Z' fill='#262626' />
					<path
						d='M129.874 20.6664C129.874 22.8124 128.786 24.1579 127.076 24.1579C126.656 24.1865 126.237 24.0949 125.868 23.8939C125.498 23.6929 125.194 23.3909 124.991 23.0234H124.886V26.2922H123.549V17.2922H124.836V18.4084H124.936C125.142 18.0281 125.45 17.7121 125.825 17.4953C126.201 17.2786 126.629 17.1695 127.063 17.1802C128.786 17.1801 129.874 18.5197 129.874 20.6664ZM128.499 20.6664C128.499 19.2397 127.822 18.3776 126.689 18.3776C125.564 18.3776 124.861 19.258 124.861 20.6723C124.861 22.08 125.564 22.9611 126.684 22.9611C127.822 22.9611 128.499 22.099 128.499 20.6664Z'
						fill='#262626'
					/>
					<path
						d='M131.654 26.4657V25.318C131.799 25.3363 131.945 25.3446 132.091 25.3429C132.375 25.3639 132.658 25.2794 132.884 25.1054C133.11 24.9314 133.264 24.6803 133.316 24.4003L133.416 24.0714L130.964 17.2921H132.42L134.118 22.6571H134.224L135.916 17.2921H137.333L134.808 24.3256C134.229 25.9691 133.589 26.4965 132.208 26.4965C132.096 26.4965 131.779 26.484 131.654 26.4657Z'
						fill='#262626'
					/>
					<path
						d='M138.375 22.1298H139.706C139.837 22.7253 140.396 23.0358 141.179 23.0358C142.069 23.0358 142.616 22.6447 142.616 22.0368C142.616 21.3857 142.125 21.0751 141.179 21.0751H140.166V20.0395H141.162C141.97 20.0395 142.381 19.7297 142.381 19.165C142.381 18.6691 141.983 18.2905 141.162 18.2905C140.414 18.2905 139.867 18.6069 139.781 19.1401H138.493C138.589 18.5274 138.922 17.9769 139.422 17.6069C139.921 17.237 140.546 17.0771 141.162 17.1618C142.772 17.1618 143.693 17.8877 143.693 19.0288C143.7 19.3465 143.598 19.6572 143.403 19.9089C143.209 20.1606 142.933 20.3381 142.623 20.4116V20.5171C142.992 20.5645 143.331 20.7455 143.576 21.0257C143.82 21.3058 143.952 21.6656 143.948 22.0368C143.948 23.3208 142.852 24.1704 141.179 24.1704C139.7 24.1703 138.498 23.4943 138.375 22.1298Z'
						fill='#262626'
					/>
					<path
						d='M145.873 24.0465V17.2921H147.198V21.9379H147.304L150.425 17.2921H151.75V24.0465H150.425V19.3759H150.325L147.198 24.0465L145.873 24.0465Z'
						fill='#262626'
					/>
					<path d='M159.049 18.4142H156.916V24.0466H155.578V18.4142H153.445V17.2922H159.049V18.4142Z' fill='#262626' />
					<path
						d='M166.25 22.2229C166.068 22.8402 165.674 23.374 165.138 23.7312C164.601 24.0884 163.956 24.2462 163.314 24.177C162.868 24.1887 162.424 24.1035 162.014 23.9272C161.604 23.7509 161.238 23.4878 160.94 23.1561C160.643 22.8244 160.421 22.432 160.29 22.0063C160.16 21.5806 160.124 21.1316 160.185 20.6907C160.126 20.2483 160.162 19.7984 160.292 19.3714C160.422 18.9444 160.643 18.5503 160.939 18.2157C161.235 17.8811 161.6 17.6138 162.009 17.432C162.417 17.2502 162.861 17.1581 163.308 17.1619C165.193 17.1619 166.331 18.4459 166.331 20.5669V21.032H161.548V21.1067C161.527 21.3546 161.558 21.6042 161.64 21.8395C161.721 22.0747 161.851 22.2904 162.021 22.4726C162.19 22.6548 162.397 22.7996 162.626 22.8976C162.855 22.9956 163.103 23.0447 163.352 23.0417C163.672 23.0798 163.995 23.0223 164.282 22.8765C164.569 22.7307 164.806 22.5032 164.962 22.2229L166.25 22.2229ZM161.548 20.0461H164.968C164.985 19.8196 164.954 19.592 164.878 19.378C164.801 19.164 164.68 18.9683 164.524 18.8035C164.367 18.6388 164.177 18.5085 163.967 18.4212C163.756 18.3339 163.53 18.2913 163.302 18.2964C163.071 18.2933 162.841 18.3364 162.627 18.4232C162.413 18.51 162.218 18.6388 162.055 18.8019C161.891 18.9651 161.762 19.1592 161.675 19.3729C161.588 19.5866 161.545 19.8155 161.548 20.0461H161.548Z'
						fill='#262626'
					/>
					<path
						d='M171.918 17.2921H174.972C176.297 17.2921 177.074 17.9615 177.074 19.0096C177.076 19.3422 176.964 19.6654 176.755 19.9251C176.547 20.1848 176.255 20.3653 175.93 20.4364V20.5418C176.325 20.5668 176.696 20.7422 176.966 21.032C177.235 21.3218 177.383 21.7037 177.378 22.099C177.378 23.2774 176.489 24.0465 175.003 24.0465H171.918V17.2921ZM173.243 18.3153V20.0643H174.568C175.376 20.0643 175.762 19.7545 175.762 19.1832C175.762 18.6566 175.426 18.3153 174.716 18.3153L173.243 18.3153ZM173.243 21.0443V23.0233H174.828C175.618 23.0233 176.041 22.6754 176.041 22.0368C176.041 21.3607 175.563 21.0443 174.661 21.0443L173.243 21.0443Z'
						fill='#262626'
					/>
				</g>
				<defs>
					<clipPath id='clip0_2437_5654'>
						<rect width='275' height='64' fill='white' />
					</clipPath>
				</defs>
			</svg>
		);
	}

	return (
		<svg
			version='1.1'
			xmlns='http://www.w3.org/2000/svg'
			xmlnsXlink='http://www.w3.org/1999/xlink'
			width='135px'
			height='40px'
			viewBox='0 0 135 40'
			enableBackground='new 0 0 135 40'
			xmlSpace='preserve'>
			<g>
				<path
					fill='#A6A6A6'
					d='M130.197,40H4.729C2.122,40,0,37.872,0,35.267V4.726C0,2.12,2.122,0,4.729,0h125.468
		C132.803,0,135,2.12,135,4.726v30.541C135,37.872,132.803,40,130.197,40L130.197,40z'
				/>
				<path
					d='M134.032,35.268c0,2.116-1.714,3.83-3.834,3.83H4.729c-2.119,0-3.839-1.714-3.839-3.83V4.725
		c0-2.115,1.72-3.835,3.839-3.835h125.468c2.121,0,3.834,1.72,3.834,3.835L134.032,35.268L134.032,35.268z'
				/>
				<g>
					<g>
						<path
							fill='#FFFFFF'
							d='M30.128,19.784c-0.029-3.223,2.639-4.791,2.761-4.864c-1.511-2.203-3.853-2.504-4.676-2.528
				c-1.967-0.207-3.875,1.177-4.877,1.177c-1.022,0-2.565-1.157-4.228-1.123c-2.14,0.033-4.142,1.272-5.24,3.196
				c-2.266,3.923-0.576,9.688,1.595,12.859c1.086,1.553,2.355,3.287,4.016,3.226c1.625-0.067,2.232-1.036,4.193-1.036
				c1.943,0,2.513,1.036,4.207,0.997c1.744-0.028,2.842-1.56,3.89-3.127c1.255-1.78,1.759-3.533,1.779-3.623
				C33.507,24.924,30.161,23.647,30.128,19.784z'
						/>
						<path
							fill='#FFFFFF'
							d='M26.928,10.306c0.874-1.093,1.472-2.58,1.306-4.089c-1.265,0.056-2.847,0.875-3.758,1.944
				c-0.806,0.942-1.526,2.486-1.34,3.938C24.557,12.205,26.016,11.382,26.928,10.306z'
						/>
					</g>
				</g>
				<g>
					<path
						fill='#FFFFFF'
						d='M53.645,31.504h-2.271l-1.244-3.909h-4.324l-1.185,3.909h-2.211l4.284-13.308h2.646L53.645,31.504z
			 M49.755,25.955L48.63,22.48c-0.119-0.355-0.342-1.191-0.671-2.507h-0.04c-0.131,0.566-0.342,1.402-0.632,2.507l-1.105,3.475
			H49.755z'
					/>
					<path
						fill='#FFFFFF'
						d='M64.662,26.588c0,1.632-0.441,2.922-1.323,3.869c-0.79,0.843-1.771,1.264-2.942,1.264
			c-1.264,0-2.172-0.454-2.725-1.362h-0.04v5.055h-2.132V25.067c0-1.026-0.027-2.079-0.079-3.159h1.875l0.119,1.521h0.04
			c0.711-1.146,1.79-1.718,3.238-1.718c1.132,0,2.077,0.447,2.833,1.342C64.284,23.949,64.662,25.127,64.662,26.588z M62.49,26.666
			c0-0.934-0.21-1.704-0.632-2.31c-0.461-0.632-1.08-0.948-1.856-0.948c-0.526,0-1.004,0.176-1.431,0.523
			c-0.428,0.35-0.708,0.807-0.839,1.373c-0.066,0.264-0.099,0.48-0.099,0.65v1.6c0,0.698,0.214,1.287,0.642,1.768
			s0.984,0.721,1.668,0.721c0.803,0,1.428-0.31,1.875-0.928C62.266,28.496,62.49,27.68,62.49,26.666z'
					/>
					<path
						fill='#FFFFFF'
						d='M75.699,26.588c0,1.632-0.441,2.922-1.324,3.869c-0.789,0.843-1.77,1.264-2.941,1.264
			c-1.264,0-2.172-0.454-2.724-1.362H68.67v5.055h-2.132V25.067c0-1.026-0.027-2.079-0.079-3.159h1.875l0.119,1.521h0.04
			c0.71-1.146,1.789-1.718,3.238-1.718c1.131,0,2.076,0.447,2.834,1.342C75.32,23.949,75.699,25.127,75.699,26.588z M73.527,26.666
			c0-0.934-0.211-1.704-0.633-2.31c-0.461-0.632-1.078-0.948-1.855-0.948c-0.527,0-1.004,0.176-1.432,0.523
			c-0.428,0.35-0.707,0.807-0.838,1.373c-0.065,0.264-0.099,0.48-0.099,0.65v1.6c0,0.698,0.214,1.287,0.64,1.768
			c0.428,0.48,0.984,0.721,1.67,0.721c0.803,0,1.428-0.31,1.875-0.928C73.303,28.496,73.527,27.68,73.527,26.666z'
					/>
					<path
						fill='#FFFFFF'
						d='M88.039,27.772c0,1.132-0.393,2.053-1.182,2.764c-0.867,0.777-2.074,1.165-3.625,1.165
			c-1.432,0-2.58-0.276-3.449-0.829l0.494-1.777c0.936,0.566,1.963,0.85,3.082,0.85c0.803,0,1.428-0.182,1.877-0.544
			c0.447-0.362,0.67-0.848,0.67-1.454c0-0.54-0.184-0.995-0.553-1.364c-0.367-0.369-0.98-0.712-1.836-1.029
			c-2.33-0.869-3.494-2.142-3.494-3.816c0-1.094,0.408-1.991,1.225-2.689c0.814-0.699,1.9-1.048,3.258-1.048
			c1.211,0,2.217,0.211,3.02,0.632l-0.533,1.738c-0.75-0.408-1.598-0.612-2.547-0.612c-0.75,0-1.336,0.185-1.756,0.553
			c-0.355,0.329-0.533,0.73-0.533,1.205c0,0.526,0.203,0.961,0.611,1.303c0.355,0.316,1,0.658,1.936,1.027
			c1.145,0.461,1.986,1,2.527,1.618C87.77,26.081,88.039,26.852,88.039,27.772z'
					/>
					<path
						fill='#FFFFFF'
						d='M95.088,23.508h-2.35v4.659c0,1.185,0.414,1.777,1.244,1.777c0.381,0,0.697-0.033,0.947-0.099l0.059,1.619
			c-0.42,0.157-0.973,0.236-1.658,0.236c-0.842,0-1.5-0.257-1.975-0.77c-0.473-0.514-0.711-1.376-0.711-2.587v-4.837h-1.4v-1.6h1.4
			v-1.757l2.094-0.632v2.389h2.35V23.508z'
					/>
					<path
						fill='#FFFFFF'
						d='M105.691,26.627c0,1.475-0.422,2.686-1.264,3.633c-0.883,0.975-2.055,1.461-3.516,1.461
			c-1.408,0-2.529-0.467-3.365-1.401s-1.254-2.113-1.254-3.534c0-1.487,0.43-2.705,1.293-3.652c0.861-0.948,2.023-1.422,3.484-1.422
			c1.408,0,2.541,0.467,3.396,1.402C105.283,24.021,105.691,25.192,105.691,26.627z M103.479,26.696
			c0-0.885-0.189-1.644-0.572-2.277c-0.447-0.766-1.086-1.148-1.914-1.148c-0.857,0-1.508,0.383-1.955,1.148
			c-0.383,0.634-0.572,1.405-0.572,2.317c0,0.885,0.189,1.644,0.572,2.276c0.461,0.766,1.105,1.148,1.936,1.148
			c0.814,0,1.453-0.39,1.914-1.168C103.281,28.347,103.479,27.58,103.479,26.696z'
					/>
					<path
						fill='#FFFFFF'
						d='M112.621,23.783c-0.211-0.039-0.436-0.059-0.672-0.059c-0.75,0-1.33,0.283-1.738,0.85
			c-0.355,0.5-0.533,1.132-0.533,1.895v5.035h-2.131l0.02-6.574c0-1.106-0.027-2.113-0.08-3.021h1.857l0.078,1.836h0.059
			c0.225-0.631,0.58-1.139,1.066-1.52c0.475-0.343,0.988-0.514,1.541-0.514c0.197,0,0.375,0.014,0.533,0.039V23.783z'
					/>
					<path
						fill='#FFFFFF'
						d='M122.156,26.252c0,0.382-0.025,0.704-0.078,0.967h-6.396c0.025,0.948,0.334,1.673,0.928,2.173
			c0.539,0.447,1.236,0.671,2.092,0.671c0.947,0,1.811-0.151,2.588-0.454l0.334,1.48c-0.908,0.396-1.98,0.593-3.217,0.593
			c-1.488,0-2.656-0.438-3.506-1.313c-0.848-0.875-1.273-2.05-1.273-3.524c0-1.447,0.395-2.652,1.186-3.613
			c0.828-1.026,1.947-1.539,3.355-1.539c1.383,0,2.43,0.513,3.141,1.539C121.873,24.047,122.156,25.055,122.156,26.252z
			 M120.123,25.699c0.014-0.632-0.125-1.178-0.414-1.639c-0.369-0.593-0.936-0.889-1.699-0.889c-0.697,0-1.264,0.289-1.697,0.869
			c-0.355,0.461-0.566,1.014-0.631,1.658H120.123z'
					/>
				</g>
				<g>
					<g>
						<path
							fill='#FFFFFF'
							d='M49.05,10.009c0,1.177-0.353,2.063-1.058,2.658c-0.653,0.549-1.581,0.824-2.783,0.824
				c-0.596,0-1.106-0.026-1.533-0.078V6.982c0.557-0.09,1.157-0.136,1.805-0.136c1.145,0,2.008,0.249,2.59,0.747
				C48.723,8.156,49.05,8.961,49.05,10.009z M47.945,10.038c0-0.763-0.202-1.348-0.606-1.756c-0.404-0.407-0.994-0.611-1.771-0.611
				c-0.33,0-0.611,0.022-0.844,0.068v4.889c0.129,0.02,0.365,0.029,0.708,0.029c0.802,0,1.421-0.223,1.857-0.669
				S47.945,10.892,47.945,10.038z'
						/>
						<path
							fill='#FFFFFF'
							d='M54.909,11.037c0,0.725-0.207,1.319-0.621,1.785c-0.434,0.479-1.009,0.718-1.727,0.718
				c-0.692,0-1.243-0.229-1.654-0.689c-0.41-0.459-0.615-1.038-0.615-1.736c0-0.73,0.211-1.329,0.635-1.794s0.994-0.698,1.712-0.698
				c0.692,0,1.248,0.229,1.669,0.688C54.708,9.757,54.909,10.333,54.909,11.037z M53.822,11.071c0-0.435-0.094-0.808-0.281-1.119
				c-0.22-0.376-0.533-0.564-0.94-0.564c-0.421,0-0.741,0.188-0.961,0.564c-0.188,0.311-0.281,0.69-0.281,1.138
				c0,0.435,0.094,0.808,0.281,1.119c0.227,0.376,0.543,0.564,0.951,0.564c0.4,0,0.714-0.191,0.94-0.574
				C53.725,11.882,53.822,11.506,53.822,11.071z'
						/>
						<path
							fill='#FFFFFF'
							d='M62.765,8.719l-1.475,4.714h-0.96l-0.611-2.047c-0.155-0.511-0.281-1.019-0.379-1.523h-0.019
				c-0.091,0.518-0.217,1.025-0.379,1.523l-0.649,2.047h-0.971l-1.387-4.714h1.077l0.533,2.241c0.129,0.53,0.235,1.035,0.32,1.513
				h0.019c0.078-0.394,0.207-0.896,0.389-1.503l0.669-2.25h0.854l0.641,2.202c0.155,0.537,0.281,1.054,0.378,1.552h0.029
				c0.071-0.485,0.178-1.002,0.32-1.552l0.572-2.202H62.765z'
						/>
						<path
							fill='#FFFFFF'
							d='M68.198,13.433H67.15v-2.7c0-0.832-0.316-1.248-0.95-1.248c-0.311,0-0.562,0.114-0.757,0.343
				c-0.193,0.229-0.291,0.499-0.291,0.808v2.796h-1.048v-3.366c0-0.414-0.013-0.863-0.038-1.349h0.921l0.049,0.737h0.029
				c0.122-0.229,0.304-0.418,0.543-0.569c0.284-0.176,0.602-0.265,0.95-0.265c0.44,0,0.806,0.142,1.097,0.427
				c0.362,0.349,0.543,0.87,0.543,1.562V13.433z'
						/>
						<path fill='#FFFFFF' d='M71.088,13.433h-1.047V6.556h1.047V13.433z' />
						<path
							fill='#FFFFFF'
							d='M77.258,11.037c0,0.725-0.207,1.319-0.621,1.785c-0.434,0.479-1.01,0.718-1.727,0.718
				c-0.693,0-1.244-0.229-1.654-0.689c-0.41-0.459-0.615-1.038-0.615-1.736c0-0.73,0.211-1.329,0.635-1.794s0.994-0.698,1.711-0.698
				c0.693,0,1.248,0.229,1.67,0.688C77.057,9.757,77.258,10.333,77.258,11.037z M76.17,11.071c0-0.435-0.094-0.808-0.281-1.119
				c-0.219-0.376-0.533-0.564-0.939-0.564c-0.422,0-0.742,0.188-0.961,0.564c-0.188,0.311-0.281,0.69-0.281,1.138
				c0,0.435,0.094,0.808,0.281,1.119c0.227,0.376,0.543,0.564,0.951,0.564c0.4,0,0.713-0.191,0.939-0.574
				C76.074,11.882,76.17,11.506,76.17,11.071z'
						/>
						<path
							fill='#FFFFFF'
							d='M82.33,13.433h-0.941l-0.078-0.543h-0.029c-0.322,0.433-0.781,0.65-1.377,0.65
				c-0.445,0-0.805-0.143-1.076-0.427c-0.246-0.258-0.369-0.579-0.369-0.96c0-0.576,0.24-1.015,0.723-1.319
				c0.482-0.304,1.16-0.453,2.033-0.446V10.3c0-0.621-0.326-0.931-0.979-0.931c-0.465,0-0.875,0.117-1.229,0.349l-0.213-0.688
				c0.438-0.271,0.979-0.407,1.617-0.407c1.232,0,1.85,0.65,1.85,1.95v1.736C82.262,12.78,82.285,13.155,82.33,13.433z
				 M81.242,11.813v-0.727c-1.156-0.02-1.734,0.297-1.734,0.95c0,0.246,0.066,0.43,0.201,0.553c0.135,0.123,0.307,0.184,0.512,0.184
				c0.23,0,0.445-0.073,0.641-0.218c0.197-0.146,0.318-0.331,0.363-0.558C81.236,11.946,81.242,11.884,81.242,11.813z'
						/>
						<path
							fill='#FFFFFF'
							d='M88.285,13.433h-0.93l-0.049-0.757h-0.029c-0.297,0.576-0.803,0.864-1.514,0.864
				c-0.568,0-1.041-0.223-1.416-0.669s-0.562-1.025-0.562-1.736c0-0.763,0.203-1.381,0.611-1.853c0.395-0.44,0.879-0.66,1.455-0.66
				c0.633,0,1.076,0.213,1.328,0.64h0.02V6.556h1.049v5.607C88.248,12.622,88.26,13.045,88.285,13.433z M87.199,11.445v-0.786
				c0-0.136-0.01-0.246-0.029-0.33c-0.059-0.252-0.186-0.464-0.379-0.635c-0.195-0.171-0.43-0.257-0.701-0.257
				c-0.391,0-0.697,0.155-0.922,0.466c-0.223,0.311-0.336,0.708-0.336,1.193c0,0.466,0.107,0.844,0.322,1.135
				c0.227,0.31,0.533,0.465,0.916,0.465c0.344,0,0.619-0.129,0.828-0.388C87.1,12.069,87.199,11.781,87.199,11.445z'
						/>
						<path
							fill='#FFFFFF'
							d='M97.248,11.037c0,0.725-0.207,1.319-0.621,1.785c-0.434,0.479-1.008,0.718-1.727,0.718
				c-0.691,0-1.242-0.229-1.654-0.689c-0.41-0.459-0.615-1.038-0.615-1.736c0-0.73,0.211-1.329,0.635-1.794s0.994-0.698,1.713-0.698
				c0.691,0,1.248,0.229,1.668,0.688C97.047,9.757,97.248,10.333,97.248,11.037z M96.162,11.071c0-0.435-0.094-0.808-0.281-1.119
				c-0.221-0.376-0.533-0.564-0.941-0.564c-0.42,0-0.74,0.188-0.961,0.564c-0.188,0.311-0.281,0.69-0.281,1.138
				c0,0.435,0.094,0.808,0.281,1.119c0.227,0.376,0.543,0.564,0.951,0.564c0.4,0,0.715-0.191,0.941-0.574
				C96.064,11.882,96.162,11.506,96.162,11.071z'
						/>
						<path
							fill='#FFFFFF'
							d='M102.883,13.433h-1.047v-2.7c0-0.832-0.316-1.248-0.951-1.248c-0.311,0-0.562,0.114-0.756,0.343
				s-0.291,0.499-0.291,0.808v2.796h-1.049v-3.366c0-0.414-0.012-0.863-0.037-1.349h0.92l0.049,0.737h0.029
				c0.123-0.229,0.305-0.418,0.543-0.569c0.285-0.176,0.602-0.265,0.951-0.265c0.439,0,0.805,0.142,1.096,0.427
				c0.363,0.349,0.543,0.87,0.543,1.562V13.433z'
						/>
						<path
							fill='#FFFFFF'
							d='M109.936,9.504h-1.154v2.29c0,0.582,0.205,0.873,0.611,0.873c0.188,0,0.344-0.016,0.467-0.049
				l0.027,0.795c-0.207,0.078-0.479,0.117-0.814,0.117c-0.414,0-0.736-0.126-0.969-0.378c-0.234-0.252-0.35-0.676-0.35-1.271V9.504
				h-0.689V8.719h0.689V7.855l1.027-0.31v1.173h1.154V9.504z'
						/>
						<path
							fill='#FFFFFF'
							d='M115.484,13.433h-1.049v-2.68c0-0.845-0.316-1.268-0.949-1.268c-0.486,0-0.818,0.245-1,0.735
				c-0.031,0.103-0.049,0.229-0.049,0.377v2.835h-1.047V6.556h1.047v2.841h0.02c0.33-0.517,0.803-0.775,1.416-0.775
				c0.434,0,0.793,0.142,1.078,0.427c0.355,0.355,0.533,0.883,0.533,1.581V13.433z'
						/>
						<path
							fill='#FFFFFF'
							d='M121.207,10.853c0,0.188-0.014,0.346-0.039,0.475h-3.143c0.014,0.466,0.164,0.821,0.455,1.067
				c0.266,0.22,0.609,0.33,1.029,0.33c0.465,0,0.889-0.074,1.271-0.223l0.164,0.728c-0.447,0.194-0.973,0.291-1.582,0.291
				c-0.73,0-1.305-0.215-1.721-0.645c-0.418-0.43-0.625-1.007-0.625-1.731c0-0.711,0.193-1.303,0.582-1.775
				c0.406-0.504,0.955-0.756,1.648-0.756c0.678,0,1.193,0.252,1.541,0.756C121.068,9.77,121.207,10.265,121.207,10.853z
				 M120.207,10.582c0.008-0.311-0.061-0.579-0.203-0.805c-0.182-0.291-0.459-0.437-0.834-0.437c-0.342,0-0.621,0.142-0.834,0.427
				c-0.174,0.227-0.277,0.498-0.311,0.815H120.207z'
						/>
					</g>
				</g>
			</g>
		</svg>
	);
};

export { AppStoreIcon };
