import { useState } from 'react';

function useLocalStorage<T>(key: string, initialValue: T) {
	const [storedValue, setStoredValue] = useState<T>(() => {
		try {
			const value = JSON.parse(localStorage.getItem(key));
			return value === null ? initialValue : value;
		} catch (e) {
			console.error('Local storage reading error:', e);
			return initialValue;
		}
	});

	const setValue = (value: T) => {
		const valueToStore = value instanceof Function ? value(storedValue) : value;
		setStoredValue(valueToStore);
		try {
			localStorage.setItem(key, JSON.stringify(valueToStore));
		} catch (e) {
			console.error('Local storage reading error:', e);
		}
	};

	const result: [T, (value: T) => void] = [storedValue, setValue];

	return result;
}

export { useLocalStorage };
