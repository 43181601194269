// @ts-nocheck
import styled, { css, StyledComponentClass, InterpolationValue } from 'styled-components';
import { isIE } from 'react-device-detect';

import * as c from '../../basic-theme/colors';

const getCSSValue = (v: number | string) => (typeof v === 'number' ? `${v}px` : v);
const resetCSS = () => css`
	width: auto;
	height: auto;
	min-width: 0;
	min-height: 0;
	max-width: none;
	max-height: none;
	margin-top: 0;
	margin-right: 0;
	margin-bottom: 0;
	margin-left: 0;
	background-color: transparent;
	border: none;
`;

const cssUtils = {
	getCSSValue,
	resetCSS,
};

declare namespace IShapeModuleStyled {
	interface IElements {
		div: StyledComponentClass<any, any, any>;
		span: StyledComponentClass<any, any, any>;
		h1: StyledComponentClass<any, any, any>;
		h2: StyledComponentClass<any, any, any>;
		h3: StyledComponentClass<any, any, any>;
		h4: StyledComponentClass<any, any, any>;
		h5: StyledComponentClass<any, any, any>;
		h6: StyledComponentClass<any, any, any>;
		p: StyledComponentClass<any, any, any>;
		img: StyledComponentClass<any, any, any>;
		a: StyledComponentClass<any, any, any>;
		button: StyledComponentClass<any, any, any>;
		label: StyledComponentClass<any, any, any>;
		input: StyledComponentClass<any, any, any>;
		form: StyledComponentClass<any, any, any>;
	}
	interface IModifiers {
		reset: Array<InterpolationValue>;
		uppercase: Array<InterpolationValue>;
		'uppercase:forRoot': Array<InterpolationValue>;
		capitalize: Array<InterpolationValue>;
		'capitalize:forRoot': Array<InterpolationValue>;
		lowercase: Array<InterpolationValue>;
		'lowercase:forRoot': Array<InterpolationValue>;
		colorAccent: Array<InterpolationValue>;
		'colorAccent:forRoot': Array<InterpolationValue>;
		colorBlackPrimary: Array<InterpolationValue>;
		'colorBlackPrimary:forRoot': Array<InterpolationValue>;
		colorBlackSecondary: Array<InterpolationValue>;
		'colorBlackSecondary:forRoot': Array<InterpolationValue>;
		colorBlackDisabled: Array<InterpolationValue>;
		'colorBlackDisabled:forRoot': Array<InterpolationValue>;
		colorWhite: Array<InterpolationValue>;
		'colorWhite:forRoot': Array<InterpolationValue>;
		clickableText: Array<InterpolationValue>;
		'clickableText:dashed': Array<InterpolationValue>;
		'userSelect:none': Array<InterpolationValue>;
		'clickableText:hover': Array<InterpolationValue>;
	}
	interface IShape {
		modifiers?: Array<keyof IModifiers>;
		css?: Array<InterpolationValue>;
		width?: number | string;
		height?: number | string;
		minWidth?: number | string;
		minHeight?: number | string;
		maxWidth?: number | string;
		maxHeight?: number | string;
		marginTop?: number | string;
		marginRight?: number | string;
		marginBottom?: number | string;
		marginLeft?: number | string;
		margin?: number | string;
		paddingTop?: number | string;
		paddingRight?: number | string;
		paddingBottom?: number | string;
		paddingLeft?: number | string;
		padding?: number | string;
		visibility?: boolean;
		backgroundColor?: string;
		fontSize?: number | string;
		fontWeight?: number | string;
		lineHeight?: number | string;
		textAlign?: string;
	}
}

const sharedCSS = css`
	${(p: IShapeModuleStyled.IShape) =>
		p.width &&
		`
		width: ${getCSSValue(p.width)};
	`}

	${(p: IShapeModuleStyled.IShape) =>
		p.height &&
		`
		height: ${getCSSValue(p.height)};
	`}

	${(p: IShapeModuleStyled.IShape) =>
		p.minWidth &&
		`
		min-width: ${getCSSValue(p.minWidth)};
	`}

	${(p: IShapeModuleStyled.IShape) =>
		p.minHeight &&
		`
		min-height: ${getCSSValue(p.minHeight)};
	`}

	${(p: IShapeModuleStyled.IShape) =>
		p.maxWidth &&
		`
		max-width: ${getCSSValue(p.maxWidth)};
	`}

	${(p: IShapeModuleStyled.IShape) =>
		p.maxHeight &&
		`
		max-height: ${getCSSValue(p.maxHeight)};
	`}

	${(p: IShapeModuleStyled.IShape) =>
		p.marginTop &&
		`
		margin-top: ${getCSSValue(p.marginTop)};
	`}

	${(p: IShapeModuleStyled.IShape) =>
		p.marginRight &&
		`
		margin-right: ${getCSSValue(p.marginRight)};
	`}

	${(p: IShapeModuleStyled.IShape) =>
		p.marginBottom &&
		`
		margin-bottom: ${getCSSValue(p.marginBottom)};
	`}

	${(p: IShapeModuleStyled.IShape) =>
		p.marginLeft &&
		`
		margin-left: ${getCSSValue(p.marginLeft)};
	`}

	${(p: IShapeModuleStyled.IShape) =>
		p.margin &&
		`
		margin: ${getCSSValue(p.margin)};
	`}

	${(p: IShapeModuleStyled.IShape) =>
		p.paddingTop &&
		`
		padding-top: ${getCSSValue(p.paddingTop)};
	`}

	${(p: IShapeModuleStyled.IShape) =>
		p.paddingRight &&
		`
		padding-right: ${getCSSValue(p.paddingRight)};
	`}

	${(p: IShapeModuleStyled.IShape) =>
		p.paddingBottom &&
		`
		padding-bottom: ${getCSSValue(p.paddingBottom)};
	`}

	${(p: IShapeModuleStyled.IShape) =>
		p.paddingLeft &&
		`
		padding-left: ${getCSSValue(p.paddingLeft)};
	`}

	${(p: IShapeModuleStyled.IShape) =>
		p.padding &&
		`
		padding: ${getCSSValue(p.padding)};
	`}

	${(p: IShapeModuleStyled.IShape) =>
		p.visibility === false &&
		`
		display: none;
	`}

	${(p: IShapeModuleStyled.IShape) =>
		p.backgroundColor &&
		`
		background-color: ${p.backgroundColor};
	`}

	${(p: IShapeModuleStyled.IShape) =>
		p.fontSize &&
		`
		font-size: ${getCSSValue(p.fontSize)};
	`}

	${(p: IShapeModuleStyled.IShape) =>
		p.fontWeight &&
		`
		font-weight: ${p.fontWeight};
	`}

	${(p: IShapeModuleStyled.IShape) =>
		p.lineHeight &&
		`
		line-height: ${p.lineHeight};
	`}

	${(p: IShapeModuleStyled.IShape) =>
		p.textAlign &&
		`
		text-align: ${p.textAlign};
	`}
`;

const mapModifiers = (
	modifiers: Record<string, Array<InterpolationValue>>,
	keys: Array<keyof IShapeModuleStyled.IModifiers>,
): Array<InterpolationValue> => {
	return keys
		? keys.reduce(
				(acc, key) =>
					modifiers[key]
						? css`
								${acc}${modifiers[key]}
						  `
						: css`
								${acc}
						  `,
				[],
		  )
		: [];
};

const transformTextForRoot = () => `
	& * {
		text-transform: initial;
		text-transform: none;
	}
`;

const colorForRoot = () => `
	& * {
		color: initial;
		${isIE ? `color: ${c.blackPrimary}` : ''};
	}
`;

const MODIFIERS = {
	reset: css`
		${resetCSS()}
	`,
	uppercase: css`
		text-transform: uppercase;
	`,
	'uppercase:forRoot': css`
		text-transform: uppercase;
		${transformTextForRoot()}
	`,
	capitalize: css`
		text-transform: capitalize;
	`,
	'capitalize:forRoot': css`
		text-transform: capitalize;
		${transformTextForRoot()}
	`,
	lowercase: css`
		text-transform: lowercase;
	`,
	'lowercase:forRoot': css`
		text-transform: lowercase;
		${transformTextForRoot()}
	`,
	colorAccent: css`
		color: ${c.accentPrimary};
	`,
	'colorAccent:forRoot': css`
		color: ${c.accentPrimary};
		${colorForRoot()}
	`,
	colorBlackPrimary: css`
		color: ${c.blackPrimary};
	`,
	'colorBlackPrimary:forRoot': css`
		color: ${c.blackPrimary};
		${colorForRoot()}
	`,
	colorBlackSecondary: css`
		color: ${c.blackSecondary};
	`,
	'colorBlackSecondary:forRoot': css`
		color: ${c.blackSecondary};
		${colorForRoot()}
	`,
	colorBlackDisabled: css`
		color: ${c.blackDisabled};
	`,
	'colorBlackDisabled:forRoot': css`
		color: ${c.blackDisabled};
		${colorForRoot()}
	`,
	colorWhite: css`
		color: ${c.whitePrimary};
	`,
	'colorWhite:forRoot': css`
		color: ${c.whitePrimary};
		${colorForRoot()}
	`,
	clickableText: css`
		color: ${c.accentPrimary};
		cursor: pointer;

		&:hover {
			text-decoration: underline;
		}
	`,
	'clickableText:hover': css`
		cursor: pointer;

		&:hover {
			color: ${c.accentPrimary};
		}
	`,
	'clickableText:dashed': css`
		color: ${c.accentPrimary};
		cursor: pointer;
		border-bottom: 1px dashed ${c.accentPrimary};
	`,
	'userSelect:none': css`
		user-select: none;
	`,
};

const getElement = (element: string | StyledComponentClass<any, any, any>) =>
	typeof element === 'string'
		? styled[element]`
				${sharedCSS}
				${(p: IShapeModuleStyled.IShape) => p.modifiers && mapModifiers(MODIFIERS, p.modifiers)}
			${(p: IShapeModuleStyled.IShape) => p.css && `${p.css}`}
		  `
		: styled(element)`
				${sharedCSS}
				${(p: IShapeModuleStyled.IShape) => p.modifiers && mapModifiers(MODIFIERS, p.modifiers)}
			${(p: IShapeModuleStyled.IShape) => p.css && `${p.css}`}
		  `;

export { IShapeModuleStyled, sharedCSS, cssUtils, getElement };
