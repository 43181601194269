import { IAppState } from '@store';

const selectContext = (state: IAppState) => {
	return state.platform.sessionContext.item;
};

function selectTicketUID(state: IAppState) {
	return state.platform.sessionContext.item?.SecurityTicket?.TicketUID || '';
}

const selectContextIsAvailable = (state: IAppState): boolean => {
	return !!state.platform.sessionContext.item;
};

const selectCurrentTenantName = (state: IAppState): string => {
	return state.platform.sessionContext.item.SessionTenant.Name;
};

const selectCurrentTenantID = (state: IAppState): number => {
	return state.platform.sessionContext.item?.SessionTenant?.ID;
};

const selectCurrentUserID = (state: IAppState): number => {
	return state.platform.sessionContext.item?.SessionUser?.ID;
};

const selectCurrentUserName = (state: IAppState): string => {
	return state.platform.sessionContext.item.SessionUser.Name;
};

const selectCurrentUserLogin = (state: IAppState): string => {
	return state.platform.sessionContext.item.SessionUser.Login;
};

const selectCurrentApplicationName = (state: IAppState): string => {
	return state.platform.sessionContext.item.SessionApplication.Name;
};

const selectCurrentApplicationID = (state: IAppState): number => {
	return state.platform.sessionContext.item.SessionApplication.ID;
};

const selectWebServicesRootPath = (state: IAppState): string => {
	return state.platform.sessionContext.item.WebServicesRootPath;
};

export {
	selectContext,
	selectTicketUID,
	selectContextIsAvailable,
	selectCurrentTenantName,
	selectCurrentTenantID,
	selectCurrentUserID,
	selectCurrentUserName,
	selectCurrentUserLogin,
	selectCurrentApplicationName,
	selectCurrentApplicationID,
	selectWebServicesRootPath,
};
