import React, { useRef } from 'react';

import { useImmediateResize } from '@core/hooks/use-resize';
import { CircularProgress } from '@ui/circular-progress';
import { Box, BoxProps } from '@ui/box';
import { UpdatingSpinner } from './styled';

export type SpinnerProps = {
	appearance?: 'circular' | 'updating';
	size?: number;
	thickness?: number;
	color?: 'accent' | 'light';
	children?: React.ReactNode;
};

const Spinner: React.FC<SpinnerProps> = props => {
	const { appearance, size, thickness, color, children } = props;

	if (appearance === 'updating') {
		return (
			<Box display='inline-flex' flexFlow='column nowrap' justifyContent='center' alignItems='center'>
				{children}
				<Box position='relative' top={8} minHeight={40}>
					<UpdatingSpinner />
				</Box>
			</Box>
		);
	}

	return (
		<Box display='inline-flex' alignItems='center' justifyContent='center'>
			{children}
			<CircularProgress size={size} thickness={thickness} color={color} />
		</Box>
	);
};

Spinner.defaultProps = {
	appearance: 'circular',
	size: 50,
	thickness: 4,
	color: 'accent',
};

type SpinnerAutoLayoutProps = {
	appearance: 'max-drawer' | 'max-workspace';
} & BoxProps;

const SpinnerAutoLayout: React.FC<SpinnerAutoLayoutProps> = props => {
	const { appearance, children, ...rest } = props;
	const rootRef = useRef<HTMLDivElement>(null);
	const createPatch = () => {
		const patch = (selector: string) => {
			const height =
				selector === 'workspace' ? window.innerHeight - 70 - 45 : rootRef.current.closest(selector)?.clientHeight;
			if (!height) return;
			rootRef.current.style.setProperty('height', `${height}px`);
		};
		const map = {
			'max-drawer': () => patch('#drawer-content'),
			'max-workspace': () => patch('workspace'),
		};

		return map[appearance] || (() => {});
	};

	useImmediateResize(() => {
		const patch = createPatch();

		patch();
	}, []);

	return (
		<Box display='flex' alignItems='center' justifyContent='center' fullWidth {...rest} onSetRef={rootRef}>
			{children}
		</Box>
	);
};

type SpinnerLayoutProps = {} & BoxProps;

const SpinnerLayout: React.FC<SpinnerLayoutProps> = props => {
	const { height, children, ...rest } = props;

	return (
		<Box display='flex' alignItems='center' justifyContent='center' height={height} fullWidth {...rest}>
			{children}
		</Box>
	);
};

export { Spinner, SpinnerAutoLayout, SpinnerLayout };
