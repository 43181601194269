import React, { useLayoutEffect } from 'react';

import { Box } from '@ui/box';
import { Alert } from '@ui/alert';
import { integrationHelpLinks } from '@shared/constants/cms-guids';
import { mainIntegrationsActionsPack } from '@integrations/actions';
import { selectExternalSystemAccountsByCMS, selectFilteredExternalSystemAccountsByCMS } from '@integrations/selectors';
import { DrawerZone } from '@funds-registers/models';
import { DrawerFooterControls, DrawerLink, DrawerTitle } from '@ui/drawer';
import { Link } from '@ui/link';
import { UserCheckIcon } from '@ui/icons/user-check';
import { UserAddIcon } from '@ui/icons/user-add';
import { ProviderIcon } from '@ui/provider-icon';
import { MessageCard } from '@ui/cards';
import { getDrawer } from '@utils';
import { Root, ContentLayout, MessageLayout, ControlsLayout, InfoLinkLayout } from './styled';
import { CmsAccountsTextFilter } from '../cms-accounts-text-filter';
import { DrawerSearchLink } from '@core/ui/drawer/drawer-search-link';

export type XCmsAccountsProps = {
	selectedCMS: CashManagementSystem;
	searchText: string;
	externalSystemAccounts: ReturnType<typeof selectExternalSystemAccountsByCMS>;
	filteredExternalSystemAccounts: ReturnType<typeof selectFilteredExternalSystemAccountsByCMS>;
	onSetZone: React.Dispatch<React.SetStateAction<DrawerZone>>;
	setSelectedExternalSystemAccountID: typeof mainIntegrationsActionsPack.setSelectedExternalSystemAccountID;
};

const XCmsAccounts: React.FC<XCmsAccountsProps> = props => {
	const {
		selectedCMS,
		searchText,
		externalSystemAccounts,
		filteredExternalSystemAccounts,
		onSetZone,
		setSelectedExternalSystemAccountID,
	} = props;

	useLayoutEffect(() => {
		getDrawer().setContent({
			title: (
				<DrawerTitle
					title={selectedCMS.Name}
					linkLabel='Назад'
					onNavigate={() => onSetZone(DrawerZone.LIST)}
					icon={<ProviderIcon providerName={selectedCMS.Name} size={30} fallback='bank-account' />}
				/>
			),
			footer: <DrawerFooterControls onClose={handleClose} />,
		});
	}, []);

	const handleClose = () => getDrawer().close();

	const handleAddExternalSystemAccount = () => onSetZone(DrawerZone.SIGNUP);

	const handleSelectExternalSystemAccount = (ID: number) => () => {
		setSelectedExternalSystemAccountID(ID);
		onSetZone(DrawerZone.IMPORT_CMS);
	};

	const renderAccounts = () => {
		if (filteredExternalSystemAccounts) {
			return filteredExternalSystemAccounts.map((account, index) => {
				const accountName = account.Name ? account.Name : `${index + 1}`;
				const taxCode = account.TaxCode ? account.TaxCode : '';
				return (
					<DrawerSearchLink
						key={index}
						title={accountName}
						preText='ИНН:'
						text={taxCode}
						searchText={searchText}
						textColor='secondary'
						icon={<UserCheckIcon color='accent' size={40} />}
						onClick={handleSelectExternalSystemAccount(account.ID)}
					/>
				);
			});
		}

		return null;
	};

	const renderInfoLink = () => {
		const infoPath = integrationHelpLinks[selectedCMS.SubsystemInstanceGUID];

		if (infoPath) {
			return (
				<InfoLinkLayout>
					<Link href={infoPath} rel='noopener' target='_blank'>
						Подробнее о процессе подключения
					</Link>
				</InfoLinkLayout>
			);
		}

		return null;
	};
	const alertTitle =
		externalSystemAccounts.length === 0
			? `В системе пока нет учётных записей для интеграции с «${selectedCMS.Name}». Вы можете добавить новую учётную запись.`
			: `В системе уже есть ${externalSystemAccounts.length === 1 ? 'одна учётная запись' : 'учётные записи'} для «${
					selectedCMS.Name
			  }». Вы можете создать новую или выбрать существующую.`;

	return (
		<Root>
			<ContentLayout>
				<MessageLayout>
					<Box marginBottom={20}>
						<Alert appearance='warning' fullWidth>
							{alertTitle}
						</Alert>
					</Box>
					<MessageCard withoutPadding>
						Отдельные учётные записи нужны для подключения счетов разных юридических лиц. Если вы{'\u00A0'}хотите
						подключить счета в{'\u00A0'}этом банке, принадлежашие другой вашей компании, выберите вариант «Добавить
						учётную запись» и{'\u00A0'}присвойте ей{'\u00A0'}произвольное имя, которое поможет вам различать разные
						учётные записи.
					</MessageCard>
					{renderInfoLink()}
				</MessageLayout>
				<Box paddingBottom={8} paddingLeft={40} paddingRight={40}>
					<CmsAccountsTextFilter />
				</Box>
				<ControlsLayout>
					{renderAccounts()}
					<DrawerLink
						title='Добавить учётную запись'
						icon={<UserAddIcon color='accent' size={40} />}
						onClick={handleAddExternalSystemAccount}
					/>
				</ControlsLayout>
			</ContentLayout>
		</Root>
	);
};

export { XCmsAccounts };
