import styled from 'styled-components';

const Root = styled.div`
	position: relative;
	width: 100%;
	max-width: none;
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	align-items: stretch;
`;

export { Root };
