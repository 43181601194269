import React from 'react';

import { deepClone } from '@utils';

type Config<P> = {
	selector?: (prop: any, props: P) => any;
	mutable?: boolean;
};

type PropsCheckOptions<P> = {
	name?: string; // for debug
	keys: Record<string, Config<P> | boolean>;
};

function withPropsCheck<P extends object>(options: PropsCheckOptions<P>) {
	const { name, keys } = options;

	return (WrappedComponent: React.ComponentType<P>): React.ComponentClass<P> => {
		return class extends React.Component<P> {
			static displayName = 'withPropsCheck[HOC]';
			map: Record<string, unknown> = {};

			shouldComponentUpdate(nextProps: P) {
				const shouldUpdate = Object.keys(keys).some(key => {
					const config: Config<P> =
						typeof keys[key] === 'object'
							? (keys[key] as Config<P>)
							: {
									mutable: false,
									selector: prop => prop,
							  };
					const value = this.map[key];
					const source = config.mutable ? deepClone(nextProps[key]) : nextProps[key];
					const nextValue = typeof config.selector === 'function' ? config.selector(source, nextProps) : source;

					return value !== nextValue;
				});

				this.saveValue(nextProps);

				return shouldUpdate;
			}

			saveValue = (props: P) => {
				Object.keys(keys).forEach(key => {
					const config: Config<P> =
						typeof keys[key] === 'object'
							? (keys[key] as Config<P>)
							: {
									mutable: false,
									selector: prop => prop,
							  };
					const source = config.mutable ? deepClone(props[key]) : props[key];

					this.map[key] = typeof config.selector === 'function' ? config.selector(source, props) : source;
				});
			};

			render() {
				return <WrappedComponent {...(this.props as any)} />;
			}
		};
	};
}

export default withPropsCheck;
