import * as moment from 'moment';
import { createSelector } from 'reselect';
import * as _ from 'underscore';

import { BusinessServiceType } from '@platform/model';
import { detectIsProfessionalEdition } from '@platform/shared/utils';
import { BASE_DATE_FORMAT } from '@shared/constants/time';
import { IAppState } from '@store';
import { getPropInSafe, isFreemiumTariff, sortDescByDate } from '@utils';

const selectBillingServiceStatus = (state: IAppState) => {
	return state.platform.billing.billingServiceStatus.item;
};

const selectBillingServiceStatusIsFetching = (state: IAppState) => {
	return state.platform.billing.billingServiceStatus.isFetching;
};

const selectBillingServiceStatusIsLoaded = (state: IAppState) => {
	return state.platform.billing.billingServiceStatus.isLoaded;
};

const selectBillingServiceStatusDidInvalidate = (state: IAppState) => {
	return state.platform.billing.billingServiceStatus.didInvalidate;
};

const selectTariffListByAscRate = createSelector(
	(state: IAppState) => state.platform.billing.tariffs.item,
	tariffs => {
		const tariffList = Object.keys(tariffs).map(key => tariffs[key]);

		tariffList.sort((a, b) => (a.Rates[a.Rates.length - 1].Rate > b.Rates[b.Rates.length - 1].Rate ? 1 : -1));

		const filteredTariffList = tariffList.filter(tariff => !isFreemiumTariff(tariff));

		return filteredTariffList;
	},
);

const selectTariffsIsFetching = (state: IAppState) => {
	return state.platform.billing.tariffs.isFetching;
};

const selectSelectedTariff = (state: IAppState) => {
	return state.platform.billing.selectedTariff.tariff;
};

const selectSelectedTariffRate = (state: IAppState) => {
	return state.platform.billing.selectedTariff.tariffRate;
};

const selectRequestedTariff = (state: IAppState) => {
	const tariff = state.platform.billing.tenantAccount.item.RequestedTariff;

	return tariff ? tariff : null;
};

const selectRequestedTariffRate = (state: IAppState) => {
	const tariffRate = state.platform.billing.tenantAccount.item.RequestedTariffRate;

	return tariffRate ? tariffRate : null;
};

function selectActiveTariff(state: IAppState) {
	return state.platform.billing.tenantAccount.item.ActiveTariff;
}

const selectTenantAccount = (state: IAppState) => {
	return state.platform.billing.tenantAccount.item;
};

const selectTenantAccountIsFetching = (state: IAppState) => {
	return state.platform.billing.tenantAccount.isFetching;
};

const selectTenantAccountIsLoaded = (state: IAppState) => {
	return state.platform.billing.tenantAccount.isLoaded;
};

const selectTenantAccountDidInvalidate = (state: IAppState) => {
	return state.platform.billing.tenantAccount.didInvalidate;
};

const selectTenantAccountExpireDate = (state: IAppState) => {
	return state.platform.billing.tenantAccount.item.ExpireDate;
};

const selectTenantBills = (state: IAppState) => {
	return state.platform.billing.tenantBills.item;
};

function selectAllTenantBills(state: IAppState) {
	return state.platform.billing.allTenantBills.item;
}

function selectAllTenantBillsIsFetching(state: IAppState): boolean {
	return state.platform.billing.allTenantBills.isFetching;
}

function selectAllTenantBillsIsLoaded(state: IAppState): boolean {
	return state.platform.billing.allTenantBills.isLoaded;
}

function selectAllTenantBillsDidInvalidate(state: IAppState): boolean {
	return state.platform.billing.allTenantBills.didInvalidate;
}

const selectPaidTenantBillList = createSelector(selectAllTenantBills, billMap => {
	const list = Object.keys(billMap)
		.map(key => billMap[key])
		.filter(el => el.Paid);
	const sortedList = sortDescByDate(list, [{ fn: x => x.PeriodDateStart }]);

	return sortedList;
});

const selectUnpaidTenantBillList = createSelector(selectAllTenantBills, billMap => {
	const list = Object.keys(billMap)
		.map(key => billMap[key])
		.filter(el => !el.Paid);

	return list;
});

const selectPaidTenantBillMap = createSelector(selectPaidTenantBillList, (billList: Array<TenantBill>) =>
	_.indexBy(billList, el => el.ID),
);

const selectUnpaidTenantBillMap = createSelector(selectUnpaidTenantBillList, (billList: Array<TenantBill>) =>
	_.indexBy(billList, el => el.ID),
);

function selectTenantBillsIsFetching(state: IAppState): boolean {
	return state.platform.billing.tenantBills.isFetching;
}

function selectTenantBillsDidInvalidate(state: IAppState): boolean {
	return state.platform.billing.tenantBills.didInvalidate;
}

function selectStatusAfterBilling(state: IAppState): StatusAction {
	return state.platform.billing.statusAfterBilling;
}

function selectCMSPaymentResponse(state: IAppState): CMSCreatePaymentDraftResponse {
	return state.platform.billing.CMSPaymentResponse.item;
}

function selectCMSPaymentResponseIsFetching(state: IAppState): boolean {
	return state.platform.billing.CMSPaymentResponse.isFetching;
}

function selectIsTrialAccount(state: IAppState): boolean {
	const isLoaded = state.platform.billing.tenantAccount.isLoaded;
	if (!isLoaded) return false;
	const tenantAccount = state.platform.billing.tenantAccount.item;
	const hasActiveTariff = Boolean(tenantAccount.ActiveTariff);
	const trialStartDate = tenantAccount.TrialPeriodStartDateTime;
	const hasUnusedTrialDays = moment(trialStartDate, BASE_DATE_FORMAT)
		.add(tenantAccount.TrialDays, 'day')
		.isAfter(moment(), 'day');
	const isTrial = !hasActiveTariff || hasUnusedTrialDays;

	return isTrial;
}

function selectTenantBusinessService(state: IAppState): BusinessService {
	return state.platform.billing.tenantAccount.item.BusinessService;
}

function selectActiveTariffRestrictions(state: IAppState): ObjectCountTariffRestrictionValue[] {
	return getPropInSafe(
		state,
		s => s.platform.billing.tenantAccount.item.ActiveTariff.Restrictions,
	) as Array<ObjectCountTariffRestrictionValue>;
}

const selectIsProfessionalEdition = createSelector(selectTenantAccount, tenantAccount =>
	detectIsProfessionalEdition(tenantAccount),
);

const selectBusinessServiceType = createSelector(selectTenantBusinessService, businessService => {
	const systemName = businessService?.SystemName?.toLowerCase();

	if (systemName === 'bfm') {
		return BusinessServiceType.BFM;
	} else if (systemName === 'invoice') {
		return BusinessServiceType.INVOICE;
	}

	return BusinessServiceType.UNKNOWN;
});

export {
	selectActiveTariff,
	selectActiveTariffRestrictions,
	selectAllTenantBills,
	selectAllTenantBillsDidInvalidate,
	selectAllTenantBillsIsFetching,
	selectAllTenantBillsIsLoaded,
	selectBillingServiceStatus,
	selectBillingServiceStatusDidInvalidate,
	selectBillingServiceStatusIsFetching,
	selectBillingServiceStatusIsLoaded,
	selectBusinessServiceType,
	selectCMSPaymentResponse,
	selectCMSPaymentResponseIsFetching,
	selectIsProfessionalEdition,
	selectIsTrialAccount,
	selectPaidTenantBillList,
	selectPaidTenantBillMap,
	selectRequestedTariff,
	selectRequestedTariffRate,
	selectSelectedTariff,
	selectSelectedTariffRate,
	selectStatusAfterBilling,
	selectTariffListByAscRate,
	selectTariffsIsFetching,
	selectTenantAccount,
	selectTenantAccountDidInvalidate,
	selectTenantAccountExpireDate,
	selectTenantAccountIsFetching,
	selectTenantAccountIsLoaded,
	selectTenantBills,
	selectTenantBillsDidInvalidate,
	selectTenantBillsIsFetching,
	selectTenantBusinessService,
	selectUnpaidTenantBillList,
	selectUnpaidTenantBillMap,
};
