import {
	checkAsyncItemLoadAction,
	createDefaultAsyncItem
} from '../../../core/libs/reducer-creator';
import {
	FETCH_SESSION_CONTEXT,
	CHECK_SESSION_CONTEXT
} from '../actions/session-context.actions';


const initialState = {
	...createDefaultAsyncItem(undefined)
};

function sessionContext(state: StoreAsyncItem<ClientSessionContext> = initialState, action) {
	switch (action.type) {
	case FETCH_SESSION_CONTEXT:
	case CHECK_SESSION_CONTEXT:
		const fetchSessionContextAction = action as AsyncAction<ClientSessionContext>;

		return {
			...state,
			...checkAsyncItemLoadAction(fetchSessionContextAction, state)
		}

	default:
		return state
	}
}

export default sessionContext