import React, { memo, useState } from 'react';

import { useMapDispatch } from '@flux';
import { counterpartyApi } from '@core/api/counterparty';
import { mainTenantLegalEntitiesActionsPack } from '@tenant-legal-entities/actions';
import {
	TransformPopover as XTransformPopover,
	TransformPopoverProps as XTransformPopoverProps,
} from './transform-tenant-legal-entity-popover.view';

export type TransformPopoverProps = {
	legalEntityID: number;
	onComplete: () => void;
} & Pick<XTransformPopoverProps, 'legalEntityName'>;

const TransformPopover: React.FC<TransformPopoverProps> = memo(props => {
	const { legalEntityID, legalEntityName, onComplete, ...rest } = props;
	const [isLoaded, setIsLoaded] = useState(false);
	const [item, setItem] = useState<TenantEntityCanBeRemovedResponseItem>(null);
	const [transformTenantLegalEntityToCounterparty] = useMapDispatch([
		mainTenantLegalEntitiesActionsPack.transformTenantLegalEntityToCounterparty,
	]);

	const handleTransformTenantLegalEntity = async () => {
		const [item] = await counterpartyApi.counterparty.checkTenantEntityCanBeRemoved([legalEntityID]);

		if (item.CanBeRemoved) {
			transformTenantLegalEntityToCounterparty(legalEntityID);
			setIsLoaded(true);
			onComplete();
		} else {
			setItem(item);
			setIsLoaded(true);
		}
	};

	const handleRequestClose = () => setIsLoaded(false);

	return (
		<XTransformPopover
			{...rest}
			isLoaded={isLoaded}
			item={item}
			legalEntityName={legalEntityName}
			onRequestTransform={handleTransformTenantLegalEntity}
			onRequestClose={handleRequestClose}
		/>
	);
});

export { TransformPopover as TransformTenantLegalEntityPopover };
