import { createObjectMap } from '@core/utils/object';
import { AuthMethodCode, ProviderRole } from '@integrations/models';

const providerRoleNamesMap = {
	[ProviderRole.DATA_PROVIDER_ROLE]: 'Провайдер данных',
	[ProviderRole.IDENTITY_PROVIDER_ROLE]: 'Удостоверяющий центр при аутентификации по SSO',
};

function getAuthMethodCode(ssiID: number, resMap: Record<string, RemoteExternalService>) {
	const roles = resMap[ssiID]?.Roles || [];
	const rolesMap = createObjectMap(roles, x => x.CLASSIFIER);
	const role = rolesMap[ProviderRole.DATA_PROVIDER_ROLE] as ExternalServiceRole & { AuthorizationMethodCode: string };

	return (role?.AuthorizationMethodCode || '') as AuthMethodCode;
}

function getProviderRoleFlags(res: RemoteExternalService) {
	const hasDataProviderRole = res?.Roles && res.Roles.some(x => x.CLASSIFIER === ProviderRole.DATA_PROVIDER_ROLE);
	const hasIdentityProviderRole =
		res?.Roles && res.Roles.some(x => x.CLASSIFIER === ProviderRole.IDENTITY_PROVIDER_ROLE);

	return {
		hasDataProviderRole,
		hasIdentityProviderRole,
	};
}

export { getAuthMethodCode, getProviderRoleFlags, providerRoleNamesMap };
