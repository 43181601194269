import { IAppState } from '@store';
import { createSelector, createAsyncSelector, createRightCheck } from '@flux';
import { accountingApi } from '@core/api/accounting';
import { createObjectMap } from '@utils/object';
import { compose, groupBy } from '@utils/helpers';
import { sortNestedTreeByAlphabet, transformNestedTreeToFlattenTree } from '@utils/tree';
import { CashflowTypeCode, cashflowTypeCodesByIDsMap } from '@cashflow-items/models';

const selectCashflowItemsRight = createRightCheck({
	view: 'xCashflowItems_ViewCashflowItems',
	change: 'xCashflowItems_ChangeCashflowItems',
});

const selectAsyncCashflowItems = createAsyncSelector<Array<CashflowItem>, IAppState>({
	get: s => s.cashflowItems.main.cashflowItems,
	selector: createSelector(
		s => s.cashflowItems.main.cashflowItems.item,
		item => item,
	),
});

const selectAsyncAccountsChartItemRoles = createAsyncSelector<Array<AccountsChartItemRole>, IAppState>({
	get: s => s.cashflowItems.main.roles,
	selector: createSelector(
		s => s.cashflowItems.main.roles.item,
		item => item,
	),
});

const selectIncomeCashflowItems = createSelector(selectAsyncCashflowItems.selectItem, cashflowItems =>
	cashflowItems.filter(x => x.Incoming && x.ID > 0),
);

const selectExpenseCashflowItems = createSelector(selectAsyncCashflowItems.selectItem, cashflowItems =>
	cashflowItems.filter(x => !x.Incoming && x.ID > 0),
);

const selectCashflowItemsMap = createSelector(selectAsyncCashflowItems.selectItem, cashflowItems => {
	const map = createObjectMap(cashflowItems, x => x.ID);

	map[-1] = { ...new accountingApi.package.CashflowItem(), Name: 'Статья не указана' };

	return map;
});

const selectAccountsChartItemRoles = createSelector(selectAsyncAccountsChartItemRoles.selectItem, roles =>
	roles.filter(x => x.ID > 0),
);

const selectAccountsChartItemRolesMap = createSelector(selectAccountsChartItemRoles, roles =>
	createObjectMap(roles, x => x.ID),
);

const selectIncomeGrouppedCashflowItems = createSelector(selectAsyncCashflowItems.selectItem, cashflowItems => {
	const grouppedCashflowItems = compose<Record<CashflowTypeCode, Array<CashflowItem>>, Array<CashflowItem>>(
		(items: Array<CashflowItem>) => groupBy(items, x => cashflowTypeCodesByIDsMap[x.CashflowTypeID]),
		(items: Array<CashflowItem>) => items.filter(x => x.Incoming && x.ID > 0),
	)(cashflowItems);

	grouppedCashflowItems[CashflowTypeCode.OPERATIONAL] = grouppedCashflowItems[CashflowTypeCode.OPERATIONAL] || [];
	grouppedCashflowItems[CashflowTypeCode.FINANCIAL] = grouppedCashflowItems[CashflowTypeCode.FINANCIAL] || [];
	grouppedCashflowItems[CashflowTypeCode.INVESTMENT] = grouppedCashflowItems[CashflowTypeCode.INVESTMENT] || [];

	return grouppedCashflowItems;
});

const selectExpenseGrouppedCashflowItems = createSelector(selectAsyncCashflowItems.selectItem, cashflowItems => {
	const grouppedCashflowItems = compose<Record<CashflowTypeCode, Array<CashflowItem>>, Array<CashflowItem>>(
		(items: Array<CashflowItem>) => groupBy(items, x => cashflowTypeCodesByIDsMap[x.CashflowTypeID]),
		(items: Array<CashflowItem>) => items.filter(x => !x.Incoming && x.ID > 0),
	)(cashflowItems);

	grouppedCashflowItems[CashflowTypeCode.OPERATIONAL] = grouppedCashflowItems[CashflowTypeCode.OPERATIONAL] || [];
	grouppedCashflowItems[CashflowTypeCode.FINANCIAL] = grouppedCashflowItems[CashflowTypeCode.FINANCIAL] || [];
	grouppedCashflowItems[CashflowTypeCode.INVESTMENT] = grouppedCashflowItems[CashflowTypeCode.INVESTMENT] || [];

	return grouppedCashflowItems;
});

function selectSearchTextFilter(state: IAppState) {
	return state.cashflowItems.main.searchText;
}

function selectIsIncomeItemsExpanded(state: IAppState) {
	return state.cashflowItems.main.isIncomeItemsExpanded;
}

function selectIsExpenseItemsExpanded(state: IAppState) {
	return state.cashflowItems.main.isExpenseItemsExpanded;
}

const createCashflowItemsSortedByAlphabetSelector = (isIncome: boolean) =>
	createSelector(selectAsyncCashflowItems.selectItem, (ciList: Array<CashflowItem>) => {
		const roots = ciList.filter(x => x.Incoming === isIncome && x.ParentID === -1 && x.ID !== -1);

		sortNestedTreeByAlphabet<CashflowItem>({
			items: roots,
			getChildItems: x => x.ChildItems as Array<CashflowItem>,
			getName: x => x.Name,
		});

		return transformNestedTreeToFlattenTree({
			items: roots,
			getChildItems: x => x.ChildItems as Array<CashflowItem>,
		});
	});

const selectIncomeCashflowItemsSortedByAlphabet = createCashflowItemsSortedByAlphabetSelector(true);
const selectExpenseCashflowItemsSortedByAlphabet = createCashflowItemsSortedByAlphabetSelector(false);

export const mainCashflowItemsSelectorsPack = {
	selectCashflowItemsRight,
	selectAsyncCashflowItems,
	selectAsyncAccountsChartItemRoles,
	selectIncomeCashflowItems,
	selectExpenseCashflowItems,
	selectCashflowItemsMap,
	selectAccountsChartItemRoles,
	selectAccountsChartItemRolesMap,
	selectIncomeGrouppedCashflowItems,
	selectExpenseGrouppedCashflowItems,
	selectSearchTextFilter,
	selectIsIncomeItemsExpanded,
	selectIsExpenseItemsExpanded,
	selectIncomeCashflowItemsSortedByAlphabet,
	selectExpenseCashflowItemsSortedByAlphabet,
};

export {
	selectCashflowItemsRight,
	selectAsyncCashflowItems,
	selectCashflowItemsMap,
	selectAsyncAccountsChartItemRoles,
};
