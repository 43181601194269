import React from 'react';

import { IconBase, IconBaseProps } from './base';

export type ErrorIconProps = IconBaseProps;

const ErrorIcon: React.FC<ErrorIconProps> = props => {
	return (
		<IconBase {...props}>
			<svg
				stroke='currentColor'
				fill='currentColor'
				strokeWidth='0'
				viewBox='0 0 24 24'
				xmlns='http://www.w3.org/2000/svg'>
				<path d='M11 7h2v7h-2zm0 8h2v2h-2z'></path>
				<path d='m21.707 7.293-5-5A.996.996 0 0 0 16 2H8a.996.996 0 0 0-.707.293l-5 5A.996.996 0 0 0 2 8v8c0 .266.105.52.293.707l5 5A.996.996 0 0 0 8 22h8c.266 0 .52-.105.707-.293l5-5A.996.996 0 0 0 22 16V8a.996.996 0 0 0-.293-.707zM20 15.586 15.586 20H8.414L4 15.586V8.414L8.414 4h7.172L20 8.414v7.172z'></path>
			</svg>
		</IconBase>
	);
};

export { ErrorIcon };
