import { correctFileName } from '@utils/url';

const $$useScrollToken = Symbol('useScroll');

function useScroll(dom: HTMLElement = null, handler: (e: Event) => void) {
	const scrollElement = dom || window;
	const scope: Map<Function, boolean> =
		typeof scrollElement[$$useScrollToken] === 'undefined'
			? (scrollElement[$$useScrollToken] = new Map())
			: scrollElement[$$useScrollToken];

	if (!scope.get(handler)) {
		scope.set(handler, true);
		scrollElement.addEventListener('scroll', handler);
	}

	return () => {
		scope.delete(handler);
		return scrollElement.removeEventListener('scroll', handler);
	};
}

function useRect(dom: Element) {
	if (!dom)
		return {
			width: 0,
			height: 0,
			x: 0,
			y: 0,
		};

	const rect = dom.getBoundingClientRect();

	return {
		width: rect.width,
		height: rect.height,
		x: rect.left,
		y: rect.top,
	};
}

type UseComputedStyleRule = {
	name: string;
	transform?: (p: any) => any;
};

function useComputedStyle(dom: HTMLElement, props: Array<UseComputedStyleRule>) {
	const styles = window.getComputedStyle(dom, null);

	return props.map(x =>
		typeof x.transform === 'function' ? x.transform(styles.getPropertyValue(x.name)) : styles.getPropertyValue(x.name),
	);
}

function useDelta(container: HTMLElement, scrollElement: HTMLElement) {
	const { x: scrollX, y: scrollY } = useRect(scrollElement);
	const { x: containerX, y: containerY } = useRect(container);
	const deltaY = containerY - scrollY;
	const deltaX = containerX - scrollX;

	return {
		deltaY,
		deltaX,
	};
}

function useResize(handler: (e: Event) => void) {
	window.addEventListener('resize', handler);

	return () => window.removeEventListener('resize', handler);
}

function useBlockingScroll(dom: HTMLElement = document.body, axis: 'x' | 'y' | 'all' = 'all') {
	const axisMap = {
		x: 'overflow-x',
		y: 'overflow-y',
		all: 'overflow',
	};
	dom.style.setProperty(axisMap[axis], 'hidden');

	return () => dom.style.removeProperty('overflow');
}

const blockingScroll = useBlockingScroll;

function useScrollbarWidth(): [number] {
	return [window.innerWidth - document.documentElement.clientWidth];
}

function useLackOfTwitching(): [() => void, () => void] {
	let originalValue = 0;
	const { body } = document;
	const [scrollbarWidth] = useScrollbarWidth();
	const property = 'padding-right';
	const elements: Array<HTMLElement> = [
		document.querySelector('#app-header') as HTMLElement,
		document.querySelector('#slide-panel') as HTMLElement,
	].filter(Boolean);

	return [
		() => {
			const styles = window.getComputedStyle(body, null);

			originalValue = Number(styles.getPropertyValue(property));
			if (scrollbarWidth === 0) return;
			body.style.setProperty(property, `${scrollbarWidth}px`);

			for (const element of elements) {
				element.style.setProperty(property, `${scrollbarWidth}px`);
			}
		},
		() => {
			originalValue ? body.style.setProperty(property, `${originalValue}px`) : body.style.removeProperty(property);

			for (const element of elements) {
				element.style.removeProperty(property);
			}
		},
	];
}

const lackOfTwitching = useLackOfTwitching;

function detectIsElementOutViewport(dom: HTMLElement) {
	if (!dom) return false;
	const rect = dom.getBoundingClientRect();

	return rect.bottom < 0 || rect.right < 0 || rect.left > window.innerWidth || rect.top > window.innerHeight;
}

function downloadFile(url: string, fileName: string) {
	const link = document.createElement('a');

	link.setAttribute('href', url);
	link.setAttribute('download', correctFileName(fileName));
	link.click();
}

function runCallbackWhenElementInjected(selector: string, cb: () => void) {
	let disconnected = false;
	if (typeof MutationObserver === 'undefined') return;
	const observer = new MutationObserver(mutationsList => {
		const elements = Array.from(mutationsList).map(x => x.target as Element);

		for (const element of elements) {
			try {
				if (element.querySelector(selector)) {
					observer.disconnect();
					disconnected = true;
					cb();
					break;
				}
			} catch (error) {
				//
			}
		}
	});

	observer.observe(document, {
		childList: true,
		subtree: true,
	});

	return () => !disconnected && observer.disconnect();
}

function createEvent(eventName: string) {
	// for IE 11
	let event = null;

	if (typeof Event === 'function') {
		event = new Event(eventName);
	} else {
		event = document.createEvent('Event');
		event.initEvent(eventName, true, true);
	}

	return event;
}

const dispatchEvent = (event: Event) => window.dispatchEvent(event);

const dispatchResizeEvent = () => dispatchEvent(createEvent('resize'));

const dispatchScrollEvent = (element: HTMLElement | typeof window = window) =>
	element.dispatchEvent(createEvent('scroll'));

function useElementSizeMM(element: HTMLElement) {
	const ONE_MM = 0.264583;
	const width = parseInt(window.getComputedStyle(element, null).width, 10);
	const height = parseInt(window.getComputedStyle(element, null).height, 10);
	const widthConverted = Math.floor(width * ONE_MM);
	const heightConverted = Math.floor(height * ONE_MM);

	return {
		width: widthConverted,
		height: heightConverted,
	};
}

const getElementSizeMM = useElementSizeMM;

function getScrollContainer(element: HTMLElement, includeHidden = false): HTMLElement | Window {
	let style = getComputedStyle(element);
	const excludeStaticParent = style.position === 'absolute';
	const overflowRegex = includeHidden ? /(auto|scroll|hidden)/ : /(auto|scroll)/;

	if (style.position === 'fixed') return window;
	for (let parent = element; (parent = parent.parentElement); ) {
		style = getComputedStyle(parent);
		if (excludeStaticParent && style.position === 'static') {
			continue;
		}
		if (overflowRegex.test(style.overflow + style.overflowY + style.overflowX)) {
			return parent;
		}
	}

	return window;
}

export type ObjectSize = {
	width: number;
	height: number;
};

const getImageSize = (source: string, setStyle: (node: HTMLImageElement) => void) => {
	return new Promise<ObjectSize>(resolve => {
		if (!source) return resolve(null);

		const image = new Image();

		image.src = source;
		image.style.setProperty('position', 'absolute');
		image.style.setProperty('visibility', 'hidden');
		image.style.setProperty('opacity', '0');

		setStyle(image);

		image.addEventListener('load', () => {
			document.body.appendChild(image);

			const width = image.clientWidth;
			const height = image.clientHeight;

			document.body.removeChild(image);

			resolve({
				width,
				height,
			});
		});
	});
};

export {
	useScroll,
	useRect,
	useComputedStyle,
	useDelta,
	useResize,
	useBlockingScroll,
	blockingScroll,
	useLackOfTwitching,
	lackOfTwitching,
	detectIsElementOutViewport,
	downloadFile,
	runCallbackWhenElementInjected,
	dispatchResizeEvent,
	dispatchScrollEvent,
	useElementSizeMM,
	getElementSizeMM,
	getScrollContainer,
	getImageSize,
};
