import React from 'react';

import { IconBase, IconBaseProps } from './base';

export type UserCheckIconProps = IconBaseProps;

const UserCheckIcon: React.FC<UserCheckIconProps> = props => {
	return (
		<IconBase {...props}>
			<svg
				stroke='currentColor'
				fill='none'
				strokeWidth='2'
				viewBox='0 0 24 24'
				strokeLinecap='round'
				strokeLinejoin='round'
				xmlns='http://www.w3.org/2000/svg'>
				<path d='M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2'></path>
				<circle cx='8.5' cy='7' r='4'></circle>
				<polyline points='17 11 19 13 23 9'></polyline>
			</svg>
		</IconBase>
	);
};

export { UserCheckIcon };
