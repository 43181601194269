function detectIsEmail(email: string): boolean {
	return /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+[a-z]{2,8}$/.test(email);
}

function detectIsPhone(phone: string): boolean {
	return /^((8|\+7)[- ]?)?(\(?\d{3,5}\)?[- ]?)?[\d\- ]{6,10}$/.test(phone) && phone.indexOf('_') === -1;
}

function correctPhoneBeforeMask(sourceValue = '') {
	const value = sourceValue
		.trim()
		.replace(/\D/g, '')
		.replace(/^(7|8)/g, '');

	return value;
}

function correctPhoneAfterMask(sourceValue = '') {
	const value = `+7${correctPhoneBeforeMask(sourceValue)}`;

	return value;
}

function extractEmails(value = ''): Array<string> {
	const emails = String(value)
		.split(',')
		.map(x => x.trim())
		.filter(x => detectIsEmail(x));

	return emails;
}

export { detectIsEmail, detectIsPhone, correctPhoneBeforeMask, correctPhoneAfterMask, extractEmails };
