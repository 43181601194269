import {
	StoreAsyncItem,
	checkAsyncAction,
	createAsyncInitialState,
	invalidateStateFromAction,
	createReducer,
} from '@flux';
import { types, FetchDynamicDataReturnType } from '@payments/actions/types';
import { PaymentsDirection } from '@payments/models';
import { createDefaultPeriod } from '@utils/date';

export type MainPaymentsState = {
	cashflowOperations: StoreAsyncItem<Array<CashflowOperationBrief>>;
	balance: StoreAsyncItem<Array<CashBalanceRecord>>;
	textFilter: string;
	fundsRegisterID: number;
	directionFilter: PaymentsDirection;
	dateRange: DateRange;
	fundsRegisterStatistics: StoreAsyncItem<Array<FundsRegisterStatistics>>;
};

const initialState: MainPaymentsState = {
	cashflowOperations: createAsyncInitialState([]),
	balance: createAsyncInitialState([]),
	textFilter: '',
	fundsRegisterID: null,
	directionFilter: PaymentsDirection.ALL,
	dateRange: createDefaultPeriod('month'),
	fundsRegisterStatistics: createAsyncInitialState([]),
};

const mainPaymentsReducer = createReducer<MainPaymentsState>(initialState, {
	[types.INVALIDATE_PAYMENTS]: (action: StaticAction, state) => {
		return {
			cashflowOperations: invalidateStateFromAction(action, state.cashflowOperations),
			fundsRegisterStatistics: invalidateStateFromAction(action, state.fundsRegisterStatistics),
		};
	},
	[types.FETCH_DYNAMIC_DATA]: (action: AsyncAction<FetchDynamicDataReturnType>, state) => {
		return {
			cashflowOperations: checkAsyncAction(action, state.cashflowOperations, x => x.cashflowOperations),
			balance: checkAsyncAction(action, state.cashflowOperations, x => x.balance),
		};
	},
	[types.ADD_PAYMENT]: (action: StaticAction, state) => {
		return {
			cashflowOperations: invalidateStateFromAction(action, state.cashflowOperations),
			fundsRegisterStatistics: invalidateStateFromAction(action, state.fundsRegisterStatistics),
		};
	},
	[types.UPDATE_PAYMENT]: (action: StaticAction, state) => {
		return {
			cashflowOperations: invalidateStateFromAction(action, state.cashflowOperations),
			fundsRegisterStatistics: invalidateStateFromAction(action, state.fundsRegisterStatistics),
		};
	},
	[types.REMOVE_PAYMENT]: (action: StaticAction, state) => {
		return {
			cashflowOperations: invalidateStateFromAction(action, state.cashflowOperations),
			fundsRegisterStatistics: invalidateStateFromAction(action, state.fundsRegisterStatistics),
		};
	},
	[types.SET_TEXT_FILTER]: (action: StaticAction<string>) => {
		return {
			textFilter: action.value,
		};
	},
	[types.SET_FUNDS_REGISTERS_ID]: (action: StaticAction<number>, state) => {
		return {
			fundsRegisterID: action.value,
			cashflowOperations: invalidateStateFromAction(action, state.cashflowOperations),
			fundsRegisterStatistics: invalidateStateFromAction(action, state.fundsRegisterStatistics),
		};
	},
	[types.SET_DIRECTION_FILTER]: (action: StaticAction<PaymentsDirection>) => {
		return {
			directionFilter: action.value,
		};
	},
	[types.SET_DATE_RANGE]: (action: StaticAction<DateRange>, state) => {
		return {
			dateRange: action.value,
			cashflowOperations: invalidateStateFromAction(action, state.cashflowOperations),
			fundsRegisterStatistics: invalidateStateFromAction(action, state.fundsRegisterStatistics),
		};
	},
	[types.MARK_AS_TRANSFER]: (action: StaticAction, state) => {
		return {
			cashflowOperations: invalidateStateFromAction(action, state.cashflowOperations),
			fundsRegisterStatistics: invalidateStateFromAction(action, state.fundsRegisterStatistics),
		};
	},
	[types.UNMARK_TRANSFER]: (action: StaticAction, state) => {
		return {
			cashflowOperations: invalidateStateFromAction(action, state.cashflowOperations),
			fundsRegisterStatistics: invalidateStateFromAction(action, state.fundsRegisterStatistics),
		};
	},
	[types.MERGE_PAYMENTS_TO_TRANSFER]: (action: StaticAction, state) => {
		return {
			cashflowOperations: invalidateStateFromAction(action, state.cashflowOperations),
			fundsRegisterStatistics: invalidateStateFromAction(action, state.fundsRegisterStatistics),
		};
	},
	[types.FETCH_FUNDS_REGISTER_STATISTICS]: (action: AsyncAction<Array<FundsRegisterStatistics>>, state) => {
		return {
			fundsRegisterStatistics: checkAsyncAction(action, state.fundsRegisterStatistics),
		};
	},
});

export default mainPaymentsReducer;
