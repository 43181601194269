import React from 'react';

import { ArrowLeftIcon } from '@ui/icons/arrow-left';
import { Typography } from '@ui/typography';
import { Root, LinkLayout, ContentLayout, Title, Link, IconLayout } from './styled';

interface IProps {
	title: string;
	linkLabel?: string;
	icon?: React.ReactElement<any>;
	onNavigate?: () => void;
}

class DrawerTitle extends React.Component<IProps> {
	renderLink = () => {
		const { linkLabel, onNavigate } = this.props;

		return (
			<Link onClick={onNavigate}>
				<ArrowLeftIcon color='current' size={16} />
				<Typography.Nbsp />
				{linkLabel}
			</Link>
		);
	};

	render() {
		const { title, linkLabel, icon } = this.props;

		return (
			<Root>
				{linkLabel && <LinkLayout>{this.renderLink()}</LinkLayout>}
				<ContentLayout>
					{icon && <IconLayout>{icon}</IconLayout>}
					<Title>{title}</Title>
				</ContentLayout>
			</Root>
		);
	}
}

export default DrawerTitle;
