import React from 'react';
import { Box } from '@ui/box';
import { PurpleBar } from '@ui/purple-bar';
import { RaisedButton } from '@ui/raised-button';

export type AppPurpleBarProps = {
	isOpen: boolean;
	hasDemoData: boolean;
	onRequestRemoveDemoData: () => void;
};

const AppPurpleBar: React.FC<AppPurpleBarProps> = props => {
	const { isOpen, hasDemoData, onRequestRemoveDemoData } = props;

	if (!isOpen) return null;

	const renderDemoData = () => {
		return (
			<Box width='100%' display='flex' alignItems='center'>
				<Box marginRight={20}>Для наглядности мы заполнили ваш аккаунт демо-данными</Box>
				<RaisedButton appearance='outlined' color='primary' size='small' onClick={onRequestRemoveDemoData}>
					Удалить демо-данные
				</RaisedButton>
			</Box>
		);
	};

	return (
		<Box width='100%'>
			<PurpleBar>{hasDemoData && renderDemoData()}</PurpleBar>
		</Box>
	);
};

export default AppPurpleBar;
