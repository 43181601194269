// @ts-nocheck
import styled from 'styled-components';

import { getMeasureProp } from '@stylesheet';
import {
	ILayoutBaseModule,
	LayoutBase,
	LayoutItemBase
} from '../styled';


declare namespace IStackLayoutModuleStyled {
	interface IRoot extends ILayoutBaseModule.ILayoutBase {
		position?: 'relative' | 'absolute' | 'fixed' | 'static';
		top?: string | number;
		righ?: string | number;
		bottom?: string | number;
		left?: string | number;
		inline?: boolean;
		orientation?: 'horizontal' | 'vertical';
		horizontalAligment?: 'left' | 'center' | 'right' | 'fill';
		verticalAligment?: 'top' | 'center' | 'bottom' | 'stretch';
	}
	interface IItem extends ILayoutBaseModule.ILayoutItemBase {}
}

const Root = styled(LayoutBase)`
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;

	${(p: IStackLayoutModuleStyled.IRoot) => p.position && `
		position: ${p.position || 'static'};
	`}

	${(p: IStackLayoutModuleStyled.IRoot) => (p.top || p.right || p.bottom || p.left) && `
		top: ${getMeasureProp(p.top, 'auto')};
		right: ${getMeasureProp(p.right, 'auto')};
		bottom: ${getMeasureProp(p.bottom, 'auto')};
		left: ${getMeasureProp(p.left, 'auto')};
	`}

	${(p: IStackLayoutModuleStyled.IRoot) => p.inline && `
		display: inline-flex;
	`}

	${(p: IStackLayoutModuleStyled.IRoot) => p.orientation === 'horizontal' && `
		flex-direction: row;
	`}

	${(p: IStackLayoutModuleStyled.IRoot) => p.orientation === 'vertical' && `
		flex-direction: column;
	`}

	${(p: IStackLayoutModuleStyled.IRoot) => p.horizontalAligment === 'left' && `
		justify-content: flex-start;
	`}

	${(p: IStackLayoutModuleStyled.IRoot) => p.horizontalAligment === 'center' && `
		justify-content: center;
	`}

	${(p: IStackLayoutModuleStyled.IRoot) => p.horizontalAligment === 'right' && `
		justify-content: flex-end;
	`}

	${(p: IStackLayoutModuleStyled.IRoot) => p.horizontalAligment === 'fill' && `
		justify-content: space-between;
	`}

	${(p: IStackLayoutModuleStyled.IRoot) => p.verticalAligment === 'top' && `
		align-items: flex-start;
	`}

	${(p: IStackLayoutModuleStyled.IRoot) => p.verticalAligment === 'center' && `
		align-items: center;
	`}

	${(p: IStackLayoutModuleStyled.IRoot) => p.verticalAligment === 'bottom' && `
		align-items: flex-end;
	`}

	${(p: IStackLayoutModuleStyled.IRoot) => p.verticalAligment === 'stretch' && `
		align-items: stretch;
	`}

	${(p: IStackLayoutModuleStyled.IRoot) => p.css && `
		${p.css}
	`}
`;

const Item = styled(LayoutItemBase)`
	display: block;

	${(p: IStackLayoutModuleStyled.IRoot) => p.css && `
		${p.css}
	`}
`;


export {
	IStackLayoutModuleStyled,
	Root,
	Item
}
