import { Store } from 'redux';

import { IAppState } from '@store';
import { isReceiveAction } from '@flux';
import { invalidateFundsRegisterStatistics } from '@funds-registers/actions/invalidators';
import { types as integrationsActionTypes } from '@integrations/actions/types';
import { invalidatePayments } from '@payments/actions/invalidators';
import { invalidateDashboardData } from '@dashboard/actions/invalidators';

function askImportInvalidator(store: Store<IAppState>, action: StoreAction) {
	const { dispatch } = store;
	const invalidateMap = {
		[integrationsActionTypes.SET_LAST_ACCOUNT_STATEMENT_IMPORT]: true,
		[integrationsActionTypes.SET_LAST_IMPORTED_FILE_DATA]: true,
	};

	if (invalidateMap[action.type] && isReceiveAction(action)) {
		dispatch(invalidateFundsRegisterStatistics());
		dispatch(invalidatePayments());
		dispatch(invalidateDashboardData());
	}
}

function createInvalidatorEffect() {
	return (store: Store<IAppState>) => (next: (action: StoreAction) => void) => (action: StoreAction) => {
		next(action);
		askImportInvalidator(store, action);
	};
}

const runInvalidatorEffect = createInvalidatorEffect();

export { runInvalidatorEffect };
