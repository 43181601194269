import { Store } from 'redux';

import { IAppState } from '@store';
import { isReceiveAction } from '@flux';
import { types as platformTypes } from '@platform/actions/types';
import { types as paymentsTypes } from '@payments/actions/types';
import { mainTypes as mainOperationTypes } from '@pl/actions/types';
import { types as fundsRegistersTypes } from '@funds-registers/actions/types';
import { invalidateFundsRegisterStatistics } from '@funds-registers/actions/invalidators';
import { invalidateDashboardData } from '@dashboard/actions/invalidators';
import { invalidateTopCounterpartiesReportData } from '@top-counterparties-report/actions/invalidators';

function askMergeFundsRegistersInvalidator(store: Store<IAppState>, action: StoreAction) {
	const { dispatch } = store;
	const invalidateMap = {
		[fundsRegistersTypes.RUN_FUNDS_REGISTER_STATISTICS_INVALIDATION_EFFECT]: true,
	};

	if (invalidateMap[action.type]) {
		dispatch(invalidateFundsRegisterStatistics());
		dispatch(invalidateDashboardData());
		dispatch(invalidateTopCounterpartiesReportData());
	}
}

function askFundsRegistersInvalidator(store: Store<IAppState>, action: StoreAction) {
	const { dispatch } = store;
	const invalidateMap = {
		[platformTypes.IMPORT_ACCOUNTS_FROM_CMS_TASK_COMPLETED]: true,
		[platformTypes.IMPORT_HISTORICAL_ACCOUNT_STATEMENT_FROM_CMS_TASK_COMPLETED]: true,
		[paymentsTypes.ADD_PAYMENT]: true,
		[paymentsTypes.UPDATE_PAYMENT]: true,
		[paymentsTypes.REMOVE_PAYMENT]: true,
		[paymentsTypes.MARK_AS_TRANSFER]: true,
		[paymentsTypes.UNMARK_TRANSFER]: true,
		[paymentsTypes.MERGE_PAYMENTS_TO_TRANSFER]: true,
		[mainOperationTypes.TRANSFORM_PLAN_OPERATIONS_TO_FACT]: true,
		[mainOperationTypes.ADD_OPERATION]: true,
		[mainOperationTypes.UPDATE_OPERATION]: true,
		[mainOperationTypes.REMOVE_OPERATIONS]: true,
		[mainOperationTypes.ADD_OPERATIONS_BY_TEMPLATE]: true,
		[mainOperationTypes.UPDATE_OPERATIONS_BY_TEMPLATE]: true,
		[mainOperationTypes.SEND_PLAN_OPERATIONS_TO_BANK]: true,
	};

	if (invalidateMap[action.type] && isReceiveAction(action)) {
		dispatch(invalidateFundsRegisterStatistics());
	}
}

function createInvalidatorEffect() {
	return (store: Store<IAppState>) => (next: (action: StoreAction) => void) => (action: StoreAction) => {
		next(action);
		askMergeFundsRegistersInvalidator(store, action);
		askFundsRegistersInvalidator(store, action);
	};
}

const runInvalidatorEffect = createInvalidatorEffect();

export { runInvalidatorEffect };
