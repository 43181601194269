import styled from 'styled-components';


const DefaultFooterRoot = styled.footer`
	width: 100%;
	padding: 0 40px;
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	align-items: center;

	@media(max-width: 640px) {
		flex-flow: column nowrap;
		justify-content: center;
	}
`;

export {
	DefaultFooterRoot,
}
