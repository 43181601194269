import styled from 'styled-components';

import { textClip } from '@ui/basic-theme/styled-utils';
import * as c from '@ui/basic-theme/colors';


const Root = styled.div`
	position: relative;
	width: 100%;
	display: flex;
`;

const HeaderLayout = styled.div`
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
`;

const Header = styled.header`
	width: 100%;
	min-height: 64px;
	background-color: #fff;
	box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
	padding: 4px 16px;
	display: flex;
	align-items: center;
`;

const LogoLayout = styled.div`
	flex: 0 0 auto;
	margin-right: 10px;
`;

const ServiceName = styled.h1`
	font-size: 1.2rem;
	font-weight: 700;
	text-align: center;
	margin-bottom: 30px;
`;

const UserName = styled.span`
	flex: 1 1 auto;
	text-align: right;
	max-width: 100%;
	font-size: 1rem;
	font-weight: 500;
	${textClip()}
`;

const ContentLayout = styled.div`
	position: relative;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 64px;
	padding: 16px;
`;

const Content = styled.div`
	width: 100%;
`;

const Status = styled.h3`
	font-size: 1rem;
	font-weight: 500;
	color: ${c.accentPrimary};
	margin-bottom: 30px;
	text-align: center;
`;

const MainLayout = styled.div`
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;
`;

const IconLayout = styled.div`
	margin-bottom: 20px;
`;

const Text = styled.p`
	font-size: 1rem;
	text-align: center;
	line-height: 1.2;
	margin-bottom: 40px;
`;

const ButtonLayout = styled.div`
	width: 100%;
	margin-bottom: 20px;
`;


export {
	Root,
	HeaderLayout,
	Header,
	LogoLayout,
	ServiceName,
	UserName,
	ContentLayout,
	Content,
	Status,
	MainLayout,
	IconLayout,
	Text,
	ButtonLayout,
}