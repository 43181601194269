import * as _ from 'underscore';

import { IAppState } from '@store';
import { getPropInSafe } from '@utils';
import { createAsyncSelector, createSelector } from '@flux';

const selectAsyncPlOperations = createAsyncSelector<Array<PLOperationBrief>, IAppState>({
	get: s => s.analytics.analysis.plList,
	selector: createSelector(
		s => s.analytics.analysis.plList.item,
		item => item,
	),
});

const selectAsyncSegments = createAsyncSelector<Array<ABCAnalysisSegment>, IAppState>({
	get: state => state.analytics.analysis.segments,
	selector: createSelector(
		state => state.analytics.analysis.segments.item,
		item => item,
	),
});

function selectDirection(state: IAppState): boolean {
	return state.analytics.analysis.income;
}

function selectSegmentName(state: IAppState): string {
	return state.analytics.analysis.segmentName;
}

function selectCounterpartyID(state: IAppState): number {
	return state.analytics.analysis.counterpartyID;
}

function selectDetalizationOptions(state: IAppState) {
	return state.analytics.analysis.detalizationOptions;
}

const selectDetalizationTableData = createSelector(selectAsyncPlOperations.selectItem, plList => {
	const grouppedPlList = _.groupBy(plList, x => (x.Counterparty ? x.Counterparty.ID : -1));
	const data = Object.keys(grouppedPlList).map(key => {
		const cpID = Number(key);
		const plList = grouppedPlList[key];
		const cpName = getPropInSafe(plList, o => o[0].Counterparty.Name, 'Контрагент не указан');
		const totalAmount = plList.reduce((acc, pl) => ((acc += pl.Amount), acc), 0);
		const paymentsCount = plList.length;

		return {
			cpID,
			cpName,
			totalAmount,
			paymentsCount,
		};
	});

	data.sort((a, b) => b.totalAmount - a.totalAmount);

	return data;
});

const selectFilteredPlOperations = createSelector(
	selectAsyncPlOperations.selectItem,
	selectCounterpartyID,
	(plList, cpID) => {
		const filteredPlList = plList.filter(x => (x.Counterparty ? x.Counterparty.ID === cpID : cpID === -1));

		return filteredPlList;
	},
);

export const analysisSelectorsPack = {
	selectAsyncPlOperations,
	selectAsyncSegments,
	selectDirection,
	selectSegmentName,
	selectCounterpartyID,
	selectDetalizationOptions,
	selectDetalizationTableData,
	selectFilteredPlOperations,
};
