import { useCallback, useMemo } from 'react';
import * as _ from 'underscore';

function useDebouncedCallback<T extends (...args) => any = () => void>(fn: T, timeoutMs = 0, deps = []): T {
	const debounced = useMemo(() => _.debounce(fn, timeoutMs), [...deps]);
	const callback = useCallback((...args) => debounced(...args), [debounced]);

	return callback as T;
}

export { useDebouncedCallback };
