import * as rubles from 'rubles';

import { declOfNum } from '@utils';

type FormatCurrencyOptions = {
	code?: string;
	locale?: string;
	fractions?: number;
};

function formatCurrency(value: number, options: FormatCurrencyOptions = {}): string {
	const { code = 'RUR', locale = 'ru', fractions = 2 } = options;
	const digits = value.toFixed(fractions);
	const parts = digits.split(/[.,]/g);
	const integer = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
	const fraction = parts[1];
	const needFractions = Boolean(fractions);

	return `${integer}${needFractions ? ',' + fraction : ''} ${getCurrencySymbol(code)}`;
}

function formatCurrencyAsText(value: number) {
	let strValue = 'ноль рублей';

	if (value > 0) {
		const str = value.toFixed(2);
		const splittedValue = str.split('.');
		const trasformedValue = +splittedValue[0] ? rubles.rubles(+splittedValue[0]) : '';

		strValue = trasformedValue.slice(0, trasformedValue.length - 10);

		if (splittedValue[1]) {
			const decimalMap = {
				'0': '',
				'1': 'десять',
				'2': 'двадцать',
				'3': 'тридцать',
				'4': 'сорок',
				'5': 'пятьдесят',
				'6': 'шестьдесят',
				'7': 'семьдесят',
				'8': 'восемьдесят',
				'9': 'девяносто',
			};
			const tensMap = {
				'11': 'одиннадцать',
				'12': 'двенадцать',
				'13': 'тринадцать',
				'14': 'четырнадцать',
				'15': 'пятнадцать',
				'16': 'шестнадцать',
				'17': 'семнадцать',
				'18': 'восемнадцать',
				'19': 'девятнадцать',
			};
			const unitsMap = {
				'0': '',
				'1': 'одна',
				'2': 'две',
				'3': 'три',
				'4': 'четыре',
				'5': 'пять',
				'6': 'шесть',
				'7': 'семь',
				'8': 'восемь',
				'9': 'девять',
			};

			const spittedFraction = splittedValue[1].split('');
			const withTwoNumbers = spittedFraction[1] ? splittedValue[1] : spittedFraction[0] + '0';
			const fraction = tensMap[splittedValue[1]]
				? tensMap[splittedValue[1]]
				: `${decimalMap[spittedFraction[0]]}${unitsMap[spittedFraction[1]] ? ` ${unitsMap[spittedFraction[1]]}` : ''}`;

			strValue += fraction
				? ` ${fraction} ${declOfNum(+withTwoNumbers, ['копейка', 'копейки', 'копеек'])}`
				: ' 00 копеек';
		}
	}

	return strValue;
}

const getCurrencySymbol = (currencyCode: string) => currencySymbolsMap[currencyCode] || currencyCode;

const currencySymbolsMap = {
	NA: '₽',
	RUR: '₽',
	USD: '$',
	EUR: '€',
	AED: 'د.إ',
	AFN: '؋',
	AMD: '֏',
	ARS: '$',
	AUD: '$',
	AWG: 'ƒ',
	AZN: '₼',
	BBD: '$',
	BMD: '$',
	BND: '$',
	BSD: '$',
	CDF: '₣',
	CHF: '₣',
	CNY: 'Ұ',
	CRC: '₡',
	CZK: 'Kč',
	DZD: 'دج',
	EGP: '£',
	GBP: '£',
	GEL: '₾',
	GHS: '₵',
	GIP: '£',
	GNF: '£',
	ILC: '₪',
	INR: '₹',
	JPY: '¥',
	KPW: '₩',
	KRW: '₩',
	KZT: '₸',
	MNT: '₮',
	NGN: '₦',
	NPR: 'रु',
	PHP: '₱',
	PLN: 'zł',
	TRY: '₺',
	THB: '฿',
	UAH: '₴',
	YER: 'ر.ي',
};

function detectIsBankAccountRegisterNumber(value: string): boolean {
	const pattern = /^\d{20}$/gi;

	return pattern.test(value);
}

function extractCurrencyDigitCode(value: string): string {
	return detectIsBankAccountRegisterNumber(value) ? value.substr(5, 3) : '-1';
}

export {
	formatCurrency,
	formatCurrencyAsText,
	getCurrencySymbol,
	detectIsBankAccountRegisterNumber,
	extractCurrencyDigitCode,
};
