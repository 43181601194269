import React from 'react';
import { Box } from '@ui/box';
import { ProgressBar } from '@ui/progress-bar';
import { SetAppProgressBarOptions } from '@shared/models';

export type AppProgressBarProps = {
	options: SetAppProgressBarOptions;
};

const AppProgressBar: React.FC<AppProgressBarProps> = ({ options }) => {
	const { isOpen, value, text } = options || {};
	if (!isOpen) return null;

	return (
		<Box width='100%'>
			<ProgressBar value={value} text={text} />
		</Box>
	);
};

export default AppProgressBar;
