import * as corepackage from 'corepackage-api';

const coreApi = {
	package: corepackage,
};

class DataSourceItem<T = string> {
	value: T;
	text: string;

	constructor(options: DataSourceItem<T>) {
		this.value = options.value;
		this.text = options.text || '';
	}
}

class DataSourceTreeNode<T, B = any> {
	ID: SimpleID;
	Name: string;
	ChildItems: Array<DataSourceTreeNode<T>>;
	ParentID: SimpleID;
	value: T;
	box?: B;

	constructor(options: Partial<DataSourceTreeNode<T>> = {}) {
		this.ID = options.ID || -1;
		this.Name = options.Name || '';
		this.ChildItems = options.ChildItems || [];
		this.ParentID = options.ParentID || -1;
		this.value = options.value || null;
		this.box = options.box || null;
	}
}

class AsyncProgressStatus {
	status: ServerStatus;
	message: string;
	progress: number;

	constructor() {
		this.status = null;
		this.message = '';
		this.progress = 0;
	}
}

class ComplexFilter<T = Record<number, boolean>, S = Array<string>> {
	value: T = null;
	source: S = null;

	constructor(options: Partial<ComplexFilter<T, S>> = {}) {
		const value =
			options.value && typeof options.value === 'object'
				? Array.isArray(options.value)
					? [...options.value]
					: { ...options.value }
				: options.value;

		this.value = (value || {}) as any;
		this.source = (options.source || []) as any;
	}
}

export { coreApi, DataSourceItem, DataSourceTreeNode, AsyncProgressStatus, ComplexFilter };
