import styled from 'styled-components';

const phone = '425px';
const tablet = '768px';
const laptop = '1200px';
const desktop = '1440px';

const createGridColumn = (cols: number) => {
	switch (cols) {
		case 12:
			return `
				width: 100%;
				flex-basis: 100%;
			`;
		case 11:
			return `
				width: 91.66666667%;
				flex-basis: 91.66666667%;
			`;
		case 10:
			return `
				width: 83.33333333%;
				flex-basis:  83.33333333%;
			`;
		case 9:
			return `
				width: 75%;
				flex-basis: 75%;
			`;
		case 8:
			return `
				width: 66.66666667%;
				flex-basis: 66.66666667%;
			`;
		case 7:
			return `
				width: 58.33333333%;
				flex-basis: 58.33333333%;
			`;
		case 6:
			return `
				width: 50%;
				flex-basis: 50%;
			`;
		case 5:
			return `
				width: 41.66666667%;
				flex-basis: 41.66666667%;
			`;
		case 4:
			return `
				width: 33.33333333%;
				flex-basis: 33.33333333%;
			`;
		case 3:
			return `
				width: 25%;
				flex-basis: 25%;
			`;
		case 2:
			return `
			width: 16.66666667%;
			flex-basis: 16.66666667%;
		`;
		case 1:
			return `
			width: 8.33333333%;
			flex-basis: 8.33333333%;
		`;

		default:
			return ``;
	}
};

interface IGrid {
	minHeight?: string;
	stretch?: boolean;
}

const Grid = styled.div`
	width: 100%;
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;
	box-sizing: border-box;

	& * {
		box-sizing: border-box;
	}

	${(props: IGrid) =>
		props.minHeight &&
		`
		min-height: ${props.minHeight};
	`}

	${(props: IGrid) =>
		props.stretch &&
		`
		align-items: stretch;
	`}
`;

interface IGridColumn {
	phone?: number;
	tablet?: number;
	laptop?: number;
	desktop?: number;
	gutter?: number;
}

const GridColumn = styled.div`
	position: relative;
	min-height: 1px;
	flex: 0 0 auto;
	padding: 0;

	${(props: IGridColumn) =>
		props.gutter &&
		`
		padding: 0 ${props.gutter}px;
	`}

	${(props: IGridColumn) => props.desktop && createGridColumn(props.desktop)}

	@media (max-width: ${phone}) {
		${(props: IGridColumn) => props.phone && createGridColumn(props.phone)}
	}

	@media (max-width: ${tablet}) {
		${(props: IGridColumn) => props.tablet && createGridColumn(props.tablet)}
	}

	@media (max-width: ${laptop}) {
		${(props: IGridColumn) => props.laptop && createGridColumn(props.laptop)}
	}
`;

export { Grid, GridColumn };
