export * from './invoice-document-viewer';
import type { ExportInvoiceToDocOptions } from './word-export';

async function asyncExportInvoiceToDOC(options: ExportInvoiceToDocOptions) {
	const { exportInvoiceToDOC } = await import('./word-export');

	return exportInvoiceToDOC(options);
}

export { asyncExportInvoiceToDOC };
