import React, { useState, useLayoutEffect, useMemo, memo } from 'react';
import { useSpring } from 'react-spring';

import { blockingScroll, lackOfTwitching } from '@utils/dom';
import { getDrawer } from '@utils/drawer';
import { Portal } from '@ui/portal';
import { Root } from './styled';

export type OverlayProps = {
	isOpen: boolean;
	zIndex?: number;
	onClick?: (e: React.MouseEvent) => void;
};

const Overlay: React.FC<OverlayProps> = memo(props => {
	const { isOpen, zIndex, onClick } = props;
	const [isCloseAnimationDone, setIsCloseAnimationDone] = useState(true);
	const scope = useMemo(() => ({ dispose: () => {} }), []);
	const style = useSpring({
		opacity: isOpen ? 1 : 0,
		config: { duration: isOpen ? undefined : 100 },
		zIndex: zIndex || 1000,
		onStart: () => {
			isOpen && setIsCloseAnimationDone(false);
		},
		onRest: () => {
			if (!isOpen) {
				setIsCloseAnimationDone(true);
				scope.dispose();
			}
		},
	});

	useLayoutEffect(() => {
		if (!isOpen || getDrawer().isOpened()) return;
		const [removeLack, restoreLack] = lackOfTwitching();
		removeLack();
		const unblock = blockingScroll();

		scope.dispose = () => {
			restoreLack();
			unblock();
		};
	}, [isOpen]);

	if (!isOpen && isCloseAnimationDone) return null;

	return (
		<Portal>
			<Root style={style} onClick={onClick} />
		</Portal>
	);
});

export { Overlay };
