import React from 'react';

import { IconBase, IconBaseProps } from './base';

export type MinusIconProps = IconBaseProps;

const MinusIcon: React.FC<MinusIconProps> = props => {
	return (
		<IconBase {...props}>
			<svg
				stroke='currentColor'
				fill='currentColor'
				strokeWidth='0'
				viewBox='0 0 1024 1024'
				xmlns='http://www.w3.org/2000/svg'>
				<path d='M872 474H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h720c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8z'></path>
			</svg>
		</IconBase>
	);
};

export { MinusIcon };
