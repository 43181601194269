import { StoreAsyncItem } from '@flux';

type ActionMapType<S> = Record<string, (action: StoreAction, state: S) => Partial<S>>;

function createReducer<S>(initialState: S, actionMap: ActionMapType<S>) {
	return (state = initialState, action): S => {
		const fn = actionMap[action.type] || (() => state);

		return {
			...state,
			...fn(action, state),
		};
	};
}

function createDefaultAsyncItem<T>(item: T = null) {
	return {
		isFetching: false,
		didInvalidate: false,
		isLoaded: false,
		item,
	};
}

function checkAsyncItemLoadAction<S = any, T = any>(
	action: AsyncAction<S>,
	state,
	setItem?: (item: S) => any,
): StoreAsyncItem<T> {
	if (action.status === 'REQUEST') {
		return {
			...state,
			isFetching: true,
			didInvalidate: false,
		};
	}

	if (action.status === 'RECEIVE') {
		return {
			isFetching: false,
			isLoaded: true,
			didInvalidate: false,
			item: setItem ? setItem(action.response) : action.response,
		};
	}

	return state;
}

function invalidateAction<S>(action: AsyncAction<S> | StaticAction<S>, state, resetFetchingFlag = true) {
	const asyncAction = action as AsyncAction<S>;
	const isStaticAction = !asyncAction.status;

	if (asyncAction.status === 'REQUEST') {
		return {
			...state,
			isFetching: true,
		};
	}

	if (asyncAction.status === 'RECEIVE' || isStaticAction) {
		return {
			...state,
			isFetching: resetFetchingFlag ? false : state.isFetching,
			didInvalidate: true,
		};
	}

	return state;
}

function restoreValidationState<S>(state: StoreAsyncItem<S>) {
	if (state.didInvalidate) {
		return {
			...state,
			didInvalidate: false,
		};
	}

	return state;
}

export { createReducer, checkAsyncItemLoadAction, createDefaultAsyncItem, invalidateAction, restoreValidationState };
