import React from 'react';

import { prettyDate, convertDateToString } from '@utils/date';
import { formatCurrency } from '@utils/currency';
import { fade } from '@utils/color';
import { Box } from '@ui/box';
import { Typography } from '@ui/typography';
import { Spinner } from '@ui/spinner';
import { DatePicker } from '@ui/date-picker';
import { HelpMark } from '@ui/help-mark';
import { Protector } from '@ui/protector';
import { FetchingPlaceholder } from '@ui/fetching-placeholder';
import { CashGapPoint } from '@planning/models';
import { Root, Label, Value } from './styled';

export type IndicatorProps = {
	isForecastFetching: boolean;
	isCashGapFetching: boolean;
	isBalanceHiddenFetching: boolean;
	isLoaded: boolean;
	isBalanceHidden: boolean;
	hasPlanningFeature: boolean;
	date: string;
	balance: number;
	cashGapPoint: CashGapPoint | null;
	onNavigateToPlanning: () => void;
	onChangeDate: (value: string) => void;
};

const Indicator: React.FC<IndicatorProps> = props => {
	const {
		isForecastFetching,
		isCashGapFetching,
		isBalanceHiddenFetching,
		isLoaded,
		isBalanceHidden,
		cashGapPoint,
		hasPlanningFeature,
		date,
		balance,
		onNavigateToPlanning,
		onChangeDate,
	} = props;

	const handleChangeDate = (date: Moment) => onChangeDate(convertDateToString(date));

	if (!hasPlanningFeature) return null;

	if (isCashGapFetching && !isLoaded) {
		return (
			<Root>
				<Box display='flex' justifyContent='center' alignItems='center' fullWidth>
					<Spinner size={20} thickness={2} />
				</Box>
			</Root>
		);
	}

	const renderCashflowForecastBranch = () => {
		const isFetching = isForecastFetching || isBalanceHiddenFetching;

		return (
			<Root>
				<Box marginBottom={6}>
					<Typography.Label fontSize={12}>Прогноз на</Typography.Label>
					<Typography.Nbsp />
					<DatePicker value={date} inlineMode disablePastDates disabled={isBalanceHidden} onChange={handleChangeDate} />
				</Box>
				<Box display='flex' justifyContent='center'>
					<Protector
						isHidden={isBalanceHidden}
						fallback={<Box height={20} width={MAX_WIDTH} backgroundColor={backgroundColor} />}>
						<FetchingPlaceholder isFetching={isFetching} maxWidth={MAX_WIDTH}>
							<Box display='flex' alignItems='center'>
								<Typography.Label component='div' fontSize={16} marginRight={4}>
									{formatCurrency(balance)}
								</Typography.Label>
								<HelpMark
									label='Прогнозируемый остаток рассчитывается на основе плановых операций'
									IconProps={{ size: 16 }}
								/>
							</Box>
						</FetchingPlaceholder>
					</Protector>
				</Box>
			</Root>
		);
	};

	const renderCashGapBranch = () => {
		return (
			<Root title='Перейти в планирование' enablePointer onClick={onNavigateToPlanning}>
				<Box marginBottom={6}>
					<Label>Кассовый разрыв {prettyDate(cashGapPoint.date)}</Label>
				</Box>
				<Value>{formatCurrency(cashGapPoint.value)}</Value>
			</Root>
		);
	};

	return cashGapPoint ? renderCashGapBranch() : renderCashflowForecastBranch();
};

const backgroundColor = fade('#ccc', 0.4);
const MAX_WIDTH = 130;

export { Indicator };
