import React from 'react';

import withTopShift from '@core/hocs/with-top-shift.hoc';

type WorkspaceProps = {
	topShift?: number;
	sessionContextLoaded: boolean;
	sessionContext: ClientSessionContext;
	workspaceComponent: React.ComponentType<any>;
	billingServiceStatus: BillingServiceStatus;
	tenantProfile: TenantProfile;
	isFullAccess: boolean;
};

const Workspace: React.FC<WorkspaceProps> = props => {
	const { topShift, workspaceComponent, sessionContext, billingServiceStatus, tenantProfile, isFullAccess } = props;
	const style = {
		paddingTop: topShift ? 70 + topShift : undefined,
	};
	const Tag = workspaceComponent;
	const availableComponents = sessionContext ? sessionContext.AvailableComponents || [] : [];
	const userRights = sessionContext ? sessionContext.UserRights || [] : [];

	return (
		<div className='workspace' style={style}>
			{Tag && (
				<Tag
					availableComponents={availableComponents}
					userRights={userRights}
					billingServiceStatus={billingServiceStatus}
					tenantProfile={tenantProfile}
					isFullAccess={isFullAccess}
				/>
			)}
		</div>
	);
};

export default withTopShift<WorkspaceProps>()(Workspace);
