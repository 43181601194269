import * as colors from './colors';
import { Theme } from '@theme';

export interface InputHintStatuses<S> {
	danger: S;
	warning: S;
	success: S;
	info: S;
	primary: S;
}

export interface InputDisplayStatuses<S> {
	readonly: S;
	disabled: S;
	primary: S;
}

export function getHintStatus(status: {
	isError: boolean;
	isWarning: boolean;
	isSuccess: boolean;
	isInformation: boolean;
}): keyof InputHintStatuses<any> {
	if (status.isError) {
		return 'danger';
	}
	if (status.isWarning) {
		return 'warning';
	}
	if (status.isSuccess) {
		return 'success';
	}
	if (status.isInformation) {
		return 'info';
	}
	return 'primary';
}

export function getHintStatusText(props) {
	return props.errorText || props.warningText || props.successText || props.informationText;
}

export function getHintStatusStyles(
	status: keyof InputHintStatuses<any>,
	customStyles: Partial<InputHintStatuses<React.CSSProperties>> = {},
	theme: Theme,
) {
	const map: InputHintStatuses<React.CSSProperties> = {
		danger: { color: theme.palette.alarm },
		warning: { color: theme.palette.warning },
		success: { color: theme.palette.accent },
		info: { color: theme.palette.text, borderWidth: 1 },
		primary: {},
	};

	return customStyles[status] || map[status];
}

export function getDisplayStatuses(state: { disabled: boolean; readonly: boolean }): keyof InputDisplayStatuses<any> {
	if (state.readonly) {
		return 'readonly';
	}
	if (state.disabled) {
		return 'disabled';
	}
	return 'primary';
}

export function getClassNameMap(type: 'text'): InputDisplayStatuses<string> {
	const baseClass = 'input input_type_' + type;
	return {
		disabled: baseClass + ' input_disabled',
		readonly: baseClass + ' input_readonly',
		primary: baseClass,
	};
}
