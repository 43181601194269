import React from 'react';

import { TextField, TextFieldProps, TextFieldRef } from '@ui/input-field';
import { SearchIcon } from '@ui/icons/search';
import { Root, IconLayout, SearchBarLayout } from './styled';

type SearchBarProps = {
	appearance?: 'search' | 'simple';
	onClick?: (e: React.SyntheticEvent<{}>) => void;
	onSetTextFieldRef?: (ref: TextFieldRef) => void;
} & TextFieldProps;

class SearchBar extends React.PureComponent<SearchBarProps> {
	static displayName = 'SearchBar';
	static defaultProps = {
		appearance: 'search',
		debounce: 0,
		onSetTextFieldRef: () => {},
	};

	render() {
		const { appearance, hintText, onClick, onSetTextFieldRef, ...rest } = this.props;

		return (
			<Root onClick={onClick}>
				<TextField
					{...rest}
					ref={onSetTextFieldRef}
					hintText={hintText || 'Введите первые символы...'}
					iconPosition='left'
					icon={
						appearance === 'search' && (
							<IconLayout>
								<SearchIcon color='muted' size={20} />
							</IconLayout>
						)
					}
					fullWidth
				/>
			</Root>
		);
	}
}

export { SearchBar, SearchBarLayout };
