function transformEscapedStr(str: string) {
	let transformedStr = str;
	const map = new Map([
		[/&lt;/g, '<'],
		[/&gt;/g, '>'],
		[/&amp;/g, '&'],
		[/&quot;/g, '"'],
		[/&#39;/g, "'"],
	]);

	for (const [pattern, replacer] of map.entries()) {
		transformedStr = transformedStr.replace(pattern, replacer);
	}

	return transformedStr;
}

function replaceWhitespacesFromTags(html: string): string {
	return html.replace(/[^\S]\s+(<)/g, '$1').trim();
}

function convertCsvArrayToNormalArray(value: string, transform: (x: string) => any) {
	return value.split(',').map(transform);
}

export { transformEscapedStr, replaceWhitespacesFromTags, convertCsvArrayToNormalArray };
