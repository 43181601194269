import React from 'react';

import { Root } from './styled';

export type UIBlockerProps = {
	isBlocked: boolean;
	children: React.ReactNode;
};

const UIBlocker: React.FC<UIBlockerProps> = props => {
	const { children, isBlocked } = props;

	return <Root isBlocked={isBlocked}>{children}</Root>;
};

export { UIBlocker };
