import React, { memo, useMemo } from 'react';

import { coreApi } from '@core/api/core';
import { useMapState, useMapDispatch, useAutoFetch } from '@flux';
import { createBooleanMap, extractKeysToArray } from '@utils/object';
import { withFormAutopicker } from '@ui/autopicker';
import { mainCashflowItemsActionsPack } from '@cashflow-items/actions';
import { mainCashflowItemsSelectorsPack } from '@cashflow-items/selectors';
import {
	CashflowItemAutopicker as XCashflowItemAutopicker,
	CashflowItemAutopickerProps as XCashflowItemAutopickerProps,
} from './cashflow-item-autopicker.view';

export type CashflowItemAutopickerProps = {
	excludeID?: number;
} & Omit<XCashflowItemAutopickerProps, 'dataSource' | 'isFetching' | 'hasChangeRight'>;

const CashflowItemAutopicker: React.FC<CashflowItemAutopickerProps> = memo(props => {
	const { excludeID, isIncome } = props;
	const [isFetching, isLoaded, cashflowItems, hasChangeRight] = useMapState([
		mainCashflowItemsSelectorsPack.selectAsyncCashflowItems.selectIsFetching,
		mainCashflowItemsSelectorsPack.selectAsyncCashflowItems.selectIsLoaded,
		mainCashflowItemsSelectorsPack.selectAsyncCashflowItems.selectItem,
		mainCashflowItemsSelectorsPack.selectCashflowItemsRight.change,
	]);
	const [fetchCashflowItems] = useMapDispatch([mainCashflowItemsActionsPack.fetchCashflowItems]);
	const dataSource: Array<CashflowItem> = useMemo(() => {
		const items = cashflowItems.filter(
			x => (typeof isIncome === 'boolean' ? x.Incoming === isIncome : true) && x.ID !== excludeID,
		);

		return items;
	}, [cashflowItems, isIncome]);

	useAutoFetch({
		selector: mainCashflowItemsSelectorsPack.selectAsyncCashflowItems.selectDidInvalidate,
		fetch: () => fetchCashflowItems(),
	});

	return (
		<XCashflowItemAutopicker
			{...props}
			isFetching={isFetching && !isLoaded}
			isUpdating={isFetching}
			hasChangeRight={hasChangeRight}
			dataSource={dataSource}
		/>
	);
});

const FormCashflowItemAutopicker = withFormAutopicker<Omit<CashflowItemAutopickerProps, 'name'>, unknown>(
	CashflowItemAutopicker,
);

const cashflowItemAutopickerTransformer = {
	ID: {
		single: {
			transformInput: ({ input }: { input: number }) => (input > 0 ? { [input]: true } : null),
			transformOutput: ({ output }) => Number(extractKeysToArray(output)[0]) || -1,
		},
		multiple: {
			transformInput: ({ input }: { input: Array<number> }) => createBooleanMap(input, x => x),
			transformOutput: ({ output }) => extractKeysToArray(output, Number),
		},
	},
	cashflowItem: {
		single: {
			transformInput: ({ input }: { input: CashflowItem | null }) => (input ? { [input.ID]: true } : null),
			transformOutput: ({ items }) => (items[0] as Array<CashflowItem>) || null,
		},
		multiple: {
			transformInput: ({ input }: { input: Array<CashflowItem> }) => createBooleanMap(input, x => x.ID),
			transformOutput: ({ items }) => items as Array<CashflowItem>,
		},
	},
	codeNaturalKey: {
		single: {
			transformInput: ({ input }: { input: CodeNaturalKey | null }) => (input ? { [input.ID]: true } : null),
			transformOutput: ({ items }) => createCodeNaturalKey(items[0]) || null,
		},
		multiple: {
			transformInput: ({ input }: { input: Array<CodeNaturalKey> }) => createBooleanMap(input, x => x.ID),
			transformOutput: ({ items }) => items.map((x: CashflowItem) => createCodeNaturalKey(x)),
		},
	},
};

const createCodeNaturalKey = (item: CashflowItem): CodeNaturalKey => {
	if (!item) return null;
	return {
		...new coreApi.package.CodeNaturalKey(),
		ID: item?.ID,
		Name: item?.Name || '',
		Code: item?.Code || '',
	};
};

export { CashflowItemAutopicker, FormCashflowItemAutopicker, cashflowItemAutopickerTransformer };
