import React from 'react';

import {
	Root,
	DividerTop,
	DividerBottom,
	ContentLayout,
	IconLayout,
	SecondIconLayout,
	TextLayout,
	Title,
	Text,
} from './styled';

interface IProps {
	title?: string;
	text?: string;
	textColor?: 'primary' | 'secondary';
	icon?: React.ReactElement<any>;
	secondIcon?: React.ReactElement<any>;
	iconScale?: number;
	disabled?: boolean;
	isActive?: boolean;
	render?: () => React.ReactElement<any>;
	withGroupHeader?: boolean;
	textMaxWidth?: string;
	onClick?: () => void;
}

class DrawerLink extends React.Component<IProps> {
	handleClick = ev => {
		const { disabled, onClick } = this.props;

		!disabled && onClick && onClick();
		ev.stopPropagation();
	};

	render() {
		const {
			title,
			text,
			textColor,
			icon,
			secondIcon,
			iconScale,
			disabled,
			isActive,
			render,
			withGroupHeader,
			textMaxWidth,
		} = this.props;

		return (
			<Root disabled={disabled} isActive={isActive} onClick={this.handleClick} withGroupHeader={withGroupHeader}>
				<DividerTop />
				{typeof render === 'function' ? (
					<ContentLayout>{render()}</ContentLayout>
				) : (
					<ContentLayout>
						{icon && <IconLayout iconScale={iconScale}>{icon}</IconLayout>}
						<TextLayout textMaxWidth={textMaxWidth}>
							<Title>{title}</Title>
							<Text color={textColor}>
								{text}
								{secondIcon && <SecondIconLayout>{secondIcon}</SecondIconLayout>}
							</Text>
						</TextLayout>
					</ContentLayout>
				)}
				<DividerBottom />
			</Root>
		);
	}
}

export default DrawerLink;
