import styled, { css } from 'styled-components';

import { Box } from '@ui/box';
import { WithThemeProps } from '@theme';
import { ContainerProps } from './container';

type RootProps = {} & ContainerProps & WithThemeProps;

const Root = styled(Box)<RootProps>`
	position: relative;
	width: 100%;
	padding: 32px 40px 30px 40px;

	${p =>
		p.appearance === 'shaded' &&
		css`
			background-color: ${p.theme.materialCard.backgroundColor};
			${p.theme.fn.createBoxShadow(1)}
		`}

	@media (max-width: 1200px) {
		padding: 20px;
	}
`;

export { Root };
