import React from 'react';

import { detectIsInvoiceApp } from '@config';
import { Root, LinkStyled, Logo } from './styled';

export type AppLogoProps = {
	appearance?: 'default' | 'mobile';
	link?: string;
};

const AppLogo: React.FC<AppLogoProps> = ({ appearance, link }) => {
	return (
		<Root className='app-logo' appearance={appearance}>
			<LinkStyled to={link}>
				<Logo isInvoiceApp={isInvoiceApp} />
			</LinkStyled>
		</Root>
	);
};

const isInvoiceApp = detectIsInvoiceApp();

AppLogo.defaultProps = {
	appearance: 'default',
	link: 'bfm/',
};

export { AppLogo };
