import { createTypes, createStaticAction } from '@flux';
import { CreateAppRoutesOptions, AppPageRoutes, createAppRoutes } from '@routes/urls';

type ActionTypes = {
	INJECT_APP_ROUTES: string;
};

const types = createTypes<ActionTypes>(['INJECT_APP_ROUTES'], '😆PLATFORM😆');

const actions = {
	injectAppRoutes: (options: CreateAppRoutesOptions) => {
		AppPageRoutes.splice(0, AppPageRoutes.length, ...createAppRoutes());

		return createStaticAction(types.INJECT_APP_ROUTES)();
	},
};

export const routingActionsPack = {
	types,
	actions,
};
