import { createSelector } from 'reselect';
import * as _ from 'underscore';
import * as moment from 'moment';
import { compose } from 'redux';

import api from '@api';
import { sortDescBy, sortTreeByAlphabet } from '@utils';
import { BASE_DATE_FORMAT } from '@shared/constants/time';
import { mainCashflowItemsSelectorsPack } from '@cashflow-items/selectors';
import { mainReportingSelectorsPack } from './main-reporting.selectors';
import {
	ReportTableCell,
	ReportTableColumn,
	ReportTableRow,
} from '@analytics/components/reporting/shared/report-table';

function getPeriodByGroupKey(date: string, groupKey: string, fallback: DateRange) {
	const map = {
		QUARTER: () => ({
			dateStart: moment(date, 'Q YYYY').startOf('quarter').format(BASE_DATE_FORMAT),
			dateEnd: moment(date, 'Q YYYY').endOf('quarter').format(BASE_DATE_FORMAT),
		}),
		MONTH: () => ({
			dateStart: moment(date, 'MMMM YYYY').startOf('month').format(BASE_DATE_FORMAT),
			dateEnd: moment(date, 'MMMM YYYY').endOf('month').format(BASE_DATE_FORMAT),
		}),
		YEAR: () => ({
			dateStart: moment(date, 'YYYY').startOf('year').format(BASE_DATE_FORMAT),
			dateEnd: moment(date, 'YYYY').endOf('year').format(BASE_DATE_FORMAT),
		}),
	};

	return map[groupKey] ? map[groupKey](groupKey) : fallback;
}

function getDirection(ID: number) {
	const all = {
		income: true,
		expense: true,
	};
	const onlyExpense = {
		income: false,
		expense: true,
	};
	const onlyIncome = {
		income: true,
		expense: false,
	};
	const map = {
		'-3': onlyIncome,
		'-4': onlyExpense,
		'-5': all,
		'-6': onlyIncome,
		'-7': onlyExpense,
		'-8': all,
		'-9': all,
		'-10': onlyIncome,
		'-11': onlyExpense,
		'-12': all,
		'-13': all,
		'-14': onlyIncome,
		'-15': onlyExpense,
		'-16': all,
		'-17': all,
	};

	return map[ID] || all;
}

function getAciIDList(ID: number, cfiMap: Record<string, CashflowItem>) {
	const map = {
		'-3': () => [-1],
		'-4': () => [-1],
		'-5': () => getCashflowNodeIDByType(1, cfiMap),
		'-6': () => getCashflowNodeIDByType(1, cfiMap),
		'-7': () => getCashflowNodeIDByType(1, cfiMap),
		'-8': () => getCashflowNodeIDByType(1, cfiMap),
		'-9': () => getCashflowNodeIDByType(2, cfiMap),
		'-10': () => getCashflowNodeIDByType(2, cfiMap),
		'-11': () => getCashflowNodeIDByType(2, cfiMap),
		'-12': () => getCashflowNodeIDByType(2, cfiMap),
		'-13': () => getCashflowNodeIDByType(3, cfiMap),
		'-14': () => getCashflowNodeIDByType(3, cfiMap),
		'-15': () => getCashflowNodeIDByType(3, cfiMap),
		'-16': () => getCashflowNodeIDByType(3, cfiMap),
		'-17': () => [],
	};
	const list = ID > 0 ? [ID, ...getDescendantCashflowNodeIDList(ID, cfiMap)] : map[ID] ? map[ID]() : [];

	return list;
}

function getCashflowNodeIDByType(typeID: number, cfiMap: Record<string, CashflowItem>) {
	const list = Object.keys(cfiMap)
		.filter(key => cfiMap[key].CashflowTypeID === typeID)
		.map(key => cfiMap[key].ID);

	return list;
}

function getDescendantCashflowNodeIDList(ID: number, cfiMap: Record<string, CashflowItem>, list: Array<number> = []) {
	const item = cfiMap[ID];

	if (!item) return [];

	for (let i = 0; i < item.ChildItems.length; i++) {
		const childItem = item.ChildItems[i];
		const hasChildren = childItem.ChildItems.length > 0;

		list.push(childItem.ID);
		hasChildren && getDescendantCashflowNodeIDList(childItem.ID, cfiMap, list);
	}

	return list;
}

const reportUtils = {
	getAciIDList,
	getDirection,
	getPeriodByGroupKey,
	getDescendantCashflowNodeIDList,
};

export function getCashflowItemsForTable(
	ciList: Array<CashflowItem>,
	parentID: number,
	data: Array<ReportTableRow> = [],
) {
	let list = [];

	ciList.forEach(ci => {
		if (!data.some(item => item.ID === ci.ID) && (ci.ParentID === -1 || data.some(item => item.ID === ci.ParentID))) {
			list = [
				...list,
				{
					ID: ci.ID,
					parentID: ci.ParentID === -1 ? parentID : ci.ParentID,
					childItemsIDList: ci.ChildItems.map(child => child.ID),
					name: ci.Name,
					columns: [],
				},
			];

			if (ci.ChildItems.length > 0) {
				list = [...list, ...getCashflowItemsForTable(ci.ChildItems as Array<CashflowItem>, ci.ParentID, list)];
			}
		}
	});

	return list;
}

export function getCompletedBalanceList(
	dateStartMoment: Moment,
	dateEndMoment: Moment,
	balanceList: Array<CashBalanceRecord>,
): Array<CashBalanceRecord> {
	const list = [];
	const diffInMonths = dateEndMoment.clone().add(1, 'day').diff(dateStartMoment, 'months');
	const balanceMap = _.indexBy(balanceList, el => el.ValueDate);
	const listStartOf = [];
	const listEndOf = [];

	const getNextBalance = (balanceList: Array<CashBalanceRecord>, date: string) => {
		const format = 'MMMM YYYY';
		const grouppedBalances = _.groupBy(balanceList, x => moment(x.ValueDate, BASE_DATE_FORMAT).format(format));
		const monthDate = moment(date, BASE_DATE_FORMAT).format(format);
		const list = grouppedBalances[monthDate];

		if (list && list.length > 0) return list[0];
		return null;
	};

	for (let i = 0; i < diffInMonths; i++) {
		const valueDateStartOf = listStartOf[i - 1]
			? moment(listStartOf[i - 1].ValueDate, BASE_DATE_FORMAT)
					.add(1, 'month')
					.startOf('month')
					.format(BASE_DATE_FORMAT)
			: dateStartMoment.clone().startOf('month').format(BASE_DATE_FORMAT);
		const valueDateEndOf = moment(valueDateStartOf, BASE_DATE_FORMAT).endOf('month').format(BASE_DATE_FORMAT);
		const hasIncomingBalance = balanceMap[valueDateStartOf];
		const nextBalance = !hasIncomingBalance ? getNextBalance(balanceList, valueDateStartOf) : null;
		const balanceRecordStartOf = hasIncomingBalance
			? { ...balanceMap[valueDateStartOf] }
			: {
					...new api.fundspackage.CashBalanceRecord(),
					Receipt: 0,
					Charge: 0,
					IncomingBalance: nextBalance?.IncomingBalance || null,
					OutgoingBalance: nextBalance?.OutgoingBalance || null,
					ValueDate: valueDateStartOf,
			  };
		const balanceRecordEndOf = balanceMap[valueDateEndOf]
			? { ...balanceMap[valueDateEndOf] }
			: {
					...new api.fundspackage.CashBalanceRecord(),
					Receipt: 0,
					Charge: 0,
					IncomingBalance: 0,
					OutgoingBalance: 0,
					ValueDate: valueDateEndOf,
			  };

		listStartOf.push(balanceRecordStartOf);
		listEndOf.push(balanceRecordEndOf);
	}

	for (let i = 0; i < listStartOf.length; i++) {
		list.push(listStartOf[i], listEndOf[i]);
	}

	return list;
}

function buildCashflowReportTreeList(forPLReport: boolean, ciMap: Record<string, CashflowItem>): Array<ReportTableRow> {
	const list = [];
	const ciList = Object.keys(ciMap).map(key => ciMap[key]);
	sortTreeByAlphabet<CashflowItem | AccountsChartItem>(ciList, el => el.Name);

	const operationalIncomeCiList = ciList.filter(ci => ci.CashflowTypeID === 1 && ci.Incoming);
	const operationalExpenseCiList = ciList.filter(ci => ci.CashflowTypeID === 1 && !ci.Incoming);
	const financialIncomeCiList = ciList.filter(ci => ci.CashflowTypeID === 2 && ci.Incoming);
	const financialExpenseCiList = ciList.filter(ci => ci.CashflowTypeID === 2 && !ci.Incoming);
	const investitionIncomeCiList = ciList.filter(ci => ci.CashflowTypeID === 3 && ci.Incoming);
	const investitionExpenseCiList = ciList.filter(ci => ci.CashflowTypeID === 3 && !ci.Incoming);

	const operationsIncomeCiChildItemsList = getCashflowItemsForTable(operationalIncomeCiList, -6);
	const operationsExpenseCiChildItemsList = getCashflowItemsForTable(operationalExpenseCiList, -7);
	const financialIncomeCiChildItemsList = getCashflowItemsForTable(financialIncomeCiList, -10);
	const financialExpenseCiChildItemsList = getCashflowItemsForTable(financialExpenseCiList, -11);
	const investitionIncomeCiChildItemsList = getCashflowItemsForTable(investitionIncomeCiList, -14);
	const investitionExpenseCiChildItemsList = getCashflowItemsForTable(investitionExpenseCiList, -15);

	!forPLReport &&
		list.push({
			ID: -2,
			parentID: -1,
			childItemsIDList: [],
			name: 'Входящий остаток',
			columns: [],
		});

	list.push({
		ID: -3,
		parentID: -1,
		childItemsIDList: [],
		name: !forPLReport ? 'Нераспределенные поступления' : 'Нераспределенные доходы',
		columns: [],
	});

	list.push({
		ID: -4,
		parentID: -1,
		childItemsIDList: [],
		name: !forPLReport ? 'Нераспределенные выплаты' : 'Нераспределенные расходы',
		columns: [],
	});

	list.push({
		ID: -5,
		parentID: -1,
		childItemsIDList: [-6, -7],
		name: 'Операционная деятельность',
		columns: [],
	});

	list.push({
		ID: -6,
		parentID: -5,
		childItemsIDList: operationsIncomeCiChildItemsList.map(i => i.ID),
		name: !forPLReport ? 'Поступления' : 'Доходы',
		columns: [],
		parentName: 'Операционная деятельность',
	});

	list.push(...operationsIncomeCiChildItemsList);

	list.push({
		ID: -7,
		parentID: -5,
		childItemsIDList: operationsExpenseCiChildItemsList.map(i => i.ID),
		name: !forPLReport ? 'Выплаты' : 'Расходы',
		columns: [],
		parentName: 'Операционная деятельность',
	});

	list.push(...operationsExpenseCiChildItemsList);

	list.push({
		ID: -8,
		parentID: -5,
		childItemsIDList: [],
		name: !forPLReport ? 'Чистый денежный поток' : 'Чистая прибыль',
		columns: [],
	});

	list.push({
		ID: -9,
		parentID: -1,
		childItemsIDList: [-10, -11],
		name: 'Финансовая деятельность',
		columns: [],
	});

	list.push({
		ID: -10,
		parentID: -9,
		childItemsIDList: financialIncomeCiChildItemsList.map(i => i.ID),
		name: !forPLReport ? 'Поступления' : 'Доходы',
		columns: [],
		parentName: 'Финансовая деятельность',
	});

	list.push(...financialIncomeCiChildItemsList);

	list.push({
		ID: -11,
		parentID: -9,
		childItemsIDList: financialExpenseCiChildItemsList.map(i => i.ID),
		name: !forPLReport ? 'Выплаты' : 'Расходы',
		columns: [],
		parentName: 'Финансовая деятельность',
	});

	list.push(...financialExpenseCiChildItemsList);

	list.push({
		ID: -12,
		parentID: -9,
		childItemsIDList: [],
		name: !forPLReport ? 'Чистый денежный поток' : 'Чистая прибыль',
		columns: [],
	});

	list.push({
		ID: -13,
		parentID: -1,
		childItemsIDList: [-14, -15],
		name: 'Инвестиционная деятельность',
		columns: [],
	});

	list.push({
		ID: -14,
		parentID: -13,
		childItemsIDList: investitionIncomeCiChildItemsList.map(i => i.ID),
		name: !forPLReport ? 'Поступления' : 'Доходы',
		columns: [],
		parentName: 'Инвестиционная деятельность',
	});

	list.push(...investitionIncomeCiChildItemsList);

	list.push({
		ID: -15,
		parentID: -13,
		childItemsIDList: investitionExpenseCiChildItemsList.map(i => i.ID),
		name: !forPLReport ? 'Выплаты' : 'Расходы',
		columns: [],
		parentName: 'Инвестиционная деятельность',
	});

	list.push(...investitionExpenseCiChildItemsList);

	list.push({
		ID: -16,
		parentID: -13,
		childItemsIDList: [],
		name: !forPLReport ? 'Чистый денежный поток' : 'Чистая прибыль',
		columns: [],
	});

	list.push({
		ID: -17,
		parentID: -1,
		childItemsIDList: [],
		name: !forPLReport ? 'Итоговый чистый денежный поток' : 'Итоговая прибыль',
		columns: [],
	});

	!forPLReport &&
		list.push({
			ID: -18,
			parentID: -1,
			childItemsIDList: [],
			name: 'Исходящий остаток',
			columns: [],
		});

	return list;
}

export function getCompletedAciSeriesMap(params: {
	dateStartMoment: Moment;
	dateEndMoment: Moment;
	aciSeriesMap: Record<string, PLOperationAccountsChartItemSeries>;
	ciMap: Record<string, CashflowItem>;
	planningMode?: boolean;
	undistributedIncomeKey: string;
}): Record<string, PLOperationAccountsChartItemSeries> {
	const { dateStartMoment, dateEndMoment, aciSeriesMap, ciMap, planningMode = false, undistributedIncomeKey } = params;
	const map = { ...aciSeriesMap };
	const diffInMonth = dateEndMoment.clone().add(1, 'day').diff(dateStartMoment, 'months');

	Object.keys(map).forEach(key => {
		const aciSeries = map[key];
		const list: Array<PLOperationTimeSeriesPoint> = [];

		for (let i = 0; i < diffInMonth; i++) {
			const valueDate = list[i - 1]
				? moment(list[i - 1].ValueDate, BASE_DATE_FORMAT)
						.add(1, 'month')
						.endOf('month')
						.format(BASE_DATE_FORMAT)
				: dateStartMoment.clone().endOf('month').format(BASE_DATE_FORMAT);

			list.push({
				...new api.plpackage.PLOperationTimeSeriesPoint(),
				DirectionIncoming:
					aciSeries.AccountsChartItemID !== -1 && ciMap[aciSeries.AccountsChartItemID]
						? ciMap[aciSeries.AccountsChartItemID].Incoming
						: key === undistributedIncomeKey
						? true
						: false,
				Amount: 0,
				ValueDate: valueDate,
			});
		}

		const listMap = _.indexBy(list, l => l.ValueDate);

		if (planningMode) {
			const points = [...aciSeries.Points];
			const currMonthMoment = moment().endOf('month');

			points.forEach(p => {
				if (listMap[p.ValueDate]) {
					const dateMoment = moment(p.ValueDate, BASE_DATE_FORMAT);

					if (
						(dateMoment.isBefore(currMonthMoment, 'month') && !p.Plan) ||
						dateMoment.isSame(currMonthMoment, 'month') ||
						(dateMoment.isAfter(currMonthMoment, 'month') && p.Plan)
					) {
						listMap[p.ValueDate].DirectionIncoming = p.DirectionIncoming;
						listMap[p.ValueDate].Plan = p.Plan;
						listMap[p.ValueDate].Amount += p.Amount;
					}
				}
			});
		} else {
			const points = aciSeries.Points.filter(p => !p.Plan);

			points.forEach(p => {
				if (listMap[p.ValueDate]) {
					listMap[p.ValueDate] = { ...p };
				}
			});
		}

		map[key] = { ...map[key], Points: Object.keys(listMap).map(key => listMap[key]) };
	});

	return map;
}

export function buildReportTableColumns(params: {
	list: Array<any>;
	dateSelector: (i) => string;
	amountSelector: (i) => number;
	incomeSelector?: (i) => boolean;
	isEmptySelector?: (i) => boolean;
	forIncomingBalance?: boolean;
	forOutgoingBalance?: boolean;
	planningMode?: boolean;
	plReportMode?: boolean;
}): Array<ReportTableColumn> {
	const {
		list,
		dateSelector,
		amountSelector,
		incomeSelector,
		isEmptySelector,
		forIncomingBalance = false,
		forOutgoingBalance = false,
		planningMode = false,
		plReportMode = false,
	} = params;
	const columns: Array<ReportTableColumn> = [];
	let totalByQuarter = 0;
	let totalByYear = 0;
	const currentMonth = !forIncomingBalance ? moment().endOf('month') : moment().startOf('month');

	list.forEach(i => {
		const dateMoment = moment(dateSelector(i), BASE_DATE_FORMAT);
		const dateMonth = !forIncomingBalance
			? moment(dateSelector(i), BASE_DATE_FORMAT).endOf('month')
			: moment(dateSelector(i), BASE_DATE_FORMAT).startOf('month');
		const dateEndOfQuarter = moment(dateSelector(i), BASE_DATE_FORMAT).endOf('quarter');
		const dateEndOfYear = moment(dateSelector(i), BASE_DATE_FORMAT).endOf('year');

		if (dateMoment.isSame(dateMonth, 'day')) {
			columns.push({
				reduceBy: 'MONTH',
				date: dateMoment.format('MMMM YYYY'),
				name: dateMoment.format('MMMM'),
				income: incomeSelector ? incomeSelector(i) : amountSelector(i) >= 0,
				isEmpty: isEmptySelector ? isEmptySelector(i) : false,
				value:
					planningMode || plReportMode
						? amountSelector(i)
						: !dateMoment.isAfter(currentMonth, 'day')
						? amountSelector(i)
						: 0,
			});
			totalByQuarter +=
				planningMode || plReportMode
					? amountSelector(i)
					: !dateMoment.isAfter(currentMonth, 'day')
					? amountSelector(i)
					: 0;
		}

		if (dateMoment.isSame(dateEndOfQuarter, 'day')) {
			columns.push({
				reduceBy: 'QUARTER',
				date: dateMoment.format('Q YYYY'),
				name: 'Итого',
				income: incomeSelector ? incomeSelector(i) : totalByQuarter >= 0,
				isEmpty: false,
				value: totalByQuarter,
			});

			totalByYear += totalByQuarter;
			totalByQuarter = 0;
		}

		if (dateMoment.isSame(dateEndOfYear, 'day')) {
			columns.push({
				reduceBy: 'YEAR',
				date: dateMoment.format('YYYY'),
				name: 'Итого за год',
				income: incomeSelector ? incomeSelector(i) : totalByYear >= 0,
				isEmpty: false,
				value: totalByYear,
			});

			totalByYear = 0;
		}
	});

	if (forIncomingBalance) {
		let totalByFirstMonth = 0;
		let totalByMonth = 0;
		let cashGapIsExists = false;

		columns.forEach((el, index) => {
			const endOfCurQuarter = moment().endOf('quarter');
			const prevMonthValue =
				columns[index - 1] && columns[index - 1].reduceBy === 'MONTH'
					? columns[index - 1].value
					: columns[index - 1] && columns[index - 1].reduceBy === 'QUARTER'
					? columns[index - 2].value
					: columns[index - 1] && columns[index - 1].reduceBy === 'YEAR'
					? columns[index - 3].value
					: 0;

			el.isOutgoing = false;

			if (el.reduceBy === 'MONTH') {
				const dateMoment = moment(el.date, 'MMMM YYYY');
				const startOfQuarter = dateMoment.clone().startOf('quarter');
				const endOfQuarter = dateMoment.clone().endOf('quarter');
				const startOfYear = dateMoment.clone().startOf('year');
				const endOfYear = dateMoment.clone().endOf('year');

				if (dateMoment.isSame(startOfQuarter, 'month')) {
					totalByMonth = el.isEmpty ? findNextValue(columns, dateMoment, endOfQuarter) : el.value;
				}

				if (dateMoment.isSame(startOfYear, 'month')) {
					totalByFirstMonth = el.isEmpty ? findNextValue(columns, dateMoment, endOfYear) : el.value;
				}

				if (planningMode && prevMonthValue >= 0 && el.value < 0) {
					el.cashGap = true;

					cashGapIsExists = true;
				}
			}

			if (el.reduceBy === 'QUARTER') {
				if (planningMode || !moment(el.date, 'Q YYYY').isAfter(endOfCurQuarter, 'day')) {
					el.income = totalByMonth >= 0;
					el.value = totalByMonth;

					totalByMonth = 0;
				}

				if (cashGapIsExists) {
					el.cashGap = true;
					cashGapIsExists = false;
				}
			}

			if (el.reduceBy === 'YEAR') {
				el.income = totalByFirstMonth >= 0;
				el.value = totalByFirstMonth;

				totalByFirstMonth = 0;
			}
		});
	}

	if (forOutgoingBalance) {
		let totalByLastMonth = 0;
		let totalByLastQuarter = 0;
		let cashGapIsExists = false;

		columns.forEach((el, index) => {
			const endOfCurMonth = moment().endOf('month');
			const endOfCurQuarter = moment().endOf('quarter');
			const prevMonthValue =
				columns[index - 1] && columns[index - 1].reduceBy === 'MONTH'
					? columns[index - 1].value
					: columns[index - 1] && columns[index - 1].reduceBy === 'QUARTER'
					? columns[index - 2].value
					: columns[index - 1] && columns[index - 1].reduceBy === 'YEAR'
					? columns[index - 3].value
					: 0;

			el.isOutgoing = true;

			if (el.reduceBy === 'MONTH') {
				if (planningMode || !moment(el.date, 'MMMM YYYY').isAfter(endOfCurMonth, 'day')) {
					totalByLastMonth = el.value;
				}

				if (planningMode && prevMonthValue >= 0 && el.value < 0) {
					el.cashGap = true;
					cashGapIsExists = true;
				}
			}

			if (el.reduceBy === 'QUARTER') {
				if (planningMode || !moment(el.date, 'Q YYYY').isAfter(endOfCurQuarter, 'day')) {
					el.income = totalByLastMonth >= 0;
					el.value = totalByLastMonth;

					totalByLastQuarter = totalByLastMonth;
					totalByLastMonth = 0;
				}

				if (cashGapIsExists) {
					el.cashGap = true;
					cashGapIsExists = false;
				}
			}

			if (el.reduceBy === 'YEAR') {
				el.income = totalByLastQuarter >= 0;
				el.value = totalByLastQuarter;

				totalByLastQuarter = 0;
			}
		});
	}

	return columns;
}

function findNextValue(columns: Array<ReportTableColumn>, dateStart: Moment, dateEnd: Moment): number {
	const col = columns
		.filter(x => x.reduceBy === 'MONTH')
		.filter(x => {
			const date = moment(x.date, 'MMMM YYYY');

			return date.isAfter(dateStart, 'day') && date.isBefore(dateEnd, 'day');
		})
		.find(x => !x.isEmpty);

	if (col) return col.value;
	return 0;
}

function getNestedSeriesByTree(
	ID: number,
	aciSeriesMap: Record<string, PLOperationAccountsChartItemSeries>,
	dataMap: Record<string, ReportTableRow>,
	series: Array<PLOperationAccountsChartItemSeries> = [],
): Array<PLOperationAccountsChartItemSeries> {
	let list = [];
	const item = aciSeriesMap[ID];

	if (item && series.every(s => s.AccountsChartItemID !== item.AccountsChartItemID)) {
		list.push(aciSeriesMap[ID]);
	}

	if (dataMap[ID]) {
		for (let i = 0; i < dataMap[ID].childItemsIDList.length; i++) {
			const childID = dataMap[ID].childItemsIDList[i];

			list = [...list, ...getNestedSeriesByTree(childID, aciSeriesMap, dataMap, [...list, ...series])];
		}
	}

	return list;
}

export function buildReportTableColumnsWithTree(
	ID: number,
	aciSeriesMap: Record<string, PLOperationAccountsChartItemSeries>,
	dataMap: Record<string, ReportTableRow>,
	mixedDirection = false,
	planningMode = false,
	plReportMode = false,
): Array<ReportTableColumn> {
	let list = [];
	const series = getNestedSeriesByTree(ID, aciSeriesMap, dataMap);
	let points: Array<PLOperationTimeSeriesPoint> = [];

	if (series.length > 0) {
		for (let i = 0; i < series.length; i++) {
			points.push(...series[i].Points);
		}

		const grouppedPoints = _.groupBy(points, el => el.ValueDate);

		points = Object.keys(grouppedPoints).map(key => {
			const point = grouppedPoints[key][0];
			const total = grouppedPoints[key].reduce((acc, p) => {
				acc = mixedDirection ? (p.DirectionIncoming ? acc + p.Amount : acc - p.Amount) : acc + p.Amount;

				return acc;
			}, 0);

			return {
				...grouppedPoints[key][0],
				Amount: total,
				DirectionIncoming: mixedDirection ? total >= 0 : point.DirectionIncoming,
			};
		});
	}

	list = buildReportTableColumns({
		list: points,
		dateSelector: (p: PLOperationTimeSeriesPoint) => p.ValueDate,
		amountSelector: (p: PLOperationTimeSeriesPoint) => p.Amount,
		incomeSelector: !mixedDirection ? (p: PLOperationTimeSeriesPoint) => p.DirectionIncoming : undefined,
		planningMode: planningMode,
		plReportMode: plReportMode,
	});

	return list;
}

export function getEmptyReportData(dateStartMoment: Moment, dateEndMoment: Moment): Array<ReportTableColumn> {
	const diffInMonth = dateEndMoment.clone().add(1, 'day').diff(dateStartMoment, 'months');
	let columns = [];
	const dates = [];

	for (let i = 0; i < diffInMonth; i++) {
		const date = dates[i - 1]
			? moment(dates[i - 1].date, BASE_DATE_FORMAT)
					.add(1, 'month')
					.endOf('month')
					.format(BASE_DATE_FORMAT)
			: dateStartMoment.clone().endOf('month').format(BASE_DATE_FORMAT);

		dates.push({ date });
	}

	columns = buildReportTableColumns({
		list: dates,
		dateSelector: d => d.date,
		amountSelector: d => 0,
	});

	return columns;
}

export function buildReportTableColumnsForNetCashflow(params: {
	ID: number;
	IDList: Array<number>;
	dataMap: Record<string, ReportTableRow>;
	forOutgoingBalance?: boolean;
}): Array<ReportTableColumn> {
	const { ID, IDList, dataMap, forOutgoingBalance = false } = params;
	const columns: Array<ReportTableColumn> = [];
	const item = { ...dataMap[ID] };

	item.columns.forEach(el => {
		let total = 0;

		IDList.forEach(ID => {
			const itemSum = dataMap[ID];
			const col = itemSum && itemSum.columns.find(col => col.date === el.date);

			if (col) {
				const value = Math.abs(col.value);

				total = col.income ? total + value : total - value;
			}
		});

		columns.push({ ...el, income: total >= 0, value: total });
	});

	if (forOutgoingBalance) {
		let totalByLastMonth = 0;
		let totalByLastQuarter = 0;

		columns.forEach(el => {
			const endOfCurMonth = moment().endOf('month');
			const endOfCurQuarter = moment().endOf('quarter');

			if (el.reduceBy === 'MONTH') {
				if (!moment(el.date, 'MMMM YYYY').isAfter(endOfCurMonth, 'day')) {
					totalByLastMonth = el.value;
				}
			}

			if (el.reduceBy === 'QUARTER') {
				if (!moment(el.date, 'Q YYYY').isAfter(endOfCurQuarter, 'day')) {
					el.income = totalByLastMonth >= 0;
					el.value = totalByLastMonth;

					totalByLastQuarter = totalByLastMonth;
					totalByLastMonth = 0;
				}
			}

			if (el.reduceBy === 'YEAR') {
				el.income = totalByLastQuarter >= 0;
				el.value = totalByLastQuarter;

				totalByLastQuarter = 0;
			}
		});
	}

	return columns;
}

export function filterCashflowReportDataByText(substr: string, list: Array<ReportTableRow>) {
	if (substr) {
		let filteredList: Array<ReportTableRow> = [];
		const listMap = _.indexBy(list, el => el.ID);

		for (let i = 0; i < list.length; i++) {
			const item = list[i];

			if (item.name.toLocaleLowerCase().indexOf(substr.toLocaleLowerCase()) !== -1) {
				completeTree(item.ID, listMap, filteredList);
			}
		}

		filteredList = truncTree(substr, listMap, filteredList);

		filteredList.sort((a, b) => {
			const indexA = list.findIndex(el => el.ID === a.ID);
			const indexB = list.findIndex(el => el.ID === b.ID);

			return indexA > indexB ? 1 : indexA === indexB ? 0 : -1;
		});

		return filteredList;
	}

	return list;
}

function completeTree(ID: number, listMap: Record<string, ReportTableRow>, list: Array<ReportTableRow>) {
	const item = listMap[ID];

	if (item) {
		if (!list.some(el => el.ID === item.ID)) {
			list.push(item);
		}

		if (!list.some(el => el.ID === item.parentID)) {
			const parent = listMap[item.parentID];

			if (parent) {
				list.push(parent);

				completeTree(parent.ID, listMap, list);
			}
		}

		for (let i = 0; i < item.childItemsIDList.length; i++) {
			const childItemID = item.childItemsIDList[i];

			completeTree(childItemID, listMap, list);
		}
	}
}

function truncTree(substr: string, listMap: Record<string, ReportTableRow>, list: Array<ReportTableRow>) {
	let truncList = [...list];
	const IDListForRemove = [];

	for (let i = 0; i < truncList.length; i++) {
		const item = truncList[i];

		if (!isMatchDescendantNode(substr, item.ID, listMap) && !isMatchDescendantParent(substr, item.ID, listMap)) {
			IDListForRemove.push(item.ID);
		}
	}

	truncList = truncList.filter(el => !IDListForRemove.some(ID => ID === el.ID));

	return truncList;
}

function isMatchDescendantParent(substr: string, ID: number, listMap: Record<string, ReportTableRow>) {
	let isMatch = false;
	const parentID = listMap[ID].parentID;
	const item = listMap[parentID];

	if (item) {
		isMatch = item.name.toLocaleLowerCase().indexOf(substr.toLocaleLowerCase()) !== -1;

		if (isMatch) {
			return isMatch;
		}

		const parent = listMap[item.parentID];

		if (parent) {
			isMatch = parent.name.toLocaleLowerCase().indexOf(substr.toLocaleLowerCase()) !== -1;

			if (isMatch) {
				return isMatch;
			}

			isMatch = isMatchDescendantParent(substr, parent.ID, listMap);

			if (isMatch) {
				return isMatch;
			}
		}
	}

	return isMatch;
}

function isMatchDescendantNode(substr: string, ID: number, listMap: Record<string, ReportTableRow>) {
	let isMatch = false;
	const item = listMap[ID];

	if (item) {
		isMatch = item.name.toLocaleLowerCase().indexOf(substr.toLocaleLowerCase()) !== -1;

		if (isMatch) {
			return isMatch;
		}

		for (let i = 0; i < item.childItemsIDList.length; i++) {
			const childItem = listMap[item.childItemsIDList[i]];

			if (childItem) {
				isMatch = childItem.name.toLocaleLowerCase().indexOf(substr.toLocaleLowerCase()) !== -1;

				if (isMatch) {
					return isMatch;
				}

				isMatch = isMatchDescendantNode(substr, childItem.ID, listMap);

				if (isMatch) {
					return isMatch;
				}
			}
		}
	}

	return isMatch;
}

function checkRowIDForDetalization(cell: ReportTableCell) {
	return cell && cell.row && cell.row.ID !== -2 && cell.row.ID !== -18;
}

function completeTreeWithChildItems(ID: number, listMap: Record<string, ReportTableRow>, list: Array<ReportTableRow>) {
	const item = listMap[ID];

	if (item) {
		if (!list.some(el => el.ID === item.ID)) {
			list.push(item);
		}

		for (let i = 0; i < item.childItemsIDList.length; i++) {
			const childItemID = item.childItemsIDList[i];

			completeTreeWithChildItems(childItemID, listMap, list);
		}
	}
}

export function filterCashflowReportDataByCell(params: {
	cell: ReportTableCell;
	listMap: Record<string, ReportTableRow>;
	list: Array<ReportTableRow>;
	checkRowIDForDetalization: (cell: ReportTableCell) => boolean;
}) {
	const { cell, listMap, list, checkRowIDForDetalization } = params;

	if (checkRowIDForDetalization(cell)) {
		const filteredList = [];

		completeTreeWithChildItems(cell.row.ID, listMap, filteredList);

		return filteredList;
	}

	return list;
}

export function filterPLOperationsByCell(
	forPLReport: boolean,
	plList: Array<PLOperationBrief>,
	rowID: number,
	ciMap: Record<string, CashflowItem>,
	col: ReportTableColumn,
	incomeSelector?: (pl) => boolean,
	cashflowTypeID?: number,
	all?: boolean,
) {
	const cashflowItem = ciMap[rowID];
	const filteredList = plList.filter(pl => {
		const dateFormat = col.reduceBy === 'MONTH' ? 'MMMM YYYY' : col.reduceBy === 'QUARTER' ? 'Q YYYY' : 'YYYY';

		const periodDate = moment(col.date, dateFormat).format(dateFormat);
		const plDate = moment(!forPLReport ? pl.CashflowDate : pl.AccrualDate, BASE_DATE_FORMAT).format(dateFormat);
		const descendantNodeIDList = cashflowItem ? getDescendantCashflowNodeIDList(cashflowItem.ID, ciMap) : [];

		const equalByCashflowItem = !cashflowTypeID
			? cashflowItem
				? pl.AccountsChartItem &&
				  (pl.AccountsChartItem.ID === cashflowItem.ID ||
						descendantNodeIDList.some(ID => ID === pl.AccountsChartItem.ID))
				: !pl.AccountsChartItem || pl.AccountsChartItem.ID === -1
			: pl.AccountsChartItem && ciMap[pl.AccountsChartItem.ID].CashflowTypeID === cashflowTypeID;

		return periodDate === plDate && (!all ? equalByCashflowItem : true) && (incomeSelector ? incomeSelector(pl) : true);
	});

	return filteredList;
}

const selectRowsForCashflowReportTable: (r) => (s) => Array<ReportTableRow> = (forPLReport: boolean) =>
	createSelector(
		mainReportingSelectorsPack.selectDateRange,
		mainReportingSelectorsPack.selectAsyncAciSeriesMap.selectItem,
		mainReportingSelectorsPack.selectAsyncCashBalanceRecords.selectItem,
		mainCashflowItemsSelectorsPack.selectCashflowItemsMap,
		mainReportingSelectorsPack.selectTextFilter,
		mainReportingSelectorsPack.selectSelectedReportTableCell,
		mainReportingSelectorsPack.selectProjectFilter,
		(period, aciSeriesMap, cashBalanceList, ciMap, substr, reportTableCell, projectFilter) => {
			const data = buildCashflowReportTreeList(forPLReport, ciMap);
			const dataMap: Record<string, ReportTableRow> = _.indexBy(data, d => d.ID);
			const dateStartMoment = moment(period.dateStart, BASE_DATE_FORMAT).startOf('quarter');
			const dateEndMoment = moment(period.dateEnd, BASE_DATE_FORMAT).endOf('quarter');
			const completedBalanceList = getCompletedBalanceList(dateStartMoment, dateEndMoment, cashBalanceList);
			const emptyData = getEmptyReportData(dateStartMoment, dateEndMoment);
			const immutableAciSeriesMap = { ...aciSeriesMap };
			const hasProjectFilter = projectFilter > 0;

			if (immutableAciSeriesMap[-1]) {
				immutableAciSeriesMap[-2] = { ...immutableAciSeriesMap[-1] };
				immutableAciSeriesMap[-3] = { ...immutableAciSeriesMap[-1] };
				immutableAciSeriesMap[-2].Points = immutableAciSeriesMap[-2].Points.filter(p => p.DirectionIncoming);
				immutableAciSeriesMap[-3].Points = immutableAciSeriesMap[-3].Points.filter(p => !p.DirectionIncoming);
			}

			const completedAciSeriesMap = getCompletedAciSeriesMap({
				dateStartMoment,
				dateEndMoment,
				aciSeriesMap: immutableAciSeriesMap,
				ciMap,
				undistributedIncomeKey: '-2',
			});

			for (let i = 0; i < data.length; i++) {
				const d = data[i];
				d.columns = emptyData;

				if (d.ID === -2 && !forPLReport && !hasProjectFilter) {
					const columns = buildReportTableColumns({
						list: completedBalanceList,
						dateSelector: (b: CashBalanceRecord) => b.ValueDate,
						isEmptySelector: (b: CashBalanceRecord) => b.IncomingBalance === null,
						amountSelector: (b: CashBalanceRecord) => b.IncomingBalance || 0,
						forIncomingBalance: true,
					});

					d.columns = columns.length > 0 ? columns : emptyData;
				}

				if (d.ID === -3) {
					const points = completedAciSeriesMap[-2] ? [...completedAciSeriesMap[-2].Points] : [];

					const columns = buildReportTableColumns({
						list: points,
						dateSelector: (p: PLOperationTimeSeriesPoint) => p.ValueDate,
						amountSelector: (p: PLOperationTimeSeriesPoint) => p.Amount,
						incomeSelector: (p: PLOperationTimeSeriesPoint) => true,
						plReportMode: forPLReport,
					});

					d.columns = columns.length > 0 ? columns : emptyData;
				}

				if (d.ID === -4) {
					const points = completedAciSeriesMap[-3] ? [...completedAciSeriesMap[-3].Points] : [];

					const columns = buildReportTableColumns({
						list: points,
						dateSelector: (p: PLOperationTimeSeriesPoint) => p.ValueDate,
						amountSelector: (p: PLOperationTimeSeriesPoint) => p.Amount,
						incomeSelector: (p: PLOperationTimeSeriesPoint) => false,
						plReportMode: forPLReport,
					});

					d.columns = columns.length > 0 ? columns : emptyData;
				}

				if (d.ID === -5 || d.ID === -9 || d.ID === -13) {
					const columns = buildReportTableColumnsWithTree(
						d.ID,
						completedAciSeriesMap,
						dataMap,
						true,
						false,
						forPLReport,
					);

					d.columns = columns.length > 0 ? columns : emptyData;
				}

				if (d.ID === -6 || d.ID === -7 || d.ID === -10 || d.ID === -11 || d.ID === -14 || d.ID === -15 || d.ID > 0) {
					const columns = buildReportTableColumnsWithTree(
						d.ID,
						completedAciSeriesMap,
						dataMap,
						false,
						false,
						forPLReport,
					);

					d.columns = columns.length > 0 ? columns : emptyData;
				}

				if (d.ID === -8) {
					const columns = buildReportTableColumnsForNetCashflow({
						ID: d.ID,
						IDList: [-6, -7],
						dataMap,
					});

					d.columns = columns.length > 0 ? columns : emptyData;
				}

				if (d.ID === -12) {
					const columns = buildReportTableColumnsForNetCashflow({
						ID: d.ID,
						IDList: [-10, -11],
						dataMap,
					});

					d.columns = columns.length > 0 ? columns : emptyData;
				}

				if (d.ID === -16) {
					const columns = buildReportTableColumnsForNetCashflow({
						ID: d.ID,
						IDList: [-14, -15],
						dataMap,
					});

					d.columns = columns.length > 0 ? columns : emptyData;
				}

				if (d.ID === -17) {
					const columns = buildReportTableColumnsForNetCashflow({
						ID: d.ID,
						IDList: [-3, -4, -8, -12, -16],
						dataMap,
					});

					d.columns = columns.length > 0 ? columns : emptyData;
				}

				if (d.ID === -18 && !forPLReport && !hasProjectFilter) {
					const columns = buildReportTableColumns({
						list: completedBalanceList,
						dateSelector: (b: CashBalanceRecord) => b.ValueDate,
						isEmptySelector: (b: CashBalanceRecord) => b.OutgoingBalance === null,
						amountSelector: (b: CashBalanceRecord) => b.OutgoingBalance || 0,
						forOutgoingBalance: true,
					});

					const debugIncomingBalanceColumns = buildReportTableColumnsForNetCashflow({
						ID: d.ID,
						IDList: [-2],
						dataMap,
					});

					const debugTotalIncomeColumns = buildReportTableColumnsForNetCashflow({
						ID: d.ID,
						IDList: [-3, -6, -10, -14],
						dataMap,
					});

					const debugTotalExpenseColumns = buildReportTableColumnsForNetCashflow({
						ID: d.ID,
						IDList: [-4, -7, -11, -15],
						dataMap,
					});

					const debugOutgoingBalanceColumns = buildReportTableColumnsForNetCashflow({
						ID: d.ID,
						IDList: [-2, -17],
						dataMap,
						forOutgoingBalance: true,
					});

					columns.forEach((col, index) => {
						col.debugValue = {
							incomingBalance: debugIncomingBalanceColumns[index].value,
							totalIncome: debugTotalIncomeColumns[index].value,
							totalExpense: debugTotalExpenseColumns[index].value,
							outgoingBalance: debugOutgoingBalanceColumns[index].value,
						};
					});

					d.columns = columns.length > 0 ? columns : emptyData;
				}
			}

			const filteredData = compose(
				(data: Array<ReportTableRow>) => filterCashflowReportDataByText(substr, data),
				(data: Array<ReportTableRow>) =>
					filterCashflowReportDataByCell({
						cell: reportTableCell,
						listMap: dataMap,
						list: data,
						checkRowIDForDetalization,
					}),
				(data: Array<ReportTableRow>) => (hasProjectFilter ? data.filter(x => ![-2, -18].includes(x.ID)) : data),
			)(data);

			return filteredData;
		},
	);

const selectHeaderForCashflowReportTable: (s) => Array<Partial<ReportTableColumn>> = createSelector(
	mainReportingSelectorsPack.selectDateRange,
	(period: DateRange) => {
		const columns = [];
		const dateStartMoment = moment(period.dateStart, BASE_DATE_FORMAT).startOf('quarter');
		const dateEndMoment = moment(period.dateEnd, BASE_DATE_FORMAT).endOf('quarter');
		const diffInMonths = dateEndMoment.clone().add(1, 'day').diff(dateStartMoment, 'months');
		const dates = [];

		for (let i = 0; i < diffInMonths; i++) {
			let date = null;

			if (dates[i - 1]) {
				date = moment(dates[i - 1], BASE_DATE_FORMAT)
					.add(1, 'month')
					.endOf('month')
					.format(BASE_DATE_FORMAT);
			} else {
				date = dateStartMoment.endOf('month').format(BASE_DATE_FORMAT);
			}

			dates.push(date);
		}

		for (let i = 0; i < dates.length; i++) {
			const dateMoment = moment(dates[i], BASE_DATE_FORMAT);
			const dateEndOfMonth = dateMoment.clone().endOf('month');
			const dateEndOfQuarter = dateMoment.clone().endOf('quarter');
			const dateEndOfYear = dateMoment.clone().endOf('year');

			if (dateMoment.isSame(dateEndOfMonth, 'day')) {
				columns.push({
					reduceBy: 'MONTH',
					date: dateMoment.format('MMMM YYYY'),
					name: dateMoment.format('MMMM'),
				});
			}

			if (dateMoment.isSame(dateEndOfQuarter, 'day')) {
				columns.push({
					reduceBy: 'QUARTER',
					date: dateMoment.format('Q YYYY'),
					name: 'Итого',
				});
			}

			if (dateMoment.isSame(dateEndOfYear, 'day')) {
				columns.push({
					reduceBy: 'YEAR',
					date: dateMoment.format('YYYY'),
					name: 'Итого за год',
				});
			}
		}

		return columns;
	},
);

const selectPLOperationsListForReporting = (forPLReport: boolean) =>
	createSelector(mainReportingSelectorsPack.selectAsyncPlOperations.selectItem, (plList = []) => {
		const sortedList = sortDescBy(plList, [
			{ fn: item => (!forPLReport ? item.CashflowDate : item.AccrualDate), isDate: true },
			{ fn: item => item.ID },
		]);

		return sortedList;
	});

export {
	reportUtils,
	checkRowIDForDetalization,
	selectRowsForCashflowReportTable,
	selectHeaderForCashflowReportTable,
	selectPLOperationsListForReporting,
};
