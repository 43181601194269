import { RECEIVE, createAsyncAction, createStaticAction } from '@flux';
import { LegalEntityForm } from '@tenant-legal-entities/models';
import { mainTenantLegalEntitiesSelectorsPack } from '@tenant-legal-entities/selectors';
import { createNotificationMessage } from '@utils/notifications';
import { types } from './types';

const actions = {
	addTenantLegalEntity: createAsyncAction(
		types.ADD_TENANT_LEGAL_ENTITY,
		(api, _, __, legalEntity: LegalEntity) => api.counterpartyPack.counterparty.addCounterparty(legalEntity),
		{
			showMessage: type => type === RECEIVE && createNotificationMessage('Организация добавлена 😊', 'success'),
		},
	) as (legalEntity: LegalEntity) => void,
	fetchTenantLegalEntities: createAsyncAction(
		types.FETCH_TENANT_LEGAL_ENTITIES,
		api => api.counterpartyPack.counterparty.fetchTenantLegalEntities(),
		{
			isValidSelector: mainTenantLegalEntitiesSelectorsPack.selectAsyncTenantLegalEntities.selectIsValid,
			isFetchingSelector: mainTenantLegalEntitiesSelectorsPack.selectAsyncTenantLegalEntities.selectIsFetching,
		},
	) as () => void,
	removeTenantLegalEntity: createAsyncAction(
		types.REMOVE_TENANT_LEGAL_ENTITY,
		(api, _, __, ID: number) => api.counterpartyPack.counterparty.removeCounterparty(ID),
		{
			showMessage: type => type === RECEIVE && createNotificationMessage('Организация удалена 😲', 'success'),
		},
	) as (ID: number) => void,
	resetFilters: () => createStaticAction(types.RESET_FILTERS)(),
	resetTenantLegalEntities: () => createStaticAction(types.RESET_TENANT_LEGAL_ENTITIES)(),
	setFilterByLegalEntityForm: (value: Record<LegalEntityForm, boolean>) =>
		createStaticAction(types.SET_FILTER_BY_LEGAL_ENTITY_FORM)(value),
	setFilterByText: (value: string) => createStaticAction(types.SET_FILTER_BY_TEXT)(value),
	transformTenantLegalEntityToCounterparty: createAsyncAction(
		types.TRANSFORM_TENANT_LEGAL_ENTITY_TO_COUNTERPARTY,
		(api, _, __, ID: number) => api.counterpartyPack.counterparty.transformTenantLegalEntityToCounterparty(ID),
		{
			showMessage: type =>
				type === RECEIVE &&
				createNotificationMessage('Собственная организация преобразована в контрагента 😊', 'success'),
		},
	) as (ID: number) => void,
	updateTenantLegalEntity: createAsyncAction(
		types.UPDATE_TENANT_LEGAL_ENTITY,
		(api, _, __, legalEntity: LegalEntity) => api.counterpartyPack.counterparty.updateCounterparty(legalEntity),
		{
			showMessage: type => type === RECEIVE && createNotificationMessage('Организация изменена 😊', 'success'),
		},
	) as (legalEntity: LegalEntity) => void,
};

const mainTenantLegalEntitiesActionsPack = actions;

export { mainTenantLegalEntitiesActionsPack };
