import { safeNumber } from '@utils/numbers';

function getSum<T>(values: Array<T>, getValue: (item: T) => number = (x => x) as (x) => number) {
	return safeNumber(values.reduce((acc, x) => (acc += getValue(x)), 0));
}

function getResult(income: number, expense: number) {
	return safeNumber(income - expense);
}

function getProfitability(income: number, expense: number) {
	const result = getResult(income, expense);

	if (income === 0 && expense > 0) return -100;
	if (income === 0 && expense === 0) return 0;

	return safeNumber((result / income) * 100);
}

function getFundsRegistersAccessFlags(sourceFundsRegisters: Array<FundsRegister> = []) {
	const fundsRegisters = sourceFundsRegisters.filter(Boolean);
	const WRITE_ACCESS_LEVEL = 2;
	const READONLY_ACCESS_LEVEL = 1;
	const readAccessLevel = [WRITE_ACCESS_LEVEL, READONLY_ACCESS_LEVEL];
	const hasWriteAccessToIncome = fundsRegisters.every(
		x => x.CurrentEmployeeAccess?.ReceiptAccessLevel === WRITE_ACCESS_LEVEL,
	);
	const hasWriteAccessToExpense = fundsRegisters.every(
		x => x.CurrentEmployeeAccess?.ChargeAccessLevel === WRITE_ACCESS_LEVEL,
	);
	const hasWriteAccessToAllDirections = hasWriteAccessToIncome && hasWriteAccessToExpense;
	const hasReadAccessToIncome = fundsRegisters.every(x =>
		readAccessLevel.includes(x.CurrentEmployeeAccess?.ReceiptAccessLevel),
	);
	const hasReadAccessToExpense = fundsRegisters.every(x =>
		readAccessLevel.includes(x.CurrentEmployeeAccess?.ChargeAccessLevel),
	);
	const hasReadAccessToAllDirections = hasReadAccessToIncome && hasReadAccessToExpense;

	return {
		hasWriteAccessToIncome,
		hasWriteAccessToExpense,
		hasWriteAccessToAllDirections,
		hasReadAccessToIncome,
		hasReadAccessToExpense,
		hasReadAccessToAllDirections,
	};
}

function detectIsBankAccountFundsRegister(source: FundsRegister | FundsRegisterBrief): boolean {
	const fundsRegister = source as FundsRegister;
	const fundsRegisterBrief = source as FundsRegisterBrief;

	if (!source) return false;
	if (fundsRegister.CLASSIFIER === 'BANK_ACCOUNT_FUNDS_REGISTER') return true;
	if (fundsRegisterBrief?.FundsRegisterType?.Code === 'FUNDS_REGISTER_TYPE_BANK_ACCOUNT') return true;
	return false;
}

function detectIsVirtualAccountFundsRegister(source: FundsRegister | FundsRegisterBrief): boolean {
	const fundsRegister = source as FundsRegister;
	const fundsRegisterBrief = source as FundsRegisterBrief;

	if (!source) return false;
	if (fundsRegister.CLASSIFIER === 'CASH_DESK_FUNDS_REGISTER') return true;
	if (fundsRegisterBrief?.FundsRegisterType?.Code === 'FUNDS_REGISTER_TYPE_CASH_DESK') return true;
	return false;
}

function getVatValueByCurrencyAmount(currencyAmount: string | number, vatPercent: number) {
	return safeNumber((Number(currencyAmount) / (1 + vatPercent / 100)) * (vatPercent / 100));
}

function getAmountWithTax(amount: number, taxRate: number) {
	if (taxRate <= 0) return amount;
	const value = safeNumber(amount + amount * taxRate);

	return value;
}

export {
	detectIsBankAccountFundsRegister,
	detectIsVirtualAccountFundsRegister,
	getAmountWithTax,
	getFundsRegistersAccessFlags,
	getProfitability,
	getResult,
	getSum,
	getVatValueByCurrencyAmount,
};
