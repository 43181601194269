import { getDrawer } from '@utils';
import type { DrawerState } from '@ui/drawer';
import { detectIsFunction } from '@utils/helpers';

type ScheduleNextDrawerTransitionOptions = {
	timeout?: number;
	getDrawerState?: () => DrawerState;
	executeFn?: () => void;
};

function scheduleNextDrawerTransition(options: ScheduleNextDrawerTransitionOptions) {
	const { timeout = 600, getDrawerState, executeFn } = options;

	getDrawer().close();
	setTimeout(() => {
		if (detectIsFunction(getDrawerState)) {
			getDrawer().open({
				...getDrawerState(),
			});
		}

		if (detectIsFunction(executeFn)) {
			executeFn();
		}
	}, timeout);
}

export { getDrawer, scheduleNextDrawerTransition };
