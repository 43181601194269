import React, { memo, useState, useMemo } from 'react';

import { useMounted } from '@core/hooks/use-mounted';
import { accountingApi } from '@core/api/accounting';
import { useMapState, useMapDispatch } from '@flux';
import { useFormContext } from '@ui/forms';
import { emitActionMessage as emitActionMessageAction } from '@shared/actions/action-message.actions';
import { securitySelectorsPack } from '@platform/selectors';
import { runCashflowItemsInvalidationEffect } from '@cashflow-items/actions/invalidators';
import { mainCashflowItemsSelectorsPack } from '@cashflow-items/selectors';
import {
	CashflowItemAutopickerCRUD as XCashflowItemAutopickerCRUD,
	CashflowItemAutopickerCrudProps as XCashflowItemAutopickerCrudProps,
} from './cashflow-item-autopicker-crud.view';

export type CashflowItemAutopickerCrudProps = {
	cashflowItemID?: number;
	cashflowItemName?: string;
	formName?: string;
	searchText?: string;
	isIncome?: boolean;
	transformer?: (value: CashflowItem) => any;
} & Pick<XCashflowItemAutopickerCrudProps, 'variant' | 'isSmallContainer' | 'onRelatedPopupChange'> &
	Partial<Pick<XCashflowItemAutopickerCrudProps, 'disabled'>>;

const CashflowItemAutopickerCRUD: React.FC<CashflowItemAutopickerCrudProps> = memo(props => {
	const { variant, formName, searchText, cashflowItemID, cashflowItemName, isIncome, transformer, disabled, ...rest } =
		props;
	const { mounted } = useMounted();
	const formContext = useFormContext<unknown>();
	const [isFetching, setIsFetching] = useState(false);
	const [initialFormObject, setInitialFormObject] = useState<CashflowItem>(createInitialFormObject({}));
	const [canChangeAnyData, cashflowItemsMap] = useMapState([
		securitySelectorsPack.selectCanChangeAnyData,
		mainCashflowItemsSelectorsPack.selectCashflowItemsMap,
	]);
	const [invalidate, emitActionMessage] = useMapDispatch([runCashflowItemsInvalidationEffect, emitActionMessageAction]);
	const scope = useMemo(() => ({ isAdd: true }), []);
	const codeValidator = useMemo(() => {
		const fn = ({ formObject }: { formObject: CashflowItem }) => {
			const code = formObject.Code;
			if (!code) return true;
			const isValid = Object.keys(cashflowItemsMap)
				.map(key => cashflowItemsMap[key])
				.every(x => x.ID === formObject.ID || x.Code !== code);

			return isValid;
		};

		return {
			fn,
			text: 'Код статьи должен быть уникальным',
		};
	}, [cashflowItemsMap]);

	const handleAddPopupOpen = () => {
		scope.isAdd = true;
		setIsFetching(false);
		setInitialFormObject(createInitialFormObject({ name: searchText, isIncome }));
	};

	const handleNestedAddPopupOpen = () => {
		scope.isAdd = true;
		setIsFetching(false);
		setInitialFormObject(
			createInitialFormObject({
				isIncome: cashflowItemsMap[cashflowItemID]?.Incoming,
				parentID: cashflowItemID,
				cashflowTypeID: cashflowItemsMap[cashflowItemID]?.CashflowTypeID || 1,
			}),
		);
	};

	const handleEditPopupOpen = async () => {
		scope.isAdd = false;

		if (cashflowItemID < 0) return;
		setIsFetching(true);

		const cashflowItem = await accountingApi.accountsChart.fetchCashflowItemByID(cashflowItemID);

		if (!mounted() || scope.isAdd) return;
		setInitialFormObject(cashflowItem);
		setIsFetching(false);
	};

	const handleRemovePopupOpen = () => {
		scope.isAdd = false;
	};

	const injectToParentForm = (cashflowItem: CashflowItem) => {
		const parentFormObject = formContext?.formObject;

		if (formName && parentFormObject) {
			parentFormObject[formName] = cashflowItem ? transformer(cashflowItem) : null;
			formContext.handleObjectChange(parentFormObject);
		}
	};

	const handleRequestAddCashflowItem = async (cashflowItem: CashflowItem) => {
		const addedCashflowItem = (await accountingApi.accountsChart.addCashflowItem(cashflowItem)) as CashflowItem;

		invalidate();
		emitActionMessage('Статья добавлена 🙃', 'success');
		injectToParentForm(addedCashflowItem);
	};

	const handleRequestEditCashflowItem = async (cashflowItem: CashflowItem) => {
		const updatedCashflowItem = (await accountingApi.accountsChart.updateCashflowItem(cashflowItem)) as CashflowItem;

		invalidate();
		emitActionMessage('Статья изменена 😊', 'success');
		injectToParentForm(updatedCashflowItem);
	};

	const handleRequestRemoveCashflowItem = async (cashflowItemID: number) => {
		await accountingApi.accountsChart.removeCashflowItem(cashflowItemID);

		invalidate();
		emitActionMessage('Статья удалена 😲', 'success');
		injectToParentForm(null);
	};

	return (
		<XCashflowItemAutopickerCRUD
			{...rest}
			isFetching={isFetching}
			variant={variant}
			cashflowItemID={cashflowItemID}
			cashflowItemName={cashflowItemName}
			disabled={disabled || !canChangeAnyData}
			initialFormObject={initialFormObject}
			codeValidator={codeValidator}
			cashflowItemsMap={cashflowItemsMap}
			onAddPopupOpen={handleAddPopupOpen}
			onEditPopupOpen={handleEditPopupOpen}
			onRemovePopupOpen={handleRemovePopupOpen}
			onNestedAddPopupOpen={handleNestedAddPopupOpen}
			onRequestAddCashflowItem={handleRequestAddCashflowItem}
			onRequestEditCashflowItem={handleRequestEditCashflowItem}
			onRequestRemoveCashflowItem={handleRequestRemoveCashflowItem}
		/>
	);
});

CashflowItemAutopickerCRUD.defaultProps = {
	transformer: x => x,
};

type CreateInitialFormObjectOptions = {
	name?: string;
	isIncome?: boolean;
	parentID?: number;
	cashflowTypeID?: number;
};

function createInitialFormObject(options: CreateInitialFormObjectOptions) {
	const { name = '', isIncome, parentID = -1, cashflowTypeID } = options;

	const cashflowItem: CashflowItem = {
		...new accountingApi.package.CashflowItem(),
		Name: name,
		Incoming: typeof isIncome === 'boolean' ? isIncome : true,
		ParentID: parentID,
		CashflowTypeID: cashflowTypeID,
		TenantSpecific: true,
		InCFConsolidation: true,
		InPLConsolidation: true,
		InProjectConsolidation: true,
		VisibleInDashboard: true,
	};

	return cashflowItem;
}

export { CashflowItemAutopickerCRUD };
