import React, { forwardRef, useRef, useImperativeHandle } from 'react';

import { detectIsFunction } from '@utils/helpers';
import { withSoftFetching } from '@core/hocs';
import { Box } from '@ui/box';
import { RaisedButton } from '@ui/raised-button';
import { IconButton } from '@ui/icon-button';
import { AddCircleIcon } from '@ui/icons/add-circle';
import { EditIcon } from '@ui/icons/edit';
import { DeleteIcon } from '@ui/icons/delete';
import { CloseButton } from '@ui/close-button';
import { Spacer } from '@ui/spacer';
import { FastContent, FastContentRef } from '@ui/fast-content';
import { PopupContentRoot } from './styled';

export type AutopickerCrudProps = {
	appearance: 'add' | 'edit' | 'remove' | 'nested';
	triggerLabel?: string;
	isFetching?: boolean;
	disabled?: boolean;
	autoFocus?: boolean;
	isSmallContainer?: boolean;
	containerWidth?: number;
	onRelatedPopupChange?: (isOpen: boolean) => void;
	onPopupOpen?: () => void;
	onRequestAction?: () => void;
	children: React.ReactNode;
};

const AutopickerCRUD = forwardRef<AutopickerCrudRef, AutopickerCrudProps>((props, ref) => {
	const {
		appearance,
		triggerLabel = 'Добавить',
		isFetching,
		disabled,
		autoFocus,
		isSmallContainer,
		onRelatedPopupChange,
		onRequestAction,
		onPopupOpen,
		children,
	} = props;
	const fastContentRef = useRef<FastContentRef>(null);

	useImperativeHandle(ref, () => ({
		closePopup: () => handleClosePopup(),
	}));

	const handleOpenPopup = (e: React.MouseEvent) => {
		detectIsFunction(onPopupOpen) && onPopupOpen();
		fastContentRef.current?.open();
		onRelatedPopupChange(true);
	};

	const handleClosePopup = () => {
		fastContentRef.current?.close();
		setTimeout(() => {
			onRelatedPopupChange(false);
		});
	};

	const handleCloseFastContent = () => {
		setTimeout(() => {
			onRelatedPopupChange(false);
		});
	};

	const triggerMap = {
		add: () => {
			return (
				<RaisedButton
					appearance='contained'
					title={triggerLabel}
					color='primary'
					size='small'
					disabled={disabled}
					fullWidth
					onClick={handleOpenPopup}>
					<AddCircleIcon color='light' />
					<span>{isSmallContainer ? 'Добавить' : triggerLabel}</span>
				</RaisedButton>
			);
		},
		nested: () => {
			if (disabled) return null;
			return (
				<IconButton variant='rounded' title={triggerLabel} stopPropagation onClick={handleOpenPopup}>
					<AddCircleIcon color='muted' />
				</IconButton>
			);
		},
		edit: () => {
			if (disabled) return null;
			return (
				<IconButton variant='rounded' title='Изменить' stopPropagation onClick={handleOpenPopup}>
					<EditIcon color='muted' />
				</IconButton>
			);
		},
		remove: () => {
			if (disabled) return null;
			return (
				<IconButton variant='rounded' title='Удалить' stopPropagation onClick={handleOpenPopup}>
					<DeleteIcon color='muted' />
				</IconButton>
			);
		},
	};
	const actionLabelMap = {
		add: 'Добавить',
		edit: 'Изменить',
		remove: 'Удалить',
		nested: 'Добавить',
	};
	const minHeightMap = {
		add: 300,
		edit: 300,
		remove: 100,
		nested: 300,
	};

	return (
		<FastContent
			ref={fastContentRef}
			autoFocus={autoFocus}
			renderTrigger={triggerMap[appearance]}
			disableOnRequestClose
			onClose={handleCloseFastContent}>
			<SoftFetchingPopupContent
				isFetching={isFetching}
				actionLabel={actionLabelMap[appearance]}
				minHeight={minHeightMap[appearance]}
				onRequestAction={onRequestAction}
				onRequestClose={handleClosePopup}>
				{children}
			</SoftFetchingPopupContent>
		</FastContent>
	);
});

AutopickerCRUD.defaultProps = {
	onRelatedPopupChange: () => {},
};

export type AutopickerCrudRef = {
	closePopup: () => void;
};

type PopupContentProps = {
	isFetching: boolean;
	actionLabel: string;
	minHeight: number;
	children: React.ReactNode;
	onRequestAction?: (e: React.MouseEvent) => void;
	onRequestClose: (e: React.MouseEvent) => void;
};

const PopupContent: React.FC<PopupContentProps> = forwardRef((props, ref) => {
	const { isFetching, actionLabel, minHeight, children, onRequestAction, onRequestClose } = props;

	return (
		<PopupContentRoot minHeight={minHeight}>
			<Box>
				<Box position='relative'>
					<Box position='absolute' top={-20} right={-20}>
						<CloseButton size='small' onClick={onRequestClose} />
					</Box>
				</Box>
				<Box marginBottom={10}>{children}</Box>
			</Box>
			{!isFetching && detectIsFunction(onRequestAction) && (
				<Box display='flex' alignItems='center' justifyContent='flex-end'>
					<RaisedButton appearance='text' size='small' color='primary' onClick={onRequestAction}>
						{actionLabel}
					</RaisedButton>
					<Spacer size={10} />
					<RaisedButton appearance='text' size='small' onClick={onRequestClose}>
						Отмена
					</RaisedButton>
				</Box>
			)}
		</PopupContentRoot>
	);
});

const SoftFetchingPopupContent = withSoftFetching<PopupContentProps>({
	enableCover: true,
	enableSpinner: true,
	forcePaint: true,
	style: {
		width: '100%',
	},
})(PopupContent);

export { AutopickerCRUD };
