import styled, { css } from 'styled-components';

import { textClip } from '@ui/basic-theme/styled-utils';

const Root = styled.div`
	position: relative;
`;

const SelectDateLayout = styled.div`
	position: fixed;
	width: 100%;
	height: auto;
	z-index: 10;
`;

const ContentLayout = styled.div`
	position: relative;
	top: 50px;
	width: 100%;
	height: auto;
	min-height: calc(100vh - 150px);
`;

const RegistersLayout = styled.div`
	width: 100%;
	height: auto;
`;

const Register = styled.div`
	width: 100%;
	height: auto;
	min-height: 178px;
	padding: 20px 40px;
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	align-items: flex-start;
`;

const RegisterNameLayout = styled.div`
	width: 100%;
	position: relative;
	margin-bottom: 11px;
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 10px;

	${p => css`
		&:before {
			content: '';
			position: relative;
			width: 9px;
			height: 9px;
			background-color: ${p.theme.palette.accent};
			border-radius: 50%;
			margin-right: 10px;
		}
	`}
`;

const RegisterName = styled.div`
	max-width: calc(100% - 40px);
	font-size: 16px;
	font-weight: 500;
	line-height: 1.25;
	${textClip()}
`;

const RegisterEntityLayout = styled.div`
	width: 100%;
	height: auto;
	margin-bottom: 12px;
`;

const RegisterEntity = styled.div`
	width: 100%;
	height: auto;
	max-width: 100%;
	min-height: 23px;
	font-size: 12px;
	font-weight: 500;
	line-height: 1.25;
	border-radius: 2px;
	padding: 4px 10px;
	${textClip()}

	${p => css`
		color: ${p.theme.palette.label};
		background-color: ${p.theme.palette.stealth};
	`}
`;

const RegisterNumber = styled.div`
	font-size: 14px;
	margin-bottom: 15px;

	${p => css`
		color: ${p.theme.palette.label};
	`}
`;

const ControlsLayout = styled.div`
	width: 100%;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 12px;
`;

const Indicator = styled.div`
	font-size: 14px;
	line-height: 1.43;
`;

const Divider = styled.div`
	width: 100%;
	height: 1px;

	${p => css`
		background-color: ${p.theme.palette.stealth};
	`}
`;

const WrapLayout = styled.div`
	position: relative;
`;

export {
	Root,
	SelectDateLayout,
	ContentLayout,
	RegistersLayout,
	Register,
	RegisterNameLayout,
	RegisterName,
	RegisterEntityLayout,
	RegisterEntity,
	RegisterNumber,
	ControlsLayout,
	Indicator,
	Divider,
	WrapLayout,
};
