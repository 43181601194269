import axios from 'axios';

import { Cookie, getFormData, isLocalhost } from '@utils';

type SpyAction = {
	title: string;
	description: string;
	payload?: {
		targetStateCode?: string;
		tenantID?: number;
		userID?: number;
		subsystemInstanceGUID?: string;
		error?: boolean;
		details?: string;
	};
};

const API_ENDPOINT = `${location.origin}/atr-document-services/api`;
const SESSION_UID_KEY = 'document-session-uid';
const DOCUMENT_UID_KEY = 'shared-document-uid';
const TARGET_STATE_CODES = {
	INVOICE_OPENED: 'INVOICE_OPENED',
	PAYMENT_INITIALIZED: 'PAYMENT_INITIALIZED',
	BANK_AUTH_SUCCESS: 'BANK_AUTH_SUCCESS',
	BANK_ACCOUNT_VALIDATED: 'BANK_ACCOUNT_VALIDATED',
	PAYMENT_SENT_TO_BANK: 'PAYMENT_SENT_TO_BANK',
	PROCEEDED_TO_PAYMENT_SIGNATURE: 'PROCEEDED_TO_PAYMENT_SIGNATURE',
	PAYMENT_SIGNATURE_COMPLETED: 'PAYMENT_SIGNATURE_COMPLETED',
};

function createSpy() {
	const createSession = (documentUID: string) => {
		if (isLocalhost()) return Promise.resolve(null);

		return new Promise<string | null>(resolve => {
			try {
				Cookie.setCookie(DOCUMENT_UID_KEY, documentUID, { path: '/' });
				axios({
					method: 'post',
					url: `${API_ENDPOINT}/documents/${documentUID}/sessions`,
				})
					.then(result => {
						const guid = result.data;

						Cookie.setCookie(SESSION_UID_KEY, guid, { path: '/' });
						resolve(guid);
					})
					.catch(error => {
						resolve(null);
						console.error('error during spy.createSession', error);
					});
			} catch (e) {
				//
			}
		});
	};

	const getDocumentUID = () => Cookie.getCookie(DOCUMENT_UID_KEY);
	const getSessionGUID = () => Cookie.getCookie(SESSION_UID_KEY);

	const sendAction = (action: SpyAction) => {
		try {
			const documentUID = getDocumentUID();
			const guid = getSessionGUID();

			if (!documentUID || !guid || isLocalhost()) return;

			const { title, description, payload = {} } = action;
			const { subsystemInstanceGUID, tenantID, userID, targetStateCode, error, ...payloadRest } = payload;
			const { screen, innerWidth, innerHeight } = window;
			const userMetadata = {
				screenResolution: `${screen.width}x${screen.height}`,
				windowWidth: innerWidth,
				windowHeight: innerHeight,
			};
			const data = {
				'action-title': title,
				'action-description': description,
				...payloadRest,
				...userMetadata,
			};

			tenantID && (data['tenant-id'] = tenantID);
			userID && (data['user-id'] = userID);
			subsystemInstanceGUID && (data['subsystem-instance-guid'] = subsystemInstanceGUID);
			targetStateCode && (data['target-state-code'] = targetStateCode);
			error && (data['error'] = error);

			axios({
				method: 'post',
				url: `${API_ENDPOINT}/documents/${documentUID}/sessions/${guid}/actions`,
				data: getFormData(data),
			})
				.then(() => {})
				.catch(error => {
					console.error('error during spy.sendAction', error);
				});
		} catch (e) {
			//
		}
	};

	return {
		targetStateCodes: TARGET_STATE_CODES,
		createSession,
		sendAction,
	};
}

const spy = createSpy();

export default spy;
