import * as _ from 'underscore';

import {
	AddFundsRegisterOptions,
	RecalculateFundsRegisterBalancesOptions as RecalculateFundsRegisterBalancesOptionsApi,
} from '@core/api/funds';
import { createAsyncAction, createStaticAction, RECEIVE } from '@flux';
import { SettingsKey, VirtualListGroupCode } from '@funds-registers/models';
import {
	selectAsyncBanks,
	selectAsyncFundsRegisterPurposes,
	selectAsyncFundsRegisterStatistics,
	selectAsyncIsBalanceHidden,
	selectAsyncSourceTypes,
} from '@funds-registers/selectors';
import { createNotificationMessage } from '@utils/notifications';
import { types } from './types';

const actions = {
	addFundsRegister: createAsyncAction(
		types.ADD_FUNDS_REGISTER,
		(api, _, __, options: AddFundsRegisterOptions) => {
			const { fundsRegister, initialBalance, initialDate } = options;
			return new Promise<FundsRegister>(resolve => {
				api.fundsPack.fundsRegister.addFundsRegister(fundsRegister, initialBalance, initialDate).then(result => {
					resolve(result);
				});
			});
		},
		{
			showMessage: type => type === RECEIVE && createNotificationMessage('Счёт добавлен 😊', 'success'),
		},
	) as (options: AddFundsRegisterOptions) => void,
	clearFundsRegisterIDForAutoInsert: () => createStaticAction(types.CLEAR_FUNDS_REGISTER_ID_FOR_AUTO_INSERT)(),
	clearFundsRegisterIDForRefresh: () => createStaticAction(types.CLEAR_FUNDS_REGISTER_ID_FOR_REFRESH)(),
	fetchBanks: createAsyncAction(types.FETCH_BANKS, api => api.referencePack.bank.fetchBanksByTerm(''), {
		isValidSelector: selectAsyncBanks.selectIsValid,
		isFetchingSelector: selectAsyncBanks.selectIsFetching,
	}) as () => void,
	fetchFundsRegisterPurposes: createAsyncAction(
		types.FETCH_FUNDS_REGISTER_PURPOSES,
		api => {
			return new Promise<Array<FundsRegisterPurpose>>(resolve => {
				api.fundsPack.fundsRegister.fetchFundsRegisterPurposes().then(result => {
					resolve(result);
				});
			});
		},
		{
			isValidSelector: selectAsyncFundsRegisterPurposes.selectIsValid,
			isFetchingSelector: selectAsyncFundsRegisterPurposes.selectIsFetching,
		},
	) as () => void,
	fetchFundsRegisterSourceTypes: createAsyncAction(
		types.FETCH_SOURCE_TYPES,
		api => {
			return new Promise<Array<FundsRegisterSourceType>>(resolve => {
				api.fundsPack.fundsRegister.fetchFundsRegisterSourceTypes().then(result => {
					resolve(result);
				});
			});
		},
		{
			isValidSelector: selectAsyncSourceTypes.selectIsValid,
			isFetchingSelector: selectAsyncSourceTypes.selectIsFetching,
		},
	) as () => void,
	fetchFundsRegisterStatistics: createAsyncAction(
		types.FETCH_FUNDS_REGISTER_STATISTICS,
		(api, _, __, dateRange?: DateRange) => {
			return new Promise<Array<FundsRegisterStatistics>>(resolve => {
				api.fundsPack.fundsRegister.fetchFundsRegisterStatistics(dateRange).then(result => {
					resolve(result);
				});
			});
		},
		{
			isValidSelector: selectAsyncFundsRegisterStatistics.selectIsValid,
			isFetchingSelector: selectAsyncFundsRegisterStatistics.selectIsFetching,
		},
	) as (dateRange?: DateRange) => void,
	fetchIsBalanceHidden: createAsyncAction(
		types.FETCH_IS_BALANCE_HIDDEN,
		(api, _, __, tenantID: number) => {
			return new Promise<boolean>(async resolve => {
				const result = await api.preferencesPack.rest.fetchPreferences(tenantID, [SettingsKey.IS_BALANCE_HIDDEN]);
				const value = result[SettingsKey.IS_BALANCE_HIDDEN]?.value || false;

				resolve(value);
			});
		},
		{
			isValidSelector: selectAsyncIsBalanceHidden.selectIsValid,
			isFetchingSelector: selectAsyncIsBalanceHidden.selectIsFetching,
		},
	) as (tenantID: number) => void,
	importFundsRegisterFilesCompleted: () => createStaticAction(types.IMPORT_FUNDS_REGISTER_FILES_COMPLETED)(),
	invalidateFundsRegisterStatistics: () => createStaticAction(types.INVALIDATE_FUNDS_REGISTER_STATISTICS)(),
	recalculateFundsRegisterBalances: createAsyncAction(
		types.RECALCULATE_FUNDS_REGISTER_BALANCES,
		(api, _, __, options: RecalculateFundsRegisterBalancesOptions) => {
			return new Promise<AsyncOperationResponse>(resolve => {
				const { onFinish, ...restOptions } = options;
				api.fundsPack.fundsRegister.recalculateFundsRegisterBalances(restOptions).then(result => {
					resolve(result);
					onFinish();
				});
			});
		},
		{
			showMessage: type =>
				type === RECEIVE && createNotificationMessage('Пересчёт остатков по счёту выполнен 😊', 'success'),
		},
	) as (options: RecalculateFundsRegisterBalancesOptions) => void,
	refreshCmsAccounts: createAsyncAction(types.REFRESH_CMS_ACCOUNTS, api => {
		return new Promise<SuccessMessage>(resolve => {
			const callAPI = () => {
				api.fundsPack.cashManagementSystem.refreshCMSAccounts().then(result => {
					resolve(result);
				});
			};
			setTimeout(callAPI, 2000);
		});
	}) as () => void,
	removeFundsRegister: createAsyncAction(
		types.REMOVE_FUNDS_REGISTER,
		(api, _, __, fundsRegisterID: number) => {
			return new Promise<boolean>(resolve => {
				api.fundsPack.fundsRegister.removeFundsRegister(fundsRegisterID).then(result => {
					resolve(result);
				});
			});
		},
		{
			showMessage: type => type === RECEIVE && createNotificationMessage('Счёт удалён 😲', 'success'),
		},
	) as (fundsRegisterID: number) => void,
	setFilterByText: (value: string) => createStaticAction(types.SET_FILTER_BY_TEXT)(value),
	setFundsRegisterIDForAutoInsert: (value: number) =>
		createStaticAction(types.SET_FUNDS_REGISTER_ID_FOR_AUTO_INSERT)(value),
	setFundsRegisterIDForRefresh: (value: number) => createStaticAction(types.SET_FUNDS_REGISTER_ID_FOR_REFRESH)(value),
	setIsBalanceHidden: createAsyncAction(types.SET_IS_BALANCE_HIDDEN, (api, _, __, tenantID: number, value: boolean) => {
		return api.preferencesPack.rest.savePreferences(tenantID, {
			[SettingsKey.IS_BALANCE_HIDDEN]: value,
		});
	}) as (tenantID: number, value: boolean) => void,
	setIsIncludeArchived: (value: boolean) => {
		localStorage.setItem(types.SET_IS_INCLUDE_ARCHIVED, JSON.stringify(value));
		return createStaticAction(types.SET_IS_INCLUDE_ARCHIVED)(value);
	},
	setVirtualListGroup: (value: VirtualListGroupCode) => {
		localStorage.setItem(types.SET_VIRTUAL_LIST_GROUP, JSON.stringify(value));
		return createStaticAction(types.SET_VIRTUAL_LIST_GROUP)(value);
	},
	updateFundsRegister: createAsyncAction(
		types.UPDATE_FUNDS_REGISTER,
		(api, _, __, fundsRegister: FundsRegister) => {
			return new Promise<FundsRegister>(resolve => {
				api.fundsPack.fundsRegister.updateFundsRegister(fundsRegister).then(result => {
					resolve(result);
				});
			});
		},
		{
			showMessage: type => type === RECEIVE && createNotificationMessage('Счёт изменён 😊', 'success'),
		},
	) as (fundsRegister: FundsRegister) => void,
	updateFundsRegisterAccesses: createAsyncAction(
		types.SET_FUNDS_REGISTER_ACCESS,
		(api, _, __, accesses: Array<FundsRegisterEmployeeAccess>) => {
			return new Promise<boolean>(resolve => {
				api.fundsPack.fundsRegister.updateFundsRegisterAccesses(accesses).then(result => {
					resolve(result);
				});
			});
		},
		{
			showMessage: type =>
				type === RECEIVE && createNotificationMessage('Настройка доступов к счёту завершена 😊', 'success'),
		},
	) as (accesses: Array<FundsRegisterEmployeeAccess>) => void,
};

export type RecalculateFundsRegisterBalancesOptions = {
	onFinish: () => void;
} & RecalculateFundsRegisterBalancesOptionsApi;

export const mainFundsRegistersActionsPack = actions;
