import { fundsApi } from '@core/api/funds';

function transformFundsRegisterToFundsRegisterBrief(source: FundsRegister): FundsRegisterBrief {
	const object: FundsRegisterBrief = {
		...new fundsApi.package.FundsRegisterBrief(),
		ID: source.ID,
		Name: source.Name,
		Number: source.RegisterNumber,
		Bank: (source as BankAccountFundsRegister).Bank || null,
		CurrencyID: source.CurrencyID,
	}; // minimal

	return object;
}
function transformFundsRegisterBriefToFundsRegister(
	source: FundsRegisterBrief,
): BankAccountFundsRegister | CashDeskFundsRegister {
	if (!source) return null;
	const isBankAccount = source?.FundsRegisterType?.Code === 'FUNDS_REGISTER_TYPE_BANK_ACCOUNT';
	const fundsRegister = {
		ID: source.ID,
		Name: source.Name,
		RegisterNumber: source.Number,
		CurrencyID: source.CurrencyID,
		Purpose: source.Purpose,
	}; // minimal
	const bankAccountFundsRegister: BankAccountFundsRegister = {
		...new fundsApi.package.BankAccountFundsRegister(),
		...fundsRegister,
		Bank: source.Bank,
	};
	const cashDeskFundsRegister: CashDeskFundsRegister = {
		...new fundsApi.package.CashDeskFundsRegister(),
		...fundsRegister,
	};

	return isBankAccount ? bankAccountFundsRegister : cashDeskFundsRegister;
}

export { transformFundsRegisterToFundsRegisterBrief, transformFundsRegisterBriefToFundsRegister };
