import React from 'react';
import MainEntry from '@platform/components/layout';
import KPIRoutes from './kpi';
import AppMenuLinks from './menu';
import { WorkspaceRoutes, TitleRoutes } from './workspace';

type RootProps = {
	routes: {
		kpi: typeof KPIRoutes;
		workspace: typeof WorkspaceRoutes;
		title: typeof TitleRoutes;
		appMenuLinks: typeof AppMenuLinks;
	};
};

export const Root: React.FC<RootProps> = props => <MainEntry {...props} />;
export default {
	kpi: KPIRoutes,
	workspace: WorkspaceRoutes,
	title: TitleRoutes,
	appMenuLinks: AppMenuLinks,
};
