import { createTypes } from '@flux';

type ActionTypes = {
	ADD_RULE: string;
	FETCH_OPERATION_TYPES: string;
	FETCH_RULES_TYPES: string;
};

export const types = createTypes<ActionTypes>(
	['ADD_RULE', 'FETCH_OPERATION_TYPES', 'FETCH_RULES_TYPES'],
	'FUNDS_REGISTERS',
);
