import React, { memo, useMemo } from 'react';

import { DataSourceItem } from '@core/api/core';
import { withFormAutopicker } from '@ui/autopicker';
import { LegalEntityForm } from '@counterparties/models';
import {
	LegalEntityFormAutopicker as XLegalEntityFormAutopicker,
	LegalEntityFormAutopickerProps as XLegalEntityFormAutopickerProps,
} from './legal-entity-form-autopicker.view';

export type LegalEntityFormAutopickerProps = {} & Omit<
	XLegalEntityFormAutopickerProps,
	'dataSource' | 'isFetching' | 'isLoaded'
>;

const LegalEntityFormAutopicker: React.FC<LegalEntityFormAutopickerProps> = memo(props => {
	const dataSource = useMemo(
		() => [
			new DataSourceItem({ value: LegalEntityForm.LEGAL_ENTITY, text: 'Юридическое лицо' }),
			new DataSourceItem({ value: LegalEntityForm.SOLE_PROPRIETOR, text: 'Индивидуальный предприниматель' }),
		],
		[],
	);

	return <XLegalEntityFormAutopicker {...props} isLoaded dataSource={dataSource} />;
});

const FormLegalEntityFormAutopicker = withFormAutopicker<LegalEntityFormAutopickerProps, unknown>(
	LegalEntityFormAutopicker,
);

const legalEntityFormAutopickerTransformer = {
	boolean: {
		single: {
			transformInput: ({ input }: { input: boolean }) =>
				typeof input === 'boolean'
					? input
						? { [LegalEntityForm.SOLE_PROPRIETOR]: true }
						: { [LegalEntityForm.LEGAL_ENTITY]: true }
					: null,
			transformOutput: ({ items }) =>
				items[0] ? (items[0] as DataSourceItem<LegalEntityForm>).value === LegalEntityForm.SOLE_PROPRIETOR : null,
		},
	},
};

export { LegalEntityFormAutopicker, FormLegalEntityFormAutopicker, legalEntityFormAutopickerTransformer };
