import { safeNumber } from '@utils/numbers';
import { compareDates } from '@utils/date';
import { detectIsBankAccountFundsRegister } from '@utils/funds-registers';

const getAmount = (x: PLOperationBase) => safeNumber(x.Amount);
const getValue = (x: PLOperationBase, date: string) => {
	let fact = 0;
	let plan = 0;
	let unexpiredPlan = 0;

	if (x.Status === 2) {
		fact = getAmount(x);
	} else {
		plan = getAmount(x);

		if (date) {
			const { isBefore } = compareDates(x.CashflowDate, date);

			if (!isBefore()) {
				unexpiredPlan = getAmount(x);
			}
		}
	}

	return { fact, plan, unexpiredPlan };
};

function getOperationsStatistics(operations: Array<PLOperationBrief>, date?: string) {
	let incomeFactTotal = 0;
	let incomeUnexpiredTotal = 0;
	let incomePlanTotal = 0;
	let expenseFactTotal = 0;
	let expensePlanTotal = 0;
	let expenseUnexpiredTotal = 0;
	let vatValueTotal = 0;
	let acquiringCommissionTotal = 0;
	const sameTransfersMap: Record<number, boolean> = {};

	for (const operation of operations) {
		const { fact, plan, unexpiredPlan } = getValue(operation, date);
		const isTransfer = operation.InternalCashFlow;

		if (isTransfer) {
			const hasSameTransfer = sameTransfersMap[operation.PaymentID];

			if (operation.ID > 0 && operation.SameDirectionTransferStrategy) {
				if (!hasSameTransfer) {
					sameTransfersMap[operation.MirrorPaymentID] = true; // обязательно MirrorPaymentID
				} else {
					continue;
				}
			}
		}

		if (operation.Expense) {
			expenseFactTotal += fact;
			expensePlanTotal += plan;
			expenseUnexpiredTotal += unexpiredPlan;
		} else {
			incomeFactTotal += fact;
			incomePlanTotal += plan;
			incomeUnexpiredTotal += unexpiredPlan;
		}
		if (operation.VatValue > 0) {
			vatValueTotal += operation.VatValue; // НДС в базовой валюте
		}
		if (typeof operation.AcquiringCommission === 'number') {
			acquiringCommissionTotal += operation.AcquiringCommission;
		}
	}

	expenseFactTotal = safeNumber(expenseFactTotal);
	expensePlanTotal = safeNumber(expensePlanTotal);
	expenseUnexpiredTotal = safeNumber(expenseUnexpiredTotal);
	incomeFactTotal = safeNumber(incomeFactTotal);
	incomePlanTotal = safeNumber(incomePlanTotal);
	incomeUnexpiredTotal = safeNumber(incomeUnexpiredTotal);
	vatValueTotal = safeNumber(vatValueTotal);

	const incomeTotal = safeNumber(incomeFactTotal + incomePlanTotal);
	const expenseTotal = safeNumber(expenseFactTotal + expensePlanTotal);
	const resultTotal = safeNumber(incomeTotal - expenseTotal);
	const incomeForecastTotal = safeNumber(incomeFactTotal + incomeUnexpiredTotal);
	const expenseForecastTotal = safeNumber(expenseFactTotal + expenseUnexpiredTotal);
	const resultFactTotal = safeNumber(incomeFactTotal - expenseFactTotal);
	const resultPlanTotal = safeNumber(incomePlanTotal - expensePlanTotal);
	const resultForecastTotal = safeNumber(incomeForecastTotal - expenseForecastTotal);
	const acquiringCommission = safeNumber(acquiringCommissionTotal);

	return {
		incomeFactTotal,
		incomePlanTotal,
		incomeUnexpiredTotal,
		incomeForecastTotal,
		incomeTotal,
		expenseFactTotal,
		expensePlanTotal,
		expenseUnexpiredTotal,
		expenseTotal,
		expenseForecastTotal,
		resultTotal,
		resultFactTotal,
		resultPlanTotal,
		resultForecastTotal,
		vatValueTotal,
		acquiringCommission,
	};
}

function getOperationsFlags(operations: Array<PLOperationBrief>) {
	let isAnyHasLimitedAccess = false;
	let isAnyPlanned = false;
	let isAnyPeriodic = false;
	let isAnySplitted = false;
	let isAnyBankAccount = false;
	let isAnyTransfer = false;
	let hasDifferentDirections = false;
	let hasIncome = false;
	let hasExpense = false;
	let isEverySupportCreatePaymentDraft = true;
	let isEveryHasNotPaymentDraft = true;
	let isPersonal = true;
	let isLonely = false;
	const operationIDs: Array<number> = [];

	for (const operation of operations) {
		operationIDs.push(operation.ID);

		if (!operation.Expense) {
			hasIncome = true;
		} else {
			hasExpense = true;
		}

		if (operation.LimitedAccess) {
			isAnyHasLimitedAccess = true;
		}

		if (operation.Status === 1) {
			isAnyPlanned = true;
		}

		if (operation.PLOperationTemplateID > 0) {
			isAnyPeriodic = true;
		}

		if (operation.Split) {
			isAnySplitted = true;
		}

		if (operation.Status !== 1 && detectIsBankAccountFundsRegister(operation?.FundsRegister)) {
			isAnyBankAccount = true;
		}

		if (operation.InternalCashFlow) {
			isAnyTransfer = true;
		}

		if ((!operation.Expense && hasExpense) || (operation.Expense && hasIncome)) {
			hasDifferentDirections = true;
		}

		if (!operation.SupportCreatePaymentDraft) {
			isEverySupportCreatePaymentDraft = false;
		}

		if (!operation.HasPaymentDraft) {
			isEveryHasNotPaymentDraft = false;
		}

		if (!operation.Personal) {
			isPersonal = false;
		}
	}

	isLonely = operations.length === 1;

	return {
		operationIDs,
		isAnyHasLimitedAccess,
		isAnyPlanned,
		isAnyPeriodic,
		isAnySplitted,
		isAnyBankAccount,
		isAnyTransfer,
		hasDifferentDirections,
		isEverySupportCreatePaymentDraft,
		isEveryHasNotPaymentDraft,
		isPersonal,
		isLonely,
	};
}

export { getOperationsStatistics, getOperationsFlags };
