// @ts-nocheck
import { combineReducers } from 'redux'

import invoiceListReducer, { IInvoiceListState } from './reducers/invoice-list.reducers'
import invoiceSelectReducer, { IInvoiceSelectState } from './reducers/invoice-select.reducers'
import listFiltersReducer from './reducers/invioce-filter.reducers'
import invoiceWizardReducer, { IInvoiceWizardState } from './reducers/invoice-wizard.reducers';
import invoicePaymentReducer, { IInvoicePaymentState } from './reducers/invoice-payment.reducers';
import mainInvoiceReducer, { IMainInvoiceState } from './reducers/main.reducer';

export declare namespace IInvoiceStore {

	type filterValues<T> = {[itemID: string]: T};
	type filtersByCP = filterValues<CounterpartyBrief>;

	interface invoiceListFilters {
		dateRange: {start: Moment, end: Moment};
		purchases: {
			counterparty: filtersByCP;
			substr: string;
			byAll: boolean;
			byNotPaid: boolean;
			byPaid: boolean;
			byOverdue: boolean;
			byDraft: boolean;
			numberSubstr: string;
			descSubstr: string;
		};
		sales: {
			counterparty: filtersByCP;
			substr: string;
			byAll: boolean;
			byNotPaid: boolean;
			byPaid: boolean;
			byOverdue: boolean;
			byDraft: boolean;
			numberSubstr: string;
			descSubstr: string;
			manager: Record<string, boolean>;
			linkedDocuments: boolean | null
		}
	}

	interface store {
		invoices: IInvoiceListState;
		selectedInvoce: IInvoiceSelectState;
		invoiceListFilters: invoiceListFilters;
		invoiceWizard: IInvoiceWizardState;
		invoicePayment: IInvoicePaymentState;
		main: IMainInvoiceState;
	}
}

const invoiceReducer = combineReducers<IInvoiceStore.store>({
	invoices: invoiceListReducer,
	selectedInvoce: invoiceSelectReducer,
	invoiceListFilters: listFiltersReducer,
	invoiceWizard: invoiceWizardReducer,
	invoicePayment: invoicePaymentReducer,
	main: mainInvoiceReducer,
})

export default {
	reducer: invoiceReducer,
	layout: {
	},
	components: {
	},
	actions: {
	},
};