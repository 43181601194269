import axios from 'axios';

import { pageContext } from '@libs/atr-core-lib';

export type SettingsItem = {
	changeDate: string;
	key: string;
	tenantID: number;
	value: any;
};

const API_ENDPOINT = `${location.origin}/snc-preferences-services/api`;

const preferencesApi = {
	rest: {
		fetchPreferences: (tenantID: number, codes: Array<string>) => {
			return new Promise<Record<string, SettingsItem>>(resolve => {
				const ticketUID = pageContext.ticketuid;

				Promise.all(
					codes.map(code =>
						axios({
							method: 'get',
							headers: {
								ticketuid: ticketUID,
							},
							url: `${API_ENDPOINT}/settings/${tenantID}/${code}`,
						}),
					),
				).then(result => {
					const data: Record<string, SettingsItem> = result
						.map(x => x.data)
						.filter(Boolean)
						.map((x: SettingsItem) => {
							try {
								x.value = x.value ? JSON.parse(x.value) : x.value;
							} catch (error) {
								return x;
							}

							return x;
						})
						.reduce((acc, x) => ((acc[x.key] = x), acc), {});

					resolve(data);
				});
			});
		},
		savePreferences: (tenantID: number, settingsMap: Record<string, any>) => {
			return new Promise(resolve => {
				const ticketUID = pageContext.ticketuid;
				const parameters = Object.keys(settingsMap).reduce((acc, key, idx) => {
					const value = typeof settingsMap[key] === 'object' ? JSON.stringify(settingsMap[key]) : settingsMap[key];

					return `${acc}${idx > 0 ? '&' : ''}${key}=${value}`;
				}, '');

				axios({
					method: 'post',
					headers: {
						ticketuid: ticketUID,
					},
					url: `${API_ENDPOINT}/settings/${tenantID}/set/${encodeURIComponent(parameters)}`,
				}).then(result => resolve(result.data));
			});
		},
	},
};

export { preferencesApi };
