import {
	StoreAsyncItem,
	checkAsyncAction,
	createAsyncInitialState,
	invalidateStateFromAction,
	createReducer,
} from '@flux';
import { types } from '@employees/actions/types';

export type MainEmployeesState = {
	employees: StoreAsyncItem<Array<EmployeeBrief>>;
	searchText: string;
	tenantLegalEntityFilter: Record<number, boolean>;
	businessRoles: StoreAsyncItem<Array<CodeNaturalKey>>;
	users: StoreAsyncItem<Array<SecurityAgent>>;
	userRights: StoreAsyncItem<Array<SecurityRight>>;
};

const initialState: MainEmployeesState = {
	employees: createAsyncInitialState([]),
	searchText: '',
	tenantLegalEntityFilter: {},
	businessRoles: createAsyncInitialState([]),
	users: createAsyncInitialState([]),
	userRights: createAsyncInitialState([]),
};

const mainEmployeesReducer = createReducer(initialState, {
	[types.INVALIDATE_EMPLOYEES]: (action: StaticAction, state) => {
		return {
			employees: invalidateStateFromAction(action, state.employees),
		};
	},
	[types.FETCH_EMPLOYEES]: (action: AsyncAction<Array<EmployeeBrief>>, state) => {
		return {
			employees: checkAsyncAction(action, state.employees),
		};
	},
	[types.ADD_EMPLOYEE]: (action: StaticAction, state) => {
		return {
			employees: invalidateStateFromAction(action, state.employees),
		};
	},
	[types.UPDATE_EMPLOYEE]: (action: StaticAction, state) => {
		return {
			employees: invalidateStateFromAction(action, state.employees),
		};
	},
	[types.REMOVE_EMPLOYEE]: (action: StaticAction, state) => {
		return {
			employees: invalidateStateFromAction(action, state.employees),
		};
	},
	[types.SET_FILTER_BY_TEXT]: (action: StaticAction<string>) => {
		return {
			searchText: action.value,
		};
	},
	[types.SET_FILTER_BY_TENANT_LEGAL_ENTITY]: (action: StaticAction<Record<number, boolean>>) => {
		return {
			tenantLegalEntityFilter: { ...action.value },
		};
	},
	[types.RESET_FILTERS]: () => {
		return {
			searchText: '',
			tenantLegalEntityFilter: {},
		};
	},
	[types.FETCH_BUSINESS_ROLES]: (action: AsyncAction<Array<CodeNaturalKey>>, state) => {
		return {
			businessRoles: checkAsyncAction(action, state.businessRoles),
		};
	},
	[types.FETCH_USERS]: (action: AsyncAction<Array<SecurityAgent>>, state) => {
		return {
			users: checkAsyncAction(action, state.users),
		};
	},
	[types.ADD_USER]: (action: AsyncAction<SecurityAgent>, state) => {
		return {
			users: invalidateStateFromAction(action, state.users),
		};
	},
	[types.UPDATE_USER]: (action: AsyncAction<SecurityAgent>, state) => {
		return {
			users: invalidateStateFromAction(action, state.users),
		};
	},
	[types.FETCH_USER_RIGHTS]: (action: AsyncAction<Array<SecurityRight>>, state) => {
		return {
			userRights: checkAsyncAction(action, state.userRights),
		};
	},
});

export { mainEmployeesReducer };
