import { createTypes } from '@flux';

type ActionTypes = {
	INVALIDATE_MODULE_DATA: string;
	SET_DATE_RANGE: string;
	SET_VIEW_MODE: string;
	SET_GRANULATION_MODE: string;
	SET_SEARCH_TEXT: string;
	SET_CHART_VISIBILITY: string;
	SET_EXPANDED_HIERARCHY_MAP: string;
	SET_SELECTED_COORDS: string;
	SET_OPERATION_DETAILS_OPTIONS: string;
	SET_OPERATION_DETAILS_TITLE: string;
	SET_FORECAST_INDICATOR_DATE: string;
	RESTORE_OPERATION_DETAILS_VALIDATION: string;
	FETCH_PLANNING_REPORT: string;
	FETCH_CASHFLOW_FORECAST: string;
	FETCH_FORECAST_INDICATOR_DATA: string;
	FETCH_CASH_GAP_INDICATOR_DATA: string;
	FETCH_PLAN_OPERATIONS: string;
	FETCH_DETAILS_OPERATIONS: string;
	SET_DATE_TYPE: string;
	SET_TENANT_ENTITIES_FILTER: string;
	SET_BUSINESS_UNITS_FILTER: string;
	SET_PROJECTS_FILTER: string;
	SET_CASHFLOW_ITEMS_FILTER: string;
	SET_FUNDS_REGISTERS_FILTER: string;
};

const types = createTypes<ActionTypes>(
	[
		'INVALIDATE_MODULE_DATA',
		'SET_DATE_RANGE',
		'SET_VIEW_MODE',
		'SET_GRANULATION_MODE',
		'SET_SEARCH_TEXT',
		'SET_CHART_VISIBILITY',
		'SET_EXPANDED_HIERARCHY_MAP',
		'SET_SELECTED_COORDS',
		'SET_OPERATION_DETAILS_OPTIONS',
		'SET_OPERATION_DETAILS_TITLE',
		'SET_FORECAST_INDICATOR_DATE',
		'RESTORE_OPERATION_DETAILS_VALIDATION',
		'FETCH_PLANNING_REPORT',
		'FETCH_CASHFLOW_FORECAST',
		'FETCH_FORECAST_INDICATOR_DATA',
		'FETCH_CASH_GAP_INDICATOR_DATA',
		'FETCH_PLAN_OPERATIONS',
		'FETCH_DETAILS_OPERATIONS',
		'SET_DATE_TYPE',
		'SET_TENANT_ENTITIES_FILTER',
		'SET_BUSINESS_UNITS_FILTER',
		'SET_PROJECTS_FILTER',
		'SET_CASHFLOW_ITEMS_FILTER',
		'SET_FUNDS_REGISTERS_FILTER',
	],
	'PLANNING 👽',
);

export { types };
