import styled from 'styled-components';

const NormativeMark = styled.div`
	flex: 0 0 auto;
	display: flex;
	justify-content: flex-end;
	text-align: right;
	font-size: 7px;
`;

const ReferenceMark = styled.div`
	font-size: 7px;
`;

export { NormativeMark, ReferenceMark };
