import { Store } from 'redux';

import { IAppState } from '@store';
import { isReceiveAction } from '@flux';
import { types as platformTypes } from '@platform/actions/types';
import { mainTypes as operationsTypes } from '@pl/actions/types';
import { invalidateOperations as mainInvalidateOperations } from '@pl/actions/invalidators';
import { types as cashflowPaymentRulesTypes } from '@cashflow-payment-rules/actions/types';
import { types as projectPaymentRulesTypes } from '@project-payment-rules/actions/types';
import { types as fundsRegistersTypes } from '@funds-registers/actions/types';
import { types as paymentsTypes } from '@payments/actions/types';
import { mainPlanningActionsPack } from '@pl-planning/actions/main-planning.actions';
import { mainPlanningMasterActionsPack } from '@pl-planning/actions/main-planning-master.actions';
import { mainReportingActionsPack } from '@analytics/actions/main-reporting.actions';
import { analysisActionsPack } from '@analytics/actions/analysis.actions';
import { initializationActionsPack } from '@initialization/actions/initialization.actions';
import { types as projectManagementTypes } from '@project-management/actions/types';
import { types as cashflowItemsTypes } from '@cashflow-items/actions/types';
import { types as transferRulesTypes } from '@transfer-rules/actions/types';
import { types as invoicesTypes } from '@invoices/actions/types';
import { types as businessUnitsTypes } from '@business-units/actions/types';
import { invalidateCashflowLimitsOperations, invalidateCashflowLimits } from '@cashflow-limits/actions/invalidators';
import { invalidateOperations as invalidateProjectOperations } from '@project-management/actions/invalidators';
import { invalidateModuleData as invalidatePlAnalyticsModuleData } from '@pl-analytics/actions/invalidators';
import { invalidateModuleData as invalidatePlanningModuleData } from '@planning/actions/invalidators';
import { invalidateModuleData as invalidateAccrualReportModuleData } from '@accrual-report/actions/invalidators';
import { invalidateModuleData as invalidateCashflowReportModuleData } from '@cashflow-report/actions/invalidators';
import { invalidateModuleData as invalidateProfitLossReportModuleData } from '@profit-loss-report/actions/invalidators';
import { invalidateBalanceReport } from '@balance-report/actions/invalidators';
import { invalidateDashboardData } from '@dashboard/actions/invalidators';
import { invalidateTopCounterpartiesReportData } from '@top-counterparties-report/actions/invalidators';

function askOperationsInvalidator(store: Store<IAppState>, action: StoreAction) {
	const { dispatch } = store;
	const invalidateMap = {
		[platformTypes.IMPORT_ACCOUNTS_FROM_CMS_TASK_COMPLETED]: true,
		[platformTypes.IMPORT_HISTORICAL_ACCOUNT_STATEMENT_FROM_CMS_TASK_COMPLETED]: true,
		[operationsTypes.GROUP_BY_DATE_TYPE]: true,
		[operationsTypes.LINK_OPERATIONS_TO_CASHFLOW_ITEM]: true,
		[operationsTypes.TRANSFORM_PLAN_OPERATIONS_TO_FACT]: true,
		[operationsTypes.MASS_UPDATE_OPERATIONS]: true,
		[operationsTypes.ADD_OPERATION]: true,
		[operationsTypes.UPDATE_OPERATION]: true,
		[operationsTypes.REMOVE_OPERATIONS]: true,
		[operationsTypes.ADD_OPERATIONS_BY_TEMPLATE]: true,
		[operationsTypes.UPDATE_OPERATIONS_BY_TEMPLATE]: true,
		[operationsTypes.SPLIT_OPERATION]: true,
		[operationsTypes.UPDATE_SPLIT_OPERATIONS]: true,
		[operationsTypes.SEND_PLAN_OPERATIONS_TO_BANK]: true,
		[fundsRegistersTypes.SET_FUNDS_REGISTER_ACCESS]: true,
		[fundsRegistersTypes.UPDATE_FUNDS_REGISTER]: true,
		[fundsRegistersTypes.REMOVE_FUNDS_REGISTER]: true,
		[fundsRegistersTypes.IMPORT_FUNDS_REGISTER_FILES_COMPLETED]: true,
		[fundsRegistersTypes.REFRESH_CMS_ACCOUNTS]: true,
		[paymentsTypes.ADD_PAYMENT]: true,
		[paymentsTypes.UPDATE_PAYMENT]: true,
		[paymentsTypes.REMOVE_PAYMENT]: true,
		[paymentsTypes.MARK_AS_TRANSFER]: true,
		[paymentsTypes.UNMARK_TRANSFER]: true,
		[paymentsTypes.MERGE_PAYMENTS_TO_TRANSFER]: true,
		[cashflowItemsTypes.ADD_CASHFLOW_ITEM]: true,
		[cashflowItemsTypes.UPDATE_CASHFLOW_ITEM]: true,
		[cashflowItemsTypes.REMOVE_CASHFLOW_ITEM]: true,
		[projectManagementTypes.ADD_PROJECT]: true,
		[projectManagementTypes.UPDATE_PROJECT]: true,
		[projectManagementTypes.REMOVE_PROJECT]: true,
		[projectManagementTypes.LINK_OPERATIONS_TO_PROJECT]: true,
		[projectPaymentRulesTypes.EXECUTE_PAYMENT_RULES]: true,
		[cashflowPaymentRulesTypes.EXECUTE_PAYMENT_RULES]: true,
		[transferRulesTypes.APPLY]: true,
		[initializationActionsPack.types.SET_IMPORT_DATA_IN_BACKGROUND_IS_COMPLETE]: true,
		[invoicesTypes.ADD_INVOICE]: true,
		[businessUnitsTypes.LINK_OPERATIONS_TO_BUSINESS_UNIT]: true,
		[businessUnitsTypes.UPDATE_BUSINESS_UNIT]: true,
		[businessUnitsTypes.REMOVE_BUSINESS_UNIT]: true,
	};

	if (invalidateMap[action.type] && isReceiveAction(action)) {
		dispatch(mainInvalidateOperations());
		dispatch(mainPlanningActionsPack.actions.invalidateOperations());
		dispatch(mainPlanningMasterActionsPack.actions.invalidateOperations());
		dispatch(mainReportingActionsPack.actions.invalidateOperations());
		dispatch(analysisActionsPack.actions.invalidateOperations());
		dispatch(invalidateDashboardData());
		dispatch(invalidateCashflowLimitsOperations());
		dispatch(invalidateProjectOperations());
		dispatch(invalidatePlAnalyticsModuleData());
		dispatch(invalidatePlanningModuleData());
		dispatch(invalidateAccrualReportModuleData());
		dispatch(invalidateCashflowReportModuleData());
		dispatch(invalidateProfitLossReportModuleData());
		dispatch(invalidateBalanceReport());
		dispatch(invalidateCashflowLimits());
		dispatch(invalidateTopCounterpartiesReportData());
	}
}

function createInvalidatorEffect() {
	return (store: Store<IAppState>) => (next: (action: StoreAction) => void) => (action: StoreAction) => {
		next(action);
		askOperationsInvalidator(store, action);
	};
}

const runInvalidatorEffect = createInvalidatorEffect();

export { runInvalidatorEffect };
