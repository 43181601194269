import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';

import { getURLParameterByName } from '@utils';
import { detectIsDevHost } from '@utils/env';
import store from '@store';
import spy from '@core/spy';
import { emitErrorAction } from '@shared/actions/error-message.action';
import { emitActionMessage } from '@shared/actions/action-message.actions';
import { filterServerErrorStack, logError } from '@libs/atr-core-lib';
import {
	selectContextIsAvailable,
	selectCurrentTenantName,
	selectCurrentTenantID,
	selectCurrentUserName,
	selectCurrentUserLogin,
	selectCurrentApplicationName,
	selectCurrentApplicationID,
} from '@platform/selectors/context.selectors';
import { URL_PARAMS as invoiceURLParams } from '@invoice/constants';

function initErrorInterceptor() {
	window['globalEmitErrorMessage'] = globalEmitErrorMessage;
}

function globalEmitErrorMessage(options: GlobalEmitErrorMessageOptions) {
	const { title, message, error, xhr, soapMessage, isNetworkTrouble } = options;

	if (isNetworkTrouble) {
		return store.dispatch(
			emitActionMessage('Кажется, мы оффлайн? 🥴 Попробуйте обновить вкладку браузера 🙂', 'warning'),
		);
	}

	const state = store.getState();
	const contextIsAvailable = selectContextIsAvailable(state);
	let tenantName = '';
	let tenantID = -1;
	let userName = '';
	let userLogin = '';
	let appName = '';
	let appID = -1;
	let messageWithMeta = '';

	if (contextIsAvailable) {
		tenantName = selectCurrentTenantName(state);
		tenantID = selectCurrentTenantID(state);
		userName = selectCurrentUserName(state);
		userLogin = selectCurrentUserLogin(state);
		appName = selectCurrentApplicationName(state);
		appID = selectCurrentApplicationID(state);
	}

	const serverErrorStack = xhr ? filterServerErrorStack((xhr?.responseText || '').replace(/></g, '>\n<')) : null;
	const soapMessageML = soapMessage ? soapMessage.replace(/></g, '>\n<') : null;

	serverErrorStack && runInvoiceBugTracking(serverErrorStack);

	logError(error || new Error(`${title}: ${message}`), {
		serverErrorStack,
		soapMessage: soapMessageML,
	});

	const errorEventID = Sentry.getCurrentHub().lastEventId() || '-';

	if (contextIsAvailable) {
		messageWithMeta = `
			Арендатор: ${tenantName} (#${tenantID})
			Пользователь: ${userName} (#${userLogin})
			Приложение: ${appName} (#${appID})
			ID ошибки: ${errorEventID}
			Заголовок ошибки: ${title}
			${message ? message : ''}
			${error ? getErrorDetail(error) : ''}
		`;
	} else {
		messageWithMeta = `
			Контекст не доступен
			ID ошибки: ${errorEventID}
			Заголовок ошибки: ${title}
			${message ? message : ''}
			${error ? getErrorDetail(error) : ''}
		`;
	}

	store.dispatch(emitErrorAction(title, messageWithMeta));

	function getErrorDetail(error) {
		const detail = [];

		typeof error === 'string' && detail.push(`Текст ошибки: ${error}`);
		error.fileName && detail.push(`Имя файла: ${error.fileName}`);
		error.lineNumber && detail.push(`Номер строки: ${error.lineNumber}`);
		error.columnNumber && detail.push(`Номер колонки: ${error.columnNumber}`);
		error.stack && detail.push(`Стэк вызовов: ${error.stack}`);

		return detail.join('');
	}
}

function createSentryConfig(context?: ClientSessionContext) {
	if (!detectIsDevHost()) {
		const userContext = context
			? {
					tenantName: context.SessionTenant.Name,
					tenantID: context.SessionTenant.ID,
					userName: context.SessionUser.Name,
					userLogin: context.SessionUser.Login,
					applicationName: context.SessionApplication.Name,
					applicationID: context.SessionApplication.ID,
					pageflowName: getCurrentPageflowName(),
			  }
			: { pageflowName: getCurrentPageflowName() };

		Sentry.init({
			dsn: 'https://a2a331e9b7034d7f93d5e3a57695fdbe@o1038547.ingest.sentry.io/6006921',
			release: 'bfm',
			integrations: [new Integrations.BrowserTracing()],
			ignoreErrors: [
				'Loading chunk',
				'ResizeObserver loop limit exceeded',
				'ResizeObserver loop completed with undelivered notifications',
			],
			tracesSampleRate: 0.25,
		});

		Sentry.setContext('user', userContext);
	}

	function getCurrentPageflowName() {
		return window.location.href.replace(window.location.origin, '');
	}
}

function runInvoiceBugTracking(errorDetails: string) {
	const isInvoicePayForm = Boolean(getURLParameterByName(invoiceURLParams.FAST_PAYMENT_MODE));

	if (isInvoicePayForm) {
		spy.sendAction({
			title: 'Server Error',
			description: 'Server error during the payment process',
			payload: {
				details: errorDetails,
				error: true,
			},
		});
	}
}

export { initErrorInterceptor, globalEmitErrorMessage, createSentryConfig };
