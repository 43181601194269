import styled, { css } from 'styled-components';

const Root = styled.div`
	position: relative;
	width: 100%;
	height: auto;
	background-color: transparent;
`;

const ContentLayout = styled.div`
	width: 100%;
	height: auto;
	min-height: calc(100vh - 150px);
	display: flex;
	flex-flow: column wrap;
	justify-content: center;
	align-items: center;
	padding: 30px 40px;
`;

const WrapLayout = styled.div`
	position: relative;
	width: 100%;
	margin-top: -80px;
`;

const InfoLayout = styled.div`
	width: 100%;
	height: auto;
	margin-bottom: 32px;
`;

const Title = styled.div`
	font-size: 14px;
	font-weight: 500;
	line-height: 1.43;
	text-align: center;
	margin-bottom: 9px;
`;

const Text = styled.div`
	font-size: 14px;
	line-height: 1.43;
	text-align: center;

	${p => css`
		color: ${p.theme.palette.label};
	`}
`;

const ProgressBarLayout = styled.div`
	width: 100%;
	height: auto;
`;

export { Root, ContentLayout, WrapLayout, InfoLayout, Title, Text, ProgressBarLayout };
