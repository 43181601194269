import * as _ from 'underscore';

import {
	checkAsyncItemLoadAction,
	createDefaultAsyncItem,
	invalidateAction
} from '@libs/reducer-creator';
import { constants as authConstants } from '../actions/auth.actions';


export interface IAuthState {
	token: StoreAsyncItem<OAuthAccessToken>;
}

const initialState = {
	token: createDefaultAsyncItem(null)
};

export default function (state: IAuthState = initialState, action) {
	switch (action.type) {
	case authConstants.FETCH_ACCESS_TOKEN:
		const fetchTokenAction = action as AsyncAction<OAuthAccessToken>;

		return {
			...state,
			token: checkAsyncItemLoadAction(fetchTokenAction, state.token)
		}

	case authConstants.GENERATE_ACCESS_TOKEN:
	case authConstants.REVOKE_ACCESS_TOKEN:
		return {
			...state,
			token: invalidateAction(action, state.token)
		}

	
	default:
		return state
	}
}
