import React, { memo } from 'react';
import { useHistory } from 'react-router-dom';

import { openCmsConnectFormDrawer } from '@funds-registers/components/cms-connect-form';
import { SyncMarker } from '@funds-registers/components/sync-marker';
import { CMSDataType, DrawerZone, SyncMarkerInitiators } from '@funds-registers/models';
import { CurrencyFormatter } from '@ui/currency-formatter';
import { HelpMark } from '@ui/help-mark';
import { ProviderIcon } from '@ui/provider-icon';
import { getFundsRegistersAccessFlags } from '@utils/finance';
import {
	AccountName,
	AccountNameLayout,
	Balance,
	BalanceLayout,
	BankIconLayout,
	ContentLayout,
	GroupLayout,
	Root,
	SyncMarkerLayout,
} from './styled';

interface IProps {
	fundsRegister: FundsRegister;
	accountId: number;
	accountName: string;
	accountCurrency: string;
	accountType: number;
	balance: number;
	bankName: string;
	connectedToCMS: boolean;
	setFundsRegisterID: (ID: number) => void;
	setFundsRegisterIDForRefresh: (ID: number) => void;
	setFundsRegisterIDForAutoInsert: (ID: number) => void;
}

const AccountMenuItem: React.FC<IProps> = memo(props => {
	const {
		fundsRegister,
		accountId,
		accountName,
		accountCurrency,
		accountType,
		balance,
		bankName,
		connectedToCMS,
		setFundsRegisterID,
		setFundsRegisterIDForRefresh,
		setFundsRegisterIDForAutoInsert,
	} = props;
	const { hasReadAccessToAllDirections } = getFundsRegistersAccessFlags([fundsRegister]);

	const history = useHistory();

	const handleClick = () => {
		setFundsRegisterID(accountId);
		history.push('/bfm/accounts/payments');
	};

	const handleSync = (status: 'SYNC' | 'CAN_CONNECT', ID: number) => {
		if (status === 'SYNC') {
			setFundsRegisterIDForRefresh(ID);
			openCmsConnectFormDrawer({ zone: DrawerZone.REFRESH_CMS, dataType: CMSDataType.LEGAL_ENTITY_BANK_ACCOUNT });
		}

		if (status === 'CAN_CONNECT') {
			setFundsRegisterIDForAutoInsert(ID);
			openCmsConnectFormDrawer({ zone: DrawerZone.ACOUNTS, dataType: CMSDataType.LEGAL_ENTITY_BANK_ACCOUNT });
		}
	};

	const renderLimitedAccessMark = () => <HelpMark label='Недостаточно прав на просмотр' />;

	return (
		<Root onClick={handleClick}>
			<ContentLayout>
				<GroupLayout>
					<BankIconLayout>
						<ProviderIcon
							providerName={bankName}
							size={16}
							fallback={accountType === 1 ? 'bank-account' : 'virtual-account'}
						/>
					</BankIconLayout>
				</GroupLayout>
				<GroupLayout>
					<AccountNameLayout>
						<AccountName>{accountName}</AccountName>
					</AccountNameLayout>
					<BalanceLayout>
						<Balance textClip={hasReadAccessToAllDirections}>
							{hasReadAccessToAllDirections ? (
								<CurrencyFormatter.Base fontSize={12} code={accountCurrency}>
									{balance}
								</CurrencyFormatter.Base>
							) : (
								renderLimitedAccessMark()
							)}
						</Balance>
					</BalanceLayout>
				</GroupLayout>
				<SyncMarkerLayout>
					<SyncMarker
						bankName={bankName}
						connectedToCMS={connectedToCMS}
						fundsRegisterID={accountId}
						initiator={SyncMarkerInitiators.ACCOUNT_MENU}
						onAction={handleSync}
						sourceTypeID={fundsRegister?.SourceType?.ID}
					/>
				</SyncMarkerLayout>
			</ContentLayout>
		</Root>
	);
});

export { AccountMenuItem };
