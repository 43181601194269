import React, { useMemo } from 'react';

import { Box } from '@ui/box';
import { Typography } from '@ui/typography';
import { HighLightedText } from '@ui/highlighted-text';
import { Autopicker, AutopickerProps, AutopickerRenderItemContentOptions } from '@ui/autopicker';

export type BankAutopickerProps = {
	name?: string;
} & AutopickerProps<Bank>;

const BankAutopicker: React.FC<BankAutopickerProps> = props => {
	const pluralItems = useMemo(() => ['банк', 'банка', 'банков'] as [string, string, string], []);

	const renderItemContent = ({ item, searchText, containerWidth }: AutopickerRenderItemContentOptions<Bank>) => {
		const isSmallContainer = containerWidth < 340;

		return (
			<Box fontSize={isSmallContainer ? 12 : 13} fullWidth>
				<Box marginBottom={2}>
					<HighLightedText value={item.Name} query={searchText} />
				</Box>
				<Box display='flex' fontSize={11}>
					<Typography.Label component='span'>
						БИК: <HighLightedText value={item.Bic} query={searchText} />
					</Typography.Label>
					<Typography.Nbsp />
					<Typography.Label component='span'>
						К/С: <HighLightedText value={item.CorrAccount || '-'} query={searchText} />
					</Typography.Label>
				</Box>
			</Box>
		);
	};

	const renderUnderlinedText = ([item]: Array<Bank>) => {
		if (!item) return null;

		return (
			<>
				<Typography.Label component='span' marginRight={8}>
					БИК: {item.Bic}
				</Typography.Label>
				<Typography.Label component='span'>К/C: {item.CorrAccount || '-'}</Typography.Label>
			</>
		);
	};

	return (
		<Autopicker
			labelText='Банк'
			hintText='Выберите банк...'
			searchBarHintText='Введите название банка или БИК...'
			pluralItems={pluralItems}
			getNameForSearchTextFilter={getNameForSearchTextFilter}
			renderItemContent={renderItemContent}
			renderUnderlinedText={renderUnderlinedText}
			{...props}
			saveOriginalSorting
			maxItems={50}
			fullWidth
		/>
	);
};

const getNameForSearchTextFilter = (item: Bank) => `${item.Name} ${item.Bic} ${item.CorrAccount}`;

export { BankAutopicker };
