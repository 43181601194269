import { getURLParameterByName } from '@utils';
import { URL_PARAMS } from '../constants';
import { history } from '@platform/components/layout/layout.view';


const getIsFastPayProcess = () => !!getURLParameterByName(URL_PARAMS.INVOICE) && !!getURLParameterByName(URL_PARAMS.FAST_PAYMENT_MODE);

function subscribeOnFastPayInvoiceProcess(data: {
	onStart: () => void;
	onEnd: () => void
}) {
	const { onStart, onEnd } = data;

	if (getIsFastPayProcess()) {
		onStart();
		return history.listen(() => !getIsFastPayProcess() && onEnd());
	} else {
		setImmediate(onEnd);
	}

	return () => {};
}

export {
	subscribeOnFastPayInvoiceProcess,
};
