import { combineReducers } from 'redux';

import { mainReferenceReducer, MainReferenceState } from './main.reducer';

export type ReferenceState = {
	main: MainReferenceState;
};

const referenceReducer = combineReducers<ReferenceState>({
	main: mainReferenceReducer,
});

export default referenceReducer;
