import { createSelector, OutputSelector } from 'reselect';

type AsyncSelector<T, S> = {
	selectItem: (state: S) => T;
	selectIsFetching: (state: S) => boolean;
	selectIsLoaded: (state: S) => boolean;
	selectDidInvalidate: (state: S) => boolean;
	selectIsValid: (state: S) => boolean;
};

type AsyncSelectorConfig<T, S> = {
	get: (s: S) => any;
	selector: OutputSelector<S, T, Function>;
};

function createAsyncSelector<T, S>(config: AsyncSelectorConfig<T, S>) {
	return {
		selectItem: config.selector,
		selectIsFetching: (state: S) => config.get(state).isFetching as boolean,
		selectIsLoaded: (state: S) => config.get(state).isLoaded as boolean,
		selectDidInvalidate: (state: S) => config.get(state).didInvalidate as boolean,
		selectIsValid: (state: S) => config.get(state).isLoaded && !config.get(state).didInvalidate,
	};
}

export { AsyncSelector, createSelector, createAsyncSelector };
