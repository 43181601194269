import React from 'react';

import { IconBase, IconBaseProps } from './base';

export type UnitOperationsIconProps = IconBaseProps;

const UnitOperationsIcon: React.FC<UnitOperationsIconProps> = props => {
	return (
		<IconBase {...props}>
			<svg
				stroke='currentColor'
				fill='currentColor'
				strokeWidth='0'
				viewBox='0 0 24 24'
				xmlns='http://www.w3.org/2000/svg'>
				<g>
					<path fill='none' d='M0 0h24v24H0z'></path>
					<path d='M16 16v-4l5 5-5 5v-4H4v-2h12zM8 2v3.999L20 6v2H8v4L3 7l5-5z'></path>
				</g>
			</svg>
		</IconBase>
	);
};

export { UnitOperationsIcon };
