import styled, { css } from 'styled-components';

const Root = styled.div`
	position: relative;
	width: auto;
	height: auto;
`;

const PreviewLayout = styled.div`
	position: relative;
`;

const DocumentLayout = styled.div`
	position: relative;
	width: 318px;
	height: 450px;
`;

const ZoomLayout = styled.div`
	position: absolute;
	transform: scale(0.4, 0.4);
	transform-origin: 0 0;
`;

const ControlsLayout = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 1;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 10px;
`;

const ButtonLayout = styled.div`
	margin-right: 10px;

	&:last-child {
		margin-right: 0;
	}
`;

const dialogStyle = {
	paddingTop: 0,
};

const dialogBodyStyle = {
	height: 'auto',
	maxHeight: 'calc(100vh - 80px)',
	overflowY: 'auto',
	overflowX: 'hidden',
	padding: 0,
	transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
};

const dialogContentStyle: React.CSSProperties = {
	width: '100%',
	maxWidth: 'none',
	transform: 'none',
	padding: 0,
};

const dialogTitleStyle = {
	position: 'relative',
	boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.15)',
};

const HeaderLayout = styled.div`
	position: relative;
	width: 100%;
	height: auto;
	min-height: 70px;
`;

const CloseButtonLayout = styled.div`
	position: absolute;
	display: inline-flex;
	top: 50%;
	right: 24px;
	transform: translate(0, -50%);
`;

const CoverLayout = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	padding: 20px;

	${p => css`
		background-color: ${p.theme.palette.background};
	`}
`;

export {
	Root,
	PreviewLayout,
	DocumentLayout,
	ZoomLayout,
	ControlsLayout,
	ButtonLayout,
	dialogStyle,
	dialogBodyStyle,
	dialogContentStyle,
	dialogTitleStyle,
	HeaderLayout,
	CloseButtonLayout,
	CoverLayout,
};
