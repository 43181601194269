import styled, { css } from 'styled-components';

type IncomeIndicatorProps = {
	isIncome?: boolean;
};

const IncomeIndicator = styled.div<IncomeIndicatorProps>`
	display: inline-block;
	width: 5px;
	height: 5px;
	border-radius: 50%;
	margin-right: 5px;
	vertical-align: middle;

	${p =>
		typeof p.isIncome === 'boolean'
			? css`
					background-color: ${p.isIncome ? p.theme.palette.positive : p.theme.palette.negative};
			  `
			: css`
					display: none;
			  `}
`;

export { IncomeIndicator };
