import React, { useMemo } from 'react';
import MaskedInput from 'react-text-mask';

import { detectIsAdditionalTaxCode } from '@utils/counterparty';
import { TextField, TextFieldProps } from '@ui/input-field';
import { withFormInput } from '@ui/forms/inputs';
import { getFormValue } from '@ui/forms/shared';
import { FormState, FormInputProps, getInputFieldValue, setInputFieldValue } from '@ui/forms';

export type AdditionalTaxCodeTextFieldProps = {} & TextFieldProps;

const AdditionalTaxCodeTextField: React.FC<AdditionalTaxCodeTextFieldProps> = props => {
	const { name, value } = props;

	return (
		<TextField labelText='КПП' hintText='xxxxxxxxx' {...props}>
			<MaskedInput value={value} mask={mask} keepCharPositions showMask guide={false} />
		</TextField>
	);
};

const mask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

const FormAdditionalTaxCodeTextFieldBase = withFormInput<Partial<AdditionalTaxCodeTextFieldProps>, unknown>(
	AdditionalTaxCodeTextField,
	(props, context, wrappedElem) => {
		const value = getInputFieldValue(props, context, wrappedElem) || '';

		return value !== '';
	},
	setInputFieldValue,
	getInputFieldValue,
);

type FormAdditionalTaxCodeTextFieldProps = Omit<AdditionalTaxCodeTextFieldProps, 'value' | 'onChange'> &
	Partial<Pick<AdditionalTaxCodeTextFieldProps, 'onChange'>> &
	FormInputProps;

const FormAdditionalTaxCodeTextField: React.FC<FormAdditionalTaxCodeTextFieldProps> = props => {
	const errorValidations = useMemo(
		() => [
			(data: FormState<any>) => {
				const value = getFormValue({
					formObject: data.formObject,
					editedObject: data.editedBriefObjects,
					props,
				});

				return !value ? true : detectIsAdditionalTaxCode(value);
			},
			...(props.errorValidations || []),
		],
		[],
	);
	const errorMsgs = useMemo(() => ['Номер должен содержать 9 символов', ...(props.errorMsgs || [])], []);

	return <FormAdditionalTaxCodeTextFieldBase {...props} errorValidations={errorValidations} errorMsgs={errorMsgs} />;
};

export { AdditionalTaxCodeTextField, FormAdditionalTaxCodeTextField };
