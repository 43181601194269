import { createStaticDataAction } from '../../../core/libs/action-creator';


const SET_BILLING_DISPLAY_PATH = '[PLATFORM]: SET_BILLING_DISPLAY_PATH';
const RESET_BILLING_DISPLAY_PATH = '[PLATFORM]: RESET_BILLING_DISPLAY_PATH';


function setBillingDisplayPathAction(path: string) {
	return createStaticDataAction(SET_BILLING_DISPLAY_PATH)(path);
}

function resetBillingDisplayPathAction() {
	return createStaticDataAction(RESET_BILLING_DISPLAY_PATH)();
}

export {
	SET_BILLING_DISPLAY_PATH,
	RESET_BILLING_DISPLAY_PATH,
	setBillingDisplayPathAction,
	resetBillingDisplayPathAction,
}
