export let inputWidth = 140;
export let marginBetweenInputs = 30;

export interface ISizes<T> {
	s: T;
	m: T;
	l: T;
	xl: T;
	xxl: T;
}
export let sizes:ISizes<number> = {
	s: 210,
	m: inputWidth,
	l: 2 * inputWidth + (2 - 1) * marginBetweenInputs,
	xl: inputWidth + inputWidth + marginBetweenInputs,
	xxl: 4 * inputWidth + (4 - 1) * marginBetweenInputs,
}

export let views = {
	default: '',
	extra: ''
}