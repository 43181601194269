// @ts-nocheck
import React from 'react';

import { IStackLayoutModuleStyled, Root, Item } from './styled';

declare namespace IStackLayoutModule {
	interface IStackLayoutProps extends IStackLayoutModuleStyled.IRoot {
		style?: React.CSSProperties;
		children: React.ReactNode;
	}
	interface IStackLayoutItemProps extends IStackLayoutModuleStyled.IItem {
		style?: React.CSSProperties;
		children: React.ReactNode;
	}
}

class StackLayoutItem extends React.Component<IStackLayoutModule.IStackLayoutItemProps> {
	static defaultProps = {};

	render() {
		const { children, ...rest } = this.props;

		return <Item {...rest}>{this.props.children}</Item>;
	}
}

class StackLayout extends React.Component<IStackLayoutModule.IStackLayoutProps> {
	static defaultProps = {
		inline: false,
		orientation: 'horizontal',
		horizontalAligment: 'left',
		verticalAligment: 'top',
	};
	static Item = StackLayoutItem;

	render() {
		const { children, ...rest } = this.props;

		return <Root {...rest}>{this.props.children}</Root>;
	}
}

export default StackLayout;
