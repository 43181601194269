import { createStaticAction } from '@flux';
import { types } from './types';
import { ThemeIdentifier } from '@theme';

const actions = {
	setThemeIdentifier: (themeIdentifier: ThemeIdentifier) => {
		return createStaticAction(types.SET_THEME_IDENTIFIER)(themeIdentifier);
	},
};

export const themeActionsPack = actions;
