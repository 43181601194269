import React from 'react';

import { Root, BadgeColors, Bar, Content } from './styled';

export type BadgeProps = {
	appearance?: 'default' | 'progress-bar' | 'contained';
	color?: BadgeColors;
	value?: number;
	children: React.ReactNode;
};

const Badge: React.FC<BadgeProps> = ({ appearance, value, children, ...rest }) => {
	return (
		<Root appearance={appearance} value={value} {...rest}>
			{appearance === 'progress-bar' && <Bar value={value} />}
			<Content>{children}</Content>
		</Root>
	);
};

Badge.defaultProps = {
	appearance: 'default',
	color: BadgeColors.default,
};

export { Badge, BadgeColors };
