import { combineReducers } from 'redux';

import mainIntegrationsReducer, { MainIntegrationsState } from './main.reducer';


export type IntegrationsState = {
	main: MainIntegrationsState;
};

const integrationsReducer = combineReducers<IntegrationsState>({
	main: mainIntegrationsReducer,
});

export default integrationsReducer;
