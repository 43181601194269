import styled, { css } from 'styled-components';

import { fade } from '@utils/color';

const FlatTabsRoot = styled.div`
	position: relative;
	width: 100%;
	height: auto;
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;
	align-items: center;
`;

interface IFlatTabRoot {
	isActive: boolean;
	value: number;
}

const FlatTabRoot = styled.div`
	position: relative;
	width: 100%;
	height: auto;
	min-height: 100px;
	display: none;

	${(props: IFlatTabRoot) =>
		props.isActive &&
		css`
			display: block;
		`}
`;

const FlatTabsHeaderLayout = styled.div`
	position: relative;
	z-index: 10;
	margin-bottom: -1px;
	width: 100%;
	height: auto;
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;
	align-items: center;
	border-left: 1px solid transparent;

	${p => css`
		border-color: ${p.theme.palette.stealth};
	`}
`;

interface IFlatTabsContentLayout {
	maxContentHeight: string;
	withoutHeader: boolean;
}

const FlatTabsContentLayout = styled.div`
	position: relative;
	z-index: 1;
	width: 100%;
	height: auto;
	max-height: ${(props: IFlatTabsContentLayout) => (props.maxContentHeight ? props.maxContentHeight : 'none')};
	overflow-y: auto;
	border: 1px solid transparent;
	padding: 20px;

	${p => css`
		border-color: ${p.theme.palette.stealth};
	`}

	${(props: IFlatTabsContentLayout) =>
		props.withoutHeader &&
		css`
			border: none;
			padding: 0;
		`}
`;

interface IFlatTabsButton {
	isActive: boolean;
}

const FlatTabsButton = styled.button<IFlatTabsButton>`
	width: auto;
	height: 80px;
	max-width: 220px;
	margin: 0;
	padding: 22px;
	outline: 0;
	border: 1px solid transparent;
	cursor: pointer;
	border-left: none;

	${p => css`
		background-color: ${fade(p.theme.palette.stealth, 0.3)};
		border-color: ${p.theme.palette.stealth};
	`}

	${p =>
		p.isActive &&
		css`
			background-color: transparent
			border-bottom: none;
		`}
`;

export { FlatTabsRoot, FlatTabRoot, FlatTabsHeaderLayout, FlatTabsContentLayout, FlatTabsButton };
