import React from 'react';

import { IconBase, IconBaseProps } from './base';

export type ExpandLessIconProps = IconBaseProps;

const ExpandLessIcon: React.FC<ExpandLessIconProps> = props => {
	return (
		<IconBase {...props}>
			<svg
				stroke='currentColor'
				fill='currentColor'
				strokeWidth='0'
				viewBox='0 0 24 24'
				xmlns='http://www.w3.org/2000/svg'>
				<path d='M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z'></path>
			</svg>
		</IconBase>
	);
};

export { ExpandLessIcon };
