import styled, { css } from 'styled-components';

const Root = styled.div`
	position: relative;
	width: 100%;
	height: auto;
`;

const ContentLayout = styled.div`
	position: relative;
	width: 100%;
	height: auto;
	padding: 30px 40px 0 40px;
`;

const FormLayout = styled.div`
	width: 100%;
	height: auto;
	display: flex;
	flex-flow: column nowrap;
	justify-content: space-between;
	align-items: flex-start;
`;

const RadioButtonLayout = styled.div`
	width: 100%;
	height: auto;
	margin-bottom: 16px;
`;

const radioButtonGroupStyle = {
	width: '100%',
};

const radioButtonStyle = {
	margin: '0 0 10px 0',
};

const radioButtonLabelStyle = {
	width: '100%',
	fontSize: '14px',
	marginLeft: '-4px',
};

const DateRangeLayout = styled.div`
	width: 100%;
	height: auto;
	min-height: 50px;
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;
	align-items: center;

	${p => css`
		background-color: ${p.theme.palette.background};
	`}
`;

export {
	Root,
	ContentLayout,
	FormLayout,
	RadioButtonLayout,
	DateRangeLayout,
	radioButtonGroupStyle,
	radioButtonStyle,
	radioButtonLabelStyle,
};
