import React, { useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { ROUTE_MAP } from '@core/routes/urls';
import { SelectDate } from '@funds-registers/components/cms-import-period-select';
import { CMSDataType } from '@funds-registers/models';
import { mainFundsRegistersSelectorsPack } from '@funds-registers/selectors';
import { mainPaymentsActionsPack } from '@payments/actions';
import { Alert } from '@ui/alert';
import { DefaultContent } from '@ui/drawer/default-drawer';
import { RaisedButton } from '@ui/raised-button';
import { Spinner, SpinnerAutoLayout } from '@ui/spinner';
import { extractFundsRegisterIdAfterImport, formatEntity, formatRegisterNumberByMask, getDrawer } from '@utils';
import { OperationType } from '../cms-import-period-select/model';
import {
	ContentLayout,
	ControlsLayout,
	Divider,
	Indicator,
	Register,
	RegisterEntity,
	RegisterEntityLayout,
	RegisterName,
	RegisterNameLayout,
	RegisterNumber,
	RegistersLayout,
	Root,
	SelectDateLayout,
	WrapLayout,
} from './styled';

export type XImportCmsAccountListProps = {
	cmsAccounts: CMSAccount[];
	dataType?: CMSDataType;
	fundsRegisterStatisticsMap: ReturnType<typeof mainFundsRegistersSelectorsPack.selectFundsRegisterStatisticsMap>;
	isFetching: boolean;
	onStartImport: (register: CMSAccount) => void;
	setFundsRegisterID: typeof mainPaymentsActionsPack.setFundsRegisterID;
	status: AsyncProgressStatus;
};

const XImportCmsAccountList: React.FC<XImportCmsAccountListProps> = props => {
	const { cmsAccounts, dataType, fundsRegisterStatisticsMap, isFetching, onStartImport, setFundsRegisterID, status } =
		props;
	const history = useHistory();
	const operation =
		dataType === CMSDataType.MARKETPLACE_TRANSACTION ? OperationType.TRANSACTIONS : OperationType.PAYMENTS;

	useLayoutEffect(() => {
		getDrawer().setContent({
			footer: null,
		});
	}, []);

	const handleClose = () => {
		getDrawer().close();
	};

	const renderButton = (register: CMSAccount) => {
		const goToAccount = () => {
			const fundsRegisterID = extractFundsRegisterIdAfterImport(fundsRegisterStatisticsMap, register.AccountNumber);
			setFundsRegisterID(fundsRegisterID);
			history.push(ROUTE_MAP.PAYMENTS);
			handleClose();
		};

		let SelectedButton = (
			<RaisedButton type='primary' onClick={() => onStartImport(register)}>
				Добавить
			</RaisedButton>
		);

		Object.keys(fundsRegisterStatisticsMap).forEach(key => {
			if (fundsRegisterStatisticsMap[key].FundsRegister.RegisterNumber === register.AccountNumber) {
				SelectedButton = <RaisedButton onClick={goToAccount}>Перейти</RaisedButton>;
			}
		});

		return SelectedButton;
	};

	const renderIndicator = (register: CMSAccount) => {
		let message = '';

		Object.keys(fundsRegisterStatisticsMap).forEach(key => {
			if (fundsRegisterStatisticsMap[key].FundsRegister.RegisterNumber === register.AccountNumber) {
				message = 'Счёт добавлен';
			}
		});

		return <Indicator>{message}</Indicator>;
	};

	const renderCmsAccounts = () => {
		if (status.status === 'FAILED') {
			return (
				<DefaultContent>
					<Alert appearance='danger'>{status.message}</Alert>
				</DefaultContent>
			);
		}

		if (cmsAccounts.length === 0) {
			return (
				<DefaultContent>
					<Alert appearance='warning'>Нет доступных счетов для подключения.</Alert>
				</DefaultContent>
			);
		}
		return cmsAccounts.map((cmsAccount: CMSAccount, i) => {
			return (
				<WrapLayout key={i}>
					<Register>
						<RegisterNameLayout>
							<RegisterName>{formatEntity(cmsAccount.AccountName) || 'Наименование не указано'}</RegisterName>
						</RegisterNameLayout>
						{cmsAccount.LegalEntityName && (
							<RegisterEntityLayout>
								<RegisterEntity>{formatEntity(cmsAccount.LegalEntityName)}</RegisterEntity>
							</RegisterEntityLayout>
						)}
						<RegisterNumber>{formatRegisterNumberByMask(cmsAccount.AccountNumber)}</RegisterNumber>
						<ControlsLayout>
							{renderIndicator(cmsAccount)}
							{renderButton(cmsAccount)}
						</ControlsLayout>
					</Register>
					{i !== cmsAccounts.length - 1 && <Divider />}
				</WrapLayout>
			);
		});
	};

	return (
		<Root>
			{!isFetching && (
				<SelectDateLayout>
					<SelectDate disabled={cmsAccounts.length === 0} operation={operation} />
				</SelectDateLayout>
			)}
			<ContentLayout>
				{isFetching ? (
					<SpinnerAutoLayout appearance='max-drawer'>
						<Spinner />
					</SpinnerAutoLayout>
				) : (
					<RegistersLayout>{renderCmsAccounts()}</RegistersLayout>
				)}
			</ContentLayout>
		</Root>
	);
};

export { XImportCmsAccountList };
