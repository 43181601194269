function hasKeys(object: object | Array<unknown>) {
	return object && Object.keys(object).length > 0;
}

function hasBooleanKeys(object: object) {
	return Boolean(object && Object.keys(object).filter(key => Boolean(object[key])).length > 0);
}

function everyKeyIsTrue(object: object): boolean {
	return object && Object.keys(object).every(key => object[key]);
}

function everyKeyIsFalse(object: object): boolean {
	return object && Object.keys(object).every(key => !object[key]);
}

function extractKeysToArray<T = string>(object: object, transformKey: (key) => T = x => x) {
	return object ? Object.keys(object).map(key => transformKey(key)) : [];
}

function extractTrueKeysToArray<T = string>(object: object, transformKey: (key) => T = x => x) {
	return object
		? Object.keys(object)
				.filter(key => object[key])
				.map(key => transformKey(key))
		: [];
}

function createBooleanMap<T = any>(items: Array<T> = [], getID: (item: T) => number | string): Record<string, boolean> {
	return items.reduce((acc, x) => ((acc[getID(x)] = true), acc), {});
}

function createPositionsMap<T = any>(
	items: Array<T> = [],
	getID: (item: T) => number | string,
): Record<string, number> {
	return items.reduce((acc, x, idx) => ((acc[getID(x)] = idx), acc), {});
}

function createObjectMap<T = any>(
	items: Array<T> = [],
	getID: (item: T, idx: number) => number | string,
): Record<string, T> {
	return items.reduce((acc, x, idx) => ((acc[getID(x, idx)] = x), acc), {});
}

function createListFromMap<T = any>(map: Record<string, T> = {}): Array<T> {
	return Object.keys(map).reduce((acc, key) => (acc.push(map[key]), acc), []);
}

function isPropsDifferent<T extends object>(prevProps: T, props: T): boolean {
	if (!prevProps || !props) return true;
	const prevKeys = Object.keys(prevProps);
	const keys = Object.keys(props);
	if (prevKeys.length !== keys.length) return true;
	const allKeys = Array.from(new Set([...prevKeys, ...keys]));

	for (const key of allKeys) {
		if (prevProps[key] !== props[key]) return true;
	}

	return false;
}

type MergeOnlyKeysOptions<T> = {
	target: T;
	source: Partial<T>;
	keys: Array<keyof T>;
};

function mergeOnlyKeys<T extends object = {}>(options: MergeOnlyKeysOptions<T>) {
	const { target, source, keys } = options;

	for (const key of keys) {
		target[key] = typeof source[key] !== 'undefined' ? source[key] : target[key];
	}

	return target;
}

function reduceMap<T extends object>(
	source: Record<string, T>,
	extractor: (item: T, key: string) => T[keyof T],
): Record<string, T[keyof T]> {
	const target: Record<string, T[keyof T]> = {};

	for (const key of Object.keys(source)) {
		target[key] = extractor(source[key], key);
	}

	return target;
}

function extractUniq<T>(items: Array<T>, getID: (x: T) => SimpleID) {
	const map = {};
	const uniq: Array<T> = [];

	for (const item of items) {
		const ID = getID(item);

		if (!map[ID]) {
			uniq.push(item);
		}

		map[ID] = true;
	}

	return uniq;
}

export {
	hasKeys,
	hasBooleanKeys,
	everyKeyIsTrue,
	everyKeyIsFalse,
	extractKeysToArray,
	extractTrueKeysToArray,
	createBooleanMap,
	createPositionsMap,
	createObjectMap,
	createListFromMap,
	isPropsDifferent,
	mergeOnlyKeys,
	reduceMap,
	extractUniq,
};
