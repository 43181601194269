import { DataSourceTreeNode } from '@core/api/core';
import { createBooleanMap, extractKeysToArray, createObjectMap } from '@utils/object';
import { flatten, groupBy } from '@utils/helpers';

type CreateDataSourceTreeOptions<T, P = any, B = any> = {
	items: Array<T>;
	filter?: (item: T) => boolean;
	root?: DataSourceTreeNode<T>;
	getID: (item: T | P) => SimpleID;
	getName?: (item: T) => string;
	getParentID?: (item: T) => SimpleID;
	getChildItems: (item: T) => Array<T | P>;
	createBox?: (item: T) => B;
	itemsMap?: Record<string, T>;
};

function createDataSourceTree<T, P = any, B = any>(
	options: CreateDataSourceTreeOptions<T, P, B>,
): DataSourceTreeNode<T, B> {
	const {
		items = [],
		filter = () => true,
		root = new DataSourceTreeNode({ Name: 'root' }),
		getID,
		getParentID,
		getName,
		getChildItems,
		createBox = () => null,
		itemsMap = items.reduce((acc, x) => ((acc[getID(x)] = x), acc), {}) as Record<string, T>,
	} = options;

	for (const item of items) {
		if (filter(item)) {
			const parentNode = new DataSourceTreeNode({
				ID: getID(item),
				Name: getName(item),
				ChildItems: [],
				ParentID: getParentID(item),
				value: item,
				box: createBox(item),
			});

			root.ChildItems.push(parentNode);

			for (const childItem of getChildItems(item)) {
				const childValue = itemsMap[getID(childItem)];
				if (!childValue) continue;
				const childItems = getChildItems(childValue)
					.map(x => {
						return itemsMap[getID(x)];
					})
					.filter(Boolean);
				const childNode = new DataSourceTreeNode({
					ID: getID(childValue),
					Name: getName(childValue),
					ChildItems: [],
					ParentID: parentNode.ID,
					value: childValue,
					box: createBox(childValue),
				});

				if (filter(childValue)) {
					parentNode.ChildItems.push(childNode);

					createDataSourceTree({
						items: childItems,
						root: childNode,
						filter,
						getID,
						getParentID,
						getName,
						getChildItems,
						itemsMap,
					});
				}
			}
		}
	}

	return root;
}

type GetAllParentIDsOptions<T> = {
	ID: SimpleID;
	getID: (item: T) => SimpleID;
	getParentID: (item: T) => SimpleID;
	items?: Array<T>;
	itemsMap?: Record<string, T>;
	parentIDs?: Array<SimpleID>;
	parentIDsMap?: Record<string, boolean>;
	rootID?: SimpleID;
};

function getAllParentIDs<T>(options: GetAllParentIDsOptions<T>) {
	const {
		ID,
		getID,
		getParentID,
		items = [],
		itemsMap = createObjectMap(items, x => getID(x)),
		parentIDs = [],
		rootID = -1,
	} = options;
	const parentID = getParentID(itemsMap[ID]);

	if (parentID && parentID !== rootID) {
		parentIDs.push(parentID);
		getAllParentIDs({ ...options, ID: parentID, parentIDs });
	}

	return parentIDs;
}

type GetAllChildItemIDsOptions<T = any> = {
	ID: SimpleID;
	items: Array<T>;
	getID: (item: T) => SimpleID;
	getChildItems: (item: T) => Array<T>;
	childItemIDs?: Array<SimpleID>;
	childItemsIDsMap?: Record<string, boolean>;
	itemsMap?: Record<string, T>;
};

function getAllChildItemIDs<T>(options: GetAllChildItemIDsOptions<T>) {
	const {
		ID,
		items = [],
		getID,
		getChildItems,
		childItemIDs = [],
		childItemsIDsMap = {},
		itemsMap = items.reduce((acc, x) => ((acc[getID(x)] = x), acc), {}) as Record<string, T>,
	} = options;
	const item = itemsMap[ID];
	const childItems = getChildItems(item) || [];

	for (const childItem of childItems) {
		const childItemID = getID(childItem);
		const hasChildren = getChildItems(childItem)?.length > 0;

		if (!childItemsIDsMap[childItemID]) {
			childItemsIDsMap[childItemID] = true;
			childItemIDs.push(childItemID);
		}

		if (hasChildren) {
			getAllChildItemIDs({
				ID: childItemID,
				items,
				getID,
				getChildItems,
				childItemIDs,
				childItemsIDsMap,
				itemsMap,
			});
		}
	}

	return childItemIDs;
}

type GetAllChildItemIDsMapOptions = {
	IDs: Array<SimpleID>;
} & Pick<GetAllChildItemIDsOptions, 'items' | 'getID' | 'getChildItems'>;

function getAllChildItemIDsMap(options: GetAllChildItemIDsMapOptions) {
	const { IDs = [], items, getID, getChildItems } = options;
	const childIDs = flatten(
		IDs.map(x => {
			const IDs = getAllChildItemIDs({
				ID: Number(x),
				items,
				getID,
				getChildItems,
			});

			return IDs || [];
		}),
	);

	return createBooleanMap(childIDs, x => x);
}

type CrateExpandedMapOptions<T> = {
	isExpanded: boolean;
	itemID: SimpleID;
	items: Array<T>;
	getID: (x: T) => SimpleID;
	getChildItemIDs: (x: T) => Array<SimpleID>;
	itemsMap?: Record<string, T>;
	initialMap?: Record<string, boolean>;
};

function crateExpandedMap<T>(options: CrateExpandedMapOptions<T>) {
	const {
		isExpanded,
		itemID,
		items,
		getID,
		getChildItemIDs,
		initialMap = {},
		itemsMap = items.reduce((acc, x) => ((acc[getID(x)] = x), acc), {}) as Record<string, T>,
	} = options;
	const item = itemsMap[itemID];

	initialMap[itemID] = isExpanded;

	if (!isExpanded) {
		for (const itemID of getChildItemIDs(item)) {
			crateExpandedMap({
				isExpanded,
				itemID,
				getID,
				getChildItemIDs,
				initialMap,
				items,
				itemsMap,
			});
		}
	}

	return initialMap;
}

type TransformNestedTreeToFlattenTreeOptions<T> = {
	items: Array<T>;
	getChildItems: (x: T) => Array<T>;
};

function transformNestedTreeToFlattenTree<T>(options: TransformNestedTreeToFlattenTreeOptions<T>) {
	const { items, getChildItems } = options;
	const list: Array<T> = [];

	for (const item of items) {
		list.push(item);
		const childItems = getChildItems(item);

		if (childItems.length > 0) {
			list.push(
				...transformNestedTreeToFlattenTree({
					items: childItems,
					getChildItems,
				}),
			);
		}
	}

	return list;
}

type CreateSortedFlattenTreeOptions<T> = {
	items: Array<T>;
	parentID?: SimpleID;
	getID: (item: T) => SimpleID;
	getParentID: (item: T) => SimpleID;
	getChildItemIDs: (x: T) => Array<SimpleID>;
	sortFn?: (a: T, b: T) => number;
};

function createSortedFlattenTree<T>(options: CreateSortedFlattenTreeOptions<T>) {
	const { items, parentID = -1, getID, getParentID, getChildItemIDs, sortFn } = options;
	const list: Array<T> = [];

	if (parentID === -1 && typeof sortFn === 'function') {
		items.sort(sortFn);
	}

	for (const item of items) {
		if (getParentID(item) === parentID) {
			list.push(item);
			if (getChildItemIDs(item).length === 0) continue;

			list.push(
				...createSortedFlattenTree({
					parentID: getID(item),
					items,
					getID,
					getParentID,
					getChildItemIDs,
				}),
			);
		}
	}

	return list;
}

type DeepFilterOptions<T, P> = {
	item: T;
	items: Array<T>;
	filter: (item: T) => boolean;
	getID: (item: T | P) => SimpleID;
	getChildItems: (item: T) => Array<T | P>;
	itemsMap?: Record<string, T>;
};

function deepFilter<T, P>(options: DeepFilterOptions<T, P>) {
	const {
		item,
		items,
		filter,
		getID,
		getChildItems,
		itemsMap = items.reduce((acc, x) => ((acc[getID(x)] = x), acc), {}),
	} = options;
	let isPassed = filter(item);

	for (const childItem of getChildItems(item)) {
		const childValue = itemsMap[getID(childItem)];
		if (!childValue) continue;
		const isChildPassed = deepFilter<T, P>({
			item: childValue,
			items,
			filter,
			getID,
			getChildItems,
			itemsMap,
		});

		isPassed = isPassed || isChildPassed;
	}

	return isPassed;
}

type GetCurrentLevelInFlattenTreeOptions<T> = {
	itemID: SimpleID;
	items: Array<T>;
	getID: (item: T) => SimpleID;
	getParentID?: (item: T) => SimpleID;
	itemsMap?: Record<string, T>;
};

function getCurrentLevelInFlattenTree<T>(options: GetCurrentLevelInFlattenTreeOptions<T>): number {
	const {
		itemID,
		items,
		getID,
		getParentID,
		itemsMap = items.reduce((acc, x) => ((acc[getID(x)] = x), acc), {}),
	} = options;
	const item = itemsMap[itemID];
	const parentID = getParentID(item);
	const parent = itemsMap[parentID];
	const level = 1;

	if (parentID === -1) return level;

	const shift = getCurrentLevelInFlattenTree({
		itemID: parentID,
		items,
		getID,
		getParentID,
		itemsMap,
	});

	return parent ? level + shift : level;
}

function getNestedContentShift(levelNumber: number, standardShift = 20) {
	return levelNumber > 1 ? (levelNumber - 1) * standardShift : 0;
}

type ExecuteDeepFnOptions<T, R> = {
	itemID: SimpleID;
	items: Array<T>;
	getID: (item: T) => SimpleID;
	getChildItemIDs: (x: T) => Array<SimpleID>;
	fn: (item: T) => R;
	transformResult?: (values: Array<R>) => any;
	itemsMap?: Record<string, T>;
	values?: Array<R>;
};

function executeDeepFn<T, R, S>(options: ExecuteDeepFnOptions<T, R>): S {
	const {
		itemID,
		items,
		getID,
		getChildItemIDs,
		fn,
		transformResult = x => x,
		itemsMap = items.reduce((acc, x) => ((acc[getID(x)] = x), acc), {}),
		values = [],
	} = options;
	const item = itemsMap[itemID];

	values.push(fn(item));

	for (const childID of getChildItemIDs(item)) {
		executeDeepFn({
			itemID: childID,
			items,
			getID,
			getChildItemIDs,
			fn,
			transformResult,
			itemsMap,
			values,
		});
	}

	return transformResult(values);
}

type SortNestedTreeByAlphabetOptions<T> = {
	items: Array<T>;
	getName: (x: T) => string;
	getChildItems: (x: T) => Array<T>;
};

function sortNestedTreeByAlphabet<T>(options: SortNestedTreeByAlphabetOptions<T>) {
	const { items, getName, getChildItems } = options;

	items.sort((a, b) => {
		if (!getName(a) || !getName(b)) return 0;
		return getName(a).toLocaleLowerCase() > getName(b).toLocaleLowerCase() ? 1 : -1;
	});

	for (const item of items) {
		const childItems = getChildItems(item) || [];

		if (childItems.length > 0) {
			sortNestedTreeByAlphabet({
				items: childItems,
				getName,
				getChildItems,
			});
		}
	}
}

type RestoreIncompleteTreeOptions<T> = {
	items: Array<T>;
	getID: (x: T) => SimpleID;
	getParentID: (x: T) => SimpleID;
	setChildItems: (item: T, childItems: Array<T>) => void;
};

function restoreIncompleteTree<T>(options: RestoreIncompleteTreeOptions<T>) {
	const { items, getID, getParentID, setChildItems } = options;
	const groupped = groupBy(items, x => getParentID(x));
	const itemsMap = createObjectMap(items, x => getID(x));
	const parentIDs = extractKeysToArray(groupped);

	for (const parentID of parentIDs) {
		const item = itemsMap[parentID];

		if (!item) continue;
		setChildItems(item, groupped[parentID] || []);
	}

	return items;
}

export type TreeSortingFunction<T> = (a: T, b: T) => -1 | 0 | 1;

export {
	createDataSourceTree,
	getAllParentIDs,
	getAllChildItemIDs,
	getAllChildItemIDsMap,
	crateExpandedMap,
	transformNestedTreeToFlattenTree,
	createSortedFlattenTree,
	deepFilter,
	getCurrentLevelInFlattenTree,
	getNestedContentShift,
	executeDeepFn,
	sortNestedTreeByAlphabet,
	restoreIncompleteTree,
};
