import styled, { css } from 'styled-components';

import { makeBoxShadows } from '@stylesheet/utils';
import type { SlidePanelState } from './slide-panel';

type RootProps = {} & Pick<SlidePanelState, 'isOpen'>;

const Root = styled.div<RootProps>`
	position: fixed;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	max-width: 100%;
	min-height: 70px;
	z-index: 1100;
	${makeBoxShadows(8)}
	transform: translateY(100%); /*for IE*/
	transform: translate(0, calc(100% + 50px));
	will-change: transform;
	transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;

	${p =>
		p.isOpen &&
		css`
			transform: translate(0, 0);
		`}

	${p => css`
		background-color: ${p.theme.palette.canvas};
	`}
`;

const Content = styled.div`
	width: 100%;
	max-width: 100%;
	min-height: 70px;
	padding: 0 70px 0 20px;
	display: flex;
`;

export { Root, Content };
