/***
 * Library contains JavaScript API for interaction with Seeneco Framework Web Services contained in PaymentsPackage
 * @author YasinskiyVI Generated by Seeneco Framework Modeler 
 *
 */
(function(w){
  /***
  * 
  */
  w.APIBudgetPayment = function() {
    this.PACKAGE = "PAYMENTS";
    this.CLASSIFIER = "APIBUDGET_PAYMENT";
    this.CopySupportingElement = false;
    this.BudgetClsCode = "";
    this.BudgetTerritoryCode = "";
    this.TaxPayerStatus = "";
    this.BudgetPaymentUID = "";
    this.BudgetPaymentReason = "";
    this.BudgetPaymentPeriod = "";
    this.BudgetDocumentNumber = "";
    this.BudgetPaymentDate = null;
    this.BudgetPaymentType = "";
    this.PayerAccount = null;
    this.RecipientAccount = null;
    this.Amount = -1;
    this.PaymentDate = null;
    this.Reference = "";
    this.Payer = null;
    this.Recipient = null;
    this.UID = "";
    this.PaymentState = null;
  };
  /***
  * 
  */
  w.APICounterpartyPayment = function() {
    this.PACKAGE = "PAYMENTS";
    this.CLASSIFIER = "APICOUNTERPARTY_PAYMENT";
    this.CopySupportingElement = false;
    this.PayerAccount = null;
    this.RecipientAccount = null;
    this.Amount = -1;
    this.PaymentDate = null;
    this.Reference = "";
    this.Payer = null;
    this.Recipient = null;
    this.UID = "";
    this.PaymentState = null;
  };
  /***
  * 
  */
  w.APISendCounterpartyPaymentResponse = function() {
    this.PACKAGE = "PAYMENTS";
    this.CLASSIFIER = "APISEND_COUNTERPARTY_PAYMENT_RESPONSE";
    this.CopySupportingElement = false;
    this.Payment = null;
    this.Message = "";
    this.Status = "";
  };
  /***
  * 
  */
  w.BudgetPayment = function() {
    this.PACKAGE = "PAYMENTS";
    this.CLASSIFIER = "BUDGET_PAYMENT";
    this.CopySupportingElement = false;
    this.BudgetClsCode = "";
    this.BudgetTerritoryCode = "";
    this.TaxPayerStatus = "";
    this.BudgetPaymentUID = "";
    this.BudgetPaymentReason = "";
    this.BudgetPaymentPeriod = "";
    this.BudgetDocumentNumber = "";
    this.BudgetPaymentDate = null;
    this.BudgetPaymentType = "";
    this.Direction = -1;
    this.AccountsChartItem = null;
    this.PaymentState = null;
    this.Counterparty = null;
    this.FundsRegister = null;
    this.ResponsibleEmployee = null;
    this.Project = null;
    this.TreasuryApplicationID = -1;
    this.InvoiceBundleID = -1;
    this.CounterpartyAccount = null;
    this.BankDocumentNumber = "";
    this.DocumentDate = null;
    this.BankOperationType = "";
    this.LinkedPLOperations = [];
    this.PaymentAmount = -1;
    this.ID = -1;
    this.PaymentDate = null;
    this.Reference = "";
    this.BusinessUnitID = -1;
    this.UID = "";
    this.LimitedAccess = false;
    this.VatValue = -1;
    this.VatPercent = -1;
    this.CurrencyVatValue = -1;
  };
  /***
  * 
  */
  w.BudgetPaymentBrief = function() {
    this.PACKAGE = "PAYMENTS";
    this.CLASSIFIER = "BUDGET_PAYMENT_BRIEF";
    this.CopySupportingElement = false;
    this.BudgetClsCode = "";
    this.BudgetTerritoryCode = "";
    this.TaxPayerStatus = "";
    this.BudgetPaymentUID = "";
    this.BudgetPaymentReason = "";
    this.BudgetPaymentPeriod = "";
    this.BudgetDocumentNumber = "";
    this.BudgetPaymentDate = null;
    this.BudgetPaymentType = "";
    this.Direction = -1;
    this.AccountsChartItem = null;
    this.PaymentState = null;
    this.Counterparty = null;
    this.FundsRegister = null;
    this.ResponsibleEmployee = null;
    this.Project = null;
    this.TreasuryApplicationID = -1;
    this.InvoiceBundleID = -1;
    this.CounterpartyAccount = null;
    this.BankDocumentNumber = "";
    this.DocumentDate = null;
    this.BankOperationType = "";
    this.HasInvoices = false;
    this.LinkedPLOperations = [];
    this.PaymentAmount = -1;
    this.ID = -1;
    this.PaymentDate = null;
    this.Reference = "";
    this.BusinessUnitID = -1;
    this.UID = "";
    this.LimitedAccess = false;
    this.VatValue = -1;
    this.VatPercent = -1;
    this.CurrencyVatValue = -1;
  };
  /***
  * 
  */
  w.CashflowOperation = function() {
    this.PACKAGE = "PAYMENTS";
    this.CLASSIFIER = "CASHFLOW_OPERATION";
    this.CopySupportingElement = false;
    this.LinkedPLOperations = [];
    this.PaymentAmount = -1;
    this.ID = -1;
    this.PaymentDate = null;
    this.Reference = "";
    this.BusinessUnitID = -1;
    this.UID = "";
    this.LimitedAccess = false;
    this.VatValue = -1;
    this.VatPercent = -1;
    this.CurrencyVatValue = -1;
  };
  /***
  * 
  */
  w.CashflowOperationBase = function() {
    this.PACKAGE = "PAYMENTS";
    this.CLASSIFIER = "CASHFLOW_OPERATION_BASE";
    this.CopySupportingElement = false;
    this.PaymentAmount = -1;
    this.ID = -1;
    this.PaymentDate = null;
    this.Reference = "";
    this.BusinessUnitID = -1;
    this.UID = "";
    this.LimitedAccess = false;
    this.VatValue = -1;
    this.VatPercent = -1;
    this.CurrencyVatValue = -1;
  };
  /***
  * 
  */
  w.CashflowOperationBrief = function() {
    this.PACKAGE = "PAYMENTS";
    this.CLASSIFIER = "CASHFLOW_OPERATION_BRIEF";
    this.CopySupportingElement = false;
    this.LinkedPLOperations = [];
    this.PaymentAmount = -1;
    this.ID = -1;
    this.PaymentDate = null;
    this.Reference = "";
    this.BusinessUnitID = -1;
    this.UID = "";
    this.LimitedAccess = false;
    this.VatValue = -1;
    this.VatPercent = -1;
    this.CurrencyVatValue = -1;
  };
  /***
  * 
  */
  w.CashflowOperationFilter = function() {
    this.PACKAGE = "PAYMENTS";
    this.CLASSIFIER = "CASHFLOW_OPERATION_FILTER";
    this.CopySupportingElement = false;
    this.CashflowItemSet = false;
    this.PeriodDateStart = null;
    this.PeriodDateEnd = null;
    this.FundsRegisterID = -1;
    this.PaymentStateCode = "";
    this.IDList = [];
    this.Reference = "";
    this.ProjectSet = false;
    this.FilterType = null;
    this.UseStandardPaymentRules = false;
    this.PaymentStateCodes = [];
    this.BusinessUnitSet = false;
    this.CounterpartyID = -1;
    this.VatNotCalculated = false;
  };
  /***
  * 
  */
  w.CashflowOperationsImportRequest = function() {
    this.PACKAGE = "PAYMENTS";
    this.CLASSIFIER = "CASHFLOW_OPERATIONS_IMPORT_REQUEST";
    this.CopySupportingElement = false;
    this.FileReference = null;
    this.OperationUID = -1;
  };
  /***
  * 
  */
  w.ExecuteMassUpdateVATRequest = function() {
    this.PACKAGE = "PAYMENTS";
    this.CLASSIFIER = "EXECUTE_MASS_UPDATE_VATREQUEST";
    this.CopySupportingElement = false;
    this.ExecutionProcessUID = -1;
    this.OperationsFilter = null;
  };
  /***
  * 
  */
  w.ExecuteMassUpdateVATResult = function() {
    this.PACKAGE = "PAYMENTS";
    this.CLASSIFIER = "EXECUTE_MASS_UPDATE_VATRESULT";
    this.CopySupportingElement = false;
    this.AffectedPaymentsCount = -1;
    this.Status = "";
    this.TotalPaymentsCount = -1;
    this.ExecutionProcessUID = -1;
  };
  /***
  * 
  */
  w.Payment = function() {
    this.PACKAGE = "PAYMENTS";
    this.CLASSIFIER = "PAYMENT";
    this.CopySupportingElement = false;
    this.Direction = -1;
    this.AccountsChartItem = null;
    this.PaymentState = null;
    this.Counterparty = null;
    this.FundsRegister = null;
    this.ResponsibleEmployee = null;
    this.Project = null;
    this.TreasuryApplicationID = -1;
    this.InvoiceBundleID = -1;
    this.CounterpartyAccount = null;
    this.BankDocumentNumber = "";
    this.DocumentDate = null;
    this.BankOperationType = "";
    this.LinkedPLOperations = [];
    this.PaymentAmount = -1;
    this.ID = -1;
    this.PaymentDate = null;
    this.Reference = "";
    this.BusinessUnitID = -1;
    this.UID = "";
    this.LimitedAccess = false;
    this.VatValue = -1;
    this.VatPercent = -1;
    this.CurrencyVatValue = -1;
  };
  /***
  * 
  */
  w.PaymentBrief = function() {
    this.PACKAGE = "PAYMENTS";
    this.CLASSIFIER = "PAYMENT_BRIEF";
    this.CopySupportingElement = false;
    this.Direction = -1;
    this.AccountsChartItem = null;
    this.PaymentState = null;
    this.Counterparty = null;
    this.FundsRegister = null;
    this.ResponsibleEmployee = null;
    this.Project = null;
    this.TreasuryApplicationID = -1;
    this.InvoiceBundleID = -1;
    this.CounterpartyAccount = null;
    this.BankDocumentNumber = "";
    this.DocumentDate = null;
    this.BankOperationType = "";
    this.HasInvoices = false;
    this.LinkedPLOperations = [];
    this.PaymentAmount = -1;
    this.ID = -1;
    this.PaymentDate = null;
    this.Reference = "";
    this.BusinessUnitID = -1;
    this.UID = "";
    this.LimitedAccess = false;
    this.VatValue = -1;
    this.VatPercent = -1;
    this.CurrencyVatValue = -1;
  };
  /***
  * 
  */
  w.PaymentRule = function() {
    this.PACKAGE = "PAYMENTS";
    this.CLASSIFIER = "PAYMENT_RULE";
    this.CopySupportingElement = false;
    this.ID = -1;
    this.Active = false;
    this.AnyConditionFulfill = false;
    this.AccountsChartItemID = -1;
    this.Conditions = [];
    this.AppliedToCharge = false;
    this.ProjectID = -1;
    this.BusinessUnitID = -1;
    this.PaymentDirection = null;
  };
  /***
  * 
  */
  w.PaymentRuleCondition = function() {
    this.PACKAGE = "PAYMENTS";
    this.CLASSIFIER = "PAYMENT_RULE_CONDITION";
    this.CopySupportingElement = false;
    this.ID = -1;
    this.PaymentFieldSystemName = "";
    this.Operator = "";
    this.PaymentFieldValue = "";
  };
  /***
  * 
  */
  w.PaymentRuleExecutionRequest = function() {
    this.PACKAGE = "PAYMENTS";
    this.CLASSIFIER = "PAYMENT_RULE_EXECUTION_REQUEST";
    this.CopySupportingElement = false;
    this.ExecutionProcessUID = -1;
    this.OperationsFilter = null;
  };
  /***
  * 
  */
  w.PaymentRuleExecutionResult = function() {
    this.PACKAGE = "PAYMENTS";
    this.CLASSIFIER = "PAYMENT_RULE_EXECUTION_RESULT";
    this.CopySupportingElement = false;
    this.ExecutedRulesCount = -1;
    this.AffectedPaymentsCount = -1;
    this.Status = "";
    this.TotalPaymentsCount = -1;
    this.ExecutionProcessUID = -1;
  };
  /***
  * 
  */
  w.TransferCashflowOperation = function() {
    this.PACKAGE = "PAYMENTS";
    this.CLASSIFIER = "TRANSFER_CASHFLOW_OPERATION";
    this.CopySupportingElement = false;
    this.SourceFundsRegister = null;
    this.TargetFundsRegister = null;
    this.Counterparty = null;
    this.Expense = false;
    this.MirrorPaymentID = -1;
    this.LinkedPLOperations = [];
    this.PaymentAmount = -1;
    this.ID = -1;
    this.PaymentDate = null;
    this.Reference = "";
    this.BusinessUnitID = -1;
    this.UID = "";
    this.LimitedAccess = false;
    this.VatValue = -1;
    this.VatPercent = -1;
    this.CurrencyVatValue = -1;
  };
  /***
  * 
  */
  w.TransferCashflowOperationBrief = function() {
    this.PACKAGE = "PAYMENTS";
    this.CLASSIFIER = "TRANSFER_CASHFLOW_OPERATION_BRIEF";
    this.CopySupportingElement = false;
    this.SourceFundsRegister = null;
    this.TargetFundsRegister = null;
    this.Counterparty = null;
    this.Expense = false;
    this.MirrorPaymentID = -1;
    this.LinkedPLOperations = [];
    this.PaymentAmount = -1;
    this.ID = -1;
    this.PaymentDate = null;
    this.Reference = "";
    this.BusinessUnitID = -1;
    this.UID = "";
    this.LimitedAccess = false;
    this.VatValue = -1;
    this.VatPercent = -1;
    this.CurrencyVatValue = -1;
  };
  /***
  * 
  */
  w.CashflowOperationFilter = function() {
    this.PACKAGE = "PAYMENTS";
    this.CLASSIFIER = "CASHFLOW_OPERATION_FILTER";
    this.CopySupportingElement = false;
    this.CashflowItemSet = false;
    this.PeriodDateStart = null;
    this.PeriodDateEnd = null;
    this.FundsRegisterID = -1;
    this.PaymentStateCode = "";
    this.IDList = [];
    this.Reference = "";
    this.ProjectSet = false;
    this.FilterType = null;
    this.UseStandardPaymentRules = false;
    this.PaymentStateCodes = [];
    this.BusinessUnitSet = false;
    this.CounterpartyID = -1;
    this.VatNotCalculated = false;
  };
  /***
  * 
  */
  w.ExecuteMassUpdateVATRequest = function() {
    this.PACKAGE = "PAYMENTS";
    this.CLASSIFIER = "EXECUTE_MASS_UPDATE_VATREQUEST";
    this.CopySupportingElement = false;
    this.ExecutionProcessUID = -1;
    this.OperationsFilter = null;
  };
  /***
  * 
  */
  w.ExecuteMassUpdateVATResult = function() {
    this.PACKAGE = "PAYMENTS";
    this.CLASSIFIER = "EXECUTE_MASS_UPDATE_VATRESULT";
    this.CopySupportingElement = false;
    this.AffectedPaymentsCount = -1;
    this.Status = "";
    this.TotalPaymentsCount = -1;
    this.ExecutionProcessUID = -1;
  };
   
   
  
  /***
   * Client API to PaymentsServiceWS web service
   */
  w.PaymentsServiceClient = function() {
    /***
     * System name of target service
     */
    this.serviceName = "PaymentsServiceWSImpl";
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param payment [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PaymentsServiceClient.prototype.updatePayment = function(payment, callbackHandler) {
    var parameterNames = ["payment"];
    var paymentJSON = JSON.stringify(payment);
    var parameterValues = [paymentJSON];
    var soapMessage = buildSOAPMessage("updatePayment", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param payment [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PaymentsServiceClient.prototype.removePayment = function(payment, callbackHandler) {
    var parameterNames = ["payment"];
    var paymentJSON = JSON.stringify(payment);
    var parameterValues = [paymentJSON];
    var soapMessage = buildSOAPMessage("removePayment", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param paymentID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PaymentsServiceClient.prototype.getPaymentByID = function(paymentID, callbackHandler) {
    var parameterNames = ["paymentID"];
    var parameterValues = [paymentID];
    var soapMessage = buildSOAPMessage("getPaymentByID", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = true;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!Sets cashflow item given in parameters  to all payments in list. Returns true if operation successful!DEFAULT]
   * @param cashflowItemID [DEFAULT!!DEFAULT]
   * @param paymentList [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PaymentsServiceClient.prototype.setCashflowItemToPayments = function(cashflowItemID, paymentList, callbackHandler) {
    var parameterNames = ["cashflowItemID", "paymentList"];
    var paymentListJSON = JSON.stringify(paymentList);
    var parameterValues = [cashflowItemID, paymentListJSON];
    var soapMessage = buildSOAPMessage("setCashflowItemToPayments", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!Assigns givencashflow item to all payments of given counterparty!DEFAULT]
   * @param cashflowItemID [DEFAULT!!DEFAULT]
   * @param counterpartyID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PaymentsServiceClient.prototype.setCashflowItemToCounterpartyPayments = function(cashflowItemID, counterpartyID, callbackHandler) {
    var parameterNames = ["cashflowItemID", "counterpartyID"];
    var parameterValues = [cashflowItemID, counterpartyID];
    var soapMessage = buildSOAPMessage("setCashflowItemToCounterpartyPayments", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!Adds oneoff fact payment!DEFAULT]
   * @param payment [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PaymentsServiceClient.prototype.addFactPayment = function(payment, callbackHandler) {
    var parameterNames = ["payment"];
    var paymentJSON = JSON.stringify(payment);
    var parameterValues = [paymentJSON];
    var soapMessage = buildSOAPMessage("addFactPayment", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!Clears links  to cashflow item in payment markup buffer history!DEFAULT]
   * @param cashflowItemID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PaymentsServiceClient.prototype.clearPaymentMarkupBuffer = function(cashflowItemID, callbackHandler) {
    var parameterNames = ["cashflowItemID"];
    var parameterValues = [cashflowItemID];
    var soapMessage = buildSOAPMessage("clearPaymentMarkupBuffer", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!Marks payment as an internal cash transfer operation!DEFAULT]
   * @param paymentID [DEFAULT!!DEFAULT]
   * @param corrFundsRegisterID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PaymentsServiceClient.prototype.markTransferPayment = function(paymentID, corrFundsRegisterID, callbackHandler) {
    var parameterNames = ["paymentID", "corrFundsRegisterID"];
    var parameterValues = [paymentID, corrFundsRegisterID];
    var soapMessage = buildSOAPMessage("markTransferPayment", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!Marks internal cash transfer payment as an external counterparty payment!DEFAULT]
   * @param paymentID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PaymentsServiceClient.prototype.markCounterpartyPayment = function(paymentID, callbackHandler) {
    var parameterNames = ["paymentID"];
    var parameterValues = [paymentID];
    var soapMessage = buildSOAPMessage("markCounterpartyPayment", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param payment [DEFAULT!!DEFAULT]
   * @param treasuryApplicationID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PaymentsServiceClient.prototype.addPaymentForTreasuryApplication = function(payment, treasuryApplicationID, callbackHandler) {
    var parameterNames = ["payment", "treasuryApplicationID"];
    var paymentJSON = JSON.stringify(payment);
    var parameterValues = [paymentJSON, treasuryApplicationID];
    var soapMessage = buildSOAPMessage("addPaymentForTreasuryApplication", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param payment [DEFAULT!!DEFAULT]
   * @param invoiceBundleID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PaymentsServiceClient.prototype.addPaymentForInvoiceBundle = function(payment, invoiceBundleID, callbackHandler) {
    var parameterNames = ["payment", "invoiceBundleID"];
    var paymentJSON = JSON.stringify(payment);
    var parameterValues = [paymentJSON, invoiceBundleID];
    var soapMessage = buildSOAPMessage("addPaymentForInvoiceBundle", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param invoiceBundleID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PaymentsServiceClient.prototype.removePaymentsByInvoiceBundle = function(invoiceBundleID, callbackHandler) {
    var parameterNames = ["invoiceBundleID"];
    var parameterValues = [invoiceBundleID];
    var soapMessage = buildSOAPMessage("removePaymentsByInvoiceBundle", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param paymentRule [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PaymentsServiceClient.prototype.addPaymentRule = function(paymentRule, callbackHandler) {
    var parameterNames = ["paymentRule"];
    var paymentRuleJSON = JSON.stringify(paymentRule);
    var parameterValues = [paymentRuleJSON];
    var soapMessage = buildSOAPMessage("addPaymentRule", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param paymentRule [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PaymentsServiceClient.prototype.updatePaymentRule = function(paymentRule, callbackHandler) {
    var parameterNames = ["paymentRule"];
    var paymentRuleJSON = JSON.stringify(paymentRule);
    var parameterValues = [paymentRuleJSON];
    var soapMessage = buildSOAPMessage("updatePaymentRule", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param paymentRuleID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PaymentsServiceClient.prototype.removePaymentRule = function(paymentRuleID, callbackHandler) {
    var parameterNames = ["paymentRuleID"];
    var parameterValues = [paymentRuleID];
    var soapMessage = buildSOAPMessage("removePaymentRule", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param paymentRuleID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PaymentsServiceClient.prototype.getPaymentRuleByID = function(paymentRuleID, callbackHandler) {
    var parameterNames = ["paymentRuleID"];
    var parameterValues = [paymentRuleID];
    var soapMessage = buildSOAPMessage("getPaymentRuleByID", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param scope [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PaymentsServiceClient.prototype.executePaymentRules = function(scope, callbackHandler) {
    var parameterNames = ["scope"];
    var scopeJSON = JSON.stringify(scope);
    var parameterValues = [scopeJSON];
    var soapMessage = buildSOAPMessage("executePaymentRules", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param executionProcessUID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PaymentsServiceClient.prototype.getPaymentRuleExecutionResult = function(executionProcessUID, callbackHandler) {
    var parameterNames = ["executionProcessUID"];
    var parameterValues = [executionProcessUID];
    var soapMessage = buildSOAPMessage("getPaymentRuleExecutionResult", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PaymentsServiceClient.prototype.getPaymentRules = function(callbackHandler) {
    var parameterNames = [];
    var parameterValues = [];
    var soapMessage = buildSOAPMessage("getPaymentRules", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = true;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param filter [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PaymentsServiceClient.prototype.getCashflowOperations = function(filter, callbackHandler) {
    var parameterNames = ["filter"];
    var filterJSON = JSON.stringify(filter);
    var parameterValues = [filterJSON];
    var soapMessage = buildSOAPMessage("getCashflowOperations", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = true;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param fileReference [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PaymentsServiceClient.prototype.importCashflowOperationsFile = function(fileReference, callbackHandler) {
    var parameterNames = ["fileReference"];
    var fileReferenceJSON = JSON.stringify(fileReference);
    var parameterValues = [fileReferenceJSON];
    var soapMessage = buildSOAPMessage("importCashflowOperationsFile", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param operationUID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PaymentsServiceClient.prototype.getCashflowOperationsImportStatus = function(operationUID, callbackHandler) {
    var parameterNames = ["operationUID"];
    var parameterValues = [operationUID];
    var soapMessage = buildSOAPMessage("getCashflowOperationsImportStatus", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!Merge two payments to one transfer!DEFAULT]
   * @param primaryId [DEFAULT!ID of primary payment which will become transfer!DEFAULT]
   * @param secondaryId [DEFAULT!ID of payment which will be removed!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PaymentsServiceClient.prototype.mergePaymentsToTransfer = function(primaryId, secondaryId, callbackHandler) {
    var parameterNames = ["primaryId", "secondaryId"];
    var parameterValues = [primaryId, secondaryId];
    var soapMessage = buildSOAPMessage("mergePaymentsToTransfer", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param payment [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PaymentsServiceClient.prototype.addPaymentDraft = function(payment, callbackHandler) {
    var parameterNames = ["payment"];
    var paymentJSON = JSON.stringify(payment);
    var parameterValues = [paymentJSON];
    var soapMessage = buildSOAPMessage("addPaymentDraft", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param offset [DEFAULT!На сколько позиций сместить курсор в таблице в порядке возрастания ID!DEFAULT]
   * @param rowCount [DEFAULT!Сколько строк обработать!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PaymentsServiceClient.prototype.massUpdateVATFromReference = function(offset, rowCount, callbackHandler) {
    var parameterNames = ["offset", "rowCount"];
    var parameterValues = [offset, rowCount];
    var soapMessage = buildSOAPMessage("massUpdateVATFromReference", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param primaryPaymentID [DEFAULT!Идентификатор первичного (исходного) платежа для объединения в перевод!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PaymentsServiceClient.prototype.getPaymentsForMerging = function(primaryPaymentID, callbackHandler) {
    var parameterNames = ["primaryPaymentID"];
    var parameterValues = [primaryPaymentID];
    var soapMessage = buildSOAPMessage("getPaymentsForMerging", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!Возвращает список ID привязанных платежей!DEFAULT]
   * @param payPeriodFrom [DEFAULT!!DEFAULT]
   * @param payPeriodTo [DEFAULT!!DEFAULT]
   * @param issuedAfter [DEFAULT!!DEFAULT]
   * @param fundsRegisterID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PaymentsServiceClient.prototype.massPaymentToInvoiceLinking = function(payPeriodFrom, payPeriodTo, issuedAfter, fundsRegisterID, callbackHandler) {
    var parameterNames = ["payPeriodFrom", "payPeriodTo", "issuedAfter", "fundsRegisterID"];
    var parameterValues = [payPeriodFrom, payPeriodTo, issuedAfter, fundsRegisterID];
    var soapMessage = buildSOAPMessage("massPaymentToInvoiceLinking", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param paymentID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PaymentsServiceClient.prototype.getFundsRegisterListForTransfer = function(paymentID, callbackHandler) {
    var parameterNames = ["paymentID"];
    var parameterValues = [paymentID];
    var soapMessage = buildSOAPMessage("getFundsRegisterListForTransfer", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param payment [DEFAULT!!DEFAULT]
   * @param corrFundsRegisterID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PaymentsServiceClient.prototype.markAsTransfer = function(payment, corrFundsRegisterID, callbackHandler) {
    var parameterNames = ["payment", "corrFundsRegisterID"];
    var paymentJSON = JSON.stringify(payment);
    var parameterValues = [paymentJSON, corrFundsRegisterID];
    var soapMessage = buildSOAPMessage("markAsTransfer", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param request [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PaymentsServiceClient.prototype.getCashflowOperationsBriefs = function(request, callbackHandler) {
    var parameterNames = ["request"];
    var requestJSON = JSON.stringify(request);
    var parameterValues = [requestJSON];
    var soapMessage = buildSOAPMessage("getCashflowOperationsBriefs", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param paymentID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PaymentsServiceClient.prototype.getTransferByID = function(paymentID, callbackHandler) {
    var parameterNames = ["paymentID"];
    var parameterValues = [paymentID];
    var soapMessage = buildSOAPMessage("getTransferByID", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };

  /***
  * [DEFAULT!!DEFAULT]
  * @param filter [DEFAULT!!DEFAULT]
  * @param callbackHandler Function to service callback result
  */
  w.PaymentsServiceClient.prototype.executeMassUpdateVAT = function(filter, callbackHandler) {
    var parameterNames = ["filter"];
    var filterJSON = JSON.stringify(filter);
    var parameterValues = [filterJSON];
    var soapMessage = buildSOAPMessage("executeMassUpdateVAT", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };

  /***
   * [DEFAULT!!DEFAULT]
   * @param executionProcessUID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PaymentsServiceClient.prototype.getMassUpdateVATExecutionResult = function(executionProcessUID, callbackHandler) {
    var parameterNames = ["executionProcessUID"];
    var parameterValues = [executionProcessUID];
    var soapMessage = buildSOAPMessage("getMassUpdateVATExecutionResult", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param filter [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PaymentsServiceClient.prototype.executeMassUpdateVAT = function(filter, callbackHandler) {
    var parameterNames = ["filter"];
    var filterJSON = JSON.stringify(filter);
    var parameterValues = [filterJSON];
    var soapMessage = buildSOAPMessage("executeMassUpdateVAT", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param executionProcessUID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PaymentsServiceClient.prototype.getMassUpdateVATExecutionResult = function(executionProcessUID, callbackHandler) {
    var parameterNames = ["executionProcessUID"];
    var parameterValues = [executionProcessUID];
    var soapMessage = buildSOAPMessage("getMassUpdateVATExecutionResult", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  
  /***
   * Package security rights constants
   */
  
  w.PaymentsSecurityRights = {
  
      CASHFLOW_ANALYTICS_RIGHT:new SecurityRightCheckHandle('191eb978-58de-4792-a7a5-9c3889d19dc8'),
      X_PAYMENTS__CHANGE_PAYMENTS:new SecurityRightCheckHandle('62b5ca7f-5c8c-40f8-b5f7-631f44887c26'),
      X_PAYMENTS__VIEW_PAYMENTS:new SecurityRightCheckHandle('34a1b3e1-7318-4acc-b23e-9da9f3c441d0')
  };
  })(typeof exports !== 'undefined' ? exports : window);
  