import React from 'react';
import { useContext } from 'react';

import { FormContext, FormContextType } from './form';

export type FormContextProps<S> = FormContextType<S>;

function withFormComponent<P = any, S = any>(WrappedComponent: React.ComponentType<P>) {
	const EnhancedComponent: React.FC<P & Partial<FormContextProps<S>>> = props => {
		const context = useContext(FormContext) as FormContextType<S>;
		const { readonly, ...restContext } = context;

		return <WrappedComponent {...props} {...restContext} />;
	};

	return EnhancedComponent;
}

export { withFormComponent };
