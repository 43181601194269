import styled, { css } from 'styled-components';

import { sizesMM, PageOrientation } from '../models';

const Root = styled.div`
	position: relative;
	overflow: hidden;
`;

type PageProps = {
	orientation: PageOrientation;
};

const Page = styled.div<PageProps>`
	position: relative;
	text-align: left;
	display: flex;
	box-shadow: none;
	border: 1px solid transparent;
	border-color: #e0e0e0;
	background-color: #fff;
	color: #000;

	${p => css`
		width: ${sizesMM[p.orientation].width}mm;
		min-width: ${sizesMM[p.orientation].width}mm;
		max-width: ${sizesMM[p.orientation].width}mm;
		min-height: ${sizesMM[p.orientation].height}mm;
		padding: ${sizesMM[p.orientation].margin}mm;
	`}
`;

const PageBreak = styled.div`
	width: 100%;
	height: 20px;
	background-color: transparent;
`;

const PageHeader = styled.div<PageProps>`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1;
	border: 1px solid transparent;
	border-color: #e0e0e0;
	border-bottom-color: transparent;
	background-color: #fff;

	${p => css`
		width: ${sizesMM[p.orientation].width}mm;
		height: ${sizesMM[p.orientation].margin}mm;
	`}
`;

const PageFooter = styled.div<PageProps>`
	position: absolute;
	bottom: 20px;
	left: 0;
	right: 0;
	z-index: 1;
	border: 1px solid transparent;
	border-color: #e0e0e0;
	border-top-color: transparent;
	background-color: #fff;

	${p => css`
		width: ${sizesMM[p.orientation].width}mm;
		height: ${sizesMM[p.orientation].margin}mm;
	`}
`;

export { Root, Page, PageBreak, PageHeader, PageFooter };
