import { Store } from 'redux';

import { IAppState } from '@store';
import { isReceiveAction } from '@flux';
import { types as cashflowItemsTypes } from '@cashflow-items/actions/types';
import { invalidatePaymentRules } from '@payment-rules/actions/invalidators';

function askRulesInvalidator(store: Store<IAppState>, action: StoreAction) {
	const { dispatch } = store;
	const invalidateMap = {
		[cashflowItemsTypes.UPDATE_CASHFLOW_ITEM]: true,
		[cashflowItemsTypes.REMOVE_CASHFLOW_ITEM]: true,
	};

	if (invalidateMap[action.type] && isReceiveAction(action)) {
		dispatch(invalidatePaymentRules());
	}
}

function createInvalidatorEffect() {
	return (store: Store<IAppState>) => (next: (action: StoreAction) => void) => (action: StoreAction) => {
		next(action);
		askRulesInvalidator(store, action);
	};
}

const runInvalidatorEffect = createInvalidatorEffect();

export { runInvalidatorEffect };
