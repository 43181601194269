// @ts-nocheck
import React from 'react';

import { sharedCSS, cssUtils, getElement } from './styled';

import type { IShapeModuleStyled } from './styled';

declare namespace IShapeModule {
	interface IShapeProps extends IShapeModuleStyled.IShape {
		as?: keyof IShapeModuleStyled.IElements;
		id?: string;
		className?: string;
		style?: React.CSSProperties;
		name?: string;
		src?: string;
		alt?: string;
		href?: string;
		target?: '_blank';
		rel?: 'noopener';
		type?: string;
		htmlFor?: string;
		action?: string;
		onClick?: React.MouseEventHandler<{}>;
		onChange?: React.ChangeEventHandler<any>;
		onFocus?: React.FocusEventHandler<{}>;
		onBlur?: React.FocusEventHandler<{}>;
		onMouseOver?: React.MouseEventHandler<{}>;
		onMouseLeave?: React.MouseEventHandler<{}>;
		onKeyDown?: React.KeyboardEventHandler<{}>;
		children: React.ReactNode;
	}
}

class Shape extends React.Component<IShapeModule.IShapeProps> {
	static defaultProps = { as: 'div' };
	element = getElement(this.props.as);

	render() {
		const { as, children, ...rest } = this.props;
		const Element = this.element;

		return <Element {...rest}>{this.props.children}</Element>;
	}
}

export { IShapeModuleStyled, sharedCSS, cssUtils };
export default Shape;
