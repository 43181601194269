import React, { useState } from 'react';

import { useMounted } from '@core/hooks/use-mounted';
import { GlobalStyle, Root, ContentLayout, SwitchLayout, Circle, Emoji } from './styled';

export type DarkModeSwitchProps = {
	isDarkMode: boolean;
	onChange: (e: React.MouseEvent | null) => void;
};

const DarkModeSwitch: React.FC<DarkModeSwitchProps> = props => {
	const { isDarkMode, onChange } = props;
	const [withGlobalStyle, setWithGlobalStyle] = useState(false);
	const { mounted } = useMounted();

	const handleToggle = (e: React.MouseEvent) => {
		setWithGlobalStyle(true);
		onChange(e);
		setTimeout(() => {
			mounted() && setWithGlobalStyle(false);
		}, 1000);
	};

	const handleKeyDown = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter') {
			handleToggle(null);
		}
	};

	return (
		<>
			{withGlobalStyle && <GlobalStyle />}
			<Root>
				<ContentLayout>
					<SwitchLayout tabIndex={0} isActive={isDarkMode} onClick={handleToggle} onKeyDown={handleKeyDown}>
						<Circle isActive={isDarkMode}>
							<Emoji>{isDarkMode ? '🌛' : '🌞'}</Emoji>
						</Circle>
					</SwitchLayout>
				</ContentLayout>
			</Root>
		</>
	);
};

export { DarkModeSwitch };
