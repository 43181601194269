import React from 'react';

import { IconBase, IconBaseProps } from './base';

export type DownloadIconProps = IconBaseProps;

const DownloadIcon: React.FC<DownloadIconProps> = props => {
	return (
		<IconBase {...props}>
			<svg
				stroke='currentColor'
				fill='none'
				strokeWidth='2'
				viewBox='0 0 24 24'
				strokeLinecap='round'
				strokeLinejoin='round'
				xmlns='http://www.w3.org/2000/svg'>
				<path d='M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4'></path>
				<polyline points='7 10 12 15 17 10'></polyline>
				<line x1='12' y1='15' x2='12' y2='3'></line>
			</svg>
		</IconBase>
	);
};

export { DownloadIcon };
