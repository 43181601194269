import React from 'react';

import { Root, MessageLayout } from './styled';

type AlertCardProps = {
	type?: 'warning' | 'alarm' | 'success' | 'default';
	message?: string;
	fontSize?: string;
	margin?: string;
	children: React.ReactNode;
};

const AlertCard: React.FC<AlertCardProps> = props => {
	const { type, message, fontSize, margin, children } = props;

	return (
		<Root type={type} margin={margin}>
			<MessageLayout fontSize={fontSize}>{children ? children : message}</MessageLayout>
		</Root>
	);
};

AlertCard.defaultProps = {
	type: 'default',
};

export { AlertCard };
