import React, { useState, useRef, forwardRef, useImperativeHandle, useMemo } from 'react';
import PopoverAnimationVertical from 'material-ui/Popover/PopoverAnimationVertical';

import { Box } from '@ui/box';
import { Popover } from '@ui/popover';
import { FocusTrap } from '@ui/focus-trap';
import { useTheme } from '@theme';

export type FastContentRef = {
	open: () => void;
	close: () => void;
};

export type FastContentProps = {
	autoFocus?: boolean;
	children: React.ReactNode;
	renderTrigger: (options: FastContentRenderTriggerOptions) => React.ReactNode;
	disableOnRequestClose?: boolean;
	onClose?: () => void;
};

export type FastContentRenderTriggerOptions = {
	onToggle: () => void;
};

const FastContent = forwardRef<FastContentRef, FastContentProps>((props, ref) => {
	const { renderTrigger, autoFocus, disableOnRequestClose, onClose } = props;
	const [isOpen, setIsOpen] = useState(false);
	const { theme } = useTheme();
	const triggerRef = useRef<HTMLDivElement>(null);
	const style = useMemo(() => {
		return {
			boxShadow: theme.isDarkMode
				? '0 1px 14px rgba(0, 0, 0, 0.45), 0 1px 12px rgba(0, 0, 0, 0.45)'
				: '0 1px 14px rgba(0, 0, 0, 0.13), 0 1px 12px rgba(0, 0, 0, 0.13)',
		};
	}, [theme.isDarkMode]);

	const handleOpen = () => setIsOpen(true);
	const handleClose = () => {
		setIsOpen(false);
		onClose();
	};
	const handleToggle = () => setIsOpen(!isOpen);

	useImperativeHandle(ref, () => ({
		open: handleOpen,
		close: handleClose,
	}));

	return (
		<div ref={triggerRef}>
			{renderTrigger({ onToggle: handleToggle })}
			{isOpen && (
				<Popover
					open={isOpen}
					animation={PopoverAnimationVertical}
					anchorEl={triggerRef.current}
					style={style}
					onRequestClose={disableOnRequestClose ? undefined : handleClose}
					useLayerForClickAway>
					<Box position='relative' padding='24px'>
						{autoFocus && <FocusTrap />}
						{props.children}
					</Box>
				</Popover>
			)}
		</div>
	);
});

FastContent.defaultProps = {
	onClose: () => {},
};

export { FastContent };
