type FormVariant = 'add' | 'nested' | 'edit' | 'read' | 'remove' | 'clone';

function useFormVariant(variant: FormVariant, hasChangeRight = true) {
	let isAdd = variant === 'add';
	let isAddNested = variant === 'nested';
	let isEdit = variant === 'edit';
	let isClone = variant === 'clone';
	let isRead = variant === 'read';
	let isRemove = variant === 'remove';

	if (!hasChangeRight) {
		isAdd = false;
		isAddNested = false;
		isEdit = false;
		isClone = false;
		isRemove = false;
		isRead = true;
	}

	return {
		isAdd,
		isAddNested,
		isEdit,
		isClone,
		isRead,
		isRemove,
		isReadonly: isRead,
	};
}

export { useFormVariant };
