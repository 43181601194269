/***
 * Library contains JavaScript API for interaction with Seeneco Framework Web Services contained in ReportsPackage
 * @author User Generated by Seeneco Framework Modeler
 *
 */
(function(w){
  /***
  *
  */
  w.BalanceReportItem = function() {
    this.PACKAGE = "REPORTS";
    this.CLASSIFIER = "BALANCE_REPORT_ITEM";
    this.CopySupportingElement = false;
    this.Value = -1;
    this.ChildItems = [];
    this.ParentID = -1;
    this.ChildItemIDs = "";
    this.Code = "";
    this.ID = -1;
    this.Name = "";
    this.GUID = "";
  };
  /***
  *
  */
  w.BalanceReportRequest = function() {
    this.PACKAGE = "REPORTS";
    this.CLASSIFIER = "BALANCE_REPORT_REQUEST";
    this.CopySupportingElement = false;
    this.ValueDate = null;
    this.TenantLegalEntitiesIDs = [];
  };
  /***
  *
  */
  w.BalanceReportResponse = function() {
    this.PACKAGE = "REPORTS";
    this.CLASSIFIER = "BALANCE_REPORT_RESPONSE";
    this.CopySupportingElement = false;
    this.Request = null;
    this.Assets = [];
    this.Liabilities = [];
    this.AssetsTotal = -1;
    this.LiabilitiesTotal = -1;
  };
  /***
  *
  */
  w.ColumnCommonAttrs = function() {
    this.PACKAGE = "REPORTS";
    this.CLASSIFIER = "COLUMN_COMMON_ATTRS";
    this.CopySupportingElement = false;
    this.Code = "";
    this.DateStart = null;
    this.DateFinish = null;
  };
  /***
  *
  */
w.DashboardDeposits = function() {
  this.PACKAGE = "REPORTS";
  this.CLASSIFIER = "DASHBOARD_DEPOSITS";
  this.CopySupportingElement = false;
  this.Placed = -1;
  this.Refunded = -1;
  this.Result = -1;
};
/***
*
*/
w.DashboardRequest = function() {
  this.PACKAGE = "REPORTS";
  this.CLASSIFIER = "DASHBOARD_REQUEST";
  this.CopySupportingElement = false;
  this.DateStart = null;
  this.DateFinish = null;
  this.TenantLegalEntitiesIDs = [];
  this.FundsRegistersIDs = [];
  this.ProjectsIDs = [];
  this.IncludingVat = false;
  this.BusinessUnitsIDs = [];
  this.DateType = "";
};
/***
*
*/
w.DashboardResponse = function() {
  this.PACKAGE = "REPORTS";
  this.CLASSIFIER = "DASHBOARD_RESPONSE";
  this.CopySupportingElement = false;
  this.Deposits = null;
};
/***
*
*/
  w.ReportColumn = function() {
    this.PACKAGE = "REPORTS";
    this.CLASSIFIER = "REPORT_COLUMN";
    this.CopySupportingElement = false;
    this.FactAmount = -1;
    this.PlanAmount = -1;
    this.HasExpiredPlan = false;
    this.TotalAmount = -1;
    this.Code = "";
  };
  /***
  *
  */
  w.ReportItem = function() {
    this.PACKAGE = "REPORTS";
    this.CLASSIFIER = "REPORT_ITEM";
    this.CopySupportingElement = false;
    this.ID = -1;
    this.Name = "";
    this.ParentID = -1;
    this.Columns = [];
    this.ChildItemIDs = "";
    this.Incoming = false;
    this.DetailByID = "";
  };
  /***
  *
  */
  w.ReportRequest = function() {
    this.PACKAGE = "REPORTS";
    this.CLASSIFIER = "REPORT_REQUEST";
    this.CopySupportingElement = false;
    this.DateStart = null;
    this.DateFinish = null;
    this.GroupingType = "";
    this.ExcludePlan = false;
    this.TenantLegalEntitiesIDs = [];
    this.ProjectIDs = [];
    this.BusinessUnitsIDs = [];
    this.ExcludeVat = false;
    this.AccountsChartItemIDs = [];
    this.FundsRegisterIDs = [];
  };
  /***
  *
  */
  w.ReportResponse = function() {
    this.PACKAGE = "REPORTS";
    this.CLASSIFIER = "REPORT_RESPONSE";
    this.CopySupportingElement = false;
    this.Request = null;
    this.Items = [];
    this.ColumnsCommonAttrs = [];
  };



  /***
   * Client API to ReportsServiceWS web service
   */
  w.ReportsServiceClient = function() {
    /***
     * System name of target service
     */
    this.serviceName = "ReportsServiceWSImpl";
  };

  /***
   * [DEFAULT!!DEFAULT]
   * @param request [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.ReportsServiceClient.prototype.getAccrualReport = function(request, callbackHandler) {
    var parameterNames = ["request"];
    var requestJSON = JSON.stringify(request);
    var parameterValues = [requestJSON];
    var soapMessage = buildSOAPMessage("getAccrualReport", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };

  /***
   * [DEFAULT!!DEFAULT]
   * @param request [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.ReportsServiceClient.prototype.getCashflowReport = function(request, callbackHandler) {
    var parameterNames = ["request"];
    var requestJSON = JSON.stringify(request);
    var parameterValues = [requestJSON];
    var soapMessage = buildSOAPMessage("getCashflowReport", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };

  /***
   * [DEFAULT!!DEFAULT]
   * @param request [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.ReportsServiceClient.prototype.getProfitLossReport = function(request, callbackHandler) {
    var parameterNames = ["request"];
    var requestJSON = JSON.stringify(request);
    var parameterValues = [requestJSON];
    var soapMessage = buildSOAPMessage("getProfitLossReport", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };

  /***
   * [DEFAULT!!DEFAULT]
   * @param request [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.ReportsServiceClient.prototype.getBalanceReport = function(request, callbackHandler) {
    var parameterNames = ["request"];
    var requestJSON = JSON.stringify(request);
    var parameterValues = [requestJSON];
    var soapMessage = buildSOAPMessage("getBalanceReport", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };

/***
 * [DEFAULT!!DEFAULT]
 * @param request [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.ReportsServiceClient.prototype.getDashboard = function(request, callbackHandler) {
  var parameterNames = ["request"];
  var requestJSON = JSON.stringify(request);
  var parameterValues = [requestJSON];
  var soapMessage = buildSOAPMessage("getDashboard", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};


  /***
   * Package security rights constants
   */

  w.ReportsSecurityRights = {

      X_REPORTS__VIEW_REPORTS:new SecurityRightCheckHandle('0e6a01ce-7cd0-4dab-97b8-4948b9bd3d1c')
  };
  })(typeof exports !== 'undefined' ? exports : window);
