import { EMIT_ACTION_MESSAGE } from '../actions/action-message.actions';
import { IStaticDataAction } from '../../../core/libs/action-creator';

interface IMessageState {
	messages: Array<IActionMessage>;
}

const initialState = {
	messages: [],
};

export default function (state: IMessageState = initialState, action: IStaticDataAction & AsyncAction<any>) {
	const asyncActionMessage = (action as AsyncAction<any>).showMessage;
	if (asyncActionMessage) {
		return {
			...state,
			messages: state.messages.concat(asyncActionMessage),
			messageViewIsOpen: true,
		};
	}
	switch (action.type) {
		case EMIT_ACTION_MESSAGE: {
			return {
				...state,
				messages: state.messages.concat(action.value),
				messageViewIsOpen: true,
			};
		}

		default:
			return state;
	}
}

export { IMessageState };
