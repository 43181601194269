import { combineReducers } from 'redux';

import { mainCounterpartiesReducer, MainCounterpartiesState } from './main.reducers';

export type CounterpartiesState = {
	main: MainCounterpartiesState;
};

const counterpartiesReducer = combineReducers<CounterpartiesState>({
	main: mainCounterpartiesReducer,
});

export { counterpartiesReducer };
