import styled, { css } from 'styled-components';

import { Theme } from '@theme';
import { darken, lighten } from '@utils/color';

type RootProps = {
	fullWidth: boolean;
};

const Root = styled.div<RootProps>`
	position: relative;
	display: inline-flex;

	${p =>
		p.fullWidth &&
		css`
			width: 100%;

			& div {
				width: 100% !important;
			}
		`}
`;

type TriggerLayoutProps = {
	withLabelText: boolean;
};

const TriggerLayout = styled.div<TriggerLayoutProps>`
	display: block;

	& .input {
		font-size: 14px !important;
		height: 24px !important;
	}

	${p =>
		p.withLabelText &&
		css`
			margin-bottom: 21px;

			& .input {
				height: 50px !important;
			}

			& .input label {
				top: 30px !important;
			}
		`}
`;

const DateRangeLayout = styled.div`
	position: relative;
	display: inline-block;
	min-height: 326px;
	padding: 16px;

	& .rdr-Day {
		transform: scale(1) !important;
	}

	${p => css`
		background-color: ${p.theme.palette.canvas};

		& .rdr-PredefinedRangesItem:hover {
			color: ${p.theme.palette.accent} !important;
		}

		& .rdr-PredefinedRangesItemActive:hover {
			color: ${p.theme.palette.textContrast} !important;
		}
	`}
`;

interface IEnterDateLayoutProps {
	rangePosition: 'right' | 'left';
}

const EnterDateLayout = styled.div`
	position: absolute;
	bottom: 24px;
	display: inline-flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	align-items: flex-start;

	${(props: IEnterDateLayoutProps) =>
		props.rangePosition === 'right' &&
		css`
			right: 28px;
		`}

	${(props: IEnterDateLayoutProps) =>
		props.rangePosition === 'left' &&
		css`
			left: 37px;
		`}
`;

const EnterDateSign = styled.div`
	font-size: 14px;
	font-weight: 500;
	line-height: 1.25;
	text-align: left;
`;

const EnterDateInputsLayout = styled.div``;

const EnterDateSeparator = styled.span`
	margin: 0 4px;

	${p => css`
		color: ${p.theme.palette.hint};
	`}
`;

type DividerLayoutProps = {
	rangePosition: 'right' | 'left';
};

const DividerLayout = styled.div<DividerLayoutProps>`
	position: absolute;
	top: 20px;
	z-index: 100;

	${p =>
		p.rangePosition === 'right' &&
		css`
			right: 221px;
		`}

	${p =>
		p.rangePosition === 'left' &&
		css`
			left: 218px;
		`}
`;

const Divider = styled.div`
	width: 1px;
	height: 275px;

	${p => css`
		background-color: ${p.theme.palette.stealth};
	`}
`;

const createDateRangeTheme = (rangePosition: 'left' | 'right', theme: Theme) => {
	return {
		DateRange: {
			display: 'inline-flex',
			flexFlow: 'row nowrap',
			justifyContent: 'flex-start',
			alignItems: 'flex-start',
			width: 'auto',
			maxWidth: '900px',
			borderRadius: '0',
			backgroundColor: 'transparent',
		},
		Calendar: {
			order: rangePosition === 'right' ? '1' : '2',
			width: '268px',
			padding: '0 15px 0 15px',
			backgroundColor: theme.palette.canvas,
		},
		Day: {
			width: '34px',
			height: '34px',
			fontWeight: '400',
			fontSize: '12px',
			lineHeight: '1.25',
			backgroundColor: theme.palette.canvas,
			color: theme.palette.text,
			display: 'inline-flex',
			flexFlow: 'row wrap',
			justifyContent: 'center',
			alignItems: 'center',
		},
		Weekday: {
			width: '34px',
			height: '34px',
			fontSize: '12px',
			fontWeight: '400',
			lineHeight: '1.25',
			marginBottom: '0',
			display: 'inline-flex',
			flexFlow: 'row wrap',
			justifyContent: 'center',
			alignItems: 'center',
			color: theme.palette.text,
		},
		DayHover: {
			backgroundColor: lighten(theme.palette.accent, 0.4),
			color: theme.palette.textContrast,
		},
		DaySelected: {
			backgroundColor: theme.palette.accent,
			color: theme.palette.textContrast,
		},
		DayInRange: {
			backgroundColor: theme.palette.accent,
			color: theme.palette.textContrast,
		},
		DayStartEdge: {
			backgroundColor: darken(theme.palette.accent, 0.1),
		},
		DayEndEdge: {
			backgroundColor: darken(theme.palette.accent, 0.1),
		},
		PredefinedRanges: {
			position: 'relative',
			top: '3px',
			left: rangePosition === 'right' ? '2px' : 'auto',
			right: rangePosition === 'left' ? '12px' : 'auto',
			order: rangePosition === 'right' ? '2' : '1',
			display: 'flex',
			flexFlow: 'column wrap',
			justifyContent: 'flex-start',
			alignItems: 'flex-start',
			width: 'auto',
			height: 'auto',
			backgroundColor: 'transparent',
			padding: '0 24px 24px 24px',
			margin: rangePosition === 'right' ? '0' : '0',
		},
		PredefinedRangesItem: {
			width: '100%',
			backgroundColor: 'transparent',
			marginBottom: '0',
			padding: '8px 10px',
			textAlign: 'left',
			fontSize: '14px',
			lineHeight: '1.25',
			color: theme.palette.label,
		},
		PredefinedRangesItemActive: {
			backgroundColor: theme.palette.accent,
			color: theme.palette.textContrast,
		},
		MonthAndYear: {
			fontSize: '14px',
			fontWeight: '500',
			lineHeight: '1.25',
			color: theme.palette.text,
		},
		MonthButton: {
			backgroundColor: 'transparent',
			cursor: 'pointer',
		},
		MonthArrowPrev: {
			borderRightColor: theme.palette.text,
			position: 'relative',
			left: '-4px',
		},
		MonthArrowNext: {
			borderLeftColor: theme.palette.text,
			position: 'relative',
			right: '-4px',
		},
	};
};

const dialogContentStyle = {
	width: 'auto',
	maxWidth: '782px',
};

const inputContainerStyle = {
	width: 73,
	height: 40,
	marginRight: 0,
};

const inputStyle = {
	fontSize: 14,
};

type TriggerInputLayoutProps = {
	titleMode: 'h1' | 'h2';
	disabled: boolean;
};

const TriggerInputLayout = styled.div<TriggerInputLayoutProps>`
	display: inline-flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: center;
	border-bottom: 1px dotted transparent;

	${p => css`
		border-color: ${p.theme.palette.label};

		&:hover {
			border-color: ${p.theme.palette.accent};
		}

		&:hover * {
			transition: color 0.2s ease-in-out;
			color: ${p.theme.palette.accent} !important;
		}
	`}

	& .input {
		margin-right: 0 !important;
	}

	& hr {
		display: none !important;
	}

	${p =>
		p.titleMode &&
		css`
			* {
				font-size: ${p.titleMode === 'h2' ? '20' : '24'}px !important;
			}

			@media (max-width: 1400px) {
				* {
					font-size: 20px !important;
				}
			}
		`}

	${p =>
		p.disabled &&
		css`
			& * {
				color: ${p.theme.palette.hint} !important;
				cursor: default;
			}

			&:hover {
				border-color: ${p.theme.palette.hint};
			}

			&:hover * {
				color: ${p.theme.palette.hint} !important;
			}
		`}
`;

const PeriodName = styled.span`
	font-size: 14px;
	font-weight: 500;
`;

const PeriodNameLayout = styled.div`
	flex-shrink: 0;
	cursor: pointer;

	@media (max-width: 1200px) {
		display: none;
	}
`;

const MaskedTriggerLayout = styled.div`
	position: absolute;
	transform: translateY(-100%);
`;

const Expander = styled.div`
	opacity: 0;
`;

export {
	Root,
	TriggerLayout,
	DateRangeLayout,
	EnterDateLayout,
	EnterDateSign,
	EnterDateInputsLayout,
	EnterDateSeparator,
	DividerLayout,
	Divider,
	createDateRangeTheme,
	dialogContentStyle,
	inputContainerStyle,
	inputStyle,
	TriggerInputLayout,
	PeriodName,
	PeriodNameLayout,
	MaskedTriggerLayout,
	Expander,
};
