/***
 * Library contains JavaScript API for interaction with Seeneco Framework Web Services contained in DocumentPackage
 * @author YasinskiyVI Generated by Seeneco Framework Modeler 
 *
 */
(function(w){
  /***
  * 
  */
  w.AttachedDocument = function() {
    this.PACKAGE = "DOCUMENT";
    this.CLASSIFIER = "ATTACHED_DOCUMENT";
    this.CopySupportingElement = false;
    this.AttachedDocumentType = null;
    this.ID = -1;
    this.Name = "";
    this.Author = null;
    this.UploadDate = null;
    this.URL = "";
    this.Comment = "";
  };
  /***
  * 
  */
  w.AttachedDocumentType = function() {
    this.PACKAGE = "DOCUMENT";
    this.CLASSIFIER = "ATTACHED_DOCUMENT_TYPE";
    this.CopySupportingElement = false;
    this.ID = -1;
    this.Name = "";
    this.Code = "";
  };
  /***
  * 
  */
  w.BusinessTransaction = function() {
    this.PACKAGE = "DOCUMENT";
    this.CLASSIFIER = "BUSINESS_TRANSACTION";
    this.CopySupportingElement = false;
    this.Debet = -1;
    this.Credit = -1;
    this.TransactionDate = null;
    this.Description = "";
    this.Deviation = false;
  };
  /***
  * 
  */
  w.BusinessTransactions = function() {
    this.PACKAGE = "DOCUMENT";
    this.CLASSIFIER = "BUSINESS_TRANSACTIONS";
    this.CopySupportingElement = false;
    this.BalanceStart = null;
    this.BalanceFinish = null;
    this.Turnover = null;
    this.Transactions = [];
    this.Employee = null;
    this.Counterparty = null;
  };
  /***
  * 
  */
  w.ReconciliationAct = function() {
    this.PACKAGE = "DOCUMENT";
    this.CLASSIFIER = "RECONCILIATION_ACT";
    this.CopySupportingElement = false;
    this.LegalEntityTransaction = null;
    this.CounterpartyTransaction = null;
    this.Warnings = [];
  };
  /***
  * 
  */
  w.ReconciliationActBalance = function() {
    this.PACKAGE = "DOCUMENT";
    this.CLASSIFIER = "RECONCILIATION_ACT_BALANCE";
    this.CopySupportingElement = false;
    this.Debet = -1;
    this.Credit = -1;
  };
  /***
  * 
  */
  w.ReconciliationActRequest = function() {
    this.PACKAGE = "DOCUMENT";
    this.CLASSIFIER = "RECONCILIATION_ACT_REQUEST";
    this.CopySupportingElement = false;
    this.PeriodDateEnd = null;
    this.PeriodDateStart = null;
    this.CounterpartyID = -1;
    this.LegalEntityID = -1;
  };
   
   
  
  /***
   * Client API to AttachedDocumentServiceWS web service
   */
  w.AttachedDocumentServiceClient = function() {
    /***
     * System name of target service
     */
    this.serviceName = "AttachedDocumentServiceWSImpl";
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param document [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.AttachedDocumentServiceClient.prototype.addDocument = function(document, callbackHandler) {
    var parameterNames = ["document"];
    var documentJSON = JSON.stringify(document);
    var parameterValues = [documentJSON];
    var soapMessage = buildSOAPMessage("addDocument", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param document [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.AttachedDocumentServiceClient.prototype.updateDocument = function(document, callbackHandler) {
    var parameterNames = ["document"];
    var documentJSON = JSON.stringify(document);
    var parameterValues = [documentJSON];
    var soapMessage = buildSOAPMessage("updateDocument", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param documentID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.AttachedDocumentServiceClient.prototype.removeDocument = function(documentID, callbackHandler) {
    var parameterNames = ["documentID"];
    var parameterValues = [documentID];
    var soapMessage = buildSOAPMessage("removeDocument", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param documentID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.AttachedDocumentServiceClient.prototype.getDocument = function(documentID, callbackHandler) {
    var parameterNames = ["documentID"];
    var parameterValues = [documentID];
    var soapMessage = buildSOAPMessage("getDocument", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param documentIDs [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.AttachedDocumentServiceClient.prototype.getDocuments = function(documentIDs, callbackHandler) {
    var parameterNames = ["documentIDs"];
    var documentIDsJSON = JSON.stringify(documentIDs);
    var parameterValues = [documentIDsJSON];
    var soapMessage = buildSOAPMessage("getDocuments", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.AttachedDocumentServiceClient.prototype.getAllAttachedDocumentTypes = function(callbackHandler) {
    var parameterNames = [];
    var parameterValues = [];
    var soapMessage = buildSOAPMessage("getAllAttachedDocumentTypes", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param documentIDs [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.AttachedDocumentServiceClient.prototype.removeDocuments = function(documentIDs, callbackHandler) {
    var parameterNames = ["documentIDs"];
    var documentIDsJSON = JSON.stringify(documentIDs);
    var parameterValues = [documentIDsJSON];
    var soapMessage = buildSOAPMessage("removeDocuments", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * Client API to DocumentServiceWS web service
   */
  w.DocumentServiceClient = function() {
    /***
     * System name of target service
     */
    this.serviceName = "DocumentServiceWSImpl";
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param request [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.DocumentServiceClient.prototype.generateReconciliationAct = function(request, callbackHandler) {
    var parameterNames = ["request"];
    var requestJSON = JSON.stringify(request);
    var parameterValues = [requestJSON];
    var soapMessage = buildSOAPMessage("generateReconciliationAct", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  
  /***
   * Package security rights constants
   */
  
  w.DocumentSecurityRights = {
  
  };
  })(typeof exports !== 'undefined' ? exports : window);
  