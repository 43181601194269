import { createAsyncAction, createStaticAction, RECEIVE } from '@flux';
import { createNotificationMessage } from '@utils/notifications';
import { selectAsyncCashflowItems, selectAsyncAccountsChartItemRoles } from '@cashflow-items/selectors';
import { types } from './types';

const actions = {
	fetchCashflowItems: createAsyncAction(
		types.FETCH_CASHFLOW_ITEMS,
		api => api.accountingPack.accountsChart.fetchCashflowItems(),
		{
			isValidSelector: selectAsyncCashflowItems.selectIsValid,
			isFetchingSelector: selectAsyncCashflowItems.selectIsFetching,
		},
	) as () => void,
	fetchAccountsChartItemRoles: createAsyncAction(
		types.FETCH_ROLES,
		api => api.accountingPack.accountsChart.fetchAccountsChartItemRoles(),
		{
			isValidSelector: selectAsyncAccountsChartItemRoles.selectIsValid,
			isFetchingSelector: selectAsyncAccountsChartItemRoles.selectIsFetching,
		},
	) as () => void,
	addCashflowItem: createAsyncAction(
		types.ADD_CASHFLOW_ITEM,
		(api, _, __, value: CashflowItem) => api.accountingPack.accountsChart.addCashflowItem(value),
		{
			showMessage: type => type === RECEIVE && createNotificationMessage('Статья добавлена 😊', 'success'),
		},
	) as (value: CashflowItem) => void,
	updateCashflowItem: createAsyncAction(
		types.UPDATE_CASHFLOW_ITEM,
		(api, _, __, value: CashflowItem) => api.accountingPack.accountsChart.updateCashflowItem(value),
		{
			showMessage: type => type === RECEIVE && createNotificationMessage('Статья изменена 😊', 'success'),
		},
	) as (value: CashflowItem) => void,
	removeCashflowItem: createAsyncAction(
		types.REMOVE_CASHFLOW_ITEM,
		(api, _, __, ID: number) => api.accountingPack.accountsChart.removeCashflowItem(ID),
		{
			showMessage: type => type === RECEIVE && createNotificationMessage('Статья удалена 😊', 'success'),
		},
	) as (ID: number) => void,
	setFilterByText: (value: string) => createStaticAction(types.SET_FILTER_BY_TEXT)(value),
	expandIncomeItems: (value: boolean) => createStaticAction(types.EXPAND_INCOME_ITEMS)(value),
	expandExpenseItems: (value: boolean) => createStaticAction(types.EXPAND_EXPENSE_ITEMS)(value),
};

export const mainCashflowItemsActionsPack = actions;
