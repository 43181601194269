import DrawerMUI from 'material-ui/Drawer';
import React from 'react';

import { GoalStep, useGoalStep } from '@core/hooks/use-goals';
import { ErrorBoundary } from '@ui/error-boundary';
import { useLackOfTwitching } from '@utils/dom';
import { DefaultDrawer, DefaultTitle } from './default-drawer';
import DrawerChooseDialog from './drawer-choose-dialog';
import DrawerConfirmDialog from './drawer-confirm-dialog';
import DrawerFooterControls from './drawer-footer-controls';
import DrawerLink from './drawer-link';
import DrawerRightControls from './drawer-right-controls';
import DrawerTitle from './drawer-title';
import { Autofocus, PreviewCentered, PreviewContent, PreviewFooter, style, TitleFooter } from './styled';
import { WideDrawer, WideSidebar, WideTitle } from './wide-drawer';

type DrawerProps = {};

export type DrawerState = {
	content?: React.ReactNode;
	footer?: React.ReactNode;
	fullMode?: boolean;
	goalStepOnExit?: GoalStep;
	onClose?: () => void;
	onScrollableContainer?: (ref: HTMLDivElement) => void;
	open?: boolean;
	overlay?: boolean;
	rightContent?: React.ReactNode | boolean;
	title?: React.ReactNode;
	titleFooter?: React.ReactNode;
	variant?: 'default' | 'preview';
};

class Drawer extends React.Component<DrawerProps, DrawerState> {
	state: DrawerState = {
		content: null,
		open: false,
		title: '',
	};
	disposeLack = () => {};
	drawerRef = null;
	focusElement: HTMLElement = null;
	handleGoalStepOnExit = () => {};
	scrollableContainerRef = null;

	getSnapshotBeforeUpdate(prevProps: DrawerProps, prevState: DrawerState) {
		if (!prevState.open && this.state.open) {
			document.body.click(); // for reset other opened popovers
		}

		return null;
	}

	componentDidUpdate(prevProps: DrawerProps, prevState: DrawerState) {
		if (prevState.onScrollableContainer !== this.state.onScrollableContainer) {
			if (this.scrollableContainerRef && typeof this.state.onScrollableContainer === 'function') {
				this.state.onScrollableContainer(this.scrollableContainerRef);
			}
		}
		if (prevState.goalStepOnExit !== this.state.goalStepOnExit) {
			this.handleGoalStepOnExit = useGoalStep(this.state.goalStepOnExit);
		}
	}

	public toggle = () => this.setState({ open: !this.state.open });

	public close = () => {
		this.setState({ open: false }, () => this.disposeLack());
		this.state.onClose && this.state.onClose();
		this.resetDrawer();

		if (this.focusElement) {
			this.focusElement?.focus();
			this.focusElement = null;
		}
	};

	public open = (newState?: Partial<DrawerState>) => {
		const { open } = this.state;
		const [lack, disposeLack] = useLackOfTwitching();

		this.focusElement = document.activeElement as HTMLElement;

		newState = newState || {};
		this.setState(
			{
				overlay: false,
				rightContent: null,
				...newState,
				open: true,
			},
			() => !open && lack(),
		);
		this.disposeLack = disposeLack;
	};

	public setContent = (newState?: Partial<DrawerState>) => this.setState({ ...newState });

	public setOnClose = (onClose: () => void | null) => this.setState({ onClose });

	public setOnScrollableContainer = (onScrollableContainer: (ref: HTMLDivElement) => void) =>
		this.setState({ onScrollableContainer });

	public isOpened = () => this.state.open;

	private exit = () => {
		this.handleGoalStepOnExit();
		this.close();
	};

	private setScrollableContainerRef = (ref: HTMLDivElement) => (this.scrollableContainerRef = ref);

	private setDrawerRef = (ref: HTMLDivElement) => (this.drawerRef = ref);

	private resetDrawer = () => {
		this.setState({
			title: '',
			variant: null,
			titleFooter: null,
			content: null,
			fullMode: false,
			rightContent: null,
			footer: null,
			onClose: null,
			onScrollableContainer: null,
		});
	};

	handleRequestChange = (open: boolean, reason: string) => {
		if (reason !== 'clickaway') {
			this.setState({ open });

			if (!open) {
				!open && this.state.onClose && this.state.onClose();
				!open && this.resetDrawer();
				this.disposeLack();

				if (this.focusElement) {
					this.focusElement?.focus();
					this.focusElement = null;
				}
			}
		}
	};

	renderDefaultDrawer = () => {
		const { open, title, titleFooter, content, footer, fullMode } = this.state;

		return (
			<DefaultDrawer.Root>
				<DefaultDrawer.HeaderLayout appearance={titleFooter || fullMode ? 'mini' : 'default'}>
					<DefaultTitle onClose={this.exit}>{title}</DefaultTitle>
				</DefaultDrawer.HeaderLayout>
				{titleFooter && <TitleFooter>{titleFooter}</TitleFooter>}
				<DefaultDrawer.ContentLayout id='drawer-content' ref={this.setScrollableContainerRef}>
					<ErrorBoundary>
						{open && <Autofocus />}
						{content}
					</ErrorBoundary>
				</DefaultDrawer.ContentLayout>
				{footer && (
					<ErrorBoundary>
						<DefaultDrawer.FooterLayout appearance={fullMode ? 'mini' : 'default'}>{footer}</DefaultDrawer.FooterLayout>
					</ErrorBoundary>
				)}
			</DefaultDrawer.Root>
		);
	};

	renderWideDrawer = () => {
		const { open, title, content, rightContent: sidebar } = this.state;

		return (
			<WideDrawer.Root ref={this.setDrawerRef}>
				<WideDrawer.MainLayout>
					<WideDrawer.HeaderLayout>
						<WideTitle onClose={this.exit}>{title}</WideTitle>
					</WideDrawer.HeaderLayout>
					<WideDrawer.ContentLayout id='drawer-content' ref={this.setScrollableContainerRef}>
						<ErrorBoundary>
							{open && <Autofocus />}
							{content}
						</ErrorBoundary>
					</WideDrawer.ContentLayout>
				</WideDrawer.MainLayout>
				<WideDrawer.SidebarLayout>
					<ErrorBoundary>
						<WideSidebar>{sidebar}</WideSidebar>
					</ErrorBoundary>
				</WideDrawer.SidebarLayout>
			</WideDrawer.Root>
		);
	};

	renderPreviewDrawer = () => {
		const { title, content, footer } = this.state;

		return (
			<WideDrawer.Root ref={this.setDrawerRef}>
				<WideDrawer.MainLayout>
					<WideDrawer.HeaderLayout>
						<WideTitle onClose={this.exit}>{title}</WideTitle>
					</WideDrawer.HeaderLayout>
					<PreviewContent id='drawer-content' ref={this.setScrollableContainerRef}>
						<PreviewCentered>
							<ErrorBoundary>{content}</ErrorBoundary>
						</PreviewCentered>
					</PreviewContent>
					{footer && (
						<ErrorBoundary>
							<PreviewFooter>{footer}</PreviewFooter>
						</ErrorBoundary>
					)}
				</WideDrawer.MainLayout>
			</WideDrawer.Root>
		);
	};

	renderContent = () => {
		const { variant, rightContent } = this.state;
		const isWithSidebar = Boolean(rightContent);

		if (variant === 'preview') {
			return this.renderPreviewDrawer();
		}

		return isWithSidebar ? this.renderWideDrawer() : this.renderDefaultDrawer();
	};

	render() {
		const { rightContent, fullMode, overlay, open } = this.state;
		const isWithSidebar = Boolean(rightContent);
		const width = isWithSidebar ? 935 : fullMode ? 900 : 420;

		return (
			<DrawerMUI
				width={width}
				open={open}
				onRequestChange={this.handleRequestChange}
				disableSwipeToOpen
				openSecondary
				docked={!overlay}
				containerStyle={style.container}
				overlayStyle={style.overlay}>
				{this.renderContent()}
			</DrawerMUI>
		);
	}
}

const DrawerSidebarControls = DrawerRightControls;

export {
	Drawer,
	DrawerChooseDialog,
	DrawerConfirmDialog,
	DrawerFooterControls,
	DrawerLink,
	DrawerRightControls,
	DrawerSidebarControls,
	DrawerTitle,
};
