import React, { useMemo } from 'react';
import MaskedInput from 'react-text-mask';

import { counterpartyApi } from '@core/api/counterparty';
import { detectIsTaxCode } from '@utils/counterparty';
import { TextField, TextFieldProps } from '@ui/input-field';
import { withFormInput } from '@ui/forms/inputs';
import { getFormValue } from '@ui/forms/shared';
import { FormState, FormInputProps, getInputFieldValue, setInputFieldValue, validators } from '@ui/forms';

export type TaxCodeTextFieldProps = {
	variant?: 'all' | 'legal-entity' | 'natural-person';
} & TextFieldProps;

const TaxCodeTextField: React.FC<TaxCodeTextFieldProps> = props => {
	const { value, variant } = props;
	const mask = maskMap[variant];
	const hintText = placeholderMap[variant];

	return (
		<TextField labelText='ИНН' hintText={hintText} {...props}>
			<MaskedInput value={value} mask={mask} keepCharPositions showMask guide={false} />
		</TextField>
	);
};

TaxCodeTextField.defaultProps = {
	variant: 'all',
};

const maskMap: Record<TaxCodeTextFieldProps['variant'], Array<RegExp>> = {
	all: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
	'legal-entity': [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
	'natural-person': [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
};

const placeholderMap: Record<TaxCodeTextFieldProps['variant'], string> = {
	all: 'xxxxxxxxxxxx',
	'legal-entity': 'xxxxxxxxxx',
	'natural-person': 'xxxxxxxxxxxx',
};

const valueLengthMap: Record<TaxCodeTextFieldProps['variant'], Array<number>> = {
	all: [10, 12],
	'legal-entity': [10],
	'natural-person': [12],
};

type FormTaxCodeTextFieldProps = {
	validateUniqueness?: boolean;
	onInvalidTaxCode?: (options: OnInvalidTaxCodeOptions) => void;
} & Omit<TaxCodeTextFieldProps, 'value' | 'onChange'> &
	Partial<Pick<TaxCodeTextFieldProps, 'onChange'>> &
	FormInputProps;

export type OnInvalidTaxCodeOptions = {
	type: 'uniq';
	counterparty: Counterparty;
};

const FormTaxCodeTextFieldBase = withFormInput<Partial<TaxCodeTextFieldProps>, unknown>(
	TaxCodeTextField,
	(props, context, wrappedElem) => {
		const value = getInputFieldValue(props, context, wrappedElem) || '';

		return value !== '';
	},
	setInputFieldValue,
	getInputFieldValue,
);

const FormTaxCodeTextField: React.FC<FormTaxCodeTextFieldProps> = props => {
	const { variant, validateUniqueness } = props;
	const errorValidations = useMemo(() => createValidations(props), [variant]);
	const errorMsgs = useMemo(() => createErrorMessages(props), [variant]);
	const asyncValidations = useMemo(() => [(data: FormState<any>) => checkIsUniqueTaxCode(data, props)], [variant]);
	const errorAsyncMsgs = useMemo(() => createAsyncErrorMessages(props), [variant]);

	return (
		<FormTaxCodeTextFieldBase
			{...props}
			errorValidations={errorValidations}
			errorMsgs={errorMsgs}
			errorAsyncValidators={validateUniqueness ? asyncValidations : undefined}
			errorAsyncMsgs={validateUniqueness ? errorAsyncMsgs : undefined}
		/>
	);
};

FormTaxCodeTextField.defaultProps = {
	variant: TaxCodeTextField.defaultProps.variant,
};

const createValidations = (props: FormTaxCodeTextFieldProps) => {
	return [
		(data: FormState<unknown>) => {
			const value = getFormValue({
				formObject: data.formObject,
				editedObject: data.editedBriefObjects,
				props,
			});

			return value ? detectIsTaxCode(value) : true;
		},
		validators.isLengthEqual(
			(data: FormState<unknown>) =>
				getFormValue({
					formObject: data.formObject,
					editedObject: data.editedBriefObjects,
					props,
				}),
			valueLengthMap[props.variant],
		),
		...(props.errorValidations || []),
	];
};

const createErrorMessages = (props: FormTaxCodeTextFieldProps) => {
	return ['Неверная последовательность цифр', valueLengthErrorMap[props.variant], ...(props.errorMsgs || [])];
};

const valueLengthErrorMap = {
	'legal-entity': 'Номер должен содержать 10 символов',
	'natural-person': 'Номер должен содержать 12 символов',
	any: 'Номер должен содержать 10 либо 12 символов',
};

const createAsyncErrorMessages = (props: FormTaxCodeTextFieldProps) => {
	const isLegalEntity = props.variant === 'legal-entity';
	const isNaturalPerson = props.variant === 'natural-person';
	const msg = isLegalEntity
		? 'Компания с таким ИНН уже существует'
		: isNaturalPerson
		? 'Лицо с таким ИНН уже существует'
		: 'Такой ИНН уже существует';

	return [msg];
};

const getCounterpartyID = (data: FormState<any>, props: FormTaxCodeTextFieldProps) => {
	const { briefObjectName } = props;
	const ID = briefObjectName
		? data?.editedBriefObjects[briefObjectName]?.ID || data?.formObject[briefObjectName]?.ID
		: data?.editedBriefObjects?.ID || data?.formObject?.ID;

	return ID || -1;
};

const checkIsUniqueTaxCode = (data: FormState<{ ID: number }>, props: FormTaxCodeTextFieldProps) => {
	const counterpartyID = getCounterpartyID(data, props);
	const taxCode = getFormValue({
		formObject: data.formObject,
		editedObject: data.editedBriefObjects,
		props,
	});

	if (!taxCode) return true;

	return new Promise<boolean>(async resolve => {
		const { isUnique, counterparty } = await counterpartyApi.counterparty.checkIsUniqueTaxCode(taxCode, counterpartyID);
		if (!isUnique && typeof props.onInvalidTaxCode === 'function') {
			props.onInvalidTaxCode({
				type: 'uniq',
				counterparty,
			});
		}

		resolve(isUnique);
	});
};

export { TaxCodeTextField, FormTaxCodeTextField };
