import styled, { css } from 'styled-components';

import { StampProps } from './stamp';

type RootProps = Pick<StampProps, 'appearance'>;

const Root = styled.div<RootProps>`
	position: relative;

	${p =>
		p.appearance === 'paid' &&
		css`
			color: #4a90e2;
		`}

	${p =>
		p.appearance === 'revoked' &&
		css`
			color: #ff3b48;
		`}
`;

const StampImage = styled.img`
	display: inline-block;
	width: 250px;
	height: auto;
	object-fit: cover;
`;

const SignLayout = styled.div`
	position: relative;
	top: -29px;
	padding: 0 10px;
`;

const Sign = styled.span`
	margin-right: 10px;
	font-size: 14px;
	font-weight: 500;
	line-height: 1.43;
	user-select: none;

	&:last-child {
		margin-right: 0;
	}
`;

export { Root, StampImage, SignLayout, Sign };
