import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';

import {
	cashflowItemAutopickerTransformer,
	FormCashflowItemAutopicker,
} from '@cashflow-items/components/cashflow-item-autopicker';
import { Box } from '@core/ui/box';
import FormRepeater from '@core/ui/forms/form-repeater/form-repeater';
import { Typography } from '@core/ui/typography';
import { DrawerRightControls } from '@ui/drawer';
import { Form, FormContent } from '@ui/forms';
import { getDrawer } from '@utils/drawer';
import * as plpackage from 'plpackage-api';
import { FormOperationTypeAutopicker, operationTypeAutopickerTransformer } from '../operation-type-autopicker';
import { FormObject } from './model';

export type RulesFormProps = {
	formObject: FormObject;
	isFetching: boolean;
	onClose: () => void;
	onSubmit: (rules: MarketplaceOperationRule[]) => void;
};

const RulesForm: React.FC<RulesFormProps> = props => {
	const { formObject, isFetching, onClose, onSubmit } = props;
	const formRef = useRef<Form<FormObject>>(null);

	const defaultRule = useMemo(() => new plpackage.MarketplaceOperationRule(), []);

	useLayoutEffect(() => {
		getDrawer().setContent({
			title: 'Определение статей',
			rightContent: <DrawerRightControls actionLabel='Сохранить' onAction={handleRequestSubmit} onClose={onClose} />,
		});
	}, []);

	const handleSubmit = () => {
		const formObject = formRef.current.getFormObject();

		onSubmit(formObject.Rules);
	};

	const handleRequestSubmit = () => formRef.current?.submitForm();

	return (
		<FormContent>
			<Form formObject={formObject} fullWidth isFetching={isFetching} onSubmit={handleSubmit} ref={formRef}>
				<Box display='grid' gridGap={40}>
					<Typography.Label>
						Соотнесите категории расходов на маркетплейсе с вашими статьями в сервисе. Это поможет корректно строить
						отчеты и даст возможность сравнивать каналы продаж.
					</Typography.Label>
					<FormRepeater
						actionLabel='+ Добавить соответствие'
						defaultFormObject={defaultRule}
						fullWidth
						name='Rules'
						withoutInitiallyItem>
						<Box display='grid' gridGap='50px' gridTemplateColumns='1fr 1fr'>
							<FormOperationTypeAutopicker
								fullWidth
								hintText='Введите значение...'
								labelText='Категория маркетплейса'
								name='MarketplaceOperationType'
								{...operationTypeAutopickerTransformer.codeNaturalKey.single}
							/>
							<FormCashflowItemAutopicker
								fullWidth
								hintText='Выберите статьи'
								includeNoData
								labelText='Статья расходов/поступлений'
								name='AccountsChartItem'
								{...cashflowItemAutopickerTransformer.codeNaturalKey.single}
							/>
						</Box>
					</FormRepeater>
				</Box>
			</Form>
		</FormContent>
	);
};

export { RulesForm };
