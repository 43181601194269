import React, { useMemo, useState, useLayoutEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

import { useMounted } from '@core/hooks';
import { getScrollContainer } from '@utils/dom';
import { ClickOutsideTracker } from '@ui/click-outside-tracker';
import { Root } from './styled';

export type PopperProps = {
	isOpen: boolean;
	anchor: HTMLElement;
	fullWidth?: boolean;
	canAutoposition?: boolean;
	children: React.ReactNode;
	onRequestClose: () => void;
};

const Popper: React.FC<PopperProps> = props => {
	const { anchor, fullWidth, canAutoposition, children, onRequestClose } = props;
	const { mounted } = useMounted();
	const [isOpen, setIsOpen] = useState(false);
	const rootRef = useRef<HTMLDivElement>(null);
	const host = useMemo(() => document.createElement('div'), []);
	const scrollContainer = useMemo(() => (anchor ? getScrollContainer(anchor) : null), [anchor]);
	const isClosing = isOpen && !props.isOpen;

	useLayoutEffect(() => {
		if (props.isOpen) {
			setIsOpen(true);
		}
	}, [props.isOpen]);

	useLayoutEffect(() => {
		if (!isOpen || !anchor) return;

		const handleUpdate = () => update();

		handleUpdate();

		window.addEventListener('resize', handleUpdate);
		scrollContainer && scrollContainer.addEventListener('scroll', handleUpdate);

		return () => {
			window.removeEventListener('resize', handleUpdate);
			scrollContainer && scrollContainer.removeEventListener('scroll', handleUpdate);
		};
	}, [isOpen, anchor]);

	const update = () => {
		if (!anchor || !rootRef.current) return;
		const rootNode = rootRef.current;
		const anchorRect = anchor.getBoundingClientRect();

		rootNode.style.setProperty('top', `${anchorRect.top}px`);
		rootNode.style.setProperty('left', `${anchorRect.left}px`);
		rootNode.style.setProperty('width', fullWidth ? `${anchorRect.width}px` : 'auto');
		canAutoposition && autoposition();
	};

	const autoposition = () => {
		const vertical = () => {
			if (!mounted() || !anchor || !rootRef.current) return;
			const rootNode = rootRef.current;
			const { height } = rootNode.getBoundingClientRect();

			if (!height) return setTimeout(vertical, 300);
			const anchorRect = anchor.getBoundingClientRect();
			const bottom = anchorRect.top + height;

			if (window.innerHeight < bottom) {
				const top = window.innerHeight < height ? 0 : anchorRect.top - (bottom - window.innerHeight);

				rootNode.style.setProperty('top', `${top}px`);
			}
		};

		vertical();
	};

	const handleAnimationEnd = () => isClosing && setIsOpen(false);

	const renderContent = () => {
		return (
			<ClickOutsideTracker onClickOutside={onRequestClose}>
				<Root ref={rootRef} isClosing={isClosing} onAnimationEnd={handleAnimationEnd}>
					{children}
				</Root>
			</ClickOutsideTracker>
		);
	};

	if (!isOpen) {
		document.body === host.parentElement && document.body.removeChild(host);
		return null;
	} else {
		document.body !== host.parentElement && document.body.appendChild(host);
	}

	return createPortal(renderContent(), host);
};

export { Popper };
