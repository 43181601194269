import styled from 'styled-components';


const HeaderRoot = styled.header`
	flex: 1 1 auto;
	width: 100%;
	height: auto;
	padding: 0 40px;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const HeaderContentLayout = styled.div`
	width: 100%;
`;

const HeaderCloseLayout = styled.div`
	position: absolute;
	top: 20px;
	left: -54px;
`;

const CloseButton = styled.button`
	background-color: rgba(62, 71, 87, 0.47);
	padding: 3px;
	margin: 0;
	outline: none;
	width: 30px;
	height: 30px;
	border: none;
	cursor: pointer;
	transition: background-color .2s ease-in-out;
	display: inline-flex;
	justify-content: center;
	align-items: center;

	&:hover {
		background-color: rgba(62, 71, 87, 0.8);
	}
`;


export {
	HeaderRoot,
	HeaderContentLayout,
	HeaderCloseLayout,
	CloseButton,
}
