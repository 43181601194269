import React, { useMemo } from 'react';
import MaskedInput from 'react-text-mask';

import { detectIsStateRegistrationCode } from '@utils/counterparty';
import { TextField, TextFieldProps } from '@ui/input-field';
import { withFormInput } from '@ui/forms/inputs';
import { getFormValue } from '@ui/forms/shared';
import { FormState, FormInputProps, getInputFieldValue, setInputFieldValue, validators } from '@ui/forms';

export type StateRegistrationCodeTextFieldProps = {
	variant?: 'legal-entity' | 'sole-proprietor';
} & TextFieldProps;

const StateRegistrationCodeTextField: React.FC<StateRegistrationCodeTextFieldProps> = props => {
	const { value, variant } = props;
	const isLegalEntity = variant === 'legal-entity';
	const mask = isLegalEntity ? legalEntityMask : soleProprietorMask;
	const hintText = isLegalEntity ? 'xxxxxxxxxxxxx' : 'xxxxxxxxxxxxxxx';
	const labelText = isLegalEntity ? 'ОГРН' : 'ОГРНИП';

	return (
		<TextField labelText={labelText} hintText={hintText} {...props}>
			<MaskedInput value={value} mask={mask} keepCharPositions showMask guide={false} />
		</TextField>
	);
};

StateRegistrationCodeTextField.defaultProps = {
	variant: 'legal-entity',
};

const legalEntityMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
const soleProprietorMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

type FormStateRegistrationCodeTextFieldProps = Omit<StateRegistrationCodeTextFieldProps, 'value' | 'onChange'> &
	Partial<Pick<StateRegistrationCodeTextFieldProps, 'onChange'>> &
	FormInputProps;

const FormStateRegistrationCodeTextFieldBase = withFormInput<Partial<StateRegistrationCodeTextFieldProps>, unknown>(
	StateRegistrationCodeTextField,
	(props, context, wrappedElem) => {
		const value = getInputFieldValue(props, context, wrappedElem) || '';

		return value !== '';
	},
	setInputFieldValue,
	getInputFieldValue,
);

const FormStateRegistrationCodeTextField: React.FC<FormStateRegistrationCodeTextFieldProps> = props => {
	const { variant } = props;
	const errorValidations = useMemo(() => createValidations(props), [variant]);
	const errorMsgs = useMemo(() => createErrorMessages(props), [variant]);

	return (
		<FormStateRegistrationCodeTextFieldBase {...props} errorValidations={errorValidations} errorMsgs={errorMsgs} />
	);
};

FormStateRegistrationCodeTextField.defaultProps = {
	variant: StateRegistrationCodeTextField.defaultProps.variant,
};

const createValidations = (props: FormStateRegistrationCodeTextFieldProps) => {
	const isLegalEntity = props.variant === 'legal-entity';

	return [
		(data: FormState<unknown>) => {
			const value = getFormValue({
				formObject: data.formObject,
				editedObject: data.editedBriefObjects,
				props,
			});

			return value ? detectIsStateRegistrationCode(value) : true;
		},
		validators.isLengthEqual(
			(data: FormState<unknown>) =>
				getFormValue({
					formObject: data.formObject,
					editedObject: data.editedBriefObjects,
					props,
				}),
			isLegalEntity ? [13] : [15],
		),
		...(props.errorValidations || []),
	];
};

const createErrorMessages = (props: FormStateRegistrationCodeTextFieldProps) => {
	const isLegalEntity = props.variant === 'legal-entity';

	return [
		'Неверная последовательность цифр',
		isLegalEntity ? 'Номер должен содержать 13 символов' : 'Номер должен содержать 15 символов',
		...(props.errorMsgs || []),
	];
};

export { StateRegistrationCodeTextField, FormStateRegistrationCodeTextField };
