import styled, { css, keyframes } from 'styled-components';

const showCenterMotion = keyframes`
	0% {
		transform: translateY(100%);
	}

	100% {
		transform: translateY(0);
	}
`;

const hideCenterMotion = keyframes`
	0% {
		transform: translateY(0);
	}

	100% {
		transform: translateY(calc(100% + 20px));
	}
`;

type RootProps = {
	needHideAnimation?: boolean;
	asSimpleComponent?: boolean;
};

const Root = styled.div<RootProps>`
	position: fixed;
	z-index: 10000;
	bottom: 20px;
	left: 20px;
	width: auto;
	height: auto;
	min-width: 329px;
	min-height: 80px;
	max-width: 650px;
	border-radius: 2px;
	transition: all 0.2s ease-in-out;
	display: flex;
	flex-flow: column wrap;
	justify-content: flex-start;
	align-items: flex-start;
	animation-name: ${showCenterMotion};
	animation-duration: 0.1s;
	animation-timing-function: ease-in-out;
	animation-direction: alternate;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;

	${p => css`
		background-color: ${p.theme.materialCard.backgroundColor};
		${p.theme.fn.createBoxShadow(2)}
	`}

	${p =>
		p.needHideAnimation &&
		css`
			animation-name: ${hideCenterMotion};
		`}

	${p =>
		p.asSimpleComponent &&
		css`
			position: relative;
			max-width: 400px;
			bottom: auto;
			left: auto;
		`}

	@media (max-width: 640px) {
		width: 100%;
		max-width: none;
		border-radius: 0;
		bottom: 0;
		right: 0;
		border-radius: 2px 2px 0 0;
	}
`;

const HeaderLayout = styled.div`
	position: relative;
	width: 100%;
	height: auto;
`;

const ContentLayout = styled.div`
	flex: 1 1 auto;
	width: 100%;
	height: auto;
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: stretch;
	font-size: 12px;
	line-height: 1.2;
`;

type IconLayoutProps = {
	type: 'default' | 'success' | 'warning' | 'alarm';
};

const IconLayout = styled.div<IconLayoutProps>`
	width: 80px;
	padding: 16px;

	& svg {
		width: 48px !important;
		height: 48px !important;
	}

	${p =>
		p.type === 'success' &&
		css`
			background-color: ${p.theme.palette.accent};
		`}

	${p =>
		p.type === 'warning' &&
		css`
			background-color: ${p.theme.palette.warning};
		`}

	${p =>
		p.type === 'alarm' &&
		css`
			background-color: ${p.theme.palette.alarm};
		`}
`;

const ChildrenLayout = styled.div`
	width: 100%;
	height: auto;
	padding: 16px;
`;

const CloseButtonLayout = styled.div`
	position: absolute;
	top: 2px;
	right: 2px;
`;

const CloseButton = styled.button`
	display: inline-flex;
	width: auto;
	height: auto;
	border: none;
	outline: none;
	padding: 0;
	margin: 0;
	background: transparent;
	cursor: pointer;
`;

export { Root, HeaderLayout, ContentLayout, IconLayout, ChildrenLayout, CloseButtonLayout, CloseButton };
