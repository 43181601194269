import {
	StoreAsyncItem,
	checkAsyncAction,
	createAsyncInitialState,
	createReducer,
	invalidateStateFromAction,
} from '@flux';
import { types } from '@products/actions/types';
import { ProductsSorting, ProductsViewCode } from '@products/models';
import { transformNestedTreeToFlattenTree } from '@utils/tree';

export type MainProductsState = {
	groupsFilter: Record<number, boolean>;
	isAllItemsExpanded: boolean;
	lastTaxRate: number;
	measureUnits: StoreAsyncItem<Array<MeasureUnit>>;
	products: StoreAsyncItem<Array<Product>>;
	productsView: ProductsViewCode;
	productTypes: StoreAsyncItem<Array<ProductType>>;
	searchText: string;
	sorting: ProductsSorting;
	typesFilter: Record<number, boolean>;
};

const initialState: MainProductsState = {
	groupsFilter: {},
	isAllItemsExpanded: true,
	lastTaxRate: null,
	measureUnits: createAsyncInitialState([]),
	products: createAsyncInitialState([]),
	productsView: ProductsViewCode.LIST,
	productTypes: createAsyncInitialState([]),
	searchText: '',
	sorting: ProductsSorting.ID_DESC,
	typesFilter: {},
};

const mainProductsReducer = createReducer(initialState, {
	[types.ADD_PRODUCT_GROUP]: (action: AsyncAction<Product>, state) => {
		return {
			products: invalidateStateFromAction(action, state.products),
		};
	},
	[types.ADD_PRODUCT]: (action: AsyncAction<Product>, state) => {
		return {
			products: invalidateStateFromAction(action, state.products),
		};
	},
	[types.EXPAND_ALL_ITEMS]: (action: StaticAction<boolean>) => {
		return {
			isAllItemsExpanded: action.value,
		};
	},
	[types.FETCH_MEASURE_UNITS]: (action: AsyncAction<Array<MeasureUnit>>, state) => {
		return {
			measureUnits: checkAsyncAction(action, state.measureUnits),
		};
	},
	[types.FETCH_PRODUCT_TYPES]: (action: AsyncAction<Array<ProductType>>, state) => {
		return {
			productTypes: checkAsyncAction(action, state.productTypes),
		};
	},
	[types.FETCH_PRODUCTS]: (action: AsyncAction<Array<Product>>, state) => {
		return {
			products: checkAsyncAction(action, state.products, response =>
				transformNestedTreeToFlattenTree({ items: response, getChildItems: x => x.ChildItems as Array<Product> }),
			),
		};
	},
	[types.INVALIDATE_PRODUCTS]: (action: StaticAction<undefined>, state) => {
		return {
			products: invalidateStateFromAction(action, state.products),
		};
	},
	[types.LINK_PRODUCTS_TO_PRODUCT_GROUP]: (action: AsyncAction<Product>, state) => {
		return {
			products: invalidateStateFromAction(action, state.products),
		};
	},
	[types.REMOVE_PRODUCT_GROUP]: (action: AsyncAction<boolean>, state) => {
		return {
			products: invalidateStateFromAction(action, state.products),
		};
	},
	[types.REMOVE_PRODUCT]: (action: AsyncAction<boolean>, state) => {
		return {
			products: invalidateStateFromAction(action, state.products),
		};
	},
	[types.RESET_FILTERS]: () => {
		return {
			searchText: '',
			typesFilter: {},
			groupsFilter: {},
		};
	},
	[types.SET_FILTER_BY_GROUPS]: (action: StaticAction<Record<number, boolean>>) => {
		return {
			groupsFilter: action.value,
		};
	},
	[types.SET_FILTER_BY_TEXT]: (action: StaticAction<string>) => {
		return {
			searchText: action.value,
		};
	},
	[types.SET_FILTER_BY_TYPES]: (action: StaticAction<Record<number, boolean>>) => {
		return {
			typesFilter: action.value,
		};
	},
	[types.SET_LAST_TAX_RATE]: (action: StaticAction<number>) => {
		return {
			lastTaxRate: action.value,
		};
	},
	[types.SET_PRODUCTS_VIEW]: (action: StaticAction<ProductsViewCode>) => {
		return {
			productsView: action.value,
		};
	},
	[types.SET_SORTING]: (action: StaticAction<ProductsSorting>) => {
		return {
			sorting: action.value,
		};
	},
	[types.UPDATE_PRODUCT_GROUP]: (action: AsyncAction<Product>, state) => {
		return {
			products: invalidateStateFromAction(action, state.products),
		};
	},
	[types.UPDATE_PRODUCT]: (action: AsyncAction<Product>, state) => {
		return {
			products: invalidateStateFromAction(action, state.products),
		};
	},
});

export { mainProductsReducer };
