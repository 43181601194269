import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { IAppState } from '../../../../../../../core/store';
import { resetBillingDisplayPathAction } from '../../../../../actions/display-controller.actions';
import { selectTenantAccountExpireDate } from '../../../../../selectors/billing.selectors';
import XPersonPaymentResultDisplay from './person-payment-result-display.view';

interface IProps {
	expireDate?: string;

	resetPath?: () => void;
}

class PersonPaymentResultDisplay extends React.PureComponent<IProps> {
	static displayName = 'PersonPaymentResultDisplay[Container]';

	render() {
		const {
			expireDate,

			resetPath,
		} = this.props;

		return <XPersonPaymentResultDisplay expireDate={expireDate} resetPath={resetPath} />;
	}
}

function mapStateToProps(state: IAppState) {
	return {
		expireDate: selectTenantAccountExpireDate(state),
	};
}

function mapDispatchToProps(dispatch: Dispatch<any>) {
	return bindActionCreators(
		{
			resetPath: resetBillingDisplayPathAction,
		},
		dispatch,
	);
}

export default connect<any, any, IProps>(mapStateToProps, mapDispatchToProps)(PersonPaymentResultDisplay);
