import store from '@store';

function detectIsLocalhost(): boolean {
	return location.hostname === 'localhost' || location.hostname === '127.0.0.1';
}

function detectIsDevHost(): boolean {
	const isDevHost = detectIsLocalhost() || location.hostname.indexOf('test') !== -1;

	return isDevHost;
}

function detectIsTechServiceUser() {
	const state = store.getState();
	const userLogin = state?.platform?.sessionContext?.item?.SessionUser?.Login || '';
	const tenantID = state?.platform?.sessionContext?.item?.SessionTenant?.ID || -1;
	const isMatch = userLogin === `techService_${tenantID}`;

	return isMatch;
}

export { detectIsLocalhost, detectIsDevHost, detectIsTechServiceUser };
