import React, { createContext, useContext, forwardRef } from 'react';

import { extractThemeBrandFromBuild } from '@utils/brand';
import { ThemeBrand, ThemeMode, ThemeIdentifier } from './models';
import { createTheme } from './characters';

const getDefaultBrandIdentifier = () => {
	const brand = extractThemeBrandFromBuild();

	return brand || ThemeBrand.SEENECO;
};

const defaultThemeIdentifier: ThemeIdentifier = [getDefaultBrandIdentifier(), ThemeMode.LIGHT];
const ThemeContext = createContext<ThemeIdentifier>(defaultThemeIdentifier);

function useTheme() {
	const value = useContext(ThemeContext);
	const theme = createTheme(value);
	const [themeBrand, themeMode] = value;
	const isDarkMode = themeMode === ThemeMode.DARK;

	return {
		isDarkMode,
		themeBrand,
		themeMode,
		theme,
	};
}

type ThemeProviderProps = {
	themeIdentifier: ThemeIdentifier;
	children: React.ReactNode;
};

const ThemeProvider: React.FC<ThemeProviderProps> = ({ themeIdentifier, children }) => {
	return <ThemeContext.Provider value={themeIdentifier}>{children}</ThemeContext.Provider>;
};

type ThemeConsumerProps = {
	children: (value: ThemeIdentifier) => React.ReactNode;
};

const ThemeConsumer: React.FC<ThemeConsumerProps> = ({ children }) => {
	return <ThemeContext.Consumer>{value => children(value)}</ThemeContext.Consumer>;
};

function withTheme<P>(WrappedComponent: React.ComponentType<P>) {
	const Component = forwardRef<unknown, P>((props, ref) => {
		const { theme } = useTheme();

		return <WrappedComponent ref={ref} {...props} theme={theme} />;
	});

	return Component;
}

export { defaultThemeIdentifier, useTheme, ThemeProvider, ThemeConsumer, withTheme };
