import { createStaticDataAction } from '../../../core/libs/action-creator';


const SET_INVOICE_FILTER_BY_DATE = '[INVOICE]: SET_INVOICE_FILTER_BY_DATE';
const filterByDate = createStaticDataAction(SET_INVOICE_FILTER_BY_DATE);

const SET_PURCHASES_FILTER_BY_COUNTERPARTY = '[INVOICE PURCHASE]: SET_PURCHASES_FILTER_BY_COUNTERPARTY';
const filterPurcasesByCounterpartyAction  = createStaticDataAction(SET_PURCHASES_FILTER_BY_COUNTERPARTY);

const SET_PURCHASES_FILTER_BY_SUBSTR = '[INVOICE PURCHASE]: SET_PURCHASES_FILTER_BY_SUBSTR';
const filterPurchasesBySubstrAction = createStaticDataAction(SET_PURCHASES_FILTER_BY_SUBSTR);

const SET_PURCHASES_FILTER_BY_DATE = '[INVOICE PURCHASES]: SET_PURCHASES_FILTER_BY_DATE';
const filterPurchasesByDateAction = createStaticDataAction(SET_PURCHASES_FILTER_BY_DATE);

const SET_PURCHASES_FILTER_BY_ALL = '[INVOICE PURCHASES]: SET_PURCHASES_FILTER_BY_ALL';
const filterPurchasesByAllAction = createStaticDataAction(SET_PURCHASES_FILTER_BY_ALL);

const SET_PURCHASES_FILTER_BY_NOT_PAID = '[INVOICE PURCHASES]: SET_PURCHASES_FILTER_BY_NOT_PAID';
const filterPurchasesByNotPaidAction = createStaticDataAction(SET_PURCHASES_FILTER_BY_NOT_PAID);

const SET_PURCHASES_FILTER_BY_PAID = '[INVOICE PURCHASES]: SET_PURCHASES_FILTER_BY_PAID';
const filterPurchasesByPaidAction = createStaticDataAction(SET_PURCHASES_FILTER_BY_PAID);

const SET_PURCHASES_FILTER_BY_OVERDUE = '[INVOICE PURCHASES]: SET_PURCHASES_FILTER_BY_OVERDUE';
const filterPurchasesByOverdueAction = createStaticDataAction(SET_PURCHASES_FILTER_BY_OVERDUE);

const SET_PURCHASES_FILTER_BY_DRAFT = '[INVOICE PURCHASES]: SET_PURCHASES_FILTER_BY_DRAFT';
const filterPurchasesByDraftAction = createStaticDataAction(SET_PURCHASES_FILTER_BY_DRAFT);

const SET_PURCHASES_FILTER_BY_NUMBER = '[INVOICE PURCHASES]: SET_PURCHASES_FILTER_BY_NUMBER';
const filterPurchasesByNumberAction = createStaticDataAction(SET_PURCHASES_FILTER_BY_NUMBER);

const SET_PURCHASES_FILTER_BY_DESCRIPTION = '[INVOICE PURCHASES]: SET_PURCHASES_FILTER_BY_DESCRIPTION';
const filterPurchasesByDescriptionAction = createStaticDataAction(SET_PURCHASES_FILTER_BY_DESCRIPTION);

const CLEAR_ALL_PURCHASES_FILTERS = '[INVOICE PURCHASES]: CLEAR_ALL_PURCHASES_FILTERS';
const clearAllPurchasesFilersAction = createStaticDataAction(CLEAR_ALL_PURCHASES_FILTERS);

const SET_SALES_FILTER_BY_COUNTERPARTY = '[INVOICE SALES]: SET_SALES_FILTER_BY_COUNTERPARTY';
const filterSalesByCounterpartyAction  = createStaticDataAction(SET_SALES_FILTER_BY_COUNTERPARTY);

const SET_SALES_FILTER_BY_SUBSTR = '[INVOICE SALES]: SET_SALES_FILTER_BY_SUBSTR';
const filterSalesBySubstrAction = createStaticDataAction(SET_SALES_FILTER_BY_SUBSTR);

const SET_SALES_FILTER_BY_DATE = '[INVOICE SALES]: SET_SALES_FILTER_BY_DATE';
const filterSalesByDateAction = createStaticDataAction(SET_SALES_FILTER_BY_DATE);

const SET_SALES_FILTER_BY_ALL = '[INVOICE SALES]: SET_SALES_FILTER_BY_ALL';
const filterSalesByAllAction = createStaticDataAction(SET_SALES_FILTER_BY_ALL);

const SET_SALES_FILTER_BY_NOT_PAID = '[INVOICE SALES]: SET_SALES_FILTER_BY_NOT_PAID';
const filterSalesByNotPaidAction = createStaticDataAction(SET_SALES_FILTER_BY_NOT_PAID);

const SET_SALES_FILTER_BY_PAID = '[INVOICE SALES]: SET_SALES_FILTER_BY_PAID';
const filterSalesByPaidAction = createStaticDataAction(SET_SALES_FILTER_BY_PAID);

const SET_SALES_FILTER_BY_OVERDUE = '[INVOICE SALES]: SET_SALES_FILTER_BY_OVERDUE';
const filterSalesByOverdueAction = createStaticDataAction(SET_SALES_FILTER_BY_OVERDUE);

const SET_SALES_FILTER_BY_DRAFT = '[INVOICE SALES]: SET_SALES_FILTER_BY_DRAFT';
const filterSalesByDraftAction = createStaticDataAction(SET_SALES_FILTER_BY_DRAFT);

const SET_SALES_FILTER_BY_NUMBER = '[INVOICE SALES]: SET_SALES_FILTER_BY_NUMBER';
const filterSalesByNumberAction = createStaticDataAction(SET_SALES_FILTER_BY_NUMBER);

const SET_SALES_FILTER_BY_DESCRIPTION = '[INVOICE SALES]: SET_SALES_FILTER_BY_DESCRIPTION';
const filterSalesByDescriptionAction = createStaticDataAction(SET_SALES_FILTER_BY_DESCRIPTION);

const SET_SALES_FILTER_BY_MANAGER = '[INVOICE SALES]: SET_SALES_FILTER_BY_MANAGER';
const filterSalesByManagerAction = createStaticDataAction(SET_SALES_FILTER_BY_MANAGER);

const SET_SALES_FILTER_BY_LINKED_DOCUMENTS_EXISTS = '[INVOICE SALES]: SET_SALES_FILTER_BY_LINKED_DOCUMENTS_EXISTS';
const filterSalesByLinkedDocumentExistsAction = createStaticDataAction(SET_SALES_FILTER_BY_LINKED_DOCUMENTS_EXISTS);

const CLEAR_ALL_SALES_FILTERS = '[INVOICE SALES]: CLEAR_ALL_SALES_FILTERS';
const clearAllSalesFilersAction = createStaticDataAction(CLEAR_ALL_SALES_FILTERS);


export {
	SET_INVOICE_FILTER_BY_DATE,
	SET_PURCHASES_FILTER_BY_COUNTERPARTY,
	SET_PURCHASES_FILTER_BY_SUBSTR,
	SET_PURCHASES_FILTER_BY_DATE,
	SET_PURCHASES_FILTER_BY_ALL,
	SET_PURCHASES_FILTER_BY_NOT_PAID,
	SET_PURCHASES_FILTER_BY_PAID,
	SET_PURCHASES_FILTER_BY_OVERDUE,
	SET_PURCHASES_FILTER_BY_DRAFT,
	SET_PURCHASES_FILTER_BY_NUMBER,
	SET_PURCHASES_FILTER_BY_DESCRIPTION,
	CLEAR_ALL_PURCHASES_FILTERS,
	SET_SALES_FILTER_BY_COUNTERPARTY,
	SET_SALES_FILTER_BY_SUBSTR,
	SET_SALES_FILTER_BY_DATE,
	SET_SALES_FILTER_BY_ALL,
	SET_SALES_FILTER_BY_NOT_PAID,
	SET_SALES_FILTER_BY_PAID,
	SET_SALES_FILTER_BY_OVERDUE,
	SET_SALES_FILTER_BY_DRAFT,
	SET_SALES_FILTER_BY_NUMBER,
	SET_SALES_FILTER_BY_DESCRIPTION,
	SET_SALES_FILTER_BY_MANAGER,
	SET_SALES_FILTER_BY_LINKED_DOCUMENTS_EXISTS,
	CLEAR_ALL_SALES_FILTERS,

	filterByDate,
	filterPurcasesByCounterpartyAction,
	filterPurchasesBySubstrAction,
	filterPurchasesByDateAction,
	filterPurchasesByAllAction,
	filterPurchasesByNotPaidAction,
	filterPurchasesByPaidAction,
	filterPurchasesByOverdueAction,
	filterPurchasesByDraftAction,
	filterPurchasesByNumberAction,
	filterPurchasesByDescriptionAction,
	clearAllPurchasesFilersAction,
	filterSalesByCounterpartyAction,
	filterSalesBySubstrAction,
	filterSalesByDateAction,
	filterSalesByAllAction,
	filterSalesByNotPaidAction,
	filterSalesByPaidAction,
	filterSalesByOverdueAction,
	filterSalesByDraftAction,
	filterSalesByNumberAction,
	filterSalesByDescriptionAction,
	filterSalesByManagerAction,
	filterSalesByLinkedDocumentExistsAction,
	clearAllSalesFilersAction
}