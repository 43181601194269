import styled, { css } from 'styled-components';

const Root = styled.span`
	& a {
		font-size: 14px;
		line-height: 1.25;
		${p => css`
			color: ${p.theme.palette.accent};
		`}
		transition: color 0.2s ease-in-out;
		cursor: pointer;
	}

	& a:hover {
		${p => css`
			color: ${p.theme.palette.accent};
		`}
		text-decoration: underline;
	}
`;

const SimpleLink = styled.a``;

export { Root, SimpleLink };
