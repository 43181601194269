import styled from 'styled-components';

import * as c from '../../../../../../../core/ui/basic-theme/colors';


const Root = styled.div`
	position: relative;
	width: 100%;
	height: auto;
	min-height: 350px;
	display: flex;
	align-items: center;
`;

const RightContentLayout = styled.div`

`;


export {
	Root,
	RightContentLayout
};
