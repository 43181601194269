import axios from 'axios';

import { getTicketUid } from '@utils/storage';

const fileApi = {
	uploadFile: (file: File, fileName = 'image') => {
		const ticketUid = getTicketUid();

		return new Promise<string>((resolve, reject) => {
			const ext = ((file && file.name ? file.name : '').split('.').pop() || '').toLowerCase();
			const url = `/atr-framework-services/upload?ticketUID=${ticketUid}&file-access-type=public&save-as-file-name=${fileName}.${ext}`;
			const data = new FormData();

			if (!ext) {
				resolve('');
				return;
			}

			data.append(file.name, file);
			axios
				.post(url, data)
				.then(res => resolve('/' + res.data[0].url))
				.catch(err => reject(err));
		});
	},
};

export { fileApi };
