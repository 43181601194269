import styled from 'styled-components';

const Root = styled.div`
	position: absolute;
	width: 1px;
	height: 1px;
	outline: none;
`;

export { Root };
