import { createAsyncSelector, createSelector } from '@core/flux';
import { ServiceTaskKey } from '@initialization/components/service-tasks/model';
import { AboutCompanyPreferences } from '@initialization/models';
import { IAppState } from '@store';

const selectAsyncAboutCompanyPreferences = createAsyncSelector<AboutCompanyPreferences, IAppState>({
	get: s => s.initialization.aboutCompanyPreferences,
	selector: createSelector(
		s => s.initialization.aboutCompanyPreferences.item,
		item => item,
	),
});

const selectAsyncServiceFeatures = createAsyncSelector<Array<ServiceTaskKey>, IAppState>({
	get: s => s.initialization.serviceFeatures,
	selector: createSelector(
		s => s.initialization.serviceFeatures.item,
		item => item,
	),
});

function selectIsBackgroundImportInProcess(state: IAppState) {
	return state.initialization.isBackgroundImportInProcess;
}

function selectIsBackgroundImportIsComplete(state: IAppState) {
	return state.initialization.isBackgroundImportIsComplete;
}

function selectIsInitializationInProcess(state: IAppState) {
	return state.initialization.inProcess;
}

function selectIsInitializationIsComplete(state: IAppState) {
	return state.initialization.isComplete;
}

export const initializationSelectorsPack = {
	selectAsyncAboutCompanyPreferences,
	selectAsyncServiceFeatures,
	selectIsBackgroundImportInProcess,
	selectIsBackgroundImportIsComplete,
	selectIsInitializationInProcess,
	selectIsInitializationIsComplete,
};
