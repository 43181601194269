import styled from 'styled-components';


interface IRoot {
	isPassword: boolean;
}

const Root = styled.div`
	position: relative;

	${(p: IRoot) => p.isPassword && `
		& .input {
			padding-right: 64px !important;
		}
	`}
`;


export {
	Root
}