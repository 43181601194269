import { Store } from 'redux';

import { IAppState } from '@store';
import { isReceiveAction } from '@flux';
import { types } from '@document-templates/actions/types';
import { invalidateModuleData } from '@document-templates/actions/invalidators';

function askDocumentTemplatesInvalidator(store: Store<IAppState>, action: StoreAction) {
	const { dispatch } = store;
	const invalidateMap = {
		[types.ADD_DOCUMENT_TEMPLATE]: true,
		[types.UPDATE_DOCUMENT_TEMPLATE]: true,
		[types.REMOVE_DOCUMENT_TEMPLATE]: true,
	};

	if (invalidateMap[action.type] && isReceiveAction(action)) {
		dispatch(invalidateModuleData());
	}
}

function createInvalidatorEffect() {
	return (store: Store<IAppState>) => (next: (action: StoreAction) => void) => (action: StoreAction) => {
		next(action);
		askDocumentTemplatesInvalidator(store, action);
	};
}

const runInvalidatorEffect = createInvalidatorEffect();

export { runInvalidatorEffect };
