import styled, { keyframes, css } from 'styled-components';

import { RaisedButton } from '@ui/raised-button';

interface IShared {
	bigMode: boolean;
}

const Root = styled.div`
	width: 100%;
	height: 100%;
	padding: 40px;
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	align-items: center;
`;

const WrapLayout = styled.div`
	width: 100%;
`;

const IconLayout = styled.div`
	width: 100%;
	height: auto;
	display: flex;
	flex-flow: column wrap;
	justify-content: center;
	align-items: center;
	margin-bottom: 20px;

	& svg {
		width: 100px !important;
		height: 100px !important;
	}

	${(props: IShared) =>
		props.bigMode &&
		css`
			margin-bottom: 40px;

			& svg {
				width: 142px !important;
				height: 142px !important;
			}
		`}
`;

const TitleLayout = styled.div`
	margin-bottom: 30px;
`;

const Title = styled.div`
	font-size: 14px;
	font-weight: 500;
	line-height: 1.43;
	text-align: center;

	${(props: IShared) =>
		props.bigMode &&
		css`
			font-size: 30px;
			line-height: 1;
		`}
`;

const Detail = styled.div`
	font-size: 14px;
	line-height: 1.43;
	text-align: center;
	margin-top: 5px;

	${(props: IShared) =>
		props.bigMode &&
		css`
			font-size: 17px;
			margin-top: 25px;
			line-height: 1.18;
		`}

	${p => css`
		color: ${p.theme.palette.label};
	`}
`;

const ControlsLayout = styled.div`
	position: relative;
	width: 100%;
	height: auto;
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	align-items: center;
`;

const ButtonLayout = styled.div``;

const ActionButton = styled(RaisedButton).attrs(() => ({
	type: 'primary',
	style: {
		minWidth: '160px',
	},
}))``;

const slowMotion = keyframes`
	0% {
		stroke-dasharray: 0 200px;
	}

	100% {
		stroke-dasharray: 300px 200px;
	}
`;

const Path = styled.path`
	animation-name: ${slowMotion};
	animation-timing-function: ease-in-out;
	animation-iteration-count: 1;
	animation-duration: 1s;

	${p => css`
		stroke: ${p.theme.palette.accent};
	`}
`;

export { Root, WrapLayout, IconLayout, TitleLayout, Title, Detail, ControlsLayout, ButtonLayout, ActionButton, Path };
