import { createTypes } from '@flux';

type ActionTypes = {
	SET_FILTER_BY_TEXT: string;
	SET_FILTER_BY_PROJECTS: string;
	SET_FILTER_BY_BUSINESS_ROLES: string;
	RESET_FILTERS: string;
	INVALIDATE_EMPLOYEES: string;
	FETCH_PROJECT_EMPLOYEES: string;
	ADD_EMPLOYEE: string;
	UPDATE_EMPLOYEE: string;
	REMOVE_EMPLOYEE: string;
	ASSIGN_ROLE_TO_EMPLOYEES: string;
	SET_PROJECS_ACCESS: string;
};

export const types = createTypes<ActionTypes>(
	[
		'SET_FILTER_BY_TEXT',
		'SET_FILTER_BY_PROJECTS',
		'SET_FILTER_BY_BUSINESS_ROLES',
		'RESET_FILTERS',
		'INVALIDATE_EMPLOYEES',
		'FETCH_PROJECT_EMPLOYEES',
		'ADD_EMPLOYEE',
		'UPDATE_EMPLOYEE',
		'REMOVE_EMPLOYEE',
		'ASSIGN_ROLE_TO_EMPLOYEES',
		'SET_PROJECS_ACCESS',
	],
	'PROJECT_EMPLOYEES',
);
