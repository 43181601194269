import * as moment from 'moment';
import { compose } from 'redux';

import { projectApi } from '@core/api/project';
import { createAsyncSelector, createRightCheck, createSelector } from '@flux';
import { compareDates, createDefaultPeriod } from '@utils/date';
import { createObjectMap, extractKeysToArray, hasBooleanKeys } from '@utils/object';
import { groupBy } from '@utils/helpers';
import { IAppState } from '@store';
import { DataSourceTreeNode } from '@core/api/core';
import { sortAscBy, sortDescBy } from '@utils';
import { safeNumber } from '@utils/numbers';
import { getProfitability, getResult, getSum } from '@utils/finance';
import {
	crateExpandedMap,
	createDataSourceTree,
	createSortedFlattenTree,
	deepFilter,
	executeDeepFn,
} from '@utils/tree';
import { BASE_DATE_FORMAT } from '@shared/constants/time';
import {
	HierarchyTableColumn,
	HierarchyTableRow,
	OperationDateTypeCode,
	ProjectRelevanceCode,
	ProjectStatisticsRatiosCode,
	ProjectStatisticsSliceCode,
	VirtualListGroupCode,
} from '@project-management/models';
import { ProjectWithStatistics, sortWithGroup } from '@project-management/utils';

const selectProjectsRight = createRightCheck({
	component: 'ProjectFinanceManagement',
	view: 'xProjects_ViewProjects',
	change: 'xProjects_ChangeProjects',
});

const selectProjectsAccessRight = createRightCheck({
	change: 'xProjects_ChangeProjectsAccess',
});

const selectAsyncProjects = createAsyncSelector<Array<Project>, IAppState>({
	get: s => s.projectManagement.main.projects,
	selector: createSelector(
		s => s.projectManagement.main.projects.item,
		item => item,
	),
});

const selectAsyncAllProjectDynamicSeries = createAsyncSelector<Array<PLOperationProjectSeries>, IAppState>({
	get: s => s.projectManagement.main.allDynamicSeries,
	selector: createSelector(
		s => s.projectManagement.main.allDynamicSeries.item,
		item => item,
	),
});

const selectAsyncDetailProjectDynamicSeries = createAsyncSelector<Array<PLOperationProjectSeries>, IAppState>({
	get: s => s.projectManagement.main.detailDynamicSeries,
	selector: createSelector(
		s => s.projectManagement.main.detailDynamicSeries.item,
		item => item,
	),
});

const selectAsyncProjectOperations = createAsyncSelector<Array<PLOperationBrief>, IAppState>({
	get: s => s.projectManagement.main.operations,
	selector: createSelector(
		s => s.projectManagement.main.operations.item,
		item => item,
	),
});

const selectProjectsMap = createSelector(selectAsyncProjects.selectItem, projects => {
	return createObjectMap(projects, x => x.ID);
});

const selectExtendedProjectsMap = createSelector(selectAsyncProjects.selectItem, projects => {
	const map = createObjectMap(projects, x => x.ID);

	map[-1] = { ...new projectApi.package.Project(), Name: 'Проект не указан' };

	return map;
});

const selectProjectsByCodeMap = createSelector(selectAsyncProjects.selectItem, projects => {
	return createObjectMap(projects, x => x.Code);
});

const selectCalculatedDateRange = createSelector(selectAsyncProjects.selectItem, (projects: Array<Project>) => {
	const dateRange = createDefaultPeriod('month');
	if (projects.length === 0) return dateRange;
	const sortedByDateStart = sortAscBy(projects, [{ fn: item => item.DateStart, isDate: true }]);
	const sortedByDateEnd = sortDescBy(projects, [{ fn: item => item.DateFinish, isDate: true }]);

	dateRange.dateStart = sortedByDateStart[0].DateStart;
	dateRange.dateEnd = sortedByDateEnd[0].DateFinish;

	return dateRange;
});

const selectAllProjectDynamicSeriesMap = createSelector(
	selectAsyncAllProjectDynamicSeries.selectItem,
	dynamicSeries => {
		const map = createObjectMap(dynamicSeries, x => x.ProjectID) as Record<string, PLOperationProjectSeries>;

		return map;
	},
);

const selectDetailProjectDynamicSeriesMap = createSelector(
	selectAsyncDetailProjectDynamicSeries.selectItem,
	dynamicSeries => {
		const map = createObjectMap(dynamicSeries, x => x.ProjectID) as Record<string, PLOperationProjectSeries>;

		return map;
	},
);

function detectIsProjectPassesRelevanceFilter(
	project: Project,
	filter: Partial<Record<ProjectRelevanceCode, boolean>>,
	now: Moment = moment(),
) {
	const { isBefore } = compareDates(project.DateFinish, now);
	const isOverdue = isBefore();
	const isPassed =
		(filter[ProjectRelevanceCode.ACTIVE] && isOverdue === false) ||
		(filter[ProjectRelevanceCode.IN_ARCHIVE] && isOverdue === true);

	return isPassed;
}

const selectFilteredProjects = createSelector(
	selectAsyncProjects.selectItem,
	selectFilterByText,
	selectFilterByTenantEntities,
	selectFilterByBusinessUnits,
	selectFilterByClients,
	selectFilterByManagers,
	selectFilterByRelevance,
	selectAsyncProjectOperations.selectItem,
	selectFundsRegistersFilter,
	(
		projects,
		searchText,
		tenantEntitiesFilter,
		businessUnitsFilter,
		clientsFilter,
		managersFilter,
		relevanceFilter,
		operations,
		fundsRegistersFilter,
	) => {
		const now = moment();
		const filteredProjects = compose(
			(projects: Array<Project>) =>
				hasBooleanKeys(relevanceFilter)
					? projects.filter(x => detectIsProjectPassesRelevanceFilter(x, relevanceFilter, now))
					: projects,
			(projects: Array<Project>) => {
				const fundsRegisterIDs = extractKeysToArray(fundsRegistersFilter.value, Number);

				if (!hasBooleanKeys(fundsRegisterIDs)) {
					return projects;
				}

				const filteredOperations = operations.filter(operation =>
					fundsRegisterIDs.some(id => operation.FundsRegister?.ID === id),
				);

				return projects.filter(x => filteredOperations.some(operation => operation.Project?.ID === x.ID));
			},
			(projects: Array<Project>) =>
				hasBooleanKeys(managersFilter) ? projects.filter(x => managersFilter[x.ManagerID]) : projects,
			(projects: Array<Project>) =>
				hasBooleanKeys(clientsFilter) ? projects.filter(x => clientsFilter[x.ClientID]) : projects,
			(projects: Array<Project>) =>
				hasBooleanKeys(tenantEntitiesFilter)
					? projects.filter(x => tenantEntitiesFilter[x.TenantLegalEntityID])
					: projects,
			(projects: Array<Project>) =>
				hasBooleanKeys(businessUnitsFilter)
					? projects.filter(x => businessUnitsFilter[x.BusinessUnit?.ID || -1])
					: projects,
			(projects: Array<Project>) =>
				searchText ? projects.filter(x => x.Name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1) : projects,
		)(projects);

		return filteredProjects;
	},
);

const selectProjectStatisticsMap = createSelector(
	selectAllProjectDynamicSeriesMap,
	selectFilteredProjects,
	selectIsIncludesPlan,
	(dynamicSeries, projects, isIncludesPlan) => {
		const createPointFilter = (isIncome: boolean) => (point: PLOperationTimeSeriesPoint) =>
			isIncome === point.DirectionIncoming && (isIncludesPlan || !point.Plan);
		const data: Record<string, Record<ProjectStatisticsRatiosCode, number>> = projects.reduce((acc, project) => {
			const points = dynamicSeries[project.ID]?.Points || [];
			const income = safeNumber(points.filter(createPointFilter(true)).reduce((acc, pl) => acc + pl.Amount, 0));
			const expense = safeNumber(points.filter(createPointFilter(false)).reduce((acc, pl) => acc + pl.Amount, 0));
			const result = getResult(income, expense);
			const profitability = getProfitability(income, expense);

			acc[project.ID] = {
				[ProjectStatisticsRatiosCode.INCOME]: income,
				[ProjectStatisticsRatiosCode.EXPENSE]: expense,
				[ProjectStatisticsRatiosCode.RESULT]: result,
				[ProjectStatisticsRatiosCode.PROFITABILITY]: profitability,
			};

			return acc;
		}, {});

		return data;
	},
);
const selectProjectsSorting = (state: IAppState) => {
	return state.projectManagement.main.sorting;
};

const selectFilteredSortedProjectsWithStatistics = createSelector(
	selectFilteredProjects,
	selectProjectStatisticsMap,
	selectProjectsSorting,
	selectVirtualListGroup,
	(filteredProjects, statisticData, sort, group) => {
		const filteredProjectsWithStatistic: Array<ProjectWithStatistics> = filteredProjects.map(project => ({
			...project,
			statistics: statisticData[project.ID],
		}));

		const filteredSortedProjectsWithStatistics = sortWithGroup(filteredProjectsWithStatistic, sort, group);

		return filteredSortedProjectsWithStatistics;
	},
);

const selectProjectStatistics = createSelector(
	selectAllProjectDynamicSeriesMap,
	selectFilteredProjects,
	(dynamicSeries, projects) => {
		const now = moment();
		const createFilter = (isExpense: boolean, isForecast: boolean) => (point: PLOperationTimeSeriesPoint) => {
			const isOverdue = isForecast ? moment(point.ValueDate, BASE_DATE_FORMAT).isBefore(now, 'day') : false;

			return isExpense === !point.DirectionIncoming && (!point.Plan || (isForecast ? point.Plan && !isOverdue : false));
		};
		const incomeFact = projects.reduce((acc, project) => {
			const points = dynamicSeries[project.ID]?.Points || [];
			const total = points.filter(createFilter(false, false)).reduce((acc, pl) => acc + pl.Amount, 0);

			return (acc += total);
		}, 0);
		const incomeForecast = projects.reduce((acc, project) => {
			const points = dynamicSeries[project.ID]?.Points || [];
			const total = points.filter(createFilter(false, true)).reduce((acc, pl) => acc + pl.Amount, 0);

			return (acc += total);
		}, 0);
		const expenseFact = projects.reduce((acc, project) => {
			const points = dynamicSeries[project.ID]?.Points || [];
			const total = points.filter(createFilter(true, false)).reduce((acc, pl) => acc + pl.Amount, 0);

			return (acc += total);
		}, 0);
		const expenseForecast = projects.reduce((acc, project) => {
			const points = dynamicSeries[project.ID]?.Points || [];
			const total = points.filter(createFilter(true, true)).reduce((acc, pl) => acc + pl.Amount, 0);

			return (acc += total);
		}, 0);
		const resultFact = incomeFact - expenseFact;
		const resultForecast = incomeForecast - expenseForecast;
		const data = {
			fact: {
				[ProjectStatisticsSliceCode.INCOME]: incomeFact,
				[ProjectStatisticsSliceCode.EXPENSE]: expenseFact,
				[ProjectStatisticsSliceCode.RESULT]: resultFact,
			},
			forecast: {
				[ProjectStatisticsSliceCode.INCOME]: incomeForecast,
				[ProjectStatisticsSliceCode.EXPENSE]: expenseForecast,
				[ProjectStatisticsSliceCode.RESULT]: resultForecast,
			},
		};

		return data;
	},
);

const selectDetailsProjectStatisticsMap = createSelector(
	(state: IAppState) => selectDetailProjectDynamicSeriesMap(state),
	(_, projectID: number) => projectID,
	(dynamicSeriesMap, projectID) => {
		const now = moment();
		const createFilter = (isExpense: boolean, isForecast: boolean) => (point: PLOperationTimeSeriesPoint) => {
			const isOverdue = isForecast ? moment(point.ValueDate, BASE_DATE_FORMAT).isBefore(now, 'day') : false;

			return isExpense === !point.DirectionIncoming && (!point.Plan || (isForecast ? point.Plan && !isOverdue : false));
		};
		const points = dynamicSeriesMap[projectID]?.Points || [];
		const incomeFact = safeNumber(points.filter(createFilter(false, false)).reduce((acc, pl) => acc + pl.Amount, 0));
		const incomeForecast = safeNumber(points.filter(createFilter(false, true)).reduce((acc, pl) => acc + pl.Amount, 0));
		const expenseFact = safeNumber(points.filter(createFilter(true, false)).reduce((acc, pl) => acc + pl.Amount, 0));
		const expenseForecast = safeNumber(points.filter(createFilter(true, true)).reduce((acc, pl) => acc + pl.Amount, 0));
		const resultFact = safeNumber(incomeFact - expenseFact);
		const resultForecast = safeNumber(incomeForecast - expenseForecast);
		const data = {
			[ProjectStatisticsSliceCode.INCOME]: {
				fact: incomeFact,
				forecast: incomeForecast,
			},
			[ProjectStatisticsSliceCode.EXPENSE]: {
				fact: expenseFact,
				forecast: expenseForecast,
			},
			[ProjectStatisticsSliceCode.RESULT]: {
				fact: resultFact,
				forecast: resultForecast,
			},
		};

		return data;
	},
);

function selectIsProjectDataFetching(state: IAppState) {
	return selectAsyncProjects.selectIsFetching(state) || selectAsyncAllProjectDynamicSeries.selectIsFetching(state);
}

function selectIsProjectDataLoaded(state: IAppState) {
	return selectAsyncProjects.selectIsLoaded(state) && selectAsyncAllProjectDynamicSeries.selectIsLoaded(state);
}

function selectFilterByText(state: IAppState) {
	return state.projectManagement.main.textFilter;
}

function selectFilterByTenantEntities(state: IAppState) {
	return state.projectManagement.main.tenantEntitiesFilter;
}

function selectFilterByBusinessUnits(state: IAppState) {
	return state.projectManagement.main.businessUnitsFilter;
}

function selectFilterByClients(state: IAppState) {
	return state.projectManagement.main.clientsFilter;
}

function selectFilterByManagers(state: IAppState) {
	return state.projectManagement.main.managersFilter;
}

function selectFilterByRelevance(state: IAppState) {
	return state.projectManagement.main.relevanceFilter;
}

function selectFundsRegistersFilter(state: IAppState) {
	return state.projectManagement.main.fundsRegistersFilter;
}

function selectProjectView(state: IAppState) {
	return state.projectManagement.main.projectView;
}

function selectVirtualListGroup(state: IAppState) {
	return state.projectManagement.main.virtualListGroup;
}

function selectDateRange(state: IAppState) {
	return state.projectManagement.main.dateRange;
}

function selectIsIncludesPlan(state: IAppState) {
	return state.projectManagement.main.isIncludesPlan;
}

function selectOperationDateType(state: IAppState) {
	return state.projectManagement.main.operationDateType;
}

function selectOperationsDirectionFilter(state: IAppState) {
	return state.projectManagement.main.operationsDirectionFilter;
}

function selectProjectsDateRange(state: IAppState) {
	return state.projectManagement.main.projectsDateRange;
}

function selectProjectsDateType(state: IAppState) {
	return state.projectManagement.main.projectsDateType;
}

const selectFilteredProjectOperations = createSelector(
	selectAsyncProjectOperations.selectItem,
	selectOperationDateType,
	selectOperationsDirectionFilter,
	(sourceOperations, dateType, directionFilter) => {
		const operations = compose(
			(operations: Array<PLOperationBrief>) =>
				sortDescBy(operations, [
					{
						fn: item => (dateType === OperationDateTypeCode.CASHFLOW ? item.CashflowDate : item.AccrualDate),
						isDate: true,
					},
					{ fn: item => item.ID },
				]),
			(operations: Array<PLOperationBrief>) =>
				operations.filter(x => {
					return directionFilter === ProjectStatisticsSliceCode.INCOME
						? x.Expense === false
						: directionFilter === ProjectStatisticsSliceCode.EXPENSE
						? x.Expense === true
						: true;
				}),
		)(sourceOperations);

		return operations;
	},
);

const selectProjectDataSourceTreeNodes = createSelector(
	(_, excludeID: number) => excludeID,
	selectAsyncProjects.selectItem,
	(excludeID, projects) => {
		const root = createDataSourceTree<Project, CodeNaturalKey>({
			items: projects,
			filter: x => x.ID !== excludeID,
			getID: x => x.ID,
			getParentID: x => x?.ParentProject?.ID || -1,
			getName: x => x.Name,
			getChildItems: x => x.ChildItems,
		});
		const dataSource = root.ChildItems.reduce((acc, x) => ((acc[x.ID] = x), acc), {}) as Record<
			string,
			DataSourceTreeNode<Project>
		>;

		return dataSource;
	},
);

const selectProjectDataSourceTreeNodesWithEmptyItem = createSelector(
	selectProjectDataSourceTreeNodes,
	initialDataSource => {
		const dataSource: Record<string, DataSourceTreeNode<Project>> = {
			...initialDataSource,
			'-1': new DataSourceTreeNode({
				ID: -1,
				Name: 'Проект не указан',
			}),
		};

		return dataSource;
	},
);

const selectProjectsHierarchy = createSelector(
	selectAsyncProjects.selectItem,
	selectAllProjectDynamicSeriesMap,
	selectIsIncludesPlan,
	selectFilterByText,
	selectFilterByTenantEntities,
	selectFilterByBusinessUnits,
	selectFilterByClients,
	selectFilterByManagers,
	selectFilterByRelevance,
	selectFundsRegistersFilter,
	selectAsyncProjectOperations.selectItem,
	(
		projects,
		dynamicSeriesMap,
		isIncludesPlan,
		searchText,
		tenantEntitiesFilter,
		businessUnitsFilter,
		clientsFilter,
		managersFilter,
		relevanceFilter,
		fundsRegistersFilter,
		operations,
	) => {
		const now = moment();
		const getID = (x: Project) => x.ID;
		const getChildItems = (x: Project) => x.ChildItems;
		const hasManagersFilter = Object.keys(managersFilter).length > 0;
		const hasClientsFilter = Object.keys(clientsFilter).length > 0;
		const hasTenantEntitiesFilter = Object.keys(tenantEntitiesFilter).length > 0;
		const hasBusinessUnitsFilter = Object.keys(businessUnitsFilter).length > 0;
		const hasRelevanceFilter = hasBooleanKeys(relevanceFilter);
		const fundsRegisterIDs = extractKeysToArray(fundsRegistersFilter.value, Number);
		const hasFundsRegistersFilter = hasBooleanKeys(fundsRegisterIDs);
		const hasAnyFilter =
			Boolean(searchText) ||
			hasManagersFilter ||
			hasClientsFilter ||
			hasTenantEntitiesFilter ||
			hasBusinessUnitsFilter ||
			hasRelevanceFilter ||
			hasFundsRegistersFilter;

		const root = createDataSourceTree<Project, CodeNaturalKey>({
			items: projects,
			getID,
			getChildItems,
			getParentID: x => x?.ParentProject?.ID || -1,
			getName: x => x.Name,
			filter: x =>
				hasAnyFilter
					? deepFilter<Project, CodeNaturalKey>({
							item: x,
							items: projects,
							getID,
							getChildItems,
							filter: project => {
								const isPassed = compose(
									(isPassed: boolean) =>
										isPassed && hasRelevanceFilter
											? detectIsProjectPassesRelevanceFilter(project, relevanceFilter, now)
											: isPassed,
									(isPassed: boolean) => {
										if (!isPassed || !hasFundsRegistersFilter) {
											return isPassed;
										}

										const filteredOperations = operations.filter(operation =>
											fundsRegisterIDs.some(id => operation.FundsRegister?.ID === id),
										);

										return filteredOperations.some(operation => operation.Project?.ID === project.ID);
									},
									(isPassed: boolean) =>
										isPassed && hasManagersFilter ? Boolean(managersFilter[project.Manager?.ID || -1]) : isPassed,
									(isPassed: boolean) =>
										isPassed && hasClientsFilter ? Boolean(clientsFilter[project.Client?.ID || -1]) : isPassed,
									(isPassed: boolean) =>
										isPassed && hasTenantEntitiesFilter
											? Boolean(tenantEntitiesFilter[project.TenantLegalEntity?.ID || -1])
											: isPassed,
									(isPassed: boolean) =>
										isPassed && hasBusinessUnitsFilter
											? Boolean(businessUnitsFilter[project.BusinessUnit?.ID])
											: isPassed,
									(isPassed: boolean) =>
										searchText ? project.Name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1 : isPassed,
								)(true);

								return Boolean(isPassed);
							},
					  })
					: true,
		});
		const sourceRows: Array<HierarchyTableRow> = root.ChildItems.map(item => {
			const columns: Array<HierarchyTableColumn> = [
				{ code: ProjectStatisticsRatiosCode.INCOME, values: [0] },
				{ code: ProjectStatisticsRatiosCode.EXPENSE, values: [0] },
				{ code: ProjectStatisticsRatiosCode.RESULT, values: [0] },
				{ code: ProjectStatisticsRatiosCode.PROFITABILITY, values: [0] },
			];

			return {
				ID: item.ID,
				name: item.Name,
				parentID: item.ParentID,
				childItemIDs: item.ChildItems.map(x => x.ID),
				value: item.value,
				columns,
			} as HierarchyTableRow;
		});
		const rows = createSortedFlattenTree<HierarchyTableRow>({
			items: [...sourceRows],
			getID: x => x.ID,
			getParentID: x => x.parentID,
			getChildItemIDs: x => x.childItemIDs,
			sortFn: (a, b) => b.ID - a.ID,
		});
		const createPointFilter = (isIncome: boolean) => (point: PLOperationTimeSeriesPoint) =>
			isIncome === point.DirectionIncoming && (isIncludesPlan || !point.Plan);
		const getFlattenNodeID = (x: HierarchyTableRow) => x.ID;
		const getFlattenChildItemIDs = (x: HierarchyTableRow) => x.childItemIDs;
		const createDeepFn = (isIncome: boolean) => (x: HierarchyTableRow) => {
			const total = getSum<PLOperationTimeSeriesPoint>(
				(dynamicSeriesMap[x.ID]?.Points || []).filter(createPointFilter(isIncome)),
				x => x.Amount,
			);

			return total;
		};

		for (const row of rows) {
			const rowID = row.ID;
			const columnsMap = createObjectMap(row.columns, x => x.code);
			const income = executeDeepFn<HierarchyTableRow, number, number>({
				itemID: rowID,
				items: rows,
				getID: getFlattenNodeID,
				getChildItemIDs: getFlattenChildItemIDs,
				transformResult: getSum,
				fn: createDeepFn(true),
			});
			const expense = executeDeepFn<HierarchyTableRow, number, number>({
				itemID: rowID,
				items: rows,
				getID: getFlattenNodeID,
				getChildItemIDs: getFlattenChildItemIDs,
				transformResult: getSum,
				fn: createDeepFn(false),
			});
			const result = getResult(income, expense);
			const profitability = getProfitability(income, expense);

			columnsMap[ProjectStatisticsRatiosCode.INCOME].values[0] = income;
			columnsMap[ProjectStatisticsRatiosCode.EXPENSE].values[0] = expense;
			columnsMap[ProjectStatisticsRatiosCode.RESULT].values[0] = result;
			columnsMap[ProjectStatisticsRatiosCode.PROFITABILITY].values[0] = profitability;
		}

		const data = {
			header: ['Проект', 'Доход', 'Расход', 'Результат', 'Рентабельность'],
			rows,
		};

		return data;
	},
);

function selectExpandedHierarchyMap(state: IAppState) {
	return state.projectManagement.main.expandedHierarchyMap;
}

const selectHasAnyFilter = createSelector(
	selectFilterByText,
	selectFilterByTenantEntities,
	selectFilterByBusinessUnits,
	selectFilterByClients,
	selectFilterByManagers,
	selectFilterByRelevance,
	selectFundsRegistersFilter,
	(
		searchText,
		tenantEntitiesFilter,
		businessUnitsFilter,
		clientsFilter,
		managersFilter,
		relevanceFilter,
		fundsRegistersFilter,
	) => {
		const hasManagersFilter = hasBooleanKeys(managersFilter);
		const hasClientsFilter = hasBooleanKeys(clientsFilter);
		const hasTenantEntitiesFilter = hasBooleanKeys(tenantEntitiesFilter);
		const hasBusinessUnitsFilter = hasBooleanKeys(businessUnitsFilter);
		const hasRelevanceFilter = hasBooleanKeys(relevanceFilter);
		const fundsRegisterIDs = extractKeysToArray(fundsRegistersFilter.value, Number);
		const hasFundsRegistersFilter = hasBooleanKeys(fundsRegisterIDs);
		const hasAnyFilter =
			Boolean(searchText) ||
			hasManagersFilter ||
			hasClientsFilter ||
			hasTenantEntitiesFilter ||
			hasBusinessUnitsFilter ||
			hasRelevanceFilter ||
			hasFundsRegistersFilter;

		return hasAnyFilter;
	},
);

const selectCorrectedExpandedHierarhyMap = createSelector(
	selectExpandedHierarchyMap,
	selectProjectsHierarchy,
	selectHasAnyFilter,
	(expandedRowsMap, hierarchy, hasAnyFilter) => {
		const { rows } = hierarchy;
		let initialMap = { ...expandedRowsMap };

		if (hasAnyFilter) {
			for (const row of rows) {
				const map = crateExpandedMap<HierarchyTableRow>({
					isExpanded: true,
					itemID: row.ID,
					items: rows,
					getID: x => x.ID,
					getChildItemIDs: x => x.childItemIDs,
				});

				initialMap = {
					...initialMap,
					...map,
					...expandedRowsMap,
				};
			}
		}

		return initialMap;
	},
);

const selectProjectsGrouppedByManagerID = createSelector(selectAsyncProjects.selectItem, projects => {
	const grouppedMap = groupBy(projects, x => x.ManagerID);

	return grouppedMap;
});

const selectOnlyParentProjects = createSelector(selectAsyncProjects.selectItem, sourceProjects => {
	const projects = sourceProjects.filter(x => x?.ChildItems?.length > 0);

	return projects;
});

const selectIsAllHierarchyProjectItemsExpanded = createSelector(
	selectOnlyParentProjects,
	selectExpandedHierarchyMap,
	(projects, hierarchyMap) => {
		const isAllExpanded = extractKeysToArray(hierarchyMap).filter(key => hierarchyMap[key]).length === projects.length;

		return isAllExpanded;
	},
);

export const mainProjectManagementSelectorsPack = {
	selectProjectsRight,
	selectProjectsAccessRight,
	selectAsyncProjects,
	selectAsyncAllProjectDynamicSeries,
	selectAsyncDetailProjectDynamicSeries,
	selectAsyncProjectOperations,
	selectProjectsMap,
	selectExtendedProjectsMap,
	selectProjectsByCodeMap,
	selectAllProjectDynamicSeriesMap,
	selectDetailProjectDynamicSeriesMap,
	selectCalculatedDateRange,
	selectProjectStatistics,
	selectProjectStatisticsMap,
	selectDetailsProjectStatisticsMap,
	selectFilteredProjects,
	selectIsProjectDataFetching,
	selectIsProjectDataLoaded,
	selectFilterByText,
	selectFilterByTenantEntities,
	selectFilterByBusinessUnits,
	selectFilterByClients,
	selectFilterByManagers,
	selectFilterByRelevance,
	selectFundsRegistersFilter,
	selectProjectView,
	selectVirtualListGroup,
	selectDateRange,
	selectOperationDateType,
	selectOperationsDirectionFilter,
	selectFilteredProjectOperations,
	selectProjectDataSourceTreeNodes,
	selectProjectDataSourceTreeNodesWithEmptyItem,
	selectProjectsHierarchy,
	selectExpandedHierarchyMap,
	selectCorrectedExpandedHierarhyMap,
	selectProjectsGrouppedByManagerID,
	selectHasAnyFilter,
	selectOnlyParentProjects,
	selectIsAllHierarchyProjectItemsExpanded,
	selectProjectsDateRange,
	selectProjectsDateType,
	selectIsIncludesPlan,
	selectFilteredSortedProjectsWithStatistics,
	selectProjectsSorting,
};

export { selectProjectsRight, selectProjectsAccessRight, selectAsyncProjects };
