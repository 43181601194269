export enum ButtonAppearance {
	contained = 'contained',
	text = 'text',
	outlined = 'outlined',
}

export enum ButtonColor {
	primary = 'primary',
	white = 'white',
	black = 'black',
}

export enum ButtonSize {
	micro = 'micro',
	small = 'small',
	medium = 'medium',
	large = 'large',
}
