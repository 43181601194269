const accountResolver = (item: FundsRegisterStatistics) => {
	const bankAccount = item.FundsRegister as BankAccountFundsRegister;
	const accountId = item.FundsRegister.ID;
	const accountName = item.FundsRegister.Name;
	const accountNumber = item.FundsRegister.RegisterNumber;
	const accountCurrency = 'NA';
	const balance = item.OutgoingBalance;
	const isLimitedAccess = item.FundsRegister.LimitedAccess;
	let bankName = null;
	let connectedToCMS = false;
	let accountType;

	if (bankAccount.Bank && bankAccount.Bank.Name) {
		bankName = bankAccount.Bank.Name;
	}

	if (item && item.FundsRegister && item.FundsRegister.CashManagementSystem) {
		connectedToCMS = true;
	}

	item.FundsRegister.CLASSIFIER === 'BANK_ACCOUNT_FUNDS_REGISTER' ? (accountType = 1) : (accountType = 2);

	return {
		accountId,
		accountName,
		accountNumber,
		accountCurrency,
		accountType,
		balance,
		bankName,
		connectedToCMS,
		isLimitedAccess,
	};
};

export { accountResolver };
