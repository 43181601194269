import React from 'react';
import styled, { css } from 'styled-components';

import { Box, BoxProps } from '@ui/box';
import { RaisedButtonProps } from './raised-button';
import { ButtonAppearance, ButtonColor, ButtonSize } from './model';
import { WithThemeProps } from '@theme';

type ButtonProps = {
	onSetRef: (ref: HTMLElement) => void;
} & Omit<RaisedButtonProps, 'type'> &
	WithThemeProps;

type ButtonContentProps = {} & Pick<RaisedButtonProps, 'size'>;

type ButtonBaseProps = {} & Pick<ButtonProps, 'onSetRef'> &
	React.ButtonHTMLAttributes<HTMLButtonElement> &
	Pick<BoxProps, 'component'>;

const ButtonBase = ({ onSetRef, ...rest }: ButtonBaseProps) => <Box onSetRef={onSetRef} {...rest} />;

const createStyles = (props: ButtonProps) => {
	const sizeMap = {
		[ButtonSize.micro]: () => css`
			text-transform: none;
			font-weight: 400;
			font-size: 14px;
			height: 18px;

			& .btn-content {
				height: 18px;
				padding: 4px 6px;
			}
		`,
		[ButtonSize.small]: () => css`
			text-transform: none;
			font-weight: 400;
			font-size: 14px;

			& .btn-content {
				height: 26px;
				padding: 4px 6px;
			}
		`,
		[ButtonSize.medium]: () => css`
			& .btn-content {
				height: 35px;
			}
		`,
		[ButtonSize.large]: () => css`
			font-size: 14px;
			height: 50px;
			border-radius: 0px;

			& .btn-content {
				height: 50px;
				padding: 8px 20px;
			}
		`,
	};

	const size = () => sizeMap[props.size]();

	const appearanceMap = {
		[ButtonAppearance.contained]: () => {
			const colorMap = {
				[ButtonColor.primary]: () => css`
					color: ${props.theme.button.contained.accent.textColor};
					background-color: ${props.theme.button.contained.accent.backgroundColor};

					&:hover {
						background-color: ${props.theme.button.contained.accent.backgroundColorHover};
					}

					&[disabled] {
						background-color: ${props.theme.button.contained.accent.backgroundColorDisabled};
						color: ${props.theme.button.contained.accent.textColorDisabled};
					}

					&[disabled] i {
						color: ${props.theme.button.contained.accent.textColorDisabled};
					}
				`,
				[ButtonColor.white]: () => css`
					color: ${props.theme.button.contained.light.textColor};
					background-color: ${props.theme.button.contained.light.backgroundColor};

					&:hover {
						background-color: ${props.theme.button.contained.light.backgroundColorHover};
					}

					&[disabled] {
						background-color: ${props.theme.button.contained.light.backgroundColorDisabled};
						color: ${props.theme.button.contained.light.textColorDisabled};
					}

					&[disabled] i {
						color: ${props.theme.button.contained.light.textColorDisabled};
					}
				`,
			};

			return css`
				${colorMap[props.color]()}
				${size()}
			`;
		},
		[ButtonAppearance.outlined]: () => {
			const colorMap = {
				[ButtonColor.primary]: () => css`
					color: ${props.theme.button.outlined.accent.textColor};
					border-color: ${props.theme.button.outlined.accent.textColor};

					&:hover {
						background-color: ${props.theme.button.outlined.accent.backgroundColorHover};
						box-shadow: none;
					}

					&[disabled] {
						background-color: ${props.theme.button.outlined.accent.backgroundColorDisabled};
						border-color: ${props.theme.button.outlined.accent.backgroundColorDisabled};
						color: ${props.theme.button.outlined.accent.textColorDisabled};
					}

					&[disabled] i {
						color: ${props.theme.button.outlined.accent.textColorDisabled};
					}
				`,
				[ButtonColor.black]: () => css`
					color: ${props.theme.button.outlined.dark.textColor};
					border-color: ${props.theme.button.outlined.dark.textColor};

					&:hover {
						background-color: ${props.theme.button.outlined.dark.backgroundColorHover};
						box-shadow: none;
					}

					&[disabled] {
						background-color: ${props.theme.button.outlined.dark.backgroundColorDisabled};
						border-color: ${props.theme.button.outlined.dark.backgroundColorDisabled};
						color: ${props.theme.button.outlined.dark.textColorDisabled};
					}

					&[disabled] i {
						color: ${props.theme.button.outlined.dark.textColorDisabled};
					}
				`,
			};

			return css`
				background-color: transparent;
				border: 1px solid transparent;
				box-shadow: none;

				&:hover {
					box-shadow: none;
				}

				${colorMap[props.color]()}
				${size()}
			`;
		},
		[ButtonAppearance.text]: () => {
			const colorMap = {
				[ButtonColor.primary]: () => css`
					color: ${props.theme.button.text.accent.textColor};
					background-color: transparent;

					&:hover {
						background-color: ${props.theme.button.text.accent.backgroundColorHover};
					}

					&:focus {
						background-color: ${props.theme.button.text.accent.backgroundColorFocus};
					}

					&[disabled] {
						background-color: ${props.theme.button.text.accent.backgroundColorDisabled};
						color: ${props.theme.button.text.accent.textColorDisabled};
					}

					&[disabled] i {
						color: ${props.theme.button.text.accent.textColorDisabled};
					}
				`,
				[ButtonColor.white]: () => css`
					color: ${props.theme.button.text.muted.textColor};
					background-color: transparent;

					&:hover {
						background-color: ${props.theme.button.text.muted.backgroundColorHover};
					}

					&:focus {
						background-color: ${props.theme.button.text.muted.backgroundColorFocus};
					}

					&[disabled] {
						background-color: ${props.theme.button.text.muted.backgroundColorDisabled};
						color: ${props.theme.button.text.muted.textColorDisabled};
					}

					&[disabled] i {
						color: ${props.theme.button.text.muted.textColorDisabled};
					}
				`,
				[ButtonColor.black]: () => css`
					color: ${props.theme.button.text.dark.textColor};

					&:hover {
						color: ${props.theme.palette.accent};
					}

					&:focus {
						background-color: ${props.theme.button.text.dark.backgroundColorFocus};
					}

					&[disabled] {
						background-color: ${props.theme.button.text.dark.backgroundColorDisabled};
						color: ${props.theme.button.text.dark.textColorDisabled};
					}

					&[disabled] i {
						color: ${props.theme.button.text.dark.textColorDisabled};
					}
				`,
			};

			return css`
				box-shadow: none;

				&:hover,
				&:focus {
					box-shadow: none;
				}

				${colorMap[props.color]()}
				${size()}
			`;
		},
	};

	return appearanceMap[props.appearance]();
};

const Button = styled(ButtonBase)<ButtonProps>`
	position: relative;
	z-index: 1;
	display: inline-block;
	max-width: 100%;
	font-size: 13px;
	text-transform: uppercase;
	font-weight: 500;
	background-color: transparent;
	transition: background-color 250ms ease-in-out, border-color 250ms ease-in-out, color 250ms ease-in-out,
		box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	user-select: none;
	cursor: pointer;

	${p => css`
		${p.theme.fn.createBoxShadow(1)}

		&:active,
		&:focus {
			box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%),
				0px 3px 14px 2px rgb(0 0 0 / 12%);
		}
	`}

	&[disabled],
	&[disabled] * {
		pointer-events: none;
	}

	${p =>
		p.fullWidth &&
		css`
			width: 100%;
		`}

	& > div {
		width: 100%;
	}

	${p => css`
		border-radius: ${p.theme.button.borderRadius};
	`}

	${p => createStyles(p)}
`;

const ButtonContent = styled.span.attrs(() => ({
	className: 'btn-content',
}))`
	position: relative;
	width: 100%;
	padding: 8px 16px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	white-space: nowrap;
	line-height: initial; // important for vertical align text

	& i ~ *,
	& * ~ i {
		margin-left: 10px;
	}

	${(p: ButtonContentProps) =>
		p.size === 'small' &&
		css`
			& i ~ *,
			& * ~ i {
				margin-left: 4px;
			}
		`}
`;

export { Button, ButtonContent };
