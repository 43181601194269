import { FetchOperationsOptions } from '@core/api/pl';
import {
	StoreAsyncItem,
	checkAsyncAction,
	createAsyncInitialState,
	createReducer,
	invalidateStateFromAction,
	restoreValidationState,
} from '@flux';
import { analysisActionsPack, mainReportingActionsPack } from '../actions';

export interface IAnalysisState {
	income: boolean;
	segmentName: string;
	counterpartyID: number;
	detalizationOptions: FetchOperationsOptions;
	plList: StoreAsyncItem<Array<PLOperationBrief>>;
	segments: StoreAsyncItem<Array<ABCAnalysisSegment>>;
}

const initialState: IAnalysisState = {
	income: true,
	segmentName: '',
	counterpartyID: -2,
	detalizationOptions: null,
	plList: createAsyncInitialState([]),
	segments: createAsyncInitialState([]),
};

const analysisReducer = createReducer<IAnalysisState>(initialState, {
	[analysisActionsPack.types.INVALIDATE_PL_OPERATIONS]: (action: StaticAction, state) => {
		return {
			plList: invalidateStateFromAction(action, state.plList),
			segments: invalidateStateFromAction(action, state.segments),
		};
	},
	[analysisActionsPack.types.RESTORE_DATA_VALIDATION]: (action: StaticAction, state) => {
		return {
			plList: restoreValidationState(state.plList),
		};
	},
	[analysisActionsPack.types.SET_DIRECTION]: (action: StaticAction<boolean>) => {
		return {
			income: action.value,
			counterpartyID: -2,
			segmentName: '',
		};
	},
	[mainReportingActionsPack.types.SET_LEGAL_ENTITY_FILTER]: () => {
		return {
			counterpartyID: -2,
			segmentName: '',
		};
	},
	[mainReportingActionsPack.types.SET_DATE_RANGE]: () => {
		return {
			counterpartyID: -2,
			segmentName: '',
		};
	},
	[analysisActionsPack.types.SET_SEGMENT_NAME]: (action: StaticAction<string>, state) => {
		return {
			segmentName: action.value,
			counterpartyID: -2,
			plList: {
				...state.plList,
				item: [],
			},
		};
	},
	[analysisActionsPack.types.SET_COUNTERPARTY_ID]: (action: StaticAction<number>) => {
		return {
			counterpartyID: action.value,
		};
	},
	[analysisActionsPack.types.SET_DETALIZATION_OPTIONS]: (action: StaticAction<FetchOperationsOptions>, state) => {
		return {
			detalizationOptions: action.value,
			plList: invalidateStateFromAction(action, state.plList),
		};
	},
	[analysisActionsPack.types.FETCH_ABC_SEGMENTS]: (action: AsyncAction<Array<ABCAnalysisSegment>>, state) => {
		return {
			segments: checkAsyncAction(action, state.segments),
		};
	},
	[analysisActionsPack.types.FETCH_PL_OPERATIONS]: (action: AsyncAction<Array<PLOperationBrief>>, state) => {
		return {
			plList: checkAsyncAction(action, state.plList),
		};
	},
});

export default analysisReducer;
