import React, { memo, useMemo } from 'react';

import { DataSourceItem } from '@core/api/core';
import { withFormAutopicker } from '@ui/autopicker';
import {
	CashflowTypeAutopicker as XCashflowTypeAutopicker,
	CashflowTypeAutopickerProps as XCashflowTypeAutopickerProps,
} from './cashflow-type-autopicker.view';

export type CashflowTypeAutopickerProps = {} & Omit<
	XCashflowTypeAutopickerProps,
	'dataSource' | 'isFetching' | 'isLoaded'
>;

const CashflowTypeAutopicker: React.FC<CashflowTypeAutopickerProps> = memo(props => {
	const dataSource = useMemo(
		() => [
			new DataSourceItem({ value: 1, text: 'Операционный' }),
			new DataSourceItem({ value: 2, text: 'Финансовый' }),
			new DataSourceItem({ value: 3, text: 'Инвестиционный' }),
		],
		[],
	);

	return <XCashflowTypeAutopicker {...props} isLoaded dataSource={dataSource} />;
});

const FormCashflowTypeAutopicker = withFormAutopicker<CashflowTypeAutopickerProps, unknown>(CashflowTypeAutopicker);

const cashflowTypeAutopickerTransformer = {
	number: {
		single: {
			transformInput: ({ input }: { input: number }) => (input ? { [input]: true } : null),
			transformOutput: ({ items }) => (items[0] ? (items[0] as DataSourceItem<number>).value : null),
		},
	},
};

export { CashflowTypeAutopicker, FormCashflowTypeAutopicker, cashflowTypeAutopickerTransformer };
