import styled, { css } from 'styled-components';

import { StepperItemProps } from './stepper-viewer';
import { pxToRem } from '@stylesheet';
import { StepperProps } from './stepper-viewer';

type RootProps = {} & Pick<StepperProps, 'appearance'>;

type ItemLayoutProps = {
	isAfter: boolean;
	isActive: boolean;
	isLast: boolean;
} & Pick<StepperProps, 'appearance'>;

type DividerProps = {} & Pick<StepperProps, 'appearance'>;

const Root = styled.div<RootProps>`
	position: relative;

	${p =>
		p.appearance === 'horizontal' &&
		css`
			display: flex;
			flex-flow: row wrap;
			align-items: center;
		`}
`;

const Label = styled.span.attrs(() => ({
	className: 'stepper-label',
}))`
	position: relative;
	display: inline-flex;
	padding: 2px;
	border-radius: 50%;
	font-size: ${pxToRem(12)};
	font-weight: 500;
	width: 20px;
	height: 20px;
	line-height: 1;
	justify-content: center;
	align-items: center;

	& ~ * {
		margin-left: 12px;
	}

	${p => css`
		color: ${p.theme.palette.textContrast};
		background-color: ${p.theme.palette.accent};
	`}
`;

const Divider = styled.div<DividerProps>`
	flex-grow: 1;

	${p =>
		p.appearance === 'vertical' &&
		css`
			width: 100%;
			border-left: 1px solid ${p.theme.palette.label};
			margin-left: 10px;
		`}

	${p =>
		p.appearance === 'horizontal' &&
		css`
			width: 20px;
			height: 1px;
			background-color: ${p.theme.palette.label};
			margin: 0 10px;
		`}
`;

const StepperItemRoot = styled.div<StepperItemProps>`
	width: auto;
	display: flex;
	align-items: center;
	padding: 8px 0;
	transition: filter 0.2s ease-in-out;

	${p =>
		p.appearance === 'clickable' &&
		css`
			&:hover {
				cursor: pointer;
			}
		`}

	${p =>
		p.disabled &&
		css`
			&:hover {
				cursor: default;
			}

			& * {
				filter: grayscale(1);
			}
		`}
`;

const ItemLayout = styled.div<ItemLayoutProps>`
	position: relative;
	width: 100%;
	min-height: 60px;
	display: inline-flex;
	flex-flow: column nowrap;

	${p =>
		p.appearance === 'horizontal' &&
		css`
			width: auto;
			min-height: 0;
			flex-flow: row nowrap;
			align-items: center;
		`}

	${p =>
		p.isLast &&
		css`
			min-height: 0;
		`}

	${p =>
		p.isAfter &&
		css`
			& > * {
				color: ${p.theme.palette.label};
			}

			& .stepper-label {
				background-color: ${p.theme.palette.stealth};
				color: ${p.theme.palette.label};
			}
		`}

	${p =>
		(p.isActive || p.isAfter) &&
		css`
			& > *:hover {
				cursor: default;
			}
		`}
`;

export { Root, Label, StepperItemRoot, Divider, ItemLayout };
