import { createTypes } from '@flux';

type ActionTypes = {
	EXPORT_TO_EXCEL: string;
	FETCH_RECONCILIATION_ACT: string;
	INVALIDATE_RECONCILIATION_ACT: string;
	SET_CLIENT_EMPLOYEE: string;
	SET_CLIENT: string;
	SET_COMPANY_EMPLOYEE: string;
	SET_COMPANY: string;
	SET_DATE_RANGE: string;
};

const types = createTypes<ActionTypes>(
	[
		'EXPORT_TO_EXCEL',
		'FETCH_RECONCILIATION_ACT',
		'INVALIDATE_RECONCILIATION_ACT',
		'SET_CLIENT_EMPLOYEE',
		'SET_CLIENT',
		'SET_COMPANY_EMPLOYEE',
		'SET_COMPANY',
		'SET_DATE_RANGE',
	],
	'RECONCILIATION_ACT',
);

export { types };
