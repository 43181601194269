import React from 'react';

import { getDrawer } from '@utils';
import { CloseIcon } from '@ui/icons/close';
import { Ripple } from '@ui/ripple';
import { Root, ButtonStyled, ButtonSign, IconButton, IconButtonContent } from './styled';

export type CloseButtonProps = {
	appearance?: 'white' | 'square';
	squareMode?: boolean;
	withDrawer?: boolean;
	onClick: () => void;
};

const handlers = [];

class CloseButton extends React.Component<CloseButtonProps> {
	componentDidMount() {
		const { withDrawer, onClick } = this.props;

		if (!withDrawer) {
			handlers.push(onClick);
			document.addEventListener('keydown', this.handleKeyDown);
		}
	}

	componentWillUnmount() {
		const { withDrawer } = this.props;

		if (!withDrawer) {
			handlers.pop();
			document.removeEventListener('keydown', this.handleKeyDown);
		}
	}

	handleKeyDown = e => {
		const { onClick } = this.props;

		if (e.keyCode === 27) {
			if (!getDrawer().isOpened()) {
				onClick === handlers[handlers.length - 1] && onClick();
			}
		}
	};

	render() {
		const { appearance, onClick } = this.props;
		const squareMode = this.props.squareMode || this.props.appearance === 'square';

		if (squareMode) {
			return (
				<Root>
					<ButtonStyled onClick={onClick}>
						<CloseIcon color='light' size={30} />
					</ButtonStyled>
					<ButtonSign>esc</ButtonSign>
				</Root>
			);
		}

		return (
			<IconButton appearance={appearance} onClick={onClick}>
				<Ripple color='rgba(0, 0, 0, 0.4)' during={200}>
					<IconButtonContent>
						<CloseIcon color={appearance === 'white' ? 'light' : 'black'} size={28} />
					</IconButtonContent>
				</Ripple>
			</IconButton>
		);
	}
}

export { CloseButton };
