import React, { useRef } from 'react';

import { useImmediateResize } from '@hooks/use-resize';
import withTopShift from '@core/hocs/with-top-shift.hoc';
import { Grid, GridColumn } from '../grid';
import { ViewLayoutRoot, ViewRoot, NestedRoutesView } from './styled';

export type ViewLayoutProps = {
	children: React.ReactNode;
};

const ViewLayout: React.FC<ViewLayoutProps> = props => {
	const rootRef = useRef<HTMLElement>(null);

	useImmediateResize(() => {
		if (!rootRef.current) return;
		const fixedView: HTMLElement = rootRef.current.querySelector('#fixed-view');
		if (!fixedView) return;
		fixedView.style.setProperty('width', fixedView.parentElement.clientWidth.toString() + 'px');
	}, [rootRef.current]);

	return (
		<ViewLayoutRoot ref={rootRef}>
			<Grid stretch>{props.children}</Grid>
		</ViewLayoutRoot>
	);
};

export type ViewProps = {
	withoutExtraView?: boolean;
	withoutPadding?: boolean;
	transparentMode?: boolean;
	autoHeight?: boolean;
	fullWidth?: boolean;
	style?: React.CSSProperties;
	topShift?: number;
	children: React.ReactNode;
};

const ViewBase: React.FC<ViewProps> = props => {
	const { withoutExtraView, fullWidth, topShift, ...rest } = props;

	return (
		<GridColumn desktop={withoutExtraView || fullWidth ? 12 : 9} laptop={12}>
			<ViewRoot withoutExtraView={withoutExtraView} fullWidth={fullWidth} topShift={topShift} {...rest}>
				{props.children}
			</ViewRoot>
		</GridColumn>
	);
};

const View = withTopShift<ViewProps>()(ViewBase);

export { ViewLayout, View, NestedRoutesView };
