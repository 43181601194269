import axios from 'axios';
import * as _ from 'underscore';

import * as accountingpackage from 'accountingpackage-api';
import * as billingpackage from 'billingpackage-api';
import * as configurationpackage from 'configurationpackage-api';
import * as corepackage from 'corepackage-api';
import * as counterpartypackage from 'counterpartypackage-api';
import * as documentpackage from 'documentpackage-api';
import * as fundspackage from 'fundspackage-api';
import * as invoicepackage from 'invoicepackage-api';
import * as notificationpackage from 'notificationpackage-api';
import * as organizationpackage from 'organizationpackage-api';
import * as paymentspackage from 'paymentspackage-api';
import * as plpackage from 'plpackage-api';
import * as processpackage from 'processpackage-api';
import * as profilepackage from 'profilepackage-api';
import * as projectpackage from 'projectpackage-api';
import * as referencepackage from 'referencepackage-api';
import * as reportspackage from 'reportspackage-api';
import * as securitypackage from 'securitypackage-api';
import * as servicepackage from 'servicepackage-api';
import * as taskpackage from 'taskpackage-api';
import { api as promiseBasedApi } from '../api';
import { pageContext } from '../libs/atr-core-lib';

function configureRESTAPITransport() {
	axios.interceptors.request.use(config => {
		config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
		config.withCredentials = true;

		return config;
	});
}

configureRESTAPITransport();

const api = {
	accountingpackage: accountingpackage,
	accountsChartServiceClient: new accountingpackage.AccountsChartServiceClient(),
	authenticationServiceClient: new securitypackage.AuthenticationServiceClient() as AuthenticationServiceClient,
	authorizationServiceClient: new securitypackage.AuthorizationServiceClient(),
	bankServiceClient: new referencepackage.BankServiceClient(),
	billingpackage: billingpackage,
	billingServiceClient: new billingpackage.BillingServiceClient(),
	businessHub: {
		getCompanyCardByTaxCode: (taxCode: string) => {
			return new Promise((resolve, reject) => {
				const ticketUID = pageContext.ticketuid;
				const apiEndpoint = `${location.origin}/snc-business-hub-services/api/v2/companies/${taxCode}/card`;
				const request = {
					method: 'get',
					headers: {
						ticketuid: ticketUID,
					},
					url: apiEndpoint,
				};

				axios(request)
					.then(response => resolve(response.data))
					.catch(reject);
			});
		},
	},
	businessServiceConfigurationServiceClient: new configurationpackage.BusinessServiceConfigurationServiceClient(),
	cashManagementSystemClient: new fundspackage.CashManagementSystemServiceClient(),
	configurationpackage: configurationpackage,
	corepackage: corepackage,
	counterpartypackage: counterpartypackage,
	counterpartyServiceClient: new counterpartypackage.CounterpartyServiceClient(),
	currencyServiceClient: new referencepackage.CurrencyServiceClient(),
	directoryServiceClient: new securitypackage.DirectoryServiceClient(),
	documentpackage: documentpackage,
	externalSystemServiceClient: new securitypackage.ExternalSystemServiceClient(),
	financialDocumentServiceClient: new invoicepackage.FinancialDocumentServiceClient(),
	financialDocumentTemplateServiceClient: new invoicepackage.FinancialDocumentTemplateServiceClient(),
	fundspackage: fundspackage,
	getSharedDocument: (sharedDocumentUID: string) => {
		return new Promise(resolve => {
			const apiEndpoint = `${getWebServicesRootPath()}/atr-document-services/api/documents/${sharedDocumentUID}`;

			axios({
				method: 'get',
				url: apiEndpoint,
			}).then(response => {
				const doc: FinancialDocument = response.data;

				resolve(doc);
			});
		});
	},
	industryServiceClient: new referencepackage.IndustryServiceClient(),
	invoicepackage: invoicepackage,
	notificationpackage: notificationpackage,
	organizationpackage: organizationpackage,
	paymentspackage: paymentspackage,
	paymentsServiceClient: new paymentspackage.PaymentsServiceClient(),
	plOperationServiceClient: new plpackage.PLOperationServiceClient(),
	plpackage: plpackage,
	processpackage: processpackage,
	processServiceClient: new processpackage.ProcessServiceClient(),
	profilepackage: profilepackage,
	projectpackage: projectpackage,
	projectServiceClient: new projectpackage.ProjectServiceClient(),
	referencepackage: referencepackage,
	registerServiceClient: new fundspackage.FundsRegisterServiceClient(),
	registrationServiceClient: new securitypackage.RegistrationServiceClient(),
	reportspackage: reportspackage,
	securitypackage: securitypackage as typeof securitypackageApi,
	serverTaskServiceClient: new taskpackage.ServerTaskServiceClient(),
	servicepackage: servicepackage,
	subsystemServiceClient: new configurationpackage.SubsystemServiceClient(),
	taskpackage: taskpackage,
	tenantProfileServiceClient: new profilepackage.TenantProfileServiceClient(),
	uploadFile: function (file, fileName = 'image') {
		return new Promise((resolve, reject) => {
			const fileExt = ((file && file.name ? file.name : '').split('.').pop() || '').toLowerCase();
			if (!fileExt) {
				resolve(null);
				return;
			}

			const data = new FormData();
			data.append(file.name, file);
			const url =
				'/atr-framework-services/upload?ticketUID=' +
				pageContext.ticketuid +
				'&file-access-type=public&save-as-file-name=' +
				fileName +
				'.' +
				fileExt;
			axios
				.post(url, data)
				.then(res => {
					resolve('/' + res.data[0].url);
				})
				.catch(err => {
					console.error(err);
				});
		});
	},
	...promiseBasedApi,
};

export type SeenecoApi = typeof api;
export default api;
