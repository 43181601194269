import React, { memo, useMemo } from 'react';

import { coreApi } from '@core/api/core';
import { useAutoFetch, useMapDispatch, useMapState } from '@flux';
import { mainMarketplacesActionsPack } from '@marketplaces/actions';
import { mainMarketplacesSelectorsPack } from '@marketplaces/selectors';
import { withFormAutopicker } from '@ui/autopicker';
import { createBooleanMap, extractKeysToArray } from '@utils/object';
import {
	OperationTypeAutopicker as XOperationTypeAutopicker,
	OperationTypeAutopickerProps as XOperationTypeAutopickerProps,
} from './operation-type-autopicker.view';

export type OperationTypeAutopickerProps = {
	excludeIDs?: number[];
} & Omit<XOperationTypeAutopickerProps, 'dataSource' | 'isFetching'>;

const OperationTypeAutopicker: React.FC<OperationTypeAutopickerProps> = memo(props => {
	const { excludeIDs = [] } = props;
	const [isFetching, isLoaded, operationTypes] = useMapState([
		mainMarketplacesSelectorsPack.selectAsyncOperationTypes.selectIsFetching,
		mainMarketplacesSelectorsPack.selectAsyncOperationTypes.selectIsLoaded,
		mainMarketplacesSelectorsPack.selectAsyncOperationTypes.selectItem,
	]);
	const [fetchOperationTypes] = useMapDispatch([mainMarketplacesActionsPack.fetchOperationTypes]);
	const dataSource: Array<MarketplaceOperationType> = useMemo(() => {
		const items =
			excludeIDs && excludeIDs.length > 0
				? operationTypes.filter(operationType => !excludeIDs.some(id => id === operationType.ID))
				: operationTypes;

		return items;
	}, [excludeIDs, operationTypes]);

	useAutoFetch({
		selector: mainMarketplacesSelectorsPack.selectAsyncOperationTypes.selectDidInvalidate,
		fetch: () => fetchOperationTypes(),
	});

	return (
		<XOperationTypeAutopicker
			dataSource={dataSource}
			isFetching={isFetching && !isLoaded}
			isUpdating={isFetching}
			{...props}
		/>
	);
});

const FormOperationTypeAutopicker = withFormAutopicker<Omit<OperationTypeAutopickerProps, 'name'>, unknown>(
	OperationTypeAutopicker,
);

const operationTypeAutopickerTransformer = {
	ID: {
		single: {
			transformInput: ({ input }: { input: number }) => (input > 0 ? { [input]: true } : null),
			transformOutput: ({ output }) => Number(extractKeysToArray(output)[0]) || -1,
		},
		multiple: {
			transformInput: ({ input }: { input: Array<number> }) => createBooleanMap(input, x => x),
			transformOutput: ({ output }) => extractKeysToArray(output, Number),
		},
	},
	operationType: {
		single: {
			transformInput: ({ input }: { input: MarketplaceOperationType | null }) => (input ? { [input.ID]: true } : null),
			transformOutput: ({ items }) => (items[0] as Array<MarketplaceOperationType>) || null,
		},
		multiple: {
			transformInput: ({ input }: { input: Array<MarketplaceOperationType> }) => createBooleanMap(input, x => x.ID),
			transformOutput: ({ items }) => items as Array<MarketplaceOperationType>,
		},
	},
	codeNaturalKey: {
		single: {
			transformInput: ({ input }: { input: CodeNaturalKey | null }) => (input ? { [input.ID]: true } : null),
			transformOutput: ({ items }) => createCodeNaturalKey(items[0]) || null,
		},
		multiple: {
			transformInput: ({ input }: { input: Array<CodeNaturalKey> }) => createBooleanMap(input, x => x.ID),
			transformOutput: ({ items }) => items.map((x: MarketplaceOperationType) => createCodeNaturalKey(x)),
		},
	},
};

const createCodeNaturalKey = (item: MarketplaceOperationType): CodeNaturalKey => {
	if (!item) return null;

	return {
		...new coreApi.package.CodeNaturalKey(),
		Code: item?.Code || '',
		ID: item?.ID,
		Name: item?.Name || '',
	};
};

export { FormOperationTypeAutopicker, OperationTypeAutopicker, operationTypeAutopickerTransformer };
