import React from 'react';
import styled, { css } from 'styled-components';

import { Theme } from '@theme';

export type IllustrationBaseProps = {
	color?: 'accent' | 'muted' | 'black' | 'alarm' | 'warning' | 'light' | 'positive' | 'negative' | 'initial';
	size?: number;
	height?: number;
	width?: number;
} & React.HTMLAttributes<unknown>;

const IllustrationBase: React.FC<IllustrationBaseProps> = props => {
	return <IllustrationStyled {...props} />;
};

IllustrationBase.defaultProps = {
	color: 'initial',
	size: 24,
};

type IllustrationStyledProps = IllustrationBaseProps;

const blackListForwardPropsMap = {
	color: true,
	size: true,
};

const shouldForwardProp = (prop: string, defaultValidatorFn: Function) => {
	return !blackListForwardPropsMap[prop] && defaultValidatorFn(prop);
};

const IllustrationStyled = styled.i.withConfig({
	shouldForwardProp,
})<IllustrationStyledProps>`
	display: inline-flex;
	justify-content: center;
	align-items: center;
	color: currentColor;
	cursor: inherit;

	${p =>
		p.color &&
		css`
			color: ${getColor(p.color, p.theme)};
		`}

	${p =>
		p.size &&
		css`
			width: ${p.size}px;
			height: ${p.size}px;

			& > svg {
				width: ${p.size}px;
				height: ${p.size}px;
			}
		`}

	${p =>
		p.width &&
		css`
			width: ${p.width}px;

			& > svg {
				width: ${p.width}px;
			}
		`}

	${p =>
		p.height &&
		css`
			height: ${p.height}px;

			& > svg {
				height: ${p.height}px;
			}
		`}
`;

const getColor = (colorAlias: string, theme: Theme) => {
	const colorMap = {
		accent: theme.palette.accent,
		muted: theme.palette.label,
		black: theme.palette.text,
		alarm: theme.palette.alarm,
		warning: theme.palette.warning,
		light: theme.palette.textContrast,
		positive: theme.palette.positive,
		negative: theme.palette.negative,
		initial: 'currentColor',
	};

	return colorMap[colorAlias] || 'currentColor';
};

export { IllustrationBase };
