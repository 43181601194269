/* eslint-disable no-self-assign */
/* eslint-disable @typescript-eslint/no-implied-eval */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable prettier/prettier */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-var-requires */
'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _simpleAssign = require('simple-assign');

var _simpleAssign2 = _interopRequireDefault(_simpleAssign);

var _transitions = require('../styles/transitions');

var _transitions2 = _interopRequireDefault(_transitions);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _propTypes3 = require('../utils/propTypes');

var _propTypes4 = _interopRequireDefault(_propTypes3);

var _Paper = require('../Paper');

var _Paper2 = _interopRequireDefault(_Paper);

function _interopRequireDefault(obj) {
	return obj && obj.__esModule ? obj : {
		default: obj
	};
}

function getStyles(props, context, state) {
	var targetOrigin = props.targetOrigin;
	var open = state.open;
	var muiTheme = context.muiTheme;
	var horizontal = targetOrigin.horizontal.replace('middle', 'vertical');

	return {
		root: {
			position: 'fixed',
			zIndex: muiTheme.zIndex.popover,
			opacity: open ? 1 : 0,
			transform: open ? 'scale(1, 1)' : 'scale(0, 0)',
			transformOrigin: horizontal + ' ' + targetOrigin.vertical,
			transition: _transitions2.default.easeOut('300ms', ['transform', 'opacity']),
			maxHeight: '100%'
		},
		horizontal: {
			maxHeight: '100%',
			overflowY: 'auto',
			transform: open ? 'scaleX(1)' : 'scaleX(0)',
			opacity: open ? 1 : 0,
			transformOrigin: horizontal + ' ' + targetOrigin.vertical,
			transition: _transitions2.default.easeOut('300ms', ['transform', 'opacity'])
		},
		vertical: {
			opacity: open ? 1 : 0,
			transform: open ? 'scaleY(1)' : 'scaleY(0)',
			transformOrigin: horizontal + ' ' + targetOrigin.vertical,
			transition: _transitions2.default.easeOut('500ms', ['transform', 'opacity'])
		}
	};
}

var PopoverAnimationDefault = function (_Component) {
	(0, _inherits3.default)(PopoverAnimationDefault, _Component);

	function PopoverAnimationDefault() {
		var _ref;

		var _temp, _this, _ret;

		(0, _classCallCheck3.default)(this, PopoverAnimationDefault);

		for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
			args[_key] = arguments[_key];
		}

		return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref = PopoverAnimationDefault.__proto__ || (0, _getPrototypeOf2.default)(PopoverAnimationDefault)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
			open: false
		}, _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
	}

	(0, _createClass3.default)(PopoverAnimationDefault, [{
		key: 'componentDidMount',
		value: function componentDidMount() {
			this.isMountedComponent = true;
			this.forceUpdate();
		}
	}, {
		key: 'componentDidUpdate',
		value: function componentDidUpdate(prevProps) {
			var _this = this;

			if (this.props.open !== this.state.open) {
				setTimeout(function () {
					if (!_this.isMountedComponent) return;
					_this.setState({
						open: _this.props.open
					});
				});
			}
		}
	}, {
		key: 'componentWillUnmount',
		value: function componentWillUnmount() {
			this.isMountedComponent = false;
		}
	}, {
		key: 'render',
		value: function render() {
			var _props = this.props,
				className = _props.className,
				style = _props.style,
				zDepth = _props.zDepth;
			var prepareStyles = this.context.muiTheme.prepareStyles;
			var styles = getStyles(this.props, this.context, this.state);

			return _react2.default.createElement(
				_Paper2.default, {
					style: (0, _simpleAssign2.default)(styles.root, style),
					zDepth: zDepth,
					className: className
				},
				_react2.default.createElement(
					'div', {
						style: prepareStyles(styles.horizontal)
					},
					_react2.default.createElement(
						'div', {
							style: prepareStyles(styles.vertical)
						},
						this.props.children
					)
				)
			);
		}
	}]);
	return PopoverAnimationDefault;
}(_react.Component);

PopoverAnimationDefault.defaultProps = {
	style: {},
	zDepth: 1
};

var MuiThemeContext = require('../styles/context').default;

PopoverAnimationDefault.contextType = MuiThemeContext;

PopoverAnimationDefault.propTypes = process.env.NODE_ENV !== "production" ? {
	children: _propTypes2.default.node,
	/**
	 * The css class name of the root element.
	 */
	className: _propTypes2.default.string,
	open: _propTypes2.default.bool.isRequired,
	/**
	 * Override the inline-styles of the root element.
	 */
	style: _propTypes2.default.object,
	targetOrigin: _propTypes4.default.origin.isRequired,
	zDepth: _propTypes4.default.zDepth
} : {};
exports.default = PopoverAnimationDefault;