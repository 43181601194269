/***
 * Library contains JavaScript API for interaction with Seeneco Framework Web Services contained in TaskPackage
 * @author name Generated by Seeneco Framework Modeler 
 *
 */
(function(w){
/***
* 
*/
w.ServerTask = function() {
  this.PACKAGE = "TASK";
  this.CLASSIFIER = "SERVER_TASK";
  this.CopySupportingElement = false;
  this.SystemName = "";
  this.Name = "";
  this.ID = -1;
  this.GUID = "";
  this.NotificationMailTemplate = "";
};
/***
* 
*/
w.ServerTaskInstance = function() {
  this.PACKAGE = "TASK";
  this.CLASSIFIER = "SERVER_TASK_INSTANCE";
  this.CopySupportingElement = false;
  this.ID = -1;
  this.StartDateTime = null;
  this.FinishDateTime = null;
  this.StatusCode = "";
  this.StatusDescription = "";
  this.Task = null;
  this.Subtasks = [];
  this.Title = "";
};
 
 

/***
 * Client API to ServerTaskServiceWS web service
 */
w.ServerTaskServiceClient = function() {
  /***
   * System name of target service
   */
  this.serviceName = "ServerTaskServiceWSImpl";
};

/***
 * [DEFAULT!!DEFAULT]
 * @param startDateFrom [DEFAULT!!DEFAULT]
 * @param startDateTo [DEFAULT!!DEFAULT]
 * @param taskIDList [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.ServerTaskServiceClient.prototype.getTaskInstances = function(startDateFrom, startDateTo, taskIDList, callbackHandler) {
  var parameterNames = ["startDateFrom", "startDateTo", "taskIDList"];
  var taskIDListJSON = JSON.stringify(taskIDList);
  var parameterValues = [startDateFrom, startDateTo, taskIDListJSON];
  var soapMessage = buildSOAPMessage("getTaskInstances", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = true;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param businessServiceID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.ServerTaskServiceClient.prototype.scheduleBusinessServiceTasks = function(businessServiceID, callbackHandler) {
  var parameterNames = ["businessServiceID"];
  var parameterValues = [businessServiceID];
  var soapMessage = buildSOAPMessage("scheduleBusinessServiceTasks", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param taskInstanceID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.ServerTaskServiceClient.prototype.getTaskInstanceByID = function(taskInstanceID, callbackHandler) {
  var parameterNames = ["taskInstanceID"];
  var parameterValues = [taskInstanceID];
  var soapMessage = buildSOAPMessage("getTaskInstanceByID", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param taskInstanceID [DEFAULT!!DEFAULT]
 * @param newStatusCode [DEFAULT!!DEFAULT]
 * @param newStatusDescription [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.ServerTaskServiceClient.prototype.changeTaskInstanceStatus = function(taskInstanceID, newStatusCode, newStatusDescription, callbackHandler) {
  var parameterNames = ["taskInstanceID", "newStatusCode", "newStatusDescription"];
  var parameterValues = [taskInstanceID, newStatusCode, newStatusDescription];
  var soapMessage = buildSOAPMessage("changeTaskInstanceStatus", parameterNames, parameterValues);
  var resultHasComplexType = false;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param taskInstanceID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.ServerTaskServiceClient.prototype.removeTaskInstanceByID = function(taskInstanceID, callbackHandler) {
  var parameterNames = ["taskInstanceID"];
  var parameterValues = [taskInstanceID];
  var soapMessage = buildSOAPMessage("removeTaskInstanceByID", parameterNames, parameterValues);
  var resultHasComplexType = false;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param parentServerTaskInstanceID [DEFAULT!!DEFAULT]
 * @param title [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.ServerTaskServiceClient.prototype.createSubtask = function(parentServerTaskInstanceID, title, callbackHandler) {
  var parameterNames = ["parentServerTaskInstanceID", "title"];
  var parameterValues = [parentServerTaskInstanceID, title];
  var soapMessage = buildSOAPMessage("createSubtask", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};


/***
 * Package security rights constants
 */

w.TaskSecurityRights = {

};
})(typeof exports !== 'undefined' ? exports : window);
