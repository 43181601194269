import React from 'react';

import { IconBase, IconBaseProps } from './base';

export type BookmarkIconProps = IconBaseProps;

const BookmarkIcon: React.FC<BookmarkIconProps> = props => {
	return (
		<IconBase {...props}>
			<svg
				stroke='currentColor'
				fill='currentColor'
				strokeWidth='0'
				viewBox='0 0 16 16'
				xmlns='http://www.w3.org/2000/svg'>
				<path d='M2 2v13.5a.5.5 0 0 0 .74.439L8 13.069l5.26 2.87A.5.5 0 0 0 14 15.5V2a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2z'></path>
			</svg>
		</IconBase>
	);
};

export { BookmarkIcon };
