import { combineReducers } from 'redux';

import mainBplReducer, { MainBplState } from './main.reducer';

export type BplState = {
	main: MainBplState;
};

const bplReducer = combineReducers<BplState>({
	main: mainBplReducer,
});

export default bplReducer;
