import styled, { css } from 'styled-components';

const Root = styled.div`
	width: 100%;
	height: 1px;

	${p => css`
		background-color: ${p.theme.palette.stealth};
	`}
`;

export { Root };
