/***
 * Library contains JavaScript API for interaction with Seeneco Framework Web Services contained in ConfigurationPackage
 * @author AlexPlex Generated by Seeneco Framework Modeler 
 *
 */
(function(w){
/***
* 
*/
w.ApplicationComponent = function() {
  this.PACKAGE = "CONFIGURATION";
  this.CLASSIFIER = "APPLICATION_COMPONENT";
  this.CopySupportingElement = false;
  this.SystemName = "";
  this.Brief = "";
  this.Name = "";
  this.Description = "";
  this.GUID = "";
};
/***
* Special type of subsystem defining a business application built on a Platform. Business application can include different domain modules and be adaptable to different industries
*/
w.BusinessApplication = function() {
  this.PACKAGE = "CONFIGURATION";
  this.CLASSIFIER = "BUSINESS_APPLICATION";
  this.CopySupportingElement = false;
  this.UsedModules = [];
  this.DayClosureTasks = [];
  this.MenuFilePath = "";
  this.Components = [];
  this.ID = -1;
  this.WelcomePageflowSystemName = "";
  this.SystemName = "";
  this.Brief = "";
  this.Name = "";
  this.Description = "";
  this.GUID = "";
};
/***
* 
*/
w.BusinessApplicationBrief = function() {
  this.PACKAGE = "CONFIGURATION";
  this.CLASSIFIER = "BUSINESS_APPLICATION_BRIEF";
  this.CopySupportingElement = false;
  this.ID = -1;
  this.Name = "";
};
/***
* 
*/
w.BusinessService = function() {
  this.PACKAGE = "CONFIGURATION";
  this.CLASSIFIER = "BUSINESS_SERVICE";
  this.CopySupportingElement = false;
  this.ID = -1;
  this.SystemName = "";
  this.Name = "";
  this.Description = "";
  this.StartPageURL = "";
  this.LandingPageURL = "";
  this.ServerTasks = [];
};
/***
* 
*/
w.DataProviderRole = function() {
  this.PACKAGE = "CONFIGURATION";
  this.CLASSIFIER = "DATA_PROVIDER_ROLE";
  this.CopySupportingElement = false;
  this.AuthorizationMethodCode = "";
};
/***
* 
*/
w.DatasourceSubsystem = function() {
  this.PACKAGE = "CONFIGURATION";
  this.CLASSIFIER = "DATASOURCE_SUBSYSTEM";
  this.CopySupportingElement = false;
  this.ETLProcess = null;
  this.ID = -1;
  this.WelcomePageflowSystemName = "";
  this.SystemName = "";
  this.Brief = "";
  this.Name = "";
  this.Description = "";
  this.GUID = "";
  this.setETLProcess = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.ETLProcess = ref;
  };
};
/***
* 
*/
w.ExternalServiceRole = function() {
  this.PACKAGE = "CONFIGURATION";
  this.CLASSIFIER = "EXTERNAL_SERVICE_ROLE";
  this.CopySupportingElement = false;
};
/***
* Datasource that is represented by a set of files exported from remote system and uploaded to a network path available for ETL process
*/
w.FileDatasourceSubsystem = function() {
  this.PACKAGE = "CONFIGURATION";
  this.CLASSIFIER = "FILE_DATASOURCE_SUBSYSTEM";
  this.CopySupportingElement = false;
  this.ETLProcess = null;
  this.ID = -1;
  this.WelcomePageflowSystemName = "";
  this.SystemName = "";
  this.Brief = "";
  this.Name = "";
  this.Description = "";
  this.GUID = "";
  this.setETLProcess = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.ETLProcess = ref;
  };
};
/***
* Role marks external service as an Identity provider in a Single Sign On technology interaction
*/
w.IdentityProviderRole = function() {
  this.PACKAGE = "CONFIGURATION";
  this.CLASSIFIER = "IDENTITY_PROVIDER_ROLE";
  this.CopySupportingElement = false;
};
/***
* Industry is the production of an economic good or service within an economy. 
System uses industries reference for fine tuning of common applications to specific need of
certain industry (for ex. HORECA, Service companies etc.)
*/
w.Industry = function() {
  this.PACKAGE = "CONFIGURATION";
  this.CLASSIFIER = "INDUSTRY";
  this.CopySupportingElement = false;
  this.SystemName = "";
  this.Brief = "";
  this.Name = "";
  this.Description = "";
  this.GUID = "";
};
/***
* Link between application and domain module defining
 usage of module in application
*/
w.IndustryModuleLink = function() {
  this.PACKAGE = "CONFIGURATION";
  this.CLASSIFIER = "INDUSTRY_MODULE_LINK";
  this.CopySupportingElement = false;
  this.Module = null;
  this.IndustrySpecific = false;
  this.Industry = null;
  this.setModule = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.Module = ref;
  };
  this.setIndustry = function(source) {
    var ref = new TypedReference();
    ref.initFromSource(source);
    this.Industry = ref;
  };
};
/***
* 
*/
w.OpenIDProfile = function() {
  this.PACKAGE = "CONFIGURATION";
  this.CLASSIFIER = "OPEN_IDPROFILE";
  this.CopySupportingElement = false;
  this.ID = -1;
  this.SystemName = "";
  this.Name = "";
  this.ClientID = "";
  this.ClientSecret = "";
};
/***
* 
*/
w.RemoteExternalService = function() {
  this.PACKAGE = "CONFIGURATION";
  this.CLASSIFIER = "REMOTE_EXTERNAL_SERVICE";
  this.CopySupportingElement = false;
  this.Roles = [];
  this.ID = -1;
  this.WelcomePageflowSystemName = "";
  this.SystemName = "";
  this.Brief = "";
  this.Name = "";
  this.Description = "";
  this.GUID = "";
};
/***
* 
*/
w.ResourceURL = function() {
  this.PACKAGE = "CONFIGURATION";
  this.CLASSIFIER = "RESOURCE_URL";
  this.CopySupportingElement = false;
  this.ID = -1;
  this.ResourceTypeCode = "";
  this.URL = "";
};
/***
* Defines a subset of system functionality residing on certain system resources
*/
w.Subsystem = function() {
  this.PACKAGE = "CONFIGURATION";
  this.CLASSIFIER = "SUBSYSTEM";
  this.CopySupportingElement = false;
  this.ID = -1;
  this.WelcomePageflowSystemName = "";
  this.SystemName = "";
  this.Brief = "";
  this.Name = "";
  this.Description = "";
  this.GUID = "";
};
/***
* Defines Subsystem host
*/
w.SubsystemHost = function() {
  this.PACKAGE = "CONFIGURATION";
  this.CLASSIFIER = "SUBSYSTEM_HOST";
  this.CopySupportingElement = false;
  this.HostName = "";
  this.PortNumber = "";
};
/***
* Defines an instance of {@link Subsystem} deployed for certain {@link Tenant}.
{@link SubsystemInstance} stores information about application deployment parameters necessary to connect to hardware\software resources
used for operating an application 
*/
w.SubsystemInstance = function() {
  this.PACKAGE = "CONFIGURATION";
  this.CLASSIFIER = "SUBSYSTEM_INSTANCE";
  this.CopySupportingElement = false;
  this.ID = -1;
  this.GUID = "";
  this.SystemName = "";
  this.Name = "";
  this.Subsystem = null;
  this.Host = null;
  this.Datasource = null;
  this.PlatformDatasource = null;
  this.ResourcesURL = [];
  this.ExternalSystemApplicationUID = "";
  this.ExternalSystemApplicationSecret = "";
  this.OpenIDProfiles = [];
};
/***
* Defines parameters for connection of {@link SubsystemInstance} to an SQL {@link DataSource} available to a platform
*/
w.SubsystemInstanceDatasource = function() {
  this.PACKAGE = "CONFIGURATION";
  this.CLASSIFIER = "SUBSYSTEM_INSTANCE_DATASOURCE";
  this.CopySupportingElement = false;
  this.Name = "";
};
/***
* Relation between application comopnent and a tenant with a flag defining if component is available to given tenant
*/
w.TenantApplicationComponent = function() {
  this.PACKAGE = "CONFIGURATION";
  this.CLASSIFIER = "TENANT_APPLICATION_COMPONENT";
  this.CopySupportingElement = false;
  this.AvailableToTenant = false;
  this.ApplicationComponentID = -1;
  this.ApplicationComponentName = "";
};
/***
* 
*/
w.TenantBusinessServiceSettings = function() {
  this.PACKAGE = "CONFIGURATION";
  this.CLASSIFIER = "TENANT_BUSINESS_SERVICE_SETTINGS";
  this.CopySupportingElement = false;
  this.StartPageURL = "";
};
/***
* Relation between subsystem instances and a tenant with a flag defining if instance is available to given tenant
*/
w.TenantSubsystemInstance = function() {
  this.PACKAGE = "CONFIGURATION";
  this.CLASSIFIER = "TENANT_SUBSYSTEM_INSTANCE";
  this.CopySupportingElement = false;
  this.SSInstanceID = -1;
  this.SSInstanceName = "";
  this.SubsystemName = "";
  this.AvailableToTenant = false;
  this.SubsystemID = -1;
  this.SubsystemGUID = "";
  this.DefaultPageflow = null;
};
 
 

/***
 * Client API to BusinessServiceConfigurationServiceWS web service
 */
w.BusinessServiceConfigurationServiceClient = function() {
  /***
   * System name of target service
   */
  this.serviceName = "BusinessServiceConfigurationServiceWSImpl";
};

/***
 * [DEFAULT!!DEFAULT]
 * @param tenantID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.BusinessServiceConfigurationServiceClient.prototype.getTenantBusinessServices = function(tenantID, callbackHandler) {
  var parameterNames = ["tenantID"];
  var parameterValues = [tenantID];
  var soapMessage = buildSOAPMessage("getTenantBusinessServices", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param businessServiceID [DEFAULT!!DEFAULT]
 * @param tenantID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.BusinessServiceConfigurationServiceClient.prototype.addBusinessServiceToTenant = function(businessServiceID, tenantID, callbackHandler) {
  var parameterNames = ["businessServiceID", "tenantID"];
  var parameterValues = [businessServiceID, tenantID];
  var soapMessage = buildSOAPMessage("addBusinessServiceToTenant", parameterNames, parameterValues);
  var resultHasComplexType = false;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.BusinessServiceConfigurationServiceClient.prototype.getBusinessServices = function(callbackHandler) {
  var parameterNames = [];
  var parameterValues = [];
  var soapMessage = buildSOAPMessage("getBusinessServices", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param businessServiceID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.BusinessServiceConfigurationServiceClient.prototype.getBusinessServiceByID = function(businessServiceID, callbackHandler) {
  var parameterNames = ["businessServiceID"];
  var parameterValues = [businessServiceID];
  var soapMessage = buildSOAPMessage("getBusinessServiceByID", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param businessServiceID [DEFAULT!!DEFAULT]
 * @param tenantID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.BusinessServiceConfigurationServiceClient.prototype.removeBusinessServiceFromTenant = function(businessServiceID, tenantID, callbackHandler) {
  var parameterNames = ["businessServiceID", "tenantID"];
  var parameterValues = [businessServiceID, tenantID];
  var soapMessage = buildSOAPMessage("removeBusinessServiceFromTenant", parameterNames, parameterValues);
  var resultHasComplexType = false;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param businessServiceID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.BusinessServiceConfigurationServiceClient.prototype.getTenantBusinessServiceSettings = function(businessServiceID, callbackHandler) {
  var parameterNames = ["businessServiceID"];
  var parameterValues = [businessServiceID];
  var soapMessage = buildSOAPMessage("getTenantBusinessServiceSettings", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param businessServiceID [DEFAULT!!DEFAULT]
 * @param settings [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.BusinessServiceConfigurationServiceClient.prototype.updateTenantBusinessServiceSettings = function(businessServiceID, settings, callbackHandler) {
  var parameterNames = ["businessServiceID", "settings"];
  var settingsJSON = JSON.stringify(settings);
  var parameterValues = [businessServiceID, settingsJSON];
  var soapMessage = buildSOAPMessage("updateTenantBusinessServiceSettings", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * Client API to IdGeneratorServiceWS web service
 */
w.IdGeneratorServiceClient = function() {
  /***
   * System name of target service
   */
  this.serviceName = "IdGeneratorServiceWSImpl";
};

/***
 * [DEFAULT!!DEFAULT]
 * @param tableName [DEFAULT!Table name owning record with requested id!DEFAULT]
 * @param ssInstanceGUID [DEFAULT!GUID of subsystem instance where DB table is deployed!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.IdGeneratorServiceClient.prototype.nextID = function(tableName, ssInstanceGUID, callbackHandler) {
  var parameterNames = ["tableName", "ssInstanceGUID"];
  var parameterValues = [tableName, ssInstanceGUID];
  var soapMessage = buildSOAPMessage("nextID", parameterNames, parameterValues);
  var resultHasComplexType = false;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * [DEFAULT!Allocates sequence of generated identifiers for certain table deployed in a given susbsytem instance.
Returns created id sequence holding all initial parameters!DEFAULT]
 * @param tableName [DEFAULT!!DEFAULT]
 * @param ssInstanceGUID [DEFAULT!!DEFAULT]
 * @param sequenceSize [DEFAULT!Number of records for which to allocate identifiers!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.IdGeneratorServiceClient.prototype.allocateIdSequence = function(tableName, ssInstanceGUID, sequenceSize, callbackHandler) {
  var parameterNames = ["tableName", "ssInstanceGUID", "sequenceSize"];
  var parameterValues = [tableName, ssInstanceGUID, sequenceSize];
  var soapMessage = buildSOAPMessage("allocateIdSequence", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param tableName [DEFAULT!!DEFAULT]
 * @param ssInstanceGUID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.IdGeneratorServiceClient.prototype.getLastId = function(tableName, ssInstanceGUID, callbackHandler) {
  var parameterNames = ["tableName", "ssInstanceGUID"];
  var parameterValues = [tableName, ssInstanceGUID];
  var soapMessage = buildSOAPMessage("getLastId", parameterNames, parameterValues);
  var resultHasComplexType = false;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * Client API to SubsystemServiceWS web service
 */
w.SubsystemServiceClient = function() {
  /***
   * System name of target service
   */
  this.serviceName = "SubsystemServiceWSImpl";
};

/***
 * [DEFAULT!Returns list of {@link Subsystem} objects that are available to given tenant. 
Subsystem is available for tenant when tenant has a subsystem instance!DEFAULT]
 * @param tenantID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.SubsystemServiceClient.prototype.getAvailableSubsystems = function(tenantID, callbackHandler) {
  var parameterNames = ["tenantID"];
  var parameterValues = [tenantID];
  var soapMessage = buildSOAPMessage("getAvailableSubsystems", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * [DEFAULT!Returns a list containing all subsystem instances with a flags defining if an instance is available to tenant!DEFAULT]
 * @param tenantID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.SubsystemServiceClient.prototype.getSSInstanceRelations = function(tenantID, callbackHandler) {
  var parameterNames = ["tenantID"];
  var parameterValues = [tenantID];
  var soapMessage = buildSOAPMessage("getSSInstanceRelations", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * [DEFAULT!Applies list of relations between tenant and subsystem instances!DEFAULT]
 * @param tenantSSInstances [DEFAULT!List of relations between tenant and subsystem instances to apply!DEFAULT]
 * @param tenantID [DEFAULT!ID of tenant for applying subsystem instances relations!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.SubsystemServiceClient.prototype.applySSInstanceRelations = function(tenantSSInstances, tenantID, callbackHandler) {
  var parameterNames = ["tenantSSInstances", "tenantID"];
  var tenantSSInstancesJSON = JSON.stringify(tenantSSInstances);
  var parameterValues = [tenantSSInstancesJSON, tenantID];
  var soapMessage = buildSOAPMessage("applySSInstanceRelations", parameterNames, parameterValues);
  var resultHasComplexType = false;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param businessApplication [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.SubsystemServiceClient.prototype.publishBusinessApplication = function(businessApplication, callbackHandler) {
  var parameterNames = ["businessApplication"];
  var businessApplicationJSON = JSON.stringify(businessApplication);
  var parameterValues = [businessApplicationJSON];
  var soapMessage = buildSOAPMessage("publishBusinessApplication", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param industry [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.SubsystemServiceClient.prototype.publishIndustry = function(industry, callbackHandler) {
  var parameterNames = ["industry"];
  var industryJSON = JSON.stringify(industry);
  var parameterValues = [industryJSON];
  var soapMessage = buildSOAPMessage("publishIndustry", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param applicationID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.SubsystemServiceClient.prototype.getBusinessApplicationByID = function(applicationID, callbackHandler) {
  var parameterNames = ["applicationID"];
  var parameterValues = [applicationID];
  var soapMessage = buildSOAPMessage("getBusinessApplicationByID", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param ssInstance [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.SubsystemServiceClient.prototype.createSubsystemInstance = function(ssInstance, callbackHandler) {
  var parameterNames = ["ssInstance"];
  var ssInstanceJSON = JSON.stringify(ssInstance);
  var parameterValues = [ssInstanceJSON];
  var soapMessage = buildSOAPMessage("createSubsystemInstance", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param datasourceSubsystem [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.SubsystemServiceClient.prototype.publishDatasourceSubsystem = function(datasourceSubsystem, callbackHandler) {
  var parameterNames = ["datasourceSubsystem"];
  var datasourceSubsystemJSON = JSON.stringify(datasourceSubsystem);
  var parameterValues = [datasourceSubsystemJSON];
  var soapMessage = buildSOAPMessage("publishDatasourceSubsystem", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param datasourceID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.SubsystemServiceClient.prototype.getDatasourceSubsystemByID = function(datasourceID, callbackHandler) {
  var parameterNames = ["datasourceID"];
  var parameterValues = [datasourceID];
  var soapMessage = buildSOAPMessage("getDatasourceSubsystemByID", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param tenantID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.SubsystemServiceClient.prototype.getAvailableDatasources = function(tenantID, callbackHandler) {
  var parameterNames = ["tenantID"];
  var parameterValues = [tenantID];
  var soapMessage = buildSOAPMessage("getAvailableDatasources", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param tenantID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.SubsystemServiceClient.prototype.getComponentsRelations = function(tenantID, callbackHandler) {
  var parameterNames = ["tenantID"];
  var parameterValues = [tenantID];
  var soapMessage = buildSOAPMessage("getComponentsRelations", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param componentsRelations [DEFAULT!!DEFAULT]
 * @param tenantID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.SubsystemServiceClient.prototype.applyComponentsRelations = function(componentsRelations, tenantID, callbackHandler) {
  var parameterNames = ["componentsRelations", "tenantID"];
  var componentsRelationsJSON = JSON.stringify(componentsRelations);
  var parameterValues = [componentsRelationsJSON, tenantID];
  var soapMessage = buildSOAPMessage("applyComponentsRelations", parameterNames, parameterValues);
  var resultHasComplexType = false;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param ssiGUID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.SubsystemServiceClient.prototype.getSubsystemInstance = function(ssiGUID, callbackHandler) {
  var parameterNames = ["ssiGUID"];
  var parameterValues = [ssiGUID];
  var soapMessage = buildSOAPMessage("getSubsystemInstance", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param componentGUID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.SubsystemServiceClient.prototype.getApplicationComponent = function(componentGUID, callbackHandler) {
  var parameterNames = ["componentGUID"];
  var parameterValues = [componentGUID];
  var soapMessage = buildSOAPMessage("getApplicationComponent", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param defaultPageflowID [DEFAULT!!DEFAULT]
 * @param subsystemInstanceID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.SubsystemServiceClient.prototype.setTenantDefaultPageflow = function(defaultPageflowID, subsystemInstanceID, callbackHandler) {
  var parameterNames = ["defaultPageflowID", "subsystemInstanceID"];
  var parameterValues = [defaultPageflowID, subsystemInstanceID];
  var soapMessage = buildSOAPMessage("setTenantDefaultPageflow", parameterNames, parameterValues);
  var resultHasComplexType = false;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param remoteExternalService [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.SubsystemServiceClient.prototype.publishRemoteExternalService = function(remoteExternalService, callbackHandler) {
  var parameterNames = ["remoteExternalService"];
  var remoteExternalServiceJSON = JSON.stringify(remoteExternalService);
  var parameterValues = [remoteExternalServiceJSON];
  var soapMessage = buildSOAPMessage("publishRemoteExternalService", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param remoteExternalServiceID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.SubsystemServiceClient.prototype.getRemoteExternalServiceByID = function(remoteExternalServiceID, callbackHandler) {
  var parameterNames = ["remoteExternalServiceID"];
  var parameterValues = [remoteExternalServiceID];
  var soapMessage = buildSOAPMessage("getRemoteExternalServiceByID", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param subsystemInstanceID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.SubsystemServiceClient.prototype.getSubsystemInstanceByID = function(subsystemInstanceID, callbackHandler) {
  var parameterNames = ["subsystemInstanceID"];
  var parameterValues = [subsystemInstanceID];
  var soapMessage = buildSOAPMessage("getSubsystemInstanceByID", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = true;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};


/***
 * Package security rights constants
 */

w.ConfigurationSecurityRights = {

};
})(typeof exports !== 'undefined' ? exports : window);
