import { createStaticAction, createAsyncAction, RECEIVE } from '@flux';
import { AssignRoleToEmployeesOptions } from '@core/api/counterparty';
import { createNotificationMessage } from '@utils/notifications';
import { mainProjectEmployeesSelectorsPack } from '@project-employees/selectors';
import { PROJECT_EMPLOYEE_ROLES } from '@project-employees/shared';
import { types } from './types';

const actions = {
	setFilterByText: (value: string) => createStaticAction(types.SET_FILTER_BY_TEXT)(value),
	setFilterByProjects: (value: Record<string, boolean>) => createStaticAction(types.SET_FILTER_BY_PROJECTS)(value),
	setFilterByBusinessRoles: (value: Record<string, boolean>) =>
		createStaticAction(types.SET_FILTER_BY_BUSINESS_ROLES)(value),
	resetFilters: () => createStaticAction(types.RESET_FILTERS)(),
	invalidateEmployees: () => createStaticAction(types.INVALIDATE_EMPLOYEES)(),
	fetchProjectEmployees: createAsyncAction(
		types.FETCH_PROJECT_EMPLOYEES,
		api => {
			return new Promise<Array<EmployeeBrief>>(resolve => {
				api.counterpartyPack.counterparty.fetchEmployeesByRoleCodes([...PROJECT_EMPLOYEE_ROLES]).then(employees => {
					resolve(employees);
				});
			});
		},
		{
			isValidSelector: mainProjectEmployeesSelectorsPack.selectAsyncProjectEmployees.selectIsValid,
			isFetchingSelector: mainProjectEmployeesSelectorsPack.selectAsyncProjectEmployees.selectIsFetching,
		},
	) as () => void,
	addEmployee: createAsyncAction(
		types.ADD_EMPLOYEE,
		(api, _, __, options: UpdateEmployeeOptions) => {
			const { employee, projectIDsForLink } = options;

			return new Promise<Employee>(async resolve => {
				const addedEmployee = await api.counterpartyPack.counterparty.addEmployee(employee);

				await api.projectPack.project.linkManagerToProjects({
					managerID: addedEmployee.ID,
					projectIDsForLink,
				});

				const accesses = options.accesses.map(access => {
					return { ...access, EmployeeID: addedEmployee.ID };
				});

				await api.projectPack.project.setProjectsAccess(accesses);

				resolve(addedEmployee);
			});
		},
		{
			showMessage: type => type === RECEIVE && createNotificationMessage('Сотрудник добавлен 😊', 'success'),
		},
	) as (options: UpdateEmployeeOptions) => void,
	updateEmployee: createAsyncAction(
		types.UPDATE_EMPLOYEE,
		(api, _, __, options: UpdateEmployeeOptions) => {
			const { employee, projectIDsForLink, projectIDsForUnlink } = options;

			return new Promise<Employee>(async resolve => {
				const updatedEmployee = await api.counterpartyPack.counterparty.updateEmployee(employee);

				await api.projectPack.project.linkManagerToProjects({
					managerID: updatedEmployee.ID,
					projectIDsForLink,
					projectIDsForUnlink,
				});

				const accesses = options.accesses.map(access => {
					return { ...access, EmployeeID: updatedEmployee.ID };
				});

				await api.projectPack.project.setProjectsAccess(accesses);

				resolve(updatedEmployee);
			});
		},
		{
			showMessage: type => type === RECEIVE && createNotificationMessage('Сотрудник изменён 😊', 'success'),
		},
	) as (options: UpdateEmployeeOptions) => void,
	removeEmployee: createAsyncAction(
		types.REMOVE_EMPLOYEE,
		(api, _, __, employeeID: number) => {
			return new Promise<boolean>(resolve => {
				api.counterpartyPack.counterparty.removeEmployee(employeeID).then(result => {
					resolve(result);
				});
			});
		},
		{
			showMessage: type => type === RECEIVE && createNotificationMessage('Сотрудник удалён 😥', 'success'),
		},
	) as (employeeID: number) => void,
	assignRoleToEmployees: createAsyncAction(
		types.ASSIGN_ROLE_TO_EMPLOYEES,
		(api, _, __, options: AssignRoleToEmployeesOptions) => {
			return new Promise<boolean>(resolve => {
				api.counterpartyPack.counterparty.assignRoleToEmployees(options).then(result => {
					resolve(result);
				});
			});
		},
		{
			showMessage: type => type === RECEIVE && createNotificationMessage('Роли назначены 😊', 'success'),
		},
	) as (options: AssignRoleToEmployeesOptions) => void,
	setProjectsAccess: createAsyncAction(
		types.SET_PROJECS_ACCESS,
		(api, _, __, accesses: Array<ProjectEmployeeAccess>) => {
			return new Promise<boolean>(resolve => {
				api.projectPack.project.setProjectsAccess(accesses).then(result => {
					resolve(result);
				});
			});
		},
		{
			showMessage: type =>
				type === RECEIVE && createNotificationMessage('Настройка доступов к проекту завершена 😊', 'success'),
		},
	) as (accesses: Array<ProjectEmployeeAccess>) => void,
};

type UpdateEmployeeOptions = {
	employee: Employee;
	projectIDsForLink: Array<number>;
	projectIDsForUnlink?: Array<number>;
	accesses: Array<ProjectEmployeeAccess>;
};

export const mainProjectEmployeesActionsPack = {
	types,
	actions,
};
