import { createTypes } from '@flux';

type ActionTypes = {
	RUN_BUSINESS_UNITS_INVALIDATION_EFFECT: string;
	INVALIDATE_BUSINESS_UNITS: string;
	FETCH_BUSINESS_UNITS: string;
	ADD_BUSINESS_UNIT: string;
	UPDATE_BUSINESS_UNIT: string;
	REMOVE_BUSINESS_UNIT: string;
	LINK_OPERATIONS_TO_BUSINESS_UNIT: string;
	SET_SEARCH_TEXT: string;
};

export const types = createTypes<ActionTypes>(
	[
		'RUN_BUSINESS_UNITS_INVALIDATION_EFFECT',
		'INVALIDATE_BUSINESS_UNITS',
		'FETCH_BUSINESS_UNITS',
		'ADD_BUSINESS_UNIT',
		'UPDATE_BUSINESS_UNIT',
		'REMOVE_BUSINESS_UNIT',
		'LINK_OPERATIONS_TO_BUSINESS_UNIT',
		'SET_SEARCH_TEXT',
	],
	'BUSINESS_UNITS',
);
