import { Store } from 'redux';

import { IAppState } from '@store';
import { mainMeasureUnitsActionsPack } from '@measure-units/actions';
import { referenceApi } from '@core/api/reference';

let isEffectRan = false;

function createPrefetchEffect() {
	return (store: Store<IAppState>) => (next: (action: StoreAction) => void) => async (action: StoreAction) => {
		if (!isEffectRan) {
			isEffectRan = true;

			const response = await referenceApi.measureUnit.fetchMeasureUnits();
			store.dispatch(mainMeasureUnitsActionsPack.setMeasureUnits(response));
		}

		next(action);
	};
}

const runPrefetchEffect = createPrefetchEffect();

export { runPrefetchEffect };
