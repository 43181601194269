import styled from 'styled-components';

const Root = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
`;

const KPIComponentLayout = styled.div`
	flex-shrink: 0;
	display: flex;
	align-items: center;
`;

export { Root, KPIComponentLayout };
