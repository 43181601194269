import styled from 'styled-components';

const DefaultHeaderRoot = styled.header`
	flex: 1 1 auto;
	width: 100%;
	height: auto;
	padding: 0 40px;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const DefaultHeaderContentLayout = styled.div`
	width: 100%;
`;

const DefaultHeaderCloseLayout = styled.div`
	margin-right: -18px;
`;

export { DefaultHeaderRoot, DefaultHeaderContentLayout, DefaultHeaderCloseLayout };
