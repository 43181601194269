import * as _ from 'underscore';

import {
	createDefaultAsyncItem,
	checkAsyncItemLoadAction,
} from '@core/libs/reducer-creator';
import * as externalSystemConstants from '../actions/external-system-account.actions';


export interface IExternalSystemAccountState {
	cashManagementSystems: StoreAsyncItem<Record<string, CashManagementSystem>>;
}

const initialState: IExternalSystemAccountState = {
	cashManagementSystems: createDefaultAsyncItem({}),
};

export default function(state: IExternalSystemAccountState = initialState, action) {
	switch (action.type) {
	case externalSystemConstants.FETCH_CASH_MANAGEMENT_SYSTEMS:
		const fetchCMSAction = action as AsyncAction<Array<CashManagementSystem>>;

		return {
			...state,
			cashManagementSystems: checkAsyncItemLoadAction(fetchCMSAction, state.cashManagementSystems, response => _.indexBy(response, el => el.ID)),
		};

	default:
		return state;
	}
}
