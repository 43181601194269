import React from 'react';

import { FormContextProps, withFormComponent } from '../component';

type WithFormCheckboxProps<S = any> = {
	name: string;
	value?: boolean;
	onChange?: (e: React.MouseEvent<{}>, value: boolean) => void;
} & Partial<S>;

function withFormCheckbox<S>(WrappedComponent: React.ComponentType<S>): React.ComponentType<WithFormCheckboxProps<S>> {
	class EnhancedComponent extends React.Component<FormContextProps<any> & WithFormCheckboxProps<S>> {
		static displayName = `withForm[HOC](${WrappedComponent.displayName || WrappedComponent.name})`;

		handleChange = (e: React.MouseEvent<{}>, value: boolean) => {
			const { name, formObject, onChange, handleObjectChange } = this.props;

			setValueByName(value, name, formObject);
			handleObjectChange(formObject);
			typeof onChange === 'function' && onChange(e, value);
		};

		render() {
			const {
				name,
				formObject,
				editedBriefObjects,
				isFetching,
				handleObjectChange,
				addFormValidation,
				removeFormValidation,
				submitForm,
				resetForm,
				validateForm,
				getBriefObject,
				addBriefObject,
				deleteBriefObject,
				handleBriefObjectChange,
				...rest
			} = this.props;
			const value = getValueByName(name, formObject);

			return <WrappedComponent {...(rest as any)} checked={value} onCheck={this.handleChange} />;
		}
	}

	return withFormComponent<WithFormCheckboxProps>(EnhancedComponent);
}

function setValueByName(value: any, name: string, formObject: any) {
	formObject[name] = value;
}

function getValueByName(name: string, formObject: any) {
	return formObject[name];
}

export default withFormCheckbox;
