import * as moment from 'moment';

import { createTypes, createAsyncAction, createStaticAction } from '@flux';
import { FetchOperationsOptions as FetchOperationsApiOptions, IntervalType } from '@core/api/pl';
import { mainPlanningSelectorsPack } from '@pl-planning/selectors';
import { BASE_DATE_FORMAT } from '@shared/constants/time';

export type FetchOperationsOptions = FetchOperationsApiOptions;

export type FetchPlanningDataReturnType = {
	plOperationDynamics: PLOperationDynamics;
	cfForecast: CashflowForecast;
};

type ActionTypes = {
	SET_DATE_RANGE: string;
	SET_DETALIZATION_OPTIONS: string;
	FETCH_PLANNING_DATA: string;
	FETCH_OPERATIONS: string;
	FETCH_PLAN_OPERATIONS: string;
	INVALIDATE_OPERATIONS: string;
	RESTORE_DATA_VALIDATION: string;
	SET_IS_EXPANDED: string;
};

const types = createTypes<ActionTypes>(
	[
		'SET_DATE_RANGE',
		'SET_DETALIZATION_OPTIONS',
		'FETCH_PLANNING_DATA',
		'FETCH_OPERATIONS',
		'FETCH_PLAN_OPERATIONS',
		'INVALIDATE_OPERATIONS',
		'RESTORE_DATA_VALIDATION',
		'SET_IS_EXPANDED',
	],
	'PLANNING',
);

const actions = {
	setDateRange: (dateRange: DateRange) => createStaticAction(types.SET_DATE_RANGE)(dateRange),
	setDetalizationOptions: (options: FetchOperationsOptions) =>
		createStaticAction(types.SET_DETALIZATION_OPTIONS)(options),
	fetchPlanningData: createAsyncAction(types.FETCH_PLANNING_DATA, (api, _, __, dateRangeSource: DateRange) => {
		return new Promise<FetchPlanningDataReturnType>(resolve => {
			const dateStart = moment(dateRangeSource.dateStart, BASE_DATE_FORMAT).startOf('quarter').format(BASE_DATE_FORMAT);
			const dateEnd = moment(dateRangeSource.dateEnd, BASE_DATE_FORMAT).endOf('quarter').format(BASE_DATE_FORMAT);
			const dateRange = { dateStart, dateEnd };

			Promise.all([
				api.plPack.plOperation.fetchAccountChartDynamics({
					dateRange,
					cashflowDateAggregation: true,
					intervalType: IntervalType.MONTH,
				}),
				api.fundsPack.fundsRegister.fetchCashflowForecast({ dateRange }),
			]).then(result => {
				resolve({
					plOperationDynamics: result[0],
					cfForecast: result[1],
				} as FetchPlanningDataReturnType);
			});
		});
	}),
	fetchOperations: createAsyncAction(
		types.FETCH_OPERATIONS,
		(api, _, __, options: FetchOperationsOptions) => {
			return new Promise<Array<PLOperationBrief>>(resolve => {
				api.plPack.plOperation.fetchOperationsBriefs(options).then(result => {
					resolve(result);
				});
			});
		},
		{
			isValidSelector: mainPlanningSelectorsPack.selectAsyncPlOperations.selectIsValid,
			isFetchingSelector: mainPlanningSelectorsPack.selectAsyncPlOperations.selectIsFetching,
		},
	),
	fetchPlanOperations: createAsyncAction(
		types.FETCH_PLAN_OPERATIONS,
		(api, _, __, options: FetchOperationsOptions) => {
			return new Promise<Array<PLOperationBrief>>(resolve => {
				api.plPack.plOperation.fetchOperationsBriefs({ ...options, takeBy: 'plan' }).then(result => {
					resolve(result);
				});
			});
		},
		{
			isValidSelector: mainPlanningSelectorsPack.selectAsyncPlPlanOperations.selectIsValid,
			isFetchingSelector: mainPlanningSelectorsPack.selectAsyncPlPlanOperations.selectIsFetching,
		},
	),
	invalidateOperations: () => createStaticAction(types.INVALIDATE_OPERATIONS)(),
	restoreDataValidation: () => createStaticAction(types.RESTORE_DATA_VALIDATION)(),
	setIsExpanded: (value: boolean) => createStaticAction(types.SET_IS_EXPANDED)(value),
};

export const mainPlanningActionsPack = {
	types,
	actions,
};
