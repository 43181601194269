import { combineReducers } from 'redux';

import { mainCashflowLimitsReducer, MainCashflowLimitsState } from './main.reducer';

export type CashflowLimitsState = {
	main: MainCashflowLimitsState;
};

const cashflowLimitsReducer = combineReducers<CashflowLimitsState>({
	main: mainCashflowLimitsReducer,
});

export { cashflowLimitsReducer };
