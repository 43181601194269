import { useLayoutEffect } from 'react';

function useResize(handler: () => void, deps = []) {
	useLayoutEffect(() => {
		window.addEventListener('resize', handler);

		return () => window.removeEventListener('resize', handler);
	}, [...deps]);
}

function useImmediateResize(handler: () => void, deps = []) {
	useLayoutEffect(() => {
		handler();
	}, [...deps]);

	useResize(handler, deps);
}

export { useResize, useImmediateResize };
