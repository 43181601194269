import { combineReducers } from 'redux';

import { mainTransferRulesReducer, MainTransferRulesState } from './main.reducer';

export type TransferRulesState = {
	main: MainTransferRulesState;
};

const transferRulesReducer = combineReducers<TransferRulesState>({
	main: mainTransferRulesReducer,
});

export { transferRulesReducer };
