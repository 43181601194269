import React, { useEffect, useRef, useState, memo } from 'react';

import { mmToPx } from '@utils/styles';
import { PageOrientation } from '../models';
import { Root, getContentWidthMM } from './styled';

export type DocumentPageProps = {
	orientation?: PageOrientation;
	children: React.ReactNode;
};

const DocumentPage: React.FC<DocumentPageProps> = memo(props => {
	const { orientation, children } = props;
	const contentRef = useRef<HTMLDivElement>(null);
	const [totalPages, setTotalPages] = useState(0);
	const pageWidth = mmToPx(getContentWidthMM(orientation));

	useEffect(() => {
		const totalWidth = contentRef.current.scrollWidth;
		const totalPages = Math.round(totalWidth / pageWidth);

		setTotalPages(totalPages);
	}, [children]);

	return (
		<Root orientation={orientation}>
			<div ref={contentRef} className='content hidden'>
				{children}
			</div>
			<div>
				{Array(totalPages)
					.fill(null)
					.map((_, idx) => {
						return (
							<div key={idx} className='paper'>
								<div className='content'>
									<div style={{ transform: `translateX(-${idx * pageWidth}px)` }}>{children}</div>
								</div>
							</div>
						);
					})}
			</div>
		</Root>
	);
});

DocumentPage.defaultProps = {
	orientation: 'portrait',
};

export { DocumentPage };
