import styled, { css } from 'styled-components';

import { DefaultContent } from '@ui/drawer/default-drawer';

interface IFormContent {
	forDrawer?: boolean;
}

const FormContent = styled(DefaultContent)`
	width: 100%;
	height: auto;
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	align-items: stretch;
	margin-bottom: 282px;

	& .input {
		margin-right: 0;
	}

	${(props: IFormContent) =>
		props.forDrawer &&
		css`
			padding: 40px;
			margin-bottom: 0;
		`}
`;

interface IFormLayout {
	width?: string;
	fullWidth?: boolean;
}

const FormLayout = styled.div`
	width: ${(p: IFormLayout) => p.width || '300px'};

	${(props: IFormLayout) =>
		props.fullWidth &&
		css`
			width: 100%;
		`}
`;

const FormExtraLayout = styled.div`
	flex: 1 1 auto;
	display: flex;
	flex-flow: column nowrap;
	justify-content: space-between;
	align-items: center;
`;

const FormGroup = styled.div`
	width: 100%;
	margin-bottom: 39px;

	&:last-child {
		margin-bottom: 0;
	}
`;

interface IFormGroupTitle {
	fontSize?: string;
	lineHeight?: number;
	marginBottom?: string;
	uppercase?: boolean;
}

const FormGroupTitle = styled.div`
	font-size: 16px;
	line-height: 1.5;
	font-weight: 500;
	margin-bottom: -6px;

	${(props: IFormGroupTitle) =>
		props.fontSize &&
		css`
			font-size: ${props.fontSize};
		`}

	${(props: IFormGroupTitle) =>
		props.lineHeight &&
		css`
			font-size: ${props.lineHeight};
		`}

	${(props: IFormGroupTitle) =>
		props.marginBottom &&
		css`
			margin-bottom: ${props.marginBottom};
		`}

	${(props: IFormGroupTitle) =>
		props.uppercase &&
		css`
			text-transform: uppercase;
			font-size: 14px;
		`}
`;

interface IFormGroupMemder {
	flexDirection?: 'row' | 'column';
}

const FormGroupMemder = styled.div`
	width: 100%;
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	align-items: flex-start;

	${(props: IFormGroupMemder) =>
		props.flexDirection == 'row' &&
		css`
			flex-flow: row wrap;
			justify-content: space-between;
			align-items: center;
		`}
`;

const FormMember = styled.div`
	width: calc(100% - 20px);

	&:nth-child(2n) {
		margin-right: 20px;
	}
`;

interface IFormGroupHorizontalLayout {
	noWrap?: boolean;
	spaceBetween?: boolean;
	inline?: boolean;
}

const FormGroupHorizontalLayout = styled.div`
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;

	${(props: IFormGroupHorizontalLayout) =>
		props.noWrap &&
		css`
			flex-flow: row nowrap;

			@media (max-width: 700px) {
				flex-flow: row wrap;
			}
		`}

	${(props: IFormGroupHorizontalLayout) =>
		props.spaceBetween &&
		css`
			justify-content: space-between;
		`}

	${(props: IFormGroupHorizontalLayout) =>
		props.inline &&
		css`
			width: auto;
			display: inline-flex;
		`}
`;

interface IFormMemberWidth {
	width: string;
	minWidth?: string;
	maxWidth?: string;
	flex?: string;
}

const FormMemberWidth = styled.div`
	${(props: IFormMemberWidth) =>
		props.width &&
		css`
			width: calc(${props.width} - 20px);
			margin-right: 20px;

			&:last-child {
				width: ${props.width};
				margin-right: 0;
			}
		`}

	${(props: IFormMemberWidth) =>
		props.flex &&
		css`
			flex: ${props.flex};
		`}

	${(props: IFormMemberWidth) =>
		props.minWidth &&
		css`
			min-width: ${props.minWidth};
		`}

	${(props: IFormMemberWidth) =>
		props.maxWidth &&
		css`
			max-width: ${props.maxWidth};
		`}

	@media (max-width: 768px) {
		width: 100%;
		margin-right: 0;

		&:last-child {
			width: 100%;
		}
	}
`;

export {
	FormContent,
	FormLayout,
	FormExtraLayout,
	FormGroup,
	FormGroupTitle,
	FormGroupMemder,
	FormMember,
	FormGroupHorizontalLayout,
	FormMemberWidth,
};
