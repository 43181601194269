import React from 'react';
import { NavLink } from 'react-router-dom';

import { UnitOperationsIcon } from '@ui/icons/unit-operations';
import { UnitFundsRegistersIcon } from '@ui/icons/unit-funds-registers';
import { UnitPlanningIcon } from '@ui/icons/unit-planning';
import { UnitReportingIcon } from '@ui/icons/unit-reporting';
import { UnitBudgetingIcon } from '@ui/icons/unit-budgeting';
import { UnitPurchasesIcon } from '@ui/icons/unit-purchases';
import { UnitSalesIcon } from '@ui/icons/unit-sales';
import { UnitProjectsIcon } from '@ui/icons/unit-projects';
import { UnitCounterpartiesIcon } from '@ui/icons/unit-counterparties';
import { UnitReferencesIcon } from '@ui/icons/unit-references';
import { UnitHubIcon } from '@ui/icons/unit-hub';
import { UnitDemoIcon } from '@ui/icons/unit-demo';
import { UnitHelpIcon } from '@ui/icons/unit-help';
import { Root, LinkStyled, IconLayout, NameLayout, DisabledLinkStyled } from './styled';
import { StatefulTooltip } from '../tooltip';

export type AppMenuItemProps = {
	path: string;
	sysName: string;
	name: string;
	tooltipText?: string;
	withReload?: boolean;
	disabled?: boolean;
};

const AppMenuItem: React.FC<AppMenuItemProps> = props => {
	const { path, sysName, name, tooltipText, withReload, disabled } = props;
	const iconColor = disabled ? 'super-muted' : 'current';
	const renderIcon = (code: string) => {
		const map = {
			plManagement: () => <UnitOperationsIcon size={24} color={iconColor} />,
			accounts: () => <UnitFundsRegistersIcon size={24} color={iconColor} />,
			plPlanning: () => <UnitPlanningIcon size={24} color={iconColor} />,
			reporting: () => <UnitReportingIcon size={24} color={iconColor} />,
			budgeting: () => <UnitBudgetingIcon size={24} color={iconColor} />,
			purchases: () => <UnitPurchasesIcon size={24} color={iconColor} />,
			sales: () => <UnitSalesIcon size={24} color={iconColor} />,
			projectManagement: () => <UnitProjectsIcon size={24} color={iconColor} />,
			counterparties: () => <UnitCounterpartiesIcon size={24} color={iconColor} />,
			referenceBooks: () => <UnitReferencesIcon size={24} color={iconColor} />,
			hub: () => <UnitHubIcon size={24} color={iconColor} />,
			demo: () => <UnitDemoIcon size={24} color={iconColor} />,
			help: () => <UnitHelpIcon size={24} color={iconColor} />,
		};

		return map[code] ? map[code]() : null;
	};

	const className = `menu-link-${sysName}`;

	const renderLink = (content: React.ReactNode) => {
		if (disabled) {
			return (
				<DisabledLinkStyled>
					{content} <StatefulTooltip position='right'>{tooltipText}</StatefulTooltip>
				</DisabledLinkStyled>
			);
		}

		if (withReload) {
			return (
				<LinkStyled href={path} target='_blank' rel='noopener'>
					{content}
				</LinkStyled>
			);
		}

		return (
			<LinkStyled component={NavLink} to={path}>
				{content}
			</LinkStyled>
		);
	};

	return (
		<Root className={className}>
			{renderLink(
				<>
					<IconLayout>{renderIcon(sysName)}</IconLayout>
					<NameLayout disabled={disabled}>{name}</NameLayout>
				</>,
			)}
		</Root>
	);
};

export { AppMenuItem };
