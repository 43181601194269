import { useMemo } from 'react';

import store from '@store';

type Action = (...args: any) => any;

function useMapDispatch<
	T1 extends Action,
	T2 extends Action,
	T3 extends Action,
	T4 extends Action,
	T5 extends Action,
	T6 extends Action,
	T7 extends Action,
	T8 extends Action,
	T9 extends Action,
	T10 extends Action,
	T11 extends Action,
	T12 extends Action,
	T13 extends Action,
	T14 extends Action,
	T15 extends Action,
	T16 extends Action,
	T17 extends Action,
	T18 extends Action,
	T19 extends Action,
	T20 extends Action,
>(
	actions: [
		T1,
		T2?,
		T3?,
		T4?,
		T5?,
		T6?,
		T7?,
		T8?,
		T9?,
		T10?,
		T11?,
		T12?,
		T13?,
		T14?,
		T15?,
		T16?,
		T17?,
		T18?,
		T19?,
		T20?,
	],
) {
	const transformedActions = useMemo(
		() =>
			actions.map(
				action =>
					(...args) =>
						store.dispatch(action(...args)),
			),
		[],
	);

	return transformedActions as [
		T1,
		T2?,
		T3?,
		T4?,
		T5?,
		T6?,
		T7?,
		T8?,
		T9?,
		T10?,
		T11?,
		T12?,
		T13?,
		T14?,
		T15?,
		T16?,
		T17?,
		T18?,
		T19?,
		T20?,
	];
}

export { useMapDispatch };
