import styled from 'styled-components';


const Root = styled.div`
	position: relative;
	width: 100%;
	height: auto;
	display: flex;
	flex-flow: column wrap;
	justify-content: flex-start;
	align-items: center;
`;

const ButtonLayout = styled.div`
	width: 100%;
	margin-bottom: 18px;

	& last-child {
		margin-bottom: 0
	}
`;

export {
	Root,
	ButtonLayout,
};
