import React from 'react';

import { IconBase, IconBaseProps } from './base';

export type BoardPersonIconProps = IconBaseProps;

const BoardPersonIcon: React.FC<BoardPersonIconProps> = props => {
	return (
		<IconBase {...props}>
			<svg
				fill='currentColor'
				stroke='currentColor'
				strokeWidth='0'
				viewBox='0 0 16 16'
				xmlns='http://www.w3.org/2000/svg'>
				<g clipPath='url(#clip0_2978_53753)'>
					<path d='M14 9.5C14 10.0304 13.7893 10.5391 13.4142 10.9142C13.0391 11.2893 12.5304 11.5 12 11.5C11.4696 11.5 10.9609 11.2893 10.5858 10.9142C10.2107 10.5391 10 10.0304 10 9.5C10 8.96957 10.2107 8.46086 10.5858 8.08579C10.9609 7.71071 11.4696 7.5 12 7.5C12.5304 7.5 13.0391 7.71071 13.4142 8.08579C13.7893 8.46086 14 8.96957 14 9.5ZM8 15.2C8 16 8.8 16 8.8 16H15.2C15.2 16 16 16 16 15.2C16 14.4 15.2 12 12 12C8.8 12 8 14.4 8 15.2Z' />
					<path d='M2 2C1.46957 2 0.960859 2.21071 0.585786 2.58579C0.210714 2.96086 0 3.46957 0 4L0 12C0 12.5304 0.210714 13.0391 0.585786 13.4142C0.960859 13.7893 1.46957 14 2 14H7.243C7.365 13.674 7.538 13.332 7.769 13H2C1.73478 13 1.48043 12.8946 1.29289 12.7071C1.10536 12.5196 1 12.2652 1 12V4C1 3.73478 1.10536 3.48043 1.29289 3.29289C1.48043 3.10536 1.73478 3 2 3H14C14.2652 3 14.5196 3.10536 14.7071 3.29289C14.8946 3.48043 15 3.73478 15 4V11.81C15.353 12.04 15.656 12.306 15.91 12.593C15.969 12.406 16 12.207 16 12V4C16 3.46957 15.7893 2.96086 15.4142 2.58579C15.0391 2.21071 14.5304 2 14 2H2Z' />
				</g>
				<defs>
					<clipPath id='clip0_2978_53753'>
						<rect width='16' height='16' fill='white' />
					</clipPath>
				</defs>
			</svg>
		</IconBase>
	);
};

export { BoardPersonIcon };
