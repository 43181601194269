import styled from 'styled-components';

const CanvasLayout = styled.span`
	cursor: pointer;
`;

const CanvasModalLayout = styled.div`
	position: relative;
	padding: 60px;
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
`;

const Border = styled.div`
	border: 2px solid #fff;
`;

export { CanvasLayout, CanvasModalLayout, Border };
