import React, { memo, useMemo } from 'react';

import { useMapState, useMapDispatch, useAutoFetch } from '@flux';
import { createBooleanMap, extractKeysToArray } from '@utils/object';
import { groupBy } from '@utils/helpers';
import { withFormAutopicker, defaultAutopickerTransformer } from '@ui/autopicker';
import { mainFundsRegistersActionsPack } from '@funds-registers/actions';
import { selectAsyncFundsRegisterStatistics, selectFundsRegisters } from '@funds-registers/selectors';
import { mainTenantLegalEntitiesActionsPack } from '@tenant-legal-entities/actions';
import { mainTenantLegalEntitiesSelectorsPack } from '@tenant-legal-entities/selectors';
import {
	transformLegalEntityToCounterpartyBrief,
	transformCounterpartyBriefToNaturalKey,
} from '@counterparties/transformers';
import {
	TenantLegalEntityAutopicker as XTenantLegalEntityAutopicker,
	TenantLegalEntityAutopickerProps as XTenantLegalEntityAutopickerProps,
} from './tenant-legal-entity-autopicker.view';

export type TenantLegalEntityAutopickerProps = {
	variant?: 'all' | 'with-funds-registers';
	name?: string;
} & Omit<
	XTenantLegalEntityAutopickerProps,
	'dataSource' | 'isFetching' | 'isLoaded' | 'crudTransformer' | 'hasChangeRight'
> &
	Partial<Pick<XTenantLegalEntityAutopickerProps, 'crudTransformer'>>;

const TenantLegalEntityAutopicker: React.FC<TenantLegalEntityAutopickerProps> = memo(props => {
	const { variant, crudTransformer, ...rest } = props;
	const [hasChangeRight, isFetching, isLoaded, dataSource, fundsRegisters] = useMapState([
		mainTenantLegalEntitiesSelectorsPack.selectTenantLegalEntitiesRight.change,
		mainTenantLegalEntitiesSelectorsPack.selectAsyncTenantLegalEntities.selectIsFetching,
		mainTenantLegalEntitiesSelectorsPack.selectAsyncTenantLegalEntities.selectIsLoaded,
		mainTenantLegalEntitiesSelectorsPack.selectAsyncTenantLegalEntities.selectItem,
		selectFundsRegisters,
	]);
	const [fetchTenantLegalEntities, fetchFundsRegisterStatistics] = useMapDispatch([
		mainTenantLegalEntitiesActionsPack.fetchTenantLegalEntities,
		mainFundsRegistersActionsPack.fetchFundsRegisterStatistics,
	]);
	const withFundsRegisters = variant === 'with-funds-registers';
	const filteredDataSource = useMemo(() => {
		const map: Record<TenantLegalEntityAutopickerProps['variant'], () => Array<CounterpartyBrief>> = {
			all: () => dataSource,
			'with-funds-registers': () => {
				const grouppedMap = groupBy(fundsRegisters, x => x.LegalEntity?.ID || -1);

				return dataSource.filter(x => Boolean(grouppedMap[x.ID]));
			},
		};

		return map[variant]();
	}, [variant, dataSource, fundsRegisters]);

	useAutoFetch({
		selector: mainTenantLegalEntitiesSelectorsPack.selectAsyncTenantLegalEntities.selectDidInvalidate,
		fetch: () => fetchTenantLegalEntities(),
	});

	useAutoFetch({
		selector: selectAsyncFundsRegisterStatistics.selectDidInvalidate,
		fetch: () => withFundsRegisters && fetchFundsRegisterStatistics(),
	});

	return (
		<XTenantLegalEntityAutopicker
			{...rest}
			hasChangeRight={hasChangeRight}
			isFetching={isFetching && !isLoaded}
			isUpdating={isFetching}
			dataSource={filteredDataSource}
			crudTransformer={crudTransformer}
		/>
	);
});

TenantLegalEntityAutopicker.defaultProps = {
	variant: 'all',
	...defaultAutopickerTransformer,
	crudTransformer: transformLegalEntityToCounterpartyBrief,
};

const FormTenantLegalEntityAutopicker = withFormAutopicker<Omit<TenantLegalEntityAutopickerProps, 'name'>, unknown>(
	TenantLegalEntityAutopicker,
);

const tenantLegalEntityAutopickerTransformer = {
	ID: {
		single: {
			transformInput: ({ input }: { input: number }) => (input > 0 ? { [input]: true } : null),
			transformOutput: ({ output }) => Number(extractKeysToArray(output)[0]) || -1,
		},
		multiple: {
			transformInput: ({ input }: { input: Array<number> }) => createBooleanMap(input, x => x),
			transformOutput: ({ output }) => extractKeysToArray(output, Number),
		},
	},
	counterpartyBrief: {
		single: {
			transformInput: ({ input }: { input: CounterpartyBrief | null }) => (input ? { [input.ID]: true } : null),
			transformOutput: ({ items }) => (items[0] as CounterpartyBrief) || null,
		},
		multiple: {
			transformInput: ({ input }: { input: Array<CounterpartyBrief> }) => createBooleanMap(input, x => x.ID),
			transformOutput: ({ items }) => items as Array<CounterpartyBrief>,
		},
	},
	naturalKey: {
		single: {
			transformInput: ({ input }: { input: NaturalKey | null }) => (input ? { [input.ID]: true } : null),
			transformOutput: ({ items }) => {
				const [item] = items as Array<CounterpartyBrief>;

				return item ? transformCounterpartyBriefToNaturalKey(item) : null;
			},
		},
		multiple: {
			transformInput: ({ input }: { input: Array<NaturalKey> }) => createBooleanMap(input, x => x.ID),
			transformOutput: ({ items }) => {
				return (items as Array<CounterpartyBrief>).map(x => transformCounterpartyBriefToNaturalKey(x));
			},
		},
	},
};

export { TenantLegalEntityAutopicker, FormTenantLegalEntityAutopicker, tenantLegalEntityAutopickerTransformer };
