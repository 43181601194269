import { useMemo, useLayoutEffect } from 'react';

function useMounted() {
	const scope = useMemo(() => ({ value: false }), []);

	useLayoutEffect(() => {
		scope.value = true;

		return () => {
			scope.value = false;
		};
	}, []);

	return {
		mounted: () => scope.value,
	};
}

export { useMounted };
