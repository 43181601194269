import {
	StoreAsyncItem,
	checkAsyncAction,
	createAsyncInitialState,
	invalidateStateFromAction,
	createReducer,
} from '@flux';
import { types } from '@measure-units/actions/types';

export type MainMeasureUnitsState = {
	measureUnits: StoreAsyncItem<Array<MeasureUnit>>;
	searchText: string;
	unsavedMeasureUnits: Array<MeasureUnit>;
	unsavedMeasureUnitsLength: number;
	lastMeasureUnitID: number;
};

const initialState: MainMeasureUnitsState = {
	measureUnits: createAsyncInitialState([]),
	searchText: '',
	unsavedMeasureUnits: [],
	unsavedMeasureUnitsLength: 0,
	lastMeasureUnitID: -1,
};

const mainMeasureUnitsReducer = createReducer<MainMeasureUnitsState>(initialState, {
	[types.INVALIDATE]: (action: StaticAction, state) => {
		return {
			measureUnits: invalidateStateFromAction(action, state.measureUnits),
		};
	},
	[types.FETCH]: (action: AsyncAction<Array<MeasureUnit>>, state) => {
		return {
			measureUnits: checkAsyncAction(action, state.measureUnits),
		};
	},
	[types.SET_MEASURE_UNITS]: (action: StaticAction<Array<MeasureUnit>>, state) => {
		const unsavedMeasureUnits = state.unsavedMeasureUnits.slice(state.unsavedMeasureUnitsLength);

		return {
			measureUnits: { ...state.measureUnits, item: action.value },
			unsavedMeasureUnits,
		};
	},
	[types.SET_SEARCH_TEXT]: (action: StaticAction<string>) => {
		return {
			searchText: action.value,
		};
	},
	[types.SET_UNSAVED_MEASURE_UNITS]: (action: StaticAction<Array<MeasureUnit>>) => {
		return {
			unsavedMeasureUnits: [...action.value],
		};
	},
	[types.SET_UNSAVED_MEASURE_UNITS_LENGTH]: (action: StaticAction<number>) => {
		return {
			unsavedMeasureUnitsLength: action.value,
		};
	},
	[types.SET_LAST_MEASURE_UNIT_ID]: (action: StaticAction<number>) => {
		return {
			lastMeasureUnitID: action.value,
		};
	},
});

export { mainMeasureUnitsReducer };
