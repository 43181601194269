import { createTypes } from '@flux';

type ActionTypes = {
	FETCH_DYNAMIC_DATA: string;
	INVALIDATE_PAYMENTS: string;
	ADD_PAYMENT: string;
	UPDATE_PAYMENT: string;
	REMOVE_PAYMENT: string;
	MARK_AS_TRANSFER: string;
	UNMARK_TRANSFER: string;
	MERGE_PAYMENTS_TO_TRANSFER: string;
	SET_TEXT_FILTER: string;
	SET_DIRECTION_FILTER: string;
	SET_FUNDS_REGISTERS_ID: string;
	SET_DATE_RANGE: string;
	FETCH_FUNDS_REGISTER_STATISTICS: string;
};

export const types = createTypes<ActionTypes>(
	[
		'FETCH_DYNAMIC_DATA',
		'INVALIDATE_PAYMENTS',
		'ADD_PAYMENT',
		'UPDATE_PAYMENT',
		'REMOVE_PAYMENT',
		'MARK_AS_TRANSFER',
		'UNMARK_TRANSFER',
		'MERGE_PAYMENTS_TO_TRANSFER',
		'SET_TEXT_FILTER',
		'SET_DIRECTION_FILTER',
		'SET_FUNDS_REGISTERS_ID',
		'SET_DATE_RANGE',
		'FETCH_FUNDS_REGISTER_STATISTICS',
	],
	'PAYMENTS',
);

export type FetchDynamicDataReturnType = {
	cashflowOperations: Array<CashflowOperationBrief>;
	balance: Array<CashBalanceRecord>;
};
