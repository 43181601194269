import {
	BLOCK_SHELL_RERENDER,
	DETAIL_PANEL_VISIBILITY_CONTROL,
	CONTROL_DETAIL_PANEL_CLICK_OUTSIDE,
	SET_APP_PROGRESS_BAR_OPTIONS,
} from '../actions/ui.actions';
import { SetAppProgressBarOptions } from '../models';

interface IUIState {
	isShellRerenderBlocked: boolean;
	detailPanelVisibility: boolean;
	detailPanelClickOutside: boolean;
	appProgressBarOptions: SetAppProgressBarOptions;
}

const initialState = {
	isShellRerenderBlocked: false,
	detailPanelVisibility: false,
	detailPanelClickOutside: true,
	appProgressBarOptions: {
		isOpen: false,
		value: 0,
		text: '',
	},
};

export default function (state: IUIState = initialState, action) {
	switch (action.type) {
		case BLOCK_SHELL_RERENDER:
			const sheelRerenderAction = action as StaticAction<boolean>;

			return {
				...state,
				isShellRerenderBlocked: sheelRerenderAction.value,
			};

		case DETAIL_PANEL_VISIBILITY_CONTROL:
			const detailPanelVisibilityAction = action as StaticAction<boolean>;

			return {
				...state,
				detailPanelVisibility: detailPanelVisibilityAction.value,
			};

		case CONTROL_DETAIL_PANEL_CLICK_OUTSIDE:
			const controlDetailPaneClickOutsideAction = action as StaticAction<boolean>;

			return {
				...state,
				detailPanelClickOutside: controlDetailPaneClickOutsideAction.value,
			};

		case SET_APP_PROGRESS_BAR_OPTIONS:
			return {
				...state,
				appProgressBarOptions: (action as StaticAction<SetAppProgressBarOptions>).value,
			};

		default:
			return state;
	}
}

export { IUIState };
