import React from 'react';

import { DateRangePicker, DateRangePickerProps } from '@ui/date-range-picker';
import { withFormComponent as withForm, FormContextProps } from '../component';
import { BASE_DATE_FORMAT } from '@shared/constants/time';

interface IProps<T> extends Partial<FormContextProps<T>>, Partial<DateRangePickerProps> {
	dateStartControl: {
		get: (f: T) => string;
		set: (f: T, value: string) => void;
	};
	dateEndControl: {
		get: (f: T) => string;
		set: (f: T, value: string) => void;
	};
	labelText?: string;
	required?: boolean;
}

class FormDateRangePicker<T> extends React.PureComponent<IProps<T>> {
	static displayName = 'FormDateRangePicker';
	static defaultProps = {};

	handleOnChange = (dateStart: Moment, dateEnd: Moment) => {
		const { formObject, handleObjectChange, dateStartControl, dateEndControl } = this.props;

		dateStartControl.set(formObject, dateStart.format(BASE_DATE_FORMAT));
		dateEndControl.set(formObject, dateEnd.format(BASE_DATE_FORMAT));

		handleObjectChange(formObject);
	};

	render() {
		const { formObject, dateStartControl, dateEndControl } = this.props;

		return (
			<DateRangePicker
				dateStart={dateStartControl.get(formObject)}
				dateEnd={dateEndControl.get(formObject)}
				onChange={this.handleOnChange}
				{...this.props}
			/>
		);
	}
}

export default withForm(FormDateRangePicker);
