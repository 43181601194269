import React from 'react';

import { ExclamationIcon } from '@ui/icons/exclamation';
import { SUCCESS } from '@shared/constants/statuses';
import { Spinner } from '@ui/spinner';
import {
	Root,
	WrapLayout,
	IconLayout,
	TitleLayout,
	Title,
	Detail,
	ControlsLayout,
	ButtonLayout,
	ActionButton,
	Path,
} from './styled';

type ResultViewProps = {
	type: ServerStatus;
	title: string;
	description?: React.ReactNode;
	actionLabel?: string;
	bigMode?: boolean;
	isFetching?: boolean;
	fetchingLabel?: string;
	fetchingLabelDescription?: string;
	children?: React.ReactNode;
	renderControls?: () => React.ReactNode;
	onAction?: (e: React.MouseEvent) => void;
};

const ResultView: React.FC<ResultViewProps> = props => {
	const {
		type,
		title,
		description,
		actionLabel,
		bigMode,
		isFetching,
		fetchingLabel,
		fetchingLabelDescription,
		children,
		onAction,
		renderControls,
	} = props;
	const isSuccess = type === SUCCESS;

	const renderIcon = () => {
		if (isSuccess) {
			return (
				<svg width='100px' height='100px' viewBox='0 0 104 104'>
					<g stroke='none' transform='translate(-158, -227)' strokeWidth='3' fill='none' fillRule='evenodd'>
						<Path d='M243.73146,242.215245 C234.838601,234.011448 222.956156,229 209.903226,229 C182.342435,229 160,251.342435 160,278.903226 C160,306.464016 182.342435,328.806452 209.903226,328.806452 L209.903226,328.806452 C237.464016,328.806452 259.806452,306.464016 259.806452,278.903226 C259.806452,273.237786 258.862359,267.792851 257.122917,262.717162 M181.806452,273.451613 L206.967742,298.612903 L259,243' />
					</g>
				</svg>
			);
		}

		return <ExclamationIcon color='alarm' size={100} />;
	};

	return (
		<Root>
			<div>
				<WrapLayout>
					<IconLayout bigMode={bigMode}>{isFetching ? <Spinner /> : renderIcon()}</IconLayout>
					<TitleLayout>
						<Title bigMode={bigMode}>{isFetching ? fetchingLabel || 'Проверка статуса' : title}</Title>
						<Detail bigMode={bigMode}>{isFetching ? fetchingLabelDescription || '' : description}</Detail>
					</TitleLayout>
					{actionLabel && (
						<ControlsLayout>
							<ButtonLayout>
								<ActionButton label={actionLabel} onClick={onAction} />
							</ButtonLayout>
						</ControlsLayout>
					)}
					{typeof renderControls === 'function' && renderControls()}
				</WrapLayout>
				{children}
			</div>
		</Root>
	);
};

export { ResultView };
