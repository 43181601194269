import React, {
	useRef,
	useState,
	useLayoutEffect,
	useMemo,
	useEffect,
	forwardRef,
	useImperativeHandle,
	useCallback,
} from 'react';

import { useMounted, useForceUpdate } from '@core/hooks';
import { detectIsFunction, detectIsUndefined, dummy } from '@utils/helpers';
import { createObjectMap, createBooleanMap } from '@utils/object';
import {
	createDataSourceTree,
	createSortedFlattenTree,
	deepFilter,
	getCurrentLevelInFlattenTree,
	getNestedContentShift,
	getAllChildItemIDs,
	TreeSortingFunction,
} from '@utils/tree';
import { Box } from '@ui/box';
import { Typography } from '@ui/typography';
import { Spinner } from '@ui/spinner';
import { GhostIcon } from '@ui/icons/ghost';
import { CheckIcon } from '@ui/icons/check';
import { ExpandLessIcon } from '@ui/icons/expand-less';
import { HighLightedText } from '@ui/highlighted-text';
import { IconButton } from '@ui/icon-button';
import { RequiredListRendererProps } from './autopicker';
import { ListImperativeRef } from './shared';
import {
	ScrollZone,
	ListItem,
	CheckIconLayout,
	ExpandButtonLayout,
	NestingIndicator,
	ListItemControlsLayout,
	VisibilityControlsLayout,
	ListItemTextLayout,
	AdditionalControlsLayout,
} from './styled';

type AutopickerTreeListProps<T = any, P = any> = {
	getChildItems?: (item: T) => Array<T | P>;
	getParentID?: (item: T) => number;
	isAllItemsExpanded?: boolean;
	sortTree?: TreeSortingFunction<T>;
} & RequiredListRendererProps<T>;

const AutopickerTreeList = forwardRef<ListImperativeRef, AutopickerTreeListProps>((props, ref) => {
	const {
		value,
		getID,
		getName,
		getChildItems,
		getParentID,
		searchText,
		dataSource,
		isUpdating,
		containerWidth,
		isSmallContainer,
		relatedPopupID,
		isAllItemsExpanded,
		getNameForSearchTextFilter,
		sortTree,
		renderItemContent,
		renderEditTrigger,
		renderRemoveTrigger,
		renderAddNestedTrigger,
		detectIsItemDisabled = () => false,
		createUnselectItem,
		onSelectItem,
	} = props;
	const { mounted } = useMounted();
	const { forceUpdate } = useForceUpdate();
	const [navigationID, setNavigationID] = useState(DEFAULT_NAVIGATION_ID);
	const [expandedMap, setExpandedMap] = useState<Record<string, boolean>>({});
	const scrollZoneRef = useRef<HTMLUListElement>(null);
	const dataSourceMap = useMemo(() => createObjectMap(dataSource, x => getID(x)), [dataSource]);
	const hasAnyFilter = Boolean(searchText);

	const root = useMemo(() => {
		return createDataSourceTree({
			items: dataSource,
			getID,
			getName,
			getParentID,
			getChildItems,
			filter: x =>
				searchText
					? deepFilter({
							item: x,
							items: dataSource,
							getID,
							getChildItems,
							itemsMap: dataSourceMap,
							filter: x => {
								const extractName = getNameForSearchTextFilter || getName;

								return extractName(x).toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase()) !== -1;
							},
					  })
					: true,
		});
	}, [dataSource, searchText]);

	const sourceItems: Array<FlattenTreeItem> = useMemo(() => {
		const itemsMap = createObjectMap(root.ChildItems, x => x.ID);

		return root.ChildItems.map(item => {
			return {
				ID: item.ID,
				name: item.Name,
				parentID: item.ParentID,
				childItemIDs: item.ChildItems.map(x => x.ID),
				allChildItemIDs: getAllChildItemIDs({
					ID: item.ID,
					items: root.ChildItems,
					getID: x => x.ID,
					getChildItems: x => x.ChildItems,
					itemsMap,
				}),
				value: item.value,
			};
		});
	}, [root]);

	const items = useMemo(() => {
		const unselectItem = detectIsFunction(createUnselectItem)
			? createDefaultFlattenTreeItem(getName(createUnselectItem()))
			: null;
		const sortFn = detectIsFunction(sortTree) ? sortTree : (a, b) => (getName(a) > getName(b) ? 1 : -1);
		const sortedItems = createSortedFlattenTree<FlattenTreeItem>({
			items: [...sourceItems],
			getID: x => x.ID,
			getParentID: x => x.parentID,
			getChildItemIDs: x => x.childItemIDs,
			sortFn: (a, b) => sortFn(a.value, b.value),
		});

		if (!hasAnyFilter && unselectItem) {
			sortedItems.unshift(unselectItem);
		}

		return sortedItems;
	}, [sourceItems]);

	const itemsMap = useMemo(() => createObjectMap(items, x => x.ID), [items]);
	const IDsMap = useMemo(() => createBooleanMap(items, x => x.ID), [items]);
	const itemsLength = items.length;

	useEffect(() => {
		if (!mounted()) return;
		setNavigationID(DEFAULT_NAVIGATION_ID);
	}, [itemsLength]);

	useEffect(() => {
		if (!mounted()) return;
		if (detectIsUndefined(isAllItemsExpanded)) return;
		setExpandedMap(IDsMap);
	}, [isAllItemsExpanded]);

	useLayoutEffect(() => {
		if (!mounted()) return;
		if (searchText) {
			setExpandedMap({});
		} else {
			isAllItemsExpanded ? setExpandedMap(IDsMap) : setExpandedMap({});
		}
	}, [searchText]);

	useLayoutEffect(() => {
		if (!mounted()) return;
		if (!scrollZoneRef.current) return;
		forceUpdate();
	}, [scrollZoneRef.current]);

	useLayoutEffect(() => {
		if (!mounted()) return;
		if (isUpdating) return;
		forceUpdate();
	}, [isUpdating]);

	useImperativeHandle(ref, () => ({
		keyboard,
	}));

	const expandItem = (item: FlattenTreeItem) => {
		const isExpanded = !expandedMap[item.ID];
		const immExpandedMap = { ...expandedMap, [item.ID]: isExpanded };

		for (const childItemID of item.allChildItemIDs) {
			if (immExpandedMap[childItemID]) {
				immExpandedMap[childItemID] = isExpanded;
			}
		}

		setExpandedMap(immExpandedMap);
	};

	const detectIsItemVisible = (ID: SimpleID) => {
		if (hasAnyFilter) return true;
		const item = itemsMap[ID];
		const hasParent = item.parentID > 0;
		const isVisible = !hasParent || (hasParent && (expandedMap[ID] || expandedMap[item.parentID]));

		return isVisible;
	};

	const getPrevVisibleItemID = (ID: SimpleID) => {
		const candidateIdx = items.findIndex(x => x.ID === ID);
		const idx = candidateIdx === -1 ? 0 : candidateIdx;
		const prevItem = items[idx - 1];
		if (!prevItem) return DEFAULT_NAVIGATION_ID;
		const prevItemID = prevItem.ID;
		const isItemVisible = detectIsItemVisible(prevItemID);
		if (isItemVisible) return prevItemID;

		return getPrevVisibleItemID(prevItemID);
	};

	const getNextVisibleItemID = (ID: SimpleID) => {
		if (ID === DEFAULT_NAVIGATION_ID) {
			if (items.length === 1) return items[0].ID;

			if (items.length > 1) {
				const [firstItem] = items;
				const firstID = firstItem.ID;
				const isItemVisible = detectIsItemVisible(firstID);

				if (isItemVisible) return firstID;

				return getNextVisibleItemID(firstID);
			}
		}

		const candidateIdx = items.findIndex(x => x.ID === ID);
		const idx = candidateIdx === -1 ? 0 : candidateIdx;
		const nextItem = items[idx + 1];
		if (!nextItem) return DEFAULT_NAVIGATION_ID;
		const nextItemID = nextItem.ID;
		const isItemVisible = detectIsItemVisible(nextItemID);

		if (isItemVisible) return nextItemID;

		return getNextVisibleItemID(nextItemID);
	};

	const keyboard = {
		processArrowUp: () => {
			const ID = getPrevVisibleItemID(navigationID);

			ID >= -1 && setNavigationID(ID);
		},
		processArrowDown: () => {
			const ID = getNextVisibleItemID(navigationID);

			ID >= -1 && setNavigationID(ID);
		},
		processArrowRight: () => {
			const item = itemsMap[navigationID];
			if (!item || searchText) return;
			const hasChildren = item.childItemIDs.length > 0;
			const isExpanded = expandedMap[item.ID];

			if (hasChildren && !isExpanded) {
				expandItem(item);
			}
		},
		processArrowLeft: () => {
			const item = itemsMap[navigationID];
			if (!item || searchText) return;
			const hasChildren = item.childItemIDs.length > 0;
			const isExpanded = expandedMap[item.ID];

			if (hasChildren && isExpanded) {
				expandItem(item);
			}
		},
		processEnter: () => {
			const item = itemsMap[navigationID];
			if (!item) return;
			const isDisabled = detectIsItemDisabled(item.value);

			item && !isDisabled && onSelectItem(null, item);
		},
	};

	const handleSelectItem = (item: FlattenTreeItem) => (e: React.MouseEvent) => {
		onSelectItem(e, item.value);
	};

	const handleExpand = (item: FlattenTreeItem) => () => expandItem(item);

	const handleStopPropagation = (e: React.MouseEvent) => e.stopPropagation();

	const renderProcessedItemContent = (item: FlattenTreeItem) => {
		if (detectIsFunction(renderItemContent)) {
			return renderItemContent({ item: item.value, searchText, containerWidth });
		}

		return <HighLightedText value={item.name} query={searchText} />;
	};

	if (isUpdating) {
		return (
			<Box display='flex' justifyContent='center' alignItems='center' height={150} fullWidth>
				<Spinner appearance='updating' />
			</Box>
		);
	}

	const hasEditRenderer = detectIsFunction(renderEditTrigger);
	const hasRemoveRenderer = detectIsFunction(renderRemoveTrigger);
	const hasAddNestedRenderer = detectIsFunction(renderAddNestedTrigger);
	const hasAnyTrigger = hasEditRenderer || hasRemoveRenderer || hasAddNestedRenderer;
	const scrollWidth = scrollZoneRef.current?.offsetWidth - scrollZoneRef.current?.clientWidth || 0;

	return (
		<ScrollZone ref={scrollZoneRef}>
			{itemsLength ? (
				items.map(x => {
					const ID = x.ID;
					const isVisible = detectIsItemVisible(ID);

					if (!isVisible) return null;

					const name = x.name;
					const isUnselectItem = ID === -1;
					const isSelected = value && value[ID];
					const isNavigated = navigationID === ID;
					const isExpanded = !isUnselectItem && (hasAnyFilter || expandedMap[ID]);
					const isMuted = isUnselectItem;
					const isDisabled = detectIsItemDisabled(x.value);
					const hasChildren = !isUnselectItem && x.childItemIDs.length > 0;
					const isRelated = ID > 0 && ID === relatedPopupID;
					const levelNumber = getCurrentLevelInFlattenTree<FlattenTreeItem>({
						itemID: x.ID,
						items,
						getID: x => x.ID,
						getParentID: x => x.parentID,
					});
					const marginLeft = getNestedContentShift(levelNumber, 20);
					const controlsCount = [hasAddNestedRenderer, hasEditRenderer, hasRemoveRenderer || hasChildren].filter(
						Boolean,
					).length;

					return (
						<AutopickerTreeListItem
							key={ID}
							isMuted={isMuted}
							isDisabled={isDisabled}
							isSelected={isSelected}
							isNavigated={isNavigated}
							isSmallContainer={isSmallContainer}
							scrollWidth={scrollWidth}
							onClick={!isDisabled ? handleSelectItem(x) : dummy}>
							{({ isHovered }) => {
								return (
									<>
										<ListItemTextLayout>
											{isSelected && (
												<CheckIconLayout>
													<CheckIcon color='accent' size={20} />
												</CheckIconLayout>
											)}
											{marginLeft > 0 && <NestingIndicator shift={marginLeft} />}
											{renderProcessedItemContent(x)}
										</ListItemTextLayout>
										<ListItemControlsLayout count={controlsCount}>
											<VisibilityControlsLayout isNavigated={isNavigated}>
												{hasAnyTrigger && !isMuted && !isDisabled && (isHovered || isNavigated || isRelated) && (
													<AdditionalControlsLayout onClick={handleStopPropagation}>
														{hasAddNestedRenderer && renderAddNestedTrigger(ID, name)}
														{hasEditRenderer && renderEditTrigger(ID, name)}
														{hasRemoveRenderer && !hasChildren && renderRemoveTrigger(ID, name)}
													</AdditionalControlsLayout>
												)}
											</VisibilityControlsLayout>
											{hasChildren && (
												<ExpandButtonLayout isExpanded={isExpanded}>
													<IconButton
														tabIndex={-1}
														variant='rounded'
														isSilentDisabled={Boolean(searchText)}
														isSilentHover
														stopPropagation
														onClick={handleExpand(x)}>
														<ExpandLessIcon color='muted' size={20} />
													</IconButton>
												</ExpandButtonLayout>
											)}
										</ListItemControlsLayout>
									</>
								);
							}}
						</AutopickerTreeListItem>
					);
				})
			) : (
				<Box
					display='flex'
					flexDirection='column'
					justifyContent='center'
					alignItems='center'
					minHeight={150}
					padding={20}>
					<Box marginBottom={10}>
						<GhostIcon color='accent' size={24} />
					</Box>
					{searchText ? (
						<Typography.Label>По запросу «{searchText}» ничего не найдено</Typography.Label>
					) : (
						<Typography.Label>Ничего не найдено</Typography.Label>
					)}
				</Box>
			)}
		</ScrollZone>
	);
});

AutopickerTreeList.defaultProps = {
	getChildItems: x => x.ChildItems,
	getParentID: x => x.ParentID,
};

const DEFAULT_NAVIGATION_ID = -2;

type AutopickerTreeListItemProps = {
	isMuted: boolean;
	isDisabled: boolean;
	isSelected: boolean;
	isNavigated: boolean;
	isSmallContainer: boolean;
	scrollWidth: number;
	children: (options: AutopickerTreeListItemChildrenOptions) => React.ReactNode;
	onClick: (e: React.MouseEvent) => void;
};

const AutopickerTreeListItem: React.FC<AutopickerTreeListItemProps> = props => {
	const { isNavigated, isSmallContainer, children, ...rest } = props;
	const rootRef = useRef<HTMLLIElement>(null);
	const [isHovered, setIsHovered] = useState(false);

	useEffect(() => {
		if (!isNavigated) return;
		scrollIntoView();
	}, [isNavigated]);

	const scrollIntoView = () => rootRef.current?.scrollIntoView({ block: 'center', behavior: 'smooth' });

	const handleMouseOver = useCallback(() => {
		setIsHovered(true);
	}, []);

	const handleMouseLeave = useCallback(() => {
		setIsHovered(false);
	}, []);

	return (
		<ListItem
			ref={rootRef}
			isSmallContainer={isSmallContainer}
			isNavigated={isNavigated}
			onMouseOver={handleMouseOver}
			onMouseLeave={handleMouseLeave}
			{...rest}>
			{children({ isHovered })}
		</ListItem>
	);
};

type AutopickerTreeListItemChildrenOptions = {
	isHovered: boolean;
};

type FlattenTreeItem<T = any> = {
	ID: SimpleID;
	name: string;
	parentID: SimpleID;
	childItemIDs: Array<SimpleID>;
	allChildItemIDs: Array<SimpleID>;
	value: T;
};

function createDefaultFlattenTreeItem(name: string) {
	return {
		ID: -1,
		name,
		parentID: -1,
		childItemIDs: [],
		allChildItemIDs: [],
		value: null,
	};
}

export { AutopickerTreeList };
