import * as moment from 'moment';

import { Layout } from '@ui/grid-layout';
import { createDateList, getDateDiff } from '@utils/date';
import { BASE_DATE_FORMAT } from '@shared/constants/time';

type DetalizationKey = 'day' | 'month';

function getDetalizationDateRange(date: string, key: DetalizationKey): DateRange {
	const range: DateRange = {
		dateStart: key === 'day' ? date : moment(date, BASE_DATE_FORMAT).startOf('month').format(BASE_DATE_FORMAT),
		dateEnd: key === 'day' ? date : moment(date, BASE_DATE_FORMAT).endOf('month').format(BASE_DATE_FORMAT),
	};

	return range;
}

function getChartCategories(dateStart: string, dateEnd: string): Array<string> {
	const detalizationKey = getDetalizationKey({ dateStart, dateEnd });
	const dates = createDateList(dateStart, dateEnd, detalizationKey);
	const categories = dates.map(x =>
		detalizationKey == 'day'
			? moment(x, BASE_DATE_FORMAT).format('DD.MM.YYYY')
			: moment(x, BASE_DATE_FORMAT).format('MMMM YYYY'),
	);

	return categories;
}

function formatDetalizationDate(date: string, key: DetalizationKey) {
	const format = key === 'day' ? 'DD.MM.YYYY' : 'MMMM YYYY';

	return moment(date, BASE_DATE_FORMAT).format(format).toLowerCase();
}

function detectIsEnabledDetalizationByDays(dateRange: DateRange): boolean {
	return getDateDiff(dateRange.dateStart, dateRange.dateEnd, 'month') <= 1;
}

function getDetalizationKey(dateRange: DateRange): DetalizationKey {
	return detectIsEnabledDetalizationByDays(dateRange) ? 'day' : 'month';
}

export enum GridLayoutCardKey {
	DIRECTION_CARD = 'DIRECTION_CARD',
	CASHFLOW_CARD_INCOME = 'CASHFLOW_CARD_INCOME',
	CASHFLOW_CARD_EXPENSE = 'CASHFLOW_CARD_EXPENSE',
	RESULT_CARD = 'RESULT_CARD',
	DEPOSITS_CARD = 'DEPOSITS_CARD',
	FORECAST_CARD = 'FORECAST_CARD',
	CP_CARD_PAYER = 'CP_CARD_PAYER',
	CP_CARD_RECIPIENT = 'CP_CARD_RECIPIENT',
}

function getDefaultGridLayout(): Array<Layout> {
	return [
		{ i: GridLayoutCardKey.DIRECTION_CARD, x: 0, y: 0, w: 12, h: 11, minW: 4, minH: 11 },
		{ i: GridLayoutCardKey.CASHFLOW_CARD_INCOME, x: 0, y: 1, w: 6, h: 11, minW: 4, minH: 11 },
		{ i: GridLayoutCardKey.CASHFLOW_CARD_EXPENSE, x: 6, y: 1, w: 6, h: 11, minW: 4, minH: 11 },
		{ i: GridLayoutCardKey.RESULT_CARD, x: 0, y: 2, w: 12, h: 6, minW: 4, minH: 6 },
		{ i: GridLayoutCardKey.DEPOSITS_CARD, x: 0, y: 3, w: 12, h: 5, minW: 4, minH: 5 },
		{ i: GridLayoutCardKey.CP_CARD_PAYER, x: 0, y: 4, w: 6, h: 11, minW: 4, minH: 11 },
		{ i: GridLayoutCardKey.CP_CARD_RECIPIENT, x: 6, y: 4, w: 6, h: 11, minW: 4, minH: 11 },
		{ i: GridLayoutCardKey.FORECAST_CARD, x: 0, y: 5, w: 12, h: 11, minW: 6, minH: 11 },
	];
}

function getDefaultCardsExpanded(): Record<string, boolean> {
	return {
		[GridLayoutCardKey.DIRECTION_CARD]: false,
		[GridLayoutCardKey.CASHFLOW_CARD_INCOME]: false,
		[GridLayoutCardKey.CASHFLOW_CARD_EXPENSE]: false,
		[GridLayoutCardKey.CP_CARD_PAYER]: false,
		[GridLayoutCardKey.CP_CARD_RECIPIENT]: false,
	};
}

export {
	getDetalizationDateRange,
	getChartCategories,
	formatDetalizationDate,
	detectIsEnabledDetalizationByDays,
	getDetalizationKey,
	getDefaultGridLayout,
	getDefaultCardsExpanded,
};
