import React from 'react';
import MaskedInput from 'react-text-mask';

import { TextFieldProps } from './';

interface IMaskParams {
	keepCharPositions?: boolean;
	showMask?: boolean;
	guide?: boolean;
	pipe?: Function;
	withoutExtends?: boolean;
	placeholderChar?: string;
}

type Props = {} & TextFieldProps;

const initialOptions: IMaskParams = {
	keepCharPositions: false,
	showMask: false,
	guide: false,
	pipe: null,
	withoutExtends: false,
	placeholderChar: '_',
};

const withMaskHOC = (WrappedComponent, mask: Array<string | RegExp>, options: IMaskParams = initialOptions) => {
	const regExp = new RegExp(
		mask
			.map(v => {
				if (v instanceof RegExp) {
					v = v.toString();
					return '(' + v.substr(1, v.length - 2) + ')';
				}
				if (v === '(' || v === ')') {
					v = '\\' + v;
				}
				return v;
			})
			.join(''),
	);

	return class WithMaskComponent extends React.Component<Props> {
		static displayName = 'withMask[HOC]';
		displayedValue: '';

		onChangeExtended = onChange => (e, newValue) => {
			const matched = newValue.match(regExp);
			this.displayedValue = newValue;

			newValue = matched ? (newValue = matched.slice(1).join('')) : '';

			if (onChange) {
				onChange(e, newValue, !!matched);
			}
		};

		componentDidUpdate(prevProps: Props) {
			if (prevProps.value !== this.props.value) {
				this.displayedValue = '';
			}
		}

		render() {
			const { name } = this.props;
			const { keepCharPositions, showMask, guide, pipe, withoutExtends, placeholderChar } = options;
			const customProps = { ...this.props };

			if (!withoutExtends) {
				customProps.onChange = this.onChangeExtended(this.props.onChange);
			}

			return (
				<WrappedComponent {...customProps}>
					<MaskedInput
						name={name}
						value={this.displayedValue || customProps.value}
						mask={mask}
						keepCharPositions={keepCharPositions}
						placeholderChar={placeholderChar || initialOptions.placeholderChar}
						showMask={showMask}
						guide={guide}
						pipe={pipe}
					/>
				</WrappedComponent>
			);
		}
	};
};

export { withMaskHOC };
