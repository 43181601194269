import styled, { css } from 'styled-components';

import { Link } from '@ui/link';

const Root = styled.li`
	position: relative;
	width: 100%;
`;

const IconLayout = styled.div`
	& svg {
		transition: color 0.2s ease-in-out;
	}

	${p => css`
		color: ${p.theme.appMenu.color};
	`}
`;

type NameLayoutProps = {
	disabled?: boolean;
};

const NameLayout = styled.div<NameLayoutProps>`
	width: 100%;
	text-align: center;
	font-size: 11px;
	line-height: 1.25;
	margin-top: 4px;
	transition: color 0.2s ease-in-out;

	${p => css`
		color: ${p.disabled ? p.theme.palette.superMuted : p.theme.appMenu.color};
	`}
`;

const LinkStyled = styled(Link)`
	position: relative;
	z-index: 1;
	width: 100%;
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	padding: 8px 0;

	&:hover {
		text-decoration: none;
	}

	${p =>
		!p.disabled &&
		css`
			&:hover,
			&.active {
				background-color: ${p.theme.appMenu.backgroundColor};
				box-shadow: ${p.theme.appMenu.boxShadow};
				z-index: 2;
			}

			&:hover {
				z-index: 3;
			}

			&:hover ${IconLayout}, &.active ${IconLayout} {
				color: ${p.theme.appMenu.activeIconColor};
			}

			&:hover ${NameLayout}, &.active ${NameLayout} {
				color: ${p.theme.appMenu.activeTextColor};
			}
		`}
	${p =>
		p.disabled &&
		css`
			cursor: default;
		`}
`;

const DisabledLinkStyled = styled.span`
	position: relative;
	z-index: 1;
	width: 100%;
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	padding: 8px 0;
`;

export { Root, IconLayout, LinkStyled, DisabledLinkStyled, NameLayout };
