import {
	StoreAsyncItem,
	createReducer,
	createAsyncInitialState,
	checkAsyncAction,
	invalidateStateFromAction,
} from '@flux';
import { types } from '@project-employees/actions/types';

export type MainProjectEmployeesState = {
	textFilter: string;
	projectsFilter: Record<string, boolean>;
	businessRolesFilter: Record<string, boolean>;
	employees: StoreAsyncItem<Array<EmployeeBrief>>;
};

const initialState: MainProjectEmployeesState = {
	textFilter: '',
	projectsFilter: {},
	businessRolesFilter: {},
	employees: createAsyncInitialState([]),
};

const mainProjectEmployeesReducer = createReducer<MainProjectEmployeesState>(initialState, {
	[types.SET_FILTER_BY_TEXT]: (action: StaticAction<string>) => {
		return {
			textFilter: action.value,
		};
	},
	[types.SET_FILTER_BY_PROJECTS]: (action: StaticAction<Record<string, boolean>>) => {
		return {
			projectsFilter: { ...action.value },
		};
	},
	[types.SET_FILTER_BY_BUSINESS_ROLES]: (action: StaticAction<Record<string, boolean>>) => {
		return {
			businessRolesFilter: { ...action.value },
		};
	},
	[types.RESET_FILTERS]: () => {
		return {
			textFilter: '',
			projectsFilter: {},
			businessRolesFilter: {},
		};
	},
	[types.INVALIDATE_EMPLOYEES]: (action: StaticAction, state) => {
		return {
			employees: invalidateStateFromAction(action, state.employees),
		};
	},
	[types.FETCH_PROJECT_EMPLOYEES]: (action: AsyncAction<Array<EmployeeBrief>>, state) => {
		return {
			employees: checkAsyncAction(action, state.employees),
		};
	},
	[types.ADD_EMPLOYEE]: (action: AsyncAction, state) => {
		return {
			employees: invalidateStateFromAction(action, state.employees),
		};
	},
	[types.UPDATE_EMPLOYEE]: (action: AsyncAction, state) => {
		return {
			employees: invalidateStateFromAction(action, state.employees),
		};
	},
	[types.REMOVE_EMPLOYEE]: (action: AsyncAction, state) => {
		return {
			employees: invalidateStateFromAction(action, state.employees),
		};
	},
	[types.ASSIGN_ROLE_TO_EMPLOYEES]: (action: AsyncAction, state) => {
		return {
			employees: invalidateStateFromAction(action, state.employees),
		};
	},
	[types.SET_PROJECS_ACCESS]: (action: AsyncAction, state) => {
		return {
			employees: invalidateStateFromAction(action, state.employees),
		};
	},
});

export default mainProjectEmployeesReducer;
