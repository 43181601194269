/***
 * Library contains JavaScript API for interaction with Seeneco Framework Web Services contained in AnalysisPackage
 * @author Generated by Seeneco Framework Modeler 
 *
 */
(function(w){
/***
* Group of ABC analysis
*/
w.ABCAnalysisSegment = function() {
  this.PACKAGE = "ANALYSIS";
  this.CLASSIFIER = "ABCANALYSIS_SEGMENT";
  this.CopySupportingElement = false;
  this.SegmentMembersPercent = -1;
  this.SegmentFactorPercent = -1;
  this.SegmentFactorValue = -1;
  this.SegmentMembersCount = -1;
  this.Members = [];
  this.Name = "";
};
/***
* 
*/
w.AnalyticalDataSegment = function() {
  this.PACKAGE = "ANALYSIS";
  this.CLASSIFIER = "ANALYTICAL_DATA_SEGMENT";
  this.CopySupportingElement = false;
  this.Members = [];
  this.Name = "";
};
/***
* 
*/
w.DatasetSegmentationRequest = function() {
  this.PACKAGE = "ANALYSIS";
  this.CLASSIFIER = "DATASET_SEGMENTATION_REQUEST";
  this.CopySupportingElement = false;
  this.FactorField = "";
  this.MemberUIDField = "";
  this.DatasetSchemaSystemName = "";
  this.ParameterValues = {};
  this.DatasetSchemaGUID = "";
};
 
 

/***
 * Client API to SegmentationServiceWS web service
 */
w.SegmentationServiceClient = function() {
  /***
   * System name of target service
   */
  this.serviceName = "SegmentationServiceWSImpl";
};

/***
 * [DEFAULT!Performs ABC analysis of source data contained in dataset!DEFAULT]
 * @param request [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.SegmentationServiceClient.prototype.doDatasetABCAnalysis = function(request, callbackHandler) {
  var parameterNames = ["request"];
  var requestJSON = JSON.stringify(request);
  var parameterValues = [requestJSON];
  var soapMessage = buildSOAPMessage("doDatasetABCAnalysis", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
};


/***
 * Package security rights constants
 */

w.AnalysisSecurityRights = {

};
})(typeof exports !== 'undefined' ? exports : window);
