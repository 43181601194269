import styled from 'styled-components';

const DocumentTable = styled.table`
	position: relative;
	table-layout: fixed;
	width: 100%;
	max-width: 100%;
	font-size: 9px;
	line-height: normal;

	& th {
		text-align: center;
		font-weight: 400;
		vertical-align: middle;
	}

	& th,
	& td {
		padding: 1px;
		border: 1px solid rgb(0, 0, 0);
	}

	&.no-borders > thead > tr > th,
	&.no-borders > tbody > tr > td {
		border-width: 0;
	}

	&.v-top th,
	&.v-top td {
		vertical-align: top;
	}

	&.v-bottom th,
	&.v-bottom td {
		vertical-align: bottom;
	}

	& .text-center {
		text-align: center;
	}

	& .text-right {
		text-align: right;
	}

	& .text-bold {
		font-weight: 700;
	}

	& .no-borders {
		border-width: 0;
	}

	& .border-bottom {
		border-bottom-width: 1px !important;
	}

	& .fat-borders {
		border-width: 2px;
	}

	& .fat-header {
		border-top-width: 2px;
		border-right-width: 2px;
		border-left-width: 2px;
	}

	& .fat-body {
		border-right-width: 2px;
		border-left-width: 2px;
	}

	& .fat-footer {
		border-right-width: 2px;
		border-bottom-width: 2px;
		border-left-width: 2px;
	}

	& .fat-border-left {
		border-left-width: 2px;
	}

	& .fat-border-right {
		border-right-width: 2px !important;
	}

	& .fat-border-top {
		border-top-width: 2px;
	}

	& .fat-border-bottom {
		border-bottom-width: 2px;
	}

	& .no-border-left {
		border-left-color: transparent;
	}

	& .no-border-right {
		border-right-color: transparent;
	}

	& .no-border-top {
		border-top-color: transparent;
	}

	& .no-border-bottom {
		border-bottom-color: transparent;
	}

	& .placeholder {
		position: relative;
		border-top-color: transparent;
		border-right-color: transparent;
		border-left-color: transparent;
	}

	& .placeholder-value {
		display: inline-block;
		padding-top: 12px;
		min-height: 14px;
	}

	& .placeholder-label {
		position: absolute;
		left: 0;
		bottom: -1px;
		transform-origin: left;
		transform: translate(46px, 100%) scale(0.8);
	}

	& .v-top {
		vertical-align: top !important;
	}

	& .v-middle {
		vertical-align: middle !important;
	}

	& .v-bottom {
		vertical-align: bottom !important;
	}

	& .border-right {
		border-right-color: #000;
	}

	& .border-bottom {
		border-bottom-color: #000;
	}

	& .transparent-border-top {
		border-top-color: transparent;
	}

	& .transparent-border-right {
		border-right-color: transparent;
	}

	& .transparent-border-bottom {
		border-bottom-color: transparent;
	}

	& .transparent-border-left {
		border-left-color: transparent;
	}

	& .fake-border-left {
		position: relative;
	}

	& .fake-border-left::after {
		content: '';
		position: absolute;
		width: 1px;
		height: calc(100% + 1px);
		background-color: #000;
		left: -1px;
		bottom: -1px;
	}

	& .relative {
		position: relative;
	}

	& .no-padding-right {
		padding-right: 0;
	}

	& .no-padding {
		padding: 0;
	}
`;

export { DocumentTable };
