import React from 'react';

import { Checkbox } from '@ui/checkbox';
import { RadioCheckedIcon } from '@ui/icons/radio-checked';
import { RadioUncheckedIcon } from '@ui/icons/radio-unchecked';
import { Root, SwitchCardLayout, SwitchLayout, ContentLayout } from './styled';

type SwitchCardProps = {
	type?: 'radio';
	isActive: boolean;
	renderSwitch?: boolean;
	hover?: boolean;
	children: React.ReactNode;
	onClick?: (e: React.MouseEvent) => void;
};

const SwitchCard: React.FC<SwitchCardProps> = props => {
	const { isActive, renderSwitch, hover, onClick, children } = props;

	return (
		<Root isActive={isActive} renderSwitch={renderSwitch} hover={hover} onClick={!isActive ? onClick : () => {}}>
			{renderSwitch && (
				<SwitchLayout>
					<Checkbox
						checked={isActive}
						checkedIcon={<RadioCheckedIcon color='accent' size={24} />}
						uncheckedIcon={<RadioUncheckedIcon color='black' size={24} />}
					/>
				</SwitchLayout>
			)}
			<ContentLayout>{children}</ContentLayout>
		</Root>
	);
};

SwitchCard.defaultProps = {
	type: 'radio',
	renderSwitch: true,
};

export { SwitchCard, SwitchCardLayout };
