import { useEffect } from 'react';

type UseScrollOptions = {
	element?: HTMLElement;
	callback: () => void;
	deps?: Array<any>;
};

function useScroll(options: UseScrollOptions) {
	const { element = window, callback, deps = [] } = options;

	useEffect(() => {
		element.addEventListener('scroll', callback);

		return () => element.removeEventListener('scroll', callback);
	}, [...deps]);
}

export { useScroll };
