import * as reportspackage from 'reportspackage-api';
import { buildTLongArray } from '@utils';

const reportsApi = {
	package: reportspackage,
	reports: {
		client: new reportspackage.ReportsServiceClient(),
		fetchCashflowReport: (options: FetchReportOptions) => {
			const {
				dateRange,
				tenantEntityIDs,
				projectIDs,
				businessUnitIDs,
				cashflowItemIDs,
				fundsRegisterIDs,
				isIncludesVAT,
				isIncludesPlan,
			} = options;
			const request: ReportRequest = {
				...new reportsApi.package.ReportRequest(),
				DateStart: dateRange.dateStart,
				DateFinish: dateRange.dateEnd,
				ExcludeVat: !isIncludesVAT,
				ExcludePlan: !isIncludesPlan,
				TenantLegalEntitiesIDs: buildTLongArray(tenantEntityIDs),
				ProjectIDs: buildTLongArray(projectIDs),
				BusinessUnitsIDs: buildTLongArray(businessUnitIDs),
				AccountsChartItemIDs: buildTLongArray(cashflowItemIDs),
				FundsRegisterIDs: buildTLongArray(fundsRegisterIDs),
			};

			return new Promise<ReportResponse>(resolve => {
				reportsApi.reports.client.getCashflowReport(request, result => {
					resolve(result);
				});
			});
		},
		fetchAccrualReport: (options: FetchReportOptions) => {
			const {
				dateRange,
				tenantEntityIDs,
				projectIDs,
				businessUnitIDs,
				cashflowItemIDs,
				fundsRegisterIDs,
				isIncludesVAT,
				isIncludesPlan,
			} = options;
			const request: ReportRequest = {
				...new reportsApi.package.ReportRequest(),
				DateStart: dateRange.dateStart,
				DateFinish: dateRange.dateEnd,
				ExcludeVat: !isIncludesVAT,
				ExcludePlan: !isIncludesPlan,
				TenantLegalEntitiesIDs: buildTLongArray(tenantEntityIDs),
				ProjectIDs: buildTLongArray(projectIDs),
				BusinessUnitsIDs: buildTLongArray(businessUnitIDs),
				AccountsChartItemIDs: buildTLongArray(cashflowItemIDs),
				FundsRegisterIDs: buildTLongArray(fundsRegisterIDs),
			};

			return new Promise<ReportResponse>(resolve => {
				reportsApi.reports.client.getAccrualReport(request, result => {
					resolve(result);
				});
			});
		},
		fetchProfitLossReport: (options: FetchReportOptions) => {
			const {
				dateRange,
				tenantEntityIDs,
				projectIDs,
				businessUnitIDs,
				cashflowItemIDs,
				fundsRegisterIDs,
				isIncludesVAT,
				isIncludesPlan,
			} = options;
			const request: ReportRequest = {
				...new reportsApi.package.ReportRequest(),
				DateStart: dateRange.dateStart,
				DateFinish: dateRange.dateEnd,
				ExcludeVat: !isIncludesVAT,
				ExcludePlan: !isIncludesPlan,
				TenantLegalEntitiesIDs: buildTLongArray(tenantEntityIDs),
				ProjectIDs: buildTLongArray(projectIDs),
				BusinessUnitsIDs: buildTLongArray(businessUnitIDs),
				AccountsChartItemIDs: buildTLongArray(cashflowItemIDs),
				FundsRegisterIDs: buildTLongArray(fundsRegisterIDs),
			};

			return new Promise<ReportResponse>(resolve => {
				reportsApi.reports.client.getProfitLossReport(request, result => {
					resolve(result);
				});
			});
		},
		fetchBalanceReport: (options: FetchBalanceReportOptions) => {
			const { date, tenantLegalEntitiesIDs } = options;
			const request: BalanceReportRequest = {
				...new reportsApi.package.BalanceReportRequest(),
				ValueDate: date,
				TenantLegalEntitiesIDs: buildTLongArray(tenantLegalEntitiesIDs),
			};
			return new Promise<BalanceReportResponse>(resolve => {
				reportsApi.reports.client.getBalanceReport(request, result => {
					resolve(result);
				});
			});
		},
		fetchDashboard: (request: DashboardRequest) => {
			return new Promise<DashboardResponse>(resolve => {
				reportsApi.reports.client.getDashboard(request, result => {
					resolve(result);
				});
			});
		},
	},
};

export type FetchReportOptions = {
	dateRange: DateRange;
	tenantEntityIDs: Array<number>;
	projectIDs: Array<number>;
	businessUnitIDs: Array<number>;
	cashflowItemIDs?: Array<number>;
	fundsRegisterIDs?: Array<number>;
	isIncludesVAT: boolean;
	isIncludesPlan: boolean;
};

export type FetchBalanceReportOptions = {
	date: string;
	tenantLegalEntitiesIDs: Array<number>;
};

export { reportsApi };
