import React from 'react';

import { IconBase, IconBaseProps } from './base';

export type UnitReportingIconProps = IconBaseProps;

const UnitReportingIcon: React.FC<UnitReportingIconProps> = props => {
	return (
		<IconBase {...props}>
			<svg stroke='currentColor' fill='none' strokeWidth='0' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M11 9V13.1707C9.83481 13.5825 9 14.6938 9 16C9 17.6569 10.3431 19 12 19C13.6569 19 15 17.6569 15 16C15 14.6938 14.1652 13.5825 13 13.1707V9H11ZM11 16C11 15.4477 11.4477 15 12 15C12.5523 15 13 15.4477 13 16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16Z'
					fill='currentColor'></path>
				<path
					d='M12 5C15.866 5 19 8.13401 19 12V13H17V12C17 9.23858 14.7614 7 12 7C9.23858 7 7 9.23858 7 12V13H5V12C5 8.13401 8.13401 5 12 5Z'
					fill='currentColor'></path>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23ZM12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z'
					fill='currentColor'></path>
			</svg>
		</IconBase>
	);
};

export { UnitReportingIcon };
