import React, { useEffect, useRef } from 'react';

export type ClickOutsideTrackerProps = {
	children: React.ReactNode;
	onClickOutside: () => void;
};

const ClickOutsideTracker: React.FC<ClickOutsideTrackerProps> = props => {
	const { children, onClickOutside } = props;
	const rootRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const handleEvent = (e: MouseEvent) => {
			if (!rootRef.current) return;
			if (!rootRef.current.contains(e.target as HTMLDivElement)) {
				onClickOutside();
			}
		};
		document.addEventListener('click', handleEvent);

		return () => document.removeEventListener('click', handleEvent);
	}, [onClickOutside]);

	return <div ref={rootRef}>{children}</div>;
};

export { ClickOutsideTracker };
