import styled, { css, keyframes, SimpleInterpolation } from 'styled-components';

import { TooltipProps } from './tooltip';

const ANIMATION_DURATION = 200;
const ARROW_SIZE = 14;

const createOpenMotion = (position: RootProps['position']) => {
	const map: Record<RootProps['position'], () => SimpleInterpolation> = {
		top: () => keyframes`
			0% {
				opacity: 0;
				transform: translateY(-20px);
			}
			100% {
				opacity: 1;
				transform: translateY(0);
			}
		`,
		right: () => keyframes`
			0% {
				opacity: 0;
				transform: translateX(20px);
			}
			100% {
				opacity: 1;
				transform: translateX(0);
			}
		`,
		left: () => keyframes`
			0% {
				opacity: 0;
				transform: translateX(-20px);
			}
			100% {
				opacity: 1;
				transform: translateX(0);
			}
		`,
		bottom: () => keyframes`
			0% {
				opacity: 0;
				transform: translateY(20px);
			}
			100% {
				opacity: 1;
				transform: translateY(0);
			}
		`,
	};

	return map[position] ? map[position]() : '';
};

const closeMotion = keyframes`
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
`;

type RootProps = {
	parentRect: DOMRect;
	contentRect: DOMRect;
	zIndex: number;
} & Pick<TooltipProps, 'appearance' | 'position'>;

const Root = styled.div<RootProps>`
	position: fixed;
	z-index: 10000;

	${p =>
		p.zIndex !== 0 &&
		css`
			z-index: ${p.zIndex};
		`}

	${p =>
		p.parentRect &&
		p.contentRect &&
		getPositionStyle({
			position: p.position,
			parentRect: p.parentRect,
			contentRect: p.contentRect,
			shift: ARROW_SIZE,
		})}
`;

type ContentProps = {
	parentRect: DOMRect;
} & Pick<TooltipProps, 'isOpen' | 'appearance' | 'position'>;

const Content = styled.div<ContentProps>`
	position: relative;
	max-width: 300px;
	padding: 12px;
	border-radius: 2px;

	${p => css`
		animation: ${p.isOpen ? createOpenMotion(p.position) : closeMotion};
		animation-duration: ${ANIMATION_DURATION}ms;
		animation-timing-function: ease-in-out;
		animation-iteration-count: 1;
		animation-fill-mode: forwards;
		${p.theme.tooltip.$boxShadow || p.theme.fn.createBoxShadow(3)};
	`}

	${p => css`
		background-color: ${p.theme.tooltip.backgroundColor};
		color: ${p.theme.tooltip.textColor};
		font-size: ${p.theme.fn.pxToRem(13)};
	`}

	${p => p.appearance === 'default' && getArrowStyle(p.position)}
`;

type GetPositionStyleOptions = {
	position: RootProps['position'];
	parentRect: RootProps['parentRect'];
	contentRect: DOMRect;
	shift: number;
};

const getPositionStyle = (options: GetPositionStyleOptions) => {
	const { position, parentRect, contentRect, shift } = options;
	const map: Record<RootProps['position'], () => SimpleInterpolation> = {
		top: () => css`
			top: ${parentRect.top - contentRect.height - shift}px;
			left: ${parentRect.left + parentRect.width / 2 - contentRect.width / 2}px;
		`,
		right: () => css`
			top: ${parentRect.top + parentRect.height / 2 - contentRect.height / 2}px;
			left: ${parentRect.left + parentRect.width + shift}px;
		`,
		left: () => css`
			top: ${parentRect.top + parentRect.height / 2 - contentRect.height / 2}px;
			left: ${parentRect.left - contentRect.width - shift}px;
		`,
		bottom: () => css`
			top: ${parentRect.top + parentRect.height + shift}px;
			left: ${parentRect.left + parentRect.width / 2 - contentRect.width / 2}px;
		`,
	};

	return map[position] ? map[position]() : '';
};

const getArrowStyle = (position: RootProps['position']) => {
	const map: Record<RootProps['position'], () => string> = {
		top: () => `
			bottom: 0;
			left: 50%;
			transform: translate(-50%, 50%) rotate(-45deg) skew(-15deg, -15deg);
		`,
		right: () => `
			top: 50%;
			left: 0;
			transform: translate(-50%, -50%) rotate(45deg) skew(-15deg, -15deg);
		`,
		left: () => `
			top: 50%;
			right: 0;
			transform: translate(50%, -50%) rotate(45deg) skew(-15deg, -15deg);
		`,
		bottom: () => `
			top: 0;
			left: 50%;
			transform: translate(-50%, -50%) rotate(-45deg) skew(-15deg, -15deg);
		`,
	};
	const base = css`
		&:after {
			content: '';
			position: absolute;
			width: ${ARROW_SIZE}px;
			height: ${ARROW_SIZE}px;
			background-color: inherit;
			${map[position] ? map[position]() : ''}
		}
	`;

	return base;
};

const CloseButtonLayout = styled.div`
	position: absolute;
	top: 3px;
	right: 3px;
	font-size: 0;
`;

const CloseButton = styled.button`
	border: 0;
	background-color: transparent;
	padding: 0;
	margin: 0;
	cursor: pointer;
`;

export { ANIMATION_DURATION, Root, Content, CloseButtonLayout, CloseButton };
