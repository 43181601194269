import styled from 'styled-components';


const DefaultContentRoot = styled.div`
	padding: 30px 40px;
`;

export {
	DefaultContentRoot,
}
