import styled, { css } from 'styled-components';

type HighlightProps = {
	highlight: boolean;
	highlightColor: string;
};

const Highlight = styled.span<HighlightProps>`
	${p =>
		p.highlight &&
		css`
			color: ${p.highlightColor || p.theme.palette.accent};
		`}
`;

export { Highlight };
