import React from 'react';

import { FlatTabsRoot, FlatTabRoot, FlatTabsHeaderLayout, FlatTabsContentLayout, FlatTabsButton } from './styled';

interface IFlatTabsProps {
	selectedValue: number;
	maxContentHeight?: string;
	withoutHeader?: boolean;
	children: React.ReactNode;
	onChange?: (value: number) => void;
}

interface IFlatTabProps {
	label?: string | React.ReactElement<any>;
	isActive?: boolean;
	value?: number;
	children: React.ReactNode;
	onActive?: (value: number) => void;
}

class FlatTabs extends React.Component<IFlatTabsProps> {
	labels: Array<string | React.ReactElement<any>> = [];

	componentDidMount() {
		const { selectedValue } = this.props;

		this.emitOnChange(selectedValue);
	}

	componentDidUpdate(prevProps: IFlatTabsProps) {
		if (prevProps.selectedValue !== this.props.selectedValue) {
			this.emitOnChange(this.props.selectedValue);
		}
	}

	getLabels = children => {
		React.Children.map(children, (child: React.ReactElement<any>, index) => {
			this.labels.push(child.props.label);
		});
	};

	willMount = (() => {
		this.getLabels(this.props.children);
	})();

	emitOnChange = (value: number) => {
		const { selectedValue, onChange } = this.props;

		if (selectedValue !== value) {
			onChange && onChange(value);
		}
	};

	handleClick = (index: number) => ev => {
		this.emitOnChange(index);
		ev.stopPropagation();
	};

	renderChildren() {
		const { children, selectedValue } = this.props;

		if (children) {
			return React.Children.map(children, (child: React.ReactElement<any>, index) => {
				return React.cloneElement(
					child,
					Object.assign({}, { value: index }, index === selectedValue && { isActive: true }),
				);
			});
		}

		return null;
	}

	renderHeader = () => {
		const { selectedValue } = this.props;

		return this.labels.map((label, index) => {
			return (
				<FlatTabsButton onClick={this.handleClick(index)} key={index} isActive={index === selectedValue}>
					{label}
				</FlatTabsButton>
			);
		});
	};

	render() {
		const { maxContentHeight, withoutHeader } = this.props;

		return (
			<FlatTabsRoot>
				{!withoutHeader && <FlatTabsHeaderLayout>{this.renderHeader()}</FlatTabsHeaderLayout>}
				<FlatTabsContentLayout maxContentHeight={maxContentHeight} withoutHeader={withoutHeader}>
					{this.renderChildren()}
				</FlatTabsContentLayout>
			</FlatTabsRoot>
		);
	}
}

class FlatTab extends React.Component<IFlatTabProps, any> {
	componentDidUpdate(prevProps: IFlatTabProps) {
		if (this.props.isActive && !prevProps.isActive) {
			this.props.onActive && this.props.onActive(this.props.value);
		}
	}

	render() {
		const { value, isActive, children } = this.props;

		return (
			<FlatTabRoot value={value} isActive={isActive}>
				{children}
			</FlatTabRoot>
		);
	}
}

export { FlatTabs, FlatTab };
