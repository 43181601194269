import React from 'react';

import { Typography } from '@ui/typography';
import { CloseButton } from '@ui/button/close-button';
import { HeaderRoot, HeaderContentLayout, HeaderCloseLayout } from './styled';

export type WideHeaderProps = {
	children: React.ReactNode;
	onClose: () => void;
};

const WideHeader: React.FC<WideHeaderProps> = ({ onClose, children }) => {
	return (
		<HeaderRoot>
			<HeaderContentLayout>{children}</HeaderContentLayout>
			<HeaderCloseLayout>
				<CloseButton squareMode withDrawer onClick={onClose} />
			</HeaderCloseLayout>
		</HeaderRoot>
	);
};

export type DefaultTitleProps = {
	children: React.ReactNode;
} & WideHeaderProps;

const WideTitle: React.FC<DefaultTitleProps> = ({ onClose, children }) => {
	return (
		<WideHeader onClose={onClose}>
			<Typography.Title fontSize={18}>{children}</Typography.Title>
		</WideHeader>
	);
};

export { WideHeader, WideTitle };
