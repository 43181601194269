/***
 * Library contains JavaScript API for interaction with Seeneco Framework Web Services contained in CashflowlimitPackage
 * @author akotov Generated by Seeneco Framework Modeler 
 *
 */
(function(w){
  /***
  * 
  */
  w.CashflowLimitCreateRequest = function() {
    this.PACKAGE = "CASHFLOWLIMIT";
    this.CLASSIFIER = "CASHFLOW_LIMIT_CREATE_REQUEST";
    this.CopySupportingElement = false;
    this.ID = -1;
    this.LimitType = "";
    this.LegalEntityID = -1;
    this.AccountChartItemID = -1;
    this.PeriodType = "";
    this.PeriodID = -1;
    this.Value = -1;
    this.ProjectID = -1;
    this.PlannedOperationsIDs = [];
    this.FromPlannedOperations = false;
  };
  /***
  * 
  */
  w.CashflowLimitsCol = function() {
    this.PACKAGE = "CASHFLOWLIMIT";
    this.CLASSIFIER = "CASHFLOW_LIMITS_COL";
    this.CopySupportingElement = false;
    this.FactAmount = -1;
    this.PlanAmount = -1;
    this.DiffAmount = -1;
    this.Code = "";
    this.CashflowLimitID = -1;
  };
  /***
  * 
  */
  w.CashflowLimitsFromPlanCreateRequest = function() {
    this.PACKAGE = "CASHFLOWLIMIT";
    this.CLASSIFIER = "CASHFLOW_LIMITS_FROM_PLAN_CREATE_REQUEST";
    this.CopySupportingElement = false;
    this.LegalEntityID = -1;
    this.PeriodTypes = [];
    this.ProjectID = -1;
    this.OverwriteExistingLimits = false;
    this.PeriodDateStart = null;
    this.PeriodDateEnd = null;
  };
  /***
  * 
  */
  w.CashflowLimitsItem = function() {
    this.PACKAGE = "CASHFLOWLIMIT";
    this.CLASSIFIER = "CASHFLOW_LIMITS_ITEM";
    this.CopySupportingElement = false;
    this.ID = -1;
    this.Name = "";
    this.ParentID = -1;
    this.Cols = [];
    this.ChildItemIDs = "";
    this.Incoming = false;
    this.DetailByID = "";
  };
  /***
  * 
  */
  w.CashflowLimitsItemsRequest = function() {
    this.PACKAGE = "CASHFLOWLIMIT";
    this.CLASSIFIER = "CASHFLOW_LIMITS_ITEMS_REQUEST";
    this.CopySupportingElement = false;
    this.DateStart = null;
    this.DateFinish = null;
    this.GroupingType = "";
    this.LegalEntitiesIDs = [];
    this.ProjectsIDs = [];
    this.BusinessUnitIDs = [];
  };
  /***
  * 
  */
  w.CashflowLimitsItemsResponse = function() {
    this.PACKAGE = "CASHFLOWLIMIT";
    this.CLASSIFIER = "CASHFLOW_LIMITS_ITEMS_RESPONSE";
    this.CopySupportingElement = false;
    this.Request = null;
    this.Items = [];
    this.ColsCommonAttrs = [];
  };
  /***
  * 
  */
  w.CashflowLimitUpdateRequest = function() {
    this.PACKAGE = "CASHFLOWLIMIT";
    this.CLASSIFIER = "CASHFLOW_LIMIT_UPDATE_REQUEST";
    this.CopySupportingElement = false;
    this.ID = -1;
    this.Value = -1;
  };
  /***
  * 
  */
  w.ColCommonAttrs = function() {
    this.PACKAGE = "CASHFLOWLIMIT";
    this.CLASSIFIER = "COL_COMMON_ATTRS";
    this.CopySupportingElement = false;
    this.Code = "";
    this.DateStart = null;
    this.DateFinish = null;
    this.PeriodID = -1;
  };
   
   
  
  /***
   * Client API to CashflowLimitServiceWS web service
   */
  w.CashflowLimitServiceClient = function() {
    /***
     * System name of target service
     */
    this.serviceName = "CashflowLimitServiceWSImpl";
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param request [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CashflowLimitServiceClient.prototype.getCashflowLimitsItems = function(request, callbackHandler) {
    var parameterNames = ["request"];
    var requestJSON = JSON.stringify(request);
    var parameterValues = [requestJSON];
    var soapMessage = buildSOAPMessage("getCashflowLimitsItems", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param request [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CashflowLimitServiceClient.prototype.createCashflowLimit = function(request, callbackHandler) {
    var parameterNames = ["request"];
    var requestJSON = JSON.stringify(request);
    var parameterValues = [requestJSON];
    var soapMessage = buildSOAPMessage("createCashflowLimit", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param request [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CashflowLimitServiceClient.prototype.updateCashflowLimit = function(request, callbackHandler) {
    var parameterNames = ["request"];
    var requestJSON = JSON.stringify(request);
    var parameterValues = [requestJSON];
    var soapMessage = buildSOAPMessage("updateCashflowLimit", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param request [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.CashflowLimitServiceClient.prototype.createCashflowLimitsFromPlan = function(request, callbackHandler) {
    var parameterNames = ["request"];
    var requestJSON = JSON.stringify(request);
    var parameterValues = [requestJSON];
    var soapMessage = buildSOAPMessage("createCashflowLimitsFromPlan", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  
  /***
   * Package security rights constants
   */
  
  w.CashflowlimitSecurityRights = {
  
      X_CASHFLOW_LIMITS__CHANGE_CASHFLOW_LIMITS:new SecurityRightCheckHandle('d6ebdc0d-be83-43cf-8f06-ba2b7fd71119'),
      X_CASHFLOW_LIMITS__VIEW_CASHFLOW_LIMITS:new SecurityRightCheckHandle('3b80b2fb-bb82-4f8f-a5bb-672c36c3e411')
  };
  })(typeof exports !== 'undefined' ? exports : window);
  