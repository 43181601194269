import styled, { css } from 'styled-components';

import { lighten } from '@utils/color';

const Root = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	flex-flow: column nowrap;
	justify-content: space-between;

	${p => css`
		background-color: ${lighten(p.theme.palette.canvas, 0.02)};
	`}
`;

type HeaderLayoutProps = {
	appearance: 'default' | 'mini';
};

const HeaderLayout = styled.div<HeaderLayoutProps>`
	flex: 0 0 auto;
	position: relative;
	width: 100%;
	min-height: 100px;
	display: flex;
	border-bottom: 1px solid transparent;

	${p => css`
		border-color: ${p.theme.palette.stealth};
	`}

	${p =>
		p.appearance === 'mini' &&
		css`
			min-height: 71px;
		`}
`;

const ContentLayout = styled.div.attrs({
	id: 'drawer-content',
})`
	flex: 1 1 auto;
	position: relative;
	width: 100%;
	overflow-y: auto;
`;

type FooterLayoutProps = {
	appearance: 'default' | 'mini';
};

const FooterLayout = styled.div<FooterLayoutProps>`
	flex: 0 0 auto;
	position: relative;
	width: 100%;
	min-height: 90px;
	display: flex;
	border-top: 1px solid transparent;

	${p => css`
		border-color: ${p.theme.palette.stealth};
	`}

	${p =>
		p.appearance === 'mini' &&
		css`
			min-height: 50px;
		`}
`;

const DefaultDrawer = {
	Root,
	HeaderLayout,
	ContentLayout,
	FooterLayout,
};

export { DefaultDrawer };
