import React, { useEffect, useRef, forwardRef } from 'react';

import { accountingApi } from '@core/api/accounting';
import { useFormVariant } from '@core/hooks';
import { Box } from '@ui/box';
import { Typography } from '@ui/typography';
import { useFormContext } from '@ui/forms';
import { AutopickerCRUD, AutopickerCrudProps, AutopickerCrudRef } from '@ui/autopicker';
import { FormInputField as FormTextField, Form, FormRef, FormInputField, FormRadioButtonGroup } from '@ui/forms';
import { cashflowTypeAutopickerTransformer, FormCashflowTypeAutopicker } from '../cashflow-type-autopicker';
import { cashflowItemAutopickerTransformer, FormCashflowItemAutopicker } from '../cashflow-item-autopicker';

export type CashflowItemAutopickerCrudProps = {
	isFetching: boolean;
	variant: 'add' | 'nested' | 'edit' | 'remove';
	initialFormObject: CashflowItem;
	cashflowItemID: number;
	cashflowItemName: string;
	disabled: boolean;
	codeValidator: {
		fn: ({ formObject }: { formObject: CashflowItem }) => boolean;
		text: string;
	};
	cashflowItemsMap: Record<number, CashflowItem>;
	onAddPopupOpen: () => void;
	onEditPopupOpen: () => void;
	onRemovePopupOpen: () => void;
	onNestedAddPopupOpen: () => void;
	onRequestAddCashflowItem: (cashflowItem: CashflowItem) => void;
	onRequestEditCashflowItem: (cashflowItem: CashflowItem) => void;
	onRequestRemoveCashflowItem: (cashflowItemID: number) => void;
} & Pick<AutopickerCrudProps, 'isSmallContainer' | 'onRelatedPopupChange'>;

const CashflowItemAutopickerCRUD: React.FC<CashflowItemAutopickerCrudProps> = props => {
	const {
		isFetching,
		variant,
		initialFormObject,
		cashflowItemID,
		cashflowItemName,
		disabled,
		isSmallContainer,
		codeValidator,
		cashflowItemsMap,
		onAddPopupOpen,
		onEditPopupOpen,
		onRemovePopupOpen,
		onNestedAddPopupOpen,
		onRequestAddCashflowItem,
		onRequestEditCashflowItem,
		onRelatedPopupChange,
		onRequestRemoveCashflowItem,
	} = props;
	const { isAdd, isAddNested, isEdit } = useFormVariant(variant);
	const formRef = useRef<FormRef<CashflowItem>>(null);
	const addCrudRef = useRef<AutopickerCrudRef>(null);
	const editCrudRef = useRef<AutopickerCrudRef>(null);
	const removeCrudRef = useRef<AutopickerCrudRef>(null);
	const isSystem = cashflowItemsMap[cashflowItemID]?.System;

	useEffect(() => {
		if (!formRef.current || isFetching) return;
		const formObject = formRef.current.getFormObject();
		const newFormObject: CashflowItem = new accountingApi.package.CashflowItem();

		newFormObject.Name = formObject.Name;
		formRef.current.setFormObject(newFormObject);
	}, []);

	useEffect(() => {
		if (!formRef.current || !initialFormObject) return;
		const formObject = formRef.current.getFormObject();

		if (formObject === initialFormObject) return;

		formRef.current.setFormObject(initialFormObject);
	}, [initialFormObject]);

	const handleRequestSubmit = () => formRef.current?.submitForm();

	const handleSubmit = (formObject: CashflowItem) => {
		if (isAdd || isAddNested) {
			onRequestAddCashflowItem(formObject);
			addCrudRef.current.closePopup();
		} else if (isEdit) {
			onRequestEditCashflowItem(formObject);
			editCrudRef.current.closePopup();
		}
	};

	const handleRequestRemove = () => {
		onRequestRemoveCashflowItem(cashflowItemID);
		removeCrudRef.current.closePopup();
	};

	const renderContent = () => {
		return (
			<Form ref={formRef as any} formObject={initialFormObject} fullWidth onSubmit={handleSubmit}>
				<UpdateCashflowItemForm
					isFetching={isFetching}
					variant={variant}
					codeValidator={codeValidator}
					cashflowItemID={cashflowItemID}
					cashflowItemsMap={cashflowItemsMap}
				/>
			</Form>
		);
	};

	const renderRemoveContent = () => {
		return (
			<Box>
				<Typography.Text fontWeight={500} lineHeight={1.6}>
					Вы уверены, что хотите удалить статью «{cashflowItemName}»?
				</Typography.Text>
			</Box>
		);
	};

	const renderMap: Record<CashflowItemAutopickerCrudProps['variant'], () => React.ReactNode> = {
		add: () => {
			return (
				<AutopickerCRUD
					ref={addCrudRef}
					appearance='add'
					triggerLabel='Добавить статью'
					isFetching={isFetching}
					disabled={disabled}
					isSmallContainer={isSmallContainer}
					onPopupOpen={onAddPopupOpen}
					onRelatedPopupChange={onRelatedPopupChange}
					onRequestAction={handleRequestSubmit}>
					{renderContent()}
				</AutopickerCRUD>
			);
		},
		edit: () => {
			return (
				<AutopickerCRUD
					ref={editCrudRef}
					appearance='edit'
					disabled={disabled || isSystem}
					isFetching={isFetching}
					onPopupOpen={onEditPopupOpen}
					onRelatedPopupChange={onRelatedPopupChange}
					onRequestAction={handleRequestSubmit}>
					{renderContent()}
				</AutopickerCRUD>
			);
		},
		remove: () => {
			return (
				<AutopickerCRUD
					ref={removeCrudRef}
					appearance='remove'
					disabled={disabled || isSystem}
					isFetching={isFetching}
					autoFocus
					onPopupOpen={onRemovePopupOpen}
					onRelatedPopupChange={onRelatedPopupChange}
					onRequestAction={handleRequestRemove}>
					{renderRemoveContent()}
				</AutopickerCRUD>
			);
		},
		nested: () => {
			return (
				<AutopickerCRUD
					ref={addCrudRef}
					appearance='nested'
					isFetching={isFetching}
					disabled={disabled}
					isSmallContainer={isSmallContainer}
					onPopupOpen={onNestedAddPopupOpen}
					onRelatedPopupChange={onRelatedPopupChange}
					onRequestAction={handleRequestSubmit}>
					{renderContent()}
				</AutopickerCRUD>
			);
		},
	};

	return renderMap[variant] ? <>{renderMap[variant]()}</> : null;
};

type UpdateCashflowItemFormProps = {} & Pick<
	CashflowItemAutopickerCrudProps,
	'isFetching' | 'variant' | 'codeValidator' | 'cashflowItemID' | 'cashflowItemsMap'
>;

const UpdateCashflowItemForm: React.FC<UpdateCashflowItemFormProps> = forwardRef((props, ref) => {
	const { isFetching, variant, codeValidator, cashflowItemID, cashflowItemsMap } = props;
	const { isAdd, isAddNested, isEdit } = useFormVariant(variant);
	const formContext = useFormContext<CashflowItem>();
	const cashflowItem = formContext.formObject;
	if (
		cashflowItem.ParentID > -1 &&
		cashflowItemsMap[cashflowItem.ParentID]?.CashflowTypeID !== cashflowItem.CashflowTypeID
	) {
		cashflowItem.CashflowTypeID = cashflowItemsMap[cashflowItem.ParentID].CashflowTypeID;
	}
	const isCashflowTypeDisabled =
		cashflowItem?.ParentID >= 0 &&
		cashflowItemsMap[cashflowItem.ParentID]?.CashflowTypeID === cashflowItem?.CashflowTypeID;
	const titleText = isAdd
		? 'Быстрое добавление новой статьи'
		: isAddNested
		? 'Быстрое добавление дочерней статьи'
		: 'Быстрое изменение статьи';

	const handleChangeDirection = () => {
		cashflowItem.ParentID = -1;
	};

	if (isFetching) return <Box minHeight={426} />;

	return (
		<Box minHeight={400}>
			<Box marginBottom={20}>
				<Typography.Text fontWeight={500} fontSize={16}>
					{titleText}
				</Typography.Text>
			</Box>
			<FormTextField
				name='Name'
				hintText={`Например, «${cashflowItem.Incoming ? 'Продажи' : 'Маркетинг'}»`}
				labelText='Наименование статьи'
				autoFocus
				withClearBtn
				required
				fullWidth
			/>
			<FormRadioButtonGroup
				name='Incoming'
				labelText='Направление'
				dataSource={directionDataSource}
				direction='row'
				disabled={isEdit}
				onChange={handleChangeDirection}
			/>
			<FormInputField
				name='Code'
				labelText='Код статьи'
				hintText='0043'
				helpMark='Нужен для корректной загрузки платежей из Excel'
				errorValidations={[codeValidator.fn]}
				errorMsgs={[codeValidator.text]}
				withClearBtn
				fullWidth
			/>
			<FormCashflowItemAutopicker
				name='ParentID'
				labelText='Родительская статья'
				hintText='Выберите родительскую статью'
				isIncome={cashflowItem.Incoming}
				excludeID={isAddNested ? -1 : cashflowItemID || -1}
				disableAdd
				disableEdit
				disableRemove
				fullWidth
				{...cashflowItemAutopickerTransformer.ID.single}
			/>
			<FormCashflowTypeAutopicker
				name='CashflowTypeID'
				fullWidth
				required
				disabled={isCashflowTypeDisabled}
				{...cashflowTypeAutopickerTransformer.number.single}
			/>
		</Box>
	);
});

const directionDataSource = [
	{ ID: true, Name: 'Доход' },
	{ ID: false, Name: 'Расход' },
];

export { CashflowItemAutopickerCRUD };
