import { createStaticDataAction } from '@libs/action-creator';
import { SetAppProgressBarOptions } from '../models';

const BLOCK_SHELL_RERENDER = '[SHARED]: BLOCK_SHELL_RERENDER';
const DETAIL_PANEL_VISIBILITY_CONTROL = '[SHARED]: DETAIL_PANEL_VISIBILITY_CONTROL';
const CONTROL_DETAIL_PANEL_CLICK_OUTSIDE = '[SHARED]: CONTROL_DETAIL_PANEL_CLICK_OUTSIDE';
const SET_APP_PROGRESS_BAR_OPTIONS = '[SHARED]: SET_APP_PROGRESS_BAR_OPTIONS';


function blockShellRerenderAction(block: boolean) {
	return createStaticDataAction(BLOCK_SHELL_RERENDER)(block);
}

function detailPanelVisibilityControlAction(visible: boolean) {
	return createStaticDataAction(DETAIL_PANEL_VISIBILITY_CONTROL)(visible);
}

function controlDetailPanelClickOutsideAction(outside: boolean) {
	return createStaticDataAction(CONTROL_DETAIL_PANEL_CLICK_OUTSIDE)(outside);
}

function setAppProgressBarOptionsAction(options: SetAppProgressBarOptions) {
	return createStaticDataAction(SET_APP_PROGRESS_BAR_OPTIONS)(options);
}

export {
	BLOCK_SHELL_RERENDER,
	DETAIL_PANEL_VISIBILITY_CONTROL,
	CONTROL_DETAIL_PANEL_CLICK_OUTSIDE,
	SET_APP_PROGRESS_BAR_OPTIONS,
	blockShellRerenderAction,
	detailPanelVisibilityControlAction,
	controlDetailPanelClickOutsideAction,
	setAppProgressBarOptionsAction,
};
