import React from 'react';

import { BoxProps } from '@ui/box';
import { Root } from './styled';

export type LinkProps = {
	appearance?: 'default' | 'muted' | 'white' | 'black' | 'dashed';
	to?: string;
	underline?: boolean;
} & BoxProps &
	React.AnchorHTMLAttributes<{}>;

const Link: React.FC<LinkProps> = props => {
	return <Root {...props} />;
};

Link.defaultProps = {
	component: 'a',
	appearance: 'default',
};

export { Link };
