import styled from 'styled-components';

import type { SpacerProps } from './spacer';
import { getMeasureProp } from '@stylesheet/utils';

const Root = styled.div`
	display: inline-block;
	flex: 0 0 auto;

	${(p: SpacerProps) =>
		p.size &&
		`
		width: ${getMeasureProp(p.size)};
		height: ${getMeasureProp(p.size)};
	`}
`;

export { Root };
