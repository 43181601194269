import * as counterpartypackage from 'counterpartypackage-api';
import * as organizationpackage from 'organizationpackage-api';
import { buildTLongArray } from '@utils';
import { hasKeys } from '@utils/object';
import { detectIsFunction } from '@utils/helpers';
import { fromStringToBoolean } from '@utils/serializer';
import { detectIsLegalEntity, detectIsEmployee } from '@utils/counterparty';
import { employeeBusinessRoleIDsMap, EmployeeBusinessRoleCode } from '../organization';
import { fileApi } from '../file';

const counterpartyApi = {
	package: counterpartypackage,
	counterparty: {
		client: new counterpartypackage.CounterpartyServiceClient(),
		fetchCounterpartyBriefList: (options: FetchCounterpartyBriefListOptions) => {
			return new Promise<Array<CounterpartyBrief>>(resolve => {
				const {
					term = '',
					selectLegalEntities = false,
					selectTenantLegalEntities = false,
					selectEmployees = false,
					selectNaturalPersons = false,
					selectSoleProprietors = false,
					legalEntityTypeIDs = [],
					groupIDs = [],
					clientManagerIDs = [],
					count,
				} = options;
				const request: CounterpartyFilter = {
					...new counterpartyApi.package.CounterpartyFilter(),
					QueryString: term,
					SelectLegalEntities: selectLegalEntities,
					SelectTenantLegalEntities: selectTenantLegalEntities,
					SelectEmployees: selectEmployees,
					SelectNaturalPersons: selectNaturalPersons,
					SelectSoleProprietors: selectSoleProprietors,
					LegalEntityTypeIDList: buildTLongArray(legalEntityTypeIDs),
					CounterpartyGroupIDList: buildTLongArray(groupIDs),
					ClientManagerIDList: buildTLongArray(clientManagerIDs),
					Count: count,
				};

				counterpartyApi.counterparty.client.getCounterpartyBriefListByFilter(request, result => {
					resolve(result);
				});
			});
		},
		fetchCounterpartyByID: (ID: number) => {
			return new Promise<Counterparty | LegalEntity | Employee | NaturalPerson>(resolve => {
				counterpartyApi.counterparty.client.getCounterpartyByID(ID, result => {
					resolve(result);
				});
			});
		},
		fetchCounterpartyByTaxCode: (taxCode: string) => {
			return new Promise<Counterparty | LegalEntity>(resolve => {
				counterpartyApi.counterparty.client.getCounterpartyByCodeOrTaxCode(taxCode, result => {
					resolve(result);
				});
			});
		},
		checkIsUniqueTaxCode: (taxCode: string, counterpartyID: number) => {
			type ReturnType = {
				isUnique: boolean;
				counterparty: Counterparty;
			};
			return new Promise<ReturnType>(resolve => {
				counterpartyApi.counterparty.fetchCounterpartyByTaxCode(taxCode).then(counterparty => {
					const isUnique = counterparty.ID === counterpartyID || !counterparty || !hasKeys(counterparty);

					resolve({
						isUnique,
						counterparty,
					});
				});
			});
		},
		addCounterparty: (counterparty: Counterparty) => {
			return new Promise<Counterparty>(async resolve => {
				if (counterparty.CLASSIFIER === 'EMPLOYEE' || counterparty.CLASSIFIER === 'NATURAL_PERSON') {
					const person = counterparty as NaturalPerson;

					person.BirthDate = person.BirthDate || '01-01-1900 00:00:00';
				}

				if (detectIsLegalEntity(counterparty)) {
					if (typeof counterparty.CompanySealImageURL === 'object') {
						const url = await fileApi.uploadFile(counterparty.CompanySealImageURL, 'company_seal_image');

						counterparty.CompanySealImageURL = url;
					}
				}

				if (detectIsEmployee(counterparty)) {
					if (typeof counterparty.SignImageURL === 'object') {
						const url = await fileApi.uploadFile(counterparty.SignImageURL, 'sign_image');

						counterparty.SignImageURL = url;
					}
				}

				if (typeof counterparty.LogoURL === 'object') {
					const url = await fileApi.uploadFile(counterparty.LogoURL, 'logo');

					counterparty.LogoURL = url;
				}

				counterpartyApi.counterparty.client.addCounterparty(counterparty, result => {
					resolve(result);
				});
			});
		},
		updateCounterparty: (counterparty: Counterparty | LegalEntity | Employee | NaturalPerson) => {
			return new Promise<Counterparty | LegalEntity | Employee>(async resolve => {
				const sourceCounterparty = await counterpartyApi.counterparty.fetchCounterpartyByID(counterparty.ID);
				const mergedCounterparty = {
					...sourceCounterparty,
					...counterparty,
				};

				if (detectIsLegalEntity(mergedCounterparty)) {
					if (typeof mergedCounterparty.CompanySealImageURL === 'object') {
						const url = await fileApi.uploadFile(mergedCounterparty.CompanySealImageURL, 'company_seal_image');

						mergedCounterparty.CompanySealImageURL = url;
					}
				}

				if (detectIsEmployee(mergedCounterparty)) {
					if (typeof mergedCounterparty.SignImageURL === 'object') {
						const url = await fileApi.uploadFile(mergedCounterparty.SignImageURL, 'sign_image');

						mergedCounterparty.SignImageURL = url;
					}
				}

				if (typeof mergedCounterparty.LogoURL === 'object') {
					const url = await fileApi.uploadFile(mergedCounterparty.LogoURL, 'logo');

					mergedCounterparty.LogoURL = url;
				}

				counterpartyApi.counterparty.client.changeCounterparty(mergedCounterparty, result => {
					resolve(result);
				});
			});
		},
		transformTenantLegalEntityToCounterparty: (legalEntityID: number) => {
			return new Promise<boolean>(async resolve => {
				const [item] = await counterpartyApi.counterparty.checkTenantEntityCanBeRemoved([legalEntityID]);

				if (item.CanBeRemoved) {
					const legalEntity = (await counterpartyApi.counterparty.fetchCounterpartyByID(legalEntityID)) as LegalEntity;

					legalEntity.TenantLegalEntity = false;

					await counterpartyApi.counterparty.updateCounterparty(legalEntity);

					resolve(true);
				} else {
					resolve(false);
				}
			});
		},
		transformCounterpartyToTenantLegalEntity: (legalEntityID: number) => {
			return new Promise<boolean>(async resolve => {
				const legalEntity = (await counterpartyApi.counterparty.fetchCounterpartyByID(legalEntityID)) as LegalEntity;

				if (detectIsLegalEntity(legalEntity)) {
					legalEntity.TenantLegalEntity = true;

					await counterpartyApi.counterparty.updateCounterparty(legalEntity);

					resolve(true);
				} else {
					resolve(false);
				}
			});
		},
		removeCounterparty: (counterpartyID: number) => {
			return new Promise<boolean>(resolve => {
				counterpartyApi.counterparty.client.removeCounterparty(counterpartyID, result => {
					resolve(fromStringToBoolean(result));
				});
			});
		},
		updateCounterpartyBrief: (type: 'counterparty' | 'legalEntity', brief: CounterpartyBrief) => {
			return new Promise<Counterparty | LegalEntity>(resolve => {
				const map = {
					counterparty: () => ({
						...new counterpartyApi.package.Counterparty(),
						ID: brief.ID,
						Name: brief.Name,
						TaxCode: brief.TaxCode,
					}),
					legalEntity: () => ({
						...new counterpartyApi.package.LegalEntity(),
						ID: brief.ID,
						Name: brief.Name,
						TaxCode: brief.TaxCode,
						AdditionalTaxCode: brief.AdditionalTaxCode,
						StateRegistrationCode: brief.StateRegistrationCode,
						SoleProprietor: brief.SoleProprietor,
					}),
				};
				const counterparty = map[type] ? map[type]() : map.counterparty();

				counterpartyApi.counterparty.updateCounterparty(counterparty).then(result => {
					resolve(result);
				});
			});
		},
		fetchEmployeeBriefList: () => {
			return new Promise<Array<EmployeeBrief>>(resolve => {
				return counterpartyApi.counterparty
					.fetchCounterpartyBriefList({
						selectEmployees: true,
					})
					.then(result => resolve(result as Array<EmployeeBrief>));
			});
		},
		fetchEmployeeByID: (employeeID: number) => {
			return new Promise<Employee>(resolve => {
				counterpartyApi.counterparty.fetchCounterpartyByID(employeeID).then(result => {
					resolve(result as Employee);
				});
			});
		},
		addEmployee: (employee: Employee) => {
			return new Promise<Employee>(resolve => {
				counterpartyApi.counterparty.addCounterparty(employee).then(result => {
					resolve(result as Employee);
				});
			});
		},
		updateEmployee: (employee: Employee) => {
			return new Promise<Employee>(resolve => {
				counterpartyApi.counterparty.updateCounterparty(employee).then(result => {
					resolve(result as Employee);
				});
			});
		},
		removeEmployee: (employeeID: number) => {
			return new Promise<boolean>(resolve => {
				counterpartyApi.counterparty.removeCounterparty(employeeID).then(result => {
					resolve(result);
				});
			});
		},
		fetchEmployeesByRoleCodes: (roleCodes: Array<string>) => {
			return new Promise<Array<EmployeeBrief>>(resolve => {
				counterpartyApi.counterparty.fetchEmployeeBriefList().then(employees => {
					const filteredEmployees = employees.filter(x => x.BusinessRole && roleCodes.includes(x.BusinessRole.Code));

					resolve(filteredEmployees);
				});
			});
		},
		assignRoleToEmployees: (options: AssignRoleToEmployeesOptions) => {
			const { employeeIDs, roleCode } = options;

			return new Promise<boolean>(async resolve => {
				for (const employeeID of employeeIDs) {
					const employee = await counterpartyApi.counterparty.fetchEmployeeByID(employeeID);
					const newEmployee = {
						...employee,
						MainBusinessRole: {
							...new organizationpackage.EmployeeBusinessRole(),
							ID: employeeBusinessRoleIDsMap[roleCode] || -1,
						},
					};

					await counterpartyApi.counterparty.updateEmployee(newEmployee);
				}

				resolve(true);
			});
		},
		fetchCurrentEmployee: () => {
			return new Promise<Employee>(resolve => {
				counterpartyApi.counterparty.client.getCurrentEmployee(result => {
					resolve(result);
				});
			});
		},
		fetchTenantLegalEntities: () => {
			return new Promise<Array<CounterpartyBrief>>(resolve => {
				counterpartyApi.counterparty.fetchCounterpartyBriefList({ selectTenantLegalEntities: true }).then(result => {
					resolve(result);
				});
			});
		},
		fetchCounterparties: (options: FetchCounterpartiesOptions) => {
			const { term, count, typeCodes = [], groupIDs = [], clientManagerIDs = [] } = options;
			const typeCodesMap = typeCodes.reduce((acc, x) => ((acc[x] = true), acc), {});
			const hasLegalEntities =
				typeCodes.length === 0 ||
				Boolean(typeCodesMap[CounterpartyType.CORPORATE]) ||
				Boolean(typeCodesMap[CounterpartyType.BANK]) ||
				Boolean(typeCodesMap[CounterpartyType.SOVEREIGN]);
			const hasNaturalPersons = typeCodes.length === 0 || Boolean(typeCodesMap[CounterpartyType.NATURAL_PERSON]);
			const hasSoleProprietors = Boolean(typeCodesMap[CounterpartyType.SOLE_PROPRIETOR]);
			const legalEntityTypeIDs = typeCodes
				.map(x =>
					x === CounterpartyType.CORPORATE
						? 1
						: x === CounterpartyType.BANK
						? 2
						: x === CounterpartyType.SOVEREIGN
						? 3
						: 0,
				)
				.filter(Boolean);

			return new Promise<Array<CounterpartyBrief>>(resolve => {
				counterpartyApi.counterparty
					.fetchCounterpartyBriefList({
						term,
						count,
						selectLegalEntities: hasLegalEntities,
						selectNaturalPersons: hasNaturalPersons,
						selectSoleProprietors: hasSoleProprietors,
						legalEntityTypeIDs,
						groupIDs,
						clientManagerIDs,
					})
					.then(result => {
						resolve(result);
					});
			});
		},
		fetchClients: (options: FetchClientsOptions) => counterpartyApi.counterparty.fetchCounterparties(options),
		fetchCounterpartyGroups: () => {
			return new Promise<Array<CounterpartyGroup>>(resolve => {
				counterpartyApi.counterparty.client.getCounterpartyGroupList(result => {
					resolve(result);
				});
			});
		},
		fetchCounterpartyGroupByID: (ID: number) => {
			return new Promise<CounterpartyGroup>(resolve => {
				counterpartyApi.counterparty.client.getCounterpartyGroupByID(ID, result => {
					resolve(result);
				});
			});
		},
		addCounterpartyGroup: (group: CounterpartyGroup) => {
			return new Promise<CounterpartyGroup>(resolve => {
				counterpartyApi.counterparty.client.addCounterpartyGroup(group, result => {
					resolve(result);
				});
			});
		},
		updateCounterpartyGroup: (group: CounterpartyGroup) => {
			return new Promise<CounterpartyGroup>(resolve => {
				counterpartyApi.counterparty.client.updateCounterpartyGroup(group, result => {
					resolve(result);
				});
			});
		},
		removeCounterpartyGroup: (ID: number) => {
			return new Promise<boolean>(resolve => {
				counterpartyApi.counterparty.client.removeCounterpartyGroup(ID, result => {
					resolve(fromStringToBoolean(result));
				});
			});
		},
		fetchCounterpartyAccountByID: (counterpartyID: number, counterpartyAccountID: number) => {
			return new Promise<CounterpartyAccount>(async resolve => {
				const counterparty = await counterpartyApi.counterparty.fetchCounterpartyByID(counterpartyID);
				const account = counterparty?.Accounts.find(x => x.ID === counterpartyAccountID) || null;

				resolve(account);
			});
		},
		addCounterpartyAccount: (counterpartyID: number, counterpartyAccount: CounterpartyAccount) => {
			return new Promise<CounterpartyAccount>(async resolve => {
				const counterparty = await counterpartyApi.counterparty.fetchCounterpartyByID(counterpartyID);

				counterparty.Accounts = [...counterparty.Accounts, counterpartyAccount];

				const updatedCounterparty = await counterpartyApi.counterparty.updateCounterparty(counterparty);
				const account =
					updatedCounterparty?.Accounts.find(x => x.AccountNumber === counterpartyAccount.AccountNumber) || null;

				resolve(account);
			});
		},
		updateCounterpartyAccount: (counterpartyID: number, counterpartyAccount: CounterpartyAccount) => {
			return new Promise<CounterpartyAccount>(async resolve => {
				const counterparty = await counterpartyApi.counterparty.fetchCounterpartyByID(counterpartyID);

				counterparty.Accounts = [
					...counterparty.Accounts.filter(x => x.ID !== counterpartyAccount.ID),
					counterpartyAccount,
				];

				const updatedCounterparty = await counterpartyApi.counterparty.updateCounterparty(counterparty);
				const account =
					updatedCounterparty?.Accounts.find(x => x.AccountNumber === counterpartyAccount.AccountNumber) || null;

				resolve(account);
			});
		},
		removeCounterpartyAccount: (counterpartyID: number, counterpartyAccountID: number) => {
			return new Promise<boolean>(async resolve => {
				const counterparty = await counterpartyApi.counterparty.fetchCounterpartyByID(counterpartyID);

				counterparty.Accounts = counterparty.Accounts.filter(x => x.ID !== counterpartyAccountID);

				const updatedCounterparty = await counterpartyApi.counterparty.updateCounterparty(counterparty);
				const isSuccess = !updatedCounterparty.Accounts.find(x => x.ID === counterpartyAccountID);

				resolve(isSuccess);
			});
		},
		fetchCounterpartyEmployeeByID: (counterpartyID: number, employeeID: number) => {
			return new Promise<EmployeeBrief>(async resolve => {
				const counterparty = (await counterpartyApi.counterparty.fetchCounterpartyByID(counterpartyID)) as LegalEntity;
				const employee = counterparty.Employees.find(x => x.ID === employeeID) || null;

				employee.Employer = {
					...employee.Employer,
					ID: counterpartyID,
				};

				resolve(employee);
			});
		},
		addCounterpartyEmployee: (counterpartyID: number, employeeBrief: EmployeeBrief) => {
			return new Promise<EmployeeBrief>(async resolve => {
				const counterparty = (await counterpartyApi.counterparty.fetchCounterpartyByID(counterpartyID)) as LegalEntity;

				counterparty.Employees = [...counterparty.Employees, employeeBrief];

				const updatedCounterparty = (await counterpartyApi.counterparty.updateCounterparty(
					counterparty,
				)) as LegalEntity;
				const employee = updatedCounterparty.Employees.find(x => x.Name === employeeBrief.Name) || null;

				resolve(employee);
			});
		},
		updateCounterpartyEmployee: (counterpartyID: number, employeeBrief: EmployeeBrief) => {
			return new Promise<EmployeeBrief>(async resolve => {
				const counterparty = (await counterpartyApi.counterparty.fetchCounterpartyByID(counterpartyID)) as LegalEntity;

				counterparty.Employees = [...counterparty.Employees.filter(x => x.ID !== employeeBrief.ID), employeeBrief];

				const updatedCounterparty = (await counterpartyApi.counterparty.updateCounterparty(
					counterparty,
				)) as LegalEntity;
				const employee = updatedCounterparty.Employees.find(x => x.Name === employeeBrief.Name) || null;

				resolve(employee);
			});
		},
		removeCounterpartyEmployee: (counterpartyID: number, employeeID: number) => {
			return new Promise<boolean>(async resolve => {
				const counterparty = (await counterpartyApi.counterparty.fetchCounterpartyByID(counterpartyID)) as LegalEntity;

				counterparty.Employees = counterparty.Employees.filter(x => x.ID !== employeeID);

				const updatedCounterparty = (await counterpartyApi.counterparty.updateCounterparty(
					counterparty,
				)) as LegalEntity;
				const isSuccess = !updatedCounterparty.Employees.find(x => x.ID === employeeID);

				resolve(isSuccess);
			});
		},
		assignGroupsToCounterparty: (options: AssignGroupsToCounterpartyOptions) => {
			return new Promise<boolean>(resolve => {
				const { counterpartyID, addIDs, removeIDs, onComplete } = options;

				Promise.all([
					...addIDs.map(
						ID =>
							new Promise(resolve =>
								counterpartyApi.counterparty.client.addCounterpartyToGroup(counterpartyID, ID, resolve),
							),
					),
					...removeIDs.map(
						ID =>
							new Promise(resolve =>
								counterpartyApi.counterparty.client.removeCounterpartyFromGroup(counterpartyID, ID, resolve),
							),
					),
				]).then(result => {
					resolve(result.every(Boolean));
					detectIsFunction(onComplete) && onComplete();
				});
			});
		},
		fetchLegalEntityTaxModes: () => {
			return new Promise<Array<LegalEntityTaxMode>>(resolve => {
				counterpartyApi.counterparty.client.getLegalEntityTaxModes(result => {
					resolve(result);
				});
			});
		},
		checkTenantEntityCanBeRemoved: (tenantEntityIDs: Array<number>) => {
			return new Promise<Array<TenantEntityCanBeRemovedResponseItem>>(resolve => {
				const IDs = buildTLongArray(tenantEntityIDs);

				counterpartyApi.counterparty.client.checkTenantEntityCanBeRemoved(IDs, result => {
					resolve(result.Items);
				});
			});
		},
		fetchCounterpartyContacts: (counterpartryID: number) => {
			return new Promise<Array<CounterpartyContact>>(resolve => {
				counterpartyApi.counterparty.client.getCounterpartyContacts(counterpartryID, result => {
					resolve(result);
				});
			});
		},
		addCounterpartyContact: (contact: CounterpartyContact) => {
			return new Promise<CounterpartyContact>(resolve => {
				counterpartyApi.counterparty.client.addCounterpartyContact(contact, result => {
					resolve(result);
				});
			});
		},
		updateCounterpartyContact: (contact: CounterpartyContact) => {
			return new Promise<CounterpartyContact>(resolve => {
				counterpartyApi.counterparty.client.updateCounterpartyContact(contact, result => {
					resolve(result);
				});
			});
		},
		removeCounterpartyContact: (contactID: number) => {
			return new Promise<boolean>(resolve => {
				counterpartyApi.counterparty.client.removeCounterpartyContact(contactID, result => {
					resolve(fromStringToBoolean(result));
				});
			});
		},
	},
};

export type FetchCounterpartyBriefListOptions = {
	term?: string;
	selectLegalEntities?: boolean;
	selectTenantLegalEntities?: boolean;
	selectEmployees?: boolean;
	selectNaturalPersons?: boolean;
	selectSoleProprietors?: boolean;
	groupIDs?: Array<number>;
	legalEntityTypeIDs?: Array<number>;
	clientManagerIDs?: Array<number>;
	count?: number;
};

export type AssignRoleToEmployeesOptions = {
	employeeIDs: Array<number>;
	roleCode: EmployeeBusinessRoleCode;
};

export type FetchCounterpartiesOptions = {
	term?: string;
	count?: number;
	typeCodes?: Array<CounterpartyType>;
	groupIDs?: Array<number>;
	clientManagerIDs?: Array<number>;
};

export type FetchClientsOptions = FetchCounterpartiesOptions;

export type AssignGroupsToCounterpartyOptions = {
	counterpartyID: number;
	addIDs: Array<number>;
	removeIDs: Array<number>;
	onComplete?: () => void;
};

export enum CounterpartyType {
	NATURAL_PERSON = 'NATURAL_PERSON',
	SOLE_PROPRIETOR = 'SOLE_PROPRIETOR',
	CORPORATE = 'CORPORATE',
	BANK = 'BANK',
	SOVEREIGN = 'SOVEREIGN',
}

export { counterpartyApi };
