import React from 'react';

import { BookmarkIcon } from '@ui/icons/bookmark';
import { Root, IconLayout, MessageLayout } from './styled';

type LabelCardProps = {
	message?: string;
	withoutPaddings?: boolean;
	children: React.ReactNode;
};

const LabelCard: React.FC<LabelCardProps> = props => {
	const { message, withoutPaddings, children } = props;

	return (
		<Root withoutPaddings={withoutPaddings}>
			<IconLayout>
				<BookmarkIcon color='alarm' />
			</IconLayout>
			<MessageLayout>{children ? children : message}</MessageLayout>
		</Root>
	);
};

export { LabelCard };
