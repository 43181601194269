import { useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';

import { IAppState } from '@store';

type UseAutoFetchOptions = {
	selector: (state: IAppState) => boolean;
	waitingForSelector?: (state: IAppState) => boolean;
	fetch: () => void;
	skipMountingFetch?: boolean;
};

function useAutoFetch(rootOptions: UseAutoFetchOptions) {
	const { selector, waitingForSelector = () => true, skipMountingFetch = false, fetch } = rootOptions;
	const didInvalidate = useSelector(selector);
	const canStart = useSelector(waitingForSelector);

	useLayoutEffect(() => {
		if (didInvalidate || !canStart || skipMountingFetch) return;
		fetch();
	}, []);

	useLayoutEffect(() => {
		if (!didInvalidate || !canStart) return;
		fetch();
	}, [didInvalidate]);

	useLayoutEffect(() => {
		if (!canStart) return;
		fetch();
	}, [canStart]);
}

export { useAutoFetch };
