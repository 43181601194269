import React from 'react';

import { getDrawer } from '@utils';
import { IconButton } from '@ui/icon-button';
import { FilterIcon } from '@ui/icons/filter';
import { Root, Content, ButtonLayout } from './styled';

export type ExtraViewProps = {
	title?: string;
	fullWidth?: boolean;
	withoutPadding?: boolean;
	children: React.ReactNode;
};

type ExtraViewState = {
	isDrawerModeActivate: boolean;
	isDrawerOpen: boolean;
};

class ExtraView extends React.PureComponent<ExtraViewProps, ExtraViewState> {
	static displayName = 'ExtraView';
	static defaultProps = {
		title: 'Фильтры',
	};
	state: ExtraViewState = {
		isDrawerModeActivate: false,
		isDrawerOpen: false,
	};

	componentDidMount() {
		this.checkMode();
		window.addEventListener('resize', this.handleResize);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize);
	}

	handleResize = () => this.checkMode();

	checkMode = () => {
		const isDrawerModeActivate = window.innerWidth <= 1200;

		if (isDrawerModeActivate !== this.state.isDrawerModeActivate) {
			this.setState({ isDrawerModeActivate });

			if (!isDrawerModeActivate && this.state.isDrawerOpen) {
				getDrawer().close();
			}
		}
	};

	handleOpenDrawer = () => {
		const { title } = this.props;
		const drawer = getDrawer();

		this.setState({ isDrawerOpen: true });
		drawer.open({
			title,
			content: <Content appearance='drawer'>{this.props.children}</Content>,
			overlay: true,
		});
		drawer.setOnClose(() => this.setState({ isDrawerOpen: false }));
	};

	render() {
		const { isDrawerModeActivate } = this.state;

		return (
			<Root desktop={3} laptop={4}>
				{isDrawerModeActivate ? (
					<ButtonLayout>
						<IconButton variant='rounded' isSilentHover onClick={this.handleOpenDrawer}>
							<FilterIcon color='accent' size={24} />
						</IconButton>
					</ButtonLayout>
				) : (
					this.props.children
				)}
			</Root>
		);
	}
}

export { ExtraView };
