import { CounterpartyType } from '@core/api/counterparty';

export enum MainCounterpartyType {
	LEGAL_ENTITY = 'legal_entity',
	NATURAL_PERSON = 'natural_person',
}

export enum LegalEntityForm {
	LEGAL_ENTITY = 'legal_entity',
	SOLE_PROPRIETOR = 'sole_proprietor',
}

export { CounterpartyType };
