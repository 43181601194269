import { createTypes } from '@flux';

type ActionTypes = {
	CREATE_OPEN_REQUEST: string;
	FETCH_OPEN_REQUESTS: string;
	FETCH_REGIONS: string;
	RESET_OPEN_REQUESTS: string;
};

export const types = createTypes<ActionTypes>(
	['CREATE_OPEN_REQUEST', 'FETCH_OPEN_REQUESTS', 'FETCH_REGIONS', 'RESET_OPEN_REQUESTS'],
	'FUNDS_REGISTER_OPEN_REQUESTS',
);
