import React from 'react';
import { compose } from 'redux';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { IAppState } from '../../../../../../../core/store';
import { withInvalidateFlag, withValidData } from '../../../../../../../core/hocs/with-valid-data.hoc';
import {
	setBillingDisplayPathAction,
	resetBillingDisplayPathAction,
} from '../../../../../actions/display-controller.actions';
import { fetchTenantByIDAction, updateTenantAction } from '../../../../../actions/tenant-account.actions';
import {
	fetchTenantAccountAction,
	fetchTenantBillsAction,
	createPaymentServiceRequestAction,
} from '../../../../../actions/billing.actions';
import { selectCurrentTenantID } from '../../../../../selectors/context.selectors';
import {
	selectTenant,
	selectTenantIsFetching,
	selectTenantDidInvalidate,
} from '../../../../../selectors/tenant-account.selectors';
import { selectTenantBills } from '../../../../../selectors/billing.selectors';
import XSelectTypePayerDisplay from './select-type-payer-display.view';

interface IProps {
	tenantID?: number;
	tenant?: Tenant;
	tenantIsFetching?: boolean;
	tenantBills?: Record<string, TenantBill>;
	setPath?: (path: string) => void;
	resetPath?: () => void;
	fetchTenantByID?: (tenantID: number) => void;
	updateTenant?: (tenant: Tenant) => void;
	fetchTenantBills?: (tenantID: number) => void;
	createPaymentServiceRequest?: (billID: number, paymentServiceCode: string) => void;
}

class SelectTypePayerDisplay extends React.PureComponent<IProps, any> {
	static displayName = 'SelectTypePayerDisplay[Container]';

	componentDidMount() {
		const { tenantID, fetchTenantBills } = this.props;

		fetchTenantBills(tenantID);
	}

	render() {
		const {
			tenant,
			tenantIsFetching,
			tenantBills,

			setPath,
			resetPath,
			updateTenant,
			createPaymentServiceRequest,
		} = this.props;

		return (
			<XSelectTypePayerDisplay
				tenant={tenant}
				tenantIsFetching={tenantIsFetching}
				tenantBills={tenantBills}
				setPath={setPath}
				resetPath={resetPath}
				updateTenant={updateTenant}
				createPaymentServiceRequest={createPaymentServiceRequest}
			/>
		);
	}
}

function mapStateToProps(state: IAppState) {
	return {
		tenantID: selectCurrentTenantID(state),
		tenant: selectTenant(state),
		tenantIsFetching: selectTenantIsFetching(state),
		tenantBills: selectTenantBills(state),
	};
}

function mapDispatchToProps(dispatch: Dispatch<any>) {
	return bindActionCreators(
		{
			setPath: setBillingDisplayPathAction,
			resetPath: resetBillingDisplayPathAction,
			fetchTenantByID: fetchTenantByIDAction,
			updateTenant: updateTenantAction,
			fetchTenantBills: fetchTenantBillsAction,
			createPaymentServiceRequest: createPaymentServiceRequestAction,
		},
		dispatch,
	);
}

export default compose(
	connect<any, any, IProps>(withInvalidateFlag(mapStateToProps, selectTenantDidInvalidate), mapDispatchToProps),
	withValidData<IProps>(props => {
		const { tenantID, fetchTenantByID } = props;

		fetchTenantByID(tenantID);
	}),
)(SelectTypePayerDisplay);
