import React from 'react';
import * as _ from 'underscore';

import { Badge, BadgeColors } from '@core/ui/badge';
import { BoardPersonIcon } from '@core/ui/icons/board-person';
import { BookIcon } from '@core/ui/icons/book';
import { ChatIcon } from '@core/ui/icons/chat';
import { CircleIcon } from '@core/ui/icons/circle';
import { DropdownIcon } from '@core/ui/icons/dropdown';
import { GearIcon } from '@core/ui/icons/gear';
import { HelpIcon } from '@core/ui/icons/help';
import { Divider } from '@ui/divider';
import { IconMenu } from '@ui/icon-menu';
import {
	BadgeLabel,
	BadgeLayout,
	ButtonIconMenuLayout,
	ButtonLabel,
	ButtonLayout,
	MenuLayout,
	Root,
	Section,
	SectionHeader,
	SectionItem,
	SectionItemBadge,
	SectionItemIcon,
} from './styled';

type HelpProps = {
	canOrderDemo: boolean;
	canOrderSetting: boolean;
	isOpen: boolean;
	onOpenSectionHelp: () => void;
	onOpenStartHelp: () => void;
	onOrderDemo: () => void;
	onOrderSetting: () => void;
	onToggleMenu: () => void;
	onWriteToSupportChat: () => void;
};

const Help: React.FC<HelpProps> = props => {
	const {
		canOrderDemo,
		canOrderSetting,
		isOpen,
		onOpenSectionHelp,
		onOpenStartHelp,
		onOrderDemo,
		onOrderSetting,
		onToggleMenu,
		onWriteToSupportChat,
	} = props;

	const renderIconMenu = () => {
		return (
			<IconMenu
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
				autoWidth={false}
				iconButtonElement={
					<ButtonIconMenuLayout>
						<DropdownIcon color='black' size={22} />
					</ButtonIconMenuLayout>
				}
				onRequestChange={onToggleMenu}
				open={isOpen}
				style={{ height: '100%' }}
				targetOrigin={{ horizontal: 'right', vertical: 'top' }}>
				<MenuLayout>
					{renderFindAnswersSection()}
					<Divider />
					{renderGetHelp()}
				</MenuLayout>
			</IconMenu>
		);
	};

	const renderFindAnswersSection = () => {
		return (
			<Section>
				<SectionHeader>Найти ответы на вопросы</SectionHeader>
				<SectionItem onClick={onOpenSectionHelp}>
					<SectionItemIcon>
						<HelpIcon color='black' size={20} />
					</SectionItemIcon>
					Помощь по данному разделу
				</SectionItem>
				<SectionItem onClick={onOpenStartHelp}>
					<SectionItemIcon>
						<BookIcon color='black' size={18} />
					</SectionItemIcon>
					Как начать пользоваться
				</SectionItem>
			</Section>
		);
	};

	const renderGetHelp = () => {
		return (
			<Section>
				<SectionHeader>Получить помощь</SectionHeader>
				<SectionItem onClick={onWriteToSupportChat}>
					<SectionItemIcon>
						<ChatIcon color='black' size={18} />
					</SectionItemIcon>
					<SectionItemBadge>
						Написать в чат поддержки
						<Badge appearance='contained' color={BadgeColors.success}>
							<BadgeLayout>
								<CircleIcon size={6} />
								<BadgeLabel>ONLINE</BadgeLabel>
							</BadgeLayout>
						</Badge>
					</SectionItemBadge>
				</SectionItem>
				<SectionItem disabled={!canOrderDemo} onClick={onOrderDemo}>
					<SectionItemIcon>
						<BoardPersonIcon color={canOrderDemo ? 'black' : 'muted'} size={18} />
					</SectionItemIcon>
					Заказать онлайн демонстрацию
				</SectionItem>
				<SectionItem disabled={!canOrderSetting} onClick={onOrderSetting}>
					<SectionItemIcon>
						<GearIcon color={canOrderSetting ? 'black' : 'muted'} size={18} />
					</SectionItemIcon>
					Заказать настройку сервиса
				</SectionItem>
			</Section>
		);
	};

	return (
		<Root>
			<ButtonLayout onClick={onToggleMenu}>
				<HelpIcon color='black' size={24} />
				<ButtonLabel>Помощь</ButtonLabel>
				{renderIconMenu()}
			</ButtonLayout>
		</Root>
	);
};

export { Help, HelpProps };
