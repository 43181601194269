type GetFormValueOptions<T = unknown> = {
	formObject: T;
	editedObject: Partial<T>;
	props: {
		name: string;
		briefObjectName?: string;
	};
};

function getFormValue(options: GetFormValueOptions) {
	const { formObject, editedObject, props } = options;
	const initialValue = formObject
		? props.briefObjectName && formObject[props.briefObjectName]
			? formObject[props.briefObjectName][props.name]
			: formObject[props.name]
		: null;
	const editedValue = editedObject
		? props.briefObjectName && editedObject[props.briefObjectName]
			? editedObject[props.briefObjectName][props.name]
			: editedObject[props.name]
		: null;
	const value = editedValue || initialValue || null;

	return value;
}

export { getFormValue };
