// @ts-nocheck
import styled from 'styled-components';

import { ILayoutBaseModule, LayoutBase, LayoutItemBase } from '../styled';
import { cssUtils } from '../../shape';

declare namespace IFlexLayoutModuleStyled {
	interface IRoot extends ILayoutBaseModule.ILayoutBase {
		inline?: boolean;
		flexDirection?: 'row' | 'column';
		flexWrap?: 'wrap' | 'nowrap';
		justifyContent?: 'flex-start' | 'center' | 'flex-end' | 'space-between' | 'space-around';
		alignItems?: 'flex-start' | 'center' | 'flex-end' | 'stretch' | 'baseline';
		alignContent?: 'flex-start' | 'center' | 'flex-end' | 'stretch' | 'space-between' | 'space-around';
	}
	interface IItem extends ILayoutBaseModule.ILayoutItemBase {
		order?: number;
		flexGrow?: number;
		flexShrink?: number;
		flexBasis?: number | string;
		alignSelf?: 'flex-start' | 'center' | 'flex-end' | 'stretch' | 'baseline';
		flex?: string;
	}
}

const Root = styled(LayoutBase)`
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: stretch;
	align-content: stretch;

	${(p: IFlexLayoutModuleStyled.IRoot) =>
		p.inline &&
		`
		display: inline-flex;
	`}

	${(p: IFlexLayoutModuleStyled.IRoot) =>
		p.flexDirection &&
		`
		flex-direction: ${p.flexDirection};
	`}

	${(p: IFlexLayoutModuleStyled.IRoot) =>
		p.flexWrap &&
		`
		flex-wrap: ${p.flexWrap};
	`}

	${(p: IFlexLayoutModuleStyled.IRoot) =>
		p.justifyContent &&
		`
		justify-content: ${p.justifyContent};
	`}

	${(p: IFlexLayoutModuleStyled.IRoot) =>
		p.alignItems &&
		`
		align-items: ${p.alignItems};
	`}

	${(p: IFlexLayoutModuleStyled.IRoot) =>
		p.alignContent &&
		`
		align-content: ${p.alignContent};
	`}

	${(p: IFlexLayoutModuleStyled.IRoot) =>
		p.css &&
		`
		${p.css}
	`}
`;

const Item = styled(LayoutItemBase)`
	display: block;
	order: 0;
	flex-grow: 0;
	flex-shrink: 1;
	flex-basis: auto;
	align-self: auto;

	${(p: IFlexLayoutModuleStyled.IItem) =>
		p.order &&
		`
		order: ${p.order};
	`}

	${(p: IFlexLayoutModuleStyled.IItem) =>
		p.flexGrow &&
		`
		flex-grow: ${p.flexGrow};
	`}

	${(p: IFlexLayoutModuleStyled.IItem) =>
		p.flexShrink &&
		`
		flex-shrink: ${p.flexShrink};
	`}

	${(p: IFlexLayoutModuleStyled.IItem) =>
		p.flexShrink &&
		`
		flex-shrink: ${p.flexShrink};
	`}

	${(p: IFlexLayoutModuleStyled.IItem) =>
		p.flexBasis &&
		`
		flex-basis: ${cssUtils.getCSSValue(p.flexBasis)};
	`}

	${(p: IFlexLayoutModuleStyled.IItem) =>
		p.alignSelf &&
		`
		align-self: ${p.alignSelf};
	`}

	${(p: IFlexLayoutModuleStyled.IItem) =>
		p.flex &&
		`
		flex: ${p.flex};
	`}

	${(p: IFlexLayoutModuleStyled.IRoot) =>
		p.css &&
		`
		${p.css}
	`}
`;

export { IFlexLayoutModuleStyled, Root, Item };
