import { createTypes } from '@flux';

type ActionTypes = {
	ADD_PRODUCT_GROUP: string;
	ADD_PRODUCT: string;
	EXPAND_ALL_ITEMS: string;
	FETCH_MEASURE_UNITS: string;
	FETCH_PRODUCT_TYPES: string;
	FETCH_PRODUCTS: string;
	INVALIDATE_PRODUCTS: string;
	LINK_PRODUCTS_TO_PRODUCT_GROUP: string;
	REMOVE_PRODUCT_GROUP: string;
	REMOVE_PRODUCT: string;
	RESET_FILTERS: string;
	SET_FILTER_BY_GROUPS: string;
	SET_FILTER_BY_TEXT: string;
	SET_FILTER_BY_TYPES: string;
	SET_LAST_TAX_RATE: string;
	SET_PRODUCTS_VIEW: string;
	SET_SORTING: string;
	UPDATE_PRODUCT_GROUP: string;
	UPDATE_PRODUCT: string;
};

export const types = createTypes<ActionTypes>(
	[
		'ADD_PRODUCT_GROUP',
		'ADD_PRODUCT',
		'EXPAND_ALL_ITEMS',
		'FETCH_MEASURE_UNITS',
		'FETCH_PRODUCT_TYPES',
		'FETCH_PRODUCTS',
		'INVALIDATE_PRODUCTS',
		'LINK_PRODUCTS_TO_PRODUCT_GROUP',
		'REMOVE_PRODUCT_GROUP',
		'REMOVE_PRODUCT',
		'RESET_FILTERS',
		'SET_FILTER_BY_GROUPS',
		'SET_FILTER_BY_TEXT',
		'SET_FILTER_BY_TYPES',
		'SET_LAST_TAX_RATE',
		'SET_PRODUCTS_VIEW',
		'SET_SORTING',
		'UPDATE_PRODUCT_GROUP',
		'UPDATE_PRODUCT',
	],
	'PRODUCTS',
);
