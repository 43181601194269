import {
	StoreAsyncItem,
	checkAsyncAction,
	createAsyncInitialState,
	createReducer,
	invalidateStateFromAction,
	optimisticUpdateState,
} from '@flux';
import { types } from '@funds-registers/actions/types';
import { VirtualListGroupCode } from '@funds-registers/models';

export type MainFundsRegistersState = {
	balanceTotalKpiRefreshMessage: StoreAsyncItem<SuccessMessage>;
	banks: StoreAsyncItem<Array<Bank>>;
	cmsAccountsTextFilter: string;
	fundsRegisterIDForAutoInsert: number;
	fundsRegisterIDForRefresh: number;
	fundsRegisterStatistics: StoreAsyncItem<Array<FundsRegisterStatistics>>;
	isBalanceHidden: StoreAsyncItem<boolean>;
	isIncludeArchived: boolean;
	purposes: StoreAsyncItem<Array<FundsRegisterPurpose>>;
	sourceTypes: StoreAsyncItem<Array<FundsRegisterSourceType>>;
	textFilter: string;
	virtualListGroup: VirtualListGroupCode;
};

const initialState: MainFundsRegistersState = {
	balanceTotalKpiRefreshMessage: createAsyncInitialState(null),
	banks: createAsyncInitialState([]),
	cmsAccountsTextFilter: '',
	fundsRegisterIDForAutoInsert: -1,
	fundsRegisterIDForRefresh: -1,
	fundsRegisterStatistics: createAsyncInitialState([]),
	isBalanceHidden: createAsyncInitialState(false),
	isIncludeArchived: getInitialIsIncludeArchived(),
	purposes: createAsyncInitialState([]),
	sourceTypes: createAsyncInitialState([]),
	textFilter: '',
	virtualListGroup: getInitialFundsRegisterGroupKey(),
};

const mainFundsRegistersReducer = createReducer<MainFundsRegistersState>(initialState, {
	[types.ADD_FUNDS_REGISTER]: (action: StaticAction, state) => {
		return {
			fundsRegisterStatistics: invalidateStateFromAction(action, state.fundsRegisterStatistics),
		};
	},
	[types.CLEAR_FUNDS_REGISTER_ID_FOR_AUTO_INSERT]: () => {
		return {
			fundsRegisterIDForAutoInsert: -1,
		};
	},
	[types.CLEAR_FUNDS_REGISTER_ID_FOR_REFRESH]: () => {
		return {
			fundsRegisterIDForRefresh: -1,
		};
	},
	[types.FETCH_BANKS]: (action: AsyncAction<Array<Bank>>, state) => {
		return {
			banks: checkAsyncAction(action, state.banks),
		};
	},
	[types.FETCH_FUNDS_REGISTER_PURPOSES]: (action: AsyncAction<Array<FundsRegisterPurpose>>, state) => {
		return {
			purposes: checkAsyncAction(action, state.purposes),
		};
	},
	[types.FETCH_FUNDS_REGISTER_STATISTICS]: (action: AsyncAction<Array<FundsRegisterStatistics>>, state) => {
		return {
			fundsRegisterStatistics: checkAsyncAction(action, state.fundsRegisterStatistics),
		};
	},
	[types.FETCH_IS_BALANCE_HIDDEN]: (action: AsyncAction<boolean>, state) => {
		return {
			isBalanceHidden: checkAsyncAction(action, state.isBalanceHidden),
		};
	},
	[types.FETCH_SOURCE_TYPES]: (action: AsyncAction<Array<FundsRegisterSourceType>>, state) => {
		return {
			sourceTypes: checkAsyncAction(action, state.sourceTypes),
		};
	},
	[types.IMPORT_FUNDS_REGISTER_FILES_COMPLETED]: (action: StaticAction<undefined>, state) => {
		return {
			fundsRegisterStatistics: invalidateStateFromAction(action, state.fundsRegisterStatistics),
		};
	},
	[types.INVALIDATE_FUNDS_REGISTER_STATISTICS]: (action: StaticAction, state) => {
		return {
			fundsRegisterStatistics: invalidateStateFromAction(action, state.fundsRegisterStatistics),
		};
	},
	[types.RECALCULATE_FUNDS_REGISTER_BALANCES]: (action: StaticAction, state) => {
		return {
			fundsRegisterStatistics: invalidateStateFromAction(action, state.fundsRegisterStatistics),
		};
	},
	[types.REFRESH_CMS_ACCOUNTS]: (action: AsyncAction<SuccessMessage>, state) => {
		return {
			balanceTotalKpiRefreshMessage: checkAsyncAction(action, state.balanceTotalKpiRefreshMessage),
			fundsRegisterStatistics: invalidateStateFromAction(action, state.fundsRegisterStatistics),
		};
	},
	[types.REMOVE_FUNDS_REGISTER]: (action: StaticAction, state) => {
		return {
			fundsRegisterStatistics: invalidateStateFromAction(action, state.fundsRegisterStatistics),
		};
	},
	[types.SET_FILTER_BY_TEXT]: (action: StaticAction<string>) => {
		return {
			textFilter: action.value,
		};
	},
	[types.SET_FUNDS_REGISTER_ID_FOR_AUTO_INSERT]: (action: StaticAction<number>) => {
		return {
			fundsRegisterIDForAutoInsert: action.value,
		};
	},
	[types.SET_FUNDS_REGISTER_ID_FOR_REFRESH]: (action: StaticAction<number>) => {
		return {
			fundsRegisterIDForRefresh: action.value,
		};
	},
	[types.SET_IS_BALANCE_HIDDEN]: (action: AsyncAction<boolean>, state) => {
		return {
			isBalanceHidden: optimisticUpdateState({
				action,
				state: state.isBalanceHidden,
				getOptimisticValue: x => !x.item,
			}),
		};
	},
	[types.SET_IS_INCLUDE_ARCHIVED]: (action: StaticAction<boolean>) => {
		return {
			isIncludeArchived: action.value,
		};
	},
	[types.SET_VIRTUAL_LIST_GROUP]: (action: StaticAction<VirtualListGroupCode>) => {
		return {
			virtualListGroup: action.value,
		};
	},
	[types.UPDATE_FUNDS_REGISTER]: (action: StaticAction, state) => {
		return {
			fundsRegisterStatistics: invalidateStateFromAction(action, state.fundsRegisterStatistics),
		};
	},
});

function getInitialIsIncludeArchived() {
	const value = JSON.parse(localStorage.getItem(types.SET_IS_INCLUDE_ARCHIVED));

	return typeof value === 'boolean' ? value : true;
}

function getInitialFundsRegisterGroupKey() {
	const value = JSON.parse(localStorage.getItem(types.SET_VIRTUAL_LIST_GROUP)) as VirtualListGroupCode;

	return typeof value === 'string' ? value : VirtualListGroupCode.TENANT_ENTITY;
}

export default mainFundsRegistersReducer;
