import React from 'react';
import { Switch, Route } from 'react-router-dom';
import styled from 'styled-components';

import { ROUTE_MAP } from './urls';
import TenantAccountKPI from '../../modules/platform/components/tenant-account/tenant-account-kpi';
import { BalanceTotalIndicator } from '@funds-registers/components/balance-total-indicator';
import { CashflowForecastIndicator } from '@planning/components/cashflow-forecast-indicator';

const { DASHBOARD, ACCOUNTS, PL_OPERATIONS, PL_ANALYTICS, PL_PAYMENT_RULES, PL_PLANNING, TENANT_ACCOUNT } = ROUTE_MAP;

const Root = styled.div`
	display: flex;
`;

const SharedIndicators: React.FC = () => {
	return (
		<Root>
			<BalanceTotalIndicator />
			<CashflowForecastIndicator />
		</Root>
	);
};

const KPIRoutes = () => (
	<Switch>
		<Route path={DASHBOARD} component={SharedIndicators} />
		<Route path={PL_OPERATIONS} component={SharedIndicators} />
		<Route path={PL_ANALYTICS} component={SharedIndicators} />
		<Route path={PL_PAYMENT_RULES} component={SharedIndicators} />
		<Route path={ACCOUNTS} component={SharedIndicators} />
		<Route path={PL_PLANNING} component={SharedIndicators} />
		<Route path={TENANT_ACCOUNT} component={TenantAccountKPI} />
	</Switch>
);

export default KPIRoutes;
