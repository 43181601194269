import { createTypes } from '@flux';

type ActionTypes = {
	RUN_EMPLOYEES_INVALIDATION_EFFECT: string;
	INVALIDATE_EMPLOYEES: string;
	FETCH_EMPLOYEES: string;
	ADD_EMPLOYEE: string;
	UPDATE_EMPLOYEE: string;
	REMOVE_EMPLOYEE: string;
	SET_FILTER_BY_TEXT: string;
	SET_FILTER_BY_TENANT_LEGAL_ENTITY: string;
	RESET_FILTERS: string;
	FETCH_BUSINESS_ROLES: string;
	FETCH_USERS: string;
	ADD_USER: string;
	UPDATE_USER: string;
	FETCH_USER_RIGHTS: string;
};

export const types = createTypes<ActionTypes>(
	[
		'RUN_EMPLOYEES_INVALIDATION_EFFECT',
		'INVALIDATE_EMPLOYEES',
		'FETCH_EMPLOYEES',
		'ADD_EMPLOYEE',
		'UPDATE_EMPLOYEE',
		'REMOVE_EMPLOYEE',
		'SET_FILTER_BY_TEXT',
		'SET_FILTER_BY_TENANT_LEGAL_ENTITY',
		'RESET_FILTERS',
		'FETCH_BUSINESS_ROLES',
		'FETCH_USERS',
		'ADD_USER',
		'UPDATE_USER',
		'FETCH_USER_RIGHTS',
	],
	'🖕EMPLOYEES',
);
