import { combineReducers } from 'redux';

import mainPaymentsReducer, { MainPaymentsState } from './main.reducers';

export type PaymentsState = {
	main: MainPaymentsState;
};

const paymentsReducer = combineReducers<PaymentsState>({
	main: mainPaymentsReducer,
});

export default paymentsReducer;
