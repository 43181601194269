import { VirtualListGroupCode } from '@funds-registers/models';

function getFundsRegisterStatisticVirtualListGroupKey(code: VirtualListGroupCode, fundsRegister: FundsRegister) {
	const tenantEntityID = fundsRegister?.LegalEntity?.ID || -1;
	const purposeNumOrder = fundsRegister?.Purpose?.NumOrder || -1;
	const currencyID = fundsRegister?.CurrencyID || -1;
	const map = {
		[VirtualListGroupCode.TENANT_ENTITY]: tenantEntityID > 0 ? tenantEntityID : Infinity,
		[VirtualListGroupCode.PURPOSE]: purposeNumOrder > 0 ? purposeNumOrder : Infinity,
		[VirtualListGroupCode.CURRENCY]: currencyID > 0 ? currencyID : Infinity,
	};

	return map[code] || null;
}

function detectCurrencyFromBankAccountRegisterNumber(
	source: string,
	currencyByDigitCodeMap: Record<string, Currency>,
): Currency {
	const fundsRegisterNumber = source.replace(/\s/g, '');
	const currencyCode = `${fundsRegisterNumber[5]}${fundsRegisterNumber[6]}${fundsRegisterNumber[7]}`;
	const currency = currencyByDigitCodeMap[currencyCode] || null;

	return currency;
}

export { getFundsRegisterStatisticVirtualListGroupKey, detectCurrencyFromBankAccountRegisterNumber };
