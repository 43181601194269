import { combineReducers } from 'redux';

import mainClientsReducer, { MainClientsState } from './main.reducers';


export type ClientsState = {
	main: MainClientsState;
};

const clientsReducer = combineReducers<ClientsState>({
	main: mainClientsReducer,
});

export default clientsReducer;
