import React from 'react';

import { globalEmitErrorMessage } from '@shared/components/error-message/error-interceptor';

export type ErrorBoundaryProps = {
	children: React.ReactNode;
};

type ErrorBoundaryState = {
	hasError: boolean;
};

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
	state: ErrorBoundaryState = {
		hasError: false,
	};

	static getDerivedStateFromError(error) {
		return { hasError: Boolean(error) };
	}

	componentDidCatch(error) {
		globalEmitErrorMessage({
			title: 'Ошибка JavaScript',
			message: error.stack.toString(),
			error,
		});
	}

	render() {
		if (this.state.hasError) return null;

		return this.props.children;
	}
}

export { ErrorBoundary };
