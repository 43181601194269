import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { ContentCopyIcon } from '@ui/icons/content-copy';
import { EyeIcon } from '@ui/icons/eye';
import { EyeSlashIcon } from '@ui/icons/eye-slash';
import { IconButton } from '@ui/icon-button';
import { Layout } from '@ui/units';
import { TextField } from '@ui/input-field';
import { Root } from './styled';

export type CopyToClipboardTextFieldProps = {
	clipboardText?: string;
	value: string;
	labelText?: string;
	fullWidth?: boolean;
	disabled?: boolean;
	readonly?: boolean;
	style?: React.CSSProperties;
	type?: 'text' | 'password';
	setAlwaysValue?: string;
	onCopy?: () => void;
	onChange?: (e: React.SyntheticEvent<{}>, value: string) => void;
	onFocus?: (e: React.FocusEvent<any>) => void;
};

type CopyToClipboardTextFieldState = {
	type: 'text' | 'password';
};

class CopyToClipboardTextField extends React.Component<CopyToClipboardTextFieldProps, CopyToClipboardTextFieldState> {
	static defaultProps = {
		type: 'text',
	} as any;
	state = {
		type: this.props.type,
	};

	handleChangeType = () => {
		this.setState(prevState => ({
			type: prevState.type === 'password' ? 'text' : 'password',
		}));
	};

	handleOnFocus = (e: React.FocusEvent<any>) => {
		const { onFocus } = this.props;

		if (this.state.type !== 'password') {
			e.currentTarget.select();
		}

		typeof onFocus === 'function' && onFocus(e);
	};

	handleOnChange = (e: React.SyntheticEvent<{}>, value: string) => {
		const { setAlwaysValue, onChange } = this.props;

		typeof onChange === 'function' && onChange(e, setAlwaysValue || value);
	};

	render() {
		const { type, value, clipboardText, onCopy, labelText, fullWidth, disabled, readonly, style, ...rest } = this.props;

		return (
			<Root isPassword={this.props.type === 'password'}>
				<TextField
					value={value}
					labelText={labelText}
					fullWidth={fullWidth}
					disabled={disabled}
					readonly={readonly}
					style={style}
					type={this.state.type}
					icon={
						<Layout.Stack marginTop={-5}>
							{this.props.type === 'password' && (
								<Layout.Stack.Item>
									<IconButton variant='rounded' isSilentHover onClick={this.handleChangeType}>
										{this.state.type === 'password' ? (
											<EyeIcon color='accent' size={20} />
										) : (
											<EyeSlashIcon color='accent' size={20} />
										)}
									</IconButton>
								</Layout.Stack.Item>
							)}
							<Layout.Stack.Item>
								<CopyToClipboard text={clipboardText || value} onCopy={onCopy}>
									<IconButton variant='rounded' isSilentHover title='Скопировать'>
										<ContentCopyIcon color='accent' size={20} />
									</IconButton>
								</CopyToClipboard>
							</Layout.Stack.Item>
						</Layout.Stack>
					}
					iconPosition={'right'}
					{...rest}
					onFocus={this.handleOnFocus}
					onChange={this.handleOnChange}
				/>
			</Root>
		);
	}
}

export { CopyToClipboardTextField };
