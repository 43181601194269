import { createStaticDataAction } from '@core/libs/action-creator';


const EMIT_ERROR = '[SHARED]: EMIT_ERROR';
const CLOSE_ERROR_MESSAGE_VIEW = '[SHARED]: CLOSE_ERROR_MESSAGE_VIEW';

function emitErrorAction(title: string, message: string) {
	const payload = {
		title,
		message,
	};

	return createStaticDataAction(EMIT_ERROR)(payload);
}

function closeErrorMessageViewAction() {
	return createStaticDataAction(CLOSE_ERROR_MESSAGE_VIEW)();
}

export {
	EMIT_ERROR,
	CLOSE_ERROR_MESSAGE_VIEW,

	emitErrorAction,
	closeErrorMessageViewAction
};
