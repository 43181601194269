import React from 'react';

import { Root } from './styled';

export type ProtectorProps = {
	isHidden: boolean;
	fallback: React.ReactNode;
	children: React.ReactNode;
};

const Protector: React.FC<ProtectorProps> = props => {
	const { isHidden, fallback, children } = props;

	return <Root isHidden={isHidden}>{isHidden ? fallback : children}</Root>;
};

export { Protector };
