import React, { forwardRef } from 'react';

import withConfirm, { ConfirmEnhancerProps } from '@core/hocs/with-confirm.hoc';
import { GoalStep, useGoalStep } from '@core/hooks/use-goals';
import { OkIcon } from '@ui/icons/ok';
import { Ripple } from '@ui/ripple';
import { detectIsFunction } from '@utils/helpers';
import { ContentLayout, Root } from './styled';

export type IconButtonProps = {
	children: React.ReactNode;
	goalStep?: GoalStep;
	isSilentDisabled?: boolean;
	isSilentHover?: boolean;
	shadowed?: boolean;
	size?: number;
	stopPropagation?: boolean;
	variant?: 'square' | 'rounded';
} & React.AllHTMLAttributes<HTMLButtonElement>;

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>((props, ref) => {
	const {
		children,
		disabled,
		goalStep,
		isSilentDisabled,
		isSilentHover,
		onClick,
		shadowed,
		size,
		stopPropagation,
		variant,
		...rest
	} = props;

	const handleGoalStep = useGoalStep(goalStep);

	const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
		stopPropagation && e.stopPropagation();
		detectIsFunction(onClick) &&
			setTimeout(() => {
				onClick(e);
				handleGoalStep();
			}, 100);
	};

	return (
		<Root
			ref={ref}
			variant={variant}
			shadowed={shadowed}
			disabled={isSilentDisabled || disabled}
			isSilentHover={isSilentHover}
			isSilentDisabled={isSilentDisabled}
			onClick={handleClick}
			{...(rest as any)}>
			<Ripple skip={isSilentDisabled || disabled}>
				<ContentLayout size={size}>{children}</ContentLayout>
			</Ripple>
		</Root>
	);
});

IconButton.defaultProps = {
	size: 32,
};

const EnhancedIconButton = withConfirm<IconButtonProps>()(IconButton);

export type IconButtonConfirmProps = IconButtonProps & ConfirmEnhancerProps;

const IconButtonConfirm: React.FC<IconButtonConfirmProps> = props => {
	return <EnhancedIconButton {...props} renderConfirmContent={() => <OkIcon color='alarm' />} />;
};

export { IconButton, IconButtonConfirm };
