import { getAuthParams } from '@config';

export type SearchParams = Record<string, string | number | boolean | void>;

const searchToParams = (search: string): SearchParams => {
	const result: SearchParams = {};

	(search || '')
		.replace(/^[?#]/, '')
		.split('&')
		.forEach(item => {
			const [key, encodedValue] = item.split('=');
			const value = decodeURIComponent(encodedValue);

			if (key) {
				if (/^[\{\[].+?[\}\]]$/.test(value)) {
					result[key] = JSON.parse(value);
				} else if (/^\d+$/.test(value)) {
					result[key] = Number(value);
				} else {
					result[key] = value;
				}
			}
		});

	return result;
};

const paramsToSearch = (params: SearchParams): string => {
	return Object.keys(params)
		.map(key => {
			const isEmpty = params[key] === '';

			return isEmpty ? '' : `${key}=${params[key]}&`;
		})
		.join('')
		.replace(/&$/, '');
};

function correctFileName(name: string): string {
	const separator = '.';
	const [ext, ...restFileName] = name.split(separator).reverse();
	const correctFileName = restFileName.reverse().join(separator).replace(/\./g, '_');
	const fileName = correctFileName + separator + ext;

	return fileName;
}

function correctFileUrl(url = ''): string {
	return (/^blob/.test(url) || /^data/.test(url) || url[0] === '/' ? '' : '/') + url;
}

function getUrlSearchParameter(name: string, search = location.search) {
	const map = new URLSearchParams(search);
	const value = map.get(name);

	return value;
}

function useUrlSearchParameter(name: string) {
	const map = new URLSearchParams(location.search);
	const value = map.get(name);

	return [value];
}

function correctPathName(value = '') {
	return value.replace(/(\/)$/, '');
}

function getClearedUrl(source: string) {
	if (!source) return '';
	const referer = correctPathName(decodeURIComponent(source));
	let transformedReferer = '';

	try {
		const { hostname, pathname } = new URL(referer);

		transformedReferer = `${hostname}${pathname}`;
	} catch (error) {
		//
	}

	return transformedReferer;
}

function transformRelativePathToAbsolutePath(path: string) {
	const { baseURL } = getAuthParams();
	const isMatched = path.indexOf(baseURL) !== -1;
	const transformedPath = isMatched
		? `${location.origin}${path}`
		: `${location.origin}${baseURL}${path.replace(/^\./, '')}`;

	return transformedPath;
}

export {
	paramsToSearch,
	searchToParams,
	correctFileName,
	correctFileUrl,
	getUrlSearchParameter,
	useUrlSearchParameter,
	getClearedUrl,
	transformRelativePathToAbsolutePath,
};
