import { SET_BILLING_DISPLAY_PATH, RESET_BILLING_DISPLAY_PATH } from '../actions/display-controller.actions';
import { pathTo as billingPathTo } from '../components/tenant-account/billing/billing-display-controller/paths';

export interface IDisplayControllerState {
	billingDisplayPath: string;
}

const initialState = {
	billingDisplayPath: billingPathTo.selectConditions,
};

export default function (state: IDisplayControllerState = initialState, action: StaticAction<string>) {
	switch (action.type) {
		case SET_BILLING_DISPLAY_PATH:
			return {
				...state,
				billingDisplayPath: action.value,
			};

		case RESET_BILLING_DISPLAY_PATH:
			return {
				...state,
				billingDisplayPath: initialState.billingDisplayPath,
			};

		default:
			return state;
	}
}
