import React, { useState, useRef, useEffect, forwardRef } from 'react';
import QRCode from 'qrcode';

import { Box } from '@ui/box';
import { Typography } from '@ui/typography';
import { ModalViewer } from '@ui/modal-viewer';
import { CanvasLayout, CanvasModalLayout, Border } from './styled';

export type QrCodeProps = {
	variant?: 'inline';
	labelText?: string;
	value: string;
	size?: number;
	transformValue?: (value: string) => string;
};

const QrCode = forwardRef<QrCodeRef, QrCodeProps>((props, ref) => {
	const { variant, value, size, transformValue, labelText } = props;
	const [isOpen, setIsOpen] = useState(false);
	const canvasRef = useRef<HTMLCanvasElement>(null);
	const canvasModalRef = useRef<HTMLCanvasElement>(null);
	const isInline = variant === 'inline';

	useEffect(() => {
		updateQrCodeCanvas({
			ref: canvasRef.current,
			value: transformValue(value),
			width: size,
		});
	}, [value]);

	useEffect(() => {
		if (!isOpen) return;
		updateQrCodeCanvas({
			ref: canvasModalRef.current,
			value: transformValue(value),
			width: 140,
		});
	}, [isOpen, value]);

	const handleOpen = () => setIsOpen(true);

	const handleClose = () => setIsOpen(false);

	if (isInline) return <canvas ref={canvasRef} width={size} height={size} />;

	return (
		<Box display='flex' alignItems='center' flexFlow='column nowrap'>
			{labelText && (
				<Typography.Label fontSize={11} marginBottom={15} transform='scale(0.96)'>
					{labelText}
				</Typography.Label>
			)}
			<CanvasLayout onClick={handleOpen}>
				<Border>
					<canvas width={size} height={size} ref={canvasRef} />
				</Border>
			</CanvasLayout>
			{!isInline && (
				<ModalViewer isOpen={isOpen} width='354px' innerCloseBtn onClose={handleClose}>
					<CanvasModalLayout>
						<Box marginTop={-20} marginBottom={20} textAlign='center'>
							<Typography.Label>
								Наведите камеру телефона на
								<Typography.Nbsp />
								этот QR-код и отсканируйте ссылку
							</Typography.Label>
						</Box>
						<Border>
							<canvas ref={canvasModalRef} />
						</Border>
					</CanvasModalLayout>
				</ModalViewer>
			)}
		</Box>
	);
});

export type QrCodeRef = {};

QrCode.defaultProps = {
	width: 100,
	transformValue: x => x,
} as any;

type UpdateQrCodeCanvasOptions = {
	ref: HTMLCanvasElement;
	value: string;
	width: number;
	onComplete?: (ref: HTMLCanvasElement) => void;
	onError?: (error: any) => void;
};

function updateQrCodeCanvas(options: UpdateQrCodeCanvasOptions) {
	const { ref, value, width = 100, onComplete = () => {}, onError = () => {} } = options;

	QRCode.toCanvas(
		ref,
		value,
		{
			width,
			quality: 1,
			margin: 0,
		},
		error => {
			if (error) {
				onError(error);
				console.error(error);
			}

			onComplete(ref);
		},
	);
}

export { QrCode, updateQrCodeCanvas };
