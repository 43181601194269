export enum BillingProvider {
	YANDEX_KASSA = 'YANDEX_KASSA',
	ROBOKASSA = 'ROBOKASSA',
	SEENECO = 'SEENECO',
}

export enum BusinessServiceType {
	BFM = 'BFM',
	INVOICE = 'INVOICE',
	UNKNOWN = 'UNKNOWN',
}
