import styled, { css } from 'styled-components';

import { textClip } from '@ui/basic-theme/styled-utils';

const Root = styled.div`
	cursor: pointer;
	display: block;
	position: relative;
	width: 100%;
	height: 70px;
	border-bottom: 1px solid transparent;
	transition: background-color 0.2s ease-in-out;

	${p => css`
		color: ${p.theme.palette.text};
		border-color: ${p.theme.palette.stealth};

		&:hover {
			background-color: ${p.theme.palette.stealth};
		}
	`}
`;

const ContentLayout = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	padding: 18px;
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: stretch;
`;

const GroupLayout = styled.div`
	width: auto;
	height: auto;
`;

const BankIconLayout = styled.div`
	margin-right: 11px;
`;

const AccountNameLayout = styled.div``;

const AccountName = styled.div`
	font-size: 12px;
	line-height: 1.25;
	max-width: 130px;
	${textClip()}
`;

const BalanceLayout = styled.div`
	margin-top: 6px;
`;

interface IBalanceProps {
	textClip: boolean;
}

const Balance = styled.div`
	font-size: 12px;
	line-height: 1.25;
	${(props: IBalanceProps) =>
		props.textClip &&
		css`
			max-width: 130px;
			${textClip()}
		`}
`;

const SyncMarkerLayout = styled.div`
	position: absolute;
	top: 15px;
	right: 8px;
`;

export {
	Root,
	ContentLayout,
	GroupLayout,
	BankIconLayout,
	AccountNameLayout,
	AccountName,
	BalanceLayout,
	Balance,
	SyncMarkerLayout,
};
