import React from 'react';

import { IconBase, IconBaseProps } from './base';

export type BankIconProps = IconBaseProps;

const BankIcon: React.FC<BankIconProps> = props => {
	return (
		<IconBase {...props}>
			<svg viewBox='0 0 24 24' fill='currentColor'>
				<path d='M0 0h24v24H0z' fill='none'></path>
				<path d='M4 10v7h3v-7H4zm6 0v7h3v-7h-3zM2 22h19v-3H2v3zm14-12v7h3v-7h-3zm-4.5-9L2 6v2h19V6l-9.5-5z'></path>
			</svg>
		</IconBase>
	);
};

export { BankIcon };
