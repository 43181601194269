import * as _ from 'underscore';
import * as moment from 'moment';

import { IAppState } from '@store';
import api from '@api';
import { createSelector, createAsyncSelector, createRightCheck } from '@flux';
import { createDateList, compareDates, today } from '@utils/date';
import { safeNumber } from '@utils/numbers';
import { getSum } from '@utils/finance';
import { sortDescBy, formatEntity, getIndexOfMonth, getIndexOfQuarter } from '@utils';
import { mainCashflowItemsSelectorsPack } from '@cashflow-items/selectors';
import { aciUtils } from '@analytics/utils';
import { DetalizationOptions } from '../actions';
import { getDetalizationKey } from '@dashboard/shared/utils';
import { BASE_DATE_FORMAT } from '@shared/constants/time';
import { createObjectMap } from '@utils/object';

const selectBalanceIndicatorRight = createRightCheck({
	view: 'xDashboard_ViewBalances',
});
const selectForecastIndicatorRight = createRightCheck({
	view: 'xDashboard_ViewForecast',
});

function extractTimeDataForXAxis(dateStart: string, dateEnd: string) {
	const dateStartMoment = moment(dateStart, BASE_DATE_FORMAT);
	const dateEndMoment = moment(dateEnd, BASE_DATE_FORMAT);
	const diffInYears = dateEndMoment.diff(dateStartMoment, 'years');
	const startYear = Number(moment(dateStart, BASE_DATE_FORMAT).format('YYYY'));
	const startQuarter = Number(moment(dateStart, BASE_DATE_FORMAT).format('Q'));
	const startMonth = Number(moment(dateStart, BASE_DATE_FORMAT).format('M'));
	const currentYear = 0;
	const currentQuarter = 0;
	const currentMonth = 0;

	return {
		dateStartMoment,
		dateEndMoment,
		diffInYears,
		startYear,
		startQuarter,
		startMonth,
		currentYear,
		currentQuarter,
		currentMonth,
	};
}
function fillWithZeroSplineDataList(
	diffInYears: number,
	dateStart: string,
	dateEnd: string,
	data: Array<any> | {},
	fillFn: (item, list: Array<number>) => void,
) {
	if (diffInYears === 0) {
		const dateStartMoment = moment(dateStart, BASE_DATE_FORMAT).startOf('month');
		const dateEndMoment = moment(dateEnd, BASE_DATE_FORMAT).endOf('month').add(1, 'day');
		const diffInMonth = dateEndMoment.diff(dateStartMoment, 'month');
		const list = Array(diffInMonth).fill(0);

		if (Array.isArray(data)) {
			data.forEach(item => {
				fillFn(item, list);
			});
		} else {
			fillFn(data, list);
		}
	} else {
		const dateStartMoment = moment(dateStart, BASE_DATE_FORMAT).startOf('quarter');
		const dateEndMoment = moment(dateEnd, BASE_DATE_FORMAT).endOf('quarter').add(1, 'day');
		const diffInQuarters = dateEndMoment.diff(dateStartMoment, 'quarter');
		const list = Array(diffInQuarters).fill(0);

		if (Array.isArray(data)) {
			data.forEach(item => {
				fillFn(item, list);
			});
		} else {
			fillFn(data, list);
		}
	}
}

type ChartPoint = {
	y: number | null;
	date: string;
};

const selectAsyncDashboardData = createAsyncSelector<DashboardResponse, IAppState>({
	get: s => s.bpl.main.dashboardData,
	selector: createSelector(
		s => s.bpl.main.dashboardData.item,
		item => item,
	),
});

const selectAsyncAciDynamicList = createAsyncSelector<Array<PLOperationAccountsChartItemSeries>, IAppState>({
	get: s => s.bpl.main.aciSeries,
	selector: createSelector(
		s => s.bpl.main.aciSeries.item,
		item => item,
	),
});

const selectAsyncCounterpartyTop = createAsyncSelector<CounterpartiesTopResponse, IAppState>({
	get: s => s.bpl.main.aciSeries,
	selector: createSelector(
		s => s.bpl.main.cpTop.item,
		item => item,
	),
});

const selectAsyncFundsRegisterStatistics = createAsyncSelector<Array<FundsRegisterStatistics>, IAppState>({
	get: s => s.bpl.main.fundsRegisterStatistics,
	selector: createSelector(
		s => s.bpl.main.fundsRegisterStatistics.item,
		item => item,
	),
});

const selectAsyncCashflowForecast = createAsyncSelector<CashflowForecast, IAppState>({
	get: s => s.bpl.main.cashflowForecast,
	selector: createSelector(
		s => s.bpl.main.cashflowForecast.item,
		item => item,
	),
});

const selectAsyncPlListForDetalization = createAsyncSelector<Array<PLOperationBrief>, IAppState>({
	get: s => s.bpl.main.detalization.plOperations,
	selector: createSelector(
		s => s.bpl.main.detalization.plOperations.item,
		plList => {
			const sortedList = sortDescBy(plList, [{ fn: item => item.CashflowDate, isDate: true }, { fn: item => item.ID }]);

			return sortedList;
		},
	),
});

const selectAsyncAnalyticsOperations = createAsyncSelector<Array<PLOperationBrief>, IAppState>({
	get: s => s.bpl.main.analytics.plOperations,
	selector: createSelector(
		s => s.bpl.main.analytics.plOperations.item,
		plList => {
			const sortedList = sortDescBy(plList, [{ fn: item => item.CashflowDate, isDate: true }, { fn: item => item.ID }]);

			return sortedList;
		},
	),
});

const selectIncomeCounterpartyDynamic = createSelector(selectAsyncCounterpartyTop.selectItem, cpTop => {
	const topSeries: Array<PLOperationCounterpartySeries> =
		cpTop?.TopPayers.map(x => ({ ...x }))
			.map(x => ((x.Points = x.Points.filter(p => !p.Plan && p.DirectionIncoming)), x))
			.filter(x => x.Points.length > 0) || [];
	const otherSeries: Array<PLOperationCounterpartySeries> =
		cpTop?.OtherPayers.map(x => ({ ...x }))
			.map(x => ((x.Points = x.Points.filter(p => !p.Plan && p.DirectionIncoming)), x))
			.filter(x => x.Points.length > 0) || [];
	const otherIDs = _.uniq(cpTop?.OtherPayerIdList.map(x => x.Value) || []);

	return {
		topSeries,
		otherSeries: otherSeries.map(x => ({ ...x, CounterpartyID: -2 })),
		otherIDs,
	};
});

const selectExpenseCounterpartyDynamic = createSelector(selectAsyncCounterpartyTop.selectItem, cpTop => {
	const topSeries: Array<PLOperationCounterpartySeries> =
		cpTop?.TopRecipients.map(x => ({ ...x }))
			.map(x => ((x.Points = x.Points.filter(p => !p.Plan && !p.DirectionIncoming)), x))
			.filter(x => x.Points.length > 0) || [];
	const otherSeries: Array<PLOperationCounterpartySeries> =
		cpTop?.OtherRecipients.map(x => ({ ...x }))
			.map(x => ((x.Points = x.Points.filter(p => !p.Plan && !p.DirectionIncoming)), x))
			.filter(x => x.Points.length > 0) || [];
	const otherIDs = _.uniq(cpTop?.OtherRecipientIdList.map(x => x.Value) || []);

	return {
		topSeries,
		otherSeries: otherSeries.map(x => ({ ...x, CounterpartyID: -2 })),
		otherIDs,
	};
});

function selectDetalizationOptions(state: IAppState): DetalizationOptions {
	return state.bpl.main.detalization.options;
}

function selectIsDetalizationOpen(state: IAppState): boolean {
	return Boolean(state.bpl.main.detalization.options);
}

function selectDateRange(state: IAppState): DateRange {
	return state.bpl.main.dateRange;
}

const selectTransformedAciSeriesMap = createSelector(
	selectDateRange,
	selectAsyncAciDynamicList.selectItem,
	mainCashflowItemsSelectorsPack.selectCashflowItemsMap,
	(
		dateRange: DateRange,
		aciSeries: Array<PLOperationAccountsChartItemSeries>,
		cfiMap: Record<string, CashflowItem>,
	) => {
		const detalizationKey = getDetalizationKey(dateRange);
		const unallocatedSeries = aciSeries.find(x => x.AccountsChartItemID === -1);
		const pointsIncome = unallocatedSeries ? unallocatedSeries.Points.filter(p => p.DirectionIncoming) : [];
		const pointsExpense = unallocatedSeries ? unallocatedSeries.Points.filter(p => !p.DirectionIncoming) : [];
		const unallocatedIncome = unallocatedSeries
			? { ...unallocatedSeries, AccountsChartItemID: -1, Points: pointsIncome }
			: null;
		const unallocatedExpense = unallocatedSeries
			? { ...unallocatedSeries, AccountsChartItemID: -2, Points: pointsExpense }
			: null;
		const filteredAciSeries = aciSeries.filter(x => x.AccountsChartItemID !== -1);

		if (unallocatedIncome) {
			filteredAciSeries.push(unallocatedIncome);
		}

		if (unallocatedExpense) {
			filteredAciSeries.push(unallocatedExpense);
		}

		const dates = createDateList(dateRange.dateStart, dateRange.dateEnd, detalizationKey);
		const indexFn = (x: PLOperationAccountsChartItemSeries) => {
			const isIncome = (cfiMap[x.AccountsChartItemID] && cfiMap[x.AccountsChartItemID].Incoming) || null;
			const points = dates.map(date => {
				const point = x.Points.find(p => p.ValueDate === date && !p.Plan);
				if (point) return point;
				const newPoint: PLOperationTimeSeriesPoint = {
					...new api.plpackage.PLOperationTimeSeriesPoint(),
					Amount: 0,
					Plan: false,
					ValueDate: date,
					DirectionIncoming: isIncome,
				};

				return newPoint;
			});

			x.Points = points;

			return x.AccountsChartItemID;
		};
		const aciSeriesMap =
			filteredAciSeries.length > 0
				? (_.indexBy(filteredAciSeries, indexFn) as Record<string, PLOperationAccountsChartItemSeries>)
				: null;

		return aciSeriesMap || {};
	},
);

const selectDataByDirection = createSelector(
	selectDateRange,
	selectTransformedAciSeriesMap,
	(dateRange: DateRange, aciSeriesMap: Record<string, PLOperationAccountsChartItemSeries>) => {
		const today = moment();
		const detalizationKey = getDetalizationKey(dateRange);
		const dates = createDateList(dateRange.dateStart, dateRange.dateEnd, detalizationKey);
		const aciSeriesList = Object.keys(aciSeriesMap || {}).map(key => aciSeriesMap[key]);
		const filterFeaturePoints = (x: ChartPoint) => !compareDates(x.date, today, detalizationKey).isAfter();
		const incomeList = dates
			.map((_, idx) => {
				const total = aciSeriesList.reduce((acc, v) => {
					const point = v.Points[idx];
					return point.DirectionIncoming ? ((acc += v.Points[idx].Amount), acc) : acc;
				}, 0);
				return {
					y: safeNumber(total),
					date: dates[idx] || '',
				};
			})
			.filter(filterFeaturePoints);
		const expenseList = dates
			.map((_, idx) => {
				const total = aciSeriesList.reduce((acc, v) => {
					const point = v.Points[idx];
					return !point.DirectionIncoming ? ((acc += v.Points[idx].Amount), acc) : acc;
				}, 0);
				return {
					y: safeNumber(total),
					date: dates[idx] || '',
				};
			})
			.filter(filterFeaturePoints);
		const resultList = incomeList
			.map((_, idx) => {
				const total = incomeList[idx].y - expenseList[idx].y;
				return {
					y: safeNumber(total),
					date: dates[idx] || '',
				};
			})
			.filter(filterFeaturePoints);

		return {
			incomeList,
			expenseList,
			resultList,
		};
	},
);

const selectDataByCashflow = (isIncome: boolean) =>
	createSelector(
		selectDateRange,
		selectTransformedAciSeriesMap,
		mainCashflowItemsSelectorsPack.selectCashflowItemsMap,
		mainCashflowItemsSelectorsPack.selectAccountsChartItemRolesMap,
		(
			dateRange: DateRange,
			aciSeriesMap: Record<string, PLOperationAccountsChartItemSeries>,
			cfiMap: Record<string, CashflowItem>,
			aciRolesMap: Record<string, AccountsChartItemRole>,
		) => {
			const detalizationKey = getDetalizationKey(dateRange);
			const today = moment();
			const dates = createDateList(dateRange.dateStart, dateRange.dateEnd, detalizationKey);
			const aciRoles = Object.keys(aciRolesMap || {})
				.map(key => aciRolesMap[key])
				.filter(x => x.Incoming === isIncome);
			const cfList = Object.keys(cfiMap)
				.map(key => cfiMap[key])
				.filter(x => x.Incoming === isIncome);
			const items = aciUtils.getCashflowRoleAggregationList(aciRoles, cfList);
			const totalAmountMap = aciUtils.getTotalAmountGroup({ items, aciSeriesMap });
			const totalAmountByNoRoles = aciUtils.getTotalAmountByNoRolesItems({ isIncome, cfList, aciSeriesMap });
			const totalAmountByRoles = Object.keys(totalAmountMap.byRoles)
				.map(key => totalAmountMap.byRoles[key])
				.reduce((acc, x) => (acc += x), 0) as number;
			const totalAmount = safeNumber(totalAmountByRoles + totalAmountByNoRoles);
			const filterFeaturePoints = (x: ChartPoint) => !compareDates(x.date, today, detalizationKey).isAfter();
			const splineMap = items.reduce((acc, item) => {
				const roleCode = item.role.Code;
				const list = dates
					.map((_, idx) => {
						const total = item.cfItems.reduce((acc, cfItem) => {
							const seriesList = aciSeriesMap[cfItem.ID] ? [aciSeriesMap[cfItem.ID]] : [];
							const total = seriesList.reduce((acc, { Points }) => {
								const point = Points[idx];

								return point.DirectionIncoming === isIncome ? (acc += point.Amount) : acc;
							}, 0);

							return (acc += total);
						}, 0);

						return {
							y: safeNumber(total),
							date: dates[idx] || '',
						};
					})
					.filter(filterFeaturePoints);

				acc[roleCode] = list;

				return acc;
			}, {});

			for (const item of items) {
				item.cfItems = item.cfItems
					.filter(x => totalAmountMap.byItems[x.ID] > 0)
					.sort((a, b) => totalAmountMap.byItems[b.ID] - totalAmountMap.byItems[a.ID]);
			}

			const obj = {
				items,
				totalAmount,
				totalAmountMap,
				totalAmountByNoRoles,
				splineMap,
			};

			return obj;
		},
	);

const selectDataByCashflowIncome = selectDataByCashflow(true);
const selectDataByCashflowExpense = selectDataByCashflow(false);

const selectDataByResult = createSelector(
	selectTransformedAciSeriesMap,
	mainCashflowItemsSelectorsPack.selectCashflowItemsMap,
	mainCashflowItemsSelectorsPack.selectAccountsChartItemRolesMap,
	(
		aciSeriesMap: Record<string, PLOperationAccountsChartItemSeries>,
		cfiMap: Record<string, CashflowItem>,
		aciRolesMap: Record<string, AccountsChartItemRole>,
	) => {
		if (!aciSeriesMap || !cfiMap || !aciRolesMap) {
			return {
				revenue: 0,
				costs: 0,
				creditPayment: 0,
				profitBeforeTaxAndPayments: 0,
				profitBeforeTax: 0,
				tax: 0,
				profit: 0,
			};
		}

		const aciRoles = Object.keys(aciRolesMap || {}).map(key => aciRolesMap[key]);
		const cfList = Object.keys(cfiMap).map(key => cfiMap[key]);
		const totalRevenueByNoRoles = aciUtils.getTotalAmountByNoRolesItems({ isIncome: true, cfList, aciSeriesMap });
		const revenue =
			aciUtils.getTotalAmountByAciRoles({
				roleCodes: [
					'REVENUE',
					'SALE_OF_GOODS',
					'SALE_OF_SERVICES',
					'EBIT_REVENUE_PERCENT',
					'EBIT_REVENUE_OTHER',
					'NONCASH_REVENUE_RATE',
					'NONCASH_REVENUE_OTHER',
					'REFUND_OF_SUPPLIERS',
					'DEPOSIT_INTEREST',
				],
				aciRoles,
				aciSeriesMap,
				cfList,
			}) + totalRevenueByNoRoles;
		const totalCostsByNoRoles = aciUtils.getTotalAmountByNoRolesItems({ isIncome: false, cfList, aciSeriesMap });
		const costs =
			aciUtils.getTotalAmountByAciRoles({
				roleCodes: [
					'OPERATING_EXPENSES',
					'PAYROLL',
					'PERSONAL_INCOME_TAX',
					'OTHER_PAYROLL_CONTRIBUTION',
					'CASH_WITHDRAWAL',
					'BANK_SERVICES',
					'OTHER_BUDGET_PAYMENTS',
					'IT_TELECOM',
					'ACQUIRING_FEE',
					'PARTNER_COMMISSION',
					'REFUND_OF_PAYMENT',
					'PAYROLL_SM',
					'PERSONAL_INCOME_TAX_SM',
					'OTHER_PAYROLL_CONTRIBUTION_SM',
					'ADVERTISING_EXPENSES',
					'ADVERTISING_EXPENSES_PLACEMENT',
					'ADVERTISING_EXPENSES_PRODUCTION',
					'PAYROLL_ADM_STAFF',
					'PERSONAL_INCOME_TAX_ADM_STAFF',
					'OTHER_PAYROLL_CONTRIBUTION_ADM_STAFF',
					'RENT',
					'OFFICE',
					'COST_SOCIAL_PACKAGE',
					'AMORTIZATION',
					'EBIT_EXPENSES_OTHER',
					'NONCASH_EXPENSES_RATE',
					'NONCASH_EXPENSES_OTHER',
					'FIXED_EXPENSES_OTHER',
					'COGS_GOODS',
					'COGS_SERVICES',
					'OTHER_DISTRIB_COSTS',
					'OTHER_ADM_COSTS',
					'LEGAL_SERVICES',
				],
				aciRoles,
				aciSeriesMap,
				cfList,
			}) + totalCostsByNoRoles;

		const profitBeforeTaxAndPayments = revenue - costs;
		const creditPayment = aciUtils.getTotalAmountByAciRoles({
			roleCodes: ['EBIT_EXPENSES_PERCENT'],
			aciRoles,
			aciSeriesMap,
			cfList,
		});
		const profitBeforeTax = profitBeforeTaxAndPayments - creditPayment;
		const simplifiedTax = aciUtils.getTotalAmountByAciRoles({
			roleCodes: ['SIMPLIFIED_TAX'],
			aciRoles,
			aciSeriesMap,
			cfList,
		});
		const vatTax = aciUtils.getTotalAmountByAciRoles({
			roleCodes: ['VAT_COST'],
			aciRoles,
			aciSeriesMap,
			cfList,
		});
		const incomeTax = aciUtils.getTotalAmountByAciRoles({
			roleCodes: ['INCOME_TAX', 'OTHER_TAX'],
			aciRoles,
			aciSeriesMap,
			cfList,
		});
		const tax = simplifiedTax + vatTax + incomeTax;
		const profit = profitBeforeTax - tax;
		const profitUsagePlus = aciUtils.getTotalAmountByAciRoles({
			roleCodes: ['LOAN_PAYABLE', 'DIVIDENDS_RECEIVED', 'LOAN_REPAYMENT', 'INVESTMENT_INCOME'],
			aciRoles,
			aciSeriesMap,
			cfList,
		});
		const profitUsageMinus = aciUtils.getTotalAmountByAciRoles({
			roleCodes: ['CREDIT_PAYMENT', 'DIVIDENDS', 'LOAN_ISSUANCE', 'INVESTMENT_EXPENSE'],
			aciRoles,
			aciSeriesMap,
			cfList,
		});
		const profitUsage = profitUsagePlus - profitUsageMinus;

		return {
			revenue,
			costs,
			creditPayment,
			profitBeforeTaxAndPayments,
			profitBeforeTax,
			profit,
			tax,
			profitUsage,
		};
	},
);

const selectDataByCounterpartyForBar = (isPayer: boolean) =>
	createSelector(isPayer ? selectIncomeCounterpartyDynamic : selectExpenseCounterpartyDynamic, cpBox => {
		const { topSeries, otherSeries, otherIDs } = cpBox;
		const sortFn = (a, b) => (a.total > b.total ? -1 : 1);
		const top = topSeries
			.map(item => {
				const ID = item.CounterpartyID || -1;
				const Name = ID > 0 ? item.CounterpartyName : 'Контрагент не указан';

				return {
					ID,
					IDs: [ID],
					Name: formatEntity(Name),
					total: item.Points.reduce((acc, p) => ((acc += p.Amount), acc), 0),
				};
			})
			.filter(item => item.total > 0)
			.sort(sortFn);

		otherSeries.length > 0 &&
			top.push({
				ID: -2,
				IDs: otherIDs,
				Name: `Ещё ${otherIDs.length}`,
				total: otherSeries.reduce(
					(acc, x) => ((acc += x.Points.reduce((acc, p) => ((acc += p.Amount), acc), 0)), acc),
					0,
				),
			});

		return top;
	});

const selectDataByCounterpartyPayerForBar = selectDataByCounterpartyForBar(true);
const selectDataByCounterpartyRecipientForBar = selectDataByCounterpartyForBar(false);

const selectDataByCounterpartyForSpline = (isPayer: boolean) =>
	createSelector(
		selectDateRange,
		isPayer ? selectIncomeCounterpartyDynamic : selectExpenseCounterpartyDynamic,
		(dateRange, cpBox) => {
			const { topSeries, otherSeries, otherIDs } = cpBox;
			const today = moment();
			const cpTopSeriesMap = _.indexBy(topSeries, x => x.CounterpartyID);
			const cpOtherSeriesMap = _.indexBy(otherSeries, x => x.CounterpartyID);
			const detalizationKey = getDetalizationKey(dateRange);
			const dates = createDateList(dateRange.dateStart, dateRange.dateEnd, detalizationKey);
			const filterFeaturePoints = (date: string) => !compareDates(date, today, detalizationKey).isAfter();
			const makeSplineData = (series: Array<PLOperationCounterpartySeries>) => {
				const data = series
					.map(x => {
						return {
							ID: x.CounterpartyID,
							IDs: [x.CounterpartyID],
							Name: x.CounterpartyID === -2 ? '' : x.CounterpartyID > 0 ? x.CounterpartyName : 'Контрагент не указан',
							list: [] as Array<number>,
						};
					})
					.map(x => {
						const cpDynamic = x.ID === -2 ? cpOtherSeriesMap[x.ID] : cpTopSeriesMap[x.ID];

						x.list = Array(dates.length)
							.fill(0)
							.filter((_, idx) => filterFeaturePoints(dates[idx]));

						cpDynamic &&
							x.list.forEach((__, idx) => {
								const date = dates[idx];
								const pointMap = _.indexBy(cpDynamic.Points, x => x.ValueDate);
								const point = pointMap[date];

								point ? (x.list[idx] = point.Amount) : (x.list[idx] = 0);
							});

						return x;
					})
					.sort((a, b) => {
						const totalAFact = a.list.reduce((acc, el) => acc + (el !== null ? el : 0), 0);
						const totalBFact = b.list.reduce((acc, el) => acc + (el !== null ? el : 0), 0);

						return totalAFact > totalBFact ? -1 : 1;
					});

				return data;
			};

			const top = makeSplineData(topSeries);
			const other = makeSplineData(otherSeries);

			if (otherSeries.length > 0 && other.length > 0) {
				const list = Array(other[0].list.length).fill(0);

				for (let i = 0; i < other[0].list.length; i++) {
					for (let j = 0; j < other.length; j++) {
						list[i] += other[j].list[i];
					}
				}

				top.push({
					ID: -2,
					IDs: otherIDs,
					Name: `Ещё ${otherIDs.length}`,
					list,
				});
			}

			return top;
		},
	);

const selectDataByCounterpartyPayerForSpline = selectDataByCounterpartyForSpline(true);
const selectDataByCounterpartyRecipientForSpline = selectDataByCounterpartyForSpline(false);

const selectDataByCounterparty = createSelector(
	selectDataByCounterpartyPayerForBar,
	selectDataByCounterpartyRecipientForBar,
	selectDataByCounterpartyPayerForSpline,
	selectDataByCounterpartyRecipientForSpline,
	(
		dataPayerForBar: ReturnType<typeof selectDataByCounterpartyPayerForBar>,
		dataRecipientForBar: ReturnType<typeof selectDataByCounterpartyPayerForBar>,
		dataPayerForSpline: ReturnType<typeof selectDataByCounterpartyPayerForSpline>,
		dataRecipientForSpline: ReturnType<typeof selectDataByCounterpartyRecipientForSpline>,
	) => {
		return {
			dataPayerForBar,
			dataRecipientForBar,
			dataPayerForSpline,
			dataRecipientForSpline,
		};
	},
);

function selectTenantEntityIdMap(state: IAppState) {
	return state.bpl.main.tenantEntityIdMap;
}

function selectFundsRegisterIdMap(state: IAppState) {
	return state.bpl.main.fundsRegisterIdMap;
}

function selectBusinessUnitIdMap(state: IAppState) {
	return state.bpl.main.businessUnitIdMap;
}

function selectProjectIdMap(state: IAppState) {
	return state.bpl.main.projectIdMap;
}

function selectIsSettingsMode(state: IAppState) {
	return state.bpl.main.isSettingsMode;
}

function selectGridLayout(state: IAppState) {
	return state.bpl.main.gridLayout;
}

function selectGridLayoutRestored(state: IAppState) {
	return state.bpl.main.gridLayoutRestored;
}

function selectIsIncludesVAT(state: IAppState) {
	return state.bpl.main.isIncludesVAT;
}

const selectFundsRegisterStatisticsMap = createSelector(
	selectAsyncFundsRegisterStatistics.selectItem,
	fundsRegisterStatistics => {
		return createObjectMap(fundsRegisterStatistics, x => x.FundsRegister?.ID);
	},
);

const selectDirectionFrsMap = createSelector(
	selectFundsRegisterStatisticsMap,
	selectTenantEntityIdMap,
	selectFundsRegisterIdMap,
	(source, entityIdsMap, frIdsMap) => {
		const frsMap = Object.keys(source)
			.map(key => source[key])
			.filter(x =>
				entityIdsMap && Object.keys(entityIdsMap).length > 0 ? entityIdsMap[x.FundsRegister?.LegalEntity?.ID] : true,
			)
			.filter(x => (frIdsMap && Object.keys(frIdsMap).length > 0 ? frIdsMap[x.FundsRegister?.ID] : true))
			.filter(x => !x.FundsRegister.Archived)
			.reduce((acc, x) => ((acc[x.FundsRegister.ID] = x), acc), {});

		return frsMap;
	},
);

function selectIsTourOpen(state: IAppState) {
	return state.bpl.main.isTourOpen;
}

function selectCardExpandedMap(state: IAppState) {
	return state.bpl.main.expanded;
}

function selectBusinessUnitAnalyticFilter(state: IAppState) {
	return state.bpl.main.analytics.businessUnitFilter;
}

function selectProjectAnalyticFilter(state: IAppState) {
	return state.bpl.main.analytics.projectFilter;
}

function selectCashflowItemAnalyticFilter(state: IAppState) {
	return state.bpl.main.analytics.cashflowItemFilter;
}

function selectCounterpartyAnalyticFilter(state: IAppState) {
	return state.bpl.main.analytics.counterpartyFilter;
}

const selectFastAnalyticsHeaderData = createSelector(
	selectAsyncAnalyticsOperations.selectItem,
	selectBusinessUnitAnalyticFilter,
	selectProjectAnalyticFilter,
	selectCashflowItemAnalyticFilter,
	selectCounterpartyAnalyticFilter,
	(plList, businessUnitFilter, projectFilter, cfiFilter, cpFilter) => {
		const now = moment();
		const filterName = businessUnitFilter
			? 'Бизнес-юнит'
			: projectFilter
			? 'Проект'
			: cfiFilter
			? 'Статья'
			: cpFilter
			? 'Контрагент'
			: '';
		const name = businessUnitFilter
			? businessUnitFilter.Name
			: projectFilter
			? projectFilter.Name
			: cfiFilter
			? cfiFilter.Name
			: cpFilter
			? cpFilter.Name
			: '';
		const filteredList = plList.filter(pl => {
			const isOverdue = moment(pl.CashflowDate, BASE_DATE_FORMAT).isBefore(now, 'day');

			return pl.Status === 2 || (pl.Status === 1 && !isOverdue);
		});

		const value = {
			filterName,
			name,
			totalAmount: filteredList.reduce((acc, pl) => Number(acc) + (pl.Expense ? -1 : 1) * Number(pl.Amount), 0),
			count: filteredList.length,
		};

		return value;
	},
);

const selectFastAnalyticsSplineData = createSelector(
	selectDateRange,
	selectAsyncAnalyticsOperations.selectItem,
	(period, plList) => {
		const dateAnalysisMoment = moment();
		const filteredPLList = plList.filter(pl => {
			const isOverdue = moment(pl.CashflowDate, BASE_DATE_FORMAT).isBefore(dateAnalysisMoment, 'day');

			return pl.Status === 2 || (pl.Status === 1 && !isOverdue);
		});
		const { diffInYears, startYear, startQuarter, startMonth } = extractTimeDataForXAxis(
			period.dateStart,
			period.dateEnd,
		);
		const data = {
			amountFact: [],
			amountForecast: [],
			incomeFact: [],
			incomeForecast: [],
			expenseFact: [],
			expenseForecast: [],
			plList: [],
			subdivide: false,
		};

		fillWithZeroSplineDataList(diffInYears, period.dateStart, period.dateEnd, data, (item, list) => {
			item.amountFact = [...list];
			item.amountForecast = [...list];
			item.incomeFact = [...list];
			item.incomeForecast = [...list];
			item.expenseFact = [...list];
			item.expenseForecast = [...list];
			item.plList = list.map(() => []);
		});

		const everyIncome = filteredPLList.every(pl => !pl.Expense);
		const everyExpense = filteredPLList.every(pl => pl.Expense);
		data.subdivide = !(everyIncome || everyExpense);
		const nowMoment = moment().endOf('month');
		const nowMonthNumber = +nowMoment.format('M');
		const nowQuarterNumber = +nowMoment.format('Q');
		const nowYearNumber = +nowMoment.format('YYYY');
		const indexOfCurrMonth = getIndexOfMonth(nowYearNumber, startYear, nowMonthNumber, startMonth);
		const indexOfCurrQuarter = getIndexOfQuarter(nowYearNumber, startYear, nowQuarterNumber, startQuarter);
		const primaryIndex = diffInYears === 0 ? indexOfCurrMonth : indexOfCurrQuarter;

		data.amountFact = data.amountFact.map((el, index) => (index > primaryIndex ? null : el));
		data.incomeFact = data.incomeFact.map((el, index) => (index > primaryIndex ? null : el));
		data.expenseFact = data.expenseFact.map((el, index) => (index > primaryIndex ? null : el));

		data.amountForecast = data.amountForecast.map((el, index) => (index < primaryIndex ? null : el));
		data.incomeForecast = data.incomeForecast.map((el, index) => (index < primaryIndex ? null : el));
		data.expenseForecast = data.expenseForecast.map((el, index) => (index < primaryIndex ? null : el));

		filteredPLList.forEach(pl => {
			const dateMoment = moment(pl.CashflowDate, BASE_DATE_FORMAT);
			const currentYearNumber = +dateMoment.format('YYYY');
			const currentMonthNumber = +dateMoment.format('M');
			const currentQuarterNumber = +dateMoment.format('Q');

			if (diffInYears === 0) {
				const isSame = dateMoment.isSame(nowMoment, 'month');
				const isAfter = dateMoment.isAfter(nowMoment, 'month');
				const isSameOrAfter = isSame || isAfter;
				const indexOfMonth = getIndexOfMonth(currentYearNumber, startYear, currentMonthNumber, startMonth);

				if (indexOfMonth >= 0 && indexOfMonth <= indexOfCurrMonth) {
					const check = pl.Status === 2 && !isAfter;

					data.amountFact[indexOfMonth] += check ? pl.Amount : 0;
					data.incomeFact[indexOfMonth] += check && !pl.Expense ? pl.Amount : 0;
					data.expenseFact[indexOfMonth] += check && pl.Expense ? pl.Amount : 0;
					check && data.plList[indexOfMonth].push(pl);
				}
				if (indexOfMonth >= indexOfCurrMonth) {
					data.amountForecast[indexOfMonth] += isSameOrAfter ? pl.Amount : 0;
					data.incomeForecast[indexOfMonth] += isSameOrAfter && !pl.Expense ? pl.Amount : 0;
					data.expenseForecast[indexOfMonth] += isSameOrAfter && pl.Expense ? pl.Amount : 0;
					isSameOrAfter && data.plList[indexOfMonth] && data.plList[indexOfMonth].push(pl);
				}
			} else {
				const isSame = dateMoment.isSame(nowMoment, 'quarter');
				const isAfter = dateMoment.isAfter(nowMoment, 'quarter');
				const isSameOrAfter = isSame || isAfter;
				const indexOfQuarter = getIndexOfQuarter(currentYearNumber, startYear, currentQuarterNumber, startQuarter);

				if (indexOfQuarter >= 0 && indexOfQuarter <= indexOfCurrQuarter) {
					const check = pl.Status === 2 && !isAfter;

					data.amountFact[indexOfQuarter] += check ? pl.Amount : 0;
					data.incomeFact[indexOfQuarter] += check && !pl.Expense ? pl.Amount : 0;
					data.expenseFact[indexOfQuarter] += check && pl.Expense ? pl.Amount : 0;
					check && data.plList[indexOfQuarter].push(pl);
				}
				if (indexOfQuarter >= indexOfCurrQuarter) {
					data.amountForecast[indexOfQuarter] += isSameOrAfter ? pl.Amount : 0;
					data.incomeForecast[indexOfQuarter] += isSameOrAfter && !pl.Expense ? pl.Amount : 0;
					data.expenseForecast[indexOfQuarter] += isSameOrAfter && pl.Expense ? pl.Amount : 0;
					isSameOrAfter && data.plList[indexOfQuarter] && data.plList[indexOfQuarter].push(pl);
				}
			}
		});

		return data;
	},
);

const selectDetalizationOperations = createSelector(
	selectAsyncAnalyticsOperations.selectItem,
	selectAnalyticsDetalizationIDs,
	(plList, IDs) => {
		const filteredList = plList.filter(pl => IDs.some(ID => ID === pl.ID));

		return filteredList;
	},
);

function selectAnalyticsDetalizationOptions(state: IAppState) {
	return state.bpl.main.analytics.options;
}

function selectAnalyticsDetalizationIDs(state: IAppState) {
	return state.bpl.main.analytics.operationIDs;
}

function selectIsAnalyticsDetalizationOpen(state: IAppState) {
	return state.bpl.main.analytics.isDetalizationOpen;
}

function selectForecastDateRange(state: IAppState) {
	return state.bpl.main.forecastDateRange;
}

const selectForecastData = createSelector(
	selectForecastDateRange,
	selectAsyncCashflowForecast.selectItem,
	(dateRange, forecast) => {
		type Point = {
			y: number;
			date: string;
			selected?: boolean;
			cashGapBalance?: number;
		};
		const dates = createDateList(dateRange.dateStart, dateRange.dateEnd, 'day');
		const incomeList: Array<Point> = [];
		const expenseList: Array<Point> = [];
		const resultList: Array<Point> = [];
		const data = {
			categories: dates,
			incomeList,
			expenseList,
			resultList,
		};

		if (!forecast) return data;

		const date = today();
		const cashGapBalance = (forecast?.CashBalances || []).find(x => {
			if (x.OutgoingBalance >= 0) return false;
			const { isAfterOrSame } = compareDates(x.ValueDate, date, 'day');

			return isAfterOrSame();
		});

		for (const date of dates) {
			const totalIncome = getSum(
				forecast.Incomes.filter(x => x.ValueDate === date && x.Plan),
				x => x.Amount,
			);
			const totalExpense = getSum(
				forecast.Expenses.filter(x => x.ValueDate === date && x.Plan),
				x => x.Amount,
			);
			const isCashGap = cashGapBalance ? date === cashGapBalance.ValueDate : false;

			incomeList.push({
				y: safeNumber(totalIncome),
				date: date,
			});
			expenseList.push({
				y: safeNumber(totalExpense),
				date: date,
			});
			resultList.push({
				y: safeNumber(totalIncome - totalExpense),
				date: date,
				selected: isCashGap,
				cashGapBalance: isCashGap ? cashGapBalance.OutgoingBalance : 0,
			});
		}

		return data;
	},
);

export { selectBalanceIndicatorRight, selectForecastIndicatorRight };

export const mainBplSelectorsPack = {
	selectAsyncDashboardData,
	selectAsyncAciDynamicList,
	selectAsyncPlListForDetalization,
	selectAsyncAnalyticsOperations,
	selectAsyncCashflowForecast,
	selectDateRange,
	selectDetalizationOptions,
	selectIsDetalizationOpen,
	selectTransformedAciSeriesMap,
	selectDataByDirection,
	selectDataByCashflowIncome,
	selectDataByCashflowExpense,
	selectDataByResult,
	selectDataByCounterparty,
	selectTenantEntityIdMap,
	selectFundsRegisterIdMap,
	selectBusinessUnitIdMap,
	selectProjectIdMap,
	selectIsSettingsMode,
	selectGridLayout,
	selectGridLayoutRestored,
	selectIsIncludesVAT,
	selectDirectionFrsMap,
	selectIsTourOpen,
	selectCardExpandedMap,
	selectBusinessUnitAnalyticFilter,
	selectProjectAnalyticFilter,
	selectCashflowItemAnalyticFilter,
	selectCounterpartyAnalyticFilter,
	selectFastAnalyticsHeaderData,
	selectFastAnalyticsSplineData,
	selectDetalizationOperations,
	selectAnalyticsDetalizationOptions,
	selectIsAnalyticsDetalizationOpen,
	selectForecastDateRange,
	selectForecastData,
};
