import React, { memo, useState } from 'react';

import api from '@api';
import { useMapDispatch, useMapState } from '@core/flux';
import { CMSDataType, DrawerZone } from '@funds-registers/models';
import { selectFundsRegisterForAutoInsert } from '@funds-registers/selectors';
import { mainIntegrationsActionsPack } from '@integrations/actions';
import {
	selectImportDateRange,
	selectSelectedCMS,
	selectSelectedExternalSystemAccount,
	selectSelectedExternalSystemAccountID,
} from '@integrations/selectors';
import { openMarketplaceOperationRulesFormDrawer } from '@marketplaces/components/rules-form';
import { useExternalServiceResponse } from '@platform/shared/utils';
import { selectCurrencyListByID } from '@shared/selectors/currency-list';
import { getPropInSafe } from '@utils';
import { XCmsImport, XCmsImportProps } from './cms-import.view';

type CmsImportProps = {
	onSetStatus: React.Dispatch<React.SetStateAction<AsyncProgressStatus>>;
	dataType: CMSDataType;
} & Pick<XCmsImportProps, 'onSetZone' | 'status' | 'dataType'>;

const CmsImport: React.FC<CmsImportProps> = memo(({ onSetZone, status, onSetStatus, dataType }) => {
	const [
		dateRange,
		selectedCMS,
		selectedExternalSystemAccountID,
		selectedExternalSystemAccount,
		autoInsertFundsRegister,
		currenciesMap,
	] = useMapState([
		selectImportDateRange,
		selectSelectedCMS,
		selectSelectedExternalSystemAccountID,
		selectSelectedExternalSystemAccount,
		selectFundsRegisterForAutoInsert,
		selectCurrencyListByID,
	]);
	const [setLastImportedAccountData, setLastAccountStatementImport] = useMapDispatch([
		mainIntegrationsActionsPack.setLastImportedAccountData,
		mainIntegrationsActionsPack.setLastAccountStatementImport,
	]);

	const [cmsAccounts, setCmsAccounts] = useState<Array<CMSAccount>>([]);
	const [isFetchingCmsAccounts, setIsFetchingCmsAccounts] = useState(true);

	const handleStartImportAccountFromCMS = (cmsAccount: CMSAccount, dateRange: DateRange) => {
		onSetZone(DrawerZone.IMPORT_CMS_PROGRESS);

		const request = {
			cmsAccount,
			cmsGUID: selectedCMS.SubsystemInstanceGUID,
			dateRange,
			dataType: dataType,
			onCheck: (isFinished, status, message) => {
				onSetStatus({ status, message });
			},
			onFinish: response => {
				setLastAccountStatementImport(response);

				const isMarketplace = dataType === CMSDataType.MARKETPLACE_TRANSACTION;

				if (isMarketplace) {
					openMarketplaceOperationRulesFormDrawer({});
					// TODO add zone
				} else {
					onSetZone(DrawerZone.IMPORT_CMS_RESULT);
				}
			},
		};

		if (dataType == CMSDataType.POS_TERMINAL) {
			console.log('Start importing transactions from CMS.');
			api.fundsPack.cashManagementSystem.importCardTransactionFromCMS(request).then(() => {
				onSetZone(DrawerZone.IMPORT_CMS_RESULT);
			});
		} else if (dataType == CMSDataType.MARKETPLACE_TRANSACTION) {
			setLastImportedAccountData({
				registerNumber: cmsAccount.AccountNumber,
				legalEntityName: cmsAccount.LegalEntityName,
			});
			setLastAccountStatementImport(null);
			api.fundsPack.cashManagementSystem.importTransactionsFromMarketplace(request).then(() => {
				console.log('Start importing transactions from Marketplace.');
			});
		} else {
			setLastImportedAccountData({
				registerNumber: cmsAccount.AccountNumber,
				legalEntityName: cmsAccount.LegalEntityName,
			});
			setLastAccountStatementImport(null);
			api.fundsPack.cashManagementSystem.importAccountStatementFromCMS(request).then(() => {
				console.log('Start importing account from CMS.');
			});
		}
	};

	const handleFetchCmsAccounts = (legalEntityName?: string, legalEntityTaxCode?: string, legalEntityUID?: string) => {
		const externalSystemAccountGUID = getPropInSafe(selectedExternalSystemAccount, o => o.GUID);
		return new Promise<CMSAccountsResponse>(() => {
			const request = {
				...new api.fundspackage.CMSAccountListRequest(),
				CashManagementSystemID: selectedCMS.ID,
				LegalEntityName: legalEntityName || '',
				LegalEntityTaxCode: legalEntityTaxCode || '',
				LegalEntityUID: legalEntityUID || '',
				ExternalSystemAccountGUID: externalSystemAccountGUID,
				ExternalSystemDataType: dataType,
			};

			api.fundsPack.cashManagementSystem.fetchAccounts(request).then(result => {
				const { success, message } = useExternalServiceResponse(result);

				if (success) {
					setCmsAccounts(result.Accounts);
				} else {
					onSetStatus({ status: 'FAILED', message: `Не удается получить список счетов. ${message}` });
				}
				setIsFetchingCmsAccounts(false);
			});
		});
	};

	return (
		<XCmsImport
			selectedCMS={selectedCMS}
			selectedExternalSystemAccountID={selectedExternalSystemAccountID}
			selectedExternalSystemAccount={selectedExternalSystemAccount}
			dateRange={dateRange}
			dataType={dataType}
			currenciesMap={currenciesMap}
			autoInsertFundsRegister={autoInsertFundsRegister}
			status={status}
			isFetchingCmsAccounts={isFetchingCmsAccounts}
			cmsAccounts={cmsAccounts}
			setLastImportedAccountData={setLastImportedAccountData}
			onSetZone={onSetZone}
			onStartImportAccountFromCMS={handleStartImportAccountFromCMS}
			onFetchCmsAccounts={handleFetchCmsAccounts}
		/>
	);
});

export { CmsImport };
