import styled, { css, keyframes } from 'styled-components';

const Root = styled.div`
	position: relative;
`;

type CoverProps = {
	isCoverActive: boolean;
};

const Cover = styled.div<CoverProps>`
	position: relative;
	transition: opacity 0.4s ease-in-out;

	&::after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 10;
		opacity: 1;
		visibility: hidden;
	}

	${p =>
		p.isCoverActive &&
		css`
			pointer-events: none;
			opacity: 0.3;

			& * {
				pointer-events: none;
				user-select: none;
			}

			&::after {
				visibility: visible;
			}
		`}
`;

const pulseMotion = keyframes`
	0%,
	80%,
	100% {
		box-shadow: 0 0;
		height: 4em;
	}
	40% {
		box-shadow: 0 -2em;
		height: 5em;
	}
`;

const UpdatingIndicator = styled.div`
	position: relative;
	font-size: 8px;
	animation: ${pulseMotion} 1s infinite ease-in-out;
	width: 1em;
	height: 4em;
	animation-delay: -0.16s;
	z-index: 20;
	zoom: 0.8;

	${p => css`
		color: ${p.theme.palette.accent};
		background-color: ${p.theme.palette.accent};

		&::before,
		&::after {
			position: absolute;
			top: 0;
			content: '';
			background-color: ${p.theme.palette.accent};
			animation: ${pulseMotion} 1s infinite ease-in-out;
			width: 1em;
			height: 4em;
		}
	`}

	&::before {
		left: -1.5em;
		animation-delay: -0.32s;
	}

	&::after {
		left: 1.5em;
	}
`;

type IndicatorLayout = {
	isFixed: boolean;
};

const IndicatorLayout = styled.div<IndicatorLayout>`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 100;

	${p =>
		p.isFixed &&
		css`
			position: fixed;
		`}
`;

const DefaultFallback = styled.div`
	width: 100%;
	height: 100px;
`;

export { Root, Cover, UpdatingIndicator, IndicatorLayout, DefaultFallback };
