import styled, { css } from 'styled-components';

type SectionItemProps = {
	disabled?: boolean;
};

const BadgeLabel = styled.div`
	font-size: 10px;
	font-weight: 700;
`;

const BadgeLayout = styled.div`
	align-items: center;
	display: grid;
	grid-gap: 4px;
	grid-template-columns: 6px auto;
	padding: 2px 4px;
`;

const ButtonIconMenuLayout = styled.div`
	align-content: center;
	display: grid;
	height: 100%;
	padding-right: 12px;
`;

const ButtonLabel = styled.div`
	font-size: 14px;
	font-weight: 500;
	overflow: hidden;

	@media (max-width: 800px) {
		display: none;
	}
`;

const ButtonLayout = styled.div`
	align-items: center;
	background-color: transparent;
	cursor: pointer;
	display: grid;
	grid-gap: 8px;
	grid-template-columns: 24px auto auto;
	height: 100%;
	justify-items: center;
	padding-left: 12px;
	transition: background-color 0.2s ease-in-out;

	${p => css`
		&:hover {
			background-color: ${p.theme.palette.stealth};
		}
	`}

	@media (max-width: 800px) {
		grid-template-columns: 24px 22px;
	}
`;

const MenuLayout = styled.div`
	display: grid;
	grid-gap: 12px;
	padding: 8px 20px;
`;

const Root = styled.div`
	height: 100%;
	max-width: 280px;
	position: relative;

	@media (max-width: 800px) {
		max-width: 90px;
	}
`;

const Section = styled.div`
	align-items: center;
	display: grid;
`;

const SectionHeader = styled.div`
	font-size: 12px;
	font-weight: 500;
	padding: 8px;
	text-transform: uppercase;

	${p => css`
		color: ${p.theme.palette.label};
	`}
`;

const SectionItem = styled.div<SectionItemProps>`
	align-items: center;
	cursor: pointer;
	display: grid;
	font-size: 14px;
	grid-gap: 16px;
	grid-template-columns: 16px auto;
	padding: 8px;
	pointer-events: auto;
	transition: background-color 0.2s ease-in-out;

	${p => css`
		&:hover {
			background-color: ${p.theme.palette.stealth};
		}
	`}

	${p =>
		p.disabled &&
		css`
			color: ${p.theme.palette.label};
			cursor: default;
			pointer-events: none;

			&:hover {
				background-color: transparent;
			}
		`}
`;

const SectionItemBadge = styled.div`
	align-items: center;
	display: grid;
	grid-gap: 12px;
	grid-template-columns: auto auto;
`;

const SectionItemIcon = styled.span`
	align-self: center;
	display: grid;
	justify-self: center;
`;

export {
	BadgeLabel,
	BadgeLayout,
	ButtonIconMenuLayout,
	ButtonLabel,
	ButtonLayout,
	MenuLayout,
	Root,
	Section,
	SectionHeader,
	SectionItem,
	SectionItemBadge,
	SectionItemIcon,
};
