import React from 'react';

import { highlight } from '@utils/strings';
import { Highlight } from './styled';

export type HighLightedTextProps = {
	value: string;
	query: string;
	highlightColor?: string;
	formatValue?: (value: string) => string;
};

const HighLightedText: React.FC<HighLightedTextProps> = ({ value, query, highlightColor, formatValue = v => v }) => {
	const matches = highlight.match(value, query, formatValue(value));
	const parts = highlight.parse(formatValue(value), matches);

	return (
		<span>
			{parts.map((x, idx) => {
				return (
					<Highlight key={`${x.text}${idx}`} highlight={x.highlight} highlightColor={highlightColor}>
						{x.text}
					</Highlight>
				);
			})}
		</span>
	);
};

HighLightedText.displayName = 'HighLightedText';
HighLightedText.defaultProps = {};

export { HighLightedText };
