import * as _ from 'underscore';

import { checkAsyncItemLoadAction, createDefaultAsyncItem } from '@core/libs/reducer-creator';
import { FETCH_CURRENCIES } from '../actions/fetch-currencies.action';

export interface ICurrencyListState extends StoreAsyncItem<Record<string, Currency>> {}

const initialState = {
	...createDefaultAsyncItem({}),
};

export default function (state: ICurrencyListState = initialState, action: AsyncAction<Array<Currency>>) {
	switch (action.type) {
		case FETCH_CURRENCIES:
			return {
				...state,
				...checkAsyncItemLoadAction(action, state, response => _.indexBy(response, item => item.DigitCode)),
			};

		default:
			return state;
	}
}
