import styled, { css } from 'styled-components';

type RootProps = {
	topShift: number;
};

interface IKPILayout {
	invoiceViewerMode: boolean;
}

const Root = styled.header`
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	width: 100%;
	height: 70px;
	z-index: 1000;
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: stretch;
	padding-left: 80px;

	${(p: RootProps) => css`
		top: ${p.topShift}px;
	`}

	${p => css`
		background-color: ${p.theme.palette.space};
		box-shadow: 0px 1px 0px ${p.theme.palette.stealth};
	`}
`;

const KPILayout = styled.div`
	position: relative;
	flex-shrink: 1;
`;

const TitleLayout = styled.div`
	position: relative;
	flex: 1 2 auto;
	display: flex;
	align-items: center;
`;

const ControlsLayout = styled.div`
	flex-shrink: 0;
	position: relative;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
`;

export { Root, KPILayout, TitleLayout, ControlsLayout };
