import { useLayoutEffect, useState, useMemo, DependencyList } from 'react';

import { useMounted } from './use-mounted';

export type AsyncValue<T> = {
	value: T;
	isFetching: boolean;
	isLoaded: boolean;
	didInvalidate: boolean;
	forceInvalidate: () => void;
};

type UseAsyncValueOptions<T> = {
	waitingFor?: () => boolean;
	initialValue?: T;
	effect: Effect<T>;
	skipMountingFetch?: boolean;
};

type Effect<T> = () => Promise<T>;

function useAsyncValue<T = null>(
	initializer: UseAsyncValueOptions<T> | Effect<T>,
	deps: DependencyList = [],
): AsyncValue<T> {
	const canStart = typeof initializer === 'object' ? (!initializer.waitingFor ? true : initializer.waitingFor()) : true;
	const skipMountingFetch = typeof initializer === 'object' ? Boolean(initializer.skipMountingFetch) : false;
	const initialValue: T = typeof initializer === 'object' ? initializer.initialValue || null : null;
	const effect: Effect<T> = typeof initializer === 'object' ? initializer.effect : initializer;
	const { mounted } = useMounted();
	const [_, update] = useState(0);
	const forceUpdate = () => mounted() && update(x => x + 1);
	const forceInvalidate = () => {
		scope.value = {
			...scope.value,
			didInvalidate: true,
			isFetching: true,
		};
		forceUpdate();
	};
	const scope = useMemo(
		() => ({
			value: {
				value: initialValue,
				isFetching: !skipMountingFetch,
				isLoaded: false,
				didInvalidate: false,
				forceInvalidate,
			},
		}),
		[],
	);

	useLayoutEffect(() => {
		scope.value = {
			...scope.value,
			didInvalidate: true,
		};
		forceUpdate();
	}, [...deps]);

	useLayoutEffect(() => {
		if (!scope.value.didInvalidate || !canStart) return;
		scope.value = {
			...scope.value,
			isFetching: true,
		};
		forceUpdate();
		runEffect();
	}, [scope.value.didInvalidate, canStart]);

	const runEffect = () =>
		(async () => {
			const value = await effect();
			scope.value = {
				...scope.value,
				value: value,
				isFetching: false,
				isLoaded: true,
				didInvalidate: false,
				forceInvalidate,
			};
			forceUpdate();
		})();

	return scope.value;
}

export { useAsyncValue };
