import React from 'react';

import { Portal } from '@ui/portal';
import { Box } from '@ui/box';
import { CloseButton } from '@ui/button/close-button';
import { Root, Content } from './styled';

type AnchorType = 'bottom';

export type SlidePanelProps = {};

export type SlidePanelState = {
	isOpen?: boolean;
	anchor?: AnchorType;
	content?: React.ReactNode;
	onClose?: () => void;
};

class SlidePanelBase extends React.PureComponent<SlidePanelProps, SlidePanelState> {
	static displayName = 'SlidePanel';
	state: SlidePanelState = {
		isOpen: false,
		anchor: 'bottom' as AnchorType,
		content: null,
		onClose: () => {},
	};
	rootRef: HTMLElement = null;
	dispose = () => {};

	setRootRef = (ref: HTMLElement) => (this.rootRef = ref);

	public open = (state: Partial<SlidePanelState>) => {
		this.setState({ ...state, isOpen: true });
	};

	public close = () => {
		const { isOpen, onClose } = this.state;
		if (!isOpen) return;

		this.dispose();
		this.setState({ isOpen: false });
		setTimeout(() => {
			typeof onClose === 'function' && onClose();
			this.reset();
		}, 200);
	};

	public reset = () =>
		this.setState({
			content: null,
			onClose: () => {},
		});

	public setContent = (state: Partial<SlidePanelState>) => this.setState({ ...state });

	render() {
		const { isOpen, content } = this.state;

		return (
			<Portal>
				<Root id='slide-panel' ref={this.setRootRef} isOpen={isOpen}>
					<Box position='absolute' top={8} right={8}>
						{isOpen && <CloseButton onClick={this.close} />}
					</Box>
					<Box width='100%'>{content}</Box>
				</Root>
			</Portal>
		);
	}
}

const scope = {
	ref: null,
};

const setRef = ref => (scope.ref = ref);

const getRef = () => scope.ref;

const SlidePanel = {
	Base: SlidePanelBase,
	Content,
	setRef,
	get: getRef as () => SlidePanelBase,
};

export { SlidePanel };
