import React from 'react';

import { Spinner, SpinnerAutoLayout } from '@ui/spinner';
import { CMSDataType, DrawerZone } from '@funds-registers/models';
import { CmsList } from '@funds-registers/components/cms-list';
import { CmsAccounts } from '@funds-registers/components/cms-accounts';
import { CmsSignUp } from '@funds-registers/components/cms-signup';
import { CmsImport } from '@funds-registers/components/cms-import';
import { CmsRefresh } from '@funds-registers/components/cms-refresh';
import { ImportProgress } from '@funds-registers/components/import-progress';
import { ImportResult } from '@funds-registers/components/import-result';

export type XCmsConnectFormProps = {
	isFetching: boolean;
	zone: DrawerZone;
	setZone: React.Dispatch<React.SetStateAction<DrawerZone>>;
	status: AsyncProgressStatus;
	setStatus: React.Dispatch<React.SetStateAction<AsyncProgressStatus>>;
	dataType: CMSDataType;
};

const XCmsConnectForm: React.FC<XCmsConnectFormProps> = ({
	isFetching,
	zone,
	setZone,
	status,
	setStatus,
	dataType,
}) => {
	const map = {
		[DrawerZone.LIST]: () => <CmsList onSetZone={setZone} dataType={dataType} />,
		[DrawerZone.ACOUNTS]: () => <CmsAccounts onSetZone={setZone} />,
		[DrawerZone.SIGNUP]: () => <CmsSignUp onSetZone={setZone} />,
		[DrawerZone.IMPORT_CMS]: () => (
			<CmsImport onSetZone={setZone} status={status} onSetStatus={setStatus} dataType={dataType} />
		),
		[DrawerZone.IMPORT_CMS_PROGRESS]: () => <ImportProgress zone={zone} status={status} />,
		[DrawerZone.IMPORT_CMS_RESULT]: () => <ImportResult zone={zone} setZone={setZone} status={status} />,
		[DrawerZone.REFRESH_CMS]: () => <CmsRefresh onSetZone={setZone} status={status} onSetStatus={setStatus} />,
		[DrawerZone.REFRESH_CMS_PROGRESS]: () => <ImportProgress zone={zone} status={status} />,
		[DrawerZone.REFRESH_CMS_RESULT]: () => <ImportResult zone={zone} setZone={setZone} status={status} />,
	};
	const zoneFetchingBlackList = [DrawerZone.SIGNUP];
	const isFetchingCalculated = zoneFetchingBlackList.includes(zone) ? false : isFetching;

	if (isFetchingCalculated) {
		return (
			<SpinnerAutoLayout appearance='max-drawer'>
				<Spinner />
			</SpinnerAutoLayout>
		);
	}

	return map[zone] ? map[zone]() : null;
};

export { XCmsConnectForm };
