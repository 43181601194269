import { ProjectStatisticsRatiosCode, Sorting, VirtualListGroupCode } from '@project-management/models';

function getProjectVirtualListGroupKey(code: VirtualListGroupCode, project: Project) {
	const map = {
		[VirtualListGroupCode.LEGAL_ENTITY]: project.TenantLegalEntity?.ID || -1,
		[VirtualListGroupCode.CLIENT]: project.Client?.ID || -1,
		[VirtualListGroupCode.MANAGER]: project.Manager?.ID || -1,
	};

	return map[code] || null;
}

function getProjectVirtualListGroupName(code: VirtualListGroupCode, project: Project) {
	const map = {
		[VirtualListGroupCode.LEGAL_ENTITY]: project.TenantLegalEntity?.Name || '',
		[VirtualListGroupCode.CLIENT]: project.Client?.Name || '',
		[VirtualListGroupCode.MANAGER]: project.Manager?.Name || '',
	};

	return map[code] || null;
}

export type ProjectWithStatistics = Project & {
	statistics: Record<ProjectStatisticsRatiosCode, number>;
};

enum groupsNames {
	LEGAL_ENTITY = 'TenantLegalEntity',
	CLIENT = 'Client',
	MANAGER = 'Manager',
}

export type SortWithGroupType = (
	array: Array<ProjectWithStatistics>,
	sort: Sorting,
	groupCode: VirtualListGroupCode,
) => Array<Project>;

type GroupedType = {
	[key: string]: ProjectWithStatistics[];
	[key: number]: ProjectWithStatistics[];
};

export const sortWithGroup: SortWithGroupType = (array, sort, groupCode) => {
	const grouped: GroupedType = array.reduce((accum, obj) => {
		const key = obj[groupsNames[groupCode]]?.ID;
		(accum[key] = accum[key] || []).push(obj);
		return accum;
	}, {});

	for (const key in grouped) {
		if (key in grouped) {
			grouped[key].sort((a, b) => {
				return sort.isAscOrder
					? b.statistics[sort.sortBy] - a.statistics[sort.sortBy]
					: a.statistics[sort.sortBy] - b.statistics[sort.sortBy];
			});
		}
	}
	return Object.keys(grouped).reduce((acc, key) => acc.concat(grouped[key]), []);
};

export { getProjectVirtualListGroupKey, getProjectVirtualListGroupName };
