import * as moment from 'moment';

import { createTypes, createAsyncAction, createStaticAction } from '@flux';
import { BASE_DATE_FORMAT } from '@shared/constants/time';
import { FetchOperationsOptions as FetchOperationsApiOptions, IntervalType } from '@core/api/pl';
import { mainPlanningMasterSelectorsPack } from '@pl-planning/selectors';

type FetchOperationsOptions = FetchOperationsApiOptions;

type ActionTypes = {
	SET_DATE_RANGE: string;
	FETCH_PLANNING_DATA: string;
	FETCH_PL_OPERATIONS: string;
	INVALIDATE_PL_OPERATIONS: string;
	SELECT_CASHFLOW_ITEM: string;
	SET_FILTER_BY_PLAN: string;
	SET_FILTER_BY_SIMILAR: string;
};

const types = createTypes<ActionTypes>(
	[
		'SET_DATE_RANGE',
		'FETCH_PLANNING_DATA',
		'FETCH_PL_OPERATIONS',
		'INVALIDATE_PL_OPERATIONS',
		'SELECT_CASHFLOW_ITEM',
		'SET_FILTER_BY_PLAN',
		'SET_FILTER_BY_SIMILAR',
	],
	'PLANNING_MASTER',
);

const actions = {
	setDateRange: (dateRange: DateRange) => createStaticAction(types.SET_DATE_RANGE)(dateRange),
	fetchPlanningData: createAsyncAction(types.FETCH_PLANNING_DATA, (api, _, __, dateRange: DateRange) => {
		return new Promise<PLOperationDynamics>(resolve => {
			api.plPack.plOperation
				.fetchAccountChartDynamics({
					dateRange,
					cashflowDateAggregation: true,
					intervalType: IntervalType.MONTH,
				})
				.then(result => resolve(result));
		});
	}),
	fetchOperations: createAsyncAction(
		types.FETCH_PL_OPERATIONS,
		(api, _, __, options: FetchOperationsOptions) => {
			return new Promise<Array<PLOperationBrief>>(resolve => {
				const { dateRange } = options;
				const optionsOne: FetchOperationsOptions = {
					dateRange: {
						dateStart: moment(dateRange.dateStart, BASE_DATE_FORMAT).startOf('month').format(BASE_DATE_FORMAT),
						dateEnd: moment(dateRange.dateStart, BASE_DATE_FORMAT).endOf('month').format(BASE_DATE_FORMAT),
					},
					takeBy: 'fact',
				};
				const optionsTwo: FetchOperationsOptions = {
					dateRange: {
						dateStart: moment(dateRange.dateEnd, BASE_DATE_FORMAT).startOf('month').format(BASE_DATE_FORMAT),
						dateEnd: moment(dateRange.dateEnd, BASE_DATE_FORMAT).endOf('month').format(BASE_DATE_FORMAT),
					},
					takeBy: 'plan',
				};

				Promise.all([
					api.plPack.plOperation.fetchOperationsBriefs(optionsOne),
					api.plPack.plOperation.fetchOperationsBriefs(optionsTwo),
				]).then(result => {
					const plList = [...result[0], ...result[1]];

					resolve(plList);
				});
			});
		},
		{
			isValidSelector: state =>
				mainPlanningMasterSelectorsPack.selectAsyncPlOperations.selectIsLoaded(state) &&
				!mainPlanningMasterSelectorsPack.selectAsyncPlOperations.selectDidInvalidate(state),
			isFetchingSelector: mainPlanningMasterSelectorsPack.selectAsyncPlOperations.selectIsFetching,
		},
	),
	invalidateOperations: () => createStaticAction(types.INVALIDATE_PL_OPERATIONS)(),
	selectCashflowItem: (ID: number) => createStaticAction(types.SELECT_CASHFLOW_ITEM)(ID),
	setFilterByPlan: (value: boolean) => createStaticAction(types.SET_FILTER_BY_PLAN)(value),
	setFilterBySimilar: (value: boolean) => createStaticAction(types.SET_FILTER_BY_SIMILAR)(value),
};

export const mainPlanningMasterActionsPack = {
	types,
	actions,
};
