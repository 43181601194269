import { Store } from 'redux';

import { preferencesApi } from '@core/api/preferences';
import { IAppState } from '@store';
import { detectIsValidBrand } from '@utils/brand';
import { ThemeMode, defaultThemeIdentifier } from '@theme';
import { themeActionsPack } from '@shared/actions/theme.actions';
import { selectCurrentTenantID } from '@platform/selectors/context.selectors';
import { SettingsKey } from '@shared/models';

let isEffectRan = false;

function createThemeEffect() {
	return (store: Store<IAppState>) => (next: (action: StoreAction) => void) => async (action: StoreAction) => {
		if (!isEffectRan) {
			const tenantID = selectCurrentTenantID(store.getState());

			if (tenantID > 0) {
				isEffectRan = true;

				const [defaultThemeBrand, defaultThemeMode] = defaultThemeIdentifier;
				const themeBrandResults = await preferencesApi.rest.fetchPreferences(tenantID, [SettingsKey.THEME_BRAND]);
				const sourceThemeBrand = themeBrandResults[SettingsKey.THEME_BRAND]?.value;
				const themeBrand = detectIsValidBrand(sourceThemeBrand) ? sourceThemeBrand : defaultThemeBrand;
				const themeModeResults = await preferencesApi.rest.fetchPreferences(tenantID, [SettingsKey.THEME_MODE]);
				const sourceThemeMode = themeModeResults[SettingsKey.THEME_MODE]?.value;
				const isValidThemeMode = [ThemeMode.DARK, ThemeMode.LIGHT].includes(sourceThemeMode);
				const themeMode = isValidThemeMode ? sourceThemeMode : defaultThemeMode;

				store.dispatch(themeActionsPack.setThemeIdentifier([themeBrand, themeMode]));
			}
		}

		next(action);
	};
}

const runThemeEffect = createThemeEffect();

export { runThemeEffect };
