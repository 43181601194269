import React from 'react';

import { IllustrationBase, IllustrationBaseProps } from './base';

export type SoleProprietorTariffIllustrationProps = IllustrationBaseProps;

const SoleProprietorTariffIllustration: React.FC<SoleProprietorTariffIllustrationProps> = props => {
	return (
		<IllustrationBase {...props}>
			<svg viewBox='0 0 38 43'>
				<g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
					<g transform='translate(-565.000000, -343.000000)' fill='currentColor' fillRule='nonzero'>
						<g transform='translate(511.000000, 276.000000)'>
							<g transform='translate(54.000000, 67.000000)'>
								<path d='M12.9351547,31.0242517 L12.9351547,33.8327575 C12.9386305,34.2679215 12.6162794,34.6385358 12.1819099,34.694545 L5.29624942,35.6244573 C2.27909931,35.9993418 0.00575981524,38.5760647 0.00834180139,41.6155589 L0.0083396042,42.9662511 L1.73102309,42.9662511 L1.73102309,41.6146651 C1.72933487,39.4421224 3.35290762,37.6030531 5.51770439,37.3333349 L12.4085289,36.4026282 C13.7028984,36.2346005 14.6698522,35.1228568 14.6586305,33.8250115 L14.6587272,31.0242517 L12.9351547,31.0242517 Z'></path>
								<path d='M32.6476259,35.6252517 L25.7499492,34.694545 C25.3181617,34.637642 24.9959099,34.2679215 25.0001801,33.8250115 L25.0001801,31.0242517 L23.2765084,31.0242517 L23.2766051,33.8172656 C23.2653834,35.1228568 24.2323372,36.2346005 25.5224365,36.4026282 L32.4261709,37.3342286 C34.5815335,37.6031524 36.2051062,39.4430162 36.203418,41.6155589 L36.203418,42.9484068 L37.9269951,42.9484068 L37.9269931,41.6164527 C37.9294758,38.5760647 35.6561363,36.0001363 32.6476259,35.6252517 Z'></path>
								<path d='M28.4473303,14.6502887 L27.5855427,14.6502887 L27.5855427,16.3738637 L28.4473303,16.3738637 C29.3978984,16.3738637 30.1709053,17.1468707 30.1709053,18.0974388 C30.1709053,19.0480069 29.3978984,19.8210139 28.4473303,19.8210139 L27.5855427,19.8210139 L27.5855427,21.5445889 L28.4473303,21.5445889 C30.3484665,21.5445889 31.8944804,19.9985751 31.8944804,18.0974388 C31.8943811,16.1964018 30.3483672,14.6502887 28.4473303,14.6502887 Z'></path>
								<path d='M9.48800462,16.3738637 L10.3497921,16.3738637 L10.3497921,14.6502887 L9.48800462,14.6502887 C7.58686836,14.6502887 6.0408545,16.1963025 6.0408545,18.0974388 C6.0408545,19.9985751 7.58686836,21.5445889 9.48800462,21.5445889 L10.3497921,21.5445889 L10.3497921,19.8210139 L9.48800462,19.8210139 C8.53743649,19.8210139 7.76442956,19.0480069 7.76442956,18.0974388 C7.76442956,17.1468707 8.5375358,16.3738637 9.48800462,16.3738637 Z'></path>
								<path d='M25.9325751,34.9936582 L25.3810231,36.2776998 C24.2797067,38.8449885 21.764157,40.5039145 18.9710439,40.5039145 C18.9684619,40.5039145 18.9658799,40.5039145 18.9632979,40.5039145 C16.1702841,40.5039145 13.6555289,38.8449885 12.5551062,36.2776998 L12.0035543,34.9936582 L10.4196051,35.6736143 L10.971157,36.9567621 C12.3430855,40.1591201 15.4791062,42.2273903 18.9625035,42.2273903 C18.9650855,42.2273903 18.9685612,42.2273903 18.9719376,42.2273903 C22.4544411,42.2273903 25.5921501,40.1591201 26.9649723,36.9575566 L27.5165242,35.673515 L25.9325751,34.9936582 Z'></path>
								<path d='M17.487097,20.6828014 L18.9409538,14.8950808 L17.2699122,14.4754088 L15.5463372,21.3352494 C15.4816882,21.5920577 15.539485,21.8652517 15.7023487,22.0746905 C15.8661062,22.2841293 16.1168568,22.4064758 16.3823048,22.4064758 L19.829455,22.4064758 L19.829455,20.6829007 L17.487097,20.6829007 L17.487097,20.6828014 Z'></path>
								<path d='M21.1703995,23.3578383 C20.1586582,23.8602333 19.0262587,24.1273695 17.8990231,24.1299515 L16.3823048,24.1299515 L16.3823048,25.8535266 L17.9008106,25.8535266 C19.2934942,25.8500508 20.6886605,25.5208476 21.9365543,24.9020647 L21.1703995,23.3578383 Z'></path>
								<path d='M29.2142794,14.2228707 L25.7671293,8.19035797 C25.5628545,7.83354734 25.1363303,7.67157737 24.7459538,7.79998152 L16.9898661,10.3853441 C14.568261,11.1928106 9.55612933,11.2031386 9.50617783,11.2031386 C9.03049654,11.2031386 8.6443903,11.5892448 8.6443903,12.0649261 L8.6443903,23.898963 C8.63148037,25.377746 9.15969515,26.8057829 10.1327067,27.920903 L13.8564273,32.1789954 C14.6725335,33.1080139 15.8497206,33.6422864 17.0889746,33.6440739 L20.8653279,33.6440739 C22.102,33.6423857 23.2800808,33.1080139 24.0943995,32.1807829 L27.8199076,27.9623141 C28.7928199,26.8488822 29.3220277,25.4226328 29.3091178,23.9455381 L29.3280855,14.6520762 C29.3280855,14.5012286 29.2892564,14.352963 29.2142794,14.2228707 Z M27.584649,23.9464319 C27.584649,23.9499076 27.584649,23.9524896 27.584649,23.9558661 C27.5949769,25.0115012 27.2175104,26.0318822 26.5255381,26.8247506 L22.8018176,31.0406374 C22.3114388,31.5990416 21.6039746,31.9197044 20.8636397,31.9204988 L17.0898684,31.9204988 C16.3470508,31.9196051 15.6403811,31.5990416 15.1516905,31.0423256 L11.4296582,26.7868152 C10.7351039,25.9905704 10.3576374,24.9701894 10.3670716,23.9059145 L10.3670716,12.9138037 C11.9837921,12.8732864 15.4740416,12.7069469 17.5336721,12.0201386 L24.6184434,9.65801848 L27.6019284,14.8778014 L27.584649,23.9464319 Z'></path>
								<path d='M29.1444665,5.42236952 C27.9035242,2.16827252 24.7864711,0.00347575058 21.277254,0 L6.90264203,0 C6.62597229,0 6.36578753,0.132674365 6.20282448,0.358498845 C6.0408545,0.583429561 5.99596767,0.872115473 6.0838545,1.13498152 L7.58766282,5.64471824 C5.65812471,6.9632194 4.73258199,9.39862818 5.37460277,11.7332402 L6.94306005,16.6368291 L8.58560046,16.111097 L7.02747113,11.2419677 C6.54404388,9.48303926 7.37822402,7.61725635 9.01043649,6.8037321 C9.3947552,6.61246651 9.58006236,6.16776905 9.44391224,5.76011316 L8.0978037,1.72347575 L21.2761617,1.72347575 C24.1623256,1.72605774 26.7157113,3.56770901 27.6309261,6.30560739 C27.7016328,6.51842263 27.8523811,6.69429561 28.0505982,6.79767436 L28.2961848,6.92518476 C29.8447806,7.69471594 30.6859122,9.41491455 30.3429053,11.111776 L29.326,16.2049423 L31.0160092,16.5427852 L32.0329145,11.4514065 C32.5252794,9.01768591 31.3333949,6.54781755 29.1444665,5.42236952 Z'></path>
								<path d='M17.2440924,13.7885012 L9.48800462,13.7885012 C9.01232333,13.7885012 8.62621709,14.1746074 8.62621709,14.6502887 L8.62621709,17.2356513 C8.62621709,19.1367875 10.1722309,20.6828014 12.0733672,20.6828014 L14.6587298,20.6828014 C16.5598661,20.6828014 18.1058799,19.1367875 18.1058799,17.2356513 L18.1058799,14.6502887 C18.1058799,14.1746074 17.7197737,13.7885012 17.2440924,13.7885012 Z M16.3823048,17.2356513 C16.3823048,18.1862194 15.6092979,18.9592263 14.6587298,18.9592263 L12.0733672,18.9592263 C11.1227991,18.9592263 10.3497921,18.1862194 10.3497921,17.2356513 L10.3497921,15.5120762 L16.3823048,15.5120762 L16.3823048,17.2356513 Z'></path>
								<path d='M28.4473303,13.7885012 L20.6912425,13.7885012 C20.2155612,13.7885012 19.829455,14.1746074 19.829455,14.6502887 L19.829455,17.2356513 C19.829455,19.1367875 21.3754688,20.6828014 23.2766051,20.6828014 L25.8619677,20.6828014 C27.7631039,20.6828014 29.3091178,19.1367875 29.3091178,17.2356513 L29.3091178,14.6502887 C29.3091178,14.1746074 28.9239053,13.7885012 28.4473303,13.7885012 Z M27.5855427,17.2356513 C27.5855427,18.1862194 26.8125358,18.9592263 25.8619677,18.9592263 L23.2766051,18.9592263 C22.326037,18.9592263 21.55303,18.1862194 21.55303,17.2356513 L21.55303,15.5120762 L27.5855427,15.5120762 L27.5855427,17.2356513 Z'></path>
								<rect x='17.2440924' y='13.7885012' width='3.44715012' height='1.72357506'></rect>
							</g>
						</g>
					</g>
				</g>
			</svg>
		</IllustrationBase>
	);
};

export { SoleProprietorTariffIllustration };
