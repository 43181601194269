import * as projectpackage from 'projectpackage-api';
import { fromStringToBoolean } from '@utils/serializer';

const projectApi = {
	package: projectpackage,
	project: {
		client: new projectpackage.ProjectServiceClient(),
		fetchProjects: () => {
			return new Promise<Array<Project>>(resolve => {
				projectApi.project.client.getProjects(result => {
					resolve(result);
				});
			});
		},
		fetchProjectByID: (ID: number) => {
			return new Promise<Project>(resolve => {
				projectApi.project.client.getProjectByID(ID, result => {
					resolve(result);
				});
			});
		},
		addProject: (project: Project) => {
			return new Promise<Project>(resolve => {
				projectApi.project.client.addProject(project, result => {
					resolve(result);
				});
			});
		},
		updateProject: (project: Project) => {
			return new Promise<Project>(resolve => {
				projectApi.project.client.changeProject(project, result => {
					resolve(result);
				});
			});
		},
		removeProject: (ID: number) => {
			return new Promise<boolean>(resolve => {
				projectApi.project.client.removeProject(ID, result => {
					resolve(fromStringToBoolean(result));
				});
			});
		},
		linkManagerToProjects: (options: LinkManagerToProjectsOptions) => {
			const { managerID, projectIDsForLink = [], projectIDsForUnlink = [] } = options;

			return new Promise<boolean>(async resolve => {
				for (const projectID of projectIDsForLink) {
					const project = await projectApi.project.fetchProjectByID(projectID);

					await projectApi.project.updateProject({ ...project, ManagerID: managerID });
				}

				for (const projectID of projectIDsForUnlink) {
					const project = await projectApi.project.fetchProjectByID(projectID);
					const isSameManager = project.Manager?.ID === managerID;

					if (isSameManager) {
						await projectApi.project.updateProject({ ...project, ManagerID: -1 });
					}
				}

				resolve(true);
			});
		},
		fetchProjectsAccess: (projectID: number) => {
			return new Promise<Array<ProjectEmployeeAccess>>(resolve => {
				projectApi.project.client.getProjectAccessList(projectID, result => {
					resolve(result);
				});
			});
		},
		setProjectsAccess: (accesses: Array<ProjectEmployeeAccess>) => {
			return new Promise<boolean>(resolve => {
				projectApi.project.client.setProjectsAccess(accesses, result => {
					resolve(fromStringToBoolean(result));
				});
			});
		},
	},
};

type LinkManagerToProjectsOptions = {
	managerID: number;
	projectIDsForLink: Array<number>;
	projectIDsForUnlink?: Array<number>;
};

export { projectApi };
