import * as _ from 'underscore';
import * as moment from 'moment';

import {
	StoreAsyncItem,
	createReducer,
	createAsyncInitialState,
	checkAsyncAction,
	invalidateStateFromAction,
	restoreValidationState,
} from '@flux';
import { BASE_DATE_FORMAT } from '@shared/constants/time';
import { FetchPlanningDataReturnType, FetchOperationsOptions, mainPlanningActionsPack } from '@pl-planning/actions';
import { mainTypes as OperationsMainTypes } from '@pl/actions/types';
import { constants as planningConstants } from '../actions/pl-planning.actions';

export type PlanningState = {
	plList: StoreAsyncItem<Array<PLOperationBrief>>;
	plPlanList: StoreAsyncItem<Array<PLOperationBrief>>;
	period: DateRange;
	aciSeries: StoreAsyncItem<Record<string, PLOperationAccountsChartItemSeries>>;
	cfForecast: StoreAsyncItem<CashflowForecast>;
	substr: string;
	selectedCell: {
		row: any;
		col: number;
	};
	selectedPoint: string;
	aciSeriesForKPI: StoreAsyncItem<Record<string, PLOperationAccountsChartItemSeries>>;
	cfForecastForForecastKPI: StoreAsyncItem<CashflowForecast>;
	cfForecastForCashGapKPI: StoreAsyncItem<CashflowForecast>;
	forecastKPIDate: string;
	detalizationOptions: FetchOperationsOptions | null;
	isExpanded: boolean;
};

const initialState = {
	plList: createAsyncInitialState([]),
	plPlanList: createAsyncInitialState([]),
	period: {
		dateStart: moment().startOf('year').format(BASE_DATE_FORMAT),
		dateEnd: moment().endOf('year').format(BASE_DATE_FORMAT),
	},
	aciSeries: createAsyncInitialState({}),
	cfForecast: createAsyncInitialState({}),
	substr: '',
	selectedCell: {
		row: null,
		col: -1,
	},
	selectedPoint: '',
	aciSeriesForKPI: createAsyncInitialState({}),
	cfForecastForForecastKPI: createAsyncInitialState({}),
	cfForecastForCashGapKPI: createAsyncInitialState({}),
	forecastKPIDate: moment().add(1, 'month').format(BASE_DATE_FORMAT),
	detalizationOptions: null,
	isExpanded: false,
} as PlanningState;

const mainPlanningReducer = createReducer<PlanningState>(initialState, {
	[mainPlanningActionsPack.types.INVALIDATE_OPERATIONS]: (action: StaticAction, state) => {
		return {
			plList: invalidateStateFromAction(action, state.plList),
			plPlanList: invalidateStateFromAction(action, state.plPlanList),
			cfForecast: invalidateStateFromAction(action, state.cfForecast),
		};
	},
	[mainPlanningActionsPack.types.RESTORE_DATA_VALIDATION]: (_, state) => {
		return {
			plList: restoreValidationState(state.plList),
		};
	},
	[mainPlanningActionsPack.types.SET_DETALIZATION_OPTIONS]: (action: StaticAction<FetchOperationsOptions>, state) => {
		return {
			detalizationOptions: action.value,
			plList: invalidateStateFromAction(action, state.plList),
		};
	},
	[mainPlanningActionsPack.types.SET_DATE_RANGE]: (action: StaticAction<DateRange>, state) => {
		return {
			period: action.value,
			detalizationOptions: null,
			selectedPoint: '',
			selectedCell: { ...initialState.selectedCell },
			aciSeries: invalidateStateFromAction(action, state.aciSeries),
			cfForecast: invalidateStateFromAction(action, state.cfForecast),
			plPlanList: invalidateStateFromAction(action, state.plPlanList),
		};
	},
	[mainPlanningActionsPack.types.FETCH_PLANNING_DATA]: (action: AsyncAction<FetchPlanningDataReturnType>, state) => {
		return {
			aciSeries: checkAsyncAction(action, state.aciSeries, response =>
				_.indexBy(
					response.plOperationDynamics.Series,
					(s: PLOperationAccountsChartItemSeries) => s.AccountsChartItemID,
				),
			),
			cfForecast: checkAsyncAction(action, state.cfForecast, response => response.cfForecast),
		};
	},
	[mainPlanningActionsPack.types.FETCH_OPERATIONS]: (action: AsyncAction<Array<PLOperationBrief>>, state) => {
		return {
			plList: checkAsyncAction(action, state.plList),
		};
	},
	[mainPlanningActionsPack.types.FETCH_PLAN_OPERATIONS]: (action: AsyncAction<Array<PLOperationBrief>>, state) => {
		return {
			plPlanList: checkAsyncAction(action, state.plPlanList),
		};
	},
	[planningConstants.FETCH_CASHFLOW_FORECAST_FOR_FORECAST_KPI]: (
		action: AsyncAction<{
			plOperationDynamics: PLOperationDynamics;
			cfForecast: CashflowForecast;
		}>,
		state,
	) => {
		return {
			ciSeriesForKPI: checkAsyncAction(action, state.aciSeriesForKPI, response =>
				_.indexBy(
					response.plOperationDynamics.Series,
					(s: PLOperationAccountsChartItemSeries) => s.AccountsChartItemID,
				),
			),
			cfForecastForForecastKPI: checkAsyncAction(
				action,
				state.cfForecastForForecastKPI,
				response => response.cfForecast,
			),
		};
	},
	[planningConstants.FETCH_CASHFLOW_FORECAST_FOR_CASH_GAP_KPI]: (action: AsyncAction<CashflowForecast>, state) => {
		return {
			cfForecastForCashGapKPI: checkAsyncAction(action, state.cfForecastForCashGapKPI),
		};
	},
	[planningConstants.SET_PLANNING_FILTER_BY_TEXT]: (action: StaticAction<string>) => {
		return {
			substr: action.value,
		};
	},
	[planningConstants.CLICK_ON_PLANNING_TABLE_CELL]: (
		action: StaticAction<{
			row: any;
			col: number;
		}>,
	) => {
		return {
			selectedPoint: '',
			selectedCell: { ...action.value },
		};
	},
	[planningConstants.CLICK_ON_PLANNING_CHART_POINT]: (action: StaticAction<string>) => {
		return {
			selectedCell: { ...initialState.selectedCell },
			selectedPoint: action.value,
		};
	},
	[planningConstants.SET_CASHFLOW_FORECAST_KPI_DATE]: (action: StaticAction<string>, state) => {
		return {
			cfForecastForForecastKPI: {
				...state.cfForecastForForecastKPI,
				didInvalidate: true,
			},
			forecastKPIDate: action.value,
		};
	},
	[OperationsMainTypes.ADD_OPERATION]: (action: AsyncAction<PLOperation>, state) => {
		return {
			cfForecastForForecastKPI: invalidateStateFromAction(action, state.cfForecastForForecastKPI),
			cfForecastForCashGapKPI: invalidateStateFromAction(action, state.cfForecastForCashGapKPI),
		};
	},
	[OperationsMainTypes.UPDATE_OPERATION]: (action: AsyncAction<PLOperation>, state) => {
		return {
			cfForecastForForecastKPI: invalidateStateFromAction(action, state.cfForecastForForecastKPI),
			cfForecastForCashGapKPI: invalidateStateFromAction(action, state.cfForecastForCashGapKPI),
		};
	},
	[OperationsMainTypes.REMOVE_OPERATIONS]: (action: AsyncAction, state) => {
		return {
			cfForecastForForecastKPI: invalidateStateFromAction(action, state.cfForecastForForecastKPI),
			cfForecastForCashGapKPI: invalidateStateFromAction(action, state.cfForecastForCashGapKPI),
		};
	},
	[OperationsMainTypes.ADD_OPERATIONS_BY_TEMPLATE]: (action: AsyncAction, state) => {
		return {
			cfForecastForForecastKPI: invalidateStateFromAction(action, state.cfForecastForForecastKPI),
			cfForecastForCashGapKPI: invalidateStateFromAction(action, state.cfForecastForCashGapKPI),
		};
	},
	[OperationsMainTypes.UPDATE_OPERATIONS_BY_TEMPLATE]: (action: AsyncAction, state) => {
		return {
			cfForecastForForecastKPI: invalidateStateFromAction(action, state.cfForecastForForecastKPI),
			cfForecastForCashGapKPI: invalidateStateFromAction(action, state.cfForecastForCashGapKPI),
		};
	},
	[mainPlanningActionsPack.types.SET_IS_EXPANDED]: (action: StaticAction<boolean>) => {
		return {
			isExpanded: action.value,
		};
	},
});

export default mainPlanningReducer;
