import React from 'react';

import { Root } from './styled';

export type PurpleBarProps = {
	children: React.ReactNode;
};

class PurpleBar extends React.PureComponent<PurpleBarProps> {
	static displayName = 'PurpleBar';

	render() {
		return <Root>{this.props.children}</Root>;
	}
}

export { PurpleBar };
