import { compose } from 'redux';

import { IAppState } from '@store';
import { createAsyncSelector, createSelector, createRightCheck } from '@core/flux';
import { createObjectMap } from '@utils/object';
import { sortDescBy } from '@utils/sorting';
import { safeNumber } from '@utils/numbers';
import { detectIsPaymentBrief, detectIsTransferBrief } from '@utils/payments';
import { PaymentsDirection, PaymentsStatisticsSliceCode } from '@payments/models';

const selectPaymentsRight = createRightCheck({
	component: 'CashflowManagement',
	view: 'xPayments_ViewPayments',
	change: 'xPayments_ChangePayments',
});

const selectAsyncCashflowOperations = createAsyncSelector<Array<CashflowOperationBrief>, IAppState>({
	get: s => s.payments.main.cashflowOperations,
	selector: createSelector(
		s => s.payments.main.cashflowOperations.item,
		item => item,
	),
});

const selectAsyncBalance = createAsyncSelector<Array<CashBalanceRecord>, IAppState>({
	get: s => s.payments.main.balance,
	selector: createSelector(
		s => s.payments.main.balance.item,
		item => item,
	),
});

const selectAsyncFundsRegisterStatistics = createAsyncSelector<Array<FundsRegisterStatistics>, IAppState>({
	get: s => s.payments.main.fundsRegisterStatistics,
	selector: createSelector(
		s => s.payments.main.fundsRegisterStatistics.item,
		item => item,
	),
});

const selectPaymentsIsFetching = createSelector(
	selectAsyncCashflowOperations.selectIsFetching,
	selectAsyncBalance.selectIsFetching,
	selectAsyncFundsRegisterStatistics.selectIsFetching,
	(cashflowOperationsIsFetching, balanceIsFetching, fundsRegisterStatisticsIsFetching) => {
		return cashflowOperationsIsFetching || balanceIsFetching || fundsRegisterStatisticsIsFetching;
	},
);

const selectBalanceMap = createSelector(selectAsyncBalance.selectItem, balance => {
	return createObjectMap(balance, x => x.ValueDate);
});

const selectFundsRegisterStatisticsMap = createSelector(
	selectAsyncFundsRegisterStatistics.selectItem,
	fundsRegisterStatistic => {
		return createObjectMap(fundsRegisterStatistic, x => x.FundsRegister.ID);
	},
);

const selectTextFilter = (state: IAppState) => state.payments.main.textFilter;

const selectDirectionFilter = (state: IAppState) => state.payments.main.directionFilter;

const selectFilteredCashflowOperations = createSelector(
	selectAsyncCashflowOperations.selectItem,
	selectTextFilter,
	selectDirectionFilter,
	(sourceCashflowOperations, sourceSearchText, directionFilter) => {
		const isBlankText = sourceSearchText === ' ';
		const searchText = isBlankText ? sourceSearchText : sourceSearchText.toLowerCase().trim();
		const cashflowOperations = compose(
			(cashflowOperations: Array<CashflowOperationBrief>) =>
				sortDescBy(cashflowOperations, [{ fn: item => item.PaymentDate, isDate: true }, { fn: item => item.ID }]),
			(cashflowOperations: Array<CashflowOperationBrief>) =>
				directionFilter !== PaymentsDirection.ALL
					? cashflowOperations.filter(
							x =>
								(directionFilter === PaymentsDirection.CHARGES && detectIsPaymentBrief(x) && x.Direction === 1) ||
								(directionFilter === PaymentsDirection.RECEIPTS && detectIsPaymentBrief(x) && x.Direction === 2) ||
								(directionFilter === PaymentsDirection.TRANSFERS && detectIsTransferBrief(x)),
					  )
					: cashflowOperations,
			(cashflowOperations: Array<CashflowOperationBrief>) =>
				searchText
					? cashflowOperations.filter(x =>
							filterCashflowOperationBySearchText(x as PaymentBrief | TransferCashflowOperationBrief, searchText),
					  )
					: cashflowOperations,
		)(sourceCashflowOperations);

		return cashflowOperations;
	},
);

function filterCashflowOperationBySearchText(
	cashflowOperation: PaymentBrief | TransferCashflowOperationBrief,
	searchText: string,
) {
	const isBlankText = searchText === ' ';
	const isMatchReference = isBlankText ? false : cashflowOperation.Reference.toLowerCase().indexOf(searchText) !== -1;
	const isMatchCounterpartyName = isBlankText
		? !cashflowOperation.Counterparty
		: (cashflowOperation.Counterparty?.Name || '').toLowerCase().indexOf(searchText) !== -1;

	return isMatchReference || isMatchCounterpartyName;
}

const selectFundsRegisterID = (state: IAppState) => state.payments.main.fundsRegisterID;

const selectCurrentFundsRegisterStatistic = createSelector(
	selectFundsRegisterStatisticsMap,
	selectFundsRegisterID,
	(fundsRegisterStatisticsMap, fundsRegisterID) => fundsRegisterStatisticsMap[fundsRegisterID],
);

const selectCurrentFundsRegister = createSelector(
	selectFundsRegisterStatisticsMap,
	selectFundsRegisterID,
	(fundsRegisterStatisticsMap, fundsRegisterID) => fundsRegisterStatisticsMap[fundsRegisterID]?.FundsRegister || null,
);

const selectDateRange = (state: IAppState) => state.payments.main.dateRange;

const selectPaymentsStatistics = createSelector(selectCurrentFundsRegisterStatistic, frStatistics => {
	const incomingBalanceTotal = frStatistics?.IncomingBalance || 0;
	const receiptTotal = safeNumber(frStatistics?.Receipt - frStatistics?.TransferIn || 0);
	const chargeTotal = safeNumber(frStatistics?.Charge - frStatistics?.TransferOut || 0);
	const transferTotal = safeNumber(frStatistics?.TransferIn - frStatistics?.TransferOut || 0);
	const outgoingBalanceTotal = frStatistics?.OutgoingBalance || 0;
	const data = {
		[PaymentsStatisticsSliceCode.INCOMING_BALANCE]: incomingBalanceTotal,
		[PaymentsStatisticsSliceCode.RECEIPT]: receiptTotal,
		[PaymentsStatisticsSliceCode.CHARGE]: chargeTotal,
		[PaymentsStatisticsSliceCode.TRANSFER]: transferTotal,
		[PaymentsStatisticsSliceCode.OUTGOING_BALANCE]: outgoingBalanceTotal,
	};

	return data;
});

function selectIsDataFetching(state: IAppState) {
	const isFetching =
		selectAsyncFundsRegisterStatistics.selectIsFetching(state) || selectAsyncCashflowOperations.selectIsFetching(state);

	return isFetching;
}

export const mainPaymentsSelectorsPack = {
	selectPaymentsRight,
	selectAsyncCashflowOperations,
	selectAsyncFundsRegisterStatistics,
	selectFilteredCashflowOperations,
	selectAsyncBalance,
	selectPaymentsIsFetching,
	selectBalanceMap,
	selectTextFilter,
	selectDirectionFilter,
	selectFundsRegisterID,
	selectCurrentFundsRegisterStatistic,
	selectCurrentFundsRegister,
	selectDateRange,
	selectPaymentsStatistics,
	selectIsDataFetching,
};

export {
	selectPaymentsRight,
	selectAsyncCashflowOperations,
	selectAsyncFundsRegisterStatistics,
	selectAsyncBalance,
	selectDateRange,
};
