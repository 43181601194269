import React, { memo, useLayoutEffect } from 'react';

import { useMapDispatch, useMapState } from '@core/flux';
import { mainFundsRegistersSelectorsPack } from '@funds-registers/selectors';
import { CMSDataType, DrawerZone } from '@funds-registers/models';
import { XCmsList } from './cms-list.view';

import { mainIntegrationsActionsPack } from '@integrations/actions';
import { mainFundsRegistersActionsPack } from '@funds-registers/actions';

export type CmsListProps = {
	onSetZone: React.Dispatch<React.SetStateAction<DrawerZone>>;
	dataType: CMSDataType;
};

const CmsList: React.FC<CmsListProps> = memo(({ onSetZone, dataType }) => {
	const [supportedCMS, bankAccountByCmsStats] = useMapState([
		mainFundsRegistersSelectorsPack.selectSupportedCMS,
		mainFundsRegistersSelectorsPack.selectBankAccountByCmsStats,
	]);
	const [setSelectedCmsID, clearFundsRegisterIDForAutoInsert] = useMapDispatch([
		mainIntegrationsActionsPack.setSelectedCmsID,
		mainFundsRegistersActionsPack.clearFundsRegisterIDForAutoInsert,
	]);

	useLayoutEffect(() => {
		clearFundsRegisterIDForAutoInsert();
	}, []);

	const handleSelectCMS = (ID: number) => {
		onSetZone(DrawerZone.ACOUNTS);
		setSelectedCmsID(ID);
	};

	return (
		<XCmsList
			dataType={dataType}
			supportedCMS={supportedCMS}
			bankAccountByCmsStats={bankAccountByCmsStats}
			onSelectCMS={handleSelectCMS}
		/>
	);
});

export { CmsList };
