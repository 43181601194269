import React, { useLayoutEffect, useRef, useState, useMemo } from 'react';

import { Root } from './styled';

export type FocusTrapProps = {
	active?: boolean;
};

const FocusTrap: React.FC<FocusTrapProps> = props => {
	const { active } = props;
	const [isFocusTrapOpen, setIsFocusTrapOpen] = useState(false);
	const autofocusRef = useRef<HTMLDivElement>(null);
	const scope = useMemo(() => ({ isFirstLayoutEffect: true }), []);

	useLayoutEffect(() => {
		if (active || scope.isFirstLayoutEffect) return;
		setIsFocusTrapOpen(true);

		const timerID = setTimeout(() => {
			autofocusRef.current?.focus();
			setIsFocusTrapOpen(false);
		}, 100);

		return () => clearTimeout(timerID);
	}, [active]);

	useLayoutEffect(() => {
		scope.isFirstLayoutEffect = false;
	}, []);

	if (!isFocusTrapOpen) return null;

	return <Root ref={autofocusRef} tabIndex={0} />;
};

export { FocusTrap };
