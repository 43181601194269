import { IAppState } from '@store';
import { createAsyncSelector, createSelector } from '@flux';

const selectAsyncPlOperations = createAsyncSelector<Array<PLOperationBrief>, IAppState>({
	get: s => s.plPlanning.master.plList,
	selector: createSelector(
		s => s.plPlanning.master.plList.item,
		item => item,
	),
});

function selectDateRange(state: IAppState): DateRange {
	return state.plPlanning.master.dateRange;
}

export const mainPlanningMasterSelectorsPack = {
	selectAsyncPlOperations,
	selectDateRange,
};
