import styled, { css } from 'styled-components';

import { RaisedButton } from '@ui/raised-button';

type ControlsLayoutProps = {
	centered: boolean;
};

const Root = styled.div`
	width: 100%;
	height: 100%;
	padding: 40px;
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	align-items: center;
`;

const WrapLayout = styled.div`
	width: 100%;
`;

const IconLayout = styled.div`
	width: 100%;
	display: flex;
	flex-flow: column wrap;
	justify-content: center;
	align-items: center;
	margin-bottom: 20px;
`;

const TitleLayout = styled.div`
	margin-bottom: 30px;
`;

const Title = styled.div`
	font-size: 14px;
	font-weight: 500;
	line-height: 1.43;
	text-align: center;
`;

const Detail = styled.div`
	font-size: 14px;
	line-height: 1.43;
	text-align: center;
	margin-top: 5px;

	${p => css`
		color: ${p.theme.palette.label};
	`}
`;

const ControlsLayout = styled.div`
	position: relative;
	width: 100%;
	height: auto;
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	align-items: center;

	${(p: ControlsLayoutProps) =>
		p.centered &&
		css`
			justify-content: center;
		`}
`;

const ButtonLayout = styled.div``;

const ActionButton = styled(RaisedButton).attrs(() => ({
	type: 'primary',
	style: {
		minWidth: '160px',
	},
}))``;

const DefaultButton = styled(RaisedButton).attrs(() => ({
	type: 'default',
	style: {
		minWidth: '160px',
	},
}))``;

export {
	Root,
	WrapLayout,
	IconLayout,
	TitleLayout,
	Title,
	Detail,
	ControlsLayout,
	ButtonLayout,
	ActionButton,
	DefaultButton,
};
