import React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { IAppState } from '../../../../../../../core/store';
import * as statuses from '../../../../../../shared/constants/statuses';
import { resetBillingDisplayPathAction } from '../../../../../actions/display-controller.actions';
import { runFastBillResultEffectAction } from '../../../../../actions/billing.actions';
import { selectCMSIsFetching } from '../../../../../selectors/external-system-account.selectors';
import * as billingSelectors from '../../../../../selectors/billing.selectors';
import XLegalEntityPaymentResultDisplay, {
	LegalEntityPaymentResultDisplayProps as XLegalEntityPaymentResultDisplayProps,
} from './legal-entity-payment-result-display.view';

type LegalEntityPaymentResultDisplayProps = Partial<XLegalEntityPaymentResultDisplayProps>;

class LegalEntityPaymentResultDisplay extends React.Component<LegalEntityPaymentResultDisplayProps> {
	static displayName = 'LegalEntityPaymentResultDisplay[Container]';

	render() {
		return <XLegalEntityPaymentResultDisplay {...(this.props as any)} />;
	}
}

const selectIsFetching = state =>
	selectCMSIsFetching(state) ||
	billingSelectors.selectTenantAccountIsFetching(state) ||
	billingSelectors.selectTenantBillsIsFetching(state) ||
	!billingSelectors.selectStatusAfterBilling(state) ||
	billingSelectors.selectStatusAfterBilling(state).status === statuses.IN_PROGRESS;

function mapStateToProps(state: IAppState) {
	return {
		isFetching: selectIsFetching(state),
		tenantBills: billingSelectors.selectTenantBills(state),
		status: billingSelectors.selectStatusAfterBilling(state),
		expireDate: billingSelectors.selectTenantAccountExpireDate(state),
	};
}

function mapDispatchToProps(dispatch: Dispatch<any>) {
	return bindActionCreators(
		{
			runFastBillResultEffect: runFastBillResultEffectAction,
			resetPath: resetBillingDisplayPathAction,
		},
		dispatch,
	);
}

export default connect<any, any, LegalEntityPaymentResultDisplayProps>(
	mapStateToProps,
	mapDispatchToProps,
)(LegalEntityPaymentResultDisplay);
