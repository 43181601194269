import * as notificationpackage from 'notificationpackage-api';
import { buildTLongArray } from '@utils';

export type SendNotificationBySmsOptions = {
	eventCode: string;
	tenantID: number;
};

export const SMS_MOBILE_APP_LINKS = 'SMS_MOBILE_APP_LINKS';

const notificationApi = {
	package: notificationpackage as typeof notificationpackageApi,
	notification: {
		client: new notificationpackage.NotificationServiceClient() as NotificationServiceClient,
		fetchNotifications: (tenantID: number) => {
			return new Promise<Array<NotificationRequest>>(resolve => {
				notificationApi.notification.client.getNotifications(tenantID, notifications => {
					resolve(notifications);
				});
			});
		},
		markNotificationsReaded: (notificationIds: Array<number>, unreadedNotifications: Array<NotificationRequest>) => {
			return new Promise<Array<NotificationRequest>>(resolve => {
				notificationApi.notification.client.markNotificationsReaded(buildTLongArray(notificationIds), () => {
					resolve(unreadedNotifications);
				});
			});
		},
		sendNotification: (options: SendNotificationOptions) => {
			const { tenant, comment, method = 'EMAIL', eventCode, emails, date } = options;
			const notification: NotificationRequest = {
				...new notificationApi.package.NotificationRequest(),
				Comment: comment,
				Parameters: {
					tenantID: tenant.StorageID || 'Отсутствует',
					tenantName: tenant.ContactPersonName || 'Отсутствует',
					tenantPhone: tenant.ContactPhoneNumber || 'Отсутствует',
				},
				Method: method,
				EventCode: eventCode,
				NotificationDateTime: date,
				NotificationTarget: emails.join(','),
				TenantID: tenant.StorageID || -1,
			};
			return new Promise<NotificationRequest>(resolve => {
				notificationApi.notification.client.createAndSendNotification(notification, result => {
					resolve(result);
				});
			});
		},
	},
};

export type SendNotificationOptions = {
	tenant: Tenant;
	comment: string;
	method?: 'EMAIL';
	eventCode: string;
	date: string;
	emails: Array<string>;
};

export { notificationApi };
