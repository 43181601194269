import styled, { css } from 'styled-components';

import { DocumentTable } from '@ui/documents-rendering';

type TableBodyCellProps = {
	appearance?: 'fat-left' | 'fat-right';
	noBorderBottom?: boolean;
	noBorders?: boolean;
	isLast?: boolean;
	textCenter?: boolean;
};

const Root = styled.div`
	position: relative;
	width: 100%;
	line-height: 15px;
	color: #000;
	padding: 0 1px;
	font-size: 12px;
`;

const CompanyLogo = styled.img`
	width: auto;
	height: auto;
	max-width: 340px;
	max-height: 140px;
	object-fit: contain;
`;

const Table = styled.table`
	position: relative;
	width: 100%;
	max-width: 100%;
	table-layout: fixed;

	& td {
		padding: 2px;
		border: 1px solid #000;
		vertical-align: top;
	}
`;

const TableBodyCell = styled.td<TableBodyCellProps>`
	${p =>
		p.noBorderBottom &&
		css`
			border-bottom-color: transparent !important;
		`}

	${p =>
		p.noBorders &&
		css`
			border-color: transparent !important;
		`}

	${p =>
		p.appearance === 'fat-left' &&
		css`
			border-left: 2px solid #000 !important;
		`}

	${p =>
		p.appearance === 'fat-right' &&
		css`
			border-right: 2px solid #000 !important;
		`}

	${p =>
		p.isLast &&
		css`
			border-bottom: 2px solid #000 !important;
		`}

	${p =>
		p.textCenter &&
		css`
			text-align: center;
		`}
`;

const Divider = styled.div`
	width: 100%;
	height: 2px;
	background-color: #000;
`;

const Placeholder = styled.div`
	flex: 1 1 auto;
	position: relative;
	top: 2px;
	font-size: 12px;
	width: 100%;
	text-align: right;
	line-height: 1.6;

	&:after {
		content: '';
		position: absolute;
		top: 20px;
		left: 0;
		display: block;
		width: 100%;
		border-bottom: 1px solid #000;
	}
`;

const EmployeeSign = styled.img`
	position: absolute;
	left: 0;
	top: -24px;
	max-width: 200px;
	max-height: 70px;
	mix-blend-mode: multiply;
	object-fit: contain;
`;

const CompanySeal = styled.img`
	position: absolute;
	top: 0;
	right: 0;
	max-width: 40mm;
	max-height: 40mm;
	transform: translate(100%, -25%) rotate(0deg);
	mix-blend-mode: multiply;
	object-fit: contain;
`;

type PhotoRequisitesLayoutProps = {
	isInvisible: boolean;
};

const PhotoRequisitesLayout = styled.div<PhotoRequisitesLayoutProps>`
	${p =>
		p.isInvisible &&
		css`
			& ${EmployeeSign}, & ${CompanySeal} {
				display: none;
			}
		`}
`;

const EmployeeName = styled.span``;

const StampLayout = styled.div`
	position: absolute;
	top: -2px;
	left: 170px;
`;

const Footer = styled.div`
	display: flex;
	padding-top: 30px;
	min-height: 150px;
	page-break-inside: avoid;
`;

const ItemsTable = styled(DocumentTable)`
	& th,
	& td {
		padding: 2px;
	}

	& .summary {
		font-size: 12px;
	}
`;

export {
	Root,
	CompanyLogo,
	Table,
	TableBodyCell,
	Divider,
	Placeholder,
	EmployeeSign,
	EmployeeName,
	CompanySeal,
	PhotoRequisitesLayout,
	StampLayout,
	Footer,
	ItemsTable,
};
