import { compose } from 'redux';
import moment from 'moment';

import { IAppState } from '@store';
import { createAsyncSelector, createSelector, createRightCheck } from '@flux';
import { extractKeysToArray, createBooleanMap } from '@utils/object';
import { formatDate, compareDates, getStartOf, today } from '@utils/date';
import { convertCsvArrayToNormalArray } from '@utils/parsing';
import {
	createDataSourceTree,
	createSortedFlattenTree,
	deepFilter,
	crateExpandedMap,
	getAllChildItemIDs,
} from '@utils/tree';
import { sortDescBy } from '@utils/sorting';
import { BASE_DATE_FORMAT } from '@shared/constants/time';
import {
	PlanningVirtualTableRow,
	PlanningVirtualTableColumn,
	PlanningChartData,
	PlanningChartPoint,
	PlanningGranulationModeCode,
	CashGapPoint,
	periodFormattersMap,
	dateFormattersMap,
} from '@planning/models';

const selectPlanningRight = createRightCheck({
	component: 'CashflowPlanning',
	view: 'xPlanning_ViewPlanning',
});

const nonCashflowIDsMap = {
	'-2': true, // Входящий остаток
	'-3': true, // Исходящий остаток
	'-4': true, // Чистый денежный поток
};

const getID = (x: PLOperationPlanningReportItem | number) => (typeof x === 'number' ? x : x.ID);

function getChildItems(item: PLOperationPlanningReportItem, items: Array<PLOperationPlanningReportItem>) {
	const idsMap = createBooleanMap(convertCsvArrayToNormalArray(item.ChildItemIDs, Number), x => x);

	return items.filter(y => idsMap[y.ID]);
}

function getPrefix(value: number, isCashflow: boolean, isIncome: boolean) {
	const prefix = !isCashflow ? (value > 0 ? '+' : value < 0 ? '-' : '') : value === 0 ? '' : isIncome ? '+' : '-';

	return prefix;
}

const selectBoundaryDate = createSelector(selectGranulationMode, granulationMode =>
	getStartOf(periodFormattersMap[granulationMode]),
);

const selectAsyncPlanningReportItems = createAsyncSelector<Array<PLOperationPlanningReportItem>, IAppState>({
	get: s => s.planning.main.reportItems,
	selector: createSelector(
		s => s.planning.main.reportItems.item,
		item => item,
	),
});

const selectAsyncCashflowForecast = createAsyncSelector<CashflowForecast, IAppState>({
	get: s => s.planning.main.cashflowForecast,
	selector: createSelector(
		s => s.planning.main.cashflowForecast.item,
		item => item,
	),
});

const selectAsyncForecastIndicatorData = createAsyncSelector<CashflowForecast, IAppState>({
	get: s => s.planning.main.forecastIndicatorData,
	selector: createSelector(
		s => s.planning.main.forecastIndicatorData.item,
		item => item,
	),
});

const selectAsyncCashGapIndicatorData = createAsyncSelector<CashflowForecast, IAppState>({
	get: s => s.planning.main.cashGapIndicatorData,
	selector: createSelector(
		s => s.planning.main.cashGapIndicatorData.item,
		item => item,
	),
});

const selectAsyncPlanOperations = createAsyncSelector<Array<PLOperationBrief>, IAppState>({
	get: s => s.planning.main.planOperations,
	selector: createSelector(
		s => s.planning.main.planOperations.item,
		item => item,
	),
});

const selectAsyncDetailsOperations = createAsyncSelector<Array<PLOperationBrief>, IAppState>({
	get: s => s.planning.main.detailsOperations,
	selector: createSelector(
		s => s.planning.main.detailsOperations.item,
		item => item,
	),
});

function selectDateRange(state: IAppState) {
	return state.planning.main.dateRange;
}

function selectViewMode(state: IAppState) {
	return state.planning.main.viewMode;
}

function selectGranulationMode(state: IAppState) {
	return state.planning.main.granulationMode;
}

function selectSearchText(state: IAppState) {
	return state.planning.main.searchText;
}

function selectIsChartVisible(state: IAppState) {
	return state.planning.main.isChartVisible;
}

const selectFilteredPlanOperations = createSelector(
	selectAsyncPlanOperations.selectItem,
	selectSearchText,
	(sourceOperations, sourceSearchText) => {
		const searchText = sourceSearchText.toLowerCase();
		const operations = compose(
			(operations: Array<PLOperationBrief>) =>
				sortDescBy(operations, [{ fn: item => item.CashflowDate, isDate: true }, { fn: item => item.ID }]),
			(operations: Array<PLOperationBrief>) =>
				searchText
					? operations.filter(
							x =>
								(x?.Counterparty?.Name || '').toLowerCase().indexOf(searchText) !== -1 ||
								(x?.Description || '').toLowerCase().indexOf(searchText) !== -1 ||
								(x?.Comment || '').toLowerCase().indexOf(searchText) !== -1,
					  )
					: operations,
		)(sourceOperations);

		return operations;
	},
);

const selectFilteredDetailsOperations = createSelector(selectAsyncDetailsOperations.selectItem, sourceOperations => {
	const operations = compose((operations: Array<PLOperationBrief>) =>
		sortDescBy(operations, [{ fn: item => item.CashflowDate, isDate: true }, { fn: item => item.ID }]),
	)(sourceOperations);

	return operations;
});

const selectPlanningChartData = createSelector(selectAsyncCashflowForecast.selectItem, forecast => {
	const data: PlanningChartData = {
		fact: [],
		plan: [],
	};
	const balances = forecast?.CashBalances || [];
	const cashGapPoint = getCashGapPoint(forecast);

	for (const balance of balances) {
		const isCashGap = cashGapPoint?.date === balance.ValueDate;
		const point: PlanningChartPoint = {
			x: moment(balance.ValueDate, BASE_DATE_FORMAT).valueOf(),
			y: balance.OutgoingBalance,
			date: balance.ValueDate,
			value: balance.OutgoingBalance,
			selected: isCashGap,
			isPlan: balance.Plan,
		};

		if (balance.Plan) {
			data.plan.push(point);
		} else {
			data.fact.push(point);
		}
	}

	if (data.fact.length > 0) {
		data.plan.unshift(data.fact[data.fact.length - 1]);
	}

	return data;
});

const selectVirtualTableDataSource = createSelector(
	selectAsyncPlanningReportItems.selectIsLoaded,
	selectAsyncPlanningReportItems.selectIsFetching,
	selectAsyncPlanningReportItems.selectItem,
	selectGranulationMode,
	selectBoundaryDate,
	selectSearchText,
	selectSelectedCoords,
	(isLoaded, isFetching, reportItems, granulationMode, boundaryDate, sourceSearchText, coords) => {
		if (!isLoaded || isFetching) return [];
		const searchText = sourceSearchText.toLowerCase();
		const hasAnyFilter = Boolean(searchText || coords);
		const selectedChildItemIDs = coords
			? getAllChildItemIDs<PLOperationPlanningReportItem>({
					ID: coords.ID,
					items: reportItems,
					getID,
					getChildItems: x => getChildItems(x, reportItems),
			  })
			: [];
		const root = createDataSourceTree<PLOperationPlanningReportItem, number>({
			items: reportItems,
			getID,
			getName: x => x.Name,
			getParentID: x => x.ParentID,
			getChildItems: x => getChildItems(x, reportItems),
			filter: x =>
				hasAnyFilter
					? deepFilter<PLOperationPlanningReportItem, number>({
							item: x,
							items: reportItems,
							getID,
							getChildItems: x => getChildItems(x, reportItems),
							filter: x => {
								const isPassed = compose(
									(isPassed: boolean) => {
										if (!coords) return isPassed;
										if (x.ID === coords.ID || selectedChildItemIDs.includes(x.ID)) return true;
										return false;
									},
									(isPassed: boolean) => (searchText ? x.Name.toLowerCase().indexOf(searchText) !== -1 : isPassed),
								)(true);

								return isPassed;
							},
					  })
					: true,
		});
		const cache = (root.ChildItems[0]?.value?.Columns || []).map(x => {
			const periodKey = periodFormattersMap[granulationMode];
			const { isBefore, isSame } = compareDates(x.ValueDate, boundaryDate, periodKey);

			return {
				label: formatDate(x.ValueDate, dateFormattersMap[granulationMode]),
				isPast: isBefore(),
				isPresent: isSame(),
			};
		});
		const sourceItems: Array<PlanningVirtualTableRow> = root.ChildItems.map(item => {
			const columns: Array<PlanningVirtualTableColumn> = [null]; // Оставляем пустую первую колонку
			const itemID = item.ID as number;
			const isSynthetic = itemID < 0;
			const isIncomingBalance = itemID === -2;
			const isCashflow = !nonCashflowIDsMap[itemID];
			const isIncome = typeof item.value.Incoming === 'boolean' ? item.value.Incoming : null;
			const cashflowIDs = getCashflowIDs(reportItems, itemID as number);

			columns.push(
				...item.value.Columns.map((x, idx) => {
					const { label, isPast, isPresent } = cache[idx];
					const plan = x.PlanAmount || 0;
					const fact = x.FactAmount || 0;
					const planPrefix = getPrefix(plan, isCashflow, isIncome);
					const factPrefix = getPrefix(fact, isCashflow, isIncome);
					const column: PlanningVirtualTableColumn = {
						date: x.ValueDate,
						plan,
						fact,
						label,
						isPast,
						isPresent,
						planPrefix,
						factPrefix,
					};

					return column;
				}),
			);

			const row: PlanningVirtualTableRow = {
				ID: itemID as number,
				name: item.Name,
				parentID: item.ParentID as number,
				childItemIDs: item.ChildItems.map(x => x.ID as number),
				cashflowIDs,
				isIncome,
				isSynthetic,
				isIncomingBalance,
				isCashflow,
				columns,
			};

			return row;
		});
		const items = createSortedFlattenTree<PlanningVirtualTableRow>({
			items: sourceItems,
			getID: x => x.ID,
			getParentID: x => x.parentID,
			getChildItemIDs: x => x.childItemIDs,
			sortFn: (a, b) => {
				if (a.ID < 0 && b.ID > 0) return -1;
				if (b.ID < 0 && a.ID > 0) return 1;
				if (b.ID < 0 && a.ID < 0) return Math.abs(a.ID) - Math.abs(b.ID);

				return b.name > a.name ? -1 : 1;
			},
		});
		const hasItems = items.length > 0;
		const dataSource: Array<PlanningVirtualTableRow> = [];

		if (hasItems) {
			dataSource.push(
				{
					ID: null,
					name: 'Статья',
					parentID: -1,
					childItemIDs: [],
					cashflowIDs: [],
					isIncome: null,
					isSynthetic: true,
					isCashflow: false,
					isIncomingBalance: false,
					columns: items[0]?.columns || [],
				},
				...items,
			);
		}

		return dataSource;
	},
);

function getCashflowIDs(reportItems: Array<PLOperationPlanningReportItem>, itemID: number) {
	const cashflowItemIDs = getAllChildItemIDs<PLOperationPlanningReportItem>({
		ID: itemID,
		items: reportItems,
		getID,
		getChildItems: x => getChildItems(x, reportItems),
	}).filter(x => Number(x) > 0);
	const map = {
		'-5': () => [-1, ...cashflowItemIDs], // Поступления
		'-6': () => [-1], // Нераспределенные поступления
		'-7': () => [-1, ...cashflowItemIDs], // Выплаты
		'-8': () => [-1], // Нераспределенные выплаты
		default: () => [itemID, ...cashflowItemIDs],
	};

	return map[itemID] ? map[itemID]() : map.default();
}

function selectExpandedHierarchyMap(state: IAppState) {
	return state.planning.main.expandedHierarchyMap;
}

const selectCorrectedExpandedHierarhyMap = createSelector(
	selectExpandedHierarchyMap,
	selectVirtualTableDataSource,
	selectSearchText,
	(expandedMap, items, searchText) => {
		let initialMap = { ...expandedMap };

		if (searchText) {
			for (const item of items) {
				const map = crateExpandedMap<PlanningVirtualTableRow>({
					isExpanded: true,
					itemID: item.ID,
					items,
					getID: x => x.ID,
					getChildItemIDs: x => x.childItemIDs,
				});

				initialMap = {
					...initialMap,
					...map,
					...expandedMap,
				};
			}
		}

		return initialMap;
	},
);

function selectSelectedCoords(state: IAppState) {
	return state.planning.main.coords;
}

function selectOperationDetailsTitle(state: IAppState) {
	return state.planning.main.detailsTitle;
}

function selectOperationDetailsOptions(state: IAppState) {
	return state.planning.main.detailsOptions;
}

function selectHasOperationDetailsOptions(state: IAppState) {
	return Boolean(state.planning.main.detailsOptions);
}

const selectOnlyParentItemIDs = createSelector(selectVirtualTableDataSource, dataSource => {
	const parentIDs = dataSource.filter(x => x.childItemIDs.length > 0).map(x => x.ID);

	return parentIDs;
});

const selectIsAllHierarchyItemsExpanded = createSelector(
	selectOnlyParentItemIDs,
	selectExpandedHierarchyMap,
	(parentIDs, hierarchyMap) => {
		const IDs = extractKeysToArray(hierarchyMap)
			.filter(key => hierarchyMap[key])
			.map(x => Number(x));
		const isAllExpanded = parentIDs.length === 0 ? false : IDs.length === parentIDs.length;

		return isAllExpanded;
	},
);

function getCashGapPoint(forecast: CashflowForecast, transformDate: (x: string) => string = x => x): CashGapPoint {
	const date = today();
	const cashGapBalance = (forecast?.CashBalances || []).find(x => {
		if (x.OutgoingBalance >= 0) return false;
		const { isAfterOrSame } = compareDates(x.ValueDate, date, 'day');

		return isAfterOrSame();
	});

	if (cashGapBalance) {
		return {
			date: transformDate(cashGapBalance.ValueDate),
			value: cashGapBalance.OutgoingBalance,
		};
	}

	return null;
}

const selectCorrectedCashGapPoint = createSelector(
	selectAsyncCashflowForecast.selectItem,
	selectGranulationMode,
	(forecast, granulationMode): CashGapPoint => {
		const point = getCashGapPoint(forecast, x => getStartOf(periodFormattersMap[granulationMode], x));

		return point;
	},
);

const selectIndicatorCashGapPoint = createSelector(selectAsyncCashGapIndicatorData.selectItem, getCashGapPoint);

function selectIsDataFetching(state: IAppState) {
	return selectAsyncPlanningReportItems.selectIsFetching(state) || selectAsyncCashflowForecast.selectIsFetching(state);
}

function selectForecastIndicatorDate(state: IAppState) {
	return state.planning.main.forecastIndicatorDate;
}

const selectForecastIndicatorOutgoingBalance = createSelector(selectAsyncForecastIndicatorData.selectItem, forecast => {
	const balances = forecast?.CashBalances || [];
	const last = balances[balances.length - 1];
	if (!last) return 0;
	return last.OutgoingBalance;
});

function selectDateType(state: IAppState) {
	return state.planning.main.dateType;
}

function selectTenantEntitiesFilter(state: IAppState) {
	return state.planning.main.tenantEntitiesFilter;
}

function selectBusinessUnitsFilter(state: IAppState) {
	return state.planning.main.businessUnitsFilter;
}

function selectProjectsFilter(state: IAppState) {
	return state.planning.main.projectsFilter;
}

function selectCashflowItemsFilter(state: IAppState) {
	return state.planning.main.cashflowItemsFilter;
}

function selectFundsRegistersFilter(state: IAppState) {
	return state.planning.main.fundsRegistersFilter;
}

export {
	selectPlanningRight,
	selectAsyncPlanningReportItems,
	selectAsyncCashflowForecast,
	selectAsyncForecastIndicatorData,
	selectAsyncCashGapIndicatorData,
	selectAsyncPlanOperations,
	selectAsyncDetailsOperations,
	selectIndicatorCashGapPoint,
	selectForecastIndicatorDate,
	selectForecastIndicatorOutgoingBalance,
};

export const mainPlanningSelectorsPack = {
	selectPlanningRight,
	selectAsyncPlanningReportItems,
	selectAsyncCashflowForecast,
	selectAsyncForecastIndicatorData,
	selectAsyncCashGapIndicatorData,
	selectAsyncPlanOperations,
	selectAsyncDetailsOperations,
	selectDateRange,
	selectViewMode,
	selectGranulationMode,
	selectSearchText,
	selectIsChartVisible,
	selectFilteredPlanOperations,
	selectFilteredDetailsOperations,
	selectPlanningChartData,
	selectBoundaryDate,
	selectVirtualTableDataSource,
	selectExpandedHierarchyMap,
	selectCorrectedExpandedHierarhyMap,
	selectSelectedCoords,
	selectOperationDetailsTitle,
	selectOperationDetailsOptions,
	selectHasOperationDetailsOptions,
	selectOnlyParentItemIDs,
	selectIsAllHierarchyItemsExpanded,
	selectCorrectedCashGapPoint,
	selectIndicatorCashGapPoint,
	selectIsDataFetching,
	selectForecastIndicatorDate,
	selectForecastIndicatorOutgoingBalance,
	selectDateType,
	selectTenantEntitiesFilter,
	selectBusinessUnitsFilter,
	selectProjectsFilter,
	selectCashflowItemsFilter,
	selectFundsRegistersFilter,
};
