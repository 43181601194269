import { combineReducers } from 'redux';

import { mainProfitLossReportReducer, MainProfitLossReportState } from './main.reducer';

export type ProfitLossReportState = {
	main: MainProfitLossReportState;
};

const profitLossReportReducer = combineReducers<ProfitLossReportState>({
	main: mainProfitLossReportReducer,
});

export { profitLossReportReducer };
