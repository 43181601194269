import { runInvalidatorEffect } from './invalidator.effect';


const projectEmployeesEffects = [
	runInvalidatorEffect,
];

export {
	projectEmployeesEffects,
};
