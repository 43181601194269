//black
export const blackPrimary = '#262626';
export const blackMuffled = '#4d4d4d'; // for icons in normal state
export const blackSecondary = '#8c8c8c';
export const blackDisabled = '#bfbfbf';
export const blackStelthy = '#ebebeb'; //for dividers

//white
export const whitePrimary = '#ffffff';
export const whiteMuffled = 'rgba(255, 255, 255, 0.8)'; // for icons in normal state
export const whiteSecondary = '#fafafa';
export const whiteDisabled = 'rgba(255, 255, 255, 0.4)';
export const whiteStelthy = 'rgba(255, 255, 255, 0.2)';

//accent
export const accentPrimary = '#26d06d';
export const accentHover = '#51d98a';
export const accentHoverLight = '#e2f8ec';
export const accentPressed = '#00c853';
export const accentDisabled = 'rgba(38, 208, 109, 0.4)';

//alarm
export const alarmPrimary = '#ff3a48';

//warning
export const warningPrimary = '#ffc107';

//background
export const backgroundDark = '#3e4757';
export const backgroundLight = '#f5f8fa';

export const purplePrimary = '#8285CC';

export var accentNormal = '#26d06d';
export var accentMuffled = '#51d98a';
export var accentDark = '#00c853';
export var alarmNormal = '#ff3a48';

export var warning = '#ffc107';
export var success = accentMuffled;



export var red50 = '#ffebee';
export var red100 = '#ffcdd2';
export var red200 = '#ef9a9a';
export var red300 = '#e57373';
export var red400 = '#ef5350';
export var red500 = '#f44336';
export var red600 = '#e53935';
export var red700 = '#d32f2f';
export var red800 = '#c62828';
export var red900 = '#b71c1c';
export var redA100 = '#ff8a80';
export var redA200 = '#ff5252';
export var redA400 = '#ff1744';
export var redA700 = '#d50000';

export var pink50 = '#fce4ec';
export var pink100 = '#f8bbd0';
export var pink200 = '#f48fb1';
export var pink300 = '#f06292';
export var pink400 = '#ec407a';
export var pink500 = '#e91e63';
export var pink600 = '#d81b60';
export var pink700 = '#c2185b';
export var pink800 = '#ad1457';
export var pink900 = '#880e4f';
export var pinkA100 = '#ff80ab';
export var pinkA200 = '#ff4081';
export var pinkA400 = '#f50057';
export var pinkA700 = '#c51162';

export var purple50 = '#f3e5f5';
export var purple100 = '#e1bee7';
export var purple200 = '#ce93d8';
export var purple300 = '#ba68c8';
export var purple400 = '#ab47bc';
export var purple500 = '#9c27b0';
export var purple600 = '#8e24aa';
export var purple700 = '#7b1fa2';
export var purple800 = '#6a1b9a';
export var purple900 = '#4a148c';
export var purpleA100 = '#ea80fc';
export var purpleA200 = '#e040fb';
export var purpleA400 = '#d500f9';
export var purpleA700 = '#aa00ff';

export var deepPurple50 = '#ede7f6';
export var deepPurple100 = '#d1c4e9';
export var deepPurple200 = '#b39ddb';
export var deepPurple300 = '#9575cd';
export var deepPurple400 = '#7e57c2';
export var deepPurple500 = '#673ab7';
export var deepPurple600 = '#5e35b1';
export var deepPurple700 = '#512da8';
export var deepPurple800 = '#4527a0';
export var deepPurple900 = '#311b92';
export var deepPurpleA100 = '#b388ff';
export var deepPurpleA200 = '#7c4dff';
export var deepPurpleA400 = '#651fff';
export var deepPurpleA700 = '#6200ea';

export var indigo50 = '#e8eaf6';
export var indigo100 = '#c5cae9';
export var indigo200 = '#9fa8da';
export var indigo300 = '#7986cb';
export var indigo400 = '#5c6bc0';
export var indigo500 = '#3f51b5';
export var indigo600 = '#3949ab';
export var indigo700 = '#303f9f';
export var indigo800 = '#283593';
export var indigo900 = '#1a237e';
export var indigoA100 = '#8c9eff';
export var indigoA200 = '#536dfe';
export var indigoA400 = '#3d5afe';
export var indigoA700 = '#304ffe';

export var blue50 = '#e3f2fd';
export var blue100 = '#bbdefb';
export var blue200 = '#90caf9';
export var blue300 = '#64b5f6';
export var blue400 = '#42a5f5';
export var blue500 = '#2196f3';
export var blue600 = '#1e88e5';
export var blue700 = '#1976d2';
export var blue800 = '#1565c0';
export var blue900 = '#0d47a1';
export var blueA100 = '#82b1ff';
export var blueA200 = '#448aff';
export var blueA400 = '#2979ff';
export var blueA700 = '#2962ff';

export var lightBlue50 = '#e1f5fe';
export var lightBlue100 = '#b3e5fc';
export var lightBlue200 = '#81d4fa';
export var lightBlue300 = '#4fc3f7';
export var lightBlue400 = '#29b6f6';
export var lightBlue500 = '#03a9f4';
export var lightBlue600 = '#039be5';
export var lightBlue700 = '#0288d1';
export var lightBlue800 = '#0277bd';
export var lightBlue900 = '#01579b';
export var lightBlueA100 = '#80d8ff';
export var lightBlueA200 = '#40c4ff';
export var lightBlueA400 = '#00b0ff';
export var lightBlueA700 = '#0091ea';

export var cyan50 = '#e0f7fa';
export var cyan100 = '#b2ebf2';
export var cyan200 = '#80deea';
export var cyan300 = '#4dd0e1';
export var cyan400 = '#26c6da';
export var cyan500 = '#00bcd4';
export var cyan600 = '#00acc1';
export var cyan700 = '#0097a7';
export var cyan800 = '#00838f';
export var cyan900 = '#006064';
export var cyanA100 = '#84ffff';
export var cyanA200 = '#18ffff';
export var cyanA400 = '#00e5ff';
export var cyanA700 = '#00b8d4';

export var teal50 = '#e0f2f1';
export var teal100 = '#b2dfdb';
export var teal200 = '#80cbc4';
export var teal300 = '#4db6ac';
export var teal400 = '#26a69a';
export var teal500 = '#009688';
export var teal600 = '#00897b';
export var teal700 = '#00796b';
export var teal800 = '#00695c';
export var teal900 = '#004d40';
export var tealA100 = '#a7ffeb';
export var tealA200 = '#64ffda';
export var tealA400 = '#1de9b6';
export var tealA700 = '#00bfa5';

export var green50 = '#e8f5e9';
export var green100 = '#c8e6c9';
export var green200 = '#a5d6a7';
export var green300 = '#81c784';
export var green400 = '#66bb6a';
export var green500 = '#4caf50';
export var green600 = '#43a047';
export var green700 = '#388e3c';
export var green800 = '#2e7d32';
export var green900 = '#1b5e20';
export var greenA100 = '#b9f6ca';
export var greenA200 = '#69f0ae';
export var greenA400 = '#00e676';
export var greenA700 = '#00c853';

export var lightGreen50 = '#f1f8e9';
export var lightGreen100 = '#dcedc8';
export var lightGreen200 = '#c5e1a5';
export var lightGreen300 = '#aed581';
export var lightGreen400 = '#9ccc65';
export var lightGreen500 = '#8bc34a';
export var lightGreen600 = '#7cb342';
export var lightGreen700 = '#689f38';
export var lightGreen800 = '#558b2f';
export var lightGreen900 = '#33691e';
export var lightGreenA100 = '#ccff90';
export var lightGreenA200 = '#b2ff59';
export var lightGreenA400 = '#76ff03';
export var lightGreenA700 = '#64dd17';

export var lime50 = '#f9fbe7';
export var lime100 = '#f0f4c3';
export var lime200 = '#e6ee9c';
export var lime300 = '#dce775';
export var lime400 = '#d4e157';
export var lime500 = '#cddc39';
export var lime600 = '#c0ca33';
export var lime700 = '#afb42b';
export var lime800 = '#9e9d24';
export var lime900 = '#827717';
export var limeA100 = '#f4ff81';
export var limeA200 = '#eeff41';
export var limeA400 = '#c6ff00';
export var limeA700 = '#aeea00';

export var yellow50 = '#fffde7';
export var yellow100 = '#fff9c4';
export var yellow200 = '#fff59d';
export var yellow300 = '#fff176';
export var yellow400 = '#ffee58';
export var yellow500 = '#ffeb3b';
export var yellow600 = '#fdd835';
export var yellow700 = '#fbc02d';
export var yellow800 = '#f9a825';
export var yellow900 = '#f57f17';
export var yellowA100 = '#ffff8d';
export var yellowA200 = '#ffff00';
export var yellowA400 = '#ffea00';
export var yellowA700 = '#ffd600';

export var amber50 = '#fff8e1';
export var amber100 = '#ffecb3';
export var amber200 = '#ffe082';
export var amber300 = '#ffd54f';
export var amber400 = '#ffca28';
export var amber500 = '#ffc107';
export var amber600 = '#ffb300';
export var amber700 = '#ffa000';
export var amber800 = '#ff8f00';
export var amber900 = '#ff6f00';
export var amberA100 = '#ffe57f';
export var amberA200 = '#ffd740';
export var amberA400 = '#ffc400';
export var amberA700 = '#ffab00';

export var orange50 = '#fff3e0';
export var orange100 = '#ffe0b2';
export var orange200 = '#ffcc80';
export var orange300 = '#ffb74d';
export var orange400 = '#ffa726';
export var orange500 = '#ff9800';
export var orange600 = '#fb8c00';
export var orange700 = '#f57c00';
export var orange800 = '#ef6c00';
export var orange900 = '#e65100';
export var orangeA100 = '#ffd180';
export var orangeA200 = '#ffab40';
export var orangeA400 = '#ff9100';
export var orangeA700 = '#ff6d00';

export var deepOrange50 = '#fbe9e7';
export var deepOrange100 = '#ffccbc';
export var deepOrange200 = '#ffab91';
export var deepOrange300 = '#ff8a65';
export var deepOrange400 = '#ff7043';
export var deepOrange500 = '#ff5722';
export var deepOrange600 = '#f4511e';
export var deepOrange700 = '#e64a19';
export var deepOrange800 = '#d84315';
export var deepOrange900 = '#bf360c';
export var deepOrangeA100 = '#ff9e80';
export var deepOrangeA200 = '#ff6e40';
export var deepOrangeA400 = '#ff3d00';
export var deepOrangeA700 = '#dd2c00';

export var brown50 = '#efebe9';
export var brown100 = '#d7ccc8';
export var brown200 = '#bcaaa4';
export var brown300 = '#a1887f';
export var brown400 = '#8d6e63';
export var brown500 = '#795548';
export var brown600 = '#6d4c41';
export var brown700 = '#5d4037';
export var brown800 = '#4e342e';
export var brown900 = '#3e2723';

export var blueGrey50 = '#eceff1';
export var blueGrey100 = '#cfd8dc';
export var blueGrey200 = '#b0bec5';
export var blueGrey300 = '#90a4ae';
export var blueGrey400 = '#78909c';
export var blueGrey500 = '#607d8b';
export var blueGrey600 = '#546e7a';
export var blueGrey700 = '#455a64';
export var blueGrey800 = '#37474f';
export var blueGrey900 = '#263238';

export var grey50 = '#fafafa';
export var grey100 = '#f5f5f5';
export var grey200 = '#eeeeee';
export var grey300 = '#e0e0e0';
export var grey400 = '#bdbdbd';
export var grey500 = '#9e9e9e';
export var grey600 = '#757575';
export var grey700 = '#616161';
export var grey800 = '#424242';
export var grey900 = '#212121';

export var black = '#000000';
export var white = '#ffffff';

export var transparent = 'rgba(0, 0, 0, 0)';
export var fullBlack = 'rgba(0, 0, 0, 1)';
export var darkBlack = 'rgba(0, 0, 0, 0.87)';
export var lightBlack = 'rgba(0, 0, 0, 0.54)';
export var minBlack = 'rgba(0, 0, 0, 0.26)';
export var faintBlack = 'rgba(0, 0, 0, 0.12)';
export var fullWhite = 'rgba(255, 255, 255, 1)';
export var darkWhite = 'rgba(255, 255, 255, 0.87)';
export var lightWhite = 'rgba(255, 255, 255, 0.54)';