import { compose } from 'redux';

import { IAppState } from '@store';
import { createSelector, createAsyncSelector } from '@flux';
import { createObjectMap } from '@utils/object';
import { sortAscBy } from '@utils/sorting';

const selectAsyncMeasureUnits = createAsyncSelector<Array<MeasureUnit>, IAppState>({
	get: s => s.measureUnits.main.measureUnits,
	selector: createSelector(
		s => s.measureUnits.main.measureUnits.item,
		item => item,
	),
});

const selectMeasureUnitsMap = createSelector(selectAsyncMeasureUnits.selectItem, measureUnits =>
	createObjectMap(measureUnits, x => x.ID),
);

const selectSearchText = (state: IAppState) => {
	return state.measureUnits.main.searchText;
};

const selectSortedMeasureUnits = createSelector(selectAsyncMeasureUnits.selectItem, measureUnits => {
	const filteredMeasureUnits = compose((measureUnits: Array<MeasureUnit>) =>
		sortAscBy(measureUnits, [{ fn: x => x.Name }, { fn: x => x.ID }]),
	)(measureUnits);

	return filteredMeasureUnits;
});

const selectFilteredMeasureUnits = createSelector(
	selectSortedMeasureUnits,
	selectSearchText,
	(measureUnits, searchText) => {
		const detectIsMatchName = (x: MeasureUnit) => (x.Name || '').toLowerCase().indexOf(searchText.toLowerCase()) !== -1;
		const detectIsMatchCode = (x: MeasureUnit) => (x.Code || '').toLowerCase().indexOf(searchText.toLowerCase()) !== -1;
		const filteredMeasureUnits = compose((measureUnits: Array<MeasureUnit>) =>
			searchText ? measureUnits.filter(x => detectIsMatchName(x) || detectIsMatchCode(x)) : measureUnits,
		)(measureUnits);

		return filteredMeasureUnits;
	},
);

const selectCorrectedMeasureUnits = createSelector(
	selectFilteredMeasureUnits,
	selectUnsavedMeasureUnits,
	(measureUnits, unsavedMeasureUnits) => {
		if (unsavedMeasureUnits?.length) {
			let correctedMeasureUnits = [...measureUnits];
			const correctedMeasureUnitsMap = createObjectMap(correctedMeasureUnits, x => x.ID);
			for (let i = 0; i < unsavedMeasureUnits.length; i++) {
				correctedMeasureUnitsMap[unsavedMeasureUnits[i].ID].IsFavorite = unsavedMeasureUnits[i].IsFavorite;
			}

			return correctedMeasureUnits;
		}

		return measureUnits;
	},
);

const selectFavoriteMeasureUnits = createSelector(selectSortedMeasureUnits, measureUnits => {
	const filteredMeasureUnits = compose((measureUnits: Array<MeasureUnit>) => measureUnits.filter(x => x.IsFavorite))(
		measureUnits,
	);

	return filteredMeasureUnits;
});

const selectLastMeasureUnit = createSelector(
	selectMeasureUnitsMap,
	selectLastMeasureUnitID,
	(measureUnitsMap, lastMeasureUnitID) => {
		return lastMeasureUnitID > -1 ? measureUnitsMap[lastMeasureUnitID] : null;
	},
);

function selectUnsavedMeasureUnits(state: IAppState) {
	return state.measureUnits.main.unsavedMeasureUnits;
}

function selectUnsavedMeasureUnitsLength(state: IAppState) {
	return state.measureUnits.main.unsavedMeasureUnitsLength;
}

function selectLastMeasureUnitID(state: IAppState) {
	return state.measureUnits.main.lastMeasureUnitID;
}

export const mainMeasureUnitsSelectorsPack = {
	selectAsyncMeasureUnits,
	selectMeasureUnitsMap,
	selectSortedMeasureUnits,
	selectFilteredMeasureUnits,
	selectCorrectedMeasureUnits,
	selectFavoriteMeasureUnits,
	selectLastMeasureUnit,
	selectSearchText,
	selectUnsavedMeasureUnits,
	selectUnsavedMeasureUnitsLength,
	selectLastMeasureUnitID,
};

export { selectAsyncMeasureUnits, selectMeasureUnitsMap, selectLastMeasureUnit };
