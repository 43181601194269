import styled from 'styled-components';

import { CloseButtonProps } from './close-button';

type RootProps = CloseButtonProps;

const Root = styled.button<RootProps>`
	position: relative;
	overflow: hidden;
	border: 0;
	background-color: transparent;
	text-align: center;
	transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	border-radius: 50%;
	outline: none;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	padding: 4px;

	&:hover,
	&:focus {
		background-color: rgba(0, 0, 0, 0.1);
	}
`;

export { Root };
