import React, { memo } from 'react';

import { useMapState, useMapDispatch, useAutoFetch } from '@flux';
import { extractKeysToArray } from '@utils/object';
import { withFormAutopicker } from '@ui/autopicker';
import { mainReferenceActionsPack } from '@reference/actions';
import { mainReferenceSelectorsPack } from '@reference/selectors';
import {
	CurrencyAutopicker as XCurrencyAutopicker,
	CurrencyAutopickerProps as XCurrencyAutopickerProps,
} from './currency-autopicker.view';

export type CurrencyAutopickerProps = {} & Omit<XCurrencyAutopickerProps, 'dataSource' | 'isFetching' | 'isLoaded'>;

const CurrencyAutopicker: React.FC<CurrencyAutopickerProps> = memo(props => {
	const [isFetching, isLoaded, dataSource] = useMapState([
		mainReferenceSelectorsPack.selectAsyncCurrencies.selectIsFetching,
		mainReferenceSelectorsPack.selectAsyncCurrencies.selectIsLoaded,
		mainReferenceSelectorsPack.selectFilteredCurrencies,
	]);
	const [fetchLegalEntityCurrencies] = useMapDispatch([mainReferenceActionsPack.fetchCurrencies]);

	useAutoFetch({
		selector: mainReferenceSelectorsPack.selectAsyncCurrencies.selectDidInvalidate,
		fetch: () => fetchLegalEntityCurrencies(),
	});

	return <XCurrencyAutopicker {...props} isFetching={isFetching} isLoaded={isLoaded} dataSource={dataSource} />;
});

const FormCurrencyAutopicker = withFormAutopicker<CurrencyAutopickerProps, unknown>(CurrencyAutopicker);

const currencyAutopickerTransformer = {
	ID: {
		single: {
			transformInput: ({ input }: { input: number }) => (input > 0 ? { [input]: true } : null),
			transformOutput: ({ output }) => Number(extractKeysToArray(output)[0]) || -1,
		},
	},
	currency: {
		single: {
			transformInput: ({ input }: { input: Currency }) => (input ? { [input.ID]: true } : null),
			transformOutput: ({ items }) => (items[0] as Currency) || null,
		},
	},
};

export { CurrencyAutopicker, FormCurrencyAutopicker, currencyAutopickerTransformer };
