const WAITING = 'WAITING';
const IN_PROGRESS = 'IN_PROGRESS';
const SUCCESS = 'SUCCESS';
const FAILED = 'FAILED';
const FINISHED_WITH_WARNING = 'FINISHED_WITH_WARNING';
const DUPLICATE_REQUEST = 'DUPLICATE_REQUEST';


export {
	WAITING,
	IN_PROGRESS,
	SUCCESS,
	FAILED,
	FINISHED_WITH_WARNING,
	DUPLICATE_REQUEST
}
