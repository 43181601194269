import axios from 'axios';
import * as moment from 'moment';

import { buildTLongArray } from '@core/utils';
import { addMonthsFromToday, today } from '@core/utils/date';
import { BASE_DATE_FORMAT } from '@shared/constants/time';
import { renderDefaultAcceptanceActTemplateMarkup } from '@utils/documents';
import { fromStringToBoolean } from '@utils/serializer';
import { getTicketUid } from '@utils/storage';
import * as corepackage from 'corepackage-api';
import * as invoicepackage from 'invoicepackage-api';

type ApiDocument = {
	UID: string;
	CreateDateTime: string;
	ExportStatusCode: ExportStatusCode;
	DownloadURL: string;
	PublisherTenantID: number;
	DocumentType: string;
	PublisherUserID: number;
	AuditDateTime: string;
};

const CORE_SERVICES_API_ENDPOINT = `${location.origin}/snc-core-services/api`;
const DOCUMENT_SERVICE_API_ENDPOINT = `${location.origin}/atr-document-services/api`;

enum ExportStatusCode {
	NOT_EXPORTED = 'NOT_EXPORTED',
	EXPORTING = 'EXPORTING',
	SUCCESS = 'SUCCESS',
	FAILED = 'FAILED',
}

enum FinancialDocumentType {
	CONTRACT = 'Contract',
}

const invoiceApi = {
	package: invoicepackage,
	financialDocument: {
		client: new invoicepackage.FinancialDocumentServiceClient(),
		addDocument: (document: FinancialDocument) => {
			return new Promise<FinancialDocument>(resolve => {
				invoiceApi.financialDocument.client.addDocument(document, -1, result => {
					resolve(result);
				});
			});
		},
		checkInvoiceCanBeModified: (invoiceID: number) => {
			return new Promise<boolean>(resolve => {
				invoiceApi.financialDocument.client.checkInvoiceCanBeModified(invoiceID, result => {
					resolve(result.Success);
				});
			});
		},
		createDefaultInvoice: (autoinsert?: Partial<Invoice>) => {
			const mergeObject = autoinsert || {};
			const requiredPaymentDate = moment().add(10, 'd').format(BASE_DATE_FORMAT);
			const invoice: Invoice = {
				...new invoiceApi.package.Invoice(),
				Outgoing: true,
				LegalEntity: mergeObject.LegalEntity || null,
				Counterparty: mergeObject.Counterparty || null,
				SignEmployee: mergeObject.SignEmployee || null,
				FundsRegister: mergeObject.FundsRegister || null,
				Project: mergeObject.Project || null,
				DateIssued: mergeObject.DateIssued || moment().format(BASE_DATE_FORMAT),
				RequiredPaymentDate: mergeObject.RequiredPaymentDate || requiredPaymentDate,
				ExpectedPaymentDate: mergeObject.ExpectedPaymentDate || requiredPaymentDate,
				PaymentProbability: mergeObject.PaymentProbability || 100,
			};

			return invoice;
		},
		fetchContractsDocuments: () => {
			return new Promise<Array<Contract>>(resolve => {
				const request = {
					...new invoiceApi.package.FinancialDocumentFilter(),
					FinancialDocumentType: FinancialDocumentType.CONTRACT,
					PeriodDateEnd: today(),
					PeriodDateStart: addMonthsFromToday(-1 * 12 * 10),
				};

				invoiceApi.financialDocument.client.getDocumentList(request, result => {
					const contracts = result as Array<Contract>;

					resolve(contracts);
				});
			});
		},
		fetchDocumentByID: (ID: number) => {
			return new Promise<FinancialDocument>(resolve => {
				invoiceApi.financialDocument.client.getDocumentByID(ID, result => {
					resolve(result);
				});
			});
		},
		fetchDocumentByNumber: (
			documentNumber: string,
			documentType: FinancialDocumentTypeCode = FinancialDocumentTypeCode.INVOICE,
		) => {
			return new Promise<FinancialDocument>(resolve => {
				invoiceApi.financialDocument.client.getDocumentByNumber(documentNumber, documentType, result => {
					resolve(result);
				});
			});
		},
		fetchDocumentByNumberInYear: (
			documentNumber: string,
			dateInYear: string,
			documentType: FinancialDocumentTypeCode = FinancialDocumentTypeCode.INVOICE,
		) => {
			const dateMoment = moment(dateInYear, BASE_DATE_FORMAT);
			return new Promise<FinancialDocument>(resolve => {
				const request = {
					...new invoiceApi.package.FinancialDocumentFilter(),
					Number: documentNumber,
					FinancialDocumentType: documentType,
					PeriodDateStart: dateMoment.startOf('year').format(BASE_DATE_FORMAT),
					PeriodDateEnd: dateMoment.endOf('year').format(BASE_DATE_FORMAT),
					Revoked: false,
				};
				invoiceApi.financialDocument.client.getDocumentList(request, result => {
					resolve(result.length == 0 ? undefined : result[0]);
				});
			});
		},
		fetchDocumentBySharedDocumentUID: (uid: string) => {
			return new Promise<FinancialDocument>(resolve => {
				const url = `${location.origin}/atr-document-services/api/documents/${uid}`;

				axios({ method: 'get', url }).then(result => {
					const invoice: Invoice = result.data;

					resolve(invoice);
				});
			});
		},
		fetchDocumentByUID: (uid: string) => {
			return new Promise<FinancialDocument>(resolve => {
				invoiceApi.financialDocument.client.getDocumentByUID(uid, result => {
					resolve(result);
				});
			});
		},
		fetchInvoiceNotificationHistory: (invoiceID: number) => {
			return new Promise<Array<InvoiceNotificationEvent>>(resolve => {
				invoiceApi.financialDocument.client.getInvoiceNotificationHistory(invoiceID, result => {
					resolve(result);
				});
			});
		},
		fetchInvoices: (dateRange: DateRange, fundsRegisterIDs: number[] = null) => {
			return new Promise<Array<Invoice>>(resolve => {
				const request = {
					...new invoiceApi.package.FinancialDocumentFilter(),
					FinancialDocumentType: 'Invoice',
					FundsRegisterIDList: Boolean(fundsRegisterIDs?.length) ? buildTLongArray(fundsRegisterIDs) : null,
					PeriodDateEnd: dateRange.dateEnd,
					PeriodDateStart: dateRange.dateStart,
				};

				invoiceApi.financialDocument.client.getDocumentList(request, result => {
					const invoices = result.filter(x => x.Outgoing) as Array<Invoice>;

					resolve(invoices);
				});
			});
		},
		fetchLastInvoice: () => {
			return new Promise<Invoice>(resolve => {
				invoiceApi.financialDocument.client.getLastIssuedDocument(FinancialDocumentTypeID.INVOICE, result => {
					resolve(result as Invoice);
				});
			});
		},
		fetchNextDocumentNumber: (documentTypeID: FinancialDocumentTypeID) => {
			return new Promise<string>(resolve => {
				invoiceApi.financialDocument.client.getNextDocumentNumber(documentTypeID, result => {
					resolve(result);
				});
			});
		},
		linkPaymentsToInvoice: (options: LinkPaymentsToInvoiceOptions) => {
			const { invoiceID, paymentIDsForLink = [], paymentIDsForUnlink = [] } = options;
			return new Promise<boolean>(async resolve => {
				const asyncQueueOne = paymentIDsForLink.map(
					paymentID =>
						new Promise<Invoice>(resolve => {
							invoiceApi.financialDocument.client.linkPaymentToInvoice(invoiceID, paymentID, result => {
								resolve(result);
							});
						}),
				);
				const asyncQueueTwo = paymentIDsForUnlink.map(
					paymentID =>
						new Promise<Invoice>(resolve => {
							invoiceApi.financialDocument.client.removeInvoicePaymentLink(invoiceID, paymentID, result => {
								resolve(result);
							});
						}),
				);

				await Promise.all([...asyncQueueOne, ...asyncQueueTwo]);

				resolve(true);
			});
		},
		removeDocument: (ID: number) => {
			return new Promise<boolean>(resolve => {
				invoiceApi.financialDocument.client.removeDocument(ID, result => {
					resolve(fromStringToBoolean(result));
				});
			});
		},
		revokeDocument: (options: RevokeDocumentOptions) => {
			const { documentID, emailFrom, emailTo, message } = options;

			return new Promise<boolean>(resolve => {
				invoiceApi.financialDocument.checkInvoiceCanBeModified(documentID).then(result => {
					if (!result) return resolve(false);
					invoiceApi.financialDocument.client.revokeDocument(documentID, emailFrom, emailTo, message, result => {
						resolve(result.Success);
					});
				});
			});
		},
		sendInvoiceEmail: (options: SendInvoiceEmailOptions) => {
			const { invoiceID, emailFrom, emailTo, message } = options;

			return new Promise<boolean>(resolve => {
				invoiceApi.financialDocument.client.sendInvoiceEMail(invoiceID, emailFrom, emailTo, message, result => {
					resolve(fromStringToBoolean(result));
				});
			});
		},
		sendInvoiceEmails: async (options: SendInvoiceEmailsOptions): Promise<boolean> => {
			const { invoiceID, emailFrom, emailsTo, message } = options;
			const result = await Promise.all(
				emailsTo.map(emailTo =>
					invoiceApi.financialDocument.sendInvoiceEmail({
						invoiceID,
						emailFrom,
						emailTo,
						message,
					}),
				),
			);

			return result.length > 0 && result.every(Boolean);
		},
		sendInvoiceWhatsApp: (options: SendInvoiceWhatsAppOptions) => {
			const { invoiceID, phoneNumber } = options;

			return new Promise<NaturalKey>(resolve => {
				invoiceApi.financialDocument.client.sendInvoiceWhatsApp(invoiceID, phoneNumber, result => {
					resolve(result);
				});
			});
		},
		setResponsibleEmployee: (documentID: number, employeeID: number) => {
			return new Promise<boolean>(resolve => {
				invoiceApi.financialDocument.client.setResponsibleEmployee(documentID, employeeID, result => {
					resolve(fromStringToBoolean(result));
				});
			});
		},
		shareDocument: (documentID: number) => {
			return new Promise<FinancialDocument>(resolve => {
				invoiceApi.financialDocument.client.shareDocument(documentID, result => {
					resolve(result);
				});
			});
		},
		updateDocument: (document: FinancialDocument) => {
			return new Promise<FinancialDocument>(resolve => {
				invoiceApi.financialDocument.client.changeDocument(document, result => {
					resolve(result);
				});
			});
		},
	},
	financialDocumentTemplate: {
		client: new invoicepackage.FinancialDocumentTemplateServiceClient(),
		fetchTemplates: () => {
			return new Promise<Array<FinancialDocumentTemplate>>(resolve => {
				invoiceApi.financialDocumentTemplate.client.getTemplates(result => {
					resolve(result);
				});
			});
		},
		fetchTemplateByID: (ID: number) => {
			return new Promise<FinancialDocumentTemplate>(resolve => {
				invoiceApi.financialDocumentTemplate.client.getTemplateByID(ID, result => {
					resolve(result);
				});
			});
		},
		addTemplate: (template: FinancialDocumentTemplate) => {
			return new Promise<FinancialDocumentTemplate>(resolve => {
				invoiceApi.financialDocumentTemplate.client.addTemplate(template, result => {
					resolve(result);
				});
			});
		},
		updateTemplate: (template: FinancialDocumentTemplate) => {
			return new Promise<FinancialDocumentTemplate>(resolve => {
				invoiceApi.financialDocumentTemplate.client.updateTemplate(template, result => {
					resolve(result);
				});
			});
		},
		removeTemplate: (ID: number) => {
			return new Promise<boolean>(resolve => {
				invoiceApi.financialDocumentTemplate.client.removeTemplate(ID, result => {
					resolve(fromStringToBoolean(result));
				});
			});
		},
		fetchNonidempotentTemplates: () => {
			return new Promise<Array<FinancialDocumentTemplate>>(resolve => {
				invoiceApi.financialDocumentTemplate.fetchTemplates().then(result => {
					if (result.length === 0) {
						const defaultTemplate: FinancialDocumentTemplate = {
							...new invoiceApi.package.FinancialDocumentTemplate(),
							Name: 'Шаблон по умолчанию (акт)',
							FinancialDocumentType: {
								...new corepackage.NaturalKey(),
								ID: 2,
								Name: 'Акт о выполнении работ/услуг',
							},
							Body: renderDefaultAcceptanceActTemplateMarkup(),
						};

						invoiceApi.financialDocumentTemplate.addTemplate(defaultTemplate).then(result => {
							resolve([result]);
						});
					} else {
						resolve(result);
					}
				});
			});
		},
	},
	rest: {
		fetchApiInvoices: (options: FetchApiInvoicesOptions) => {
			const { dateRange } = options;

			return new Promise<Array<APIInvoice>>(async (resolve, reject) => {
				const url = `${CORE_SERVICES_API_ENDPOINT}/v2/invoices`;

				try {
					const result = await axios({
						method: 'get',
						url,
						params: {
							periodStart: dateRange.dateStart,
							periodEnd: dateRange.dateEnd,
						},
						headers: {
							ticketuid: getTicketUid(),
						},
					});

					resolve(result.data);
				} catch (error) {
					reject(error);
				}
			});
		},
		exportSharedDocumentToPDF: (options: ExportSharedDocumentToPdfOptions) => {
			const { sharedDocumentUID, onCheckStatus } = options;

			return new Promise((resolve, reject) => {
				const makeRequest = () => {
					const url = `${DOCUMENT_SERVICE_API_ENDPOINT}/documents/${sharedDocumentUID}/pdf`;

					axios
						.post(url)
						.then(response => {
							const document = response.data as ApiDocument;

							if (document.ExportStatusCode === ExportStatusCode.SUCCESS) {
								onCheckStatus({
									inProgress: false,
									isSuccess: true,
									downloadURL: document.DownloadURL,
								});
								resolve(null);
							} else if (document.ExportStatusCode === ExportStatusCode.FAILED) {
								onCheckStatus({
									inProgress: false,
									isSuccess: false,
									downloadURL: '',
								});
								resolve(null);
							} else {
								onCheckStatus({
									inProgress: true,
									isSuccess: false,
									downloadURL: '',
								});
								setTimeout(() => {
									makeRequest();
								}, 1000);
							}
						})
						.catch(error => reject(error.response));
				};

				makeRequest();
			});
		},
	},
};

export type RevokeDocumentOptions = {
	documentID: number;
	emailFrom: string;
	emailTo: string;
	message?: string;
};

export type SendInvoiceEmailOptions = {
	invoiceID: number;
	emailFrom: string;
	emailTo: string;
	message?: string;
};

export type SendInvoiceEmailsOptions = {
	invoiceID: number;
	emailFrom: string;
	emailsTo: Array<string>;
	message?: string;
};

export type SendInvoiceWhatsAppOptions = {
	invoiceID: number;
	phoneNumber: string;
};

export type LinkPaymentsToInvoiceOptions = {
	invoiceID: number;
	paymentIDsForLink?: Array<number>;
	paymentIDsForUnlink?: Array<number>;
};

export type ExportSharedDocumentToPdfOptions = {
	sharedDocumentUID: string;
	onCheckStatus: (options: OnCheckStatusOptions) => void;
};

type OnCheckStatusOptions = {
	isSuccess: boolean;
	inProgress: boolean;
	downloadURL: string;
};

export enum FinancialDocumentTypeCode {
	ACCEPTANCE_ACT = 'AcceptanceAct', // Акт о выполнении работ/услуг
	ACCEPTANCE_ACT_NATURAL_PERSON = 'AcceptanceActNaturalPerson', // Акт о выполнении работ/услуг для физ.лиц
	CHEQUE = 'Cheque', // Чек
	CUSTOM = 'Custom', // Пользовательский
	DELIVERY_NOTE = 'DeliveryNote', // Товарная накладная
	INVOICE = 'Invoice', // Счёт на оплату
	INVOICE_FACT = 'InvoiceFact', // Счёт-фактура
	RECEIPT = 'Receipt', // Расписка
	SERVICE_NOTE = 'ServiceNote', // Служебная записка
	UNDOCUMENT_EXPENSE_INVOICE = 'UndocumentExpenseInvoice', // Акт неподтверждённых расходов
	UNI_TRANSFER_DOCUMENT = 'UniTransferDoc', // УПД
}

export enum FinancialDocumentTypeID {
	ACCEPTANCE_ACT = 2, // Акт о выполнении работ/услуг
	ACCEPTANCE_ACT_NATURAL_PERSON = 12, // Акт о выполнении работ/услуг для физ.лиц
	CHEQUE = 3, // Чек
	CUSTOM = 10, // Пользовательский
	DELIVERY_NOTE = 1, // Товарная накладная
	INVOICE = 7, // Счёт на оплату
	INVOICE_FACT = 8, // Счёт-фактура
	RECEIPT = 4, // Расписка
	SERVICE_NOTE = 5, // Служебная записка
	UNDOCUMENT_EXPENSE_INVOICE = 6, // Акт неподтверждённых расходов
	UNI_TRANSFER_DOCUMENT = 9, // УПД
}

type FetchApiInvoicesOptions = {
	dateRange: DateRange;
};

export { invoiceApi };
