import styled, { createGlobalStyle, css, keyframes } from 'styled-components';

import { pxToRem } from '@stylesheet';

const GlobalStyle = createGlobalStyle`
	*,
	*::after,
	*::before {
		transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out !important;
	}
`;

type SwitchProps = {
	isActive: boolean;
};

const switchRightMotion = keyframes`
	0% {
		transform: scale(1, 1) translate(0, 0);
	}

	50% {
		transform: scale(1.4, 1) translate(50%, 0);
	}

	100% {
		transform: scale(1, 1) translate(100%, 0);
	}
`;

const switchLeftMotion = keyframes`
	0% {
		transform: scale(1, 1) translate(100%, 0);
	}

	50% {
		transform: scale(1.4, 1) translate(50%, 0);
	}

	100% {
		transform: scale(1, 1) translate(0, 0);
	}
`;

const Root = styled.div`
	position: relative;
	display: inline-flex;
	flex-flow: column nowrap;
`;

const ContentLayout = styled.div`
	position: relative;
	display: inline-flex;
	align-items: center;
	width: auto;
	height: auto;
`;

const SwitchLayout = styled.div<SwitchProps>`
	position: relative;
	display: inline-flex;
	width: 40px;
	height: 20px;
	border-radius: 10px;
	background-color: #82cbf5;
	cursor: pointer;
	transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
	outline: none;

	${p =>
		p.isActive &&
		css`
			background-color: #1e0082;
		`}
`;

const Circle = styled.div.attrs(() => ({
	className: 'switch-circle',
}))<SwitchProps>`
	position: absolute;
	top: 0;
	left: 0;
	display: inline-flex;
	width: 30px;
	height: 30px;
	z-index: 1;

	${p =>
		!p.isActive &&
		css`
			animation: ${switchLeftMotion};
			animation-duration: 0.2s;
			animation-timing-function: ease-in-out;
			animation-iteration-count: 1;
			animation-fill-mode: forwards;
		`}

	${p =>
		p.isActive &&
		css`
			animation: ${switchRightMotion};
			animation-duration: 0.2s;
			animation-timing-function: ease-in-out;
			animation-iteration-count: 1;
			animation-fill-mode: forwards;
		`}
`;

const Emoji = styled.span`
	position: absolute;
	top: -5px;
	left: -8px;
	font-size: ${pxToRem(22)};
	user-select: none;
`;

export { GlobalStyle, Root, ContentLayout, SwitchLayout, Circle, Emoji };
