function detectIsFunction(x: unknown): x is Function {
	return typeof x === 'function';
}

function detectIsUndefined(x: unknown): x is undefined {
	return typeof x === 'undefined';
}

function groupBy<T>(elements: Array<T>, selector: (x: T) => string | number): Record<string, Array<T>> {
	const map: Record<string, Array<T>> = {};

	for (const x of elements) {
		const key = selector(x);

		if (!key) continue;

		if (!map[key]) {
			map[key] = [];
		}

		map[key].push(x);
	}

	return map;
}

function compose<T, S = T>(...funcs: Array<(x) => any>): (x: S) => T {
	if (funcs.length === 0) return x => x as unknown as T;
	if (funcs.length === 1) return funcs[0];

	return funcs.reduce(
		(fn1, fn2) =>
			(...args) =>
				fn1(fn2(...args)),
	);
}

function deepClone(value: any) {
	const isObject = typeof value === 'object';
	const isFunction = typeof value === 'function';
	const copyObj = isObject
		? Array.isArray(value)
			? [...value]
			: value
			? { ...value }
			: value
		: isFunction
		? function (...rest) {
				return value.apply(this, ...rest);
		  }
		: value;
	const clonePropsFn = (prop: string) => (copyObj[prop] = deepClone(copyObj[prop]));

	Boolean(copyObj) && isObject && Object.keys(copyObj).forEach(clonePropsFn);

	return copyObj;
}

function dummy() {}

function debounce<T extends (...args) => void>(fn: T, timeout = 0): T {
	let timerID = null;
	const debounced: any = (...args) => {
		timerID && clearTimeout(timerID);
		timerID = setTimeout(() => {
			fn(...args);
		}, timeout);
	};

	return debounced;
}

type NestedArray<T> = T | Array<NestedArray<T>>;

function flatten<T = any>(source: Array<NestedArray<T>>): Array<T> {
	if (source.length === 0) return [];
	const list = [];
	const levelMap = { 0: { idx: 0, source } };
	let level = 0;

	do {
		const { source, idx } = levelMap[level];
		const item = source[idx];

		if (idx >= source.length) {
			level--;
			levelMap[level].idx++;
			continue;
		}

		if (Array.isArray(item)) {
			level++;
			levelMap[level] = {
				idx: 0,
				source: item,
			};
		} else {
			list.push(item);
			levelMap[level].idx++;
		}
	} while (!(level === 0 && levelMap[level].idx >= levelMap[level].source.length));

	return list;
}

type GetDiffOptions<T> = {
	sourceOne: Array<T>;
	sourceTwo: Array<T>;
	getID: (x: T) => string | number;
	getKey: (x: T) => string | number;
};

function getDiff<T>(options: GetDiffOptions<T>) {
	const { sourceOne, sourceTwo, getID, getKey } = options;
	const diff: Record<string | number, boolean> = {};
	const sortFn = (a: T, b: T) => (getKey(a) > getKey(b) ? 1 : -1);
	const sortedSourceOne = [...sourceOne].sort(sortFn);
	const sortedSourceTwo = [...sourceTwo].sort(sortFn);

	for (let i = 0; i < sortedSourceTwo.length; i++) {
		if (getKey(sortedSourceOne[i]) !== getKey(sortedSourceTwo[i])) {
			const item = sortedSourceTwo[i];

			if (item) {
				diff[getID(item)] = true;
			}
		}
	}

	return diff;
}

type DetectIsObjectsShallowEqualOptions<T> = {
	objectOne: T;
	objectTwo: T;
	extractKeys?: (x: T) => Array<string>;
};

function detectIsObjectsShallowEqual<T extends object>(options: DetectIsObjectsShallowEqualOptions<T>) {
	const { objectOne, objectTwo, extractKeys = (x: T) => Object.keys(x) } = options;
	const keysOne = extractKeys(objectOne);
	const keysTwo = extractKeys(objectTwo);

	if (keysOne.length !== keysTwo.length) {
		return false;
	}

	for (const key of keysOne) {
		if (objectOne[key] !== objectTwo[key]) {
			return false;
		}
	}

	return true;
}

export {
	detectIsFunction,
	detectIsUndefined,
	groupBy,
	compose,
	deepClone,
	dummy,
	debounce,
	flatten,
	getDiff,
	detectIsObjectsShallowEqual,
};
