// @ts-nocheck
import styled, { css } from 'styled-components';

import { IShapeModuleStyled, cssUtils, sharedCSS } from '../shape';

declare namespace ILayoutBaseModule {
	interface ILayoutShared extends IShapeModuleStyled.IShape {}
	interface ILayoutBase extends ILayoutShared {}
	interface ILayoutItemBase extends ILayoutShared {}
}

const sharedLayoutCSS = css`
	${cssUtils.resetCSS()}
	${sharedCSS}
`;

const LayoutBase = styled.div`
	${sharedLayoutCSS}
`;

const LayoutItemBase = styled.div`
	${sharedLayoutCSS}
`;

export { ILayoutBaseModule, LayoutBase, LayoutItemBase };
