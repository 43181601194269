import React from 'react';

import { IconBase, IconBaseProps } from './base';

export type UnitReferencesIconProps = IconBaseProps;

const UnitReferencesIcon: React.FC<UnitReferencesIconProps> = props => {
	return (
		<IconBase {...props}>
			<svg
				stroke='currentColor'
				fill='currentColor'
				strokeWidth='0'
				viewBox='0 0 24 24'
				xmlns='http://www.w3.org/2000/svg'>
				<g>
					<path fill='none' d='M0 0h24v24H0z'></path>
					<path d='M13 21v2h-2v-2H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h6a3.99 3.99 0 0 1 3 1.354A3.99 3.99 0 0 1 15 3h6a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1h-8zm7-2V5h-5a2 2 0 0 0-2 2v12h7zm-9 0V7a2 2 0 0 0-2-2H4v14h7z'></path>
				</g>
			</svg>
		</IconBase>
	);
};

export { UnitReferencesIcon };
