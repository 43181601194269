import styled, { css } from 'styled-components';

import { getMeasureProp } from '@stylesheet';
import { ViewProps } from './view';

type ViewRootProps = {} & Partial<ViewProps>;

type TitleProps = {
	marginRight?: string | number;
	inline?: boolean;
};

const ViewLayoutRoot = styled.section`
	position: relative;
	width: 100%;
`;

const ViewRoot = styled.div`
	position: relative;
	width: 100%;
	padding: 16px 40px 40px 40px;
	z-index: 1;

	${p => css`
		background-color: ${p.theme.palette.space};
		${p.theme.fn.createBoxShadow(1)};
	`}

	${(p: ViewRootProps) => css`
		min-height: calc(100vh - ${70 + p.topShift}px);
	`}

	${(p: ViewRootProps) =>
		(p.withoutExtraView || p.fullWidth) &&
		css`
			box-shadow: none;
		`}

	${(p: ViewRootProps) =>
		p.withoutPadding &&
		css`
			padding: 0;
		`}

	${(p: ViewRootProps) =>
		p.transparentMode &&
		css`
			background-color: transparent;
			box-shadow: none;
		`}

	${(p: ViewRootProps) =>
		p.autoHeight &&
		css`
			min-height: 0;
		`}

	@media(max-width: 1200px) {
		padding: 20px;
	}
`;

const NestedRoutesView = styled(ViewRoot)`
	min-height: 0;
`;

const FixedView = styled.div.attrs({
	id: 'fixed-view',
})`
	position: fixed;
	width: inherit;

	@media (max-width: 1200px) {
		position: relative;
	}
`;

interface IHeader {
	spaceBetween?: boolean;
	column?: boolean;
	withPadding?: boolean;
	withoutMargin?: boolean;
	padding?: string;
	maxWidth?: string;
}

const Header = styled.div`
	position: relative;
	width: 100%;
	min-height: 35px;
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 20px;

	${(props: IHeader) =>
		props.spaceBetween &&
		css`
			justify-content: space-between;
		`}

	${(props: IHeader) =>
		props.column &&
		css`
			flex-flow: column nowrap;
			align-items: flex-start;
		`}

	${(props: IHeader) =>
		props.withPadding &&
		css`
			padding: 16px 40px 40px 40px;
		`}

	${(props: IHeader) =>
		props.withoutMargin &&
		css`
			margin: 0;
		`}

	${(props: IHeader) =>
		props.padding &&
		css`
			padding: ${props.padding};
		`}

	${(props: IHeader) =>
		props.maxWidth &&
		css`
			max-width: ${props.maxWidth};
		`}
`;

interface IHeaderGroupLayout {
	fullWidth?: boolean;
}

const HeaderGroupLayout = styled.div`
	display: flex;
	flex-flow: row wrap;
	align-items: center;

	${(props: IHeaderGroupLayout) =>
		props.fullWidth &&
		css`
			width: 100%;
		`}
`;

const Title = styled.h1`
	font-size: 24px;
	line-height: 1.33;

	${(p: TitleProps) =>
		p.marginRight &&
		css`
			margin-right: ${getMeasureProp(p.marginRight)};
		`}

	${(p: TitleProps) =>
		p.inline &&
		css`
			display: inline;
		`}

	@media(max-width: 1400px) {
		font-size: 20px;
	}
`;

const MaxWidthLayout = styled.div`
	width: 100%;
	max-width: 920px;
	display: flex;
	flex-flow: row wrap;
	align-items: center;
`;

const WithActionButton = styled.div`
	width: 100%;
	display: flex;
	flex-flow: row nowrap;
	align-items: flex-start;
	justify-content: space-between;
`;

interface IWithActionButtonHeader {
	block?: boolean;
}

const WithActionButtonHeader = styled.div`
	flex-shrink: 1;
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	margin-right: 10px;

	${(p: IWithActionButtonHeader) =>
		p.block &&
		css`
			display: block;
		`}

	@media(max-width: 1200px) {
		margin-bottom: 10px;
	}
`;

const ActionButtonLayout = styled.div`
	display: flex;
	flex-shrink: 0;
	flex-flow: nowrap row;
`;

export {
	ViewLayoutRoot,
	ViewRoot,
	NestedRoutesView,
	FixedView,
	Header,
	HeaderGroupLayout,
	Title,
	MaxWidthLayout,
	WithActionButton,
	WithActionButtonHeader,
	ActionButtonLayout,
};
