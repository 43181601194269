// @ts-ignore
import * as configurationpackage from 'configurationpackage-api';


const configurationApi = {
	package: configurationpackage as typeof configurationpackageApi,
	subsystem: {
		client: new configurationpackage.SubsystemServiceClient() as SubsystemServiceClient,
		fetchRemoteExternalServiceBySubsystemID(subsystemInstanceID: number) {
			return new Promise<RemoteExternalService>(resolve => {
				configurationApi.subsystem.client.getSubsystemInstanceByID(subsystemInstanceID, ssInstance => {
					const remoteExternalServiceID = ssInstance.Subsystem.ID;

					configurationApi.subsystem.client.getRemoteExternalServiceByID(remoteExternalServiceID, result => {
							resolve(result);
						});
					},
				);
			});
		},
	},
};

export {
	configurationApi,
};
