import React from 'react';
import { ReactNode } from 'react';

import { CheckIcon } from '@ui/icons/check';
import { Root, Label, StepperItemRoot, Divider, ItemLayout } from './styled';

export type Step<T = string | number> = {
	value: T;
	text: string;
};

export type RenderItemOptions = {
	item: Step;
	idx: number;
	isActive: boolean;
	isBefore: boolean;
	isAfter: boolean;
	disabled: boolean;
};

export type StepperProps = {
	appearance: 'horizontal' | 'vertical';
	disabled?: boolean;
	value: string | number;
	dataSource: Array<Step>;
	end?: string;
	renderItem: (options: RenderItemOptions) => ReactNode;
};

class Stepper extends React.PureComponent<StepperProps> {
	static displayName = 'Stepper';

	render() {
		const { appearance, value, dataSource, disabled, end, renderItem } = this.props;
		const valueIdx = dataSource.findIndex(x => x.value === value);
		const isEnd = end && value && end === value;

		return (
			<Root appearance={appearance}>
				{dataSource.map((x, idx, arr) => {
					const isBefore = isEnd ? true : idx < valueIdx;
					const isAfter = isEnd ? false : idx > valueIdx;
					const isActive = idx === valueIdx;
					const isLast = idx === arr.length - 1;

					return (
						<ItemLayout key={x.value} appearance={appearance} isAfter={isAfter} isActive={isActive} isLast={isLast}>
							{renderItem({
								item: x,
								idx,
								isActive,
								isBefore,
								isAfter,
								disabled,
							})}
							{!isLast && <Divider appearance={appearance} />}
						</ItemLayout>
					);
				})}
			</Root>
		);
	}
}

export type StepperItemProps = {
	appearance?: 'default' | 'clickable';
	disabled?: boolean;
	children: React.ReactNode;
	onClick?: (e: React.SyntheticEvent<{}>) => void;
};

class StepperItem extends React.PureComponent<StepperItemProps> {
	static displayName = 'StepperItem';

	render() {
		return <StepperItemRoot {...this.props}>{this.props.children}</StepperItemRoot>;
	}
}

export type StepperLabelProps = {
	children: React.ReactNode;
} & Pick<RenderItemOptions, 'isBefore'>;

class StepperLabel extends React.PureComponent<StepperLabelProps> {
	static displayName = 'StepperLabel';

	render() {
		const { isBefore } = this.props;

		return <Label>{isBefore ? <CheckIcon color='light' size={10} /> : this.props.children}</Label>;
	}
}

export { Stepper, StepperItem, StepperLabel };
