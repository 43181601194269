import { combineReducers } from 'redux';

import { mainCashflowReportReducer, MainCashflowReportState } from './main.reducer';

export type CashflowReportState = {
	main: MainCashflowReportState;
};

const cashflowReportReducer = combineReducers<CashflowReportState>({
	main: mainCashflowReportReducer,
});

export { cashflowReportReducer };
