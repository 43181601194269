import styled, { css, keyframes } from 'styled-components';

const show = keyframes`
	0% {
		transform: translate3D(-600px, 0, 0);
	}

	100% {
		transform: translate3D(0, 0, 0);
	}
`;

const hide = keyframes`
	0% {
		transform: translate3D(0, 0, 0);
	}

	50% {
		transform: translate3D(-600px, 0, 0);
	}

	100% {
		visibility: hidden;
		max-height: 0;
		margin: 0;
		transform: translate3D(-600px, 0, 0);
	}
`;

const Root = styled.div`
	position: fixed;
	bottom: 20px;
	left: 30px;
	display: inline-flex;
	flex-flow: column-reverse nowrap;
	align-items: flex-start;
	backface-visibility: hidden;
	z-index: 11000;
`;

type MessageProps = {
	animationPaused: boolean;
};

const Message = styled.div<MessageProps>`
	position: relative;
	max-height: 200px;
	overflow: hidden;
	background-color: transparent;
	margin: 5px 0;
	animation: ${show} 0.6s ease-in-out 0s, ${hide} 0.6s ease-in-out 6s;
	animation-fill-mode: forwards;
	will-change: transform;
	transform: translate3D(-600px, 0, 0);
	backface-visibility: hidden;
	perspective: 1000;

	${p => css`
		${p.theme.fn.createBoxShadow(2)};
	`}

	${p =>
		p.animationPaused &&
		css`
			animation-play-state: paused;
		`}
`;

export { Root, Message };
