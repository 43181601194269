/***
 * Library contains JavaScript API for interaction with Seeneco Framework Web Services contained in OrganizationPackage
 * @author name Generated by Seeneco Framework Modeler 
 *
 */
(function(w){
/***
* 
*/
w.EmployeeBusinessRole = function() {
  this.PACKAGE = "ORGANIZATION";
  this.CLASSIFIER = "EMPLOYEE_BUSINESS_ROLE";
  this.CopySupportingElement = false;
  this.Name = "";
  this.ID = -1;
  this.SystemName = "";
};
/***
* 
*/
w.EmployeeMonthWorkdays = function() {
  this.PACKAGE = "ORGANIZATION";
  this.CLASSIFIER = "EMPLOYEE_MONTH_WORKDAYS";
  this.CopySupportingElement = false;
  this.MonthID = -1;
  this.WorkdayCount = -1;
};
 
 


/***
 * Package security rights constants
 */

w.OrganizationSecurityRights = {

};
})(typeof exports !== 'undefined' ? exports : window);
