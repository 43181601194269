import { createTypes, createStaticAction } from '@flux';

type ActionTypes = {
	MODIFY_INVOCE_BEFORE_PAYMENT: string;
	MODIFICATION_COMPLETED: string;
	AUTOINSERT_TO_NEW_INVOICE: string;
	EXPORT_INVOICE_TO_PDF: string;
};

const types = createTypes<ActionTypes>(
	['MODIFY_INVOCE_BEFORE_PAYMENT', 'MODIFICATION_COMPLETED', 'AUTOINSERT_TO_NEW_INVOICE', 'EXPORT_INVOICE_TO_PDF'],
	'INVOICE',
);

const actions = {
	modifyInvoiceBeforePayment: (options: ModifyInvoiceBeforePaymentOptions) =>
		createStaticAction<ModifyInvoiceBeforePaymentOptions>(types.MODIFY_INVOCE_BEFORE_PAYMENT)(options),
	completeModification: (invoice: Invoice) => createStaticAction(types.MODIFICATION_COMPLETED)(invoice),
	autoinsertToNewInvoice: (invoice: Partial<Invoice> | null) =>
		createStaticAction(types.AUTOINSERT_TO_NEW_INVOICE)(invoice),
};

const modifyInvoiceTypes = {
	CHANGE_LEGAL_ENTITY_ADDITIONAL_TAX_CODE: 'CHANGE_LEGAL_ENTITY_ADDITIONAL_TAX_CODE',
	SELECT_FUNDS_REGISTER: 'SELECT_FUNDS_REGISTER',
};

export type ModifyInvoiceBeforePaymentOptions = {
	type: keyof typeof modifyInvoiceTypes;
	invoice: Invoice;
	frList?: Array<BankAccountFundsRegister>;
};

const mainInvoiceActionsPack = {
	types,
	actions,
};

export { modifyInvoiceTypes, mainInvoiceActionsPack };
