const pathTo = {
	selectConditions: 'billing/selectConditions',
	selectTypePayer: 'billing/selectTypePayer',
	personPaymentResult: 'billing/personPaymentResult',
	legalEntityPaymentResult: 'billing/legalEntityPaymentResult',
	legalEntityPaymentResultWithCheck: 'billing/legalEntityPaymentResultWithCheck'
};


export {
	pathTo
}
