// @ts-nocheck
import {
	createDefaultAsyncItem,
	checkAsyncItemLoadAction
} from '../../../core/libs/reducer-creator';
import {
	ADD_INVOICE_PAYMENT_DRAFT,
	SEND_PAYMENT_DRAFT,
	FETCH_INVOICE_AFTER_PAYMENT,
	SYNCHRONIZE_PAYMENT_STATUS,
	SET_STATUS_FOR_FAST_PAY_INVOICE,
	CONTROL_FAST_PAY_INVOICE_FORM,
	SET_FUNDS_REGISTERS_FOR_FAST_PAY_INVOICE,
	CONTROL_REMOTE_PAYMENT_SIGNATURE_FORM,
} from '../actions/invoice-payment.actions';


interface IInvoicePaymentState {
	isFetching: boolean;
	didInvalidate: boolean;
	isLoaded: boolean;
	item: CMSCreatePaymentDraftResponse;
	invoicePayment: {
		isFetching: boolean;
		didInvalidate: boolean;
		isLoaded: boolean;
		item: Payment;
	};
	invoiceAfterPayment: {
		isFetching: boolean;
		didInvalidate: boolean;
		isLoaded: boolean;
		item: Invoice;
	};
	CMSPaymentStatusResponse: {
		isFetching: boolean;
		didInvalidate: boolean;
		isLoaded: boolean;
		item: CMSPaymentStatusResponse;
	};
	fastPayInvoiceStatus: Status<{}>;
	isFastPayFormOpen: boolean;
	isRemotePaymentSignatureFormOpen: boolean;
	fastPayFormCmsGUID: string;
	fastPay: {
		fundsRegisters: Array<BankAccountFundsRegister> | null;
		invoice: Invoice | null;
	}
}

const initialState = {
	...createDefaultAsyncItem(null),
	invoicePayment: createDefaultAsyncItem(null),
	invoiceAfterPayment: createDefaultAsyncItem(null),
	CMSPaymentStatusResponse: createDefaultAsyncItem(null),
	fastPayInvoiceStatus: null,
	isFastPayFormOpen: false,
	isRemotePaymentSignatureFormOpen: false,
	fastPayFormCmsGUID: '',
	fastPay: {
		fundsRegisters: null,
		invoice: null,
	},
};

export default function(state: IInvoicePaymentState = initialState, action) {
	switch (action.type) {

	case ADD_INVOICE_PAYMENT_DRAFT:
		return {
			...state,
			invoicePayment: checkAsyncItemLoadAction(action, state.invoicePayment)
		};

	case SEND_PAYMENT_DRAFT:
		return {
			...state,
			...checkAsyncItemLoadAction(action, state)
		};

	case FETCH_INVOICE_AFTER_PAYMENT:
		return {
			...state,
			invoiceAfterPayment: checkAsyncItemLoadAction(action, state.invoiceAfterPayment, (item: Invoice) => item.ID > 0 ? item : null)
		};

	case SYNCHRONIZE_PAYMENT_STATUS:
		return {
			...state,
			CMSPaymentStatusResponse: checkAsyncItemLoadAction(action, state.CMSPaymentStatusResponse)
		};

	case SET_STATUS_FOR_FAST_PAY_INVOICE:
		const setStatusAction = action as staticAction<StatusAction>;

		return {
			...state,
			fastPayInvoiceStatus: setStatusAction.value
		};

	case CONTROL_FAST_PAY_INVOICE_FORM:
		const controlFormAction = action as staticAction<{isOpen: boolean, cmsGUID: string}>;

		return {
			...state,
			isFastPayFormOpen: controlFormAction.value.isOpen,
			fastPayFormCmsGUID: controlFormAction.value.cmsGUID
		};

	case CONTROL_REMOTE_PAYMENT_SIGNATURE_FORM:
		const controlRemoteFormAction = action as staticAction<{
			isOpen: boolean;
			cmsGUID: string;
			invoice: null;
		}>;

		return {
			...state,
			isRemotePaymentSignatureFormOpen: controlRemoteFormAction.value.isOpen,
			fastPayFormCmsGUID: controlRemoteFormAction.value.cmsGUID,
			fastPay: {
				...state.fastPay,
				invoice: controlRemoteFormAction.value.invoice,
			},
		};

	case SET_FUNDS_REGISTERS_FOR_FAST_PAY_INVOICE:
		return {
			...state,
			fastPay: {
				...state.fastPay,
				fundsRegisters: (action as staticAction<Array<BankAccountFundsRegister> | null>).value
			}
		};

	default:
		return state
	}
}

export {
	IInvoicePaymentState
}
