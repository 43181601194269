import React from 'react';

import { IllustrationBase, IllustrationBaseProps } from './base';

export type DesktopIllustrationProps = IllustrationBaseProps;

const DesktopIllustration: React.FC<DesktopIllustrationProps> = props => {
	return (
		<IllustrationBase {...props}>
			<svg viewBox='0 0 240 240'>
				<g fill='currentColor'>
					<path d='M8,168h60v12H52v8h40v-8H76v-12h64c2.211,0,4-1.789,4-4V80c0-2.211-1.789-4-4-4H8c-2.211,0-4,1.789-4,4v84     C4,166.211,5.789,168,8,168z M12,84h124v76H76h-8H12V84z' />
					<rect x='168' y='60' width='40' height='8' />
					<path d='M196,108c-6.617,0-12,5.383-12,12s5.383,12,12,12s12-5.383,12-12S202.617,108,196,108z M196,124c-2.207,0-4-1.793-4-4     c0-2.207,1.793-4,4-4c2.207,0,4,1.793,4,4C200,122.207,198.207,124,196,124z' />
					<path d='M49.172,93.172l-24,24c-1.562,1.562-1.562,4.094,0,5.656C25.953,123.609,26.976,124,28,124s2.047-0.391,2.828-1.172     l24-24c1.562-1.562,1.562-4.094,0-5.656C53.266,91.609,50.734,91.609,49.172,93.172z' />
					<path d='M224,192V44c0-2.211-1.789-4-4-4h-64c-2.211,0-4,1.789-4,4v148H0v8h156h64h20v-8H224z M216,192h-56V48h56V192z' />
				</g>
			</svg>
		</IllustrationBase>
	);
};

export { DesktopIllustration };
