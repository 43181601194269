import { compose } from 'redux';

import { IAppState } from '@store';
import { createSelector, createAsyncSelector, createRightCheck } from '@flux';
import { sortDescBy } from '@utils/sorting';
import { hasKeys } from '@utils/object';
import { mainProjectManagementSelectorsPack } from '@project-management/selectors';

const selectManagersRight = createRightCheck({
	component: 'ProjectFinanceManagement',
	view: 'xProjects_ViewManagers',
	change: 'xProjects_ChangeManagers',
});

const selectAsyncProjectEmployees = createAsyncSelector<Array<EmployeeBrief>, IAppState>({
	get: s => s.projectEmployees.main.employees,
	selector: createSelector(
		s => s.projectEmployees.main.employees.item,
		item => item,
	),
});

const selectFilteredProjectEmployees = createSelector(
	selectAsyncProjectEmployees.selectItem,
	mainProjectManagementSelectorsPack.selectProjectsGrouppedByManagerID,
	selectFilterByText,
	selectProjectsFilter,
	selectBusinessRolesFilter,
	(sourceEmployees, grouppedProjectsMap, textFilter, projectsFilter, businessRolesFilter) => {
		const hasProjectsFilter = hasKeys(projectsFilter);
		const hasBusinessRolesFilter = hasKeys(businessRolesFilter);
		const employees = compose(
			(employees: Array<EmployeeBrief>) => sortDescBy<EmployeeBrief>(employees, [{ fn: x => x.ID }]),
			(employees: Array<EmployeeBrief>) =>
				hasBusinessRolesFilter ? employees.filter(x => businessRolesFilter[x.BusinessRole?.ID]) : employees,
			(employees: Array<EmployeeBrief>) =>
				hasProjectsFilter
					? employees.filter(x => {
							const projects = grouppedProjectsMap[x.ID] || [];
							const withoutProject = projectsFilter[-1] && projects.length === 0;
							const isIncludes = projects.some(x => projectsFilter[x.ID]);

							return isIncludes || withoutProject;
					  })
					: employees,
			(employees: Array<EmployeeBrief>) =>
				textFilter ? employees.filter(x => x.Name.toLowerCase().indexOf(textFilter.toLowerCase()) !== -1) : employees,
		)(sourceEmployees);

		return employees;
	},
);

function selectFilterByText(state: IAppState) {
	return state.projectEmployees.main.textFilter;
}

function selectProjectsFilter(state: IAppState) {
	return state.projectEmployees.main.projectsFilter;
}

function selectBusinessRolesFilter(state: IAppState) {
	return state.projectEmployees.main.businessRolesFilter;
}

export const mainProjectEmployeesSelectorsPack = {
	selectManagersRight,
	selectAsyncProjectEmployees,
	selectFilterByText,
	selectFilteredProjectEmployees,
	selectProjectsFilter,
	selectBusinessRolesFilter,
};

export { selectManagersRight };
