import styled, { css } from 'styled-components';

type RootProps = {
	hasLabel: boolean;
	disabled: boolean;
	paddingOff: boolean;
};

const Root = styled.div<RootProps>`
	position: relative;
	display: inline-block;
	width: 100%;

	${p =>
		p.hasLabel &&
		!p.paddingOff &&
		css`
			padding-top: 20px;
		`}

	& .radio-buttons-group_btns > div:not(:last-child) {
		margin-right: 20px;
	}

	${p =>
		p.disabled &&
		css`
			& svg {
				fill: ${p.theme.palette.label} !important;
			}
		`}
`;

const Label = styled.label`
	display: inline-block;
	width: 100%;
	font-size: 11px;
	line-height: 22px;
	pointer-events: none;
	user-select: none;
	margin-bottom: 4px;

	${p => css`
		color: ${p.theme.palette.label};
	`}
`;

export { Root, Label };
