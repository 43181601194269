import React from 'react';
import ReactDOM from 'react-dom';
import { ReactInstance, ComponentClass, CSSProperties } from 'react';

import { ItemRendererProps } from '../';
import LineSelectRenderer from '../line-select-renderer';
import { IconMenu } from '@ui/icon-menu';
import { ActionMenuItem } from '@ui/action-menu';
import { IconButton } from '@ui/icon-button';
import { MoreVerticalIcon } from '@ui/icons/more-vertical';
import { StatefulTooltip } from '../../tooltip';
import withConfirm, { ConfirmEnhancerProps } from '@core/hocs/with-confirm.hoc';
import { RaisedButton, RaisedButtonProps } from '@ui/raised-button';
import { Box } from '@ui/box';
import { withTheme, WithThemeProps } from '@theme';
import {
	Root,
	ContentLayout,
	MainLayout,
	ControlsLayout,
	ButtonLayout,
	IconLayout,
	ChekboxLayout,
	LineRendererCell,
	LineRendererSign,
	LineRendererValue,
	LineRendererStatus,
	LineRendererDocket,
	LineRendererDocketItem,
	LineRendererImage,
	ExtraContentLayout,
} from './styled';

interface IProps {
	withCheckbox?: boolean;
	checkboxIconParams?: {
		type: string;
		color: string;
	};
	controls?: Array<React.ReactElement<any>>;
	moreControls?: Array<React.ReactElement<typeof ActionMenuItem>>;
	moreLabel?: string;
	minHeight?: number;
	icon?: React.ReactElement<any>;
	alignTop?: boolean;
	align?: 'flex-start' | 'center' | 'flex-end';
	alwaysRenderControls?: boolean;
	noExtend?: boolean;
	noClickable?: boolean;
	renderExtraContent?: () => React.ReactNode;
}

function LineRenderer<S extends { ID: number }>(props: Partial<ItemRendererProps<S>> & IProps) {
	const {
		isActive,
		isSelected,
		item,
		withCheckbox,
		checkboxIconParams,
		minHeight,
		icon,
		alignTop,
		align,
		alwaysRenderControls,
		noExtend,
		noClickable,
		renderExtraContent,
		onItemSelect,
	} = props;
	let timeClickStart = 0;
	let timeClickEnd = 0;

	const handleClick = ev => {
		const { index, noClickable, onClick } = props;

		if (timeClickEnd - timeClickStart <= 300 && !noClickable) {
			onClick(index);
		}

		ev.stopPropagation();
	};

	const handleMouseDown = ev => {
		timeClickStart = ev.timeStamp;
	};

	const handleMouseUp = ev => {
		timeClickEnd = ev.timeStamp;
	};

	const renderControls = () => {
		const { controls } = props;

		if (controls) {
			return controls.map((item, index) => {
				if (!item) {
					return null;
				}

				return <ButtonLayout key={index}>{item}</ButtonLayout>;
			});
		}

		return null;
	};

	const renderMoreControls = () => {
		const { moreControls, moreLabel } = props;

		if (moreControls && moreControls.length > 0) {
			return <LineRendererMenu items={moreControls} label={moreLabel} />;
		}

		return null;
	};

	if (!item) {
		return null;
	}

	return (
		<Root onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} onClick={handleClick}>
			<ContentLayout
				className={'items-list-repeater__row' + (isSelected ? ' items-list-repeater__row_checked' : '')}
				isActive={isActive}
				minHeight={minHeight}
				noExtend={noExtend}
				noClickable={noClickable}>
				<MainLayout alignTop={alignTop} align={align} isActive={isActive}>
					{withCheckbox && (
						<IconLayout>
							<ChekboxLayout>
								<LineSelectRenderer
									item={item}
									icon={checkboxIconParams}
									checked={isSelected}
									selectHandler={onItemSelect}
								/>
							</ChekboxLayout>
						</IconLayout>
					)}
					{icon && <IconLayout>{icon}</IconLayout>}
					{props.children}
				</MainLayout>
				{isActive && typeof renderExtraContent === 'function' && (
					<ExtraContentLayout>{renderExtraContent()}</ExtraContentLayout>
				)}
				{(alwaysRenderControls || isActive) && (
					<ControlsLayout>
						{renderControls()}
						{renderMoreControls()}
					</ControlsLayout>
				)}
			</ContentLayout>
		</Root>
	);
}

type LineRendererMenuProps = {
	items: Array<React.ReactElement<typeof ActionMenuItem>>;
	label: string;
};

type LineRendererMenuState = {
	isOpen: boolean;
};

class LineRendererMenu extends React.PureComponent<LineRendererMenuProps, LineRendererMenuState> {
	static displayName = 'LineRendererMenu';
	static defaultProps = { label: 'Дополнительные действия' };
	state = { isOpen: false };

	handleToggleMenu = () => {
		const { isOpen } = this.state;

		!isOpen && this.setState({ isOpen: true });
		isOpen && this.setState({ isOpen: false });
	};

	handleCloseMenu = () => {
		this.setState({ isOpen: false });
	};

	render() {
		const { items, label } = this.props;
		const { isOpen } = this.state;
		const isAllItemsNullable = items.length === 0 || items.every(el => !el);

		if (isAllItemsNullable) {
			return null;
		}

		return (
			<span>
				<IconMenu
					open={isOpen}
					iconButtonElement={
						<IconButton variant='rounded' stopPropagation isSilentHover onClick={this.handleToggleMenu}>
							<MoreVerticalIcon size={20} />
						</IconButton>
					}
					anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
					targetOrigin={{ horizontal: 'right', vertical: 'top' }}
					autoWidth={false}
					onRequestChange={this.handleCloseMenu}>
					{items.map((el, index) => {
						if (!el) {
							return null;
						}

						return React.cloneElement(
							el as any,
							Object.assign(
								{},
								{
									key: index,
									handleCloseMenu: this.handleCloseMenu,
								},
							),
						);
					})}
				</IconMenu>
				<StatefulTooltip position='top'>{label}</StatefulTooltip>
			</span>
		);
	}
}

const LineRendererActionMenuItem = ActionMenuItem;

type LineRendererButtonProps = {
	confirmLabel?: string;
} & RaisedButtonProps &
	WithThemeProps;

type LineRendererButtonState = {
	style: CSSProperties;
};

class LineRendererButtonBase extends React.PureComponent<LineRendererButtonProps, LineRendererButtonState> {
	static displayName = 'LineRendererButton';
	static defaultprops = {
		style: {},
	};
	state: LineRendererButtonState = {
		style: {
			...this.props.style,
			minWidth: 'auto',
		},
	};
	component = this.props.confirmLabel ? withConfirm()(RaisedButton) : RaisedButton;
	rootRef: ReactInstance = null;

	setRootRef = (ref: ReactInstance) => (this.rootRef = ref);

	componentDidMount() {
		this.props.confirmLabel && this.detectWidth();
	}

	detectWidth = () => {
		if (!this.rootRef) return;
		const dom = ReactDOM.findDOMNode(this.rootRef) as HTMLElement;
		if (!dom) return;

		this.setState({
			style: {
				...this.props.style,
				minWidth: dom.clientWidth,
			},
		});
	};

	renderConfirmContent = () => {
		return (
			<Box
				component='span'
				display='inline-flex'
				justifyContent='center'
				alignItems='center'
				fontSize='0.8rem'
				color={this.props.theme.palette.alarm}>
				{this.props.confirmLabel}
			</Box>
		);
	};

	render() {
		const { type, size, label, icon, confirmLabel, style, ...rest } = this.props;
		const Button = this.component as ComponentClass<RaisedButtonProps & ConfirmEnhancerProps>;

		return (
			<Button
				{...rest}
				ref={this.setRootRef}
				appearance='text'
				size='small'
				style={this.state.style}
				renderConfirmContent={this.renderConfirmContent}
				stopPropagation>
				{icon ? (
					<Box component='span' display='inline-flex' alignItems='center'>
						{icon}
						{label && (
							<Box component='span' marginLeft={6}>
								{label}
							</Box>
						)}
					</Box>
				) : (
					label
				)}
			</Button>
		);
	}
}

const LineRendererButton = withTheme(LineRendererButtonBase);

export {
	LineRenderer,
	LineRendererButton,
	LineRendererCell,
	LineRendererSign,
	LineRendererValue,
	LineRendererStatus,
	LineRendererDocket,
	LineRendererDocketItem,
	LineRendererImage,
	LineRendererActionMenuItem,
};
