import React, { useLayoutEffect } from 'react';

import { CMSDataType } from '@funds-registers/models';
import { mainFundsRegistersSelectorsPack } from '@funds-registers/selectors';
import { DrawerLink } from '@ui/drawer';
import { ProviderIcon } from '@ui/provider-icon';
import { declOfNum, getDrawer } from '@utils';
import { Root } from './styled';

type XCmsListProps = {
	dataType: CMSDataType;
	supportedCMS: ReturnType<typeof mainFundsRegistersSelectorsPack.selectSupportedCMS>;
	bankAccountByCmsStats: ReturnType<typeof mainFundsRegistersSelectorsPack.selectBankAccountByCmsStats>;
	onSelectCMS: (ID: number) => void;
};

const XCmsList: React.FC<XCmsListProps> = ({ dataType, supportedCMS, bankAccountByCmsStats, onSelectCMS }) => {
	let titlePostfix = '';
	let textEndArray: [string, string, string];
	switch (dataType) {
		case CMSDataType.POS_TERMINAL:
			titlePostfix = 'терминал';
			textEndArray = ['терминал', 'терминала', 'терминалов'];
			break;
		case CMSDataType.LEGAL_ENTITY_BANK_ACCOUNT:
			titlePostfix = 'банк';
			textEndArray = ['счёт', 'счёта', 'счетов'];
			break;
		case CMSDataType.NATURAL_PERSON_CARD_ACCOUNT:
			titlePostfix = 'карту физлица';
			textEndArray = ['карта', 'карты', 'карт'];
			break;
		case CMSDataType.MARKETPLACE_TRANSACTION:
			titlePostfix = 'маркетплейс';
			textEndArray = ['счёт', 'счёта', 'счетов'];
			break;
	}
	useLayoutEffect(() => {
		getDrawer().setContent({
			title: 'Подключить ' + titlePostfix,
			footer: null,
		});
	}, []);

	const renderTextStart = (ID: number) =>
		declOfNum(bankAccountByCmsStats[ID], ['Подключён', 'Подключено', 'Подключено']);
	const renderTextEnd = (ID: number) => declOfNum(bankAccountByCmsStats[ID], textEndArray);

	return (
		<Root>
			{supportedCMS
				.filter(cms => cms.ProvidedData?.includes(dataType))
				.map(CMS => (
					<DrawerLink
						key={CMS.ID}
						title={CMS.Name}
						text={`${renderTextStart(CMS.ID)} ${bankAccountByCmsStats[CMS.ID]} ${renderTextEnd(CMS.ID)}`}
						icon={<ProviderIcon providerName={CMS.Name} size={55} fallback='bank-account' />}
						onClick={() => onSelectCMS(CMS.ID)}
					/>
				))}
		</Root>
	);
};

export { XCmsList };
