import React from 'react';

import withTopShift from '@core/hocs/with-top-shift.hoc';
import { AppLogo } from '@ui/app-logo';
import { ScrollableView } from '@ui/view';
import { AppMenuLinkProps } from '@routes/menu';
import { Root, LogoLayout, styles } from './styled';

type AppMenuProps = {
	topShift?: number;
	appMenuLinks: React.FC<AppMenuLinkProps>;
	availableComponents: Array<ApplicationComponent>;
	userRights: Array<SecurityRight>;
	isFullAccess: boolean;
	tenantProfile: TenantProfile;
};

class AppMenu extends React.Component<AppMenuProps> {
	static displayName = 'AppMenu';

	render() {
		const {
			topShift,
			appMenuLinks: AppMenuLinks,
			availableComponents,
			userRights,
			isFullAccess,
			tenantProfile,
		} = this.props;

		return (
			<Root topShift={topShift}>
				<LogoLayout>
					<AppLogo link='/bfm' />
				</LogoLayout>
				<ScrollableView appearance='mini' style={styles.scrollableView}>
					{AppMenuLinks && (
						<AppMenuLinks
							availableComponents={availableComponents}
							userRights={userRights}
							isFullAccess={isFullAccess}
							tenantProfile={tenantProfile}
						/>
					)}
				</ScrollableView>
			</Root>
		);
	}
}

export default withTopShift<AppMenuProps>()(AppMenu);
