export enum MODULE_PREFERENCE_CODE {
	DIRECTION = 'operations_direction',
	DATE_TYPE = 'operations_date_type',
	CASHFLOW_TYPE = 'operations_cashflow_type',
}

export enum OperationSorting {
	DATE_ASC = 'date_asc',
	DATE_DESC = 'date_desc',
}

export enum OperationsStatisticsSliceCode {
	INCOME = 'INCOME',
	EXPENSE = 'EXPENSE',
	RESULT = 'RESULT',
}

export enum OperationDirection {
	INCOME = 'income',
	EXPENSE = 'expense',
	TRANSFER = 'transfer',
}

export enum OperationStatus {
	PLAN = 'PLAN',
	FACT = 'FACT',
}

export type OperationPersonal = 'personal' | 'business';

export enum OperationCashflowType {
	UNKNOWN = 'UNKNOWN',
	OPERATIONAL = 'OPERATIONAL',
	FINANCIAL = 'FINANCIAL',
	INVESTMENT = 'INVESTMENT',
}

export const operationCashflowTypeSortMap = {
	[OperationCashflowType.UNKNOWN]: 0,
	[OperationCashflowType.OPERATIONAL]: 1,
	[OperationCashflowType.FINANCIAL]: 2,
	[OperationCashflowType.INVESTMENT]: 3,
};

export const operationCashflowTypeIdMap = {
	[OperationCashflowType.UNKNOWN]: -1,
	[OperationCashflowType.OPERATIONAL]: 1,
	[OperationCashflowType.FINANCIAL]: 2,
	[OperationCashflowType.INVESTMENT]: 3,
};

export enum OperationDateType {
	CASHFLOW = 'CASHFLOW',
	ACCRUAL = 'ACCRUAL',
}
