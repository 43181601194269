import React from 'react';

import { formatCurrency } from '@utils/currency';
import { Root, Integer, Fraction, Symbol, Prefix } from './styled';

export type CurrencyFormatterProps = {
	code?: string;
	locale?: string;
	separator?: string;
	fontSize?: number;
	fontWeight?: number;
	color?: string;
	prefix?: string | React.ReactElement<any>;
	needFraction?: boolean;
	children: number;
};

const CurrencyFormatterBase: React.FC<CurrencyFormatterProps> = props => {
	const { children, code, locale, separator, fontSize, fontWeight, color, prefix, needFraction } = props;
	const patternSpace = String.fromCharCode(160);
	const formatted =
		typeof children === 'number' ? formatCurrency(children, { code, locale, fractions: needFraction ? 2 : 0 }) : '0';
	const digits = formatted.replace(/[^\d\s.,+-]/g, '');
	const symbol = formatted.replace(/(\d|\s|\.|,|\+|-)/g, '');
	const parts = digits.split(/[.,]/g);
	const integer = parts[0];
	const fraction = parts[1];

	return (
		<Root fontSize={fontSize} fontWeight={fontWeight} color={color}>
			{prefix || null}
			{prefix && `${patternSpace}`}
			<Integer className='currency-formatter-integer'>{integer}</Integer>
			{needFraction && fraction && separator}
			{needFraction && fraction && (
				<Fraction className='currency-formatter-fraction' fontSize={fontSize}>
					{fraction}
				</Fraction>
			)}
			{patternSpace}
			<Symbol className='currency-formatter-symbol'>{symbol}</Symbol>
		</Root>
	);
};

CurrencyFormatterBase.defaultProps = {
	code: 'RUR',
	locale: 'ru',
	separator: ',',
	needFraction: true,
};

type CurrencyFormatterPrefixProps = {
	isIncome: boolean;
};

const CurrencyFormatterPrefix: React.FC<CurrencyFormatterPrefixProps> = ({ isIncome }) => {
	return <Prefix isIncome={isIncome}>{isIncome ? '+' : '—'}</Prefix>;
};

const CurrencyFormatter = {
	Base: CurrencyFormatterBase,
	Prefix: CurrencyFormatterPrefix,
};

export { CurrencyFormatter };
