import React from 'react';

import { BoxProps } from '@ui/box';
import { Adaptive } from '@stylesheet';
import { Root } from './styled';

export type MaterialCardProps = {
	appearance?: 'paper';
	elevation?: number;
	hoverElevation?: number;
} & Adaptive &
	BoxProps;

const MaterialCard: React.FC<MaterialCardProps> = props => {
	return <Root display='inline-block' {...props} />;
};

MaterialCard.defaultProps = {
	appearance: 'paper',
	elevation: 1,
	hoverElevation: 3,
	padding: 16,
};

export { MaterialCard };
