/***
 * Library contains JavaScript API for interaction with Seeneco Framework Web Services contained in AccountingPackage
 * @author akeapy Generated by Seeneco Framework Modeler 
 *
 */
(function(w){
/***
* 
*/
w.AccountingOperation = function() {
  this.PACKAGE = "ACCOUNTING";
  this.CLASSIFIER = "ACCOUNTING_OPERATION";
  this.CopySupportingElement = false;
  this.ID = -1;
  this.Reference = "";
  this.ValueDate = null;
  this.Transactions = [];
  this.OperationTemplateID = -1;
  this.LegalEntityID = -1;
};
/***
* 
*/
w.AccountingTransaction = function() {
  this.PACKAGE = "ACCOUNTING";
  this.CLASSIFIER = "ACCOUNTING_TRANSACTION";
  this.CopySupportingElement = false;
  this.ID = -1;
  this.Amount = -1;
  this.ValueDate = null;
  this.AccountsChartItemID = -1;
  this.Charge = false;
  this.LegalEntityID = -1;
};
/***
* Item in hierarchical chart of accounts
*/
w.AccountsChartItem = function() {
  this.PACKAGE = "ACCOUNTING";
  this.CLASSIFIER = "ACCOUNTS_CHART_ITEM";
  this.CopySupportingElement = false;
  this.ID = -1;
  this.Name = "";
  this.Code = "";
  this.ParentID = -1;
  this.ChildItems = [];
  this.TenantSpecific = false;
  this.Role = null;
  this.Principial = false;
  this.VisibleInDashboard = false;
  this.System = false;
};
/***
* 
*/
w.AccountsChartItemRole = function() {
  this.PACKAGE = "ACCOUNTING";
  this.CLASSIFIER = "ACCOUNTS_CHART_ITEM_ROLE";
  this.CopySupportingElement = false;
  this.ID = -1;
  this.Code = "";
  this.Description = "";
  this.Incoming = false;
  this.Name = "";
};
/***
* 
*/
w.AcctOperationTemplate = function() {
  this.PACKAGE = "ACCOUNTING";
  this.CLASSIFIER = "ACCT_OPERATION_TEMPLATE";
  this.CopySupportingElement = false;
  this.ID = -1;
  this.Name = "";
  this.Description = "";
  this.TransactionTemplates = [];
  this.Code = "";
};
/***
* 
*/
w.AcctTransactionTemplate = function() {
  this.PACKAGE = "ACCOUNTING";
  this.CLASSIFIER = "ACCT_TRANSACTION_TEMPLATE";
  this.CopySupportingElement = false;
  this.ID = -1;
  this.AccountsChartItemID = -1;
  this.Charge = false;
};
/***
* 
*/
w.ACIRoleRule = function() {
  this.PACKAGE = "ACCOUNTING";
  this.CLASSIFIER = "ACIROLE_RULE";
  this.CopySupportingElement = false;
  this.ID = -1;
  this.Incoming = false;
  this.PaymentField = "";
  this.AccountsChartItemRole = null;
  this.LogicalOperator = "";
  this.PaymentFieldValue = "";
  this.Priority = -1;
  this.DefaultRole = false;
};
/***
* 
*/
w.BalanceItem = function() {
  this.PACKAGE = "ACCOUNTING";
  this.CLASSIFIER = "BALANCE_ITEM";
  this.CopySupportingElement = false;
  this.BalanceItemKind = -1;
  this.ID = -1;
  this.Name = "";
  this.Code = "";
  this.ParentID = -1;
  this.ChildItems = [];
  this.TenantSpecific = false;
  this.Role = null;
  this.Principial = false;
  this.VisibleInDashboard = false;
  this.System = false;
};
/***
* 
*/
w.CashflowItem = function() {
  this.PACKAGE = "ACCOUNTING";
  this.CLASSIFIER = "CASHFLOW_ITEM";
  this.CopySupportingElement = false;
  this.CashflowTypeID = -1;
  this.Incoming = false;
  this.MarkupRules = [];
  this.PaymentMarkupDatasetSchema = null;
  this.InCFConsolidation = false;
  this.InPLConsolidation = false;
  this.InProjectConsolidation = false;
  this.LinkToPLDisabled = false;
  this.Constant = false;
  this.InABCAnalysis = false;
  this.ID = -1;
  this.Name = "";
  this.Code = "";
  this.ParentID = -1;
  this.ChildItems = [];
  this.TenantSpecific = false;
  this.Role = null;
  this.Principial = false;
  this.VisibleInDashboard = false;
  this.System = false;
};
/***
* 
*/
w.CashflowTemplateItemCharacter = function() {
  this.PACKAGE = "ACCOUNTING";
  this.CLASSIFIER = "CASHFLOW_TEMPLATE_ITEM_CHARACTER";
  this.CopySupportingElement = false;
  this.Incoming = false;
  this.LinkedCashflowItems = [];
};
/***
* 
*/
w.CashflowType = function() {
  this.PACKAGE = "ACCOUNTING";
  this.CLASSIFIER = "CASHFLOW_TYPE";
  this.CopySupportingElement = false;
  this.ID = -1;
  this.Name = "";
  this.Code = "";
};
/***
* 
*/
w.CounterpartyFSItemValue = function() {
  this.PACKAGE = "ACCOUNTING";
  this.CLASSIFIER = "COUNTERPARTY_FSITEM_VALUE";
  this.CopySupportingElement = false;
  this.CounterpartyID = -1;
  this.PeriodID = -1;
  this.Value = -1;
  this.ManualInput = false;
};
/***
* 
*/
w.DependentFSTemplateItem = function() {
  this.PACKAGE = "ACCOUNTING";
  this.CLASSIFIER = "DEPENDENT_FSTEMPLATE_ITEM";
  this.CopySupportingElement = false;
  this.DependentValueExpression = null;
  this.ID = -1;
  this.Name = "";
  this.ItemNumOrder = -1;
  this.ParentID = -1;
  this.Character = null;
  this.ChildItems = [];
  this.Measure = -1;
  this.GUID = "";
  this.Code = "";
  this.ParentGUID = "";
  this.BreakdownTemplate = null;
  this.BreakdownTemplateAggregatedItem = null;
};
/***
* Определяет строку внешнего по отношению к рассчитываемому финансового отчета
*/
w.ExternalFinancialStatementItem = function() {
  this.PACKAGE = "ACCOUNTING";
  this.CLASSIFIER = "EXTERNAL_FINANCIAL_STATEMENT_ITEM";
  this.CopySupportingElement = false;
  this.TemplateID = -1;
  this.Values = [];
  this.ChildItems = [];
  this.ParentItemID = -1;
  this.ItemTemplate = null;
  this.ID = -1;
  this.Name = "";
};
/***
* 
*/
w.FAStatementTemplate = function() {
  this.PACKAGE = "ACCOUNTING";
  this.CLASSIFIER = "FASTATEMENT_TEMPLATE";
  this.CopySupportingElement = false;
  this.TemplateItems = [];
  this.Type = "";
  this.FSTemplateID = -1;
  this.CompareFSTemplateItemID = -1;
};
/***
* 
*/
w.FATemplate = function() {
  this.PACKAGE = "ACCOUNTING";
  this.CLASSIFIER = "FATEMPLATE";
  this.CopySupportingElement = false;
  this.ID = -1;
  this.Name = "";
  this.Ratios = [];
  this.FAStatementTemplates = [];
};
/***
* 
*/
w.FinancialRatio = function() {
  this.PACKAGE = "ACCOUNTING";
  this.CLASSIFIER = "FINANCIAL_RATIO";
  this.CopySupportingElement = false;
  this.ID = -1;
  this.Name = "";
  this.Measure = -1;
  this.CalculationExpression = null;
  this.Description = "";
  this.GUID = "";
  this.RatioGroupID = -1;
  this.GroupNumOrder = -1;
  this.FinRatioComparisonAlgID = -1;
  this.FinancialRatioType = null;
};
/***
* 
*/
w.FinancialRatioGroup = function() {
  this.PACKAGE = "ACCOUNTING";
  this.CLASSIFIER = "FINANCIAL_RATIO_GROUP";
  this.CopySupportingElement = false;
  this.ID = -1;
  this.Name = "";
  this.ParentID = -1;
  this.ChildGroups = [];
  this.Ratios = [];
  this.FinancialRatioType = null;
};
/***
* 
*/
w.FinancialRatioType = function() {
  this.PACKAGE = "ACCOUNTING";
  this.CLASSIFIER = "FINANCIAL_RATIO_TYPE";
  this.CopySupportingElement = false;
  this.Code = "";
  this.ID = -1;
  this.Name = "";
  this.GUID = "";
};
/***
* 
*/
w.FinancialRatioValue = function() {
  this.PACKAGE = "ACCOUNTING";
  this.CLASSIFIER = "FINANCIAL_RATIO_VALUE";
  this.CopySupportingElement = false;
  this.Ratio = null;
  this.Values = [];
  this.VariantID = -1;
};
/***
* 
*/
w.FinancialStatementImportRequest = function() {
  this.PACKAGE = "ACCOUNTING";
  this.CLASSIFIER = "FINANCIAL_STATEMENT_IMPORT_REQUEST";
  this.CopySupportingElement = false;
  this.CounterpartyID = -1;
  this.FinancialStatementItems = [];
  this.Variant = null;
  this.Currency = null;
  this.ForceNewVersion = false;
  this.FinancialStatementTemplateID = -1;
  this.OperationUID = -1;
};
/***
* 
*/
w.FinancialStatementItem = function() {
  this.PACKAGE = "ACCOUNTING";
  this.CLASSIFIER = "FINANCIAL_STATEMENT_ITEM";
  this.CopySupportingElement = false;
  this.Values = [];
  this.ChildItems = [];
  this.ParentItemID = -1;
  this.ItemTemplate = null;
  this.ID = -1;
  this.Name = "";
};
/***
* 
*/
w.FinancialStatementSource = function() {
  this.PACKAGE = "ACCOUNTING";
  this.CLASSIFIER = "FINANCIAL_STATEMENT_SOURCE";
  this.CopySupportingElement = false;
  this.CounterpartyID = -1;
  this.ExternalCounterpartyCode = "";
  this.TemplateID = -1;
  this.AutoLoad = false;
  this.ExternalSourceID = -1;
  this.FSMonthList = [];
  this.ID = -1;
  this.Name = "";
  this.GUID = "";
};
/***
* 
*/
w.FinancialStatementTemplate = function() {
  this.PACKAGE = "ACCOUNTING";
  this.CLASSIFIER = "FINANCIAL_STATEMENT_TEMPLATE";
  this.CopySupportingElement = false;
  this.ID = -1;
  this.Name = "";
  this.Items = [];
  this.Type = "";
  this.GUID = "";
  this.ApplicableLegalEntityTypes = [];
};
/***
* 
*/
w.FinancialStatementTemplateFileReference = function() {
  this.PACKAGE = "ACCOUNTING";
  this.CLASSIFIER = "FINANCIAL_STATEMENT_TEMPLATE_FILE_REFERENCE";
  this.CopySupportingElement = false;
  this.UploadedFileName = "";
  this.FinancialStatementTemplateID = "";
  this.UploadDateTime = null;
};
/***
* 
*/
w.FinancialStatementTemplateImport = function() {
  this.PACKAGE = "ACCOUNTING";
  this.CLASSIFIER = "FINANCIAL_STATEMENT_TEMPLATE_IMPORT";
  this.CopySupportingElement = false;
  this.StatementValid = false;
  this.AddedItemsCount = -1;
  this.AccountCreated = false;
  this.ImportStatusCode = "";
  this.FinancialStatementTemplateID = -1;
  this.Message = "";
  this.ChangedItemsCount = -1;
  this.RemovedItemsCount = -1;
};
/***
* 
*/
w.FinRatioComparisonAlg = function() {
  this.PACKAGE = "ACCOUNTING";
  this.CLASSIFIER = "FIN_RATIO_COMPARISON_ALG";
  this.CopySupportingElement = false;
  this.SysName = "";
  this.Name = "";
  this.ID = -1;
};
/***
* 
*/
w.FSItemValue = function() {
  this.PACKAGE = "ACCOUNTING";
  this.CLASSIFIER = "FSITEM_VALUE";
  this.CopySupportingElement = false;
  this.PeriodID = -1;
  this.Value = -1;
  this.ManualInput = false;
};
/***
* 
*/
w.FSTemplateItem = function() {
  this.PACKAGE = "ACCOUNTING";
  this.CLASSIFIER = "FSTEMPLATE_ITEM";
  this.CopySupportingElement = false;
  this.ID = -1;
  this.Name = "";
  this.ItemNumOrder = -1;
  this.ParentID = -1;
  this.Character = null;
  this.ChildItems = [];
  this.Measure = -1;
  this.GUID = "";
  this.Code = "";
  this.ParentGUID = "";
  this.BreakdownTemplate = null;
  this.BreakdownTemplateAggregatedItem = null;
};
/***
* 
*/
w.FSTemplateItemCharacter = function() {
  this.PACKAGE = "ACCOUNTING";
  this.CLASSIFIER = "FSTEMPLATE_ITEM_CHARACTER";
  this.CopySupportingElement = false;
};
/***
* 
*/
w.FSTemplateItemTransformation = function() {
  this.PACKAGE = "ACCOUNTING";
  this.CLASSIFIER = "FSTEMPLATE_ITEM_TRANSFORMATION";
  this.CopySupportingElement = false;
  this.ID = -1;
  this.Expression = null;
  this.TargetFSTemplateItem = null;
  this.GUID = "";
};
/***
* 
*/
w.FSTemplateTransformation = function() {
  this.PACKAGE = "ACCOUNTING";
  this.CLASSIFIER = "FSTEMPLATE_TRANSFORMATION";
  this.CopySupportingElement = false;
  this.ID = -1;
  this.Name = "";
  this.Items = [];
  this.GUID = "";
  this.TargetFSTemplate = null;
};
/***
* 
*/
w.PLItem = function() {
  this.PACKAGE = "ACCOUNTING";
  this.CLASSIFIER = "PLITEM";
  this.CopySupportingElement = false;
  this.Expense = false;
  this.ID = -1;
  this.Name = "";
  this.Code = "";
  this.ParentID = -1;
  this.ChildItems = [];
  this.TenantSpecific = false;
  this.Role = null;
  this.Principial = false;
  this.VisibleInDashboard = false;
  this.System = false;
};
/***
* 
*/
w.PrimaryFSTemplateItem = function() {
  this.PACKAGE = "ACCOUNTING";
  this.CLASSIFIER = "PRIMARY_FSTEMPLATE_ITEM";
  this.CopySupportingElement = false;
  this.ID = -1;
  this.Name = "";
  this.ItemNumOrder = -1;
  this.ParentID = -1;
  this.Character = null;
  this.ChildItems = [];
  this.Measure = -1;
  this.GUID = "";
  this.Code = "";
  this.ParentGUID = "";
  this.BreakdownTemplate = null;
  this.BreakdownTemplateAggregatedItem = null;
};
/***
* 
*/
w.RatioCalculationLog = function() {
  this.PACKAGE = "ACCOUNTING";
  this.CLASSIFIER = "RATIO_CALCULATION_LOG";
  this.CopySupportingElement = false;
  this.Period = "";
  this.Entries = [];
};
/***
* 
*/
w.RatioCalculationLogEntry = function() {
  this.PACKAGE = "ACCOUNTING";
  this.CLASSIFIER = "RATIO_CALCULATION_LOG_ENTRY";
  this.CopySupportingElement = false;
  this.CalculationExpressionText = "";
  this.ExpressionValueSubstitution = "";
  this.Result = "";
  this.RatioName = "";
  this.RatioID = -1;
  this.EvaluationError = false;
  this.ErrorText = "";
};
 
 

/***
 * Client API to AccountsChartServiceWS web service
 */
w.AccountsChartServiceClient = function() {
  /***
   * System name of target service
   */
  this.serviceName = "AccountsChartServiceWSImpl";
};

/***
 * [DEFAULT!Creates an item in existing chart of accounts and returns it to user with filled identifier!DEFAULT]
 * @param item Item to add
 * @param callbackHandler Function to service callback result
 */
w.AccountsChartServiceClient.prototype.addItem = function(item, callbackHandler) {
  var parameterNames = ["item"];
  var itemJSON = JSON.stringify(item);
  var parameterValues = [itemJSON];
  var soapMessage = buildSOAPMessage("addItem", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * Updates existing item using provided value
 * @param item 
 * @param callbackHandler Function to service callback result
 */
w.AccountsChartServiceClient.prototype.updateItem = function(item, callbackHandler) {
  var parameterNames = ["item"];
  var itemJSON = JSON.stringify(item);
  var parameterValues = [itemJSON];
  var soapMessage = buildSOAPMessage("updateItem", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param id 
 * @param callbackHandler Function to service callback result
 */
w.AccountsChartServiceClient.prototype.getItemByID = function(id, callbackHandler) {
  var parameterNames = ["id"];
  var parameterValues = [id];
  var soapMessage = buildSOAPMessage("getItemByID", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = true;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param itemID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.AccountsChartServiceClient.prototype.removeItem = function(itemID, callbackHandler) {
  var parameterNames = ["itemID"];
  var parameterValues = [itemID];
  var soapMessage = buildSOAPMessage("removeItem", parameterNames, parameterValues);
  var resultHasComplexType = false;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param itemID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.AccountsChartServiceClient.prototype.getDescendants = function(itemID, callbackHandler) {
  var parameterNames = ["itemID"];
  var parameterValues = [itemID];
  var soapMessage = buildSOAPMessage("getDescendants", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = true;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!Check is it is possible for caller tenant to copy default chart of accounts!DEFAULT]
 * @param itemsType [DEFAULT!Defines what type of items to copy - possible values are
- 'CF' for cashflow items
- 'PL' for profit and loss items!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.AccountsChartServiceClient.prototype.checkPossibleToCopyDefaultCA = function(itemsType, callbackHandler) {
  var parameterNames = ["itemsType"];
  var parameterValues = [itemsType];
  var soapMessage = buildSOAPMessage("checkPossibleToCopyDefaultCA", parameterNames, parameterValues);
  var resultHasComplexType = false;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param rule [DEFAULT!!DEFAULT]
 * @param cashflowItemID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.AccountsChartServiceClient.prototype.addItemMarkupRule = function(rule, cashflowItemID, callbackHandler) {
  var parameterNames = ["rule", "cashflowItemID"];
  var ruleJSON = JSON.stringify(rule);
  var parameterValues = [ruleJSON, cashflowItemID];
  var soapMessage = buildSOAPMessage("addItemMarkupRule", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param rule [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.AccountsChartServiceClient.prototype.changeItemMarkupRule = function(rule, callbackHandler) {
  var parameterNames = ["rule"];
  var ruleJSON = JSON.stringify(rule);
  var parameterValues = [ruleJSON];
  var soapMessage = buildSOAPMessage("changeItemMarkupRule", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param cashflowItemID [DEFAULT!!DEFAULT]
 * @param rule [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.AccountsChartServiceClient.prototype.removeItemMarkupRule = function(cashflowItemID, rule, callbackHandler) {
  var parameterNames = ["cashflowItemID", "rule"];
  var ruleJSON = JSON.stringify(rule);
  var parameterValues = [cashflowItemID, ruleJSON];
  var soapMessage = buildSOAPMessage("removeItemMarkupRule", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param itemsType [DEFAULT!System code of accounts chart type for which to remove items!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.AccountsChartServiceClient.prototype.removeUnusedItems = function(itemsType, callbackHandler) {
  var parameterNames = ["itemsType"];
  var parameterValues = [itemsType];
  var soapMessage = buildSOAPMessage("removeUnusedItems", parameterNames, parameterValues);
  var resultHasComplexType = false;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!Returns list of root accounts chart items!DEFAULT]
 * @param fsType [DEFAULT!Financial statement type for result accounts chart item (BS,PL,CF)!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.AccountsChartServiceClient.prototype.getAccountsChart = function(fsType, callbackHandler) {
  var parameterNames = ["fsType"];
  var parameterValues = [fsType];
  var soapMessage = buildSOAPMessage("getAccountsChart", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = true;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param items [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.AccountsChartServiceClient.prototype.addItemsList = function(items, callbackHandler) {
  var parameterNames = ["items"];
  var itemsJSON = JSON.stringify(items);
  var parameterValues = [itemsJSON];
  var soapMessage = buildSOAPMessage("addItemsList", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param role [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.AccountsChartServiceClient.prototype.addAccountsChartItemRole = function(role, callbackHandler) {
  var parameterNames = ["role"];
  var roleJSON = JSON.stringify(role);
  var parameterValues = [roleJSON];
  var soapMessage = buildSOAPMessage("addAccountsChartItemRole", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param role [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.AccountsChartServiceClient.prototype.updateAccountsChartItemRole = function(role, callbackHandler) {
  var parameterNames = ["role"];
  var roleJSON = JSON.stringify(role);
  var parameterValues = [roleJSON];
  var soapMessage = buildSOAPMessage("updateAccountsChartItemRole", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.AccountsChartServiceClient.prototype.getAllAccountsChartItemRoles = function(callbackHandler) {
  var parameterNames = [];
  var parameterValues = [];
  var soapMessage = buildSOAPMessage("getAllAccountsChartItemRoles", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.AccountsChartServiceClient.prototype.getACIRoleRules = function(callbackHandler) {
  var parameterNames = [];
  var parameterValues = [];
  var soapMessage = buildSOAPMessage("getACIRoleRules", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * Client API to FinancialStatementServiceWS web service
 */
w.FinancialStatementServiceClient = function() {
  /***
   * System name of target service
   */
  this.serviceName = "FinancialStatementServiceWSImpl";
};

/***
 * [DEFAULT!!DEFAULT]
 * @param template [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.FinancialStatementServiceClient.prototype.addTemplate = function(template, callbackHandler) {
  var parameterNames = ["template"];
  var templateJSON = JSON.stringify(template);
  var parameterValues = [templateJSON];
  var soapMessage = buildSOAPMessage("addTemplate", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param template [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.FinancialStatementServiceClient.prototype.updateTemplate = function(template, callbackHandler) {
  var parameterNames = ["template"];
  var templateJSON = JSON.stringify(template);
  var parameterValues = [templateJSON];
  var soapMessage = buildSOAPMessage("updateTemplate", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param templateID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.FinancialStatementServiceClient.prototype.removeTemplate = function(templateID, callbackHandler) {
  var parameterNames = ["templateID"];
  var parameterValues = [templateID];
  var soapMessage = buildSOAPMessage("removeTemplate", parameterNames, parameterValues);
  var resultHasComplexType = false;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param templateID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.FinancialStatementServiceClient.prototype.getTemplateByID = function(templateID, callbackHandler) {
  var parameterNames = ["templateID"];
  var parameterValues = [templateID];
  var soapMessage = buildSOAPMessage("getTemplateByID", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = true;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!Builds financial statement by given template on the basis of primary items holding data.
Returns all items in statement !DEFAULT]
 * @param primaryItems [DEFAULT!!DEFAULT]
 * @param templateID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.FinancialStatementServiceClient.prototype.calculate = function(primaryItems, templateID, callbackHandler) {
  var parameterNames = ["primaryItems", "templateID"];
  var primaryItemsJSON = JSON.stringify(primaryItems);
  var parameterValues = [primaryItemsJSON, templateID];
  var soapMessage = buildSOAPMessage("calculate", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.FinancialStatementServiceClient.prototype.getTemplates = function(callbackHandler) {
  var parameterNames = [];
  var parameterValues = [];
  var soapMessage = buildSOAPMessage("getTemplates", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param legalEntityType [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.FinancialStatementServiceClient.prototype.getTemplatesByLegalEntityType = function(legalEntityType, callbackHandler) {
  var parameterNames = ["legalEntityType"];
  var legalEntityTypeJSON = JSON.stringify(legalEntityType);
  var parameterValues = [legalEntityTypeJSON];
  var soapMessage = buildSOAPMessage("getTemplatesByLegalEntityType", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param templateItem [DEFAULT!!DEFAULT]
 * @param templateID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.FinancialStatementServiceClient.prototype.addTemplateItem = function(templateItem, templateID, callbackHandler) {
  var parameterNames = ["templateItem", "templateID"];
  var templateItemJSON = JSON.stringify(templateItem);
  var parameterValues = [templateItemJSON, templateID];
  var soapMessage = buildSOAPMessage("addTemplateItem", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param templateItem [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.FinancialStatementServiceClient.prototype.updateTemplateItem = function(templateItem, callbackHandler) {
  var parameterNames = ["templateItem"];
  var templateItemJSON = JSON.stringify(templateItem);
  var parameterValues = [templateItemJSON];
  var soapMessage = buildSOAPMessage("updateTemplateItem", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param templateItem [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.FinancialStatementServiceClient.prototype.removeTemplateItem = function(templateItem, callbackHandler) {
  var parameterNames = ["templateItem"];
  var templateItemJSON = JSON.stringify(templateItem);
  var parameterValues = [templateItemJSON];
  var soapMessage = buildSOAPMessage("removeTemplateItem", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param ratio [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.FinancialStatementServiceClient.prototype.addRatio = function(ratio, callbackHandler) {
  var parameterNames = ["ratio"];
  var ratioJSON = JSON.stringify(ratio);
  var parameterValues = [ratioJSON];
  var soapMessage = buildSOAPMessage("addRatio", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param ratio [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.FinancialStatementServiceClient.prototype.updateRatio = function(ratio, callbackHandler) {
  var parameterNames = ["ratio"];
  var ratioJSON = JSON.stringify(ratio);
  var parameterValues = [ratioJSON];
  var soapMessage = buildSOAPMessage("updateRatio", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param ratioID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.FinancialStatementServiceClient.prototype.removeRatio = function(ratioID, callbackHandler) {
  var parameterNames = ["ratioID"];
  var parameterValues = [ratioID];
  var soapMessage = buildSOAPMessage("removeRatio", parameterNames, parameterValues);
  var resultHasComplexType = false;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param ratioTypeIDs [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.FinancialStatementServiceClient.prototype.getRatios = function(ratioTypeIDs, callbackHandler) {
  var parameterNames = ["ratioTypeIDs"];
  var ratioTypeIDsJSON = JSON.stringify(ratioTypeIDs);
  var parameterValues = [ratioTypeIDsJSON];
  var soapMessage = buildSOAPMessage("getRatios", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = true;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param ratioID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.FinancialStatementServiceClient.prototype.getRatioByID = function(ratioID, callbackHandler) {
  var parameterNames = ["ratioID"];
  var parameterValues = [ratioID];
  var soapMessage = buildSOAPMessage("getRatioByID", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = true;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param ratioID [DEFAULT!!DEFAULT]
 * @param counterpartyID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.FinancialStatementServiceClient.prototype.addRatioToCounterparty = function(ratioID, counterpartyID, callbackHandler) {
  var parameterNames = ["ratioID", "counterpartyID"];
  var parameterValues = [ratioID, counterpartyID];
  var soapMessage = buildSOAPMessage("addRatioToCounterparty", parameterNames, parameterValues);
  var resultHasComplexType = false;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param ratioID [DEFAULT!!DEFAULT]
 * @param counterpartyID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.FinancialStatementServiceClient.prototype.removeRatioFromCounterparty = function(ratioID, counterpartyID, callbackHandler) {
  var parameterNames = ["ratioID", "counterpartyID"];
  var parameterValues = [ratioID, counterpartyID];
  var soapMessage = buildSOAPMessage("removeRatioFromCounterparty", parameterNames, parameterValues);
  var resultHasComplexType = false;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param counterpartyID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.FinancialStatementServiceClient.prototype.getCounterpartyRatios = function(counterpartyID, callbackHandler) {
  var parameterNames = ["counterpartyID"];
  var parameterValues = [counterpartyID];
  var soapMessage = buildSOAPMessage("getCounterpartyRatios", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = true;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param counterpartyID [DEFAULT!!DEFAULT]
 * @param currencyID [DEFAULT!!DEFAULT]
 * @param variantID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.FinancialStatementServiceClient.prototype.getCounterpartyRatioValues = function(counterpartyID, currencyID, variantID, callbackHandler) {
  var parameterNames = ["counterpartyID", "currencyID", "variantID"];
  var parameterValues = [counterpartyID, currencyID, variantID];
  var soapMessage = buildSOAPMessage("getCounterpartyRatioValues", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = true;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param faTemplateID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.FinancialStatementServiceClient.prototype.getFinancialAnalysisTemplate = function(faTemplateID, callbackHandler) {
  var parameterNames = ["faTemplateID"];
  var parameterValues = [faTemplateID];
  var soapMessage = buildSOAPMessage("getFinancialAnalysisTemplate", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = true;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param ratioGroup [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.FinancialStatementServiceClient.prototype.addRatioGroup = function(ratioGroup, callbackHandler) {
  var parameterNames = ["ratioGroup"];
  var ratioGroupJSON = JSON.stringify(ratioGroup);
  var parameterValues = [ratioGroupJSON];
  var soapMessage = buildSOAPMessage("addRatioGroup", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param ratioGroup [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.FinancialStatementServiceClient.prototype.updateRatioGroup = function(ratioGroup, callbackHandler) {
  var parameterNames = ["ratioGroup"];
  var ratioGroupJSON = JSON.stringify(ratioGroup);
  var parameterValues = [ratioGroupJSON];
  var soapMessage = buildSOAPMessage("updateRatioGroup", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param ratioGroupID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.FinancialStatementServiceClient.prototype.removeRatioGroup = function(ratioGroupID, callbackHandler) {
  var parameterNames = ["ratioGroupID"];
  var parameterValues = [ratioGroupID];
  var soapMessage = buildSOAPMessage("removeRatioGroup", parameterNames, parameterValues);
  var resultHasComplexType = false;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param ratioGroupID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.FinancialStatementServiceClient.prototype.getRatioGroupByID = function(ratioGroupID, callbackHandler) {
  var parameterNames = ["ratioGroupID"];
  var parameterValues = [ratioGroupID];
  var soapMessage = buildSOAPMessage("getRatioGroupByID", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = true;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param ratioTypeIDs [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.FinancialStatementServiceClient.prototype.getRatioGroups = function(ratioTypeIDs, callbackHandler) {
  var parameterNames = ["ratioTypeIDs"];
  var ratioTypeIDsJSON = JSON.stringify(ratioTypeIDs);
  var parameterValues = [ratioTypeIDsJSON];
  var soapMessage = buildSOAPMessage("getRatioGroups", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = true;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param counterpartyID [DEFAULT!!DEFAULT]
 * @param currencyID [DEFAULT!!DEFAULT]
 * @param forceRecalculation [DEFAULT!!DEFAULT]
 * @param variantID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.FinancialStatementServiceClient.prototype.calculateCounterpartyRatioValues = function(counterpartyID, currencyID, forceRecalculation, variantID, callbackHandler) {
  var parameterNames = ["counterpartyID", "currencyID", "forceRecalculation", "variantID"];
  var parameterValues = [counterpartyID, currencyID, forceRecalculation, variantID];
  var soapMessage = buildSOAPMessage("calculateCounterpartyRatioValues", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param counterpartyID [DEFAULT!!DEFAULT]
 * @param currencyID [DEFAULT!!DEFAULT]
 * @param ratioValues [DEFAULT!!DEFAULT]
 * @param variantID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.FinancialStatementServiceClient.prototype.setCounterpartyRatioValues = function(counterpartyID, currencyID, ratioValues, variantID, callbackHandler) {
  var parameterNames = ["counterpartyID", "currencyID", "ratioValues", "variantID"];
  var ratioValuesJSON = JSON.stringify(ratioValues);
  var parameterValues = [counterpartyID, currencyID, ratioValuesJSON, variantID];
  var soapMessage = buildSOAPMessage("setCounterpartyRatioValues", parameterNames, parameterValues);
  var resultHasComplexType = false;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param counterpartyID [DEFAULT!!DEFAULT]
 * @param currencyID [DEFAULT!!DEFAULT]
 * @param ratioValues [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.FinancialStatementServiceClient.prototype.removeCounterpartyRatioValues = function(counterpartyID, currencyID, ratioValues, callbackHandler) {
  var parameterNames = ["counterpartyID", "currencyID", "ratioValues"];
  var ratioValuesJSON = JSON.stringify(ratioValues);
  var parameterValues = [counterpartyID, currencyID, ratioValuesJSON];
  var soapMessage = buildSOAPMessage("removeCounterpartyRatioValues", parameterNames, parameterValues);
  var resultHasComplexType = false;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param counterpartyID [DEFAULT!!DEFAULT]
 * @param currencyID [DEFAULT!!DEFAULT]
 * @param budgetVersionID [DEFAULT!!DEFAULT]
 * @param variantID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.FinancialStatementServiceClient.prototype.getCounterpartyRatioValuesForVersion = function(counterpartyID, currencyID, budgetVersionID, variantID, callbackHandler) {
  var parameterNames = ["counterpartyID", "currencyID", "budgetVersionID", "variantID"];
  var parameterValues = [counterpartyID, currencyID, budgetVersionID, variantID];
  var soapMessage = buildSOAPMessage("getCounterpartyRatioValuesForVersion", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = true;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param counterpartyID [DEFAULT!!DEFAULT]
 * @param currencyID [DEFAULT!!DEFAULT]
 * @param budgetVersionDate [DEFAULT!!DEFAULT]
 * @param variantID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.FinancialStatementServiceClient.prototype.getCounterpartyRatioValuesVersionForDate = function(counterpartyID, currencyID, budgetVersionDate, variantID, callbackHandler) {
  var parameterNames = ["counterpartyID", "currencyID", "budgetVersionDate", "variantID"];
  var parameterValues = [counterpartyID, currencyID, budgetVersionDate, variantID];
  var soapMessage = buildSOAPMessage("getCounterpartyRatioValuesVersionForDate", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = true;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param ratioIDList [DEFAULT!!DEFAULT]
 * @param counterpartyIDList [DEFAULT!!DEFAULT]
 * @param budgetVersionDate [DEFAULT!!DEFAULT]
 * @param currencyID [DEFAULT!!DEFAULT]
 * @param variantID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.FinancialStatementServiceClient.prototype.getCounterpartyListRatioValuesVersion = function(ratioIDList, counterpartyIDList, budgetVersionDate, currencyID, variantID, callbackHandler) {
  var parameterNames = ["ratioIDList", "counterpartyIDList", "budgetVersionDate", "currencyID", "variantID"];
  var ratioIDListJSON = JSON.stringify(ratioIDList);
  var counterpartyIDListJSON = JSON.stringify(counterpartyIDList);
  var parameterValues = [ratioIDListJSON, counterpartyIDListJSON, budgetVersionDate, currencyID, variantID];
  var soapMessage = buildSOAPMessage("getCounterpartyListRatioValuesVersion", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = true;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param financialStatementTemplateID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.FinancialStatementServiceClient.prototype.getFinancialStatementTemplateImport = function(financialStatementTemplateID, callbackHandler) {
  var parameterNames = ["financialStatementTemplateID"];
  var parameterValues = [financialStatementTemplateID];
  var soapMessage = buildSOAPMessage("getFinancialStatementTemplateImport", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param fileReference [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.FinancialStatementServiceClient.prototype.importFinancialStatementTemplateFile = function(fileReference, callbackHandler) {
  var parameterNames = ["fileReference"];
  var fileReferenceJSON = JSON.stringify(fileReference);
  var parameterValues = [fileReferenceJSON];
  var soapMessage = buildSOAPMessage("importFinancialStatementTemplateFile", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param transformation [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.FinancialStatementServiceClient.prototype.addTemplateTransformation = function(transformation, callbackHandler) {
  var parameterNames = ["transformation"];
  var transformationJSON = JSON.stringify(transformation);
  var parameterValues = [transformationJSON];
  var soapMessage = buildSOAPMessage("addTemplateTransformation", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param transformation [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.FinancialStatementServiceClient.prototype.updateTemplateTransformation = function(transformation, callbackHandler) {
  var parameterNames = ["transformation"];
  var transformationJSON = JSON.stringify(transformation);
  var parameterValues = [transformationJSON];
  var soapMessage = buildSOAPMessage("updateTemplateTransformation", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param transformationID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.FinancialStatementServiceClient.prototype.removeTemplateTransformation = function(transformationID, callbackHandler) {
  var parameterNames = ["transformationID"];
  var parameterValues = [transformationID];
  var soapMessage = buildSOAPMessage("removeTemplateTransformation", parameterNames, parameterValues);
  var resultHasComplexType = false;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param transformationID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.FinancialStatementServiceClient.prototype.getTemplateTransformationByID = function(transformationID, callbackHandler) {
  var parameterNames = ["transformationID"];
  var parameterValues = [transformationID];
  var soapMessage = buildSOAPMessage("getTemplateTransformationByID", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = true;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.FinancialStatementServiceClient.prototype.getTemplateTransformations = function(callbackHandler) {
  var parameterNames = [];
  var parameterValues = [];
  var soapMessage = buildSOAPMessage("getTemplateTransformations", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = true;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param templateItemTransformation [DEFAULT!!DEFAULT]
 * @param templateTransformationID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.FinancialStatementServiceClient.prototype.addTemplateItemTransformation = function(templateItemTransformation, templateTransformationID, callbackHandler) {
  var parameterNames = ["templateItemTransformation", "templateTransformationID"];
  var templateItemTransformationJSON = JSON.stringify(templateItemTransformation);
  var parameterValues = [templateItemTransformationJSON, templateTransformationID];
  var soapMessage = buildSOAPMessage("addTemplateItemTransformation", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param templateItemTransformation [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.FinancialStatementServiceClient.prototype.updateTemplateItemTransformation = function(templateItemTransformation, callbackHandler) {
  var parameterNames = ["templateItemTransformation"];
  var templateItemTransformationJSON = JSON.stringify(templateItemTransformation);
  var parameterValues = [templateItemTransformationJSON];
  var soapMessage = buildSOAPMessage("updateTemplateItemTransformation", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param templateItemTransformation [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.FinancialStatementServiceClient.prototype.removeTemplateItemTransformation = function(templateItemTransformation, callbackHandler) {
  var parameterNames = ["templateItemTransformation"];
  var templateItemTransformationJSON = JSON.stringify(templateItemTransformation);
  var parameterValues = [templateItemTransformationJSON];
  var soapMessage = buildSOAPMessage("removeTemplateItemTransformation", parameterNames, parameterValues);
  var resultHasComplexType = false;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param variantID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.FinancialStatementServiceClient.prototype.getBudgetVariant = function(variantID, callbackHandler) {
  var parameterNames = ["variantID"];
  var parameterValues = [variantID];
  var soapMessage = buildSOAPMessage("getBudgetVariant", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param variantCode [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.FinancialStatementServiceClient.prototype.getBudgetVariantByCode = function(variantCode, callbackHandler) {
  var parameterNames = ["variantCode"];
  var parameterValues = [variantCode];
  var soapMessage = buildSOAPMessage("getBudgetVariantByCode", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param importFinancialStatementRequest [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.FinancialStatementServiceClient.prototype.importFinancialStatement = function(importFinancialStatementRequest, callbackHandler) {
  var parameterNames = ["importFinancialStatementRequest"];
  var importFinancialStatementRequestJSON = JSON.stringify(importFinancialStatementRequest);
  var parameterValues = [importFinancialStatementRequestJSON];
  var soapMessage = buildSOAPMessage("importFinancialStatement", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param operationUID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.FinancialStatementServiceClient.prototype.getImportFinancialStatementStatus = function(operationUID, callbackHandler) {
  var parameterNames = ["operationUID"];
  var parameterValues = [operationUID];
  var soapMessage = buildSOAPMessage("getImportFinancialStatementStatus", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = true;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param counterpartyID [DEFAULT!!DEFAULT]
 * @param currencyID [DEFAULT!!DEFAULT]
 * @param variantID [DEFAULT!!DEFAULT]
 * @param forceNewVersion [DEFAULT!!DEFAULT]
 * @param templateID [DEFAULT!!DEFAULT]
 * @param fileName [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.FinancialStatementServiceClient.prototype.importFinancialStatementFile = function(counterpartyID, currencyID, variantID, forceNewVersion, templateID, fileName, callbackHandler) {
  var parameterNames = ["counterpartyID", "currencyID", "variantID", "forceNewVersion", "templateID", "fileName"];
  var parameterValues = [counterpartyID, currencyID, variantID, forceNewVersion, templateID, fileName];
  var soapMessage = buildSOAPMessage("importFinancialStatementFile", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param request [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.FinancialStatementServiceClient.prototype.importFinancialStatementDataToStaging = function(request, callbackHandler) {
  var parameterNames = ["request"];
  var requestJSON = JSON.stringify(request);
  var parameterValues = [requestJSON];
  var soapMessage = buildSOAPMessage("importFinancialStatementDataToStaging", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!Import FinancialStatement from staging data!DEFAULT]
 * @param counterpartyID [DEFAULT!!DEFAULT]
 * @param currencyID [DEFAULT!!DEFAULT]
 * @param forceNewVersion [DEFAULT!!DEFAULT]
 * @param templateID [DEFAULT!!DEFAULT]
 * @param variantID [DEFAULT!!DEFAULT]
 * @param startMonthID [DEFAULT!!DEFAULT]
 * @param endMonthID [DEFAULT!!DEFAULT]
 * @param externalSourceID [DEFAULT!-1 for default means first appropriate source!DEFAULT]
 * @param externalCounterpartyCode [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.FinancialStatementServiceClient.prototype.importFinancialStatementFromStaging = function(counterpartyID, currencyID, forceNewVersion, templateID, variantID, startMonthID, endMonthID, externalSourceID, externalCounterpartyCode, callbackHandler) {
  var parameterNames = ["counterpartyID", "currencyID", "forceNewVersion", "templateID", "variantID", "startMonthID", "endMonthID", "externalSourceID", "externalCounterpartyCode"];
  var parameterValues = [counterpartyID, currencyID, forceNewVersion, templateID, variantID, startMonthID, endMonthID, externalSourceID, externalCounterpartyCode];
  var soapMessage = buildSOAPMessage("importFinancialStatementFromStaging", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param counterpartyID [DEFAULT!!DEFAULT]
 * @param externalCounterpartyCode [DEFAULT!!DEFAULT]
 * @param templateID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.FinancialStatementServiceClient.prototype.lookupFinancialStatementSource = function(counterpartyID, externalCounterpartyCode, templateID, callbackHandler) {
  var parameterNames = ["counterpartyID", "externalCounterpartyCode", "templateID"];
  var parameterValues = [counterpartyID, externalCounterpartyCode, templateID];
  var soapMessage = buildSOAPMessage("lookupFinancialStatementSource", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * Client API to GeneralLedgerServiceWS web service
 */
w.GeneralLedgerServiceClient = function() {
  /***
   * System name of target service
   */
  this.serviceName = "GeneralLedgerServiceWSImpl";
};

/***
 * [DEFAULT!!DEFAULT]
 * @param operation [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.GeneralLedgerServiceClient.prototype.makeOperation = function(operation, callbackHandler) {
  var parameterNames = ["operation"];
  var operationJSON = JSON.stringify(operation);
  var parameterValues = [operationJSON];
  var soapMessage = buildSOAPMessage("makeOperation", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param operation [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.GeneralLedgerServiceClient.prototype.updateOperation = function(operation, callbackHandler) {
  var parameterNames = ["operation"];
  var operationJSON = JSON.stringify(operation);
  var parameterValues = [operationJSON];
  var soapMessage = buildSOAPMessage("updateOperation", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param operationID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.GeneralLedgerServiceClient.prototype.removeOperation = function(operationID, callbackHandler) {
  var parameterNames = ["operationID"];
  var parameterValues = [operationID];
  var soapMessage = buildSOAPMessage("removeOperation", parameterNames, parameterValues);
  var resultHasComplexType = false;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param operationID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.GeneralLedgerServiceClient.prototype.getOperationByID = function(operationID, callbackHandler) {
  var parameterNames = ["operationID"];
  var parameterValues = [operationID];
  var soapMessage = buildSOAPMessage("getOperationByID", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param template [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.GeneralLedgerServiceClient.prototype.addOperationTemplate = function(template, callbackHandler) {
  var parameterNames = ["template"];
  var templateJSON = JSON.stringify(template);
  var parameterValues = [templateJSON];
  var soapMessage = buildSOAPMessage("addOperationTemplate", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param template [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.GeneralLedgerServiceClient.prototype.updateOperationTemplate = function(template, callbackHandler) {
  var parameterNames = ["template"];
  var templateJSON = JSON.stringify(template);
  var parameterValues = [templateJSON];
  var soapMessage = buildSOAPMessage("updateOperationTemplate", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param templateID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.GeneralLedgerServiceClient.prototype.removeOperationTemplate = function(templateID, callbackHandler) {
  var parameterNames = ["templateID"];
  var parameterValues = [templateID];
  var soapMessage = buildSOAPMessage("removeOperationTemplate", parameterNames, parameterValues);
  var resultHasComplexType = false;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};

/***
 * [DEFAULT!!DEFAULT]
 * @param templateID [DEFAULT!!DEFAULT]
 * @param callbackHandler Function to service callback result
 */
w.GeneralLedgerServiceClient.prototype.getOperationTemplateByID = function(templateID, callbackHandler) {
  var parameterNames = ["templateID"];
  var parameterValues = [templateID];
  var soapMessage = buildSOAPMessage("getOperationTemplateByID", parameterNames, parameterValues);
  var resultHasComplexType = true;
  var autoRestartOnError = false;
  callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
};


/***
 * Package security rights constants
 */

w.AccountingSecurityRights = {

    FINANCIAL_RATIO_MODEL_INPUT_RIGHT:new SecurityRightCheckHandle('ee30ed3e-30ae-4fc7-a3e9-71c18a087a54'),
    FINANCIAL_STATEMENT_TEMPLATE_INPUT_RIGHT:new SecurityRightCheckHandle('934204ca-2cd5-41e4-8191-ea3dffaedafa'),
    FINANCIAL_STATEMENT_VIEW_RIGHT:new SecurityRightCheckHandle('b3cc837e-33ae-4bc8-9946-743e0cda0ef2')
};
})(typeof exports !== 'undefined' ? exports : window);
