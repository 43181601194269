import { createStaticDataAction } from '../../../core/libs/action-creator';

const EMIT_ACTION_MESSAGE = '[SHARED]: EMIT_ACTION_MESSAGE';

function emitActionMessage(message: string | React.ReactNode, type: IActionMessageType = 'default') {
	const payload: IActionMessage = {
		type,
		message,
		receiveAt: Date.now()
	};

	return createStaticDataAction(EMIT_ACTION_MESSAGE)(payload);
}

export {
	EMIT_ACTION_MESSAGE,
	emitActionMessage,
};
