import React from 'react';

import { formatLegalEntityName } from '@utils/counterparty';
import { plural } from '@utils/plural';
import { Box } from '@ui/box';
import { Typography } from '@ui/typography';
import { RaisedButtonConfirm } from '@ui/raised-button';
import { StarIcon } from '@ui/icons/star';
import { Tooltip } from '@ui/tooltip';

export type TransformPopoverProps = {
	isLoaded: boolean;
	item: TenantEntityCanBeRemovedResponseItem;
	legalEntityName: string;
	onRequestTransform: () => void;
	onRequestClose: () => void;
};

const TransformPopover: React.FC<TransformPopoverProps> = props => {
	const { isLoaded, item, legalEntityName, onRequestTransform, onRequestClose } = props;
	const canTransform = item?.CanBeRemoved || false;
	const isTooltipOpened = Boolean(isLoaded && !canTransform);

	const renderDescription = () => {
		return (
			<Box textAlign='left' fullWidth>
				<Typography.Text component='div' marginBottom={10}>
					Преобразование организации «{formatLegalEntityName(legalEntityName)}» в контрагента невозможно, так как в
					системе имеются привязанные к ней сущности:
				</Typography.Text>
				<ul style={{ paddingLeft: 16 }}>
					{item.IntegrationsCount > 0 && (
						<li>
							{item.IntegrationsCount}{' '}
							{plural({
								count: item.IntegrationsCount,
								titles: ['интеграция', 'интеграции', 'интеграций'],
							})}
						</li>
					)}
					{item.FundsRegistersCount > 0 && (
						<li>
							{item.FundsRegistersCount}{' '}
							{plural({ count: item.FundsRegistersCount, titles: ['счёт', 'счёта', 'счетов'] })}
						</li>
					)}
					{item.OperationsCount > 0 && (
						<li>
							{item.OperationsCount}{' '}
							{plural({ count: item.OperationsCount, titles: ['операция', 'операции', 'операций'] })}
						</li>
					)}
					{item.InvoicesCount > 0 && (
						<li>
							{item.InvoicesCount}{' '}
							{plural({
								count: item.InvoicesCount,
								titles: ['выставленный счёт', 'выставленных счёта', 'выставленных счетов'],
							})}
						</li>
					)}
				</ul>
			</Box>
		);
	};

	return (
		<RaisedButtonConfirm
			appearance='contained'
			color='white'
			size='small'
			disabled={isTooltipOpened}
			fullWidth
			onClick={onRequestTransform}>
			<StarIcon color='accent' />
			<span>Преобразовать в контрагента</span>
			<Tooltip isOpen={isTooltipOpened} position='top' onRequestClose={onRequestClose}>
				{() => renderDescription()}
			</Tooltip>
		</RaisedButtonConfirm>
	);
};

export { TransformPopover };
