import * as _ from 'underscore';

import createAction from '@core/libs/action-creator';
import api from '@api';
import * as externalAccountSelectors from '../selectors/external-system-account.selectors';


const FETCH_CASH_MANAGEMENT_SYSTEMS = '[PLATFORM]: FETCH_CASH_MANAGEMENT_SYSTEMS';

const fetchCashManagementSystemsAction = createAction(FETCH_CASH_MANAGEMENT_SYSTEMS, fetchCashManagementSystems, {
	isValidSelector: state => externalAccountSelectors.selectCMSIsLoaded(state) && !externalAccountSelectors.selectCMSDidInvalidate(state),
	isFetchingSelector: externalAccountSelectors.selectCMSIsFetching,
});

function fetchCashManagementSystems(api, getState, dispatch) {
	return fetchCashManagementSystemsMethod();
}

function fetchCashManagementSystemsMethod() {
	return new Promise(resolve => {
		api.cashManagementSystemClient.getCashManagementSystems((result: Array<CashManagementSystem>) => {
			resolve(result);
		});
	});
}

export const methods = {
	fetchCashManagementSystemsMethod,
}

export {
	FETCH_CASH_MANAGEMENT_SYSTEMS,
	fetchCashManagementSystemsAction,
};
