import { runPayInvoiceEffect } from './pay-invoice.effect';


const invoiceEffects = [
	runPayInvoiceEffect
];


export {
	invoiceEffects
}
