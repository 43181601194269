import { combineReducers } from 'redux';

import { mainEmployeesReducer, MainEmployeesState } from './main.reducers';

export type EmployeesState = {
	main: MainEmployeesState;
};

const employeesReducer = combineReducers<EmployeesState>({
	main: mainEmployeesReducer,
});

export { employeesReducer };
