import styled, { css, createGlobalStyle } from 'styled-components';

const TextFieldGlobalStyle = createGlobalStyle`
	${p => css`
		.input_readonly input,
		.input_readonly textarea,
		.input_readonly label,
		.input_readonly div:not(.input-bottom-text-label),
		.select_readonly input,
		.select_readonly textarea,
		.select_readonly label,
		.select_readonly div:not(.input-bottom-text-label) {
			color: ${p.theme.palette.text} !important;
		}

		.input_readonly hr,
		.select_readonly hr {
			border-color: ${p.theme.palette.hint} !important;
		}

		.input_readonly hr:last-child {
			border-bottom: none !important;
		}
	`}
`;

type RootProps = {
	fullWidth: boolean;
	withoutMarginRight: boolean;
};

const Root = styled.div<RootProps>`
	position: relative;
	display: inline-block;
	width: ${p => (p.fullWidth ? '100%' : 'auto')};

	${p =>
		p.withoutMarginRight &&
		css`
			margin-right: 0;
		`}
`;

type ClearButtonLayoutProps = {
	withLabelText: boolean;
	withRightIcon?: boolean;
};

const ClearButtonLayout = styled.div<ClearButtonLayoutProps>`
	position: absolute;
	top: 30px;
	right: 0px;

	${p =>
		!p.withLabelText &&
		css`
			top: 6px;
			right: 0;
		`}

	${p =>
		p.withRightIcon &&
		css`
			right: 20px;
		`}
`;

const SpinnerLayout = styled.div`
	position: absolute;
	top: 38px;
	right: 0;
`;

export { TextFieldGlobalStyle, Root, ClearButtonLayout, SpinnerLayout };
