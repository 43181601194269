import { combineReducers } from 'redux';

import { mainAccrualReportReducer, MainAccrualReportState } from './main.reducer';

export type AccrualReportState = {
	main: MainAccrualReportState;
};

const accrualReportReducer = combineReducers<AccrualReportState>({
	main: mainAccrualReportReducer,
});

export { accrualReportReducer };
