import { StoreAsyncItem, createAsyncInitialState, checkAsyncAction, createReducer } from '@flux';
import { mainTypes as types } from '@reference/actions';

export type MainReferenceState = {
	taxModes: StoreAsyncItem<Array<LegalEntityTaxMode>>;
	currencies: StoreAsyncItem<Array<Currency>>;
	countries: StoreAsyncItem<Array<Country>>;
};

const initialState: MainReferenceState = {
	taxModes: createAsyncInitialState([]),
	currencies: createAsyncInitialState([]),
	countries: createAsyncInitialState([]),
};

const mainReferenceReducer = createReducer<MainReferenceState>(initialState, {
	[types.FETCH_LEGAL_ENTITY_TAX_MODES]: (action: AsyncAction<LegalEntityTaxMode>, state) => {
		return {
			taxModes: checkAsyncAction(action, state.taxModes),
		};
	},
	[types.FETCH_CURRENCIES]: (action: AsyncAction<Array<Currency>>, state) => {
		return {
			currencies: checkAsyncAction(action, state.currencies),
		};
	},
	[types.FETCH_COUNTRIES]: (action: AsyncAction<Array<Country>>, state) => {
		return {
			countries: checkAsyncAction(action, state.countries),
		};
	},
});

export { mainReferenceReducer };
