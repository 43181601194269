import React, { useEffect, useRef } from 'react';

import { useFormVariant } from '@core/hooks';
import { Box } from '@ui/box';
import { Typography } from '@ui/typography';
import { AutopickerCRUD, AutopickerCrudProps, AutopickerCrudRef } from '@ui/autopicker';
import { FormTextField, Form, FormRef, useFormContext } from '@ui/forms';
import { FormTaxCodeTextField } from '@counterparties/components/tax-code-text-field';
import { FormAdditionalTaxCodeTextField } from '@counterparties/components/additional-tax-code-text-field';
import { FormStateRegistrationCodeTextField } from '@counterparties/components/state-registration-code-text-field';
import { TransformTenantLegalEntityPopover } from '../transform-tenant-legal-entity-popover';
import {
	FormLegalEntityFormAutopicker,
	legalEntityFormAutopickerTransformer,
} from '@counterparties/components/legal-entity-form-autopicker';
import { FormTaxModeAutopicker, taxModeAutopickerTransformer } from '@reference/components/tax-mode-autopicker';

export type TenantLegalEntityAutopickerCrudProps = {
	variant: 'add' | 'edit';
	isFetching: boolean;
	initialFormObject: LegalEntity;
	disabled: boolean;
	onAddPopupOpen: () => void;
	onEditPopupOpen: () => void;
	onRequestAdd: (value: LegalEntity) => void;
	onRequestEdit: (value: LegalEntity) => void;
	onResetForm: () => void;
} & Pick<AutopickerCrudProps, 'isSmallContainer' | 'onRelatedPopupChange'>;

const TenantLegalEntityAutopickerCRUD: React.FC<TenantLegalEntityAutopickerCrudProps> = props => {
	const {
		isFetching,
		variant,
		initialFormObject,
		disabled,
		isSmallContainer,
		onAddPopupOpen,
		onEditPopupOpen,
		onRequestAdd,
		onRequestEdit,
		onRelatedPopupChange,
		onResetForm,
	} = props;
	const { isAdd, isEdit } = useFormVariant(variant);
	const formRef = useRef<FormRef<LegalEntity>>(null);
	const addCrudRef = useRef<AutopickerCrudRef>(null);
	const editCrudRef = useRef<AutopickerCrudRef>(null);

	useEffect(() => {
		if (!formRef.current || !initialFormObject) return;
		const formObject = formRef.current.getFormObject();

		if (formObject === initialFormObject) return;

		formRef.current.setFormObject(initialFormObject);
	}, [initialFormObject]);

	const handleRequestSubmit = () => formRef.current?.submitForm();

	const handleSubmit = (formObject: LegalEntity) => {
		if (isAdd) {
			onRequestAdd(formObject);
			addCrudRef.current.closePopup();
		} else if (isEdit) {
			onRequestEdit(formObject);
			editCrudRef.current.closePopup();
		}
	};

	const handleClosePopover = () => {
		addCrudRef.current?.closePopup();
		editCrudRef.current?.closePopup();
		onResetForm();
	};

	const renderContent = () => {
		if (isFetching) return <Box minHeight={HEIGHT} />;

		return (
			<Form ref={formRef as any} formObject={initialFormObject} fullWidth onSubmit={handleSubmit}>
				<Content variant={variant} onRequestClosePopover={handleClosePopover} />
			</Form>
		);
	};

	const renderMap: Record<TenantLegalEntityAutopickerCrudProps['variant'], () => React.ReactNode> = {
		add: () => {
			return (
				<AutopickerCRUD
					ref={addCrudRef}
					appearance='add'
					triggerLabel='Добавить организацию'
					isFetching={isFetching}
					disabled={disabled}
					isSmallContainer={isSmallContainer}
					onPopupOpen={onAddPopupOpen}
					onRelatedPopupChange={onRelatedPopupChange}
					onRequestAction={handleRequestSubmit}>
					{renderContent()}
				</AutopickerCRUD>
			);
		},
		edit: () => {
			return (
				<AutopickerCRUD
					ref={editCrudRef}
					appearance='edit'
					disabled={disabled}
					isFetching={isFetching}
					onPopupOpen={onEditPopupOpen}
					onRelatedPopupChange={onRelatedPopupChange}
					onRequestAction={handleRequestSubmit}>
					{renderContent()}
				</AutopickerCRUD>
			);
		},
	};

	return renderMap[variant] ? <>{renderMap[variant]()}</> : null;
};

type ContentProps = {
	onRequestClosePopover: () => void;
} & Pick<TenantLegalEntityAutopickerCrudProps, 'variant'>;

const Content: React.FC<ContentProps> = props => {
	const { variant, onRequestClosePopover } = props;
	const { isAdd, isEdit } = useFormVariant(variant);
	const { formObject } = useFormContext<LegalEntity>();
	const titleText = isAdd ? 'Быстрое добавление собственной организации' : 'Быстрое изменение собственной организации';
	const isSoleProprietor = Boolean(formObject.SoleProprietor);
	const hasAdditionalTaxCode = !isSoleProprietor;

	return (
		<Box minHeight={HEIGHT}>
			<Typography.Text component='div' fontWeight={500} fontSize={16}>
				{titleText}
			</Typography.Text>
			<FormTextField
				name='Name'
				labelText='Наименование'
				hintText='Например, ООО «Ромашка»'
				autoFocus
				withClearBtn
				required
				fullWidth
			/>
			<FormLegalEntityFormAutopicker
				name='SoleProprietor'
				labelText='Форма ведения деятельности'
				disableReset
				required
				fullWidth
				{...legalEntityFormAutopickerTransformer.boolean.single}
			/>
			<FormTaxModeAutopicker
				name='LegalEntityTaxMode'
				helpMark='Форма налогообложения поможет нам спрогнозировать налоги и выделить НДС'
				fullWidth
				{...taxModeAutopickerTransformer.legalEntityTaxMode.single}
			/>
			<Box
				display='grid'
				gridTemplateColumns={hasAdditionalTaxCode ? 'repeat(3, minmax(0, 1fr))' : 'repeat(2, minmax(0, 1fr))'}
				gridGap={16}>
				<FormTaxCodeTextField
					name='TaxCode'
					variant={isSoleProprietor ? 'natural-person' : 'legal-entity'}
					validateUniqueness
					withClearBtn
					required
					fullWidth
				/>
				{hasAdditionalTaxCode && (
					<FormAdditionalTaxCodeTextField name='AdditionalTaxCode' withClearBtn required fullWidth />
				)}
				<FormStateRegistrationCodeTextField
					name='StateRegistrationCode'
					variant={isSoleProprietor ? 'sole-proprietor' : 'legal-entity'}
					withClearBtn
					fullWidth
				/>
			</Box>
			<FormTextField
				name='LegalAddress'
				labelText='Юридический адрес'
				hintText='Введите адрес...'
				rowsMax={3}
				withClearBtn
				multiLine
				fullWidth
			/>
			{isEdit && (
				<Box marginTop={8}>
					<TransformTenantLegalEntityPopover
						legalEntityID={formObject.ID}
						legalEntityName={formObject.Name}
						onComplete={onRequestClosePopover}
					/>
				</Box>
			)}
		</Box>
	);
};

const HEIGHT = 430;

export { TenantLegalEntityAutopickerCRUD };
