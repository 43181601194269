'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _typeof2 = require('babel-runtime/helpers/typeof');

var _typeof3 = _interopRequireDefault(_typeof2);

var _keys = require('babel-runtime/core-js/object/keys');

var _keys2 = _interopRequireDefault(_keys);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _assign = require('babel-runtime/core-js/object/assign');

var _assign2 = _interopRequireDefault(_assign);

exports.withOptions = withOptions;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _shallowEqual = require('fbjs/lib/shallowEqual');

var _shallowEqual2 = _interopRequireDefault(_shallowEqual);

var _warning = require('warning');

var _warning2 = _interopRequireDefault(_warning);

var _supports = require('./supports');

var supports = _interopRequireWildcard(_supports);

function _interopRequireWildcard(obj) {
	if (obj && obj.__esModule) {
		return obj;
	} else {
		var newObj = {};
		if (obj != null) {
			for (var key in obj) {
				if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key];
			}
		}
		newObj.default = obj;
		return newObj;
	}
}

function _interopRequireDefault(obj) {
	return obj && obj.__esModule ? obj : {
		default: obj
	};
}

var defaultEventOptions = {
	capture: false,
	passive: false
};
/* eslint-disable prefer-spread */

function mergeDefaultEventOptions(options) {
	return (0, _assign2.default)({}, defaultEventOptions, options);
}

function getEventListenerArgs(eventName, callback, options) {
	var args = [eventName, callback];
	args.push(supports.passiveOption ? options : options.capture);
	return args;
}

function on(target, eventName, callback, options) {
	if (supports.addEventListener) {
		target.addEventListener.apply(target, getEventListenerArgs(eventName, callback, options));
	} else if (supports.attachEvent) {
		// IE8+ Support
		target.attachEvent('on' + eventName, function () {
			callback.call(target);
		});
	}
}

function off(target, eventName, callback, options) {
	if (supports.removeEventListener) {
		target.removeEventListener.apply(target, getEventListenerArgs(eventName, callback, options));
	} else if (supports.detachEvent) {
		// IE8+ Support
		target.detachEvent('on' + eventName, callback);
	}
}

function forEachListener(props, iteratee) {
	var children = props.children,
		target = props.target,
		eventProps = (0, _objectWithoutProperties3.default)(props, ['children', 'target']);


	(0, _keys2.default)(eventProps).forEach(function (name) {
		if (name.substring(0, 2) !== 'on') {
			return;
		}

		var prop = eventProps[name];
		var type = typeof prop === 'undefined' ? 'undefined' : (0, _typeof3.default)(prop);
		var isObject = type === 'object';
		var isFunction = type === 'function';

		if (!isObject && !isFunction) {
			return;
		}

		var capture = name.substr(-7).toLowerCase() === 'capture';
		var eventName = name.substring(2).toLowerCase();
		eventName = capture ? eventName.substring(0, eventName.length - 7) : eventName;

		if (isObject) {
			iteratee(eventName, prop.handler, prop.options);
		} else {
			iteratee(eventName, prop, mergeDefaultEventOptions({
				capture: capture
			}));
		}
	});
}

function withOptions(handler, options) {
	process.env.NODE_ENV !== "production" ? (0, _warning2.default)(options, 'react-event-listener: Should be specified options in withOptions.') : void 0;

	return {
		handler: handler,
		options: mergeDefaultEventOptions(options)
	};
}

var EventListener = function (_Component) {
	(0, _inherits3.default)(EventListener, _Component);

	function EventListener() {
		(0, _classCallCheck3.default)(this, EventListener);
		return (0, _possibleConstructorReturn3.default)(this, (EventListener.__proto__ || (0, _getPrototypeOf2.default)(EventListener)).apply(this, arguments));
	}

	(0, _createClass3.default)(EventListener, [{
		key: 'componentDidMount',
		value: function componentDidMount() {
			this.addListeners();
		}
	}, {
		key: 'shouldComponentUpdate',
		value: function shouldComponentUpdate(nextProps) {
			return !(0, _shallowEqual2.default)(this.props, nextProps);
		}
	}, {
		key: 'getSnapshotBeforeUpdate',
		value: function getSnapshotBeforeUpdate() {
			this.removeListeners();

			return null;
		}
	}, {
		key: 'componentDidUpdate',
		value: function componentDidUpdate() {
			this.addListeners();
		}
	}, {
		key: 'componentWillUnmount',
		value: function componentWillUnmount() {
			this.removeListeners();
		}
	}, {
		key: 'addListeners',
		value: function addListeners() {
			this.applyListeners(on);
		}
	}, {
		key: 'removeListeners',
		value: function removeListeners() {
			this.applyListeners(off);
		}
	}, {
		key: 'applyListeners',
		value: function applyListeners(onOrOff) {
			var target = this.props.target;


			if (target) {
				var element = target;

				if (typeof target === 'string') {
					element = window[target];
				}

				forEachListener(this.props, onOrOff.bind(null, element));
			}
		}
	}, {
		key: 'render',
		value: function render() {
			return this.props.children || null;
		}
	}]);

	return EventListener;
}(_react.Component);

process.env.NODE_ENV !== "production" ? EventListener.propTypes = {
	/**
	 * You can provide a single child too.
	 */
	children: _propTypes2.default.element,
	/**
	 * The DOM target to listen to.
	 */
	target: _propTypes2.default.oneOfType([_propTypes2.default.object, _propTypes2.default.string])
} : void 0;
exports.default = EventListener;