import React from 'react';
import * as _ from 'underscore';

import { IAppState } from '../store';

export const DATA_IS_INVALIDATE = 'CORE:DATA_IS_INVALIDATE';

type ISelector = (state: IAppState) => boolean;
type ISelectors = ISelector | Array<ISelector>;

function withInvalidateFlag<S>(
	mapStateToProps: (state: IAppState, props: S) => S,
	selectors: ISelectors,
): (state: IAppState, props: S) => S {
	const selectorsArray: Array<ISelector> = _.isArray(selectors)
		? (selectors as Array<ISelector>)
		: [selectors as ISelector];
	return (state: IAppState, props: S) => ({
		[DATA_IS_INVALIDATE]: _.some(selectorsArray, selector => selector(state)),
		...mapStateToProps(state, props),
	});
}

function withValidData<IProps>(handler: (props: IProps) => void) {
	return (WrappedComponent: React.ComponentType<IProps>) => {
		return class extends React.Component<IProps, null> {
			componentDidMount() {
				handler(this.props);
			}

			componentDidUpdate(prevProps: IProps) {
				if (this.props[DATA_IS_INVALIDATE] && !prevProps[DATA_IS_INVALIDATE]) {
					handler(this.props);
				}
			}

			render() {
				return <WrappedComponent {...this.props} />;
			}
		};
	};
}

export { withValidData, withInvalidateFlag };
