import React from 'react';

import { Root } from './styled';

export type SpacerProps = {
	size?: number | string;
};

const Spacer: React.FC<SpacerProps> = props => {
	return <Root {...props} />;
};

export { Spacer };
