import { Store } from 'redux';

import { IAppState } from '@store';
import { isReceiveAction } from '@flux';
import { types as platformTypes } from '@platform/actions/types';
import { types as fundsRegisterTypes } from '@funds-registers/actions/types';
import { mainTypes as operationTypes } from '@pl/actions/types';
import { invalidatePayments } from '@payments/actions/invalidators';

function askPaymentsInvalidator(store: Store<IAppState>, action: StoreAction) {
	const { dispatch } = store;
	const invalidateMap = {
		[platformTypes.IMPORT_ACCOUNTS_FROM_CMS_TASK_COMPLETED]: true,
		[platformTypes.IMPORT_HISTORICAL_ACCOUNT_STATEMENT_FROM_CMS_TASK_COMPLETED]: true,
		[fundsRegisterTypes.ADD_FUNDS_REGISTER]: true,
		[fundsRegisterTypes.UPDATE_FUNDS_REGISTER]: true,
		[fundsRegisterTypes.REMOVE_FUNDS_REGISTER]: true,
		[fundsRegisterTypes.IMPORT_FUNDS_REGISTER_FILES_COMPLETED]: true,
		[fundsRegisterTypes.RECALCULATE_FUNDS_REGISTER_BALANCES]: true,
		[fundsRegisterTypes.REFRESH_CMS_ACCOUNTS]: true,
		[operationTypes.TRANSFORM_PLAN_OPERATIONS_TO_FACT]: true,
		[operationTypes.ADD_OPERATION]: true,
		[operationTypes.UPDATE_OPERATION]: true,
		[operationTypes.REMOVE_OPERATIONS]: true,
		[operationTypes.ADD_OPERATIONS_BY_TEMPLATE]: true,
		[operationTypes.UPDATE_OPERATIONS_BY_TEMPLATE]: true,
		[operationTypes.SEND_PLAN_OPERATIONS_TO_BANK]: true,
	};

	if (invalidateMap[action.type] && isReceiveAction(action)) {
		dispatch(invalidatePayments());
	}
}

function createInvalidatorEffect() {
	return (store: Store<IAppState>) => (next: (action: StoreAction) => void) => (action: StoreAction) => {
		next(action);
		askPaymentsInvalidator(store, action);
	};
}

const runInvalidatorEffect = createInvalidatorEffect();

export { runInvalidatorEffect };
