import { FetchOperationsOptions } from '@core/api/pl';
import { FetchAbcSegmentsOptions as FetchAbcSegmentsOptionsApi } from '@core/api/analysis';
import { createAsyncAction, createStaticAction, createTypes } from '@flux';

export type FetchAbcSegmentsOptions = {} & FetchAbcSegmentsOptionsApi;

type ActionTypes = {
	SET_DIRECTION: string;
	SET_SEGMENT_NAME: string;
	SET_COUNTERPARTY_ID: string;
	SET_DETALIZATION_OPTIONS: string;
	INVALIDATE_PL_OPERATIONS: string;
	RESTORE_DATA_VALIDATION: string;
	FETCH_ABC_SEGMENTS: string;
	FETCH_PL_OPERATIONS: string;
};

const types = createTypes<ActionTypes>(
	[
		'SET_DIRECTION',
		'SET_SEGMENT_NAME',
		'SET_COUNTERPARTY_ID',
		'SET_DETALIZATION_OPTIONS',
		'INVALIDATE_PL_OPERATIONS',
		'RESTORE_DATA_VALIDATION',
		'FETCH_ABC_SEGMENTS',
		'FETCH_PL_OPERATIONS',
	],
	'ANALYSIS',
);

const actions = {
	setDirection: (income: boolean) => createStaticAction(types.SET_DIRECTION)(income),
	setSegmentName: (name: string) => createStaticAction(types.SET_SEGMENT_NAME)(name),
	setCounterpartyID: (ID: number) => createStaticAction(types.SET_COUNTERPARTY_ID)(ID),
	setDetalizationOptions: (options: FetchOperationsOptions) =>
		createStaticAction(types.SET_DETALIZATION_OPTIONS)(options),
	invalidateOperations: () => createStaticAction(types.INVALIDATE_PL_OPERATIONS)(),
	restoreDataValidation: () => createStaticAction(types.RESTORE_DATA_VALIDATION)(),
	fetchPLOperations: createAsyncAction(types.FETCH_PL_OPERATIONS, (api, _, __, options: FetchOperationsOptions) => {
		return new Promise<Array<PLOperationBrief>>(resolve => {
			api.plPack.plOperation.fetchOperationsBriefs({ ...options, inConsolidation: true }).then(result => {
				resolve(result);
			});
		});
	}),
	fetchAbcSegments: createAsyncAction(types.FETCH_ABC_SEGMENTS, (api, _, __, options: FetchAbcSegmentsOptions) => {
		return new Promise<Array<ABCAnalysisSegment>>(resolve => {
			api.analysisPack.fetchAbcSegments(options).then(result => {
				resolve(result);
			});
		});
	}),
};

export const analysisActionsPack = {
	actions,
	types,
};
