import { createTypes } from '@flux';

type ActionTypes = {
	SET_DATE_RANGE: string;
	INVALIDATE_DASHBOARD_DATA: string;
	FETCH_DASHBOARD_DATA: string;
	FETCH_DYNAMIC_DATA: string;
	FETCH_CASHFLOW_FORECAST: string;
	FETCH_DETALIZATION_DATA: string;
	SET_DETALIZATION_OPTIONS: string;
	SET_FILTER_BY_COMPANIES: string;
	SET_FILTER_BY_FUNDS_REGISTERS: string;
	SET_FILTER_BY_BUSINESS_UNITS: string;
	SET_FILTER_BY_PROJECTS: string;
	SET_SETTINGS_MODE: string;
	CHANGE_GRID_LAYOUT: string;
	SAVE_GRID_LAYOUT: string;
	SET_TOUR_OPEN: string;
	SET_IS_CARD_EXPANDED: string;
	SET_BUSINESS_UNIT_ANALYTICS_FILTER: string;
	SET_PROJECT_ANALYTICS_FILTER: string;
	SET_CAHSFLOW_ITEM_ANALYTICS_FILTER: string;
	SET_COUNTERPARTY_ANALYTICS_FILTER: string;
	SET_LAST_SEARCH_PHRASES: string;
	FETCH_ANALYTICS_DETALIZATION_DATA: string;
	SET_ANALYTICS_DETALIZATION_OPTIONS: string;
	SET_ANALYTICS_DETALIZATION_IDS: string;
	SET_IS_ANALYTICS_DETALIZATION_OPEN: string;
	SET_IS_INCLUDES_VAT: string;
};

export const types = createTypes<ActionTypes>(
	[
		'SET_DATE_RANGE',
		'INVALIDATE_DASHBOARD_DATA',
		'FETCH_DASHBOARD_DATA',
		'FETCH_DYNAMIC_DATA',
		'FETCH_CASHFLOW_FORECAST',
		'FETCH_DETALIZATION_DATA',
		'SET_DETALIZATION_OPTIONS',
		'SET_FILTER_BY_COMPANIES',
		'SET_FILTER_BY_FUNDS_REGISTERS',
		'SET_FILTER_BY_BUSINESS_UNITS',
		'SET_FILTER_BY_PROJECTS',
		'SET_SETTINGS_MODE',
		'CHANGE_GRID_LAYOUT',
		'SAVE_GRID_LAYOUT',
		'SET_TOUR_OPEN',
		'SET_IS_CARD_EXPANDED',
		'SET_BUSINESS_UNIT_ANALYTICS_FILTER',
		'SET_PROJECT_ANALYTICS_FILTER',
		'SET_CAHSFLOW_ITEM_ANALYTICS_FILTER',
		'SET_COUNTERPARTY_ANALYTICS_FILTER',
		'SET_LAST_SEARCH_PHRASES',
		'FETCH_ANALYTICS_DETALIZATION_DATA',
		'SET_ANALYTICS_DETALIZATION_OPTIONS',
		'SET_ANALYTICS_DETALIZATION_IDS',
		'SET_IS_ANALYTICS_DETALIZATION_OPEN',
		'SET_IS_INCLUDES_VAT',
	],
	'BPL',
);
