import {
	EMIT_ERROR,
	CLOSE_ERROR_MESSAGE_VIEW
} from '../actions/error-message.action';
import { IStaticDataAction } from '../../../core/libs/action-creator';


interface IErrorMessage {
	title: string;
	message: string;
};

interface IErrorMessageState {
	errorMessageViewIsOpen: boolean;
	errors: Array<IErrorMessage>;
};

const initialState = {
	errorMessageViewIsOpen: false,
	errors: []
};

export default function(
	state: IErrorMessageState = initialState,
	action: IStaticDataAction) {
	switch (action.type) {
		case EMIT_ERROR: {
			const errorMessage = action.value;

			return {
				...state,
				errors: state.errors.concat(errorMessage),
				errorMessageViewIsOpen: true
			}
		}

		case CLOSE_ERROR_MESSAGE_VIEW: {
			return {
				...state,
				errorMessageViewIsOpen: false
			}
		}

		default:
			return state;
	}
}


export {
	IErrorMessage,
	IErrorMessageState
};
