import React, { useEffect, useRef, forwardRef } from 'react';

import { fundsApi } from '@core/api/funds';
import { useFormVariant } from '@core/hooks';
import { replaceMaskedValue } from '@utils/strings';
import { Box } from '@ui/box';
import { Typography } from '@ui/typography';
import { AutopickerCRUD, AutopickerCrudProps, AutopickerCrudRef } from '@ui/autopicker';
import { FormTextField, Form, FormRef } from '@ui/forms';
import { FormFundsRegisterTextField } from '@ui/funds-register-text-field';
import {
	FormFundsRegisterPurposeAutopicker,
	fundsRegisterPurposeAutopickerTransformer,
} from '@funds-registers/components/funds-register-purpose-autopicker';
import { FormBankAutopicker, bankAutopickerTransformer } from '@funds-registers/components/bank-autopicker';
import { FormCurrencyAutopicker, currencyAutopickerTransformer } from '@reference/components/currency-autopicker';
import {
	FormTenantLegalEntityAutopicker,
	tenantLegalEntityAutopickerTransformer,
} from '@tenant-legal-entities/components/tenant-legal-entity-autopicker';
import { Checkbox } from '@ui/checkbox';

export type FundsRegisterAutopickerCrudProps = {
	isFetching: boolean;
	variant: 'add' | 'edit';
	initialFormObject: FundsRegister;
	isBankAccount: boolean;
	disabled: boolean;
	withoutTypeChange?: boolean;
	onToggleIsBankAccount: () => void;
	onAddPopupOpen: () => void;
	onEditPopupOpen: () => void;
	onRequestAddFundsRegister: (fundsRegister: FundsRegister) => void;
	onRequestEditFundsRegister: (fundsRegister: FundsRegister) => void;
} & Pick<AutopickerCrudProps, 'isSmallContainer' | 'onRelatedPopupChange'>;

const FundsRegisterAutopickerCRUD: React.FC<FundsRegisterAutopickerCrudProps> = props => {
	const {
		isFetching,
		variant,
		initialFormObject,
		isBankAccount,
		disabled,
		isSmallContainer,
		withoutTypeChange,
		onToggleIsBankAccount,
		onAddPopupOpen,
		onEditPopupOpen,
		onRequestAddFundsRegister,
		onRequestEditFundsRegister,
		onRelatedPopupChange,
	} = props;
	const { isAdd, isEdit } = useFormVariant(variant);
	const formRef = useRef<FormRef<FundsRegister>>(null);
	const addCrudRef = useRef<AutopickerCrudRef>(null);
	const editCrudRef = useRef<AutopickerCrudRef>(null);

	useEffect(() => {
		if (!formRef.current || !initialFormObject) return;
		const formObject = formRef.current.getFormObject();

		if (formObject === initialFormObject) return;

		formRef.current.setFormObject(initialFormObject);
	}, [initialFormObject]);

	useEffect(() => {
		if (!isAdd || !formRef.current || isFetching) return;
		const formObject = formRef.current.getFormObject();
		const newFormObject: FundsRegister = isBankAccount
			? new fundsApi.package.BankAccountFundsRegister()
			: { ...new fundsApi.package.CashDeskFundsRegister(), CurrencyID: 1 };

		newFormObject.Name = formObject.Name;
		newFormObject.Purpose = formObject.Purpose;
		formRef.current.setFormObject(newFormObject);
	}, [isBankAccount]);

	const handleRequestSubmit = () => formRef.current?.submitForm();

	const handleSubmit = (formObject: FundsRegister) => {
		formObject.RegisterNumber = replaceMaskedValue(formObject.RegisterNumber);

		if (isAdd) {
			onRequestAddFundsRegister(formObject);
			addCrudRef.current.closePopup();
		} else if (isEdit) {
			onRequestEditFundsRegister(formObject);
			editCrudRef.current.closePopup();
		}
	};

	const renderContent = () => {
		return (
			<UpdateFundsRegisterForm
				isFetching={isFetching}
				formRef={formRef}
				variant={variant}
				initialFormObject={initialFormObject}
				isBankAccount={isBankAccount}
				withoutTypeChange={withoutTypeChange}
				onSubmit={handleSubmit}
				onToggleIsBankAccount={onToggleIsBankAccount}
			/>
		);
	};

	const renderMap: Record<FundsRegisterAutopickerCrudProps['variant'], () => React.ReactNode> = {
		add: () => {
			return (
				<AutopickerCRUD
					ref={addCrudRef}
					appearance='add'
					triggerLabel='Добавить счёт'
					isFetching={isFetching}
					disabled={disabled}
					isSmallContainer={isSmallContainer}
					onPopupOpen={onAddPopupOpen}
					onRelatedPopupChange={onRelatedPopupChange}
					onRequestAction={handleRequestSubmit}>
					{renderContent()}
				</AutopickerCRUD>
			);
		},
		edit: () => {
			return (
				<AutopickerCRUD
					ref={editCrudRef}
					appearance='edit'
					disabled={disabled}
					isFetching={isFetching}
					onPopupOpen={onEditPopupOpen}
					onRelatedPopupChange={onRelatedPopupChange}
					onRequestAction={handleRequestSubmit}>
					{renderContent()}
				</AutopickerCRUD>
			);
		},
	};

	return renderMap[variant] ? <>{renderMap[variant]()}</> : null;
};

type UpdateFundsRegisterFormProps = {
	formRef: React.MutableRefObject<FormRef<FundsRegister>>;
	onSubmit: (formObject: FundsRegister) => void;
} & Pick<
	FundsRegisterAutopickerCrudProps,
	'isFetching' | 'variant' | 'initialFormObject' | 'isBankAccount' | 'withoutTypeChange' | 'onToggleIsBankAccount'
>;

const UpdateFundsRegisterForm: React.FC<UpdateFundsRegisterFormProps> = forwardRef((props, ref) => {
	const {
		isFetching,
		variant,
		formRef,
		isBankAccount,
		initialFormObject,
		withoutTypeChange,
		onSubmit,
		onToggleIsBankAccount,
	} = props;
	const { isAdd, isEdit } = useFormVariant(variant);
	const title = isAdd ? 'Быстрое добавление нового счёта' : 'Быстрое изменение счёта';
	const nameHintText = isBankAccount ? 'Например, «Счёт в банке»' : 'Например, «Моя касса»';

	if (isFetching) return <Box minHeight={HEIGHT} />;

	return (
		<Form ref={formRef as any} formObject={initialFormObject} fullWidth onSubmit={onSubmit}>
			<Box minHeight={HEIGHT}>
				<Box marginBottom={16}>
					<Typography.Text fontWeight={500} fontSize={16}>
						{title}
					</Typography.Text>
				</Box>
				<Checkbox
					checked={isBankAccount}
					label='Это банковский счёт'
					disabled={isEdit || withoutTypeChange}
					onCheck={onToggleIsBankAccount}
				/>
				<FormTextField
					name='Name'
					hintText={nameHintText}
					labelText='Наименование счёта'
					autoFocus
					withClearBtn
					required
					fullWidth
				/>
				<FormFundsRegisterTextField
					name='RegisterNumber'
					appearance={isBankAccount ? 'bank-account' : 'virtual-account'}
					labelText='Номер счёта'
					disabled={isEdit && isBankAccount}
					enableOnBlurValidation
					withClearBtn
					required
					fullWidth
					getCurrencyID={getCurrencyID}
					setCurrency={setCurrency}
				/>
				<FormCurrencyAutopicker
					name='CurrencyID'
					labelText='Валюта счёта'
					hintText={isBankAccount ? 'Определяется автоматически' : 'Например, «Российский рубль»'}
					disabled={isBankAccount}
					required
					fullWidth
					{...currencyAutopickerTransformer.ID.single}
				/>
				{isBankAccount && (
					<FormBankAutopicker name='Bank' required fullWidth {...bankAutopickerTransformer.bank.single} />
				)}
				<FormFundsRegisterPurposeAutopicker
					name='Purpose'
					fullWidth
					required
					{...fundsRegisterPurposeAutopickerTransformer.fundsRegisterPurpose.single}
				/>
				<FormTenantLegalEntityAutopicker
					name='LegalEntity'
					disableAdd
					disableEdit
					fullWidth
					{...tenantLegalEntityAutopickerTransformer.counterpartyBrief.single}
				/>
			</Box>
		</Form>
	);
});

const getCurrencyID = (fundsRegister: FundsRegister) => fundsRegister.CurrencyID;
const setCurrency = (fundsRegister: FundsRegister, currency: Currency) =>
	(fundsRegister.CurrencyID = currency?.ID || -1);

const HEIGHT = 500;

export { FundsRegisterAutopickerCRUD };
