import * as moment from 'moment';

import createAction, { createStaticDataAction } from '../../../core/libs/action-creator';
import { BASE_DATE_FORMAT } from '../../shared/constants/time';
import * as planningSelectors from '../selectors/planning.selectors';
import { ReportTableRow } from '@analytics/components/reporting/shared/report-table';

const SET_PLANNING_FILTER_BY_TEXT = '[PL]: SET_PLANNING_FILTER_BY_TEXT';
const CLICK_ON_PLANNING_TABLE_CELL = '[PL]: CLICK_ON_PLANNING_TABLE_CELL';
const CLICK_ON_PLANNING_CHART_POINT = '[PL]: CLICK_ON_PLANNING_CHART_POINT';
const FETCH_CASHFLOW_FORECAST_FOR_FORECAST_KPI = '[PL]: FETCH_CASHFLOW_FORECAST_FOR_FORECAST_KPI';
const FETCH_CASHFLOW_FORECAST_FOR_CASH_GAP_KPI = '[PL]: FETCH_CASHFLOW_FORECAST_FOR_CASH_GAP_KPI';
const SET_CASHFLOW_FORECAST_KPI_DATE = '[PL]: SET_CASHFLOW_FORECAST_KPI_DATE';

function setPlanningFilterByTextAction(term: string) {
	return createStaticDataAction(SET_PLANNING_FILTER_BY_TEXT)(term);
}

function clickOnPlanningTableCellAction(row: ReportTableRow, col: number) {
	return createStaticDataAction(CLICK_ON_PLANNING_TABLE_CELL)({ row, col });
}

function clickOnPlanningChartPointAction(date: string) {
	return createStaticDataAction(CLICK_ON_PLANNING_CHART_POINT)(date);
}

const fetchPLCashflowForecastForForecastKPIAction = createAction(
	FETCH_CASHFLOW_FORECAST_FOR_FORECAST_KPI,
	(api, _, __, dateRange: DateRange) => {
		const prevMonthDateStartMoment = moment().endOf('month').subtract(1, 'month').startOf('month');
		const prevMonthDateEndMoment = prevMonthDateStartMoment.clone().endOf('month');
		const dateRangeDynamic: DateRange = {
			dateStart: prevMonthDateStartMoment.format(BASE_DATE_FORMAT),
			dateEnd: prevMonthDateEndMoment.format(BASE_DATE_FORMAT),
		};

		return new Promise(resolve => {
			Promise.all([
				api.plPack.plOperation.fetchAccountChartDynamics({ dateRange: dateRangeDynamic }),
				api.fundsPack.fundsRegister.fetchCashflowForecast({ dateRange }),
			]).then(result => {
				resolve({
					plOperationDynamics: result[0],
					cfForecast: result[1],
				});
			});
		});
	},
	{
		isValidSelector: state =>
			planningSelectors.selectCashflowForecastForForecastKPIIsLoaded(state) &&
			!planningSelectors.selectCashflowForecastForForecastKPIDidInvalidate(state),
		isFetchingSelector: planningSelectors.selectCashflowForecastForForecastKPIIsFetching,
	},
);

const fetchPLCashflowForecastForCashGapKPIAction = createAction(
	FETCH_CASHFLOW_FORECAST_FOR_CASH_GAP_KPI,
	(api, _, __, dateRange: DateRange) => {
		return api.fundsPack.fundsRegister.fetchCashflowForecast({ dateRange });
	},
	{
		isValidSelector: state =>
			planningSelectors.selectCashflowForecastForCashGapKPIIsLoaded(state) &&
			!planningSelectors.selectCashflowForecastForCashGapKPIDidInvalidate(state),
		isFetchingSelector: planningSelectors.selectCashflowForecastForCashGapKPIIsFetching,
	},
);

function setCashflowForecastKPIDateAction(date: string) {
	return createStaticDataAction(SET_CASHFLOW_FORECAST_KPI_DATE)(date);
}

export const constants = {
	SET_PLANNING_FILTER_BY_TEXT,
	CLICK_ON_PLANNING_TABLE_CELL,
	CLICK_ON_PLANNING_CHART_POINT,
	FETCH_CASHFLOW_FORECAST_FOR_FORECAST_KPI,
	FETCH_CASHFLOW_FORECAST_FOR_CASH_GAP_KPI,
	SET_CASHFLOW_FORECAST_KPI_DATE,
};

export const actions = {
	setPlanningFilterByTextAction,
	clickOnPlanningTableCellAction,
	clickOnPlanningChartPointAction,
	fetchPLCashflowForecastForForecastKPIAction,
	fetchPLCashflowForecastForCashGapKPIAction,
	setCashflowForecastKPIDateAction,
};
