import React from 'react';

import { getFundsRegistersAccessFlags } from '@utils/finance';
import { fade } from '@utils/color';
import { Box } from '@ui/box';
import { Typography } from '@ui/typography';
import { Protector } from '@ui/protector';
import { FetchingPlaceholder } from '@ui/fetching-placeholder';
import { CurrencyFormatter } from '@ui/currency-formatter';
import { AccountsMenu } from '@funds-registers/components/accounts-menu';
import { SyncMarker } from '@funds-registers/components/sync-marker';
import { RefreshIcon } from '@ui/icons/refresh';
import { EyeIcon } from '@ui/icons/eye';
import { EyeSlashIcon } from '@ui/icons/eye-slash';
import { IconButton } from '@ui/icon-button';
import { Root, ContentLayout, AnimatedLayout } from './styled';

export type XBalanceTotalIndicatorProps = {
	isOpen: boolean;
	isFetching: boolean;
	isAnyInProgress: boolean;
	balance: number;
	isBalanceHidden: boolean;
	fundsRegisters: Array<FundsRegister>;
	fundsRegisterStatistics: Array<FundsRegisterStatistics>;
	accountStatementImportFailed: AccountStatementImport;
	setFundsRegisterID: (ID: number) => void;
	setFundsRegisterIDForRefresh: (ID: number) => void;
	setFundsRegisterIDForAutoInsert: (ID: number) => void;
	onRequestToggleBalanceVisibility: () => void;
	onRequestRefresh: () => void;
	onRequestToggleOpen: () => void;
	onRequestClose: () => void;
};

const XBalanceTotalIndicator: React.FC<XBalanceTotalIndicatorProps> = props => {
	const {
		isOpen,
		isFetching,
		isAnyInProgress,
		balance,
		isBalanceHidden,
		fundsRegisters,
		fundsRegisterStatistics,
		accountStatementImportFailed,
		setFundsRegisterID,
		setFundsRegisterIDForRefresh,
		setFundsRegisterIDForAutoInsert,
		onRequestToggleBalanceVisibility,
		onRequestRefresh,
		onRequestToggleOpen,
		onRequestClose,
	} = props;
	const { hasReadAccessToAllDirections } = getFundsRegistersAccessFlags(fundsRegisters);
	const hasAccess = hasReadAccessToAllDirections;

	return (
		<Root isOpen={isOpen}>
			<ContentLayout onClick={onRequestToggleOpen}>
				<Box flex='1 1 auto'>
					<Typography.Text component='div' fontSize={12} marginBottom={6}>
						Остатки на счетах
					</Typography.Text>
					<Protector
						isHidden={!hasAccess || isBalanceHidden}
						fallback={<Box height={21} width={130} backgroundColor={backgroundColor} />}>
						<FetchingPlaceholder isFetching={isFetching} maxWidth={130} gradientColor={gradientColor}>
							<CurrencyFormatter.Base fontSize={18} needFraction={false}>
								{balance}
							</CurrencyFormatter.Base>
						</FetchingPlaceholder>
					</Protector>
				</Box>
				<Box position='absolute' top={0} left={0}>
					<IconButton
						title={!isAnyInProgress ? 'Обновить' : ''}
						variant='rounded'
						isSilentDisabled={isAnyInProgress}
						stopPropagation
						onClick={onRequestRefresh}>
						<span>
							<AnimatedLayout isAnimated={isFetching || isAnyInProgress}>
								<RefreshIcon color='light' size={22} />
							</AnimatedLayout>
						</span>
					</IconButton>
				</Box>
				<Box position='absolute' bottom={0} left={0}>
					<IconButton
						title={isBalanceHidden ? 'Показать баланс' : 'Скрыть баланс'}
						variant='rounded'
						stopPropagation
						onClick={onRequestToggleBalanceVisibility}>
						<span>
							{isBalanceHidden ? <EyeIcon color='light' size={20} /> : <EyeSlashIcon color='light' size={20} />}
						</span>
					</IconButton>
				</Box>
				{accountStatementImportFailed && (
					<Box position='absolute' bottom={8} right={8}>
						<SyncMarker
							fundsRegisterID={accountStatementImportFailed.FundsRegisterID}
							connectedToCMS
							needSyncButtonIfFailed={false}
						/>
					</Box>
				)}
			</ContentLayout>
			<AccountsMenu
				isOpen={isOpen}
				fundsRegisterStatistics={fundsRegisterStatistics}
				setFundsRegisterID={setFundsRegisterID}
				setFundsRegisterIDForRefresh={setFundsRegisterIDForRefresh}
				setFundsRegisterIDForAutoInsert={setFundsRegisterIDForAutoInsert}
				onClose={onRequestClose}
			/>
		</Root>
	);
};

const backgroundColor = fade('#fff', 0.4);

const gradientColor = {
	start: fade('#fff', 0.2),
	stop: fade('#fff', 0.4),
};

export { XBalanceTotalIndicator };
