import React, { memo, useState, useMemo } from 'react';

import { fundsApi } from '@core/api/funds';
import { counterpartyApi } from '@core/api/counterparty';
import { useMounted } from '@core/hooks/use-mounted';
import { detectIsBankAccountFundsRegister } from '@utils/finance';
import { useMapState, useMapDispatch } from '@flux';
import { useFormContext } from '@ui/forms';
import { runFundsRegisterStatisticsInvalidationEffect } from '@funds-registers/actions/invalidators';
import { emitActionMessage as emitActionMessageAction } from '@shared/actions/action-message.actions';
import { securitySelectorsPack } from '@platform/selectors';
import {
	FundsRegisterAutopickerCRUD as XFundsRegisterAutopickerCRUD,
	FundsRegisterAutopickerCrudProps as XFundsRegisterAutopickerCrudProps,
} from './funds-register-autopicker-crud.view';

export type FundsRegisterAutopickerCrudProps = {
	fundsRegisterID?: number;
	fundsRegisterName?: string;
	formName?: string;
	searchText?: string;
	transformer?: (value: FundsRegister) => any;
	isBankAccount?: boolean;
	legalEntityID?: number;
} & Pick<
	XFundsRegisterAutopickerCrudProps,
	'variant' | 'isSmallContainer' | 'withoutTypeChange' | 'onRelatedPopupChange'
> &
	Partial<Pick<XFundsRegisterAutopickerCrudProps, 'disabled'>>;

const FundsRegisterAutopickerCRUD: React.FC<FundsRegisterAutopickerCrudProps> = memo(props => {
	const {
		variant,
		formName,
		searchText,
		fundsRegisterID,
		fundsRegisterName,
		transformer,
		disabled,
		isBankAccount: isBankAccountFromProps,
		legalEntityID,
		...rest
	} = props;
	const { mounted } = useMounted();
	const formContext = useFormContext<unknown>();
	const [isFetching, setIsFetching] = useState(false);
	const [isBankAccount, setIsBankAccount] = useState(isBankAccountFromProps);
	const [initialFormObject, setInitialFormObject] = useState<FundsRegister>(null);
	const [canChangeAnyData] = useMapState([securitySelectorsPack.selectCanChangeAnyData]);
	const [invalidate, emitActionMessage] = useMapDispatch([
		runFundsRegisterStatisticsInvalidationEffect,
		emitActionMessageAction,
	]);
	const scope = useMemo(() => ({ isAdd: true }), []);

	const handleToggleIsBankAccount = () => setIsBankAccount(x => !x);

	const handleAddPopupOpen = () => {
		scope.isAdd = true;
		setIsBankAccount(isBankAccountFromProps);
		setInitialFormObject(
			createInitialFormObject({
				name: searchText,
				isBankAccount: isBankAccountFromProps,
				legalEntityID,
			}),
		);
		setIsFetching(false);
	};

	const handleEditPopupOpen = async () => {
		scope.isAdd = false;

		if (fundsRegisterID < 0) return;
		setIsFetching(true);

		const fundsRegister = await fundsApi.fundsRegister.fetchFundsRegisterByID(fundsRegisterID);
		const isBankAccount = detectIsBankAccountFundsRegister(fundsRegister);

		if (!fundsRegister.Purpose || fundsRegister?.Purpose?.ID < 0) {
			fundsRegister.Purpose = null;
		}

		if (!mounted() || scope.isAdd) return;
		setInitialFormObject(fundsRegister);
		setIsBankAccount(isBankAccount);
		setIsFetching(false);
	};

	const injectToParentForm = (fundsRegister: FundsRegister) => {
		const parentFormObject = formContext?.formObject;

		if (formName && parentFormObject) {
			parentFormObject[formName] = fundsRegister ? transformer(fundsRegister) : null;
			formContext.handleObjectChange(parentFormObject);
		}
	};

	const handleRequestAddFundsRegister = async (fundsRegister: FundsRegister) => {
		const addedFundsRegister = await fundsApi.fundsRegister.addFundsRegister(fundsRegister);

		invalidate();
		emitActionMessage('Счёт добавлен 🙃', 'success');
		injectToParentForm(addedFundsRegister);
	};

	const handleRequestEditFundsRegister = async (fundsRegister: FundsRegister) => {
		const updatedFundsRegister = await fundsApi.fundsRegister.updateFundsRegister(fundsRegister);

		invalidate();
		emitActionMessage('Счёт изменён 😊', 'success');
		injectToParentForm(updatedFundsRegister);
	};

	return (
		<XFundsRegisterAutopickerCRUD
			{...rest}
			isFetching={isFetching}
			variant={variant}
			disabled={disabled || !canChangeAnyData}
			initialFormObject={initialFormObject}
			isBankAccount={isBankAccount}
			onToggleIsBankAccount={handleToggleIsBankAccount}
			onAddPopupOpen={handleAddPopupOpen}
			onEditPopupOpen={handleEditPopupOpen}
			onRequestAddFundsRegister={handleRequestAddFundsRegister}
			onRequestEditFundsRegister={handleRequestEditFundsRegister}
		/>
	);
});

FundsRegisterAutopickerCRUD.defaultProps = {
	transformer: x => x,
	isBankAccount: false,
	legalEntityID: -1,
};

type CreateInitialFormObjectOptions = {
	isBankAccount: boolean;
	name?: string;
	legalEntityID?: number;
};

function createInitialFormObject(options: CreateInitialFormObjectOptions) {
	const { name = '', isBankAccount, legalEntityID = -1 } = options;
	const fundsRegister: FundsRegister = isBankAccount
		? {
				...new fundsApi.package.BankAccountFundsRegister(),
				Name: name,
				LegalEntity: { ...new counterpartyApi.package.CounterpartyBrief(), ID: legalEntityID },
		  }
		: {
				...new fundsApi.package.CashDeskFundsRegister(),
				Name: name,
				CurrencyID: 1,
				LegalEntity: { ...new counterpartyApi.package.CounterpartyBrief(), ID: legalEntityID },
		  };

	return fundsRegister;
}

export { FundsRegisterAutopickerCRUD };
