import React from 'react';

import { Box } from '@ui/box';
import { Ripple } from '@ui/ripple';
import { Root, Segment, SegmentLayout, SegmentedItemRoot, SegmentedItemContent, ErrorText } from './styled';

export type SegmentedListProps<T extends Object = any> = {
	value: T | Array<T>;
	dataSourceConfig?: {
		value: string;
		text: string;
	};
	dataSource: Array<T>;
	errorText?: React.ReactNode;
	readonly?: boolean;
	disabled?: boolean;
	onChange?: (e: React.SyntheticEvent<{}>, value: T | Array<T>) => void;
	renderSegment?: (options: RenderSegmentOptions<T>) => React.ReactNode;
};

export type RenderSegmentOptions<T extends Object = any> = {
	item: T;
	key: string | number;
	isActive: boolean;
	readonly: boolean;
	disabled: boolean;
	onClick: SegmentedListProps['onChange'];
};

class SegmentedList<T> extends React.PureComponent<SegmentedListProps<T>> {
	static displayName = 'SegmentedList';
	static defaultProps: Partial<SegmentedListProps> = {
		dataSourceConfig: {
			value: 'value',
			text: 'text',
		},
	};

	getID = (item: T) => (typeof item === 'object' ? item[this.props.dataSourceConfig.value] : item);

	getText = (item: T) => item[this.props.dataSourceConfig.text];

	handleClick = (item: T) => (e: React.SyntheticEvent<{}>) => {
		const { value, readonly, disabled, onChange } = this.props;
		if (readonly || disabled) return;
		const isList = Array.isArray(value);
		const transformedValue = transformValue(value);
		const valueIDs = transformedValue.map(x => this.getID(x));
		const ID = this.getID(item);
		const hasSameValue = valueIDs.includes(ID);
		let list = [];

		if (hasSameValue) {
			list = transformedValue.filter(x => this.getID(x) !== ID);
		} else {
			list = [...transformedValue, item];
		}

		onChange(e, isList ? list : item);
	};

	renderSegment = (options: RenderSegmentOptions<T>) => {
		const { item, key, isActive, readonly, disabled } = options;

		return (
			<SegmentLayout key={key}>
				<Segment isActive={isActive} readonly={readonly} disabled={disabled} onClick={this.handleClick(item)}>
					{this.getText(item)}
				</Segment>
			</SegmentLayout>
		);
	};

	render() {
		const { value, dataSource, errorText, readonly, disabled, renderSegment, onChange } = this.props;
		const transformedValue = transformValue(value);
		const valueIDs = transformedValue.map(x => this.getID(x));

		return (
			<Root>
				{dataSource.map(x => {
					const ID = this.getID(x);
					const isActive = valueIDs.includes(ID);

					return typeof renderSegment === 'function'
						? renderSegment({
								item: x,
								key: this.getID(x),
								isActive,
								readonly,
								disabled,
								onClick: onChange,
						  })
						: this.renderSegment({
								item: x,
								key: this.getID(x),
								isActive,
								readonly,
								disabled,
								onClick: () => {},
						  });
				})}
				{errorText && (
					<Box marginTop={2}>
						<ErrorText>{errorText}</ErrorText>
					</Box>
				)}
			</Root>
		);
	}
}

function transformValue<T>(value: T | Array<T>): Array<T> {
	return Array.isArray(value) ? value : [value];
}

export type SegmentedItemProps = {
	isActive: boolean;
	multiple?: boolean;
} & React.AllHTMLAttributes<HTMLButtonElement>;

const SegmentedItem: React.FC<SegmentedItemProps> = props => {
	const { isActive, multiple, disabled, children, onClick } = props;
	const hasHandler = disabled ? false : !isActive || multiple;

	return (
		<SegmentedItemRoot
			isActive={isActive}
			disabled={disabled}
			multiple={multiple}
			onClick={hasHandler ? onClick : undefined}>
			<Ripple skip={isActive || disabled} color='rgba(0, 0, 0, 0.4)'>
				<SegmentedItemContent>{children}</SegmentedItemContent>
			</Ripple>
		</SegmentedItemRoot>
	);
};

export { SegmentedList, SegmentedItem };
