import { FetchOperationsOptions } from '@core/api/pl';
import { ComplexFilter } from '@core/api/core';
import { createDefaultPeriod } from '@utils/date';
import {
	StoreAsyncItem,
	checkAsyncAction,
	createAsyncInitialState,
	invalidateStateFromAction,
	createReducer,
	restoreValidationState,
} from '@flux';
import { ReportCellCoords } from '@standard-reports/models';
import { types } from '../actions/types';

export type MainCashflowReportState = {
	dateRange: DateRange;
	report: StoreAsyncItem<ReportResponse>;
	detailsOperations: StoreAsyncItem<Array<PLOperationBrief>>;
	tenantEntitiesFilter: ComplexFilter;
	businessUnitsFilter: ComplexFilter;
	projectsFilter: ComplexFilter;
	cashflowItemsFilter: ComplexFilter;
	fundsRegistersFilter: ComplexFilter;
	searchText: string;
	expandedHierarchyMap: Record<string, boolean>;
	expandedQuartersMap: Record<string, boolean>;
	coords: ReportCellCoords;
	detailsOptions: FetchOperationsOptions | null;
	detailsTitle: string | null;
	isIncludesVAT: boolean;
	isIncludesPlan: boolean;
	isHeaderCollapsed: boolean;
};

const initialState: MainCashflowReportState = {
	dateRange: createDefaultPeriod('year'),
	report: createAsyncInitialState(null),
	detailsOperations: createAsyncInitialState([]),
	tenantEntitiesFilter: new ComplexFilter(),
	businessUnitsFilter: new ComplexFilter(),
	projectsFilter: new ComplexFilter(),
	cashflowItemsFilter: new ComplexFilter(),
	fundsRegistersFilter: new ComplexFilter(),
	searchText: '',
	expandedHierarchyMap: {},
	expandedQuartersMap: {},
	coords: null,
	detailsOptions: null,
	detailsTitle: null,
	isIncludesVAT: true,
	isIncludesPlan: false,
	isHeaderCollapsed: true,
};

const mainCashflowReportReducer = createReducer<MainCashflowReportState>(initialState, {
	[types.INVALIDATE_MODULE_DATA]: (action: StaticAction, state) => {
		return {
			report: invalidateStateFromAction(action, state.report),
			detailsOperations: invalidateStateFromAction(action, state.detailsOperations),
		};
	},
	[types.FETCH_CASHFLOW_REPORT]: (action: AsyncAction<Array<ReportItem>>, state) => {
		return {
			report: checkAsyncAction(action, state.report),
		};
	},
	[types.SET_DATE_RANGE]: (action: StaticAction<DateRange>, state) => {
		return {
			dateRange: action.value,
			report: invalidateStateFromAction(action, state.report),
			detailsOperations: invalidateStateFromAction(action, state.detailsOperations),
			coords: null,
			detailsOptions: null,
			detailsTitle: null,
			expandedQuartersMap: {},
		};
	},
	[types.SET_SEARCH_TEXT]: (action: StaticAction<string>) => {
		return {
			searchText: action.value,
			expandedHierarchyMap: {},
		};
	},
	[types.SET_SELECTED_COORDS]: (action: StaticAction<ReportCellCoords>) => {
		return {
			coords: action.value,
		};
	},
	[types.SET_TENANT_ENTITIES_FILTER]: (action: StaticAction<ComplexFilter>, state) => {
		return {
			tenantEntitiesFilter: action.value,
			fundsRegistersFilter: new ComplexFilter(),
			report: invalidateStateFromAction(action, state.report),
			coords: null,
			detailsTitle: null,
			detailsOptions: null,
			detailsOperations: createAsyncInitialState([]),
		};
	},
	[types.SET_BUSINESS_UNITS_FILTER]: (action: StaticAction<ComplexFilter>, state) => {
		return {
			businessUnitsFilter: action.value,
			report: invalidateStateFromAction(action, state.report),
			coords: null,
			detailsTitle: null,
			detailsOptions: null,
			detailsOperations: createAsyncInitialState([]),
		};
	},
	[types.SET_PROJECTS_FILTER]: (action: StaticAction<ComplexFilter>, state) => {
		return {
			projectsFilter: action.value,
			report: invalidateStateFromAction(action, state.report),
			coords: null,
			detailsTitle: null,
			detailsOptions: null,
			detailsOperations: createAsyncInitialState([]),
		};
	},
	[types.SET_CASHFLOW_ITEMS_FILTER]: (action: StaticAction<ComplexFilter>, state) => {
		return {
			cashflowItemsFilter: action.value,
			report: invalidateStateFromAction(action, state.report),
			coords: null,
			detailsTitle: null,
			detailsOptions: null,
			detailsOperations: createAsyncInitialState([]),
		};
	},
	[types.SET_FUNDS_REGISTERS_FILTER]: (action: StaticAction<ComplexFilter>, state) => {
		return {
			fundsRegistersFilter: action.value,
			report: invalidateStateFromAction(action, state.report),
			coords: null,
			detailsTitle: null,
			detailsOptions: null,
			detailsOperations: createAsyncInitialState([]),
		};
	},
	[types.SET_OPERATION_DETAILS_OPTIONS]: (action: StaticAction<FetchOperationsOptions | null>, state) => {
		return {
			detailsOptions: action.value,
			detailsTitle: action.value ? state.detailsTitle : null,
			coords: action.value ? state.coords : null,
			detailsOperations: action.value
				? invalidateStateFromAction(action, state.detailsOperations)
				: createAsyncInitialState([]),
		};
	},
	[types.SET_OPERATION_DETAILS_TITLE]: (action: StaticAction<string | null>) => {
		return {
			detailsTitle: action.value,
		};
	},
	[types.RESTORE_OPERATION_DETAILS_VALIDATION]: (_, state) => {
		return {
			detailsOperations: restoreValidationState(state.detailsOperations),
		};
	},
	[types.FETCH_DETAILS_OPERATIONS]: (action: AsyncAction<Array<PLOperationBrief>>, state) => {
		return {
			detailsOperations: checkAsyncAction(action, state.detailsOperations),
		};
	},
	[types.SET_EXPANDED_HIERARCHY_MAP]: (action: StaticAction<Record<string, boolean>>) => {
		return {
			expandedHierarchyMap: { ...action.value },
		};
	},
	[types.SET_EXPANDED_QUARTERS_MAP]: (action: StaticAction<Record<string, boolean>>) => {
		return {
			expandedQuartersMap: { ...action.value },
		};
	},
	[types.SET_IS_INCLUDES_VAT]: (action: StaticAction<boolean>, state) => {
		return {
			isIncludesVAT: action.value,
			report: invalidateStateFromAction(action, state.report),
			detailsOptions: state.detailsOptions ? { ...state.detailsOptions, excludingVat: !action.value } : null,
			detailsOperations: state.detailsOptions
				? invalidateStateFromAction(action, state.detailsOperations)
				: createAsyncInitialState([]),
		};
	},
	[types.SET_IS_INCLUDES_PLAN]: (action: StaticAction<boolean>, state) => {
		return {
			isIncludesPlan: action.value,
			report: invalidateStateFromAction(action, state.report),
			detailsOptions: state.detailsOptions ? { ...state.detailsOptions, takeBy: action.value ? 'all' : 'fact' } : null,
			detailsOperations: state.detailsOptions
				? invalidateStateFromAction(action, state.detailsOperations)
				: createAsyncInitialState([]),
		};
	},
	[types.SET_IS_HEADER_COLLAPSED]: (action: StaticAction<boolean>) => {
		return {
			isHeaderCollapsed: action.value,
		};
	},
});

export { mainCashflowReportReducer };
