import React from 'react';

import { TextFieldGlobalStyle } from '@ui/input-field';

export type AppGlobalStyleProps = {};

const AppGlobalStyle: React.FC<AppGlobalStyleProps> = props => {
	return (
		<>
			<TextFieldGlobalStyle />
		</>
	);
};

export { AppGlobalStyle };
