export enum CashflowTypeCode {
	OPERATIONAL = 'operational',
	FINANCIAL = 'financial',
	INVESTMENT = 'investment',
}

export const cashflowTypeCodesByIDsMap = {
	1: CashflowTypeCode.OPERATIONAL,
	2: CashflowTypeCode.FINANCIAL,
	3: CashflowTypeCode.INVESTMENT,
};
