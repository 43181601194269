export enum PlanningViewModeCode {
	LIST = 'LIST',
	TABLE = 'TABLE',
}

export enum PlanningGranulationModeCode {
	DAYS = 'DAYS',
	WEEKS = 'WEEKS',
	MONTHS = 'MONTHS',
	YEARS = 'YEARS',
}

export type PlanningVirtualTableRow = {
	ID: number;
	name: string;
	parentID: number;
	childItemIDs: Array<number>;
	cashflowIDs: Array<number>;
	isIncome: boolean | null;
	isSynthetic: boolean;
	isIncomingBalance: boolean;
	isCashflow: boolean;
	columns: Array<PlanningVirtualTableColumn>;
};

export type PlanningVirtualTableColumn = {
	date: string;
	label: string;
	plan: number | null;
	fact: number | null;
	isPast: boolean;
	isPresent: boolean;
	planPrefix: string;
	factPrefix: string;
};

export type PlanningChartPoint = {
	x: number;
	y: number;
	date: string;
	value: number;
	selected: boolean;
	isPlan: boolean;
};

export type PlanningChartData = {
	fact: Array<PlanningChartPoint>;
	plan: Array<PlanningChartPoint>;
};

export type CashGapPoint = {
	date: string;
	value: number;
};

export type CellCoords = {
	ID: number;
	rowIdx: number;
	columnIdx: number;
};

export const periodFormattersMap = {
	[PlanningGranulationModeCode.DAYS]: 'day',
	[PlanningGranulationModeCode.MONTHS]: 'month',
};

export const dateFormattersMap = {
	[PlanningGranulationModeCode.DAYS]: 'DD.MM.YYYY',
	[PlanningGranulationModeCode.MONTHS]: 'MMMM YYYY',
};
