import styled from 'styled-components';

const Root = styled.div`
	position: relative;
	width: 100%;
`;

const GroupHeaderLayout = styled.div``;

export { Root, GroupHeaderLayout };
