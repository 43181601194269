import { createTypes } from '@flux';

type ActionTypes = {
	FETCH_TERMINALS: string;
	FETCH_TRANSACTIONS: string;
	FETCH_TURNOVERS: string;
	IMPORT_TERMINALS: string;
	IMPORT_TRANSACTIONS: string;
	RESET_FILTERS: string;
	SET_DATE_RANGE: string;
	SET_FILTER_BY_OPERATION_TYPES: string;
	SET_FILTER_BY_TERMINALS: string;
	SET_FILTER_BY_TEXT: string;
};

const types = createTypes<ActionTypes>(
	[
		'FETCH_TERMINALS',
		'FETCH_TRANSACTIONS',
		'FETCH_TURNOVERS',
		'IMPORT_TERMINALS',
		'IMPORT_TRANSACTIONS',
		'RESET_FILTERS',
		'SET_DATE_RANGE',
		'SET_FILTER_BY_OPERATION_TYPES',
		'SET_FILTER_BY_TERMINALS',
		'SET_FILTER_BY_TEXT',
	],
	'TRANSACTIONS',
);

export { types };
