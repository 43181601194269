import React from 'react';

import { DrawerConfirmDialog } from '@ui/drawer';

export type RemoveStandardDataFormProps = {
	onRemove: () => void;
	onClose: () => void;
};

class RemoveStandardDataForm extends React.PureComponent<RemoveStandardDataFormProps> {
	static displayName = 'RemoveDemoDataForm';

	render() {
		return (
			<DrawerConfirmDialog
				title='Вы точно хотите удалить демо-данные?'
				actionLabel='Удалить'
				onAction={this.props.onRemove}
				onClose={this.props.onClose}
			/>
		);
	}
}

export default RemoveStandardDataForm;
