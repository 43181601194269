import { createAsyncAction } from '@flux';
import { mainReferenceSelectorsPack } from '@reference/selectors';
import { mainTypes as types } from './types';

const actions = {
	fetchLegalEntityTaxModes: createAsyncAction(
		types.FETCH_LEGAL_ENTITY_TAX_MODES,
		api => api.counterpartyPack.counterparty.fetchLegalEntityTaxModes(),
		{
			isValidSelector: mainReferenceSelectorsPack.selectAsyncLegalEntityTaxModes.selectIsValid,
			isFetchingSelector: mainReferenceSelectorsPack.selectAsyncLegalEntityTaxModes.selectIsFetching,
		},
	) as () => void,
	fetchCurrencies: createAsyncAction(types.FETCH_CURRENCIES, api => api.referencePack.currency.fetchCurrencies(), {
		isValidSelector: mainReferenceSelectorsPack.selectAsyncCurrencies.selectIsValid,
		isFetchingSelector: mainReferenceSelectorsPack.selectAsyncCurrencies.selectIsFetching,
	}) as () => void,
	fetchCountries: createAsyncAction(types.FETCH_COUNTRIES, api => api.referencePack.country.fetchCountries(), {
		isValidSelector: mainReferenceSelectorsPack.selectAsyncCountries.selectIsValid,
		isFetchingSelector: mainReferenceSelectorsPack.selectAsyncCountries.selectIsFetching,
	}) as () => void,
};

export const mainReferenceActionsPack = actions;
