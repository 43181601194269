import * as _ from 'underscore';

import {
	StoreAsyncItem,
	createReducer,
	createAsyncInitialState,
	checkAsyncAction,
	invalidateStateFromAction,
} from '@flux';
import { createDefaultPeriod } from '@utils/date';
import { mainReportingActionsPack, FetchReportingDataReturnType, DetalizationOptions } from '@analytics/actions';

export interface IReportingState {
	dateRange: DateRange;
	operations: StoreAsyncItem<Array<PLOperationBrief>>;
	aciSeries: StoreAsyncItem<Record<string, PLOperationAccountsChartItemSeries>>;
	cashBalanceRecords: StoreAsyncItem<Array<CashBalanceRecord>>;
	detalizationOptions: DetalizationOptions | null;
	legalEntityFilter: CounterpartyBrief;
	projectFilter: number | null;
	textFilter: string;
	selectedCell: {
		row: any;
		col: number;
	};
	isExpandedCfReport: boolean;
	isExpandedPlReport: boolean;
	isIncludesVAT: boolean;
}

const initialState: IReportingState = {
	dateRange: createDefaultPeriod('year'),
	operations: createAsyncInitialState([]),
	aciSeries: createAsyncInitialState({}),
	cashBalanceRecords: createAsyncInitialState([]),
	detalizationOptions: null,
	legalEntityFilter: null,
	projectFilter: null,
	textFilter: '',
	selectedCell: { row: null, col: -1 },
	isExpandedCfReport: false,
	isExpandedPlReport: false,
	isIncludesVAT: true,
} as IReportingState;

const mainReportingReducer = createReducer<IReportingState>(initialState, {
	[mainReportingActionsPack.types.INVALIDATE_PL_OPERATIONS]: (action: StaticAction, state) => {
		return {
			operations: invalidateStateFromAction(action, state.operations),
		};
	},
	[mainReportingActionsPack.types.FETCH_PL_OPERATIONS]: (action: AsyncAction<Array<PLOperationBrief>>, state) => {
		return {
			operations: checkAsyncAction(action, state.operations),
		};
	},
	[mainReportingActionsPack.types.FETCH_REPORTING_DATA]: (action: AsyncAction<FetchReportingDataReturnType>, state) => {
		return {
			aciSeries: checkAsyncAction(action, state.aciSeries, response =>
				_.indexBy(
					response.plOperationDynamics.Series,
					(s: PLOperationAccountsChartItemSeries) => s.AccountsChartItemID,
				),
			),
			cashBalanceRecords: checkAsyncAction(action, state.cashBalanceRecords, response => response.cashBalanceRecords),
		};
	},
	[mainReportingActionsPack.types.SET_DATE_RANGE]: (action: StaticAction<DateRange>, state) => {
		return {
			dateRange: action.value,
			aciSeries: invalidateStateFromAction(action, state.aciSeries),
			cashBalanceRecords: invalidateStateFromAction(action, state.cashBalanceRecords),
			selectedCell: { row: null, col: -1 },
			detalizationOptions: null,
			operations: createAsyncInitialState([]),
		};
	},
	[mainReportingActionsPack.types.SET_LEGAL_ENTITY_FILTER]: (action: StaticAction<CounterpartyBrief>, state) => {
		return {
			legalEntityFilter: action.value ? { ...action.value } : null,
			aciSeries: invalidateStateFromAction(action, state.aciSeries),
			cashBalanceRecords: invalidateStateFromAction(action, state.cashBalanceRecords),
			selectedCell: { row: null, col: -1 },
			detalizationOptions: null,
			operations: createAsyncInitialState([]),
		};
	},
	[mainReportingActionsPack.types.SET_PROJECT_FILTER]: (action: StaticAction<number | null>, state) => {
		return {
			projectFilter: action.value,
			aciSeries: invalidateStateFromAction(action, state.aciSeries),
			cashBalanceRecords: invalidateStateFromAction(action, state.cashBalanceRecords),
			selectedCell: { row: null, col: -1 },
			detalizationOptions: null,
			operations: createAsyncInitialState([]),
		};
	},
	[mainReportingActionsPack.types.SET_TEXT_FILTER]: (action: StaticAction<string>) => {
		return {
			textFilter: action.value,
		};
	},
	[mainReportingActionsPack.types.SET_IS_INCLUDES_VAT]: (action: StaticAction<boolean>, state) => {
		return {
			isIncludesVAT: action.value,
			aciSeries: invalidateStateFromAction(action, state.aciSeries),
			cashBalanceRecords: invalidateStateFromAction(action, state.cashBalanceRecords),
			operations: invalidateStateFromAction(action, state.operations),
		};
	},
	[mainReportingActionsPack.types.SET_DETALIZATION_OPTIONS]: (action: StaticAction<DetalizationOptions>) => {
		return {
			detalizationOptions: action.value,
		};
	},
	[mainReportingActionsPack.types.CLICK_ON_TABLE_CELL]: (
		action: StaticAction<{
			row: any;
			col: number;
		}>,
		state,
	) => {
		const isExists = Boolean(action.value.row);
		const isDifferent = JSON.stringify(action.value) !== JSON.stringify(state.selectedCell);

		return {
			selectedCell: { ...action.value },
			detalizationOptions: isExists ? state.detalizationOptions : null,
			operations: isExists
				? isDifferent
					? invalidateStateFromAction(action, state.operations)
					: state.operations
				: createAsyncInitialState([]),
		};
	},
	[mainReportingActionsPack.types.SET_IS_EXPANDED_CF_REPORT]: (action: StaticAction<boolean>) => {
		return {
			isExpandedCfReport: action.value,
		};
	},
	[mainReportingActionsPack.types.SET_IS_EXPANDED_PL_REPORT]: (action: StaticAction<boolean>) => {
		return {
			isExpandedPlReport: action.value,
		};
	},
});

export default mainReportingReducer;
