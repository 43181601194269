const formatCurrency = (value: number, code: string = 'RUR', locale: string = 'ru'): string => {
	const getCurrencyCode = (symbolicCode: string) => {
		if (symbolicCode === '' || symbolicCode === 'NA') {
			symbolicCode = 'RUR';
		}

		const currencyUnitsByCode = {
			RUR: 'RUB',
		};

		return currencyUnitsByCode[symbolicCode]
			?	currencyUnitsByCode[symbolicCode]
			: symbolicCode;
	};
	
	return new Intl.NumberFormat(
		locale,
		{
			style: 'currency',
			currency: getCurrencyCode(code)
		}
	).format(value);
};

export {
	formatCurrency
};
