// @ts-nocheck
import {
	checkAsyncItemLoadAction,
	createDefaultAsyncItem,
	invalidateAction
} from '../../../core/libs/reducer-creator';
import {
	ADD_INVOICE,
	REMOVE_INVOICE,
	SEND_INVOICE_EMAIL
} from '../actions/invoice-add.actions';
import { FETCH_INVOICE_LIST, FETCH_INVOICE_LIST_PREV } from '../actions/invoice-list.actions';
import { SET_INVOICE_FILTER_BY_DATE } from '../actions/invoice-filter.actions';
import { IMPORT_INVOICE } from '../actions/invoice-import.actions';
import {
	SEND_PAYMENT_DRAFT,
	LINK_PAYMENTS_TO_INVOICE,
	REMOVE_INVOICE_PAYMENTS_LINK
} from '../actions/invoice-payment.actions';
import { constants as changeConstants } from '../actions/invoice-change.actions';


export interface IInvoiceListState extends StoreAsyncItem<Array<Invoice>> {
	invoiceListPrev: StoreAsyncItem<Array<Invoice>>;
}

const initialState = {
	...createDefaultAsyncItem([]),
	invoiceListPrev: createDefaultAsyncItem([])
}

export default function(state: IInvoiceListState = initialState, action) {
	switch (action.type) {
	case FETCH_INVOICE_LIST:
	const listAction = action as asyncAction<Array<Invoice>>;
		return {
			...state,
			...checkAsyncItemLoadAction(listAction, state)
		}

	case FETCH_INVOICE_LIST_PREV:
		const fetchListPrev = action as asyncAction<Array<Invoice>>;

		return {
			...state,
			invoiceListPrev: checkAsyncItemLoadAction(fetchListPrev, state.invoiceListPrev)
		}

	case changeConstants.SET_RESPONSIBLE_EMPLOYEE:
		const setEmplAction = action as asyncAction<{ status: boolean, invoice: Invoice }>;

		if (setEmplAction.status === 'RECEIVE') {
			const status = setEmplAction.response.status;
			const invoice = setEmplAction.response.invoice;

			if (status) {
				const invoiceList = state.item.filter(el => el.ID !== invoice.ID);

				invoiceList.push(invoice);
	
				return {
					...state,
					isFetching: false,
					didInvalidate: false,
					item: invoiceList
				}
			} else {
				return state;
			}
		} else {
			return state;
		}

	case ADD_INVOICE:
	case REMOVE_INVOICE:
	case SEND_INVOICE_EMAIL:
	case IMPORT_INVOICE:
	case SEND_PAYMENT_DRAFT:
	case LINK_PAYMENTS_TO_INVOICE:
	case REMOVE_INVOICE_PAYMENTS_LINK:
	case changeConstants.REVOKE_INVOICE:
	case changeConstants.CHANGE_INVOICE:
		return invalidateAction(action, state);
	
	case SET_INVOICE_FILTER_BY_DATE:
		return {...state, didInvalidate: true}

	default:
		return state
	}
}