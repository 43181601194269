import { Store } from 'redux';

import { preferencesApi } from '@core/api/preferences';
import { IAppState } from '@store';
import { selectCurrentTenantID } from '@platform/selectors/context.selectors';
import { mainMeasureUnitsActionsPack } from '@measure-units/actions';
import { MODULE_PREFERENCE_CODE } from '@measure-units/models';

let isEffectRan = false;
const settingsKey = MODULE_PREFERENCE_CODE.LAST_MEASURE_UNIT_ID;

function createLastMeasureUnitEffect() {
	return (store: Store<IAppState>) => (next: (action: StoreAction) => void) => async (action: StoreAction) => {
		if (!isEffectRan) {
			const tenantID = selectCurrentTenantID(store.getState());

			if (tenantID > 0) {
				isEffectRan = true;

				const response = await preferencesApi.rest.fetchPreferences(tenantID, [settingsKey]);
				const lastMeasureUnitID = response[settingsKey]?.value;

				if (lastMeasureUnitID) {
					store.dispatch(mainMeasureUnitsActionsPack.setLastMeasureUnitID(lastMeasureUnitID));
				}
			}
		}

		next(action);
	};
}

const runLastMeasureUnitEffect = createLastMeasureUnitEffect();

export { runLastMeasureUnitEffect };
