import React, { useEffect, useState } from 'react';

import { api } from '@core/api';
import { useMapDispatch } from '@core/flux';
import { mainMarketplacesActionsPack } from '@marketplaces/actions';
import { getDrawer } from '@utils/drawer';
import * as plpackage from 'plpackage-api';
import { FormObject } from './model';
import { RulesForm as XRulesForm } from './rules-form.view';

export type RulesFormProps = {
	onClose?: () => void;
};

const RulesForm: React.FC<RulesFormProps> = props => {
	const { onClose = () => {} } = props;
	const [addRule] = useMapDispatch([mainMarketplacesActionsPack.addRule]);
	const [formObject, setFormObject] = useState<FormObject>(null);
	const [isFetching, setIsFetching] = useState(true);

	useEffect(() => {
		(async () => {
			const rules = await api.plPack.marketplaceOperationRule.fetchRules();

			setFormObject({
				Rules: rules && rules.length > 0 ? rules : [{ ...new plpackage.MarketplaceOperationRule() }],
			});

			setIsFetching(false);
		})();
	}, []);

	const handleSubmit = (rules: MarketplaceOperationRule[]) => {
		rules.map(rule => addRule(rule));

		handleClose();
	};

	const handleClose = () => getDrawer().close();

	return <XRulesForm isFetching={isFetching} formObject={formObject} onClose={handleClose} onSubmit={handleSubmit} />;
};

type DrawerOptions = RulesFormProps;

function openMarketplaceOperationRulesFormDrawer(options: DrawerOptions) {
	const { onClose } = options;

	getDrawer().open({
		content: <RulesForm {...options} />,
		onClose,
		overlay: true,
		rightContent: <div />,
	});
}

export { openMarketplaceOperationRulesFormDrawer };
