import React, { useRef, memo, useEffect, useMemo } from 'react';

import { DataSourceItem } from '@core/api/core';
import { useResize, useDebouncedCallback, useMounted } from '@core/hooks';
import { dummy, detectIsFunction } from '@utils/helpers';
import { Box } from '@ui/box';
import { StatefulTooltip } from '@ui/tooltip';
import { Root, ControlsLayout, TabLayout, Tab, SliderLayout, Slider, SliderBar } from './styled';

export type TabPickerProps<T extends object = any> = {
	value: string | number;
	dataSource: Array<T>;
	marginLeft?: number;
	withoutBorder?: boolean;
	getValue?: (x: T) => string | number;
	getText?: (x: T) => React.ReactNode;
	detectIsDisabled?: (x: T) => boolean;
	getTooltipText?: (x: T) => string;
	renderTab?: (options: RenderTabOptions<T>) => React.ReactNode;
	onChange?: (e: React.SyntheticEvent, item: T) => void;
};

function TabPicker<T extends object>(props: TabPickerProps<T>) {
	const {
		value,
		dataSource,
		marginLeft,
		withoutBorder,
		renderTab = ({ node }) => node,
		getValue,
		getText,
		detectIsDisabled = () => false,
		getTooltipText = () => '',
		onChange,
	} = props;
	const { mounted } = useMounted();
	const rootRef = useRef<HTMLDivElement>(null);
	const sliderRef = useRef<HTMLDivElement>(null);
	const scope = useMemo(() => ({ value }), []);

	scope.value = value;

	const debouncedMoveSlider = useDebouncedCallback(
		() =>
			mounted() &&
			moveSlider({
				rootNode: rootRef.current,
				sliderNode: sliderRef.current,
				value: scope.value,
			}),
		600,
	);

	useEffect(() => {
		requestAnimationFrame(() => {
			mounted() &&
				moveSlider({
					rootNode: rootRef.current,
					sliderNode: sliderRef.current,
					value,
				});
		});
	}, [value]);

	useResize(debouncedMoveSlider, [value]);

	const handleClick = (item: T) => (e: React.SyntheticEvent) => {
		detectIsFunction(onChange) && onChange(e, item);
	};

	return (
		<Root ref={rootRef}>
			<ControlsLayout>
				{dataSource.map((x, idx) => {
					const itemValue = getValue(x);
					const isFirst = idx === 0;
					const isActive = itemValue === value;
					const isDisabled = detectIsDisabled(x);
					const tooltipText = getTooltipText(x);
					const attrs = {
						[TAB_BUTTON_ATTR]: itemValue,
					};
					const node = (
						<Tab {...attrs} isActive={isActive} isDisabled={isDisabled} onClick={isDisabled ? dummy : handleClick(x)}>
							<Box position='relative' top={2}>
								{getText(x)}
							</Box>
						</Tab>
					);

					return (
						<TabLayout key={idx} isFirst={isFirst} marginLeft={marginLeft}>
							{renderTab({ node, item: x })}
							{tooltipText && <StatefulTooltip position='right'>{tooltipText}</StatefulTooltip>}
						</TabLayout>
					);
				})}
			</ControlsLayout>
			<SliderLayout>
				<Slider withoutBorder={withoutBorder}>
					<SliderBar ref={sliderRef} />
				</Slider>
			</SliderLayout>
		</Root>
	);
}

const TabPickerComponent = memo(TabPicker) as React.FC<TabPickerProps>;

TabPickerComponent.defaultProps = {
	getValue: (x: DataSourceItem) => x.value,
	getText: (x: DataSourceItem) => x.text,
};

const TAB_BUTTON_ATTR = 'data-tab-button';

type MoveSliderOptions = {
	rootNode: HTMLElement;
	sliderNode: HTMLElement;
	value: string | number;
};

function moveSlider(options: MoveSliderOptions) {
	const { rootNode, sliderNode, value } = options;
	if (!rootNode) return;
	const button = Array.from(rootNode.querySelectorAll(`[${TAB_BUTTON_ATTR}]`)).find(
		x => x.getAttribute(TAB_BUTTON_ATTR) === `${value}` || `${value}`.indexOf(x.getAttribute(TAB_BUTTON_ATTR)) !== -1,
	);

	if (!button) return;

	const rootRect = rootNode.getBoundingClientRect();
	const buttonRect = button.getBoundingClientRect();

	sliderNode.style.setProperty('width', `${buttonRect.width}px`);
	sliderNode.style.setProperty('transform', `translateX(${buttonRect.left - rootRect.left}px)`);
}

export type RenderTabOptions<T> = {
	node: React.ReactNode;
	item: T;
};

export { TabPickerComponent as TabPicker };
