type PageContext = {
	ticketuid: string;
};

type SharedContext = {
	ticketUID: string;
};

const getSharedContext = (): SharedContext => {
	const { pageContext } = window as Window & typeof globalThis & { pageContext: PageContext };

	return { ticketUID: pageContext.ticketuid };
};

export { getSharedContext };
