import { createSelector } from 'reselect';

import store, { IAppState } from '@store';

type CreateRightCheckOptions = {
	component?: string;
	view?: string;
	change?: string;
};

function createRightCheck(options: CreateRightCheckOptions) {
	const { component, view, change } = options;
	const selectHasComponent = (s: IAppState) => (component ? selectComponentIsAvailableToTenant(component)(s) : true);
	const selectHasReadonly = (s: IAppState) => selectHasReadonlyRight(s);
	const selectHasViewRight = (s: IAppState) =>
		view ? selectRightIsAvailableToTenant(view)(s) || selectHasReadonly(s) : false;
	const selectHasChangeRight = (s: IAppState) => (change ? selectRightIsAvailableToTenant(change)(s) : false);
	const selectHasFull = (s: IAppState) => selectHasComponent(s) && selectHasViewRight(s) && selectHasChangeRight(s);
	const selectCanVisit = (s: IAppState) => selectHasComponent(s) && selectHasViewRight(s);

	return {
		view: selectHasViewRight,
		change: selectHasChangeRight,
		full: selectHasFull,
		canVisit: selectCanVisit,
	};
}

function detectIsTechService(userLogin: string, tenantID: number) {
	return userLogin === `techService_${tenantID}`;
}

const selectComponentIsAvailableToTenant = (componentName: string) =>
	createSelector(
		(state: IAppState): Array<ApplicationComponent> => state.platform.sessionContext.item.AvailableComponents,
		(components): boolean => {
			const isAvailable = components.some(item => item.SystemName === componentName);

			return isAvailable;
		},
	);

const selectRightIsAvailableToTenant = (rightName: string) =>
	createSelector(
		(state: IAppState): Array<SecurityRight> => state.platform.sessionContext.item.UserRights,
		(rights): boolean => {
			const isAvailable = !selectIsTechService(store.getState())
				? rights.some(item => item.SystemName === rightName)
				: true;

			return isAvailable;
		},
	);

const selectIsTechService = createSelector(
	(state: IAppState): string => state.platform.sessionContext.item?.SessionUser?.Login,
	(state: IAppState): number => state.platform.sessionContext.item?.SessionTenant?.ID,
	(userLogin, tenantID) => detectIsTechService(userLogin, tenantID),
);

const selectHasReadonlyRight = (state: IAppState) =>
	selectIsTechService(state) ? false : selectRightIsAvailableToTenant('xAudit_Readonly')(state);

export {
	createRightCheck,
	selectComponentIsAvailableToTenant,
	selectRightIsAvailableToTenant,
	selectIsTechService,
	selectHasReadonlyRight,
};
