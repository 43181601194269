export type PageOrientation = 'portrait' | 'landscape';

type Size = {
	width: number;
	height: number;
	margin: number;
};

const sizesMM: Record<PageOrientation, Size> = {
	portrait: {
		width: 210,
		height: 297,
		margin: 12.7,
	},
	landscape: {
		width: 297,
		height: 210,
		margin: 12.7,
	},
};

export { sizesMM };
