import React from 'react';

import { mainIntegrationsActionsPack } from '@integrations/actions';
import { BASE_DATE_FORMAT } from '@shared/constants/time';
import { UIBlocker } from '@ui/blocker';
import { DateRangePicker } from '@ui/date-range-picker';
import { FormAppearance, OperationType } from './model';
import { Label, PickerLayout, Root } from './styled';

export type XCmsImportDateRangeSelectProps = {
	appearance: FormAppearance;
	dateRange: DateRange;
	disabled: boolean;
	operation?: OperationType;
	setDateRange: typeof mainIntegrationsActionsPack.setImportDateRange;
};

const XCmsImportDateRangeSelect: React.FC<XCmsImportDateRangeSelectProps> = props => {
	const { appearance, dateRange, disabled, operation = OperationType.PAYMENTS, setDateRange } = props;

	const handleChangeDate = (dateStart, dateEnd) => {
		setDateRange({
			dateStart: dateStart.format(BASE_DATE_FORMAT),
			dateEnd: dateEnd.format(BASE_DATE_FORMAT),
		});
	};

	const label = appearance === FormAppearance.IMPORT_EXTERNAL ? 'Импорт' : 'Обновление';
	const operationLabel = operationLabelsMap[operation];

	return (
		<Root>
			<Label>{`${label} ${operationLabel} за период:`}</Label>
			<PickerLayout>
				<UIBlocker isBlocked={disabled}>
					<DateRangePicker
						dateStart={dateRange.dateStart}
						dateEnd={dateRange.dateEnd}
						onChange={handleChangeDate}
						rangePosition='right'
						anchorHorizontal='right'
						anchorVertical='bottom'
						targetHorizontal='right'
						targetVertical='top'
						syncDates
					/>
				</UIBlocker>
			</PickerLayout>
		</Root>
	);
};

const operationLabelsMap = {
	[OperationType.PAYMENTS]: 'платежей',
	[OperationType.TRANSACTIONS]: 'транзакций',
};

export { XCmsImportDateRangeSelect };
