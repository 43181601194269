import React, { useState, useEffect, useRef } from 'react';

import { getElementSizeMM } from '@utils/dom';
import { PageOrientation, sizesMM } from '../models';
import { Root, Page, PageBreak, PageHeader, PageFooter } from './styled';

export type DocumentPageProps = {
	orientation?: PageOrientation;
	children: React.ReactNode;
};

const DocumentPage: React.FC<DocumentPageProps> = props => {
	const { orientation, children } = props;
	const [items, setItems] = useState([1]);
	const rootRef = useRef<HTMLDivElement>(null);
	const contentRef = useRef<HTMLDivElement>(null);
	const pageRef = useRef<HTMLDivElement>(null);
	const pageHeightMM = sizesMM[orientation].height;
	const pageMarginMM = sizesMM[orientation].margin;
	const pageContentHeightMM = pageHeightMM - 2 * pageMarginMM;

	useEffect(() => {
		splitDocument();
	}, []);

	const splitDocument = () => {
		const { height } = getElementSizeMM(contentRef.current);
		const count = Math.ceil(height / pageContentHeightMM) || 1;
		const items = Array(count).fill(1);

		setItems(items);
	};

	const pages = items.map((_, idx, arr) => {
		const isLast = idx === arr.length - 1;

		return (
			<Root key={idx}>
				<PageHeader orientation={orientation} />
				<Page
					ref={ref => (pageRef.current = pageRef.current || ref)}
					orientation={orientation}
					style={{
						height: arr.length > 1 ? `${pageHeightMM}mm` : '',
						overflow: !isLast ? 'hidden' : 'initial',
					}}>
					<div
						ref={ref => (contentRef.current = contentRef.current || ref)}
						style={{
							width: '100%',
							transform: `translateY(${-idx * pageContentHeightMM}mm)`,
						}}>
						{children}
					</div>
				</Page>
				{!isLast && <PageFooter orientation={orientation} />}
				{!isLast && <PageBreak />}
			</Root>
		);
	});

	return (
		<div ref={rootRef} style={{ margin: 'auto' }}>
			{pages}
		</div>
	);
};

DocumentPage.defaultProps = {
	orientation: 'portrait',
};

export { DocumentPage };
