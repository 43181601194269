import { Store } from 'redux';

import { goalsApi } from '@core/api/goals';
import { getAuthParams } from '@config';
import { IAppState } from '@store';
import { detectIsDevHost } from '@utils/env';
import { selectCurrentTenantID, selectCurrentUserID } from '@platform/selectors/context.selectors';

let isEffectRan = false;

function createGoalsEffect() {
	const isDevHost = detectIsDevHost();

	return (store: Store<IAppState>) => (next: (action: StoreAction) => void) => async (action: StoreAction) => {
		if (!isEffectRan && !isDevHost) {
			try {
				const state = store.getState();
				const tenantID = selectCurrentTenantID(state);
				const userID = selectCurrentUserID(state);
				const { clientApplication, businessService } = getAuthParams();
				const clientApplicationIDsMap = {
					web_bfm: 1,
					web_invoice: 2,
					web_ba: 3,
					web_invoice_sberbank: 13,
				};
				const goalIDsMap = {
					bfm: 1, // вход в сервис bfm
					invoice: 2, // вход в сервис invoice
				};
				const clientApplicationID = clientApplicationIDsMap[clientApplication] || -1;
				const goalID = goalIDsMap[businessService] || -1;

				if (tenantID > 0 && userID > 0) {
					isEffectRan = true;
					await goalsApi.achieveGoal({
						tenantID,
						userID,
						goalID,
						clientApplicationID,
					});
				}
			} catch (error) {
				console.error('goals effect', error);
			}
		}

		next(action);
	};
}

const runGoalsEffect = createGoalsEffect();

export { runGoalsEffect };
