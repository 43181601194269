import { createTypes } from '@flux';

type ActionTypes = {
	INVALIDATE_PAYMENT_RULES: string;
	SET_FILTER_BY_DIRECTION: string;
	SET_FILTER_BY_TEXT: string;
	FETCH_PAYMENT_RULES: string;
	ADD_PAYMENT_RULE: string;
	UPDATE_PAYMENT_RULE: string;
	REMOVE_PAYMENT_RULE: string;
	SET_PAYMENT_RULE_ACTIVITY: string;
	EXECUTE_PAYMENT_RULES: string;
	SET_IS_AUTO_APPLY_RULES: string;
};

const types = createTypes<ActionTypes>(
	[
		'INVALIDATE_PAYMENT_RULES',
		'SET_FILTER_BY_DIRECTION',
		'SET_FILTER_BY_TEXT',
		'FETCH_PAYMENT_RULES',
		'ADD_PAYMENT_RULE',
		'UPDATE_PAYMENT_RULE',
		'REMOVE_PAYMENT_RULE',
		'SET_PAYMENT_RULE_ACTIVITY',
		'EXECUTE_PAYMENT_RULES',
		'SET_IS_AUTO_APPLY_RULES',
	],
	'CASHFLOW_PAYMENT_RULES',
);

export { types };
