import { createTypes } from '@flux';

type ActionTypes = {
	RUN_CASHFLOW_ITEMS_INVALIDATION_EFFECT: string;
	INVALIDATE_CASHFLOW_ITEMS: string;
	FETCH_CASHFLOW_ITEMS: string;
	ADD_CASHFLOW_ITEM: string;
	UPDATE_CASHFLOW_ITEM: string;
	REMOVE_CASHFLOW_ITEM: string;
	FETCH_ROLES: string;
	SET_FILTER_BY_TEXT: string;
	EXPAND_INCOME_ITEMS: string;
	EXPAND_EXPENSE_ITEMS: string;
};

export const types = createTypes<ActionTypes>(
	[
		'RUN_CASHFLOW_ITEMS_INVALIDATION_EFFECT',
		'INVALIDATE_CASHFLOW_ITEMS',
		'FETCH_CASHFLOW_ITEMS',
		'ADD_CASHFLOW_ITEM',
		'UPDATE_CASHFLOW_ITEM',
		'REMOVE_CASHFLOW_ITEM',
		'FETCH_ROLES',
		'SET_FILTER_BY_TEXT',
		'EXPAND_INCOME_ITEMS',
		'EXPAND_EXPENSE_ITEMS',
	],
	'😀CASHFLOW_ITEMS',
);
