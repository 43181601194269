import React, { memo } from 'react';

import { useMapDispatch, useMapState } from '@core/flux';
import { mainIntegrationsActionsPack } from '@integrations/actions';
import {
	selectCmsAccountsTextFilter,
	selectExternalSystemAccountsByCMS,
	selectFilteredExternalSystemAccountsByCMS,
	selectSelectedCMS,
} from '@integrations/selectors';
import { XCmsAccounts, XCmsAccountsProps } from './cms-accounts.view';

type CmsAccountsProps = Pick<XCmsAccountsProps, 'onSetZone'>;

const CmsAccounts: React.FC<CmsAccountsProps> = memo(({ onSetZone }) => {
	const [externalSystemAccounts, filteredExternalSystemAccounts, selectedCMS, searchText] = useMapState([
		selectExternalSystemAccountsByCMS,
		selectFilteredExternalSystemAccountsByCMS,
		selectSelectedCMS,
		selectCmsAccountsTextFilter,
	]);
	const [setSelectedExternalSystemAccountID] = useMapDispatch([
		mainIntegrationsActionsPack.setSelectedExternalSystemAccountID,
	]);

	return (
		<XCmsAccounts
			selectedCMS={selectedCMS}
			searchText={searchText}
			externalSystemAccounts={externalSystemAccounts}
			filteredExternalSystemAccounts={filteredExternalSystemAccounts}
			onSetZone={onSetZone}
			setSelectedExternalSystemAccountID={setSelectedExternalSystemAccountID}
		/>
	);
});

export { CmsAccounts };
