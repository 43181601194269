import * as documentpackage from 'documentpackage-api';

const documentApi = {
	package: documentpackage,
	document: {
		client: new documentpackage.DocumentServiceClient(),
		generateReconciliationAct: (request: ReconciliationActRequest) => {
			return new Promise<ReconciliationAct>(resolve => {
				documentApi.document.client.generateReconciliationAct(request, result => {
					resolve(result);
				});
			});
		},
	},
};

export { documentApi };
