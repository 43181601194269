import api from './api';
import { IAppState } from '@store';
import { globalEmitErrorMessage } from '@shared/components/error-message/error-interceptor';

const REQUEST = 'REQUEST';
const RECEIVE = 'RECEIVE';
const UNVALID_TICKET_ERROR = 'UNVALID_TICKET_ERROR';
const DUBLICATE_REQUEST_ERROR = 'DUBLICATE_REQUEST_ERROR';

export interface IStaticDataAction<T = any> {
	type: string;
	value?: T;
}

export type RequestAction = {
	type: string;
	status: 'REQUEST';
	showMessage: IActionMessage;
	asyncPack: AsyncPack;
};

type AsyncPack = {
	fn: Function;
	args: Array<any>;
	addons: ActionAddon;
};

type ActionAddon = {
	showMessage?: (type: 'RECEIVE' | 'REQUEST', apiBox: typeof api, getState?, ...args: any[]) => IActionMessage;
	isValidSelector?: (state: IAppState) => boolean;
	isFetchingSelector?: (state: IAppState) => boolean;
};

function requestAction(type: string, showMessage: IActionMessage, asyncPack: AsyncPack) {
	return {
		type: type,
		status: REQUEST,
		showMessage: showMessage,
		asyncPack,
	};
}

function receiveAction<S>(type: string, response: S, showMessage: IActionMessage) {
	return {
		type: type,
		status: RECEIVE,
		response: response,
		showMessage: showMessage,
		receivedAt: Date.now(),
	};
}

function errorAction(type: string, e: Error) {
	if (e.message == UNVALID_TICKET_ERROR) {
		window.location.href = '/auth';
		return;
	}
	if (e.message == DUBLICATE_REQUEST_ERROR) {
		console.warn(e);
	} else {
		console.error(e);
		globalEmitErrorMessage({
			title: 'Ошибка JavaScript',
			message: e.message,
			error: e,
		});
	}
	return {
		type: type,
		status: 'ERROR',
		error: e,
	};
}

export function createStaticDataAction<T>(type: string) {
	return (value?: T): IStaticDataAction<T> => ({ type, value });
}

export default function createAsyncAction<S>(
	type: string,
	asyncFn: (apiBox: typeof api, getState, dispatch, ...args: any[]) => Promise<S>,
	addons: ActionAddon = {},
) {
	return function (...args) {
		return function (dispatch, getState, apiBox: typeof api) {
			if (addons.isValidSelector && addons.isValidSelector(getState())) return;
			if (addons.isFetchingSelector && addons.isFetchingSelector(getState())) return;

			const requestMsg = addons.showMessage && addons.showMessage(REQUEST, apiBox, getState, ...args);

			dispatch(
				requestAction(type, requestMsg, {
					fn: asyncFn,
					args: [apiBox, getState, dispatch, ...args],
					addons,
				}),
			);

			// Сам процесс обращений к серверу продолжается в platform/effects/async-initializer.effect.ts.
			// Это сделано для того, чтобы у нас было гибкое управление асинхронными экшенами, например, для их отмены,
			// если у пользователя нет прав, или это демо-аккаунт и нельзя давать возможность редактировать информацию.

			return Promise.resolve();
		};
	};
}

export function createLoader<S>(
	loader: (apiBox: typeof api, getState, dispatch, ...args: any[]) => Promise<S>,
): (...args: any[]) => Promise<S> {
	return function (...args) {
		return loader(api, undefined, undefined, ...args)
			.then((response: S) => response)
			.catch(e => {
				errorAction('', e);
				return undefined as S;
			});
	};
}

export { receiveAction, errorAction, REQUEST, RECEIVE, UNVALID_TICKET_ERROR, DUBLICATE_REQUEST_ERROR };
