import { combineReducers } from 'redux';

import mainReconciliationActReducer, { MainReconciliationActState } from './main.reducer';

export type ReconciliationActState = {
	main: MainReconciliationActState;
};

const reconciliationActReducer = combineReducers<ReconciliationActState>({
	main: mainReconciliationActReducer,
});

export default reconciliationActReducer;
