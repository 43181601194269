import { combineReducers } from 'redux';

import { mainPlanningReducer, MainPlanningState } from './main.reducer';

export type PlanningState = {
	main: MainPlanningState;
};

const planningReducer = combineReducers<PlanningState>({
	main: mainPlanningReducer,
});

export { planningReducer };