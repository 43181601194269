import React from 'react';

import { Portal } from '../portal';
import { CloseIcon } from '@ui/icons/close';
import { SuccessIcon } from '@ui/icons/success';
import { ExclamationIcon } from '@ui/icons/exclamation';
import {
	Root,
	HeaderLayout,
	ContentLayout,
	IconLayout,
	ChildrenLayout,
	CloseButtonLayout,
	CloseButton,
} from './styled';

interface IProps {
	isOpen?: boolean;
	autoHide?: boolean;
	autoHideTimeout?: number;
	type?: 'default' | 'success' | 'warning' | 'alarm';
	asSimpleComponent?: boolean;
	children: React.ReactNode;
	onClose?: Function;
}

interface IState {
	isOpenInternal: boolean;
	needHideAnimation: boolean;
}

class Snackbar extends React.Component<IProps, IState> {
	static defaultProps = {
		isOpen: false,
		autoHide: false,
		autoHideTimeout: 6000,
		type: 'default',
		onClose: () => {},
	};
	state = {
		isOpenInternal: false,
		needHideAnimation: false,
	};
	isMountedComponent = false;

	componentDidMount() {
		this.isMountedComponent = true;
	}

	componentDidUpdate(prevProps: IProps) {
		if (this.props.isOpen && !prevProps.isOpen && !this.state.isOpenInternal) {
			this.setState({
				isOpenInternal: true,
				needHideAnimation: false,
			});
			this.props.autoHide && setTimeout(() => this.props.onClose(), this.props.autoHideTimeout);
		}

		if (!this.props.isOpen && prevProps.isOpen && this.state.isOpenInternal) {
			this.initCloseWithAnimation();
		}
	}

	componentWillUnmount() {
		this.isMountedComponent = false;
	}

	handleClose = () => {
		this.initCloseWithAnimation();
		this.props.onClose();
	};

	initCloseWithAnimation = () => {
		this.setState(
			{
				needHideAnimation: true,
			},
			() => {
				setTimeout(() => {
					this.isMountedComponent && this.setState({ isOpenInternal: false });
				}, 1000);
			},
		);
	};

	renderIcon = () => {
		const { type } = this.props;

		if (type === 'success') {
			return (
				<IconLayout type={type}>
					<SuccessIcon color='light' size={48} />
				</IconLayout>
			);
		}

		if (type === 'warning' || type === 'alarm') {
			return (
				<IconLayout type={type}>
					<ExclamationIcon color='light' size={48} />
				</IconLayout>
			);
		}

		return null;
	};

	renderContent = () => {
		const { needHideAnimation } = this.state;

		return (
			<Root needHideAnimation={needHideAnimation}>
				<HeaderLayout>
					<CloseButtonLayout>
						<CloseButton onClick={this.handleClose}>
							<CloseIcon />
						</CloseButton>
					</CloseButtonLayout>
				</HeaderLayout>
				<ContentLayout>
					{this.renderIcon()}
					<ChildrenLayout>{this.props.children}</ChildrenLayout>
				</ContentLayout>
			</Root>
		);
	};

	renderAsSimpleComponent = () => {
		return (
			<Root asSimpleComponent>
				<ContentLayout>
					{this.renderIcon()}
					<ChildrenLayout>{this.props.children}</ChildrenLayout>
				</ContentLayout>
			</Root>
		);
	};

	render() {
		const { asSimpleComponent } = this.props;
		const { isOpenInternal } = this.state;

		if (asSimpleComponent) {
			return this.renderAsSimpleComponent();
		}

		return <Portal>{isOpenInternal && this.renderContent()}</Portal>;
	}
}

export { Snackbar };
