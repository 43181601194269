import styled from 'styled-components';

const Root = styled.div`
	flex: 1 1 auto;
	position: relative;
	height: auto;
	min-height: 100%;
	padding: 33px 20px;
`;

export { Root };
