import React from 'react';

import { getPropInSafe } from '../../../../../core/libs/utils';
import { Root, TitleLayout, Title, NumberLayout, Number, InfoLayout } from './styled';

interface IProps {
	tenant: Tenant;
}

class TenantAccountKPI extends React.PureComponent<IProps> {
	static displayName = 'TenantAccountKPI';

	render() {
		const { tenant } = this.props;
		const tenantID = getPropInSafe(tenant, o => o.StorageID, '');

		return (
			<Root>
				<TitleLayout>
					<Title>Личный кабинет</Title>
				</TitleLayout>
				<InfoLayout>
					<NumberLayout>
						<Number>#{tenantID}</Number>
					</NumberLayout>
				</InfoLayout>
			</Root>
		);
	}
}

export default TenantAccountKPI;
