import { css } from 'styled-components';

export type Adaptive = {
	fullWidth?: boolean;
};

const makeFullWidth = (p: Adaptive) => css`
	width: ${p.fullWidth ? '100%' : 'auto'};
`;

export { makeFullWidth };
