import { combineReducers } from 'redux';

import mainInvoicesReducer, { MainInvoicesState } from './main.reducer';


export type InvoicesState = {
	main: MainInvoicesState;
};

const invoicesReducer = combineReducers<InvoicesState>({
	main: mainInvoicesReducer,
});

export default invoicesReducer;
