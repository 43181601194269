import styled, { css } from 'styled-components';

type RootProps = {
	isBlocked: boolean;
};

const Root = styled.div<RootProps>`
	position: relative;
	transition: opacity 200ms ease-in-out;

	${p =>
		p.isBlocked &&
		css`
			opacity: 0.4;
			& * {
				pointer-events: none !important;
				user-select: none !important;
			}
		`}
`;

export { Root };
