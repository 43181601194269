import React, { useState, memo } from 'react';

import { MobileAppsButton as XMobileAppsButton } from './mobile-apps-button.view';

type MobileAppsButtonProps = {};

const MobileAppsButton: React.FC<MobileAppsButtonProps> = memo(() => {
	const [isOpen, setIsOpen] = useState(false);
	const handleRequestOpen = () => setIsOpen(true);
	const handleRequestClose = () => setIsOpen(false);

	return <XMobileAppsButton isOpen={isOpen} onRequestOpen={handleRequestOpen} onRequestClose={handleRequestClose} />;
});

export { MobileAppsButton };
