import { combineReducers } from 'redux';

import mainPlanningReducer, { PlanningState } from './main-planning.reducer';
import mainPlanningMasterReducer, { PlanningMasterState } from './main-planning-master.reducer';

export type PLPlanningState = {
	main: PlanningState;
	master: PlanningMasterState;
};

export const plPlanningReducer = combineReducers<PLPlanningState>({
	main: mainPlanningReducer,
	master: mainPlanningMasterReducer,
});
