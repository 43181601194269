import React, { memo, useEffect, useState } from 'react';

import { useMapState } from '@core/flux';
import { selectLastImportedAccountData } from '@integrations/selectors';
import { XImportProgress, XImportProgressProps } from './import-progress.view';

type ImportProgressProps = {
	status: AsyncProgressStatus;
} & Pick<XImportProgressProps, 'zone'>;

const ImportProgress: React.FC<ImportProgressProps> = memo(props => {
	const { zone, status } = props;
	const [lastImportedAccountData] = useMapState([selectLastImportedAccountData]);
	const [progress, setProgress] = useState(0);
	const progressMap = {
		WAITING: 30,
		IN_PROGRESS: 50,
		SUCCESS: 100,
		FAILED: 0,
		FINISHED_WITH_WARNING: 70,
		DUPLICATE_REQUEST: 70,
	};

	useEffect(() => {
		setProgress(progressMap[status.status] || 0);
	}, [status]);

	return <XImportProgress zone={zone} progress={progress} lastImportedAccountData={lastImportedAccountData} />;
});

export { ImportProgress };
