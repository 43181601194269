import * as _ from 'underscore';
import { Store } from 'redux';

import { IAppState } from '@store';
import api from '@api';
import spy from '@core/spy';
import { getFundsRegisterType, getPropInSafe } from '@utils';
import { MODULE_BANK_BNK_CMS } from '@shared/constants/cms-guids';
import { IMPORT_INVOICE, importInvoiceAction } from '../actions/invoice-import.actions';
import { methods as changeInvMethods } from '../actions/invoice-change.actions';
import { modifyInvoiceTypes, mainInvoiceActionsPack } from '@invoice/actions';
import {
	RUN_FAST_PAY_INVOICE_EFFECT,
	setStatusForFastPayInvoiceAction,
	controlFastPayInvoiceFormAction,
	setFundsRegistersForFastPayInvoiceAction,
	controlRemotePaymentSignatureFormAction,
	methods as invPaymentMethods,
} from '../actions/invoice-payment.actions';
import { selectCMSByGUIDMap } from '@platform/selectors/external-system-account.selectors';
import * as statuses from '@shared/constants/statuses';
import { history } from '@platform/components/layout/layout.view';
import * as ctxSelectors from '@platform/selectors/context.selectors';
import { ROUTE_MAP } from '@routes/urls';
import { fundsApi } from '@core/api/funds';
import {CMSDataType} from "@funds-registers/models";

const PURCHASES_URL = ROUTE_MAP.PURCHASES;
const makeRedirectToRoot = () => history.push(PURCHASES_URL);
const makeRedirectToBank = (response: CMSCreatePaymentDraftResponse) => {
	const signBankURL = getPropInSafe(response, o => o.CMSPaymentDraft.SignBankURL, '');

	if (signBankURL) {
		spy.sendAction({
			title: 'Payment Sending Process',
			description: `Redirect to bank`,
			payload: {
				targetStateCode: spy.targetStateCodes.PROCEEDED_TO_PAYMENT_SIGNATURE,
			},
		});
		window.location.assign(signBankURL);
	}
};

type SendPaymentOptions = {
	store: Store<IAppState>;
	invoice: Invoice;
	fr?: FundsRegister;
	cmsID: number;
};

const sendPayment = async (options: SendPaymentOptions) => {
	const { store, invoice, fr, cmsID } = options;
	if (cmsID < 0) return;

	if (fr) {
		invoice.FundsRegister = {
			...new api.fundspackage.FundsRegisterBrief(),
			ID: fr.ID,
			Name: fr.Name,
			Number: fr.RegisterNumber,
		};
	}

	const changedInvoice = (await changeInvMethods.changeInvoiceMethod(invoice)) as Invoice;
	await invPaymentMethods.addInvoicePaymentDraftMethod(changedInvoice);
	const invoiceWithPayment = (await api.invoicePack.financialDocument.fetchDocumentByNumberInYear(
		changedInvoice.Number,
		changedInvoice.DateIssued,
	)) as Invoice;
	const invoicePaymentDraft = invoiceWithPayment.Payments.find(
		(p: InvoicePayment) => p.PaymentState['Code'] === 'DRAFT',
	);

	if (invoicePaymentDraft) {
		invPaymentMethods
			.sendPaymentDraftMethod(invoicePaymentDraft.ID, cmsID)
			.then((response: CMSCreatePaymentDraftResponse) => {
				const authorizationSuccess = getPropInSafe(response, o => o.AuthorizationSuccess);
				const serviceAvailable = getPropInSafe(response, o => o.ServiceAvailable);
				const isSuccess = response.Status === statuses.SUCCESS;

				if (isSuccess) {
					spy.sendAction({
						title: 'Payment Sending Process',
						description: `Payment draft sent success`,
						payload: {
							targetStateCode: spy.targetStateCodes.PAYMENT_SENT_TO_BANK,
						},
					});
					store.dispatch(
						setStatusForFastPayInvoiceAction({
							status: statuses.IN_PROGRESS,
							message: 'Идет перенаправление в интернет-банк для подписания платежа...',
						}),
					);

					setTimeout(() => makeRedirectToBank(response), 3000);
				} else {
					const message =
						authorizationSuccess === false
							? `Ошибка авторизации вызова при обращении к банку. ${response.Message || ''}`
							: serviceAvailable === false
							? `Сервис на стороне банка недоступен. ${response.Message || ''}`
							: `Неизвестная ошибка. ${response.Message || ''}`;
					spy.sendAction({
						title: 'Payment Sending Process',
						description: `Payment draft sent failed with message "${message}"`,
						payload: {
							error: true,
						},
					});
					store.dispatch(
						setStatusForFastPayInvoiceAction({
							status: statuses.FAILED,
							message,
						}),
					);

					makeRedirectToRoot();
				}
			});
	} else {
		const makeStatus = () => {
			const { FAILED, SUCCESS, FINISHED_WITH_WARNING } = statuses;
			const isFailed = invoiceWithPayment.Payments.some(
				(p: InvoicePayment) => p.PaymentState['Code'] === 'FAILED_BY_CMS',
			);
			const isSentToCMS = invoiceWithPayment.Payments.some(
				(p: InvoicePayment) =>
					p.PaymentState['Code'] === 'SENT_TO_CMS' ||
					p.PaymentState['Code'] === 'IMPLEMENTED_BY_CMS' ||
					p.PaymentState['Code'] === 'EXECUTED',
			);
			const getMessage = (status: string) => {
				const map = {
					[FAILED]: 'Платёж отклонен банком',
					[SUCCESS]: 'Платёж по счёту уже отправлен в банк',
					[FINISHED_WITH_WARNING]: 'У счёта на оплату не найден черновик платежа',
				};

				return map[status];
			};

			if (isFailed) {
				spy.sendAction({
					title: 'Payment Sending Process',
					description: `Payment failed with message "${getMessage(FAILED)}"`,
					payload: {
						error: true,
					},
				});
				store.dispatch(
					setStatusForFastPayInvoiceAction({
						status: FAILED,
						message: getMessage(FAILED),
					}),
				);
				return;
			}

			if (isSentToCMS) {
				spy.sendAction({
					title: 'Payment Sending Process',
					description: `Payment sent to cms with message "${getMessage(SUCCESS)}"`,
				});
				store.dispatch(
					setStatusForFastPayInvoiceAction({
						status: SUCCESS,
						message: getMessage(SUCCESS),
					}),
				);
				return;
			}

			spy.sendAction({
				title: 'Payment Sending Process',
				description: `Payment failed with message "${getMessage(FINISHED_WITH_WARNING)}"`,
				payload: {
					error: true,
				},
			});

			store.dispatch(
				setStatusForFastPayInvoiceAction({
					status: FINISHED_WITH_WARNING,
					message: getMessage(FINISHED_WITH_WARNING),
				}),
			);
		};

		makeStatus();
		makeRedirectToRoot();
	}
};

const detectSupportFastPayment = (cms: CashManagementSystem) => cms.SupportFastPayment;
const detectSupportGetAccounts = (cms: CashManagementSystem) => cms.SupportGetAccountList;
const detectSupportRemotePaymentSignature = (cms: CashManagementSystem) => cms.SupportRemotePaymentSignature;

const fetchFundsRegistersByCMS = (cms: CashManagementSystem, invoice: Invoice) => {
	type ResultType = [Array<BankAccountFundsRegister>, boolean, string];
	return new Promise<ResultType>(async resolve => {
		const frList = await api.fundsPack.fundsRegister.fetchFundsRegisters();
		const filteredFrList = getOnlySupportCreatePaymentDraftFundsRegisters(
			frList,
			cms,
		) as Array<BankAccountFundsRegister>;

		if (filteredFrList.length === 0 && detectSupportGetAccounts(cms)) {
			const name = invoice.LegalEntity.Name || invoice.LegalEntity.NameEng;
			const taxCode = invoice.LegalEntity.TaxCode;
			const GUID = invoice.LegalEntity.GUID;
			const cmsAccountsResponse = await fundsApi.cashManagementSystem.fetchAccounts({
				...new fundsApi.package.CMSAccountListRequest(),
				CashManagementSystemID: cms.ID,
				ExternalSystemDataType: CMSDataType.LEGAL_ENTITY_BANK_ACCOUNT,
				LegalEntityName: name,
				LegalEntityTaxCode: taxCode,
				LegalEntityUID: GUID,
			});
			const isSuccess = cmsAccountsResponse.AuthorizationSuccess && cmsAccountsResponse.ServiceAvailable;
			const responseMessage = cmsAccountsResponse.Message;
			const errorPrefix = 'Не удается получить список счетов';
			let errorMessage = !isSuccess
				? !cmsAccountsResponse.AuthorizationSuccess
					? `${errorPrefix}. Ошибка авторизации вызова при обращении к банку. `
					: !cmsAccountsResponse.ServiceAvailable
					? `${errorPrefix}. Сервис на стороне банка недоступен. `
					: `${errorPrefix}. `
				: '';
			responseMessage && (errorMessage += responseMessage);

			if (!isSuccess) {
				resolve([[], false, errorMessage]);
				return;
			}

			const cmsAccountList = cmsAccountsResponse.Accounts;
			const frList = await Promise.all(
				cmsAccountList.map(
					async cmsAccount => await api.fundsPack.fundsRegister.createFundsRegisterFromCmsAccount(cmsAccount),
				),
			);

			resolve([frList, true, '']);
		} else {
			resolve([filteredFrList, true, '']);
		}
	});
};

const createMessageEmitter = store => (status: ServerStatus, message: string) =>
	store.dispatch(setStatusForFastPayInvoiceAction({ status, message }));

function createPayInvoiceEffect() {
	let isEffectRunning = false;
	let sharedUID = '';
	let cmsGUID = '';
	let invoice: Invoice = null;
	const messageDelay = 2000;
	const { IN_PROGRESS, FINISHED_WITH_WARNING } = statuses;

	return store => next => action => {
		const state = store.getState();
		const emitMessage = createMessageEmitter(store);

		if (action.type === RUN_FAST_PAY_INVOICE_EFFECT) {
			isEffectRunning = true;
			const { value } = action as StaticAction<{ sharedUID: string; cmsGUID: string }>;
			const checkSharedInvoice = async (sharedUID: string, onCheck: () => void) => {
				const sharedInvoice = (await api.invoicePack.financialDocument.fetchDocumentBySharedDocumentUID(
					sharedUID,
				)) as Invoice;
				const leTaxCode = getPropInSafe(sharedInvoice, o => o.LegalEntity.TaxCode);
				const cpTaxCode = getPropInSafe(sharedInvoice, o => o.Counterparty.TaxCode);
				const entities = await api.counterpartyPack.counterparty.fetchTenantLegalEntities();
				const entitiesMap = _.indexBy(entities, e => e.TaxCode);
				const isSameCounterparty = leTaxCode && Boolean(leTaxCode === cpTaxCode || entitiesMap[leTaxCode]);

				if (isSameCounterparty) {
					emitMessage(
						FINISHED_WITH_WARNING,
						'Счёт не может быть оплачен, потому что ИНН компании-получателя совпадает с ИНН вашей организации',
					);
					return;
				}

				onCheck();
			};
			const makeProcess = (sharedUID: string) => {
				checkSharedInvoice(sharedUID, () => {
					emitMessage(IN_PROGRESS, 'Импорт счёта на оплату...');

					setTimeout(() => {
						store.dispatch(importInvoiceAction(sharedUID, false, false));
					}, messageDelay);
				});
			};
			sharedUID = value.sharedUID;
			cmsGUID = value.cmsGUID;

			spy.sendAction({
				title: 'Auth',
				description: `Payment form opened`,
				payload: {
					targetStateCode: spy.targetStateCodes.BANK_AUTH_SUCCESS,
					tenantID: ctxSelectors.selectCurrentTenantID(state),
					userID: ctxSelectors.selectCurrentUserID(state),
					subsystemInstanceGUID: cmsGUID,
				},
			});

			emitMessage(IN_PROGRESS, 'Проверка наличия банковских счетов в системе...');
			store.dispatch(controlFastPayInvoiceFormAction({ isOpen: true, cmsGUID }));

			setTimeout(() => {
				makeProcess(sharedUID);
			}, messageDelay);
		}

		if (isEffectRunning) {
			const makeProcess = async () => {
				const sharedInvoice = (await api.invoicePack.financialDocument.fetchDocumentBySharedDocumentUID(
					sharedUID,
				)) as Invoice;
				invoice = (await api.invoicePack.financialDocument.fetchDocumentByNumberInYear(sharedInvoice.Number, sharedInvoice.DateIssued)) as Invoice;
				const cmsMap = selectCMSByGUIDMap(store.getState());
				const cms = cmsMap[cmsGUID];
				const cmsID = cms ? cms.ID : -1;
				const isSupportFastPayment = detectSupportFastPayment(cms);
				const isSupportRemotePaymentSignature = detectSupportRemotePaymentSignature(cms);
				const [frList, isFetchSuccess, errorMessage] = await fetchFundsRegistersByCMS(cms, invoice);
				const legalEntity = getPropInSafe(invoice, o => o.LegalEntity, null);
				const isSoleProprietor = getPropInSafe(legalEntity, o => o.SoleProprietor);
				const taxCode = getPropInSafe(legalEntity, o => o.TaxCode);
				const needInputAdditionalTaxCode = isSoleProprietor
					? false
					: !getPropInSafe(legalEntity, o => o.AdditionalTaxCode);
				const filteredFrList = taxCode
					? frList.filter(fr => fr.LegalEntity.TaxCode === taxCode && fr.CurrencyID === 1)
					: [];
				const pickFundsRegisterCmsWhiteList = [MODULE_BANK_BNK_CMS];
				const hasInvoiceFundsRegister = invoice.FundsRegister && invoice.FundsRegister.ID > 0;
				const allowSelectFundsRegister =
					!isSupportFastPayment || pickFundsRegisterCmsWhiteList.includes(cms.SubsystemInstanceGUID);
				const needSelectFundsRegister =
					!hasInvoiceFundsRegister && allowSelectFundsRegister && filteredFrList.length > 1;
				const needAutoInsertFundsRegister = !hasInvoiceFundsRegister && filteredFrList[0];

				if (!isFetchSuccess) {
					spy.sendAction({
						title: 'Funds Registers',
						description: `Fetch funds registers failed with error "${errorMessage}"`,
						payload: {
							error: true,
						},
					});
					emitMessage(FINISHED_WITH_WARNING, errorMessage);
					isEffectRunning = false;
					return next(action);
				} else if (needInputAdditionalTaxCode) {
					spy.sendAction({
						title: 'Legal Entity',
						description: `Need input additional tax code for legal entity`,
					});
					emitMessage(null, 'Пожалуйста, укажите КПП плательщика:');
					store.dispatch(
						mainInvoiceActionsPack.actions.modifyInvoiceBeforePayment({
							invoice,
							type: modifyInvoiceTypes.CHANGE_LEGAL_ENTITY_ADDITIONAL_TAX_CODE as any,
						}),
					);
					setTimeout(() => (isEffectRunning = true));
				} else if (isSupportRemotePaymentSignature && filteredFrList.length > 0) {
					store.dispatch(controlRemotePaymentSignatureFormAction({ isOpen: true, cmsGUID, invoice }));
					store.dispatch(controlFastPayInvoiceFormAction({ isOpen: false, cmsGUID }));
					store.dispatch(setFundsRegistersForFastPayInvoiceAction(filteredFrList));
					setTimeout(() => (isEffectRunning = true));
				} else if (needSelectFundsRegister) {
					spy.sendAction({
						title: 'Funds Registers',
						description: `Found funds registers with the following numbers [${filteredFrList
							.map(fr => fr.RegisterNumber)
							.join(', ')}]`,
						payload: {
							targetStateCode: spy.targetStateCodes.BANK_ACCOUNT_VALIDATED,
						},
					});
					emitMessage(null, 'Пожалуйста, выберите счёт для оплаты:');
					store.dispatch(
						mainInvoiceActionsPack.actions.modifyInvoiceBeforePayment({
							invoice,
							type: modifyInvoiceTypes.SELECT_FUNDS_REGISTER as any,
							frList: filteredFrList,
						}),
					);
					setTimeout(() => (isEffectRunning = true));
				} else if (needAutoInsertFundsRegister) {
					const [fr] = filteredFrList;

					spy.sendAction({
						title: 'Funds Registers',
						description: `Found one funds register with number "${fr.RegisterNumber}"`,
						payload: {
							targetStateCode: spy.targetStateCodes.BANK_ACCOUNT_VALIDATED,
						},
					});
					sendPayment({ store, invoice, fr, cmsID });
				} else if (hasInvoiceFundsRegister) {
					const cmsMap = selectCMSByGUIDMap(store.getState());
					const cmsID = cmsMap[cmsGUID] ? cmsMap[cmsGUID].ID : -1;

					spy.sendAction({
						title: 'Funds Registers',
						description: `Select funds register with number "${invoice.FundsRegister.Number}"`,
					});
					emitMessage(IN_PROGRESS, 'Формирование запроса...');
					sendPayment({ store, invoice, cmsID });
				} else {
					spy.sendAction({
						title: 'Funds Registers',
						description: `Funds registers not found`,
						payload: {
							error: true,
						},
					});
					emitMessage(
						FINISHED_WITH_WARNING,
						`Не найдено расчётных счетов в банке «${cms.Name}», привязанных к компании с ИНН ${taxCode}, с которых может быть проведена оплата`,
					);
					makeRedirectToRoot();
				}
			};

			if (action.type === IMPORT_INVOICE && action.status === 'RECEIVE') {
				spy.sendAction({
					title: 'Import Invoice',
					description: `Invoice imported successfully, the process of receiving funds registers has begun...`,
				});
				emitMessage(IN_PROGRESS, 'Формирование запроса...');
				setTimeout(() => {
					makeProcess();
					isEffectRunning = false;
				}, messageDelay);
			}

			if (action.type === mainInvoiceActionsPack.types.MODIFICATION_COMPLETED) {
				emitMessage(IN_PROGRESS, 'Формирование запроса...');
				setTimeout(() => {
					makeProcess();
					isEffectRunning = false;
				}, messageDelay);
			}
		}

		next(action);
	};
}

const runPayInvoiceEffect = createPayInvoiceEffect();

function getOnlySupportCreatePaymentDraftFundsRegisters(
	sourceFundsRegisters: Array<FundsRegister>,
	cms: CashManagementSystem,
): Array<FundsRegister> {
	const fundsRegisters = sourceFundsRegisters.filter(x => {
		const isBankAccount = getFundsRegisterType(x) === 'bank';
		const isConnectedToSameCMS =
			!!x.CashManagementSystem && x.CashManagementSystem.SubsystemInstanceGUID === cms.SubsystemInstanceGUID;
		const hasLegalEntity = getPropInSafe(x, o => o.LegalEntity.ID, -1) > 0;
		const isSupportCreatePaymentDraft = cms.SupportCreatePaymentDraft;

		return isBankAccount && isConnectedToSameCMS && hasLegalEntity && isSupportCreatePaymentDraft;
	});

	return fundsRegisters;
}

export { runPayInvoiceEffect };
