function detectIsBankAccountFundsRegister(source: FundsRegister | FundsRegisterBrief): boolean {
	const fundsRegister = source as FundsRegister;
	const fundsRegisterBrief = source as FundsRegisterBrief;

	if (!source) return false;
	if (fundsRegister.CLASSIFIER === 'BANK_ACCOUNT_FUNDS_REGISTER') return true;
	if (fundsRegisterBrief?.FundsRegisterType?.Code === 'FUNDS_REGISTER_TYPE_BANK_ACCOUNT') return true;
	return false;
}

function detectIsVirtualAccountFundsRegister(source: FundsRegister | FundsRegisterBrief): boolean {
	const fundsRegister = source as FundsRegister;
	const fundsRegisterBrief = source as FundsRegisterBrief;

	if (!source) return false;
	if (fundsRegister.CLASSIFIER === 'CASH_DESK_FUNDS_REGISTER') return true;
	if (fundsRegisterBrief?.FundsRegisterType?.Code === 'FUNDS_REGISTER_TYPE_CASH_DESK') return true;
	return false;
}

function detectIsCreditFundsRegister(fundsRegister: FundsRegister | FundsRegisterBrief) {
	return fundsRegister?.Purpose?.Code === 'CREDIT';
}

function detectIsDepositFundsRegister(fundsRegister: FundsRegister | FundsRegisterBrief) {
	return fundsRegister?.Purpose?.Code === 'DEPOSIT';
}

function detectIsBankAccountRegisterNumber(candidate: string): boolean {
	const pattern = /^\d{20}$/gi;

	return pattern.test(candidate);
}

function formatBankAccountRegisterNumber(candidate: string, separator = '.'): string {
	function format(target) {
		const formatted = target
			.replace(new RegExp(/(\d{5})(.)/), '$1' + separator + '$2')
			.replace(new RegExp(`(.)(\\${separator}\\d{3})(.)`), '$1' + '$2' + separator + '$3')
			.replace(new RegExp(`(.)(\\${separator}\\d)(\\d{11})`), '$1' + '$2' + separator + '$3');

		return formatted;
	}

	return detectIsBankAccountRegisterNumber(candidate) ? format(candidate) : candidate;
}

export {
	detectIsBankAccountFundsRegister,
	detectIsVirtualAccountFundsRegister,
	detectIsCreditFundsRegister,
	detectIsDepositFundsRegister,
	formatBankAccountRegisterNumber,
};
