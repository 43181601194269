import { CSSProperties } from 'react';
import styled, { css } from 'styled-components';

type RootProps = {
	topShift: number;
};

const Root = styled.div<RootProps>`
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	width: 80px;
	z-index: 1000;

	${p => css`
		top: ${p.topShift}px;
		background-color: ${p.theme.appMenu.backgroundColor};
	`}
`;

const LogoLayout = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	z-index: 30;
`;

const styles: Record<string, CSSProperties> = {
	scrollableView: {
		position: 'relative',
		marginTop: 70,
		maxHeight: 'calc(100vh - 70px)',
		zIndex: 20,
	},
};

const LinksLayout = styled.div`
	position: relative;
	margin-top: 70px;
	max-height: calc(100vh - 70px);
	overflow-y: auto;
	z-index: 20;
`;

export { Root, LogoLayout, LinksLayout, styles };
