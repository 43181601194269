import * as moment from 'moment';

import { BASE_DATE_FORMAT, BASE_TIME_FORMAT } from '@shared/constants/time';

export enum PeriodNames {
	WEEK = 'WEEK',
	MONTH = 'MONTH',
	QUARTER = 'QUARTER',
	YEAR = 'YEAR',
}

export enum MomentDisplayFormats {
	MMMM = 'MMMM',
	MMMM_YYYY = 'MMMM YYYY',
	Q = 'Q',
	Q_YYYY = 'Q YYYY',
	YYYY = 'YYYY',
}

export type MomentPeriodsKey = 'day' | 'week' | 'month' | 'quarter' | 'year';

function createDefaultPeriod(type: 'month' | 'quarter' | 'year', startDate: string | Moment = moment()): DateRange {
	const date = getMoment(startDate);

	return {
		dateStart: date.startOf(type).format(BASE_DATE_FORMAT),
		dateEnd: date.endOf(type).format(BASE_DATE_FORMAT),
	};
}

function correctDateRange(dateRange: DateRange, type: 'month' | 'quarter' | 'year'): DateRange {
	const dateStart = getMoment(dateRange.dateStart);
	const dateEnd = getMoment(dateRange.dateEnd);

	return {
		dateStart: dateStart.startOf(type).format(BASE_DATE_FORMAT),
		dateEnd: dateEnd.endOf(type).format(BASE_DATE_FORMAT),
	};
}

function createDateList(dateStart: string, dateEnd: string, precision: 'day' | 'month'): Array<string> {
	const isByDays = precision === 'day';
	const dateStartMoment = isByDays
		? moment(dateStart, BASE_DATE_FORMAT).subtract(1, 'day')
		: moment(dateStart, BASE_DATE_FORMAT).startOf('month').subtract(1, 'day');
	const dateEndMoment = isByDays
		? moment(dateEnd, BASE_DATE_FORMAT)
		: moment(dateEnd, BASE_DATE_FORMAT).endOf('month').add(1, 'day');
	const key = isByDays ? 'day' : 'month';
	const diff = dateEndMoment.diff(dateStartMoment, key);
	const list = new Array(diff).fill('').map(() => dateStartMoment.add(1, key).endOf(key).format(BASE_DATE_FORMAT));

	return list;
}

function prettyDate(date: string, time = false) {
	return time
		? moment(date, BASE_TIME_FORMAT).format('DD.MM.YYYY HH:mm:ss')
		: moment(date, BASE_DATE_FORMAT).format('DD.MM.YYYY');
}

function prettyMonth(date: string) {
	return moment(date, BASE_DATE_FORMAT).format('MMMM YYYY');
}

function formatDate(date: string, pattern: string) {
	return moment(date, BASE_DATE_FORMAT).format(pattern);
}

function getDateDiff(dateStart: string, dateEnd: string, precision: MomentPeriodsKey = 'day'): number {
	return moment(dateEnd, BASE_DATE_FORMAT).diff(moment(dateStart, BASE_DATE_FORMAT), precision);
}

const getMoment = (date: string | Moment) => (typeof date === 'string' ? moment(date, BASE_DATE_FORMAT) : date);

function convertDateToString(date: string | Moment) {
	return getMoment(date).format(BASE_DATE_FORMAT);
}

function getDateBoundaries(date: string | Moment, periodKeys: Array<MomentPeriodsKey> = ['month', 'quarter', 'year']) {
	const dateMoment = getMoment(date);
	const boundaries = periodKeys.map(key => ({
		start: dateMoment.clone().startOf(key),
		end: dateMoment.clone().endOf(key),
	}));

	return boundaries;
}

function getBoundaryDateRange(date: string | Moment, periodKey: MomentPeriodsKey = 'month') {
	const dateMoment = getMoment(date);
	const dateRange: DateRange = {
		dateStart: dateMoment.clone().startOf(periodKey).format(BASE_DATE_FORMAT),
		dateEnd: dateMoment.clone().endOf(periodKey).format(BASE_DATE_FORMAT),
	};

	return dateRange;
}

function compareDates(dateOne: string | Moment, dateTwo: string | Moment, momentKey: MomentPeriodsKey = 'day') {
	const dateOneMoment = getMoment(dateOne);
	const dateTwoMoment = getMoment(dateTwo);
	const isSame = () => dateOneMoment.isSame(dateTwoMoment, momentKey);
	const isAfter = () => dateOneMoment.isAfter(dateTwoMoment, momentKey);
	const isBefore = () => dateOneMoment.isBefore(dateTwoMoment, momentKey);
	const isAfterOrSame = () => isAfter() || isSame();
	const isBeforeOrSame = () => isBefore() || isSame();

	return {
		isSame,
		isAfter,
		isBefore,
		isAfterOrSame,
		isBeforeOrSame,
	};
}

function getFormattedDates(date: string | Moment, formats: Array<MomentDisplayFormats>) {
	const dateMoment = getMoment(date);
	const formattedDates = formats.map(format => dateMoment.format(format));

	return formattedDates;
}

function today(): string {
	return moment().format(BASE_DATE_FORMAT);
}

function now(): string {
	return moment().format(BASE_TIME_FORMAT);
}

function nowYear(): string {
	return moment().format('YYYY');
}

function addMonthsFromToday(value: number): string {
	return moment().add(value, 'month').format(BASE_DATE_FORMAT);
}

type GetMirroredDateRangeOptions = {
	past: {
		value: number;
		type: MomentPeriodsKey;
	};
	future: {
		value: number;
		type: MomentPeriodsKey;
	};
};

function getMirroredDateRange(options: GetMirroredDateRangeOptions) {
	const { past, future } = options;
	const dateRange: DateRange = {
		dateStart: moment().subtract(past.value, past.type).startOf(past.type).format(BASE_DATE_FORMAT),
		dateEnd: moment().add(future.value, future.type).endOf(future.type).format(BASE_DATE_FORMAT),
	};

	return dateRange;
}

function pluralDate(value: string) {
	const pluralMap = {
		'01': 'января',
		'02': 'февраля',
		'03': 'марта',
		'04': 'апреля',
		'05': 'мая',
		'06': 'июня',
		'07': 'июля',
		'08': 'августа',
		'09': 'сентября',
		'10': 'октября',
		'11': 'ноября',
		'12': 'декабря',
	};
	const [day, month, yearWithTime] = value.split('-');
	const [year] = yearWithTime.split(' ');

	return `${day} ${pluralMap[month]} ${year} г.`;
}

function setupMoment() {
	moment.locale('ru', {
		months: [
			'Январь',
			'Февраль',
			'Март',
			'Апрель',
			'Май',
			'Июнь',
			'Июль',
			'Август',
			'Сентябрь',
			'Октябрь',
			'Ноябрь',
			'Декабрь',
		],
		monthsShort: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сент', 'Окт', 'Нояб', 'Дек'],
	});
}

function getStartOf(periodKey: MomentPeriodsKey, date: string | Moment = moment()) {
	const point = getMoment(date);

	return point.startOf(periodKey).format(BASE_DATE_FORMAT);
}

function getEndOf(periodKey: MomentPeriodsKey, date: string | Moment = moment()) {
	const point = getMoment(date);

	return point.endOf(periodKey).format(BASE_DATE_FORMAT);
}

function getTimestamp() {
	return new Date().getTime();
}

export {
	BASE_DATE_FORMAT,
	BASE_TIME_FORMAT,
	createDefaultPeriod,
	correctDateRange,
	prettyDate,
	prettyMonth,
	formatDate,
	convertDateToString,
	createDateList,
	getDateDiff,
	getDateBoundaries,
	getBoundaryDateRange,
	compareDates,
	getFormattedDates,
	today,
	now,
	nowYear,
	addMonthsFromToday,
	getMirroredDateRange,
	pluralDate,
	setupMoment,
	getStartOf,
	getEndOf,
	getTimestamp,
};
