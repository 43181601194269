import React, { memo, useEffect } from 'react';

import { useMapDispatch, useMapState } from '@core/flux';
import { GoalStep, useGoalStep } from '@core/hooks/use-goals';
import { DrawerZone } from '@funds-registers/models';
import { mainFundsRegistersSelectorsPack } from '@funds-registers/selectors';
import { mainIntegrationsActionsPack } from '@integrations/actions';
import {
	selectLastAccountStatementImport,
	selectLastImportedAccountData,
	selectLastImportedFileData,
} from '@integrations/selectors';
import { mainPaymentsActionsPack } from '@payments/actions';
import { getDrawer } from '@utils';
import { FileAppearance } from '../import-files-form/model';
import { XImportResult, XImportResultProps } from './import-result.view';

type ImportResultProps = {
	appearance?: FileAppearance;
	setZone: React.Dispatch<React.SetStateAction<DrawerZone>>;
} & Pick<XImportResultProps, 'zone' | 'status'>;

const ImportResult: React.FC<ImportResultProps> = memo(props => {
	const { appearance, setZone, status, zone } = props;
	const [fundsRegisterStatisticsMap, lastImportedAccountData, lastImportedFileData, lastAccountStatementImport] =
		useMapState([
			mainFundsRegistersSelectorsPack.selectFundsRegisterStatisticsMap,
			selectLastImportedAccountData,
			selectLastImportedFileData,
			selectLastAccountStatementImport,
		]);
	const [clearLastImportedAccountData, clearLastImportedFileData, setFundsRegisterID] = useMapDispatch([
		mainIntegrationsActionsPack.clearLastImportedAccountData,
		mainIntegrationsActionsPack.clearLastImportedFileData,
		mainPaymentsActionsPack.setFundsRegisterID,
	]);
	const handleGoalStepOnFail = useGoalStep(
		(zone === DrawerZone.IMPORT_FILE_RESULT && goalStepOnFileFailMap[appearance]) ||
			(zone === DrawerZone.REFRESH_CMS_RESULT && GoalStep.SYNC_ERROR),
	);
	const handleGoalStepOnSuccess = useGoalStep(
		(zone === DrawerZone.IMPORT_FILE_RESULT && goalStepOnFileSuccessMap[appearance]) ||
			(zone === DrawerZone.REFRESH_CMS_RESULT && GoalStep.SYNC_SUCCESS),
	);

	useEffect(() => {
		const isSuccess = status.status === 'SUCCESS';
		const isFailed = status.status === 'FAILED';

		isSuccess && handleGoalStepOnSuccess();
		isFailed && handleGoalStepOnFail();
	}, [status]);

	const handleClose = () => {
		clearLastImportedAccountData();
		clearLastImportedFileData();
		getDrawer().close();
	};

	const handleAddAccount = () => {
		setZone(DrawerZone.ACOUNTS);
		clearLastImportedAccountData();
	};

	const handleAddImportFile = () => {
		setZone(DrawerZone.IMPORT_FILE);
		clearLastImportedFileData();
	};

	const handleRepeat = () => {
		switch (zone) {
			case DrawerZone.IMPORT_CMS_RESULT:
				setZone(DrawerZone.IMPORT_CMS);
				clearLastImportedAccountData();
				break;
			case DrawerZone.REFRESH_CMS_RESULT:
				setZone(DrawerZone.REFRESH_CMS);
				clearLastImportedAccountData();
				break;
			case DrawerZone.IMPORT_FILE_RESULT:
				handleAddImportFile();
				clearLastImportedFileData();
				break;
		}
	};

	return (
		<XImportResult
			zone={zone}
			status={status}
			fundsRegisterStatisticsMap={fundsRegisterStatisticsMap}
			lastImportedAccountData={lastImportedAccountData}
			lastImportedFileData={lastImportedFileData}
			lastAccountStatementImport={lastAccountStatementImport}
			setFundsRegisterID={setFundsRegisterID}
			onClose={handleClose}
			onAddAccount={handleAddAccount}
			onAddImportFile={handleAddImportFile}
			onRepeat={handleRepeat}
		/>
	);
});

const goalStepOnFileFailMap = {
	[FileAppearance.EXCEL]: GoalStep.IMPORT_EXCEL_FAIL,
	[FileAppearance.TXT]: GoalStep.IMPORT_1C_FAIL,
};

const goalStepOnFileSuccessMap = {
	[FileAppearance.EXCEL]: GoalStep.IMPORT_EXCEL_SUCCESS,
	[FileAppearance.TXT]: GoalStep.IMPORT_1C_SUCCESS,
};

export { ImportResult };
