const PAYMENT = 'PAYMENT';
const PAYMENT_BRIEF = 'PAYMENT_BRIEF';
const BUDGET_PAYMENT_BRIEF = 'BUDGET_PAYMENT_BRIEF';
const TRANSFER_CASHFLOW_OPERATION = 'TRANSFER_CASHFLOW_OPERATION';
const TRANSFER_CASHFLOW_OPERATION_BRIEF = 'TRANSFER_CASHFLOW_OPERATION_BRIEF';

function detectIsPayment(cashflowOperation: CashflowOperation): cashflowOperation is Payment {
	return cashflowOperation?.CLASSIFIER === PAYMENT;
}

function detectIsPaymentBrief(cashflowOperation: CashflowOperationBrief): cashflowOperation is PaymentBrief {
	return cashflowOperation?.CLASSIFIER === PAYMENT_BRIEF || cashflowOperation?.CLASSIFIER === BUDGET_PAYMENT_BRIEF;
}

function detectIsTransfer(cashflowOperation: CashflowOperation): cashflowOperation is TransferCashflowOperation {
	return cashflowOperation?.CLASSIFIER === TRANSFER_CASHFLOW_OPERATION;
}

function detectIsTransferBrief(
	cashflowOperation: CashflowOperationBrief,
): cashflowOperation is TransferCashflowOperationBrief {
	return cashflowOperation?.CLASSIFIER === TRANSFER_CASHFLOW_OPERATION_BRIEF;
}

function detectIsIncomeCashflowOperation(cashflowOperation: CashflowOperation | CashflowOperationBrief) {
	const payment = cashflowOperation as Payment;
	const transfer = cashflowOperation as TransferCashflowOperation;

	return detectIsTransfer(transfer) ? !transfer.Expense : payment.Direction === 2;
}

function detectIsIncomeCashflowOperationBrief(cashflowOperation: CashflowOperationBrief) {
	const payment = cashflowOperation as PaymentBrief;
	const transfer = cashflowOperation as TransferCashflowOperationBrief;

	return detectIsTransferBrief(transfer) ? !transfer.Expense : payment.Direction === 2;
}

function getCashflowOperationBriefFlags(cashflowOperation: CashflowOperationBrief) {
	const isTransfer = detectIsTransferBrief(cashflowOperation);
	const isPayment = !isTransfer;
	const transfer = cashflowOperation as TransferCashflowOperationBrief;
	const payment = cashflowOperation as PaymentBrief;
	const isIncome = isTransfer ? !transfer.Expense : payment.Direction === 2;
	const isExpense = isTransfer ? transfer.Expense : payment.Direction === 1;

	return {
		isTransfer,
		isPayment,
		isIncome,
		isExpense,
	};
}

export {
	detectIsPayment,
	detectIsPaymentBrief,
	detectIsTransfer,
	detectIsTransferBrief,
	detectIsIncomeCashflowOperation,
	detectIsIncomeCashflowOperationBrief,
	getCashflowOperationBriefFlags,
};
