import styled, { css } from 'styled-components';

const Label = styled.div`
	flex: 1 1 auto;
	font-size: 13px;
	font-weight: 500;
	text-transform: uppercase;
	padding: 10px 16px;
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	align-items: center;
`;

const Separator = styled.div``;

type ActionMenuItemRootProps = {
	minWidth: string;
};

const ActionMenuItemRoot = styled.div<ActionMenuItemRootProps>`
	position: relative;
	width: 100%;
	min-width: 200px;
	font-size: 14px;
	line-height: 1.43;
	transition: background-color 0.2s ease-in-out;
	cursor: pointer;

	${p => css`
		&:hover {
			background-color: ${p.theme.palette.stealth};
		}
	`}

	${p =>
		p.minWidth &&
		css`
			min-width: ${p.minWidth};
		`}
`;

type ActionMenuItemIconLayoutProps = {
	marginLeft: string;
};

const ActionMenuItemIconLayout = styled.div<ActionMenuItemIconLayoutProps>`
	font-size: 0;
	margin-right: 10px;

	${p =>
		p.marginLeft &&
		css`
			margin-left: ${p.marginLeft};
		`}
`;

type IActionMenuItemContentLayoutProps = {
	disabled: boolean;
};

const ActionMenuItemContentLayout = styled.div<IActionMenuItemContentLayoutProps>`
	width: 100%;
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: center;
	padding: 12px 16px;

	${p =>
		p.disabled &&
		css`
			color: ${p.theme.palette.label};
		`}
`;

export { Label, Separator, ActionMenuItemRoot, ActionMenuItemIconLayout, ActionMenuItemContentLayout };
