import { CSSProperties } from 'react';
import styled, { css } from 'styled-components';

import { DraggableScrollZone } from '@ui/draggable-scroll-zone';

const TitleFooter = styled.div`
	width: 100%;
	min-height: 40px;
	border-bottom: 1px solid transparent;
	display: flex;
	flex-flow: row wrap;
	align-items: center;

	${p => css`
		border-color: ${p.theme.palette.stealth};
	`}
`;

const PreviewContent = styled(DraggableScrollZone)`
	position: relative;
	flex: 1 1 auto;
	width: 100%;
	display: flex;
	overflow: auto;
	padding: 40px;

	${p => css`
		background-color: ${p.theme.palette.background};
	`}
`;

const PreviewCentered = styled.div`
	margin: auto;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const PreviewFooter = styled.div`
	flex: 0 0 auto;
	position: relative;
	width: 100%;
	min-height: 50px;
	display: flex;
	border-top: 1px solid transparent;

	${p => css`
		border-color: ${p.theme.palette.stealth};
	`}
`;

const Autofocus = styled.input.attrs(() => ({
	autoFocus: true,
}))`
	position: absolute;
	width: 1px;
	height: 1px;
	outline: none;
	background-color: transparent;
`;

const style: Record<string, CSSProperties> = {
	container: {
		overflow: 'visible',
		maxWidth: '100%',
	},
	overlay: {
		backgroundColor: 'rgba(62, 71, 87, 0.7)',
	},
};

export { TitleFooter, Autofocus, style, PreviewContent, PreviewCentered, PreviewFooter };
