import React, { useMemo } from 'react';

import { Autopicker, AutopickerProps, AutopickerRenderItemContentOptions } from '@ui/autopicker';
import { Box } from '@ui/box';
import { Typography } from '@ui/typography';
import { HighLightedText } from '@ui/highlighted-text';
import { TenantLegalEntityAutopickerCRUD } from '../tenant-legal-entity-autopicker-crud';

export type TenantLegalEntityAutopickerProps = {
	name?: string;
	hasChangeRight: boolean;
	disableAdd?: boolean;
	disableEdit?: boolean;
	crudTransformer: (value: LegalEntity) => any;
} & AutopickerProps<CounterpartyBrief>;

const TenantLegalEntityAutopicker: React.FC<TenantLegalEntityAutopickerProps> = props => {
	const { name: formName, hasChangeRight, disableAdd, disableEdit, crudTransformer } = props;
	const pluralItems = useMemo(() => ['организация', 'организации', 'организаций'] as [string, string, string], []);

	const renderItemContent = ({
		item,
		searchText,
		containerWidth,
	}: AutopickerRenderItemContentOptions<CounterpartyBrief>) => {
		const isSmallContainer = containerWidth < 340;

		return (
			<Box fontSize={isSmallContainer ? 12 : 13} fullWidth>
				<Box marginBottom={2}>
					<HighLightedText value={item.Name} query={searchText} />
				</Box>
				<Typography.Label component='div' fontSize={11}>
					ИНН: <HighLightedText value={item.TaxCode} query={searchText} />
				</Typography.Label>
			</Box>
		);
	};

	const getNameForSearchTextFilter = (item: CounterpartyBrief) => `${item.Name} ${item.TaxCode}`;

	return (
		<Autopicker
			labelText='Организация'
			hintText='Выберите организацию...'
			pluralItems={pluralItems}
			getNameForSearchTextFilter={getNameForSearchTextFilter}
			renderItemContent={renderItemContent}
			renderAddTrigger={
				!disableAdd && hasChangeRight
					? ({ searchText, isSmallContainer, onRelatedPopupChange }) => {
							return (
								<TenantLegalEntityAutopickerCRUD
									variant='add'
									formName={formName}
									searchText={searchText}
									transformer={crudTransformer}
									isSmallContainer={isSmallContainer}
									onRelatedPopupChange={onRelatedPopupChange}
								/>
							);
					  }
					: undefined
			}
			renderEditTrigger={
				!disableEdit && hasChangeRight
					? ({ ID, onRelatedPopupChange }) => {
							return (
								<TenantLegalEntityAutopickerCRUD
									variant='edit'
									formName={formName}
									legalEntityID={ID}
									transformer={crudTransformer}
									onRelatedPopupChange={onRelatedPopupChange}
								/>
							);
					  }
					: undefined
			}
			{...props}
			fullWidth
		/>
	);
};

export { TenantLegalEntityAutopicker };
