import React, { useLayoutEffect, useEffect } from 'react';

import { dispatchResizeEvent, useLackOfTwitching } from '@utils/dom';
import { getDrawer } from '@utils/drawer';
import { Dialog } from '@ui/dialog';
import { CloseButton } from '@ui/button/close-button';
import { getMeasureProp } from '@stylesheet';
import {
	dialogRootStyle,
	dialogContentStyle,
	dialogActionsContainerStyle,
	ContentLayout,
	paperStyle,
	CloseButtonLayout,
} from './styled';

export type ModalViewerProps = {
	isOpen: boolean;
	width?: string | number;
	innerCloseBtn?: boolean;
	modal?: boolean;
	children: React.ReactNode;
	onClose: () => void;
};

const ModalViewer: React.FC<ModalViewerProps> = props => {
	const { isOpen, width, innerCloseBtn, modal, onClose, children } = props;
	const [lack, disposeLack] = useLackOfTwitching();

	useEffect(() => {
		const isDrawerOpen = getDrawer().isOpened();
		if (isDrawerOpen) return;
		isOpen ? lack() : disposeLack();

		return () => disposeLack();
	}, [isOpen]);

	useLayoutEffect(() => {
		setTimeout(() => {
			dispatchResizeEvent();
		});
	});

	return (
		<Dialog
			open={isOpen}
			contentStyle={{ ...dialogContentStyle, width: getMeasureProp(width) }}
			bodyStyle={dialogRootStyle as React.CSSProperties}
			actionsContainerStyle={dialogActionsContainerStyle}
			autoScrollBodyContent
			paperProps={{
				style: paperStyle,
			}}
			overlayStyle={{
				backgroundColor: 'rgba(62, 71, 87, 0.7)',
			}}
			modal={modal}
			onRequestClose={onClose}>
			<ContentLayout>
				<CloseButtonLayout innerCloseBtn={innerCloseBtn}>
					<CloseButton squareMode={!innerCloseBtn} onClick={onClose} />
				</CloseButtonLayout>
				{children}
			</ContentLayout>
		</Dialog>
	);
};

export { ModalViewer };
