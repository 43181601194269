import * as _ from 'underscore';

import { accountingApi } from '@core/api/accounting';
import { safeNumber } from '@utils/numbers';
import plUtils from '@pl-planning/shared/utils';

export type CashflowRoleAggregation = {
	role: AccountsChartItemRole;
	cfItems: Array<CashflowItem>;
};

function getCashflowRoleAggregationList(
	aciRoles: Array<AccountsChartItemRole>,
	cfList: Array<CashflowItem>,
): Array<CashflowRoleAggregation> {
	const list: Array<CashflowRoleAggregation> = [];
	const groupped = _.groupBy(cfList, x => x?.Role?.ID);

	for (const role of aciRoles) {
		if (role.ID < 0) continue;

		const cfItems = groupped[role.ID] || [];

		list.push({ role, cfItems });
	}

	return list;
}

export type GetTotalByRolesOptions = {
	roleCodes: Array<string>;
	aciSeriesMap: Record<string, PLOperationAccountsChartItemSeries>;
	aciRoles: Array<AccountsChartItemRole>;
	cfList: Array<CashflowItem>;
};

function getTotalAmountByAciRoles(options: GetTotalByRolesOptions): number {
	const { roleCodes, aciSeriesMap, aciRoles, cfList } = options;
	const items = getCashflowRoleAggregationList(aciRoles, cfList);
	const totalAmountMap = getTotalAmountGroup({ items, aciSeriesMap });
	const totalAmount = Object.keys(totalAmountMap.byRoles)
		.filter(key => roleCodes.includes(key))
		.map(key => totalAmountMap.byRoles[key])
		.reduce((acc, x) => (acc += x), 0);

	return totalAmount;
}

type GetAciIdsByRoleOptions = {
	roleCode: string;
	cfiMap: Record<string, CashflowItem>;
};

function getAciIdsByRole(options: GetAciIdsByRoleOptions): Array<number> {
	const { roleCode, cfiMap } = options;
	const cfiList = Object.keys(cfiMap).map(key => cfiMap[key]);
	const grouppedCfiMap = _.groupBy(cfiList, x => (x.Role ? x.Role.Code : 'NA'));
	const items = grouppedCfiMap[roleCode] || [];
	const IDs: Array<number> = _.flatten(items.map(x => [x.ID, ...getDescendantCashflowNodeIds(x.ID, cfiMap)]));
	const uniqIDs = _.uniq(IDs);
	const filteredIDs = uniqIDs.filter(ID => (cfiMap[ID].Role ? cfiMap[ID].Role.Code === roleCode : true));

	return filteredIDs;
}

function getDescendantCashflowNodeIds(ID: number, cfiMap: Record<number, CashflowItem>, arr: Array<number> = []) {
	let list = [];

	if (cfiMap[ID]) {
		for (let i = 0; i < cfiMap[ID].ChildItems.length; i++) {
			const childItem = cfiMap[ID].ChildItems[i];

			if (!arr.some(ID => ID === childItem.ID)) {
				list.push(childItem.ID);

				list = [...list, ...getDescendantCashflowNodeIds(childItem.ID, cfiMap, list)];
			}
		}
	}

	return list;
}

type getTotalAmountMapOptions = {
	items: Array<CashflowRoleAggregation>;
	aciSeriesMap: Record<string, PLOperationAccountsChartItemSeries>;
};

function getTotalAmountGroup(options: getTotalAmountMapOptions) {
	const { items, aciSeriesMap } = options;
	const map = {
		byRoles: {},
		byItems: {},
	};

	for (const item of items) {
		const totalAmount = item.cfItems.reduce((acc, cfItem) => {
			const series = aciSeriesMap[cfItem.ID] ? [aciSeriesMap[cfItem.ID]] : [];
			const totalByItems = plUtils.getTotalAmountBySeries(series);
			const total = acc + totalByItems;

			map.byItems[cfItem.ID] = safeNumber(totalByItems);

			return total;
		}, 0);

		map.byRoles[item.role.Code] = safeNumber(totalAmount);
	}

	return map;
}

type GetNoRolesTotalAmountOptions = {
	isIncome: boolean;
	cfList: Array<CashflowItem>;
	aciSeriesMap: Record<string, PLOperationAccountsChartItemSeries>;
};

function getTotalAmountByNoRolesItems(options: GetNoRolesTotalAmountOptions) {
	const { isIncome, cfList, aciSeriesMap } = options;
	const key = isIncome ? -1 : -2;
	const hasUnknownItem = cfList.some(x => x.ID < 0);
	const patchedList = hasUnknownItem ? cfList : [...cfList, { ...new accountingApi.package.CashflowItem(), ID: -1 }];
	const total = patchedList
		.filter(x => x.ID < 0 || !x.Role || x.Role.ID < 0)
		.reduce((acc, { ID }) => {
			const series = ID < 0 ? aciSeriesMap[key] : aciSeriesMap[ID];

			if (!series) return acc;

			const total = series.Points.filter(p => p.DirectionIncoming === isIncome && !p.Plan).reduce(
				(acc, p) => (acc += p.Amount),
				0,
			);

			return safeNumber((acc += total));
		}, 0);

	return total;
}

function getDescendantCashflowNodeIDList(ID: number, cfiMap: Record<number, CashflowItem>, arr: Array<number> = []) {
	let list = [];

	if (cfiMap[ID]) {
		for (let i = 0; i < cfiMap[ID].ChildItems.length; i++) {
			const childItem = cfiMap[ID].ChildItems[i];

			if (!arr.some(ID => ID === childItem.ID)) {
				list.push(childItem.ID);

				list = [...list, ...getDescendantCashflowNodeIDList(childItem.ID, cfiMap, list)];
			}
		}
	}

	return list;
}

const aciUtils = {
	getDescendantCashflowNodeIDList,
	getCashflowRoleAggregationList,
	getTotalAmountByAciRoles,
	getDescendantCashflowNodeIds,
	getAciIdsByRole,
	getTotalAmountGroup,
	getTotalAmountByNoRolesItems,
};

export { aciUtils };
