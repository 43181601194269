'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _simpleAssign = require('simple-assign');

var _simpleAssign2 = _interopRequireDefault(_simpleAssign);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactEventListener = require('react-event-listener');

var _reactEventListener2 = _interopRequireDefault(_reactEventListener);

var _keycode = require('keycode');

var _keycode2 = _interopRequireDefault(_keycode);

var _transitions = require('../styles/transitions');

var _transitions2 = _interopRequireDefault(_transitions);

var _FocusRipple = require('./FocusRipple');

var _FocusRipple2 = _interopRequireDefault(_FocusRipple);

var _TouchRipple = require('./TouchRipple');

var _TouchRipple2 = _interopRequireDefault(_TouchRipple);

var _Paper = require('./../Paper');

var _Paper2 = _interopRequireDefault(_Paper);

var _warning = require('warning');

var _warning2 = _interopRequireDefault(_warning);

function _interopRequireDefault(obj) {
	return obj && obj.__esModule ? obj : {
		default: obj
	};
}

function getStyles(props, context, hasLabel) {
	var baseTheme = context.muiTheme.baseTheme;

	return {
		root: {
			cursor: props.disabled ? 'not-allowed' : 'pointer',
			position: 'relative',
			overflow: 'visible',
			display: 'table',
			height: 'auto',
			width: '100%'
		},
		input: {
			position: 'absolute',
			cursor: 'inherit',
			pointerEvents: 'all',
			opacity: 0,
			width: '100%',
			height: '100%',
			zIndex: 2,
			left: 0,
			boxSizing: 'border-box',
			padding: 0,
			margin: 0
		},
		controls: {
			display: 'flex',
			width: '100%',
			height: '100%'
		},
		label: {
			float: 'left',
			position: 'relative',
			display: 'block',
			width: 'calc(100% - 60px)',
			lineHeight: '24px',
			color: baseTheme.palette.textColor,
			fontFamily: baseTheme.fontFamily
		},
		wrap: {
			transition: _transitions2.default.easeOut(),
			float: 'left',
			position: 'relative',
			display: 'block',
			flexShrink: 0,
			width: 60 - baseTheme.spacing.desktopGutterLess,
			marginRight: props.labelPosition === 'right' && hasLabel ? baseTheme.spacing.desktopGutterLess : 0,
			marginLeft: props.labelPosition === 'left' && hasLabel ? baseTheme.spacing.desktopGutterLess : 0
		},
		ripple: {
			color: props.rippleColor || baseTheme.palette.primary1Color,
			height: '200%',
			width: '200%',
			top: -12,
			left: -12
		}
	};
}

var EnhancedSwitch = function (_Component) {
	(0, _inherits3.default)(EnhancedSwitch, _Component);

	function EnhancedSwitch() {
		var _ref;

		var _temp, _this, _ret;

		(0, _classCallCheck3.default)(this, EnhancedSwitch);

		for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
			args[_key] = arguments[_key];
		}

		return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref = EnhancedSwitch.__proto__ || (0, _getPrototypeOf2.default)(EnhancedSwitch)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
			isKeyboardFocused: false
		}, _this.handleChange = function (event) {
			_this.tabPressed = false;
			_this.setState({
				isKeyboardFocused: false
			});

			var isInputChecked = _this.refs.checkbox.checked;

			if (!_this.props.hasOwnProperty('checked') && _this.props.onParentShouldUpdate) {
				_this.props.onParentShouldUpdate(isInputChecked);
			}

			if (_this.props.onSwitch) {
				_this.props.onSwitch(event, isInputChecked);
			}
		}, _this.handleKeyDown = function (event) {
			var code = (0, _keycode2.default)(event);

			if (code === 'tab') {
				_this.tabPressed = true;
			}
			if (_this.state.isKeyboardFocused && code === 'space') {
				_this.handleChange(event);
			}
		}, _this.handleKeyUp = function (event) {
			if (_this.state.isKeyboardFocused && (0, _keycode2.default)(event) === 'space') {
				_this.handleChange(event);
			}
		}, _this.handleMouseDown = function (event) {
			// only listen to left clicks
			if (event.button === 0) {
				_this.refs.touchRipple.start(event);
			}
		}, _this.handleMouseUp = function () {
			_this.refs.touchRipple.end();
		}, _this.handleMouseLeave = function () {
			_this.refs.touchRipple.end();
		}, _this.handleTouchStart = function (event) {
			_this.refs.touchRipple.start(event);
		}, _this.handleTouchEnd = function () {
			_this.refs.touchRipple.end();
		}, _this.handleBlur = function (event) {
			_this.setState({
				isKeyboardFocused: false
			});

			if (_this.props.onBlur) {
				_this.props.onBlur(event);
			}
		}, _this.handleFocus = function (event) {
			// setTimeout is needed becuase the focus event fires first
			// Wait so that we can capture if this was a keyboard focus
			// or touch focus
			setTimeout(function () {
				if (_this.tabPressed) {
					_this.setState({
						isKeyboardFocused: true
					});
				}
			}, 150);

			if (_this.props.onFocus) {
				_this.props.onFocus(event);
			}
		}, _temp), (0, _possibleConstructorReturn3.default)(_this, _ret), (function () {
			// emulate component will mount
			if (!_this.state) {
				_this.state = {};
			}
			var switched = _this.props.checked || _this.props.defaultChecked || false;
			_this.state.switched = switched;
		})();
	}

	(0, _createClass3.default)(EnhancedSwitch, [{
		key: 'componentDidMount',
		value: function componentDidMount() {
			var inputNode = this.refs.checkbox;
			if ((!this.props.switched || inputNode.checked !== this.props.switched) && this.props.onParentShouldUpdate) {
				this.props.onParentShouldUpdate(inputNode.checked);
			}
		}
	}, {
		key: 'componentDidUpdate',
		value: function componentDidUpdate(prevProps) {
			var hasCheckedProp = this.props.hasOwnProperty('checked');
			var hasNewDefaultProp = this.props.hasOwnProperty('defaultChecked') && this.props.defaultChecked !== prevProps.defaultChecked;

			if (hasCheckedProp || hasNewDefaultProp) {
				var switched = this.props.checked || this.props.defaultChecked || false;

				if (switched !== this.state.switched) {
					this.setState({
						switched: switched
					});
				}

				if (prevProps.onParentShouldUpdate && switched !== prevProps.switched) {
					prevProps.onParentShouldUpdate(switched);
				}
			}
		}
	}, {
		key: 'isSwitched',
		value: function isSwitched() {
			return this.refs.checkbox.checked;
		}

		// no callback here because there is no event

	}, {
		key: 'setSwitched',
		value: function setSwitched(newSwitchedValue) {
			if (!this.props.hasOwnProperty('checked') || this.props.checked === false) {
				if (this.props.onParentShouldUpdate) {
					this.props.onParentShouldUpdate(newSwitchedValue);
				}
				this.refs.checkbox.checked = newSwitchedValue;
			} else {
				process.env.NODE_ENV !== "production" ? (0, _warning2.default)(false, 'Material-UI: Cannot call set method while checked is defined as a property.') : void 0;
			}
		}
	}, {
		key: 'getValue',
		value: function getValue() {
			return this.refs.checkbox.value;
		}

		// Checkbox inputs only use SPACE to change their state. Using ENTER will
		// update the ui but not the input.


		/**
		 * Because both the ripples and the checkbox input cannot share pointer
		 * events, the checkbox input takes control of pointer events and calls
		 * ripple animations manually.
		 */

	}, {
		key: 'render',
		value: function render() {
			var _props = this.props,
				name = _props.name,
				value = _props.value,
				checked = _props.checked,
				iconStyle = _props.iconStyle,
				inputStyle = _props.inputStyle,
				inputType = _props.inputType,
				label = _props.label,
				labelStyle = _props.labelStyle,
				labelPosition = _props.labelPosition,
				onSwitch = _props.onSwitch,
				onBlur = _props.onBlur,
				onFocus = _props.onFocus,
				onMouseUp = _props.onMouseUp,
				onMouseDown = _props.onMouseDown,
				onMouseLeave = _props.onMouseLeave,
				onTouchStart = _props.onTouchStart,
				onTouchEnd = _props.onTouchEnd,
				onParentShouldUpdate = _props.onParentShouldUpdate,
				disabled = _props.disabled,
				disableTouchRipple = _props.disableTouchRipple,
				disableFocusRipple = _props.disableFocusRipple,
				className = _props.className,
				rippleColor = _props.rippleColor,
				rippleStyle = _props.rippleStyle,
				style = _props.style,
				switched = _props.switched,
				switchElement = _props.switchElement,
				thumbStyle = _props.thumbStyle,
				trackStyle = _props.trackStyle,
				other = (0, _objectWithoutProperties3.default)(_props, ['name', 'value', 'checked', 'iconStyle', 'inputStyle', 'inputType', 'label', 'labelStyle', 'labelPosition', 'onSwitch', 'onBlur', 'onFocus', 'onMouseUp', 'onMouseDown', 'onMouseLeave', 'onTouchStart', 'onTouchEnd', 'onParentShouldUpdate', 'disabled', 'disableTouchRipple', 'disableFocusRipple', 'className', 'rippleColor', 'rippleStyle', 'style', 'switched', 'switchElement', 'thumbStyle', 'trackStyle']);
			var prepareStyles = this.context.muiTheme.prepareStyles;
			var hasLabel = Boolean(label);

			var styles = getStyles(this.props, this.context, hasLabel);
			var wrapStyles = (0, _simpleAssign2.default)(styles.wrap, iconStyle);
			var mergedRippleStyle = (0, _simpleAssign2.default)(styles.ripple, rippleStyle);

			if (thumbStyle) {
				wrapStyles.marginLeft /= 2;
				wrapStyles.marginRight /= 2;
			}

			var labelElement = label && _react2.default.createElement(
				'label', {
					style: prepareStyles((0, _simpleAssign2.default)(styles.label, labelStyle))
				},
				label
			);

			var showTouchRipple = !disabled && !disableTouchRipple;
			var showFocusRipple = !disabled && !disableFocusRipple;

			var touchRipple = _react2.default.createElement(_TouchRipple2.default, {
				ref: 'touchRipple',
				key: 'touchRipple',
				style: mergedRippleStyle,
				color: mergedRippleStyle.color,
				muiTheme: this.context.muiTheme,
				centerRipple: true
			});

			var focusRipple = _react2.default.createElement(_FocusRipple2.default, {
				key: 'focusRipple',
				innerStyle: mergedRippleStyle,
				color: mergedRippleStyle.color,
				muiTheme: this.context.muiTheme,
				show: this.state.isKeyboardFocused
			});

			var ripples = [showTouchRipple ? touchRipple : null, showFocusRipple ? focusRipple : null];

			var inputElement = _react2.default.createElement('input', (0, _extends3.default)({}, other, {
				ref: 'checkbox',
				type: inputType,
				style: prepareStyles((0, _simpleAssign2.default)(styles.input, inputStyle)),
				name: name,
				value: value,
				checked: this.state.switched,
				disabled: disabled,
				onBlur: this.handleBlur,
				onFocus: this.handleFocus,
				onChange: this.handleChange,
				onMouseUp: showTouchRipple ? this.handleMouseUp : undefined,
				onMouseDown: showTouchRipple ? this.handleMouseDown : undefined,
				onMouseLeave: showTouchRipple ? this.handleMouseLeave : undefined,
				onTouchStart: showTouchRipple ? this.handleTouchStart : undefined,
				onTouchEnd: showTouchRipple ? this.handleTouchEnd : undefined
			}));

			// If toggle component (indicated by whether the style includes thumb) manually lay out
			// elements in order to nest ripple elements
			var switchOrThumbElement = !thumbStyle ? _react2.default.createElement(
				'div', {
					style: prepareStyles(wrapStyles)
				},
				switchElement,
				ripples
			) : _react2.default.createElement(
				'div', {
					style: prepareStyles(wrapStyles)
				},
				_react2.default.createElement('div', {
					style: prepareStyles((0, _simpleAssign2.default)({}, trackStyle))
				}),
				_react2.default.createElement(
					_Paper2.default, {
						style: thumbStyle,
						zDepth: 1,
						circle: true
					},
					' ',
					ripples,
					' '
				)
			);

			var elementsInOrder = labelPosition === 'right' ? _react2.default.createElement(
				'div', {
					style: styles.controls
				},
				switchOrThumbElement,
				labelElement
			) : _react2.default.createElement(
				'div', {
					style: styles.controls
				},
				labelElement,
				switchOrThumbElement
			);

			return _react2.default.createElement(
				'div', {
					ref: 'root',
					className: className,
					style: prepareStyles((0, _simpleAssign2.default)(styles.root, style))
				},
				_react2.default.createElement(_reactEventListener2.default, {
					target: 'window',
					onKeyDown: this.handleKeyDown,
					onKeyUp: this.handleKeyUp
				}),
				inputElement,
				elementsInOrder
			);
		}
	}]);
	return EnhancedSwitch;
}(_react.Component);

var MuiThemeContext = require('../styles/context').default;

EnhancedSwitch.contextType = MuiThemeContext;

EnhancedSwitch.propTypes = process.env.NODE_ENV !== "production" ? {
	checked: _propTypes2.default.bool,
	className: _propTypes2.default.string,
	defaultChecked: _propTypes2.default.bool,
	disableFocusRipple: _propTypes2.default.bool,
	disableTouchRipple: _propTypes2.default.bool,
	disabled: _propTypes2.default.bool,
	iconStyle: _propTypes2.default.object,
	inputStyle: _propTypes2.default.object,
	inputType: _propTypes2.default.string.isRequired,
	label: _propTypes2.default.node,
	labelPosition: _propTypes2.default.oneOf(['left', 'right']),
	labelStyle: _propTypes2.default.object,
	name: _propTypes2.default.string,
	onBlur: _propTypes2.default.func,
	onFocus: _propTypes2.default.func,
	onMouseDown: _propTypes2.default.func,
	onMouseLeave: _propTypes2.default.func,
	onMouseUp: _propTypes2.default.func,
	onParentShouldUpdate: _propTypes2.default.func,
	onSwitch: _propTypes2.default.func,
	onTouchEnd: _propTypes2.default.func,
	onTouchStart: _propTypes2.default.func,
	rippleColor: _propTypes2.default.string,
	rippleStyle: _propTypes2.default.object,
	style: _propTypes2.default.object,
	switchElement: _propTypes2.default.element.isRequired,
	switched: _propTypes2.default.bool.isRequired,
	thumbStyle: _propTypes2.default.object,
	trackStyle: _propTypes2.default.object,
	value: _propTypes2.default.any
} : {};
exports.default = EnhancedSwitch;