import styled from 'styled-components';
import { isIE } from 'react-device-detect';

import * as c from '@ui/basic-theme/colors';

const Root = styled.div`
	position: relative;
	width: 100%;
	height: auto;
`;

const RightContentLayout = styled.div`

`;

const ContentLayout = styled.div`
	position: relative;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
`;

const BillingPreviewLayout = styled.div`
	flex: 0 0 auto;
`;

const FastBillFormLayout = styled.div`
	flex: 1 1 auto;
	margin-left: 30px;

	${() => isIE && `
		position: absolute;
		right: 0;
		width: calc(50% - 30px);
		margin-left: 0;
	`}
`;

const ResultViewLayout = styled.div`
	position: relative;
	width: 100%;
	height: auto;
	min-height: 350px;
	display: flex;
	align-items: center;
`;


export {
	Root,
	RightContentLayout,
	ContentLayout,
	BillingPreviewLayout,
	FastBillFormLayout,
	ResultViewLayout
}
