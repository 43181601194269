import { createTypes } from '@flux';

type ActionTypes = {
	IMPORT_ACCOUNTS_FROM_CMS_TASK_COMPLETED: string;
	IMPORT_HISTORICAL_ACCOUNT_STATEMENT_FROM_CMS_TASK_COMPLETED: string;
	NOTIFY_SUPPORTS_BY_EMAILS: string;
	RUN_CHECK_SERVER_TASKS_EFFECT: string;
};

export const types = createTypes<ActionTypes>(
	[
		'IMPORT_ACCOUNTS_FROM_CMS_TASK_COMPLETED',
		'IMPORT_HISTORICAL_ACCOUNT_STATEMENT_FROM_CMS_TASK_COMPLETED',
		'NOTIFY_SUPPORTS_BY_EMAILS',
		'RUN_CHECK_SERVER_TASKS_EFFECT',
	],
	'🎃PLATFORM',
);
