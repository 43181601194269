// @ts-nocheck
import React from 'react';
import * as _ from 'underscore';

import { declOfNum } from '@utils';
import SelectFieldMUI from 'material-ui/SelectField';
import MenuItemMUI from 'material-ui/MenuItem';
import { IconButton } from '@ui/icon-button';
import * as inputUtils from '@ui/basic-theme/input-styles';
import { sizes, ISizes } from '@ui/basic-theme/sizes';
import { CloseIcon } from '@ui/icons/close';
import { DropdownIcon } from '@ui/icons/dropdown';
import { IBaseControl } from '../';
import { FormElementContext } from '@ui/forms/inputs';
import { FloatingLabelText } from '@ui/internal/floating-label-text';
import { withTheme, WithThemeProps } from '@theme';
import { Root, ClearButtonLayout } from './styled';

type SelectFieldValue = number | string | Array<number> | Array<string>;

export type SelectFieldProps<T> = {
	isLoaded?: boolean;
	isFetching?: boolean;
	dataSource: Array<T>;
	dataSourceConfig?: { text: string; value: string };
	style?: React.CSSProperties;
	className?: string;
	multiple?: boolean;
	maxHeight?: number;
	menuItemStyle?: React.CSSProperties;
	selectedMenuItemStyle?: React.CSSProperties;
	selectionRenderer?: (value: any) => React.ReactNode;
	itemsMsg?: [string, string, string];
	required?: boolean;
	helpMark?: React.ReactNode;
	dropDownAutoWidth?: boolean;
	popoverProps?: {
		scrollableContainer: HTMLElement | 'window';
	} & Partial<__MaterialUI.Popover.PopoverProps>;
	onChange: (value: SelectFieldValue, item?: T | Array<T>) => void;
} & IBaseControl.props<SelectFieldValue> &
	WithThemeProps;

class SelectField<T> extends React.Component<SelectFieldProps<T>> {
	static contextType = FormElementContext;
	static defaultProps = {
		dropDownAutoWidth: false,
		itemsMsg: ['элемент', 'элемента', 'элементов'],
	} as any;
	valueField: string = this.props.dataSourceConfig ? this.props.dataSourceConfig.value : 'value';
	textField: string = this.props.dataSourceConfig ? this.props.dataSourceConfig.text : 'text';
	rootRef: HTMLElement = null;

	componentDidMount() {
		this.setRef();
	}

	setRef() {
		if (this.context && this.context.setWrappedElem) {
			this.context.setWrappedElem(this);
		}
	}

	setRootRef = (ref: HTMLElement) => (this.rootRef = ref);

	focus() {
		this.rootRef &&
			this.rootRef.scrollIntoView({
				behavior: 'smooth',
				block: 'center',
			});
	}

	handleChange = (event, index, value) => {
		const { multiple, dataSource, onChange } = this.props;

		if (!multiple) {
			const item = _.find(dataSource, d => d[this.valueField] === value);

			onChange(value, item);
		} else {
			const items = dataSource.filter(d => !!_.find(value, v => v === d[this.valueField]));

			onChange(value, items);
		}
	};

	handleClear = () => {
		const { multiple, onChange } = this.props;

		if (multiple) {
			onChange([]);
		} else {
			onChange(-1);
		}
	};

	getText = (value: SelectFieldValue): string => {
		const item = this.props.dataSource.find(x => x[this.valueField] === value);

		return item ? item[this.textField] : '';
	};

	renderSelection = (sourceValue: SelectFieldValue) => {
		const { itemsMsg, selectionRenderer } = this.props;
		const value = sourceValue;
		const values = (sourceValue || []) as Array<string | number>;
		const length = values.length;

		if (typeof selectionRenderer === 'function') return selectionRenderer(value);
		if (!Array.isArray(sourceValue)) return typeof value !== 'undefined' ? this.getText(value) : '';
		if (length === 0) return '';
		if (length === 1) return this.getText(values[0]);

		return `Выбрано ${length} ${declOfNum(length, itemsMsg)}`;
	};

	render() {
		const { fullWidth, withClearBtn, labelText, readonly, required, helpMark, dropDownAutoWidth, popoverProps, theme } =
			this.props;
		const items =
			this.props.isLoaded !== false &&
			_.map(this.props.dataSource, i => {
				return (
					<MenuItemMUI
						key={i[this.valueField]}
						value={i[this.valueField]}
						primaryText={i[this.textField]}
						innerDivStyle={{ width: '100%', padding: '8px 16px' }}
					/>
				);
			});
		const status = inputUtils.getHintStatus({
			isError: !!this.props.errorText,
			isInformation: !!this.props.informationText,
			isSuccess: !!this.props.successText,
			isWarning: !!this.props.warningText,
		});
		const statusText = inputUtils.getHintStatusText(this.props);
		const statusStyle = inputUtils.getHintStatusStyles(status, { danger: this.props.errorStyle }, theme);

		const size: keyof ISizes<number> = this.props.size;
		const style = this.props.style || {};

		if (size) {
			style.width = sizes[size];
		}
		if (this.props.fullWidth) {
			style.width = '100%';
			style.minWidth = 'none';
		}
		style.fontSize = 14;
		const labelStyle = {
			paddingRight: '32px',
			color: this.props.disabled && 'inherit',
		};
		const floatingLabelText = (
			<FloatingLabelText labelText={labelText} readonly={readonly} required={required} helpMark={helpMark} />
		);

		return (
			<Root ref={this.setRootRef} fullWidth={fullWidth}>
				<SelectFieldMUI
					className={
						(this.props.className ? this.props.className + ' ' : '') +
						'select' +
						(this.props.disabled ? ' select_disabled' : '') +
						(this.props.readonly ? ' select_readonly' : '')
					}
					value={this.props.value}
					onChange={this.handleChange}
					disabled={this.props.disabled || this.props.readonly}
					errorText={statusText}
					errorStyle={{ position: 'absolute', bottom: -9, ...statusStyle }}
					floatingLabelText={floatingLabelText}
					floatingLabelFixed={true}
					floatingLabelStyle={{ width: '133%' }}
					selectionRenderer={this.renderSelection}
					floatingLabelFocusStyle={statusStyle}
					style={style}
					labelStyle={labelStyle}
					hintText={this.props.hintText}
					multiple={this.props.multiple}
					maxHeight={this.props.maxHeight}
					menuItemStyle={{
						...this.props.menuItemStyle,
						width: '100%',
						fontSize: 14,
						lineHeight: 1.2,
						minHeight: 0,
						whiteSpace: 'normal',
					}}
					selectedMenuItemStyle={this.props.selectedMenuItemStyle}
					dropDownMenuProps={{
						autoWidth: dropDownAutoWidth,
						iconButton: <DropdownIcon size={24} />,
					}}
					popoverProps={popoverProps}>
					{items}
				</SelectFieldMUI>
				{withClearBtn && (
					<ClearButtonLayout>
						<IconButton variant='rounded' isSilentHover onClick={this.handleClear}>
							<CloseIcon size={16} />
						</IconButton>
					</ClearButtonLayout>
				)}
			</Root>
		);
	}
}

const XSelectField = withTheme(SelectField);

export { XSelectField as SelectField };
