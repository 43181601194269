import React, { useRef, useState, useMemo, useEffect, useCallback, useImperativeHandle } from 'react';

import { createObjectMap, extractKeysToArray, hasBooleanKeys, extractUniq } from '@utils/object';
import { plural } from '@utils/plural';
import { detectIsFunction } from '@utils/helpers';
import { sortAscBy } from '@utils/sorting';
import { Box } from '@ui/box';
import { TextField, TextFieldRef } from '@ui/input-field';
import { IconButton } from '@ui/icon-button';
import { RaisedButton } from '@ui/raised-button';
import { DropdownIcon } from '@ui/icons/dropdown';
import { SearchBar } from '@ui/search-bar';
import { FocusTrap } from '@ui/focus-trap';
import { Popper } from '@ui/popper';
import { ResizeContainerSensor, ResizeContainerSensorOptions } from '@ui/resize-container-sensor';
import { SoftFetching } from '@ui/soft-fetching';
import { AutopickerFlatList } from './autopicker-flat-list';
import { ListImperativeRef } from './shared';
import { Root, Anchor, TextFieldCover, UnderlinedTextLayout } from './styled';

export type AutopickerProps<T = any, P = any> = {
	value: P;
	getID?: (item: T) => SimpleID;
	getName?: (item: T) => string;
	getNameForSearchTextFilter?: (item: T) => string;
	dataSource: Array<T>;
	labelText?: React.ReactNode;
	hintText?: string;
	searchBarHintText?: string;
	errorText?: string;
	helpMark?: React.ReactNode;
	isFetching?: boolean;
	isUpdating?: boolean;
	isLoaded?: boolean;
	didInvalidate?: boolean;
	disabled?: boolean;
	readonly?: boolean;
	multiple?: boolean;
	required?: boolean;
	fullWidth?: boolean;
	pluralItems?: [string, string, string];
	setDefaultValue?: 'first' | 'single';
	maxItems?: number;
	disableReset?: boolean;
	saveOriginalSorting?: boolean;
	innerRef?: React.MutableRefObject<AutopickerInnerRef>;
	createUnselectItem?: () => T;
	renderUnderlinedText?: (items: Array<T>) => React.ReactNode;
	transformInput?: (options: AutopickerTransformInputOptions<P>) => AutopickerDefaultValueType;
	transformOutput?: (options: AutopickerTransformOutputOptions<T>) => P;
	renderItemContent?: (options: AutopickerRenderItemContentOptions) => React.ReactNode;
	renderAddTrigger?: (options: RenderTriggerOptions) => React.ReactNode;
	renderEditTrigger?: (options: RenderTriggerOptions) => React.ReactNode;
	renderRemoveTrigger?: (options: RenderTriggerOptions) => React.ReactNode;
	renderAddNestedTrigger?: (options: RenderTriggerOptions) => React.ReactNode;
	onChange: (options: AutopickerOnChangeOptions<T>) => void;
	onAsyncRequest?: (searchText: string) => void;
	children?: (props: RequiredListRendererProps<T>) => React.ReactElement<RequiredListRendererProps<T>>;
};

const Autopicker: AutopickerComponent = props => {
	const {
		value: sourceValue,
		labelText,
		hintText,
		searchBarHintText,
		errorText,
		helpMark,
		isFetching,
		isUpdating,
		isLoaded,
		didInvalidate,
		disabled,
		readonly,
		required,
		multiple,
		fullWidth,
		dataSource,
		getID,
		getName,
		getNameForSearchTextFilter,
		maxItems,
		disableReset,
		saveOriginalSorting,
		renderUnderlinedText,
		pluralItems,
		setDefaultValue,
		createUnselectItem,
		innerRef,
		renderItemContent,
		renderAddTrigger,
		renderEditTrigger,
		renderRemoveTrigger,
		renderAddNestedTrigger,
		transformInput,
		transformOutput,
		children,
		onChange,
		onAsyncRequest,
	} = props;
	const value = useMemo(() => transformInput({ input: sourceValue }), [sourceValue]);
	const hasValue = useMemo(() => hasBooleanKeys(value), [value]);
	const [isOpen, setIsOpen] = useState(false);
	const [searchText, setSearchText] = useState('');
	const [isRelatedPopupOpen, setIsRelatedPopupOpen] = useState(false);
	const [relatedPopupID, setRelatedPopupID] = useState<SimpleID>(-1);
	const rootRef = useRef<HTMLDivElement>(null);
	const anchorRef = useRef<HTMLDivElement>(null);
	const textFieldRef = useRef<TextFieldRef>(null);
	const searchBarTextFieldRef = useRef<TextFieldRef>(null);
	const listRef = useRef<ListImperativeRef>(null);
	const dataSourceMap = useMemo(() => {
		const dataSourceMap = createObjectMap(dataSource, getID);

		if (detectIsFunction(createUnselectItem)) {
			dataSourceMap[-1] = createUnselectItem();
		}

		return dataSourceMap;
	}, [dataSource]);
	const scope: Scope = useMemo(
		() => ({
			isFirstOpen: true,
			onAsyncRequest: undefined,
		}),
		[],
	);

	scope.onAsyncRequest = onAsyncRequest;

	useImperativeHandle(innerRef, () => ({
		scrollIntoView: () => rootRef.current?.scrollIntoView({ block: 'center', behavior: 'smooth' }),
		open: () => handleOpen(),
		close: () => handleClose(),
	}));

	useEffect(() => {
		if (!didInvalidate) return;
		handleAsyncRequest(searchText);
	}, [didInvalidate]);

	useEffect(() => {
		if (typeof isLoaded !== 'boolean' || isLoaded) return;
		handleAsyncRequest(searchText);
	}, [isLoaded]);

	useEffect(() => {
		const hasValue = hasBooleanKeys(value);
		if (!setDefaultValue || hasValue) return;
		const map = {
			first: () => {
				const extractName = getNameForSearchTextFilter || getName;
				const sortedDataSoure = saveOriginalSorting ? dataSource : sortAscBy(dataSource, [{ fn: x => extractName(x) }]);
				const [first] = sortedDataSoure;

				return first || null;
			},
			single: () => {
				const [single] = dataSource;

				return dataSource.length === 1 ? single : null;
			},
		};
		const item = map[setDefaultValue]();

		if (item) {
			handleSelectItem(null, item);
		}
	}, [dataSource]);

	const handleToggleOpen = (e: React.MouseEvent) => {
		e.stopPropagation();
		if (isFetching || disabled || readonly) return;
		setIsOpen(x => {
			const isOpen = !x;

			if (scope.isFirstOpen) {
				scope.isFirstOpen = false;
				handleAsyncRequest('');
			}

			return isOpen;
		});
	};

	const handleOpen = useCallback(() => {
		!isRelatedPopupOpen && setIsOpen(true);
	}, [isRelatedPopupOpen]);

	const handleClose = useCallback(() => {
		!isRelatedPopupOpen && setIsOpen(false);
	}, [isRelatedPopupOpen]);

	const handleRelatedPopupChange = (ID: SimpleID) => (isOpen: boolean) => {
		setIsRelatedPopupOpen(isOpen);
		setRelatedPopupID(isOpen ? ID : -1);
		!isOpen && searchBarTextFieldRef?.current?.focus();
	};

	const handleAsyncRequest = (value: string) => {
		detectIsFunction(scope.onAsyncRequest) && scope.onAsyncRequest(value);
	};

	const handleChangeSearchText = (_, value: string) => {
		setSearchText(value);
		handleAsyncRequest(value);
	};

	const handleChangeTextField = (_, value: string) => {
		if (!value) {
			onChange({ e: null, item: null, items: [], newValue: transformOutput({ output: null, items: [] }) });
			handleAsyncRequest('');
		}
	};

	const handleSelectItem = (e: React.MouseEvent, item: any) => {
		const ID = getID(item);
		const newValue = multiple
			? value
				? (!value[ID] ? (value[ID] = true) : delete value[ID], { ...value })
				: { [ID]: true }
			: value
			? !value[ID]
				? { [ID]: true }
				: null
			: { [ID]: true };
		const items = newValue ? extractKeysToArray(newValue).map(x => dataSourceMap[x]) : [];

		onChange({ e, item, items, newValue: transformOutput({ output: newValue, items }) });

		if (multiple) {
			setTimeout(() => {
				searchBarTextFieldRef.current?.focus();
			}, 100);
		} else {
			handleClose();
		}
	};

	const getValueText = (value: Record<string, boolean>) => {
		if (!value || !hasBooleanKeys(value)) return '';
		const IDs = extractKeysToArray(value);

		if (IDs.length === 1) return getName(dataSourceMap[IDs[0]]);

		return `Выбрано ${IDs.length} ${plural({
			count: IDs.length,
			titles: pluralItems || ['элемент', 'элемента', 'элементов'],
		})}`;
	};

	const handleKeyDown = (e: React.KeyboardEvent) => {
		if (!listRef.current) return;
		const keyMap = {
			ArrowUp: listRef.current.keyboard.processArrowUp,
			ArrowDown: listRef.current.keyboard.processArrowDown,
			ArrowRight: listRef.current.keyboard.processArrowRight,
			ArrowLeft: listRef.current.keyboard.processArrowLeft,
			Enter: listRef.current.keyboard.processEnter,
		};

		keyMap[e.key] && keyMap[e.key]();
	};

	const handleSetSearchBarTextFieldRef = (ref: TextFieldRef) => {
		searchBarTextFieldRef.current = ref;
	};

	const handleRenderEditTrigger = (isSmallContainer: boolean, containerWidth: number) => (ID: number, name: string) => {
		return renderEditTrigger({
			ID,
			name,
			searchText,
			isSmallContainer,
			containerWidth,
			onRelatedPopupChange: handleRelatedPopupChange(ID),
		});
	};

	const handleRenderRemoveTrigger =
		(isSmallContainer: boolean, containerWidth: number) => (ID: number, name: string) => {
			return renderRemoveTrigger({
				ID,
				name,
				searchText,
				isSmallContainer,
				containerWidth,
				onRelatedPopupChange: handleRelatedPopupChange(ID),
			});
		};

	const handleRenderAddNestedTrigger =
		(isSmallContainer: boolean, containerWidth: number) => (ID: number, name: string) => {
			return renderAddNestedTrigger({
				ID,
				name,
				searchText,
				isSmallContainer,
				containerWidth,
				onRelatedPopupChange: handleRelatedPopupChange(ID),
			});
		};

	const renderProcessedUnderlinedText = () => {
		if (!detectIsFunction(renderUnderlinedText) || errorText) return null;
		const items = hasValue && dataSource.length > 0 ? extractKeysToArray(value).map(x => dataSourceMap[x]) : [];

		return <UnderlinedTextLayout readonly={readonly}>{renderUnderlinedText(items)}</UnderlinedTextLayout>;
	};

	const valueText = useMemo(() => getValueText(value), [value, dataSourceMap]);
	const withTextFieldCover = !isFetching && !readonly && !disabled;

	return (
		<>
			<Root ref={rootRef} fullWidth={fullWidth} onClick={handleToggleOpen}>
				{withTextFieldCover && <TextFieldCover />}
				<TextField
					ref={textFieldRef}
					name='autopicker'
					labelText={labelText}
					hintText={hintText}
					errorText={errorText}
					helpMark={helpMark}
					value={valueText}
					isFetching={isFetching}
					required={required}
					icon={
						!disabled &&
						!readonly && (
							<IconButton variant='rounded' isSilentHover>
								<DropdownIcon color='muted' size={24} />
							</IconButton>
						)
					}
					iconPosition='right'
					disabled={disabled}
					readonly={readonly}
					withClearBtn={!disableReset}
					fullWidth
					onChange={handleChangeTextField}>
					<input value={valueText} disabled />
				</TextField>
				{renderProcessedUnderlinedText()}
				<Anchor ref={anchorRef} />
				<FocusTrap active={isOpen} />
			</Root>
			<Popper isOpen={isOpen} anchor={anchorRef.current} canAutoposition fullWidth onRequestClose={handleClose}>
				<ResizeContainerSensor>
					{({ width: containerWidth }: ResizeContainerSensorOptions) => {
						const isSmallContainer = containerWidth < SMALL_CONTAINER_WIDTH;

						return (
							<>
								<Box position='relative' padding='8px 8px 0 8px'>
									<Box display='flex' alignItems='center' fullWidth>
										{detectIsFunction(renderAddTrigger) && (
											<Box flex='1 1 50%' marginRight={10}>
												{renderAddTrigger({
													searchText,
													isSmallContainer,
													containerWidth,
													onRelatedPopupChange: handleRelatedPopupChange(-1),
												})}
											</Box>
										)}
										<Box flex='1 1 50%'>
											<RaisedButton
												appearance='contained'
												title='Закрыть'
												color='white'
												size='small'
												fullWidth
												onClick={handleClose}>
												Закрыть
											</RaisedButton>
										</Box>
									</Box>
									<SearchBar
										value={searchText}
										debounce={300}
										hintText={isSmallContainer ? 'Поиск...' : searchBarHintText || 'Введите первые символы...'}
										autoFocus
										withClearBtn
										onChange={handleChangeSearchText}
										onKeyDown={handleKeyDown}
										onSetTextFieldRef={handleSetSearchBarTextFieldRef}
									/>
								</Box>
								<SoftFetching isFetching={isUpdating} isSilent={isFetching}>
									<Box paddingBottom={8} fullWidth>
										{detectIsFunction(children) ? (
											children({
												ref: listRef,
												value,
												searchText,
												isUpdating,
												getID,
												getName,
												getNameForSearchTextFilter: getNameForSearchTextFilter || getName,
												dataSource,
												isSmallContainer,
												saveOriginalSorting,
												maxItems,
												containerWidth,
												relatedPopupID,
												createUnselectItem,
												renderItemContent,
												renderEditTrigger: detectIsFunction(renderEditTrigger)
													? handleRenderEditTrigger(isSmallContainer, containerWidth)
													: undefined,
												renderRemoveTrigger: detectIsFunction(renderRemoveTrigger)
													? handleRenderRemoveTrigger(isSmallContainer, containerWidth)
													: undefined,
												renderAddNestedTrigger: detectIsFunction(renderAddNestedTrigger)
													? handleRenderAddNestedTrigger(isSmallContainer, containerWidth)
													: undefined,
												onSelectItem: handleSelectItem,
											})
										) : (
											<Box display='flex' justifyContent='center' padding={10}>
												Не найден рендерер списка
											</Box>
										)}
									</Box>
								</SoftFetching>
							</>
						);
					}}
				</ResizeContainerSensor>
			</Popper>
		</>
	);
};

const defaultAutopickerTransformInput = ({ input }) => input;
const defaultAutopickerTransformOutput = ({ output }) => output;
const defaultAutopickerTransformer = {
	transformInput: defaultAutopickerTransformInput,
	transformOutput: defaultAutopickerTransformOutput,
};

Autopicker.defaultProps = {
	labelText: ' ',
	hintText: 'Выберите элемент...',
	dataSource: [],
	getID: x => x?.ID || -1,
	getName: x => x?.Name || '',
	transformInput: defaultAutopickerTransformInput,
	transformOutput: defaultAutopickerTransformOutput,
	children: props => <AutopickerFlatList {...props} />,
};

export type AutopickerDefaultValueType = Record<string, boolean> | null;

export type AutopickerOnChangeOptions<T> = {
	e: React.MouseEvent;
	item: T;
	items: Array<T>;
	newValue: AutopickerProps<T>['value'];
};

export type RenderTriggerOptions = {
	ID?: number;
	name?: string;
	searchText: string;
	isSmallContainer: boolean;
	containerWidth: number;
	onRelatedPopupChange: (isOpen: boolean) => void;
};

export type AutopickerTransformInputOptions<V> = {
	input: V;
};

export type AutopickerTransformOutputOptions<T> = {
	output: AutopickerDefaultValueType;
	items: Array<T>;
};

export type AutopickerComponent<T = any> = React.FC<AutopickerProps<T>>;

type Scope = {
	isFirstOpen: boolean;
	onAsyncRequest: (searchText: string) => void;
};

export type RequiredListRendererProps<T> = {
	ref: React.MutableRefObject<ListImperativeRef>;
	searchText: string;
	isUpdating: boolean;
	isSmallContainer?: boolean;
	containerWidth?: number;
	maxItems?: number;
	saveOriginalSorting?: boolean;
	relatedPopupID: SimpleID;
	detectIsItemDisabled?: (item: T) => boolean;
	renderEditTrigger?: (ID: SimpleID, name: string) => React.ReactNode;
	renderRemoveTrigger?: (ID: SimpleID, name: string) => React.ReactNode;
	renderAddNestedTrigger?: (ID: SimpleID, name: string) => React.ReactNode;
	onSelectItem: (e: React.MouseEvent | null, item: T) => void;
} & Pick<
	AutopickerProps<T>,
	| 'getID'
	| 'getName'
	| 'value'
	| 'dataSource'
	| 'renderItemContent'
	| 'getNameForSearchTextFilter'
	| 'createUnselectItem'
>;

export type AutopickerRenderItemContentOptions<T = any> = {
	item: T;
	searchText: string;
	containerWidth: number;
};

type CreateAutopickerAsyncDataSourceOptions<T> = {
	value: AutopickerDefaultValueType;
	prevDataSource: Array<T>;
	newDataSource: Array<T>;
	getID?: (item: T) => number;
};

function createAutopickerAsyncDataSource<T>(options: CreateAutopickerAsyncDataSourceOptions<T>) {
	const { value, prevDataSource, newDataSource, getID = x => (x as any).ID } = options;
	const items = value ? prevDataSource.filter(x => value[getID(x)]) : [];
	const dataSource = extractUniq<T>([...newDataSource, ...items], x => getID(x));

	return dataSource;
}

const SMALL_CONTAINER_WIDTH = 300;

export type AutopickerInnerRef = {
	scrollIntoView: () => void;
	open: () => void;
	close: () => void;
};

export {
	Autopicker,
	defaultAutopickerTransformInput,
	defaultAutopickerTransformOutput,
	defaultAutopickerTransformer,
	createAutopickerAsyncDataSource,
};
