import styled from 'styled-components';

import * as c from '../../../../../../../core/ui/basic-theme/colors';


const Root = styled.div`
	position: relative;
	width: 100%;
	height: auto;
`;

const FormLayout = styled.div`
	margin-bottom: 20px;
`;

const ButtonLayout = styled.div`
	margin-top: 20px;
`;

const EmailLayout = styled.div`
	margin-bottom: 20px;
`;

const SignLayout = styled.div`
	margin-top: 20px;
`;


export {
	Root,
	FormLayout,
	ButtonLayout,
	EmailLayout,
	SignLayout
}
