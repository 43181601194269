import { Store } from 'redux';

import { preferencesApi } from '@core/api/preferences';
import { IAppState } from '@store';
import { isReceiveAction } from '@flux';
import { initializationActionsPack, StartInitializationOptions } from '@initialization/actions';
import { mainTenantProfileActionsPack } from '@platform/actions/main-tenant-profile.actions';
import fetchSessionContext, { FETCH_SESSION_CONTEXT } from '@platform/actions/session-context.actions';
import { SettingsKey } from '@initialization/models';
import { selectCurrentTenantID } from '@platform/selectors/context.selectors';

function createInitializationEffect() {
	let options: StartInitializationOptions = null;
	let isEffectRunning = false;
	let isTenantProfileLoaded = false;

	return (store: Store<IAppState>) => (next: (action: StoreAction) => void) => (action: StoreAction) => {
		const state = store.getState();
		const tenantID = selectCurrentTenantID(state);
		const emitSuccess = () => {
			setTimeout(() => {
				isEffectRunning = false;
				store.dispatch(initializationActionsPack.actions.setInitializationInProcess(false));
				store.dispatch(initializationActionsPack.actions.setInitializationIsComplete(true));
				options.onSuccess();
			}, 2000);
		};
		const emitError = (msg: string) => {
			isEffectRunning = false;
			options.onError(msg);
		};

		if (action.type === initializationActionsPack.types.START_INITIALIZATION_EFFECT) {
			const syncAction = action as StaticAction<StartInitializationOptions>;
			options = syncAction.value;
			const { withDemoDataImport } = options;

			isEffectRunning = true;

			preferencesApi.rest
				.savePreferences(tenantID, { [SettingsKey.IS_INITIALIZATION_WIZARD_ZONE_COMPLETED]: true })
				.then(() => {});
			store.dispatch(initializationActionsPack.actions.setInitializationInProcess(true));
			store.dispatch(initializationActionsPack.actions.importStandardData({ withDemoDataImport }));
		}

		if (!isEffectRunning) return next(action);

		if (action.type === initializationActionsPack.types.IMPORT_STANDARD_DATA && isReceiveAction(action)) {
			const asyncAction = action as AsyncAction<boolean>;

			if (asyncAction.response === true) {
				store.dispatch(initializationActionsPack.actions.initializeApplicationByProfile() as any);
			} else {
				emitError('Ошибка при IMPORT_STANDARD_DATA');
			}
		}

		if (action.type === initializationActionsPack.types.INITIALIZE_APPLICATION_BY_PROFILE && isReceiveAction(action)) {
			const asyncAction = action as AsyncAction<SuccessMessage>;
			const message = asyncAction.response;

			if (message.Success) {
				store.dispatch(mainTenantProfileActionsPack.actions.invalidateTenantProfile());
				store.dispatch(mainTenantProfileActionsPack.actions.fetchTenantProfile() as any);
			} else {
				emitError(message.Message);
			}
		}

		if (
			!isTenantProfileLoaded &&
			action.type === mainTenantProfileActionsPack.types.FETCH_TENANT_PROFILE &&
			isReceiveAction(action)
		) {
			const asyncAction = action as AsyncAction<TenantProfile>;
			const isCompleted = asyncAction.response.Completed;

			isTenantProfileLoaded = true;

			if (isCompleted) {
				store.dispatch(fetchSessionContext() as any);
			} else {
				emitError('Completed == false');
			}
		}

		if (action.type === FETCH_SESSION_CONTEXT && isReceiveAction(action)) {
			emitSuccess();
		}

		next(action);
	};
}

const runInitializationEffect = createInitializationEffect();

export { runInitializationEffect };
