import styled, { css } from 'styled-components';

const Root = styled.div`
	position: relative;
	max-width: 100%;
	overflow-x: auto;
	overflow-y: hidden;

	${p => css`
		background-color: ${p.theme.palette.space};
	`}
`;

const ControlsLayout = styled.div`
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
`;

type TabLayoutProps = {
	isFirst: boolean;
	marginLeft: number;
};

const TabLayout = styled.div<TabLayoutProps>`
	${p =>
		p.isFirst &&
		p.marginLeft &&
		css`
			margin-left: ${p.marginLeft}px;

			@media (max-width: 1200px) {
				margin-left: 20px;
			}
		`}
`;

type TabProps = {
	isActive: boolean;
	isDisabled: boolean;
};

const Tab = styled.button<TabProps>`
	height: 40px;
	background-color: transparent;
	outline: none;
	font-size: 12px;
	font-weight: 400;
	line-height: 1.36;
	text-transform: uppercase;
	cursor: pointer;
	padding: 0 8px;
	margin: 0;
	border: none;
	transition: all 0.2s ease-in-out;

	${p =>
		p.isActive &&
		css`
			font-weight: 500;
		`};

	${p =>
		p.isDisabled &&
		css`
			cursor: default;
			color: ${p.theme.palette.label};
		`}

	${p => css`
		&:focus {
			background-color: ${p.theme.palette.stealth};
		}
	`}

	@media (max-width: 1200px) {
		white-space: nowrap;
	}
`;

const SliderLayout = styled.div`
	width: 100%;
	margin-top: 2px;
`;

type SliderProps = {
	withoutBorder: boolean;
};

const Slider = styled.div<SliderProps>`
	position: relative;
	width: 100%;
	height: 2px;
	border-bottom: 1px solid transparent;

	${p => css`
		border-color: ${p.theme.palette.stealth};
	`}

	${p =>
		p.withoutBorder &&
		css`
			border-bottom: none;
		`};
`;

const SliderBar = styled.div`
	position: absolute;
	top: -2px;
	left: 0;
	height: 4px;
	transition: transform 0.2s ease-in-out;

	${p => css`
		background-color: ${p.theme.palette.accent};
	`}
`;

export { Root, ControlsLayout, TabLayout, Tab, SliderLayout, Slider, SliderBar };
