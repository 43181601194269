import React from 'react';

import { StatefulTooltip, TooltipPosition, TooltipProps } from '@ui/tooltip';
import { HelpIcon, HelpIconProps } from '@ui/icons/help';
import { Root } from './styled';

export type HelpMarkProps = {
	label: React.ReactNode;
	IconProps?: HelpIconProps;
	TooltipProps?: Partial<TooltipProps>;
	tooltipPosition?: TooltipPosition;
};

const HelpMark: React.FC<HelpMarkProps> = ({ label, IconProps, TooltipProps, tooltipPosition = 'right' }) => {
	if (label) {
		return (
			<Root>
				<HelpIcon size={18} {...IconProps} />
				<StatefulTooltip position={tooltipPosition} {...TooltipProps}>
					{label}
				</StatefulTooltip>
			</Root>
		);
	}

	return null;
};

HelpMark.defaultProps = {
	IconProps: {},
	TooltipProps: {},
};

export { HelpMark };
