import {
	StoreAsyncItem,
	checkAsyncAction,
	createAsyncInitialState,
	invalidateStateFromAction,
	createReducer,
} from '@flux';
import { types } from '@business-units/actions/types';

export type MainBusinessUnitsState = {
	businessUnits: StoreAsyncItem<Array<BusinessUnit>>;
	searchText: string;
};

const initialState: MainBusinessUnitsState = {
	businessUnits: createAsyncInitialState([]),
	searchText: '',
};

const mainBusinessUnitsReducer = createReducer<MainBusinessUnitsState>(initialState, {
	[types.INVALIDATE_BUSINESS_UNITS]: (action: StaticAction, state) => {
		return {
			businessUnits: invalidateStateFromAction(action, state.businessUnits),
		};
	},
	[types.FETCH_BUSINESS_UNITS]: (action: AsyncAction<Array<BusinessUnit>>, state) => {
		return {
			businessUnits: checkAsyncAction(action, state.businessUnits),
		};
	},
	[types.ADD_BUSINESS_UNIT]: (action: StaticAction, state) => {
		return {
			businessUnits: invalidateStateFromAction(action, state.businessUnits),
		};
	},
	[types.UPDATE_BUSINESS_UNIT]: (action: StaticAction, state) => {
		return {
			businessUnits: invalidateStateFromAction(action, state.businessUnits),
		};
	},
	[types.REMOVE_BUSINESS_UNIT]: (action: StaticAction, state) => {
		return {
			businessUnits: invalidateStateFromAction(action, state.businessUnits),
		};
	},
	[types.SET_SEARCH_TEXT]: (action: StaticAction<string>) => {
		return {
			searchText: action.value,
		};
	},
});

export { mainBusinessUnitsReducer };
