import {
	StoreAsyncItem,
	createReducer,
	createAsyncInitialState,
	checkAsyncAction,
	invalidateStateFromAction,
} from '@flux';
import { types } from '../actions/types';
import { FilterDirection } from '../models';

export type MainCashflowPaymentRulesState = {
	paymentRules: StoreAsyncItem<Array<PaymentRule>>;
	executionResult: PaymentRuleExecutionResult;
	executionProgress: number;
	textFilter: string;
	directionFilter: Record<FilterDirection, boolean>;
	isAutoApplyRules: boolean;
};

const initialState: MainCashflowPaymentRulesState = {
	paymentRules: createAsyncInitialState([]),
	executionResult: null,
	executionProgress: 0,
	textFilter: '',
	directionFilter: {
		[FilterDirection.INCOME]: true,
		[FilterDirection.EXPENSE]: true,
	},
	isAutoApplyRules: true,
};

const mainCashflowPaymentRulesReducer = createReducer<MainCashflowPaymentRulesState>(initialState, {
	[types.INVALIDATE_PAYMENT_RULES]: (action: StaticAction, state) => {
		return {
			paymentRules: invalidateStateFromAction(action, state.paymentRules),
		};
	},
	[types.FETCH_PAYMENT_RULES]: (action: AsyncAction<Array<PaymentRule>>, state) => {
		return {
			paymentRules: checkAsyncAction(action, state.paymentRules),
		};
	},
	[types.ADD_PAYMENT_RULE]: (action: AsyncAction, state) => {
		return {
			paymentRules: invalidateStateFromAction(action, state.paymentRules),
		};
	},
	[types.UPDATE_PAYMENT_RULE]: (action: AsyncAction, state) => {
		return {
			paymentRules: invalidateStateFromAction(action, state.paymentRules),
		};
	},
	[types.REMOVE_PAYMENT_RULE]: (action: AsyncAction, state) => {
		return {
			paymentRules: invalidateStateFromAction(action, state.paymentRules),
		};
	},
	[types.SET_FILTER_BY_DIRECTION]: (action: StaticAction<Record<FilterDirection, boolean>>) => {
		return {
			directionFilter: { ...action.value },
		};
	},
	[types.SET_FILTER_BY_TEXT]: (action: StaticAction<string>) => {
		return {
			textFilter: action.value,
		};
	},
	[types.SET_IS_AUTO_APPLY_RULES]: (action: StaticAction<boolean>) => {
		return {
			isAutoApplyRules: action.value,
		};
	},
});

export default mainCashflowPaymentRulesReducer;
