import styled, { css } from 'styled-components';

import { fade } from '@utils/color';

type RootProps = {
	isActive: boolean;
	renderSwitch: boolean;
	hover: boolean;
};

const Root = styled.div<RootProps>`
	position: relative;
	width: 100%;
	height: auto;
	min-height: 50px;
	padding: 16px 28px;
	border: 1px solid transparent;
	border-radius: 2px;
	transition: all 0.2s ease-in-out;
	cursor: pointer;
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: stretch;

	${p => css`
		border-color: ${p.theme.palette.stealth};
	`}

	${p =>
		p.isActive &&
		css`
			border-color: ${p.theme.palette.accent};
			background-color: ${fade(p.theme.palette.accent, 0.2)};
			cursor: default;
			z-index: 1;
		`}

	${p =>
		!p.renderSwitch &&
		css`
			background-color: transparent;
		`}

	${p =>
		p.hover &&
		css`
			&:hover {
				z-index: 1;
				border-color: ${p.theme.palette.accent};
				background-color: ${fade(p.theme.palette.accent, 0.1)};
			}
		`}
`;

const SwitchCardLayout = styled.div`
	margin-bottom: 20px;

	&:last-child {
		margin-bottom: 0;
	}
`;

const SwitchLayout = styled.div`
	flex-shrink: 0;
	margin-right: 10px;
	margin-top: -1px;
`;

const ContentLayout = styled.div`
	flex: 1 1 auto;
`;

export { Root, SwitchCardLayout, SwitchLayout, ContentLayout };
