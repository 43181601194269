import { detectIsInteger } from '@utils/numbers';
import { detectIsTaxCode, detectIsStateRegistrationCode } from '@utils/counterparty';
import { detectIsPhone, detectIsEmail } from '@utils/contacts';
import { FormState } from '../form';

function isNumber<T>(selector: (s: FormState<T>) => any): (d: FormState<T>) => boolean {
	return (data: FormState<T>) => (selector(data) ? !isNaN(+selector(data)) : true);
}

function isIntegerNumber<T>(selector: (s: FormState<T>) => any): (d: FormState<T>) => boolean {
	return (data: FormState<T>) => (selector(data) ? detectIsInteger(selector(data)) : true);
}

function isPositiveNumber<T>(selector: (s: FormState<T>) => any): (d: FormState<T>) => boolean {
	return (data: FormState<T>) => selector(data) > 0;
}

function gt<T>(selector: (s: FormState<T>) => any, value: number): (d: FormState<T>) => boolean {
	return (data: FormState<T>) => selector(data) > value;
}

function gte<T>(selector: (s: FormState<T>) => any, value: number): (d: FormState<T>) => boolean {
	return (data: FormState<T>) => selector(data) >= value;
}

function lt<T>(selector: (s: FormState<T>) => any, value: number): (d: FormState<T>) => boolean {
	return (data: FormState<T>) => selector(data) < value;
}

function lte<T>(selector: (s: FormState<T>) => any, value: number): (d: FormState<T>) => boolean {
	return (data: FormState<T>) => selector(data) <= value;
}

function isEmail<T>(selector: (s: FormState<T>) => any): (d: FormState<T>) => boolean {
	return (data: FormState<T>) => (selector(data) ? detectIsEmail(selector(data)) : true);
}

function isPhone<T>(selector: (s: FormState<T>) => string): (d: FormState<T>) => boolean {
	return (data: FormState<T>) => (selector(data) ? detectIsPhone(selector(data).toString()) : true);
}

function isUniqValue<T>(selector: (s: FormState<T>) => any, list: Array<any>): (d: FormState<T>) => boolean {
	return (data: FormState<T>) => (selector(data) ? list.every(el => el !== selector(data)) : true);
}

function isLengthEqual<T>(selector: (s: FormState<T>) => any, variants: Array<any>): (d: FormState<T>) => boolean {
	return (data: FormState<T>) => (selector(data) ? variants.some(el => el === `${selector(data)}`.length) : true);
}

function isTaxCode<T>(selector: (s: FormState<T>) => number | string): (d: FormState<T>) => boolean {
	return (data: FormState<T>) => (selector(data) ? detectIsTaxCode(selector(data) as string) : true);
}

function isStateRegistrationCode<T>(selector: (s: FormState<T>) => number | string): (d: FormState<T>) => boolean {
	return (data: FormState<T>) => (selector(data) ? detectIsStateRegistrationCode(selector(data) as string) : true);
}

export {
	isNumber,
	isIntegerNumber,
	isPositiveNumber,
	gt,
	gte,
	lt,
	lte,
	isEmail,
	isPhone,
	isUniqValue,
	isLengthEqual,
	isTaxCode,
	isStateRegistrationCode,
};
