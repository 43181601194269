function capitalize(value: string) {
	if (!value) return '';
	const str = value[0].toUpperCase() + value.slice(1);

	return str;
}

function capitalizeSentence(value: string) {
	const [firstWord, ...rest] = value.split(' ');
	const str = capitalize(firstWord);

	return [str, ...rest].join(' ');
}

type HighlightItem = {
	text: string;
	highlight: boolean;
};

const highlight = {
	match: (text: string, query: string, formatedText: string): Array<Array<number>> => {
		const separatorsIndexes: Array<number> = [];
		const idx = text.toLowerCase().indexOf(query.toLowerCase());
		let idxCorrection = 0;
		let queryLengthCorrection = 0;

		if (idx !== -1 && text !== formatedText && query.length > 0) {
			for (let i = 0; i < text.length; i++) {
				if (text[i] !== formatedText[i + separatorsIndexes.length]) {
					while (text[i] !== formatedText[i + separatorsIndexes.length]) {
						separatorsIndexes.push(i + separatorsIndexes.length);
					}
				}
			}
			for (let i = 0; i < separatorsIndexes.length; i++) {
				if (separatorsIndexes[i] <= idx + idxCorrection) {
					idxCorrection += 1;
				}
				if (separatorsIndexes[i] < idx + query.length + queryLengthCorrection) {
					queryLengthCorrection += 1;
				}
			}
		}

		return idx !== -1 ? [[idx + idxCorrection, idx + query.length + queryLengthCorrection]] : [];
	},
	parse: (text: string, matches: Array<Array<number>>) => {
		const result: Array<HighlightItem> = [];

		matches.forEach(match => {
			result.push(
				{ text: text.substring(0, match[0]), highlight: false },
				{ text: text.substring(match[0], match[1]), highlight: true },
				{ text: text.substring(match[1], text.length), highlight: false },
			);
		});

		if (matches.length === 0) {
			result.push({ text, highlight: false });
		}

		return result.filter(x => Boolean(x.text));
	},
};

function comma(items: Array<string> = []) {
	return items.filter(Boolean).join(', ');
}

function replaceMaskedValue(value: string, separator = ' '): string {
	const replacedValue = (value || '').replace(new RegExp(`(\\s|\\_|\\${separator})`, 'g'), '');

	return replacedValue;
}

function uppercase(value = '') {
	return value.toLocaleUpperCase();
}

function joinNonemptyValues(separator: string, ...values: string[]) {
	return values?.filter(value => !!value).join(separator || '') || '';
}

export { capitalize, capitalizeSentence, highlight, comma, replaceMaskedValue, uppercase, joinNonemptyValues };
