import styled, { css } from 'styled-components';
import { isIE } from 'react-device-detect';

const Root = styled.div`
	position: relative;
	width: 100%;
	height: auto;
`;

const MessageCardLayout = styled.div`
	margin-bottom: 28px;
`;

const ContentLayout = styled.div``;

interface IPickerLayout {
	fullWidth?: boolean;
}

const PickerLayout = styled.div`
	width: ${(props: IPickerLayout) => (props.fullWidth ? '100%' : 'auto')};
	margin-top: 20px;
`;

const RatesContentLayout = styled.div`
	position: relative;
	width: 100%;
	height: auto;
	min-height: 44px;
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	align-items: center;
`;

const RatesDesc = styled.div`
	align-self: stretch;
`;

const RatesTitle = styled.div`
	font-size: 14px;
	font-weight: 500;
	line-height: 1.43;
	text-align: left;
	margin-bottom: 2px;
`;

const RatesDates = styled.div`
	font-size: 12px;
	line-height: 1.25;
	text-align: left;

	${p => css`
		color: ${p.theme.palette.label};
	`}
`;

interface IRatesSum {
	isMuted?: boolean;
	flex?: boolean;
	isActive?: boolean;
}

const RatesSum = styled.div<IRatesSum>`
	font-size: 16px;
	line-height: 1.25;
	text-align: left;

	${p =>
		p.isMuted &&
		css`
			font-size: 14px;
			text-decoration: line-through;
			margin-left: 20px;
			color: ${p.theme.palette.label};
		`}

	${p =>
		p.flex &&
		css`
			display: flex;
		`}

	${p =>
		p.isActive &&
		css`
			font-size: 14px;
			color: ${p.theme.palette.accent};
			margin-top: 4px;
		`}
`;

const RatesSumContainer = styled.div`
	flex-basis: 200px;
`;

const RatesName = styled.span`
	text-transform: lowercase;
`;

const LoaderLayout = styled.div`
	width: 100%;
	height: calc(100vh - 195px);
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	align-items: center;
`;

const buttonStyle = {
	minWidth: '170px',
	marginBottom: '20px',
};

const RatesInfoLayout = styled.div``;

const PayButtonLayout = styled.div`
	flex: 1 1 auto;
	margin-left: 30px;

	${() =>
		isIE &&
		css`
			position: absolute;
			right: 0;
			width: calc(50% - 30px);
			margin-left: 0;
		`}
`;

const PreviewContentLayout = styled.div`
	position: relative;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
`;

const BillingPreviewLayout = styled.div`
	flex: 0 0 auto;
`;

const ParamsLayout = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 20px;
`;

const Param = styled.div`
	margin-right: 30px;

	&:last-child {
		margin-right: 0;
	}
`;

const ParamSign = styled.span`
	display: block;
	font-size: 12px;
	line-height: 1.25;
	margin-bottom: 7px;

	${p => css`
		color: ${p.theme.palette.label};
	`}
`;

const ParamValue = styled.span`
	display: block;
	font-size: 14px;
	line-height: 1.43;
`;

const ChangeLinkLayout = styled.div`
	margin-bottom: 20px;
`;

const TitleLayout = styled.div`
	display: flex;
	justify-content: space-between;
`;

const SyncPaymentLayout = styled.div``;

export {
	Root,
	MessageCardLayout,
	ContentLayout,
	PickerLayout,
	RatesContentLayout,
	RatesDesc,
	RatesTitle,
	RatesDates,
	RatesSum,
	RatesName,
	LoaderLayout,
	buttonStyle,
	RatesInfoLayout,
	PayButtonLayout,
	PreviewContentLayout,
	BillingPreviewLayout,
	ParamsLayout,
	Param,
	ParamSign,
	ParamValue,
	ChangeLinkLayout,
	RatesSumContainer,
	TitleLayout,
	SyncPaymentLayout,
};
