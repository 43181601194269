import React from 'react';
import { connect } from 'react-redux';

import { IAppState } from '../../../../core/store';
import { Snackbar } from '@ui/snackbar';
import { Notify } from '@ui/notify';
import { Title } from './styled';

type ActionMessageProps = {
	messages: Array<IActionMessage>;
	closeActionMessageView?: () => void;
};

class ActionMessage extends React.Component<ActionMessageProps> {
	render() {
		const { messages } = this.props;

		return (
			<Notify
				messages={messages.map(el => {
					return (
						<Snackbar type={el.type} asSimpleComponent>
							<Title>{el.message}</Title>
						</Snackbar>
					);
				})}
			/>
		);
	}
}

export const mapStateToProps = (state: IAppState) => state.shared.actionMessage;

export const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ActionMessage as any);
