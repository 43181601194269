import { IAppState } from '@store';
import { ROUTE_MAP } from '@routes/urls';


const selectIsShellRerenderBlocked = (state: IAppState): boolean => {
	return state.shared.ui.isShellRerenderBlocked;
}

const selectIsDetailPanelVisible = (state: IAppState): boolean => {
	return state.shared.ui.detailPanelVisibility;
}

const selectIsDetailPanelClickOutside = (state: IAppState): boolean => {
	return state.shared.ui.detailPanelClickOutside;
}

const selectAppProgressBarOptions = (state: IAppState) => state.shared.ui.appProgressBarOptions;

const selectIsAppProgressBarOpen = (state: IAppState) => state.shared.ui.appProgressBarOptions?.isOpen;

const selectIsAppPurpleBarBarOpen = (state: IAppState) => 
	Boolean(state.platform.tenantProfile.tenantProfile?.item?.HasStandardData) && location.pathname !== ROUTE_MAP.INITIALIZATION;

export {
	selectIsShellRerenderBlocked,
	selectIsDetailPanelVisible,
	selectIsDetailPanelClickOutside,
	selectAppProgressBarOptions,
	selectIsAppProgressBarOpen,
	selectIsAppPurpleBarBarOpen,
};
