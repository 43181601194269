export enum MODULE_PREFERENCE_CODE {
	USE_ONLY_USER_PAYMENT_RULES = 'use_only_user_payment_rules',
}

export enum PaymentFieldSystemName {
	COUNTERPARTY_NAME = 'COUNTERPARTY_NAME',
	COUNTERPARTY_TAX_CODE = 'COUNTERPARTY_TAX_CODE',
	COUNTERPARTY_ACCOUNT_NUMBER = 'COUNTERPARTY_ACCOUNT_NUMBER',
	OWN_LEGAL_ENTITY_NAME = 'OWN_LEGAL_ENTITY_NAME',
	OWN_LEGAL_ENTITY_TAX_CODE = 'OWN_LEGAL_ENTITY_TAX_CODE',
	OWN_LEGAL_ENTITY_ACCOUNT_NUMBER = 'OWN_LEGAL_ENTITY_ACCOUNT_NUMBER',
	PAYMENT_REFERENCE = 'PAYMENT_REFERENCE',
}

export enum Operator {
	EQUALS = '=',
	CONTAINS = 'CONTAINS',
}

export enum FilterType {
	INCOME = 'income',
	EXPENSE = 'expense',
	PROJECT = 'project',
	BUSINESS_UNIT = 'business-unit',
}

export enum PaymentDirectionType {
	BIDIRECTIONAL = -1,
	EXPENSE = 1,
	INCOME = 2,
}
