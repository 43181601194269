import {
	StoreAsyncItem,
	checkAsyncAction,
	createAsyncInitialState,
	invalidateStateFromAction,
	createReducer,
} from '@flux';
import { createDefaultPeriod } from '@utils/date';
import { FetchTopCounterpartiesReportData } from '@top-counterparties-report/model';
import { types } from '@top-counterparties-report/actions/types';

export type MainTopCounterpartiesReportState = {
	dateRange: DateRange;
	direction: 'payers' | 'payees';
	reportData: StoreAsyncItem<FetchTopCounterpartiesReportData>;
	splineFilter: Record<number, boolean>;
};

const initialState: MainTopCounterpartiesReportState = {
	dateRange: createDefaultPeriod('year'),
	direction: null,
	reportData: createAsyncInitialState(null),
	splineFilter: {},
};

const mainTopCounterpartiesReportReducer = createReducer<MainTopCounterpartiesReportState>(initialState, {
	[types.FETCH_REPORT_DATA]: (action: AsyncAction<FetchTopCounterpartiesReportData>, state) => {
		return {
			reportData: checkAsyncAction(action, state.reportData),
		};
	},
	[types.INVALIDATE_REPORT_DATA]: (action: StaticAction, state) => {
		return {
			reportData: invalidateStateFromAction(action, state.reportData),
		};
	},
	[types.SET_DATE_RANGE]: (action: StaticAction<DateRange>, state) => {
		return {
			dateRange: action.value,
			reportData: invalidateStateFromAction(action, state.reportData),
			splineFilter: {},
		};
	},
	[types.SET_DIRECTION]: (action: StaticAction<'payers' | 'payees'>) => {
		return {
			direction: action.value,
			splineFilter: {},
		};
	},
	[types.SET_SPLINE_FILTER]: (action: StaticAction<Record<number, boolean>>, state) => {
		return {
			splineFilter: {
				...state.splineFilter,
				...action.value,
			},
		};
	},
});

export { mainTopCounterpartiesReportReducer };
