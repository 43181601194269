import * as organizationpackage from 'organizationpackage-api';
import { datamanagementApi } from '../datamanagement';
import { coreApi } from '../core';

const organizationApi = {
	package: organizationpackage,
	fetchEmployeesBusinessRoles: () => {
		return new Promise<Array<CodeNaturalKey>>(resolve => {
			datamanagementApi.dataset.fetchDataset('EmployeeBusinessRole_AllRecords_DS').then(result => {
				const roles = result.Records.map(x => ({
					...new coreApi.package.CodeNaturalKey(),
					ID: x.BusinessRoleID,
					Name: x.BusinessRoleName,
					Code: x.BusinessRoleCode,
				}));

				resolve(roles);
			});
		});
	},
};

export enum EmployeeBusinessRoleCode {
	DIRECTOR = 'Director',
	GENERAL_DIRECTOR = 'GeneralDirector',
	PROJECT_MANAGER = 'ProjectManager',
	PROJECT_OFFICE_MANAGER = 'ProjectOfficeManager',
	CLIENT_MANAGER = 'CustomerManager',
	SOLE_PROPRIETOR = 'SoleProprietor',
	CHIEF_ACCOUNTANT = 'ChiefAccountant',
	BOOKKEEPER = 'Bookkeeper',
}

export enum EmployeeBusinessRoleID {
	PROJECT_MANAGER = 2,
}

export const employeeBusinessRoleIDsMap = {
	[EmployeeBusinessRoleCode.PROJECT_MANAGER]: 2,
};

export { organizationApi };
