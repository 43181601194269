import { combineReducers } from 'redux';

import mainDocumentsReducer, { mainDocumentsState } from './main.reducer';

export type DocumentsState = {
	main: mainDocumentsState;
};

const documentsReducer = combineReducers<DocumentsState>({
	main: mainDocumentsReducer,
});

export { documentsReducer };
