// @ts-expect-error
import { CookieUtils } from 'Utils';
import axios from 'axios';

import { pageContext } from '../../../core/libs/atr-core-lib';
import createAction, { UNVALID_TICKET_ERROR } from '../../../core/libs/action-creator';
import { createSentryConfig } from '../../shared/components/error-message/error-interceptor';
import { fetchBillingServiceStatusAction } from './billing.actions';
import { fetchTenantByIDAction } from './tenant-account.actions';
import { fetchCashManagementSystemsAction } from '../actions/external-system-account.actions';
import { fetchCurrenciesAction } from '@shared/actions/fetch-currencies.action';

const FETCH_SESSION_CONTEXT = '[PLATFORM]: FETCH_SESSION_CONTEXT';
const CHECK_SESSION_CONTEXT = '[PLATFORM]: CHECK_SESSION_CONTEXT';

export default createAction<ClientSessionContext>(FETCH_SESSION_CONTEXT, updateContext);
const checkSessionContextAction = createAction(CHECK_SESSION_CONTEXT, checkSessionContext);

function updateContext(api, getState, dispatch) {
	const rootPath =
		getWebServicesRootPath() + '/atr-framework-services/clientSessionContext?ticketuid=' + pageContext.ticketuid;

	return new Promise<ClientSessionContext>((resolve, reject) => {
		if (!pageContext.ticketuid) {
			throw new Error(UNVALID_TICKET_ERROR);
		}

		axios({
			method: 'get',
			url: rootPath,
		}).then(response => {
			const context: ClientSessionContext = response.data;

			if (!context.SecurityTicket) {
				CookieUtils.deleteCookie('ticketuid'); // удаление лишнего ticketuid с path = /bfm
				reject(new Error(UNVALID_TICKET_ERROR));

				return;
			}

			createSentryConfig(context);
			resolve(context);

			const tenantID = context.SessionTenant.ID;

			dispatch(fetchBillingServiceStatusAction(tenantID));
			dispatch(fetchTenantByIDAction(tenantID));
			dispatch(fetchCashManagementSystemsAction());
			dispatch(fetchCurrenciesAction());
		});
	});
}

function checkSessionContext() {
	const rootPath =
		getWebServicesRootPath() + '/atr-framework-services/clientSessionContext?ticketuid=' + pageContext.ticketuid;

	return new Promise((resolve, reject) => {
		if (!pageContext.ticketuid) {
			resolve(null);
		}

		axios({
			method: 'get',
			url: rootPath,
		}).then(response => {
			const context: ClientSessionContext = response.data;

			if (!context.SecurityTicket) {
				resolve(null);

				return;
			}

			resolve(context);
		});
	});
}

export { FETCH_SESSION_CONTEXT, CHECK_SESSION_CONTEXT, checkSessionContextAction };
