import { Store } from 'redux';

import { IAppState } from '@store';
import { isReceiveAction } from '@flux';
import { types } from '@transfer-rules/actions/types';
import { invalidateTransferRules } from '@transfer-rules/actions/invalidators';

function askRulesInvalidator(store: Store<IAppState>, action: StoreAction) {
	const { dispatch } = store;
	const invalidateMap = {
		[types.ADD]: true,
		[types.UPDATE]: true,
		[types.REMOVE]: true,
	};

	if (invalidateMap[action.type] && isReceiveAction(action)) {
		dispatch(invalidateTransferRules());
	}
}

function createInvalidatorEffect() {
	return (store: Store<IAppState>) => (next: (action: StoreAction) => void) => (action: StoreAction) => {
		next(action);
		askRulesInvalidator(store, action);
	};
}

const runInvalidatorEffect = createInvalidatorEffect();

export { runInvalidatorEffect };
