import { combineReducers } from 'redux';

import analysisReducer, { IAnalysisState } from './reducers/analysis.reducers';
import mainReportingReducer, { IReportingState } from './reducers/main-reporting.reducer';

export declare namespace IAnalyticsStore {
	interface store {
		analysis: IAnalysisState;
		reporting: IReportingState;
	}
}

const analyticsReducer = combineReducers<IAnalyticsStore.store>({
	analysis: analysisReducer,
	reporting: mainReportingReducer,
});

export default {
	reducer: analyticsReducer,
	layout: {},
	components: {},
};
