import styled, { css, keyframes } from 'styled-components';

const pulseMotion = keyframes`
0%,
80%,
100% {
	box-shadow: 0 0;
	height: 4em;
}
40% {
	box-shadow: 0 -2em;
	height: 5em;
}
`;

const UpdatingSpinner = styled.div`
	position: absolute;
	font-size: 8px;
	transform: translateZ(0);
	animation: ${pulseMotion} 1s infinite ease-in-out;
	width: 1em;
	height: 4em;
	animation-delay: -0.16s;
	zoom: 0.8;

	${p => css`
		color: ${p.theme.palette.accent};
		background-color: ${p.theme.palette.accent};

		&::before,
		&::after {
			position: absolute;
			top: 0;
			content: '';
			background-color: ${p.theme.palette.accent};
			animation: ${pulseMotion} 1s infinite ease-in-out;
			width: 1em;
			height: 4em;
		}

		&::before {
			left: -1.5em;
			animation-delay: -0.32s;
		}

		&::after {
			left: 1.5em;
		}
	`}
`;

export { UpdatingSpinner };
