import {
	StoreAsyncItem,
	checkAsyncAction,
	createAsyncInitialState,
	invalidateStateFromAction,
	createReducer,
} from '@flux';
import { transformNestedTreeToFlattenTree } from '@utils/tree';
import { types } from '@cashflow-items/actions/types';

export type MainCashflowItemsState = {
	cashflowItems: StoreAsyncItem<Array<CashflowItem>>;
	roles: StoreAsyncItem<Array<AccountsChartItemRole>>;
	searchText: string;
	isIncomeItemsExpanded: boolean;
	isExpenseItemsExpanded: boolean;
};

const initialState: MainCashflowItemsState = {
	cashflowItems: createAsyncInitialState([]),
	roles: createAsyncInitialState([]),
	searchText: '',
	isIncomeItemsExpanded: false,
	isExpenseItemsExpanded: false,
};

const mainCashflowItemsReducer = createReducer(initialState, {
	[types.INVALIDATE_CASHFLOW_ITEMS]: (action: StaticAction<undefined>, state) => {
		return {
			cashflowItems: invalidateStateFromAction(action, state.cashflowItems),
		};
	},
	[types.FETCH_CASHFLOW_ITEMS]: (action: AsyncAction<Array<CashflowItem>>, state) => {
		return {
			cashflowItems: checkAsyncAction(action, state.cashflowItems, response =>
				transformNestedTreeToFlattenTree({ items: response, getChildItems: x => x.ChildItems as Array<CashflowItem> }),
			),
		};
	},
	[types.FETCH_ROLES]: (action: AsyncAction<Array<AccountsChartItemRole>>, state) => {
		return {
			roles: checkAsyncAction(action, state.roles),
		};
	},
	[types.ADD_CASHFLOW_ITEM]: (action: AsyncAction<CashflowItem>, state) => {
		return {
			cashflowItems: invalidateStateFromAction(action, state.cashflowItems),
		};
	},
	[types.UPDATE_CASHFLOW_ITEM]: (action: AsyncAction<CashflowItem>, state) => {
		return {
			cashflowItems: invalidateStateFromAction(action, state.cashflowItems),
		};
	},
	[types.REMOVE_CASHFLOW_ITEM]: (action: AsyncAction<boolean>, state) => {
		return {
			cashflowItems: invalidateStateFromAction(action, state.cashflowItems),
		};
	},
	[types.SET_FILTER_BY_TEXT]: (action: StaticAction<string>) => {
		return {
			searchText: action.value,
		};
	},
	[types.EXPAND_INCOME_ITEMS]: (action: StaticAction<boolean>) => {
		return {
			isIncomeItemsExpanded: action.value,
		};
	},
	[types.EXPAND_EXPENSE_ITEMS]: (action: StaticAction<boolean>) => {
		return {
			isExpenseItemsExpanded: action.value,
		};
	},
});

export { mainCashflowItemsReducer };
