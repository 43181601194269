import styled, { css } from 'styled-components';

import { fade } from '@utils/color';

type RootProps = {
	disabled: boolean;
	isActive: boolean;
	withGroupHeader: boolean;
};

const Root = styled.div<RootProps>`
	position: relative;
	width: 100%;
	height: auto;
	background-color: transparent;
	transition: background-color 0.2s ease-in-out;
	cursor: pointer;

	${p => css`
		&:hover {
			z-index: 1;
			background-color: ${fade(p.theme.palette.accent, 0.1)};

			.account-link-divider {
				width: 100%;
				background-color: ${p.theme.palette.accent};
			}

			&:last-child {
				.account-link-divider--bottom {
					background-color: ${p.theme.palette.accent};
				}
			}

			&:first-child {
				.account-link-divider--top {
					background-color: ${p.theme.palette.accent};
				}
			}

			.account-arrow-layout {
				display: block;
			}
		}
	`}

	${p =>
		p.disabled &&
		css`
			filter: grayscale(1);

			&:hover {
				.account-link-divider {
					background-color: transparent;
				}

				&:last-child {
					.account-link-divider--bottom {
						background-color: transparent;
					}
				}

				&:first-child {
					.account-link-divider--top {
						background-color: transparent;
					}
				}

				.account-arrow-layout {
					display: none;
				}
			}
		`}

	${p =>
		!p.withGroupHeader &&
		css`
			&:first-child {
				.account-link-divider--top {
					background-color: transparent;
				}
			}
		`}

	${p =>
		p.withGroupHeader &&
		css`
			& .account-link-divider {
				background-color: transparent;
			}

			&:hover {
				background-color: rgba(38, 208, 109, 0.05);
			}

			&:hover .account-link-divider {
				width: 100%;
				background-color: ${p.theme.palette.accent};
			}
		`}

	${p =>
		p.isActive &&
		css`
			& {
				background-color: rgba(38, 208, 109, 0.05);
			}

			&:hover .account-link-divider {
				width: 100%;
				background-color: ${p.theme.palette.accent};
			}
		`}
`;

const Divider = styled.div`
	position: absolute;
	right: 0;
	width: 100%;
	height: 1px;

	${p => css`
		background-color: ${p.theme.palette.stealth};
	`}
`;

const DividerTop = styled(Divider).attrs(() => ({
	className: 'account-link-divider account-link-divider--top',
}))``;

const DividerBottom = styled(Divider).attrs(() => ({
	className: 'account-link-divider account-link-divider--bottom',
}))``;

const ContentLayout = styled.div`
	width: auto;
	height: auto;
	min-height: 97px;
	padding: 10px 40px;
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: center;
`;

interface IIconLayout {
	iconScale: number;
}

const IconLayout = styled.div`
	margin-right: 15px;
	width: 54px;
	height: 54px;
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	align-items: center;

	& svg {
		width: 55px !important;
		height: 55px !important;
		position: relative;
	}

	& .svgAddBankAccountGroup {
		position: absolute;
		transform: translateY(5px);
	}

	${(props: IIconLayout) =>
		props.iconScale &&
		css`
			& svg {
				zoom: ${props.iconScale};
			}
		`}
`;

const SecondIconLayout = styled.div`
	font-size: 0;
	margin-left: 8px;
`;

interface ITextLayout {
	textMaxWidth: string;
}

const TextLayout = styled.div`
	max-width: 60%;

	${(p: ITextLayout) =>
		p.textMaxWidth &&
		css`
			max-width: ${p.textMaxWidth};
		`}
`;

const Title = styled.div`
	font-size: 16px;
	font-weight: 500;
	line-height: 1.25;
	text-align: left;
`;

const Text = styled.div`
	font-size: 14px;
	line-height: 1.25;
	text-align: left;
	margin-top: 4px;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;

	${p => css`
		color: ${p.theme.palette.label};
	`}
`;

export { Root, DividerTop, DividerBottom, ContentLayout, IconLayout, SecondIconLayout, TextLayout, Title, Text };
