import React from 'react';

import { useMapState } from '@core/flux';
import { BusinessServiceType } from '@platform/model';
import { selectBusinessServiceType } from '@platform/selectors/billing.selectors';
import { AppMenu } from '@ui/app-menu';
import { filterRoutes } from './urls';

export type AppMenuLinkProps = {
	availableComponents: Array<ApplicationComponent>;
	userRights: Array<SecurityRight>;
	tenantProfile: TenantProfile;
	isFullAccess: boolean;
};

const AppMenuLinks: React.FC<AppMenuLinkProps> = props => {
	const { userRights, availableComponents, isFullAccess } = props;
	const [businessServiceType] = useMapState([selectBusinessServiceType]);
	const isBfmApp = businessServiceType === BusinessServiceType.BFM;
	const links = filterRoutes(availableComponents, userRights, undefined, isFullAccess).filter(x => x.showInMenu);

	return !links.length ? null : <AppMenu links={links} isBfmApp={isBfmApp} />;
};

export default AppMenuLinks;
