import { createTypes } from '@flux';

type ActionTypes = {
	RUN_INVALIDATION_EFFECT: string;
	INVALIDATE: string;
	FETCH: string;
	SET_DATE: string;
	SET_TENANT_ENTITIES_FILTER: string;
	EXPAND_ASSETS_ITEMS: string;
	EXPAND_LIABILITIES_ITEMS: string;
};

export const types = createTypes<ActionTypes>(
	[
		'RUN_INVALIDATION_EFFECT',
		'INVALIDATE',
		'FETCH',
		'SET_DATE',
		'SET_TENANT_ENTITIES_FILTER',
		'EXPAND_ASSETS_ITEMS',
		'EXPAND_LIABILITIES_ITEMS',
	],
	'BALANCE_REPORT',
);
