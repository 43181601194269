import { combineReducers } from 'redux';

import { mainBusinessUnitsReducer, MainBusinessUnitsState } from './main.reducer';

export type BusinessUnitsState = {
	main: MainBusinessUnitsState;
};

const businessUnitsReducer = combineReducers<BusinessUnitsState>({
	main: mainBusinessUnitsReducer,
});

export { businessUnitsReducer };
