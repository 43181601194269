import { Store } from 'redux';

import { IAppState } from '@store';
import { isReceiveAction } from '@flux';
import { types as projectEmployeesTypes } from '@project-employees/actions/types';
import { types } from '@employees/actions/types';
import { invalidateEmployees } from '@employees/actions/invalidators';

function askEmployeesInvalidator(store: Store<IAppState>, action: StoreAction) {
	const { dispatch } = store;
	const syncMap = {
		[types.RUN_EMPLOYEES_INVALIDATION_EFFECT]: true,
	};
	const asyncMap = {
		[projectEmployeesTypes.ADD_EMPLOYEE]: true,
		[projectEmployeesTypes.UPDATE_EMPLOYEE]: true,
		[projectEmployeesTypes.REMOVE_EMPLOYEE]: true,
	};

	if (syncMap[action.type]) {
		dispatch(invalidateEmployees());
	} else if (asyncMap[action.type] && isReceiveAction(action)) {
		dispatch(invalidateEmployees());
	}
}

function createInvalidatorEffect() {
	return (store: Store<IAppState>) => (next: (action: StoreAction) => void) => (action: StoreAction) => {
		next(action);
		askEmployeesInvalidator(store, action);
	};
}

const runInvalidatorEffect = createInvalidatorEffect();

export { runInvalidatorEffect };
