import React from 'react';

import { Root } from './styled';

export type AlertProps = {
	appearance?: 'warning' | 'danger' | 'success' | 'default';
	fullWidth?: boolean;
	children: React.ReactNode;
};

const Alert: React.FC<AlertProps> = props => {
	return <Root {...props} />;
};

Alert.defaultProps = {
	appearance: 'default',
};

export { Alert };
