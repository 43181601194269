import { createStaticAction } from '@flux';
import { types } from './types';

const invalidateProjects = () => createStaticAction(types.INVALIDATE_PROJECTS)();

const invalidateOperations = () => createStaticAction(types.INVALIDATE_OPERATIONS)();

const runProjectsInvalidationEffect = () => createStaticAction(types.RUN_PROJECTS_INVALIDATION_EFFECT)();

export { invalidateProjects, invalidateOperations, runProjectsInvalidationEffect };
