// @ts-nocheck
import React from 'react';

import { IFlexLayoutModuleStyled, Root, Item } from './styled';

declare namespace IFlexLayoutModule {
	interface IFlexLayoutProps extends IFlexLayoutModuleStyled.IRoot {
		style?: React.CSSProperties;
		children: React.ReactNode;
	}
	interface IFlexLayoutItemProps extends IFlexLayoutModuleStyled.IItem {
		style?: React.CSSProperties;
		children: React.ReactNode;
	}
}

class FlexLayoutItem extends React.Component<IFlexLayoutModule.IFlexLayoutItemProps> {
	static defaultProps = {};

	render() {
		const { children, ...rest } = this.props;

		return <Item {...rest}>{this.props.children}</Item>;
	}
}

class FlexLayout extends React.Component<IFlexLayoutModule.IFlexLayoutProps> {
	static defaultProps = {
		inline: false,
	};
	static Item = FlexLayoutItem;

	render() {
		const { children, ...rest } = this.props;

		return <Root {...rest}>{this.props.children}</Root>;
	}
}

export default FlexLayout;
