import React from 'react';
import * as _ from 'underscore';

import { detectIsFunction } from '@utils/helpers';
import { Box } from '@ui/box';
import { Form, FormContextProps } from '@ui/forms';
import { ValidationHandler } from '../form';
import { withFormComponent } from '../component';
import { IconButton, IconButtonConfirm } from '@ui/icon-button';
import { RaisedButton } from '@ui/raised-button';
import { EditIcon } from '@ui/icons/edit';
import { DeleteIcon } from '@ui/icons/delete';
import { OkIcon } from '@ui/icons/ok';
import { LineRendererButton } from '@ui/list/line-renderer';
import { ItemRoot, RemoveButtonLayout, ContentLayout, ChildrenLayout, Input, TextLayout } from './styled';

export type FormRepeaterProps<T> = {
	actionLabel?: string;
	addBtnLayoutStyle?: React.CSSProperties;
	addCustomFormValidation?: ValidationHandler<T>;
	appearance?: 'material-card' | 'simple';
	children: React.ReactNode | ((formObject, index, name, fn) => React.ReactNode);
	confirmLabel?: string;
	defaultFormObject?: T;
	errorText?: React.ReactNode;
	fullWidth?: boolean;
	name: string;
	onAddItem?: (item: T, index: number) => void;
	onChangeItem?: (item: T, index: number) => void;
	onCheckBeforeAddItem?: (cb: () => void) => void;
	readonly?: boolean;
	variant?: 'dark-alternate';
	withCustomItem?: boolean;
	withoutControls?: boolean;
	withoutInitiallyItem?: boolean;
	withoutRemoveButton?: boolean;
	withRemoving?: boolean;
} & Partial<FormContextProps<T>>;

class FormRepeater<T extends { ID: number }> extends React.Component<FormRepeaterProps<T>> {
	static defaultProps = {
		appearance: 'material-card',
		confirmLabel: 'OK',
	} as any;
	formRefList: Array<Form<T>> = [];
	formResults = [];
	nextID = -1;

	componentDidMount() {
		const { formObject, name, withoutInitiallyItem, addFormValidation, addCustomFormValidation } = this.props;
		const zone = formObject[name];

		if (!withoutInitiallyItem && zone && zone.length === 0) {
			this.handleAddFormItem();
			this.forceUpdate();
		}

		typeof addCustomFormValidation === 'function' && addFormValidation(addCustomFormValidation);
	}

	componentWillUnmount() {
		const { removeFormValidation, addCustomFormValidation } = this.props;

		typeof addCustomFormValidation === 'function' && removeFormValidation(addCustomFormValidation);
	}

	private handleAddFormItem = () => {
		const { name, formObject, defaultFormObject, handleObjectChange, onCheckBeforeAddItem, onAddItem } = this.props;
		this.nextID = formObject[name].length ? _.min(formObject[name], (item: T) => item.ID).ID - 1 : -1;
		this.nextID = this.nextID < 0 ? this.nextID : -1;
		const item = { ...(defaultFormObject as T), ID: this.nextID-- };

		if (typeof onCheckBeforeAddItem === 'function') {
			onCheckBeforeAddItem(() => {
				formObject[name].push(item);
				handleObjectChange(formObject);
				onAddItem && onAddItem(item, formObject[name].length - 1);
			});
		} else {
			formObject[name].push(item);
			handleObjectChange(formObject);
			onAddItem && onAddItem(item, formObject[name].length - 1);
		}
	};

	private handleRemoveItem = (item: T) => {
		const { name, formObject, handleObjectChange } = this.props;

		formObject[name] = formObject[name].filter(i => i.ID !== item.ID);
		handleObjectChange(formObject);
	};

	private handleChangeItem = (index: number) => (item: T) => {
		const { formObject, handleObjectChange, onChangeItem } = this.props;

		handleObjectChange(formObject);
		onChangeItem && onChangeItem(item, index);
	};

	private renderChildren = () => {
		const {
			addFormValidation,
			appearance,
			confirmLabel,
			formObject,
			name,
			readonly,
			removeFormValidation,
			variant,
			withCustomItem,
			withoutControls,
			withoutInitiallyItem,
			withoutRemoveButton,
			withRemoving,
		} = this.props;
		const zone = formObject[name];

		if (zone && zone.length > 0) {
			return zone.map((item, index) => {
				return (
					<FormRepeaterItem
						addFormValidation={addFormValidation}
						appearance={appearance}
						confirmLabel={confirmLabel}
						item={item}
						key={item.ID}
						onChange={this.handleChangeItem(index)}
						onRemove={!withoutInitiallyItem ? zone.length > 1 && this.handleRemoveItem : this.handleRemoveItem}
						readonly={readonly}
						removeFormValidation={removeFormValidation}
						variant={variant}
						withCustomItem={withCustomItem}
						withoutControls={withoutControls}
						withoutRemoveButton={withoutRemoveButton}
						withRemoving={withRemoving}>
						{typeof this.props.children === 'function'
							? this.props.children(formObject, index, name, this.handleRemoveItem)
							: this.props.children}
					</FormRepeaterItem>
				);
			});
		}

		return null;
	};

	render() {
		const { actionLabel, readonly, withoutControls, errorText, fullWidth, addBtnLayoutStyle } = this.props;
		const needRenderAddButton = !readonly && !withoutControls;

		return (
			<Box position='relative' fullWidth={fullWidth}>
				{errorText && <TextLayout color='error'>{errorText}</TextLayout>}
				{this.renderChildren()}
				{needRenderAddButton && (
					<div style={addBtnLayoutStyle}>
						<RaisedButton appearance='text' size='small' color='primary' onClick={this.handleAddFormItem}>
							{actionLabel || '+ Добавить ещё'}
						</RaisedButton>
					</div>
				)}
			</Box>
		);
	}
}

export type FormRepeaterItemProps<T = any> = {
	addFormValidation: (fn) => void;
	children: React.ReactNode;
	confirmLabel: string;
	item: T;
	onChange: (item: T) => void;
	onRemove: (item: T) => void;
	readonly: boolean;
	removeFormValidation: (fn) => void;
	withCustomItem: boolean;
	withoutControls: boolean;
	withoutRemoveButton: boolean;
	withRemoving: boolean;
} & Pick<FormRepeaterProps<T>, 'appearance' | 'variant'>;

class FormRepeaterItem<T> extends React.Component<FormRepeaterItemProps<T>> {
	formRef: Form<T> = null;

	componentDidMount() {
		this.props.addFormValidation(this.validateForm);
	}

	componentWillUnmount() {
		this.props.removeFormValidation(this.validateForm);
	}

	setFormRef = (ref: Form<T>) => (this.formRef = ref);

	validateForm = () => {
		if (this.formRef && this.formRef.validateForm()) {
			return true;
		}

		return false;
	};

	handleChange = () => this.props.onChange(this.props.item);

	handleRemove = () => this.props.onRemove(this.props.item);

	render() {
		const {
			appearance,
			confirmLabel,
			item,
			onRemove,
			readonly,
			variant,
			withCustomItem,
			withoutControls,
			withoutRemoveButton,
			withRemoving,
		} = this.props;
		const needRenderRemoveButton =
			(!withCustomItem && !readonly && !withoutControls && !withoutRemoveButton) || withRemoving;

		return (
			<ItemRoot itemAppearance={appearance} variant={variant}>
				<Form
					ref={this.setFormRef}
					formObject={item}
					disableCloneObject
					preventSetState
					fullWidth
					onChange={this.handleChange}>
					<ContentLayout>
						<ChildrenLayout>{this.props.children}</ChildrenLayout>
						{needRenderRemoveButton && (
							<RemoveButtonLayout>
								<LineRendererButton
									title='Удалить'
									icon={<DeleteIcon color='muted' />}
									disabled={!onRemove}
									stopPropagation
									confirmLabel={confirmLabel}
									onClick={this.handleRemove}
								/>
							</RemoveButtonLayout>
						)}
					</ContentLayout>
				</Form>
			</ItemRoot>
		);
	}
}

type CustomFormRepeaterItemProps<T> = {
	formRef: Form<T>;
	item: T;
	fieldName?: string;
	index: number;
	briefObjectName: string;
	itemRenderer?: (item: T) => React.ReactNode;
	onChange: (formObject: T) => void;
	onKeyDown?: (e) => void;
} & FormContextProps<T>;

type CustomFormRepeaterItemState = {
	value: string;
	isHovered: boolean;
	isEdited: boolean;
};

class CustomFormRepeaterItem<T> extends React.Component<CustomFormRepeaterItemProps<T>, CustomFormRepeaterItemState> {
	static displayName = 'CustomFormRepeaterItem';
	static defaultProps = {
		fieldName: 'Name',
	};
	state = {
		value: this.props.item[this.props.fieldName],
		isHovered: false,
		isEdited: false,
	};
	inputRef = null;

	componentDidMount() {
		this.focus();
	}

	focus = () => this.inputRef && this.inputRef.focus();

	setInputRef = (ref: HTMLInputElement) => {
		this.inputRef = ref;
	};

	handleChangeItem = e => {
		this.setState({ value: e.target.value });
	};

	handleMouseOver = () => {
		!this.state.isHovered && this.setState({ isHovered: true });
	};

	handleMouseLeave = () => {
		this.state.isHovered &&
			this.setState({
				isHovered: false,
			});
	};

	handleMoveCaretToEnd = e => {
		const value = e.target.value;

		e.target.value = '';
		e.target.value = value;
	};

	handleEditItem = () => this.setState({ isEdited: true }, this.focus);

	handleDeleteItem = () => {
		const { formRef, briefObjectName, index, onChange } = this.props;

		if (formRef) {
			const rootFormObject = formRef.getFormObject();

			rootFormObject[briefObjectName].splice(index, 1);
			formRef.handleObjectChange(rootFormObject);
			onChange(rootFormObject);
		}
	};

	handleSaveItem = () => {
		const { formRef, item, fieldName, formObject, handleObjectChange, onChange } = this.props;
		const { value } = this.state;
		const name = value || item[fieldName];

		if (name) {
			formObject[fieldName] = name;

			handleObjectChange(formObject);
			const rootFormObject = formRef.getFormObject();

			onChange(rootFormObject);
			this.setState({ isEdited: false });
		}
	};

	handleKeyDown = e => {
		if (e.key === 'Enter') {
			this.handleSaveItem();
		}
	};

	renderName = () => {
		const { item, fieldName, itemRenderer } = this.props;
		const { value, isEdited } = this.state;
		const name = item[fieldName];

		if (name && !isEdited) {
			return detectIsFunction(itemRenderer) ? itemRenderer(item) : name;
		}

		return (
			<Input
				ref={this.setInputRef}
				value={value}
				onClick={ev => ev.stopPropagation()}
				onFocus={this.handleMoveCaretToEnd}
				onChange={this.handleChangeItem}
				onKeyDown={this.handleKeyDown}
			/>
		);
	};

	render() {
		const { item, fieldName } = this.props;
		const { isEdited, isHovered } = this.state;
		const hasItem = Boolean(item[fieldName]);
		const hasControls = isHovered || isEdited || !hasItem;

		return (
			<Box
				display='flex'
				justifyContent='space-between'
				alignItems='center'
				minHeight={32}
				marginBottom={8}
				onMouseOver={this.handleMouseOver}
				onMouseLeave={this.handleMouseLeave}>
				<Box flex='1 1 auto' display='flex' alignItems='center' paddingRight={4}>
					{this.renderName()}
				</Box>
				{hasControls && (
					<Box flex='0 0 auto' display='flex'>
						{isEdited || !hasItem ? (
							<IconButton variant='rounded' stopPropagation onClick={this.handleSaveItem}>
								<OkIcon color='accent' />
							</IconButton>
						) : (
							<IconButton variant='rounded' stopPropagation onClick={this.handleEditItem}>
								<EditIcon color='muted' />
							</IconButton>
						)}
						<IconButtonConfirm variant='rounded' stopPropagation onClick={this.handleDeleteItem}>
							<DeleteIcon color='muted' />
						</IconButtonConfirm>
					</Box>
				)}
			</Box>
		);
	}
}

const CustomFormRepeaterItemWithForm =
	withFormComponent<Partial<CustomFormRepeaterItemProps<any>>>(CustomFormRepeaterItem);

export { CustomFormRepeaterItemWithForm as CustomFormRepeaterItem };
export default withFormComponent(FormRepeater);
